import React from "react";
import { IconInfo } from "../../../Common/Icons";
import useHover from "../../../CustomHooks/useHover";
import SideBarContactInfo from "../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/SideBarContactInfo";
const DeliveryWarehouse = ({ consignee }) => {
  const [referenceElementRef, referenceElement] = useHover();
  return (
    <>
        <div className="mt-10">
          <div className="d-flex justify-content-between align-items-start">
            <div className="font-medium mb-2 font-14 text-capitalize mr-1">
              {consignee.company_name}
            </div>
            <div>
              <div
                className={`bg-light wh-16px rounded-circle d-flex justify-content-center align-items-center pointer ${
                  referenceElement && "bg-gray-200 pointer"
                }`}
                ref={referenceElementRef}
              >
                <IconInfo className="text-gray-900 font-10" />
              </div>
            </div>
            {referenceElement && (
              <SideBarContactInfo
                opne={referenceElement}
                refNo={referenceElementRef.current}
                email={consignee.email}
                mobile={consignee.mobile}
                color={"gray-700"}
                cssClass={"sidebar-contact__info"}
              />
            )}
          </div>
          <div
            className={`${
              (consignee.main_contact_name ||
                consignee.mobile ||
                consignee.email) &&
              "text-gray-900 font-weight-normal font-12"
            }`}
          >
            {consignee.address && consignee.address.address}
          </div>
        </div>
    </>
  );
};

export default DeliveryWarehouse;
