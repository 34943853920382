import React from 'react';
import { IconAngleArrowLeft, IconPlus } from '../../../Components/Common/Icons';

const ChassisLoadSubHeader = (props) => {
  const { handleChassisLoad } = props;
  return (
    <React.Fragment>
      <div className="subheader">
        <div className="subheader__inner d-flex">
          <div className="d-flex flex-row align-items-center">
            <button
              className="btn btn-outline-light"
              onClick={handleChassisLoad}
            >
              <IconAngleArrowLeft />
            </button>
            <div className="d-flex ml-3 align-items-center">
              <h5 className="mb-0">{props.chassisNo}</h5>
            </div>
          </div>
          {props.buttons}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChassisLoadSubHeader;
