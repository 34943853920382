export const header = 'header';
export const Address = 'Address';
export const Phone = 'Phone';
export const COMPANY_NAME = 'Company Name';
export const ESTIMATED_RATE = "estimatedRate";
export const textarea = 'textarea';
export const textInput = 'textInput';
export const section1 = 'section1';
export const subSection = 'subSection';
export const selectDropDown = 'selectDropDown';
export const section2 = 'section2';
export const section3 = 'section3';
export const orderDetail = 'orderDetail';
export const commodity = 'commodity';
export const pricing = 'pricing';
export const invoice = 'Invoice';
export const deliveryOrder = 'Delivery Order';
export const pod = 'Proof of Delivery';
export const rateConf = 'Rate Confirmation';
export const carrierRateCon = 'Carrier Rate Confirmation';
export const combineInvoice = 'Combine Invoice';
export const combineInvoiceNew = 'Combine Invoice New';
export const SUB_INVOICE = 'Sub Customer Invoice';
export const SUB_COMBINE = "Sub Customer Combine Invoice";
export const SUB_COMBINE_NEW = "Sub Customer Combine Invoice New";
export const Statement = 'Statement';
export const AccentColor = 'accentColor';
export const TextColor = 'textColor';
export const typeOfLoad = "typeOfLoad";
export const customers = "customers";
export const defaultAccentColor = "#367BF6";
export const NOTES = 'notes';
export const DISCLAIMER = 'disclaimer';
export const summarySection1 = "summarySection1";
export const statement = 'statement';

export const defaultCurrency = 'defaultCurrency';

export const TypeOfLoad = [
    { value: "IMPORT", label: "IMPORT" },
    { value: "EXPORT", label: "EXPORT" },
    { value: "ROAD", label: "ROAD" },
    { value: "BILL_ONLY", label: "BILL ONLY" },
    { value : "ALL", label : "ALL"}
  ];

export const loadInfos = 'loadInfos';


export const loads = 'loads';
export const summary = 'summary';
export const totalAmount = 'totalAmount';
export const DocumentTypeOptions = [
  {value : invoice , label : invoice},
  {value : SUB_INVOICE , label : SUB_INVOICE},
  {value : deliveryOrder, label : deliveryOrder},
  {value : pod, label : pod},
  {value : rateConf, label : rateConf},
  {value : combineInvoice, label : combineInvoice},
  {value : SUB_COMBINE, label : SUB_COMBINE},
  {value : combineInvoiceNew, label : combineInvoiceNew},
  {value : SUB_COMBINE_NEW, label : SUB_COMBINE_NEW},
  {value : carrierRateCon, label : carrierRateCon},
  {value : Statement, label : Statement}
]
export const getBoxName = (section, DocType) =>  {
   let obj = {
     header : "Header",
     section1 : `${DocType} Details `,
     subSection : `${DocType} Details `,
     section2 : 'Address Details',
     orderDetail : 'Order Details',
     commodity : "Commodity Details",
     pricing : "Charge Details",
     notes : "Notes ",
     disclaimer : 'Disclaimer',
     loadInfos : 'Signature ',
     loads : 'Loads ',
     summary : 'Summary ',
     totalAmount : 'Total Amount',
     estimatedRate : 'Estimated Rate',
     note : 'Notes',
     section3 : 'Driver/Carrier Details',
     summarySection1 : 'Summary Details',
     statement : 'Statement'
   }
   return obj[section]
}
export const widthCalc = (title, factor = 1) => {
  let widthPercentage ;
    switch (title){
    case 'Charge Name' : widthPercentage = 18 * factor; break;
    case "Description" :widthPercentage = 32 * factor; break;
    case "Units" : widthPercentage = 12.5 * factor; break;
    case "Free Units" : widthPercentage = 12.5 * factor; break;
    case "Rates" : widthPercentage = 12.5 * factor; break;
    case "Charges" :widthPercentage = 12.5 * factor; break;
    case 'Commodity' : widthPercentage = 18 * factor; break;
    case "Weight" : widthPercentage = 16.6 * factor; break;
    case "Pallets" : widthPercentage = 16.6 * factor; break;
    case "Pieces" : widthPercentage = 16.6 * factor; break;   
  }
   return widthPercentage;
}
export const calculatePricingWidth = (fields, title ) =>{
  
  
  let totalPercentage = 0;
  let filtredRows = fields.map(item => {
    if(item.isShow)  {
      totalPercentage += widthCalc(item.title, 1)
    }
  });
  let factor = 100/totalPercentage;
  return widthCalc(title, factor)
}

export const getFieldsArrayByTypeOfDocument = (type) =>{
  let fieldArray = {
    [invoice] : [header, section1,subSection, section2,orderDetail,commodity,pricing,NOTES,DISCLAIMER],
    [SUB_INVOICE] : [header, section1,subSection, section2,orderDetail,commodity,pricing,NOTES,DISCLAIMER],
    [deliveryOrder] : [header, section1,subSection,section2,orderDetail,commodity,loadInfos,NOTES, DISCLAIMER],
    [pod] : [header, section1,subSection,section2,orderDetail,commodity,loadInfos,NOTES, DISCLAIMER],
    [rateConf] : [header, section1,subSection,section2,orderDetail,commodity,pricing, ESTIMATED_RATE, loadInfos,NOTES, DISCLAIMER],
    [carrierRateCon] : [header, section1,subSection, section2, section3,orderDetail, commodity, pricing,ESTIMATED_RATE,loadInfos,NOTES,DISCLAIMER],
    [combineInvoice] : [header, section1,subSection,section2, loads,summary, NOTES, totalAmount, DISCLAIMER],
    [SUB_COMBINE] : [header, section1,subSection,section2, loads,summary, NOTES, totalAmount, DISCLAIMER],
    [combineInvoiceNew] : [header, section1,subSection, loads, NOTES, totalAmount, DISCLAIMER],
    [SUB_COMBINE_NEW] : [header, section1,subSection, loads, NOTES, totalAmount, DISCLAIMER],
    [Statement] : [header, section1,subSection, section2, summary]
  }
  return fieldArray[type]
}
