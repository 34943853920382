import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import * as $ from "jquery";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { convertTMSStatusName } from "../../Components/Common/functions";
import configuration from "../../config";
import NewTranscations from "../../pages/tms/carrier/Transcation/NewTranscations";
import AuditLoad from "../../pages/tms/Load/AuditLoad";
import NewBillOfLading from "../../pages/tms/Load/NewBillOfLading";
import BillOfLading from "../../pages/tms/Load/BillOfLading";
import Documents from "../../pages/tms/Load/Documents";
import Load from "../../pages/tms/Load/Load";
import LoadDriverOrder from "../../pages/tms/Load/LoadDriverOrder";
import LoadHistory from "../../pages/tms/Load/LoadHistory";
import Notes from "../../pages/tms/Load/Notes";
import OneLoadMessaging from "../../pages/tms/Load/OneLoadMessaging";
import Payment from "../../pages/tms/Load/Payment";
import {
  duplicateLoad, removeLoad, sendBillingEmail
} from "../../pages/tms/services";
import { convertUnit,
   getStorage, toastr, isTrialUser, isNewModal, isMilitaryTime
} from "../../services/Common.services";
import confirm from "../Common/ConfirmAert";
import { CustomIconAlert, CustomIconSaveOutline } from "../Common/CustomIcons/Index";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight, IconAt,
  IconBilling, IconCaretDown, IconClone, IconDocument,
  IconInfoFocus,
  IconMessage,
  IconNotes,
  IconPayment,
  IconPhone, IconPrinter, IconReset, IconSearchData, IconSendEmail, IconStatus,
  IconTrack, IconTrash, IconTruck, IconUserSingle,IconMapMarker, IconTimesThin, IconTimesThinClose, IconPlusSmall, IconCloseBold, IconInfo
} from "../Common/Icons";

import ReactTooltip from "react-tooltip";
import StatusComponent from "../Common/StatusComponent";
import SubHeaderTab from "../../Components/SubHeaderTab";
import { debounce } from "throttle-debounce";
import * as actions from '../../pages/tms/Customer/actionCreators'
import {assignCSRtoCustomer} from '../../pages/tms/Customer/actionCreators'
import { color } from "highcharts";
import { getLoadRoute, calculateLoadDistance } from "./actions";
import { streetTile } from "../../pages/trucker/NewTrack1/utility";
import NewPaymentInline from "../../pages/tms/Load/NewPaymentInline";
import { Modal } from "react-bootstrap";
import NewLoadDriverOrder from "../../pages/tms/Load/NewLoadDriverOrder";
import CustomTooltip from "../Common/CustomTooltip";
import DriverPay from "./Tabs/DriverPay";
import NewOneLoadMessaging from "../../pages/tms/Load/NewOneLoadMessaging";
import NewNotes from "../../pages/tms/Load/NewNotes";
import NewLoadHistory from "../../pages/tms/Load/NewLoadHistory";
import Port from "./Tabs/Components/Port";
import DeliveryWarehouse from "./Tabs/Components/DeliveryWarehouse";
const firebase = configuration.firebase;

const polyline = require("@mapbox/polyline");

mapboxgl.accessToken = configuration.map_box_api_key;
let routePoints = [];
const colors = [
  "#e87676",
  "#d4cc37",
  "#91c21f",
  "#0b9914",
  "#71e3cc",
  "#427ae3",
  "#27247d",
  "#ae4dbd",
  "#d13689",
  "#d90b2d",
];
var geojson = {
  type: "geojson",
  data: {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  },
};

class CustomerServiceSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.type ? props.types : props.activeTab,
      selectedLoad: null,
      TMSCustomers: [],
      activeUsers: [],
      loggedUser: null,
      user: JSON.parse(getStorage("loggedInUser")),
      showBilling: true,
      showExtraData: false,
      isSavingLoad: false,
      isSaveOptions: false,
      billingActiveTab: props.billingActiveTab?props.billingActiveTab:"main-customer",
      noteCount: 0,
      showAllCSRList : false,
      csrSearchTerm: "",
      isloading : false,
      assignedCSR : [],
      listAllCSR : [],
      showRemoveIcon : undefined,
      measureUnit : "Miles",
      totalDistance : "",
      isShowingPrompt : false,
      isBlockingTabChange : false,
      nextTab : undefined,
      closingSideBar : false,
      toChangeLoad  : undefined
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._duplicateLoad = this._duplicateLoad.bind(this);
    this.getHeaderButtons = this.getHeaderButtons.bind(this);
    this.blockTabChange = this.blockTabChange.bind(this);
    this.changeTopTab = this.changeTopTab.bind(this);
    this.showPrompt = this.showPrompt.bind(this);
    this.continueWithoutSaving = this.continueWithoutSaving.bind(this);
    this.closeCustomerServiceSideBar = this.closeCustomerServiceSideBar.bind(this);
    this.handleNextPrevLoad = this.handleNextPrevLoad.bind(this);
    this.routeLayers = [];
    this.routeLayerGroup = null;
    this.userTimeFormat = isMilitaryTime();
    const currentUser = JSON.parse(getStorage("loggedInUser"));
    this.isBillingInline = false;
      if(currentUser && currentUser.isBillingInline){
        this.isBillingInline = true;
      } 
  }

  handleTabChange = (value) => {
    this.setState({ billingActiveTab: value , isBlockingTabChange: false });
  };

  componentDidUpdate(props) {
    if(!_.isEqual(props.selectedLoad, this.props.selectedLoad)) this.setDistanceUnit()
  }

  handleSubmitLoad = () => {};

  handleExpenseTab = () => {
    this.setState({ isBlockingTabChange: false });
  }

  showMap(props) {
    const oldReference_number = _.get(
      this.state.selectedLoad,
      "reference_number"
    );
    const newReference_number = _.get(props.selectedLoad, "reference_number");
    if (props.showSidebar && newReference_number != oldReference_number) {
      setTimeout(() => {
        this.loadMap();
      }, 1000);
      setTimeout(() => {
        this._getLoadRoute();
      }, 2000);
    }
    this.setState({ selectedLoad: props.selectedLoad });
  }

  
  componentWillReceiveProps(props) {

    this.showMap(props);
    let loadCount = 0;
    if(props.selectedLoad){
      for (const [keys, values] of Object.entries(props.selectedLoad)) {
        ["driverNote", "billingNote", "yardNote", "officeNote"].map((d) => {
          if (keys.includes(d) && values !== "") {
            loadCount = loadCount + 1;
          }
        });
      }
      this.setState({assignedCSR : props.selectedLoad.assignedCSR })
    }
    this.setState({ noteCount: loadCount, activeTab: props.activeTab });
    if(this.props.selectedLoad.distance !== this.state.totalDistance) this.setDistanceUnit()
  }

  getFirebaseFieldName(path) {
    let fieldName = "test";
    if (path.toLowerCase() === "load".toLowerCase()) {
      fieldName = "loadinfo";
    } else if (path.toLowerCase() === "billing".toLowerCase()) {
      fieldName = "billing";
    } else if (path.toLowerCase() === "documents".toLowerCase()) {
      fieldName = "document";
    } else if (path.toLowerCase() === "payments".toLowerCase()) {
      fieldName = "payment";
    } else if (path.toLowerCase() === "orderstatus".toLowerCase()) {
      fieldName = "statusorder";
    } else if (path.toLowerCase() === "driverpay".toLowerCase()) {
      fieldName = "driverpay";
    }
    return fieldName;
  }
  componentDidMount() {
    // this.fetchUsers();
    this.setState({ selectedLoad: this.props.selectedLoad, assignedCSR : this.props.selectedLoad.assignedCSR }, () => {
      if (
        this.state.selectedLoad &&
        Object.keys(this.state.selectedLoad).length &&
        getStorage("loggedInUser") != null
      ) {
        const currentUser = JSON.parse(getStorage("loggedInUser"));
        this.setState({ loggedUser: currentUser });
        let carrierId = currentUser._id;
        if (currentUser.role === "driver") {
          carrierId = currentUser.driver.carrier;
        }
        if (currentUser.role === "fleetmanager") {
          carrierId = currentUser.fleetManager.carrier;
        }
        if (
          currentUser.customer &&
          currentUser.customer.customerType &&
          currentUser.customer._id != this.state.selectedLoad.caller._id
        ) {
          this.setState({ showBilling: false });
        }

        this.userPresense = firebase
          .database()
          .ref(
            `${carrierId}/presense/${this.state.selectedLoad.reference_number}`
          );
        this.userPresense.on("value", (snapshot) => {
          const data = snapshot.val();
          const pathField = `${this.getFirebaseFieldName(
            `${this.state.activeTab}`
          )}`;
          if (data && data[pathField]) {
            const activeUsers = [];
            Object.keys(data[pathField]).forEach((element) => {
              if (data[pathField][element]) {
                activeUsers.push(element);
              }
            });
            this.setState({ activeUsers });
          }
        });
      }
    });
    document.addEventListener("mousedown", this.handleClickOutside);
    this.showMap(this.props);
    this.listAllCSR();
    this.setDistanceUnit();
  }

  componentWillUnmount() {
    const scroller = document?.getElementById('load-tabs');
    scroller?.removeEventListener("scroll", this.handleScroll);  
    document.removeEventListener("mousedown", this.handleClickOutside);  
    if (this.userPresense) {
      this.userPresense.off("value");
    }
    if(this.map){
      this.map.remove();
    }
    this.handleClickOutside = null;
    this._duplicateLoad = null;
    this.getHeaderButtons = null;
  }

  handleClickOutside(event) {
    if (
      this.state.isSaveOptions &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({ isSaveOptions: false });
    }
    if (this.state.showAllCSRList && this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({showAllCSRList : false})
    }
  }
  
  
  changeTopTab(tab){
    if(tab !== this.state.activeTab && this.state.isBlockingTabChange){
      this.setState({isShowingPrompt : true, nextTab : tab});
    }else{
      if(this.props && this.props.changeCustomerServiceSidebarTab){
        this.props.changeCustomerServiceSidebarTab(
          tab
        );
      }
      this.setState({ activeTab: tab, nextTab : undefined }) 
     }
  }
  showPrompt(value){
    this.setState({isShowingPrompt : value})
  }

  blockTabChange (value){
    this.setState({isBlockingTabChange : value})
  }
  handleClosePrompt() {
    if (this.state.isBlockingTabChange) {
      this.setState({isShowingPrompt : true});
    } else {
      this.props.closeCustomerServiceSideBar()
    }
  }
  continueWithoutSaving(){
    this.setState({isShowingPrompt : false, isBlockingTabChange : false},()=>{
      if(this.state.nextTab){
        this.changeTopTab(this.state.nextTab)
      } 
      if(this.state.closingSideBar){
        this.closeCustomerServiceSideBar()
      }
      if(this.state.toChangeLoad){
        this.handleNextPrevLoad(this.state.toChangeLoad)
      }
      if(this.state.isCloseModal) {
        this.handleClosePrompt()
      }
    });
  }
  closeCustomerServiceSideBar(){
    if(this.state.isBlockingTabChange){
      this.showPrompt(true);
      this.setState({closingSideBar : true})
    }else{
      this.setState({closingSideBar : false})
      this.props.closeCustomerServiceSideBar()
    }
  }
  handleNextPrevLoad(dir){
    let handleFunction = dir == 'next' ? this.props.handleNextLoad : this.props.handlePrevLoad;
     if(this.state.isBlockingTabChange){
      this.showPrompt(true);
      this.setState({ toChangeLoad : dir})
     }else{
      this.setState({ toChangeLoad : undefined})
      handleFunction()
     } 
  }
  updateRouteLine() {
    if (this.map) {
      this.addRouteToMap();
    } 
  }

  boundToRouteLine() {
    let coordinates = routePoints[0];
    if (coordinates.length > 0) {
      this.map.fitBounds(new window.L.LatLngBounds(coordinates[0], coordinates[coordinates.length - 1]));
    }
  }

  addRouteToMap() {

    if(!this.routeLayerGroup) {
      const layerGroup = new window.L.LayerGroup(null, {
        pane: "overlayPane",
      });
      layerGroup.addTo(this.map);
      this.routeLayerGroup = layerGroup;
    }
    if(this.routeLayers.length > 0 && this.routeLayerGroup) {
      // delete existing routes
      this.routeLayerGroup.clearLayers();
      this.routeLayers = [];
    }

    const routeLayer = window.L.polyline(routePoints[0].map((e) => e.reverse()), {
      color: "var(--color-primary-500)",
      weight: 7,
      opacity: 1.0,
    })

    this.routeLayers.push(routeLayer);
    routeLayer.addTo(this.routeLayerGroup);
    this.boundToRouteLine();
  }

  checkUserPermisison = (permission) => {
    let allow = false;
    const { user } = this.state;
    if (user) {
      if (user.role === "carrier") {
        allow = true;
      } else if (user.permissions) {
        permission.map((x) => {
          if (user.permissions.indexOf(x) > -1) {
            return (allow = true);
          }
        });
      }
      return allow;
    }
  };

  loadMap() {
    if (this.mapContainer) {
      if (!this.map) {
        // this.map = new mapboxgl.Map({
        //   container: this.mapContainer,
        //   style: "mapbox://styles/mapbox/streets-v9",
        //   center: [-75.25, 35.71],
        //   zoom: 1,
        // });

        this.map = new window.L.Map("CustomerServiceSidebarMap", {
          zoom: 1,
          minZoom: 1,
          center: [35.71, -75.25],
          layers: [streetTile()],
        });
      }
    }
  }

  customerDetails() {
    $(".cm-d").toggle();
  }

  enableload(_id) {
    confirm(
      "Enable Load",
      "Are you sure you want to enable this load?",
      (value) => {
        if (value) {
          removeLoad(_id, true).then(() => {
            toastr.show("Successfully Enable", "success");
            this.props.refreshDispatcher();
            this.props.closeCustomerServiceSideBar();
          });
        }
      }
    );
  }

  removeload(_id) {
    confirm(
      "Delete Load",
      "Are you sure you want to delete this load?",
      (value) => {
        if (value) {
          removeLoad(_id).then((el) => {
            this.props.closeCustomerServiceSideBar();
            toastr.show("Removed.", "success");
            this.props.refreshDispatcher();
          });
        }
      }
    );
  }

  _duplicateLoad(count, reference_number, carryOverPricing) {
    const payload = { reference_number };

    if (count > 50) {
      toastr.show("You have exceeded the number of times allowed!", "error");
      return;
    }
    if (!count) {
      toastr.show("You must enter a value between 0 and 50.", "error");
      return;
    }

    if (carryOverPricing) {
      payload.carryOverPricing = carryOverPricing;
    }

    duplicateLoad(payload).then(() => {
      count -= 1;
      if (count > 0) {
        this._duplicateLoad(count, reference_number, carryOverPricing);
      } else {
        toastr.show("Added!", "success");
        this.props.refreshDispatcher();
        this.props.closeCustomerServiceSideBar();
      }
    });
  }

  duplicateLoad(reference_number) {
    const options = {
      showInputText: true,
      showCheckBox: true,
    };

    confirm(
      "Duplicate This Load",
      "How many new loads do you want to create from this load?",
      async (confirm, count, carryOverPricing) => {
        if (confirm)
          this._duplicateLoad(count, reference_number, carryOverPricing);
      },
      options
    );
  }

  previewOtherDocument = (type) => {
    const formData = new FormData();
    formData.append(
      "reference_number",
      this.state.selectedLoad.reference_number
    );
    formData.append("type", type);

    sendBillingEmail(formData).then((result) => {
      const fileName = `${this.state.selectedLoad.reference_number}-${type}.pdf`;
      var byteArray = new Uint8Array(result.data.data.data);
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: "application/octet-stream" })
      );
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  _getLoadRoute() {
    if (this.map) {
      const { selectedLoad = {} } = this.props;
      const { reference_number } = selectedLoad;
      this.map && this.map.stop();
      if (reference_number) {
        this.setState({ isMapRouteLoading: true });
        getLoadRoute(reference_number)
          .then((routePath) => {
            //let routePoints = [];
            let pointsArr = routePath.map((d) => polyline.decode(d));
            //routePoints = pointsArr.slice().flat()
            routePoints = pointsArr
              .slice()
              .map((pArr) => pArr.map((p) => p.slice().reverse()));
            //geojson.data.geometry.coordinates = routePoints[2];
            this.updateRouteLine();
          })
          .catch(() => {
            this.setState({ isMapRouteLoading: false });
          });
      }
    }
  }

  checkUserPermisison = (permission) => {
    let allow = false;
    const { user } = this.state;
    if (user) {
      if (user.role === "carrier") {
        allow = true;
      } else if (user.permissions) {
        permission.map((x) => {
          if (user.permissions.indexOf(x) > -1) {
            return (allow = true);
          }
        });
      }
      return allow;
    }
  };

  getHeaderButtons = (isDeleteEnable, disabledSaveButton) => {
    return (
      <div className="ml-auto position-relative d-flex">
        {this.props.isFromCustomerService && this.props.customerServiceButtons}
        {this.checkUserPermisison(["print_all_docs"]) && this.props.printModal && (
          <button
            className="btn btn-link mr-1 tooltip-label"
            onClick={() => this.props.printModal()}
          >
            <IconPrinter />
            <CustomTooltip position="bottom" content="Print" />
          </button>
        )}
        {this.props.duplicateLoad && (
          <button
            className="btn btn-link mr-1 tooltip-label"
            disabled={isTrialUser() ? true : false}
            onClick={() => {
              this.props.duplicateLoad(
                this.state.selectedLoad.reference_number
              );
            }}
          >
            <IconClone />
            <CustomTooltip position="bottom" content="Duplicate Load" />
          </button>
        )}
        {isDeleteEnable && (
          <button
            className="btn btn-link mr-1 tooltip-label"
            onClick={() => {
              this.props.isFromCustomerService ?
              this.props.removeLoad() :
              this.removeload(this.state.selectedLoad._id);
            }}
          >
            <IconTrash />
            <CustomTooltip position="bottom" content="Remove Load" />
          </button>
        )}
        {!this.props.isFromCustomerService &&
          <button
            className="btn btn-outline-light mr-2 "
            onClick={() => this.closeCustomerServiceSideBar()}
          >
            Close
          </button>
        }
        <div
          className="position-relative mr-2"
          ref={(node) => (this.wrapperRef = node)}
        >
          <button
            className="btn btn-success p-0"
            disabled={disabledSaveButton || this.state.isSavingLoad}
          >
            <div
              style={{
                padding: "5px 10px",
                paddingRight:
                  this.state.activeTab === "billing" &&
                  this.state.billingActiveTab === "main-customer"
                    ? 0
                    : 10,
              }}
              onClick={() => !disabledSaveButton && this.handleSubmitLoad()}
            >
              {this.state.isSavingLoad && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </div>
            {this.state.activeTab === "billing" &&
              this.state.billingActiveTab === "main-customer" && (
                <span
                  className="pr-1"
                  onClick={() =>
                    !disabledSaveButton && 
                    this.setState({
                      isSaveOptions: !this.state.isSaveOptions,
                    })
                  }
                >
                  <IconCaretDown />
                </span>
              )}
          </button>
          {this.state.activeTab === "billing" &&
            this.state.billingActiveTab === "main-customer" &&
            this.state.isSaveOptions && (
              <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                <span
                  className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                  onClick={() => {
                    this.handleSubmitLoad(true, false);
                    this.setState({ isSaveOptions: false });
                  }}
                >
                  <CustomIconSaveOutline className="mr-3" />
                  {`Save & Add to Customer Rates`}
                </span>

                <React.Fragment>
                  <hr className="m-2" />
                  <span
                    className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                    onClick={() => {
                      this.handleSubmitLoad(false, true);
                      this.setState({ isSaveOptions: false });
                    }}
                  >
                    <IconSendEmail className="mr-3" />
                    {`Save & Send Rate Confirmation`}
                  </span>
                </React.Fragment>
              </div>
            )}
        </div>
        {this.props.handlePrevLoad && (
          <button
            className="btn btn-outline-light mr-2 tooltip-label"
            onClick={() =>  this.handleNextPrevLoad('prev')}
          >
            <IconAngleArrowLeft />
            <CustomTooltip position="bottom" content="Prev. Load" />
          </button>
        )}
        {this.props.handleNextLoad && (
          <button
            className="btn btn-outline-light tooltip-label"
            onClick={() => this.handleNextPrevLoad('next')}
          >
            <IconAngleArrowRight />
            <CustomTooltip position="bottom" content="Next Load" />
          </button>
        )}
      </div>
    )
  }
  handleScroll = (e) => {
    const scroller = e.target;
    const header = document?.getElementById('load-tabs');
    scroller.addEventListener("scroll", () => {
      if(scroller?.scrollTop > 10) header?.classList.add("shadow-3","z-1")
      else header?.classList.remove("shadow-3","z-1");
    });
  }
  getNewHeaderButtons =  (isDeleteEnable, disabledSaveButton) => {
    return (
      <div className="ml-auto position-relative d-flex navbar--icon">
        {this.props.isFromCustomerService && this.props.customerServiceButtons}
        {this.checkUserPermisison(["print_all_docs"]) && this.props.printModal && (
          <div>
          <button
            data-tip
            data-for="printicon"
            className="btn btn-link mr-1 bg-white day-card"
            onClick={() => this.props.printModal()}
          >
            <IconPrinter />
          </button>
           <ReactTooltip id="printicon" place="top"effect="solid" className="react-tooltip-new">
           { "Print"}
         </ReactTooltip>
         </div>
        )}
        {this.props.duplicateLoad && (
          <div>
          <button
            data-tip
            data-for="cloneicon"
            className="btn btn-link mr-1 bg-white day-card"
            disabled={isTrialUser() ? true : false}
            onClick={() => {
              this.props.duplicateLoad(
                this.state.selectedLoad.reference_number
              );
              document.querySelector(".load-info-modal")?.removeAttribute("tabindex")
            }}
          >
            <IconClone />
          </button>
          <ReactTooltip id="cloneicon" place="top" effect="solid" className="react-tooltip-new">
           { "Duplicate Load"}
         </ReactTooltip>
          </div>
        )}

        {isDeleteEnable && (
          <div>
          <button
            data-tip
            data-for="removeicon"
            className="btn btn-link bg-white day-card"
            onClick={() => {
              this.props.isFromCustomerService ?
              this.props.removeLoad() :
              this.removeload(this.state.selectedLoad._id);
            }}
          >
            <IconTrash />
          </button>
          <ReactTooltip id="removeicon" place="top" effect="solid" className="react-tooltip-new">
          { "Remove Load"}
        </ReactTooltip>
        </div>
        )}
        {this.props.handlePrevLoad && (
          <div>
          <button
           data-tip
           data-for="anglearrowlefticon"
            className="btn btn-outline-light ml-20 mr_5 bg-white day-card prev__arrow"
            onClick={() => this.props.handlePrevLoad()}
          >
            <IconAngleArrowLeft />
          </button>
          <ReactTooltip id="anglearrowlefticon" place="top"  effect="solid" className="react-tooltip-new">
          { "Prev. Load"}
        </ReactTooltip>
        </div>
        )}
        {this.props.handleNextLoad && (
          <div>
          <button
            data-tip
            data-for="anglearrowrighticon"
            className="btn btn-outline-light mr-20 bg-white day-card"
            onClick={() => this.props.handleNextLoad()}
          >
            <IconAngleArrowRight />
          </button>
          <ReactTooltip id="anglearrowrighticon" place="top" effect="solid" className="react-tooltip-new">
          { "Next Load"}
        </ReactTooltip>
          </div>
        )}
        {this.state.activeTab === "billing" && !this.isBillingInline && (
          <button
            className="btn btn-success p-0 mr-2"
            disabled={disabledSaveButton || this.state.isSavingLoad}
          >
            <div
              style={{
                padding: "5px 10px",
                paddingRight:
                  this.state.activeTab === "billing" &&
                  this.state.billingActiveTab === "main-customer"
                    ? 0
                    : 10,
              }}
              onClick={() => !disabledSaveButton && this.handleSubmitLoad()}
            >
              {this.state.isSavingLoad && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </div>
            {this.state.activeTab === "billing" &&
              this.state.billingActiveTab === "main-customer" && (
                <span
                  className="pr-1"
                  onClick={() =>
                    !disabledSaveButton && 
                    this.setState({
                      isSaveOptions: !this.state.isSaveOptions,
                    })
                  }
                >
                  <IconCaretDown />
                </span>
              )}
          </button>)}
        {!this.props.isFromCustomerService &&
          <button
            className="btn btn-outline-light mr-2 bg-white day-card pr-15 pl-15"
            onClick={() => this.setState({
              isCloseModal: true
            }, () => {
              this.handleClosePrompt()
            })}
          >
           <IconCloseBold className="mr-15"/> <span className="font-12 font-medium">Close</span>
          </button>
          
        }
        <div
          className="position-relative mr-2"
          ref={(node) => (this.wrapperRef = node)}
        >
          {this.state.activeTab === "billing" &&
            this.state.billingActiveTab === "main-customer" &&
            this.state.isSaveOptions && (
              <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                <span
                  className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                  onClick={() => {
                    this.handleSubmitLoad(true, false);
                    this.setState({ isSaveOptions: false });
                  }}
                >
                  <CustomIconSaveOutline className="mr-3" />
                  {`Save & Add to Customer Rates`}
                </span>

                <React.Fragment>
                  <hr className="m-2" />
                  <span
                    className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                    onClick={() => {
                      this.handleSubmitLoad(false, true);
                      this.setState({ isSaveOptions: false });
                    }}
                  >
                    <IconSendEmail className="mr-3" />
                    {`Save & Send Rate Confirmation`}
                  </span>
                </React.Fragment>
              </div>
            )}
        </div>
      </div>
    )
  }

  assignCSR = (csr) => {
    this.setState({isloading : true})
    let csrList = this.state.assignedCSR && this.state.assignedCSR.length > 0 ?  [...this.state.assignedCSR] : []
     const existingCSR = csrList.length > 0 ? csrList.find(item=> item._id === csr.value) : undefined
    if(!existingCSR){
      csrList.push({value : csr.value})
      this.assignCSRtoLoad(csrList, `${csr.label} assigned successfully`)
    }else{
      toastr.show(`${csr.label} is already added`, "error")
      this.setState({isloading : false})
    }
    this.setState({showAllCSRList : false})
  }
  listAllCSR = (search) => {
    
    let data = {
      CSR: true,
      active : true
    };
    if(search){
        data.searchTerm = search 
    }
    return new Promise((resolve, reject) => {
      this.props.actions.listAllFleetManagers(data).then((options) => {
        const CSR = [];
        let allDatas = options || [];

        allDatas.forEach((obj) => {
          CSR.push({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj,
          });
        });

        this.setState({ AllCSRList: CSR });
        resolve(options);
      });
    });
  }
  assignCSRtoLoad = (csrList, msg) => {
    
    const formData = new FormData()
    formData.append('assignedCSR', JSON.stringify(csrList.map(item=> item._id || item.value)))
    formData.append('loadId',  this.props.selectedLoad && this.props.selectedLoad._id )
    actions.assignCSRtoLoad(formData).then(result => {
      if(result && result.assignedCSR){
        toastr.show(msg,'success')
        this.setState({assignedCSR : result.assignedCSR })
      }
      this.setState({isloading : false})
    })
    .catch((err)=> this.setState({isloading : false}))
    // assignCSRtoCustomer(formData)
   
  }

  

  removeCSRfromCustomer = (csr) =>{
    this.setState({isloading : true})
    let csrList = this.state.assignedCSR.filter(item=> item._id != csr._id)
    this.setState({assignedCSR : csrList})
    this.assignCSRtoLoad(csrList,`${csr.name} ${csr.lastName} Removed Successfully`)
  }
  showInvoicePage = (invoiceNumber)=>{
    if(invoiceNumber){
      this.setState({invoiceNumber:invoiceNumber})
    }
    this.setState({showInvoice:!this.state.showInvoice})

  }
 setDistanceUnit (){
   const selectedLoad = this.props.selectedLoad;
  const measureUnits = selectedLoad && selectedLoad.carrier && selectedLoad.carrier.carrier && selectedLoad.carrier.carrier.distanceMeasure;
  let measureUnit = measureUnits && measureUnits == 'km'? "Kilometers" : "Miles" ;
  let totalDistance ;
  if (selectedLoad && selectedLoad.distance) {
    if (selectedLoad.distance > 6000) totalDistance = "6000+";
    else totalDistance = selectedLoad.distance.toFixed(2);
    // totalDistance = convertUnit("convertedValue", "distance", totalDistance);
  }
  this.setState({measureUnit, totalDistance})
 }
  render() {
    const { showSidebar, selectedLoad, changeList, Terminals } = this.props;
     const isDeleteEnable =
       ((this.props.removeload !== null && this.state.user.role === "carrier") ||
       (this.state.user.role === "fleetmanager" &&
         this.checkUserPermisison(["deleteloads"])))&& !selectedLoad.isDeleted

  
    let totalWeight = 0;
    let weightUnit = "LBS";
    if (selectedLoad && selectedLoad.items) {
      selectedLoad.items.forEach((item) => {
        if (selectedLoad.carrier && selectedLoad.carrier.carrier && selectedLoad.carrier.carrier.weightMeasure == "kg") {
          totalWeight += Number(item.weightKgs);
          weightUnit = "KG"
        } else {
          totalWeight += Number(item.weight);
        }
      });
    }

    let userDetails = JSON.parse(getStorage("loggedInUser"));
    let disabledSaveButton = false;
    if (!["load", "billing", "orderstatus"].includes(this.state.activeTab)) {
      disabledSaveButton = true;
    }
	if(selectedLoad.isDeleted){
		disabledSaveButton = true;
	}
    if (["load", "billing", "orderstatus"].includes(this.state.activeTab)) {
      if (selectedLoad && 
        !disabledSaveButton &&
        [
          "APPROVED",
          "BILLING",
          "REBILLING",
          "PARTIAL_PAID",
          "FULL_PAID",
        ].includes(selectedLoad.status)
      ) {
        disabledSaveButton = true;
      }
    }
    if(this.state.activeTab === "billing" || this.state.billingActiveTab === "sub-customer"){

      if(
        JSON.parse(getStorage("loggedInUser")).role === "fleetmanager" &&
       JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer 
      ){
       disabledSaveButton = true;  
      }else if(
       ["customer", "fleetcustomer"].includes(JSON.parse(getStorage("loggedInUser")).role)
      ){
       disabledSaveButton = true;
      }
      else{
       disabledSaveButton = false;
      }
   }

    if (selectedLoad && this.state.activeTab === "load") {
      if (
        !disabledSaveButton &&
        getStorage("currentUserRole") == "fleetmanager" &&
        userDetails.fleetManager.carrier !== selectedLoad.carrier._id
      ) {
        disabledSaveButton = true;
      } else if (
        !disabledSaveButton &&
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") !== selectedLoad.carrier._id
      ) {
        disabledSaveButton = true;
      } else if (
        !disabledSaveButton &&
        userDetails.role == "customer" &&
        (!userDetails.customer || !userDetails.customer.canEditLoad)
      ) {
        disabledSaveButton = true;
      }
    }
   const allCSRList = this.state.AllCSRList && this.state.AllCSRList.length > 0 && this.state.AllCSRList.filter(item => {
      return  !this.state.assignedCSR || !this.state.assignedCSR.find(csr=> csr._id == item.value)
   })
   let showAddRemoveOptions = (getStorage("currentUserRole") == "carrier" || (getStorage("currentUserRole") == "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager && JSON.parse(getStorage("loggedInUser")).fleetManager.admin  )) 
   
   return (
    <React.Fragment>
    {!isNewModal() ? <>
      {showSidebar && this.state.selectedLoad && this.props.isFromCustomerService && (
      <SubHeaderTab
        val="customerServiceItems"
        activeTab="Load"
        buttons={this.getHeaderButtons(isDeleteEnable, disabledSaveButton)}
      />
    )}
    {showSidebar && this.state.selectedLoad && !this.props.isFromCustomerService && (
      <div className="subheader">
        <div className="subheader__inner d-flex">
          <div className="d-flex flex-row align-items-center">
            <button
              data-tip
              data-for="back"
              className="btn btn-outline-light"
              onClick={() => this.props.closeCustomerServiceSideBar()}
            >
              <IconAngleArrowLeft />
            </button>
            <ReactTooltip id="back" place="right" className="type-gray">
              {this.props.backArrowTooltip ? this.props.backArrowTooltip : "Back To Dispatcher Page"}
            </ReactTooltip>
            <div className="d-flex ml-3 align-items-center">
              <h5 className="mb-0 mr-2">
                Load #: {this.state.selectedLoad.reference_number}
              </h5>
              <StatusComponent load={this.state.selectedLoad}/>
            </div>
          </div>
          {this.getHeaderButtons(isDeleteEnable, disabledSaveButton)}
        </div>
      </div>
    )}
    {showSidebar && this.state.selectedLoad && (
      <div className="page-fluid">
        <div className="top-page">
          <div className="form-row-md fcpopup">
            <div className="col-lg fcpopup__left" style={{ maxWidth: 270 }}>
              {this.props.isFromCustomerService &&
                <div className="card p-10 mb_5">
                  <div className={`d-flex justify-content-between ${
                      selectedLoad.isDeleted ? "flex-column" : "flex-row"
                    }`}
                  >
                    <h5 className={`mb-1 font-20 ${
                        selectedLoad.isDeleted ? "text-danger" : ""
                      }`}
                    >
                      #
                      {selectedLoad && selectedLoad.reference_number
                        ? selectedLoad.reference_number
                        : ""}
                    </h5>
                    {selectedLoad.isDeleted && (
                      <div className="d-flex align-items-center">
                        <span className="badge badge-danger mr-2">
                          Deleted
                        </span>
                        <button
                          data-tip
                          data-for="reEnable"
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            this.enableload(selectedLoad._id);
                          }}
                        >
                          Enable this Load
                        </button>
                        <ReactTooltip id="reEnable">
                          <span>Press Here to Enable it</span>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                  <div className="justify-content-center align-items-center">
                    <StatusComponent load={selectedLoad}/>   
                  </div>
                </div>
              }
              <div className="card p-15 mb-1">
                <h5>Customer</h5>
                <div>
                  {selectedLoad.caller
                    ? selectedLoad.caller.company_name
                    : ""}
                </div>
                <div
                  className={`${
                    (selectedLoad.caller.mobile ||
                      selectedLoad.caller.billingEmail ||
                      selectedLoad.caller.main_contact_name) &&
                    "mb-10"
                  }`}
                >
                  {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.address
                    ? selectedLoad.caller.address.address
                    : ""}
                </div>
                {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.main_contact_name && (
                    <div
                      className={`${
                        (selectedLoad.caller.mobile ||
                          selectedLoad.caller.billingEmail) &&
                        "mb-10"
                      } d-flex align-items-center`}
                    >
                      <div className="flex-shrink-0 mr-10 align-self-center">
                        <IconUserSingle />
                      </div>
                      <div className="flex-grow-1">
                        {selectedLoad.caller.main_contact_name}
                      </div>
                    </div>
                  )}
                {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.mobile && (
                    <div
                      className={`${
                        selectedLoad.caller.billingEmail && "mb-10"
                      } d-flex align-items-center`}
                    >
                      <div className="flex-shrink-0 mr-10 align-self-center">
                        <IconPhone />
                      </div>
                      <div className="flex-grow-1">
                        {selectedLoad.caller.mobile}
                      </div>
                    </div>
                  )}
                {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.billingEmail && (
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 mr-10 align-self-start">
                        <IconAt />
                      </div>
                      <div className="flex-grow-1 text-break">
                        {selectedLoad.caller.billingEmail}
                      </div>
                    </div>
                  )}
              </div>

              <div className="card p-15 mb-1">
                <h5>
                  {selectedLoad.type_of_load === "ROAD"
                    ? "Shipper"
                    : "Port"}
                </h5>

                {selectedLoad.shipper.map((shipper, sI) => {
                  return (
                    <React.Fragment>
                      <div className="font-medium mb-1">
                        {shipper.company_name}
                      </div>
                      <div
                        className={`${
                          (shipper.main_contact_name ||
                            shipper.mobile ||
                            shipper.email) &&
                          "mb-10"
                        }`}
                      >
                        {shipper.address && shipper.address.address}
                        <span data-tip data-for="port"><IconMapMarker className="wh-20px" /></span>
                      </div>
                      <ReactTooltip id="port" place="right" className="type-gray">
                        {shipper?.main_contact_name !== "" && (
                          <div
                            className={`${
                              (shipper.mobile || shipper.email) && "mb-10"
                            } d-flex align-items-center`}
                          >
                            <div className="flex-shrink-0 mr-10 align-self-center">
                              <IconUserSingle />
                            </div>
                            <div className="flex-grow-1">
                              {shipper.main_contact_name}
                            </div>
                          </div>
                        )}

                        {shipper?.mobile !== "" && (
                          <div
                            className={`${
                              shipper.email && "mb-10"
                            } d-flex align-items-center`}
                          >
                            <div className="flex-shrink-0 mr-10 align-self-center">
                              <IconPhone />
                            </div>
                            <div className="flex-grow-1">
                              {shipper.mobile}
                            </div>
                          </div>
                        )}

                        {shipper?.email !== "" && (
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 mr-10 align-self-center">
                              <IconAt />
                            </div>
                            <div className="flex-grow-1 text-break">{shipper.email}</div>
                          </div>
                        )}
                      </ReactTooltip>
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="card p-15 mb-1">
                <h5>Consignee</h5>
                {selectedLoad.consignee.map((consignee) => {
                  return (
                    <React.Fragment>
                      <div className="">
                        <div className="font-medium">
                          {consignee.company_name}
                        </div>
                        <div
                          className={`${
                            (consignee.main_contact_name ||
                              consignee.mobile ||
                              consignee.email) &&
                            "mb-10"
                          }`}
                        >
                          {consignee.address && consignee.address.address}
                          <span data-tip data-for="consignee"><IconMapMarker className="wh-20px" /></span>
                        </div>
                        <ReactTooltip id="consignee" place="right" className="type-gray">
                          {consignee.main_contact_name && (
                            <div
                              className={`${
                                (consignee.mobile || consignee.email) &&
                                "mb-10"
                              } d-flex align-items-center`}
                            >
                              <div className="flex-shrink-0 mr-10 align-self-center">
                                <IconUserSingle />
                              </div>
                              <div className="flex-grow-1">
                                {consignee.main_contact_name}
                              </div>
                            </div>
                          )}

                          {consignee.mobile && (
                            <div
                              className={`${
                                consignee.email && "mb-10"
                              } d-flex align-items-center`}
                            >
                              <div className="flex-shrink-0 mr-10 align-self-center">
                                <IconPhone />
                              </div>
                              <div className="flex-grow-1">
                                {consignee.mobile}
                              </div>
                            </div>
                          )}

                          {consignee.email && (
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 mr-10 align-self-center">
                                <IconAt />
                              </div>
                              <div className="flex-grow-1 text-break">
                                {consignee.email}
                              </div>
                            </div>
                          )}
                        </ReactTooltip>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>

              {selectedLoad && selectedLoad.type_of_load !== "ROAD" && (
                <div className="card p-15 mb-1">
                  <h5>Return</h5>
                  <div className="font-medium">
                    {" "}
                    {selectedLoad.emptyOrigin
                      ? selectedLoad.emptyOrigin.company_name
                      : ""}
                  </div>
                  <div>
                    {selectedLoad.emptyOrigin &&
                    selectedLoad.emptyOrigin.address
                      ? selectedLoad.emptyOrigin.address.address
                      : "N/A"}
                  </div>
                </div>
              )}

              <div className="card p-15 mb-1">
                <h5>Summary</h5>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Customer Reps </span>&nbsp;
                  <div className="group d-flex align-items-center mt-1" >
                    {this.state.assignedCSR && this.state.assignedCSR.length> 0 && 
                     this.state.assignedCSR.slice(0, 3).map((csr, index)=>{
                      return(
                        <React.Fragment>
                          <div className="group__avatar position-relative">
                            <div className={`avatar-circle avatar-sm${!csr.profilePicture && "border-1 text-primary" }  `} data-tip data-for={csr._id} onMouseEnter={()=> this.setState({showRemoveIcon : index})} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                            {csr.profilePicture ?
                                   <img className="avatar rounded-circle " src={csr.profilePicture} />
                                 : <div className="avatar-circle__initial  " >{csr?.name?.[0] && csr?.lastName?.[0] ? `${csr.name[0]}${csr.lastName[0]}` : ""}</div>}
                                {
                                this.state.showRemoveIcon == index &&  showAddRemoveOptions &&
                                <div className="notification-icon-header z-1 pointer"
                                onMouseEnter={()=> ReactTooltip.hide()}
                                 onClick={()=>this.removeCSRfromCustomer(csr)}  style={{top: "-7px", color : "#444242", backgroundColor : "#dbe2eb", border : "1px solid #b3b3b3" }}><IconTimesThinClose/></div>}  
                            </div>
                            </div>
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id={csr._id}
                          >
                            <span key ={index}>{csr?.name && csr?.lastName ? `${csr.name} ${csr.lastName}` : ""} 

                            </span>
                          </ReactTooltip>                           

                          </React.Fragment>
                      )
                    })}
                    {this.state.assignedCSR && this.state.assignedCSR?.length > 3 && (
                      <div className="group__avatar z-3">
                        <div className="avatar avatar-circle avatar-sm bg-light border-1 text-primary" onMouseEnter={()=> this.setState({showRemoveIcon : 'more'})} >
                        <span className="text-dark">  +{this.state.assignedCSR.length - 3}</span>  
                        </div>

                          {this.state.showRemoveIcon == 'more' &&  <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated z-3 pointer" style={{right : "0" }} ref={this.wrapperRef}>
                          <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                            {this.state.assignedCSR && this.state.assignedCSR.length > 3 && 
                                this.state.assignedCSR.slice(3).map((csr, index)=>{
                              return(
                                <div  key={index} className="d-flex align-items-center mb-10 pointer" >
                                  <div className="flex-shrink-0 mr-10 align-self-center">
                                    {
                                      csr && csr.profilePicture ? 
                                      <img className="avatar rounded-circle " src={csr.profilePicture}  /> : 
                                      <div className="avatar-circle undefined">
                                      {`${csr.name[0]}${csr.lastName[0]}`}
                                    </div>
                                    }                                          
                                  </div>
                                  <div className="flex-grow-1 text-truncate">
                                    <h5 className="font-12 font-weight-500 text-dark m-0">{`${csr.name} ${csr.lastName}`}</h5>
                                  </div>
                                  {showAddRemoveOptions && 
                                  <div   onClick={()=>this.removeCSRfromCustomer(csr)}>
                                  <IconTimesThin />
                                  </div>
                                }
                                </div>
                              ) 
                            })}
                          </div>
                        </div>}


                      </div>
                    )}
                    { showAddRemoveOptions
                     && <div className="ml-10 group__avatar">
                          
                     {this.state.isloading ? 
                       <span
                           className="spinner-border spinner-border-sm mr-2"
                           role="status"
                           aria-hidden="true"
                         >
                         </span>
                     : <div className="avatar-circle avatar-sm bg-primary pointer" onMouseEnter={()=> this.setState({showRemoveIcon : undefined})} onClick={()=>{this.setState({showAllCSRList : !this.state.showAllCSRList,csrSearchTerm : ""}); this.listAllCSR()}}> 
                       <div className="avatar-circle__initial text-white font-10" >
                       <IconPlusSmall /> 
                       </div>   
                       </div>
                       }  
                       {this.state.showAllCSRList && 
                         <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated popdrop--hcenter z-1" ref={this.wrapperRef}>
                           <div className="app-search position-relative mb-1">
                             <span className="search-icon ml-1"></span>
                             <div className="position-relative mb-10">
                               <input 
                                 type="input" 
                                 class="form-control input-search-left bg-gray-50" 
                                 placeholder="Search CSR.." 
                                 value={this.state.csrSearchTerm} 
                                 onChange={ e => {
                                   this.setState({csrSearchTerm : e.target.value});
                                     debounce(this.listAllCSR(e.target.value),500)
                                     }} 
                               />
                             </div>
                           </div>
                           <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }}>
                             {allCSRList && allCSRList.length > 0 ? allCSRList.map((csr, index)=>{
                               return(
                                 <div  key={index} className="d-flex align-items-center mb-10 pointer" onClick={()=>this.assignCSR(csr)}>
                                   <div className="flex-shrink-0 mr-10 align-self-center">
                                     {
                                       csr && csr.allInfos && csr.allInfos.allInfos && csr.allInfos.allInfos.profilePicture ? 
                                       <img className="avatar rounded-circle " src={csr.allInfos.allInfos.profilePicture}  /> : 
                                       <div className="avatar-circle undefined">
                                       {`${csr.label[0]}${csr.label.split(" ")[1][0]}`}
                                     </div>
                                     }
                                     
                                   </div>
                                   <div className="flex-grow-1 text-truncate">
                                     <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                                   </div>
                                 </div>
                               ) 
                             }) :  <div> No CSR's Available</div>}
                           </div>
                         </div>
                       }
                     </div>
                    }
                   
                    
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created By </span>&nbsp;
                  <span>{`${selectedLoad.addedBy.name}`.trim()}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created at </span>&nbsp;
                  <span>
                    {moment(selectedLoad.createdAt).format(
                      `${this.userTimeFormat ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY hh:mm a"}`
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Container #</span>
                  <span>{selectedLoad.containerNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  {selectedLoad.type_of_load === "IMPORT" && (
                    <React.Fragment>
                    <span className="text-muted">BOL #</span>
                    <span>{selectedLoad.callerbillLandingNo}</span>
                    </React.Fragment>
                  )} 
                  { selectedLoad.type_of_load === "EXPORT" &&  (
                    <React.Fragment>
                    <span className="text-muted">BKG #</span>
                    <span>{selectedLoad.bookingNo}</span>
                    </React.Fragment>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis #</span>
                  <span>{selectedLoad.chassisNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">SSL</span>
                  <span>
                    {selectedLoad && selectedLoad.containerOwner
                      ? selectedLoad.containerOwner.company_name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Size</span>
                  <span>
                    {" "}
                    {selectedLoad && selectedLoad.containerSize
                      ? selectedLoad.containerSize.name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Weight</span>
                  <span>
                    {totalWeight.toFixed(2)} {weightUnit}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Type</span>
                  <span>
                    {selectedLoad && selectedLoad.containerType
                      ? selectedLoad.containerType.name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Commodity </span>
                  <span>
                    {selectedLoad &&
                      selectedLoad.items?.length > 0 &&
                      selectedLoad.items[0].commodity}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-muted">Reference</span>
                  <span>
                    {selectedLoad && selectedLoad.secondaryReferenceNo
                      ? selectedLoad.secondaryReferenceNo
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-muted">Total {this.state.measureUnit}</span>
                  <div className="ml-auto">
                    <span>{this.state.totalDistance || ""}</span>
                  </div>

                  <div className="ml-auto">
                    <button
                      className="btn btn-link"
                      onClick={() => {
                        calculateLoadDistance(
                          this.props.selectedLoad.reference_number
                        );
                        this.setDistanceUnit()
                      }
                        
                      }
                    >
                      <span>
                        <IconReset className="text-primary" />
                      </span>
                    </button>
                  </div>
                </div>
                {selectedLoad && selectedLoad.customerEmployee && selectedLoad.customerEmployee.length > 0 && selectedLoad.customerEmployee.map((eachEmployee) =>{
                  return (
                    <React.Fragment key={eachEmployee._id}>
                       <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Emp Email</span>
                        <div className="ml-auto">
                            {eachEmployee.billingEmail}
                        </div>

                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Emp Name</span>
                        <div className="ml-auto">
                            {eachEmployee.firstName &&  eachEmployee.firstName} {eachEmployee.lastName &&  eachEmployee.lastName}
                        </div>

                      </div>
                      </React.Fragment>
                    )
                  })}
                <div className="align-items-center mt-2 ">
                  <span className="text-muted">Preview </span>
                  <div className="ml-auto">
                    <div
                      id="CustomerServiceSidebarMap"
                      className="mapboxgl-map-300"
                      style={{ height: 300 }}
                      ref={(el) => (this.mapContainer = el)}
                    ></div>
                    <div className="overlay"></div> 
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg fcpopup__right">
              <div className="card mb-0">
                <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.props.changeCustomerServiceSidebarTab("load");
                      this.setState({ activeTab: `load` });
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "load" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#home2"
                      role="tab"
                    >
                      <IconInfoFocus />
                      <div className="nav-link__text">Load Info</div>
                    </a>
                  </li>

                  {this.state.showBilling && (
                    <li
                      className="nav-item"
                      onClick={() => {
                        this.props.changeCustomerServiceSidebarTab(
                          "billing"
                        );
                        this.setState({ activeTab: `billing` });
                        // this.handleTabChange("main-customer");
                      }}
                    >
                      <a
                        className={`nav-link ${
                          this.state.activeTab === "billing" ? "active" : ""
                        }`}
                        data-toggle={!this.state.isBlockingTabChange && "tab"}
                        href="#billing"
                        role="tab"
                      >
                        <IconBilling />
                        <div className="nav-link__text">Billing</div>
                      </a>
                    </li>
                  )}

                  <li
                    className="nav-item"
                    onClick={() => {
                      this.props.changeCustomerServiceSidebarTab(
                        "documents"
                      );
                      this.setState({ activeTab: `documents` });
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "documents" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#documents"
                      role="tab"
                    >
                      <IconDocument />
                      <div className="nav-link__text">Documents</div>
                    </a>
                  </li>

                  <li
                    className="nav-item"
                    onClick={() => {
                      this.props.changeCustomerServiceSidebarTab(
                        "payments"
                      );
                      this.setState({ activeTab: `payments` });
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "payments" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#payments"
                      role="tab"
                    >
                      <IconPayment />
                      <div className="nav-link__text">Payment</div>
                    </a>
                  </li>

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "orderstatus"
                          );
                          this.setState({ activeTab: `orderstatus` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "orderstatus"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#OrderStatus"
                          role="tab"
                        >
                          <IconStatus />
                          <div className="nav-link__text">Routing</div>
                        </a>
                      </li>
                    )}

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "driverpay"
                          );
                          this.setState({ activeTab: `driverpay` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "driverpay"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#DriverPay"
                          role="tab"
                        >
                          <IconTruck />
                          <div className="nav-link__text">Driver Pay</div>
                        </a>
                      </li>
                    )}
                  {/* { ((this.props.type === "customer" && getStorage("currentUserRole") == "customer") ||
                    (!this.props.type && getStorage("currentUserRole") !== "fleetcustomer" ))&& ( */}
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "tracking"
                          );
                          this.setState({ activeTab: `tracking` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "tracking"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#tracking"
                          role="tab"
                        >
                          <IconTrack />
                          <div className="nav-link__text">Tracking</div>
                        </a>
                      </li>
                    {/* )} */}
                   

                  <li
                    className="nav-item"
                    onClick={() => {
                      this.changeTopTab(`messaging`)
                      this.props.changeCustomerServiceSidebarTab(
                        "messaging"
                      );
                      this.setState({ activeTab: `messaging` });
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "messaging" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#Messaging"
                      role="tab"
                    >
                      <IconMessage />
                      <div className="nav-link__text">Messaging</div>
                    </a>
                  </li>

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "audit"
                          );
                          this.setState({ activeTab: `audit` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "audit" ? "active" : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#audit"
                          role="tab"
                        >
                          <IconSearchData />
                          <div className="nav-link__text">Audit</div>
                        </a>
                      </li>
                    )}

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "notes"
                          );
                          this.setState({ activeTab: `notes` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "notes" ? "active" : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#audit"
                          role="tab"
                        >
                          <span className="position-relative">
                            <IconNotes />
                            {this.state.noteCount > 0 ? (
                              <span
                                className="avatar-xs bg-danger rounded-circle text-white d-flex align-items-center justify-content-center"
                                style={{
                                  position: "absolute",
                                  top: -8,
                                  left: -8,
                                }}
                              >
                                {this.state.noteCount}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>

                          <div className="nav-link__text">Notes</div>
                        </a>
                      </li>
                    )}
                </ul>
              </div>

              <div className="tab-loads">
                {this.state.activeTab === "load" && (
                  <Load
                    showButton={true}
                    Terminals={Terminals}
                    selectedLoads={this.props.selectedLoad}
                    loadType={this.props.selectedLoad.type_of_load}
                    location={{
                      query: this.props.selectedLoad.reference_number,
                    }}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    showExtraData={this.state.showExtraData}
                    handleSubmitLoad={(submit) => {
                      this.handleSubmitLoad = submit;
                    }}
                    isSavingLoad={this.state.isSavingLoad}
                    changeSavingLoadStatus={(status) =>
                      this.setState({ isSavingLoad: status })
                    }
                  />
                )}
                {this.state.activeTab === "billing" && (
                  <BillOfLading
                    loads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    pricing={this.props.selectedLoad.pricing}
                    chargeConflicts={this.props.selectedLoad.chargeConflicts}
                    updateList={this.props.updateData}
                    changeList={changeList}
                    setLoadEmails={this.props.setLoadEmails}
                    onEditorStateChange={this.props.onEditorStateChange}
                    editorState={this.props.editorState}
                    inpSubject={this.props.inpSubject}
                    handleSubmitLoad={(submit) => {
                      this.handleSubmitLoad = submit;
                    }}
                    changeSavingLoadStatus={(status) =>
                      this.setState({ isSavingLoad: status })
                    }
                    setBillingActiveTab={this.handleTabChange}
                    billingActiveTab={this.state.billingActiveTab}
                  />
                )}
                {this.state.activeTab === "documents" && (
                  <Documents
                    selectedLoads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    type={this.props.type}
                  />
                )}
                {this.state.activeTab === "payments" && (
                  <Payment
                    loads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    type={this.props.type}
                  />
                )}
                {this.state.activeTab === "orderstatus" && (
                  <LoadDriverOrder
                    selectedLoads={this.props.selectedLoad}
                    reference_number={
                      this.props.selectedLoad.reference_number
                    }
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    changeList={changeList}
                    changeTopTab={this.changeTopTab}
                    handleSubmitLoad={(submit) => {
                      this.handleSubmitLoad = submit;
                    }}
                    changeSavingLoadStatus={(status) =>
                      this.setState({ isSavingLoad: status })
                    }
                  />
                )}
                {this.state.activeTab === "driverpay" && (
                  <NewTranscations
                    isTab={true}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                    selectedLoads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    reference_number={
                      this.props.selectedLoad.reference_number
                    }
                  />
                )}
                {this.state.activeTab === "tracking" && (
                  <LoadHistory
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                  />
                )}
                {this.state.activeTab === "messaging" && (
                  <OneLoadMessaging
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                  />
                )}
                {this.state.activeTab === "audit" && (
                  <AuditLoad
                    Terminals={Terminals}
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                  />
                )}
                {this.state.activeTab === "notes" && (
                  <Notes
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    showNotes={this.props.showNotes}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </> : <React.Fragment>
    <Modal show={true} className="load-info-modal pr-20 pl-20 vh-100 mt-24" animation={false}>
    <Modal.Header>
      {showSidebar && this.state.selectedLoad && !this.props.isFromCustomerService && (
      <>
      {this.props?.isNewDeleted ? 
      <div className="subheader" style={{height: "70px"}}>
        <div className="subheader__inner d-flex align-items-center">
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex align-items-center">
              <div>
              <h5 className="mb-0 mr-2 font-20">
                Load #: {this.state.selectedLoad.reference_number}
              </h5>
                 <span className="badge badge-danger mr-2">
                  Deleted
                </span>
              <StatusComponent load={this.state.selectedLoad} deleted={true}/>
              </div>
              <button
                  data-tip
                  data-for="reEnable"
                  type="button"
                  className="btn btn-success border-0 text-white p-2 ml-2"
                  onClick={() => {
                    this.enableload(selectedLoad._id);
                  }}
                >
                  Enable this Load
                </button>
            </div>
          </div>
          {this.getNewHeaderButtons(isDeleteEnable, disabledSaveButton)}
        </div>
      </div> : <div className="subheader d-flex align-items-center">
        <div className="subheader__inner d-flex align-items-center justify-content-between w-100 border-0">
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex align-items-center">
              <h5 className="mb-0 mr-10 font-20">
                Load #: {this.state.selectedLoad.reference_number}
              </h5>
              <StatusComponent load={this.state.selectedLoad} />
            </div>
          </div>
          {this.getNewHeaderButtons(isDeleteEnable, disabledSaveButton)}
        </div>
      </div>}
      </>
    )}
    </Modal.Header>

    <Modal.Body className='load-modal-full pt-0'>
      {showSidebar && this.state.selectedLoad && (
          <div className="form-row-md fcpopup">
            <div className="col-lg fcpopup__left pr-0 mr_12">
              {this.props.isFromCustomerService &&
                <div className="card p-15 mb-1">
                  <div className={`d-flex justify-content-between ${
                      selectedLoad.isDeleted ? "flex-column" : "flex-row"
                    }`}
                  >
                    <h5 className={`mb-1 font-20 ${
                        selectedLoad.isDeleted ? "text-danger" : ""
                      }`}
                    >
                      #
                      {selectedLoad && selectedLoad.reference_number
                        ? selectedLoad.reference_number
                        : ""}
                    </h5>
                    {selectedLoad.isDeleted && (
                      <div className="d-flex align-items-center">
                        <span className="badge badge-danger mr-2">
                          Deleted
                        </span>
                        <button
                          data-tip
                          data-for="reEnable"
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            this.enableload(selectedLoad._id);
                          }}
                        >
                          Enable this Load
                        </button>
                        <ReactTooltip id="reEnable">
                          <span>Press Here to Enable it</span>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                  <div className="justify-content-center align-items-center">
                    <StatusComponent load={selectedLoad}/>   
                  </div>
                </div>
              }
              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">Customer</h5>
                <div className="mt-10">
                <div className="font-medium mb-2 font-14 text-capitalize">
                  {selectedLoad.caller
                    ? selectedLoad.caller.company_name
                    : ""}
                </div>
                <div
                  className={`${
                    (selectedLoad.caller.mobile ||
                      selectedLoad.caller.billingEmail ||
                      selectedLoad.caller.main_contact_name) &&
                    "text-gray-900 font-weight-normal font-12"
                  }`}
                >
                  {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.address
                    ? selectedLoad.caller.address.address
                    : ""}
                </div>
                </div>
                {/* {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.main_contact_name && (
                    <div
                      className={`${
                        (selectedLoad.caller.mobile ||
                          selectedLoad.caller.billingEmail) &&
                        "mb-10"
                      } d-flex align-items-center`}
                    >
                      <div className="flex-shrink-0 mr-10 align-self-center">
                        <IconUserSingle />
                      </div>
                      <div className="flex-grow-1 font-medium">
                        {selectedLoad.caller.main_contact_name}
                      </div>
                    </div>
                  )} */}
                {/* {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.mobile && (
                    <div
                      className={`${
                        selectedLoad.caller.billingEmail && "mb-10"
                      } d-flex align-items-center`}
                    >
                      <div className="flex-shrink-0 mr-10 align-self-center">
                        <IconPhone />
                      </div>
                      <div className="flex-grow-1 font-medium">
                        {selectedLoad.caller.mobile}
                      </div>
                    </div>
                  )} */}
                {/* {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.billingEmail && (
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 mr-10 align-self-start">
                        <IconAt />
                      </div>
                      <div className="flex-grow-1 text-break font-medium">
                        {selectedLoad.caller.billingEmail}
                      </div>
                    </div>
                  )} */}
              </div>

              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">
                  {selectedLoad.type_of_load === "ROAD"
                    ? "Shipper"
                    : "Port"}
                </h5>

                {selectedLoad.shipper.map((shipper, sI) => {
                  return (
                    <React.Fragment>
                      <Port shipper={shipper} />
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">
                   Delivery Warehouse
                 </h5>
                 {selectedLoad.consignee.map((consignee,i)=>{
                   return (
                      <React.Fragment>
                        <DeliveryWarehouse consignee={consignee} />
                      </React.Fragment>
                    );
                 })}
              </div>

              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">
                  Return Location
                </h5>
                {selectedLoad && selectedLoad.type_of_load !== "ROAD" && (
                <div className="d-flex justify-content-between align-items-start">
                <div className="mt-10">
                  {selectedLoad.emptyOrigin && selectedLoad.emptyOrigin.address && (
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="font-medium mb-2 font-12 text-capitalize mr-1">
                        {" "}
                        {selectedLoad.emptyOrigin
                          ? selectedLoad.emptyOrigin.company_name
                          : ""}
                      </div>
                    </div>
                  )}
                  <div>
                    {selectedLoad.emptyOrigin && selectedLoad.emptyOrigin.address
                      ? selectedLoad.emptyOrigin.address.address
                      : "N/A"}
                  </div>
                </div>
              </div>
                )}
              </div>

              <div className="card p-10 mb_5">
                <h5 className="text-black font-14 mt_3">Load Info Summary</h5>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Customer Reps </span>&nbsp;
                  <div className="group d-flex align-items-center mt-1" >
                    {this.state.assignedCSR && this.state.assignedCSR.length> 0 && 
                     this.state.assignedCSR.slice(0, 3).map((csr, index)=>{
                      return(
                        <React.Fragment>
                          <div className="group__avatar position-relative">
                            <div className={`avatar-circle avatar-sm wh-20px ${!csr.profilePicture && "border-1 text-primary" }  `} data-tip data-for={csr._id} onMouseEnter={()=> this.setState({showRemoveIcon : index})} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                            {csr.profilePicture ?
                                   <img className="avatar rounded-circle " src={csr.profilePicture} />
                                 : <div className="avatar-circle__initial  " >{csr?.name && csr?.lastName ? `${csr.name[0]}${csr.lastName[0]}` : ""}</div>}
                                {
                                this.state.showRemoveIcon == index &&  showAddRemoveOptions &&
                                <div className="notification-icon-header z-1 pointer"
                                onMouseEnter={()=> ReactTooltip.hide()}
                                 onClick={()=>this.removeCSRfromCustomer(csr)}  style={{top: "-7px", color : "#444242", backgroundColor : "#dbe2eb", border : "1px solid #b3b3b3" }}><IconTimesThinClose/></div>}  
                            </div>
                            </div>
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id={csr._id}
                          >
                            <span key ={index}>{csr?.name && csr?.lastName ? `${csr.name} ${csr.lastName}` : ""} 

                            </span>
                          </ReactTooltip>                           

                          </React.Fragment>
                      )
                    })}
                    {this.state.assignedCSR && this.state.assignedCSR.length > 3 && (
                      <div className="group__avatar z-3">
                        <div className="avatar avatar-circle avatar-sm bg-light border-1 text-primary" onMouseEnter={()=> this.setState({showRemoveIcon : 'more'})} >
                        <span className="text-dark">  +{this.state.assignedCSR.length - 3}</span>  
                        </div>

                          {this.state.showRemoveIcon == 'more' &&  <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated z-3 pointer" style={{right : "0" }} ref={this.wrapperRef}>
                          <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                            {this.state.assignedCSR && this.state.assignedCSR.length > 3 && 
                                this.state.assignedCSR.slice(3).map((csr, index)=>{
                              return(
                                <div  key={index} className="d-flex align-items-center mb-10 pointer" >
                                  <div className="flex-shrink-0 mr-10 align-self-center">
                                    {
                                      csr && csr.profilePicture ? 
                                      <img className="avatar rounded-circle " src={csr.profilePicture}  /> : 
                                      <div className="avatar-circle undefined">
                                      {`${csr.name[0]}${csr.lastName[0]}`}
                                    </div>
                                    }                                          
                                  </div>
                                  <div className="flex-grow-1 text-truncate">
                                    <h5 className="font-12 font-weight-500 text-dark m-0">{`${csr.name} ${csr.lastName}`}</h5>
                                  </div>
                                  {showAddRemoveOptions && 
                                  <div   onClick={()=>this.removeCSRfromCustomer(csr)}>
                                  <IconTimesThin />
                                  </div>
                                }
                                </div>
                              ) 
                            })}
                          </div>
                        </div>}


                      </div>
                    )}
                    { showAddRemoveOptions
                     && <div className="ml-10 group__avatar position-relative">
                          
                     {this.state.isloading ? 
                       <span
                           className="spinner-border spinner-border-sm mr-2"
                           role="status"
                           aria-hidden="true"
                         >
                         </span>
                     : <div className="avatar-circle avatar-sm bg-primary pointer wh-16px" onMouseEnter={()=> this.setState({showRemoveIcon : undefined})} onClick={()=>{this.setState({showAllCSRList : !this.state.showAllCSRList,csrSearchTerm : ""}); this.listAllCSR()}}> 
                       <div className="avatar-circle__initial text-white font-10" >
                       <IconPlusSmall /> 
                       </div>   
                       </div>
                       }  
                       {this.state.showAllCSRList && 
                         <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated popdrop--hcenter z-1" ref={this.wrapperRef}>
                           <div className="app-search position-relative mb-1">
                             <span className="search-icon ml-1"></span>
                             <div className="position-relative mb-10">
                               <input 
                                 type="input" 
                                 class="form-control input-search-left bg-gray-50" 
                                 placeholder="Search CSR.." 
                                 value={this.state.csrSearchTerm} 
                                 onChange={ e => {
                                   this.setState({csrSearchTerm : e.target.value});
                                     debounce(this.listAllCSR(e.target.value),500)
                                     }} 
                               />
                             </div>
                           </div>
                           <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }}>
                             {allCSRList && allCSRList.length > 0 ? allCSRList.map((csr, index)=>{
                               return(
                                 <div  key={index} className="d-flex align-items-center mb-10 pointer" onClick={()=>this.assignCSR(csr)}>
                                   <div className="flex-shrink-0 mr-10 align-self-center">
                                     {
                                       csr && csr.allInfos && csr.allInfos.allInfos && csr.allInfos.allInfos.profilePicture ? 
                                       <img className="avatar rounded-circle " src={csr.allInfos.allInfos.profilePicture}  /> : 
                                       <div className="avatar-circle undefined">
                                       {`${csr.label[0]}${csr.label.split(" ")[1][0]}`}
                                     </div>
                                     }
                                     
                                   </div>
                                   <div className="flex-grow-1 text-truncate">
                                     <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                                   </div>
                                 </div>
                               ) 
                             }) :  <div> No CSR's Available</div>}
                           </div>
                         </div>
                       }
                     </div>
                    }
                   
                    
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created By </span>&nbsp;
                  <span className="text-muted text-dark font-medium">{`${selectedLoad.addedBy.name}`.trim()}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created Date </span>&nbsp;
                  <span className="text-muted text-dark font-medium">
                    {moment(selectedLoad.createdAt).format(
                     `${this.userTimeFormat ? "MM/DD/YYYY HH:mm" : "MM/DD/YYYY hh:mm a"}`
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Container #</span>
                  <span className="text-muted text-dark font-medium">{selectedLoad.containerNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  {selectedLoad.type_of_load === "IMPORT" && (
                    <React.Fragment>
                    <span className="text-muted">BOL #</span>
                    <span className="text-muted text-dark font-medium">{selectedLoad.callerbillLandingNo}</span>
                    </React.Fragment>
                  )} 
                  { selectedLoad.type_of_load === "EXPORT" &&  (
                    <React.Fragment>
                    <span className="text-muted">BKG #</span>
                    <span className="text-muted text-dark font-medium">{selectedLoad.bookingNo}</span>
                    </React.Fragment>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis #</span>
                  <span className="text-muted text-dark font-medium">{selectedLoad.chassisNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">SSL</span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad && selectedLoad.containerOwner
                      ? selectedLoad.containerOwner.company_name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Size</span>
                  <span className="text-muted text-dark font-medium">
                    {" "}
                    {selectedLoad && selectedLoad.containerSize
                      ? selectedLoad.containerSize.name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Weight</span>
                  <span className="text-muted text-dark font-medium">
                    {totalWeight.toFixed(2)} {weightUnit}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Type</span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad && selectedLoad.containerType
                      ? selectedLoad.containerType.name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Commodity </span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad &&
                      selectedLoad.items.length > 0 &&
                      selectedLoad.items[0].commodity}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-muted">Reference</span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad && selectedLoad.secondaryReferenceNo
                      ? selectedLoad.secondaryReferenceNo
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-muted">Total {this.state.measureUnit}</span>                   

                  <div className="ml-auto">
                  <span>{this.state.totalDistance || ""}</span>
                    <button
                      className="btn btn-link pr-0"
                      onClick={() => {
                        calculateLoadDistance(
                          this.props.selectedLoad.reference_number
                        );
                        this.setDistanceUnit()
                      }
                        
                      }
                    >
                      <span>
                        <IconReset className="text-primary" />
                      </span>
                    </button>
                  </div>
                </div>
                {selectedLoad && selectedLoad.customerEmployee && selectedLoad.customerEmployee.length > 0 && selectedLoad.customerEmployee.map((eachEmployee) =>{
                  return (
                    <React.Fragment key={eachEmployee._id}>
                       <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Emp Email</span>
                        <div className="ml-auto">
                            {eachEmployee.billingEmail}
                        </div>

                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Emp Name</span>
                        <div className="ml-auto">
                            {eachEmployee.firstName &&  eachEmployee.firstName} {eachEmployee.lastName &&  eachEmployee.lastName}
                        </div>

                      </div>
                      </React.Fragment>
                    )
                  })}
                <div className="align-items-center">
                  <span className="text-muted">Preview </span>
                  <div className="ml-auto">
                    <div
                      id="CustomerServiceSidebarMap"
                      className="mapboxgl-map-300 mt-10"
                      style={{ height: 300 }}
                      ref={(el) => (this.mapContainer = el)}
                    ></div>
                    <div className="overlay mt-10"></div> 
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg fcpopup__right">
              <div className="card mb-0">
                <ul className="nav nav-tabs nav-tabs-custom nav-justified" id="load-tabs">
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.changeTopTab("load")
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "load" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#home2"
                      role="tab"
                    >
                      <IconInfoFocus />
                      <div className={`nav-link__text mt_4 ${this.state.activeTab === "load" ? "active_text font-semi-bold" : ""}` }>Load Info</div>
                    </a>
                  </li>

                  {this.state.showBilling && (
                    <li
                      className="nav-item"
                      onClick={() => {
                        this.changeTopTab("billing")
                      }}
                    >
                      <a
                        className={`nav-link ${
                          this.state.activeTab === "billing" ? "active" : ""
                        }`}
                        data-toggle={!this.state.isBlockingTabChange && "tab"}
                        href="#billing"
                        role="tab"
                      >
                        <IconBilling />                           
                        <div className={`nav-link__text ${this.state.activeTab === "billing" ? "active_text font-semi-bold" : ""}` }>Billing</div>
                      </a>
                    </li>
                  )}

                  <li
                    className="nav-item"
                    onClick={() => {
                      this.changeTopTab("documents")
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "documents" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#documents"
                      role="tab"
                    >
                      <IconDocument />                          
                      <div className={`nav-link__text ${this.state.activeTab === "documents" ? "active_text font-semi-bold" : ""}` }>Documents</div>
                    </a>
                  </li>

                  <li
                    className="nav-item"
                    onClick={() => {
                      this.changeTopTab("payments")
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "payments" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#payments"
                      role="tab"
                    >
                      <IconPayment />                          
                      <div className={`nav-link__text ${this.state.activeTab === "payments" ? "active_text font-semi-bold" : ""}` }>Payment</div>
                    </a>
                  </li>

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.changeTopTab("orderstatus")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "orderstatus"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#OrderStatus"
                          role="tab"
                        >
                          <IconStatus />                              
                          <div className={`nav-link__text ${this.state.activeTab === "orderstatus" ? "active_text font-semi-bold" : ""}` }>Routing</div>
                        </a>
                      </li>
                    )}

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.changeTopTab("driverpay")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "driverpay"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#DriverPay"
                          role="tab"
                        >
                          <IconTruck />                              
                          <div className={`nav-link__text ${this.state.activeTab === "driverpay" ? "active_text font-semi-bold" : ""}` }>Driver Pay</div>
                        </a>
                      </li>
                    )}
                  {/* { ((this.props.type === "customer" && getStorage("currentUserRole") == "customer") ||
                    (!this.props.type && getStorage("currentUserRole") !== "fleetcustomer" ))&& ( */}
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.changeTopTab("tracking")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "tracking"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#tracking"
                          role="tab"
                        >
                          <IconTrack />                              
                          <div className={`nav-link__text ${this.state.activeTab === "tracking" ? "active_text font-semi-bold" : ""}` }>Tracking</div>
                        </a>
                      </li>
                    {/* )} */}
                   

                  <li
                    className="nav-item"
                    onClick={() => {
                     this.changeTopTab("messaging")
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "messaging" ? "active" : ""
                      }`}
                      data-toggle={!this.state.isBlockingTabChange && "tab"}
                      href="#Messaging"
                      role="tab"
                    >
                      <IconMessage />                          
                      <div className={`nav-link__text ${this.state.activeTab === "messaging" ? "active_text font-semi-bold" : ""}` }>Messaging</div>                         
                    </a>
                  </li>

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.changeTopTab("audit")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "audit" ? "active" : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#audit"
                          role="tab"
                        >
                          <IconSearchData />                              
                          <div className={`nav-link__text ${this.state.activeTab === "audit" ? "active_text font-semi-bold" : ""}` }>Audit</div>   
                        </a>
                      </li>
                    )}

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.changeTopTab("notes")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "notes" ? "active" : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#audit"
                          role="tab"
                        >
                          <span className="position-relative">
                            <IconNotes />
                            {this.state.noteCount > 0 ? (
                              <span
                                className="avatar-xs bg-primary rounded-circle text-white d-flex align-items-center justify-content-center"
                                style={{
                                  position: "absolute",
                                  top: -8,
                                  left: -8,
                                }}
                              >
                                {this.state.noteCount}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>                              
                          <div className={`nav-link__text ${this.state.activeTab === "notes" ? "active_text font-semi-bold" : ""}` }>Notes</div>
                        </a>
                      </li>
                    )}
                </ul>
              </div>

              <div className={`tab-loads ${this.state.activeTab === "messaging" ? 'overflow-hidden' : ""}`} onScroll={this.handleScroll}>
                {this.state.activeTab === "load" && (
                  <Load
                    showButton={true}
                    Terminals={Terminals}
                    selectedLoads={this.props.selectedLoad}
                    loadType={this.props.selectedLoad.type_of_load}
                    location={{
                      query: this.props.selectedLoad.reference_number,
                    }}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    showExtraData={this.state.showExtraData}
                    handleSubmitLoad={(submit) => {
                      this.handleSubmitLoad = submit;
                    }}
                    isSavingLoad={this.state.isSavingLoad}
                    changeSavingLoadStatus={(status) =>
                      this.setState({ isSavingLoad: status })
                    }
                  />
                )}
                {this.state.activeTab === "billing" && (
                  this.isBillingInline ? 
                  <NewBillOfLading
                    loads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    pricing={this.props.selectedLoad.pricing}
                    chargeConflicts={this.props.selectedLoad.chargeConflicts}
                    updateList={this.props.updateData}
                    changeList={changeList}
                    setLoadEmails={this.props.setLoadEmails}
                    onEditorStateChange={this.props.onEditorStateChange}
                    editorState={this.props.editorState}
                    inpSubject={this.props.inpSubject}
                    handleSubmitLoad={(submit) => {
                      this.handleSubmitLoad = submit;
                    }}
                    changeSavingLoadStatus={(status) =>
                      this.setState({ isSavingLoad: status })
                    }
                    setBillingActiveTab={this.handleTabChange}
                    billingActiveTab={this.state.billingActiveTab}
                    blockTabChange={this.blockTabChange}
                    isBlockingTabChange={this.state.isBlockingTabChange}
                    showPrompt={this.showPrompt}
                    handleExpenseTab={this.handleExpenseTab}
                    isShowingPrompt={this.state.isShowingPrompt}
                  /> :
                  <BillOfLading
                    loads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    pricing={this.props.selectedLoad.pricing}
                    chargeConflicts={this.props.selectedLoad.chargeConflicts}
                    updateList={this.props.updateData}
                    changeList={changeList}
                    setLoadEmails={this.props.setLoadEmails}
                    onEditorStateChange={this.props.onEditorStateChange}
                    editorState={this.props.editorState}
                    inpSubject={this.props.inpSubject}
                    handleSubmitLoad={(submit) => {
                      this.handleSubmitLoad = submit;
                    }}
                    changeSavingLoadStatus={(status) =>
                      this.setState({ isSavingLoad: status })
                    }
                    setBillingActiveTab={this.handleTabChange}
                    billingActiveTab={this.state.billingActiveTab}
                  />
                )}
                {this.state.activeTab === "documents" && (
                  <Documents
                    selectedLoads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    type={this.props.type}
                  />
                )}
                {this.state.activeTab === "payments" && (
                  <NewPaymentInline
                    loads={this.props.selectedLoad}
                    id={this.props.selectedLoad.reference_number}
                    key={`sub_${selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                    type={this.props.type}
                    blockTabChange={this.blockTabChange}
                    isBlockingTabChange={this.state.isBlockingTabChange}
                    showPrompt={this.showPrompt}
                />
                )}
                {this.state.activeTab === "orderstatus" && (
                    <NewLoadDriverOrder
                      selectedLoads={this.props.selectedLoad}
                      reference_number={
                        this.props.selectedLoad.reference_number
                      }
                      key={`sub_${this.props.selectedLoad.reference_number}`}
                      updateList={this.props.updateData}
                      changeList={changeList}
                      originalLoad={_.cloneDeep(this.props.selectedLoad)}
                      changeTopTab={this.changeTopTab}
                      handleSubmitLoad={(submit) => {
                        this.handleSubmitLoad = submit;
                      }}
                      changeSavingLoadStatus={(status) =>
                        this.setState({ isSavingLoad: status })
                      }
                      /> 
                )}
                {this.state.activeTab === "driverpay" && (
                   <DriverPay
                   isTab={true}
                   location={{
                     query: {
                       reference_number:
                         this.props.selectedLoad.reference_number,
                     },
                   }}
                   selectedLoads={this.props.selectedLoad}
                   id={this.props.selectedLoad.reference_number}
                   key={`sub_${this.props.selectedLoad.reference_number}`}
                   updateList={this.props.updateData}
                   reference_number={
                     this.props.selectedLoad.reference_number
                   }
                   blockTabChange={this.blockTabChange}
                   isBlockingTabChange={this.state.isBlockingTabChange}
                   showPrompt={this.showPrompt}
                   />
                )}
                {this.state.activeTab === "tracking" && (
                  <NewLoadHistory
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    updateList={this.props.updateData}
                  />
                )}
                {this.state.activeTab === "messaging" && (
                  <NewOneLoadMessaging
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                  />
                )}
                {this.state.activeTab === "audit" && (
                  <AuditLoad
                    Terminals={Terminals}
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                  />
                )}
                {this.state.activeTab === "notes" && (
                  <NewNotes
                    selectedLoads={this.props.selectedLoad}
                    key={`sub_${this.props.selectedLoad.reference_number}`}
                    showNotes={this.props.showNotes}
                    location={{
                      query: {
                        reference_number:
                          this.props.selectedLoad.reference_number,
                      },
                    }}
                  />
                )}
                <Modal show={this.state.isShowingPrompt} className="backdrop_modal_open"  dialogClassName="modal-sm modal-dialog-centered">
                     <Modal.Header></Modal.Header>

                     <Modal.Body>
                       <div className="modal-body">
                         <div className={`text-center`}>
                           <div className="mb-30">
                             <CustomIconAlert />
                           </div>

                           <h4 className="modal-title mb-10">
                             You are missing required fields.
                           </h4>

                           <p className="text-muted font-14">
                             Do you want to exit the page without finishing ?
                             Any changes made will not be saved
                           </p>
                         </div>
                       </div>
                     </Modal.Body>
                     <Modal.Footer>
                       <button
                         className="btn btn-close"
                         id='cancleChanges'
                         onClick={() =>
                           this.setState({ isShowingPrompt: false, 
                            nextTab : undefined,
                            closingSideBar : false,
                            toChangeLoad  : undefined })
                         }
                       >
                         Cancel
                       </button>
                       <button className={`btn btn-danger`} id='continueWthoutSaving' onClick={() => this.continueWithoutSaving() }>
                         Continue Without Saving
                       </button>
                     </Modal.Footer>
                   </Modal>
              </div>
            </div>
          </div>
    )}
    </Modal.Body>
    </Modal>
  </React.Fragment>}
    
  </React.Fragment>
   );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    allPublicUsers: state.TmsReducer.allPublicUsers,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerServiceSideBar);
