import React from 'react'
import PopperTooltip from '../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import { IconSaveAsDefault } from '../../../Common/Icons';
import useHover from '../../../CustomHooks/useHover';

const RedirectToRates = ({info,redirectToRates, style}) => {
    const [redirectToRatesRef, redirectToRatesElement] = useHover();
  return (
    <>
        <td style={style}>
            {(info.driverPayRateId ||
                info.dynamicDriverPayId ||
                info.isManual) && (
                    <>
                        <span
                            ref={redirectToRatesRef}
                            onClick={() =>
                                redirectToRates(info)
                            }
                            className={'pt-2'}
                        >
                            <IconSaveAsDefault />
                        </span>
                        <PopperTooltip 
                                isOpen={redirectToRatesElement}
                                refNo={redirectToRatesRef.current} 
                                name={info.driverPayRateId
                                    ? "Driver Pay Rate"
                                    : info.dynamicDriverPayId
                                        ? "Dynamic Driver Pay"
                                        : info.isManual
                                            ? "Manually Changed"
                                : ""}  
                                color={'gray-700'} 
                                cssClass={"load-driver"} />
                    </>
                )}
        </td>
    </>
  )
}

export default RedirectToRates
