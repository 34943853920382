import * as $ from "jquery";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import LoadDriverOrder from "../../../pages/tms/Load/LoadDriverOrder";
import {
  assignLoad,
  getAvailableLoads,
  makeLoadStartable
} from "../../../pages/trucker/NewTrack1/actionCreators";
import DriverDetails from "../../../pages/trucker/NewTrack1/DriverDetails";
import { DriverSummaryComponent } from "../../../pages/trucker/NewTrack1/elements/DriverSummaryComponent";
import { toastr, getStorage } from "../../../services/Common.services";
import "./scrollhide.css";
import "./style.css";
import _ from "lodash";

var Loader = require("react-loader");

class TrackLeftBar extends Component {
  constructor(props) {
    super(props);
    this.dayDrivingLimit = 11 * 60;
    this.dayOnLimit = 14 * 60;
    this.dayOffSlLimit = 10 * 60;
    this.maxShiftLimit = 14 * 60;
    this.restBreakLimit = 30;
    this.untilBreakLimit = 8 * 60;
    this.consecutiveDriveLimit = 8 * 60;
    this.weekOnDrivingLimit = 60 * 60;
    this.state = {
      name: "",
      activeDriver: undefined,
      driverELDdata: {},
      closeRow: {},
      remainUntilBreakLimit: 0,
      remainDriveLimitPerWeek: this.weekOnDrivingLimit,
      remainShiftLimitToday: this.maxShiftLimit,
      dayDrivingLimit: this.dayDrivingLimit,
      dayOnLimit: this.dayOnLimit,
      dayOffSlLimit: this.dayOffSlLimit,
      clockTime: 0,
      driverList: [],
      isLoaded: false,
      innerHeight: null,
      activeDriver: null,
      loads: [],
      isLoading: false,
      showDriverModal: false,
      selectedLoad: null,
    };

    this.closeDriverEldRow = this.closeDriverEldRow.bind(this);
    this.activeDriver = this.activeDriver.bind(this);
    this.activeDriverState = this.activeDriverState.bind(this);
    this.updateDriver = this.updateDriver.bind(this);
    this.handleUpdateActiveDriver = this.handleUpdateActiveDriver.bind(this);
    this.handleAssignLoad = this.handleAssignLoad.bind(this);
  }

  // close the eld row
  closeDriverEldRow(id, status) {
    let closeRow = Object.assign({}, this.state.closeRow);
    status = status === undefined ? false : status ? false : true;
    closeRow[id] = status;
    this.setState({
      closeRow,
    });
  }

  activeDriverState(driverid) {
    this.setState({
      activeDriver: driverid,
    });
  }

  componentDidMount() {
    $(".driver-list").css(
      "height",
      window.innerHeight -
        $("nav").height() -
        25 -
        $(".shipment-filter").height() -
        $(".search").height()
    );
    this.setState({
      innerHeight: window.innerHeight,
    });
    var self = this;
    $(window).resize(() => {
      self.setState({
        innerHeight: window.innerHeight,
      });
    });
    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    const carrierUser = JSON.parse(getStorage("carrierDetail"));
    if(loggedInUser.role === "fleetmanager" && carrierUser.driverPreferences) {
      this.setState({ driverPreferences: true });
    }
    if (loggedInUser && loggedInUser.driverPreferences) {
      this.setState({ driverPreferences: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.drivers && nextProps.dispatchedDriverList && nextProps.notAssignedDriverList ) {
      const driverList = nextProps.filterSelected === 1 ?  nextProps.drivers 
                        : nextProps.filterSelected === 2 ? nextProps.dispatchedDriverList
                        : nextProps.filterSelected === 3 ? nextProps.notAssignedDriverList
                        : nextProps.drivers
      this.setState({
        driverList,
        isLoaded: true,
        driverPlaces: nextProps.driverPlaces,
      });
    }
  }
  updateDriver(params) {
    const driverList = this.state.driverList;
    driverList.forEach((user) => {
      if (user.driver._id === params.driverId) {
        user.driver.currentDriverAddress = params.currentDriverAddress;
        user.driver.lastUpdatedAddress = params.lastUpdatedAddress;
      }
    });
    this.setState({ driverList });
  }
  clearFilter() {
    let self = this;
    this.setState(
      {
        name: "",
        activeDriver: undefined,
      },
      () => {
        self.props.getallDriverLogs();
        // self.props.selectDriver();
      }
    );
  }
  handleDriverSearch = (e) => {
    const value = e.target.value;
    if (this.props.handleDriverSearch) {
      // console.log("Handle Driver Search: Valid");
      this.props.handleDriverSearch(value);
    } else {
      // console.log("Handle Driver Search: In Valid");
    }
  };

  handleSearchLoad(e) {
    const value = e.target.value;
    this.props.handleSearchLoad(value);
  }

  activeDriver(driverID) {
    return this.state.activeDriver === driverID ? "active" : "test";
  }

  async handleUpdateActiveDriver(driverID) {
    try {
      this.setState({
        isLoading: true,
        activeDriver: driverID,
      });
      let _loads = await getAvailableLoads();
      let loads = []
      let driver = this.state.driverList.find((d) => d._id === driverID)
      if(this.state.driverPreferences && driver) {
        loads = _loads.filter((load) => {
          if (load.hazmat && !driver.driver.hazmat) return false;
          else if (load.liquor && driver.driver.liquor === "false") return false;
          else if ((load.overWeight && !driver.driver.overweight)
            || (load.overWeight && _.intersection(driver.driver.owerWeightStates, load.consignee.map((d) => d.state)).length === 0))
            return false;
          return true;
        })
      } else {
        loads = _loads;
      }
      this.setState({
        loads,
        isLoading: false,
      });
    } catch (e) {
      toastr.show("We could not get the load.  Please try again.", "error");
    }
  }

  async handleAssignLoad(referenceNumber, driverID) {
    try {
      this.setState({
        isLoading: true,
      });
      const data = await assignLoad({
        reference_number: referenceNumber,
        userId: driverID,
        isloadStartable: false,
      });

      await makeLoadStartable({
        reference_number: referenceNumber,
      });

      this.setState({
        activeDriver: null,
        loads: [],
        isLoading: false,
        showDriverModal: true,
        selectedLoad: data,
      });

      if (this.props.getallDriverLogs) {
        this.props.getallDriverLogs();
      }

      toastr.show("Your load has been assigned!", "success");
    } catch (e) {
      toastr.show("We could not assign this load. Try again!", "error");
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { loads, activeDriver, isLoading, showDriverModal, selectedLoad } =
      this.state;
    return (
      <React.Fragment>
        {!this.props.selectedDriverID ? (
          <div className="mapbar" style={{ height: "calc(100vh - 120px)" }}>
            <div className="card p-2 mb-1">
              <div className="app-search mb-2">
                <input
                  type="text"
                  className="input-search-left form-control"
                  placeholder="Search..."
                  onChange={this.handleDriverSearch}
                />
                <span className="search-icon"></span>
              </div>
              <ul
                className="nav nav-compact nav-justified text-nowrap"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.props.filterSelected === 1 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => {
                      if (this.props.handleDispatchFilter) {
                        this.props.handleDispatchFilter(1);
                      }
                    }}
                  >
                    All Drivers (
                      {this.props.drivers.length})
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.props.filterSelected === 2 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => {
                      if (this.props.handleDispatchFilter) {
                        this.props.handleDispatchFilter(2);
                      }
                    }}
                  >
                    Dispatched ({this.props.dispatchedDriverList.length})
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.props.filterSelected === 3 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => {
                      if (this.props.handleDispatchFilter) {
                        this.props.handleDispatchFilter(3);
                      }
                    }}
                  >
                    Not Assigned (
                   {this.props.notAssignedDriverList.length}
                    )
                  </a>
                </li>
              </ul>
            </div>
            <div className="dlist" style={{ height: "calc(100% - 55px)" }}>
              <Loader
                loaded={this.state.isLoaded}
                options={{ width: 2, length: 2, top: "80%", radius: 10 }}
              >
                {this.state.driverList &&
                  this.state.driverList.map((driver) => {
                    return (
                      <DriverSummaryComponent
                        driver={driver}
                        driverLocationDataFromFirebase={this.props.driverLocationDataFromFirebase && this.props.driverLocationDataFromFirebase[driver._id]}
                        key={driver._id + Math.random()}
                        selectDriver={this.props.selectDriver}
                        selectedDriverID={this.props.selectedDriverID}
                        driverPlaces={this.props.driverPlaces}
                        closeDriverEldRow={this.closeDriverEldRow}
                        stateTo={this.state}
                        Id={driver._id}
                        closeRow={this.state.closeRow}
                        activeDriverState={this.activeDriverState}
                        driverELDdata={this.state.driverELDdata}
                        updateDriver={this.updateDriver}
                        updateReverseLocation={this.props.updateReverseLocation}
                        filterDriverList={this.props.filterDriverList}
                        userType={this.props.userType}
                        activeDriver={activeDriver}
                        changeActiveDriver={this.handleUpdateActiveDriver}
                        loads={loads}
                        assignLoad={this.handleAssignLoad}
                        isLoading={isLoading}
                        showAssignBtn={true}
                      />
                    );
                  })}
              </Loader>
            </div>
          </div>
        ) : (
          <DriverDetails
            selectedDriver={this.props.selectedDriver}
            drawRouteToMap={this.props.drawRouteToMap}
            mapInstance={this.props.mapInstance}
            toggleLiveLocation={this.props.toggleLiveLocation}
            liveLocationToggled={this.props.liveLocationToggled}
          />
        )}
        { showDriverModal && <Modal
          show={showDriverModal}
          centered={true}
          className="modal-extend"
          scrollable={true}
        >
          <Modal.Body className="bg-gray-50 p-3 h-100">
            <LoadDriverOrder
              selectedLoads={selectedLoad}
              reference_number={selectedLoad && selectedLoad.reference_number}
              key={`sub_${selectedLoad ? selectedLoad.reference_number : ""}`}
              changeList={this.updateList}
              closeStatusModal={() => {
                this.setState({ showDriverModal: false });  
              }}
              dispatcherModal={true}
            />
          </Modal.Body>
        </Modal>}
      </React.Fragment>
    );
  }
}

export default connect()(TrackLeftBar);
