import React, { useEffect, useRef, useState } from 'react'
import config from '../../config';
import PrintModal from '../../pages/tms/Dashboard/Print';
import {  getNewLoadDetail } from '../../pages/tms/Dispatcher/actionCreators';
import { duplicateLoad } from '../../pages/tms/services';
import { getStorage, toastr } from '../../services';
import confirm from '../Common/ConfirmAert';
import CustomerServiceSideBar from '../CustomerServiceSideBar/CustomerServiceSideBar';

const firebase = config.getNewFirebaseInstance;
const db = firebase('load-info-model').database();

const LoadInfoModal = (props) => {
  const loadRef = useRef(null)
  const  loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const { hideLoadModal, refNumber,loadInfo, handleNextLoad, handlePrevLoad, hideDuplicate, refreshDispatcher, openWithTab } = props
  const [showPrintModal, setPrintModal] = useState(false)
  const [activeTab, setActiveTab] = useState(openWithTab ?? "load")

  const [loadData,setLoadData] = useState(null)

  useEffect(()=>{
    if(refNumber)getLoadInfo(refNumber)
    subscribeToLoadChange()
    return () => {
      loadRef && loadRef.current.off();
    }
  },[refNumber])

  const getCarrierId = () =>
    loggedInUser.role === "carrier"
      ? loggedInUser._id
      : loggedInUser.role === "fleetmanager"
      ? loggedInUser.fleetManager.carrier
      : undefined;

  const subscribeToLoadChange = async () => {
    loadRef.current = db.ref(`${getCarrierId()}/lastUpdatedLoad`);

    loadRef.current.on("value", (snapshot) => {
      try {
        const data = snapshot.val()
        const load = JSON.parse(data);
        if(refNumber===load.reference_number){
          setLoadData(load)
        }
      } catch(e) {
        console.log("Tracking exception: ", e);
      }
    });
  }

  const getLoadInfo = async(reference_number)=>{
    const res = await getNewLoadDetail(reference_number);
    if(res)setLoadData(res)
  }
  const duplicateLoadFun = (count, reference_number, carryOverPricing) => {
    const payload = { reference_number };

    if (count > 50) {
      toastr.show("You have exceeded the number of times allowed!", "error");
      return;
    }
    if (!count) {
      toastr.show("You must enter a value between 0 and 50.", "error");
      return;
    }

    if (carryOverPricing) {
      payload.carryOverPricing = carryOverPricing;
    }

    duplicateLoad(payload).then(() => {
      count -= 1;
      if (count > 0) {
        duplicateLoadFun(count, reference_number, carryOverPricing);
      } else {
        toastr.show("Added!", "success");
      }
    });
  }
  const duplicateLoadModal = (reference_number) => {
    const options = {
      showInputText: true,
      showCheckBox: true,
    };
    confirm(
      "Duplicate This Load",
      "How many new loads do you want to create from this load?",
      async (confirm, count, carryOverPricing) => {
        if (confirm)
          duplicateLoadFun(count, reference_number, carryOverPricing);
      },
      options
    );
  };
  
  return (
    <>
    {console.log(loadData,"LoadData", loadData?.isDeleted,"isDeleted")}
      {loadData &&
        <CustomerServiceSideBar
          key={
            loadData
              ? `loadInfo_${loadData.reference_number}`
              : ""
          }
          closeCustomerServiceSideBar={hideLoadModal}
          showSidebar={true}
          selectedLoad={loadData}
          activeTab={activeTab}
          changeCustomerServiceSidebarTab={(activeCustomerServiceSidebarTab) => setActiveTab(activeCustomerServiceSidebarTab)}
          printModal={() => setPrintModal(true)}
          refreshDispatcher={refreshDispatcher}
          duplicateLoad={!hideDuplicate && duplicateLoadModal}
          handleNextLoad={handleNextLoad}
          handlePrevLoad={handlePrevLoad}
          isNewDeleted={loadData?.isDeleted || props.isNewDeleted }
        />
      }
      {showPrintModal &&
        <PrintModal
          showModal={showPrintModal}
          handleClose={() => setPrintModal(false)}
          selectedLoads={loadData}
        />
      }
    </>
  )
}

export default LoadInfoModal