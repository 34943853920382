import React, { Component } from "react";

import _ from "lodash";
import allField from "./allField";
import AsyncSelect from "react-select/async";
import {
  getTMScaller,
  getTMSshipper,
  getTMSconsignee,
} from "../Dispatcher/actionCreators";

let freeze = false; //mark delay
let timer; //saved timer

class PdfMap extends Component {
  constructor(props){
    super(props);
    this.state = {
        inputVal: ''
    }
  }
  componentDidMount(){
    if(!["caller", "shipper", "consignee", "containerSize", "containerOwner", "containerType"].includes(this.props.Key)){
      let gid = Array.isArray(this.props.Data[this.props.Key])
      ? this.props.Data[this.props.Key][0]
      : this.props.Data[this.props.Key];
      this.setState({inputVal:gid});
    }
  }
  getTMScallerAsyncSearch = async (strSearch) => {
    freeze = true; //set mark for stop calls
    return new Promise(async (res, err) => {
      //return promise
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer); //remove  prev timer
        timer = setTimeout(async () => {
          freeze = false;
          var r = [];

          if (this.props.Key == "shipper") {
            r = await getTMSshipper(strSearch); //request
          } else if (this.props.Key == "consignee") {
            r = await getTMSconsignee(strSearch); //request
          } else {
            r = await getTMScaller(strSearch); //request
          }

          r = _.sortBy(r, ["label"]);
          res(r);
        }, 1000);
      });

      p.then(function (x) {
        res(x);
      });
    });
  };

  getDefaultVal = (opts, gId) => {
    let val = opts.find((aad) => gId === aad.value);
    if (!val && gId) {
      val = {
        value: gId,
        label: this.props.pdfData[this.props.Id][this.props.Key + "Value"]
          ? this.props.pdfData[this.props.Id][this.props.Key + "Value"]
          : this.props.Data[this.props.Key],
      };
    }
    return val;
  };

  onChangeInput=(e)=>{
    this.props.onSetDataValue({
      key:this.props.Key,
      value:e.target.value,
      index:this.props.Id
    });
    this.setState({inputVal:e.target.value})
  }

  render() {
    let ValueKey = this.props.Key + "Value";
    let Label = allField[this.props.Key]
      ? allField[this.props.Key].label
      : null;
    if (!Label) {
      if (this.props.Key.indexOf("Value") != -1) {
        let thatKey = this.props.Key.replace("Value", "");
        Label = allField[thatKey].label;
      }
    }
    let oldData = this.props.pdfData[this.props.Id][ValueKey]
      ? this.props.Data[this.props.Key]
      : undefined;
    if (Label == "Port" && this.props.type_of_load == "ROAD") {
      Label = "Port";
    }
    if (Label == "Port" && this.props.type_of_load == "ROAD") {
      Label = "Port";
    }
    if (Label == "Booking No" && this.props.type_of_load == "EXPORT") {
      Label = "Bill Lading NO";
    }
    let ArrayData = [];
    if (["caller", "shipper", "consignee"].indexOf(this.props.Key) != -1) {
      ArrayData = this.props.wholeCompaniesInfo;
    }
    if (["containerSize"].indexOf(this.props.Key) != -1) {
      ArrayData = this.props.ContainerSize;
    }
    if (["containerOwner"].indexOf(this.props.Key) != -1) {
      ArrayData = this.props.ContainerOwner;
    }
    if (["containerType"].indexOf(this.props.Key) != -1) {
      ArrayData = this.props.ContainerType;
    }

    let gid = Array.isArray(this.props.Data[this.props.Key])
      ? this.props.Data[this.props.Key][0]
      : this.props.Data[this.props.Key];
    if (this.props.caller && this.props.Key == "caller") {
      gid = this.props.caller;
    }
    if (this.props.shipper && this.props.Key == "shipper") {
      gid = this.props.shipper;
    }
    if (this.props.consignee && this.props.Key == "consignee") {
      gid = this.props.consignee;
    }
    let options = ArrayData.map((opt) => {
      let vals = {
        value: opt._id,
        label: opt.company_name ? opt.company_name : opt.name,
        emails: opt.employeeEmails ? opt.employeeEmails : "",
      };
      return vals;
    });
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <label>{Label == "Booking No" ? "BOL/BKG" : Label}</label>
          </div>
          <div className="col-md-4">
            {this.props.pdfData[this.props.Id][ValueKey]
              ? this.props.pdfData[this.props.Id][ValueKey]
              : this.props.Data[this.props.Key]}
          </div>
          <div className="col-md-4">
            <div className="form-group">
              {["containerSize", "containerOwner", "containerType"].indexOf(
                this.props.Key
              ) != -1 && (
                <select
                  className="form-control"
                  onChange={(e) =>
                    this.props.changeMap({
                      key: this.props.Key,
                      id: e.target.value,
                      oldValue: oldData,
                      value: this.props.pdfData[this.props.Id][ValueKey]
                        ? this.props.pdfData[this.props.Id][ValueKey]
                        : this.props.Data[this.props.Key],
                      Id: this.props.Id,
                    })
                  }
                >
                  {!this.props.Data[this.props.key] &&
                    this.props.pdfData[this.props.Id][ValueKey] && (
                      <label>Please Select to Map</label>
                    )}
                  <option value="">Select</option>
                  {ArrayData.map((H) => {
                    return (
                      <option selected={gid == H._id} value={H._id}>
                        {H.company_name ? H.company_name : H.name}
                      </option>
                    );
                  })}
                </select>
              )}
              {["caller", "shipper", "consignee"].indexOf(this.props.Key) !=
                -1 && (
                <AsyncSelect
                  placeHolder={
                    !this.props.Data[this.props.key] &&
                    this.props.pdfData[this.props.Id][ValueKey] && (
                      <span>Please Select to Map</span>
                    )
                  }
                  defaultOptions={options}
                  defaultValue={this.getDefaultVal(options, gid)}
                  onChange={(e) => {
                    this.props.changeMap({
                      key: this.props.Key,
                      id: e.value,
                      oldValue: oldData,
                      value: this.props.pdfData[this.props.Id][ValueKey]
                        ? this.props.pdfData[this.props.Id][ValueKey]
                        : this.props.Data[this.props.Key],
                      Id: this.props.Id,
                    });
                  }}
                  loadOptions={this.getTMScallerAsyncSearch}
                />
              )}
              {
                !["caller", "shipper", "consignee", "containerSize", "containerOwner", "containerType"].includes(this.props.Key) &&
                  <input
                    value={this.state.inputVal}
                    onChange={(e) =>  this.onChangeInput(e)   }
                    className={`form-control`}
                    type="text"
                    placeholder={Label}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PdfMap;
