import React, { useRef, useState } from "react";
import moment from "moment-timezone";
import { IconCircleClose, IconCloseBold } from "../../../../../../Components/Common/Icons";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";

const DateTimePicker = ({ defaultDate, isShowTime, onChange, OnBlurUpdate, onRemoveDate, displayTimeZone, hideShowSelectDate, setShow, className }) => {
	const today = moment().startOf("day");
	const [selectedDay, setSelectedDay] = useState(defaultDate);
	const [isTime, setIsTime] = useState(false);
	const [currentMonth, setCurrentMonth] = useState(defaultDate ? defaultDate:today);
	const firstDayCurrentMonth = moment(currentMonth).startOf("month")

	const boxRef = useRef();
	useOnClickOutside(boxRef, () => { OnBlurUpdate && OnBlurUpdate(); setShow && setShow(false)});

	const previousMonth = (e) => {
		e.preventDefault();
		let firstDayNextMonth = moment(firstDayCurrentMonth)
			.subtract(1, "month")
			.startOf("month")

		setCurrentMonth(firstDayNextMonth);
	};

	const nextMonth = (e) => {
		e.preventDefault();
		let firstDayNextMonth = moment(firstDayCurrentMonth)
			.add(1, "month")
			.startOf("month")
		setCurrentMonth(firstDayNextMonth);
	};

	const days = (startDate, endDate) => {
		var now = startDate,
			dates = [];

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.format("MM/DD/yyyy"));
			now.add(1, "days");
		}
		return dates;
	};

	const time = () => {
		const starOfDay = moment(today).startOf("day"),
			endOfDay = moment(today).endOf("day"),
			time = [];

		while (starOfDay.isSameOrBefore(endOfDay)) {
			time.push(starOfDay.format("lll"));
			starOfDay.add(15, "m");
		}
		return time;
	};

	const OnHandleChange = (val, _time) => {
		let selectedDate = moment(val).startOf("day").format("MM/DD/yyyy");
		if (isTime || _time) {
			_time = _time === undefined ? isTime : _time;
			const time = moment(_time).format("hh:mm A");
			const date = selectedDate + " " + time;
			setSelectedDay(date);

			const _date = moment.tz(date, displayTimeZone)
				.add("minutes", moment(date).tz(displayTimeZone).utcOffset() * -1)
				.toISOString();
			onChange(_date);
		} else {
			setSelectedDay(selectedDate);

			const _date = moment.tz(selectedDate, displayTimeZone)
				.add("minutes", moment(selectedDate).tz(displayTimeZone).utcOffset() * -1)
				.toISOString();
			onChange(_date);
		}
	};

	const selectedTime = moment(selectedDay).format("hh:mm A");

	return (
		<div ref={boxRef} className="position-relative">
			<div className={`${isShowTime ? `date-time-dropdown__menu` : `date-dropdown__menu`} position-absolute shadow-5 card ${className ? className : ''}`}>
				{!hideShowSelectDate && <div className="form-group p-10 m-0 d-flex justify-content-center justify-content-between">
				<div className={`form-control datetime-input__value w-200 text-gray-400 d-flex position-relative ml-10 ${isShowTime ? 'align-items-center' : 'justify-content-between'}`}>
						<div className="position-relative">
							<input
								className="border-0"
								type="text"
								placeholder="Select Date"
								value={
									(selectedDay && !isShowTime)
										? moment(selectedDay).format("MM/DD/yyyy")
										: (selectedDay && isShowTime)
											? moment(selectedDay).format("MM/DD/yyyy hh:mm A")
											: ""
								}
								onChange={() => {}}
							/>
						</div>
						{selectedDay && (
							<div className="input-icon">
								<span
									className="pointer"
									onClick={() => {
										onRemoveDate && onRemoveDate();
										setSelectedDay(null);
									}}
								>
									<IconCircleClose className="fill-primary" />
								</span>
							</div>
						)}
					</div>
					<div
						className="d-flex justify-content-center align-items-center pointer mr-10"
						onClick={() => OnBlurUpdate()}
					>
						<IconCloseBold />
					</div>
				</div>}
				<div className="d-flex border_top">
					<div className="calendar-picker__calendar w-100 p-10 pb-0">
						<div className="calendar-picker__calendar-nav d-flex justify-content-between align-items-center my-15 px-10">
							<div className="calendar-picker__calendar-nav-controls d-flex justify-content-between align-items-center pointer">
								<div>
									<i
										className="uil uil-angle-left text-dark font-18"
										onClick={(e) => previousMonth(e)}
									/>
								</div>
							</div>
							<div className="calendar-picker__calendar-nav-month font-medium font-14 text-dark position-relative">
								{moment(currentMonth).format("MMMM,yyyy")}
							</div>
							<div className="calendar-picker__calendar-nav-controls d-flex justify-content-between align-items-center pointer">
								<div>	
									<i
										className="uil uil-angle-right text-dark font-18"
										onClick={(e) => nextMonth(e)}
									/>

								</div>
							</div>
						</div>
						<div className="grid grid-cols-7 my-10">
							{["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((el, i) =>
								<div key={el+i} className="calendar-weekday d-flex justify-content-center text-gray-900 font-semibold">
									{el}
								</div>
							)}
						</div>
						<div className="calendar-week d-flex justify-content-between align-items-center">
							<div className="calendar-day grid w-100 grid-cols-7 text-gray-900">
								{days(
									moment(firstDayCurrentMonth),
									moment(firstDayCurrentMonth).endOf("month")
								).map((day, index) => {
									const isToday =
										moment(0, "HH").diff(moment(day), "days") == 0;
									const isSame = selectedDay
										? moment(selectedDay).isSame(moment(day), "day")
										: false;
									return (
										<div
											key={index}
											className={` ${index === 0 && colStartClasses[moment(day).day()]}`}
										>
											<div
												onClick={() => OnHandleChange(day)}
												className={`calendar-day__cell ${isSame && "text-white"} 
															${!isSame && isToday && "today_dateseleted"}
															${isSame && isToday && "bg-black"}
															${isSame && !isToday && "bg-black"}
															${(isSame || isToday) && "font-semibold"}
														}`}
											>
												{moment(day).format("D")}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{isShowTime && <div className="calendar-picker__sidebar">
						<div className="p-10 p-15 pb-0 pr-1">
						<ul
							className="timing_list m-0 grid grid-cols-3 pr-10 text-gray-900"
							style={{ maxHeight: "287px", overflowY: "auto" }}
						>
							{time().map((el, i) => {
								const _disTime = moment(el).format("hh:mm A");
								return (
									<li
										key={"times" + i}
										className={`d-flex justify-content-center align-items-center pointer text-center rounded list-unstyled
											py-2 rounded-10 ${selectedTime === _disTime ? 'bg-primary text-white' : ''}`}
										onClick={() => {
											OnHandleChange(selectedDay ? selectedDay : today, el);
											setIsTime(true);
										}}
									>
										{_disTime}
									</li>
								)
							})}
						</ul>
						</div>
					</div>}
				</div>
			</div>
		</div>
	);
};

const colStartClasses = [
	"",
	"col-start-2",
	"col-start-3",
	"col-start-4",
	"col-start-5",
	"col-start-6",
	"col-start-7",
];

export default DateTimePicker;