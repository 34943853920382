import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams';
import config from '../../../config';

export function updateDriverOrderById(params){
  const url = 'tms/updateDriverOrderById';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
export function removeStatusDriverOrderById(params){
  const url = 'tms/removeStatusDriverOrderById';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
export function updateLoadAssignedDate(params){
  const url = 'tms/updateLoadAssignedDate';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function addCharge(params) {
  let url = 'tms/addChargesInSub';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
        'authorization': getStorage('token')
    })
    .then((result) => {
        if (result)
          resolve(result.data.data)
    })
    .catch((error) => {
        reject(error);
    });
});
}

export function uncompleteLoad(params){
  const url = 'tms/uncompleteLoad';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
export function editLoad(params){
  const url = 'tms/editLoad';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
export function setLoadStatusOrder(params){
    const url = 'tms/updateDriverOrder';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token')
        })
        .then((result) => {
            if (result)
              resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    });
}
export function deleteDocument(payload) {
  return function (dispatch) {
    dispatch({
      type: 'TMS_UPDATE_DOCUMENT',
      payload
    })
  }
}
export function updateDocument(payload) {
  return function (dispatch) {
    dispatch({
      type: 'TMS_UPDATE_DOCUMENT',
      payload
    })
  }
}

export function updateLoad(payload) {
  return function (dispatch) {
    dispatch({
      type: 'TMS_UPDATE_LOAD',
      payload
    })
  }
}


export function updateDocumentRotate(payload) {
  return function (dispatch) {
    dispatch({
      type: 'TMS_UPDATE_DOCUMENT_ROTATE',
      payload
    })
  }
}

export function updateNotes(payload) {
  return function (dispatch) {
    dispatch({
      type: 'TMS_UPDATE_NOTES',
      payload
    })
  }
}

export function removeStreetTurn(params) {
  let url = 'tms/removeStreetTurn';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCallers(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let customers = [];
        if (result.data && result.data.data) {
          customers = result.data.data.map((customer) => 
            ({ value: customer._id, label: customer.company_name, allInfos: customer })
          );
        }
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getShippers(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let customers = [];
        if (result.data && result.data.data) {
          customers = result.data.data.map((customer) => 
            ({ value: customer._id, label: customer.company_name, allInfos: customer })
          );
        }
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getConsignees(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let customers = [];
        if (result.data && result.data.data) {
          customers = result.data.data.map((customer) => 
            ({ value: customer._id, label: customer.company_name, allInfos: customer })
          );
        }
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getFromToPlace(params) {
  const obj = { limit: 10 }
  if (params)
    obj.searchTerm = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let customers = [];
        if (result.data && result.data.data) {
          customers = result.data.data.map((customer) => 
            ({ value: customer._id, label: customer.company_name })
          );
        }
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getLoadNoteDetails(params) {
  let url = 'tms/getLoadNoteDetails';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function removeBobtailDriverPay(params) {
  let url = 'tms/remove-bobtail-driver-pay';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function updateLoadCompletedDate(params){
  const url = 'tms/updateLoadCompletedDate';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
// 39Iz2u&N
export function createInvoiceMain(params){
  const url = 'v1/invoice/create/main';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
// 39Iz2u&N
export function createInvoiceSub(params){
  const url = 'v1/invoice/create/sub';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function updateInvoiceV1(params) {
  let url = 'v1/invoice/update';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token'),
      })
          .then((result) => {
              resolve(result.data.data)
          })
          .catch((error) => {
              reject(error);
          });
  })
}

export function addPricing(params){
  const url = 'pricing/add';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function removePricing(params){
  const url = 'pricing/remove';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function getLoadBillingInfo(params){
  let url = 'load/billing-info';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
        'authorization': getStorage('token')
    })
    .then((result) => {
        if (result)
          resolve(result.data.data)
    })
    .catch((error) => {
        reject(error);
    });
  });
}

export function updatePricing(params){
  const url = 'pricing/update';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function conflictedCharges(params){
  const url = 'pricing/conflict';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function approveStatus(params){
  const url = 'status/approve';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function unapproveStatus(params){
  const url = 'status/unapprove';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function rebillingStatus(params){
  const url = 'status/rebilling';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function billingStatus(params){
  const url = 'status/billing';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function updateBillingDate(params){
  const url = 'update/billing-date';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function addNewSubBill(params){
  const url = 'sub-bill/add';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function changeCustomerSubBill(params){
  const url = 'sub-bill/change-customer';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function addNewPricing(params){
  const url = 'sub-bill/pricing/add';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function removeSubBill(params){
  const url = 'sub-bill/remove';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function changeSubBillingDate(params){
  const url = 'sub-bill/change-billing-date';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function updateSubBillPricing(params){
  const url = 'sub-bill/pricing/update-one';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function removeSubPricing(params){
  const url = 'sub-bill/pricing/remove';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function updateManyPricing(params){
  const url = 'sub-bill/pricing/update';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function approveInvoice(params){
  const url = 'sub-bill/approve';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function unApproveInvoice(params){
  const url = 'sub-bill/unapprove';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function rebillInvoice(params){
  const url = 'sub-bill/rebill';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function invoiceSubBill(params){
  const url = 'sub-bill/invoice';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function addExpense(params){
  const url = 'expense/add';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function updateExpense(params){
  const url = 'expense/update';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function removeExpense(params){
  const url = 'expense/remove';
  return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function uploadDocumentTracking(params){
  const url = 'document/upload';
  let fullUrl = `${config.docUrl}driver/uploadProofOfDelivery`;
  return new Promise((resolve, reject) => {
      HTTP('put', "", params, {'authorization': getStorage('token')}, fullUrl)
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}

export function uploadDocumentForLoad(params){
  const url = 'tms/uploadDocumentForLoad';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {'authorization': getStorage('token')})
      .then((result) => {
          if (result)
            resolve(result?.data?.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}