import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  IconDownload,
  IconPlus,
  IconPrinter,
  IconTrash,
} from "../../../Components/Common/Icons";
import configuration from "../../../config";
import { fileNameTypeFromUrl, getStorage, isMilitaryTime, toastr } from "../../../services/Common.services";
import { TMS_GET_USER_LOAD } from "../constant";
import { addPaymentTMSLoad, removePaymentTMSLoad } from "../services";
import PaymentForm from "./PaymentForm";
import $ from 'jquery'


import { LoaderBar } from "../../../Components/Common/LoaderBar";
const firebase = configuration.firebase;
let timeZone = getStorage("timeZone");

const ViewFile = (props) => {
  let extension;
  let fileName = props.document;
  if (fileName) {
    extension = fileName.split(".").pop();
    if (extension == "pdf") {
      pdfjsLib.getDocument(fileName).then((pdf) => {
        pdf.getPage(1).then((page) => {
          var scale = 1.5;
          var viewport = page.getViewport(scale);
          // Prepare canvas using PDF page dimensions.
          var canvas = document.getElementById("the-canvas-preview");
          var context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          // Render PDF page into canvas context.
          var renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
        });
      });
    }
  }

  return (
    <div
      id="myModal"
      className="modal fade show"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-modal="true"
      style={{ paddingRight: "17px", display: "block" }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">
              Document
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.handleHide()}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="perview_screen">
              {extension && extension != "pdf" && <img src={props.document} />}
              {extension && extension == "pdf" && (
                <canvas
                  id="the-canvas-preview"
                  style={{ width: 500, height: 500 }}
                ></canvas>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-close"
              data-dismiss="modal"
              onClick={() => props.handleHide()}
            >
              Close
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
};
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDate: new Date(),
      selectedLoads: null,
      documentType: null,
      pdfPreviewUrl: null,
      imagePreviewUrl: null,
      selectedDocuments: {},
      allCustomers: props.customers,
      tab: 1,
      showAddPaymentModal: false,
      selectedData: [],
      loader: false,
      isSaving: false,
      isLoadingBarShow: false,
    };
    this.userTimeFormat = isMilitaryTime();
  }

  updateInput = (event) => {
    this.setState({ documentType: event.target.value });
  };

  componentDidMount() {
    if (getStorage("loggedInUser") != null) {
      const currentUser = JSON.parse(getStorage("loggedInUser"));
      let carrierId = currentUser._id;
      if (currentUser.role === "driver") {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === "fleetmanager") {
        carrierId = currentUser.fleetManager.carrier;
      }
      let reference_number;
      if (this.props.selectedLoads) {
        reference_number = this.props.selectedLoads.reference_number;
      } else {
        reference_number = this.props.loads.reference_number;
      }
      this.userPresense = firebase
        .database()
        .ref(
          `${carrierId}/presense/${reference_number}/payment/${currentUser._id}`
        );

      if (this.userPresense) {
        this.userPresense.set(true);
      }
    }
  }
  componentWillUnmount() {
    if (this.userPresense) {
      this.userPresense.set(false);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.customers && this.setState({ allCustomers: props.customers });
  }

  savepayment = (paymentData) => {
    return new Promise((resolve, reject) => {
      if (this.props.selectedLoads || this.props.loads) {
        if (this.state.documentFile && !this.state.documentType) {
          toastr.show("Please select a document type.", "error");
          return false;
        }

        if (this.state.tab === 2 && !paymentData.additionalPricingId) {
          toastr.show("Please select a sub reference number.", "error");
          return false;
        }

        if (!paymentData.paymentDate) {
          toastr.show("Please enter a payment date.", "error");
          return false;
        }

        // if (this.props.loads && ['BILLING', 'FULL_PAID', 'PARTIAL_PAID'].indexOf(this.props.loads.status) == -1) {
        //   toastr.show('You must update the status to billing before you can add a payment.', 'warning');
        //   return false;
        // }
        // if (this.props.selectedLoads && ['BILLING', 'FULL_PAID', 'PARTIAL_PAID'].indexOf(this.props.selectedLoads.status) == -1) {
        //   toastr.show('You must update the status to billing before you can add a payment.', 'warning');
        //   return false;
        // }
        this.setState({ isSaving: true });
        if (paymentData.amount) {
          const formData = new FormData();
          if (this.props.loads) {
            formData.append(
              "reference_number",
              this.props.loads.reference_number
            );
          } else {
            formData.append(
              "reference_number",
              this.props.selectedLoads.reference_number
            );
          }
          if (paymentData.DepositToAccountRef) {
            formData.append(
              "DepositToAccountRef",
              paymentData.DepositToAccountRef
            );
          }
          formData.append("amount", Number(paymentData.amount));
          if (this.state.documentFile && this.state.documentType) {
            formData.append("document", this.state.documentFile);
            formData.append("filename", `${new Date().getTime().toString()}`);
            formData.append("documentType", this.state.documentType);
          }
          if (paymentData.notes) formData.append("notes", paymentData.notes);
          if (paymentData.checkNo)
            formData.append("checkNo", paymentData.checkNo);
          if (this.state.tab === 2)
            formData.append(
              "additionalPricingId",
              paymentData.additionalPricingId
            );

          formData.append("paymentDate", paymentData.paymentDate);
          if (paymentData.customerId) {
            formData.append("customerId", paymentData.customerId);
          }
          formData.append("timeZone", timeZone);
          this.setState({ isSaving: true });
          addPaymentTMSLoad(formData)
            .then((da) => {
              toastr.show("You have added a payment!", "success");
              this.setState({ isSaving: false });
              resolve();
              this.setState({
                amount: "",
                notes: "",
                paymentDate: new Date(),
                customerId: "",
                documentFile: null,
                documentType: null,
              });
              if (this.props.updateList) {
                this.props.updateList(da.data.data);
              } else {
                this.props.dispatch({
                  type: TMS_GET_USER_LOAD,
                  reference_number: this.props.selectedLoads
                    ? this.props.selectedLoads.reference_number
                    : this.props.loads.reference_number,
                  payload: {
                    skip: this.state.activePage - 1,
                    limit: this.state.limit,
                  },
                });
              }
              this.setState({
                showAddPaymentModal: false,
                loader: false,
                isSaving: false,
              });
            })
            .catch(() => {
              this.setState({
                loader: false,
                isSaving: false,
              });
            });
        } else {
          this.setState({ isSaving: false });
          toastr.show("Please enter an amount.", "error");
        }
      }
    });
  };

  handleShowAddPaymentModal = () => {
    this.setState({ showAddPaymentModal: true });
  };
  handleHidePaymentModal = () => {
    this.setState({ showAddPaymentModal: false });
  };

  selectDocumentType = (value) => {
    this.setState({ documentType: value });
  };
  removeDocument = () => {
    this.setState({ documentFile: null });
  };
  // Return  sub ref number for each payments
  getSubRefNo = (data, isCustomer) => {
    let val = "";
    if (
      data.additionalPricingId &&
      this.props.selectedLoads &&
      this.props.loads.additionalPricing.length
    ) {
      let find = this.props.loads.additionalPricing.find(
        (item) => (item._id).toString() === data.additionalPricingId
      );
      if (find && find.sub_reference_number && !isCustomer) {
        val = find.sub_reference_number;
      }
      if (find && find.customerId && isCustomer) {
        val = find.customerId.company_name;
      }
    }
    return val;
  };
  selectAll = (e) => {
    let check = e.target.checked;
    const data = this.props.loads || this.props.selectedLoads;
    if (check) {
      this.setState({ selectedData: data.paymentHistory.map((d) => d._id) });
    } else {
      this.setState({ selectedData: [] });
    }
  };
  checkHandler = (e, id) => {
    let check = e.target.checked;
    if (check) {
      if (!this.state.selectedData.includes(id)) {
        let dummyData = this.state.selectedData;
        dummyData.push(id);
        this.setState({ selectedData: dummyData });
      }
    } else {
      const newData = this.state.selectedData.filter((d) => d !== id);
      this.setState({ selectedData: newData });
    }
  };
  renderPaymentHistory = (paymentHistory, colspan) => {
    return (
      <div className="table mb-20">
        <table className="table table-card table-card--wrap mb-10">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Note</th>
              <th>Check</th>
              <th>File Name</th>
              <th>Doc Type</th>
              <th width="150px">Date & Time</th>
              {this.state.tab != 1 && (
                <Fragment>
                  <th>Sub Customer</th>
                  <th>Sub Customer Ref No</th>
                </Fragment>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory && paymentHistory.length > 0 ? (
              paymentHistory.map((data, index) => {
                const [fileName, fileType] = fileNameTypeFromUrl(data && data.document)
                return (
                  <tr key={index}>
                    <td>${data.amount && data.amount.toFixed(2)}</td>
                    <td className="text-break">{data.notes}</td>
                    <td>{data.checkNo}</td>
                    <td>
                      {fileName}
                    </td>
                    <td>
                      <span className="badge badge-light">
                        {data.documentType}
                      </span>
                    </td>
                    <td>
                      <React.Fragment>
                        <div>
                          {moment(data.paymentDate)
                            .tz(getStorage("timeZone"))
                            .format("MM/DD/YY")}
                        </div>
                        <div className="text-muted">
                          {moment(data.paymentDate)
                            .tz(getStorage("timeZone"))
                            .format(`${this.userTimeFormat ? "HH:mm" : "hh:mm a" }`)}
                        </div>
                      </React.Fragment>

                    </td>
                    {this.state.tab != 1 && (
                      <Fragment>
                        <td> {this.getSubRefNo(data, true)}</td>
                        <td>{this.getSubRefNo(data)}</td>
                      </Fragment>
                    )}
                    <td className="text-right" width="50px">
                      <div className="d-flex justify-content-end ">
                        {data.document && (
                          <React.Fragment>
                          <button
                            className="btn btn-link text-dark"
                            onClick={() => {
                              this.downloadDoc(data.document);
                            }}
                          >
                            <IconDownload />
                          </button>
                          <button
                              className="btn btn-link text-dark"
                              onClick={() => {
                                this.handlePrint(data.document);
                              }}
                            >
                              <IconPrinter />
                            </button>
                            </React.Fragment>
                        )}
                        <button
                          className="btn btn-link"
                          onClick={() => this.removePayment(data)}
                        >
                          <IconTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  uploadResult = (file, preview, type) => {
    // data.size * 0.0009765625
    if (file.type.indexOf("image") == -1) {
      type = file.type.split("/")[1];
    }
    this.setState(
      {
        documentFile: null,
        [`${type}PreviewUrl`]: null,
        pdfPreviewUrl: null,
        [type]: null,
      },
      () => {
        this.setState({
          documentFile: file,
          [`${type}PreviewUrl`]: preview,
          [type]: preview,
        });
      }
    );

  };

  handleHide = () => {
    this.setState({ showModal: false });
  };

  viewDocument = (data) => {
    if (!data.document) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.document;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.document },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.document, pdfPreviewUrl: null });
      }
    }
  };

  handlePrint = (url) => {
    window.open(url, "PRINT", "_blank");
  };

  downloadDoc = (url) => {
    if (url) {
      let fileName = url.split("/tms");
      let file = fileName[1].replace("/", "");
      $.ajax({
        url: url,
        xhrFields: {
          responseType: 'blob'
        },
        success: function (blob) {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = file;
          link.click();
        }
      })
    }

  }

  removePayment = (data) => {
    this.setState({ isLoadingBarShow: true });
    const formData = new FormData();
    if (this.props.loads) {
      formData.append("reference_number", this.props.loads.reference_number);
    } else {
      formData.append(
        "reference_number",
        this.props.selectedLoads.reference_number
      );
    }
    formData.append("paymentId", data._id);
    removePaymentTMSLoad(formData).then((da) => {
      this.setState({ isLoadingBarShow: false });
      toastr.show("You have removed this payment.", "success");
      if (this.props.updateList) {
        this.props.updateList(da.data.data);
      } else {
        this.props.dispatch({
          type: TMS_GET_USER_LOAD,
          reference_number: this.props.selectedLoads
            ? this.props.selectedLoads.reference_number
            : this.props.loads.reference_number,
          payload: {
            skip: this.state.activePage - 1,
            limit: this.state.limit,
          },
        });
      }
    });
  };
  renderReturn = () => {
    var selectedLoads =
      this.props.selectedLoads && this.props.selectedLoads.length == 0
        ? undefined
        : this.props.selectedLoads;
    if (this.props.loads) {
      selectedLoads = this.props.loads;
    }
    let filteredPayment = [];
    if (
      this.state.tab == 1 &&
      selectedLoads &&
      selectedLoads.paymentHistory &&
      selectedLoads.paymentHistory.length > 0
    ) {
      filteredPayment = selectedLoads.paymentHistory.filter((payment) => {
        return (
          !payment.customerId ||
          payment.customerId == "undefined" ||
          payment.customerId == this.state.customerId
        );
      });
    }
    if (
      this.state.tab == 2 &&
      selectedLoads &&
      selectedLoads.paymentHistory &&
      selectedLoads.paymentHistory.length > 0
    ) {
      filteredPayment = selectedLoads.paymentHistory.filter((payment) => {
        return (
          payment.customerId && payment.customerId != this.state.customerId
        );
      });
    }

    const renderModal = () => {
      return (
        <PaymentForm
          handleHidePaymentModal={this.handleHidePaymentModal}
          uploadResult={this.uploadResult}
          docuementType={this.state.docuementType}
          setDocumentType={this.selectDocumentType}
          removeDocument={this.removeDocument}
          documentFile={this.state.documentFile}
          load={selectedLoads}
          type={this.props.type}
          showAddPaymentModal={this.state.showAddPaymentModal}
          allCustomers={this.props.customers}
          tab={this.state.tab}
          savepayment={this.savepayment}
          loader={this.state.loader}
          isSaving={this.state.isSaving}
        />
      );
    };
    return (
      <React.Fragment>
        <div className="card p-15 mb-10 d-flex flex-row align-items-center justify-content-between">
          <ul className="nav nav-compact" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link ${this.state.tab == 1 && "active"}`}
                onClick={() => this.setState({ tab: 1 })}
                role="tab"
              >
                Main Customer
              </a>
            </li>
            {getStorage("currentUserRole") != "customer" && (
              <li className="nav-item">
                <a
                  className={`nav-link ${this.state.tab == 2 && "active"}`}
                  onClick={() => this.setState({ tab: 2 })}
                  role="tab"
                >
                  Sub Customer
                </a>
              </li>
            )}
          </ul>
          <button
            className="ml-auto btn btn-primary"
            onClick={this.handleShowAddPaymentModal}
          >
            <IconPlus className="mr-2" />
            Add Payment
          </button>
        </div>

        {this.state.tab == 1 && !this.props.type && (
          <React.Fragment>
            {/* <PaymentForm allCustomers={this.props.customers} tab={this.state.tab} savepayment={this.savepayment}></PaymentForm> */}
            {this.renderPaymentHistory(filteredPayment, 8)}
          </React.Fragment>
        )}

        {this.state.tab == 2 && !this.props.type && (
          <React.Fragment>
            {/* <PaymentForm allCustomers={this.props.customers} tab={this.state.tab} savepayment={this.savepayment} load={selectedLoads}></PaymentForm> */}
            {this.state.allCustomers && this.state.allCustomers.length > 0 && (
              <React.Fragment>{this.renderPaymentHistory(filteredPayment, 10)}</React.Fragment>
            )}
          </React.Fragment>
        )}

        {this.state.showAddPaymentModal && renderModal()}
      </React.Fragment>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_payments") ||
          (loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer &&
            loggedInUser.permissions.includes("customer_employee_load_payments"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapStateToProps(state) {
  return {
    selectedLoads: state.TmsReducer.selectedLoads,
    customers: state.TmsReducer.customers,
  };
}

export default connect(mapStateToProps, null)(Payment);
