const initialState = {
    chargeCode: [],
  }
export default function chargeCodeReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CHARGE_CODE':
        return {
            ...state,
            chargeCode: action.payload
        }
        default: return { ...state }
    }
};