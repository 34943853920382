import React, { Component, Fragment } from "react";

import * as Yup from "yup";
import $ from "jquery";
import _ from "lodash";
import Select from "react-select";
import { smallSelectStyle } from "../../../../assets/js/select-style";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import { bindActionCreators } from "redux";
import ReactTooltip from "react-tooltip";
import configuration from "../../../../config";
import { TMS_GET_CUSTOMERS_ALL } from "../../constant";
import * as actionCreators from "../actionCreators";
import { AddProfile, getTerminal } from "../../services";
import AddCustomerComponent from "../AddCustomerComponent";
import { getCustomers, getSubCustomer } from "../../Dispatcher/actionCreators";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import { Spinner } from "../../../../Components/Common/Components/Component";
import UpgradeToPro from '../../../../Components/Common/UpgradeToPro';
import { assignCSRtoCustomer } from "../actionCreators";
import {
    deletTmsCustomer,
    connectWithQuickBook,
    getStorage,
    disconnectQuickBooks,
    toastr,
    isTrialUser,
} from "../../../../services/Common.services";
import Papa from "papaparse";
import "../style.css";
import confirm from "../../../../Components/Common/ConfirmAert";
import {
    
    IconPlus,
    IconAngleArrowRight,
    IconQuickBook,
    IconAngleArrowDown,
    IconCheckCircle,
    IconPen,
    IconTrash,
    IconDownload,
} from "../../../../Components/Common/Icons";
import config from "../../../../config";
import SubHeaderTab from "../../../../Components/SubHeaderTab";
import { dissolve } from "@turf/turf";
import DataGrid, { Row } from "react-data-grid";
import { isAtBottom } from "../../NewDispatcher/DispatcherTable/Functions";
import NewCustomerModal from "../NewCustomer/NewCustomerModal/NewCustomerModal";
import { browserHistory } from "react-router";
import { TabLists } from "./utility";

const firebase = config.firebase;
const sortingOptions = {
    "Company Name": "company_name",
    Phone: "mobile",
    "Main Contact Name": "main_contact_name",
    Address: "address.address",
    City: "city",
    State: "state",
    "Zip Code": "zip_code"
};

const cardOptions = [
    { label: "Customers", value: ["caller", "ALL"], countKey: "customer" },
    {
        label: "Shipper/PORT",
        value: ["shipper", "containerReturn", "ALL"],
        countKey: "shipper",
    },
    { label: "Consignees", value: ["consignee", "ALL"], countKey: "consignees" },
    {
        label: "Chassis",
        value: ["chassisTermination", "chassisPick", "ALL"],
        countKey: "chassis",
    },
];

const sortableFields = [
    { name: 'Company Name', key: "company_name", field: 'company_name' },
    { name: 'Phone', key: "mobile", field: 'mobile' },
    { name: 'Main Contact Name', key: "main_contact_name", field: 'main_contact_name' },
    { name: 'City', key: "city", field: 'city' },
    { name: 'State', key: "state", field: 'state' },
    { name: 'Zip Code', key: "zip_code", field: 'zip_code' },
]

let filterOnHoldOptions = [{ value: 'All', label: 'All' }, { value: 'On Hold', label: 'On Hold' }, { value: 'Not On Hold', label: 'Not On Hold' }]
let Selected_customer = {
    companyID: 0,
    company_name: "",
    address: {},
    lat: "",
    lng: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    main_contact_name: "",
    secondary_contact_name: "",
    mobile: "",
    email: "",
    billingEmail: "",
    password: "",
    credit_limit: "",
    payment_terms: "",
    account_hold: false,
    qbEmail: "",
    documents: [],
    geofence: {
        lng: undefined,
        lat: undefined,
        zoom: undefined,
        polygonData: [],
    },
    fleetCustomer: "",
    secondaryPhoneNo: "",
    officeHoursStart: "",
    officeHoursEnd: "",
    currency: "",
};

const EmptyRowsRenderer = () => {
    return (
        <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
            <div style={{ padding: "20px 10px", backgroundColor: 'white', fontSize: "12px" }}>
                No Result
            </div>
        </div>
    );
}
const removeExtraFields = ["isEdi214EmptyDate", "isEdi214LastFreeDate", "isEdi214HookContainer", "isEdi214DropContainer", "isEdi214PullContainer", "isEdi214DeliverLoad"]
const carrierDetail = JSON.parse(getStorage("carrierDetail"));
class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            customers: [],
            Selected_customer: Selected_customer,
            addCustomer: false,
            qbRoute: "",
            isConnected: false,
            isQbOnline: false,
            documents: [],
            tab: 1,
            showAddModal: false,
            Terminals: [],
            fitlerDeletedEnabled: "Active",
            isActive: false,
            resetId: undefined,
            counts: {
                total: 0,
                consignees: 0,
                customer: 0,
                shippers: 0,
            },
            searchTerm: "",
            sortBy: { company_name: 1 },
            selectedCard: null,
            choosenTerminals: [],
            upgradeTopro: false,
            isSaving: false,
            filterOnHold: 'All',
            assignedCSR: [],
            AllCSRList: [],
            showAllCSRList: false,
            csrSearchTerm: "",
            isloading: false,
            state: [],
            city: [],
            country: [],
            totalCustomers: 0,
            skipCount: 0,
            skip: 0,
            sortColumns: [],
            columns: [],
            allCustomers: [],
            isLoading: false,
            loadsPerReq: 20,
        };
        this.remountKey = 0;
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    
    getColumns() {
        const columns = [
            {
                key: "edit",
                name: "",
                width: 80,
                cellClass: "cell d-flex justify-content-center",
                formatter: ({ row }) => {
                    return !row.isDeleted && (
                        <button
                            type="button"
                            className="btn btn-none text-muted"
                            onClick={() => this.selectCustomer(row)}
                        >
                            <IconPen />
                        </button>
                    )
                },
            },
            {
                key: "delete",
                name: "",
                width: 80,
                cellClass: "cell d-flex justify-content-center",
                formatter: ({ row }) => {
                    return row.isDeleted ? (
                        <button
                            type="button"
                            className="btn btn-none text-muted"
                            onClick={() => this.removeCustomer(row)}
                        >
                            <IconCheckCircle />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-none text-muted"
                            onClick={() => this.removeCustomer(row)}
                        >
                            <IconTrash />
                        </button>
                    )
                },
            },
            {
                key: "company_name",
                name: "Company Name",
                sortable: true,
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">
                        {row.company_name}
                        {row.account_hold && (
                            <span class="badge badge-status-dark-grey badge-sm text-white ml-2">On Hold</span>
                        )}
                    </div>
                },
            },
            {
                key: "mobile",
                name: "Phone",
                resizable: true,
                sortable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">{row.mobile}</div>
                },
            },
            {
                key: "main_contact_name",
                name: "Main Contact Name",
                sortable: true,
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">{row.main_contact_name}</div>
                },
            },
            {
                key: "address1",
                name: "Address1",
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">{row.address1}</div>
                },
            },
            {
                key: "city",
                name: "City",
                sortable: true,
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">{row.city}</div>
                },
            },
            {
                key: "state",
                name: "State",
                sortable: true,
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">{row.state}</div>
                },
            },
            {
                key: "zip_code",
                name: "Zip Code",
                sortable: true,
                resizable: true,
                formatter: ({ row }) => {
                    return <div class="font-12">{row.zip_code}</div>
                },
            },

        ]
        this.setState({ columns })
    }

    getCustomersLimit = (payload) => {
        this.props.dispatch({
            type: TMS_GET_CUSTOMERS_ALL,
            payload,
        });
    };

    disconnectQuickBooks = () => {
        disconnectQuickBooks()
            .then((response) => {
                this.setState({ isConnected: false });
            })
            .catch((err) => { });
    };

    connectQuickBook = () => {
        connectWithQuickBook().then((response) => {
            if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.connected
            ) {
                this.setState({
                    isConnected: true,
                    qbRoute: response.data.data.redirecturl,
                });
            } else {
                if (
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.error &&
                    response.data.data.error.detail) {
                    toastr.show(response.data.data.error.detail, "error");
                }
                this.setState({
                    qbRoute: response && response.data && response.data.data && response.data.data.redirecturl,
                });
            }
        });
    };
    handleInput = (e) => {
        // this.setState({ searchTerm: e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""), activePage: 1 }, () => {
        //   this.delayedQuery();
        // })
        this.setState({ searchTerm: e.target.value, activePage: 1 }, () => {
            this.delayedQuery();
        });
    };
    delayedQuery = _.debounce(() => {
        this.setState({ skip: 0 }, () => {
            this.getcustomerData();
        })
    }, 500);

    getCounts(data) {
        this.props.actions
            .getTMSCustomerCount(data)
            .then((res) => this.setState({ counts: res, totalCustomers: res.total }))
            .catch((err) => console.log(err));
    }

    removeCustomer = async (customer) => {
        const response = await getSubCustomer({ subCustomerId: customer._id });
        if (response.isUsed) {
            toastr.show("This customer is already used in subcustomer.", "warning");
            return;
        }

        if (customer && customer._id) {
            confirm(
                `${customer.isDeleted ? "Activate Customer?" : "Delete Customer?"}`,
                `Are you sure ${customer.isDeleted
                    ? "you want to activate this customer?"
                    : "you want to delete this customer?"
                }`,
                (confirm) => {
                    if (confirm) {
                        deletTmsCustomer({
                            _id: customer._id,
                            isDeleted: !customer.isDeleted,
                        }).then((result) => {
                            if (result && result.data && result.data.data) {
                                let { allCustomers } = this.state;
                                allCustomers.forEach((obj, i) => {
                                    if (obj._id === customer._id) {
                                        allCustomers[i].isDeleted = result.data.data.success;
                                    }
                                })
                                this.setState({ allCustomers })
                            }
                            toastr.show(
                                `Successfully ${customer.isDeleted ? "Enabled" : "Deleted"}`,
                                "success"
                            );
                            this.setState({ skip: 0 }, () => {
                                this.getcustomerData()
                            })
                        });
                    }
                }
            );
        }
    };
    
    handleCompleteDownload = () => {
        // debugger
        const { selectedCard, counts, isActive } = this.state;
        let csvalues = [];
        let params = { isDeleted: isActive };

        if (selectedCard) {
            params = {
                customerType: JSON.stringify(selectedCard.value),
            };
        }

        getCustomers(params, counts.total).then((result) => {
            if (result && result.length > 0) {
                csvalues = result.map((value) => {
                    let csvVal = {};
                    csvVal["COMPANY NAME"] = value.company_name ? value.company_name : "";
                    csvVal["PHONE"] = value.mobile ? value.mobile : "";
                    csvVal["MAIN CONTACT NAME"] = value.main_contact_name
                        ? value.main_contact_name
                        : "";
                    csvVal["ADDRESS"] = value.address
                        ? value.address.address
                            ? value.address.address.split(",")[0]
                            : ""
                        : "";
                    csvVal["CITY"] = value.city ? value.city : "";
                    csvVal["STATE"] = value.state ? value.state : "";
                    csvVal["ZIP CODE"] = value.zip_code ? value.zip_code : "";
                    return csvVal;
                });

                var csv = Papa.unparse(csvalues);
                this.onDownloadClick(csv);
            }
        });
    };
    onDownloadClick = (csv) => {
        toastr.show("Please wait while downloading.", "success");
        var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `${new Date().getTime()}.csv`);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `${new Date().getTime()}.csv`);
        tempLink.click();
    };
    getcustomerData(appendCustomers) {
        let payload = {
            limit: this.state.loadsPerReq,
            skip: this.state.skip,
        };
        let countPayload = {};

        if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
            payload.choosenTerminals = JSON.stringify(
                this.state.choosenTerminals.map((Data) => {
                    return Data.value;
                })
            );
        }

        if (this.state.fitlerDeletedEnabled === "Inactive") {
            payload.isDeleted = true;

            countPayload.isDeleted = true;
        }
        if (this.state.filterOnHold === 'On Hold') {
            payload.account_hold = true
            countPayload.account_hold = true
        }

        if (this.state.filterOnHold === 'Not On Hold') {
            payload.account_hold = false
            countPayload.account_hold = false
        }
        if (
            payload.choosenTerminals &&
            this.state.choosenTerminals &&
            this.state.choosenTerminals.length > 0
        ) {
            countPayload.choosenTerminals = payload.choosenTerminals;
        }
        if (this.state.searchTerm) {
            payload.searchTerm = this.state.searchTerm;
            countPayload.searchTerm = this.state.searchTerm;
        }
        if (this.state.sortColumns.length > 0) {
            const col = this.state.sortColumns[0].columnKey;
            const direction = this.state.sortColumns[0].direction;
            let sortOrder = direction === "ASC" ? 1 : -1;
            const sortableField = sortableFields.find(el => el.key === col);
            if (sortableField) {
                const sortField = { [sortableField.field]: sortOrder };
                payload.sortBy = JSON.stringify(sortField);
            }
        }
        if (this.state.selectedCard) {
            payload.customerType = JSON.stringify(this.state.selectedCard.value);
            countPayload.customerType = JSON.stringify(this.state.selectedCard.value);
        }

        this.getCounts(countPayload);
        this.setState({ isLoading: true })
        this.props.actions.getAllCustomers(payload).then((res) => {
            let allCustomers = res
            if (appendCustomers) {
                allCustomers = [...this.state.allCustomers, ...res];
            } else {
                this.remountKey = this.remountKey + 1;
            }
            this.setState({ allCustomers, isLoading: false }, () => { this.handleDefaultScroll() })
        })
    }

    updateCustomer = (obj) => {
        if(obj._id === this.state.Selected_customer._id){
            this.setState({Selected_customer : obj})  
        }

        var allCustomers = _.cloneDeep(this.state.allCustomers)
        let Index = allCustomers.findIndex((id)=> id._id === obj._id)
        allCustomers[Index] = obj
        this.setState({allCustomers})
    }

    componentDidMount() {
        this.getColumns();
        const querySearch = _.get(this.props, "location.query.id");
        this.openSearchedCustomer(querySearch);
        const loggedUser = getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"))

        if (getStorage("currentUserRole") !== "customer") {
            if (loggedUser && loggedUser.role === "carrier" &&
                !!loggedUser.agentToken &&
                !!loggedUser.endpoint) {
                this.setState({ isQbOnline: false })
            } else {
                this.connectQuickBook();
                this.setState({ isQbOnline: true })
            }

        }

        this.getcustomerData();
        this.listAllCSR();
        document.addEventListener("mousedown", this.handleClickOutside);


        if (
            (getStorage("currentUserRole") === "fleetmanager" &&
                configuration.idForTerminal.indexOf(
                    JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
                ) !== -1) ||
            (getStorage("currentUserRole") === "carrier" &&
                configuration.idForTerminal.indexOf(getStorage("currentUserID")) !== -1)
        ) {
            getTerminal().then((response) => {
                const Terminals = response.data.data ? response.data.data : [];
                this.setState({ Terminals });
            });
        }
        if (getStorage("loggedInUser") != null) {
            const currentUser = JSON.parse(getStorage("loggedInUser"));
            let carrierId = currentUser._id;

            if (currentUser.role === "driver") {
                carrierId = currentUser.driver.carrier;
            }
            if (currentUser.role === "fleetmanager") {
                carrierId = currentUser.fleetManager.carrier;
            }
            this.socketCustomerDataRef = firebase
                .database()
                .ref(`${carrierId}/updateCustomer`);

            this.socketCustomerDataRef.on("value", (snapshot) => {
                const customerDetails = JSON.parse(snapshot.val());

                if (customerDetails) {
                    const customerId = customerDetails.customerId;
                    const actionType = customerDetails.type;

                    if (actionType === "update") {
                        this.props.actions
                            .getTMSCustomerById({ customerId })
                            .then(() => {
                            });
                    }
                }
            });
        }
        let preActiveTab = this.props?.location?.pathname.split("/").pop()
        let activeTab = TabLists.find((el=>el.value === preActiveTab))
        this.setState({tab:activeTab.id},()=>{
            if(this.props?.location.query?.id) {
                browserHistory.push(
                    "/tms/customer/" +
                    activeTab.value +
                      "?id=" +
                      encodeURIComponent(
                        this.props?.location?.query?.id
                      )
                  );
            }
        })
    }


    componentWillReceiveProps(props1) {
        let Customer = props1.customers;
        if (Customer && Customer.length > 0) {
            this.setState({ customers: [...Customer] }, () => { });
        }
        const newId = _.get(props1, "location.query.id");
        const oldId = _.get(this.props, "location.query.id");
        if (newId && newId !== oldId) {
            this.openSearchedCustomer(newId);
        }

    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        this.setState({ assignedCSR: [] })
    }

    openSearchedCustomer(querySearch) {
        if (querySearch) {
            const data = {
                allIds: JSON.stringify([querySearch]),
            };
            actionCreators
                .getCustomersByIDS(data)
                .then((customers) => {
                    this.selectCustomer(customers[0]);
                })
                .catch((error) => {
                });
        }
    }

    resetform() {
        this.setState({ Selected_customer });
    }

    camelize(str) {
        return str.replace(/\W+(.)/g, function (match, chr) {
            return chr.toUpperCase();
        });
    }

    closeModal() {
        this.setState({ addCustomer: false, tab: 1, showAddModal: false });
    }

    updateState(data) {
        this.setState(data);
    }

    submitForm = (data) => {

        let self = this;
        let customerProfileData = {};
        return new Promise((resolve, reject) => {
            if (data) {
                if (data.documentFile && !data.fileType) {
                    toastr.show("You must choose a type.", "warning");
                    this.setState({ tab: 2 });
                    reject('Please choose file type');
                    return;
                }

                customerProfileData = typeof data == 'object' && JSON.parse(JSON.stringify(data));
                if (customerProfileData.employeeEmails) {
                    delete customerProfileData.employeeEmails;
                }
                customerProfileData.address = {
                    address: customerProfileData.address,
                    lat: customerProfileData.lat ? customerProfileData.lat : 12,
                    lng: customerProfileData.lng ? customerProfileData.lng : 12,
                    address1: customerProfileData.address1,
                    city: customerProfileData.city,
                    state: customerProfileData.state,
                    country: customerProfileData.country,
                    zip_code: customerProfileData.zip_code
                };
                customerProfileData.companyID =
                    this.camelize(customerProfileData.company_name) +
                    Math.floor(Math.random() * 10000 + 1);
                delete customerProfileData.chassisTerminationUsageCount;
                delete customerProfileData.emptyOriginUsageCount;
                delete customerProfileData.chassisPickUsageCount;
                delete customerProfileData.consigneeUsageCount;
                delete customerProfileData.shipperUsageCount;
                delete customerProfileData.callerUsageCount;
                delete customerProfileData.credit_hold;

                delete customerProfileData.lat;
                delete customerProfileData.lng;
                if (customerProfileData.createdAt) {
                    delete customerProfileData.createdAt;
                }
                if (customerProfileData.updatedAt) {
                    delete customerProfileData.updatedAt;
                }
                delete customerProfileData.__v;
                delete customerProfileData.carrier;
                delete customerProfileData.SyncToken;
                delete customerProfileData.qbId;
                delete customerProfileData.type;
                delete customerProfileData.usageCount;
                delete customerProfileData.pdfMap;
                delete customerProfileData.invalidCount;
                delete customerProfileData.isConnected;
                delete customerProfileData.isFailed;
                if (customerProfileData.mobile) {
                    customerProfileData.mobile = customerProfileData.mobile.replace(
                        /-|\s/g,
                        ""
                    );
                }
                if (!customerProfileData._id && !customerProfileData.password) {
                    customerProfileData.password = "123456";
                }

                if (data.documentFile && data.fileType) {
                    customerProfileData.document = data.documentFile;
                    customerProfileData.fileType = data.fileType;
                    customerProfileData.filename = `${new Date().getTime().toString()}`;
                    delete data.documentFile;
                    delete customerProfileData.documentFile;
                }
                if (!customerProfileData.notes || customerProfileData.notes === "") {
                    delete customerProfileData.notes;
                }
                if (
                    !customerProfileData.emailStatuses ||
                    customerProfileData.emailStatuses === ""
                ) {
                    delete customerProfileData.emailStatuses;
                }
                Object.keys(customerProfileData).forEach((key) => {
                    if (customerProfileData[key] === null || removeExtraFields.includes(key)) {
                        delete customerProfileData[key];
                    }
                });

                if (!customerProfileData.officeHoursStart) {
                    customerProfileData.officeHoursStart = "";
                }
                if (!customerProfileData.officeHoursEnd) {
                    customerProfileData.officeHoursEnd = "";
                }
                if (customerProfileData.salesAgents) {
                    customerProfileData.salesAgents = JSON.stringify(
                        customerProfileData.salesAgents.map((d) => d.value)
                    );
                }
            }
            this.setState({ isSaving: true })
            delete customerProfileData.assignedCSR
            AddProfile("carrier/addTMSCustomer", customerProfileData)
                .then((result) => {
                    if (customerProfileData._id) {
                        this.setState({ isSaving: false })
                        toastr.show("Successfully updated", "success");
                    } else {
                        this.setState({ isSaving: false, showAddModal: false })
                        if (this.props.isQuickAdd) {
                            this.props.falsyData("customer");
                        }
                        toastr.show("Successfully added", "success");
                    }

                    setTimeout(() => {
                        $("#closeModalButton").click();
                        if (result && result.data && result.data.data) {
                            let { allCustomers } = this.state;
                            allCustomers.forEach((obj, i) => {
                                if (obj._id === result.data.data._id) {
                                    allCustomers[i] = result.data.data;
                                }
                            })
                            this.setState({ allCustomers, skip: 0 }, () => this.getcustomerData())
                        }
                    }, 1000);

                    if (
                        result.data &&
                        result.data.data &&
                        result.data.data.documents &&
                        result.data.data.documents.length > 0
                    ) {
                        self.setState({ documents: result.data.data.documents });
                    }

                    resolve();
                })
                .catch((error) => {
                    reject();
                    this.setState({ tab: 1, isSaving: false });
                });

        });
    };

    firstORlast(k) {
        this.props.dispatch({
            type: TMS_GET_CUSTOMERS_ALL,
            payload: {
                skip: 0,
                limit: k,
            },
        });
    }

    selectCustomer = (customer) => {
        if (customer) {
            const Selected_customer = JSON.parse(JSON.stringify(customer));
            Selected_customer["fleetCustomer"] = customer.fleetCustomer
                ? customer.fleetCustomer
                : "";
            Selected_customer["lat"] = customer.address ? customer.address.lat : "";
            Selected_customer["lat"] = customer.address ? customer.address.lat : "";
            Selected_customer["lng"] = customer.address ? customer.address.lng : "";
            Selected_customer["address"] = customer.address
                ? customer.address.address
                : "";
            Selected_customer["newTerminal"] = customer.newTerminal
                ? customer.newTerminal
                : "";
            Selected_customer["isSendNotificationEmail"] =
                customer.isSendNotificationEmail;
            Selected_customer["notificationEmails"] = customer.notificationEmails;
            Selected_customer["extraSubCustomer"] = customer.extraSubCustomer;

            if (customer.newTerminal && customer.newTerminal.length > 0) {
                let newTerminal = [];
                this.state.Terminals.map((Data) => {
                    if (customer.newTerminal.indexOf(Data._id) != -1) {
                        newTerminal.push({
                            value: Data._id,
                            label: Data.name,
                        });
                    }
                });
                Selected_customer["newTerminal"] = newTerminal;
            }
            let geofence = {
                lng: customer?.geofence?.center[0]
                    ? customer?.geofence?.center[0]
                    : undefined,
                lat: customer?.geofence?.center[1]
                    ? customer?.geofence?.center[1]
                    : undefined,
                zoom: customer?.geofence?.zoom ? customer?.geofence?.zoom : undefined,
                polygonData: customer?.geofence?.polygon,
            };
            Selected_customer["geofence"] = geofence;
            if (customer.assignedCSR && customer.assignedCSR.length > 0) {
                const CSR = customer.assignedCSR
                    .map((obj) => ({
                        value: obj._id,
                        label: obj.name + " " + obj.lastName,
                        allInfos: obj,
                    }));


                this.setState({ assignedCSR: CSR });
            } else {
                this.setState({ assignedCSR: [] });
            }
            this.setState({
                Selected_customer,
                addCustomer: true,
                documents: customer.documents ? customer.documents : [],
                tab: !this.state.tab ? 1 : this.state.tab,
            },()=>{
                let activeTab = TabLists.find((el=>el.id === this.state.tab))
                if (this.state.Selected_customer) {
                  browserHistory.push(
                    "/tms/customer/" +
                    activeTab.value +
                      "?id=" +
                      encodeURIComponent(
                        this.state.Selected_customer._id
                      )
                  );
                }
            });

        }
    };

    sortOption(header) {
        let key = sortingOptions[header];
        let order = 1;
        if (this.state.sortBy[sortingOptions[header]])
            order = this.state.sortBy[sortingOptions[header]] * -1;
        this.setState(
            {
                sortBy: {
                    [key]: order,
                },
            },
            () => {
                this.getcustomerData();
            }
        );
    }

    filterStatus(option) {
        this.setState(
            {
                selectedCard:
                    this.state.selectedCard &&
                        this.state.selectedCard.label === option.label
                        ? null
                        : option,
                skip: 0,
                allCustomers: [],
                skipCount: 0
            },
            () => this.getcustomerData()
        );
    }

    setChangePasswordId = (resetId) => {
        this.setState({ resetId });
    };

    setTab = (tab) => {
        this.setState({ tab }, () => {
            let activeTab = TabLists.find((el=>el.id === this.state.tab))
            if (this.state.Selected_customer) {
                browserHistory.push(
                "/tms/customer/" +
                activeTab.value +
                    "?id=" +
                    encodeURIComponent(
                    this.state.Selected_customer._id
                    )
                );
            }
        });

    };
    listAllCSR = (search) => {

        let data = {
            CSR: true,
            active: true
        };
        if (search) {
            data.searchTerm = search
        }
        return new Promise((resolve, reject) => {
            this.props.actions.listAllFleetManagers(data).then((options) => {
                const CSR = [];
                let allDatas = options || [];

                allDatas.forEach((obj) => {
                    CSR.push({
                        value: obj.value,
                        label: obj.allInfos.name + " " + obj.allInfos.lastName,
                        allInfos: obj,
                    });
                });

                this.setState({ AllCSRList: CSR });
                resolve(options);
            });
        });
    }

    assignCSRtoCustomer = (csrList, msg) => {
        const formData = new FormData()
        formData.append('assignedCSR', JSON.stringify(csrList.map(item => item.value)))
        formData.append('customerId', this.state.Selected_customer._id)
        assignCSRtoCustomer(formData)
            .then(result => {
                const CSR = [];
                let allDatas = result.assignedCSR || [];
                allDatas.forEach((obj) => {
                    CSR.push({
                        value: obj._id,
                        label: obj.name + " " + obj.lastName,
                        allInfos: obj,
                    });
                });
                toastr.show(msg, 'success')
                this.setState({ assignedCSR: CSR, isloading: false });
                this.getcustomerData()
            })
            .catch((err) => this.setState({ isloading: false }))
    }
    assignCSR = (csr) => {
        this.setState({ isloading: true })
        let csrList = [...this.state.assignedCSR]
        const existingCSR = csrList.find(item => item.value === csr.value)
        if (!existingCSR) {
            csrList.push(csr)
            this.assignCSRtoCustomer(csrList, `${csr.label} assigned successfully`)
        } else {
            toastr.show(`${csr.label} is already added`, "error")
            this.setState({ isloading: false })
        }
        this.setState({ showAllCSRList: false })

    }

    removeCSRfromCustomer = (csr) => {
        this.setState({ isloading: true })
        let csrList = this.state.assignedCSR.filter(item => item.value != csr.value)
        this.assignCSRtoCustomer(csrList, `${csr.label} Removed Successfully`)
    }
    handleClickOutside(event) {
        if (this.state.showAllCSRList && this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ showAllCSRList: false })
        }
    }

    handleDefaultScroll = () => {
        const dataTable = document.getElementsByClassName('rdg r104f42s700-beta13 fill-grid rdg-light customer-grid');
        if (dataTable && dataTable[0]) {
            this.handleScroll({ currentTarget: dataTable[0] }, true);
        }
    }

    handleScroll = (e) => {
        if (this.state.isLoading || isAtBottom(e)) return;
        if (this.state.skip + this.state.loadsPerReq < this.state.totalCustomers) {
            this.setState({ skip: this.state.loadsPerReq + this.state.skip }, () => {
                this.getcustomerData(true)
            })
        }
    }

    onSortColumnsChange = ((sortColumns) => {
        this.setState({ sortColumns: sortColumns.slice(-1) }, () => {
            this.getcustomerData();
        })
    });

    onClose = () => {
        this.setState({ addCustomer: false, tab: 1 }, () => {
            browserHistory.push("/tms/customer")
        })
    }

    render() {
        let allCustomers = this.props.customers;
        if (!allCustomers) {
            allCustomers = [];
        }

        const enabledCount = _.cloneDeep(allCustomers).filter(
            (d) => d.isDeleted === false
        ).length;
        const disabledCount = _.cloneDeep(allCustomers).filter(
            (d) => d.isDeleted === true
        ).length;

        let Terminals = [];
        if (this.state.Terminals && this.state.Terminals.length > 0) {
            Terminals = this.state.Terminals.map((D) => {
                return {
                    value: D._id,
                    label: D.name,
                };
            });
        }
        let companyAddress = [];
        if (this.state.Selected_customer) {
            if (this.state.Selected_customer.address) {
                companyAddress.push(this.state.Selected_customer.address);
            }
        }

        if (["Enabled", "Inactive"].includes(this.state.fitlerDeletedEnabled))
            allCustomers = allCustomers.filter((d) =>
                this.state.fitlerDeletedEnabled === "Enabled"
                    ? !d.isDeleted
                    : d.isDeleted
            );


        const checkPermission = () => {
            if (isTrialUser()) {
                this.setState({ upgradeTopro: !this.state.upgradeTopro })
            }
        }

        let filteredAllCSR = this.state.AllCSRList.filter((item) => {
            return !this.state.assignedCSR.find(csr => csr.value == item.value)
        })
        // 
        return (
            <div className="position-relative subheader-wrapper">
                {this.state.isLoading && <LoaderBar></LoaderBar>}
                {!this.props.isQuickAdd &&
                    <div>
                        <SubHeaderTab
                            val="customerItems"
                            buttons={
                                <Fragment>
                                    <button
                                        className="btn btn-outline-light mr-2"
                                        onClick={() => this.handleCompleteDownload()}
                                    >
                                        <IconDownload className="mr-2" />
                                        Download CSV
                                    </button>
                                    {this.state.isQbOnline && getStorage("currentUserRole") != "customer" && (
                                        <React.Fragment>
                                            <span
                                                data-tip
                                                data-for={getStorage("currentUserRole") === 'fleetmanager' && "registerTip"}
                                            >
                                                <a
                                                    href={isTrialUser() ? null : getStorage("currentUserRole") !== 'fleetmanager' && !this.state.isConnected ? this.state.qbRoute : "javascript:void(0)"}
                                                    id='QBConnectBtn'
                                                    onClick={() => (getStorage("currentUserRole") !== 'fleetmanager') && this.state.isConnected ? this.disconnectQuickBooks() : checkPermission()}

                                                    target={!this.state.isConnected && getStorage("currentUserRole") !== 'fleetmanager' && "_blank"}
                                                    className={`btn btn-outline-light mr-2 ${(getStorage("currentUserRole") == 'fleetmanager' || carrierDetail?.isShiposCarrier) && " disabled"}`}
                                                >
                                                    <IconQuickBook className={`mr-2 text-${getStorage("currentUserRole") !== 'fleetmanager' ? "success" : "secondary"}`} />
                                                    {this.state.isConnected ? "Disconnect QuickBooks" : "Connect to QuickBooks"}
                                                </a>
                                            </span>
                                            <ReactTooltip id="registerTip" place="bottom" effect="solid">Only the account owner can connect Quickbooks</ReactTooltip>
                                        </React.Fragment>
                                    )}
                                    <button
                                        className="btn btn-success"
                                        onClick={() => {
                                            this.resetform();
                                            this.setState({
                                                showAddModal: true,
                                                documents: [],
                                                newTerminal: null,
                                            });
                                        }}
                                    >
                                        <IconPlus className="mr-2" />
                                        Add New Customer
                                    </button>
                                </Fragment>
                            }
                        />
                        <div className={`page-fluid`}>
                            {!this.state.addCustomer ? (
                                <React.Fragment>
                                    <div className="top-page">
                                        {/* Title and Pagination */}
                                        <div className="pb-15 d-flex align-items-center justify-content-between">
                                            <h4 className="font-20 mb-0">Customers</h4>
                                        </div>
                                        {/* Customer Cards */}
                                        <div className={`form-row mb-15 ${this.state.selectedCard ? 'hasactive' : ''}`}>
                                            {cardOptions.map((option, index) => {
                                                return (
                                                    <div
                                                        className="col-xl col-md-3 col-sm-6 col-6 mb-1"
                                                        key={index}
                                                    >
                                                        <div
                                                            className={`ncard d-flex flex-column justify-content-between p-10 sbox--wrapper pointer ${this.state.selectedCard &&
                                                                    this.state.selectedCard.label === option.label
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                            onClick={() => this.filterStatus(option)}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <div className={`mr-10 sbox sbox--default px-2 h-100 ${this.state.selectedCard &&
                                                                        this.state.selectedCard.label === option.label
                                                                        ? "sbox--active"
                                                                        : ""
                                                                    }`}>
                                                                    {this.state.counts[option.countKey]}
                                                                </div>
                                                                <h6 className="card-title text-uppercase text-muted mb-0">
                                                                    {option.label}
                                                                </h6>
                                                                <div className="ml-auto">
                                                                    {
                                                                        this.state.selectedCard &&
                                                                            this.state.selectedCard.label === option.label
                                                                            ? <IconAngleArrowDown />
                                                                            : <IconAngleArrowRight />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {/* Table Filter */}
                                        <div className="filter-row d-flex align-items-center">
                                            {/* Search Bar */}
                                            <div className="app-search header-search">
                                                <span className="search-icon"> </span>
                                                <div className="position-relative">
                                                    <input
                                                        className="bill-control input-search-left form-control"
                                                        name=""
                                                        id="customerSearchInput"
                                                        placeholder="Search"
                                                        value={this.state.searchTerm}
                                                        onChange={(d) => this.handleInput(d)}
                                                    />
                                                </div>
                                            </div>
                                            {/* Active or Inactive */}
                                            <div className="mr-2 w-150">
                                                <Select
                                                    options={[
                                                        { value: "Active", label: "Active" },
                                                        { value: "Inactive", label: "Inactive" },
                                                    ]}
                                                    value={{
                                                        value: this.state.fitlerDeletedEnabled,
                                                        label: this.state.fitlerDeletedEnabled,
                                                    }}
                                                    styles={smallSelectStyle}
                                                    onChange={({ value }) =>
                                                        this.setState(
                                                            {
                                                                fitlerDeletedEnabled: value,
                                                                isActive: !this.state.isActive,
                                                                skip: 0
                                                            }, () => { this.getcustomerData(); }
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="form-group mr-2 mb-0  w-150">
                                                <Select
                                                    options={filterOnHoldOptions}
                                                    value={this.state.filterOnHold && filterOnHoldOptions.find(el => el.value === this.state.filterOnHold)}
                                                    styles={smallSelectStyle}
                                                    onChange={({ value }) => {
                                                        let account_hold = false;
                                                        if (value === 'On Hold') {
                                                            account_hold = true;
                                                        } else if (value === 'Not On Hold') {
                                                            account_hold = false;
                                                        }
                                                        this.setState({ filterOnHold: value, account_hold, skip: 0 }, () => {
                                                            this.getcustomerData();
                                                        })
                                                    }}
                                                />
                                            </div>

                                            {/* Select Terminal */}
                                            {((getStorage("currentUserRole") === "fleetmanager" &&
                                                configuration.idForTerminal.indexOf(
                                                    JSON.parse(getStorage("loggedInUser")).fleetManager
                                                        .carrier
                                                ) != -1) ||
                                                (getStorage("currentUserRole") == "carrier" &&
                                                    configuration.idForTerminal.indexOf(
                                                        getStorage("currentUserID")
                                                    ) != -1)) && (
                                                    <div className="form-group mb-0 w-150">
                                                        <Select
                                                            onChange={(choosenTerminals) =>
                                                                this.setState({ choosenTerminals }, () => {
                                                                    this.getcustomerData();
                                                                })
                                                            }
                                                            styles={smallSelectStyle}


                                                            isMulti
                                                            name="choosenTerminals"
                                                            options={Terminals}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>

                                    {/* Customer Profile Table */}
                                    <DataGrid
                                        key={this.remountKey}
                                        columns={this.state.columns}
                                        rows={this.state.allCustomers}
                                        sortColumns={this.state.sortColumns}
                                        onSortColumnsChange={this.onSortColumnsChange}
                                        rowHeight={50}
                                        defaultColumnOptions={{
                                            sortable: true,
                                        }}
                                        headerRowHeight={30}
                                        className={"fill-grid rdg-light customer-grid"}
                                        onScroll={this.handleScroll}
                                        components={{
                                            noRowsFallback: <EmptyRowsRenderer />
                                        }}
                                    />
                                    
                                </React.Fragment>
                            ) : (
                                    <NewCustomerModal
                                        filteredAllCSR={filteredAllCSR}
                                        selectCustomer={this.state.Selected_customer}
                                        onClose={(()=> this.onClose())}
                                        assignedCSR={this.state.assignedCSR}
                                        isLoading={this.state.isloading}
                                        showAllCSRList={this.state.showAllCSRList}
                                        setShowAllCSRList={() => {this.setState({showAllCSRList: !this.state.showAllCSRList,csrSearchTerm: ""});}}
                                        assignCSR={this.assignCSR}
                                        removeCSRfromCustomer={this.removeCSRfromCustomer}
                                        wrapperRef={this.wrapperRef}
                                        listAllCSR={this.listAllCSR}
                                        tab={this.state.tab}
                                        setTab={this.setTab}
                                        AllProps={this.props}
                                        addCustomer={this.state.addCustomer}
                                        updateCustomer={this.updateCustomer}
                                        AllState={this.state}
                                    />
                            )}
                        </div>
                    </div>
                }
                <Modal show={this.state.showAddModal || this.props.isQuickAdd} dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable" animation={false}>
                    <Modal.Header>
                        <Modal.Title>Add New Customer</Modal.Title>
                    </Modal.Header>
                    <ModalBody>

                        <AddCustomerComponent
                            Terminals={this.state.Terminals}
                            submitForm={this.submitForm}
                            AddModal={this.state.showAddModal || this.props.isQuickAdd}
                            closeModal={() => this.closeModal()}
                            updateState={(val) => this.updateState(val)}
                            initialValues={this.state.Selected_customer}
                            {...this.props}
                            {...this.state}
                            documents={this.state.documents}
                            added={() => {
                                this.getcustomerData();
                            }}
                            isQuickAdd={this.props.isQuickAdd}
                            showAddModal={this.state.showAddModal}
                            isOnlyPopup={this.props.isOnlyPopup}
                        />

                    </ModalBody>
                    <Modal.Footer>
                        <button
                            className="btn btn-close"
                            onClick={() => {
                                this.setState({ showAddModal: false, tab: 1 });
                                if (this.props.isQuickAdd) {
                                    this.props.falsyData("customer");
                                }
                            }}
                        >
                            Cancel
                        </button>
                        {this.state.tab !== 5 && (
                            <button
                                className={`btn ${this.state.Selected_customer &&
                                        this.state.Selected_customer._id
                                        ? "btn-primary"
                                        : "btn-success"
                                    }`}
                                disabled={this.state.isSaving}
                                onClick={() => {
                                    $(".btncustomersave").trigger("click");
                                    // this.submitForm(this.submitForm)
                                }}
                            >
                                {this.state.Selected_customer &&
                                    this.state.Selected_customer._id
                                    ? "Update New Customer"
                                    : "Add New Customer"}
                            </button>
                        )}
                    </Modal.Footer>
                </Modal>

                {this.state.resetId ? <Modal show={this.state.resetId ? true : false} className="show">
                    <ResetCustomerPassword
                        setResetId={() => this.setState({ resetId: undefined })}
                        customerId={this.state.resetId}
                        actions={this.props.actions}
                    />
                </Modal> : null}


                <Modal show={this.state.upgradeTopro} bsSize="md" onHide={() => this.setState({ upgradeTopro: false })}>
                    {this.state.upgradeTopro && <div className="m-5 pt-5">
                        <UpgradeToPro />
                    </div>}
                </Modal>
            </div>
        );
    }
}

Yup.addMethod(Yup.string, "sameAs", function (ref, message) {
    return this.test("sameAs", message, function (value) {
        let other = this.resolve(ref);
        return !other || !value || value === other;
    });
});

const ResetForm = (props) => {
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = props;
    return (
        <div className="center-child">
            <Modal.Header>
                <h4 className="modal-title" id="myModalLabel">
                    Reset Password
                </h4>
                <button
                    type="button"
                    className="close"
                    onClick={() => props.setResetId()}
                >
                    <i className="uil uil-times"></i>
                </button>
            </Modal.Header>
            <div className="sign-up-form">
                <form className="form" onSubmit={handleSubmit}>
                    <div>
                        <Modal.Body>
                            <div className={`form-group  ${errors.password && "has-error"}`}>
                                <label htmlFor="password">
                                    New Password
                                    <span
                                        className="required-field"
                                        title="This field is reqired."
                                    >
                                        *
                                    </span>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.password && touched.password && (
                                    <span className="text-danger">{errors.password}</span>
                                )}
                            </div>
                            <div
                                className={`form-group  ${errors.confirmPassword && "has-error"
                                    }`}
                            >
                                <label htmlFor="password">
                                    Confirm Password
                                    <span
                                        className="required-field"
                                        title="This field is reqired."
                                    >
                                        *
                                    </span>
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.confirmPassword && touched.confirmPassword && (
                                    <span className="text-danger">{errors.confirmPassword}</span>
                                )}
                            </div>
                            {isSubmitting && (
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <Spinner />
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-close"
                                onClick={() => {
                                    props.setResetId();
                                }}
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                name="resetCustomerPassword"
                                id="resetCustomerPassword"
                                value="Save"
                                className="btn btn-danger"
                            >
                                Reset
                            </button>
                        </Modal.Footer>
                    </div>
                </form>
            </div>
        </div>
    );
};

const ResetCustomerPassword = withFormik({
    mapPropsToValues: (props) => ({
        password: "",
        confirmPassword: "",
    }),
    validationSchema: Yup.object().shape({
        password: Yup.string()
            .min(5, "Enter atleast 5 characters.")
            .max(25, "Enter less than 25 characters.")
            .required("Password is required."),
        confirmPassword: Yup.string()
            .sameAs(Yup.ref("password"), "passwords doesn't match")
            .min(5, "Enter atleast 5 characters.")
            .max(50, "Enter less than 50 characters.")
            .required("Password confirm is required"),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        const password = values.password;
        const customerId = props.customerId;
        props.actions
            .resetCustomerPassword({
                customerId,
                password,
            })
            .then((result) => {
                props.setResetId();
                toastr.show("Your password has been successfully changed!", "success");
                setSubmitting(false);
            })
            .catch((Err) => { })
            .finally(() => {
                setSubmitting(false);
            });
    },
})(ResetForm);

function mapStateToProps(state) {
    return {
        customers: state.TmsReducer.customersAll,
        isCustomerLoading: state.TmsReducer.isCustomerAllLoading,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        dispatch: dispatch,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Customer);
