import React, { useState, useEffect } from 'react'
import { smallSelectStyle } from '../../../../../../assets/js/select-style';
import CustomCreatableSelect from '../../../../../../Components/Common/CustomSelect/creatable';
import Select from "react-select";
import _ from 'lodash';
import AsyncSelect from "react-select/async";
import { convertTMSStatusName } from '../../../../../../Components/Common/functions';
import { editTMSCustomer } from '../../../../services';
import { errorColorResponse, successColorResponse, onChangeSuccessColorResponse  } from '../../utility';
import { loadFlags } from '../../../../Load/utility';
import { customerSubType } from '../../../../../../services';
import { TextInputField } from './CommonInputComponent';
import { validateEmail } from '../../../../../../utils';

const emailStatusOptionsValue = [
    "CHASSISPICK_ARRIVED",
    "CHASSISPICK_DEPARTED",
    "PULLCONTAINER_ARRIVED",
    "PULLCONTAINER_DEPARTED",
    "DELIVERLOAD_ARRIVED",
    "DELIVERLOAD_DEPARTED",
    "RETURNCONTAINER_ARRIVED",
    "RETURNCONTAINER_DEPARTED",
    "COMPLETED",
].map((d) => ({
    value: d,
    label: convertTMSStatusName("IMPORT", d),
}));
const EmailNotificationComponent = ({ Selected_customer, tab, updateCustomer, AllProps, AllState }) => {
    const [isSendNotificationEmail, setIsSendNotificationEmail] = useState(Selected_customer.isSendNotificationEmail ? Selected_customer.isSendNotificationEmail : false);
    const [isSendContainerNotification, setIsSendContainerNotification] = useState(Selected_customer.isSendContainerNotification ? Selected_customer.isSendContainerNotification : false);
    const [emailStatusOptions, setEmailStatusOptions] = useState(Selected_customer.emailStatuses ?
        Selected_customer.emailStatuses.map((d) => ({ value: d, label: convertTMSStatusName("IMPORT", d), })) : []
    );
    const [isSendPODEmail, setIsSendPODEmail] = useState(Selected_customer.isSendPODEmail ? Selected_customer.isSendPODEmail : false);
    const [isEmailNeedToSend, setIsEmailNeedToSend] = useState(Selected_customer?.isEmailNeedToSend ? { label: "Yes", value: true } : { label: "No", value: false })
    let AllsalesAgent = _.get(AllState, "Selected_customer.salesAgents", []).map(
        (d) => ({ label: d.name, value: d._id })
    )
    const [salesAgent, setSalesAgent] = useState(AllsalesAgent ? AllsalesAgent : []);
    const [defaultSalesAgents, setDefaultSalesAgents] = useState([]);
    const [customer_SubType, setCustomer_SubType] = useState(Selected_customer.customerSubType ? Selected_customer.customerSubType : "")
    const [companyField, setCompanyField] = useState(Selected_customer.companyField ? Selected_customer.companyField : "")
    const [isSendEmailDeliveryAppointmentChanged, setIsSendEmailDeliveryAppointmentChanged] = useState(
        Selected_customer.isSendEmailDeliveryAppointmentChanged ? Selected_customer.isSendEmailDeliveryAppointmentChanged : false
    );
    const [isSendEmailPricingDetailEmail, setIsSendEmailPricingDetailEmail] = useState(
        Selected_customer.isSendEmailPricingDetailEmail ? Selected_customer.isSendEmailPricingDetailEmail : false
    );
    const [isSendEmailContainerAvailable, setIsSendEmailContainerAvailable] = useState(
        Selected_customer.isSendEmailContainerAvailable ? Selected_customer.isSendEmailContainerAvailable : false
    );
    const [isSendEmailContainerAdd, setIsSendEmailContainerAdd] = useState(
        Selected_customer.isSendEmailContainerAdd ? Selected_customer.isSendEmailContainerAdd : false
    );
    const [isSendEmailLoadAccepted, setIsSendEmailLoadAccepted] = useState(
        Selected_customer.isSendEmailLoadAccepted ? Selected_customer.isSendEmailLoadAccepted : false
    );
    const [isSendEmailContainerReadyForPickup, setIsSendEmailContainerReadyForPickup] = useState(
        Selected_customer.isSendEmailContainerReadyForPickup ? Selected_customer.isSendEmailContainerReadyForPickup : false
    );
    const [doc_all, setDoc_all] = useState(Selected_customer.doc_all ? Selected_customer.doc_all : false);
    const [doc_tir, setDoc_tir] = useState(Selected_customer.doc_tir ? Selected_customer.doc_tir : false);
    const [doc_pod, setDoc_pod] = useState(Selected_customer.doc_pod ? Selected_customer.doc_pod : false);
    const [doc_none, setDoc_none] = useState(Selected_customer.doc_none ? Selected_customer.doc_none : false);
    const [notificationEmails, setNotificationEmails] = useState(Selected_customer.notificationEmails ? Selected_customer.notificationEmails.split(",") : []);
    const [extraEmail, setExtraEmail] = useState(Selected_customer.extraEmail ? Selected_customer.extraEmail.split(",") : [])

    useEffect(() => {
        listAllFleetManagers()
    }, [])

    const onChangeHandler = (key, value) => {
        let params = {
            _id: Selected_customer._id,
            [key]: value
        }
        editTMSCustomer(params).then((result) => {
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse(key)
        }).catch((err) => {
            console.log(err)
            errorColorResponse(key)
        })
    }

    const listAllFleetManagers = (search) => {
        if (!search) {
            return new Promise((resolve, reject) => {
                AllProps.actions
                    .listAllFleetManagers({ salesAgent: true }, 10)
                    .then((options) => {
                        const salesAgents = [];
                        let allDatas = options || [];

                        allDatas.forEach((obj) => {
                            salesAgents.push({
                                value: obj._id,
                                label: `${obj.name}`,
                                allInfos: obj,
                            });
                        });
                        setDefaultSalesAgents(salesAgents)
                        resolve(salesAgents);
                    });
            });
        }
        let searchData = {
            searchTerm: search,
            salesAgent: true,
        };
        return new Promise((resolve, reject) => {
            AllProps.actions.listAllFleetManagers(searchData).then((options) => {
                resolve(options);
            });
        });
    };

    const onBlurHandler = (key, val) => {
        let data = {
            _id: Selected_customer._id,
            [key]: val
        }
        editTMSCustomer(data).then((result) => {
            successColorResponse(key)
            updateCustomer(result.data.data)
        }).catch((err) => {
            console.log("err", err)
            errorColorResponse(key)
        })
    }

    const setFeatureValue = (key, val) => {
        let params = {
            _id: Selected_customer._id,
        }
        if (key === "doc_all") {
            setDoc_all(val);
            setDoc_tir(false);
            setDoc_pod(false);
            setDoc_none(false);
            params.doc_all = val;
            params.doc_tir = false;
            params.doc_pod = false;
            params.doc_none = false;
        } else if (key === "doc_none") {
            setDoc_none(val);
            setDoc_all(false);
            setDoc_tir(false);
            setDoc_pod(false);
            params.doc_all = false;
            params.doc_tir = false;
            params.doc_pod = false;
            params.doc_none = val;
        } else if (key === "doc_tir") {
            setDoc_tir(val);
            setDoc_all(false);
            setDoc_none(false);
            params.doc_tir = val;
            params.doc_all = false;
            params.doc_none = false;
        } else if (key === "doc_pod") {
            setDoc_pod(val);
            setDoc_all(false);
            setDoc_none(false);
            params.doc_pod = val;
            params.doc_all = false;
            params.doc_none = false;
        }

        editTMSCustomer(params).then((result) => {
            updateCustomer(result.data.data)
            // onChangeSuccessColorResponse(key)
        }).catch((err) => {
            console.log(err)
            // errorColorResponse(key)
        })
    };

    const handleNotificationEmailsChange = (key,val) => {
        if(Selected_customer[key] === val) return
        val = val.filter((d) =>
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
        );
        setNotificationEmails(val);
        let params = {
            _id: Selected_customer._id,
            notificationEmails: val.toString()
        }
        editTMSCustomer(params).then((result) => {
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse("notificationEmails")
        }).catch((err) => {
            console.log(err)
            errorColorResponse("notificationEmail")
        })
    }
    const handleExtraEmailsChange = (val) => {
        val = val.filter((d) =>
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
        );
        setExtraEmail(val);
        let params = {
            _id: Selected_customer._id,
            extraEmail: val.toString()
        }
        editTMSCustomer(params).then((result) => {
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse("extraEmail")
        }).catch((err) => {
            console.log(err)
            errorColorResponse("extraEmail")
        })
    }
    return (
        <div>
            <div
                id="formContainer"
                className={`${Selected_customer?._id ? "card pt-30 px-20" : ""
                    }`}
                style={{
                    display: tab == 7 ? "" : "none",
                }}
            >
                <div className="form-row">
                    <div className="col-md-4 mb-2">
                        <div className="form-group form-check  mb-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSendNotificationEmail"
                                checked={isSendNotificationEmail ? true : false}
                                onChange={(e) => {
                                    setIsSendNotificationEmail(e.target.checked);
                                    onChangeHandler("isSendNotificationEmail", e.target.checked);
                                }}
                            />
                            <label
                                className="form-check-label"
                                for="isSendNotificationEmail"
                            >
                                Send Email Notification{" "}
                            </label>
                        </div>
                        <div className="mb-1">
                            <CustomCreatableSelect
                                isMulti={true}
                                disabled={!isSendNotificationEmail}
                                isClearable={true}
                                size="small"
                                placement="right"
                                placeholder="Enter emails.."
                                value={notificationEmails.map((i) => {
                                    const val = {
                                        label: i,
                                        value: i,
                                    };

                                    return val;
                                })}
                                onChange={(val) => {
                                    handleNotificationEmailsChange("notificationEmails",
                                        _.compact(
                                            val.map((i) => {
                                                if (!validateEmail(i.value)) {
                                                    return i.value;
                                                } else {
                                                    return;
                                                }
                                            })
                                        )
                                    );
                                }}
                                className={'notificationEmails'}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group form-check  mb-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSendContainerNotification"
                                checked={
                                    isSendContainerNotification
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    setIsSendContainerNotification(e.target.checked);
                                    onChangeHandler("isSendContainerNotification", e.target.checked);
                                }}
                            />
                            <label
                                className="form-check-label"
                                for="isSendContainerNotification"
                            >
                                Send Container Email Notification{" "}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group form-check  mb-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSendPODEmail"
                                checked={isSendPODEmail ? true : false}
                                onChange={(e) => {
                                    setIsSendPODEmail(e.target.checked);
                                    onChangeHandler("isSendPODEmail", e.target.checked);
                                }}
                            />
                            <label
                                className="form-check-label"
                                for="isSendPODEmail"
                            >
                                Send POD Email{" "}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Statuses for email notification</label>
                            <Select
                                isDisabled={!isSendNotificationEmail}
                                value={emailStatusOptions}
                                styles={smallSelectStyle}
                                onChange={(emailStatus) => {
                                    setEmailStatusOptions(emailStatus);
                                    onChangeHandler("emailStatuses", emailStatus.map((d) => d.value))
                                }}
                                isMulti
                                name="emailStatuses"
                                options={emailStatusOptionsValue}
                                className="basic-multi-select emailStatuses"
                                classNamePrefix="select"
                            />
                        </div>
                    </div>
                    {Selected_customer?.customerType.intersect(["ALL", "caller"]).length > 0 && (
                    <div className="col-md-4">
                        <div className="form-group  mb-2">
                            <label className="label-control d-block">CC Emails</label>
                            <div className="mb-1">
                                <CustomCreatableSelect
                                    isMulti={true}
                                    isClearable={true}
                                    size="small"
                                    placement="right"
                                    placeholder="Enter emails.."
                                    value={extraEmail.map((i) => {
                                        const val = {
                                            label: i,
                                            value: i,
                                        };

                                        return val;
                                    })}
                                    onChange={(val) => {
                                        handleExtraEmailsChange(
                                            _.compact(
                                                val.map((i) => {
                                                    if (!validateEmail(i.value)) return i.value;
                                                })
                                            )
                                        );
                                    }}
                                    className={"extraEmail"}
                                />
                            </div>
                        </div>
                    </div>
                    )}
                    {Selected_customer?.customerType.intersect(["ALL", "caller"]).length > 0 && (
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Email Invoice to Customer?
                            </label>
                            <Select
                                name="isEmailNeedToSend"
                                id='isEmailNeedToSend'
                                value={isEmailNeedToSend}
                                styles={smallSelectStyle}
                                onChange={(value) => {
                                    setIsEmailNeedToSend(value);
                                    onChangeHandler("isEmailNeedToSend", value.value)
                                }}
                                options={loadFlags}
                                classNamePrefix="select"
                                className='isEmailNeedToSend'
                            />
                        </div>
                    </div>
                    )}
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Sales Agent</label>
                            <AsyncSelect
                                menuPlacement="top"
                                isMulti
                                styles={smallSelectStyle}
                                value={salesAgent}
                                defaultOptions={defaultSalesAgents}
                                loadOptions={listAllFleetManagers}
                                onChange={(salesAgents) => {
                                    setSalesAgent(salesAgents);
                                    onChangeHandler("salesAgents", salesAgents.map((el) => el.value));
                                }}
                                className={"salesAgents"}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Customer Sub Type </label>
                            <Select
                                styles={smallSelectStyle}
                                options={customerSubType}
                                className={"customerSubType"}
                                onChange={(value) => {
                                    setCustomer_SubType(value)
                                    onChangeHandler("customerSubType", value.value)
                                }}
                                value={customer_SubType && customerSubType.find((el) => el.value === customer_SubType)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                QuickBooks Company Field
                            </label>
                            <TextInputField
                                type={"text"}
                                name={"companyField"}
                                className={"form-control"}
                                value={companyField}
                                onChange={(e, key) => {
                                    setCompanyField(e.target.value.trim());
                                }}
                                onBlur={(key, val) => onBlurHandler(key, val)}
                                placeholder={"QuickBooks Company Field"}
                            />

                        </div>
                    </div>
                </div>
                {isSendNotificationEmail && (
                    <React.Fragment>
                        <hr />
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <h4>Email Notifictions</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isSendEmailDeliveryAppointmentChanged"
                                        checked={isSendEmailDeliveryAppointmentChanged ? true : false}
                                        onChange={(e) => {
                                            setIsSendEmailDeliveryAppointmentChanged(e.target.checked);
                                            onChangeHandler("isSendEmailDeliveryAppointmentChanged", e.target.checked);
                                        }
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        for="isSendEmailDeliveryAppointmentChanged"
                                    >
                                        Send Email Delivery Appointment Changed
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isSendEmailPricingDetailEmail"
                                        checked={isSendEmailPricingDetailEmail
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                            setIsSendEmailPricingDetailEmail(e.target.checked);
                                            onChangeHandler("isSendEmailPricingDetailEmail", e.target.checked);
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        for="isSendEmailPricingDetailEmail"
                                    >
                                        Send Email Pricing Detail{" "}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isSendEmailContainerAvailable"
                                        checked={isSendEmailContainerAvailable
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                            setIsSendEmailContainerAvailable(e.target.checked);
                                            onChangeHandler("isSendEmailContainerAvailable", e.target.checked);
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        for="isSendEmailContainerAvailable"
                                    >
                                        Send Email Container Available
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isSendEmailContainerAdd"
                                        checked={isSendEmailContainerAdd ? true : false}
                                        onChange={(e) => {
                                            setIsSendEmailContainerAdd(e.target.checked);
                                            onChangeHandler("isSendEmailContainerAdd", e.target.checked);
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        for="isSendEmailContainerAdd"
                                    >
                                        Send Email Container Add
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isSendEmailLoadAccepted"
                                        checked={isSendEmailLoadAccepted ? true : false}
                                        onChange={(e) => {
                                            setIsSendEmailLoadAccepted(e.target.checked);
                                            onChangeHandler("isSendEmailLoadAccepted", e.target.checked);
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        for="isSendEmailLoadAccepted"
                                    >
                                        Send Email Load Accepted
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isSendEmailContainerReadyForPickup"
                                        checked={isSendEmailContainerReadyForPickup
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                            setIsSendEmailContainerReadyForPickup(e.target.checked);
                                            onChangeHandler("isSendEmailContainerReadyForPickup", e.target.checked);
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        for="isSendEmailContainerReadyForPickup"
                                    >
                                        Send Email Container Ready For Pickup
                                    </label>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <React.Fragment>
                    <hr />
                    <div className="mb-2">
                        <h4>Email Attachments</h4>
                    </div>

                    <div
                        className={doc_none ? "mb-2 div-invisible" : "mb-2"}
                    >
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="doc_all"
                                checked={doc_all ? true : false}
                                onChange={(e) => {
                                    setFeatureValue("doc_all", e.target.checked)
                                }}
                            />
                            <label className="form-check-label" for="doc_all">
                                ALL
                            </label>
                        </div>
                    </div>
                    <div
                        className={doc_none ? "mb-2 div-invisible" : "mb-2"}
                    >
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="doc_tir"
                                checked={doc_tir ? true : false}
                                onChange={(e) => {
                                    setFeatureValue("doc_tir", e.target.checked)
                                }}
                            />
                            <label className="form-check-label" for="doc_tir">
                                TIR
                            </label>
                        </div>
                    </div>
                    <div
                        className={doc_none ? "mb-2 div-invisible" : "mb-2"}
                    >
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="doc_pod"
                                checked={doc_pod ? true : false}
                                onChange={(e) => {
                                    setFeatureValue("doc_pod", e.target.checked)
                                }}
                            />
                            <label className="form-check-label" for="doc_pod">
                                POD
                            </label>
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="doc_none"
                                checked={doc_none ? true : false}
                                onChange={(e) => {
                                    setFeatureValue("doc_none", e.target.checked)
                                }}
                            />
                            <label className="form-check-label" for="doc_none">
                                NONE
                            </label>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </div>
    )
}

export default EmailNotificationComponent