import React, { useEffect, useRef, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { getStorage } from "../../../services/Common.services";
import {
  IconAngleArrowDown,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconTriangleArrowDown,
} from "../Icons";
import DateTimePicker from "../../../pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateTimePicker";

const weekOptions = [
  {
    label: "This Week",
    value: 0,
  },
  {
    label: "Previous Week",
    value: -1,
  },
  {
    label: "1 Week Before",
    value: -2,
  },
  {
    label: "2 Week Before",
    value: -3,
  },
  {
    label: "3 Week Before",
    value: -4,
  },
];

const getWeekdays = (filterType) => {
  const baseDate = new Date();
  const dayOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Today",
      value: 0,
    },
  ];
  if(filterType == "dispatcher"){
    dayOptions.splice(1, 0, {
      label: "Past Loads",
      value: "past due",
    });
  }
  for (let i = 1; i <= 7; i++) {
    baseDate.setDate(baseDate.getDate() + 1);
    dayOptions.push({
      label: baseDate.toLocaleDateString("en-US", { weekday: "long" }),
      value: i,
    });
  }
  if (filterType == "driverItinerary") {
    dayOptions.splice(0, 1);
  }
  return dayOptions;
};


const renderInput = (props, openCalendar, closeCalendar) => {
  function clear() {
    props.onChange({ target: { value: "" } });
  }
  return (
    <div>
      <button id="prev" className="btn btn-white mr-3" onClick={openCalendar}>
        <IconCalendar />
      </button>
    </div>
  );
};

const DayCalenderFilter = (props) => {
  const {
    selectedDate,
    handleDateChange,
    deliveryFrom,
    loadsFilter,
    page,
    updateFilter,
    isWeek = false,
    newDispatcher,
  } = props;
  const [val, setVal] = useState(isWeek ? 0 : page == "dispatcher" ? loadsFilter : (page == "driverItinerary" ? 0 : "all"));
  const [showMore, setShowMore] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [allOptions, setAllOptions] = useState(
    isWeek ? weekOptions : getWeekdays(page)
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const ref = useRef(null);

  useEffect(()=>{
    if(loadsFilter !== undefined){
      setVal(loadsFilter)
    }
  },[loadsFilter])
  //ClickOutside The Ref to close the document dropdown
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showMore && ref && !ref.current.contains(e.target)) {
        setShowMore(null);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showMore]);

  const timeZone = getStorage("timeZone");

  // Handle switch
  const handleSwitch = (type, i) => {
    setSelectedIndex(i);
     if(loadsFilter !== undefined){
      updateFilter(type)
    }else{
      setVal(type);
    }
    if (!isWeek) {
      handleDayPrevAndNext(type);
    } else {
      handleWeekPrevAndNext(type);
    }
    setShowMore(false);
  };

  // Handle Day Change
  const handleDayPrevAndNext = (type) => {
    let day = 1;
    let dateVal = selectedDate;

    if (type === "prev") {
      day = -1;
    } else if (type === "next") {
      day = 1;
    } else {
      day = type;
      dateVal = moment();
    }

    if (type === "all") {
      removeDateFilter();
    } else if (type === "past due"){
      handleDateChange(null, type)
    } else {
      const newDate = moment(dateVal)
        .tz(timeZone)
        .add(day, "days")
        .toISOString();
      if (newDate) {
        handleDateChange(newDate, type);
      }
    }
  };

  // Handle week Change
  const handleWeekPrevAndNext = (type) => {
    let week = 1;
    let dateVal = selectedDate;
    if (type === "prev") {
      week = -1;
    } else if (type === "next") {
      week = 1;
    } else {
      week = type;
      dateVal = moment();
    }

    if (type === "all") {
      removeDateFilter();
    } else if(type === "past due"){
      handleDateChange(null, type);
    }else {
      const newDate = moment(dateVal)
        .tz(timeZone)
        .add(week, "weeks")
        .toISOString();
      if (newDate) {
        handleDateChange(newDate, type);
      }
    }
  };

  const removeDateFilter = () => {
    handleDateChange(null);
  };

  const getLabel = () => {
    let label = val;
    let selected = allOptions.find((opt) => opt && opt.value === val);
    if (selected) {
      if(selected.label==="All"){
        label = `${selected.label} Loads`
      }else if(selected.label === "Past Loads"){
        label = selected.label
      }else{
        label = `${selected.label}'s Loads`;
      }
    } else if (val === "prev" || val === "next" || val === null) {
      if (isWeek) {
        let startDate = moment(selectedDate)
          .startOf("weeks")
          .format("MM/DD/YYYY");
        let endDate = moment(selectedDate).endOf("weeks").format("MM/DD/YYYY");
        label = startDate + "-" + endDate;
      } else {
        label = moment(selectedDate).format("LL");
      }
    }
    return label;
  };

  return (
    <div className="d-flex align-items-center" key={1}>
      <button
        id="prev"
        className="btn btn-white mr-3 wh-32px p-0 d-flex align-item-center justify-content-center"
        onClick={() => {
          handleSwitch("prev");
        }}
      >
        <IconAngleArrowLeft />
      </button>
      <div className="d-flex align-items-center mr-3">
        <div className="position-relative d-flex align-items-center" ref={ref}>
          <div>          
          <h4
            className={`pointer font-16 mb-0 ${
              deliveryFrom ? "text-primary" : ""
            }`}
            id="displayDate"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >    
          {getLabel()}       
          </h4>
          {showMore && (
            <div className="card card--shadow-5 popdrop popdrop--left popdrop--sm mb-0 p-10">
              {allOptions.map((opt, i) => {
                return (
                  <span
                    className={`pointer popdrop__dropdown-item`}
                    style={{
                      color: selectedIndex === i ? "#367bf6" : "",
                    }}
                    onClick={() =>
                      val !== opt.value && handleSwitch(opt.value, i)
                    }
                  >
                    {opt.label}
                  </span>
                );
              })}
            </div>
          )}
          </div>
          <div className="ml-2 text-dark"> <IconTriangleArrowDown /></div>        
        </div>
      </div>
      <button
        id="next"
        className="btn btn-white mr-15 wh-32px p-0 d-flex align-item-center justify-content-center"
        onClick={() => {
          handleSwitch("next");
        }}
      >
        <IconAngleArrowRight className="font-14" />
      </button>

      {newDispatcher &&
        <div className="btn btn-white mr-1 wh-32px p-0 d-flex align-item-center justify-content-center" onClick={() => setShowCalendar(!showCalendar)}>
          <IconCalendar />
        </div>
      }

      {page !== 'driverItinerary' &&
        (newDispatcher ?
          (showCalendar && <DateTimePicker
            onChange={(date) => {
              setVal(null);
              if(loadsFilter !== undefined){
                updateFilter(null)
              }
              handleDateChange(date);
            }}
            OnBlurUpdate={() => setShowCalendar(false)}
            isShowTime={false}
            displayTimeZone={timeZone}
            defaultDate={selectedDate}
          />)
        :
          <Datetime
            onChange={(date) => {
              setVal(null);
              if(loadsFilter !== undefined){
                updateFilter(null)
              }
              handleDateChange(date);
            }}
            timeFormat={false}
            renderInput={renderInput}
            displayTimeZone={timeZone}
          />
        )}
    </div>
  );
};

export default DayCalenderFilter;
