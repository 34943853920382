import React from "react";
import { Modal } from "react-bootstrap";
import { IconCloseBold , IconAngleArrowLeft} from "../../../../../Components/Common/Icons";
import { getStorage } from "../../../../../services";
import { TabLists } from "../utility";
import TabList from "../../TabList";
import SideBar from "./SideBar";
import EditCustomerComponent from "../NewCustomerModal/Components/EditCustomerComponent";
const NewCustomerModal = ({
  filteredAllCSR,
  selectCustomer,
  onClose,
  assignedCSR,
  isLoading,
  showAllCSRList,
  setShowAllCSRList,
  assignCSR,
  removeCSRfromCustomer,
  wrapperRef,
  listAllCSR,
  tab,
  setTab,
  isQuickAdd,
  addCustomer,
  updateCustomer,
  AllProps,
  AllState,
}) => {
  let showAddRemoveOptions =
    getStorage("currentUserRole") == "carrier" ||
    (getStorage("currentUserRole") == "fleetmanager" &&
      JSON.parse(getStorage("loggedInUser")).fleetManager &&
      JSON.parse(getStorage("loggedInUser")).fleetManager.admin);
  return (
    <Modal
      show={true}
      className="load-info-modal pr-20 pl-20 vh-100 mt-20"
      animation={false}
    >
      <Modal.Header>
        <div className="subheader">
          <div className="subheader__inner d-flex">
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn btn-outline-light"
                onClick={() => onClose()}
              >
                <IconAngleArrowLeft />
              </button>
              <div className="d-flex ml-3 align-items-center">
                <h5 className="mb-0 mr-2">{selectCustomer?.company_name}</h5>
              </div>
            </div>
            <div className="ml-auto position-relative">
              <button
                className="btn btn-outline-light mr-2 bg-white day-card pr-15 pl-15"
                onClick={() => {
                  onClose();
                }}
              >
                <IconCloseBold className="mr-15" />{" "}
                <span className="font-12 font-medium">Close</span>
              </button>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="load-modal-full pt-0">
        <div className="form-row-md fcpopup">
          <SideBar
            selectCustomer={selectCustomer}
            assignedCSR={assignedCSR}
            isLoading={isLoading}
            showAllCSRList={showAllCSRList}
            setShowAllCSRList={setShowAllCSRList}
            showAddRemoveOptions={showAddRemoveOptions}
            filteredAllCSR={filteredAllCSR}
            assignCSR={assignCSR}
            removeCSRfromCustomer={removeCSRfromCustomer}
            wrapperRef={wrapperRef}
            listAllCSR={listAllCSR}
          />
          <div className="col-md" style={{ width: "80%" }}>
            <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom bg-white">
              {TabLists.map((list) => {
                return (
                  <TabList
                    {...list}
                    key={list.id}
                    tab={tab}
                    setTab={setTab}
                    Selected_customer={selectCustomer}
                  />
                );
              })}
            </ul>
            <div className="tab">
              {addCustomer && (
                <EditCustomerComponent
                  isQuickAdd={isQuickAdd}
                  tab={tab}
                  addCustomer={addCustomer}
                  Selected_customer={selectCustomer}
                  updateCustomer={updateCustomer}
                  AllProps={AllProps}
                  AllState={AllState}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewCustomerModal;
