import React, { Component } from 'react';
import "./utils/currency";
import "babel-polyfill"
import Routes from './routes';
import './Components/Common/interceptor';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Helmet from 'react-helmet';

import configs from './redux/store';
import { loadReCaptcha } from 'react-recaptcha-v3';
import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import { setupGAnalytics } from './utils/googleAnalytics';

const { store, persistor } = configs()

class App extends Component {

  componentDidMount() {
    loadReCaptcha("6Lc9TrsZAAAAANl8CR4Ha7SkuS2e4EDImobE44lZ");
    // setupHotjar();
    setupGAnalytics();
    caches.keys().then(keyList => {
      keyList = keyList.filter((key) => key.includes('webpack-offline'));
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key);
        }),
      );
    });
    window.addEventListener('storage', e => {  
      if(e.key === 'token' && ((e.oldValue && !e.newValue) || (e.newValue && !e.oldValue) || (e.oldValue && e.newValue && e.oldValue !== e.newValue))) {
          window.location.reload()
       }
   });
  }
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Helmet>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20578305.js"></script>
          </Helmet>
          <Routes />
        </PersistGate>
      </Provider>
    );
  }
};

export default App;