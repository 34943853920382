import React from "react";
import { convertTMSStatusName, isEmptyOrLoaded } from "../functions";

const StatusComponent = (props) => {
  const { load } = props;

  //   Check if empty or loaded
  const emptyOrLoadedInfo =
    load && load.driverOrder ? isEmptyOrLoaded(load) : null;

  // get Status Detail
  let statusObj = load
    ? convertTMSStatusName(
        load.type_of_load,
        ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(
          load.status
        ) > -1 && !load.isLive
          ? "DROPCONTAINER_DEPARTED"
          : load.status,
        load,
        false,
        true
      )
    : null;
  return (
    <span
      className={`text-capitalize text-white badge badge-sm ${props?.deleted ? "" : "badge-sm"} badge-status${
        emptyOrLoadedInfo && emptyOrLoadedInfo.status === "empty"
          ? "-light"
          : ""
      }-${
        statusObj && statusObj.colorStatus ? statusObj.colorStatus : "purple"
      }`}
    >
      {statusObj && statusObj.status && statusObj.status.toLowerCase()}
      {emptyOrLoadedInfo && emptyOrLoadedInfo.status ? (
        <span className="ml-1 ">- {emptyOrLoadedInfo.status}</span>
      ) : (
        ""
      )}
    </span>
  );
};

export default StatusComponent;
