export const successColorResponse = (element) => {
    const Element = document.getElementById(`${element}`)
    Element?.classList.add('background-success');
    Element.style.transition = "background .4s ease-out";

    setTimeout(() => {
        Element?.classList.remove("background-success")
    }, 400);
}

export const errorColorResponse = (element) => {
    const Element = document.getElementById(`${element}`)

    Element?.classList.add("background-error")
    Element.style.transition = "background .4s ease-out";

    setTimeout(() => {
        Element.classList.remove("background-error")
    }, 400);
}

export const onChangeSuccessColorResponse = (key) =>{
    const Element = document?.querySelector(`.${key}`);
    let divInElement;
    divInElement = Element?.querySelector("div");
    divInElement.classList.add('background-success');
    divInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        divInElement.classList.remove("background-success")
    }, 400);
}

export const onChangeErrorColorResponse = (key) =>{
    const Element = document?.querySelector(`.${key}`);
    let divInElement;
    divInElement = Element?.querySelector("div");
    divInElement.classList.add('background-error');
    divInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        divInElement.classList.remove("background-error")
    }, 400);
}

export const onDateChangeSuccessColorResponse = (key) => {
    const Element = document?.querySelector(`.${key}`);
    let InputInElement;
    InputInElement =  Element?.querySelector("input");
    InputInElement.classList.add('background-success');
    InputInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        InputInElement.classList.remove("background-success")
    }, 400);
}
export const onDateChangeErrorColorResponse = (key) => {
    const Element = document?.querySelector(`.${key}`);
    let InputInElement;
    InputInElement =  Element?.querySelector("input");
    InputInElement.classList.add('background-error');
    InputInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        InputInElement.classList.remove("background-error")
    }, 400);
}

export let TabLists = [
    {
        label: "Chassis",
        value: "chassis",
        id: 1,
    },
    {
        label: "Documents",
        value: "documents",
        id: 2,
    },
    {
        label: "History",
        value: "history",
        id: 3,
    },
    {
        label: "Audit",
        value: "audit",
        id: 4,
    },
    {
        label: "Notes",
        value: "notes",
        id: 5,
    },
];