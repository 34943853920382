import React, { useRef } from "react";
import { TextInputField } from "./CommonInputComponent";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useState } from "react";
import { errorColorResponse, Required, SetRequired, successColorResponse } from "../../utility";
import { editTMSCustomer } from "../../../../services";
import { toastr } from "../../../../../../services";
const CompanyCustomerComponent = ({ Customer , updateCustomer}) => {
  const [company_name, setCompany_name] = useState(Customer.company_name ? Customer.company_name : "");
  const [address1, setAddress1] = useState(Customer.address1 ? Customer.address1 : "");
  const [city, setCity] = useState(Customer.city ? Customer.city : "");
  const [state, setState] = useState(Customer.state ? Customer.state : "");
  const [zip_code, setZip_code] = useState(Customer.zip_code ? Customer.zip_code : "");
  const [country, setCountry] = useState(Customer.country ? Customer.country : "");
  const [mcNumber, setMcNumber] = useState(Customer.mcNumber ? Customer.mcNumber : "");

  const handleSelect = async (address) => {
    if (address) {
      let response = await geocodeByAddress(address);
      if (response && response.length) {
        let place = response[0];
        let location = [];
        let lat;
        let lng;
        await geocodeByAddress(address)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            location.push(latLng);
          })
          .catch((error) => console.error("Error", error));
        setAddress1(place.formatted_address);
        SetRequired("address1")
        if (location && location.length) {
          lat = location[0].lat;
          lng = location[0].lng;
        }
        if (place.address_components.length) {
          let address1Value = "";
          let filterAddress1 = place.address_components.filter((data) => {
            if (data.types.indexOf("street_number") != "-1") {
              return data.types.indexOf("street_number") != "-1";
            } else if (data.types.indexOf("neighborhood") != "-1") {
              return data.types.indexOf("neighborhood") != "-1";
            }
          });
          let filterAddress2 = place.address_components.filter((data) => {
            if (data.types.indexOf("route") != "-1") {
              return data.types.indexOf("route") != "-1";
            } else if (data.types.indexOf("sublocality") != "-1") {
              return data.types.indexOf("sublocality") != "-1";
            }
          });
          if (
            filterAddress1 &&
            filterAddress1.length &&
            filterAddress2 &&
            filterAddress2.length
          ) {
            address1Value = `${filterAddress1[0].short_name} ${filterAddress2[0].short_name}`;
            setAddress1(address1Value);
            SetRequired("address1")
          } else {
              toastr.show("Select a valid address","error");
            return;
          }
          place.address_components.forEach((data) => {
            if (
              data.types.indexOf("locality") != "-1" ||
              data.types.indexOf("sublocality") != "-1"
            ) {
              setCity(data.long_name);
              SetRequired("city")
            }
            if (data.types.indexOf("administrative_area_level_1") != "-1") {
              setState(data.short_name);
              SetRequired("state")
            }

            if (data.types.indexOf("country") != "-1") {
              setCountry(data.short_name);
              SetRequired("zip_code")
            }

            if (data.types.indexOf("postal_code") != "-1") {
              setZip_code(data.long_name);
              SetRequired("country")
            }
          });
          let addressObj = {address,lat,lng,address1: address1Value,city,state,country,zip_code};
          let data = {_id : Customer._id,address : addressObj,address1 : address1Value}
          editTMSCustomer(data).then((result)=>{
            successColorResponse("address1")
            updateCustomer(result.data.data)
          }).catch((err)=>{
            console.log("err",err)
            errorColorResponse("address1")
          })
        }
      }
    }
  };

  const handleSelectCity = async (SelecTedcity) => {
    if (SelecTedcity) {
      let response = await geocodeByAddress(SelecTedcity);
      if (response && response.length) {
        let place = response[0];
        if (place.address_components.length) {
          place.address_components.forEach((data) => {
            if (data.types.indexOf("locality") != "-1") {
              let dataObj ={
                _id : Customer._id,
                city : data.short_name
              }
              editTMSCustomer(dataObj).then((result)=>{
                successColorResponse("city")
                updateCustomer(result.data.data)
              }).catch((err)=>{
                console.log("err", err);
                errorColorResponse("city");
              })
              setCity(data.short_name);
              SetRequired("city")
            }
          });
        }
      }
    }
  };

  const handleSelectState = async (state) => {
    if (state) {
      let response = await geocodeByAddress(state);
      if (response && response.length) {
        let place = response[0];
        if (place.address_components.length) {
          place.address_components.forEach((data) => {
            if (data.types.indexOf("administrative_area_level_1") != "-1") {
              let dataObj ={
                _id : Customer._id,
                state : data.short_name
              }
              editTMSCustomer(dataObj).then((result)=>{
                successColorResponse("state")
                updateCustomer(result.data.data)
              }).catch((err)=>{
                console.log("err", err);
                errorColorResponse("state");
              })
              setState(data.short_name);
              SetRequired("state")
            }
          });
        }
      }
    }
  };

  const handleSelectCountry = async (coutry) => {
    if (coutry) {
      let response = await geocodeByAddress(coutry);
      if (response && response.length) {
        let place = response[0];
        if (place.address_components.length) {
          place.address_components.forEach((data) => {
            if (data.types.indexOf("country") != "-1") {
              let dataObj ={
                _id : Customer._id,
                country : data.short_name
              }
              editTMSCustomer(dataObj).then((result)=>{
                successColorResponse("country")
                updateCustomer(result.data.data)
              }).catch((err)=>{
                console.log("err", err);
                errorColorResponse("country");
              })
              setCountry(data.short_name);
              SetRequired("country")
            }
          });
        }
      }
    }
  };

  const onBlurHandler = (key,val) =>{
    if(company_name == "" || zip_code == "" || mcNumber == ""){
      return
    }
    if(Customer[key] == val) return

    let data = {
      _id : Customer._id,
      [key] :val
    }
    editTMSCustomer(data).then((result)=>{
      successColorResponse(key)
      updateCustomer(result.data.data)
    }).catch((err)=>{
      console.log("err",err)
      errorColorResponse(key)
    })
  }

  return (
    <>
      <div class="form-row">
        <div class="col-lg-12">
          <div class="font-14 font-medium mb-15">Company</div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label class="col-form-label">
              <span class="text-danger">*</span> Company Name
            </label>
            <TextInputField
              type={"text"}
              name={"company_name"} 
              className={`form-control ${!company_name && "background-failure"}`}
              value={company_name}
              onChange={(e, key) => {
                setCompany_name(e.target.value);
                SetRequired(key);
              }}
              onBlur={(key, val) => onBlurHandler(key, val)}
              placeholder={"Company Name"}
              autoComplete="off"
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div class="form-group">
            <label class="col-form-label">
              <span class="text-danger">*</span> Address1
            </label>
            <PlacesAutocomplete
              type="text"
              className={`form-control ${!address1 && "background-failure"}`}
              autoComplete="off"
              required={true}
              value={address1}
              name="address1"
              onChange={(address1) => {
                if(address1){
                  setAddress1(address1);
                  SetRequired("address1")
                }else{
                  setAddress1(address1);
                  Required("address1")
                }
              }}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextInputField
                    type={"text"}
                    name={"address1"}
                    className={`form-control ${!address1 && "background-failure"}`}
                    placeholder="Enter Street Address"
                    {...getInputProps({
                      placeholder: "Enter Street Address",
                      className: "location-search-input form-control",
                    })}
                    value={address1}
                    required={true}
                  />
                  {suggestions && suggestions.length != 0 && (
                    <div className="react-mapbox-ac-suggestion">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active address-span"
                          : "suggestion-item address-span";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="form-group">
            <label class="col-form-label">
              <span class="text-danger">*</span> City
            </label>
            <PlacesAutocomplete
              type="text"
              className={`form-control ${!city && "background-failure"}`}
              component={TextInputField}
              autoComplete="off"
              required={true}
              value={city}
              name="city"
              onChange={(city) => {
                if (city) {
                  setCity(city);
                  SetRequired("city");
                } else {
                  setCity(city);
                  Required("city");
                }
              }}
              searchOptions={{ types: ["(cities)"] }}
              onSelect={handleSelectCity}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextInputField
                    type="text"
                    name="city"
                    {...getInputProps({
                      placeholder: "Enter City",
                      className: "location-search-input form-control",
                    })}
                    value={city}
                    placeholder={"Enter City"}
                    required={true}
                    className={`form-control ${!city && "background-failure"}`}
                  />
                  {suggestions && suggestions.length != 0 && (
                    <div className="react-mapbox-ac-suggestion">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active address-span"
                          : "suggestion-item address-span";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group">
            <label class="col-form-label">
              <span class="text-danger">*</span> State
            </label>
            <PlacesAutocomplete
              type="text"
              className={`form-control ${!state && "background-failure"}`}
              autoComplete="off"
              value={state}
              name="state"
              onChange={(state) => {
                if (state) {
                  setState(state);
                  SetRequired("state");
                } else {
                  setState(state);
                  Required("state");
                }
              }}
              searchOptions={{ types: ["administrative_area_level_1"] }}
              onSelect={handleSelectState}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextInputField
                    type="text"
                    autoComplete="off"
                    required={true}
                    name="state"
                    {...getInputProps({
                      placeholder: "Enter State",
                      className: "location-search-input form-control",
                    })}
                    value={state}
                    placeholder={"Enter State"}
                    className={`form-control ${!state && "background-failure"}`}
                  />
                  {suggestions && suggestions.length != 0 && (
                    <div className="react-mapbox-ac-suggestion">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active address-span"
                          : "suggestion-item address-span";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group">
            <label class="col-form-label">
              <span class="text-danger">*</span> Zip Code
            </label>
            <TextInputField
              type={"text"}
              name={"zip_code"}
              className={`form-control ${!zip_code && "background-failure"}`}
              value={zip_code}
              onChange={(e, key) => {
                setZip_code(e.target.value && e.target.value.replace(/[^\w\s]/gi, ""));
                SetRequired(key);
              }}
              onBlur={(key, val) => onBlurHandler(key, val)}
              placeholder={"Zip Code"}
              autoComplete="off"
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <label class="col-form-label">
              <span class="text-danger">*</span> Country
            </label>
            <PlacesAutocomplete
              type="text"
              className={`form-control ${!country && "background-failure"}`}
              autoComplete="off"
              onFocus={() => {}}
              required={true}
              value={country}
              name="country"
              onChange={(country) => {
                if(country){
                  setCountry(country);
                  SetRequired("country");
                }else{
                  setCountry(country);
                  Required("country")
                }
              }}
              searchOptions={{ types: ["country"] }}
              onSelect={handleSelectCountry}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextInputField
                    type="text"
                    required={true}
                    name="country"
                    autoComplete="off"
                    onFocus={() => {}}
                    {...getInputProps({
                      placeholder: "Enter Country",
                      className: "location-search-input form-control",
                    })}
                    placeholder={"Enter Country"}
                    className={`form-control ${!country && "background-failure"}`}
                  />
                  {suggestions && suggestions.length != 0 && (
                    <div className="react-mapbox-ac-suggestion">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active address-span"
                          : "suggestion-item address-span";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group new-set-label">
            <label class="col-form-label">MC Number</label>
            <TextInputField
              type={"text"}
              name={"mcNumber"}
              className={"form-control"}
              value={mcNumber}
              onChange={(e, key) => {
                setMcNumber(e.target.value && e.target.value.replace(/[^0-9]/gi, ""));
              }}
              placeholder={"MC Number"}
              onBlur={(key, val) => onBlurHandler(key, val)}
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <div class="hr-light my-20"></div>
    </>
  );
};

export default CompanyCustomerComponent;
