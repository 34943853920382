export const TMS_LOGIN_SUCCESS = "TMS_LOGIN_SUCCESS";
export const TMS_LOGOUT = "TMS_LOGOUT";
export const TMS_CREATE_LOAD = "TMS_CREATE_LOAD";
export const TMS_FETCH_PRICING = "TMS_FETCH_PRICING";
export const TMS_GET_USER_LOAD = "TMS_GET_USER_LOAD";
export const TMS_GET_USER_LOAD_FETCH_SUCCEEDED =
  "TMS_GET_USER_LOAD_FETCH_SUCCEEDED";
export const TMS_GET_CUSTOMERS_ALL = "TMS_GET_CUSTOMERS_ALL";
export const TMS_GET_CUSTOMERS_ALL_START = "TMS_GET_CUSTOMERS_ALL_START";
export const TMS_GET_CUSTOMERS_ALL_FAILED = "TMS_GET_CUSTOMERS_ALL_FAILED";
export const TMS_GET_CUSTOMERS_ALL_SUCCEEDED =
  "TMS_GET_CUSTOMERS_ALL_SUCCEEDED";
export const TMS_GET_CUSTOMERS = "TMS_GET_CUSTOMERS";
export const TMS_UPDATE_STATE = "TMS_UPDATE_STATE";
export const TMS_GET_FLEETCUSTOMERS = "TMS_GET_FLEETCUSTOMERS";
export const TMS_GET_FLEETCUSTOMERS_FAILED = "TMS_GET_FLEETCUSTOMERS";
export const TMS_GET_FLEETCUSTOMERS_START = "TMS_GET_FLEETCUSTOMERS";
export const TMS_GET_CUSTOMERS_START = "TMS_GET_CUSTOMERS_START";
export const TMS_GET_CUSTOMERS_FAILED = "TMS_GET_CUSTOMERS_FAILED";
export const TMS_GET_CUSTOMERS_SUCCEEDED = "TMS_GET_CUSTOMERS_SUCCEEDED";
export const TMS_GET_CUSTOMERS_FLEETSUCCEEDED =
  "TMS_GET_CUSTOMERS_FLEETSUCCEEDED";
export const TMS_GET_LOCATION_PROFILE = "TMS_GET_LOCATION_PROFILE";
export const TMS_GET_LOCATION_PROFILE_SUCCEEDED =
  "TMS_GET_LOCATION_PROFILE_SUCCEEDED";
export const TMS_NEW_LOAD = "TMS_NEW_LOAD";
export const TMS_NEW_LOAD_SUCCEEDED = "TMS_NEW_LOAD_SUCCEEDED";
export const LOCATION_PRICE = "LOCATION_PRICE";
export const UPDATE_LOCATION_PRICE = "UPDATE_LOCATION_PRICE";
export const GET_CALLERS_START = "GET_CALLERS_START";
export const GET_CALLERS_SUCCESS = "GET_CALLERS_SUCCESS";
export const GET_CALLERS_FAILED = "GET_CALLERS_FAILED";
export const GET_SHIPPERS_START = "GET_SHIPPERS_START";
export const GET_SHIPPERS_SUCCESS = "GET_SHIPPERS_SUCCESS";
export const GET_SHIPPERS_FAILED = "GET_SHIPPERS_FAILED";
export const GET_CONSIGNEES_START = "GET_CONSIGNEES_START";
export const GET_CONSIGNEES_SUCCESS = "GET_CONSIGNEES_SUCCESS";
export const GET_CONSIGNEES_FAILED = "GET_CONSIGNEES_FAILED";
export const GET_ALL_CUSTOMER_START = "GET_ALL_CUSTOMER_START";
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS";
export const GET_ALL_CUSTOMER_FAILED = "GET_ALL_CUSTOMER_FAILED";
export const GET_CHASSIS_TERMS_START = "GET_CHASSIS_TERMS_START";
export const GET_CHASSIS_TERMS_SUCCESS = "GET_CHASSIS_TERMS_SUCCESS";
export const GET_CHASSIS_TERMS_FAILED = "GET_CHASSIS_TERMS_FAILED";
export const GET_ALL_USER_PUBLIC_INFO_SUCCESS =
  "GET_ALL_USER_PUBLIC_INFO_SUCCESS";
export const GET_ALL_USER_PUBLIC_INFO_FAILED =
  "GET_ALL_USER_PUBLIC_INFO_FAILED";
export const GET_TIMESLOTS_SUCCESS = "GET_TIMESLOTS_SUCCESS";
export const GET_TIMESLOTS_START = "GET_TIMESLOTS_START"
export const GET_TIMESLOTS_FAILED = "GET_TIMESLOTS_START"

export const size = ["26'", "40'", "45'", "48'", "53'"];
export const trailerTypes = [
  "Dry Van",
  "Reefer",
  "Flat Bed",
  "Drop Deck",
  "Low Boy",
  "Double Drop Deck",
];
export const customerTypes = [
  { value: "caller", label: "CUSTOMER" },
  { value: "shipper", label: "PORT/SHIPPER" },
  { value: "consignee", label: "CONSIGNEE" },
  { value: "chassisPick", label: "CHASSIS PICK" },
  { value: "containerReturn", label: "CHASSIS RETURN" },
  { value: "chassisTermination", label: "CHASSIS TERMINATION" },
  { value: "ALL", label: "ALL" },
];
export const DOCUMENT_CUSTOMER = [
  "Check",
  "ACH",
  "Wire",
  "Credit",
  "Credit Card",
  "Cash",
  "Other",
];

export const ROUTING_LABELS =
{
  "PULLCONTAINER": "From",
  "DELIVERLOAD": "To",
  "DROPCONTAINER": "To",
  "LIFTOFF": "To",
  "RETURNCONTAINER": "To",
  "HOOKCONTAINER": "From",
  "LIFTON": "From"
}

export const loadEvents = {
  PULLCONTAINER : "PULLCONTAINER",
  DELIVERLOAD : "DELIVERLOAD",
  DROPCONTAINER: "DROPCONTAINER",
  HOOKCONTAINER:"HOOKCONTAINER",
  RETURNCONTAINER: "RETURNCONTAINER",
  CHASSISPICK : "CHASSISPICK",
  CHASSISTERMINATION : "CHASSISTERMINATION",
  LIFTOFF  : "LIFTOFF",
  LIFTON  : "LIFTON",
};

export const tmsDrops = ['DROPCONTAINER', 'LIFTOFF'];
export const tmsHooks = ['HOOKCONTAINER','LIFTON'];
export const loadEventRel = {
  'DROPCONTAINER': 'HOOKCONTAINER',
  'LIFTOFF': 'LIFTON'
}

// =>  /admin/emailAuditReport
export const MAIL_AUDIT_REPORT_HEADERS = ["Sender", "To", "Subject", "Date and Time", "Status"];
export const MAIL_AUDIT_REPORT_STATUS = [
  { label: "Delivered", value: "delivered" },
  { label: "Rejected", value: "rejected" },
  { label: "Rejected (Soft Bounce)", value: "Soft bounces" },
  { label: "Rejected (Hard Bounce)", value: "Hard bounces" },
  { label: "All", value: "all" }
];

export const DOWNLOAD_LIMIT_OPTIONS = [
  { label: "100", value: "100" },
  { label: "500", value: "500" },
  { label: "1000", value: "1000" },
  { label: "1500", value: "1500" },
  { label: "2000", value: "2000" },
  { label: "2500", value: "2500" },
  { label: "3000", value: "3000" },
  { label: "3500", value: "3500" },
  { label: "4000", value: "4000" },
];
