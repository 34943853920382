import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import {
  HomeReducer,
  LoginReducer,
  ProfileReducer,
} from '../Components/';

import {
  filterUsers,
  changeNav,
  changeEquipemt
} from './headerReducers';

import {
  problemContainers
} from './problemContainerReducers';

// Tms reducers
import TmsReducer from '../pages/tms/TmsReducer';
import containerReducer from './containerReducer';
import chassisReducer from './chassisReducer';
import onboardingReducer from './onboardingReducer';
import adminReducer from './adminReducer';
import StatesReducer from '../Components/DynamicPricing/StatesReducer';
import chargeCodeReducer from './chargeCodeReducer';
import dispatcherTableReducer from './dispatcherTableReducer';

export default combineReducers({
  HomeReducer,
  LoginReducer,
  form: formReducer,
  HeaderReducers: filterUsers,
  changeNav,
  changeEquipemt,
  TmsReducer,
  ProfileReducer,
  problemContainers,
  containerReducer,
  chassisReducer,
  onboardingReducer,
  adminReducer,
  StatesReducer,
  chargeCodeReducer,
  dispatcherTableReducer
});
