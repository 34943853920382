import React, { useState, useEffect } from "react";
import {
  IconCalendar,
  IconPlay,
  IconPause,
  IconLocation,
  IconLocationCancel,
} from "../../../../Components/Common/Icons";
import TimeRangeSlider from "../../../../Components/Common/TimeRangeSlider";
import { getStorage } from "../../../../services/Common.services";
import DatetimeRangePicker from "react-bootstrap-datetimerangepicker-edited";
import moment from "moment";
import useTrackingHistoryPlayer from "../useTruckHistoryPlayerController";

// const driverMarkerLayer = new window.L.FeatureGroup();

const TrackingHistoryPlayer = ({
  driverOrder,
  load,
  driver,
  mapInstance,
  toggleLiveLocation,
  liveLocationToggled = false,
}) => {
  const timeZone = getStorage("timeZone");
  const [playerState, _setPlayerState] = useState(false);
  const [loadingState, _setLoadingState] = useState(false);
  const [playerSeekState, _setPlayerSeekState] = useState(0);
  const [maxIndexState, _setMaxIndexState] = useState(0);
  const [startDateTimeFromPicker, _setStartDateTimeFromPicker] = useState(null);
  const [endDateTimeFromPicker, _setEndDateTimeFromPicker] = useState(null);
  const [driverMarkerLayer] = useState(
    new window.L.LayerGroup(null, {
      pane: "overlayPane",
    })
  );
  const [driverRouteLayer] = useState(
    new window.L.LayerGroup(null, {
      pane: "overlayPane",
    })
  );

  const [ 
    loadState, 
    errorState,
    labelList,
    playersState, 
    startDateTime,
    playerSeekingState, 
    endDateTime,
    unixtimeKeyList, 
    init, 
    fetchHistory, 
    play, 
    pause, 
    stop, 
    seekTo
  ] = useTrackingHistoryPlayer(mapInstance, driverMarkerLayer, timeZone, driverRouteLayer)

  // const controller = new TrackingHistoryPlayerController();
  // console.log("loading state----",controller.loadingState)

  init();

  useEffect(() => {
    // console.log("Driver Order: ", driverOrder);
    // console.log("Driver: ", driver);
    // console.log("Status: ", driverOrder && driverOrder.type);
    // console.log("Ref No: ", load && load.reference_number);

    fetchHistory({
        refNo: load && load.reference_number,
        startDate: null,
        endDate: null,
        driver: driver && driver._id,
        type: driverOrder && driverOrder.type,
        // driverOrderId: driverOrder && driverOrder._id,
      });
  }, [driverOrder, load, driver]);

  useEffect(() => {
    fetchHistory({
      refNo: load && load.reference_number,
      startDate: startDateTimeFromPicker,
      endDate: endDateTimeFromPicker,
      driver: driver && driver._id,
      type: driverOrder && driverOrder.type,
      // driverOrderId: driverOrder && driverOrder._id,
    });
  }, [endDateTimeFromPicker]);

  useEffect(() => {
    _setPlayerState(playersState);
  }, [playerState, playersState]);

  useEffect(() => {
    _setLoadingState(loadState);
  }, [loadingState, loadState]);

  useEffect(() => {
    _setPlayerSeekState(playerSeekingState);
  }, [playerSeekState, playerSeekingState]);

  useEffect(() => {
    _setMaxIndexState(unixtimeKeyList.length);
  }, [maxIndexState,unixtimeKeyList]);

  const handleDateTimePicker = (startDateTime, endDateTime) => {
    // console.log(
    //   "Start dae: ",
    //   startDateTime.tz(timeZone).toISOString(true),
    //   "End Date: ",
    //   endDateTime.tz(timeZone).toISOString(true)
    // );
    if (startDateTime && endDateTime) {
      const isoStartDateTime = moment
        .tz(startDateTime.format("YYYY-MM-DDTHH:mm:ss"), timeZone)
        .toISOString();
      const isoEndDateTime = moment
        .tz(endDateTime.format("YYYY-MM-DDTHH:mm:ss"), timeZone)
        .toISOString();

      _setStartDateTimeFromPicker(isoStartDateTime);
      _setEndDateTimeFromPicker(isoEndDateTime);
    }
  };

  const fromToDateFormatter = () => {
    if (liveLocationToggled) {
      return "Now";
    }

    if (!startDateTime) {
      return "...";
    }

    return `${
      startDateTime &&
      startDateTime.format("Do MMM YYYY")
    } to ${
      endDateTime &&
      endDateTime.format("Do MMM YYYY")
    }`;
  };
  return (
    <div
      className="card mb-0 p-10 shadow-5 d-flex align-items-center flex-row"
      style={{
        position: "absolute",
        bottom: "20px",
        left: "calc(440px + 120px)",
      }}
    >
      <div className="input-wrapper mr-10">
        {/* <input className="form-control" type="text" placeholder="From - To" />
        <span className="input-icon">
          <IconCalendar />
        </span> */}
        <DatetimeRangePicker
          timePicker
          timePicker24Hour
          drops="up"
          utc={false}
          startDate={startDateTime}
          endDate={endDateTime}
          onApply={(e, picker) => {
            handleDateTimePicker(picker.startDate, picker.endDate);
          }}
        >
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control"
              style={{ minWidth: "250px" }}
              value={fromToDateFormatter()}
              disabled
            />
            <span className="input-icon">
              <IconCalendar />
            </span>
          </div>
        </DatetimeRangePicker>
      </div>
      <button
        className="btn btn-primary"
        onClick={(e) => {
          if (toggleLiveLocation) {
            toggleLiveLocation(false);
          }
          if (!loadingState) {
            if (!playersState) {
              play();
            } else {
              pause();
            }
          }
        }}
      >
        {loadingState ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        ) : !playerState ? (
          <IconPlay />
        ) : (
          <IconPause />
        )}
      </button>
      {/* Use the class below ml-10 only if the button is display under any case even if its disabled to maintain the spacing */}
      {toggleLiveLocation && (
        <button
          className="btn btn-outline-light ml-10"
          onClick={() => {
            toggleLiveLocation(!liveLocationToggled);
            stop();
          }}
        >
          {!liveLocationToggled && <IconLocation />}
          {liveLocationToggled && <IconLocationCancel />}
        </button>
      )}
      {/* Range Slider */}
      <TimeRangeSlider
        className="ml-20 mr-10 align-self-end mb-1"
        labels={
          liveLocationToggled ? ["Live location data"] : labelList
        }
        minIndex={0}
        maxIndex={maxIndexState > 0 ? maxIndexState - 1 : 0} // maxIndex inclusive
        rangeValue={liveLocationToggled ? 0 : playerSeekState}
        disable={loadingState}
        onChange={(changedIndex) => {
          if (!liveLocationToggled) {
            seekTo(changedIndex);
          }
        }}
      />
    </div>
  );
};

export default TrackingHistoryPlayer;