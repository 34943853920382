import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js";
import $ from "jquery";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { notify } from "react-notify-toast";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import AsyncCreatableSelect from "react-select/async-creatable";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
import { convertTMSStatusName } from "../../../Components/Common/functions";
import {
  IconCheck,
  IconInfo,
  IconTimes,
  IconTrash,
  IconUpload,
} from "../../../Components/Common/Icons";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import configuration from "../../../config";
import {
  connectWithQuickBook,
  currencies,
  differnceFinder,
  disconnectQuickBooks,
  getGooglePlaces,
  getGoogleAutoPlaces,
  getStorage,
  toastr,
  fixesStateName,
  fixesCountryName,
  customerSubType,
  fileNameTypeFromUrl,
  isQuickbooksUser
} from "../../../services";
import { validateEmail } from "../../../utils";
import { loadFlags, directFactoryFlags } from "../Load/utility";
import CustomerAccessManagement from "../carrier/CustomerAccessManagement";
import { getCustomers } from "../Dispatcher/actionCreators";
import {
  ButtonComponent,
  DateTimeField,
  InputField,
  SelectField,
} from "../formComponent";
import UploadedDocuments from "../Load/DocumentList";
import {
  deleteCustomerDoc,
  getFleetCustomers,
  removeFleetFromCustomer,
} from "../services";
import * as actionCreateors from "./actionCreators";
import "./style.css";
import FileViewer from "react-file-viewer";
import confirm from "../../../Components/Common/ConfirmAert";
import FullScreenUploader from "../../../Components/FullScreenUploader";
import { validateCompanyNames } from "../carrier/UploadLoads/actionCreators";

mapboxgl.accessToken = configuration.map_box_api_key;

class AddCustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetCustomerdata: [],
      showFileUploadModal: false,
      features: [],
      selectedDoc: "",
      companyID: 0,
      progress: 0,
      qbRoute: "",
      isConnected: false,
      terminals: [],
      fileType: null,
      terminal: "",
      TerminalRequired:false,
      tab: 1,
      documentFile: null,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      type: "",
      address: "",
      isLoading: false,
      documents: [],
      allCustomerTags: [],
      allEmail: [],
      emailList: [],
      canEditLoad: props.Selected_customer.canEditLoad
        ? props.Selected_customer.canEditLoad
        : "",
      isTIROptional: props.Selected_customer.isTIROptional
        ? props.Selected_customer.isTIROptional
        : "",
      payType: props.Selected_customer.payType
        ? props.Selected_customer.payType
        : "",
      fleetCustomer: props.Selected_customer.fleetCustomer
        ? props.Selected_customer.payType
        : "",
      city: props.Selected_customer.city ? props.Selected_customer.city : "",
      state: props.Selected_customer.state ? props.Selected_customer.state : "",
      country: props.Selected_customer.country ? props.Selected_customer.country : "",
      credit_limit: props.Selected_customer.credit_limit
        ? props.Selected_customer.credit_limit
        : "",
      balance: props.Selected_customer.balance
        ? props.Selected_customer.balance
        : "",
      // notificationEmails:[],
      isSendNotificationEmail: props.Selected_customer.isSendNotificationEmail
        ? props.Selected_customer.isSendNotificationEmail
        : false,
      isSendPODEmail: props.Selected_customer.isSendPODEmail
        ? props.Selected_customer.isSendPODEmail
        : false,
      isSendContainerNotification: props.Selected_customer
        .isSendContainerNotification
        ? props.Selected_customer.isSendContainerNotification
        : false,
      notificationEmails: props.Selected_customer.notificationEmails
        ? props.Selected_customer.notificationEmails.split(",")
        : [],
        extraEmail: props.Selected_customer.extraEmail
        ? props.Selected_customer.extraEmail.split(",")
        : [],
      emailStatuses: props.Selected_customer.emailStatuses
        ? props.Selected_customer.emailStatuses.map((d) => ({
            value: d,
            label: convertTMSStatusName("IMPORT", d),
          }))
        : [],
      customerType: props.Selected_customer.customerType
        ? props.Selected_customer.customerType
        : ["ALL"],
      customerTags: props.Selected_customer.customerTags
        ? props.Selected_customer.customerTags
        : [],
      notes:
        props.Selected_customer && props.Selected_customer.notes
          ? props.Selected_customer.notes
          : "",
      lng:
        props &&
        props.Selected_customer &&
        props.Selected_customer.geofence &&
        props.Selected_customer.geofence.lng
          ? props.Selected_customer.geofence.lng
          : -74.0029393,
      lat:
        props &&
        props.Selected_customer &&
        props.Selected_customer.geofence &&
        props.Selected_customer.geofence.lat
          ? props.Selected_customer.geofence.lat
          : 40.717718,
      zoom:
        props &&
        props.Selected_customer &&
        props.Selected_customer.geofence &&
        props.Selected_customer.geofence.zoom
          ? props.Selected_customer.geofence.zoom
          : 10.91,
      polygonData:
        props &&
        props.Selected_customer &&
        props.Selected_customer.geofence &&
        props.Selected_customer.geofence.polygonData
          ? props.Selected_customer.geofence.polygonData
          : [],
      audits: [],
      emailStatusOptions:
        (props.Selected_customer &&
          props.Selected_customer.emailStatuses &&
          props.Selected_customer.emailStatuses.map((d) => ({
            value: d,
            label: convertTMSStatusName("IMPORT", d),
          }))) ||
        [],
      newTerminal: props.Selected_customer.newTerminal || [],
      CustID: null,
      portUsername: props.Selected_customer.portUsername
        ? props.Selected_customer.portUsername
        : "",
      extraSubCustomer: _.get(
        props,
        "Selected_customer.extraSubCustomer",
        []
      ).map((d) => ({ label: d.company_name, value: d._id })),
      salesAgents: _.get(props, "Selected_customer.salesAgents", []).map(
        (d) => ({ label: d.name, value: d._id })
      ),
      officeHoursStart: props.Selected_customer.officeHoursStart
        ? props.Selected_customer.officeHoursStart
        : "",
      officeHoursEnd: props.Selected_customer.officeHoursEnd
        ? props.Selected_customer.officeHoursEnd
        : "",
      currency: props.Selected_customer.currency
        ? props.Selected_customer.currency
        : "",
      defaultSubCustomer: [],
      defaultSalesAgents: [],

      isSendEmailDeliveryAppointmentChanged: props.Selected_customer
        .isSendEmailDeliveryAppointmentChanged
        ? props.Selected_customer.isSendEmailDeliveryAppointmentChanged
        : false,
      isSendEmailPricingDetailEmail: props.Selected_customer
        .isSendEmailPricingDetailEmail
        ? props.Selected_customer.isSendEmailPricingDetailEmail
        : false,
      isSendEmailContainerAvailable: props.Selected_customer
        .isSendEmailContainerAvailable
        ? props.Selected_customer.isSendEmailContainerAvailable
        : false,
      isSendEmailContainerAdd: props.Selected_customer.isSendEmailContainerAdd
        ? props.Selected_customer.isSendEmailContainerAdd
        : false,
      isSendEmailLoadAccepted: props.Selected_customer.isSendEmailLoadAccepted
        ? props.Selected_customer.isSendEmailLoadAccepted
        : false,
      isSendEmailContainerReadyForPickup: props.Selected_customer
        .isSendEmailContainerReadyForPickup
        ? props.Selected_customer.isSendEmailContainerReadyForPickup
        : false,

      doc_all:
        !props.Selected_customer.doc_all ||
        !props.Selected_customer.doc_tir ||
        !props.Selected_customer.doc_pod ||
        !props.Selected_customer.doc_none
          ? true
          : false,
      doc_tir: props.Selected_customer.doc_tir
        ? props.Selected_customer.doc_tir
        : false,
      doc_pod: props.Selected_customer.doc_pod
        ? props.Selected_customer.doc_pod
        : false,
      doc_none: props.Selected_customer.doc_none
        ? props.Selected_customer.doc_none
        : false,
      isOnlyPopup: false,
      isSaving: true,
      csvPreviewUrl: "",
      xlsPreviewUrl: "",
      address1: "",
      customerSubType:props.Selected_customer.customerSubType ? props.Selected_customer.customerSubType:'',
      companyField: props.Selected_customer.companyField ? props.Selected_customer.companyField : '',
      showFullDropScreen:false,
    };
    this.loggedInUser = null;
    this.tempEmail = null;

    this.updateArea = this.updateArea.bind(this);
    this.getCustomerOptions = this.getCustomerOptions.bind(this);
    this.listAllFleetManagers = this.listAllFleetManagers.bind(this);
    this.handleNotificationEmailsChange =
      this.handleNotificationEmailsChange.bind(this);
    this.handleExtraEmailsChange =
      this.handleExtraEmailsChange.bind(this);
  }

  deleteEquipmentDoc = (params) => {
    deleteCustomerDoc(params)
      .then((response) => {
        const documents =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.documents;

        this.props.added();
        this.props.updateState({ documents });
        this.setState({ imagePreviewUrl: "", selectedDoc: null });
        toastr.show("Successfully deleted", "success");
      })
      .catch((error) => console.log(error));
  };

  requirePassword = (value) => {
    if (!this.props.initialValues._id) {
      if (!value) {
        return "Please enter password";
      } else if (value && value.length < 5) {
        return "Password length should be greater than 5  character long*";
      } else if (value && value.length > 50) {
        return "Password length should be less than 50 character long*";
      } else {
        return undefined;
      }
    }
  };

  requireEmail = (value) => {
    if (!this.props.initialValues._id) {
      if (!value) {
        return "Please enter email";
      } else {
        return undefined;
      }
    }
  };

  requireTerminal = (value) =>{
    if (!value || value.length == 0) {
      return "Terminal is required*";
    } else {
      return undefined;
    }
  }
  
  onEmailInputChange = (textInput, { action }) => {
    this.setState({tempEmail: textInput})
    if (action === "input-change") {
      this.tempEmail = textInput
    }
    if (action === "set-value" || action === "menu-close") {
      this.tempEmail = null;
    }
    if (this.tempEmail !== null && action === "input-blur") {
      const emailList = this.state.emailList
      const findDuplicateTempEmail = emailList.find((email) => email.value === this.tempEmail )
      
      if(!findDuplicateTempEmail) {
        this.addEmails(this.tempEmail);
      }
      this.tempEmail = null;
    }
};

  address = (value) => {
    if (!value) {
      return "Address is not allowed to be empty";
    } else {
      return undefined;
    }
  };

  connectQuickBook = () => {
    connectWithQuickBook().then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.connected
      ) {
        this.setState({
          isConnected: true,
          qbRoute: response.data.data.redirecturl,
        });
      } else {
        if(
          response.data &&
          response.data.data &&
          response.data.data.error &&
          response.data.data.error.detail){
          toastr.show(response.data.data.error.detail , "error");
        }
        this.setState({});
      }
    });
  };

  handleNotificationEmailsChange(notificationEmails) {
    notificationEmails = notificationEmails.filter((d) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
    );
    this.setState({ notificationEmails });
  }

  handleExtraEmailsChange(extraEmail) {
    extraEmail = extraEmail.filter((d) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
    );
    this.setState({ extraEmail });
  }

  removeQuery = (queryNames) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    queryNames.forEach((q) => delete location.query[q]);
    browserHistory.push(location);
  };

  onSelect = (place) => {
    let lng = place.geometry.location.lng;
    let lat = place.geometry.location.lat;
    this.props.change("address", place.formatted_address);
    this.props.change("lat", lat);
    this.props.change("lng", lng);
    if (place.address_components.length) {
      let address1 = "";
      place.address_components.forEach((data) => {
        // if (data.types.indexOf("street_number") != "-1") {
        //   address1 = `${data.short_name}`;
        // }
        // if (data.types.indexOf("route") != "-1") {
        //   let sortAddress = `${address1} ${data.short_name}`;
        //   this.props.change("address", sortAddress);
        // }
        
        if (data.types.indexOf("locality") != "-1") {
          this.props.change("city", data.long_name);
        }
        if (data.types.indexOf("administrative_area_level_1") != "-1") {
          this.props.change("state", data.short_name);
        }

        if (data.types.indexOf("country") != "-1") {
          this.props.change("country", data.short_name);
        }

        if (data.types.indexOf("postal_code") != "-1") {
          this.props.change("zip_code", data.long_name);
        }
      });
    }
    this.setState({ features: [] });
  };

  removeFleetFromCustomer(_id) {
    removeFleetFromCustomer({ _id }).then((res) => {
      if (res.statusCode === 200) {
        toastr.show("Removed!", "success");
      }
    });
  }
  updateDocument = (e) => {
    this.setState({ fileType: e.target.value });
  };

  getGooglePlaceData = async () => {
    const value = this.state.address || "";
    if (value !== "") {
      const response = await getGoogleAutoPlaces(value);
      if(response.data && response.data.results.length > 0) {
        this.setState({ features: response.data.results });
      }
    }
  };

  getPlace = (e) => {
    const value = e.target.value;

    if (value) {
      this.getGooglePlaceData();
      this.setState({ address: value });
    } else {
      this.setState({ address: "", features: [] });
    }
  };

  difference(origObj, newObj) {
    function changes(newObj, origObj) {
      let arrayIndexCounter = 0;
      return _.transform(newObj, function (result, value, key) {
        if (!_.isEqual(value, origObj[key])) {
          let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
          result[resultKey] =
            _.isObject(value) && _.isObject(origObj[key])
              ? changes(value, origObj[key])
              : value;
        }
      });
    }
    return changes(newObj, origObj);
  }

  componentDidMount() {
    let self = this;
    if (self.props.tab === 1 || self.props.tab === 7) {
      $("#formContainer").show();
    } else {
      $("#formContainer").hide();
    }

    const selectedCustomer = self.props.Selected_customer || {};

    if (selectedCustomer._id) {
      self.props.actions
        .getTMSCustomerAudit(selectedCustomer._id)
        .then((res) => {
          const audits = res || [];
          const newAudit = [];
          if (audits && audits.length) {
            for (let i = 0; i < audits.length; i++) {
              if (i === 0) {
                newAudit.push(audits[i]);
              } else {
                const diff = this.difference(
                  audits[i - 1].data,
                  audits[i].data
                );
                audits[i].data = diff;
              }
            }
          }

          self.setState({ audits });
        });

      if (selectedCustomer.billingEmail) {
        const billingEmail = selectedCustomer.billingEmail.includes(",")
          ? selectedCustomer.billingEmail.split(",")
          : [selectedCustomer.billingEmail];
        const allEmail = billingEmail
          .filter((email) => email)
          .map((email) => ({ label: email, value: email }));
        this.setState({ allEmail, emailList: allEmail });
      }
    }

    self.props.actions.getCustomerTags().then((tags) => {
      let allTags = tags.map((tag) => ({ label: tag.tag, value: tag.tag }));
      self.setState({ allCustomerTags: allTags });
    });

    if (this.props && this.props.addType) {
      this.setState({ customerType: [`${this.props.addType}`] });
    }

    this.getCustomerOptions();
    this.listAllFleetManagers();
    this.updateState();
    this.setState({showFullDropScreen:true})
  }

  updateState() {
    const { documents } = this.props;
    this.setState({ documents });
  }
  componentWillUnmount() {
    this.setState({showFullDropScreen:false})
  }
  componentDidUpdate(prevProps, prevState) {
    const customerType =
      prevProps.Selected_customer && prevProps.Selected_customer.customerType
        ? prevProps.Selected_customer.customerType
        : [];
    if (
      this.props.Selected_customer.customerType &&
      !_.isEqual(customerType, this.props.Selected_customer.customerType)
    ) {
      this.setState({
        customerType: this.props.Selected_customer.customerType,
      });
    }

    if (!_.isEqual(this.state.documents, this.props.documents)) {
      this.updateState();
    }

    const customerTags =
      prevProps.Selected_customer && prevProps.Selected_customer.customerTags
        ? prevProps.Selected_customer.customerTags
        : [];
    const currentProps =
      this.props.Selected_customer && this.props.Selected_customer.customerTags
        ? this.props.Selected_customer.customerTags
        : [];
    if (currentProps && !_.isEqual(customerTags, currentProps)) {
      this.setState({ customerTags: currentProps });
    }

    const polygonData =
      prevProps.Selected_customer &&
      prevProps.Selected_customer.geofence &&
      prevProps.Selected_customer.geofence.polygonData
        ? prevProps.Selected_customer.geofence.polygonData
        : [];
    if (
      this.props.Selected_customer &&
      this.props.Selected_customer.geofence &&
      this.props.Selected_customer.geofence.polygonData &&
      !_.isEqual(polygonData, this.props.Selected_customer.geofence.polygonData)
    ) {
      this.setState({
        polygonData: this.props.Selected_customer.geofence.polygonData,
      });
    }
  }

  loadMap() {
    const { zoom } = this.state;
    const { lat, lng } = this.props.Selected_customer;
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [lng || this.state.lng, lat || this.state.lat],
      zoom: zoom,
    });

    var marker = new mapboxgl.Marker({
      color: "#3b93f1",
      draggable: true,
    })
      .setLngLat([lng || this.state.lng, lat || this.state.lat])
      .addTo(this.map);

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    this.map.addControl(this.draw);
    this.map.on("draw.create", this.updateArea);
    this.map.on("draw.delete", this.updateArea);
    this.map.on("load", () => {
      this.map.resize();
      this.drawPolygon(this.state.polygonData);
    });
  }

  drawPolygon(points) {
    if (points.length > 0) {
      this.draw.add({ type: "Polygon", coordinates: points });
    }
  }

  updateArea(e) {
    let data = this.draw.getAll();
    let polygonData = [];
    if (data.features.length === 0) {
      this.setState({ polygonData });
      return;
    }
    data.features.length > 1 && this.draw.delete(data.features.shift().id);
    polygonData = data.features[0].geometry.coordinates;
    this.setState({
      polygonData,
      lat: this.map.getCenter().lat.toFixed(4),
      lng: this.map.getCenter().lng.toFixed(4),
      zoom: this.map.getZoom().toFixed(2),
    });
  }

  componentWillMount = async () => {
    this.loggedInUser = JSON.parse(getStorage("loggedInUser"));
    this.fetchData();
  };
  fetchData = () => {
    getFleetCustomers()
      .then((data) => {
        if (data.length !== 0) {
          let id = this.props.Selected_customer.fleetCustomer;
          this.setState({
            fleetCustomer: data.filter((d) => d.value === id),
            fleetCustomerdata: data,
          });
        }
      })
      .catch((error) => {});
  };
  componentWillReceiveProps(props) {
    let self = this;
    let emailStatusOptions,
      isSendNotificationEmail,
      isSendPODEmail,
      notificationEmails,
      newTerminal,
      extraSubCustomer,
      isSendContainerNotification;
    if (props.tab === 1 || props.tab === 7) {
      $("#formContainer").show();
    } else {
      $("#formContainer").hide();
    }
    if (props.tab === 3) {
      self.loadMap();
    }
   
    if(props.Selected_customer.credit_limit != this.state.credit_limit){
      this.setState({credit_limit : props.Selected_customer.credit_limit})
    }
    if(props.Selected_customer.balance != this.state.balance){
      this.setState({balance :  props.Selected_customer.balance})
    }
    
  }

  disconnectQuickBooks = () => {
    disconnectQuickBooks()
      .then((response) => {
        this.setState({ isConnected: false });
      })
      .catch((err) => {});
  };

  uploadResult = (file, preview, type) => {
    if(file.type.match("image/png")|| file.type.match("image/jpg") ||file.type.match("image/jpeg")  || file.type.match("application.pdf") ||
    file.type.match("text/csv") || file.type.match("application/vnd.ms-excel") || file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")){
      this.setState({showFileUploadModal:true})
      if (file.type.indexOf("image") == -1) {
        type = file.type.split("/")[1];
      }
      setTimeout(() => {
        this.setState({ progress: 100, isSaving: false });
      }, 1000);
      this.setState(
        {
          documentFile: null,
          [`${type}PreviewUrl`]: null,
          pdfPreviewUrl: null,
          [type]: null,
        },
        () => {
          this.setState({
            documentFile: file,
            [`${type}PreviewUrl`]: preview,
            [type]: preview,
          });
        }
      );
      if (type == "pdf") {
        this.setState({ imagePreviewUrl: null, csvPreviewUrl: null, xlsPreviewUrl: null, });
        let base64result = preview.split(",")[1];
        var pdfData = atob(base64result);
        var loadingTask = pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then(function (pdf) {
          // Fetch the first page.
          pdf.getPage(1).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport(scale);
            // Prepare canvas using PDF page dimensions.
            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // Render PDF page into canvas context.
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          });
        });
      } else if(type === "csv") {
        let csvPreviewUrl = {pre: preview, name: file.name};
        this.setState({csvPreviewUrl, xlsPreviewUrl: null, pdfPreviewUrl: null, imagePreviewUrl: null})
      } else if(type === "vnd.ms-excel" || type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        let xlsPreviewUrl = {pre: preview, name: file.name};
        this.setState({xlsPreviewUrl, csvPreviewUrl: null, pdfPreviewUrl: null, imagePreviewUrl: null})
      }
    } else {
      toastr.show("Please enter only PDF, CSV, XLS, XLSX and/or Image (JPG, PNG, JPEG ) file formats.", "error");
      return;
    }
  };

 

  downloadDoc(doc) {
    $.ajax({
      url: doc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: (blob) => {
        const extension = fileNameTypeFromUrl(doc.url)[1];
        if (extension != "pdf") {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          link.download = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.click();
        } else {
          var blob = new Blob([blob], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.download = fileName;
          link.click();
        }
      },
    });
  }

  setCustomerType(arr) {
    let { customerType } = this.state;
    if (
      customerType.length == 1 &&
      customerType.indexOf("ALL") > -1 &&
      arr.indexOf("ALL") === -1
    ) {
      customerType = arr;
      this.setState({ customerType });
      return;
    }
    if (customerType.intersect(arr).length > 0) {
      customerType = customerType.subtract(arr);
    } else {
      customerType = customerType.insert(arr);
    }
    if (arr.indexOf("ALL") > -1) {
      customerType = ["ALL"];
    }
    if (
      customerType.intersect([
        "caller",
        "shipper",
        "containerReturn",
        "consignee",
        "chassisTermination",
        "chassisPick",
      ]).length === 6
    ) {
      customerType = ["ALL"];
    }

    if (customerType.length === 0) {
      customerType = ["ALL"];
    }

    this.setState({ customerType });
  }

  save = () => {
    $("#profileButton").click();
  };

  getCustomerOptions = (search) => {
    if (!search) {
      return new Promise((resolve, reject) => {
        getCustomers(null, 10).then((options) => {
          const allDatas = options.filter(
            (op) => op.value !== _.get(this.props, "Selected_customer._id")
          );
          const subCustomers = [];

          allDatas.forEach((obj) => {
            subCustomers.push({
              value: obj._id,
              label: `${obj.company_name}, ${
                obj.address ? obj.address.address : ""
              }`,
              allInfos: obj,
            });
          });

          this.setState({ defaultSubCustomer: subCustomers });

          resolve(subCustomers);
        });
      });
    }

    return new Promise((resolve, reject) => {
      getCustomers(search).then((options) => {
        const result = options.filter(
          (op) => op.value !== _.get(this.props, "Selected_customer._id")
        );
        resolve(result);
      });
    });
  };

  listAllFleetManagers = (search) => {
    if (!search) {
      return new Promise((resolve, reject) => {
        this.props.actions
          .listAllFleetManagers({ salesAgent: true }, 10)
          .then((options) => {
            const salesAgents = [];
            let allDatas = options || [];

            allDatas.forEach((obj) => {
              salesAgents.push({
                value: obj._id,
                label: `${obj.name}`,
                allInfos: obj,
              });
            });

            this.setState({ defaultSalesAgents: salesAgents });

            resolve(salesAgents);
          });
      });
    }

    let searchData = {
      searchTerm: search,
      salesAgent: true,
    };
    return new Promise((resolve, reject) => {
      this.props.actions.listAllFleetManagers(searchData).then((options) => {
        resolve(options);
      });
    });
  };

  isDisabled(permission) {
    switch (permission) {
      case "credit-limit":
        if (this.loggedInUser && this.loggedInUser.role === "fleetmanager") {
          console.log(
            ' ---- this.loggedInUser.permissions.includes("other_profile_customer_credit - limit") ---- ',
            !this.loggedInUser.permissions.includes(
              "customer_credit_limit"
            )
          );
          return !this.loggedInUser.permissions.includes(
            "customer_credit_limit"
          );
        }
        return false;
      case "payment-terms":
        if (this.loggedInUser && this.loggedInUser.role === "fleetmanager") {
          console.log(
            ' ---- this.loggedInUser.permissions.includes("other_profile_customer_payment-limit - limit") ---- ',
            !this.loggedInUser.permissions.includes(
              "customer_payment_terms"
            )
          );
          return !this.loggedInUser.permissions.includes(
            "customer_payment_terms"
          );
        }
        return false;

      default:
        return false;
    }
  }

  addCustomerTags(values) {
    const formData = new FormData();
    formData.append("tag", values);
    this.setState({ customerTags: [...this.state.customerTags, values] }, () =>
      console.log("customerTag :: ", this.state.customerTags)
    );
    this.props.actions
      .addCustomerTags(formData)
      .then(() => {
        toastr.show("Customer Tags Add", "success");
      })
      .catch((err) => {});
  }

  addEmails(values) {
    const value = { label: values, value: values };
    this.setState({ emailList: [...this.state.emailList, value] });
  }

  getCustomerTags = async (strSearch) => {
    let freeze = true;
    return new Promise(async (res, err) => {
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer);
        let timer = setTimeout(async () => {
          freeze = false;
          const r = await this.props.actions.getCustomerTags({
            searchText: strSearch,
          });
          res(r.map((d) => ({ label: d.tag, value: d.tag })));
        }, 1000);
      });

      p.then(function (x) {
        res(x);
      });
    });
  };

  validateFormSubmit = () => {
    const { valid } = this.props;
    if (!valid) {
      toastr.show("Please enter all mandatory fields and try again!", "error");
    }
  };

  billingEmail = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)
      ? false
      : true;

  setFeatureValue = (key, val) => {
    this.setState({ [key]: val });
    if (key === "doc_all" && val) {
      this.setState({
        doc_all: true,
        doc_tir: false,
        doc_pod: false,
        doc_none: false,
      });
    } else if (key === "doc_all" && !val) {
      this.setState({ doc_all: false });
    } else if (key === "doc_none" && val) {
      this.setState({
        doc_all: false,
        doc_tir: false,
        doc_pod: false,
        doc_none: true,
      });
    } else if (key === "doc_none" && !val) {
      this.setState({ doc_none: false });
    } else if (key !== "doc_all" && key !== "doc_none") {
      this.setState({ doc_none: false, doc_all: false });
    }
  };

  handleShowFileUploadModal = () => {
    this.setState({
      showFileUploadModal: true,
      documentFile: null,
      progress: 0,
      fileType: null,
      isSaving: true
    });
  };
  deleteDocument = (id) => {
    confirm(
      "Remove Document?",
      "Are you sure you want to remove this document?",
      (confirm) => {
        if (confirm) {
          this.deleteEquipmentDoc({
            docId: id,
            _id: this.props.Selected_customer._id,
          })
        }
      }
    );
  };
  handleChange = (address1) => {
    this.setState({ address1 });
  };
  handleCityChange = (city) => {
    this.setState({ city });
  };
  handleStateChange = (state) => {
    this.setState({ state });
  };
  handleCountryChange = (country) => {
    this.setState({ country });
  };
 
  handleSelect = async (address) => {
    if(address) {
      let response = await geocodeByAddress(address);
      if(response && response.length) {
        let place = response[0];
        let location = [];
        await geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {location.push(latLng)})
        .catch(error => console.error('Error', error));
        // let lng = place.geometry.location.lng;
        // let lat = place.geometry.location.lat;
        this.props.change("address", place.formatted_address);
        this.state.address = place.formatted_address;
        if(location && location.length) {
          this.props.change("lat", location[0].lat);
          this.props.change("lng", location[0].lng);
        }
        if (place.address_components.length) {
          let address1 = "";
          let filterAddress1 = place.address_components.filter(data => {
            if(data.types.indexOf("street_number") != "-1") {
              return data.types.indexOf("street_number") != "-1";
            } else if(data.types.indexOf("neighborhood") != "-1") {
              return data.types.indexOf("neighborhood") != "-1"
            }
          });
          let filterAddress2 = place.address_components.filter(data => {
            if(data.types.indexOf("route") != "-1") {
              return data.types.indexOf("route") != "-1";
            } else if(data.types.indexOf("sublocality") != "-1") {
              return data.types.indexOf("sublocality") != "-1";
            }
          });
          if((filterAddress1 && filterAddress1.length) && (filterAddress2 && filterAddress2.length)) {
            address1 = `${filterAddress1[0].short_name} ${filterAddress2[0].short_name}`;
            this.state.address1 = address1;
            this.props.change("address1", address1);
          }
          place.address_components.forEach((data) => {
            
            if (data.types.indexOf("locality") != "-1" || data.types.indexOf("sublocality") != "-1") {
              this.props.change("city", data.long_name);
            }
            if (data.types.indexOf("administrative_area_level_1") != "-1") {
              this.props.change("state", data.short_name);
            }
  
            if (data.types.indexOf("country") != "-1") {
              this.props.change("country", data.short_name);
            }
  
            if (data.types.indexOf("postal_code") != "-1") {
              this.props.change("zip_code", data.long_name);
            }
          });
        }
      }
    }
  };
  handleSelectCity = async (city) => {
    if(city) {
      let response = await geocodeByAddress(city);
      if(response && response.length) {
        let place = response[0];
        if (place.address_components.length) {
          place.address_components.forEach((data) => {
            if (data.types.indexOf("locality") != "-1") {
              this.props.change("city", data.short_name);
            }
          });
        }
      }
    }
  }
  handleSelectState = async (state) => {
    if(state) {
      let response = await geocodeByAddress(state);
      if(response && response.length) {
        let place = response[0];
        if (place.address_components.length) {
          place.address_components.forEach((data) => {
            if (data.types.indexOf("administrative_area_level_1") != "-1") {
              this.props.change("state", data.short_name);
            }
          });
        }
      }
    }
  }
  handleSelectCountry = async (coutry) => {
    if(coutry) {
      let response = await geocodeByAddress(coutry);
      if(response && response.length) {
        let place = response[0];
        if (place.address_components.length) {
          place.address_components.forEach((data) => {
            if (data.types.indexOf("country") != "-1") {
              this.props.change("country", data.short_name);
            }
          });
        }
      }
    }
  }

  isDuplicate = (arr) => {
    var valueArr = arr.map(function(item){ return item.label });

    var isDuplicate = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx 
    });

    return isDuplicate;
  }

  findDuplicateEmails = (arr) => {
    const newSet = new Set();
    const filteredArr = arr.filter(el => {
      const duplicate = newSet.has(el.label);
      newSet.add(el.label);
      return !duplicate;
    });
    return filteredArr;
  };

  _handleKeyDown = (e) => {
    const emailList = this.state.emailList;
    const checkDuplicateEmail = emailList.some((el) => el.value === this.state.tempEmail);
    if (e.key === 'Tab') {
      if(checkDuplicateEmail) {
        e.preventDefault();
        toastr.show("Please check! You have added a duplicate email", "warning");
        return;
      } 
    }
  }
  render() {
    var { handleSubmit, reset } = this.props;
    let { customerType, documents, address } = this.state;
    const isConsignee = customerType.intersect(["consignee"]).length > 0;
    let Terminal = [...this.props.Terminals];
    if (Terminal && Terminal.length > 0) {
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }
    
    return (
      <React.Fragment>
        {this.props.isLoader && <LoaderBar></LoaderBar>}
        {this.props.tab == 2 && (
          <React.Fragment>
              <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                <h4 className="mb-0 font-20">
                {documents && documents.length === 0 ? "No ": documents.filter((doc) => doc.checked).length} Documents
                </h4>
                <button
                  className="ml-auto btn btn-primary"
                  onClick={() => this.handleShowFileUploadModal()}
                >
                  <IconUpload className="mr-2" />
                  Upload Document
                </button>
              </div>
              {documents && (
                <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                <UploadedDocuments
                  listDocuments={documents.filter((doc) => doc.checked)}
                  deleteDocument={this.deleteDocument}
                  onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                  newView={this.newView}
                  selectedDoc={this.state.selectedDoc}
                  setSelectedDoc={(docIndex) => {
                    this.setState({ selectedDoc: docIndex });
                  }}
                  handleShowUploadModal={this.handleShowUploadModal}
                  uploadResult={this.uploadResult}
                  multi={false} 
                  type={"image"}
                />
                </div>
              )}
            {/* <h4 className="card-title">Documents</h4>
         <div className="table-responsive">
              <table className="table table-card">
                <thead>
                  <tr>
                    <th width="200px" scope="col">
                      Doc Type
                    </th>
                    <th scope="col">Date</th>
                    <th scope="col">User</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents &&
                    documents.length > 0 &&
                    documents.map((info, key) => {
                      return (
                        <tr
                          key={key}
                          id={info._id}
                          style={{
                            display: !info.checked ? "none" : "",
                          }}
                        >
                          <td>{info.type}</td>
                          <td>{moment(info.date).format("MM/DD/YYYY")}</td>
                          <td>{info.userId.name}</td>
                          <td>
                            <span className="lint d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() => {
                                  this.previewImage(info);
                                }}
                                className="btn btn-none text-primary"
                              >
                                <i
                                  style={{
                                    color:
                                      this.state.selectedDoc &&
                                      this.state.selectedDoc._id === info._id
                                        ? "#a5db03"
                                        : "",
                                  }}
                                  className="uil uil-eye font-18"
                                />
                              </button>

                              <button
                                className="btn btn-none text-primary"
                                onClick={() => this.downloadDoc(info)}
                              >
                                <IconDownload />
                              </button>
                              <button
                                type="button"
                                className="btn btn-none text-danger"
                                onClick={() =>
                                  this.deleteEquipmentDoc({
                                    docId: info._id,
                                    _id: this.props.Selected_customer._id,
                                  })
                                }
                              >
                                <IconTrash />
                              </button>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>   */}
          </React.Fragment>
        )}

        <div
          id="formContainer"
          className={`${
            this.props.initialValues._id ? "card pt-30 px-20" : ""
          }`}
          style={{
            display: this.props.tab == 1 || this.props.tab == 7 ? "" : "none",
          }}
        >
          {this.props.tab === 1 && (
            <div>
              {this.props.isQuickAdd && (
                <div className="font-medium mb-10 font-14">Customer Type</div>
              )}
              <div className="d-flex mb-4">
                <div className="form-check form-check-primary pr-30 ml-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="caller"
                    onClick={() => this.setCustomerType(["caller"])}
                    checked={
                      customerType.intersect(["caller"]).length > 0
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label" for="caller">
                    Customer
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="shipper"
                    onClick={() =>
                      this.setCustomerType(["shipper", "containerReturn"])
                    }
                    checked={
                      customerType.intersect(["shipper", "containerReturn"])
                        .length > 0
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label" for="shipper">
                  Terminals
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="consignee"
                    onClick={() => this.setCustomerType(["consignee"])}
                    checked={
                      customerType.intersect(["consignee"]).length > 0
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label" for="consignee">
                  Warehouses
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="chassisPick"
                    onClick={() =>
                      this.setCustomerType([
                        "chassisTermination",
                        "chassisPick",
                      ])
                    }
                    checked={
                      customerType.intersect([
                        "chassisPick",
                        "chassisTermination",
                      ]).length > 0
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label" for="chassisPick">
                  Yards
                  </label>
                </div>
                <div className="form-check form-check-primary pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="ALL"
                    onClick={() => this.setCustomerType(["ALL"])}
                    checked={
                      customerType.intersect(["ALL"]).length > 0 ? true : false
                    }
                  />
                  <label className="form-check-label" for="all">
                    All
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="">
            <input type="hidden" value="something" />
            <form
              onSubmit={handleSubmit(async(data) => {
                this.setState({ isLoading: true });
                data['isAddressVerified'] = false;
                if(!this.state.address1 && data.address) {
                  data['address1'] = data.address.split(',')[0];
                } else {
                  data['address1'] = this.state.address1;
                }
                if(data.address.split(',')[0] !== data.address1) {
                  data.address = data.address.replace(/^[^,]+, */, `${data.address1}, `);
                  this.state.address = data.address;
                }
                if(!data.address) {
                  data.address = `${data.address1 ? data.address1 : ""} ${data.city ? data.city : ""} ${data.zip_code ? data.zip_code : ""} ${data.state ? data.state : ""} ${data.country ? data.country : ""}`
                }
                if (this.state.currency) {
                  data["currency"] = this.state.currency;
                }
                if(this.state.customerSubType){
                  data["customerSubType"] = this.state.customerSubType
                }
                if (this.state.canEditLoad) {
                  data["canEditLoad"] = this.state.canEditLoad;
                }
                if (this.state.isTIROptional) {
                  data["isTIROptional"] = this.state.isTIROptional;
                }
                if (this.state.notes) {
                  data["notes"] = this.state.notes;
                }
                if (this.state.payType) {
                  data["payType"] = this.state.payType;
                }
                
                let emailList = this.state.emailList;
                // validation before saving
                const checkDuplicateEmail = emailList.some((el) => el.value === this.state.tempEmail);
                if(checkDuplicateEmail) {
                  toastr.show("Please check! You have added a duplicate email", "warning");
                  return;
                } 
                
                // validation on saving
                const checkDuplicateOnSave = this.isDuplicate(emailList)
                if(checkDuplicateOnSave) {
                  emailList = this.findDuplicateEmails(emailList);
                  this.setState({emailList: emailList })
                }
                
                const inValidEmailList = emailList.filter(
                  (email) => !this.billingEmail(email.value)
                );
                if (inValidEmailList && inValidEmailList.length > 0) {
                  toastr.show("Please check the format of the billing email.", "error");
                  this.setState({ isLoading: false });
                  return;
                }

                let billingEmail = "";
                (emailList || []).forEach((email) => {
                  billingEmail +=
                    emailList.length === 1 ? email.value : email.value + ",";
                });

                data["billingEmail"] = billingEmail;
                data["isSendNotificationEmail"] =
                  this.state.isSendNotificationEmail;
                data["isSendEmailDeliveryAppointmentChanged"] =
                  this.state.isSendEmailDeliveryAppointmentChanged;
                data["isSendEmailPricingDetailEmail"] =
                  this.state.isSendEmailPricingDetailEmail;
                data["isSendEmailContainerAvailable"] =
                  this.state.isSendEmailContainerAvailable;
                data["isSendEmailContainerAdd"] =
                  this.state.isSendEmailContainerAdd;
                data["isSendEmailLoadAccepted"] =
                  this.state.isSendEmailLoadAccepted;
                data["isSendEmailContainerReadyForPickup"] =
                  this.state.isSendEmailContainerReadyForPickup;

                data["isSendContainerNotification"] =
                  this.state.isSendContainerNotification;
                data["isSendPODEmail"] = this.state.isSendPODEmail;
                data["notificationEmails"] =
                  this.state.notificationEmails.toString();
                data["extraEmail"] =
                  this.state.extraEmail.toString();
                data["emailStatuses"] = this.state.emailStatusOptions.map(
                  (d) => d.value
                );
                if (this.state.fleetCustomer) {
                  data["fleetCustomer"] = this.state.fleetCustomer.value;
                }
                if (this.state.documentFile) {
                  data.documentFile = this.state.documentFile;
                  data.fileType = this.state.fileType;
                }
                data["geofence"] = JSON.stringify({
                  center: [this.state.lng, this.state.lat],
                  zoom: this.state.zoom,
                  polygon: this.state.polygonData,
                });
                data["customerType"] = this.state.customerType;
                data["notes"] = this.state.notes;
         
                if((getStorage("currentUserRole") == "fleetmanager" &&
                configuration.idForTerminal.indexOf(
                  JSON.parse(getStorage("loggedInUser")).fleetManager
                    .carrier
                ) != -1) ||
                (getStorage("currentUserRole") == "carrier" &&
                  configuration.idForTerminal.indexOf(
                    getStorage("currentUserID")
                  ) != -1) ||this.state.newTerminal)
                 { 
                   if(this.state.newTerminal && this.state.newTerminal.length>0){
                    data["newTerminal"] = this.state.newTerminal.filter(d => d.value).map((D) => {
                      return D.value
                    }) 
                   }
                } 
                if (this.state.CustID) {
                  data["_id"] = this.state.CustID;
                }
                if (this.state.customerTags) {
                  data["customerTags"] = JSON.stringify(
                    this.state.customerTags
                  );
                }
                if(this.state.extraSubCustomer && this.state.extraSubCustomer.length > 0){
                  data.extraSubCustomer = JSON.stringify(this.state.extraSubCustomer.map((d) => d.value));
                }else {
                  data.extraSubCustomer = []
                }
                // data.extraSubCustomer = this.state.extraSubCustomer;
                data.salesAgents = this.state.salesAgents;

                if (!data.officeHoursStart || data.officeHoursStart === "") {
                  delete data.officeHoursStart;
                }

                if (data.company_name) {
                  data.company_name = data.company_name.trim();
                }

                if (!data.officeHoursEnd || data.officeHoursEnd === "") {
                  delete data.officeHoursEnd;
                }
                data["doc_all"] = this.state.doc_all;
                data["doc_tir"] = this.state.doc_tir;
                data["doc_pod"] = this.state.doc_pod;
                data["doc_none"] = this.state.doc_none;


                this.props
                  .submitForm(data)
                  .then((addedDataId) => {
                    if (addedDataId) {
                      this.setState({ CustID: addedDataId });
                      this.props.closeModal()
                    } else if (!this.props.initialValues._id) {
                      reset();
                      this.setState({
                        isSendNotificationEmail: false,
                        isSendContainerNotification: false,
                        isSendPODEmail: false,
                      });
                    }
                    if (!addedDataId) {
                      this.setState({
                        features: [],
                        documentFile: null,
                        imagePreviewUrl: null,
                        pdfPreviewUrl: null,
                        fileType: null,
                        csvPreviewUrl: null, 
                        xlsPreviewUrl: null
                      });
                    }

                    this.setState({
                      isLoading: false,
                      showFileUploadModal: false,
                    });
                    if (this.props.isQuickAdd) {
                      this.setState({ isOnlyPopup: !this.props.isOnlyPopup });
                    }
                  })
                  .catch((e) => {
                    this.setState({ isLoading: false });
                  });
              })}
            >
              {this.props.isQuickAdd && <div class="hr-light my-20"></div>}
              {this.props.tab === 1 && (
             <React.Fragment>
                <div className="form-row">
                  <div className="col-lg-12">
                    <div className="font-14 font-medium mb-15">Company</div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <Field
                        type="text"
                        className="form-control"
                        component={InputField}
                        autoComplete="company_name"
                        required={true}
                        name="company_name"
                        label="Company Name"
                        validate={[required, validateWhiteSpace]}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                    <PlacesAutocomplete
                        type="text"
                        className="form-control"
                        component={InputField}
                        autoComplete="off"
                        required={true}
                        value={this.state.address1}
                        name="address1"
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        validate={[required, validateWhiteSpace]}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <Field
                              type="text"
                              component={InputField}
                              autoComplete="off"
                              required={true}
                              name="address1"
                              label="Address1"
                                {...getInputProps({
                                  placeholder: 'Enter Street Address',
                                  className: 'location-search-input form-control',
                                })}
                              validate={[required, validateWhiteSpace]}
                            />
                              {suggestions &&
                                suggestions.length != 0 && (
                                  <div className="react-mapbox-ac-suggestion">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                      ? 'suggestion-item--active address-span'
                                      : 'suggestion-item address-span';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                    })}
                                  </div>
                                )
                              }
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <PlacesAutocomplete
                        type="text"
                        className="form-control"
                        component={InputField}
                        autoComplete="off"
                        required={true}
                        value={this.state.city}
                        name="city"
                        onChange={this.handleCityChange}
                        searchOptions={{ types: ['(cities)'] }}
                        onSelect={this.handleSelectCity}
                        validate={[required, validateWhiteSpace]}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <Field
                              type="text"
                              component={InputField}
                              autoComplete="off"
                              required={true}
                              name="city"
                              label="City"
                                {...getInputProps({
                                  placeholder: 'Enter City',
                                  className: 'location-search-input form-control',
                                })}
                              validate={[required, validateWhiteSpace]}
                            />
                              {suggestions &&
                                suggestions.length != 0 && (
                                  <div className="react-mapbox-ac-suggestion">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                      ? 'suggestion-item--active address-span'
                                      : 'suggestion-item address-span';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                    })}
                                  </div>
                                )
                              }
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="form-group">
                    <PlacesAutocomplete
                        type="text"
                        className="form-control"
                        component={InputField}
                        autoComplete="off"
                        required={true}
                        value={this.state.state}
                        name="state"
                        onChange={this.handleStateChange}
                        searchOptions={{ types: ['administrative_area_level_1'] }}
                        onSelect={this.handleSelectState}
                        validate={[required, validateWhiteSpace]}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <Field
                              type="text"
                              component={InputField}
                              autoComplete="off"
                              required={true}
                              name="state"
                              label="State"
                                {...getInputProps({
                                  placeholder: 'Enter State',
                                  className: 'location-search-input form-control',
                                })}
                              validate={[required, validateWhiteSpace]}
                            />
                              {suggestions &&
                                suggestions.length != 0 && (
                                  <div className="react-mapbox-ac-suggestion">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                      ? 'suggestion-item--active address-span'
                                      : 'suggestion-item address-span';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                    })}
                                  </div>
                                )
                              }
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <Field
                        component={InputField}
                        type="text"
                        className="form-control"
                        required={true}
                        label="Zip Code"
                        validate={[required, validateWhiteSpace]}
                        normalize={zipcodeNormalize}
                        name="zip_code"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                    <PlacesAutocomplete
                        type="text"
                        className="form-control"
                        component={InputField}
                        autoComplete='off'
                        onFocus={()=>{}}
                        required={true}
                        value={this.state.country}
                        name="country"
                        onChange={this.handleCountryChange}
                        searchOptions={{ types: ['country'] }}
                        onSelect={this.handleSelectCountry}
                        validate={[required, validateWhiteSpace]}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <Field
                              type="text"
                              component={InputField}
                              required={true}
                              name="country"
                              label="Country"
                              autoComplete='off'
                              onFocus={()=>{}}
                                {...getInputProps({
                                  placeholder: 'Enter Country',
                                  className: 'location-search-input form-control',
                                })}
                              validate={[required, validateWhiteSpace]}
                            />
                              {suggestions &&
                                suggestions.length != 0 && (
                                  <div className="react-mapbox-ac-suggestion">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                      ? 'suggestion-item--active address-span'
                                      : 'suggestion-item address-span';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                    })}
                                  </div>
                                )
                              }
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            component={InputField}
                            type="text"
                            className="form-control"
                            label="MC number"
                            normalize={numberNormalize}
                            name="mcNumber"
                          />
                        </div>
                      </div>
                </div>
                <div className="hr-light mt-15 mb-20"></div>
                </React.Fragment>
              )}
              
              {this.props.tab === 1 && customerType.intersect(["caller", "consignee", "ALL"]).length > 0 && (
                <React.Fragment>
                  <div className="form-row">
                     <div className="col-md-12">
                     <div className="font-medium font-14 mb-15">Email</div>
                    </div>
                    <React.Fragment>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={renderField}
                              name="email"
                              required={
                                customerType.intersect(["caller"]).length > 0
                                  ? true
                                  : false
                              }
                              validate={
                                customerType.intersect(["caller"]).length > 0
                                  ? [this.requireEmail]
                                  : []
                              }
                              label={`Email and Customer Portal Admin Login`}
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                        {!this.props.initialValues._id && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <Field
                                type="text"
                                className="form-control"
                                component={renderField}
                                name="password"
                                required={
                                  customerType.intersect(["caller", "ALL"])
                                    .length > 0
                                    ? true
                                    : false
                                }
                                validate={
                                  customerType.intersect(["caller", "ALL"])
                                    .length > 0
                                    ? [this.requirePassword]
                                    : []
                                }
                                label={`Password `}
                                placeholder="Enter Password"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={InputField}
                              name="receiverEmail"
                              label="Receiver Email"
                              placeholder="Enter Receiver Email"
                              validate={[receiverEmail]}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    {customerType.intersect(["caller", "ALL"]).length > 0 && (
                      <React.Fragment>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Billing Email</label>
                            <AsyncCreatableSelect
                              menuPlacement="top"
                              cacheOptions
                              isMulti
                              styles={smallSelectStyle}
                              defaultOptions={this.state.allEmail}
                              formatCreateLabel={(inputValue) =>
                                `Add "${inputValue}"`
                              }
                              value={this.state.emailList}
                              onChange={(value) => {
                                this.setState({ emailList: value });
                              }}
                              onCreateOption={(value) => this.addEmails(value)}
                              onInputChange={this.onEmailInputChange}
                              placeholder={"Enter Billing Email"}
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null,Menu: () => null}}
                              onKeyDown={this._handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              type="text"
                              className="form-control"
                              component={renderField}
                              name="qbEmail"
                              label="Quickbooks Email"
                              required={
                                isQuickbooksUser()
                                ? true
                                : false
                              }
                              validate={
                                isQuickbooksUser()
                                ? [ this.requireEmail, quickBookEmail ]
                                : []
                              }
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                    <div class="hr-light my-20"></div>
                </React.Fragment>
              )}

              {this.props.tab === 1 && customerType.intersect([
                "caller",
                "consignee",
                "shipper",
                "ALL",
              ]).length > 0 && (
                <React.Fragment>
                  <div className="font-medium font-14 mb-15">Payment</div>
                  <div className="form-row">
                    {customerType.intersect(["caller", "ALL"]).length > 0 && (
                      <React.Fragment>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Field
                              type="text"
                              disabled={this.isDisabled("credit-limit")}
                              className="form-control"
                              component={InputField}
                              name="credit_limit"
                              label={`Credit Limit ($${!this.state.credit_limit && !this.state.balance ? 0 : Math.abs(
                                this.state.credit_limit - this.state.balance
                              ).toFixed(2)} ${
                                this.state.credit_limit < this.state.balance
                                  ? "oustanding amount"
                                  : "remaining"
                              } )`}
                              normalize={numberNormalize}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <Field
                              type="text"
                              disabled={this.isDisabled("payment-terms")}
                              className="form-control"
                              component={InputField}
                              name="payment_terms"
                              label="Payment Terms"
                              normalize={numberNormalize}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {customerType.intersect([
                      "caller",
                      "ALL",
                    ]).length > 0 && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            component={SelectField}
                            name="credit_hold"
                            label="Credit Hold"
                            onChange={(credit_hold) => {
                              this.props.change("credit_hold", credit_hold);
                            }}
                            data={loadFlags}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    {customerType.intersect([
                      "caller",
                      "consignee",
                      "shipper",
                      "ALL",
                    ]).length > 0 && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            component={SelectField}
                            name="account_hold"
                            label="Account Hold"
                            onChange={(account_hold) => {
                              this.props.change("account_hold", account_hold);
                            }}
                            data={loadFlags}
                          />
                        </div>
                      </div>
                    )}

                    {customerType.intersect([
                      "caller",
                      "consignee",
                      "shipper",
                      "ALL",
                    ]).length > 0 && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Currency {this.state.currency} </label>
                          <Select
                            options={currencies}
                            styles={smallSelectStyle}
                            value={
                              this.state.currency &&
                              currencies.find(
                                (item) => item.value === this.state.currency
                              )
                            }
                            onChange={({ value }) =>
                              this.setState({ currency: value })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="hr-light my-20"></div>
                </React.Fragment>
              )}

              <React.Fragment>
                {this.props.tab === 1 && (
                  <React.Fragment>
                    <div className="font-14 font-medium mb-15">Contact</div>
                    <div className="form-row">
                      {customerType.intersect([
                        "caller",
                        "shipper",
                        "consignee",
                        "ALL",
                      ]).length > 0 && (
                        <React.Fragment>
                          <div className="col-md-2">
                            <div className="form-group dropdown-on-top">
                              <Field
                                menuPlacement="top"
                                component={DateTimeField}
                                // className="form-control"
                                label="Office Hour Start"
                                name="officeHoursStart"
                                dateFormat={false}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group dropdown-on-top">
                              <Field
                                menuPlacement="top"
                                component={DateTimeField}
                                // className="form-control"
                                label="Office Hour End"
                                name="officeHoursEnd"
                                dateFormat={false}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="form-row">
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={InputField}
                            name="main_contact_name"
                            label={`Main Contact Name`}
                            // normalize={fieldNormalize}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={phoneComponent}
                            validate={[mobile]}
                            name="mobile"
                            label="Main Phone"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={InputField}
                            name="secondary_contact_name"
                            label="Secondary Contact Name"
                          />
                        </div>
                      </div>
                      {customerType.intersect([
                        "caller",
                        "consignee",
                        "shipper",
                        "containerReturn",
                        "ALL",
                      ]).length > 0 && (
                        <div className="col-md-2">
                          <div className="form-group new-set-label">
                            <Field
                              type="text"
                              className="form-control"
                              component={phoneComponent}
                              name="secondaryPhoneNo"
                              validate={[mobile]}
                              label="Secondary Phone"
                            />
                          </div>
                        </div>
                      )}
                     
                    </div>
                  </React.Fragment>
                )}
                {/* PropsTABS == 7 */}
                <div className="form-row">
                  {this.props.tab === 7 && (
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check  mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendNotificationEmail"
                          checked={
                            this.state.isSendNotificationEmail ? true : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendNotificationEmail: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendNotificationEmail"
                        >
                          Send Email Notification{" "}
                        </label>
                      </div>
                      <div className="mb-1">
                        <CustomCreatableSelect
                          isMulti={true}
                          disabled={!this.state.isSendNotificationEmail}
                          isClearable={true}
                          size="small"
                          placement="right"
                          placeholder="Enter emails.."
                          value={this.state.notificationEmails.map((i) => {
                            const val = {
                              label: i,
                              value: i,
                            };

                            return val;
                          })}
                          onChange={(val) => {
                            this.handleNotificationEmailsChange(
                              _.compact(
                                val.map((i) => {
                                  if (!validateEmail(i.value)) return i.value;
                                })
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {this.props.tab === 7 && (
                    <div className="col-md-4">
                      <div className="form-group form-check  mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendContainerNotification"
                          checked={
                            this.state.isSendContainerNotification
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendContainerNotification: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendContainerNotification"
                        >
                          Send Container Email Notification{" "}
                        </label>
                      </div>
                    </div>
                  )}
                  {this.props.tab === 7 && (
                    <div className="col-md-4">
                      <div className="form-group form-check  mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendPODEmail"
                          checked={this.state.isSendPODEmail ? true : false}
                          onChange={(e) =>
                            this.setState({ isSendPODEmail: e.target.checked })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendPODEmail"
                        >
                          Send POD Email{" "}
                        </label>
                      </div>
                    </div>
                  )}
                  {this.props.tab === 7 && (
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Statuses for email notification</label>
                        <Select
                          isDisabled={!this.state.isSendNotificationEmail}
                          value={this.state.emailStatusOptions}
                          styles={smallSelectStyle}
                          onChange={(emailStatusOptions) =>
                            this.setState({ emailStatusOptions })
                          }
                          isMulti
                          name="emailStatuses"
                          options={emailStatusOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  )}
                  {customerType.intersect(["ALL", "caller"]).length > 0 &&
                    this.props.tab === 7 && (
                      <div className="col-md-4">
                        <div className="form-group  mb-2">
                          {/* <span
                            style={{
                              position: "absolute",
                              marginLeft: 75,
                              color: "#b53818",
                            }}
                          >
                            add multiple email by (,) comma
                          </span> */}
                          <label className="label-control d-block">CC Emails</label>
                            <div className="mb-1">
                                <CustomCreatableSelect
                                isMulti={true}
                                isClearable={true}
                                size="small"
                                placement="right"
                                placeholder="Enter emails.."
                                value={this.state.extraEmail.map((i) => {
                                  const val = {
                                    label: i,
                                    value: i,
                                  };

                                  return val;
                                })}
                                onChange={(val) => {
                                  this.handleExtraEmailsChange(
                                    _.compact(
                                      val.map((i) => {
                                        if (!validateEmail(i.value)) return i.value;
                                      })
                                    )
                                  );
                                }}
                              />
                            </div>
                          {/* <Field
                            type="text"
                            className="form-control"
                            component={InputField}
                            name="extraEmail"
                            label="CC Emails"
                          /> */}
                        </div>
                      </div>
                    )}
                  {customerType.intersect(["ALL", "caller"]).length > 0 &&
                    this.props.tab === 7 && (
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            type="text"
                            className="form-control"
                            component={SelectField}
                            name="isEmailNeedToSend"
                            label="Email Invoice to Customer?"
                            onChange={(target, isEmailNeedToSend) => {
                              this.setState({ isEmailNeedToSend });
                            }}
                            data={loadFlags}
                          />
                        </div>
                      </div>
                    )}
                </div>
                <div className="form-row">
                  {customerType.intersect([
                    "ALL",
                    "caller",
                    "shipper",
                    "containerReturn",
                    "consignee",
                    "chassisTermination",
                    "chassisPick",
                  ]).length > 0 &&
                    ((getStorage("currentUserRole") == "fleetmanager" &&
                      configuration.idForTerminal.indexOf(
                        JSON.parse(getStorage("loggedInUser")).fleetManager
                          .carrier
                      ) != -1) ||
                      (getStorage("currentUserRole") == "carrier" &&
                        configuration.idForTerminal.indexOf(
                          getStorage("currentUserID")
                        ) != -1)) &&
                    this.props.tab === 1 && (
                      <React.Fragment>
                      <div className="col-md-4">                   
                        
                        <div className="form-group new-set-label">
                        <Field 
                        name="newTerminal"
                        required={true}
                        isMulti={true}
                        data={this.state.newTerminal}
                        value={this.state.newTerminal}
                        label="Branches" 
                        onChange={(newTerminal) =>
                        {      
                          let data = Object.values(newTerminal)
                          this.setState({ newTerminal:data})}
                        }
                        component={ReduxFormSelect} 
                        validate={this.requireTerminal}
                        options={Terminal} />
                        </div>
                      </div> 
                    
                          </React.Fragment>
                    )}
                  {customerType.intersect(["shipper"]).length > 0 &&
                    this.props.tab === 1 && (
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            component={InputField}
                            type="text"
                            className="form-control"
                            label="Port Username"
                            name="portUsername"
                            disabled={!this.props.showAddModal}
                          />
                        </div>
                      </div>
                    )}
                  {customerType.intersect(["shipper"]).length > 0 &&
                    this.props.tab === 1 && (
                      <div className="col-md-4">
                        <div className="form-group new-set-label">
                          <Field
                            component={InputField}
                            type="text"
                            className="form-control"
                            label="Port Password"
                            name="portPassword"
                            disabled={!this.props.showAddModal}
                          />
                        </div>
                      </div>
                    )}
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <label>Sales Agent</label>
                      <AsyncSelect
                        menuPlacement="top"
                        isMulti
                        styles={smallSelectStyle}
                        value={this.state.salesAgents}
                        defaultOptions={this.state.defaultSalesAgents}
                        loadOptions={this.listAllFleetManagers}
                        onChange={(salesAgents) =>
                          this.setState({ salesAgents })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Customer Sub Type </label>
                        <Select
                          styles={smallSelectStyle}
                          options={customerSubType}
                          value={this.state.customerSubType && customerSubType.find(item => item.value === this.state.customerSubType)}
                          onChange={({ value }) => this.setState({ customerSubType: value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <Field
                        type="text"
                        className="form-control"
                        component={InputField}
                        name="companyField"
                        label="QuickBooks Company Field"
                        normalize = {fieldNormalize}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>

              <div
                className="text-center"
                style={{ display: "none" }}
                // onClick={() => this.validateFormSubmit()}
              >
                <ButtonComponent
                  type="submit"
                  label="Add"
                  id="profileButton"
                  className="btn btn-primary btncustomersave"
                ></ButtonComponent>
                <br />
              </div>
              {this.props.tab === 1 && (
                <React.Fragment>
                  <div class="hr-light my-20"></div>
                  <div className="font-medium font-14 mb-15">Preferences</div>
                  <div className="form-row">
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Fleet Customer</label>
                        <Select
                          isClearable
                          value={this.state.fleetCustomer}
                          styles={smallSelectStyle}
                          onChange={(fleetCustomer) => {
                            if (
                              fleetCustomer === null &&
                              this.props.Selected_customer._id
                            )
                              this.removeFleetFromCustomer(
                                this.props.Selected_customer._id
                              );
                            this.setState({ fleetCustomer });
                          }}
                          options={this.state.fleetCustomerdata}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <label>Sub Customer</label>
                        <AsyncSelect
                          menuPlacement="top"
                          isMulti
                          styles={smallSelectStyle}
                          value={this.state.extraSubCustomer}
                          defaultOptions={this.state.defaultSubCustomer}
                          loadOptions={this.getCustomerOptions}
                          onChange={(extraSubCustomer) =>
                            this.setState({ extraSubCustomer })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group new-set-label">
                        <Field
                          onChange={(target, payType) => {
                            this.setState({ payType });
                          }}
                          type="text"
                          className="form-control"
                          component={SelectField}
                          name="payType"
                          label="Pay Type"
                          data={directFactoryFlags}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {this.props.tab === 1 && (
                <div className="form-row">
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <label>Customer Tags</label>
                      <AsyncCreatableSelect
                        menuPlacement="top"
                        cacheOptions
                        isMulti
                        styles={smallSelectStyle}
                        loadOptions={this.getCustomerTags}
                        defaultOptions={this.state.allCustomerTags}
                        formatCreateLabel={(inputValue) =>
                          `Add "${inputValue}"`
                        }
                        value={
                          this.state.customerTags &&
                          this.state.customerTags.map((d) => ({
                            label: d,
                            value: d,
                          }))
                        }
                        onChange={(value) => {
                          this.setState({
                            customerTags: value.map((d) => d.value),
                          });
                        }}
                        onCreateOption={(value) => this.addCustomerTags(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <Field
                        menuPlacement="top"
                        onChange={(target, canEditLoad) => {
                          this.setState({ canEditLoad });
                        }}
                        type="text"
                        className="form-control"
                        component={SelectField}
                        name="canEditLoad"
                        label="Can Edit Load"
                        data={loadFlags}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group new-set-label">
                      <Field
                        menuPlacement="top"
                        onChange={(target, isTIROptional) => {
                          this.setState({ isTIROptional });
                        }}
                        type="text"
                        className="form-control"
                        component={SelectField}
                        name="isTIROptional"
                        label="Tir Optional"
                        data={loadFlags}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.props.tab === 7 && this.state.isSendNotificationEmail && (
                <React.Fragment>
                  <hr />
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <h4>Email Notifictions</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendEmailDeliveryAppointmentChanged"
                          checked={
                            this.state.isSendEmailDeliveryAppointmentChanged
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendEmailDeliveryAppointmentChanged:
                                e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendEmailDeliveryAppointmentChanged"
                        >
                          Send Email Delivery Appointment Changed
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendEmailPricingDetailEmail"
                          checked={
                            this.state.isSendEmailPricingDetailEmail
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendEmailPricingDetailEmail: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendEmailPricingDetailEmail"
                        >
                          Send Email Pricing Detail{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendEmailContainerAvailable"
                          checked={
                            this.state.isSendEmailContainerAvailable
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendEmailContainerAvailable: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendEmailContainerAvailable"
                        >
                          Send Email Container Available
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendEmailContainerAdd"
                          checked={
                            this.state.isSendEmailContainerAdd ? true : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendEmailContainerAdd: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendEmailContainerAdd"
                        >
                          Send Email Container Add
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendEmailLoadAccepted"
                          checked={
                            this.state.isSendEmailLoadAccepted ? true : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendEmailLoadAccepted: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendEmailLoadAccepted"
                        >
                          Send Email Load Accepted
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isSendEmailContainerReadyForPickup"
                          checked={
                            this.state.isSendEmailContainerReadyForPickup
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              isSendEmailContainerReadyForPickup:
                                e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="isSendEmailContainerReadyForPickup"
                        >
                          Send Email Container Ready To Return
                        </label>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {this.props.tab === 7 && (
                <React.Fragment>
                  <hr />
                  <div className="mb-2">
                    <h4>Email Attachments</h4>
                  </div>

                  <div
                    className={
                      this.state.doc_none ? "mb-2 div-invisible" : "mb-2"
                    }
                  >
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_all"
                        checked={this.state.doc_all ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_all", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_all">
                        ALL
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.doc_none ? "mb-2 div-invisible" : "mb-2"
                    }
                  >
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_tir"
                        checked={this.state.doc_tir ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_tir", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_tir">
                        TIR
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.doc_none ? "mb-2 div-invisible" : "mb-2"
                    }
                  >
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_pod"
                        checked={this.state.doc_pod ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_pod", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_pod">
                        POD
                      </label>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="doc_none"
                        checked={this.state.doc_none ? true : false}
                        onChange={(e) =>
                          this.setFeatureValue("doc_none", e.target.checked)
                        }
                      />
                      <label className="form-check-label" for="doc_none">
                        NONE
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </form>
          </div>
        </div>

        <div
          className="pb-3 position-relative"
          style={{
            height: "400px",
            display: this.props.tab == 3 ? "" : "none",
          }}
        >
          <div
            id="map"
            ref={(el) => (this.mapContainer = el)}
            style={{ height: "100%", minHeight: "400px" }}
            className="mapContainer"
          ></div>
        </div>

        {this.props.tab == 4 && (
          <div className="card my-10">
            <div className="px-30 py-20">
              <div className="d-flex align-items-center mb-20">
                <h5 className="font-16 mb-0">Add Notes</h5>
              </div>
              <div style={{ height: "calc( 100% - 30px )" }}>
                <textarea
                  rows="5"
                  className="form-control"
                  value={this.state.notes}
                  style={{ height: "100%" }}
                  onChange={({ target }) =>
                    this.setState({ notes: target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        )}

        {this.props.tab == 5 && (
          <div className="table-responsive">
            <table className="table table-card table-card--wrap">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Type</th>
                  <th>Time</th>

                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {this.state.audits &&
                  this.state.audits.map((d, index) => {
                    let description;
                    let url;
                    let company_name;

                    if (
                      ["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)
                    ) {
                      url = (
                        <a
                          className="badge badge-info"
                          href={d.data.document && d.data.document.url}
                          target="_blank"
                        >
                          {d.data.document &&  d.data.document.url}
                        </a>
                      );
                      description = !_.isNil(d.data.document && d.data.document.url) && (
                        <p>
                          {d.data.document.type} changed to{" "}
                          <b className="badge badge-info">{url}</b>
                        </p>
                      );
                    }
                    if (d.type === "ADD") {
                      description = "Company Name ";
                      company_name = (
                        <b className="badge badge-soft-warning">
                          {d.data.company_name}
                        </b>
                      );
                    }
                    if (d.type === "UPDATE") {
                      let data;
                      if (
                        index !== this.state.audits.length - 1 &&
                        this.state.audits[index + 1].userId
                      ) {
                        data = this.state.audits[index + 1].data;
                      }
                      const additionalInfo = d.data;
                      let dateFormat = ["officeHoursEnd"];
                      let differanceDescription = differnceFinder(
                        data,
                        additionalInfo
                      );

                      description = Object.keys(differanceDescription || [])
                        .filter(
                          (key1) =>
                            differanceDescription[key1] &&
                            typeof differanceDescription[key1] !== "object"
                        )
                        .map((key1) => {
                          if (dateFormat.includes(key1)) {
                            return (
                              <p>
                                {key1} changed to{" "}
                                <b className="badge badge-soft-warning">
                                  {moment(differanceDescription[key1]).format(
                                    "ddd MMM DD YYYY"
                                  )}
                                </b>
                              </p>
                            );
                          } else {
                            return (
                              !_.isNil(differanceDescription[key1]) && (
                                <p>
                                  {key1} changed to{" "}
                                  <b className="badge badge-soft-warning">
                                    {differanceDescription[key1].toString()}
                                  </b>
                                </p>
                              )
                            );
                          }
                        });
                    }
                    if(['ADDED_CSR', 'REMOVED_CSR'].includes(d.type)){

                      let csrName = ""
                      if(d.data){
                       csrName = d.data.name ? csrName+d.data.name+" " : "";
                       csrName = d.data.lastName ? csrName+d.data.lastName : ""
                      } 
                      const desc = d.type === "ADDED_CSR" ? "New CSR Assigned " : "CSR Removed "
                     description = <p>{desc}   <b className={`badge  ${d.type === "ADDED_CSR" ? "badge-soft-green" : "badge-soft-brown"}`}>{csrName}</b></p>
                   }
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="avatar-circle avatar-xs mr-1">
                              {" "}
                              {d.userId && d.userId.name.charAt(0)}
                            </span>
                            {d.userId && d.userId.name}
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-gray-100 badge-sm text-capitalize">
                            {d.type.replaceAll(/_/g, " ").toLowerCase()}
                          </span>
                        </td>

                        <td>
                          <div>
                            {moment(d.createdAt)
                              .tz(getStorage("timeZone"))
                              .format("MM/DD/YY")}
                          </div>
                          <div className="text-muted">
                            {moment(d.createdAt)
                              .tz(getStorage("timeZone"))
                              .format("LT")}
                          </div>
                        </td>

                        <td>
                          {description}
                          {company_name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}

        {this.props.tab == 6 && (
          <CustomerAccessManagement
            isCustomerProfile={true}
            CustomerID={this.props.Selected_customer._id}
            isQuick={this.props.isQuick}
            initialValues={this.props.initialValues}
          />
        )}

        <Modal show={this.state.showFileUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card p-20 mb-0">
              <ImageUploader
                uploadResult={this.uploadResult}
                type={"image"}
                className="custom-file-input"
              />

              {this.state.documentFile && (
                <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                  <IconUpload className="mr-20" />
                  <div className="w-100">
                    <div className="font-medium d-flex justify-content-between w-100 mb-1">
                      <div>{this.state.documentFile.name}</div>
                      <div className="ml-auto">{this.state.progress}%</div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={`${this.state.progress}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${this.state.progress}%` }}
                      ></div>
                    </div>
                  </div>
                  <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                    <span
                      onClick={() =>
                        this.setState({
                          documentFile: null,
                          imagePreviewUrl: null,
                          pdfPreviewUrl: null,
                          progress: 0,
                          isSaving: true,
                          csvPreviewUrl: null, 
                          xlsPreviewUrl: null
                        })
                      }
                    >
                      {" "}
                      <IconTimes className="" />
                    </span>
                  </button>
                </div>
              )}
              {this.state.documentFile && (
                <div className="dropzone-fileupload d-flex">
                  {this.state.progress == 0 ? (
                    <button className="btn btn-warning btn-circle btn-xs mr-15">
                      <IconInfo />
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-circle btn-xs mr-15">
                      <IconCheck />
                    </button>
                  )}
                  <div className="document-preview document-preview--sm mr-3">
                    {this.state.imagePreviewUrl && (
                      <img src={this.state.imagePreviewUrl} alt={""} />
                    )}
                    {this.state.pdfPreviewUrl && (
                      <canvas id="the-canvas"></canvas>
                    )}
                    {this.state.csvPreviewUrl &&( 
                      <img
                        className="document-preview__image"
                        src="/assets/img/csv.png"
                        alt="csv.png"
                      />
                    )}
                    {this.state.xlsPreviewUrl && (
                      <FileViewer
                        fileType="xlsx"
                        filePath={this.state.xlsPreviewUrl.pre}
                      />
                    )}
                  </div>
                  <div className="w-100 d-flex">
                    <div>
                      <div className="font-medium ">
                        {this.state.documentFile.name}
                      </div>
                      <div className="text-muted">
                        {(this.state.documentFile.size * 0.0009765625).toFixed(
                          1
                        )}{" "}
                        kb
                      </div>
                    </div>
                    <div className="w-150 ml-auto">
                      <Select
                        menuPlacement="auto"
                        styles={smallSelectStyle}
                        menuPosition={"fixed"}
                        options={documentType}
                        value={
                          this.state.fileType &&
                          documentType.find(
                            (d) => d.value === this.state.fileType
                          )
                        }
                        onChange={({ value }) =>
                          this.setState({ fileType: value })
                        }
                      />
                    </div>
                  </div>
                  <button className="btn btn-link btn-circle btn-xs ml-3">
                    <span
                      onClick={() =>
                        this.setState({
                          documentFile: null,
                          imagePreviewUrl: null,
                          pdfPreviewUrl: null,
                          progress: 0,
                          isSaving: true,
                          csvPreviewUrl: null, 
                          xlsPreviewUrl: null
                        })
                      }
                    >
                      {" "}
                      <IconTrash />{" "}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                this.setState({ showFileUploadModal: false, isSaving: true, csvPreviewUrl: null, xlsPreviewUrl: null, fileType: ""});
              }}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isSaving || this.state.isLoading}
              onClick={() => {
                if (this.state.documentFile) {
                  $(".btncustomersave").trigger("click");
                  //   if (this.state.fileType) {
                  //     this.setState({
                  //       showFileUploadModal: false,
                  //       documentFile: null,
                  //       imagePreviewUrl: null,
                  //       pdfPreviewUrl: null,
                  //       progress: 0,
                  //     });
                  //   } else {
                  //     toastr.show("Please select a type.", "warning");
                  //   }
                  // } else {
                  //   this.setState({
                  //     showFileUploadModal: false,
                  //     documentFile: null,
                  //     imagePreviewUrl: null,
                  //     pdfPreviewUrl: null,
                  //     progress: 0,
                  //   });
                } else {
                  toastr.show("Please select file", "error");
                }
              }}
            >
              {this.state.isLoading && (
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        { this.state.showFullDropScreen && this.props.tab === 2 && !this.state.showFileUploadModal && 
          <FullScreenUploader  uploadResult={this.uploadResult} multi={false} type={"image"}/>
        }
      </React.Fragment>
    );
  }
}

AddCustomerComponent = reduxForm({
  // a unique name for the form
  form: "tmscustomeradd",
  enableReinitialize: true,
  // validate
})(AddCustomerComponent);

function mapStateToProps(state) {
  return {
    Fleetcustomers: state.TmsReducer.Fleetcustomers,
    Terminals: state.HomeReducer.terminals,
    callers: state.TmsReducer.callers,
    customers: state.TmsReducer.customers,
    shippers: state.TmsReducer.shippers,
    consignees: state.TmsReducer.consignees,
    allCustomers: state.TmsReducer.allCustomers,
    chassisTerms: state.TmsReducer.chassisTerms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreateors, dispatch),
  };
}

AddCustomerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCustomerComponent);
export default AddCustomerComponent;

const emailStatusOptions = [
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "COMPLETED",
].map((d) => ({
  value: d,
  label: convertTMSStatusName("IMPORT", d),
}));

export const phoneComponent = ({
  input,
  label,
  className,
  meta: { touched, error },
}) => {
  return (
    <div>
      <label>{label}</label>
      <div>
        <NumberFormat
          className={className}
          {...input}
          autoComplete={input.name}
          format="###-###-####"
          mask="_"
        />
      </div>
      {touched && error && (
        <span className="error" style={{ color: "red" }}>
          {error}
        </span>
      )}
    </div>
  );
};

const renderField = ({
  input,
  label,
  type,
  required,
  meta: { touched, error, warning },
}) => (
  <div className="form-group new-set-label">
    <label className="label-control d-block">
      {required && <span className="text-danger">*</span>} {label}
    </label>
    <input
      className="form-control"
      {...input}
      placeholder={label}
      type={type}
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-warning">{warning}</span>))}
  </div>
);

const ReduxFormSelect = ({  input,
  label,
  required,
  options,
  value,
  data,
  isMulti,
  meta: { touched, error, warning }})=>{
    let valueData = ''
    if(input.value[0] && input.value[0].label){
      valueData = input.value
    }else {
      valueData = data && data.filter(d => d.value);
    }
  return (
    <div className="form-group new-set-label">
    <label className="label-control d-block">
      {required && <span className="text-danger">*</span>} {label}
    </label>
    
    <Select
      {...input}
      menuPosition={"fixed"}
      isMulti={isMulti}
      value={valueData}
      styles={smallSelectStyle}
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-warning">{warning}</span>))}
  </div>
 
  )
}

const required = (value) => {
  if(value || typeof value === "number"){
    return undefined
  }else{
    return "is required*"
  }
};

const validateWhiteSpace = (value) =>
  value && value.trim() !== "" ? undefined : "is required*";

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const mobile = (value) => {
  let phone = value || null;

  if (phone) {
    phone = phone.replace(/-|_| /g, "");

    if (phone.length < 10) {
      return "Company Phone Number Have Atleast 10 Digits*";
    }
  }

  return undefined;
};

const receiverEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)
    ? "Please enter valid Receiver email address"
    : undefined;
const quickBookEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value)
    ? "Please enter valid Quickbooks email address."
    : undefined;
const zipcodeNormalize = (value) => value && value.replace(/[^\w\s]/gi, "");
const fieldNormalize = (value) => value && value.replace(/[^[A-za-z ]/gi, "");
const numberNormalize = (value) => value && value.replace(/[^0-9]/gi, "");

const documentType = [
  { value: "Customer Contract", label: "CUSTOMER CONTRACT" },
  { value: "Rate Confirmation", label: "RATE CONFIRMATION" },
  { value: "Email", label: "Email" },
  { value: "Other", label: "Other" },
  { value: "Credit App", label: "Credit App" },
  { value: "Credit Report", label: "Credit Report" },
  { value: "Business License", label: "Business License" },
];