import $ from "jquery";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import { smallSelectStyle } from "../../../../assets/js/select-style";
import configuration from "../../../../config";
import { getStorage } from "../../../../services/Common.services";
import * as actionCreators from "../../Driver/actionCreators";
import { fetchUsers } from "../../services";
import * as actionCreatorss from "../AccountReceivable/actionCreators";
import * as ManualActionCreators from "./actionCreator";

const validate = (values, props) => {
  var errors = {};
  if (!values.description) {
    errors.description = "Description is required";
  }
  if (values.description && values.description.length > 100) {
    errors.description = "Enter less than 100 characters";
  }
  if (!values.driverId) {
    errors.driverId = "Choose Driver from the list";
  }
  if (!values.amount) {
    errors.amount = "Amount is required";
  }
  if (!values.from) {
    errors.from = "Enter From address";
  }
  if (!values.to) {
    errors.to = "Enter To Address";
  }
  if (!props.isTab && !values.reference_number) {
    errors.reference_number = "Please select Load";
  }
  return errors;
};

const renderField = ({
  input,
  label,
  type,
  isTextArea,
  meta: { touched, error, warning },
}) => (
  <div>
    {isTextArea ? (
      <textarea
        className="form-control"
        {...input}
        placeholder={label}
        type={type}
      />
    ) : (
      <input
        className="form-control"
        {...input}
        placeholder={label}
        type={type}
      />
    )}

    {touched &&
      ((error && <span className="text-danger error">{error}</span>) ||
        (warning && <span className="text-danger error">{warning}</span>))}
  </div>
);

class ManualAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allDrivers: [],
      TripList: [],
      fromAddress: [],
      toAddress: [],
      reference_number: "",
    };
    this.searchAddressthrottle = debounce(500, this.getPlace);
    this.searchLoadthrottle = debounce(500, this._searchLoadthrottle);
  }

  _searchLoadthrottle = (query, callback) => {
    ManualActionCreators.getLoads(query)
      .then(resp => callback(resp));
  };
  

  getPlaceBySearch = (e, type) => {
    this.searchAddressthrottle(e, type);
  };

  componentWillReceiveProps(props) {
    if (props.tmsDrivers && props.tmsDrivers.length > 0) {
      let allDrivers = props.tmsDrivers;
      allDrivers = allDrivers.filter((driver) => driver.isDeleted === false);
      if (props.selectedDriver && props.selectedDriver._id) {
        allDrivers = allDrivers.filter(
          (driver) => driver._id == props.selectedDriver._id
        );
        props.change("driverId", props.selectedDriver._id);
      }
      if (allDrivers && allDrivers.length > 0) {
        if (
          getStorage("currentUserRole") == "fleetmanager" &&
          configuration.idForTerminal.indexOf(
            JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
          ) != -1
        ) {
          let fleetTerminal = JSON.parse(getStorage("loggedInUser"))
            .fleetManager.terminals;
          allDrivers = allDrivers.filter(
            (D) => fleetTerminal.indexOf(D.terminal) > -1
          );
        }
      }
      this.setState({ allDrivers });
    }
  }

  requireLoad = (value) => {
    if (
      !this.props.location ||
      (!this.props.location.query &&
        !this.props.location.query.reference_number)
    ) {
      if (!value) {
        return "Please select load";
      } else {
        return undefined;
      }
    }
  };

  componentDidMount() {
    if (!this.props.loadDriver) {
      this.props.actions.listAllDrivers().then((result) => {
        let allDrivers = result.filter((driver) => driver.isDeleted === false);
        if (allDrivers && allDrivers.length > 0) {
          if (
            getStorage("currentUserRole") == "fleetmanager" &&
            configuration.idForTerminal.indexOf(
              JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
            ) != -1
          ) {
            let fleetTerminal = JSON.parse(getStorage("loggedInUser"))
              .fleetManager.terminals;
            allDrivers = allDrivers.filter(
              (D) => fleetTerminal.indexOf(D.terminal) > -1
            );
          }
        }
        this.setState({ allDrivers }, () => { });
      });
    }
    if(this.props.isTab && this.props.selectedLoads && this.props.selectedLoads.containerNo){
      this.props.change("containerNo", this.props.selectedLoads.containerNo);
    }
    $(window).click(() => {
      this.setState({ fromAddress: [], toAddress: [] });
    });
  }


  keyPressOnNumberInput = (evt)=>{
    var invalidChars = [
      "-",
      "+",
      "e",
    ];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }
  getPlace = (e, type) => {
    fetchUsers("carrier/getTMSCustomers", {
      searchTerm: e.target.value,
      limit: 10,
    }).then((response) => {
      const datas = response.data.data;
      if (type == "from") {
        this.setState({ fromAddress: datas });
      } else {
        this.setState({ toAddress: datas });
      }
    });
  };

  onSelect = (place, type) => {
    this.props.change(type, place.company_name);
    this.setState({ fromAddress: [], toAddress: [] });
  };

  selectField = ({ input, label, type, meta: { touched, error, warning } }) => {
    return (
      <div>
        <Select
          options={
            this.state.allDrivers && this.state.allDrivers.length > 0
              ? this.state.allDrivers.map((d) => ({
                value: d._id,
                label: `${d.name} ${d.lastName || ""}`.trim(),
              }))
              : this.props.allDrivers &&
              this.props.allDrivers.map((d) => ({
                value: d._id,
                label: `${d.name} ${d.lastName || ""}`.trim(),
              }))
          }
          value={input.value && this._findDriver()}
          onChange={({ value }) => input.onChange(value)}
          styles={smallSelectStyle}
          placeholder="Select Driver..."
        />

        {touched && error && <span className="text-danger error">{error}</span>}
      </div>
    );
  };

  selectLoadField = ({
    placeholder,
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => {
    return (
      <div>
        <AsyncSelect
          onChange={this.onSelectLoad}
          styles={smallSelectStyle}
          loadOptions={this.searchLoadthrottle}
          placeholder={placeholder}
        />
        {touched && error && <span className="text-danger error">{error}</span>}
      </div>
    );
  };

  _findDriver(value) {
    let data = this.state.allDrivers.find((d) => d._id === value);
    if (data)
      return {
        value: data._id,
        label: `${data.name} ${data.lastName || ""}`.trim(),
      };

    return;
  }

  onSelectLoad = (e) => {
    if (e) {
      if (e.allInfos.containerNo) {
        this.props.change("containerNo", e.allInfos.containerNo);
      }else{
        this.props.change("containerNo", '');
      }
      
      this.props.change("loadId", e.allInfos?._id)
      this.props.change("reference_number", e.value);
      this.setState({ reference_number: e.value }, () => { });
    }
  };

  onChange = (e) => {
    if (e.target.value) {
      const TripList = JSON.parse(JSON.stringify(this.state.TripList));
      const containerNo = TripList.filter(
        (trip) => trip.reference_number == e.target.value
      );
      this.props.change("containerNo", containerNo[0].containerNo);
    }
  };

  render() {
    let disabled = this.props.disabled;
    const { handleSubmit, showManual, onCancel, isTab } = this.props;
    return (
      <Modal className="backdrop_modal_open" show={showManual} bsSize="sm" centered animation={false}>
        <form
          onSubmit={handleSubmit((data) => {
            if (this.props.id != undefined) {
              data.id = this.props.id;
            }
            if (this.props.settlementNo) {
              data.settlementNo = this.props.settlementNo;
            }
            this.props.submitForm(data);
            this.props.onCancel();
          })}
        >
          <Modal.Header>
            <Modal.Title>Add Driver Pay</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
            {/* <div className="form-row">
              {this.props.location &&
                this.props.location.query &&
                !this.props.location.query.reference_number && (
                  <div className="form-group col-md-6">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Reference Number
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      component={this.selectLoadField}
                      name="reference_number"
                      label="Reference Number"
                      placeholder="Enter Load#..."
                    />
                  </div>
                )}

              {this.props.loadss && (
                <div className="form-group col-md-6">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Reference Number
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    component={this.selectLoadField}
                    name="reference_number"
                    label="Reference Number"
                    placeholder="Enter Load#..."
                  />
                </div>
              )}

              <div className="form-group col-md-6">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Container No
                </label>
                <Field
                  type="text"
                  className="form-control"
                  component={renderField}
                  name="containerNo"
                  label="CONTAINER"
                />
              </div>
            </div> */}
            {!isTab && this.props.location &&
                this.props.location.query &&
                !this.props.location.query.reference_number && (
                  <div className="form-row">
                  <div className="form-group col-md-12">
                  <label className="col-form-label">
                  <span className='text-danger'>*</span> Reference Number
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      component={this.selectLoadField}
                      name="reference_number"
                      label="Reference Number"
                      placeholder="Enter Load#..."
                    />
                  </div>
                  </div>
                )}
            {this.props.loadss && (
              <div className="form-row">
                <div className="form-group col-md-12">
                <label className="col-form-label">
                  Reference Number 
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    component={this.selectLoadField}
                    name="reference_number"
                    label="Reference Number"
                    placeholder="Enter Load#..."
                  />
                </div>
              </div>
            )}
            <div className="form-row">
              <div className="form-group col-md-12">
              <label className="col-form-label">
                   Container No
                </label>
                <Field
                  type="text"
                  className="form-control"
                  component={renderField}
                  name="containerNo"
                  label="CONTAINER"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
              <label className="col-form-label">
                  <span className='text-danger'>*</span> Driver
                </label>
                <Field
                  type="text"
                  className="form-control"
                  component={this.selectField}
                  name="driverId"
                  label="DRIVER"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
              <label className="col-form-label">
              <span className='text-danger'>*</span> From
                </label>
                <Field
                  type="text"
                  className="form-control"
                  component={renderField}
                  name="from"
                  label="FROM"
                  isDebounce={true}
                  onChange={(e) => this.getPlaceBySearch(e, "from")}
                />
                {this.state.fromAddress &&
                  this.state.fromAddress.length != 0 && (
                    <div className="react-mapbox-ac-suggestion">
                      {this.state.fromAddress.map((place, key) => {
                        return (
                          <p
                            key={key}
                            onClick={() => this.onSelect(place, "from")}
                          >
                            {place.company_name}
                          </p>
                        );
                      })}
                    </div>
                  )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
              <label className="col-form-label">
              <span className='text-danger'>*</span> To
                </label>
                <Field
                  type="text"
                  className="form-control"
                  component={renderField}
                  name="to"
                  label="TO"
                  isDebounce={true}
                  onChange={(e) => this.getPlaceBySearch(e, "to")}
                />
                {this.state.toAddress && this.state.toAddress.length != 0 && (
                  <div className="react-mapbox-ac-suggestion">
                    {this.state.toAddress.map((place, key) => {
                      return (
                        <p
                          key={key}
                          onClick={() => this.onSelect(place, "to")}
                        >
                          {place.company_name}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
              <label className="col-form-label">
              <span className='text-danger'>*</span> Amount
                </label>
                <Field
                  type="number"
                  className="form-control"
                  component={renderField}
                  name="amount"
                  label="AMOUNT"
                  min={0}
                  onKeyDown={(e)=>{this.keyPressOnNumberInput(e)}}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
              <label className="col-form-label">
              <span className='text-danger'>*</span> Description
                </label>
                <Field
                  type="text"
                  className="form-control"
                  component={renderField}
                  name="description"
                  label="DESCRIPTION"
                  isTextArea={true}
                />
              </div>
            </div>
            {((getStorage("currentUserRole") == "fleetmanager" &&
              configuration.idForTerminal.indexOf(
                JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
              ) != -1) ||
              (getStorage("currentUserRole") == "carrier" &&
                configuration.idForTerminal.indexOf(
                  getStorage("currentUserID")
                ) != -1)) && (
                <div className="form-row">
                  <div className="form-group col-md-12">
                  <label className="col-form-label">
                       Terminal
                    </label>
                    <Select
                      onChange={(val) => {
                        this.setState({ terminal: val.value },()=>{
                          this.props.onSelectTerminal(this.state.terminal);
                        });
                      }}
                      styles={smallSelectStyle}
                      name="choosenTerminals"
                      options={this.props.terminals.map((item) => ({
                        value: item._id,
                        label: item.name,
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            type="button"
            onClick={() => onCancel()}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            type="submit"
            disabled={this.props.isSaving}
          >
            {this.props.isSaving && (
              <i className="uil uil-spinner-alt mr-1" />
            )}
            Add Driver Pay
          </button>
        </Modal.Footer>
      </form>
      </Modal >
    );
  }
}

ManualAdd = reduxForm({
  // a unique name for the form
  form: "manualAdd",
  enableReinitialize: true,
  validate,
})(ManualAdd);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    loads: bindActionCreators(actionCreatorss, dispatch),
    manual: bindActionCreators(ManualActionCreators, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    tmsLoads: state.TmsReducer.loads,
    tmsDrivers: state.TmsReducer.drivers,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualAdd);
