import React, { useCallback, useEffect, useState } from "react";
import useWindowSize from "../../../Components/CustomHooks/useWindowSize";

const useBoxPosition = (cellRef, boxRef, refHeight) => {
  const [style, setStyle] = useState({
    position: "absolute",
    top: 0,
    transition: "none",
    opacity: 0,
  });

  const { height: screenH, width: screenW } = useWindowSize();

  const calculateBoxPosition = useCallback(() => {
    const {
      x: cellX,
      y: cellY,
      height: cellH,
    } = cellRef.getBoundingClientRect();
    const { width: boxW, height: boxH } = boxRef?.current?.getBoundingClientRect();
    const clickX = cellX;
    const clickY = cellY + cellH;

    const right = screenW - clickX > boxW;
    const left = !right;
    const top = screenH - clickY > (refHeight || boxH) + 5;
    const bottom = !top;
    const style = {
      position: "absolute",
      opacity: 1,
      transition: "none",
      zIndex:10000
    };
    if (right) {
      style.left = `${clickX }px`;
    }

    if (left) {
      style.left = `${screenW - boxW }px`;
    }

    if (top) {
      style.top = `${clickY + 5-20}px`;
    }

    if (bottom) {
      style.top = `${screenH - (refHeight || boxH) -20}px`;
    }

    setStyle(style);
  },[])

  useEffect(() => {
    const interval = setInterval(() => {
      calculateBoxPosition();
    }, 500)
    calculateBoxPosition();
    return () => clearInterval(interval);
  }, [screenH,screenW]);

  return style;
};

export default useBoxPosition;
