import React, { Component } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUsers } from '../services';
import CommonLoadComponent from './CommonLoadComponent';
import RoadLoadComponent from './RoadLoadComponent';
import { getStorage } from '../../../services/Common.services';

import { ImportValidation, RoadValidation } from '../validations';
import { PopUp } from '../formComponent';

import _ from 'underscore'
import NewCommonLoadComponent from './NewCommonLoadComponent';
import NewRoadLoadComponent from './NewRoadLoadComponent';

let cachedUrl;
const commodityItems = {
  commodity: '',
  description: '',
  pieces: '',
  units: '',
  weight: '',
  weightUnitType: '',
  pallets: '',
  palletsUnits: '',
}

class NewAddLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isSaveCalled : false,
      datas: [{ username: 'test' }],
      charges: [],
      items: [{ ...commodityItems }],
      inputValue: '',
      fieldName: '',
      customerProfile: {},
      pricingDetails: {}
    }
    this.onSelect = this.onSelect.bind(this);
    this.ddlChange = this.ddlChange.bind(this);
    this.datechanges = this.datechanges.bind(this);
    this.vesselChange = this.vesselChange.bind(this);
    this.datechangesPickupDelivery = this.datechangesPickupDelivery.bind(this);
    this.isRequireReference = false;
  }

  componentDidMount() {
    const props = this.props;
    var items = []

    if (getStorage('carrierDetail') != null) {
      const carrierDetail = JSON.parse(localStorage.getItem("carrierDetail"));
      this.isRequireReference = carrierDetail.requireReference
    }

    if (props.selectedLoads && props.selectedLoads.items) {
      items = _.map(props.selectedLoads.items || [], function (e) { return _.omit(e, '_id') })
      let pickupTimes = (props.selectedLoads.pickupTimes && props.selectedLoads.pickupTimes.length !== 0) ? props.selectedLoads.pickupTimes : [];
      let deliveryTimes = (props.selectedLoads.deliveryTimes && props.selectedLoads.deliveryTimes.length !== 0) ? props.selectedLoads.deliveryTimes : [];
      let preSetOrderStatus = props.selectedLoads.preSetOrderStatus ? props.selectedLoads.preSetOrderStatus : "";
      let shipper = props.selectedLoads.shipper;
      let consignee = props.selectedLoads.consignee;
      if (shipper.length > pickupTimes.length) {
        [...new Array(Math.abs(shipper.length - pickupTimes.length))].forEach((el) => pickupTimes.push({}))
      }
      if (consignee.length > deliveryTimes.length) {
        [...new Array(Math.abs(consignee.length - deliveryTimes.length))].forEach((el) => deliveryTimes.push({}))
      }
      this.setState({
        customerProfile: {
          ...props.selectedLoads,
          pickupTimes,
          deliveryTimes,
          preSetOrderStatus
        }, items: items
      })
      this.props.change('caller', props.selectedLoads && props.selectedLoads.caller ? props.selectedLoads.caller.company_name : '');
      this.props.change('shipper', props.selectedLoads && props.selectedLoads.shipper ? props.selectedLoads.shipper.company_name : '');
      this.props.change('consignee', props.selectedLoads && props.selectedLoads.consignee ? props.selectedLoads.consignee.company_name : '');
      this.props.change('chassisPick', props.selectedLoads && props.selectedLoads.chassisPick ? props.selectedLoads.chassisPick.company_name : '');
      this.props.change('emptyOrigin', props.selectedLoads && props.selectedLoads.emptyOrigin ? props.selectedLoads.emptyOrigin.company_name : '');
      this.props.change('chassisTermination', props.selectedLoads && props.selectedLoads.chassisTermination ? props.selectedLoads.chassisTermination.company_name : '');
      this.props.change('loadTime', props.selectedLoads && props.selectedLoads.loadTime ? props.selectedLoads.loadTime : '');
      this.props.change('containerAvailableDay', props.selectedLoads && props.selectedLoads.containerAvailableDay ? props.selectedLoads.containerAvailableDay : '');

      this.props.change('cutOff', props.selectedLoads && props.selectedLoads.cutOff ? props.selectedLoads.cutOff : '');
      this.props.change('return', props.selectedLoads && props.selectedLoads.return ? props.selectedLoads.return : '');
      this.props.change('pickupTime', props.selectedLoads && props.selectedLoads.pickupTime ? props.selectedLoads.pickupTime : '');
      this.props.change('deliveryTime', props.selectedLoads && props.selectedLoads.deliveryTime ? props.selectedLoads.deliveryTime : '');
      this.props.change('callerbillLandingNo', props.selectedLoads && props.selectedLoads.callerbillLandingNo ? props.selectedLoads.callerbillLandingNo : '');
      this.props.change('callerPONo', props.selectedLoads && props.selectedLoads.callerPONo ? props.selectedLoads.callerPONo : '');
      this.props.change('doNo', props.selectedLoads && props.selectedLoads.doNo ? props.selectedLoads.doNo : '');
      this.props.change('bookingNo', props.selectedLoads && props.selectedLoads.bookingNo ? props.selectedLoads.bookingNo : '');
      this.props.change('secondaryReferenceNo', props.selectedLoads && props.selectedLoads.secondaryReferenceNo ? props.selectedLoads.secondaryReferenceNo : '');
      this.props.change('sealNo', props.selectedLoads && props.selectedLoads.sealNo ? props.selectedLoads.sealNo : '');
      this.props.change('purchaseOrderNo', props.selectedLoads && props.selectedLoads.purchaseOrderNo ? props.selectedLoads.purchaseOrderNo : '');
      this.props.change('shipmentNo', props.selectedLoads && props.selectedLoads.shipmentNo ? props.selectedLoads.shipmentNo : '');
      this.props.change('deliveryOrderNo', props.selectedLoads && props.selectedLoads.deliveryOrderNo ? props.selectedLoads.deliveryOrderNo : '');
      this.props.change('releaseNo', props.selectedLoads && props.selectedLoads.releaseNo ? props.selectedLoads.releaseNo : '');
      this.props.change('emptyDay', props.selectedLoads && props.selectedLoads.emptyDay ? props.selectedLoads.emptyDay : '');
      this.props.change('lastFreeDay', props.selectedLoads && props.selectedLoads.lastFreeDay ? props.selectedLoads.lastFreeDay : '');

      this.props.change('containerNo', props.selectedLoads && props.selectedLoads.containerNo ? props.selectedLoads.containerNo : '');
      this.props.change('containerType', props.selectedLoads && props.selectedLoads.containerType && props.selectedLoads.containerType._id ? props.selectedLoads.containerType : '');
      this.props.change('containerSize', props.selectedLoads && props.selectedLoads.containerSize && props.selectedLoads.containerSize._id ? props.selectedLoads.containerSize : '');
      this.props.change('containerOwner', props.selectedLoads && props.selectedLoads.containerOwner && props.selectedLoads.containerOwner._id ? props.selectedLoads.containerOwner : '');
      this.props.change('chassisNo', props.selectedLoads && props.selectedLoads.chassisNo ? props.selectedLoads.chassisNo : '');
      this.props.change('chassisType', props.selectedLoads && props.selectedLoads.chassisType && props.selectedLoads.chassisType._id ? props.selectedLoads.chassisType : '');
      this.props.change('chassisSize', props.selectedLoads && props.selectedLoads.chassisSize && props.selectedLoads.chassisSize._id ? props.selectedLoads.chassisSize : '');
      this.props.change('chassisOwner', props.selectedLoads && props.selectedLoads.chassisOwner && props.selectedLoads.chassisOwner._id ? props.selectedLoads.chassisOwner : '');
      this.props.change('temperature', props.selectedLoads && props.selectedLoads.temperature ? props.selectedLoads.temperature : '');
      this.props.change('hazmat', props.selectedLoads && props.selectedLoads.hazmat ? true : false);
      this.props.change('hot', props.selectedLoads && props.selectedLoads.hot ? true : false);
      this.props.change('liquor', props.selectedLoads && props.selectedLoads.liquor ? props.selectedLoads.liquor : '');
      this.props.change('routeType', props.selectedLoads && props.selectedLoads.routeType ? props.selectedLoads.routeType : '');
      this.props.change('custom', props.selectedLoads ? props.selectedLoads.custom : '');
      this.props.change('freight', props.selectedLoads ? props.selectedLoads.freight : '');
      this.props.change('carrierHold', props.selectedLoads ? props.selectedLoads.carrierHold : '');
      this.props.change('brokerHold', props.selectedLoads ? props.selectedLoads.brokerHold : '');
      this.props.change('returnNo', props.selectedLoads ? props.selectedLoads.returnNo : '');
      this.props.change('overWeight', props.selectedLoads && props.selectedLoads.overWeight ? true : false);
      this.props.change('scac', props.selectedLoads && props.selectedLoads.scac ? props.selectedLoads.scac : '');
      this.props.change('trailer', props.selectedLoads && props.selectedLoads.trailer ? props.selectedLoads.trailer : '');
      this.props.change('terminal', props.selectedLoads && props.selectedLoads.terminal ? props.selectedLoads.terminal : '');
      this.props.change('pickupFromTime', props.selectedLoads && props.selectedLoads.pickupFromTime ? props.selectedLoads.pickupFromTime : '');
      this.props.change('pickupToTime', props.selectedLoads && props.selectedLoads.pickupToTime ? props.selectedLoads.pickupToTime : '');
      this.props.change('deliveryFromTime', props.selectedLoads && props.selectedLoads.deliveryFromTime ? props.selectedLoads.deliveryFromTime : '');
      this.props.change('deliveryToTime', props.selectedLoads && props.selectedLoads.deliveryToTime ? props.selectedLoads.deliveryToTime : '');
      this.props.change('pickupTimes', props.selectedLoads && props.selectedLoads.pickupTimes ? props.selectedLoads.pickupTimes : [{}]);
      this.props.change('deliveryTimes', props.selectedLoads && props.selectedLoads.deliveryTimes ? props.selectedLoads.deliveryTimes : [{}]);
      this.props.change('preSetOrderStatus', props.selectedLoads && props.selectedLoads.preSetOrderStatus ? props.selectedLoads.preSetOrderStatus : "");
      this.props.change('returnFromTime', props.selectedLoads && props.selectedLoads.returnFromTime ? props.selectedLoads.returnFromTime : "");
      this.props.change('returnToTime', props.selectedLoads && props.selectedLoads.returnToTime ? props.selectedLoads.returnToTime : "");
      this.props.change('appointmentNo', props.selectedLoads && props.selectedLoads.appointmentNo ? props.selectedLoads.appointmentNo : "");
      this.props.change('reservationNo', props.selectedLoads && props.selectedLoads.reservationNo ? props.selectedLoads.reservationNo : "");
      this.props.change('genset',props.selectedLoads && props.selectedLoads.genset ? props.selectedLoads.genset : "")
      this.props.change('grayContainerNo', props.selectedLoads && props.selectedLoads.grayContainerNo ? props.selectedLoads.grayContainerNo : '');
      this.props.change('grayContainerType', props.selectedLoads && props.selectedLoads.grayContainerType && props.selectedLoads.grayContainerType._id ? props.selectedLoads.grayContainerType : '');
      this.props.change('grayContainerSize', props.selectedLoads && props.selectedLoads.grayContainerSize && props.selectedLoads.grayContainerSize._id ? props.selectedLoads.grayContainerSize : '');
      this.props.change('grayContainerOwner', props.selectedLoads && props.selectedLoads.grayContainerOwner && props.selectedLoads.grayContainerOwner._id ? props.selectedLoads.grayContainerOwner : '');
      this.props.change('grayChassisNo', props.selectedLoads && props.selectedLoads.grayChassisNo ? props.selectedLoads.grayChassisNo : '');
      this.props.change('grayChassisType', props.selectedLoads && props.selectedLoads.grayChassisType && props.selectedLoads.grayChassisType._id ? props.selectedLoads.grayChassisType : '');
      this.props.change('grayChassisSize', props.selectedLoads && props.selectedLoads.grayChassisSize && props.selectedLoads.grayChassisSize._id ? props.selectedLoads.grayChassisSize : '');
      this.props.change('grayChassisOwner', props.selectedLoads && props.selectedLoads.grayChassisOwner && props.selectedLoads.grayChassisOwner._id ? props.selectedLoads.grayChassisOwner : '');
      // this.props.change('vessel', props.selectedLoads && props.selectedLoads.vessel ? props.selectedLoads.vessel : null);
    }

    this.props.handleSubmitLoad(this.handleSubmitLoad);
  }

  handleSubmitLoad = () => {
    const submitBtn = document.getElementById('submitLoadInfoBtn');
    submitBtn.click();
  }

  componentWillReceiveProps(props) {
    this.setState({ pricing: props.pricing });
    if (this.props.selectedLoads !== props.selectedLoads) {
      var items = [];
      if (props.selectedLoads && props.selectedLoads.items) {
        items = _.map(props.selectedLoads.items || [], function (e) { return _.omit(e, '_id') })
      }
      let pickupTimes = (props.selectedLoads.pickupTimes && props.selectedLoads.pickupTimes.length !== 0) ? props.selectedLoads.pickupTimes : [];
      let deliveryTimes = (props.selectedLoads.deliveryTimes && props.selectedLoads.deliveryTimes.length !== 0) ? props.selectedLoads.deliveryTimes : [];
      let preSetOrderStatus = props.selectedLoads.preSetOrderStatus ? props.selectedLoads.preSetOrderStatus : "";
      let shipper = props.selectedLoads.shipper;
      let consignee = props.selectedLoads.consignee;
      if (shipper.length > pickupTimes.length) {
        [...new Array(Math.abs(shipper.length - pickupTimes.length))].forEach((el) => pickupTimes.push({}))
      }
      if (consignee.length > deliveryTimes.length) {
        [...new Array(Math.abs(consignee.length - deliveryTimes.length))].forEach((el) => deliveryTimes.push({}))
      }
      this.setState({
        customerProfile: {
          ...props.selectedLoads,
          pickupTimes,
          deliveryTimes,
          preSetOrderStatus
        }, items: items
      })
      this.props.change('caller', props.selectedLoads && props.selectedLoads.caller ? props.selectedLoads.caller.company_name : '');
      this.props.change('shipper', props.selectedLoads && props.selectedLoads.shipper ? props.selectedLoads.shipper.company_name : '');
      this.props.change('consignee', props.selectedLoads && props.selectedLoads.consignee ? props.selectedLoads.consignee.company_name : '');
      this.props.change('chassisPick', props.selectedLoads && props.selectedLoads.chassisPick ? props.selectedLoads.chassisPick.company_name : '');
      this.props.change('emptyOrigin', props.selectedLoads && props.selectedLoads.emptyOrigin ? props.selectedLoads.emptyOrigin.company_name : '');
      this.props.change('chassisTermination', props.selectedLoads && props.selectedLoads.chassisTermination ? props.selectedLoads.chassisTermination.company_name : '');
      this.props.change('loadTime', props.selectedLoads && props.selectedLoads.loadTime ? props.selectedLoads.loadTime : '');
      this.props.change('containerAvailableDay', props.selectedLoads && props.selectedLoads.containerAvailableDay ? props.selectedLoads.containerAvailableDay : '');

      this.props.change('cutOff', props.selectedLoads && props.selectedLoads.cutOff ? props.selectedLoads.cutOff : '');
      this.props.change('return', props.selectedLoads && props.selectedLoads.return ? props.selectedLoads.return : '');
      this.props.change('freeReturnDate', props.selectedLoads && props.selectedLoads.freeReturnDate ? props.selectedLoads.freeReturnDate : '');
      this.props.change('pickupTime', props.selectedLoads && props.selectedLoads.pickupTime ? props.selectedLoads.pickupTime : '');
      this.props.change('deliveryTime', props.selectedLoads && props.selectedLoads.deliveryTime ? props.selectedLoads.deliveryTime : '');
      this.props.change('callerbillLandingNo', props.selectedLoads && props.selectedLoads.callerbillLandingNo ? props.selectedLoads.callerbillLandingNo : '');
      this.props.change('callerPONo', props.selectedLoads && props.selectedLoads.callerPONo ? props.selectedLoads.callerPONo : '');
      this.props.change('doNo', props.selectedLoads && props.selectedLoads.doNo ? props.selectedLoads.doNo : '');
      this.props.change('bookingNo', props.selectedLoads && props.selectedLoads.bookingNo ? props.selectedLoads.bookingNo : '');
      this.props.change('secondaryReferenceNo', props.selectedLoads && props.selectedLoads.secondaryReferenceNo ? props.selectedLoads.secondaryReferenceNo : '');
      this.props.change('sealNo', props.selectedLoads && props.selectedLoads.sealNo ? props.selectedLoads.sealNo : '');
      this.props.change('purchaseOrderNo', props.selectedLoads && props.selectedLoads.purchaseOrderNo ? props.selectedLoads.purchaseOrderNo : '');
      this.props.change('shipmentNo', props.selectedLoads && props.selectedLoads.shipmentNo ? props.selectedLoads.shipmentNo : '');
      this.props.change('deliveryOrderNo', props.selectedLoads && props.selectedLoads.deliveryOrderNo ? props.selectedLoads.deliveryOrderNo : '');
      this.props.change('releaseNo', props.selectedLoads && props.selectedLoads.releaseNo ? props.selectedLoads.releaseNo : '');
      this.props.change('emptyDay', props.selectedLoads && props.selectedLoads.emptyDay ? props.selectedLoads.emptyDay : '');
      this.props.change('lastFreeDay', props.selectedLoads && props.selectedLoads.lastFreeDay ? props.selectedLoads.lastFreeDay : '');

      this.props.change('containerNo', props.selectedLoads && props.selectedLoads.containerNo ? props.selectedLoads.containerNo : '');
      this.props.change('containerType', props.selectedLoads && props.selectedLoads.containerType && props.selectedLoads.containerType._id ? props.selectedLoads.containerType : '');
      this.props.change('containerSize', props.selectedLoads && props.selectedLoads.containerSize && props.selectedLoads.containerSize._id ? props.selectedLoads.containerSize : '');
      this.props.change('containerOwner', props.selectedLoads && props.selectedLoads.containerOwner && props.selectedLoads.containerOwner._id ? props.selectedLoads.containerOwner : '');
      this.props.change('chassisNo', props.selectedLoads && props.selectedLoads.chassisNo ? props.selectedLoads.chassisNo : '');
      this.props.change('chassisType', props.selectedLoads && props.selectedLoads.chassisType && props.selectedLoads.chassisType._id ? props.selectedLoads.chassisType : '');
      this.props.change('chassisSize', props.selectedLoads && props.selectedLoads.chassisSize && props.selectedLoads.chassisSize._id ? props.selectedLoads.chassisSize : '');
      this.props.change('chassisOwner', props.selectedLoads && props.selectedLoads.chassisOwner && props.selectedLoads.chassisOwner._id ? props.selectedLoads.chassisOwner : '');
      this.props.change('temperature', props.selectedLoads && props.selectedLoads.temperature ? props.selectedLoads.temperature : '');
      this.props.change('hazmat', props.selectedLoads && props.selectedLoads.hazmat ? true : false);
      this.props.change('hot', props.selectedLoads && props.selectedLoads.hot ? true : false);
      this.props.change('liquor', props.selectedLoads && props.selectedLoads.liquor ? props.selectedLoads.liquor : '');
      this.props.change('routeType', props.selectedLoads && props.selectedLoads.routeType ? props.selectedLoads.routeType : '');
      this.props.change('custom', props.selectedLoads ? props.selectedLoads.custom : '');
      this.props.change('freight', props.selectedLoads ? props.selectedLoads.freight : '');
      this.props.change('carrierHold', props.selectedLoads ? props.selectedLoads.carrierHold : '');
      this.props.change('brokerHold', props.selectedLoads ? props.selectedLoads.brokerHold : '');
      this.props.change('returnNo', props.selectedLoads ? props.selectedLoads.returnNo : '');
      this.props.change('overWeight', props.selectedLoads && props.selectedLoads.overWeight ? true : false);
      this.props.change('scac', props.selectedLoads && props.selectedLoads.scac ? props.selectedLoads.scac : '');
      this.props.change('trailer', props.selectedLoads && props.selectedLoads.trailer ? props.selectedLoads.trailer : '');
      this.props.change('terminal', props.selectedLoads && props.selectedLoads.terminal ? props.selectedLoads.terminal : '');
      this.props.change('pickupFromTime', props.selectedLoads && props.selectedLoads.pickupFromTime ? props.selectedLoads.pickupFromTime : '');
      this.props.change('pickupToTime', props.selectedLoads && props.selectedLoads.pickupToTime ? props.selectedLoads.pickupToTime : '');
      this.props.change('deliveryFromTime', props.selectedLoads && props.selectedLoads.deliveryFromTime ? props.selectedLoads.deliveryFromTime : '');
      this.props.change('deliveryToTime', props.selectedLoads && props.selectedLoads.deliveryToTime ? props.selectedLoads.deliveryToTime : '');
      this.props.change('pickupTimes', props.selectedLoads && props.selectedLoads.pickupTimes ? props.selectedLoads.pickupTimes : [{}]);
      this.props.change('deliveryTimes', props.selectedLoads && props.selectedLoads.deliveryTimes ? props.selectedLoads.deliveryTimes : [{}]);
      this.props.change('preSetOrderStatus', props.selectedLoads && props.selectedLoads.preSetOrderStatus ? props.selectedLoads.preSetOrderStatus : "");
      this.props.change('returnFromTime', props.selectedLoads && props.selectedLoads.returnFromTime ? props.selectedLoads.returnFromTime : "");
      this.props.change('returnToTime', props.selectedLoads && props.selectedLoads.returnToTime ? props.selectedLoads.returnToTime : "");
      this.props.change('appointmentNo', props.selectedLoads && props.selectedLoads.appointmentNo ? props.selectedLoads.appointmentNo : "");
      this.props.change('reservationNo', props.selectedLoads && props.selectedLoads.reservationNo ? props.selectedLoads.reservationNo : "");
      this.props.change('genset',props.selectedLoads && props.selectedLoads.genset ? props.selectedLoads.genset : "")
      this.props.change('grayContainerNo', props.selectedLoads && props.selectedLoads.grayContainerNo ? props.selectedLoads.grayContainerNo : '');
      this.props.change('grayContainerType', props.selectedLoads && props.selectedLoads.grayContainerType && props.selectedLoads.grayContainerType._id ? props.selectedLoads.grayContainerType : '');
      this.props.change('grayContainerSize', props.selectedLoads && props.selectedLoads.grayContainerSize && props.selectedLoads.grayContainerSize._id ? props.selectedLoads.grayContainerSize : '');
      this.props.change('grayContainerOwner', props.selectedLoads && props.selectedLoads.grayContainerOwner && props.selectedLoads.grayContainerOwner._id ? props.selectedLoads.grayContainerOwner : '');
      this.props.change('grayChassisNo', props.selectedLoads && props.selectedLoads.grayChassisNo ? props.selectedLoads.grayChassisNo : '');
      this.props.change('grayChassisType', props.selectedLoads && props.selectedLoads.grayChassisType && props.selectedLoads.grayChassisType._id ? props.selectedLoads.grayChassisType : '');
      this.props.change('grayChassisSize', props.selectedLoads && props.selectedLoads.grayChassisSize && props.selectedLoads.grayChassisSize._id ? props.selectedLoads.grayChassisSize : '');
      this.props.change('grayChassisOwner', props.selectedLoads && props.selectedLoads.grayChassisOwner && props.selectedLoads.grayChassisOwner._id ? props.selectedLoads.grayChassisOwner : '');
      // this.props.change('vessel', props.selectedLoads && props.selectedLoads.vessel ? props.selectedLoads.vessel : null);
      
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  onSelect = (data, fieldName) => {
    const customerProfile = Object.assign({}, this.state.customerProfile);
    switch (fieldName) {
      case 'containerType':
        this.props.change(fieldName, data.name)
        break;
      case 'containerSize':
        this.props.change(fieldName, `${data.l}X${data.b}X${data.h} ${data.unit}^3`)
        break;
      case 'chassisOwner':
        this.props.change(fieldName, data.owner);
        this.props.change('chassisSize', data.size);
        this.props.change('chassisNo', data.chassisNo);
        break;
      case 'chassisNo':
        this.props.change(fieldName, data.chassisNo);
        this.props.change('chassisSize', data.chassisSize._id);
        this.props.change('chassisOwner', data.chassisOwner._id);
        this.props.change('chassisType', data.chassisType._id);
        customerProfile['chassisOwner'] = data.chassisOwner;
        customerProfile['chassisSize'] = data.chassisSize;
        customerProfile['chassisType'] = data.chassisType;
        break;
      default:
        this.props.change(fieldName, data.company_name);
    }
    customerProfile[fieldName] = data;
    this.setState({ customerProfile, showModal: false });
    if (fieldName == 'chassisNo') {
      let newstate = Object.assign({}, customerProfile, { 'chassisOwner': data.chassisOwner._id, 'chassisSize': data.chassisSize._id, 'chassisType': data.chassisType._id })
      this.setState({ customerProfile: newstate });
    }
  }

  updateState = (fieldName, data) => {
    let customerProfile = { ...this.state.customerProfile }
    customerProfile[fieldName] = data;
    this.setState({ customerProfile: customerProfile }, () => {
    })
    if (data && data.company_name) {
      this.props.change(fieldName, data.company_name)
    } else {
      this.props.change(fieldName, data)
    }
  }

  handleKeyPress = (e, input) => {
    if (e.key && e.key == 'Enter') {
      let url;
      switch (input.name) {
        case 'containerSize':
          url = 'admin/getContainerSize';
          break;
        case 'containerType':
          url = 'admin/getContainerType';
          break;
        case 'containerOwner':
          url = 'carrier/getTMSContainerOwner';
          break;
        case 'chassisNo':
        case 'chassisOwner':
          url = 'carrier/getTMSChassis';
          break;
        case 'chassisPick':
        case 'emptyOrigin':
        case 'chassisTermination':
          url = 'carrier/getTMSCustomers';
          break;
        default:
          url = 'carrier/getTMSCustomers';
      }
      this.setState({ showModal: !this.state.showModal, inputValue: input.value, fieldName: input.name }, () => {
        this.fetchUsers(url, this.state.inputValue);
      })
    }
  }

  fetchUsers = (url, inputValue) => {
    var url = url ? url : cachedUrl;
    cachedUrl = url;
    fetchUsers(url, { searchTerm: inputValue })
      .then((response) => {
        const datas = response.data.data;
        this.setState({ datas });
      })
  }

  onValueChange = (event, values, input) => {
    if (values instanceof Date) {
      values = moment(values).add(((values).getTimezoneOffset() + moment(values).utcOffset()) * -1, 'minutes').toISOString()
    }
    this.props.change(input.name, values);
    let old = _.clone(this.state.customerProfile);
    old[input.name] = values;
    this.setState({ customerProfile: old })
  }

  submitForm = (data) => {
    data.isEdi=this.props.selectedLoads && this.props.selectedLoads.isEdi?true:false;
    data.isHot = data.hot;

    let validations = {}
    Object.keys(this.props.loadType == 'ROAD' ? RoadValidation : ImportValidation).forEach((key) => {
      if (!this.state.customerProfile || !this.state.customerProfile[key]) {
        validations[key] = ImportValidation[key]
      }
    })
    if (JSON.stringify(validations) == '{}') {
      this.props.submitForm(data, this.state.customerProfile);
    } else {
      throw new SubmissionError(validations)
    }
  }

  updatePricing = () => {
    this.props.updatePricing({
      items: this.state.items,
      pricing: this.state.pricing
    })
  }

  onKeyChange = (e, input) => {
    this.props.change(input.name, e.target.value);
    if (input.name == 'chassisNo') {
      let customerProfile = Object.assign({}, this.state.customerProfile);
      customerProfile['chassisNo'] = e.target.value;
      this.setState({ customerProfile }, () => {
      })
    }
  }

  close = () => {
    this.setState({ showPriceEdit: false })
  }

  openPriceEdit = (data) => {
    const pricingDetails = Object.assign({}, data);
    this.setState({ showPriceEdit: true, pricingDetails })
  }

  AddPriceRows() {
    this.state.pricing.push({ ...commodityItems });
    this.setState({
      rowsData: [...this.state.pricing]
    });
  }

  AddItemRows() {
    this.state.items.push({ ...commodityItems });
    this.setState({
      rowsData: [...this.state.items]
    });
  }

  tblvalchanges(index, e) {
    const { name, value } = e.target;
    const old = [...this.state.items];
    old[index][name] = value
    this.setState({
      items: [...old]
    });
  };

  pricingchanges(index, e) {
    const { name, value } = e.target;
    const old = [...this.state.pricing];
    old[index][name] = value;
    const basePrice = old.find((obj) => { return obj.name === 'Base Price' })
    if (name === 'type') {
      old[index]['finalAmount'] = old[index]['type'] === 'fixed' ? old[index]['amount'] : (old[index]['amount'] * (basePrice ? basePrice.finalAmount : 0)) / 100
    } else if (name === 'amount') {
      old[index]['finalAmount'] = old[index]['type'] === 'fixed' ? old[index]['amount'] : (old[index]['amount'] * (basePrice ? basePrice.finalAmount : 0)) / 100
    }
    this.setState({
      pricing: [...old]
    });
  };

  DeletePriceRow(index) {
    let data = [...this.state.pricing];
    data.splice(index, 1);
    this.setState({
      pricing: data
    });
  };

  DeleteItemRow(index) {
    let data = [...this.state.items];
    data.splice(index, 1);
    this.setState({
      items: data
    });
  };
  ddlChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    let newstate = Object.assign({}, this.state.customerProfile, { [name]: val })
    this.setState({ customerProfile: newstate });
    this.props.change(name, val)
  }

  datechanges(name, picker) {
    let values = picker;
    let newstate = Object.assign({}, this.state.customerProfile, { [name]: values })
    this.setState({ customerProfile: newstate });
    this.props.change(name, values);
  }
  datechangesPickupDelivery(name, picker, index) {
    let values = picker;
    let { customerProfile } = this.state;
    let controlName = name.indexOf('pickup') > -1 ? 'pickup' : 'delivery';
    customerProfile[`${controlName}Times`][index][name] = values;
    this.props.change(`${controlName}Times`, customerProfile[`${controlName}Times`]);
    this.setState({ customerProfile });
    this.props.change(name, values);
  }
  vesselChange(name, picker) {
    let values = picker;
    let { customerProfile } = this.state;
    customerProfile[name] = {eta : values}
    this.setState({customerProfile});
    this.props.change(name, values);
  }
  render() {
    const { handleSubmit, submitForm } = this.props;
    var disabled = false;
    let userDetails = JSON.parse(getStorage('loggedInUser'));
    var newRecord = !this.props.selectedLoads ? true : false;
    if (this.props.selectedLoads)
      disabled = ["APPROVED", "BILLING", "REBILLING", "PARTIAL_PAID", "FULL_PAID"].includes(this.props.selectedLoads.status);
    let showSave = false;
    if (getStorage('currentUserRole') == 'fleetmanager' && JSON.parse(getStorage('loggedInUser')).fleetManager.carrier == this.props.selectedLoads.carrier._id) {
      showSave = true;
    } else if (getStorage('currentUserRole') == 'carrier' && getStorage('currentUserID') == this.props.selectedLoads.carrier._id) {
      showSave = true;
    } else if (getStorage('currentUserRole') == 'customer' && getStorage('currentUserID') == this.props.selectedLoads.addedBy._id) {
      showSave = true;
    } else if (userDetails.role == 'customer' && userDetails.customer && userDetails.customer.canEditLoad) {
      showSave = true;
    }
    var isSecondaryRef = this.state.customerProfile && this.state.customerProfile.secondaryReferenceNo ? true : false;
    if(this.props.isSaving){
      disabled=this.props.isSaving
    }

    return (
      <React.Fragment>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="text-right py-0 pt-2 pr-3" style={{ display: 'none' }}>
            {/* {showSave && */}
              <button type='button' className="btn btn-primary  btnLoadEditsave"
                disabled={disabled}
                onClick={
                  handleSubmit((data) => {
                    if(showSave) {
                      this.submitForm(data, this.state.customerProfile);
                      this.setState({ isSaveCalled: true })
                      if (this.CommonLoadComponent && this.CommonLoadComponent.clearInfosOnAdd) {
                        this.CommonLoadComponent.clearInfosOnAdd();
                      }
                    }
                  })
                }
                id="submitLoadInfoBtn"
              >
                {this.props.isSaving &&
                  <i className="uil uil-spinner-alt"></i>
                }
                {this.props.selectedLoads ? ' Save' : ' Create Load'}
              </button>
            {/* } */}
          </div>
          {this.props.loadType === 'ROAD' ?
            <NewRoadLoadComponent
              updateState={this.updateState}
              datechanges={this.datechanges}
              datechangesPickupDelivery={this.datechangesPickupDelivery}
              onSelect={this.onSelect}
              newRecord={newRecord}
              isSaveCalled={this.isSaveCalled}
              handleKeyPress={this.handleKeyPress}
              disabled={!showSave || disabled}
              isRequireReference={(this.isRequireReference && !isSecondaryRef)}
              {...this.props}
              {...this.state} /> :
            <NewCommonLoadComponent
              ref={instance => { this.CommonLoadComponent = instance; }}
              updateState={this.updateState}
              vesselChange={this.vesselChange}
              onDdlChange={this.ddlChange}
              newRecord={newRecord}
              disabled={!showSave || disabled }
              onSelect={this.onSelect}
              isRequireReference={(this.isRequireReference && !isSecondaryRef)}
              handleKeyPress={this.handleKeyPress}
              datechanges={this.datechanges}
              datechangesPickupDelivery={this.datechangesPickupDelivery}
              onValueChange={this.onValueChange}
              onKeyChange={this.onKeyChange}
              onExit={this.props.onExit}
              stepsEnabled={this.props.stepsEnabled}
              initialStep={this.props.initialStep}
              originalLoad={this.props.originalLoad}
              {...this.props}
              {...this.state}
            />
          }
        </form>
        {this.state.showModal &&
          <PopUp datas={this.state.datas} inputValue={this.state.inputValue} close={this.closeModal} fieldName={this.state.fieldName} onSelect={this.onSelect.bind(this)} fetchUsers={this.fetchUsers} />
        }
      </React.Fragment>
    )
  }
}

NewAddLoad = reduxForm({
  form: 'axleload',
})(NewAddLoad);

function mapStateToProps(state) {
  return {
    formValue: state.form,
    shippersOptions: state.TmsReducer.shippers,
    consigneesOptions: state.TmsReducer.consignees,
    callers: state.TmsReducer.callers,
    customers: state.TmsReducer.customers,
    allCustomers: state.TmsReducer.allCustomers,
    chassisTerms: state.TmsReducer.chassisTerms
  }
}

export default connect(mapStateToProps, null)(NewAddLoad);
NewAddLoad.propTypes = {
  formClass: PropTypes.string,
  submit: PropTypes.func,
  loadType: PropTypes.string
};
