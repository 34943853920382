import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spinner } from '../../Components/Common/Components/Component';
import { browserHistory } from 'react-router';
import * as actionCreators from '../Token/actionCreators';
import { toastr } from '../../services/Common.services';
import { withFormik } from 'formik';
import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'sameAs', function (ref, message) {
  return this.test('sameAs', message, function (value) {
    let other = this.resolve(ref);
    return !other || !value || value === other;
  })
})

const InnerForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) => (
    <div className="center-child">
      <div className="col-xs-12 col-sm-6 col-md-5">
        <div className="sign-up-form">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h4 className="heading text-uppercase">Forget</h4>
            </div>
          </div>
          <div className="row inline-center-children">
            <div className="col-sm-12">
              <div className="btn-group swith-block" role="group">
                <h3>Forget Password</h3>
              </div>
            </div>

          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div>
              <div className="row">
                <div className="col-sm-12">
                  <div className={`form-group  ${errors.password && 'has-error'}`}>
                    <label htmlFor="password" className="text-uppercase">
                      New Password
                        <span className="required-field" title="This field is reqired.">*</span>
                    </label>
                    <input type="password" className="form-control" id="password" name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                    {errors.password && touched.password && <span className="text-danger">{errors.password}</span>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className={`form-group  ${errors.confirmPassword && 'has-error'}`}>
                    <label htmlFor="password" className="text-uppercase">
                      Confirm Password
                        <span className="required-field" title="This field is reqired.">*</span>
                    </label>
                    <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur} />
                    {errors.confirmPassword && touched.confirmPassword && <span className="text-danger">{errors.confirmPassword}</span>}
                  </div>
                </div>
              </div>
              {isSubmitting &&
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <Spinner />
                  </div>
                </div>
              }

              <div className="row">
                <div className="col-sm-12 inline-center-children">
                  <div className="btn-group">
                    <input type="submit" name="login" id="login" className="text-uppercase btn-submit clickable" value="Save" disabled={isSubmitting} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

const Forget = withFormik({
  // Transform outer props into form values
  mapPropsToValues: props => ({
    password: '',
    confirmPassword: ''
  }),
  // Add a custom validation function (this can be async too!)
  validationSchema: Yup.object().shape({
    password: Yup.string().min(5, 'Enter atleast 5 characters.').max(20, 'Enter less than 20 characters.').required('Password is required.'),
    confirmPassword: Yup.string()
      .sameAs(Yup.ref('password'), 'passwords doesn\'t match')
      .min(5, 'Enter atleast 5 characters.')
      .max(50, 'Enter less than 50 characters.')
      .required('Password confirm is required')
  }),
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */,
    }
  ) => {
    const newPassword = values.password;
    const passwordResetToken = props.params.token;
    props.actions.forgetPassword({
      passwordResetToken,
      newPassword
    }).then((result) => {
      toastr.show('Your password has been successfully changed!', 'success');
      browserHistory.push('/')
      setSubmitting(false);
    })
      .catch((Err) => {})
      .finally(() => {
        setSubmitting(false);
      })
  },
})(InnerForm);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    userType: state.HomeReducer.userType,
    user: state.TokenReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forget);
