import React, { Component } from 'react';
import {
  IconDocument,
  IconSearchData,
  IconNotes,
  IconLoadCartRight,
  IconClock9,
} from '../../../Components/Common/Icons';
import TabAudit from './tabContent/audit';
import TabChassis from './tabContent/chassis';
import TabDocument from './tabContent/documents';
import TabHistory from './tabContent/history';
import TabNotes from './tabContent/notes';
class ChassisLoadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  _findValue = (key, value, label) => {
    let data = this.props.selectedchassis[key].find((d) => d._id === value);
    if (data) return data[label] || data.name ;
    return;
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-fluid">
          <div className="form-row-md fcpopup">
            <div className="col-md fcpopup__left" style={{ maxWidth: 270 }} ref="inner">
              <div className="card p-15 mb-1">
                <h5>About</h5>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis #</span>
                  <span>{this.props.selectedchassis.chassisNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis Type</span>
                  <span>{
                        this.props.selectedchassis.Chassis_Type &&
                        this._findValue(
                          "ChassisType",
                          this.props.selectedchassis.Chassis_Type,
                          "name"
                        )
                      }</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis size</span>
                  <span>{
                        this.props.selectedchassis.Chassis_Size &&
                        this._findValue(
                          "ChassisSize",
                          this.props.selectedchassis.Chassis_Size,
                        "company_name"
                        )
                      }</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis Owner</span>
                  <span>{
                        this.props.selectedchassis.Chassis_Owner &&
                        this._findValue(
                          "TMSChassisOwner",
                          this.props.selectedchassis.Chassis_Owner,
                          "company_name"
                        )
                        }</span>
                </div>
              </div>
            </div>
            <div className="col-md fcpopup__right" ref="inner">
              <div className="card mb-0">
                <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 1 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(1)}
                    >
                      <IconLoadCartRight />
                      <div className="nav-link__text">Chassis</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 2 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(2)}
                    >
                      <IconDocument />
                      <div className="nav-link__text">Documents</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 3 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(3)}
                    >
                      <IconClock9 />
                      <div className="nav-link__text">History</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 4 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(4)}
                    >
                      <IconSearchData />
                      <div className="nav-link__text">Audit</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 5 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(5)}
                    >
                      <IconNotes />
                      <div className="nav-link__text">Notes</div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-loads">
                {this.state.isLoadTabActive === 1 && <TabChassis chassisDetail={{...this.props.selectedchassis}} setValChange={this.props.setValChange} setVal={this.props.setVal}/>}
                {this.state.isLoadTabActive === 2 && <TabDocument />}
                {this.state.isLoadTabActive === 3 && <TabHistory chassisHistory={this.props.selectedchassis.chassisHistory}/>}
                {this.state.isLoadTabActive === 4 && <TabAudit audits={this.props.selectedchassis.audits}/>}
                {this.state.isLoadTabActive === 5 && <TabNotes notes={this.props.selectedchassis.notes} setVal={this.props.setVal}/>}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChassisLoadPage;
