import React ,{Component}from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { setStorage } from '../../services';
class LoginButton extends Component {
    constructor(props){
        super(props);
        this.state = {
            count: 0
          };
    }
    handleClick = ()=>{
        setStorage("authRole",JSON.stringify(this.props.role))
        .then( this.props.auth0.loginWithRedirect())
       
    }
    render(){
        // const search = window.location.search
        const {user,loginWithRedirect,getAccessTokenSilently} = this.props.auth0
        // console.log(search, "This is the search...",user)
        const {onUser} = this.props
        if(user && this.state.count === 0){
            this.setState({count: this.state.count + 1})
            let token
            (async () => {
                token = await getAccessTokenSilently()
                onUser({email: user.email , token})
            })()
            
        }
        return (
            <div className="text-center">
            <button type='button' onClick={this.handleClick} className="btn btn-primary btn-lg btn-block mt-40">
            {this.props.processing &&  
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            >
            </span>
          }
            Enterprise Login</button>
            </div>
        )
    }
}

export default withAuth0(LoginButton) 