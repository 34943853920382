import React,{useEffect,useState, useRef,memo} from "react";
import { Link } from "react-router";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { 
  updateQueryString,
  getFilterParams,
  getSessionDispatchView,  
  getStorage,
  isNewModal
} from "../../services/Common.services";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper';
import * as actionCreators from '../../pages/tms/Dispatcher/actionCreators'
import * as tabs from "./items";
import configuration from "../../config";
import { Icondots, IconDeleteRed, IconPlus, IconPen, IconCheck, IconTimes } from "../Common/Icons";
import ProBatch from '../Common/ProBatch/index';
import useHover from "../CustomHooks/useHover";
import PopperTooltip from "../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";

const SubHeaderTab = (props) => {
  const { buttons, val, title, updateProblemInvoiceCount,removeDispatcherView, clearFilter, modalView, filteredData, isDispatcherView, reload, isLoadingBarShow } = props; 
  // const [importStCounts,setImportStCount]=useState(0)
  const [stCounts,setStCount]=useState(0)
  const [pbCounts,setPbCount]=useState(0)
  const [dispatcherView,setDispatcherView] = useState() 
  const [showInput, setShowInput] = useState(false)
  const [problemInvoiceCount,setProblemInvoiceCount]=useState(0)
  const [newView, setNewView] = useState("")
  const [showOptions, setShowOptions] = useState();

  const [Ref1, setRef1] = useState(null);

  const [showButton, setShowButton] = useState();
  const wrapperRef = useRef(null);

  const [referenceElementRef, referenceElement] = useHover();

  useEffect(() => { 
    if(!dispatcherView?.length)
    setDispatcherView(getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews")))
  }, [isLoadingBarShow]);

  useOutsideAlerter(wrapperRef);
  const user =
    getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"));
  const timeZone = localStorage.getItem("timeZone")
  let carrierId = null;
    if (user && user.role === 'fleetmanager') {
      carrierId = user.fleetManager.carrier;
    }else if(user && user.role === 'carrier') {
      carrierId = user._id;
    }
  const carrier =
    getStorage("carrierDetail") && JSON.parse(getStorage("carrierDetail"));
  const getAuthorize = (array) => {
    let tempArray = [];
    if (
      user &&
      user.currentUserRole !== "carrier" &&
      user.permissions.length &&
      array.length
      ) {
        tempArray = array.filter((item) => {
          if (!item.permission) {
            return item;
          }
          if(Array.isArray(item.permission)){
            if(_.intersection(user.permissions,item.permission).length > 0){
              return item;
            }
          }else{
            if (user.permissions.includes(item.permission)) {
              return item;
            }
          }
        });
    } else {
      tempArray.push(...array);
    }
    return tempArray;
  };

  const getCountST=()=>{
    const filterParams = {
      status: StreetTurnStatus,
      type_of_load: ["IMPORT","EXPORT"],
    };
    actionCreators.getStreetTurnLoadCounts(filterParams).then((res) => {
      let ImpCount = 0
      let ExpCount = 0
      res.data.forEach(el=>{
        ImpCount = el.IMPORT+ImpCount
        ExpCount = el.EXPORT+ExpCount
      })
      setStCount(ImpCount+ExpCount)
      });

  }

  const getPbCounts = ()=>{
    const filterParams = {
      status: AllStatus,
      "caution":true,
    };
    if(localStorage.getItem("timeZone")!=="null"){
      actionCreators.getContainerInformationCount()
      .then(res=>{
        let resp=res.data;
        let count=0;
        count=(resp.problemContainerCount?resp.problemContainerCount:0)+(resp.lfdCount?resp.lfdCount:0)+(resp.returnContainerCount?resp.returnContainerCount:0);
        setPbCount(count);
      })
      .catch((err) =>{})
    }
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setShowOptions()
              ReactTooltip.hide();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

  useEffect(()=>{
    if(val==="dispatchItems"){
      getCountST()
      getPbCounts()
    }
    if(val==="accRecievableItems"&& (user.qbAccount !== undefined ||carrier.qbAccount !== undefined) && (user.role != 'customer') &&  !user?.fleetManager?.isCustomer ){
      GetProblemInvoiceCount()
    }
  },[updateProblemInvoiceCount])

  const GetProblemInvoiceCount = ()=>{
        let TotalCount =0
    const params = {
      isSubCustomer:true,
      getCountOnly:true
    }
    actionCreators.getDesyncedQbLoadsCount(params)
    .then((res)=>{
      TotalCount = TotalCount+res.count
      actionCreators.getDesyncedQbLoadsCount({getCountOnly:true})
      .then((res)=>{
        TotalCount = TotalCount+res.count;
      setProblemInvoiceCount(TotalCount)})
     })
  }

  useEffect(()=>{
    let localView = getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews"));
    setDispatcherView(localView)
  },[reload])

  useEffect(()=>{
    
    if(dispatcherView && dispatcherView.length>0 && !showButton && filteredData){
      const query  = new URLSearchParams(window.location.search.substring(1))
      // If viewID exist in query string, setButton to view and load filter data from query string
      // if(query.has("filter")) {
      //   const filterParams = getFilterParams("filter", query) 
      //   if(filterParams && filterParams.cv) return setShowButton(filterParams.cv)
      // }
        if(query.has("filter")) {
          if(query.has("view"))
            return setShowButton(query.get("view"))
        }else{
          if(query.has("view")) {
            dispatcherView.forEach((item)=>{
              if(item._id === query.get("view")){
                setShowButton(item._id)
                updateQueryString({"view": item._id})
                filteredData(getSessionDispatchView(item._id, false) ? getSessionDispatchView(item._id, false) : item, true, false)
              }
            })
            return 
          }
        }


          // Otherwise filter default views data
        dispatcherView.forEach((item)=>{
          if(item.isDefaultView){
            setShowButton(item._id)
            updateQueryString({"view": item._id})
            filteredData(getSessionDispatchView(item._id, false) ? getSessionDispatchView(item._id, false) : item, false, false)
            
          }
        })
 
    }
    dispatcherView?.last()?.isDefaultView !== false && setShowButton(dispatcherView?.last()?._id)
  },[dispatcherView])
  
  const deleteView = (view, index) => {
      removeDispatcherView(view)
  }

  useEffect(() => {
    if (val === "reportItems") {
      const data = document.querySelector(".sub_header_tab a.active")
        ?.parentElement?.id;
      if (data) {
        const seletectTab = document.getElementById(data);
        const scrollContainer = document.getElementById("subheader_scroll");

        const SubHeaderAutoScroll = (event) => {
          event.preventDefault();
          scrollContainer.scrollLeft += event.deltaY;
        };
        scrollContainer.addEventListener("wheel", SubHeaderAutoScroll);
        seletectTab.scrollIntoView({ block: "nearest", inline: "nearest" });
        return () => {
          scrollContainer.removeEventListener("wheel", SubHeaderAutoScroll);
        };
      }
    }
  }, [buttons]);

  const badgeIcon = (title)=>{
    switch (title.split(" ").join('_')) {
      case "Street_Turns":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white badge-success-500 badge-pill">{stCounts}</span>
        break;
      case "Problem_Containers":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white bg-warning-500 badge-pill">{pbCounts}</span>
        break;
      case "Problem_Invoices":
        return <span className="badge badge-sm font-12 ml-1 px-1 text-white bg-warning-500 badge-pill">{problemInvoiceCount}</span>
        break;
      default:
        return null
        break;
    }
  }

  const isShiposCarrier = carrier?.isShiposCarrier;
  const driverItems = tabs["driverItems"].map(item => item.title);
  const customerItems = tabs["customerItems"].map(item => item.title);
  const accRecievableItems = tabs["accRecievableItems"].map(item => item.title);
  const allowedTabs = [...driverItems,...customerItems, accRecievableItems[0], "Dispatcher","Online Tendered Loads V2", "General Info", "My Apps"];
  let tabsArray = getStorage("currentUserRole") !== "customer" && !title && tabs[val] && tabs[val].length > 0 && getAuthorize(tabs[val]);

  if(!isShiposCarrier){
    tabsArray = tabsArray.filter(item => item.title !== "Online Tendered Loads V2" )
  }else if(val === "customerServiceItems"){
    tabsArray = tabsArray.filter(item => item.title === "Online Tendered Loads V2" );
    tabsArray.unshift(tabs["dispatchItems"][0])
  }

  return (
    <div className="subheader">
      <div className="subheader__inner d-flex align-items-center py-0">
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          <ul
            className="nav nav-tabs nav-tabs-custom flex-grow-1 overflow-x-auto text-nowrap flex-nowrap mr-2"
            role="tablist"
            style={{ overflowY: "hidden" }}
            id="subheader_scroll"
          >
            {title && (
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  // to={item.path}
                  activeClassName={"active"}
                >
                  <span className="hide-menu">{title}</span>
                </Link>
              </li>
            )}
            {tabsArray.map((item, i) => {
                const tabLink = isShiposCarrier ? (!allowedTabs.includes(item.title) ? '' : item.path) : item.path;

                if (item.title === "Terminal Settings") {
                  return (
                    getStorage("currentUserRole") == "carrier" &&
                    configuration.idForTerminal.indexOf(
                      getStorage("currentUserID")
                    ) != -1 && (
                      <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                        <Link
                          className={`nav-link`}
                          to={item.path}
                          activeClassName={"active"}
                        >
                          <span className="hide-menu">{item.title}</span>
                        </Link>
                      </li>
                    )
                  );
                } else if(item.title === "Import Charge Report" || item.title === "Export Charge Report") {
                  return (
                      configuration.idForCustomChargeReport.indexOf(
                        carrierId
                      ) > -1 && (
                      <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                        <Link
                          className={`nav-link`}
                          to={item.path}
                          activeClassName={"active"}
                        >
                          <span className="hide-menu">{item.title}</span>
                        </Link>
                      </li>
                    )
                  )
                }
                
                if(item.title === "Driver Pay Rates") {
                  return (
                    <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                      <Link
                        className={`nav-link`}
                        to={JSON.parse(getStorage("carrierDetail"))?.newDriverPay ? "/tms/driver-pay-rates" : "/tms/payment-setting"}
                        activeClassName={"active"}
                      >
                        <span className="hide-menu">{item.title}</span>
                      </Link>
                    </li>
                  )
                }
                
                if(item.title ==="Problem Invoices" && !(user.qbAccount !== undefined||carrier.qbAccount !== undefined)){
                  return false
                }
                if(item.title ==="My Apps" && (user.role !== 'carrier')){
                  return false
                }
                if(item.title === "Driver Planner" && !(JSON.parse(getStorage("carrierDetail") ?? "{}")?.enableDriverPlanner ?? false)) {
                  return (<></>);
                }
                if(item.title === "Loads" && isNewModal()) return <></>
                return (
                  <li className="nav-item sub_header_tab" key={i} id={`tab${item.title.split(" ").join("_")}`}>
                    {(isShiposCarrier && !allowedTabs.includes(item.title)) && <ProBatch />}
                    <Link
                      className={`nav-link ${isShiposCarrier && !allowedTabs.includes(item.title) && 'disabled__tab'}`}
                      to={tabLink}
                      activeClassName={"active"}
                    >
                      <span className="hide-menu">{item.title}
                       { badgeIcon(item.title) }</span>
                    </Link>
                  </li>
                );
              })}
              {isDispatcherView && dispatcherView && dispatcherView.length > 0 && dispatcherView.map((item, i)=>{
                if(item.viewName){
                    
                    if(item.viewName == newView){
                      setShowInput(!showInput);
                      setNewView("")
                    }
                    return (
                      <li className="nav-item" key={i}>
                        <div className="d-flex align-items-center justify-content-center">
                          <span className={`nav-link hide-menu ${showButton === item._id ? "active" : ""}`} onClick={()=> {
                            setShowButton(item._id)
                            updateQueryString({"view": item._id})
                            filteredData(            
                              getSessionDispatchView(item._id, false) ? getSessionDispatchView(item._id, false) : item
                              ,true
                            )}
                            }> {item.viewName} {item.isDefaultView && <span class="badge badge-sm font-12 ml-1 text-white badge-status-light-blue badge-pill"><IconCheck /></span> } 
                          </span>
                          {showButton == item._id && 
                            <React.Fragment>
                              <span className ="pointer" onClick={() => {
                                setShowOptions()
                                setShowButton()
                                clearFilter(true)}}>
                                <IconTimes />
                              </span>
                              <span 
                              data-tip
                              data-for="addView"
                              data-event="click"
                               ref={(ref) => setRef1(ref)}
                              className = "pointer  tooltip-label"
                                onClick={()=>{
                                  setShowOptions(showOptions != item._id ? item._id : undefined)
                                  ReactTooltip.show(Ref1);
                                }}
                              >
                                  <Icondots className='ml-2'  />
                              </span>
                              <ReactTooltip
                                place="bottom"
                                effect="solid"
                                id="addView"
                                className="dispatcher-info"
                              >
                                  <div className="bg-white h-90 p-10 rounded-lg" ref={wrapperRef}>
                                    <div className="pl-10 pr-10 pb-2 pt-1 pointer">
                                    <h6 className="font-14 m-0" onClick={()=>{
                                      setShowOptions()
                                      modalView(item)
                                      ReactTooltip.hide();
                                      }} ><IconPen className="text-gray-500" /> <span className="ml-10">Edit</span></h6>
                                      
                                    </div>
                                    <div className="pl-10 pr-10 pb-1 pt-2 pointer">
                                    <h5 className="font-14 m-0" onClick={()=>{
                                      setShowOptions()
                                      deleteView(item, i)
                                      ReactTooltip.hide();
                                      }}><IconDeleteRed  /> <span className="ml-10 text-danger">Delete</span></h5>
                                    </div>
                                  </div>
                              </ReactTooltip>
                            </React.Fragment>
                          }
                        </div>
                      </li>
                    )
                }
              })
              }
              {isDispatcherView && (
                <li className="nav-item">
                  <span 
                    className="nav-link hide-menu" 
                    onClick={()=>{
                      !isShiposCarrier && modalView()
                    }}> 
                    <span ref={referenceElementRef}>
                      <IconPlus />
                    </span>
                  </span>
                    {referenceElement && 
                      <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef?.current} name={'Add New View'}  color={'gray-700'} cssClass={"add_view"} />
                    }
                </li>
              )}
          </ul>

          <div className="ml-auto d-flex flex-shrink-0 ml-2">{buttons}</div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(memo(SubHeaderTab)) ;

const StreetTurnStatus = [
  "AVAILABLE",
  "PENDING",
  "DISPATCHED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED"
];

const AllStatus = ["AVAILABLE","PENDING","DEPARTED","DISPATCHED","CHASSISPICK_ARRIVED","CHASSISPICK_DEPARTED","PULLCONTAINER_ARRIVED","PULLCONTAINER_DEPARTED","DROPCONTAINER_ARRIVED","DROPCONTAINER_DEPARTED","HOOKCONTAINER_ARRIVED,","HOOKCONTAINER_DEPARTED","DELIVERLOAD_ARRIVED","DELIVERLOAD_DEPARTED","RETURNCONTAINER_ARRIVED","RETURNCONTAINER_DEPARTED","CHASSISTERMINATION_ARRIVED","CHASSISTERMINATION_DEPARTED"]