import React, { Component } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { bindActionCreators } from "redux";
import * as actionCreators from "./actionCreator";
import { smallSelectStyle } from "../../../assets/js/select-style";
import confirm from "../../../Components/Common/ConfirmAert";
import { loadEventRel, loadEvents, ROUTING_LABELS, tmsDrops, tmsHooks } from "../../tms/constant";
import {
  IconCalendar,
  IconCheck,
  IconDrag,
  IconPlus,
  IconTimes,
  IconTrash,
  IconNotes,
  IconPool
} from "../../../Components/Common/Icons";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import {
  getCarrierByUserId,
  getStorage,
  isMilitaryTime,
  makeOptionsForSelect,
  toastr
} from "../../../services/Common.services";
import { getDefaultAddress, hardRemoveDriver, getAllCustomers } from "../Dispatcher/actionCreators";
import {
  removeStatusDriverOrderById,
  setLoadStatusOrder,
  uncompleteLoad,
  updateDriverOrderById,
  removeBobtailDriverPay,
  updateLoadCompletedDate
} from "./actionCreator";
import StreetTurn from "./StreetTurn";
import "./style.css";
import { manualDriverPay, UpdatePricing, saveLoadNotes, removeValueLoad } from './../../tms/services'
import ReactTooltip from "react-tooltip";
import LoadInfoModal from "../../../Components/LoadInfoModal/LoadInfoModal";
import RecommendedReturnTerminals from "./RecommendedReturnTerminals";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { ICONS, PRESET_ORDER_STATUS, ROUTING_ADDRESS_FIELDS, ROUTING_DRAG_LEGS } from "./DriverOrderUtility";
import DriverOrderSidebar from "./DriverOrderSidebar";

class NewLoadDriverOrder extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      assignedDriver: null,
      selectedRoute: null,
      driverOrder: [],
      driverNote: "",
      officeNote: "",
      droppedIndex: null,
      CHASSISPICKCUSTOMER: props.selectedLoads?.chassisPick || null,
      PULLCONTAINERCUSTOMER: props.selectedLoads.shipper[0] || null,
      DELIVERLOADCUSTOMER: props.selectedLoads.consignee[0] || null,
      RETURNCONTAINERCUSTOMER: props.selectedLoads.emptyOrigin || null,
      DROPCONTAINERCUSTOMER: null,
      LIFTOFFCUSTOMER: null,
      CHASSISTERMINATIONCUSTOMER: props.selectedLoads.chassisTermination || null,
      isPrePullCharge: props.selectedLoads.isPrePullCharge,
      isNextContainerNeedToPickup:
      props.selectedLoads.isNextContainerNeedToPickup,
      activeNoteTab: "load",
      allowDriverCompletion: props.selectedLoads.allowDriverCompletion,
      changeCompletionDate: null,
      groupTerminalData: [],
      showNote: false,
      selectedLoad: null,
      loading: false,
      isGrayPool: props.selectedLoads.isGrayPool,
      grayChassisNo: props.selectedLoads.grayChassisNo,
      grayContainerNo: props.selectedLoads.grayContainerNo,
      loadCompletedAt: props.selectedLoads.loadCompletedAt,
      colorFlag: false,
      currentLoads:props.selectedLoads,
      isLoader: false
    };

    this.colorIndex = 0;
    this.submitNotes = this.submitNotes.bind(this);
    this.currentUserDetails = JSON.parse(getStorage("loggedInUser"));
    this.userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
  }

  componentDidMount() {
    this.getLoadDriverOrder();
    if (this.props.selectedLoads.officeNote) {
      this.setState({ officeNote: this.props.selectedLoads.officeNote });
    }
    if (this.props.selectedLoads.driverNote) {
      this.setState({ driverNote: this.props.selectedLoads.driverNote });
    }
    if (this.props.selectedLoads.terminal) {
      let carrierId = this.currentUserDetails._id;

      if (this.currentUserDetails.role == "carrier") {
        carrierId = this.currentUserDetails._id;
      } else if (this.currentUserDetails.role == "fleetmanager") {
        carrierId = this.currentUserDetails.fleetManager.carrier;
      }
      if (carrierId) {
        getCarrierByUserId(carrierId).then((response) => {
          let details = response.data.data;
          if (details?.carrier?.terminalDefaultDrop?.length > 0) {
            const terminalDefaultDrop =
              details.carrier.terminalDefaultDrop.find(
                (tdd) => tdd.terminal === this.props.selectedLoads.terminal._id
              );
            if (terminalDefaultDrop) {
              this.setState({
                DROPCONTAINERCUSTOMER: terminalDefaultDrop.customerId,
                LIFTOFFCUSTOMER: terminalDefaultDrop.customerId,
              });
            }
          }
        });
      }
    } else {
      if (
        this.props.selectedLoads.carrier.carrier &&
        this.props.selectedLoads.carrier.carrier.defaultAddress
      ) {
        getDefaultAddress({
          _id: this.props.selectedLoads.carrier.carrier.defaultAddress,
        }).then((dropcontainerCustomer) => {
          this.setState({ DROPCONTAINERCUSTOMER: dropcontainerCustomer, LIFTOFFCUSTOMER: dropcontainerCustomer });
        });
      }
    }

    if (this.props.handleSubmitLoad) {
      this.props.handleSubmitLoad(() => this.handleOrderStatusChange("update"));
    }

    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    const carrierUser = JSON.parse(getStorage("carrierDetail"));
    if(loggedInUser.role === "fleetmanager" && carrierUser.driverPreferences) {
      this.setState({ driverPreferences: true });
    }
    if (loggedInUser && loggedInUser.driverPreferences) {
      this.setState({ driverPreferences: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.selectedLoads.driverOrder, this.props.selectedLoads.driverOrder)) {
      this.setState({ driverOrder: this.props.selectedLoads.driverOrder });
    }
    if (!_.isEqual(prevProps.selectedLoads.grayContainerNo, this.props.selectedLoads.grayContainerNo)) {
      this.setState({ grayContainerNo: this.props.selectedLoads.grayContainerNo });
    }
    if (!_.isEqual(prevProps.selectedLoads.grayChassisNo, this.props.selectedLoads.grayChassisNo)) {
      this.setState({ grayChassisNo: this.props.selectedLoads.grayChassisNo });
    }
    if (!_.isEqual(prevProps.selectedLoads.loadCompletedAt, this.props.selectedLoads.loadCompletedAt)) {
      this.setState({ loadCompletedAt: this.props.selectedLoads.loadCompletedAt });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props.selectedLoads, nextProps.selectedLoads) || !_.isEqual(this.state, nextState);
  }

  componentWillReceiveProps(props) {
    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    const carrierUser = JSON.parse(getStorage("carrierDetail"));
    if(loggedInUser.role === "fleetmanager" && carrierUser.driverPreferences) {
      this.setState({ driverPreferences: true });
    }
    if (loggedInUser && loggedInUser.driverPreferences) {
      this.setState({ driverPreferences: true });
    }
  }

  checkTheChange = (e,className) => {
    let date = moment(e).toISOString();
    // check with previous value if same than return
    const noChange = _.isEqual(this.props.originalLoad?.driverOrder[0]?.loadAssignedDate,date)
    if(noChange) return
    this.handleOrderStatusChange("update", className)
  }

  getLoadDriverOrder = () => {
    if (this.props.selectedLoads) {
      this.setState({
        driverOrder: this.props.selectedLoads.driverOrder,
        isPrePullCharge: this.props.selectedLoads.isPrePullCharge,
        isNextContainerNeedToPickup:
          this.props.selectedLoads.isNextContainerNeedToPickup,
        allowDriverCompletion: this.props.selectedLoads.allowDriverCompletion,
        changeCompletionDate: null,
        isGrayPool: this.props.selectedLoads.isGrayPool,
        grayChassisNo: this.props.selectedLoads.grayChassisNo,
        grayContainerNo: this.props.selectedLoads.grayContainerNo,
      });
    }
    if (this.props.selectedLoads.preSetOrderStatus) {
      this.setState({
        selectedRoute: this.props.selectedLoads.preSetOrderStatus,
      });
    } else {
      this.setState({ selectedRoute: "" });
    }
  };

  createLoadStatus(type, customerId, driver, prevType, loadAssignedDate, isGrayPool = false) {
    const status = {
      type: type,
      isVoidOut: false,
    };
    if (customerId) status.customerId = customerId;
    if (driver) status.driver = driver;
    if (prevType) status.prevType = prevType;
    if (loadAssignedDate) status.loadAssignedDate = loadAssignedDate;
    status.isGrayPool = isGrayPool
    return status;
  }

  onSelectedRouteChange(selectedRoute, reset) {
    // check with previous value if same than return
    const noChange = _.isEqual(this.props.originalLoad?.preSetOrderStatus,selectedRoute)
    if(noChange) return
    this.setState({ selectedRoute });
    
    let driverOrder = [],isGrayPool = false;
    if (!reset)
      driverOrder = this.state.driverOrder.filter(
        (status) => status.isVoidOut || status.arrived || status.departed
      );

    const loadAssignedDate = driverOrder[driverOrder.length - 1]
      ? driverOrder[driverOrder.length - 1].loadAssignedDate
      : this.state.driverOrder[0] && this.state.driverOrder[0].loadAssignedDate;
    const customer = {
      DROPCONTAINER: this.state.DROPCONTAINERCUSTOMER,
      HOOKCONTAINER: this.state.DROPCONTAINERCUSTOMER,
      DELIVERLOAD: this.state.DELIVERLOADCUSTOMER,
      RETURNCONTAINER: this.state.RETURNCONTAINERCUSTOMER,
    };

    if (
      this.props.selectedLoads &&
      this.props.selectedLoads.type_of_load === "ROAD"
    ) {
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus(
            "DELIVERLOAD",
            con,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DELIVERLOAD"],
          this.props.selectedLoads.driver,
          "DELIVERLOAD",
          loadAssignedDate
        )
      );
    } else if (selectedRoute === "PULL_DROP_DELIVER_DROP_RETURN") {
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DROPCONTAINER"],
          this.props.selectedLoads.driver,
          "PULLCONTAINER",
          loadAssignedDate
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["HOOKCONTAINER"],
          null,
          "PULLCONTAINER",
          null
        )
      );
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus("DELIVERLOAD", con, null, null, null)
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null
        )
      );
    } else if (selectedRoute === "PULL_DROP_DELIVER_RETURN") {
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DROPCONTAINER"],
          this.props.selectedLoads.driver,
          "PULLCONTAINER",
          loadAssignedDate
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["HOOKCONTAINER"],
          null,
          "PULLCONTAINER",
          null
        )
      );
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus("DELIVERLOAD", con, null, null, null)
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null
        )
      );
    } else if (selectedRoute === "PULL_DELIVER_DROP_RETURN") {
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus(
            "DELIVERLOAD",
            con,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DELIVERLOAD"],
          this.props.selectedLoads.driver,
          "DELIVERLOAD",
          loadAssignedDate
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null
        )
      );
    } else if (selectedRoute === "PICK_RUN_GRAY_POOL") {
      isGrayPool = true;
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate,
          )
        );
      });
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus(
            "DELIVERLOAD",
            con,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate,
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DELIVERLOAD"],
          this.props.selectedLoads.driver,
          "DELIVERLOAD",
          loadAssignedDate,
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          true
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          true
        )
      );
    } else if (selectedRoute === "PREPULL_GRAY_POOL") {
      isGrayPool = true;
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate,
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DROPCONTAINER"],
          this.props.selectedLoads.driver,
          "PULLCONTAINER",
          loadAssignedDate,
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["HOOKCONTAINER"],
          null,
          "PULLCONTAINER",
          null,
        )
      );
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus("DELIVERLOAD", con, null, null, null)
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "DROPCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "HOOKCONTAINER",
          customer["DELIVERLOAD"],
          null,
          "DELIVERLOAD",
          null,
          true
        )
      );
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          null,
          null,
          null,
          true
        )
      );
    } else if (selectedRoute === "PULL_RETURN") {
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          this.props.selectedLoads.driver,
          null,
          loadAssignedDate
        )
      );
    } else {
      this.props.selectedLoads.shipper.map((sh) => {
        driverOrder.push(
          this.createLoadStatus(
            "PULLCONTAINER",
            sh,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      this.props.selectedLoads.consignee.map((con) => {
        driverOrder.push(
          this.createLoadStatus(
            "DELIVERLOAD",
            con,
            this.props.selectedLoads.driver,
            null,
            loadAssignedDate
          )
        );
      });
      driverOrder.push(
        this.createLoadStatus(
          "RETURNCONTAINER",
          customer["RETURNCONTAINER"],
          this.props.selectedLoads.driver,
          null,
          loadAssignedDate
        )
      );
    }
    this.setState({ driverOrder, isGrayPool }, ()=>{
      this.handleOrderStatusChange("update","loadRoute");
    });
  }

  handleDragStart = (e) => {
    e.dataTransfer.setData("loadStatusType", e.target.id);
    e.stopPropagation();
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) { 
      this.handleDragOver(event)
    }
  }
  handleDragEnter = (index) => {
    if (this.state.droppedIndex !== index)
      this.setState({ droppedIndex: index });
  };

  handleOnDrop = async (e) => {
    const loadStatusType = e.dataTransfer.getData("loadStatusType");
    let currentIndex = this.state.droppedIndex;
    let removeDriverIndex = null;

    // if (loadStatusType !== 'COMPLETED' && !this.state[`${loadStatusType}CUSTOMER`]) {
    //     toastr.show('You must select a customer first.', 'error');
    //     return;
    // }

    let driverOrder = _.cloneDeep(this.state.driverOrder);

    if (currentIndex === 100) {
      currentIndex = 0;
      if (
        (driverOrder[currentIndex] &&
          (driverOrder[currentIndex].isVoidOut ||
            driverOrder[currentIndex].arrived)) ||
        (loadStatusType === "CHASSISPICK" &&
          driverOrder[currentIndex].type !== "PULLCONTAINER") ||
        ["CHASSISPICK", "PULLCONTAINER"].indexOf(loadStatusType) === -1
      ) {
        return;
      }
      const loadAssignedDate =
        driverOrder[currentIndex] && driverOrder[currentIndex].loadAssignedDate;
      driverOrder.splice(
        currentIndex,
        0,
        this.createLoadStatus(
          loadStatusType,
          this.state[`${loadStatusType}CUSTOMER`],
          driverOrder[0].driver,
          null,
          loadAssignedDate
        )
      );
      this.setState({ driverOrder }, () => {
        this.handleOrderStatusChange("update");
      });
    } else {
      if (
        (driverOrder[currentIndex + 1]?.isVoidOut || driverOrder[currentIndex + 1]?.arrived) ||
        loadStatusType === "CHASSISPICK" ||
        (loadStatusType === "CHASSISTERMINATION" && driverOrder[currentIndex].type !== "RETURNCONTAINER") ||
        (loadStatusType === "COMPLETED" && currentIndex !== driverOrder.length - 1)
      ) {
        return;
      }

      if (loadStatusType === "COMPLETED") {
        this.setState({ allowDriverCompletion: true }, () => {
          this.handleOrderStatusChange("update");
        });
        return;
      }

      const loadAssignedDate = driverOrder[currentIndex + 1]?.loadAssignedDate 
        || driverOrder[currentIndex]?.loadAssignedDate;
      
      if (tmsDrops.includes(driverOrder[currentIndex].type)) {
        if (driverOrder.length === currentIndex + 1) {
          driverOrder.splice(
            currentIndex + 1,
            0,
            this.createLoadStatus(
              loadEventRel[driverOrder[currentIndex].type],
              driverOrder[currentIndex].customerId,
              driverOrder[currentIndex].driver,
              driverOrder[currentIndex].prevType,
              loadAssignedDate,
              driverOrder[currentIndex].isGrayPool,
            )
          );
          removeDriverIndex = 0;
        }
        currentIndex += 1;
      }

      if (tmsDrops.includes(loadStatusType)) {
        let prevType = null;
        for (let i = currentIndex; i >= 0; i--) {
          if (
            !tmsDrops.includes(driverOrder[i].type) &&
            !tmsHooks.includes(driverOrder[i].type) &&
            !driverOrder[i].isVoidOut
            ) {
            prevType = driverOrder[i].type;
            break;
          }
        }
        if (currentIndex + 1 !== driverOrder.length) {
          driverOrder.splice(
            currentIndex + 1,
            0,
            this.createLoadStatus(
              loadEventRel[loadStatusType],
              this.state[`${loadStatusType}CUSTOMER`],
              driverOrder[currentIndex].driver,
              prevType,
              loadAssignedDate,
              driverOrder[currentIndex].isGrayPool,
            )
          );
        }
        driverOrder.splice(
          currentIndex + 1,
          0,
          this.createLoadStatus(
            loadStatusType,
            this.state[`${loadStatusType}CUSTOMER`],
            driverOrder[currentIndex].driver,
            prevType,
            loadAssignedDate,
            driverOrder[currentIndex].isGrayPool,
          )
        );
        if (removeDriverIndex === null) removeDriverIndex = 2;
      } else {
        driverOrder.splice(
          currentIndex + 1,
          0,
          this.createLoadStatus(
            loadStatusType,
            this.state[`${loadStatusType}CUSTOMER`],
            driverOrder[currentIndex].driver,
            null,
            loadAssignedDate,
            driverOrder[currentIndex].isGrayPool,
          )
        );
      }

      this.setState({ driverOrder }, () => {
        if (removeDriverIndex !== null) {
          this.assignDriver(null, currentIndex + removeDriverIndex);
        } else {
          this.handleOrderStatusChange("update");
        }
      });
    }       
  };

  assignDriver = (value, index) => {
    if(value && value.allInfos && value.allInfos.driver && value.allInfos.driver.accountHold){
      toastr.show('This driver is not able to be selected because they are marked in an ON HOLD status in Driver Profiles', 'error');
      return;
    }
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    let startIndexOfLag;

    if (index < driverOrder.length) {
      for (let i = index; i >= 0; i--) {
        startIndexOfLag = i;
        if (tmsHooks.includes(driverOrder[i].type)) break;
      }

      if (value) {
        for (let i = startIndexOfLag; i < driverOrder.length; i++) {
          if (!driverOrder[i].isVoidOut) {
            driverOrder[i].driver = value && value.allInfos;
            driverOrder[i].loadAssignedDate = moment()
              .tz(getStorage("timeZone"))
              .toISOString();
            if (tmsDrops.includes(driverOrder[i].type)) break;
          }
        }
      } else {
        for (let i = startIndexOfLag; i < driverOrder.length; i++) {
          if (!driverOrder[i].isVoidOut) {
            driverOrder[i].driver = null;
            delete driverOrder[i].arrived;
            delete driverOrder[i].departed;
            delete driverOrder[i].loadAssignedDate;
          }
        }
      }

      this.setState({ driverOrder }, () => this.handleOrderStatusChange("update",`select-driver-${index}`) );
    }
  };

  assignDriverOnRemoval = (value, loadAssignedDate, index) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    for (let i = index; i < driverOrder.length; i++) {
      if (!driverOrder[i].isVoidOut) {
        driverOrder[i].driver = value;
        driverOrder[i].loadAssignedDate = loadAssignedDate;
        if (tmsDrops.includes(driverOrder[i].type)) break;
      }
    }
    this.setState({ driverOrder }, () => this.handleOrderStatusChange("update"));
  };

  removeStatus = (id) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    let driver, loadAssignedDate;
    
    // Removing Drop Container
    if (driverOrder[id].type === loadEvents.DROPCONTAINER) {
      if (driverOrder[id + 1]?.type === loadEvents.HOOKCONTAINER) {
        driverOrder.splice(id + 1, 1);
      }
      driverOrder.splice(id, 1);
      driver = driverOrder[id - 1].driver;
      loadAssignedDate = driverOrder[id - 1].loadAssignedDate;
    }
    // Removing Hook Container
    else if (driverOrder[id].type === loadEvents.HOOKCONTAINER) {
      if (id + 1 === driverOrder.length || id + 2 === driverOrder.length) {
        driverOrder.splice(id, 2);
      } else {
        driverOrder.splice(id - 1, 2);
        driver = driverOrder[id - 2].driver;
        loadAssignedDate = driverOrder[id - 2].loadAssignedDate;
      }
    }
    // Removing Lift Off
    else if (driverOrder[id].type === loadEvents.LIFTOFF) {
      if (driverOrder[id + 1]?.type === loadEvents.LIFTON) {
        driverOrder.splice(id + 1, 1);
      } else if (driverOrder[id + 1]?.type === loadEvents.CHASSISPICK && driverOrder[id + 2]?.type === loadEvents.LIFTON) {
        driverOrder.splice(id + 1, 2);
      }
      driverOrder.splice(id, 1);
      driver = driverOrder[id - 1].driver;
      loadAssignedDate = driverOrder[id - 1].loadAssignedDate;
    }
    // Removing Lift On
    else if (driverOrder[id].type === loadEvents.LIFTON) {
      if (id + 1 === driverOrder.length || id + 2 === driverOrder.length) {
        driverOrder.splice(id, 2);
        if (driverOrder[id - 1]?.type === loadEvents.CHASSISPICK) {
          driverOrder.splice(id - 1, 1);
        }
      } else {
        if (driverOrder[id - 1]?.type === loadEvents.CHASSISPICK) {
          driverOrder.splice(id - 1, 1);
        }
        driverOrder.splice(id - 1, 2);
        driver = driverOrder[id - 2].driver;
        loadAssignedDate = driverOrder[id - 2].loadAssignedDate;
      }
    } else {
      driverOrder.splice(id, 1);
    }
    this.setState({ driverOrder }, () => {
      if (driver) {
        this.assignDriverOnRemoval(driver, loadAssignedDate, id);
      } else {
        this.handleOrderStatusChange("update")
      }
    });
  };

  removeExtraLeg = (index) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    driverOrder.splice(index);
    
    this.setState({ driverOrder }, () => {
      const formDataOrder = new FormData();
      formDataOrder.append(
        "reference_number",
        this.props.selectedLoads && this.props.selectedLoads.reference_number
      );
      formDataOrder.append("driverOrder", JSON.stringify(driverOrder));
      formDataOrder.append("preSetOrderStatus", this.state.selectedRoute);
      formDataOrder.append(
        "allowDriverCompletion",
        this.state.allowDriverCompletion ? true : false
      );

      formDataOrder.append(
        "isPrePullCharge",
        this.state.isPrePullCharge ? true : false
      );
      formDataOrder.append(
        "isNextContainerNeedToPickup",
        this.state.isNextContainerNeedToPickup ? true : false
      );
      formDataOrder.append("isCompleteLoad",true)
      setLoadStatusOrder(formDataOrder)
      .then(()=>{
        this.setState(this.setState({openModalRefNumber:this.props.selectedLoads.streetTurn, showLoadModel:true}))
      })
    });
  }
  validateOrderStatus = (driverOrder) => {
    // let isValid = driverOrder.filter((status) => !status.customerId).length > 0? false: true;
    // if(!isValid){
    //     toastr.show("Please assign a driver and/or a customer.", 'error');
    //     return false;
    // }
    let inValidStatus = driverOrder.find(
      (status) =>
        status.driver &&
        !status.customerId &&
        ["CHASSISPICK", "RETURNCONTAINER", "CHASSISTERMINATION"].indexOf(
          status.type
        ) === -1
    );
    if (inValidStatus) {
      toastr.show("A valid customer is required here." + inValidStatus.type, "error");
      return false;
    }

    inValidStatus = driverOrder.find(
      (status) => status.driver && !status.loadAssignedDate
    );
    if (inValidStatus) {
      toastr.show(
        "Load Assigned Date is required for " + inValidStatus.type,
        "error"
      );
      return false;
    }

    if (
      this.props.selectedLoads &&
      this.props.selectedLoads.preSetOrderStatus === this.state.selectedRoute
    ) {
      if (driverOrder[0] && driverOrder[0].driver) {
        for (let i = 1; i < driverOrder.length; i++) {
          if(driverOrder[0].driver && !driverOrder[0].isVoidOut){
            if (!driverOrder[i].driver) inValidStatus = true;
            if (tmsDrops.includes(driverOrder[i].type)) break;
          }
        }
        if (inValidStatus) {
          toastr.show("A driver is required.", "error");
          return false;
        }
      }
    }

    const fDriverIndx = driverOrder.findIndex((status) => !status.driver && !status.isVoidOut);
    if (fDriverIndx > -1) {
      driverOrder.forEach((status, i) => {
        if (i > fDriverIndx) {
          if (!status.isVoidOut && status.driver) {
            inValidStatus = true;
          }
        }
      });
      if (inValidStatus) {
        toastr.show("Driver is required", "error");
        return false;
      }
    }

    if (this.props.selectedLoads?.type_of_load != "ROAD") {
      if ([loadEvents.DROPCONTAINER, loadEvents.LIFTOFF, loadEvents.RETURNCONTAINER, loadEvents.CHASSISTERMINATION].indexOf(
          driverOrder[driverOrder.length - 1].type
        ) === -1
      ) {
        toastr.show("Last status must be either dropped, return container or chassis termination", "error");
        return false;
      }
    }
    return true;
  };

  changeCompletionStatus = (type, index, date, statusId) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    driverOrder[index][type] = moment(date).toISOString();

    const changeCompletionDate = {
      statusId: statusId,
      type: type,
      date: date,
    };
    this.setState({ driverOrder, changeCompletionDate });
  };

  changeLoadAssignedDate = (index, date) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);

    const loadAssignedDate = date && moment(date).toISOString();
    let startIndexOfLag;

    for (let i = index; i >= 0; i--) {
      startIndexOfLag = i;
      if (tmsHooks.includes(driverOrder[i].type)) break;
    }

    for (let i = startIndexOfLag; i < driverOrder.length; i++) {
      if (loadAssignedDate) {
        driverOrder[i].loadAssignedDate = loadAssignedDate;
      } else {
        delete driverOrder[i].loadAssignedDate;
      }
      if (tmsDrops.includes(driverOrder[i].type)) break;
    }

    this.setState({ driverOrder });
  };

  changeLoadCompletedDate = () => {
    const noChange = _.isEqual(this.props.originalLoad?.loadCompletedAt,this.state?.loadCompletedAt)
    if(noChange) return
    if(this.state.loadCompletedAt){
      const formData = new FormData();
      formData.append(
        "reference_number",
        this.props.selectedLoads &&
          this.props.selectedLoads.reference_number
      );
      formData.append(
        "loadCompletedAt",
        this.state.loadCompletedAt
      );

      updateLoadCompletedDate(formData)
        .then((result) => {
          toastr.show("You have updated load completed date successfully.", "success");
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toastr.show("The update has failed.  Please try again.", "error");
          console.log("Error ------", err);
        });
    }else{
      toastr.show("Enter Completed Date", "error");
      return;
    }
  }

  updateDriverOrderById = (type, driverOrderId) => {
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append(
      "reference_number",
      this.props.selectedLoads && this.props.selectedLoads.reference_number
    );
    formData.append("driverOrderId", driverOrderId._id);
    formData.append("arrivedOrDeparted", type);
    if (this.state.changeCompletionDate) {
      formData.append(
        "date",
        moment(this.state.changeCompletionDate.date).toISOString()
      );
      formData.append("strictChange", true);
    }
    updateDriverOrderById(formData)
      .then((result) => {
        toastr.show("You have updated the status!", "success");
        if(type === "departed" && (driverOrderId.type === 'PULLCONTAINER' || driverOrderId.type === 'HOOKCONTAINER') && driverOrderId.isBobtail){
          const newFormData = new FormData();
          newFormData.append("description", "Bobtail");
          newFormData.append("amount", 0);
          newFormData.append("approved", false);
          newFormData.append("driverId", driverOrderId.driver && driverOrderId.driver._id);
          newFormData.append("reference_number", this.props.selectedLoads && this.props.selectedLoads.reference_number);
          newFormData.append("loadType", driverOrderId.type);
          if(this.props?.selectedLoads?.terminal){
            newFormData.append("terminal", this.props.selectedLoads.terminal._id);
          }
          manualDriverPay(newFormData)
          this.manualCustomerRate()
        }
        this.setState({ changeCompletionDate: null, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });
  };

  manualCustomerRate = () => {
    let pricing = (this.props.selectedLoads && this.props.selectedLoads.pricing) ? this.props.selectedLoads.pricing : [];
    let existingBobtailCharge = pricing.find((price) => price.name === 'bobtail_charge');
    if(!existingBobtailCharge){
      let bobtailPrice = {
        "amount": 0,
        "chargePerDay": 0,
        "chargePerMinute": 0,
        "finalAmount": "0.00",
        "unit": "0.00",
        "freeDays": 0,
        "perType": "fixed",
        "name": "bobtail_charge",
        "chargeType": "MANUALLY_CHANGED",
        "description": "Bobtail"
      }
      pricing.push({ ...bobtailPrice })
      var pricingData = {
        pricing: pricing,
        reference_number : this.props.selectedLoads.reference_number,
        isSaveCustomerRates: false,
      };
      UpdatePricing("tms/updatePricingLoad", pricingData)
    }
  }
  removeStatusDriverOrderById = (type, driverOrderId) => {

    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append(
      "reference_number",
      this.props.selectedLoads &&
        this.props.selectedLoads.reference_number
    );
    formData.append("driverOrderId", driverOrderId);
    formData.append("arrivedOrDeparted", type);

    removeStatusDriverOrderById(formData)
      .then((result) => {
        toastr.show("You have updated the status!", "success");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });


    // confirm(
    //   "Alert",
    //   `Do you really want to remove this status date?`,
    //   (confirm) => {
    //     if (confirm) {
    //    ß
    //     }
    //   }
    // );
  };

  pullVoid = (status, index) => {
    if (!status.driver) {
      toastr.show("Please assign a driver.", "error");
      return;
    }

    let driverOrder = _.cloneDeep(this.state.driverOrder);
    let isRemoveDriver = false
    if(status.type === 'PULLCONTAINER' || status.type === 'HOOKCONTAINER'){
      isRemoveDriver = true
    }
    const newStatus = { ...driverOrder[index] };
    delete newStatus.arrived;
    delete newStatus.departed;

    driverOrder.splice(index + 1, 0, newStatus);
    driverOrder[index].isVoidOut = true;
    this.setState({ driverOrder }, () => {
      this.updateStatus(true,isRemoveDriver,true,false,status);
    });
  };

  removePullVoid = (status, index) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    driverOrder[index].isVoidOut = false;
    let driverId = driverOrder[index].driver
    if (driverOrder[index + 1] && driverOrder[index + 1].type === status.type) {
      driverOrder.splice(index + 1, 1);
    }
    for (let i = index; i < driverOrder.length; i++) {
      if (!driverOrder[i].isVoidOut) {
        driverOrder[i].driver = driverId;
        if (tmsDrops.includes(driverOrder[i].type)) break;
      }
    }

    this.setState({ driverOrder }, () => {
      this.updateStatus(true);
    });
  };

  updateStatus = (keepModalOpen,isRemoveDriver,isVoidOut,isBobtail,currentLeg,field) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    if (!this.validateOrderStatus(driverOrder)) {
      return;
    }
    let customerAddress = currentLeg && `${ currentLeg.address ? currentLeg.address.address ? currentLeg.address.address : "" : ""}`.trim()
    this.props.changeSavingLoadStatus &&
      this.props.changeSavingLoadStatus(true);
    this.setState({ isLoading: true });
    driverOrder.forEach((status) => {
      if (status.customerId) {
        status.address = status.customerId.address;
        status.city = status.customerId.city;
        status.state = status.customerId.state;
        status.country = status.customerId.country;
        status.zip_code = status.customerId.zip_code;
        status.company_name =
          status.customerId.company_name &&
          status.customerId.company_name.toUpperCase();
        status.customerId = status.customerId._id;
      } else {
        delete status.address;
        delete status.city;
        delete status.state;
        delete status.country;
        delete status.zip_code;
        delete status.company_name;
        delete status.customerId;
      }

      if (status.driver) {
        status.driverName = `${status.driver.name} ${status.driver.lastName || ""}`
        status.driver = status.driver._id;
      } else {
        delete status.driver;
        delete status.driverName;
      }
    });

    const formData = new FormData();
    formData.append(
      "reference_number",
      this.props.selectedLoads && this.props.selectedLoads.reference_number
    );
    formData.append("driverOrder", JSON.stringify(driverOrder));
    formData.append("preSetOrderStatus", this.state.selectedRoute);
    formData.append(
      "allowDriverCompletion",
      this.state.allowDriverCompletion ? true : false
    );

    formData.append(
      "isPrePullCharge",
      this.state.isPrePullCharge ? true : false
    );
    formData.append(
      "isNextContainerNeedToPickup",
      this.state.isNextContainerNeedToPickup ? true : false
    );
    formData.append("isGrayPool",this.state.isGrayPool ? true : false);
    if(this.state.isGrayPool){
      if(this.state.grayContainerNo) formData.append("grayContainerNo",this.state.grayContainerNo)
      if(this.state.grayChassisNo) formData.append("grayChassisNo",this.state.grayChassisNo)
      if(this.state.grayChassisType) formData.append("grayChassisType",this.state.grayChassisType)
      if(this.state.grayChassisSize) formData.append("grayChassisSize",this.state.grayChassisSize)
      if(this.state.grayChassisOwner) formData.append("grayChassisOwner",this.state.grayChassisOwner)
      if(this.state.grayChassisId) formData.append("grayChassisId",this.state.grayChassisId)
    }
    setLoadStatusOrder(formData)
      .then((result) => {
        field && this.transitionEffect(field,true)

        if(isVoidOut){
          const newFormData = new FormData();
            newFormData.append("description", "Void Out");
            newFormData.append("amount", 0);
            newFormData.append("approved", false);
            newFormData.append("driverId", currentLeg.driver && currentLeg.driver._id);
            newFormData.append("reference_number", this.props.selectedLoads && this.props.selectedLoads.reference_number);
            if(customerAddress){
              newFormData.append("to", customerAddress);
            }
            newFormData.append("loadType", currentLeg.type);
            if(this.props?.selectedLoads?.terminal){
              newFormData.append("terminal", this.props.selectedLoads.terminal._id);
            }
            manualDriverPay(newFormData)
        }
        if(isRemoveDriver) {
          const formData1 = new FormData();
          formData1.append(
            "reference_number",
            this.props.selectedLoads &&
              this.props.selectedLoads.reference_number
          );
          this.props.actions.hardRemoveDriver(formData1)
        }
        if(isBobtail){
            const newFormData = new FormData();
            newFormData.append("description", "Bobtail");
            newFormData.append("amount", 0);
            newFormData.append("approved", false);
            newFormData.append("driverId",  currentLeg.driver && currentLeg.driver._id);
            newFormData.append("reference_number", this.props.selectedLoads && this.props.selectedLoads.reference_number);
            newFormData.append("loadType", currentLeg.type);
            if(this.props?.selectedLoads?.terminal){
              newFormData.append("terminal", this.props.selectedLoads.terminal._id);
            }
            manualDriverPay(newFormData)
            this.manualCustomerRate()
        }
        !!!field && toastr.show("Updated Successfully", "success");
        this.props.changeSavingLoadStatus &&
          this.props.changeSavingLoadStatus(false);
        this.setState({ isLoading: false });
        if (this.props.closeStatusModal && !keepModalOpen) {
          // this.props.closeStatusModal();
        }
      })
      .catch((err) => {
        field && this.transitionEffect(field,false)
        this.props.changeSavingLoadStatus &&
        this.props.changeSavingLoadStatus(false);
        this.setState({ isLoading: false });
        !!!field && toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });
  };

  completeLoad = () => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    if (!this.validateOrderStatus(driverOrder)) {
      return;
    }

    const inValid = driverOrder.find((status) => !status.driver);
    if (inValid) {
      toastr.show("A driver is required.", "error");
    } else {
      this.setState({ isLoading: true });
      const driverOrderId = driverOrder[driverOrder.length - 1]._id;
      const formData = new FormData();
      formData.append(
        "reference_number",
        this.props.selectedLoads &&
          this.props.selectedLoads.reference_number
      );
      formData.append("driverOrderId", driverOrderId);
      formData.append("arrivedOrDeparted", "departed");
      formData.append("completeLoad", true);

      updateDriverOrderById(formData)
        .then((result) => {
          toastr.show("Completed!", "success");
          this.setState({ isLoading: false });
          if (this.props.closeStatusModal) {
            this.props.closeStatusModal();
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toastr.show("The update has failed.  Please try again.", "error");
          console.log("Error ------", err);
        });


      // confirm(
      //   "Alert",
      //   `Do you really want to complete this load?`,
      //   (confirm) => {
      //     if (confirm) {
            
      //     }
      //   }
      // );
    }
  };

  uncompleteLoad = () => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append(
      "reference_number",
      this.props.selectedLoads &&
        this.props.selectedLoads.reference_number
    );

    uncompleteLoad(formData)
      .then((result) => {
        toastr.show("You have uncompleted this load.", "success");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });


    // confirm(
    //   "Alert",
    //   `Do you really want to uncomplete this load?`,
    //   (confirm) => {
    //     if (confirm) {
          
    //     }
    //   }
    // );
  };
  removeStreetTurn = (reference_number,streetTurn)=>{
    const formData = new FormData();
    formData.append("reference_number", reference_number);
    formData.append("streetTurn",streetTurn)
    confirm(
      "Remove Street Turn?",
      `Are You sure to Remove Street Turn?`,
      (confirm) => {
        if (confirm) {
          this.props.actions.removeStreetTurn(formData).then(() => {
            toastr.show("Removed!", "success");
          });
        }
      }
    );
  }

  handleOrderStatusChange = (label, field) => {
    console.log(field)
    const disabled =
      _.isEqual(this.state.driverOrder, this.props.selectedLoads.driverOrder) && 
      this.props.selectedLoads.isPrePullCharge === this.state.isPrePullCharge &&
      this.props.selectedLoads.isNextContainerNeedToPickup ===
        this.state.isNextContainerNeedToPickup &&
      this.props.selectedLoads.allowDriverCompletion ===
        this.state.allowDriverCompletion &&
      this.props.selectedLoads.grayContainerNo ===
          this.state.grayContainerNo &&
      this.props.selectedLoads.grayChassisNo ===
          this.state.grayChassisNo;
    const isCompleted =
      [
        "COMPLETED",
        "BILLING",
        "APPROVED",
        "UNAPPROVED",
        "REBILLING",
        "PARTIALLY_PAID",
        "FULL_PAID",
      ].indexOf(this.props.selectedLoads.status) > -1;
    
    if (  
      this.state.grayContainerNo &&
      !/^[A-Za-z]{4}\d{6,8}$/.test(this.state.grayContainerNo)
    ) {
      toastr.show("Please Enter Correct Gray Container No", "warning");
      this.setState({ grayContainerNo: "" });
      this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
      return;
    }
    
    if (!disabled && !isCompleted) {
      if (label === "update") {
        this.updateStatus(false, false, false, false, false, field);
      } 
      else if (label === "discard") {
        this.getLoadDriverOrder();
      }
    }
    else if (isCompleted) {
      toastr.show("The load is now completed!", "error");
    }
  };

  setCustomerToStatus = (index, value, status) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    driverOrder[index].customerId = value && value.allInfos;

    if (status.type === loadEvents.DROPCONTAINER && driverOrder[index + 1]?.type === loadEvents.HOOKCONTAINER) {
      driverOrder[index + 1].customerId = value && value.allInfos;
    } else if (status.type === loadEvents.HOOKCONTAINER && driverOrder[index - 1].type === loadEvents.DROPCONTAINER) {
      driverOrder[index - 1].customerId = value && value.allInfos;
    }

    if (status.type === loadEvents.LIFTOFF && driverOrder[index + 1]?.type === loadEvents.LIFTON) {
      driverOrder[index + 1].customerId = value && value.allInfos;
    } else if (status.type === loadEvents.LIFTOFF && driverOrder[index + 2]?.type === loadEvents.LIFTON) {
      driverOrder[index + 2].customerId = value && value.allInfos;
    } else if (status.type === loadEvents.LIFTON && driverOrder[index - 1].type === loadEvents.LIFTOFF) {
      driverOrder[index - 1].customerId = value && value.allInfos;
    } else if (status.type === loadEvents.LIFTON && driverOrder[index - 2].type === loadEvents.LIFTOFF) {
      driverOrder[index - 2].customerId = value && value.allInfos;
    }

    this.setState({ driverOrder }, () => this.handleOrderStatusChange("update",`leg-${index}`));
  };

  submitNotes() {
    const tab = this.state.activeNoteTab;
    this.setState({
      loading: true,
      showNote: false,
    });
    let key = null;
    const data = {
      reference_number: this.props.selectedLoads.reference_number,
    };

    if (this.state.officeNote) {
      data.officeNote = this.state.officeNote;
      key = "officeNote";
      this.setState({
        loading: true,
      });
    }
    if (this.state.driverNote) {
      data.driverNote = this.state.driverNote;
      key = "driverNote";
      this.setState({
        loading: true,
      });
    }
    if (this.state.officeNote && this.state.driverNote) {
      this.setState({
        showNote: false
      })
    }
    if (tab && tab !== "" && data.reference_number && key) {
      saveLoadNotes(data)
        .then(() => {
          this.setState({
            loading: false,
          });
          toastr.show("Notes have been added.", "success");
          if (key && this.props.changeList) {
            this.props.changeList(this.state[`${key}`]);
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          toastr.show(err.message, "error");
        });
    }
  }

  createLegsFromDriverOrder = (driverOrder) => {
    const driverLegs = [],
      legIndexes = [0];
    let current = 0,
      lastPos = 0;
    while (current < driverOrder.length) {
      if (tmsDrops.includes(driverOrder[current].type)) {
        driverLegs.push(driverOrder.slice(lastPos, current + 1));
        lastPos = current + 1;
        legIndexes.push(lastPos);
      }
      current++;
    }
    driverLegs.push(driverOrder.slice(lastPos, current + 1));
    return { driverLegs, legIndexes };
  };

  assignBobtail = (value, index,status) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    if (index < driverOrder.length) {
      for (let i = index; i >= 0; i--) {
        driverOrder[i].isBobtail = value; break;
      }
      this.setState({ driverOrder },()=>{
        let isBobtail = false;
        if(status.departed && (status.type === 'PULLCONTAINER' || status.type === 'HOOKCONTAINER') && value){
          isBobtail = true;
        }
        if(typeof value === 'boolean' && !value && status.driver && status.driver._id) {
          let params = {
            reference_number: this.props.selectedLoads && this.props.selectedLoads.reference_number,
            loadType: status.type,
            userId: status.driver._id
          }
          removeBobtailDriverPay(params)
        }
        this.updateStatus(true,false,false,isBobtail,status);
      });
    }
  };

  preSetOrderStatusUpdate = (value) => {
    this.setState({isGrayPool: value});
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    if(!value){
      for (let i = 0; i < driverOrder.length; i++) {
        driverOrder[i].isGrayPool = false;
      }
    }else{
      let hookIndex = -1
      for (let i = 0; i < driverOrder.length; i++) {
        if (driverOrder[i].type != 'HOOKCONTAINER')
            continue;
        hookIndex = i;
      }
      for(let i = hookIndex; i< driverOrder.length; i++){
        driverOrder[i].isGrayPool = true;
      }
    }
    this.setState({driverOrder}, ()=>this.handleOrderStatusChange("update"))
  }

  getChassis = (searchTerm = "") => {
    return new Promise((resolve, reject) => {
      this.props
        .getTMSChassis({ limit: 10, searchTerm })
        .then((result) => {
          let data =
            result &&
            result.map((d) => ({
              value: d._id,
              label: d.chassisNo,
              allInfo: d,
            }));
          resolve(data);
        })
        .catch((err) => {
          console.log("-*-* GET Chassis error -*-*", err);
          reject(err);
        });
    });
  };

  onRemovePress(e, input, showToast = true) {
    const params = {
      reference_number: this.props.selectedLoads.reference_number,
      key: input.name,
    };
    if (!this.props.disabled) {
      removeValueLoad(params).then((result) => {
        showToast && this.transitionEffect(input.name,true)
      });
    }
  }
  
  grayChassisUpdate(value) {
    if (value) {
      this.setState({
        grayChassisNo: value.label,
        grayChassisType: value.allInfos ? value.allInfos.chassisType ? value.allInfos.chassisType._id : null : null, 
        grayChassisSize: value.allInfos ? value.allInfos.chassisSize ? value.allInfos.chassisSize._id : null : null, 
        grayChassisOwner: value.allInfos ? value.allInfos.chassisOwner ? value.allInfos.chassisOwner._id : null : null, 
        grayChassisId: value.value ? value.value : null
      },()=>{
        this.handleOrderStatusChange("update","grayChassisNo")  
      });
    }
  }

  getDrivers(info, dIndex) {
    let drivers = [];
    if (this.props.driverList) {
      let ddrivers = this.props.driverList;
      const deliverIndex = info.driverOrder.findIndex((d) => d.type === 'DELIVERLOAD');
      if(this.state.driverPreferences && (info.overWeight || info.hazmat || info.liquor) && dIndex < deliverIndex) {
        if (info.overWeight) {
          ddrivers.forEach((d) => {
            if(!d.driver.overweight && !_.intersection(d.driver.owerWeightStates, info.consignee.map((d) => d.state)).length > 0) {
              d.disableValue = true
            }
          });
        }
        if (info.hazmat) {
          ddrivers.forEach((d) => {
            if(!d.driver.hazmat) {
              d.disableValue = true
            }
          });
        }
        if (info.liquor) {
          ddrivers.forEach((d) => {
            if(d.driver.liquor !== "true") {
              d.disableValue = true
            }
          });
        }
        drivers = ddrivers;
      } else {
        ddrivers.forEach((d) => d.disableValue = false)
        drivers = ddrivers;
      }
    } else {
      drivers = [];
    }
    return drivers.map((d) => {
      return {
        value: d._id,
        label: `${d.name} ${d.lastName || ""}`.trim(),
        allInfos: d,
        isDisabled: d.disableValue
      };
    });
  }

  transitionEffect = (field, color) => {
    const element = document.querySelector(`.${field}`);
    let dateOrInpt = ["input-wrapper"];
    const isThere = dateOrInpt.some((e)=>element?.className?.includes(e));
    const transitionElement = isThere ? document.querySelector(`.${field} > div > input`) : document.querySelector(`.${field} > div`);
    if(color){
      transitionElement?.classList.remove("background-failure");
      transitionElement?.classList.add("background-success");
      setTimeout(()=>{
        transitionElement?.classList.remove("background-success","text-danger");
      },400)
    }else {
      transitionElement?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          transitionElement?.classList.add("background-failure","text-danger");
          transitionElement?.classList.remove("background-error","text-danger");
        },400)
    }
  }

  updateEmptyOrigin = async (index, emptyOriginVal, status) => {
    this.setState({isLoader : true})
    const emptyOriginData = emptyOriginVal?.emptyOrigin;
    const customerList = await getAllCustomers(emptyOriginData?.emptyOriginId).then((data) => {
      return data;
    });

    if(status.type === "RETURNCONTAINER") {
      const newEmptyOrigin = customerList.find((customer) => customer?.allInfos?._id === emptyOriginData.emptyOriginId);
      
      let driverOrder = _.cloneDeep(this.state.driverOrder);
      driverOrder[index].customerId = newEmptyOrigin && newEmptyOrigin.allInfos;
      
      this.setState({ driverOrder });
      this.setState({isLoader : false})
    }
  }

  renderReturn() {
    const disabled =
      _.isEqual(this.state.driverOrder, this.props.selectedLoads.driverOrder) &&
      this.props.selectedLoads.isPrePullCharge === this.state.isPrePullCharge &&
      this.props.selectedLoads.isNextContainerNeedToPickup ===
        this.state.isNextContainerNeedToPickup &&
      this.props.selectedLoads.allowDriverCompletion ===
        this.state.allowDriverCompletion &&
      this.props.selectedLoads.grayChassisNo ===
      this.state.grayChassisNo && 
      this.props.selectedLoads.grayContainerNo ===
      this.state.grayContainerNo;

    const isCompleted =
      [
        "COMPLETED",
        "BILLING",
        "APPROVED",
        "UNAPPROVED",
        "REBILLING",
        "PARTIALLY_PAID",
        "FULL_PAID",
      ].indexOf(this.props.selectedLoads.status) > -1;

    const loadCompletedAt = this.props.selectedLoads.loadCompletedAt;
    const completedStatus = this.state.driverOrder.find(
      (status) => status.arrived && !status.isVoidOut
    );
    const isHook = this.state.driverOrder.find((order) => order.type === 'HOOKCONTAINER')

    const selectedLoad = this.props.selectedLoads;
    const { driverLegs, legIndexes } = this.createLegsFromDriverOrder(
      this.state.driverOrder
    );

    return (
      <React.Fragment>
        <div className="tab">
          {this.props.dispatcherModal && (
            <div className="d-flex align-items-center m-2 mb-3">
              <h5 className="font-20 mb-0">
                Load #:{" "}
                {this.props.selectedLoads &&
                  this.props.selectedLoads.reference_number}
              </h5>
              <div className="d-flex ml-auto">
                <button
                  className="btn btn-white shadow-none mr-10"
                  onClick={() => this.props.closeStatusModal()}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          <div className="form-row-md mt-10">
            {this.props.dispatcherModal && (
              <DriverOrderSidebar
                {...this.props}
              />
            )}
            <div className="col fcpopup__sidebar">
              <div className="card p-10 mb-10">
                {!completedStatus && (
                  <React.Fragment>
                    <div className="form-group mb-0">
                      <label>Load Template</label>
                      <Select
                        name="loadRoute"
                        options={PRESET_ORDER_STATUS}
                        value={PRESET_ORDER_STATUS.find(
                          (val) => val.value === this.state.selectedRoute
                        )}
                        onChange={(value) =>
                          this.onSelectedRouteChange(value.value)
                        }
                        className="w-100 loadRoute"
                        styles={smallSelectStyle}
                      />
                    </div>
                    <div className="hr-light"></div>
                  </React.Fragment>
                )}
                <div className="form-group">
                  <label>Drag & Drop to Add Legs</label>

                  {ROUTING_DRAG_LEGS.map((dLeg) => {
                    if (this.props.selectedLoads?.type_of_load === 'ROAD'
                      && ['CHASSISPICK', 'RETURNCONTAINER', 'CHASSISTERMINATION'].includes(dLeg.leg)
                    ) { return; }

                    return (
                      <button
                        className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                        id={dLeg.leg}
                        draggable
                        onDragStart={this.handleDragStart}
                        onDragOver={this.handleDragOver}
                        onDragEnd={() => this.setState({ droppedIndex: null })}
                      >
                        <IconDrag className="mr-10 text-gray-200" />
                        {ICONS[dLeg.leg]}
                        <span>{dLeg.legTitle}</span>
                      </button>
                    )
                  })}
                </div>
              </div>  
              <div className="card">
                <div className="pt-10 px-10">
                  {this.props.selectedLoads &&
                    this.props.selectedLoads.type_of_load === "IMPORT" && this.props.selectedLoads.driverOrderId && this.props.selectedLoads.containerOwner && this.props.selectedLoads.containerNo &&  (
                      <React.Fragment>
                        <label>Export Load</label>
                        <StreetTurn selectedLoads={this.props.selectedLoads} completeLoad={this.completeLoad} removeExtraLeg={this.removeExtraLeg}/>                       
                      </React.Fragment>

                    )}
                  {this.props.selectedLoads &&
                    this.props.selectedLoads.streetTurn && (
                      <div className="">
                        <h4 className="tre d-flex flex-wrap mt-2 mb-2">
                          Assigned Street Turn{" "}
                          <span className="badge mt-1 badge-info badge-rounded text-uppercase font-medium">
                            {this.props.selectedLoads.streetTurn}
                          </span>
                        </h4>
                        <button className="btn btn-danger btn-sm btn-block" onClick={()=>this.removeStreetTurn(this.props.selectedLoads.reference_number,this.props.selectedLoads.streetTurn)}>Remove Street Turn</button>
                      </div>
                    )}
                  {this.props.selectedLoads &&
                    this.props.selectedLoads.bobTail && (
                      <div className="">
                        <h4 className="tre">
                          BobTail
                          {this.props.selectedLoads.bobTail &&
                            this.props.selectedLoads.bobTail.driver && (
                              <span className="badge badge-info badge-rounded text-uppercase font-medium">{` ${this.props.selectedLoads.bobTail.driver.name} ${this.props.selectedLoads.bobTail.driver.lastName}`}</span>
                            )}
                        </h4>
                      </div>
                    )}
                </div>
                <div className="hr-light"></div>
                <div className="form-group px-10">
                  <label className="mb-15">More Options</label>
                  <div>
                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isPrePullCharge"
                        checked={this.state.isPrePullCharge}
                        value={1}
                        onChange={(e) => {
                          this.setState({ isPrePullCharge: e.target.checked }, ()=>this.handleOrderStatusChange("update"));                          
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isPrePullCharge"
                      >
                        Add Pre Pull Charge
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isNextContainerNeedToPickup"
                        name="isNextContainerNeedToPickup"
                        checked={this.state.isNextContainerNeedToPickup}
                        value={1}
                        onChange={(e) => {
                          this.setState({
                            isNextContainerNeedToPickup: e.target.checked,
                          }, ()=>this.handleOrderStatusChange("update"));                          
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isNextContainerNeedToPickup"
                      >
                        Driver Select Container
                      </label>
                    </div>
                    {isHook && 
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="isGrayPool"
                          name="isGrayPool"
                          checked={this.state.isGrayPool}
                          value={1}
                          onChange={(e) => {
                            this.preSetOrderStatusUpdate(e.target.checked);                            
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isGrayPool"
                        >
                          Gray Pool
                        </label>
                      </div>
                    }
                  </div>
                </div>
                <div className="p-10 moreinfo-card">
                  {!isCompleted && (
                    <button
                      className="btn btn-outline-error-100 btn-block"
                      disabled={!disabled || isCompleted}
                      onClick={() => this.completeLoad()}
                    >
                      Complete Load
                    </button>
                  )}
                  {["COMPLETED", "UNAPPROVED"].indexOf(
                    this.props.selectedLoads.status
                  ) > -1 && (
                    <React.Fragment>
                      <span className="text-dark font-weight-500 mr-3">
                        Load Completed Date
                      </span>
                      <div className="input-wrapper outline-btn-success mt-1 mb-10">
                        <div className="btn-outline-success-100">
                        <DateWrapper 
                          dateFormat="MM-DD-yyyy"
                          timeFormat={this.userTimeFormat}
                          isShowTime={true}
                          onChange={(date) =>
                            this.setState({ loadCompletedAt : moment(date).toISOString()})
                          }
                          displayTimeZone={getStorage("timeZone")}
                          defaultDate={ 
                            this.state.loadCompletedAt ? moment(this.state.loadCompletedAt) : ""
                          }
                          OnBlurUpdate={() => this.changeLoadCompletedDate()}
                          hideShowSelectDate={true}
                        />
                          <div
                            className="input-icon"
                            onClick={() => this.changeLoadCompletedDate()}
                          >
                            <IconCheck />
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-outline-error-100 btn-block"
                        onClick={() => this.uncompleteLoad()}
                      >
                        Uncomplete Load
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col routing_info--card">
              {this.state.isLoading && <LoaderBar />}

              {driverLegs.length > 0 &&
                driverLegs.map((leg, legIndex) => {
                  let legColor = legIndex % 2 == 0 ? "blue" : "pink";
                 
                  let firstStatus = leg.find((status) => !status.isVoidOut );
                  let currentLegIndex = leg.findIndex((status) => !status.isVoidOut );
                  if (!firstStatus) firstStatus = leg[0];
                  const dIndex = legIndexes[legIndex];
                  if(this.state.driverOrder[dIndex] && this.state.driverOrder[dIndex].isGrayPool) legColor = 'gray'
                  if (leg.length > 0) {
                    return (
                      <div className="card card-blue mb-10">                         
                          {this.state.driverOrder[dIndex].isGrayPool && 
                            <div className="bg-gray-700 d-flex align-items-center p-10 rounded-top-left rounded-top-right">
                              <span className="text-white">
                                <IconPool className="text-white" />
                              </span>
                              <h5 className="font-16 text-white ml-10 mb-0">
                                Gray Pool
                              </h5>
                            </div>
                          }
                          <div className="p-15">
                            <div className="row mb-0 align-items-center">
                              <span>
                                <div
                                  className={`avatar-circle card-${legColor}__avatar text-white mx-10`}
                                >
                                  <div className="avatar-title">{legIndex + 1}</div>
                                </div>
                              </span>
                              <div className="col-3 p-0 select-wrapper__input">
                                <Select
                                  options={this.getDrivers(selectedLoad, dIndex)
                                    .filter((dd) => !dd.isDeleted && !dd.isBlocked)
                                    .map((d) => d)}
                                  value={
                                    firstStatus.driver
                                      ? {
                                          label: `${firstStatus.driver.name} ${
                                            firstStatus.driver.lastName || ""
                                          }`.trim(),
                                          value: firstStatus.driver._id,
                                        }
                                      : null
                                  }
                                  onChange={(value) =>
                                    {
                                      this.assignDriver(value, dIndex);                                                                                                                                             
                                    }                                    
                                  }
                                  placeholder="Select Driver"
                                  className={`w-100 select-driver-${dIndex}`}
                                  isDisabled={
                                    firstStatus.isVoidOut ||
                                    firstStatus.arrived  ||
                                    isCompleted 
                                    // this.disabledDriver
                                  }
                                  isClearable
                                  styles={smallSelectStyle}
                                />
                              </div>
                            
                              <div className="p-0">
                                <div
                                  className="form-group mb-0 col date__picker-wrapper"
                                  style={{ maxWidth: 200 }}
                                >
                                  <div className={`input-wrapper loadAssignDate-${dIndex}`} >
                                    <div>
                                      <DateWrapper 
                                        className={`loadAssignDate-${dIndex} laDate`}
                                        dateFormat="MM-DD-yyyy"
                                        timeFormat={this.userTimeFormat}
                                        isShowTime={true}
                                        onChange={(date) =>{
                                          this.changeLoadAssignedDate(
                                            dIndex + 0,
                                            date
                                          );
                                        }}
                                        displayTimeZone={getStorage("timeZone")}
                                        disabled={leg[0] && leg[0].isVoidOut || isCompleted}
                                        defaultDate={leg[0] && leg[0].loadAssignedDate
                                          ? moment(
                                            leg[0] && leg[0].loadAssignedDate
                                            )
                                          : ""}
                                        OnBlurUpdate={(e)=>this.checkTheChange(e,`loadAssignDate-${dIndex}`)}
                                        hideShowSelectDate={true}
                                      />

                                    </div>
                                    <span className="input-icon">
                                      {leg[0] && leg[0].loadAssignedDate &&
                                      !leg[0] && leg[0].isVoidOut ? (
                                        <span
                                          onClick={() =>
                                            this.changeLoadAssignedDate(
                                              dIndex + 0
                                            )
                                          }
                                        >
                                          <IconTimes />
                                        </span>
                                      ) : (
                                        <IconCalendar />
                                      )}
                                    </span>
                                    <div></div>
                                  </div>
                                </div> 
                              </div>
                            
                              <div className="ml-auto mr-3 d-flex routing-header-right">
                                <div className="form-check mr-20">
                                  {this.state.driverOrder[dIndex].isBobtail ? <a
                                  href="#" className="btn btn-outline-light"
                                  onClick={(e) =>  this.assignBobtail(false,currentLegIndex + dIndex,this.state.driverOrder[dIndex])}
                                  >
                                  <IconCheck className="mr-2" />
                                  Bobtail
                                  </a> : <a 
                                  href="#" className="btn btn-outline-light"
                                
                                  onClick={(e) =>  this.assignBobtail(true,currentLegIndex + dIndex,this.state.driverOrder[dIndex])}
                                  >
                                  <IconPlus className="mr-2" />
                                  Add Bobtail
                                  </a>}
                                </div>
                                <div>
                                {(this.state.officeNote[dIndex] || this.state.driverNote[dIndex])  ? 
                                  <a
                                    href="#" className="btn btn-outline-light"
                                    data-tip
                                    data-for="notes"
                                    onClick={() => {
                                      this.props.showNotesModel && this.props.showNotesModel()
                                      this.props.changeTopTab("notes")
                                    }}
                                  >
                                    <IconNotes />
                                    <ReactTooltip
                                      place="top"
                                      effect="solid"
                                      id="notes"
                                      ref={(el) => (this.tooltip = el)}
                                    >
                                      <span>View in "Notes" tab Click to open</span>
                                    </ReactTooltip>
                                  </a>
                                :
                                <a
                                  href="#" className="btn btn-outline-light"
                                  onClick={() => {
                                    this.setState({ showNote: true })
                                  }}
                                >
                                  <IconPlus className="mr-2" />
                                  Add Note
                                </a>
                                }
                                
                                </div>
                              </div>
                            </div>
                            {this.state.driverOrder[dIndex].isGrayPool && 
                              <div className="mb-0 mt-3 mr-10 ml-10">
                                <div className="form-row bg-warning-50 p-10">
                                  <div className="d-flex align-items-center mr-3">
                                      <h6 className="m-0 font-12 font-medium">Gray Container #</h6>                                
                                      <form className="ml-10 w-200">
                                        <div className="form-group m-0">
                                          <div className="input-wrapper grayContainerNo">
                                            <input
                                              type="text"
                                              className="form-control transition-white-field"
                                              placeholder="Enter Container"
                                              name="grayContainerNo"
                                              autoComplete="off" 
                                              value={this.state.grayContainerNo
                                                ? this.state.grayContainerNo 
                                                : ''
                                              } 
                                              onChange={(e) => {
                                                this.setState({ grayContainerNo: e.target.value });                                                
                                              }}
                                              onKeyDown={(e) => {
                                                this.handleKeyDown(e);                                             
                                              }}
                                              onBlur={()=>{this.handleOrderStatusChange("update","grayContainerNo")}}            
                                            />
                                            {(this.props.selectedLoads.grayContainerNo || this.state.grayContainerNo) && (
                                              <div className="input-icon">
                                                <span onClick={(e) => { this.onRemovePress(null, { name: "grayContainerNo" }); }}>
                                                  <IconTimes />
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </form>                              
                                  </div>
                                  <div className="d-flex align-items-center mt-xs-1">
                                      <h6 className="m-0 font-12 font-medium">Gray Chassis #</h6>                                
                                      <form className="ml-10">
                                        <div className="form-group m-0">
                                          <AsyncCreatableSelect
                                            menuPlacement="auto"
                                            placeholder="Select.."
                                            className="w-200 grayChassisNo"
                                            defaultOptions={this.props.chassis.map((chassis) => ({
                                              label: chassis.chassisNo,
                                              value: chassis._id,
                                              allInfos: chassis,
                                            }))}
                                            cacheOptions
                                            loadOptions={this.getChassis}
                                            isClearable
                                            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                            value={
                                              this.state.grayChassisNo ? 
                                                { label: this.state.grayChassisNo, value: this.state.grayChassisNo }
                                              : null
                                            }
                                            onChange={(value) => {
                                              if(value){
                                                this.grayChassisUpdate(value)
                                              }else {
                                                this.onRemovePress(null, { name: "grayChassisNo" });
                                              }
                                            }}
                                            onCreateOption={(value) =>{
                                              this.setState({"grayChassisNo": value})}
                                            }
                                            onKeyDown={(e) => this.handleKeyDown(e)}
                                            // onBlur={()=> this.handleOrderStatusChange("update") }
                                            styles={smallSelectStyle}
                                          />
                                        </div>
                                      </form>                              
                                  </div>
                                </div>
                              </div>
                            }
                            <div
                              className={`form-row pb-3 align-items-end`}
                              key={100}
                              onDrop={(e) => {
                                if (legIndex === 0) {
                                  this.handleOnDrop(e);
                                }
                              }}
                              onDragOver={(e) => {
                                if (legIndex === 0) {
                                  this.handleDragOver(e);
                                }
                              }}
                              onDragEnter={() => {
                                if (legIndex === 0) {
                                  this.handleDragEnter(100);
                                }
                              }}
                            >
                              {this.state.droppedIndex === 100 && (
                                <div className="col-12 kanban-card-view mt-4">
                                  <p className="text-center my-4">
                                    Drop Your Status Here
                                  </p>
                                </div>
                              )}
                            </div>
                            {leg.map((status, statusIndex) => {
                              const index = dIndex + statusIndex;
                              const addressComp = ROUTING_ADDRESS_FIELDS[status.type];

                              return (
                                <React.Fragment>
                                  <div
                                    onDrop={this.handleOnDrop}
                                    onDragOver={(e) => this.handleDragOver(e)}
                                    onDragEnter={() => {
                                      this.handleDragEnter(index);
                                    }}
                                  >
                                    <div
                                      className={`outline-card outline-card--${legColor} p-10 mb-1`}
                                    >
                                      <div className="d-flex align-items-center mb-10 routing__card">
                                        {ICONS[status.type]}
                                        <span className="font-14">
                                          {status.type}
                                        </span>
                                        <div className="d-flex ml-auto align-items-center routing__card--status">
                                          {status.isVoidOut && 
                                            <span class="badge bg-danger text-light mr-2">
                                              Driver: {`${status.driver.name} ${status.driver.lastName || ""}`.trim()}
                                            </span>
                                          }
                                          {status.isVoidOut && (
                                            <span class="badge bg-danger text-light">
                                              Voided Out{" "}
                                              <span
                                                className="ml-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.removePullVoid(status, index)
                                                }
                                              >
                                                <IconTimes />
                                              </span>
                                            </span>
                                          )}
                                          {status.driver &&
                                            !status.isVoidOut &&
                                            !status.arrived && (
                                              <button
                                                className="btn btn-primary btn-sm mr-2"
                                                onClick={() =>
                                                  this.updateDriverOrderById(
                                                    "arrived",
                                                    status
                                                  )
                                                }
                                                disabled={!disabled}
                                              >
                                                Enroute
                                              </button>
                                            )}
                                          {status.driver &&
                                            !status.isVoidOut &&
                                            status.arrived && (
                                              <div className="input-wrapper">
                                                {/* <p className="driver-order-status-text text-primary">Enroute</p> */}
                                                <div className="mr-2 input-primary right">
                                                <DateWrapper 
                                                  className="mr-2 input-primary laDate right-0"
                                                  inputClassName="form-control-sm"
                                                  isShowTime={true}
                                                  onChange={(e) =>
                                                    this.changeCompletionStatus(
                                                      "arrived",
                                                      index,
                                                      e,
                                                      status._id
                                                    )
                                                  }
                                                  dateFormat="MM-DD-yyyy"
                                                  timeFormat={this.userTimeFormat}
                                                  displayTimeZone={getStorage("timeZone")}
                                                  disabled={isCompleted  || !disabled}
                                                  defaultDate={
                                                    status.arrived
                                                      ? moment(status.arrived)
                                                      : ""
                                                  }
                                                  OnBlurUpdate={() => this.state.changeCompletionDate && this.updateDriverOrderById('arrived', status)}
                                                  hideShowSelectDate={true}
                                                />
                                                {!isCompleted &&
                                                  (this.state
                                                    .changeCompletionDate &&
                                                  this.state.changeCompletionDate
                                                    .statusId === status._id &&
                                                  this.state.changeCompletionDate
                                                    .type === "arrived" ? (
                                                    <div
                                                      className="input-icon text-white"
                                                      onClick={() =>
                                                        this.updateDriverOrderById(
                                                          "arrived",
                                                          status
                                                        )
                                                      }
                                                    >
                                                      <IconCheck />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="input-icon text-white"
                                                      onClick={() =>
                                                        this.removeStatusDriverOrderById(
                                                          "arrived",
                                                          status._id
                                                        )
                                                      }
                                                    >
                                                      <IconTimes />
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            )}
                                          {status.driver &&
                                            !status.isVoidOut &&
                                            !status.departed && (
                                              <button
                                                className="btn btn-success btn-sm mr-2"
                                                onClick={() =>
                                                  this.updateDriverOrderById(
                                                    "departed",
                                                    status
                                                  )
                                                }
                                                disabled={!disabled}
                                              >
                                                Arrived
                                              </button>
                                            )}
                                          {status.driver &&
                                            !status.isVoidOut &&
                                            status.departed && (
                                              <div className="input-wrapper">
                                                <div className="mr-2 input-success right">
                                                <DateWrapper 
                                                  className="mr-2 input-success laDate right-0"
                                                  inputClassName="form-control-sm"
                                                  onChange={(e) =>{
                                                    this.changeCompletionStatus(
                                                      "departed",
                                                      index,
                                                      e,
                                                      status._id
                                                    );
                                                  }                                                                                                        
                                                  }
                                                  displayTimeZone={getStorage("timeZone")}
                                                  disabled={isCompleted  || !disabled}
                                                  defaultDate={
                                                    status.departed
                                                      ? moment(status.departed)
                                                      : ""
                                                  }
                                                  dateFormat="MM-DD-yyyy hh:mm"
                                                  timeFormat={this.userTimeFormat}
                                                  OnBlurUpdate={() => this.state.changeCompletionDate && this.updateDriverOrderById('departed', status)}
                                                  hideShowSelectDate={true}
                                                  isShowTime={true}
                                                />
                                                {!isCompleted &&
                                                  (this.state
                                                    .changeCompletionDate &&
                                                  this.state.changeCompletionDate
                                                    .statusId === status._id &&
                                                  this.state.changeCompletionDate
                                                    .type === "departed" ? (
                                                    <div
                                                      className="input-icon text-white"
                                                      onClick={() =>
                                                        this.updateDriverOrderById(
                                                          "departed",
                                                          status
                                                        )
                                                      }
                                                    >
                                                      <IconCheck />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="input-icon text-white"
                                                      onClick={() =>
                                                        this.removeStatusDriverOrderById(
                                                          "departed",
                                                          status._id
                                                        )
                                                      }
                                                    >
                                                      <IconTimes />
                                                    </div>
                                                  ))}
                                                  </div>
                                              </div>
                                            )}
                                          {status.driver &&
                                            !status.isVoidOut &&
                                            !status.departed &&
                                            ((this.state.driverOrder[index - 1] &&
                                              (this.state.driverOrder[index - 1]
                                                .isVoidOut ||
                                                this.state.driverOrder[index - 1]
                                                  .departed)) ||
                                              index === 0) && (
                                              <button
                                                className="btn btn-secondary btn-sm mr-2"
                                                onClick={() =>
                                                  this.pullVoid(status, index)
                                                }
                                                disabled={!disabled}
                                              >
                                                Void Out
                                              </button>
                                            )}
                                          {!status.isVoidOut && !status.departed && (
                                            <button
                                              className="btn btn-link text-danger"
                                              disabled={isCompleted}
                                              onClick={() =>{
                                                if(leg.length > 1){this.removeStatus(index)}
                                              }
                                              }
                                            >
                                              <IconTrash />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                        <label>{ROUTING_LABELS[status.type]}</label>
                                          <div className="w-100">
                                            {addressComp && (
                                              <>
                                                <AsyncSelect
                                                  className={`w-100 leg-${index}`}
                                                  isClearable
                                                  defaultOptions={makeOptionsForSelect(
                                                    this.props[addressComp.optionsField], "company_name", "address.address", "_id"
                                                  )}
                                                  cacheOptions
                                                  value={status.customerId ? {
                                                    label: `${status.customerId.company_name } ${status.customerId?.address?.address || ""}`,
                                                    value: status.customerId?._id,
                                                  } : null}
                                                  onChange={(value) => this.setCustomerToStatus(index, value, status)}
                                                  loadOptions={addressComp.loadOptions}
                                                  isDisabled={status.isVoidOut || isCompleted}
                                                  styles={smallSelectStyle}
                                                  isLoading={status.type === "RETURNCONTAINER" && this.state.isLoader}
                                                />
                                                {status.type === "RETURNCONTAINER" && this.props.isEmptyEnabled && 
                                                  <RecommendedReturnTerminals row={this.props.selectedLoads} isLoadInfo={true}/>
                                                }
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                    {this.state.droppedIndex === index && (
                                      <div className="col-12 kanban-card-view my-2">
                                        <p className="text-center my-4">
                                          Drop Your Status Here
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                      </div>
                    );
                  }
                })}

              {this.state.allowDriverCompletion && (
                <div className="card p-15 card-blue mb-10">
                  <div
                    className={`outline-card outline-card--blue p-10 mb-1`}
                    key={"driverCompletion"}
                  >
                    <div className="d-flex align-items-center mb-10">
                      {ICONS.COMPLETED}
                      <span className="font-14">Completed</span>
                      <div className="d-flex ml-auto">
                        {loadCompletedAt && (
                          <Datetime
                            className="mr-2 input-primary"
                            dateFormat="MM-DD-YY"
                            timeFormat={this.userTimeFormat ? this.userTimeFormat : true}
                            inputProps={{ disabled: isCompleted }}
                            displayTimeZone={getStorage("timeZone")}
                            value={moment(loadCompletedAt)}
                          />
                        )}
                        {!isCompleted && (
                          <button
                            className="btn btn-link text-danger"
                            onClick={() =>{
                              this.setState({ allowDriverCompletion: false },
                                () => this.handleOrderStatusChange("update")
                              );                              
                            }
                            }
                          >
                            <IconTrash />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.showNote && <Modal className="backdrop_modal_open" show={this.state.showNote} bsSize="sm" animation={false}>
          <Modal.Header>
            <h5 className="modal-title" id="showModal">
              Notes
            </h5>
          </Modal.Header>
          <Modal.Body>
            <ul
              className="nav nav-compact w-100 text-center"
              role="tablist"
            >
              <li className="nav-item w-50">
                {" "}
                <a
                    className={`nav-link ${this.state.activeNoteTab == "load" ? "active"  : ''}  mr-1`}
                  data-toggle="tab"
                  href="#note"
                  role="tab"
                  onClick={() => this.setState({ activeNoteTab: "load" })}
                >
                  <span className="hidden-xs-down">Load</span>
                </a>{" "}
              </li>
              <li className="nav-item w-50">
                {" "}
                <a
                  className={`nav-link ${this.state.activeNoteTab == "driver" ? "active"  : ''}  mr-1`}
                  data-toggle="tab"
                  href="#note"
                  role="tab"
                  onClick={() => this.setState({ activeNoteTab: "driver" })}
                >
                  <span className="hidden-xs-down">Driver</span>
                </a>{" "}
              </li>
            </ul>

            <form className="mt-3">
              <div className="form-group">
                <textarea
                  disabled={this.state.loading}
                  className="form-control"
                  name={
                    this.state.activeNoteTab === "load"
                      ? "officeNote"
                      : "driverNote"
                  }
                  rows={7}
                  placeholder={`Write notes for the ${this.state.activeNoteTab === "load"
                  ? "load"
                  : "driver"} here..`}
                  value={
                    this.state.activeNoteTab === "load"
                      ? this.state.officeNote
                      : this.state.driverNote
                  }
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.setState({ showNote: false, officeNote: "", driverNote: "" })}
              className="btn btn-close"
            >
              Close
            </button>
            <button
              disabled={this.state.loading}
              onClick={() => this.submitNotes()}
              className="btn btn-primary"
              value="Save"
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>}
        {this.state.showLoadModel && 
            this.state.openModalRefNumber &&
            <LoadInfoModal 
              refNumber={this.state.openModalRefNumber} 
              hideLoadModal={()=>this.setState({showLoadModel:false,openModalRefNumber:null})}  
              />
          }
      </React.Fragment>
    );
  }
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser) {
      if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" ||loggedInUser.role === "fleetcustomer") {
        return this.renderReturn();
      } else if (loggedInUser.permissions.length !== 0) {
        if (loggedInUser.permissions.includes("customer_service_order_status")){ 
          return this.renderReturn();
        }
        else {return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>}
      }

      else {return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>;}
    }
  }
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ ...actionCreators, hardRemoveDriver }, dispatch) };
}

function mapStateToProps(state) {
  return {
    driverList: state.TmsReducer.drivers,
    customers: state.TmsReducer.customers,
    shippersOptions: state.TmsReducer.shippers,
    consigneesOptions: state.TmsReducer.consignees,
    callers: state.TmsReducer.callers,
    customers: state.TmsReducer.customers,
    allCustomers: state.TmsReducer.allCustomers,
    chassisTerms: state.TmsReducer.chassisTerms,
    chassis: state.chassisReducer.chassis,
    isEmptyEnabled: state.TmsReducer.isEmptyEnabled,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLoadDriverOrder);
