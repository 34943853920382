import React, { useState, Component } from "react";
import moment from "moment";
import { getStorage } from "../../../services/Common.services";
import { getRoutesFromDriverOrders } from "./utility";
import TrackingHistoryPlayer from "./elements/TrackingHistoryPlayer";
import { IconShare } from "../../../Components/Common/Icons";
import LiveShareModal from "./LiveShareModal";
import ReactTooltip from "react-tooltip";

let timeZone = null;

class DriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectCard: -1,
      selectedDriverOrder: null,
      selectedLoad: null,
      showModal: false,
      liveShareReferenceNumber: null,
    };
    timeZone = getStorage("timeZone");
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d sec",
        m: "%d min",
        mm: "%d min",
        h: "%d hr",
        hh: "%d hr",
        d: "%d day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years",
      },
    });
  }

  handleSelectCard = (i, load = null, driverId = null) => {
    if (!this.props.disableClicks) {
      this.setState({
        // isSelectCard: this.state.isSelectCard === i ? -1 : i,
        isSelectCard: i,
      });
    }

    if (this.props.drawRouteToMap && load) {
      const routes = getRoutesFromDriverOrders(load.driverOrder, driverId);
      this.props.drawRouteToMap(routes);
    }
  };
  handleShowModal = (referenceNumber) => {
    this.setState({ showModal: true, liveShareReferenceNumber: referenceNumber });
  };
  onCancel = () => {
    this.setState({ showModal: false,liveShareReferenceNumber: null });
  };
  getStatusClass = (driverOrder) => {
    if (driverOrder.arrived && driverOrder.departed) {
      return ["complete", "uil-check", "dlist--complete"];
    } else if (driverOrder.arrived && !driverOrder.departed) {
      return ["active", "uil-play", "dlist--active"];
    } else if (!driverOrder.arrived && !driverOrder.departed) {
      return ["upcoming", "uil-hourglass", "dlist--upcoming"];
    }
  };

  getTimeDifference(endTime, startTime) {
    if (startTime && endTime) {
      const startTimeObject = moment(startTime);
      const endTimeObject = moment(endTime);
      const duration = moment.duration(endTimeObject.diff(startTimeObject));

      return duration.humanize();
    }
    return null;
  }

  loads = [];
  render() {
    if (this.props.allLoads) {
      this.loads = [this.props.allLoads];
    } else {
      this.loads = this.props.selectedDriver.loads;
    }

    return (
      <React.Fragment>
        <LiveShareModal
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          referenceNumber={this.state.liveShareReferenceNumber}
        />
        {/* START TrackBar Design */}
        <div
          className="mapbar mapbar-big"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <div
            className={`dlist ${
              this.state.isSelectCard !== null ? "dlist--cardselected" : ""
            }`}
            style={{ height: "calc(100% - 10px)" }}
          >
            {this.loads &&
              this.loads.map((load, index) => {
                return (
                  <div
                    key={Math.random()}
                    className={`dlist__card mb-1 p-20 ${
                      this.state.isSelectCard === index
                        ? "dlist__card--selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        getStorage("currentUserRole") != "customer" &&
                        getStorage("currentUserRole") !== "fleetcustomer"
                      ) {
                        if (this.props.toggleLiveLocation) {
                          this.props.toggleLiveLocation(false);
                        }
                        this.setState({
                          selectedLoad: load,
                          selectedDriverOrder: null,
                        });
                        this.selectedDriver =
                          this.props.selectedDriver &&
                          this.props.selectedDriver._id;
                        this.selectedDriver = null;
                        this.handleSelectCard(
                          index,
                          load,
                          this.props.selectedDriver &&
                            this.props.selectedDriver._id
                        );
                      }
                    }}
                  >
                    <div className="d-flex align-items-start py-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a className="text-dark">{load.reference_number}</a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>
                            {moment(load.loadAssignedDate)
                              .tz(timeZone)
                              .format("MM/DD/YY, LT")}
                          </span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="text-muted">
                          {load.containerSizeName}
                        </div>
                        <div className="text-muted">{load.containerNo}</div>
                      </div>
                      <div className="flex-shrink-0 ml-10">
                        <button
                          className="btn btn-outline-primary p-1"
                          onClick={(e) => {
                            this.handleShowModal(load.reference_number);
                            e.stopPropagation();
                          }}
                        >
                          <IconShare />
                          <ReactTooltip id="buttonEnable" place="right">
                            <span>
                              share a public links for others to view ETA and
                              live Location
                            </span>
                          </ReactTooltip>
                        </button>
                      </div>
                    </div>
                    <div className="dlist__distance">
                      {load.driverOrder.map((driverOrder, key) => {
                        let showLoad = true;
                        let showNextLeg = false;

                        if (this.props.allLoads) {
                          showLoad = true;
                          showNextLeg = true;
                        } else if (
                          driverOrder.driver !== this.props.selectedDriver._id
                        ) {
                          showLoad = false;
                        }

                        if (
                          (load.driverOrder[key + 1] &&
                            load.driverOrder[key + 1].driver) ===
                          (this.props.selectedDriver &&
                            this.props.selectedDriver._id)
                        ) {
                          showNextLeg = true;
                        }

                        const statusClassData =
                          this.getStatusClass(driverOrder) &&
                          this.getStatusClass(driverOrder);
                        const statusClass =
                          statusClassData && statusClassData[2];
                        const statusIcon =
                          statusClassData && statusClassData[1];

                        if (!showLoad) {
                          return;
                        }

                        return (
                          <div
                            key={Math.random()}
                            className={`dlist__distance--row d-flex ${statusClass}`}
                            onClick={(e) => {
                              if (
                                getStorage("currentUserRole") != "customer" &&
                                getStorage("currentUserRole") !==
                                  "fleetcustomer"
                              ) {
                                if (this.props.toggleLiveLocation) {
                                  this.props.toggleLiveLocation(false);
                                }
                                e.stopPropagation();
                                this.setState({
                                  selectedDriverOrder: driverOrder,
                                  selectedLoad: load,
                                });

                                if (
                                  driverOrder.address &&
                                  this.props.handleDriverOrderClick
                                ) {
                                  this.props.handleDriverOrderClick(
                                    driverOrder
                                  );
                                }

                                if (
                                  this.props.drawRouteToMap &&
                                  load.driverOrder[key + 1]
                                ) {
                                  let routeId;

                                  if (
                                    this.props.selectedDriver &&
                                    this.props.selectedDriver._id &&
                                    load.driverOrder[key + 1].driver ===
                                      this.props.selectedDriver._id
                                  ) {
                                    routeId = `${driverOrder._id}-${
                                      load.driverOrder[key + 1]._id
                                    }`;
                                  } else if (
                                    this.props.selectedDriver &&
                                    this.props.selectedDriver._id &&
                                    load.driverOrder[key + 1].driver !==
                                      this.props.selectedDriver._id
                                  ) {
                                    routeId = "ignore";
                                  } else {
                                    routeId = `${driverOrder._id}-${
                                      load.driverOrder[key + 1]._id
                                    }`;
                                  }

                                  const routes = getRoutesFromDriverOrders(
                                    load.driverOrder,
                                    this.props.selectedDriver &&
                                      this.props.selectedDriver._id,
                                    routeId
                                  );
                                  this.props.drawRouteToMap(routes);
                                } else if (
                                  this.props.drawRouteToMap &&
                                  !load.driverOrder[key + 1]
                                ) {
                                  const routes = getRoutesFromDriverOrders(
                                    load.driverOrder,
                                    this.props.selectedDriver &&
                                      this.props.selectedDriver._id,
                                    "ignore"
                                  );
                                  this.props.drawRouteToMap(routes);
                                }
                                this.setState({
                                  isSelectCard: index,
                                });
                              }
                            }}
                          >
                            <div className="dlist__timeframe">
                              <div className="dlist__timeframe--icon">
                                <i className={`uil ${statusIcon}`}></i>
                              </div>
                              {load.driverOrder[key + 1] &&
                                load.driverOrder[key + 1].arrived &&
                                showNextLeg && (
                                  <div className="dlist__timeframe--miles">
                                    {load.driverOrder[key + 1] &&
                                      load.driverOrder[key + 1].distance}{" "}
                                    mi
                                  </div>
                                )}
                            </div>
                            <div className="dlist__distance--info w-100">
                              <div className="d-flex align-items-start pb-2">
                                <div className="flex-grow-1">
                                  <h5 className="font-14 mb-1">
                                    <a className="text-dark">
                                      {driverOrder.company_name &&
                                        driverOrder.company_name}
                                    </a>
                                  </h5>
                                  <p className="text-muted mb-0">
                                    <span>
                                      {driverOrder.address &&
                                        driverOrder.address.address}
                                    </span>
                                  </p>
                                </div>
                                <div className="flex-shrink-0">
                                  <span className="badge badge-soft-success">
                                    {driverOrder.type && driverOrder.type}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center pb-2">
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-0">
                                    <span>Enroute</span>
                                  </p>
                                  <div>
                                    {driverOrder.arrived
                                      ? `${moment(driverOrder.arrived)
                                          .tz(timeZone)
                                          .format("MM/DD/YY, LT")}`
                                      : ""}
                                  </div>
                                </div>
                                <div className="flex-shrink-0 mx-2">
                                  <span className="badge bg-light">
                                    {driverOrder.departed &&
                                      driverOrder.arrived &&
                                      `${this.getTimeDifference(
                                        driverOrder.departed,
                                        driverOrder.arrived
                                      )}`}
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-0">
                                    <span>Arrived</span>
                                  </p>
                                  <div>
                                    {driverOrder.departed
                                      ? `${moment(driverOrder.departed)
                                          .tz(timeZone)
                                          .format("MM/DD/YY, LT")}`
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="dlist__hr">
                                <span className="badge badge-light font-12 dlist__hr__time">
                                  {driverOrder.departed &&
                                    load.driverOrder[key + 1] &&
                                    load.driverOrder[key + 1].arrived &&
                                    `${this.getTimeDifference(
                                      load.driverOrder[key + 1].arrived,
                                      driverOrder.departed
                                    )}`}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          {this.state.isSelectCard !== -1 && (
            <TrackingHistoryPlayer
              driverOrder={this.state.selectedDriverOrder}
              load={this.state.selectedLoad}
              driver={this.props.selectedDriver}
              mapInstance={this.props.mapInstance}
              toggleLiveLocation={this.props.toggleLiveLocation}
              liveLocationToggled={this.props.liveLocationToggled}
            />
          )}
        </div>
        {/* END TrackBar Design */}
      </React.Fragment>
    );
  }
}

export default DriverDetails;
