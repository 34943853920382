import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { bindActionCreators } from 'redux';

import * as actions from './actionCreators';
import {getAppSettings, getStandardPort} from '../../pages/tms/Settings/actionCreators';
import { getCarrierByUserId, getCarrierId, getStorage, setStorage } from '../../services';
import { TMS_GET_CUSTOMERS } from '../../pages/tms/constant';
import { getSettings, getTerminalsId, getCustomReportIds } from '../../pages/tms/services';
import { getDispatcherView } from '../../pages/tms/Dispatcher/DispatcherView/actionCreators';

function fetchData(dispatch) {
  const allActions = bindActionCreators(actions, dispatch);
  // const getAppSettings = bindActionCreators(getAppSettings, dispatch);

  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  // console.log("-------- render Fetch Data -------");
  if(loggedInUser &&  !['admin', 'support'].includes(loggedInUser.role)){
    allActions.getChargeCodes();
    let carrierId = getCarrierId();
    getCarrierByUserId(carrierId) 
    .then((res) => {
      if(res){
        setStorage("carrierDetail", JSON.stringify(res.data.data));
      }
    })
    .catch((err) =>{
      console.log(err)
    });
  }
  if(!getStorage("browsertoken")){
    createBrowserToken();
  }
  if (loggedInUser && (loggedInUser.role === "carrier"
    || (loggedInUser.role === "fleetmanager" &&
      loggedInUser.fleetManager && !loggedInUser.fleetManager.isCustomer))) {

    // console.log("-*-*-* api call *-*-*",);
    dispatch(getAppSettings());
    dispatch(getStandardPort());
  
    allActions.getContainerSize();
    allActions.getContainerType();
    allActions.getTMSContainerOwner();
    allActions.getTMSChassis();
    allActions.getChassisSize();
    allActions.getChassisType();
    allActions.getTMSChassisOwner();
    allActions.getTrailers();
    allActions.getAllStatusFilter();
    allActions.getAllDrivers();
    allActions.getCallers();
    allActions.getShippers();
    allActions.getConsignees();
    allActions.getAllCustomers();
    allActions.getChassisTerms();
    getTerminalsId();
    getCustomReportIds();
    getSettings()
      .then(response => {
        if (response.data && response.data.data) {
          const distanceUnit = _.get(response, 'data.data.carrier.distanceMeasure')
          const weightUnit = _.get(response, 'data.data.carrier.weightMeasure')
          const isCommodityProfileShow = _.get(response, 'data.data.carrier.isCommodityProfileShow')
          const groupedProfiles = _.get(response, 'data.data.groupedProfiles')
          const groupedCities = _.get(response, 'data.data.groupedCities')
          const TimeZone = _.get(response,'data.data.carrier.homeTerminalTimezone')
          setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow ,groupedProfiles,groupedCities}))
          setStorage("timeZone",TimeZone)
          sessionStorage.setItem("tabId",Math.floor(Math.random()*1E16))
        }
      })
      .catch(error => {
        console.log("-*-* error -*-*", error)
      })
    getDispatcherView().then((result) =>  {
      setStorage('dispatcherViews', result.length > 0 ? JSON.stringify(result) : null)
      let defaultView = result.filter((res) => res.isDefaultView === true)
      if(defaultView){
        setStorage('dispatcherDefaultView', defaultView.length > 0 ? JSON.stringify(defaultView[0]) : null)
      }
    })
    allActions.getAllUserPublicInfo();

    dispatch({
      type: TMS_GET_CUSTOMERS,
      payload: {
        skip: 0,
        limit: 10,
      }
    });
  }

}

function createBrowserToken() {
  setStorage('browsertoken',  uuidv4());
}
export default fetchData;
