import $ from "jquery";
import jsonLogic from "json-logic-js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "underscore";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { IconMinus, IconPlus, IconSort, IconSortDown, IconSortUp } from "../../../Components/Common/Icons/index";
import PriceInput from "../../../Components/Common/PriceInput";
import { getExpenseRules } from "../../../Components/DynamicPricing/actionCreators";
import {
  convertUnit,
  getPricingSettingsOptions,
  getStorage,
  toastr,
  treeToJsonParser,
} from "../../../services/Common.services";
import { TMS_GET_USER_LOAD } from "../constant";
import { UpdateExpense } from "../services";
import * as tmsAction from "./actionCreator";

const MANUALLY_CHANGED = 'MANUALLY_CHANGED';
const expenseItems = {
  amount: 0,
  chargePerDay: 0,
  chargePerMinute: 0,
  finalAmount: 0,
  description: "",
  unit: 0,
  freeDays: 0,
  perType: "",
  name: "",
  manualFinalAmount: 0,
};
// const sortingOptions = {
//   "Name of Charge #": "name",
// };

class LoadExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseRules: [],
      asce: true,
    };
  }

  componentWillMount() {
    this.getExpenseRules();
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
    } else {
      this.componentWillReceiveProps(this.props);
    }
  }

  componentDidMount() {
    if(this.props.handleExpenseUpdate) {
      this.props.handleExpenseUpdate(() => this.updateExpense());
    }
  }

  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  getExpenseRules() {
    getExpenseRules()
      .then((expenseRules) => {
        this.setState({ expenseRules });
      })
      .catch((error) => {
        console.log(" -*-*-* error get pricing rules -*-*-*- ", error);
      });
  }

  componentWillReceiveProps(props) {
    if (
      this.props.loads.expense !== props.loads.expense ||
      (props.loads.expense && !this.state.expense)
    ) {
      let base_price = 0;
      let allPricings = props.loads.expense.filter((x) => x);
      if (allPricings && allPricings.length > 0) {
        allPricings.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price +=  parseFloat(x.finalAmount)  ;
            }
            x.manualFinalAmount = x.finalAmount;
          }
        });
      }
      this.setState({ expense: allPricings, base_price:base_price.toFixed(2) });
    }
    if (getStorage("currentUserRole") == "customer" && !props.loads.expense) {
      this.setState({ expense: [] });
    }
    
  }

  AddPriceRows() {
    let expense = this.state.expense ? [...this.state.expense] : [];
    expense.push({ ...expenseItems });
    
    // let sort = undefined
    // if(this.state.sort =="name"){
    //   sort = "name"
    // }
    this.setState({
      rowsData: [...expense],
      expense,
      asce:true
    });
  }

  findMatchingExpenseRule(chargeName) {
    let selectedLoads = undefined;
    if (this.props.selectedLoads) {
      selectedLoads = this.props.selectedLoads;
    } else {
      selectedLoads = this.props.loads;
    }

    const loads = {
      ...selectedLoads,
      caller: selectedLoads.caller && selectedLoads.caller._id,
      shipper: selectedLoads.shipper && selectedLoads.shipper.map((s) => s._id),
      consignee:
        selectedLoads.consignee && selectedLoads.consignee.map((s) => s._id),
      chassisPick: selectedLoads.chassisPick && selectedLoads.chassisPick._id,
      emptyOrigin: selectedLoads.emptyOrigin && selectedLoads.emptyOrigin._id,
      chassisTermination:
        selectedLoads.chassisTermination &&
        selectedLoads.chassisTermination._id,
      containerType:
        selectedLoads.containerType && selectedLoads.containerType._id,
      containerSize:
        selectedLoads.containerSize && selectedLoads.containerSize._id,
      containerOwner:
        selectedLoads.containerOwner && selectedLoads.containerOwner._id,
      chassisType: selectedLoads.chassisType && selectedLoads.chassisType._id,
      chassisSize: selectedLoads.chassisSize && selectedLoads.chassisSize._id,
      chassisOwner:
        selectedLoads.chassisOwner && selectedLoads.chassisOwner._id,
      terminal: selectedLoads.terminal && selectedLoads.terminal._id,
      commodityProfile:
        selectedLoads.commodityProfile &&
        selectedLoads.commodityProfile.map((s) => s._id),
    };

    const { expenseRules } = this.state;
    const newExpenseFromSettings = [];
    let weight = 0;

    if (selectedLoads.items) {
      selectedLoads.items.forEach((obj) => {
        weight += parseInt(obj.weight);
      });
    }
    selectedLoads.weight = weight;
    expenseRules
      .filter((rule) => rule.name === chargeName && rule.isManual)
      .forEach((obj) => {
        let isAllConditionMatched = true;

        if (obj.query && obj.query[0] && obj.query[0].children.length > 0) {
          const rule = JSON.stringify(treeToJsonParser(obj.query)[0]);
          const data = JSON.stringify(loads);
          isAllConditionMatched = jsonLogic.apply(
            JSON.parse(rule),
            JSON.parse(data)
          );
        }
        if (isAllConditionMatched) {
          let totalDays = 0;
          if (obj.type === "fixed") {
            obj.finalAmount = obj.amount;
          } else if (obj.type === "perpound") {
            totalDays = weight;
          }
          obj.totalDays = totalDays;
          newExpenseFromSettings.push(obj);
        }
      });
    return newExpenseFromSettings[0];
  }

  // sortTable(sort) {
  //   if (this.state.sort == "name" && !this.state.asce) {
  //     this.setState({ sort: undefined, asce: true });
  //   } else {
  //     this.setState({
  //       sort,
  //       asce: this.state.sort !== sort ? true : !this.state.asce,
  //     });
  //   }
  // }
  onFieldChange = (key,name) => {
    const noChange = _.isEqual(this.state.expense[key][name],this.props.prevExpense[key][name])
    if(noChange) return
    let value = this.state.expense[key][name]?.trim()
    if(value === "" && !this.props.prevExpense[key][name]) return
    this.updateExpense()
  }


  expensechanges(index, e) {
    const { name, value } = e.target;
    const old = [...this.state.expense];

    const positiveValuesOnly = [
      // "amount",
      // "finalAmount",
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      old[index][name] = value;
    }

   
    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    if (name === "name") {
      const data = this.findMatchingExpenseRule(value);

      if (data) {
        let units = 0;
        const freeDays = data.freeDays || 0;
        const chargePerDay = data.amount || 0;

        if (data.type === "fixed") units = 1;

        let finalAmount = (units - freeDays) * chargePerDay;

        if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;
        old[index]["description"] = data.description;
        old[index]["perType"] = data.type;
        old[index]["freeDays"] = freeDays > 0 ? freeDays : 0;
        old[index]["chargePerDay"] = chargePerDay;
        old[index]["unit"] = units;
        old[index]["finalAmount"] = finalAmount;
        old[index]["manualFinalAmount"] = 0;
        old[index]["amount"] = finalAmount;

        this.setState({
          expense: [...old],
        });
        return;
      }
    }

    if (name === "type") {
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
            100;
    } else if (name === "amount") {
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
            100;
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay",'perType'].includes(name)) {
      let computedAmount =
      (old[index].unit - old[index].freeDays) * old[index].chargePerDay;

      old[index][name] = value;

      if (computedAmount >= old[index].manualFinalAmount) {
        old[index]["finalAmount"] = computedAmount.toFixed(2);
      } else {
        old[index]["finalAmount"] = parseInt(
          old[index].manualFinalAmount
        ).toFixed(2);
      }

      if((name == "perType" && value =="percentage")
      || (name !== "perType" && old[index]["perType"] == "percentage") ){
        if (old[index]["name"] !== 'Fuel') {
          old[index]["finalAmount"] = (this.state.base_price * old[index]["unit"] / 100).toFixed(2);
        }
      }
    }
    this.setState({
      expense: [...old],
    });
  }
  DeletePriceRow(index) {
    let data = [...this.state.expense];
    data.splice(index, 1);
    this.setState({
      expense: data,
    },()=>{
      this.updateExpense()
    });
  }

  updateExpense() {
    let valid = true;
    if (this.state.expense.filter((data) => !data.name).length > 0) {
      toastr.show("You must enter a name in the field.", "error");
      return;
    }
    if (
      this.state.expense.filter((data) => data.finalAmount === "").length > 0
    ) {
      toastr.show("Please enter the missing field for your rate!", "error");
      return;
    }
    if (this.state.expense.filter((data) => data.name === "").length > 0) {
      toastr.show("Please enter a name for your rate!", "error");
      return;
    }
    var payload = {
      expense: this.state.expense.map((p) => _.omit(p, "manualFinalAmount")),
    };
    let newExpense = payload.expense.map((p) => {
      let newDescription = p.description && p?.description.trim()
      return { ...p, description: newDescription}
    })
    payload.expense = newExpense;
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(true);
      UpdateExpense("tms/updateExpenseLoad", payload)
        .then((response) => {
          $(".reloadCurrentLoad").click();
          this.setState({ expense: response.data.data.expense });

          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("The expense has been updated.", "success");
          this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
        })
        .catch(() => { this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false); });
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  keyPressOnNumberInput = (evt)=>{
    var invalidChars = [
      "-",
      "+",
      "e",
    ];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }

  renderReturn = () => {
    let {expense} = this.state
    var disabled = true;
    var selectedLoads;
    if (this.props.loads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    if (selectedLoads) {
      disabled = [
        "APPROVED",
        "BILLING",
        "REBILLING",
        "PARTIAL_PAID",
        "FULL_PAID",
      ].includes(selectedLoads.status);
    }
    let showSave = false;
    if (selectedLoads) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ==
          selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") == selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "customer" &&
        getStorage("currentUserID") == selectedLoads.addedBy._id
      ) {
        showSave = true;
      }
    }
    // if (this.state.sort) {
    //   expense = orderBy(
    //     expense,
    //     (charge)=>charge[this.state.sort].toLowerCase(), !this.state.asce && "desc"
    //   );
    // }
  
    return (
      <div>
        {selectedLoads && (
          <div className="mb-1">
            <table className="table table-card mb-10 main-cstm-charge">
              <thead>
                <tr>
                  <th width="200" className="z-3">Name of Charge #</th> 
                  <th width="300" className="z-3">Description</th>
                  <th width="210" className="z-3">Price</th>
                  <th width="140" className="z-3">Unit Count</th>
                  <th width="150" className="z-3">Unit of Measure</th>
                  <th width="100" className="z-3">Free Units</th>
                  <th width="100" className="z-3">Per Units</th>
                  <th width="42"></th>
                </tr>
              </thead>{" "}
              <tbody>
                {expense &&
                  expense.length > 0 &&
                  expense.map((item, key) => {
                    let chargeValue = this.props.chargeCodeList.find((d) => d.value === item.name)
                    let charges = this.props.chargeCodeList.filter((d) => d.isActive)
                    return (
                      <tr key={key}>
                        <td>
                          <CustomSelect
                            size="small"
                            placement="right"
                            disabled={disabled}
                            options={charges.map((charge) => ({label: charge.chargeName, value: charge.value}))}
                            value={chargeValue ? {label: chargeValue.chargeName, value: chargeValue.value} : null}
                            onChange={({ value }) =>
                              this.expensechanges(key, {
                                target: { value, name: "name" },
                              })
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="description"
                            value={item.description}
                            onChange={(e) => this.expensechanges(key, e)}
                          />
                        </td>
                        {item.name === "Fuel" ? (
                          <td className="lint">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="mr-1 d-flex w-100">
                                <input
                                  className="form-control p-1"
                                  type="number"
                                  min={0}
                                  disabled={disabled}
                                  name="amount"
                                  value={item.amount}
                                  onKeyDown={(e)=>{this.keyPressOnNumberInput(e)}}
                                  onChange={(e) => this.expensechanges(key, e)}
                                />
                              </div>
                              <div className="mr-1 d-flex w-100">
                                <CustomSelect
                                  className="w-100"
                                  size="small"
                                  disabled={disabled}
                                  options={[
                                    { value: "fixed", label: "$" },
                                    { value: "percentage", label: "%" },
                                  ]}
                                  value={
                                    item.type && item.type === "fixed"
                                      ? { value: "fixed", label: "$" }
                                      : { value: "percentage", label: "%" }
                                  }
                                  onChange={({ value }) =>
                                    this.expensechanges(key, {
                                      target: { value, name: "type" },
                                    })
                                  }
                                />
                              </div>
                              <div className="d-flex w-100">
                                <input
                                  className="form-control p-1"
                                  type="number"
                                  min={0}
                                  disabled={disabled}
                                  name="finalAmount"
                                  value={item.finalAmount}
                                  onKeyDown={(e)=>{this.keyPressOnNumberInput(e)}}
                                  onChange={(e) => this.expensechanges(key, e)}
                                />
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              disabled={disabled}
                              name="finalAmount"
                              value={item.finalAmount}
                              onKeyDown={(e)=>{this.keyPressOnNumberInput(e)}}
                              onChange={(e) => this.expensechanges(key, e)}
                            />{" "}
                          </td>
                        )}
                        <td>
                          <PriceInput
                            disabled={disabled}
                            name="unit"
                            value={parseFloat(item.unit).toFixed(2)}
                            onChange={(val) => {
                              this.expensechanges(key, {
                                target: {
                                  value: val,
                                  name: "unit",
                                },
                              });
                            }}
                          />
                        </td>
                        <td>
                          <CustomSelect
                            className="w-100"
                            size="small"
                            options={options}
                            value={
                              item.perType &&
                              options.find((op) => op.value === item.perType)
                            }
                            onChange={({ value }) =>
                              this.expensechanges(key, {
                                target: { value, name: "perType" },
                              })
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            className="form-control"
                            name="freeDays"
                            disabled={!item.perType}
                            value={item.freeDays}
                            onChange={(e) =>
                              item.perType && this.expensechanges(key, e)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            className="form-control"
                            name="chargePerDay"
                            disabled={!item.perType}
                            value={item.chargePerDay}
                            onChange={(e) =>
                              item.perType && this.expensechanges(key, e)
                            }
                          />
                        </td>
                        {showSave && (
                          <td>
                            <button
                              className="btn btn-xs btn-circle bg-soft-danger"
                              disabled={disabled}
                              onClick={() => this.DeletePriceRow(key)}
                            >
                              <IconMinus className="text-danger" />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="card rounded-0 mb-0 p-3">
              <a
                className="text-primary font-weight-500"
                onClick={() => this.AddPriceRows()}
              >
                <IconPlus className="mr-2" />
                Add Expense
              </a>

            </div>
          </div>
        )}
      </div>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_billing") ||
          (loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer &&
            loggedInUser.permissions.includes("customer_service_billing"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapStateToProps() {
  return {
    // selectedLoads: state.TmsReducer.selectedLoads,
  };
}

function mapDispatchToProps(dispatch) {
  
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadExpense);

const options = [
  { value: "perday", label: "perday" },
  { value: "perhour", label: "perhour" },
  { value: "permile", label: `per${convertUnit().toLowerCase()}` },
  {
    value: "perpound",
    label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
  },
  { value: "fixed", label: "fixed" },
  { value: "percentage", label: "percentage" },
];
