import React, { useState } from 'react'
import { toastr, updateNewTranscation } from '../../../../services';

const NumberFormater = (props) => {
  const [amount, setAmount] = useState(props.initialValues.amount)
  const update = () => {
    props.unselectCell()
    if (amount === props.initialValues.amount) return
    let data = { ...props.initialValues };
    data.amount = amount;
    let additionalPrice = [];
    if (
      props.label != "Pulled Container" &&
      props.label != "Return Container" &&
      props.label != "Delivered" &&
      props.label != "Get Container" &&
      props.orginalPricing &&
      props.orginalPricing.length > 0
    ) {
      props.orginalPricing.map((dat) => {
        if (dat) {
          if (dat.name == props.label) {
            additionalPrice.push({
              name: this.props.label,
              amount: Number(data.amount),
            });
          } else {
            additionalPrice.push({
              name: dat.name,
              amount: dat.amount,
            });
          }
        }
      });
      delete data.amount;
    }
    if (additionalPrice.length > 0) {
      data.additionalPrice = JSON.stringify(additionalPrice);
    }
    if (props.isFromSettlement) {
      data.isFromSettlement = true;
    } else {
      data.isFromSettlement = false;
    }
    if (props.from) {
      data.from = props.from;
    }

    data.isManual = true;

    updateNewTranscation(data)
      .then((response) => {
        props.update(response.data.data);
      })

  };

  return (
    <input
      className="form-control border-0 font-14 text-right text-dark bg-transparent"
      placeholder="$0.00"
      type="text"
      value={amount && `$${amount}`}
      onChange={(e) => setAmount(e.target.value.replace('$', '').trim())}
      onBlur={() => update()}
      onKeyPress={(event) => {
        if(event.key==="Enter"){
          update()
        }
        if (!/[0-9.]/.test(event.key) || (event.target.value.indexOf('.')!=-1 && event.key===".")) {
          event.preventDefault();
      }
      }}
    />
  )
}

export default NumberFormater