import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { bindActionCreators } from "redux";
import { smallSelectStyle } from "../../../assets/js/select-style";
import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import {
  IconCalendar,
  IconDownload,
  IconMinus,
  IconPlus,
  IconSendEmail,
  IconTrash,
} from "../../../Components/Common/Icons";
import PriceInput from "../../../Components/Common/PriceInput";
import {
  convertUnit,
  getBasicSettings,
  getPricingSettingsOptions,
  getStorage,
  makeOptionsForSelect,
  toastr,
} from "../../../services/Common.services";
import * as actionCreators from "../Dispatcher/actionCreators";
import { emailInvoice, getDocumentDesignswithLoad, getSubInvoiceDesign } from "../DocumentConfiguration/actionCreators";
import { SUB_INVOICE } from "../DocumentConfiguration/constants";
import {
  sendMultileDocToSubCustomerEmail,
  UpdateAdditionalPricing,
  getSettings
} from "./../../tms/services";
import * as tmsAction from "./actionCreator";

let freeze = false; //mark delay
let timer; //saved timer

const getTMScallerAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await actionCreators.getTMScaller(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

class AdditionalPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalPricing: [],
      emails: [],
      cc: [],
      isSaving: false,
      errors: [],
      formErrors: [],
      asce: true,
      editorState: EditorState.createEmpty(),
      isEnable: false,
      isModified: false,
      updateDateOnInvoice:false
      
    };
    this.updateAdditionalPricing = this.updateAdditionalPricing.bind(this);
    this.handleextraEmailChange = this.handleextraEmailChange.bind(this);
    this.handleccEmailChange = this.handleccEmailChange.bind(this);
  }

  componentWillReceiveProps(props) {
    if (!_.isEqual(props.selectedLoads, this.props.selectedLoads)) {
      if (props.selectedLoads && !this.state.isModified) {
        this.setState({ additionalPricing: props.selectedLoads.additionalPricing });
      }
      if (props.selectedLoads.reference_number !== this.props.selectedLoads.reference_number) {
        this.setState({ additionalPricing: props.selectedLoads.additionalPricing })
      }
    }
    this.defaultEmailConfig()
  }

  componentWillMount() {
    const props = this.props;
    
    this.setState({ additionalPricing: props.selectedLoads ? props.selectedLoads.additionalPricing : [] })
  }

  componentDidMount = () => {
    if (this.props.addCustomerPriceRows) {
      this.props.addCustomerPriceRows(() => this.AddCustomerPriceRows());
    }
    if (this.props.updateAdditionalPricing) {
      this.props.updateAdditionalPricing(() => this.updateAdditionalPricing());
    }
    this.getSettings();
    this.getUserBasicSetting();
  };

  getUserBasicSetting(){
    getBasicSettings().then(res=>{
      if(res?.updateDateOnInvoice){
        this.setState({updateDateOnInvoice:res?.updateDateOnInvoice})
      }
    })
  }

  handleextraEmailChange(emails) {
    this.setState({ emails });
  }

  handleccEmailChange(cc) {
    this.setState({ cc });
  }

  AddCustomerPriceRows() {
    const _pricingObj = {
      customerId: undefined,
      id: Math.random().toString(16).slice(2),
      pricing: [
        {
          name: "",
          description: "",
          amount: 0,
          unit: 1,
          finalAmount: 0,
          freeDays: 0,
          chargePerDay: 0,
          freeMinutes: 0,
          chargePerMinute: 0,
          manualFinalAmount: 0,
        }
      ],
      billingDate: null,
    };
    const newPricing = this.state.additionalPricing.insert(_pricingObj);
    this.setState({
      additionalPricing: newPricing,
    });
  }

  AddPriceRows(customerId, pIndex) {
    const oldPricing = this.state.additionalPricing;
    const newPricing = [];
    oldPricing.forEach((obj, k) => {
      if (obj.customerId === customerId && k === pIndex) {
        const _pricingObj = {
          name: "",
          description: "",
          amount: 0,
          unit: 1,
          finalAmount: 0,
          freeDays: 0,
          chargePerDay: 0,
          freeMinutes: 0,
          chargePerMinute: 0,
          manualFinalAmount: 0,
        };
        obj.pricing.push(_pricingObj);
        newPricing.push(obj);
      } else {
        newPricing.push(obj);
      }
    });
   
    this.setState((prevState) => {
      let additionalPricing = [...prevState.additionalPricing];
      additionalPricing = newPricing;
      return {
        additionalPricing: additionalPricing,
        sort: undefined,
        asce: true,
        isModified: true
      };
    });
  }

  DeleteCustomerPriceRow(index) {
    const newPricing = this.state.additionalPricing.removeAt(index);
    this.setState((prevState) => {
      let additionalPricing = [...prevState.additionalPricing];
      additionalPricing = newPricing;
      this.updateAdditionalPricing()
      return { additionalPricing: additionalPricing };
    });
    this.setState({
      isEnable: false,
      isModified: true
    });
  }

  DeletePriceRow(k, key) {
    let data = _.cloneDeep(this.state.additionalPricing);
    if (data[k].pricing && data[k].pricing.length) {
      data[k].pricing.splice(key, 1);

      this.setState({
        additionalPricing: data,
        isEnable: false,
        isModified: true
      });
    }
  }

  PriceError(name, index, mainIndex) {
    const old = [...this.state.additionalPricing];
    let formErrors = [...this.state.formErrors];
    if (!formErrors[index]) {
      formErrors[index] = [];
    }
    
  }

  pricingchanges(mainIndex, index, e) {
    const { name, value } = e.target;
    const old = [...this.state.additionalPricing];
    const positiveValuesOnly = [
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[mainIndex].pricing[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      old[mainIndex].pricing[index][name] = value;
    }

    let formErrors = [...this.state.formErrors];
    // formErrors
    if (!formErrors[mainIndex]) {
      formErrors[mainIndex] = [];
    }
    if (
      (name == "unit" || name == "freeDays" || name == "chargePerDay") 
      // old[mainIndex].pricing[index]["finalAmount"] < 0
    ) {
      this.PriceError(name, index, mainIndex);
    }
    if (!formErrors[mainIndex][index]) {
      formErrors[mainIndex][index] = {};
    }
    if (
      (
        name == "unit" ||
        name == "freeDays" ||
        name == "chargePerDay") &&
      parseFloat(value) < 0
    ) {
      formErrors[mainIndex][index][name] = "Only Positive numbers Allowed";
      this.setState({ formErrors });
      return;
    } else {
      formErrors[mainIndex][index][name] = null;
      if (
        (name == "unit" || name == "freeDays" || name == "chargePerDay") &&
        old[mainIndex].pricing[index]["finalAmount"] >= 0
      ) {
        formErrors[mainIndex][index]["finalAmount"] = null;
      }
      this.setState({ formErrors });
    }
    const basePrice = old[mainIndex].pricing.find((obj) => {
      return obj.name === "Base Price";
    });
    if (name === "type") {
      old[mainIndex].pricing[index]["finalAmount"] =
        old[mainIndex].pricing[index]["type"] === "fixed"
          ? old[mainIndex].pricing[index]["amount"]
          : (old[mainIndex].pricing[index]["amount"] *
              (basePrice ? basePrice.finalAmount : 0)) /
            100;
    } else if (name === "amount") {
      old[mainIndex].pricing[index]["finalAmount"] =
        old[mainIndex].pricing[index]["type"] === "fixed"
          ? old[mainIndex].pricing[index]["amount"]
          : (old[mainIndex].pricing[index]["amount"] *
              (basePrice ? basePrice.finalAmount : 0)) /
            100;
      old[mainIndex].pricing[index]["manualFinalAmount"] = old[mainIndex].pricing[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[mainIndex].pricing[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay","perType"].includes(name)) {
      let computedAmount =
        (old[mainIndex].pricing[index].unit -
          old[mainIndex].pricing[index].freeDays) *
        old[mainIndex].pricing[index].chargePerDay;
      old[mainIndex].pricing[index][name] = value;

      if (computedAmount >= old[mainIndex].pricing[index].manualFinalAmount) {
        old[mainIndex].pricing[index]["finalAmount"] =
          computedAmount.toFixed(2);
      } else {
        old[mainIndex].pricing[index]["finalAmount"] =
          parseFloat(old[mainIndex].pricing[index].manualFinalAmount).toFixed(2);
      }

      let basePrice = Number(old[mainIndex].pricing.find(item => item.name === "Base Price")?.finalAmount);
      if((name == "perType" && value =="percentage")
      || (name !== "perType" && old[mainIndex].pricing[index]["perType"] == "percentage") ){
        if (old[mainIndex].pricing[index]["name"] !== 'Fuel') {
          old[mainIndex].pricing[index]["finalAmount"] = (basePrice * old[mainIndex].pricing[index]["unit"] / 100).toFixed(2);
        }
      }
    } 
    this.setState((prevState) => {
      const additionalPricing = [...prevState.additionalPricing];
      additionalPricing[mainIndex].name = value;
      return { additionalPricing: additionalPricing };
    });
    this.setState({
      additionalPricing: [...old],
    });
    if (value) {
      this.setState({
        isEnable: true,
        isModified: true
      });
    }
  }

  updateAdditionalPricing(type, additionalPrice) {
    const additionalPricing = _.cloneDeep(this.state.additionalPricing);
    let formErrors = [...this.state.formErrors];
    let isValid = true;
    if (
      additionalPricing.filter((obj) => {
        return !obj.customerId;
      }).length > 0
    ) {
      toastr.show("You must select a customer!", "warning");
      return;
    }
    if (
      additionalPricing.filter((obj) => {
        return (
          obj.pricing.filter((data) => {
            return !data.name;
          }).length > 0
        );
      }).length > 0
    ) {
      // toastr.show("You must enter a name in the field.", "error");
      return;
    }
    if (additionalPricing.filter((obj) => {return obj.pricing.filter((data) => { return !data.name}).length > 0}).length > 0 ) {
      toastr.show("You must enter a name in the field.", "error");
      return;
    }
    // only save those which are not billing

    if (additionalPricing && additionalPricing.length) {
      additionalPricing.forEach((d, index) => {
        formErrors[index] = formErrors[index] ? formErrors[index] : [];
        const price = d.pricing;
        if (price && price.length) {
          price.forEach((p, i) => {
            formErrors[index][i] = formErrors[index][i]
              ? formErrors[index][i]
              : {};
            if (p.finalAmount) {
              d.pricing[i].finalAmount = parseFloat(p.finalAmount).toFixed(2);
              isValid = isValid && parseFloat(p.finalAmount) >= 0;
            }
            if (p.unit) {
              formErrors[index][i]["unit"] =
                parseFloat(p.unit) < 0 ? "Only Positive numbers Allowed" : null;
              isValid = isValid && parseFloat(p.unit) >= 0;
            }
            if (p.freeDays) {
              formErrors[index][i]["freeDays"] =
                parseFloat(p.freeDays) < 0
                  ? "Only Positive numbers Allowed"
                  : null;
              isValid = isValid && parseFloat(p.freeDays) >= 0;
            }
            if (p.amount) {
              d.pricing[i].amount = parseFloat(p.amount).toFixed(2);
            }
            if(p.description){
              d.pricing[i].description = p?.description.trim();
            }
            if (p.chargePerDay) {
              formErrors[index][i]["chargePerDay"] =
                parseFloat(p.chargePerDay) < 0
                  ? "Only Positive numbers Allowed"
                  : null;
              isValid = isValid && parseFloat(p.chargePerDay) >= 0;
            }
          });
        }
      });
      this.setState({ formErrors });
    }
    // else {
    //   isValid = false;
    // }
    // if (!isValid) {
    //   toastr.show("Please enter all mandatory fields properly.", "warning");
    //   return;
    // }
    this.setState({ isSaving: true });
    let payload = {
      reference_number: this.props.selectedLoads.reference_number,
      additionalPricing:  additionalPricing
    };

    this.props.changeSavingLoadStatus &&
      this.props.changeSavingLoadStatus(true);
    UpdateAdditionalPricing(payload)
      .then((response) => {
        toastr.show("Updated Successfully", "success");
        let load = this.props.selectedLoads;
        let data = response.data.data;
        if (data.length > 0) {
          let errors = [];
          data.map((dd, i) => {
            if (dd.error) {
              errors[i] = _.uniqBy(dd.error, "Code");
            } else {
              if (
                load &&
                load.additionalPricing &&
                load.additionalPricing.length > 0 &&
                dd.data &&
                dd.data.updatedAdditionalPricing
              ) {
                let ddData = dd.data.updatedAdditionalPricing[0]
                  ? dd.data.updatedAdditionalPricing[0]
                  : null;
                if (ddData) {
                  load.additionalPricing = load.additionalPricing.map((ap) => {
                    return ap._id === ddData._id ? ddData : ap;
                  });
                }
              }
              errors[i] = null;
            }
          });
          this.setState({ errors: errors });
        }
        this.setState({ isSaving: false, isModified: false });
        this.props.changeSavingLoadStatus &&
          this.props.changeSavingLoadStatus(false);
           // 39Iz2u&N
           if(type === "invoice" || type === "approve_invoice"){
            let additionalPriceValue = data?.additionalPricing.find((d) => d._id === additionalPrice._id)
            this.defaultEmailConfig(additionalPriceValue.sub_reference_number)
            this.setEmails(additionalPrice.customerId);
            this.setState({selectedCustomer: additionalPrice, sendEmailModal: true})
            this.invoice_create(additionalPrice._id)
          }
      })
      .catch((e) => {
        this.setState({ isSaving: false });
        this.props.changeSavingLoadStatus &&
          this.props.changeSavingLoadStatus(false);
      });
  }
  onFieldChange = (k,key,name) => {
      const noChange = _.isEqual(this.state.additionalPricing[k]["pricing"][key] && this.state.additionalPricing[k]["pricing"][key][name],this.props.prevAdditionalPricing[k] && this.props.prevAdditionalPricing[k]["pricing"][key][name])
      if(noChange) return
      this.updateAdditionalPricing();
  }
  // 39Iz2u&N
  invoice_create(id){
    const payload = {
      reference_number:this.props.selectedLoads.reference_number,
      additionalId: id
    }
    if(JSON.parse(getStorage("carrierDetail"))?.isNewInvoice)
        tmsAction.createInvoiceSub(payload).then(data=>{});
  }

   // 39Iz2u&N
  invoice_billingDate(invoiceId,date){
    if(invoiceId && JSON.parse(getStorage("carrierDetail"))?.isNewInvoice){
      const params = {
        billingDate:date,
        invoiceId:invoiceId
      }
      tmsAction.updateInvoiceV1(params).then(() => {})
    }
  }

  chkchange(e, k, type) {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (
      ["fleetmanager", "carrier"].indexOf(getStorage("currentUserRole")) > -1
    ) {
      const {value} = e.target;
      if (loggedInUser.role === "fleetmanager" && type === "rebill") {
        if(loggedInUser.permissions.includes("rebill_loads")) {
          this.setState(
            (prevState) => {
              const additionalPricing = [...prevState.additionalPricing];
              additionalPricing[k].status = value;
              return { additionalPricing: additionalPricing };
            },
            () => {
              // 39Iz2u&N
              const additionalPrice = this.state.additionalPricing[k]
              this.updateAdditionalPricing(type, additionalPrice);
            }
          );
        } else {
          toastr.show("Please contact your admin to allow the setting in permissions page for you to rebill", "error")
        }
      } else if (loggedInUser.role === "fleetmanager" &&  (type === "invoice" || type === "approve_invoice")) {
          if(loggedInUser.permissions.includes("approve_invoice")) {
            this.setState(
              (prevState) => {
                const additionalPricing = [...prevState.additionalPricing];
                additionalPricing[k].status = value;
                if(value==="REBILLING"){
                  additionalPricing[k].billingDate=moment.tz(getStorage("timeZone")).toISOString()
                }
                if(this.state.updateDateOnInvoice){
                  additionalPricing[k].billingDate=moment.tz(getStorage("timeZone")).toISOString()
                }
                return { additionalPricing: additionalPricing };
              },
              () => {
                // 39Iz2u&N
                const additionalPrice = this.state.additionalPricing[k]
                this.updateAdditionalPricing(type, additionalPrice);
              }
            );
          } else {
            toastr.show("You do not have permission to invoice a bill.  Please contact your admin.", "error")
          }
      }  else {
        this.setState(
          (prevState) => {
            const additionalPricing = [...prevState.additionalPricing];
            additionalPricing[k].status = value;
            if((type === "invoice" || type === "approve_invoice") && this.state.updateDateOnInvoice){
              additionalPricing[k].billingDate=moment.tz(getStorage("timeZone")).toISOString()
            }
            return { additionalPricing: additionalPricing };
          },
          () => {
            // 39Iz2u&N
            const additionalPrice = this.state.additionalPricing[k]
            this.updateAdditionalPricing(type, additionalPrice);
          }
        );
      }
    }
  }

  // 39Iz2u&N
  invoice_create(id) {
    const payload = {
      reference_number: this.props.selectedLoads.reference_number,
      additionalId: id
    }
    if (JSON.parse(getStorage("carrierDetail"))?.isNewInvoice)
      tmsAction.createInvoiceSub(payload).then(data => { });
  }

  // 39Iz2u&N
  invoice_billingDate(invoiceId, date) {
    if (invoiceId && JSON.parse(getStorage("carrierDetail"))?.isNewInvoice) {
      const params = {
        billingDate: date,
        invoiceId: invoiceId
      }
      tmsAction.updateInvoiceV1(params).then(() => { })
    }
  }

  arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }


  downloadInvoice(customer, isDownload) {
    const { selectedLoads } = this.props;
    let progressField = `${customer.customerId._id}_isDownloadInProcess`;
    let formData = new FormData();

    const allReferenceNumbers = [];
    allReferenceNumbers.push(selectedLoads.reference_number);
    formData.append("reference_number", JSON.stringify(allReferenceNumbers));
    if (this.state.emails && this.state.emails.length > 0) {
      formData.append("email", this.state.emails.join(","));
    }
    if (this.state.cc && this.state.cc.length > 0) {
      formData.append("cc", this.state.cc.join(","));
    }
    if (this.state.inpSubject) {
      formData.append("subject", this.state.inpSubject);
    }
    if (this.state.inpBody) {
      formData.append("body", this.state.inpBody);
    }
    formData.append("customerId", customer.customerId._id);
    if (isDownload) {
      this.setState({ [progressField]: true });
      formData.append("isDownload", true);
    }
    formData.append("indexId", JSON.stringify([customer._id]));
    this.setState({ isEmailSendInProcess: true });
    sendMultileDocToSubCustomerEmail(formData)
      .then((result) => {
        this.setState({ isEmailSendInProcess: false, sendEmailModal: false });
        if (isDownload) {
          this.setState({ [progressField]: false });
          this.setState(
            {
              [`${customer.customerId._id}-invoice`]: `data:application/pdf;base64,${this.arrayBufferToBase64(
                result.data.data.data
              )}`,
            },
            () => {
              $(`#${customer.customerId._id}-invoice`)[0].click();
            }
          );
        } else {
          toastr.show("Email Sent", "success");
        }
      })
      .catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ [progressField]: false });
        this.setState({ isEmailSendInProcess: false });
      });
  }
  download_Invoice(customer, isDownload) {
    const { selectedLoads } = this.props;
    let progressField = `${customer.customerId._id}_isDownloadInProcess`;
   
    const allReferenceNumbers = [];
    allReferenceNumbers.push(selectedLoads.reference_number);
    this.setState({ isEmailSendInProcess: true });
    let query = {
      typeOfDocument: SUB_INVOICE,
    };
    if(selectedLoads.reference_number){
     query.reference_number = selectedLoads.reference_number 
    } 
     if (isDownload){this.setState({ [progressField]: true });}
  
      let obj = {isSubCustomer : true, additionalPriceId : customer._id, customerId : customer.customerId._id }
      if(isDownload){
        getSubInvoiceDesign({...obj,...query }).then((result) => {
          this.setState({ isEmailSendInProcess: false, sendEmailModal: false });
            this.setState({ [progressField]: false });
            this.setState(
              {
                [`${customer.customerId._id}-invoice`]: `data:application/pdf;base64,${this.arrayBufferToBase64(
                  result.data[0].data
                )}`,
              },
              () => {
                $(`#${customer.customerId._id}-invoice`)[0].click();
              }
            );
         
        })
        .catch((err) => {
          toastr.show("Something went wrong, Please try again", "error");
          this.setState({ [progressField]: false });
          this.setState({ isEmailSendInProcess: false });
        });
      }else{
        this.setState({ isEmailSendInProcess: true, sendEmailModal: true });
        let formData = {isSubCustomer : true, additionalPriceId : customer._id, customerId : customer.customerId._id};
   
        if (this.state.emails && this.state.emails.length > 0) {
          formData.email= this.state.emails.join(",")
        }
        if (this.state.cc && this.state.cc.length > 0) {
          formData.cc= this.state.cc.join(",")
        }
        if (this.state.inpSubject) {
          formData.subject= this.state.inpSubject
        }
        if (this.state.inpBody) {
          formData.body = this.state.inpBody
        }
         emailInvoice({...formData, ...query})
         .then(res => {
         this.setState({ isEmailSendInProcess: false, sendEmailModal: false ,[progressField]: false});
         toastr.show("Email Sent", "success");})
         .catch(err => {
         console.log("error after emailing invoice", err);
         toastr.show("Email not Sent", "error")
         this.setState({ isEmailSendInProcess: false, sendEmailModal: false, [progressField]: false });
        })
      }
      
     }

  setEmails(customer) {
    const updateState = {};
    const { selectedLoads } = this.props;
    let emails = [];
    let cc = [];
    emails=customer.billingEmail ? customer.billingEmail.split(',').filter(Boolean) : [customer.email]
    if (customer.isEmailNeedToSend) {
      if (selectedLoads) {
        cc = selectedLoads?.carrier?.carrier?.billingEmail ? selectedLoads.carrier.carrier.billingEmail.split(',') : [];
      }
      let ccEmails = customer.extraEmail.split(',').filter(Boolean);
      cc = cc.concat(ccEmails);
    }

    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(this.state.inpBody))
      ),
    });

    updateState.emails = emails.compact(true);
    updateState.cc = cc.compact(true);
    this.setState(updateState);
  }

  getSettings() {
    getSettings()
      .then((result) => {
        const data = result.data.data;
        if (data) {
          this.setState({ settings: data }, () =>this.defaultEmailConfig())
        }
      })
      .catch((error) => {
        console.log("-*-* get settings error ----", error);
      });
  }

  defaultEmailConfig(subRefNumber) {
    const { selectedLoads } = this.props;
    let inpSubject = "",
      inpBody = "";
    inpSubject = this.state.settings &&
      this.state.settings.carrier &&
      this.state.settings.carrier.emailSubject
        ? this.dynamicStringByLoadInfo(
            this.state.settings.carrier.emailSubject,
            selectedLoads
          )
        : `Billing for Load #${subRefNumber ? subRefNumber : selectedLoads.reference_number}`;
    inpBody = this.state.settings?.carrier?.emailBody
      ? this.dynamicStringByLoadInfo(this.state.settings.carrier.emailBody, selectedLoads)
      : "Please review attached billing invoices.";
    this.setState({ inpSubject, inpBody });
  }

  dynamicStringByLoadInfo(str, data) {
    var allData = str.split(" ");
    var pattern = /\@(.*?)\@/g;
    allData.filter((obj) => {
      let vals = obj.match(pattern);
      if (vals) {
        vals.forEach((key) => {
          if (key === "@bolbkg@") {
            str = str.replace(key, data.bolbkg || "");
          } else if (key === "@referenceNo@") {
            str = str.replace(key, data.secondaryReferenceNo || "");
          } else if (key === "@invoiceNo@" || key === "@LoadNo@") {
            str = str.replace(key, data.reference_number || "");
          } else if (key === "@consigneeName@") {
            str = str.replace(key, data.consigneeName || "");
          } else if (key === "@weight@") {
            str = str.replace(key, data.totalWeight || "");
          } else {
            str = str.replace(key, data[key.substr(1, key.length - 2)] || "");
          }
        });
      }
    });
    return str;
  }

  _findCustomer(data) {
    let obj = null;
    if (data.customerId)
      obj = { value: data.customerId._id, label: data.customerId.company_name };

    return obj;
  }

  //   sortTable(sort){
  //     if(this.state.sort =="name" && !this.state.asce){
  //       this.setState({sort:undefined,asce:true})
  // }else{
  //   this.setState({
  //     sort,
  //     asce: this.state.sort !== sort ? true : !this.state.asce,
  //   });
  // }
  //   }

  // sortTable(sort) {
  //   if (this.state.sort == "name" && !this.state.asce) {
  //     this.setState({ sort: undefined, asce: true });
  //   } else {
  //     this.setState({
  //       sort,
  //       asce: this.state.sort !== sort ? true : !this.state.asce,
  //     });
  //   }
  // }
  saveAdditionalPricing(index) {
    let pricing = this.state.additionalPricing[index];
    if (pricing && !pricing.customerId) {
      toastr.show("You must select a customer!", "warning");
      return;
    } else {
      let payload = {
        reference_number: this.props.selectedLoads.reference_number,
        additionalPricing: [pricing],
      };

      if (pricing && pricing.status !== "BILLING") {
        payload.isSave = true;
      }

      UpdateAdditionalPricing(payload).then((response) => {
        toastr.show("Updated Successfully", "success");
        if (this.props.updateList) {
          this.props.updateList(response.data.data);
        } else {
          this.props.tmsAction.updateLoad(response.data.data);
        }
      });
    }
  }

  changeBillingDate = (k, value) => {
    this.setState((prevState) => {
      var additionalPricing = [...prevState.additionalPricing];
      let subInvoiceNumber = additionalPricing[k].subInvoiceNumber
        ? additionalPricing[k].subInvoiceNumber
        : null;
      additionalPricing[k].billingDate = value ? value : undefined;
      if (subInvoiceNumber) {
        additionalPricing = additionalPricing.map((pricing) => {
          if (pricing.subInvoiceNumber == additionalPricing[k].subInvoiceNumber) {
            pricing.billingDate = value ? value : undefined;
          }
          return pricing;
        });
      }
      return { additionalPricing: additionalPricing };
    });
  };

  render() {
    const { formErrors } = this.state;
    var disabled = false;
    const { selectedLoads } = this.props;
    const {chargeCode} = this.props;
    let chargeCodeList = [];
    if(chargeCode && chargeCode.length) {
      chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");
    }
    return (
      <div>
        
        {this.state.additionalPricing.map((obj, k) => {
          let newPricing = obj.pricing;
          let downloadLabel = obj.subInvoiceNumber
            ? obj.subInvoiceNumber
            : obj.sub_reference_number
            ? obj.sub_reference_number
            : this.props.selectedLoads &&
              this.props.selectedLoads.reference_number
            ? this.props.selectedLoads.reference_number
            : "";
          let isTotalAmount = 0;

          // if (this.state.sort) {
          //   newPricing = orderBy(
          //     obj.pricing,
          //     (charge) => charge[this.state.sort].toLowerCase(),
          //     !this.state.asce && "desc"
          //   );
          // } else {
            newPricing = obj.pricing;
            newPricing.forEach(function(obj){
              if(obj["finalAmount"]){
                isTotalAmount = isTotalAmount + parseFloat(obj["finalAmount"]);
              }
            });
          // }

          return (
            <div key={k}>
              <div className="card p-15 mb-1 d-flex flex-row justify-content-between">
                <div className={`d-flex flex-row ${this.state.isSaving || (obj.qbInvoiceId) ? 'disabled-pointer-events':''}`}>
                  <AsyncSelect
                    defaultOptions={makeOptionsForSelect(
                      this.props.customers,
                      "company_name",
                      "address.address",
                      "_id"
                    )}
                    value={this._findCustomer(obj)}
                    onChange={(customer) =>
                      this.setState((prevState) => {
                        const additionalPricing = [
                          ...prevState.additionalPricing,
                        ];
                        additionalPricing[k].customerId = customer.allInfos;
                        this.updateAdditionalPricing()
                        return { additionalPricing: additionalPricing, isModified: true };  
                      })}                    
                    placeholder="Select Sub Customer..."
                    styles={smallSelectStyle}
                    className="w-200 z-4"
                    loadOptions={getTMScallerAsyncSearch}
                  />

                  <button
                    className="btn btn-link text-muted"
                    onClick={() => this.DeleteCustomerPriceRow(k)}
                  >
                    <IconTrash />
                  </button>
                </div>

                <div className="d-flex flex-row">
                  {this.props.selectedLoads.additionalPricing[k] &&
                    ["BILLING"].indexOf(
                      this.props.selectedLoads.additionalPricing[k].status
                    ) > -1 && (
                      <button
                        className="ml-auto btn btn-outline-light mr-2"
                        onClick={() => {
                          this.defaultEmailConfig(obj.sub_reference_number)
                          this.setEmails(obj.customerId);
                          this.setState({
                            selectedCustomer: obj,
                            sendEmailModal: true,
                          });
                        }}
                      >
                        <IconSendEmail className="mr-2" />
                        Email
                      </button>
                    )}

                  <a
                    download={`${downloadLabel}-invoice.pdf`}
                    id={`${
                      obj.customerId ? obj.customerId._id : obj.customerId
                    }-invoice`}
                    href={
                      this.state[
                        `${
                          obj.customerId ? obj.customerId._id : obj.customerId
                        }-invoice`
                      ]
                    }
                  ></a>
                  <button
                    disabled={
                      this.state[
                        `${
                          obj.customerId ? obj.customerId._id : obj.customerId
                        }_isDownloadInProcess`
                      ]
                    }
                    className="ml-auto btn btn-outline-light"
                    onClick={() => JSON.parse(getStorage("carrierDetail")) && JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ? this.download_Invoice(obj, true) : this.downloadInvoice(obj, true)}
                  >
                    <IconDownload className="mr-2" />
                    Download PDF
                  </button>
                </div>
              </div>
              <div className={`table mb-1 ${this.state.isSaving ? 'disabled-pointer-events':''}`}>
                <table className={`table table-card main-cstm-charge ${(obj.status && obj.status === "BILLING") ? 'disabled-pointer-events':''}`} >
                  <thead>
                    <tr>
                      <th width="200">Name of Charge #
                      </th>
                      <th width="300">Description</th>
                      <th width="210">Price</th>
                      <th width="140" className="z-3">Unit Count</th>
                      <th width="150" className="z-3">Unit of Measure</th>
                      <th width="100">Free Units</th>
                      <th width="100">Per Units</th>
                      <th width="42"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {newPricing.map((item, key) => {
                      let chargeValue = chargeCodeList && chargeCodeList.find((d) => d.value === item.name)
                      let charges = chargeCodeList && chargeCodeList.filter((d) => d.isActive)
                      return (
                        <tr key={key}>
                          <td>
                            <CustomSelect
                              disabled={disabled}
                              options={charges && charges.map((charge) => ({label: charge.chargeName, value: charge.value}))}
                              value={chargeValue ? {label: chargeValue.chargeName, value: chargeValue.value} : null}
                              onChange={({ value }) => {
                                this.pricingchanges(k, key, {
                                  target: { value, name: "name" },
                                })
                                this.updateAdditionalPricing()
                              }
                              }
                              size="small"
                              placement="right"
                              placeholder="Select..."
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Description"
                              name="description"
                              value={item.description}
                              onChange={(e) => this.pricingchanges(k, key, e)}
                              onBlur={(e) => this.onFieldChange(k,key, e.target.name)}
                            />
                          </td>
                          {item.name === "Fuel" ? (
                            <td className="lint">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="mr-1 d-flex w-100">
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="amount"
                                    value={item.amount}
                                    onChange={(e) =>
                                      this.pricingchanges(k, key, e)
                                    }
                                    onBlur={(e) => this.onFieldChange(k,key, e.target.name)}
                                    min={0}
                                  />
                                </div>
                                <div className="mr-1 d-flex w-100">
                                  <Select
                                    disabled={disabled}
                                    options={[
                                      { value: "fixed", label: "$" },
                                      { value: "percentage", label: "%" },
                                    ]}
                                    value={
                                      item.type && item.type === "fixed"
                                        ? { value: "fixed", label: "$" }
                                        : {
                                            value: "percentage",
                                            label: "%",
                                          }
                                    }
                                    onChange={({ value }) => {
                                      this.pricingchanges(k, key, {
                                        target: { value, name: "type" },
                                      })
                                      const noChange = _.isEqual(this.state.additionalPricing[k]["pricing"] && this.state.additionalPricing[k]["pricing"][key]["type"],this.props.prevAdditionalPricing[k] && this.props.prevAdditionalPricing[k]["pricing"][key]["type"])
                                      if(noChange) return
                                      this.updateAdditionalPricing()
                                    }}
                                  />
                                </div>
                                <div className="d-flex w-100">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="finalAmount"
                                    value={item.finalAmount}
                                    onChange={(e) =>
                                      this.pricingchanges(k, key, e)
                                    }
                                    onBlur={(e) => this.onFieldChange(k,key, e.target.name)}
                                    min={0}
                                  />
                                  {formErrors[k] &&
                                    formErrors[k][key] &&
                                    formErrors[k][key].finalAmount && (
                                      <small style={{ color: "red" }}>
                                        {formErrors[k] &&
                                          formErrors[k][key] &&
                                          formErrors[k][key].finalAmount}
                                      </small>
                                    )}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                name="finalAmount"
                                value={item.finalAmount}
                                onChange={(e) => this.pricingchanges(k, key, e)}
                                onBlur={(e) => this.onFieldChange(k,key, e.target.name)}
                                min={0}
                              />
                              {formErrors[k] &&
                                formErrors[k][key] &&
                                formErrors[k][key].finalAmount && (
                                  <small style={{ color: "red" }}>
                                    {formErrors[k] &&
                                      formErrors[k][key] &&
                                      formErrors[k][key].finalAmount}
                                  </small>
                                )}
                            </td>
                          )}
                          <td>
                            <PriceInput
                              disabled={disabled}
                              name="unit"
                              value={item.unit}
                              onChange={(val) => {
                                this.pricingchanges(k, key, {
                                  target: {
                                    value: val,
                                    name: "unit",
                                  },
                                });
                              }}
                              onBlur={() => this.onFieldChange(k,key,"unit")}
                              />
                          </td>
                          <td>
                            <CustomSelect
                              options={options}
                              value={
                                item.perType &&
                                options.find((op) => op.value === item.perType)
                              }
                              onChange={({ value }) =>{
                                this.pricingchanges(k, key, {
                                  target: { value, name: "perType" },
                                })
                                const noChange = _.isEqual(this.state.additionalPricing[k]["pricing"] && this.state.additionalPricing[k]["pricing"][key]["perType"],this.props.prevAdditionalPricing[k] && this.props.prevAdditionalPricing[k]["pricing"][key]["perType"])
                                if(noChange) return
                                this.updateAdditionalPricing()
                              }
                              }
                              size="small"
                              placement="right"
                              placeholder="Select..."
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$0"
                              name="freeDays"
                              disabled={!item.perType || disabled || item.perType === "percentage"}
                              value={item.freeDays}
                              onChange={(e) =>
                                item.perType && this.pricingchanges(k, key, e)
                              }
                              onBlur={(e) => this.onFieldChange(k,key, e.target.name)}
                              min={0}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$0"
                              name="chargePerDay"
                              disabled={!item.perType}
                              value={item.chargePerDay}
                              onChange={(e) =>
                                item.perType && this.pricingchanges(k, key, e)
                              }
                              onBlur={(e) => this.onFieldChange(k,key, e.target.name)}
                              min={0}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-xs btn-circle bg-soft-danger"
                              onClick={
                                () => this.DeletePriceRow(k, key)
                              }
                            >
                              <IconMinus className="text-danger" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td><h4 className="m-0">${isTotalAmount.toFixed(2)}</h4></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="card rounded-0 mb-0 p-15">
                  <a
                    className={`text-primary font-weight-500 ${obj.status === "BILLING" ? "disabled-pointer-events" : ""}`}
                    onClick={() => this.AddPriceRows(obj.customerId, k)}
                  >
                    <IconPlus className="mr-2" />
                    Add Charge
                  </a>
                </div>
                <div className="card mt-1 mb-3 p-15">
                 
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row">
                      <div
                        className={`rounded-3 py-2 px-2 mr-1 ${
                          obj.status === "APPROVED"
                            ? "badge-gray-300"
                            : "badge-light"
                        }`}
                      >
                        <div className="form-check">
                          <input
                            type="checkbox"
                            disabled={
                              (obj.status !== "COMPLETED") &&
                              (!["PARTIAL_PAID","FULL_PAID"].includes(obj.status)) &&
                              (!this.state.isEnable || obj.status === "APPROVED") &&
                              (obj.status !== "UNAPPROVED") ||
                              (["BILLING","REBILLING"].includes(obj.status))
                            }
                            value="APPROVED"
                            name={`status_` + k}
                            className="form-check-input form-check-input--radio-check"
                            id={`chkApproved_${obj._id + "_" + k}`}
                            checked={
                              obj.status === "APPROVED"
                            }
                            onChange={(e) => this.chkchange(e, k)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`chkApproved_${obj._id + "_" + k}`}
                          >
                            Approved
                          </label>
                        </div>
                      </div>
                      <div
                        className={`rounded-3 py-2 px-2 mr-1 ${
                          obj.status === "UNAPPROVED"
                            ? "badge-gray-300"
                            : "badge-light"
                        }`}
                      >
                        <div className="form-check">
                          <input
                            type="checkbox"
                            disabled={
                              (!["PARTIAL_PAID","FULL_PAID"].includes(obj.status)) &&
                              (!this.state.isEnable || obj.status === "UNAPPROVED") &&
                              (obj.status !== "REBILLING" || !obj.status) &&
                              (obj.status !== "APPROVED") ||
                              (["BILLING"].includes(obj.status))
                            }
                            value="UNAPPROVED"
                            name={`status_` + k}
                            className="form-check-input form-check-input--radio-check"
                            id={`chkUnapproved_${obj._id + "_" + k}`}
                            checked={obj.status === "UNAPPROVED"}
                            onChange={(e) => this.chkchange(e, k)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`chkUnapproved_${obj._id + "_" + k}`}
                          >
                            Unapproved
                          </label>
                        </div>
                      </div>
                      <div
                        className={`rounded-3 py-2 px-2 mr-1 ${
                          obj.status === "REBILLING"
                            ? "badge-gray-300"
                            : "badge-light"
                        }`}
                      >
                        <div className="form-check">
                          <input
                            type="checkbox"
                            disabled={
                              (!["PARTIAL_PAID","FULL_PAID"].includes(obj.status)) &&
                              (!this.state.isEnable || obj.status === "REBILLING") && 
                              (obj.status !== "BILLING") || 
                              (["APPROVED","UNAPPROVED"].includes(obj.status))||
                              !obj.status
                            }
                            value="REBILLING"
                            name={`status_` + k}
                            className="form-check-input form-check-input--radio-check"
                            id={`chkRebill_${obj._id + "_" + k}`}
                            checked={obj.status === "REBILLING"}
                            onChange={(e) => this.chkchange(e, k, "rebill")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`chkRebill_${obj._id + "_" + k}`}
                          >
                            Credit/Rebill
                          </label>
                        </div>
                      </div>

                      <div
                        className={`rounded-3 py-2 px-2 mr-1 ${
                          obj.status === "BILLING"
                            ? "badge-gray-300"
                            : "badge-light"
                        }`}
                      >
                        <div className="form-check">
                          <input
                            disabled={
                              this.state.isEnable &&
                              (["APPROVED","BILLING"].includes(obj.status)) &&
                              (!obj.hasOwnProperty('status') || !obj.status) ||
                              (obj.status === "UNAPPROVED") ||
                              (["BILLING"].includes(obj.status)) || 
                              !obj.status
                            }
                            type="checkbox"
                            value="BILLING"
                            name={`status_` + k}
                            className="form-check-input"
                            id={`chkBilling_${obj._id + "_" + k}`}
                            checked={["BILLING"].includes(obj.status)}
                            onChange={(e) => this.chkchange(e, k, "invoice")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`chkBilling_${obj._id + "_" + k}`}
                          >
                            Invoice
                          </label>
                        </div>
                      </div>
                      <div
                        className={`rounded-3 py-2 px-2 mr-1 ${
                          obj.status === "BILLING"
                            ? "badge-gray-300"
                            : "badge-light"
                        }`}
                      >
                        <div className="form-check">
                          <input
                            type="checkbox"
                            disabled={
                              this.state.isEnable &&
                              (["APPROVED","BILLING"].includes(obj.status)) &&
                              (!obj.hasOwnProperty('status') || !obj.status) ||
                              (obj.status !== "UNAPPROVED") ||
                              (["BILLING"].includes(obj.status))
                            }
                            value="APPROVE_AND_BILLING"
                            name={`status_` + k}
                            className="form-check-input"
                            id={`chkApprove_Billing_${obj._id + "_" + k}`}
                            checked={["BILLING"].includes(obj.status)}
                            onChange={(e) => this.chkchange(e, k, "approve_invoice")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`chkApprove_Billing_${obj._id + "_" + k}`}
                          >
                            Approve and Invoice
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* {obj.billingDate && ( */}
                    <div className="d-flex align-items-center">
                      {obj.sub_reference_number && (
                        <div className="d-flex align-items-center mr-3">
                          Sub Reference No.
                          <span className="badge badge-light">{obj.sub_reference_number}</span>
                        </div>
                      )}
                      {obj.subInvoiceNumber && (
                        <div className="d-flex align-items-center mr-3">
                          Combined Invoice No.
                          <span className="badge badge-light">{obj.subInvoiceNumber}</span>
                        </div>
                      )}
                      <span className="text-dark font-weight-500 mr-2">
                        Sub Billing Date
                      </span>
                      <div className="input-wrapper">
                        <Datetime
                          inputProps={{ disabled }}
                          dateFormat="MM-DD-YYYY"
                          displayTimeZone={getStorage("timeZone")}
                          onChange={(e) =>
                            this.changeBillingDate(k, moment(e).toISOString())
                          }
                          value={
                            obj.billingDate
                              ? moment(obj.billingDate)
                              : "Billing Date & Time"
                          }
                          timeFormat={false}
                          onBlur={(e)=>this.invoice_billingDate(obj.invoiceId,moment(e).toISOString())}
                        />
                        <div
                          className="input-icon cursor-inherit"
                        >
                          <IconCalendar />
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <Modal show={this.state.sendEmailModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label className="col-form-label">To</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.emails.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };

                      return val;
                    })}
                    onChange={(val) => {
                      this.handleextraEmailChange(val.map((i) => i.value));
                    }}
                  />

                  <span className="text-gray-400 d-block mt-1">
                    You can add multiple email addresses
                  </span>
                </div>
                <div className="form-group">
                  <label className="col-form-label">CC/BCC</label>
                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.cc.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };

                      return val;
                    })}
                    onChange={(val) => {
                      this.handleccEmailChange(val.map((i) => i.value));
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    value={this.state.inpSubject}
                    onChange={(e) => {
                      this.setState({ inpSubject: e.target.value });
                    }}
                  />
                </div>
                <div className="editor">
                  <label className="col-form-label">Message</label>
                  <Editor
                    wrapperClassName="demo-wrapper"
                    ref="draftRef"
                    onEditorStateChange={(editorState) => {
                      this.setState({
                        editorState,
                        inpBody: draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        ),
                      });
                    }}
                    handlePastedText={() => false}
                    editorState={this.state.editorState}
                    editorClassName="editorClassName"
                    placeholder="Enter Messsage"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => this.setState({ sendEmailModal: false })}
            >
              Cancel
            </button>
            <button
              disabled={this.state.isEmailSendInProcess}
              className="btn btn-primary"
              onClick={() =>(
                JSON.parse(getStorage("carrierDetail")) && JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign) ?
                this.download_Invoice(this.state.selectedCustomer, false) : 
                this.downloadInvoice(this.state.selectedCustomer, false)
              }
            >
              {this.state.isEmailSendInProcess ? (
                <i className="uil uil-cloud-download mr-1"></i>
              ) : (
                ""
              )}
              Send Email
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const states = (state) => ({
  customers: state.TmsReducer.customers,
  chargeCode: state.chargeCodeReducer.chargeCode
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch: dispatch,
    tmsAction: bindActionCreators(tmsAction, dispatch),
  };
}

export default connect(states, mapDispatchToProps)(AdditionalPricing);

const options = [
  { value: "perday", label: "perday" },
  { value: "perhour", label: "perhour" },
  { value: "permile", label: `per${convertUnit().toLowerCase()}` },
  {
    value: "perpound",
    label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
  },
  { value: "fixed", label: "fixed" },
  { value: "percentage", label: "percentage" },
];
