import React, { useState, useEffect } from "react";
import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";

import Select from "react-select";
import { getPricingSettingsOptions, toastr } from "../../../../services/Common.services";

import * as actionCreators from "../actionCreators";
const accessorial = {
  accessorialType: "",
  unit: "",
  unitPrice: 0,
  pull: 0,
  pullShuttle: 0,
  delivery: 0,
  deliveryShuttle: 0,
  return: 0,
};

const initialValues = {
  accessorials: [accessorial],
};

const unitOpts = [
  {
    label: "Flat %",
    value: "flat",
  },
  {
    label: "Flat Charge",
    value: "flat_charge",
  },
  {
    label: "Miles",
    value: "miles",
  },
  {
    label: "Pound",
    value: "pound",
  },
];

const pricingSettingsOptions=getPricingSettingsOptions();

const Accessorials = (props) => {
  const [initFormVal, setInitFormVal] = useState(initialValues);

  useEffect(() => {
    if (
      props.driverProfile &&
      props.driverProfile.driver &&
      props.driverProfile.driver.accessorials
    ) {
      let initvalues = {
        _id: props.driverProfile.driver._id,
        accessorials: props.driverProfile.driver.accessorials,
      };

      setInitFormVal(initvalues);
    }
  }, []);

  const onSaveFormVals = (values, actions) => {
    values._id = props.driverProfile._id;
    // values.accessorials = values.accessorials.map((item) => {
    //   delete item._id;
    //   return item;
    // });
    props.onSaveAccessorial(values);
  };

  const getDefaultVal = (val, opts) => {
    let value = opts.find((opt) => opt.value === val);
    console.log(value);
    return value;
  };
  return (
    <React.Fragment>
      <Modal.Header>
        <h4 className="modal-title" id="myModalLabel">
          Accessorials
        </h4>
        <button type="button" className="close" onClick={() => props.onClose()}>
          <i className="uil uil-times"></i>
        </button>
      </Modal.Header>
      <div className="sign-up-form">
        <Formik
          enableReinitialize
          initialValues={initFormVal}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSaveFormVals(values, actions);
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => (
            <Form>
              <div>
                <Modal.Body>
                  <FieldArray name="accessorials">
                    {({ insert, remove, push }) => (
                      <div>
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th width="20%">Accessorial Type</th>
                              <th width="10%">Unit</th>
                              <th width="10%">Unit Price</th>
                              <th width="10%">Pull</th>
                              <th width="10%">Pull Shuttle</th>
                              <th width="10%">Delivery</th>
                              <th width="10%">Delivery Shuttle</th>
                              <th width="10%">Return</th>
                              <th width="10%" className="text-center">
                                <button
                                  type="button"
                                  onClick={() => push(accessorial)}
                                  className="btn btn-none text-success"
                                >
                                  <i className="uil uil-plus-circle font-18" />
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.accessorials.length > 0 &&
                              values.accessorials.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <Select
                                      name={`accessorials.${index}.accessorialType`}
                                      placeholder="Accessorial Type"
                                      options={pricingSettingsOptions}
                                      value={
                                        item.accessorialType &&
                                        pricingSettingsOptions.find(
                                          (opt) =>
                                            opt.value === item.accessorialType
                                        )
                                      }
                                      onChange={(option) => {
                                        setFieldValue(
                                          `accessorials.${index}.accessorialType`,
                                          option.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      className={`${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index]
                                          .accessorialType &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index]
                                          .accessorialType &&
                                        "is-invalid"
                                      }`}
                                    />
                                    {errors &&
                                      errors.accessorials &&
                                      errors.accessorials[index] &&
                                      errors.accessorials[index]
                                        .accessorialType &&
                                      touched &&
                                      touched.accessorials &&
                                      touched.accessorials[index] &&
                                      touched.accessorials[index]
                                        .accessorialType && (
                                        <small style={{ color: "red" }}>
                                          {
                                            errors.accessorials[index]
                                              .accessorialType
                                          }
                                        </small>
                                      )}
                                  </td>
                                  <td>
                                    <Select
                                      name={`accessorials.${index}.unit`}
                                      placeholder="Unit"
                                      options={unitOpts}
                                      value={
                                        item.unit &&
                                        unitOpts.find(
                                          (opt) => opt.value === item.unit
                                        )
                                      }
                                      onChange={(option) => {
                                        setFieldValue(
                                          `accessorials.${index}.unit`,
                                          option.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    {errors &&
                                      errors.accessorials &&
                                      errors.accessorials[index] &&
                                      errors.accessorials[index].unit &&
                                      touched &&
                                      touched.accessorials &&
                                      touched.accessorials[index] &&
                                      touched.accessorials[index].unit && (
                                        <small style={{ color: "red" }}>
                                          {errors.accessorials[index].unit}
                                        </small>
                                      )}
                                  </td>
                                  <td>
                                    <Field
                                      name={`accessorials.${index}.unitPrice`}
                                      placeholder="Unit Price"
                                      type="number"
                                      className={`form-control ${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index].unitPrice &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index].unitPrice &&
                                        "is-invalid"
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`accessorials.${index}.unitPrice`}
                                      component="small"
                                      className="invalid-feedback"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`accessorials.${index}.pull`}
                                      placeholder="Pull"
                                      type="number"
                                      className={`form-control ${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index].pull &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index].pull &&
                                        "is-invalid"
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`accessorials.${index}.pull`}
                                      component="small"
                                      className="invalid-feedback"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`accessorials.${index}.pullShuttle`}
                                      placeholder="Pull Shuttle"
                                      type="number"
                                      className={`form-control ${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index]
                                          .pullShuttle &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index]
                                          .pullShuttle &&
                                        "is-invalid"
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`accessorials.${index}.pullShuttle`}
                                      component="small"
                                      className="invalid-feedback"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`accessorials.${index}.delivery`}
                                      placeholder="Delivery"
                                      type="number"
                                      className={`form-control ${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index].delivery &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index].delivery &&
                                        "is-invalid"
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`accessorials.${index}.delivery`}
                                      component="small"
                                      className="invalid-feedback"
                                    />
                                  </td>

                                  <td>
                                    <Field
                                      name={`accessorials.${index}.deliveryShuttle`}
                                      placeholder="Delivery Shuttle"
                                      type="number"
                                      className={`form-control ${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index]
                                          .deliveryShuttle &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index]
                                          .deliveryShuttle &&
                                        "is-invalid"
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`accessorials.${index}.deliveryShuttle`}
                                      component="small"
                                      className="invalid-feedback"
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`accessorials.${index}.return`}
                                      placeholder="Return"
                                      type="number"
                                      className={`form-control ${
                                        errors &&
                                        errors.accessorials &&
                                        errors.accessorials[index] &&
                                        errors.accessorials[index].return &&
                                        touched &&
                                        touched.accessorials &&
                                        touched.accessorials[index] &&
                                        touched.accessorials[index].return &&
                                        "is-invalid"
                                      }`}
                                    />
                                    <ErrorMessage
                                      name={`accessorials.${index}.return`}
                                      component="small"
                                      className="invalid-feedback"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      className="btn btn-none text-danger"
                                    >
                                      <i className="uil uil-trash-alt font-18" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </FieldArray>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" className="btn btn-close" onClick={() => {props.onClose();}}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary" 
                    // disabled={isSubmitting} 
                  >
                    {isSubmitting ? <i className="uil uil-spinner-alt">Saving</i> : ''}
                    Save
                  </button>
                </Modal.Footer>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) };
}

export default connect(null, mapDispatchToProps)(Accessorials);

export const validationSchema = yup.object().shape({
  accessorials: yup
    .array()
    .of(
      yup.object().shape({
        accessorialType: yup
          .string()
          .nullable()
          .required("Accessorial Type is Required"),
        unit: yup.string().nullable().required("Unit is Required"),
      })
    )
    .min(1, "Need at least a Connection"),
});
