export const DRIVER_PERMISSIONS = {
    DISPATCH: {
        "ALLOW_DRIVER_TO_REJECT_LOADS" : "Allow driver to reject loads",
        "NEVER_REQUIRE_TIR_IN_FOR_THIS_DRIVER" : "Never require TIR IN for this driver",
        "NEVER_REQUIRE_TIR_OUT_FOR_THIS_DRIVER" : "Never require TIR OUT for this driver",
        "NEVER_REQUIRE_PROOF_OF_DELIVERY_FOR_THIS_DRIVER" : "Never require POD for this driver",
        "NEVER_REQUIRE_PROOF_OF_DELIVERY_SIGNATURE_FOR_THIS_DRIVER" : "Never require POD signature for this driver",
        "NEVER_REQUIRE_CHASSIS_#_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require chassis # to be entered for this driver",
        "NEVER_REQUIRE_TRAILER_NUMBER_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require trailer number to be entered for this driver",
        "NEVER_REQUIRE_CONTAINER_#_TO_BE_ENTERED_FOR_THIS_DRIVER" : "Never require container # to be entered for this driver",
        "ALLOW_DRIVER_TO_EDIT_CONTAINER_NUMBER" :"Allow driver to edit container number"
    },
    OTHER: { 
        "ALLOW_CHAT_FOR_DRIVER" : "Allow chat for driver",
        "ALLOW_GROUP_CHAT_FOR_DRIVER" : "Allow group chat for driver",
        "NEVER_SHOW_DRIVER_ACCESS_TO_SETTLEMENT_TAB" : "Never show driver access to settlement tab",
        "NEVER_SHOW_DRIVER_PAY_TO_THIS_DRIVER" : "Never show driver pay to this driver",
        "NEVER_SHOW_THE_COMMODITY_ON_MOBILE_APP_TO_THIS_DRIVER" : "Never show the commodity on mobile app to this driver",
        "REQUIRE_DRIVER_TO_BE_CLOCKED_IN_TO_ACCEPT_AND_START_A_LOAD" : "Require driver to be clocked in to accept and start a load",
        "REQUIRE_DRIVER_TO_CLOCK_INTO_YOUR_GEOFENCED_LOCATION_FIRST" : "Require driver to clock into your geofenced location first",
        "ALLOW_DRIVER_TO_ASSIGN_THEMSELVES_TO_TRUCK": "Allow driver to assign themselves to truck",
    },
    PROFILE: { 
        "ALLOW_DRIVER_TO_UPDATE_TRUCK_INFORMATION_IN_PORT_PRO" : "Allow driver to update truck information in port pro",
        "ALLOW_DRIVER_TO_UPDATE_CHASSIS_INFORMATION_IN_PORT_PRO" : "Allow driver to update chassis information in port pro",
        "ALLOW_DRIVER_TO_UPDATE_TRAILER_INFORMATION_IN_PORT_PRO" : "Allow driver to update trailer information in port pro",
        "ALLOW_DRIVER_TO_UPDATE_DRIVER_DOCS_AND_EXPIRATIONS_IN_PORT_PRO" : "Allow driver to update driver docs and expirations in port pro (twic, medical, cdl, sealink)"
    }
}
