import { getStorage } from '../../../../services/Common.services';
import { HTTP } from '../../../../services/Http.service';

export function getLoads(params) {
  let url = 'tms/getLoads';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function makeLoadReadyForPickup(params) {
  let url = 'tms/makeLoadReadyForPickup';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function makeLoadHot(params) {
  let url = 'tms/makeLoadHot';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSCombinedLoad(params) {
  let url = 'tms/getTMSCombinedLoad';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function deleteQbInvoices(data) {
  let url = 'tms/deleteQbInvoices';
  return new Promise((resolve, reject) => {
    HTTP("post", url, data, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getExportedInvoice(data) {
  let url = 'export-qbo-invoices';
  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if(result?.data?.data?.csvData){
          resolve(result?.data?.data?.csvData);
        } else {
          reject('Something went wrong.')
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function createCombinedInvoiceForMain(params){
  const url = 'v1/invoice/create/combinemain';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}//39Iz2u&N
