import React, { useEffect, useState } from 'react'
import { Link } from 'react-router'
import { IconWarningCircle } from '../Icons';
function TimeZoneAlert() {
  const [showElement, setShowElement] = React.useState(false);

  
  useEffect(() => {
    setInterval(() => {
      const TimeZone = localStorage.getItem("timeZone")
      const currentUserRole = localStorage.getItem("currentUserRole")
      if (currentUserRole !== "admin" && (!TimeZone || TimeZone === "null" || TimeZone === "undefined")) {
        setShowElement(true);
      } else {
        setShowElement(false);
      }
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      {showElement ? (
        <div className="position-fixed d-inline-block text-center alert-msg-top ">
          <div class="alert alert-danger d-inline-block font-14 ounded-lg" role="alert">
            <IconWarningCircle /> Your admin must set a Time Zone in Settings before continuing <Link className="text-primary" to="/settings/general-info">Click here to set timezone</Link>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default TimeZoneAlert