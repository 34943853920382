import React, { useEffect, useState } from "react";
import moment from "moment";
import { getStorage, toastr } from "../../../services";
import { changeEmptyOrigin } from "../NewDispatcher/DispatcherTable/actionCreator";
import { connect } from "react-redux";


const RecommendedReturnTerminals = ({
  appSettings, trackosSynced,
  row,
  allEmpty,
  gateData,
  isLoadInfo = false,
  standard_ports
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isCheckedPort, setIsCheckedPort] = useState(null);
  const [recomendePorts, setRecomendePorts] = useState([]);
  const [wrongRetun, setwrongRetun] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [showReccomendation, setShowReccomendation] = useState(false);
  // console.log(all)
  useEffect(() => {
    if (appSettings.includes('EMPTY_RETURN_LOCATIONS') && trackosSynced && isRightPort()) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [row]);

  useEffect(() => {
    isEnabled && getEmptyOrigin();
  }, [allEmpty, row, isEnabled]);


  useEffect(() => {
    if (isEnabled) {
      setShowReccomendation(recomendePorts.length > 0);
      wrongReturnPort();
    }
  }, [recomendePorts]);


  const isRightPort = () => {
    let rightPort = false
    if (row?.shipperName && standard_ports && Object.keys(standard_ports).length > 0) {
      rightPort = Object.values(standard_ports).find(port => {
        return port.includes(row.shipperName)
      })
    }
    return rightPort;
  }

  // set All return Ports
  const getEmptyOrigin = () => {
    let allRecomendePorts = [];
    let sizeTypes, containerOwnerName;
    if (row.containerSizeName || row.containerTypeName) {
      sizeTypes =
        (row.containerSizeName && row.containerSizeName.replace(/\D/g, "")) +
        " " +
        (row.containerTypeName && row.containerTypeName);
    }
    if (row.containerOwnerName) {
      containerOwnerName = row.containerOwnerName;
    }
    allEmpty?.map((emp) => {
      if (emp && emp.length > 0) {

        let found = emp.find(
          (dd) =>
            dd.ssl
              ?.map((ss) => ss.toUpperCase())
              ?.includes(containerOwnerName?.toUpperCase()) &&
            dd.types == sizeTypes &&
            (["OPEN", "DUAL"].includes(dd.shift1) ||
              ["OPEN", "DUAL"].includes(dd.shift2) ||
              ["OPEN", "DUAL"].includes(dd.shift3))
        );

        if (found) {
          if (gateData?.length > 0) {
            let isOpen = shiftOpenNow(gateData, found);
            isOpen == "OPEN" && allRecomendePorts.push({ ...found });
          } else {
            allRecomendePorts.push({ ...found });
          }
        }
      }
    });
    setRecomendePorts(allRecomendePorts);
  };

  //set Wrong return port
  const wrongReturnPort = () => {
    setwrongRetun(null);
    if (row.isSelectedFromRecommendation && row.emptyOriginName) {
      let found = recomendePorts?.find((ff) =>
        ff?.portMapper?.includes(row.emptyOriginName)
      );
      if (!found) {
        let emptyPort = {
          port: row.emptyOriginName,
          isSelected: true,
        };
        setIsCheckedPort(row.emptyOriginName);
        setwrongRetun(emptyPort);
        setShowReccomendation(!!emptyPort.port);
      }
    }
  };

  const selectReturnLocation = (empties, row) => {
    const {
      reference_number,
      containerOwnerName,
      containerSizeName,
      containerTypeName,
      shipperName,
      returnFromTime,
    } = row;
    const params = {
      emptyOriginName: empties.port,
      containerInfo: {
        containerOwner: containerOwnerName,
        containerSize: containerSizeName,
        containerType: containerTypeName,
        portName: shipperName,
      },
      reference_number: reference_number,
      returnFromTimeExist: returnFromTime ? true : false,
    };
    setIsSaving(empties.port + reference_number);
    changeEmptyOrigin(params)
      .then((response) => {
        setIsSaving(false);
        if (response?.data?.data?.success) {
          toastr.show("Empty Origin Updated Successfully", "success");
        } else {
          setIsCheckedPort(null);
          toastr.show(
            response?.data?.data?.message
              ? response.data.data.message
              : "Empty Origin could not be Updated Successfully",
            "error"
          );
        }
      })
      .catch((err) => setIsSaving(false));
  };

  const checkifAlreadyAdded = (shipperName,emptyOrigin, emptiesData) => {
    let checkifExists = false;
    let portName = Object.values(standard_ports)?.find(port => {
      return port?.includes(shipperName)
    })


    if (Array.isArray(portName) && portName.length > 0) {
      portName = portName.map(i => i.toLowerCase() && i?.replace("_", " "))
      if (portName.includes(emptiesData.port?.replace("_", " ")?.toLowerCase()) && emptyOrigin && emptyOrigin._id) checkifExists = true
    }
    return checkifExists

  }
console.log(wrongRetun,'wrongRetun')
  return (
    <>
      {(isEnabled && showReccomendation)  && (
        <>
          {isLoadInfo && (
            <div className="font-14 text-primary font-weight-500 mt-10">
              Recommended Return Terminals
            </div>
          )}
          <div className="recommendation-field-wrapper d-flex h-100">
            {wrongRetun && (
              <div className="recommendation-field warning">
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={isCheckedPort == wrongRetun.port}
                    name={"location" + row.reference_number}
                    value={wrongRetun.port}
                    id={wrongRetun.port + row.reference_number}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor={wrongRetun.port + row.reference_number}
                  >
                    {wrongRetun.port}
                  </label>
                </div>
              </div>
            )}
            {recomendePorts.map((empties, i) => {
              return (
                <div key={i} className="recommendation-field" htmlFor={empties.port + row.reference_number} >
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id={empties.port + row.reference_number}
                      onChange={(e) => {
                        selectReturnLocation(empties, row);
                        setIsCheckedPort(e.target.value);
                      }}
                      value={empties.port}
                      checked={checkifAlreadyAdded(row.emptyOriginName,row.emptyOrigin, empties)}
                      name={"location" + row.reference_number}
                    // disabled={hasAptDate()}
                    />
                    <label
                      key={empties.port}
                      className="form-check-label"
                      htmlFor={empties.port + row.reference_number}
                    >
                      {empties.port.replace("_", " ")}
                      {isSaving == empties.port + row.reference_number && (
                        <span
                          className="spinner-border spinner-border-sm ml-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const states = (state) => ({
  allEmpty: state.TmsReducer.allEmpty,
  gateData: state.TmsReducer.gateData,
  prev_allEmpty: state.TmsReducer.prev_allEmpty,
  appSettings: state.TmsReducer.appSettings,
  trackosSynced: state.TmsReducer.trackosSynced,
  standard_ports: state.TmsReducer.standard_ports,
});

export default connect(states)(RecommendedReturnTerminals);

const shiftOpenNow = (gateData, empty) => {
  let timeZone = getStorage('timeZone')
  let shift = "CLOSE";
  if (empty.port && gateData?.length > 0) {
    gateData.forEach(dd => {
      if (dd.key == empty.port && !dd.data.caution) {
        let foundGateHr = dd?.data?.gateHours?.find(gh => gh.date == moment.tz(timeZone).format("MMMM DD, yyyy"))
        let timenow = moment().tz(timeZone);
        let shiftindex = 3;  //upto s shift
        for (let index = 1; index <= shiftindex; index++) {
          if (foundGateHr && foundGateHr['shift' + index]) {
            let beforeTime, afterTime;
            foundGateHr['shift' + index] = foundGateHr['shift' + index].replace(" ", "");
            // before time  if 3rd shift, add a day 
            beforeTime = index == 3 ? moment(foundGateHr['shift' + index].split('-')[0], "hh:mm").tz(timeZone).add(1, 'days') : moment(foundGateHr['shift' + index].split('-')[1], "hh:mm").tz(timeZone);
            // after time  if 2nd or 3rd shift, add a day 
            afterTime = index == 1 ? moment(foundGateHr['shift' + index].split('-')[1], "hh:mm").tz(timeZone) : moment(foundGateHr['shift' + index].split('-')[1], "hh:mm").tz(timeZone).add(1, 'days');
            if (timenow.isBetween(beforeTime, afterTime)) {
              shift = ["OPEN", "DUAL"].includes(empty['shift' + index]) && foundGateHr['status' + index] == 'OPEN' ? 'OPEN' : 'CLOSE';
            }
          }
        }
      }
    })
  }
  return shift;
}

