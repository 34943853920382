import {
  asyncActionNames,
  buildAsyncActions,
} from "../../../Components/Common/GlobalActionCreators";
import { getStorage, toastr } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";

const actionNames = asyncActionNames("GET_PROFILE");
const actionCreators = buildAsyncActions(actionNames);

export function getProfile() {
  const url = "getUser";
  return function (dispatch) {
    // we can dispatch progress
    HTTP("get", url, null, {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1OTAyNTVlZGUxOTM3YzBiM2Y2YTNhNmQiLCJpYXQiOjE1MDA5Njg3OTl9.wD_abt21gNhLQ44V6gmKFW38e4D87SH1Ejeszi6CmFs",
    })
      .then((result) => {
        dispatch(actionCreators.success(result.data.data));
      })
      .catch((error) => {
        dispatch(actionCreators.failure(error));
      });
  };
}
export function updateCarrierProfile(profile) {
  const url = "carrier/updateCarrier";
  const formData = new FormData();
  if (profile.name) formData.append("name", profile.name);
  if (profile.lastName) formData.append("lastName", profile.lastName);
  if (profile.company_name)
    formData.append("company_name", profile.company_name);
  if (profile.MCNumber) formData.append("MCNumber", profile.MCNumber);
  if (profile.mobile) formData.append("mobile", profile.mobile);
  if (profile.portMarket) formData.append("portMarket", profile.portMarket);
  if (profile.homeTerminalTimezone)
    formData.append("homeTerminalTimezone", profile.homeTerminalTimezone);
  if (profile.distanceMeasure)
    formData.append("distanceMeasure", profile.distanceMeasure);
  if (profile.weightMeasure)
    formData.append("weightMeasure", profile.weightMeasure);
  if (profile.logIncrements)
    formData.append("logIncrements", profile.logIncrements);
  if (profile.primaryCycle)
    formData.append("primaryCycle", profile.primaryCycle);
  if (profile.odometer) formData.append("odometer", profile.odometer);
  if (profile.fleet_allowEditAccount)
    formData.append("fleet_allowEditAccount", profile.fleet_allowEditAccount);
  if (profile.fleet_allowMessaging)
    formData.append("fleet_allowMessaging", profile.fleet_allowMessaging);
  if (profile.fleet_requireLocation)
    formData.append("fleet_requireLocation", profile.fleet_requireLocation);
  if (profile.USDOTNumber) formData.append("USDOTNumber", profile.USDOTNumber);
    formData.append("billingEmail", profile.billingEmail);
  profile.isCommodityProfileShow != undefined &&
    formData.append("isCommodityProfileShow", profile.isCommodityProfileShow);
  if (profile.geofence) {
    formData.append("geofence", profile.geofence);
  }

  if (profile.certificateOfInsurance) {
    formData.append("certificateOfInsurance", profile.certificateOfInsurance);
  }
  if (profile.w9) {
    formData.append("w9", profile.w9);
  }
  if (profile.authorityLetter) {
    formData.append("authorityLetter", profile.authorityLetter);
  }
  if (profile.defaultAddress || profile.defaultAddress == "") {
    formData.append("defaultAddress", profile.defaultAddress);
  }
  if (profile.emailFrom) {
    formData.append("emailFrom", profile.emailFrom);
  }
  if (profile.emailSubject) {
    formData.append("emailSubject", profile.emailSubject || "");
  }
  if (profile.emailBody) {
    formData.append("emailBody", profile.emailBody ||  "" );
  }

  if (profile.terminalDefaultDrop)
    formData.append(
      "terminalDefaultDrop",
      JSON.stringify(profile.terminalDefaultDrop)
    );
    if (profile.address) {
      formData.append("address", JSON.stringify(profile.address));
    }
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      // we can dispatch progress
      HTTP("put", url, formData, {
        Authorization: getStorage("token"),
        "Content-Type": "multipart/form-data;boundary=${formData._boundary}",
      })
        .then((result) => {
          dispatch(actionCreators.success(result.data.data));
          toastr.show("Updated Successfully.", "success");
          resolve(result);
        })
        .catch((error) => {
          dispatch(actionCreators.failure(error));
          // toastr.show(error.message, "error");
          resolve(true);
        });
    });
  };
}

export function updateEquipmentParameter(profile) {
  console.log(profile);
  console.log(profile.equipmentParameters);
  const url = "carrier/updateEquipmentParameter";
  const formData = new FormData();
  formData.append(
    "equipmentParameters",
    JSON.stringify(profile.equipmentParameters)
  );

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      // we can dispatch progress
      HTTP("put", url, formData, {
        Authorization: getStorage("token"),
        "Content-Type": "multipart/form-data;boundary=${formData._boundary}",
      })
        .then((result) => {
          dispatch(actionCreators.success(result.data.data));
          // toastr.show("Updated Successfully", "success");
          resolve(true);
        })
        .catch((error) => {
          dispatch(actionCreators.failure(error));
          toastr.show(error.message, "error");
          resolve(true);
        });
    });
  };
}

export function addDomainName(params) {
  let url = "carrier/addDomain";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, params, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (result.data && result.data.data) resolve(result.data.data);
          else reject();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function checkDomainName(params) {
  let url = "carrier/checkDomain";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, params, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          console.log(result);
          if (result.data && result.data.data) resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function verifyDomainEmail(params) {
  let url = "carrier/verifyDomainEmail";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, params, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          console.log(result);
          if (result.data && result.data.data) resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function addLink(params) {
  let url = "carrier/addDomainVerificationLink";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, params, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          console.log(result);
          if (result.data && result.data.data) resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
