import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContactForm from '../Login/form/ContactForm';
import { getStorage, toastr } from '../../services/Common.services';
import Validation from '../../Components/Common/Validation/Validation.js';
import { browserHistory, Link } from 'react-router'
import * as actionCreators from './actionCreators';
import { HTTP } from '../../services/Http.service';
import TokenComponent from './TokenComponent';

class Token extends Component {
  constructor(props) {
    super(props);
    if (getStorage('token') != null) {
      if (getStorage('currentUserRole') == 'shipper') {
        browserHistory.push('/create-order')
      } else if (getStorage('currentUserRole') == 'carrier') {
        browserHistory.push('/track1')
      }
    }
    this.state = {
      email: '',
      emailInvalid: false,
      formValid: false,
      processing: false,
      isOpen: false
    }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let activeClass = '';
      if (window.scrollY > 80) {
        activeClass = 'stick-to-top';
      }
      (this.state.activeClasss !== activeClass) && this.setState({ activeClass });
    });
  
  //Add Class to body only on signin page
    document.getElementsByTagName('body')[0].className = 'isloginpage';
}

componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = '';
}

  handleSubmit(e) {
    this.setState({
      processing: true
    });
    const url = 'getResetPasswordToken?email=' + this.props.formValues.values.email;
    HTTP('get', url, {}).then((result) => {
      toastr.show('A token link has been sent to the email provided.', 'success')
      this.setState({
        processing: false,
        email: ''
      });
    }).catch((error) => {
      this.setState({
        processing: false
      });
    });
  }


  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    });
    const isValid = Validation(name, value);
    if (!isValid) {
      this.setState({
        [`${name}Invalid`]: true
      });
    } else {
      this.setState({
        [`${name}Invalid`]: false
      })
    }

    if (!this.state.emailInvalid) {
      this.setState({
        formValid: true
      });
    } else {
      this.setState({
        formValid: false
      });
    }
  }

  render() {
    return (
      <div className="login-page">
        <nav className={`navbar navbar-expand-lg navbar-main fixed-top  ${this.state.activeClass}`} onScroll={this.handleScroll} id="mainNav">
          <div className="container-fluid">
            <a className="navbar-brand" href="./">
              <img className="menu-open__hidelogo" src="./assets/images/logo.png" alt="logo" width="200" />
              <img className="menu-open__logo" src="./assets/images/portpro_logo_white.png" alt="logo" width="121" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <svg className="navbar-toggler__open" viewbox="0 0 11.5 7.4">
                <path fill="currentColor" d="M10.8,5.9c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.7-0.6,0.7l-0.1,0h-10C0.3,7.4,0,7,0,6.6        c0-0.4,0.3-0.7,0.6-0.7l0.1,0H10.8z M10.8,2.9c0.4,0,0.8,0.3,0.8,0.7c0,0.4-0.3,0.7-0.6,0.7l-0.1,0h-6C4.3,4.4,4,4.1,4,3.7        C4,3.3,4.3,3,4.6,2.9l0.1,0H10.8z M10.8,0c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.7-0.6,0.7l-0.1,0h-10C0.3,1.5,0,1.2,0,0.8        C0,0.4,0.3,0.1,0.6,0l0.1,0H10.8z"></path>
              </svg>
              <svg className="navbar-toggler__close" viewbox="0 0 9.5 9.5">
                <path className="st0" d="M8.7,9.5c-0.2,0-0.4-0.1-0.5-0.2L4.7,5.8L1.3,9.2C1,9.5,0.5,9.5,0.2,9.2s-0.3-0.8,0-1.1l3.4-3.4L0.2,1.3        c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l3.4,3.4l3.4-3.4c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L5.8,4.7l3.4,3.4c0.3,0.3,0.3,0.8,0,1.1        C9.1,9.4,8.9,9.5,8.7,9.5z"></path>
              </svg>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <a className="nav-link" href="https://portpro.io/#whyus">Why Us?</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="https://portpro.io/#features">Features</a>
                  <div className="dropdown__content" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="https://portpro.io/#customer-management">Customer Management</a>
                    <a className="dropdown-item" href="https://portpro.io/#dispatch-mobile">Dispatch & Mobile</a>
                    <a className="dropdown-item" href="https://portpro.io/#tracking">Truck & Container Tracking</a>
                    <a className="dropdown-item" href="https://portpro.io/#billing-settlements">Billing & Settlements</a>
                    <a className="dropdown-item" href="https://portpro.io/#saftey">Safety</a><a className="dropdown-item" href="./#reporting">Reporting </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://portpro.io/#markets">Markets</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://portpro.io/press.html">Press</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact-us" onClick={this.openModal}>Contact Us</a>
                </li>
              </ul>
              <div className="navbar-nav nav-right">
                <a className="btn btn-nav-signin" href="/">Sign In</a>
                <a className="btn btn-nav-demo" href="https://portpro.io/schedule-demo">Schedule Demo</a>
              </div>
            </div>
          </div>
        </nav>

        <div className="section-bg w-100" style={{ backgroundImage: "url(../../assets/images/demo-lines.svg)", backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
          <section className="seaction-signin signin pb-125">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h1 className="section-title mb-40">Reset Password</h1>
                  <p className="section-description">Reset your password to access your dashboard.</p>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="tab-content">

                      <TokenComponent handleEmail={this.handleSubmit} processing={this.state.processing} />

                      <p className="mt-4 text-center">Already have a account?
                        <Link to="/" > Login</Link>
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="support-section support">
            <div className="container">
              <div className="row">
                <div className="col-md-6 support__col">
                  <div className="support__icon support__icon-1">
                    <svg viewBox="0 0 35 31">
                      <path fill="currentColor" d="M30.6,13v13.5H4.4V12.9L0,10.4v18.4C0,30,1,31,2.2,31h30.6c1.2,0,2.2-1,2.2-2.2V10.6L30.6,13z M32.8,0H2.2                        C0.9,0,0,0.9,0,2.2v3.1l17.5,10L35,5.5V2.2C35,0.9,34.1,0,32.8,0z"></path>
                    </svg>
                  </div>
                  <div className="support__goright">
                    <div className="support__title">General Communication</div>
                    <div className="support__description">
                      For general queries, including <br />partnership opportunities, please email <br /><a href="mailto:sales@portpro.io">sales@portpro.io</a></div>
                  </div>
                </div>
                <div className="col-md-6 support__col">
                  <div className="support__icon support__icon support__icon-2">
                    <svg viewBox="0 0 32 32">
                      <path fill="currentColor " d="M16,0C7.2,0,0,7.2,0,16v10c0,3.3,2.7,6,6,6h2c1.1,0,2-0.9,2-2V20c0-1.1-0.9-2-2-2H4v-2C4,9.4,9.4,4,16,4                        s12,5.4,12,12v2h-4c-1.1,0-2,0.9-2,2v10c0,1.1,0.9,2,2,2h2c3.3,0,6-2.7,6-6V16C32,7.2,24.8,0,16,0z"></path>
                    </svg>
                  </div>
                  <div className="support__goright">
                    <div className="support__title">
                      Technical or <br />account support</div>
                    <div className="support__description">
                      We’re here to help! If you have <br />technical issues, please email us at <br /><a href="mailto:support@portpro.io">support@portpro.io</a></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer className="main-footer pt-80 pb-60 w-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 main-footer__col">
                <figure className="mr-3 mb-4">
                  <img src="./assets/images/logo.png" alt="PortPro Logo" height="20" />

                </figure>
                <p className="font-size-16 my-0 swaton-circular">Copyright 2020 PortPro.<br />All Rights Reserved.</p>
              </div>
              <div className="col-lg-4 main-footer__col">
                <h3 className="widget-title">Main Navigation</h3>
                <div className="row">
                  <div className="col-6 text-left">
                    <ul className="reset-ul font-size-14 lh-2">
                      <li> <a href="https://portpro.io/#home">Home</a></li>
                      <li><a href="https://portpro.io/#whyportpro">Why Us?</a></li>
                      <li><a href="https://portpro.io/#customer-management">Customer Management</a></li>
                      <li><a href="https://portpro.io/#dispatch-mobile">Dispatch & Mobile</a></li>
                      <li><a href="https://portpro.io/#tracking">Tracking  </a></li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="reset-ul font-size-14 lh-2">
                      <li><a href="https://portpro.io/#billng-settlement">Billing & Settlements</a></li>
                      <li><a href="https://portpro.io/#saftey">Safety</a></li>
                      <li><a href="https://portpro.io/#reporting">Reporting </a></li>
                      <li> <a href="https://portpro.io/#markets">Markets</a></li>
                      <li> <a href="https://portpro.io/press.html">Press</a></li>
                      <li> <a href="javascript:void(0)" onClick={this.openModal}>Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 main-footer__right main-footer__col">
                <div className="font-size-14 lh-2">
                  <h3 className="widget-title">Contact</h3>
                  <p className="main-footer__contact">
                    <svg viewBox="0 0 15 15">
                      <path fill="currentColor" d="M8.4,9.1C8.1,9.3,7.8,9.4,7.5,9.4c-0.3,0-0.6-0.1-0.9-0.2L0,5.6v6.3c0,1,0.8,1.9,1.9,1.9h11.2              c1,0,1.9-0.8,1.9-1.9V5.6L8.4,9.1z M13.1,1.3H1.9C0.8,1.3,0,2.1,0,3.1v0.6C0,4,0.1,4.2,0.3,4.3l6.9,3.7c0.1,0.1,0.2,0.1,0.3,0.1              c0.1,0,0.2,0,0.3-0.1l6.9-3.7C14.9,4.2,15,4,15,3.8V3.1C15,2.1,14.2,1.3,13.1,1.3z"></path>
                    </svg>
                    <a href="mailto:sales@portpro.io">sales@portpro.io</a>
                  </p>
                  <p className="main-footer__contact">
                    <svg viewBox="0 0 15 15">
                      <path fill="currentColor" d="M7.5,0C4.6,0,1.6,2.2,1.6,5.9c0,3.4,5.2,8.7,5.4,8.9C7.2,14.9,7.3,15,7.5,15s0.3-0.1,0.5-0.2              c0.2-0.2,5.4-5.5,5.4-8.9C13.4,2.2,10.4,0,7.5,0z M7.5,7.8c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S8.6,7.8,7.5,7.8z"></path>
                    </svg>580 Marin Blvd Jersey City, NJ 07310
                </p>
                  <div className="social-icon social-icon-md align-items-end reset-ul"><a href="https://www.facebook.com/PortPro-Technologies-109949560754548" target="_blank">
                    <svg viewBox="0 0 20 20">
                      <path fill="currentColor" d="M20,10.1c0-5.4-4.2-9.8-9.5-10S0.6,3.7,0.1,9s3.1,10.2,8.4,11v-7H5.9v-2.9h2.5V7.8c0-2.5,1.5-3.9,3.8-3.9                c0.8,0,1.5,0.1,2.2,0.2v2.5h-1.3c-0.8-0.1-1.5,0.5-1.6,1.2c0,0.1,0,0.2,0,0.3v1.9h2.8L13.9,13h-2.3v7C16.4,19.2,20,15,20,10.1z"></path>
                    </svg>
                  </a><a href="https://www.linkedin.com/company/portpro/" target="_blank">
                      <svg viewBox="0 0 20 20">
                        <path fill="currentColor" d="M4.6,7.6v11.8C4.6,19.7,4.3,20,4,20H1.1c-0.3,0-0.6-0.3-0.6-0.6V7.6c0-0.3,0.3-0.6,0.6-0.6H4                C4.3,7.1,4.6,7.3,4.6,7.6z M20,12.2c0-2.5-1.6-4.8-4.1-5.4c-1.6-0.4-3.3,0.1-4.5,1.3V7.6c0-0.3-0.3-0.6-0.6-0.6H8                c-0.3,0-0.6,0.3-0.6,0.6v11.8C7.4,19.7,7.7,20,8,20h2.9c0.3,0,0.6-0.3,0.6-0.6v-6.6c0-1.2,0.8-2.2,1.9-2.4c0.7-0.1,1.4,0.1,1.9,0.5                c0.5,0.5,0.8,1.1,0.8,1.8v6.7c0,0.3,0.3,0.6,0.6,0.6h2.9c0.3,0,0.6-0.3,0.6-0.6V12.2z M2.3,0C1,0,0,1.1,0,2.4s1,2.4,2.3,2.4                s2.3-1.1,2.3-2.4S3.5,0,2.3,0L2.3,0z"></path>
                      </svg></a><a href="https://www.instagram.com/portprotms/" target="_blank">
                      <svg viewBox="0 0 20 20">
                        <path fill="currentColor" d="M19.9,5.9c0-0.8-0.2-1.7-0.5-2.5C19,2.1,17.9,1,16.5,0.5c-0.8-0.3-1.6-0.4-2.4-0.5C13,0,12.7,0,10,0                S6.9,0,5.9,0.1C5,0.1,4.2,0.2,3.5,0.5C2.1,1,1,2.1,0.5,3.4C0.2,4.2,0.1,5,0.1,5.9C0,6.9,0,7.3,0,10s0,3.1,0.1,4.1                c0,0.8,0.2,1.6,0.5,2.4c0.5,1.3,1.6,2.4,2.9,2.9c0.8,0.3,1.6,0.5,2.4,0.5C6.9,20,7.3,20,10,20s3.1,0,4.1-0.1c0.8,0,1.7-0.2,2.4-0.5                c1.3-0.5,2.4-1.6,2.9-2.9c0.3-0.8,0.4-1.6,0.5-2.4c0-1.1,0.1-1.4,0.1-4.1C20,7.3,20,7,19.9,5.9L19.9,5.9z M10,15.1                c-2.8,0-5.1-2.3-5.1-5.1S7.2,4.9,10,4.9c2.8,0,5.1,2.3,5.1,5.1S12.8,15.1,10,15.1z M15.3,5.9c-0.7,0-1.2-0.5-1.2-1.2                c0-0.4,0.2-0.8,0.6-1c0.4-0.2,0.8-0.2,1.2,0c0.4,0.2,0.6,0.6,0.6,1C16.5,5.3,16,5.9,15.3,5.9z M10,6.6c1.8,0,3.3,1.5,3.3,3.3                s-1.5,3.3-3.3,3.3S6.7,11.8,6.7,10S8.2,6.6,10,6.6z"></path>
                      </svg></a>
                      <a href="https://twitter.com/PortProtms" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 20 20">
                                    <path fill="currentColor"
                                        d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z">
                                    </path>
                                </svg>
                            </a>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Modal bsSize="xl" show={this.state.isOpen} onHide={this.closeModal} dialogClassName="contact-modal modal-xl">
          <Modal.Header closeButton>
            <Modal.Title>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-0">
          <ContactForm />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch)
  }
}

function mapStateToProps(state) {
  return {
    userType: state.HomeReducer.userType,
    user: state.TokenReducer,
    formValues: state.form.token
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Token);
