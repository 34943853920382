import React, { useDebugValue, useState } from 'react'
import Select from "react-select";
import { smallSelectStyle } from '../../../../../../assets/js/select-style';
import { TextInputField } from './CommonInputComponent';
import { currencies } from '../../../../../../services';
import { editTMSCustomer } from '../../../../services';
import { errorColorResponse, successColorResponse , onChangeSuccessColorResponse } from '../../utility';

const PaymentCustomerComponent = ({ customerType ,Selected_customer ,updateCustomer }) => {
    const [credit_limit,setCredit_limit] = useState(Selected_customer.credit_limit ? Selected_customer.credit_limit : 0);
    const [payment_terms ,setPayment_terms] = useState(Selected_customer.payment_terms ? Selected_customer.payment_terms : 0);
    const [account_hold , setAccount_hold] = useState(Selected_customer.account_hold);
    const [currency , setCurrency] = useState(Selected_customer.currency ? Selected_customer.currency : "");
    const YesOrNo = [{ label: 'Yes', value: true}, { label: 'No', value: false }]
    const onChangeHandler = (key , value) => {
        let params = {
            _id:Selected_customer._id,
            [key] : value
        }
        editTMSCustomer(params).then((result)=>{
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse(key)
        }).catch((err)=>{
            console.log(err)
            errorColorResponse(key)
        })
    }
    const onBlurHandler = (key , value) =>{
        if(Selected_customer[key] == value) return
        let params = {
            _id : Selected_customer._id,
            [key] : value
        }
        editTMSCustomer(params).then((result)=>{
            updateCustomer(result.data.data)
            successColorResponse(key)
        }).catch((err)=>{
            console.log("err",err)
            errorColorResponse(key)
        })
    }
    return (
        <div>
            {customerType.intersect([
                "caller",
                "consignee",
                "shipper",
                "ALL",
            ]).length > 0 && (
                    <React.Fragment>
                        <div className="font-medium font-14 mb-15">Payment</div>
                        <div className="form-row">
                            {customerType.intersect(["caller", "ALL"]).length > 0 && (
                                <React.Fragment>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label class="col-form-label">
                                            {`Credit Limit ($${Math.abs(
                                                Selected_customer.credit_limit - Selected_customer.balance
                                            )} ${Selected_customer.credit_limit < Selected_customer.balance
                                                    ? "oustanding amount"
                                                    : "remaining"
                                                } )`}
                                        </label>
                                        <TextInputField
                                            type="text"
                                            name="credit_limit"
                                            className="form-control"
                                            onChange={(e, name) => {setCredit_limit(e.target.value && e.target.value.replace(/[^0-9]/gi, ""))}}
                                            value={credit_limit}
                                            onBlur={(name,value)=>{onBlurHandler(name, value)}}
                                            required={false}
                                        />
                                    </div>
                                </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label class="col-form-label">
                                                Payment Terms
                                            </label>
                                            <TextInputField 
                                                type="text"
                                                name="payment_terms"
                                                className="form-control"
                                                onChange={(e,name)=>{
                                                    setPayment_terms(e.target.value && e.target.value.replace(/[^0-9]/gi, ""))
                                                }}
                                                onBlur={(name,value)=>{
                                                    onBlurHandler(name, value)
                                                }}
                                                value={payment_terms}
                                                required={false}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {customerType.intersect([
                                "caller",
                                "consignee",
                                "shipper",
                                "ALL",
                            ]).length > 0 && (
                                    <React.Fragment>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label class="col-form-label">
                                                    Account Hold
                                                </label>
                                                <Select
                                                    options={YesOrNo}
                                                    styles={smallSelectStyle}
                                                    name={"account_hold"}
                                                    className={"account_hold"}
                                                    onChange={(val)=>{
                                                        setAccount_hold(val.value)
                                                        onChangeHandler("account_hold" , val.value)
                                                    }}
                                                    value={YesOrNo.find((el)=> el.value === account_hold)}
                                                    id={"account_hold"}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Currency {currency}</label>
                                                <Select
                                                    options={currencies}
                                                    styles={smallSelectStyle}
                                                    name="currency"
                                                    className='currency'
                                                    onChange={(val)=>{
                                                        setCurrency(val.value)
                                                        onChangeHandler("currency" , val.value)
                                                    }}
                                                    value={currencies.find((item) => item.value === currency)}
                                                    />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                        </div>
                        <div class="hr-light my-20"></div>
                    </React.Fragment>
                )}
        </div>
    )
}

export default PaymentCustomerComponent
