import { isNewModal } from "../../services";
export const dispatchItems = [
  {
    id: 1,
    title: "Dispatcher",
    path: "/tms/Dispatcher",
    permission: "dispatcher",
  },
  {
    id: 10,
    title: "Online Tendered Loads V2",
    path: "/new-tendered",
    permission: "customer_service_tendered_loads",
  },
  {
    id: 2,
    title: "Driver Itinerary",
    path: "/tms/customer-service/driver-itinerary",
    permission: "dispatcher_driver_itinerary",
  },
  {
    id: 6,
    title: "Driver Planner",
    path: "/tms/customer-service/driver-planner",
    permission: "dispatcher_driver_itinerary",
  },
  {
    id: 3,
    title: "Double Moves",
    path: "/tms/customer-service/dropped-dispatch-yard-container",
    permission: "dispatcher_double_moves",
  },
  {
    id: 4,
    title: "Street Turns",
    path: "/tms/customer-service/street-turns",
    permission: "dispatcher_street_turns",
  },
  {
    id: 5,
    title: "Problem Containers",
    path: "/tms/customer-service/problem-container",
    permission: "dispatcher_problem_container",
  },  
];

export const customerServiceItems = isNewModal() ? [
  {
    id: 1,
    title: "Dashboard",
    path: "/tms/customer-dashboard",
    permission: "customer_service_dashboard",
  },
  {
    id: 2,
    title: "Loads",
    path: "/tms/customer-service/load",
    permission: "customer_service_load_info",
  },
  {
    id: 2,
    title: "Quotes",
    path: "/tms/load-quote-settings",
    permission: "customer_service_quotes",
  },
  {
    id: 3,
    title: "Yard Check",
    path: "/tms/customer-service/at-yard-container-loads",
    permission: "customer_service_dropped_container",
  },
  {
    id: 4,
    title: "Tendered Loads",
    path: "/tendered",
    permission: "customer_service_tendered_loads",
  },
  {
    id: 5,
    title: "Load Calendar",
    path: "/tms/customer-service/calendar",
    permission: "customer_service_load_calendar",
  },
  {
    id: 6,
    title: "Bulk Uploads",
    path: "/tms/customer-service/bulk-upload1",
    permission: "customer_service_bulk_upload",
  },
  {
    id: 7,
    title: "Messaging",
    path: "/driver-messaging",
    permission: ["customer_service_drive_messaging", "customer_service_employee_messaging", "customer_service_group_messaging", "customer_service_customer_messaging"],
  },
  {
    id: 8,
    title: "Deleted Loads",
    path: "/tms/deleted-loads",
    permission: "customer_service_deleted_load",
  },
  {
    id: 9,
    title: "Online Tendered Loads",
    path: "/tendered-online",
    permission: "customer_service_online_tendered_loads",
  },
  {
    id: 10,
    title: "Online Tendered Loads V2",
    path: "/new-tendered",
    permission: "customer_service_tendered_loads",
  },
] : [
  {
    id: 1,
    title: "Dashboard",
    path: "/tms/customer-dashboard",
    permission: "customer_service_dashboard",
  },
  // {
  //   id: 2,
  //   title: "Loads",
  //   path: "/tms/customer-service/load",
  //   permission: "customer_service_load_info",
  // },
  {
    id: 2,
    title: "Quotes",
    path: "/tms/load-quote-settings",
    permission: "customer_service_quotes",
  },
  {
    id: 3,
    title: "Yard Check",
    path: "/tms/customer-service/at-yard-container-loads",
    permission: "customer_service_dropped_container",
  },
  {
    id: 4,
    title: "Tendered Loads",
    path: "/tendered",
    permission: "customer_service_tendered_loads",
  },
  {
    id: 5,
    title: "Load Calendar",
    path: "/tms/customer-service/calendar",
    permission: "customer_service_load_calendar",
  },
  {
    id: 6,
    title: "Bulk Uploads",
    path: "/tms/customer-service/bulk-upload1",
    permission: "customer_service_bulk_upload",
  },
  {
    id: 7,
    title: "Messaging",
    path: "/driver-messaging",
    permission: ["customer_service_drive_messaging", "customer_service_employee_messaging", "customer_service_group_messaging", "customer_service_customer_messaging"],
  },
  {
    id: 8,
    title: "Deleted Loads",
    path: "/tms/deleted-loads",
    permission: "customer_service_deleted_load",
  },
  {
    id: 9,
    title: "Online Tendered Loads",
    path: "/tendered-online",
    permission: "customer_service_online_tendered_loads",
  },
  {
    id: 10,
    title: "Online Tendered Loads V2",
    path: "/new-tendered",
    permission: "customer_service_tendered_loads",
  },
];

export const accRecievableItems = [
  {
    id: 1,
    title: "Accounts Receivable Dashboard",
    path: "/tms/billing/account-receivable",
    permission: "billing_account_receivable",
  },
  {
    id: 2,
    title: "Apply Payments",
    path: "/tms/billing/payment",
    permission: "billing_batch_apply_payment",
  },
  {
    id: 3,
    title: "Subaccounts Receivable",
    path: "/tms/billing/sub-customer-account-receivable",
    permission: "billing_subaccount_receivable",
  },
  {
    id: 4,
    title: "Aging",
    path: "/tms/billing/aging1",
    permission: "reports_aging",
  },
  {
    id: 5,
    title: "Chassis Audit",
    path: "/tms/customer-service/chassis-validation",
    permission: "billing_chassis_validation",
  },
  {
    id: 6,
    title: "Problem Invoices",
    path: "/tms/desyncedQBLoads",
    permission: "billing_problem_invoices",
  },
];

export const accPayableItems = [
  {
    id: 1,
    title: "Driver Pay",
    path: "/tms/billing/driver-pay",
    permission: "billing_driver_pay",
  },
  {
    id: 2,
    title: "Driver Settlements",
    path: "/tms/billing/settlements",
    permission: "billing_driver_settlement",
  },
  {
    id: 3,
    title: "Dynamic Expenses",
    path: "/tms/dynamic-expense",
    permission: "dynamic_dynamic_expense",
  },
];

export const driverItems = [
  {
    id: 1,
    title: "Driver Profiles",
    path: "/tms/Driver",
    permission: "other_profile_driver",
  },
  {
    id: 2,
    title: "Truck Assignments",
    path: "/tms/AssignTruck",
    permission: "other_assign_truck",
  },
  {
    id: 3,
    title: "Truck Owners",
    path: "/tms/fleetTruck",
    permission: "safety_truck_owner",
  },
  {
    id: 4,
    title: "Driver Pay Rates",
    path: "/tms/payment-setting",
    permission: "other_driver_pay_rate",
  },
  {
    id: 5,
    title: "Driver Deduction Rates",
    path: "/tms/billing/settings",
    permission: "billing_settlement_setting",
  },
  {
    id: 6,
    title: "Dynamic Driver Pay",
    path: "/dynamic-driverPay",
    permission: "other_dynamic_driver_pay",
  },
];

export const equipmentItems = [
  {
    id: 1,
    title: "Truck Profiles",
    path: "/tms/Truck",
    permission: "other_profile_truck",
  },
  {
    id: 2,
    title: "Chassis Profiles",
    path: "/tms/AddChassis",
    permission: "other_profile_chassis",
  },
  {
    id: 3,
    title: "Trailer Profiles",
    path: "/tms/Trailer",
    permission: "other_profile_trailer",
  },
];

export const customerItems = [
  {
    id: 1,
    title: "Customer Profile",
    path: "/tms/customer",
    permission: "other_profile_customer",
  },
  {
    id: 2,
    title: "Customer Rates",
    path: "/tms/load-price-settings",
    permission: "other_customer_rate",
  },
  {
    id: 3,
    title: "Group Customer",
    path: "/tms/FleetCustomer",
    permission: "other_group_customer",
  },
];

export const reportItems = [
  {
    id: 1,
    title: "Aging",
    path: "/tms/report/aging1",
    permission: "reports_aging",
  },
  {
    id: 2,
    title: "Load Master",
    path: "/tms/report/load-master-report",
    permission: "reports_master_load-master-report",
  },
  {
    id: 3,
    title: "Admin Dashboard",
    path: "/tms/report/agingChart",
    permission: "reports_admin_dashboard",
  },
  {
    id: 30,
    title: "Admin Dashboard 2",
    path: "/tms/report/reportDashboard",
    permission: "reports_admin_dashboard_2",
  },
  {
    id: 4,
    title: "Clock",
    path: "/tms/report/driverclockinoutreport",
    permission: "reports_clock_report",
  },
  {
    id: 5,
    title: "Charge",
    path: "/tms/report/report1",
    permission: "reports_charge_report",
  },
  {
    id: 6,
    title: "Charge 2",
    path: "/tms/report/charge-report-2",
    permission: "reports_charge_report_2",
  },
  {
    id: 7,
    title: "Charge 3",
    path: "/tms/report/charge-3",
    permission: "reports_charge_report_3",
  },
  {
    id: 8,
    title: "Customer Revenue",
    path: "/tms/report/revenue",
    permission: "reports_customer_revenue",
  },
  {
    id: 9,
    title: "Driver Pay",
    path: "/tms/report/driver-pay-report",
    permission: "reports_driver_pay",
  },
  {
    id: 29,
    title: "Driver Pay Summary",
    path: "/tms/report/driver-pay-summary-report",
    permission: "reports_driver_pay_summary",
  },
  {
    id: 10,
    title: "Driver Revenue",
    path: "/tms/report/driverrevenue",
    permission: "reports_driver_revenue",
  },
  {
    id: 11,
    title: "Forecast Report",
    path: "/tms/report/ForcastReport",
    permission: "reports_forecast_report",
  },
  {
    id: 12,
    title: "New Driver Revenue",
    path: "/tms/report/newdriverrevenue",
    permission: "reports_new_driver_revenue",
  },
  {
    id: 13,
    title: "Leg Type",
    path: "/tms/report/leg-type-report",
    permission: "reports_leg_type",
  },
  {
    id: 14,
    title: "LFD",
    path: "/tms/report/lfd-report",
    permission: "reports_lfd_report",
  },
  {
    id: 15,
    title: "Loads",
    path: "/tms/report/report2",
    permission: "reports_loads_report",
  },
  {
    id: 16,
    title: "Load Pricing",
    path: "/tms/report/pricing-load-report",
    permission: "reports_loads_report_pricing",
  },
  {
    id: 17,
    title: "Load Count",
    path: "/tms/report/report3",
    permission: "reports_load_count",
  },
  {
    id: 18,
    title: "Pricing Breakdown",
    path: "/tms/report/report5",
    permission: "reports_pricing_breakdown",
  },
  {
    id: 19,
    title: "Productivity",
    path: "/tms/report/productivityReport",
    permission: "reports_productivity_report",
  },
  {
    id: 20,
    title: "Return",
    path: "/tms/report/return-report",
    permission: "reports_return_report",
  },
  {
    id: 21,
    title: "Revenue",
    path: "/tms/report/revenue-report",
    permission: "reports_revenue_report",
  },
  {
    id: 22,
    title: "Status Driver",
    path: "/tms/report/driverstatusreport",
    permission: "reports_status_driver",
  },
  {
    id: 23,
    title: "Summary",
    path: "/tms/report/summary-report",
    permission: "reports_summary_report",
  },
  {
    id: 24,
    title: "Vessel",
    path: "/tms/report/report4",
    permission: "reports_vessel_report",
  },
  {
    id: 25,
    title: "Profitability Report",
    path: "/tms/report/profitability-report",
    permission: "reports_profitability_report",
  },
  {
    id: 26,
    title: "Chassis History",
    path: "/tms/report/chassishistory",
    permission: "reports_chassis_history",
  },
  {
    id: 27,
    title: "ClockIn-ClockOut Report",
    path: "/tms/report/clock-in-clock-out-report",
    permission: "reports_clock_in_out",
  },
  {
    id: 28,
    title: "Import Charge Report",
    path: "/tms/report/import-charge-report",
    permission: "reports_import_charge",
  },
  {
    id: 29,
    title: "Export Charge Report",
    path: "/tms/report/export-charge-report",
    permission: "reports_export_charge",
  },
  {
    id: 31,
    title: "External Charge",
    path: "/tms/report/external-charge",
    permission: "reports_external_charge_report",
  },
];

export const settingItems = [
  {
    id: 1,
    title: "General Settings",
    path: "/settings/general-setting",
    permission: "settings_general",
  },
  {
    id: 2,
    title: "Users & Permissions",
    path: "/carrieraccessmanagement",
    permission: "other_permission",
  },
  {
    id: 3,
    title: "Role Management",
    path: "/roleaccessmanagement",
    permission: "other_role_management",
  },
  {
    id: 4,
    title: "Expense",
    path: "/settings/expense-setting",
    permission: "settings_expense",
  },
  {
    id: 5,
    title: "Container Settings",
    path: "/tms/Container/Owner",
    permission: ["other_container_owner",
      "other_container_size",
      "other_container_type",
      "other_container_free_day"
    ],
  },
  {
    id: 6,
    title: "Chassis Settings",
    path: "/tms/Chassis/Owner",
    permission: ["other_chassis_owner",
      "other_chassis_size",
      "other_chassis_type"
    ],
  },
  {
    id: 7,
    title: "Commodity Settings",
    path: "/settings/Commodity",
    permission: "settings_commodity",
  },
  {
    id: 8,
    title: "Terminal Settings",
    path: "/tms/terminals",
    permission: "settings_terminal",
  },
  {
    id: 9,
    title: "Dispatch Color Settings",
    path: "/settings/loadStatusColors",
    permission: "settings_load_status_colors",
  },
  {
    id: 10,
    title: "Group Settings",
    path: "/settings/group-setting",
    permission: "settings_group_settings",
  },
  {
    id: 11,
    title: "General Info",
    path: "/settings/general-info",
    permission: "settings_general_info",
  },
  {
    id: 12,
    title: "Equipment Parameters",
    path: "/account/equipment-parameters",
    permission: "settings_equipment_params",
  },
  {
    id: 13,
    title: "Document Configuration",
    path: "/account/invoice-setup",
    permission: "settings_invoice_setup",
  },
  {
    id: 14,
    title: "Email Configuration",
    path: "/account/email-configuration",
    permission: "settings_email_configuration",
  },
  {
    id: 15,
    title: "Billing Configuration",
    path: "/account/billing-configuration",
    permission: "settings_billing_configuration",
  },
  { id: 16,
    title: "Developer Setting",
    path: "/settings/developer-settings",
    permission: "settings_developer",
  },
  { id: 17,
    title: "My Apps",
    path: "/settings/my-apps",
    permission: "settings_my_apps",
  },
  {
    id: 18,
    title: "Enterprise Conneciton Setup",
    path: "/settings/auth0-connect",
    permission: "settings_general_info",
  },
  { id: 19,
    title: "Container Tracking",
    path: "/container-tracking",
    permission: "container-tracking",
  }
];

export const groupSettings = [
  {
    id: 1,
    title: "Carrier Group Settings",
    path: "/settings/group-setting",
  },
  {
    id: 2,
    title: "Carrier Cities Settings",
    path: "/settings/group-setting/cities",
  },
];

export const learnings = [
  {
    id: 1,
    title: "Features",
    path: "/tms/learning/Features",
  },
  {
    id: 2,
    title: "Webinars",
    path: "/tms/learning/Webinars",
  },
  {
    id: 3,
    title: "Videos",
    path: "/tms/learning/Videos",
  },
  {
    id: 4,
    title: "Tutorials",
    path: "/tms/learning/Tutorials",
  },
  {
    id: 6,
    title: "Blogs",
    path: "/tms/learning/Blogs",
  },
  
];

export const appointmentlistItems = [
  {
    id: 1,
    title: "Appointment List",
    path: "/tms/AppointmentList",
    permission: "appointment_list",
  },
]
