import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import ReactTooltip from "react-tooltip";
import { browserHistory } from 'react-router';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { cloneDeep } from 'lodash';
import { debounce, throttle } from "throttle-debounce";
import { orderBy } from "lodash";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import draftToHtml from "draftjs-to-html";
import $ from "jquery";
import jsonLogic from "json-logic-js";
import moment from "moment";

import { CustomIconNotes } from "../../../Components/Common/CustomIcons/Index";
import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { getTruckersByCustomer } from "../../../Components/Common/FloatingChat/actionCreators";
import { LoaderBar } from '../../../Components/Common/LoaderBar';
import {
  IconCalendar,
  IconDownload,
  IconInfoFocus,
  IconMinus,
  IconNoteArrowRight,
  IconPlus,
  IconSendEmail,
  IconSort,
  IconSortDown,
  IconSortUp,
} from "../../../Components/Common/Icons/index";
import PriceInput from "../../../Components/Common/PriceInput/index";
import { getPricingRules } from "../../../Components/DynamicPricing/actionCreators";
import configuration from "../../../config";
import {
  convertUnit,
  getPricingSettingsOptions,
  getStorage,
  isMilitaryTime,
  setStorage,
  toastr,
  treeToJsonParser,
} from "../../../services/Common.services";
import { validateEmail } from "../../../utils";
import { TMS_GET_USER_LOAD } from "../constant";
import {
  changeLoadStatus,
  saveLoadNotes,
  getSettings,
  sendBillingEmail,
  UpdatePricing,
} from "../services";
import * as tmsAction from "./actionCreator";
import AdditionalPricing from "./AdditionalPricing";
import LoadExpense from "./LoadExpense";
import confirmCharge from "../../../Components/Common/ChargeConflictAlert";
import "./style.css";
import { emailInvoice, getInvoiceDesign } from "../DocumentConfiguration/actionCreators";
import {  invoice, rateConf } from "../DocumentConfiguration/constants";
import { listenQBSocketMessage, listenQBCustomerSocketMessage } from "../../../Components/CustomHooks/listenQBSocket";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";

const firebase = configuration.firebase;
const MANUALLY_CHANGED = 'MANUALLY_CHANGED';
const pathname = window.location.pathname;
const newFirebase = configuration.getNewFirebaseInstance;
const newDb = newFirebase('new-bill-of-lading').database();
const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};

const pricingItems = {
  amount: 0,
  chargePerDay: 0,
  chargePerMinute: 0,
  finalAmount: 0,
  unit: 1,
  freeDays: 0,
  perType: "",
  name: "",
  manualFinalAmount: 0,
  chargeType: MANUALLY_CHANGED,
};
// const sortingOptions = {
//   "Name of Charge #": "name",
// };

let timeZone = getStorage("timeZone");
class BillOfLading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ ...commodityItems }],
      APPROVED: false,
      UNAPPROVED: false,
      REBILLING: false,
      BILLING: false,
      statusname: null,
      paymentDate: new Date(),
      showConfirmModel: false,
      extraEmail: [],
      ccEmail: [],
      showNote: false,
      isEmailSendInProcess: false,
      pricingRules: [],
      editorState: EditorState.createEmpty(),
      inpSubject: null,
      billingNote: {},
      officeNote: {},
      asce: true,
      isLoadTabActive: false,
      showUploadModal: false,
      showSendEmailModal: false,
      isCustomerTabActive: props.billingActiveTab ? props.billingActiveTab : "main-customer",
      isSubExpBillTabActive: "bill",
      isBillActive: true,
      formErrors: [],
      isLoading : true,
      approvalStatus:""
    };
    this.handleextraEmailChange = this.handleextraEmailChange.bind(this);
    this.handleccEmailChange = this.handleccEmailChange.bind(this);
    this.pricingSettingsOptions = getPricingSettingsOptions();
    this.saveNotesDebounce = debounce(2000, this.submitNotes);
    this.socketRef = null;
    timeZone = getStorage("timeZone");
    this.userTimeFormat = isMilitaryTime();
    this.firebaseRef = React.createRef();
  }

  handleExpenseUpdate = () => {};
  addCustomerPriceRows = () => {};
  updateAdditionalPricing = () => {};

  handleCustomerTabActive = (i) => {
    this.setState({
      isCustomerTabActive: i,
    });
  };

  handleSubExpBillTabActive = (i) => {
    this.setState({
      isSubExpBillTabActive: i,
    });
  };

  componentWillMount() {
    this.getPricingRules();
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
    } else {
      this.componentWillReceiveProps(this.props);
    }
    
  }
  keyPressOnNumberInput = (evt) => {
    var invalidChars = ["+", "e"];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };
  componentDidMount() {
    this.isFirebaseInitialize = false;
    this.isCustomerFirebaseInitialize = false;
    if (getStorage("loggedInUser") != null) {
      const currentUser = JSON.parse(getStorage("loggedInUser"));
      let carrierId = currentUser._id;
      if (currentUser.role === "driver") {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === "fleetmanager") {
        carrierId = currentUser.fleetManager.carrier;
      }
      if (currentUser.role === "customer") {
        this.props.dispatch(getTruckersByCustomer()).then((truckers) => {
          carrierId =
            truckers[0] && truckers[0].carrier && truckers[0].carrier._id;
          this.setPricingSettingOptions(carrierId, currentUser);
        });
      } else {
        this.setPricingSettingOptions(carrierId, currentUser);
      }
      this.notesDetail()

      if (["fleetmanager","carrier"].includes(currentUser.role) &&  pathname === "/tms/Dispatcher/billing") {
        this.socketQBStatus = firebase.database().ref(`${carrierId}/qb_attempt`);
        listenQBSocketMessage(this.socketQBStatus, this.isFirebaseInitialize)

        this.socketCustomerQBStatus = firebase.database().ref(`${carrierId}/qb_customer_attempt`);
        listenQBCustomerSocketMessage(this.socketCustomerQBStatus, this.isCustomerFirebaseInitialize)
      }
    }

    if (this.props.handleSubmitLoad) {
      this.props.handleSubmitLoad(
        (isSaveCustomerRates, isSendRateConfirmation) =>
          this.updateBillOfLading(isSaveCustomerRates, isSendRateConfirmation)
      );
    }

    this.socketRef = firebase.database().ref(`${this.props?.loads?._id}/qbUpdateAttempt`)
    this.socketRef.on('value',(snapshot)=>{
      const values=snapshot.val();
    
      const data=values && JSON.parse(values)
      if(data?.msg && data?.msg !== "") {
        toastr.show(data?.msg, "error")
        this.socketRef.remove()
      }
  
    });

    this.subscribeToFirebase()
  }

  updateBillOfLading = (isSaveCustomerRates, isSendRateConfirmation) => {
    if (this.state.isCustomerTabActive === "main-customer") {
      if (this.state.isSubExpBillTabActive === "bill") {
        this.updatePricing(isSaveCustomerRates, isSendRateConfirmation);
      } else if (this.state.isSubExpBillTabActive === "sub_expense") {
        this.handleExpenseUpdate();
      }
    } else if (this.state.isCustomerTabActive === "sub-customer") {
      this.updateAdditionalPricing();
    }
  };

  notesDetail(){
    this.setState({isLoading :true});
    tmsAction.getLoadNoteDetails({reference_number : this.props.id}).then((data) => {
      if(data.billingNote && Object.keys(data.billingNote).length != 0){
        this.setState({
          billingNote : data.billingNote ? data.billingNote : {},
        })
      }
      if(data.officeNote && Object.keys(data.officeNote).length != 0){
        this.setState({
          officeNote : data.officeNote ? data.officeNote : {},
        })
      }
      this.setState({isLoading :false});
    });;

  }
  
  submitNotes = (key,value) => {
    saveLoadNotes({
      [key]:value,
      reference_number: this.props.loads.reference_number,
    })
      .then(() => {
        toastr.show("Notes have been added.", "success");
        this.notesDetail()
      })
      .catch((err) => toastr.show(err.message, "error"));
  };

  setPricingSettingOptions(carrierId, currentUser) {
    let reference_number;
    if (this.props.selectedLoads) {
      reference_number = this.props.selectedLoads.reference_number;
    } else {
      reference_number = this.props.loads.reference_number;
    }
    this.userPresense = firebase
      .database()
      .ref(
        `${carrierId}/presense/${reference_number}/billing/${currentUser._id}`
      );

    if (this.userPresense) {
      this.userPresense.set(true);
    }
    this.getSettings();
  }

  getSettings() {
    getSettings()
      .then((result) => {
        const data = result.data.data;
        if (data) {
          this.setState({ settings: data })
          const distanceUnit = _.get(data, 'carrier.distanceMeasure')
          const weightUnit = _.get(data, 'carrier.weightMeasure')
          const isCommodityProfileShow = _.get(data, 'carrier.isCommodityProfileShow')
          const groupedProfiles = _.get(data, 'groupedProfiles')
          setStorage('measureUnits', JSON.stringify({ distanceUnit, weightUnit, isCommodityProfileShow ,groupedProfiles}))
        }

      })
      .catch((error) => {
        console.log("-*-* get settings error ----", error);
      });
  }

  componentWillUnmount() {
    if (this.userPresense) {
      this.userPresense.set(false);
    }

    if (this.socketRef) {
      this.socketRef.off("value");
    }

    if(this.socketQBStatus) {
      this.socketQBStatus.off("value")
    }
    if(this.socketCustomerQBStatus) {
      this.socketCustomerQBStatus.off("value")
    }
    if(newFirebase){
      this.unsubscribeFromFirebase();
    }
  }
  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  getPricingRules() {
    getPricingRules()
      .then((pricingRules) => {
        this.setState({ pricingRules });
      })
      .catch((error) => {
        console.log(" -*-*-* error get pricing rules -*-*-*- ", error);
      });
  }

  componentWillReceiveProps(props) {
    var items = [];
    let officeNote, billingNote;
    if (
      this.props.pricing !== props.pricing ||
      (props.pricing && !this.state.pricing)
    ) {
      let base_price = 0,
        x_charges = 0;
      let allPricings = props.pricing.filter((x) => x);
      if (allPricings && allPricings.length > 0) {
        allPricings.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price +=  parseFloat(x.finalAmount)  ;
            } else {
              x_charges = (
                parseFloat(x_charges) + parseFloat(x.finalAmount)
              ).toFixed(2);
            }
            x.manualFinalAmount = x.finalAmount;
          }
        });
      }
      const Total = (parseFloat(base_price) + parseFloat(x_charges)).toFixed(2);
      this.setState(
        { pricing: allPricings ,  base_price:base_price.toFixed(2), x_charges, Total, billing_date:null },
        () => {}
      );
    }
    if (getStorage("currentUserRole") == "customer" && !props.pricing) {
      this.setState({ pricing: [] });
    }
    if (props.selectedLoads && props.selectedLoads.items) {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: null,
        [props.selectedLoads.status]: true,
      });
      items = _.map(props.selectedLoads.items || [], function (e) {
        return _.omit(e, "_id");
      });
    }
    if (props.loads && props.loads.items) {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: null,
        [props.loads.status]: true,
      });
      items = _.map(props.loads.items || [], function (e) {
        return _.omit(e, "_id");
      });
    }
    let extraEmail = "";
    let ccEmail = "";
    let ccEmails = [];
    let inpSubject = "";
    let inpBody = "";

    if (this.props !== props && props.selectedLoads) {
      // inpSubject = this.state.settings &&
      // this.state.settings.carrier &&
      // this.state.settings.carrier.emailSubject
      //   ? this.dynamicStringByLoadInfo(
      //       this.state.settings.carrier.emailSubject,
      //       props.loads
      //     )
      //   : `Billing for Load #${props.selectedLoads.reference_number}`;
      inpBody = "Please review attached billing invoice.";
      // if (props.selectedLoads.caller.isEmailNeedToSend) {
      ccEmails = [
        ...ccEmails,
        props.selectedLoads.carrier.carrier.billingEmail,
        props.selectedLoads.caller.extraEmail,
      ];
      // }
      if (
        props.selectedLoads.customerEmployee &&
        props.selectedLoads.customerEmployee.length > 0 &&
        props.selectedLoads.customerEmployee[0].billingEmail
      ) {
        let customerEmployeeEmails = props.selectedLoads.customerEmployee.map(
          (D) => D.billingEmail
        );
        ccEmails = [...ccEmails, ...customerEmployeeEmails];
      }
      ccEmail = ccEmails.join(",");
    } else if (props.loads) {
      // inpSubject = this.state.settings &&
      // this.state.settings.carrier &&
      // this.state.settings.carrier.emailSubject
      //   ? this.dynamicStringByLoadInfo(
      //       this.state.settings.carrier.emailSubject,
      //       props.loads
      //     )
      //   : `Billing for Load #${props.loads.reference_number}`;
      inpBody = "Please review attached billing invoice.";
      // if (props.loads.caller.isEmailNeedToSend) {
      let billingEmail =
        props.loads.carrier.carrier && props.loads.carrier.carrier.billingEmail
          ? props.loads.carrier.carrier.billingEmail
          : "";
      ccEmails = [...ccEmails, billingEmail, props.loads.caller.extraEmail];
      // ccEmail = `${billingEmail},${props.loads.caller.extraEmail}`;
      // }
      if (
        props.loads.customerEmployee &&
        props.loads.customerEmployee.length > 0 &&
        props.loads.customerEmployee[0].billingEmail
      ) {
        let customerEmployeeEmails = props.loads.customerEmployee.map(
          (D) => D.billingEmail
        );
        ccEmails = [...ccEmails, ...customerEmployeeEmails];
      }
      ccEmail = ccEmails.join(",");
    }
    this.setState({
      // inpSubject,
      inpBody,
      customerProfile: props.selectedLoads ? props.selectedLoads : props.loads,
      items: items,
      ccEmail:ccEmail? ccEmail.split(",").compact(true):[],
      // officeNote: officeNote,
      // billingNote: billingNote,
    });
  }

  AddPriceRows() {
    let pricing = this.state.pricing ? [...this.state.pricing] : [];
    pricing.push({ ...pricingItems });
    this.setState({
      rowsData: [...pricing],
      asce: true,
      pricing,
    });
  }

  AddItemRows() {
    this.state.items.push({ ...commodityItems });
    this.setState({
      rowsData: [...this.state.items],
    });
  }

  tblvalchanges(index, e) {
    const { name, value } = e.target;
    const old = [...this.state.items];
    old[index][name] = value;
    this.setState({
      items: [...old],
    });
  }

  findMatchingPricingRule(chargeName) {
    let selectedLoads = undefined;
    if (this.props.selectedLoads) {
      selectedLoads = this.props.selectedLoads;
    } else {
      selectedLoads = this.props.loads;
    }

    const loads = {
      ...selectedLoads,
      caller: selectedLoads.caller && selectedLoads.caller._id,
      shipper: selectedLoads.shipper && selectedLoads.shipper.map((s) => s._id),
      consignee:
        selectedLoads.consignee && selectedLoads.consignee.map((s) => s._id),
      chassisPick: selectedLoads.chassisPick && selectedLoads.chassisPick._id,
      emptyOrigin: selectedLoads.emptyOrigin && selectedLoads.emptyOrigin._id,
      chassisTermination:
        selectedLoads.chassisTermination &&
        selectedLoads.chassisTermination._id,
      containerType:
        selectedLoads.containerType && selectedLoads.containerType._id,
      containerSize:
        selectedLoads.containerSize && selectedLoads.containerSize._id,
      containerOwner:
        selectedLoads.containerOwner && selectedLoads.containerOwner._id,
      chassisType: selectedLoads.chassisType && selectedLoads.chassisType._id,
      chassisSize: selectedLoads.chassisSize && selectedLoads.chassisSize._id,
      chassisOwner:
        selectedLoads.chassisOwner && selectedLoads.chassisOwner._id,
      terminal: selectedLoads.terminal && selectedLoads.terminal._id,
      commodityProfile:
        selectedLoads.commodityProfile &&
        selectedLoads.commodityProfile.map((s) => s._id),
    };

    const { pricingRules } = this.state;
    const newPricingFromSettings = [];
    let weight = 0;

    if (selectedLoads.items) {
      selectedLoads.items.forEach((obj) => {
        weight += parseInt(obj.weight);
      });
    }
    selectedLoads.weight = weight;
    pricingRules
      .filter((rule) => rule.name === chargeName && rule.isManual)
      .forEach((obj) => {
        let isAllConditionMatched = true;

        if (obj.query && obj.query[0] && obj.query[0].children.length > 0) {
          const rule = JSON.stringify(treeToJsonParser(obj.query)[0]);
          const data = JSON.stringify(loads);
          isAllConditionMatched = jsonLogic.apply(
            JSON.parse(rule),
            JSON.parse(data)
          );
        }

        if (isAllConditionMatched) {
          let totalDays = 0;
          if (obj.type === "fixed") {
            obj.finalAmount = obj.amount;
          } else if (obj.type === "perpound") {
            totalDays = weight;
          }
          obj.totalDays = totalDays;
          newPricingFromSettings.push(obj);
        }
      });
    return newPricingFromSettings[0];
  }

  pricingchanges(index, e) {
    const { name, value } = e.target;
    const old = [...this.state.pricing];

    const positiveValuesOnly = [
      // "amount",
      // "finalAmount",
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      old[index][name] = value;
    }

    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });
    let formErrors = [...this.state.formErrors];
    // formErrors
    if (!formErrors[index]) {
      formErrors[index] = [];
    }

    if (name == "unit" && parseFloat(old[index]["unit"]) < 0) {
      formErrors[index]["unit"] = "Only Positive numbers Allowed";
      this.setState({ formErrors });
    } else if (name == "freeDays" && parseFloat(old[index]["freeDays"]) < 0) {
      formErrors[index]["freeDays"] = "Only Positive numbers Allowed";
      this.setState({ formErrors });
    } else if (
      name == "chargePerDay" &&
      parseFloat(old[index]["chargePerDay"]) < 0
    ) {
      formErrors[index]["chargePerDay"] = "Only Positive numbers Allowed";
      this.setState({ formErrors });
    } else {
      formErrors[index][name] = null;
      this.setState({ formErrors });
    }

    if (name === "name") {
      const data = this.findMatchingPricingRule(value);

      if (data) {
        let units = 0;
        const freeDays = data.freeDays || 0;
        const chargePerDay = data.amount || 0;
        
        if (data.type === "fixed") units = 1;

        let finalAmount = (units - freeDays) * chargePerDay;

        if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;

        old[index]["description"] = data.description;
        old[index]["perType"] = data.type;
        old[index]["freeDays"] = freeDays > 0 ? freeDays : 0;
        old[index]["chargePerDay"] = chargePerDay;
        old[index]["unit"] = units;
        old[index]["finalAmount"] = finalAmount;
        old[index]["manualFinalAmount"] = 0;
        old[index]["amount"] = finalAmount;

        // console.log("---- old{index} ----", old[index]);
        this.setState({
          pricing: [...old],
        });
        return;
      }
    }

    if (name === "type") { 
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
            100;
    } else if (name === "amount") { 
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
            100;
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay",'perType'].includes(name)) {
      let computedAmount =
      (old[index].unit - old[index].freeDays) * old[index].chargePerDay;

      old[index][name] = value;

      if(old[index].name != "Fuel"){
        if (computedAmount >= old[index].manualFinalAmount) {
          old[index]["finalAmount"] = computedAmount.toFixed(2);
        } else {
          old[index]["finalAmount"] = parseInt(
            old[index].manualFinalAmount
          ).toFixed(2);
        }
      }

      if((name == "perType" && value =="percentage")
      || (name !== "perType" && old[index]["perType"] == "percentage") ){
        if (old[index]["name"] !== 'Fuel') {
          old[index]["finalAmount"] = (this.state.base_price * old[index]["unit"] / 100).toFixed(2);
        }
      }
    }

    if (!old[index].chargeType || old[index].chargeType !== MANUALLY_CHANGED){
      old[index].chargeType = MANUALLY_CHANGED;
      delete old[index].subChargeType;
      delete old[index].customerRateId;
      delete old[index].dynamicPricingId;
      delete old[index].discountApplied;
    }
    this.setState({
      pricing: [...old],
    });
  }

  DeletePriceRow(index) {
    let data = [...this.state.pricing];
    let name = data[index].name
    data.splice(index, 1);
    let formErrors = [...this.state.formErrors];
    formErrors[index] = null;
    this.setState({
      pricing: data,
      formErrors: formErrors,
    }, () => {
      if(name) {
        this.updatePricing(null, null, null, true);
      }
    })
  }

  DeleteItemRow(index) {
    let data = [...this.state.items];
    data.splice(index, 1);
    this.setState({
      items: data,
    });
  }

  updatePricing(isSaveCustomerRates, isSendRateConfirmation, preferredRateType, isDeleted) {
    let isValid = true;
    if (this.state.pricing.filter((data) => !data.name).length > 0) {
      toastr.show("You must enter a name in the field.", "error");
      return;
    }
    if (
      this.state.pricing.filter((data) => data.finalAmount === "").length > 0
    ) {
      toastr.show("Please enter the missing field for your rate!", "error");
      return;
    }
    if (this.state.pricing.filter((data) => data.name === "").length > 0) {
      toastr.show("Please enter a name for your rate!", "error");
      return;
    }
    if(this.state.pricing.filter((data) =>parseFloat(data.unit) <0 ).length > 0){
      toastr.show("Unit Count must be greater than 0", "error");
      return;
    }
    const price = this.state.pricing;
    if (price && price.length) {
      price.forEach((p, i) => {
        // if (p.finalAmount) {
        //   isValid = isValid && parseFloat(p.finalAmount) >= 0;
        // }
        if (p.unit) {
          isValid = isValid && parseFloat(p.unit) >= 0;
        }
        if (p.freeDays) {
          isValid = isValid && parseFloat(p.freeDays) >= 0;
        }
        if (p.chargePerDay) {
          isValid = isValid && parseFloat(p.chargePerDay) >= 0;
        }
      });
    } else {
      isValid = false;
    }
    if(this.state.pricing.length===0){
      isValid = true;
    }
    var payload = {
      // items: this.state.items,
      pricing:   this.state.pricing.map((p) => {
        p.unit = parseFloat(p.unit).toFixed(2);
        p.finalAmount = parseFloat(p.finalAmount).toFixed(2); 
        return _.omit(p, "manualFinalAmount");
      }),
      isSaveCustomerRates: isSaveCustomerRates || false,
    };
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }

    if(preferredRateType) {
      payload.preferredRateType = preferredRateType;
    }

    if (isValid) {
      this.props.changeSavingLoadStatus &&
        this.props.changeSavingLoadStatus(true);
      UpdatePricing("tms/updatePricingLoad", payload)
        .then((response) => {
          let base_price = 0,
            x_charges = 0;

          response.data.data.pricing.map((x) => {
            if (x.name == "Base Price") {
              base_price += parseFloat(x.finalAmount);
            } else {
              x_charges = (parseFloat(x_charges) + parseFloat(x.finalAmount)).toFixed(2);
            }
          });
          let Total = (parseFloat(base_price) + parseFloat(x_charges)).toFixed(2);
          $(".reloadCurrentLoad").click();
          this.setState({
            pricing: response.data.data.pricing,
            base_price:base_price.toFixed(2),
            x_charges,
            Total,
          });

          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show(`Your pricing has been ${isDeleted ? "deleted!" : "updated!"}`, "success");
          if (isSendRateConfirmation) {
            this.setLoadEmails(
              this.props.selectedLoads
                ? this.props.selectedLoads
                : this.props.loads, rateConf
            );
          }
          this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(false);
        })
        .catch(() => {
          this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(false);
        });
    } else {
      toastr.show("Please enter all mandatory fields properly.", "warning");
    }
  }

  chkchange = (e, type) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser.role === "fleetmanager" && type === "rebill") {
      if(loggedInUser.permissions.includes("rebill_loads")) {
        this.setState(
          {
            REBILLING: false,
            [e.target.name]: e.target.checked,
            statusname: e.target.name,
          },
          () => {
            this.updateStatus();
          }
        );
      } else {
        toastr.show("Please contact your admin to allow the setting in permissions page for you to rebill", "error")
      }
    } else if (loggedInUser.role === "fleetmanager" && (type === "invoice" || type === "approve_invoice")) {
        if(loggedInUser.permissions.includes("approve_invoice")) {
          this.setState(
            {
              BILLING: false,
              [e.target.name]: e.target.checked,
              statusname: e.target.name,
            },
            () => {
              this.updateStatus();
            }
          );
        } else {
          toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
      }
    } else {
      this.setState(
        {
          APPROVED: false,
          UNAPPROVED: false,
          REBILLING: false,
          BILLING: false,
          APPROVEDBILLING: false,
          AUTOASSIGN: false,
          [e.target.name]: e.target.checked,
          statusname: e.target.name,
        },
        () => {
          this.updateStatus();
        }
      );
    }
  };

  // sortTable(sort) {
  //   if (this.state.sort == "name" && !this.state.asce) {
  //     this.setState({ sort: undefined, asce: true });
  //   } else {
  //     this.setState({
  //       sort,
  //       asce: this.state.sort !== sort ? true : !this.state.asce,
  //     });
  //   }
  // }
  // 39Iz2u&N
  invoice_create(){
    const payload = { reference_number: this.props.loads.reference_number }
    if(JSON.parse(getStorage("carrierDetail"))?.isNewInvoice)
        tmsAction.createInvoiceMain(payload).then((data) => {});
  }

  // 39Iz2u&N
  chkV1change(status){
      if(status==="BILLING"){
        this.invoice_create()
      }
  }
  // 39Iz2u&N
  invoice_billingDate(){
    let invoiceid
    if (this.props.loads) {
      invoiceid=this.props.loads.invoiceId && this.props.loads.invoiceId
    } else {
      invoiceid=this.props.selectedLoads.invoiceId && this.props.selectedLoads.invoiceId
    }
    if(invoiceid && JSON.parse(getStorage("carrierDetail"))?.isNewInvoice){
      const params = {
        billingDate:this.state.billing_date,
        invoiceId:invoiceid
      }
      tmsAction.updateInvoiceV1(params).then(() => {})
    }
  }

  // 39Iz2u&N
  invoice_create() {
    const payload = { reference_number: this.props.loads.reference_number }
    if (JSON.parse(getStorage("carrierDetail"))?.isNewInvoice)
      tmsAction.createInvoiceMain(payload).then((data) => { });
  }

  // 39Iz2u&N
  chkV1change(status) {
    if (status === "BILLING") {
      this.invoice_create()
    }
  }
  // 39Iz2u&N
  invoice_billingDate() {
    let invoiceid
    if (this.props.loads) {
      invoiceid = this.props.loads.invoiceId && this.props.loads.invoiceId
    } else {
      invoiceid = this.props.selectedLoads.invoiceId && this.props.selectedLoads.invoiceId
    }
    if (invoiceid && JSON.parse(getStorage("carrierDetail"))?.isNewInvoice) {
      const params = {
        billingDate: this.state.billing_date,
        invoiceId: invoiceid
      }
      tmsAction.updateInvoiceV1(params).then(() => { })
    }
  }

  updateStatus() {
    if (this.state.statusname) {
      const newStatus = this.state.statusname
      let selectedLoads, billing_date;
      const formData = new FormData();
      if (this.props.loads) {
        selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
      } else if (this.props.selectedLoads) {
        selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
      } else {
        selectedLoads = undefined;
      }
      if (this.state.billing_date) {
        billing_date = this.state.billing_date;
      }
      
      if (billing_date) {
        formData.append("billingDate", moment(billing_date).toISOString());
      }
      if (this.props.loads) {
        formData.append("reference_number", this.props.loads.reference_number);
      } else {
        formData.append(
          "reference_number",
          this.props.selectedLoads.reference_number
        );
      }
      if (this.state.statusname === "APPROVEDBILLING") {
        formData.append("status", "APPROVED");
        changeLoadStatus(formData).then((_data) => {
          const _formData = new FormData();
          if (this.props.loads) {
            _formData.append(
              "reference_number",
              this.props.loads.reference_number
            );
          } else {
            _formData.append(
              "reference_number",
              this.props.selectedLoads.reference_number
            );
          }
          _formData.append("status", "BILLING");
          changeLoadStatus(_formData).then((data) => {
            this.chkV1change("BILLING");  // 39Iz2u&N
            if (this.props.updateList) {
              this.props.updateList(data.data.data);
            } else {
              this.props.dispatch({
                type: TMS_GET_USER_LOAD,
                reference_number: this.props.selectedLoads
                  ? this.props.selectedLoads.reference_number
                  : this.props.loads.reference_number,
                payload: {
                  skip: this.state.activePage - 1,
                  limit: this.state.limit,
                },
              });             
            }
            this.setLoadEmails(
              this.props.selectedLoads
                ? this.props.selectedLoads
                : this.props.loads
            );
            this.setState({ showConfirmModel: true });
            toastr.show("This has been updated!", "success");
          });
        });
      } else {
        formData.append("status", this.state.statusname);
        changeLoadStatus(formData).then((data) => {
          // 39Iz2u&N
          if (newStatus === "BILLING") {
            this.chkV1change("BILLING")
          }
          if (this.props.updateList) {
            this.props.updateList(data.data.data);
          } else {
            this.props.dispatch({
              type: TMS_GET_USER_LOAD,
              reference_number: this.props.selectedLoads
                ? this.props.selectedLoads.reference_number
                : this.props.loads.reference_number,
              payload: {
                skip: this.state.activePage - 1,
                limit: this.state.limit,
              },
            });
          }
          if (newStatus === "BILLING") {
            this.setLoadEmails(
              this.props.selectedLoads
                ? this.props.selectedLoads
                : this.props.loads
            );
            this.setState({ showConfirmModel: true });
          }
          toastr.show("This has been updated!", "success");
        });
      }
    } else if (this.state.billing_date) {
      const formData = new FormData();
      formData.append(
        "billingDate",
        moment(this.state.billing_date).toISOString()
      );
      if (this.props.loads) {
        formData.append("reference_number", this.props.loads.reference_number);
      } else {
        formData.append(
          "reference_number",
          this.props.selectedLoads.reference_number
        );
      }
      changeLoadStatus(formData).then((data) => {
        if (this.props.updateList) {
          this.props.updateList(data.data.data);
        } else {
          this.props.dispatch({
            type: TMS_GET_USER_LOAD,
            reference_number: this.props.selectedLoads
              ? this.props.selectedLoads.reference_number
              : this.props.loads.reference_number,
            payload: {
              skip: this.state.activePage - 1,
              limit: this.state.limit,
            },
          });
        }
        toastr.show("The billing date has been updated.", "success");
      });
    } else {
      toastr.show("You must select a status.", "warning");
    }
  }
  updateBillingDate() {
    if (this.state.billing_date) {
      const formData = new FormData();
      formData.append(
        "billingDate",
        moment(this.state.billing_date).toISOString()
      );
      if (this.props.loads) {
        formData.append("reference_number", this.props.loads.reference_number);
      } else {
        formData.append(
          "reference_number",
          this.props.selectedLoads.reference_number
        );
      }
      changeLoadStatus(formData).then((data) => {
        if (this.props.updateList) {
          this.props.updateList(data.data.data);
        } else {
          this.props.dispatch({
            type: TMS_GET_USER_LOAD,
            reference_number: this.props.selectedLoads
              ? this.props.selectedLoads.reference_number
              : this.props.loads.reference_number,
            payload: {
              skip: this.state.activePage - 1,
              limit: this.state.limit,
            },
          });
        }
        toastr.show("The billing date has been updated.", "success");
      });
    } else {
      toastr.show("Please select a billing date.", "warning");
    }
  }

  arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  
  downloadInvoice(data){
    return new Promise((resolve, reject)=>{
      let load = {};
      if (this.props.loads) {
      load = this.props.loads
      } else {
       load = this.props.selectedLoads
      }
      let query = {};
      if(load.reference_number){
       query.reference_number = load.reference_number 
      } 
      query.typeOfDocument = invoice
                                                                                       
           getInvoiceDesign(query).then((result)=>{
             const fileName = `${
               this.props.loads
                 ? this.props.loads.reference_number
                 : this.props.selectedLoads.reference_number
             }-invoice.pdf`;
             var byteArray = new Uint8Array(result.data.data);
             var a = window.document.createElement("a");
             a.href = window.URL.createObjectURL(
               new Blob([byteArray], { type: "application/octet-stream" })
             );
             a.download = fileName;
             document.body.appendChild(a);
             a.click();
             document.body.removeChild(a);
             resolve(byteArray)
           })
        
    })
     
   }
   sendEmail(isDownload) {
    let progressField = "isEmailSendInProcess";
    let formData = new FormData();
    if (this.props.loads) {
      formData.append("reference_number", this.props.loads.reference_number);
    } else {
      formData.append(
        "reference_number",
        this.props.selectedLoads.reference_number
      );
    }
    if (this.state.inpSubject) {
      formData.append("subject", this.state.inpSubject);
    }
    if (this.state.inpBody) {
      formData.append("body", this.state.inpBody);
    }
    if (isDownload) {
      progressField = "isDownloadInProcess";
      formData.append("mergeDownload", true);
    }
    if (this.state.extraEmail.length > 0) {
      formData.append("email", this.state.extraEmail.join(","));
    }
    if (this.state.ccEmail.length > 0) {
      formData.append("cc", this.state.ccEmail.join(","));
    }
    if(this.state.isRateConfirm){
      formData.append("isRateConfirm", true)
    }
    this.setState({ [progressField]: true });
    sendBillingEmail(formData)
      .then((result) => {
        this.setState({ [progressField]: false, showConfirmModel: false });
        if (isDownload) {
          const fileName = `${
            this.props.loads
              ? this.props.loads.reference_number
              : this.props.selectedLoads.reference_number
          }-invoice.pdf`;
          var byteArray = new Uint8Array(result.data.data.data);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          toastr.show("Your email has been sent!", "success");
          this.setState({ showConfirmModel: false, isRateConfirm : false });
        }
      })
      .catch(() => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ [progressField]: false,  isRateConfirm : false });
      });
  }
  async send_Email(isDownload) {
    return new Promise((resolve, reject)=>{
      let load = {};
      let progressField = "";
      if (this.props.loads) {
      load = this.props.loads
      } else {
       load = this.props.selectedLoads
      }
      let query = {
        typeOfDocument: invoice,
      };
      if(this.state.isRateConfirm){
        query.typeOfDocument = rateConf
      }
      if (load.reference_number) {
        query.reference_number = load.reference_number 
      }       
      if (!isDownload) {
        query.isEmailing = true
      }
      if (!isDownload) {
        progressField = "isEmailSendInProcessNew";
       
      } else {
        progressField = "isDownloadInProcess";
      }
      this.setState({ [progressField]: true });
          if(isDownload)   {
            
            getInvoiceDesign(query).then((result)=>{
              const fileName = `${this.props.loads
                  ? this.props.loads.reference_number
                  : this.props.selectedLoads.reference_number
              }-invoice.pdf`;
              var byteArray = new Uint8Array(result.data[0].data);
              var a = window.document.createElement("a");
              a.href = window.URL.createObjectURL(
                new Blob([byteArray], { type: "application/octet-stream" })
              );
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              resolve(byteArray)
              this.setState({ [progressField]: false })
            }).catch(err => {toastr.show("Failed to download invoice","error"); this.setState({ [progressField]: false });})
          }  else{
          
            let formData = {};
            if (this.props.loads) {
              formData.reference_number = this.props.loads.reference_number
            } else {
              formData.reference_number= this.props.selectedLoads.reference_number
              
            }
            if (this.state.inpSubject) {
              formData.subject = this.state.inpSubject
            }
            if (this.state.inpBody) {
              formData.body= this.state.inpBody
            }
           
            if (this.state.extraEmail.length > 0) {
              formData.email= this.state.extraEmail.join(",")
            }
            if (this.state.ccEmail.length > 0) {
              formData.cc= this.state.ccEmail.join(",")
            }
             formData.typeOfDocument = query.typeOfDocument
            emailInvoice({ ...formData, ...query })
            .then(res => { 
              toastr.show("email sent", "success");
              this.setState({ [progressField]: false, showConfirmModel: false, isRateConfirm : false });
            }).catch(err => {
                     toastr.show("Something went wrong, Please try again", "error");
                     this.setState({ [progressField]: false,  isRateConfirm : false })})
          }                                                                     
          
        
                                                                    
    })
     
  
  
   
  }

  handleextraEmailChange(extraEmail) {
    this.setState({ extraEmail });
  }
  handleccEmailChange(ccEmail) {
    this.setState({ ccEmail });
  }

  handleChange(e) {
    const note = this.state[e.target.name];
    note.note = e.target.value;
    this.setState({ [e.target.name] : note });
    this.saveNotesDebounce(e.target.name,e.target.value);
  }

  dynamicStringByLoadInfo(str, data) {
    var allData = str.split(" ");
    var pattern = /\@(.*?)\@/g;
    allData.filter((obj) => {
      let vals = obj.match(pattern);
      if (vals) {
        vals.forEach((key) => {
          if (key === "@bolbkg@") {
            str = str.replace(key, data.bolbkg || "");
          } else if (key === "@referenceNo@") {
            str = str.replace(key, data.secondaryReferenceNo || "");
          } else if (key === "@invoiceNo@" || key === "@LoadNo@") {
            str = str.replace(key, data.reference_number || "");
          } else if (key === "@consigneeName@") {
            str = str.replace(key, data.consigneeName || "");
          } else if (key === "@weight@") {
            str = str.replace(key, data.totalWeight || "");
          } else {
            str = str.replace(key, data[key.substr(1, key.length - 2)] || "");
          }
        });
      }
    });
    return str;
  }

  setLoadEmails(load, document) {
      if(load && load.caller){
        this.setState({extraEmail: load.caller.billingEmail ? load.caller.billingEmail.split(',').filter(Boolean) : [load.caller.email]})
      }
    const updateState = {
      showConfirmModel: load,
    };
    const emails = [];
    const cc = [];
    let editorState;
    let carrierId;
    let body;
    let subject;
    if (getStorage("currentUserRole") == "fleetmanager") {
      let loggedInUser = JSON.parse(getStorage("loggedInUser"));
      carrierId = loggedInUser.fleetManager.carrier;
    } else {
      carrierId = getStorage("currentUserID");
    }
    
    body =
      this.state.settings &&
      this.state.settings.carrier &&
      this.state.settings.carrier.emailBody
        ? this.dynamicStringByLoadInfo(
            this.state.settings.carrier.emailBody,
            load
          )
        :  `<p>Please review attached billing ${document || invoice}s.</p>`;
    subject =
      this.state.settings &&
      this.state.settings.carrier &&
      this.state.settings.carrier.emailSubject
        ? this.dynamicStringByLoadInfo(
            this.state.settings.carrier.emailSubject,
            load
          )
        : `${document == rateConf ? rateConf : 'Billing'} for Load ${load.reference_number}`;
    // }

    const processedHTML = DraftPasteProcessor.processHTML(body);
    const contentState = ContentState.createFromBlockArray(processedHTML);
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    updateState.inpSubject = subject;
    updateState.inpBody = body;
    emails.push(load.caller.billingEmail || load.caller.email);
    if(document == rateConf){
      updateState.isRateConfirm  = true
    }else{
      updateState.isRateConfirm  = false
    }
    // if (load.caller.isEmailNeedToSend) {
    cc.push(load.carrier.carrier.billingEmail);
    cc.push(load.caller.extraEmail);
    // }
    if (load.customerEmployee && load.customerEmployee.length > 0) {
      load.customerEmployee.forEach((employee, i) => {
        if (employee.billingEmail) {
          cc.push(employee.billingEmail);
        }
      });
    }
    updateState.emails = emails.compact(true);
    updateState.cc = cc.compact(true);
    this.setState(updateState);
    this.setState({ editorState });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      inpBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  // updateFields(val, reference_number) {

  //   editLoad({ approvedDate: val, reference_number }).then(() => {
  //     toastr.show('Updated!', "success");
  //     this.setState({ editBillingDate: false })
  //   })
  // }

  redirectToRates = (chargeItem) => {
    if (chargeItem.chargeType === CHARGE_TYPES.CUSTOMER_RATES.name) {
      // window.open('/tms/load-price-settings?rateId=' + chargeItem.customerRateId, '_blank');
      browserHistory.push('/tms/load-price-settings?rateId=' + chargeItem.customerRateId);
    }
    else if (chargeItem.chargeType === CHARGE_TYPES.DYNAMIC_PRICING.name) {
      browserHistory.push('/dynamic-pricing?rateId=' + chargeItem.dynamicPricingId);
    }
  }

  confirmChargeConlicts = (index, charge, conflictedCharge, chargeCode) => {
    confirmCharge(
      (confirm, selectedCharge) => {
        if (confirm) {
          const _pricing = cloneDeep(this.state.pricing);
          _pricing[index] = selectedCharge;

          const otherConflicts = this.props.chargeConflicts && this.props.chargeConflicts.filter((cc) => cc.name !== selectedCharge.name);
          if (selectedCharge.chargeType === CHARGE_TYPES.DYNAMIC_PRICING.name && otherConflicts.length > 0) {
            otherConflicts.forEach((cc) => {
              const cIndex = this.state.pricing.findIndex((price) => price.name === cc.name);
              if (cIndex >= -1) _pricing[cIndex] = cc;
            })
          }

          this.setState({ pricing: _pricing }, () => {
            this.updatePricing(null, null, selectedCharge.chargeType);
          });
        }
      },
      {
        charge,
        conflictedCharge,
        chargeCode
      }
    );
  }

  // for subscribing to firebase
  subscribeToFirebase = () => {
    this.unsubscribeFromFirebase();
    const currentUserRole = JSON.parse(getStorage("currentUser"));
    let carrierId = '';
    if(currentUserRole!=='carrier'){
      const carrierDetail = JSON.parse(getStorage("carrierDetail"));
      carrierId = carrierDetail._id;
    }else{
      const carrierDetail = JSON.parse(getStorage("loggedInUser"));
      carrierId = carrierDetail._id;
    }
    this.firebaseRef.current = newDb.ref(`${carrierId}/lastUpdatedLoad`);
    this.firebaseRef.current.on('value', (snapshot) => {
      try {
        const values=snapshot.val();
        const data=values && JSON.parse(values)
        this.setState({approvalStatus:data.approvalStatus})
      } catch (error) {
        console.error("Error: ", error)
      }
    });
  }

  // for unsubscribing to firebase
  unsubscribeFromFirebase = () => {
    if(this.firebaseRef.current) {
      this.firebaseRef.current.off();
    }
  }
  renderReturn = () => {
    var disabled = true;
    var billing_date = null;
    var selectedLoads;
    if (this.props.loads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    if (selectedLoads) {
      disabled = [
        "APPROVED",
        "BILLING",
        "REBILLING",
        "PARTIAL_PAID",
        "FULL_PAID",
      ].includes(selectedLoads.status);
      billing_date = selectedLoads.billingDate;
    }
    let showSave = false;
    if (selectedLoads) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ==
          selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") == selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "customer" &&
        getStorage("currentUserID") == selectedLoads.addedBy._id
      ) {
        showSave = true;
      }
      else if (
        JSON.parse(getStorage("loggedInUser")).role === "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
      ) {
        disabled = true;
      }
      else if (
        ["customer", "fleetcustomer"].includes(JSON.parse(getStorage("loggedInUser")).role)
      ) {
        disabled = true;
      }
    }
    
    let { pricing } = this.state;
    // if (this.state.sort) {
    //   pricing = orderBy(
    //     pricing,
    //     (charge) => charge[this.state.sort].toLowerCase(),
    //     !this.state.asce && "desc"
    //   );
    // }
    const { formErrors } = this.state;
    const {chargeCode} = this.props;
    let chargeCodeList = [];
    if(chargeCode && chargeCode.length) {
      chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");
    }
    console.log(selectedLoads)
    return (
      <React.Fragment>
        <div className="tab mt-10">
          <div className="card p-15 d-flex flex-row align-items-center justify-content-between mb-10">
            <ul className="nav nav-compact" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link  ${
                    this.state.isCustomerTabActive === "main-customer" &&
                    "active"
                  }`}
                  href="#!"
                  onClick={() => {
                    if(this.props.setBillingActiveTab){
                      this.props.setBillingActiveTab("main-customer")
                      this.handleCustomerTabActive("main-customer")
                    }
                  }}
                >
                  Main Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link  ${
                    this.state.isCustomerTabActive === "sub-customer" &&
                    "active"
                  }`}
                  href="#!"
                  onClick={() => {
                    if(this.props.setBillingActiveTab) {
                      this.props.setBillingActiveTab("sub-customer")
                      this.handleCustomerTabActive("sub-customer")
                    }
                  }}
                >
                  Sub Customer
                </a>
              </li>
            </ul>
            {this.state.isCustomerTabActive === "sub-customer" ? (
              <button
                className="btn btn-primary"
                onClick={() => this.addCustomerPriceRows()}
              >
                <IconPlus className="mr-2" />
                Add Sub Bill
              </button>
            ) : null}
          </div>
          {this.state.isCustomerTabActive === "main-customer" && (
            <div id="main-customer">
              <div className="card mb-0 p-15 d-flex flex-row justify-content-between">
                <ul className="nav nav-compact" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "bill" && "active"
                      }`}
                      href="#!"
                      onClick={() => this.handleSubExpBillTabActive("bill")}
                    >
                      Invoice
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "sub_expense" &&
                        "active"
                      }`}
                      href="#!"
                      onClick={() =>
                        this.handleSubExpBillTabActive("sub_expense")
                      }
                    >
                      Expense
                    </a>
                  </li>
                </ul>
                <div className="d-flex flex-row align-items-center">
                  {selectedLoads &&
                    ["BILLING", "PARTIAL_PAID"].indexOf(selectedLoads.status) >
                      -1 && (
                      <button
                        className="ml-auto btn btn-outline-light mr-2"
                        onClick={() => {
                          this.setLoadEmails(
                            this.props.selectedLoads
                              ? this.props.selectedLoads
                              : this.props.loads
                          );
                        }}
                      >
                        <IconSendEmail className="mr-2" />
                        Email
                      </button>
                    )}

                  <a
                    download={`${
                      this.props.selectedLoads &&
                      this.props.selectedLoads.reference_number
                    }-invoice.pdf`}
                    id="downloadPdf"
                    href={this.state.downloadedContent}
                  ></a>

                  {this.state.isSubExpBillTabActive === "bill" && 
                   
                
                    <button
                      disabled={this.state.isDownloadInProcess}
                      className="btn btn-outline-light "
                      onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ? this.send_Email(true) :  this.sendEmail(true)}
                    >
                      {this.state.isDownloadInProcess  ? (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <IconDownload className="mr-2" />
                      )}
                      Download PDF
                    </button> 
                    }
                  
                </div>
              </div>

              <div className="mb-1">
                {this.state.isSubExpBillTabActive === "bill" && (
                  <div id="bills">
                    {(this.state.approvalStatus && pricing.length>0 && selectedLoads.status==='BILLING') &&
                      <div className="d-flex p-15 mb-10 justify-content-end">
                        <button class={`btn btn-sm mr-2 ${this.state.approvalStatus==='PENDING' ? 'btn-primary' : this.state.approvalStatus==='REJECTED' ?'btn-danger':'btn-success'}`}>INVOICE APPROVAL: {this.state.approvalStatus}</button>
                      </div>
                     }
                    <table className="table table-card mb-1">
                      <thead>
                        <tr>
                          <th width="200">Name of Charge #</th>
                          <th width="300">Description</th>
                          <th width="210">Price</th>
                          <th width="160" className="z-3">Unit Count</th>
                          <th width="150" className="z-3">Unit of Measure</th>
                          <th width="100">Free Units</th>
                          <th width="100">Per Units</th>
                          <th width="42"></th>
                          <th width="42"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pricing &&
                          pricing.length > 0 &&
                          pricing.map((item, key) => {
                            let conflictedCharge = null;
                            if(this.props.chargeConflicts && this.props.chargeConflicts.length) {
                              conflictedCharge = this.props.chargeConflicts.find((charge) => charge.name === item.name)
                            }
                            let chargeValue = chargeCodeList && chargeCodeList.find((d) => d.value === item.name)
                            let charges = chargeCodeList && chargeCodeList.filter((d) => d.isActive)
                            return (
                              <tr key={key} className={conflictedCharge && "tr-active"}>
                                <td>
                                  <div className={conflictedCharge && "input-border-red"}>
                                    <CustomSelect
                                      isDisabled={disabled}
                                      name={"name"}
                                      options={charges && charges.map((charge) => ({label: charge.chargeName, value: charge.value}))}
                                      value={chargeValue  ? {label: chargeValue.chargeName, value: chargeValue.value} : null}
                                      size="small"
                                      placement="right"
                                      placeholder="Select..."
                                      onChange={({ value }) =>
                                        this.pricingchanges(key, {
                                          target: { value, name: "name" },
                                        })
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={`form-control ${conflictedCharge && "input-border-red"}`}
                                    name="description"
                                    value={item.description ? item.description : ""}
                                    onChange={(e) =>
                                      this.pricingchanges(key, e)
                                    }
                                    disabled={disabled}
                                  />
                                </td>

                                {item.name === "Fuel" ? (
                                  <td className="lint">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="mr-1 d-flex w-100">
                                        <input
                                          className="form-control"
                                          type="number"
                                          disabled={disabled}
                                          name="amount"
                                          min={0}
                                          value={item.amount}
                                          onChange={(e) =>
                                            this.pricingchanges(key, e)
                                          }
                                        />
                                      </div>
                                      <div className="mr-1 d-flex w-100">
                                        <CustomSelect
                                          size="small"
                                          placement="right"
                                          placeholder="Select..."
                                          className="w-100"
                                          isDisabled={disabled}
                                          options={[
                                            {
                                              value: "fixed",
                                              label: "$",
                                            },
                                            {
                                              value: "percentage",
                                              label: "%",
                                            },
                                          ]}
                                          value={
                                            item.type && item.type === "fixed"
                                              ? {
                                                  value: "fixed",
                                                  label: "$",
                                                }
                                              : {
                                                  value: "percentage",
                                                  label: "%",
                                                }
                                          }
                                          onChange={({ value }) =>
                                            this.pricingchanges(key, {
                                              target: {
                                                value,
                                                name: "type",
                                              },
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="d-flex w-100">
                                        <input
                                          min={0}
                                          onKeyDown={(e) => {
                                            this.keyPressOnNumberInput(e);
                                          }}
                                          className="form-control"
                                          type="number"
                                          disabled={disabled}
                                          name="finalAmount"
                                          value={item.finalAmount}
                                          onChange={(e) =>
                                            this.pricingchanges(key, e)
                                          }
                                        />
                                        {formErrors &&
                                          formErrors[key] &&
                                          formErrors[key].finalAmount && (
                                            <small style={{ color: "red", position: "absolute" }}>
                                              {formErrors &&
                                                formErrors[key] &&
                                                formErrors[key].finalAmount}
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td>
                                    <span data-tip data-for={`discount${key}`}>
                                      <input
                                        min={0}
                                        type="number"
                                        className={`form-control ${conflictedCharge 
                                          ? "input-border-red" 
                                          : (item.discountApplied && "border-2 border-primary rounded")}`}
                                        disabled={disabled}
                                        name="finalAmount"
                                        value={item.finalAmount}
                                        onChange={(e) =>
                                          this.pricingchanges(key, e)
                                        }
                                      />
                                    </span>
                                    {formErrors &&
                                      formErrors[key] &&
                                      formErrors[key].finalAmount && (
                                        <small style={{ color: "red", position: "absolute" }}>
                                          {formErrors &&
                                            formErrors[key] &&
                                            formErrors[key].finalAmount}
                                        </small>
                                      )}
                                    {item.discountApplied &&
                                      <ReactTooltip id={`discount${key}`}>
                                        <span>A discount has been applied</span>
                                      </ReactTooltip>
                                    }
                                  </td>
                                )}

                                <td>
                                  <div className={conflictedCharge && "input-border-red"}>
                                    <PriceInput
                                      name="unit"
                                      value={item.unit}
                                      onChange={(val) => {
                                        this.pricingchanges(key, {
                                          target: {
                                            value: val,
                                            name: "unit",
                                          },
                                        });
                                      }}
                                      disabled={disabled}
                                    />
                                  </div>
                                  {formErrors &&
                                    formErrors[key] &&
                                    formErrors[key].unit && (
                                      <small style={{ color: "red", position: "absolute" }}>
                                        {formErrors &&
                                          formErrors[key] &&
                                          formErrors[key].unit}
                                      </small>
                                    )}
                                </td>

                                <td>
                                  <div className={conflictedCharge && "input-border-red"}>
                                    <CustomSelect
                                      className="w-80"
                                      size="small"
                                      placement="right"
                                      placeholder="Select..."
                                      options={options}
                                      value={
                                        item.perType &&
                                        options.find(
                                          (op) => op.value === item.perType
                                        )
                                      }
                                      onChange={({ value }) =>
                                        this.pricingchanges(key, {
                                          target: {
                                            value,
                                            name: "perType",
                                          },
                                        })
                                      }
                                      isDisabled={disabled}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    min={0}
                                    type="number"
                                    className={`form-control ${conflictedCharge && "input-border-red"}`}
                                    name="freeDays"
                                    onKeyDown={(e) => {
                                      this.keyPressOnNumberInput(e);
                                    }}
                                    disabled={!item.perType || disabled || item.perType === "percentage" }
                                    value={item.freeDays ? item.freeDays : null}
                                    onChange={(e) =>
                                      item.perType &&
                                      this.pricingchanges(key, e)
                                    }
                                    placeholder="0"
                                  />
                                  {formErrors &&
                                    formErrors[key] &&
                                    formErrors[key].freeDays && (
                                      <small style={{ color: "red", position: "absolute" }}>
                                        {formErrors &&
                                          formErrors[key] &&
                                          formErrors[key].freeDays}
                                      </small>
                                    )}
                                </td>

                                <td>
                                  <input
                                    min={0}
                                    type="number"
                                    className={`form-control ${conflictedCharge && "input-border-red"}`}
                                    name="chargePerDay"
                                    onKeyDown={(e) => {
                                      this.keyPressOnNumberInput(e);
                                    }}
                                    disabled={!item.perType || disabled}
                                    value={item.chargePerDay ? item.chargePerDay : null}
                                    onChange={(e) =>
                                      item.perType &&
                                      this.pricingchanges(key, e)
                                    }
                                    placeholder="0"
                                  />
                                  {formErrors &&
                                    formErrors[key] &&
                                    formErrors[key].chargePerDay && (
                                      <small style={{ color: "red", position: "absolute" }}>
                                        {formErrors &&
                                          formErrors[key] &&
                                          formErrors[key].chargePerDay}
                                      </small>
                                    )}
                                </td>
                                <td>
                                  {conflictedCharge
                                  ? <div>
                                      <span data-tip data-for={`charge_type${key}`} className="text-danger" onClick={() => this.confirmChargeConlicts(key, item, conflictedCharge, chargeCode)}>
                                        <IconInfoFocus />
                                      </span>
                                      <ReactTooltip id={`charge_type${key}`}>
                                        <span>
                                          Conflict Charge, Click to View
                                        </span>
                                      </ReactTooltip>
                                    </div>
                                  : (item.chargeType &&
                                    <div>
                                      <span data-tip data-for={`charge_type${key}`} onClick={() => this.redirectToRates(item)}>
                                        <IconNoteArrowRight />
                                      </span>
                                      <ReactTooltip id={`charge_type${key}`}>
                                        <span>
                                          {item.subChargeType
                                            ? CHARGE_TYPES[item.subChargeType].title
                                            : CHARGE_TYPES[item.chargeType].title
                                          }
                                        </span>
                                      </ReactTooltip>
                                    </div>)
                                  }
                                </td>
                                {showSave && (
                                  <td>
                                    <button
                                      className="btn btn-xs btn-circle bg-soft-danger"
                                      disabled={disabled}
                                      onClick={() => this.DeletePriceRow(key)}
                                    >
                                      <IconMinus className="text-danger" />
                                    </button>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <div className="card rounded-0 mb-0 p-15">
                      <div>
                        <a
                          className="text-primary font-weight-500"
                          onClick={() => !disabled && this.AddPriceRows()}
                        >
                          <IconPlus className="mr-2" />
                          Add Charge
                        </a>
                      </div>
                      
                    </div>
                  </div>
                )}
                {this.state.isSubExpBillTabActive === "sub_expense" && (
                  <div id="expenses">
                    <LoadExpense
                      loads={this.props.loads}
                      id={this.props.loads.reference_number}
                      key={`sub_${this.props.loads.reference_number}`}
                      handleExpenseUpdate={(submit) => {
                        this.handleExpenseUpdate = submit;
                      }}
                      changeSavingLoadStatus={this.props.changeSavingLoadStatus}
                      chargeCodeList={chargeCodeList}
                    />
                  </div>
                )}

                <div className="card d-flex flex-row align-items-center justify-content-between mt-1 mb-3 p-15 form-check-old">
                  <div className="d-flex flex-row">
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.APPROVED === true &&
                        selectedLoads &&
                        !(
                          ["COMPLETED", "UNAPPROVED"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-gray-300"
                          : this.state.APPROVED === true
                          ? "badge-brand-300"
                          : "badge-light"
                      }`}
                    >
                      {/* <Form.Check type="checkbox" label="Approve" className="rounded-checkbox text-white" /> */}
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input--radio-check"
                          value="approve"
                          name="APPROVED"
                          id="chkApproved"
                          checked={
                            this.state.APPROVED === true &&
                            selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                          onChange={(e) => this.chkchange(e)}
                          disabled={
                            selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                        />
                        <label className="form-check-label" for="chkApproved">
                          Approve
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.UNAPPROVED === true &&
                        selectedLoads &&
                        !(
                          ["APPROVED", "REBILLING"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-gray-300"
                          : this.state.UNAPPROVED === true
                          ? "badge-brand-300"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check"
                          type="checkbox"
                          name="UNAPPROVED"
                          id="chkUnapproved"
                          checked={this.state.UNAPPROVED}
                          onChange={(e) => this.chkchange(e)}
                          disabled={
                            selectedLoads &&
                            !(
                              ["APPROVED", "REBILLING"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                        />
                        <label className="form-check-label" for="chkUnapproved">
                          Unapprove
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.REBILLING &&
                        (this.state.UNAPPROVED ||
                          (selectedLoads && selectedLoads.status !== "BILLING"))
                          ? "badge-gray-300"
                          : this.state.REBILLING === true
                          ? "badge-brand-300"
                          : "badge-light"
                      }`}
                    >
                      {/* <Form.Check type="checkbox" label="Rebill" className="rounded-checkbox" /> */}
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check"
                          type="checkbox"
                          name="REBILLING"
                          id="chkRebill"
                          checked={this.state.REBILLING}
                          // checked={
                          //   this.state.REBILLING
                          //   === true && this.state.UNAPPROVED ||
                          //   (selectedLoads &&
                          //     selectedLoads.status !== "BILLING")
                          // }
                          onChange={(e) => this.chkchange(e, "rebill")}
                          disabled={
                            this.state.UNAPPROVED ||
                            (selectedLoads &&
                              selectedLoads.status !== "BILLING")
                          }
                        />
                        <label className="form-check-label" for="chkRebill">
                          Rebill
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.BILLING &&
                        (this.state.UNAPPROVED ||
                          (selectedLoads &&
                            !(
                              ["APPROVED", "REBILLING"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )))
                          ? "badge-gray-300"
                          : this.state.BILLING === true
                          ? "badge-brand-300"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check"
                          type="checkbox"
                          name="BILLING"
                          id="chkBilling"
                          checked={this.state.BILLING}
                          onChange={(e) => this.chkchange(e, "invoice")}
                          disabled={
                            this.state.UNAPPROVED ||
                            (selectedLoads &&
                              !(
                                ["APPROVED", "REBILLING"].indexOf(
                                  selectedLoads.status
                                ) > -1
                              ))
                          }
                        />
                        <label className="form-check-label" for="chkBilling">
                          Invoice
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-2 ${
                        this.state.APPROVEDBILLING &&
                        selectedLoads &&
                        !(
                          ["COMPLETED", "UNAPPROVED"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-gray-300"
                          : this.state.APPROVEDBILLING
                          ? "badge-brand-300"
                          : "badge-light"
                      }`}
                    >
                      {/* <Form.Check type="checkbox" label="Approve & Invoice" className="rounded-checkbox" /> */}
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check"
                          type="checkbox"
                          name="APPROVEDBILLING"
                          id="chkApprovedBilling"
                          checked={this.state.APPROVEDBILLING}
                          onChange={(e) => this.chkchange(e, "approve_invoice")}
                          disabled={
                            selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          for="chkApprovedBilling"
                        >
                          Approve & Invoice
                        </label>
                      </div>
                    </div>
                    
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="text-dark font-weight-500 mr-3">
                      Billing Date
                    </span>
                    <div className="input-wrapper">
                      <DateWrapper 
                        placeholder = "Select Date.."
                        displayTimeZone={getStorage("timeZone")}
                        onChange={(e) =>
                          this.setState({
                            // billing_date: e.toISOString(),
                            billing_date: moment(e).toISOString()
                          })
                        }
                        defaultDate = {
                          this.state.billing_date
                            ? moment(this.state.billing_date)
                            : billing_date
                            ? moment(billing_date)
                            : ""
                        }
                        OnBlurUpdate = {()=>{this.invoice_billingDate();this.updateBillingDate()}} 
                        hideShowSelectDate={true}
                        isShowTime = {false}
                        className = 'form-control transition-white-field right-0'
                      />
                      <div
                        className="input-icon"
                        onClick={() => this.updateBillingDate()}
                      >
                        <IconCalendar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row no-gutters">
                <div className="col-md-4">
                  <div className="card rounded-5 p-20 mr-3">
                    <h5 className="mb-4 font-weight-bold">
                      <CustomIconNotes className="mr-2" />
                      Summary
                    </h5>
                    <div className="summary">
                      <div className="row mb-10">
                      <div className="col-6 text-muted font-size-regular">Base Price</div>
                        <div className="col-6 font-size-regular text-right">
                          <NumberFormat
                            value={this.state.base_price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-6 text-muted">
                          Accessorials
                        </div>
                        <div className="col-6 text-right">
                          <NumberFormat
                            value={this.state.x_charges}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row mb-10">
                        <div className="col-6 font-size-regular font-weight-500 text-primary">
                          Total
                        </div>
                        <div className="col-6 font-size-medium font-weight-500 text-primary text-right">
                          <NumberFormat
                            value={this.state.Total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card rounded-5 p-20 mr-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-4 font-weight-bold">Billing Notes</h5>
                      {this.state.isLoading &&
                        <LoaderBar></LoaderBar>
                      }
                      {this.state.billingNote.createdAt && this.state.billingNote.createdBy && 
                        (<h6 className="font-10 text-gray-500">Last Edited By {this.state.billingNote.createdBy} <br/>
                          at {moment(this.state.billingNote.createdAt).tz(timeZone)?.format(`${this.userTimeFormat ? 'MM/DD/YY HH:mm' : 'MM/DD/YY hh:mm A'}`)}</h6>)
                      }
                    </div>
                    <textarea
                      name="billingNote"
                      type="message"
                      className="border rounded px-3 pt-3 text-break font-size-regular"
                      style={{ minHeight: "105px", resize: "none" }}
                      value={this.state.billingNote.note ? this.state.billingNote.note : ""}
                      onChange={(e) =>{
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card rounded-5 p-20">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-4 font-weight-bold">Load Notes</h5>
                      {this.state.isLoading &&
                        <LoaderBar></LoaderBar>
                      }
                      {this.state.officeNote.createdBy && this.state.officeNote.createdAt && 
                        (<h6 className="font-10 text-gray-500">Last Edited By {this.state.officeNote.createdBy} <br/>
                        at {moment(this.state.officeNote.createdAt).tz(timeZone)?.format(`${this.userTimeFormat ? 'MM/DD/YY HH:mm' : 'MM/DD/YY hh:mm A'}`)}</h6>)
                      }
                    </div>
                    <textarea
                      name="officeNote"
                      type="message"
                      className="border rounded px-3 pt-3 font-size-regular"
                      style={{ minHeight: "105px", resize: "none" }}
                      value={this.state.officeNote.note ? this.state.officeNote.note : ""}
                      onChange={(e) =>{
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.isCustomerTabActive === "sub-customer" && (
            <div id="sub-customer">
              {selectedLoads && (
                <AdditionalPricing
                  selectedLoads={selectedLoads}
                  load={selectedLoads}
                  TMSCustomers={this.props.TMSCustomers}
                  updateList={this.props.updateList}
                  addCustomerPriceRows={(addrow) => {
                    this.addCustomerPriceRows = addrow;
                  }}
                  updateAdditionalPricing={(submit) => {
                    this.updateAdditionalPricing = submit;
                  }}
                  changeSavingLoadStatus={this.props.changeSavingLoadStatus}
                />
              )}
            </div>
          )}

          {/* {this.state.isCustomerTabActive === 2 && <SubCustomer />} */}
        </div>

        {/* Send Email Modal */}
        <Modal show={this.state.showConfirmModel} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label className="col-form-label">To</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.extraEmail.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };
                      return val;
                    })}
                    onChange={(val) => {
                      this.handleextraEmailChange(
                        _.compact(
                          val.map((i) => {
                            if (!validateEmail(i.value)) return i.value;
                          })
                        )
                      );
                    }}
                  />

                  <span className="text-gray-400 d-block mt-1">
                    You can add multiple email addresses
                  </span>
                </div>
                <div className="form-group">
                  <label className="col-form-label">CC/BCC</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.ccEmail.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };

                      return val;
                    })}
                    onChange={(val) => {
                      this.handleccEmailChange(
                        _.compact(
                          val.map((i) => {
                            if (!validateEmail(i.value)) return i.value;
                          })
                        )
                      );
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    value={this.state.inpSubject}
                    onChange={(e) => {
                      this.setState({ inpSubject: e.target.value });
                    }}
                  />
                </div>
                <div className="editor">
                  <label className="col-form-label">Message</label>
                  <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    ref="draftRef"
                    onEditorStateChange={this.onEditorStateChange}
                    handlePastedText={() => false}
                    editorClassName="editorClassName"
                    placeholder="Enter Messsage"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => this.setState({ showConfirmModel: false })}
            >
              Cancel
            </button>
            
           
             <button
              disabled={
                this.state.isEmailSendInProcessNew ||
                this.state.extraEmail.length === 0
              }
              className="btn btn-primary"
              onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ? this.send_Email() : this.sendEmail()}
            >
              {this.state.isEmailSendInProcessNew ? (
                <i className="uil uil-cloud-download mr-1"></i>
              ) : (
                ""
              )}
              Send Email
            </button> 
            
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_billing") ||
          (loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer &&
            loggedInUser.permissions.includes("customer_employee_load_billing"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillOfLading);

const options = [
  { value: "perday", label: "perday" },
  { value: "perhour", label: "perhour" },
  { value: "permile", label: `per${convertUnit().toLowerCase()}` },
  {
    value: "perpound",
    label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
  },
  { value: "fixed", label: "fixed" },
  { value: "percentage", label: "percentage" },
];

const CHARGE_TYPES = {
  CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
  DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
  SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
  MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
  PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
  ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
  CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
};