import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams';
import _ from 'lodash';
import { TMS_UPDATE_STATE } from '../constant';

export function resetCustomerPassword(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'user/customer-resetpassword', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSCustomerAudit(data) {
  let url = 'carrier/getTMSCustomerAudit';
  url = data ? url + '?customerId=' + data : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSCustomerCount(data) {
  let url = 'carrier/getTMSCustomerCount';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getCustomersByIDS(data) {
  let url = "carrier/getTMSCustomers";
  url = data ? url + "?" + jsonToQueryParams(data) : url;

  return new Promise((resolve, reject) => {
    HTTP("get", url, data, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const customers = _.get(response, "data.data", [])

        resolve(customers)
      })
      .catch(error => {
        console.log("---- getCustomersByIDS error -----", error)
        reject(error);
      })
  })
}


export function addCustomerTags(params) {
  let url = 'tms/addCustomerTag';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCustomerTags(params) {
  let url = 'tms/getCustomerTags';
  if(params)
    url+="?"+jsonToQueryParams(params)
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, {},{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function listAllFleetManagers(params, limit) {
  let obj = { ...params, limit: 10 }

  if (limit && typeof(limit)==='number') {
    if (params){
      obj = { ...params, limit}
    } else {
      obj = { limit }
    }
  }else{
    delete obj.limit
  }

  let url = 'carrier/getFleetManagers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          let allDatas = result.data.data;
          if(limit && typeof(limit)==='number'){
              resolve(allDatas);
          }
          let fleetManagers = []
          allDatas.forEach((obj) => {
            fleetManagers.push({ value: obj._id, label: `${obj.name}`, allInfos: obj });
          });
          resolve(fleetManagers);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
 
export function getTMSCustomerById(params) {
  let url = 'carrier/getTMSCustomerById';
  url = params ? url + "?" + jsonToQueryParams(params) : url;

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          const data = result.data ? result.data.data :  {}

          dispatch({ type: TMS_UPDATE_STATE, payload: data })
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function getAllCustomers(params) {
  let url = 'carrier/getTMSCustomers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function assignCSRtoCustomer(params){
  let url = 'carrier/assignCSRtoCustomer';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data) resolve(result.data.data );
    })
    .catch((error) => {
      reject(error);
    });
  })
}
export function assignCSRtoLoad (params){
  let url = 'carrier/assignCSRtoLoad';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data) resolve(result.data.data );
    })
    .catch((error) => {
      reject(error);
    });
  })
} 

export function uploadDocumentCustomer (params) {
  let url = 'carrier/addCustomerDocument';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      resolve(result?.data?.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}
