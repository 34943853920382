import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomIconAlert } from "../CustomIcons/Index";

const confirm = (title, message, callback, options) => {
  confirmAlert({
    title,
    message,
    onClickOutside: () => {
      callback(false);
    },
    customUI: ({ title, message, onClose }) => {
      const showInputText =
        options && options.showInputText ? options.showInputText : false;
      const showCheckBox =
        options && options.showCheckBox ? options.showCheckBox : false;
      const defaultValue =
        options && options.defaultValue ? options.defaultValue : null;

      return (
        <div className="custom-ui card">
          <div className={`modal-header ${showInputText && 'd-none'}`}>
            <h4 class="modal-title" id="myModalLabel">
              {title}
            </h4>
          </div>

          <div className="modal-body">
            <div className={`${!showInputText && 'text-center'}`}>
              <div className="mb-30">
              {!showInputText && (
               <CustomIconAlert />
              )}
                
              </div>
              {showInputText &&
              <h4 className="modal-title mb-1">{title}</h4>
              }
              <p className="text-muted font-14">{message}</p>
              {showInputText && (
                <div className="form-group w-150 mt-20">
                  <input
                    type="number"
                    className="form-control"
                    defaultValue="1"
                    min="1"
                    max="50"
                    id="count"
                    style={{ width: 150 }}
                  />
                </div>
               
              )}
              {showCheckBox && (
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="carryOverPricing"
                  />
                  <label
                    className="form-check-label"
                    style={{ overflowWrap: "break-word" }}
                    htmlFor="carryOverPricing"
                  >
                   Do Not Carry Over Pricing
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-close"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                callback(
                  true,
                  showInputText ? document.getElementById("count").value : null,
                  showCheckBox
                    ? !document.getElementById("carryOverPricing").checked
                    : null
                );
                onClose();
              }}
            >
              {showInputText ? 'Confirm' : 'Yes' }
            </button>
          </div>
        </div>
      );
    },
  });
};

export default confirm;
