import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import { getStorage } from './../services/Common.services';

const primaryFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});

const driverLocationFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DRIVER_LOCATION_DATABASEURL ?? process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}, "DriverLocation");

// testid 5ae8972b5eb3b02839cad0e1
global.config = {
  map_box_api_key: 'pk.eyJ1IjoiYmlrcmFtYmFzbmV0IiwiYSI6ImNqNXZhMjZtMDIzb3MzM3M2dHBpM3Z2Nm8ifQ.axKcLyOqjTsIyQ17MtnoeQ',
  publishKey: 'pub-c-removed',
  subscribeKey: 'sub-c-removed',
  baseUrl: process.env.REACT_APP_API_URL|| 'https://testapidev.app.portpro.io/',
  docUrl: process.env.REACT_APP_DOC_API_URL || 'https://tmx-doc.axle.network/',
  pdfUrl: process.env.REACT_APP_PDF_API_URL || 'https://tmx-pdf-api.app.portpro.io/' ,
  newDocUrl : process.env.REACT_APP_DOC_DESIGN_URL || 'https://new-doc-dev.axle.network/',
  ediUrl: process.env.REACT_APP_EDI_URL || 'https://dev.edi.axle.network/',
  publicApi: process.env.REACT_APP_PORTPRO_PUBLIC_API || 'https://demo-staging.app.portpro.io/v1/',
  quotesRatesScriptURL:process.env.REACT_APP_QUOTESRATES_SCRIPT,
  devUrl:'https://stagingapi.app.portpro.io/',
  localUrl: 'http://localhost:8081/',
  localPublicUrl:"http://localhost:5000/",
  socketUrl: 'https://testapi.app.portpro.io/',
  idForTerminal: getStorage("idForTerminal") || [],
  idForCarrier:['5ae8972b5eb3b02839cad0e1','606c912832bce740e2a297fe','607d7623b8e7522545be3f13'],
  idForCompany:['60196e05993170084efd0f4d','5ae8972b5eb3b02839cad0e1','60747b8448cb687a0f377e82'],
  idForCanada:['5ae8972b5eb3b02839cad0e1','6058a1f9afecf214ec2fab2b','60196e05993170084efd0f4d'],
  idForCanadaSettings:['5ae8972b5eb3b02839cad0e1','6058a1f9afecf214ec2fab2b'],
  idForCustomChargeReport: getStorage("idForCustomChargeReport") || [],
  pubNubPublishKey: 'pub-c-6f5631a5-1b5e-48ca-9f26-526897f613bb',
  pubNubSubscribeKey: 'sub-c-6de51438-0fe3-11e9-a971-425ad67106f3',
  prodFe: "https://app.portpro.io/tms/ai",
  devFe: "https://dev.app.portpro.io/tms/ai",
  localFe: "http://localhost:3000/tms/ai",
  version:'3.0.23',
  newModal: false,
  wpUrl:process.env.REACT_APP_WP || 'https://portprolearning.com/wp-json/wp/v2/',
  TRACKOSAPI:process.env.REACT_APP_TRACKOSAPI || "https://dev-api.drayos.com/api",
  sessionTimeoutDuration: process.env.REACT_APP_SESSION_TIMEOUT_DURATION ?? 1800000,
}

const getNewFirebaseInstance = (appName) => {
  return firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  }, appName);
}

global.config.firebase = primaryFirebaseApp;
global.config.driverLocationFirebaseApp = driverLocationFirebaseApp;
global.config.getNewFirebaseInstance = getNewFirebaseInstance;

export default global.config;
