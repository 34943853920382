const initialState = {
  containerSize: [],
  containerType: [],
  containerOwner: [],
}
export default function containerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CONTAINER_SIZE':
      return {
        ...state,
        containerSize: action.payload
      };

    case 'SET_CONTAINER_TYPE':
      return {
        ...state,
        containerType: action.payload
      }
    case 'SET_CONTAINER_OWNER':
      return {
        ...state,
        containerOwner: action.payload
      }
    default: return { ...state }
  }
};
