import React, { Component } from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "../formComponent";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import * as tmsAction from "./actionCreator";
import { ReferenceInfo } from "../../../Components/Common/FeatureOf";
import { ContainerInfo } from "../../../Components/Common/FeatureOf";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { HTTP } from "../../../services/Http.service";
import { getStorage, isMilitaryTime } from "../../../services/Common.services";
import {
  editLoad,
  removeValueLoad,
  getTerminal,
  getChassisSize,
  getChassisType,
  getTMSChassisOwner,
  getTMSContainerOwner,
  UpdatePricing,
} from "../services";
import {
  toastr,
  makeOptionsForSelect,
  showForCarrier,showForTerminal
} from "./../../../services/Common.services";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import configuration from "../../../config";
import {
  getChassisPickCustomers,
  getEmptyOriginCustomers,
  getChassisTerminationCustomers,
  getTMScaller,
  getTMSshipper,
  getTMSconsignee,
  getContainerType,
  getCommodityProfile,
  getContainerSize,
  getTMSChassis,
  getAllCustomers,
} from "../Dispatcher/actionCreators";
import { bindActionCreators } from "redux";

import ExtraLabel from "../../../Components/Common/ExtraLabel";
import {
  IconCalendar,
  IconMinus,
  IconPlus,
  IconTimes,
  IconPool,
} from "../../../Components/Common/Icons";
import _ from "lodash";
import { newSmallSelectStyle, smallSelectStyle } from "../../../assets/js/select-style";
import confirm from "../../../Components/Common/ConfirmAert";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import RecommendedReturnTerminals from "./RecommendedReturnTerminals";
import CommodityComponent from "./CommodityComponent";
let freeze = false; //mark delay
let timer; //saved timer

const getChassisPickCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisPickCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
const getEmptyOriginCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getEmptyOriginCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
const getChassisTerminationCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisTerminationCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
const getTMSshipperAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSshipper(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getTMScallerAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMScaller(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getTMSconsigneeAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSconsignee(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};



const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightKgs: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};

class NewCommonLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeTypes: ["Local", "Highway"],
      showCutOffModal: false,
      bookingInfoLoading: false,
      bookingCutOffInfo: undefined,
      terminals: [],
      Terminals: [],
      ContainerDefaultValue: null,
      ContainerOwner: [],
      commodityProfiles: [],
      items: [{ ...commodityItems }],
      terminal: "",
      showDateModal: false,
      isLoader: false,
      addCommodity: false,
    };
    this.willCall = {};
    this.clearInfosOnAdd = this.clearInfosOnAdd.bind(this);
    this.onRemovePress = this.onRemovePress.bind(this);
    this.selectChassis = this.selectChassis.bind(this);
    this.selectGrayChassis = this.selectGrayChassis.bind(this);
    this.initialValues= {};
    this.userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
    this.timeZone = getStorage("timeZone");
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this;
    if (props.customerProfile) {
      if (props.customerProfile.chassisPick) this.setState({ chassisPick: "" });
      if (props.customerProfile.emptyOrigin) this.setState({ emptyOrigin: "" });
      if (props.customerProfile.chassisTermination)
        this.setState({ chassisTermination: "" });
      if (props.customerProfile.terminal && props.customerProfile.terminal._id) {
        this.setState({ terminal:{value: props.customerProfile.terminal._id, label:props.customerProfile.terminal.name} });
      }
      if (
        props.customerProfile.items &&
        props.customerProfile.items.length > 0
      ) {
        let items = props.customerProfile.items.map((D) => {
          if (D._id) {
            delete D._id;
          }
          return D;
        });
        self.setState({ items });
      }
    }
    if (props.Terminals && props.Terminals.length > 0) {
      self.setState({ Terminals: props.Terminals });
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      if (this.state.Terminals.length === 0) {
        getTerminal().then((response) => {
          const Terminals = response.data.data ? response.data.data : [];
          self.setState({ Terminals });
        });
      }
    }
  }
  repetiveAPICall = (obj) => {
    for(const property in obj){
      let finalObj = {
        reference_number: this.props.customerProfile.reference_number,
        [property]: obj[property],
        source: "loadinfo",
      }
      if(property === "containerNo" || property === "grayContainerNo") {
        editLoad(finalObj).then((result)=>{
          if (result.data.data.hasContainer) {
            this.onReConfirmUpdate(
              finalObj,
              this.props.customerProfile.reference_number,
              obj[property]
            )
          }
        }).catch(()=>{})
      }
      else{
        editLoad(finalObj).then(()=>{}).catch(()=>{})
      }
    }
    this.willCall = Object.assign({})
  }
  onPickDeliveryChanges = (time,update,i) => {
    let Times = [];
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    
    let noChange = _.isEqual(this.props.originalLoad[controlName],this.props.customerProfile[controlName])
    if (noChange) return
    this.props.customerProfile[field].forEach((el,i)=>{
      el._id && Times.push({ ...this.props.customerProfile[controlName][i], customerId: el._id })
    })
    let filteredPickup = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [controlName]: filteredPickup,
      }
      return
    }
    let arr =
      time.indexOf("pickup") > -1
        ? update === "pickupFrom"
          ? [`.pickupTo-${i}`, `.pickupFrom-${i}`]
          : [`.pickupTo-${i}`]
        : update === "deliveryFrom"
        ? [`.deliveryTo-${i}`, `.deliveryFrom-${i}`]
        : [`.deliveryTo-${i}`];

    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))

    editLoad(obj).then(()=>{
      Times = [];
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}

  onDateSelect = (key,value) => {
    this.setState({ showDateModal: false })
    if(!value) return
    let finalValue = value;
    let noChange = this.props.originalLoad[key] && this.props.originalLoad[key].eta ? _.isEqual(this.props.originalLoad[key].eta,finalValue) : _.isEqual(this.props.originalLoad[key],finalValue)
    if (noChange) return

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue
      }
      return
    }
    const element = document.querySelector(`.${key}`);
    const inputElement = element.querySelector("input");
    editLoad(obj).then((result)=>{
      inputElement?.classList.remove("background-failure");
      inputElement?.classList.add("background-success");
      setTimeout(()=>{
        inputElement?.classList.remove("background-success","text-danger");
      },400)
    }).catch((err) => {
      inputElement?.classList.add("background-error","text-danger")
      setTimeout(()=>{
        inputElement?.classList.add("background-failure","text-danger");
        inputElement?.classList.remove("background-error","text-danger");
      },400)
  })}
  onReConfirmUpdate = (data,reference_number,key) => {
    let title = "Container: " + data[key];
    let body = (
      <div>
        <h4>
            This container is already in the system.
        </h4>
        <div>
          <p>
            Do you want to save the{" "}
            {"Container: " + data[key]}{" "}
            to Load {reference_number} anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        data.isContainerConfirm = true
        const element = document.querySelector(`.${key}`);
        return editLoad(data).then(()=>{
          element?.classList.remove("background-failure");
          element?.classList.add("background-success");
          setTimeout(()=>{
            element?.classList.remove("background-success","text-danger");
          },400)
        }).catch((err) => {
            element?.classList.add("background-error","text-danger");
        setTimeout(()=>{
            element?.classList.add("background-failure","text-danger");
            element?.classList.remove("background-error","text-danger");
        },400)
      });
      } else {
        return
      }
    });
};
  onSelectFieldChange = (key,value) => {
    let divInElement;
    const element = document?.querySelector(`.${key}`);
    if(key === "hazmat" || key === "overWeight" || key === "liquor" || key === "hot") {
      divInElement = document?.querySelector(`#${key}-label`)
    } else {
      divInElement = element?.querySelector("div");
    }
    const elementToChange = divInElement ? divInElement : element;
    
    const valueToSend = typeof value === "string" ? value.trim() : value;

    if(valueToSend === "" && !!this.props.originalLoad[key]){
      const params = {
        reference_number: this.props.customerProfile.reference_number,
        key: key
      };
      if (!this.props.disabled) {
        removeValueLoad(params).then((result) => {
          elementToChange?.classList.remove("background-failure");
          elementToChange?.classList.add("background-success");
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
          },400)
          this.props.updateState(key, result.data.data[key]);
        });
      }
      return
    }
    if(valueToSend === "" && !this.props.originalLoad[key]) return
    let finalValue;
    if (typeof valueToSend === "boolean" || typeof valueToSend === "string") {
      if(key === "freight" || key === "custom"){
      let noChange = _.isEqual(this.props.originalLoad[key],valueToSend)
      if(noChange) return
    }
    if(this.props.originalLoad[key]){
      let noChange = key === "terminal" ? _.isEqual(this.props.originalLoad[key]._id,valueToSend) : _.isEqual(this.props.originalLoad[key],valueToSend)
      if(noChange) return // || valueToSend === "" for not allowed empty elements
    }

      if(key === "containerNo"){
        if (valueToSend && !/^[A-Za-z]{4}\d{6,8}$/.test(valueToSend)) {
          toastr.show("Please Enter Correct Container No", "warning");
          return;
        }
      }
      if(key === "grayContainerNo"){
        if (valueToSend && !/^[A-Za-z]{4}\d{6,8}$/.test(valueToSend)) {
          toastr.show("Please Enter Correct Gray Container No", "warning");
          return;
        }
      }
      finalValue = valueToSend
    }
    else {
      let noChange = this.props.originalLoad[key] ? _.isEqual(this.props.originalLoad[key]._id,valueToSend._id) : false
      if(noChange) return
      finalValue = valueToSend._id
    }

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      source: "loadinfo"
    }
    if(key === "hot") obj.isHot = value;
    let list = ["secondaryReferenceNo","containerNo","grayContainerNo"]
    if(this.props.isRequireReference && !list.includes(key)){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue
      }
      return
    }
    if(key === "containerNo" || key === "grayContainerNo") {
      if(this.props.isRequireReference){
        toastr.show('You must enter a Reference # to save', 'error');
        this.willCall = {
          ...this.willCall,
          [key]: finalValue
        }
        return
      }
      editLoad(obj).then((result)=>{
        if (result.data.data.hasContainer) {
          this.onReConfirmUpdate(
            obj,
            this.props.customerProfile.reference_number,
            key
          )
        }
        else{
          const element = document.querySelector(`.${key}`);
          element?.classList.remove("background-failure");
          element?.classList.add("background-success");
          setTimeout(()=>{
          element?.classList.remove("background-success","background-error","text-danger");
          },400)
        }
        
      }).catch((err) => {
        const element = document.querySelector(`.${key}`);
        element?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          element?.classList.add("background-failure","text-danger");
          element?.classList.remove("background-error","text-danger");
        },400)
      })
    }
    else{
      editLoad(obj).then(()=>{
        elementToChange?.classList.remove("background-failure");
        if(elementToChange.tagName === "LABEL") elementToChange.style.color = "green"
        else elementToChange?.classList.add("background-success");
        if(this.willCall !== {}){
          this.repetiveAPICall(this.willCall)
        }
        setTimeout(()=>{
          elementToChange?.classList.remove("background-success","text-danger");
          elementToChange.style.color = null
        },400)
      }).catch((err) => {
        elementToChange?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          elementToChange?.classList.add("background-failure","text-danger");
          elementToChange?.classList.remove("background-error","text-danger");
        },400)
    })
    }
  }

  onMultipleSelectionChange = (key,value,i) =>{
    if(!value) return
    let finalValue;
    let controlField = key === "shipper" ? "pickupTimes": "deliveryTimes";
    let prevValue = this.props.originalLoad[key] && this.props.originalLoad[key].map((obj)=> obj._id)
    finalValue = value && value.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let noChange = _.isEqual(prevValue,finalValue)
    if(noChange) return
    const Times = this.props.customerProfile[controlField].filter((obj)=> !_.isEmpty(obj))
    const finalTimes = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      [controlField]: finalTimes,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue,
        [controlField]: finalTimes,
      }
      return
    }
    const element = document.querySelector(`.${key}-${i} > div`)

    editLoad(obj).then(()=>{
        element?.classList.remove("background-failure");
        element?.classList.add("background-success");
          setTimeout(()=>{
            element?.classList.remove("background-success","text-danger");
          },400)
    }).catch(() => {
      element?.classList.add("background-error","text-danger");
      setTimeout(()=>{
        element?.classList.add("background-failure","text-danger");
        element?.classList.remove("background-error","text-danger");
      },400)
  })}

  onReturnTimes = (value) => {
    if(!value) return
    let finalValue = value;
    let noChange = _.isEqual(this.props.originalLoad["returnFromTime"],finalValue) && _.isEqual(this.props.originalLoad["returnToTime"],finalValue)
    if (noChange) return
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      returnFromTime: finalValue,
      returnToTime: finalValue,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
      returnFromTime: finalValue,
      returnToTime: finalValue,
      }
      return
    }
    const arr = [".returnFromTime",".returnToTime"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
    editLoad(obj).then(()=>{
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}
  

  onRemoveField = (time, updateTime, updateList) => {
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let updatedTime = updateTime.filter((obj)=> !_.isEmpty(obj))
    let filteredPickup = JSON.stringify(updatedTime)
    let list = updateList.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      [field]: list,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
      ...this.willCall,
      [controlName]: filteredPickup,
      [field]: list,
      }
      return
    }
    editLoad(obj).then((result)=>{
      toastr.show("Updated!", "success");
    }).catch((err) => {
      toastr.show('Failed to Update !', 'error');
  })
  }

  onCommodityChanges = (comdArr,index,name) => {
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: comdArr,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: comdArr,
      }
      return
    }
    let elementArr;
    if(name === "weight" || name === "weightKgs"){
      let elements = [`.weight-${index}`,`.weightKgs-${index}`];
      elementArr = elements.map((el)=>document.querySelector(el))
    } else {
      // let isInput = JSON.parse(getStorage("measureUnits")).isCommodityProfileShow == true
      let element = document.querySelector(`.${name}-${index}`)
      elementArr = name === "commodity" && JSON.parse(getStorage("measureUnits"))?.isCommodityProfileShow ? [element] : [element]
    }
    editLoad(obj).then(()=>{
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
        !name && toastr.show("Successfully removed", "success");
    }).catch((err) => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
  })}

  tblvalchanges = (index, e, blur) => {
    const { name, value } = e.target;
    const old = [...this.state.items];
    if(value === "" &&  !this.props.originalLoad?.items[index] && this.props.originalLoad?.items[index][name]) return
    old[index][name] = (name === "description" || name === "commodity") ? value : value ? +value : "";
    if (name == "weight") {
      old[index]["weightKgs"] = parseFloat((parseFloat(value) * 0.453592).toFixed(2));
    }
    if (name == "weightKgs") {
      old[index]["weight"] = parseFloat((parseFloat(value) * 2.20462).toFixed(2));
    }
    this.setState({
      items: [...old],
    });
    let oldItems = [
      ...this.props.originalLoad?.items,
    ]
    oldItems?.forEach((item)=> {
      item && item._id && delete item._id
    })
    
    if(!_.isEqual(old, oldItems) && blur) this.onCommodityChanges(old,index,name)
    this.props.updateState("items", old);
  };

  DeleteItemRow = (index) => {
    let data = [...this.state.items];
    data.splice(index, 1);
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: data,
      }
      this.setState({
        items: data,
      })
      return
    }
    this.setState({
      items: data,
    },()=>{
      this.onCommodityChanges(data)
    });
    this.props.updateState("items", data);
  };

  AddItemRows = (item) => {
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.push(item);
    this.setState({
      items,
      addCommodity: false
    });
    this.updateCommodity(items)
  };

  updateCommodity = (items) => {
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: items,
      source: "loadinfo",
    }
    editLoad(obj).then(()=>{
      let element = document.querySelector(`.fd-row-${items.length - 1 }`)
      element?.classList.add("background-success")
      setTimeout(()=>{
        element?.classList.remove("background-success")
      },400)
    })
  }
 
  updatePricing(isSaveCustomerRates) {
    let valid = true;
    var payload = {
      items: this.state.items,
    };
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      UpdatePricing("tms/updateItemsLoad", payload)
        .then((response) => {
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("Your pricing has been updated!", "success");
        })
        .catch((err) => { });
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  getCutOffDetails(e) {
    this.props.change("bookingNo", e.target.value);
    let url = "tms/bookingInfo";
    const bookingNo = e.target.value;
    const port = this.props.customerProfile.shipper[0]
      ? this.props.customerProfile.shipper[0].company_name
      : "";
    if (
      port == "MAHER" ||
      port == "GLO" ||
      port == "NYCT" ||
      port == "APM" ||
      port == "PNCT"
    ) {
      url = url + "?bookingNo=" + bookingNo + "&port=" + port;
      if (
        this.props.customerProfile.shipper[0] &&
        this.props.customerProfile.shipper[0].company_name &&
        this.props.customerProfile.shipper[0].company_name == "MAHER"
      ) {
        this.props.TMSContainerOwner.forEach((data) => {
          if (data._id == this.props.formValue.axleload.values.containerOwner) {
            url = url + "?ssl=" + data.company_name;
          }
        });
      }
      if (
        port == "MAHER" &&
        !this.props.formValue.axleload.values.containerOwner
      ) {
        toastr.show("Please choose a SSL.");
        return;
      }
      toastr.show("We are getting booking information.");

      HTTP("GET", url, null, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (
            result &&
            result.data.data &&
            result.data.data.result &&
            result.data.data.result.cutOff
          ) {
            this.props.datechanges("cutOff", result.data.data.result.cutOff);
          }
        })
        .catch((error) => {
          this.setState({
            bookingInfoLoading: false,
            bookingCutOffInfo: undefined,
          });
        });
    }
  }

  updateCutOff() {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      cutOff: this.state.bookingCutOffInfo.result.cutOff,
      source: "loadInfo"
    };
    editLoad(params).then((result) => {
      toastr.show("Updated!", "custom", 2000, {
        background: "#A6D92C",
        text: "#FFFFFF",
      });
      this.props.datechanges("cutOff", params.cutOff);
      this.setState({ showCutOffModal: false });
    });
  }

  selectChassis(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
    } = data;

    if(this.props.originalLoad.chassisNo === label 
      && this.props.originalLoad.chassisType?._id === chassisType?._id 
      && this.props.originalLoad.chassisOwner?._id === chassisOwner?._id 
      && this.props.originalLoad.chassisSize?._id === chassisSize?._id){
      return
    }

    setTimeout(() => {
      this.props.updateState("chassisSize", {
        _id: chassisSize?._id,
        name: chassisSize?.name,
      });
    }, 100);
    setTimeout(() => {
      this.props.updateState("chassisType", {
        _id: chassisType?._id,
        name: chassisType?.name,
      });
    }, 200);
    setTimeout(() => {
      this.props.updateState("chassisOwner", {
        _id: chassisOwner?._id,
        company_name: chassisOwner?.company_name,
      });
    }, 300);
    setTimeout(() => {
      this.props.updateState("chassisNo", label);
    }, 400);
    if (value) {
      setTimeout(() => {
        this.props.updateState("chassisId", value);
        this.props.onDdlChange({
          target: {
            name: "chassisNo",
            value: label,
          },
        });
      }, 400);
    }
    
    let chassisObj = {
      chassisId: value,
      chassisNo: label,
      chassisSize: chassisSize?._id,
      chassisType: chassisType?._id,
      chassisOwner: chassisOwner?._id,
    }
    Object.keys(chassisObj).forEach(key => chassisObj[key] === undefined && delete chassisObj[key])
    this.setState({
      ...chassisObj,
    });


    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      ...chassisObj,
      source: "loadinfo"
    }
    
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        ...chassisObj,
      }
      return
    }

    const arr = [".chassisNo",".chassisOwner",".chassisType",".chassisSize"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("div"))
    
    editLoad(obj).then(()=>{
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}

  selectGrayChassis(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
    } = data;

    if(this.props.originalLoad.grayChassisNo === label 
      && this.props.originalLoad.grayChassisType._id === chassisType._id 
      && this.props.originalLoad.grayChassisOwner._id === chassisOwner._id 
      && this.props.originalLoad.grayChassisSize._id === chassisSize._id){
      return
    }

    if(chassisSize){
      setTimeout(() => {
        this.props.updateState("grayChassisSize", {
          _id: chassisSize._id,
          name: chassisSize.name,
        });
      }, 100);
    }
    if(chassisType){
      setTimeout(() => {
        this.props.updateState("grayChassisType", {
          _id: chassisType._id,
          name: chassisType.name,
        });
      }, 200);
    }
    if(chassisOwner){
      setTimeout(() => {
        this.props.updateState("grayChassisOwner", {
          _id: chassisOwner._id,
          name: chassisOwner.company_name,
        });
      }, 300);
    }
    setTimeout(() => {
      this.props.updateState("grayChassisNo", label);
    }, 400);
    if (value) {
      setTimeout(() => {
        this.props.updateState("grayChassisId", value);
        this.props.onDdlChange({
          target: {
            name: "chassisNo",
            value: label,
          },
        });
      }, 400);
    }

    let chassisObj = {
      chassisId: value,
      grayChassisNo: label,
      grayChassisSize: chassisSize._id,
      grayChassisType: chassisType._id,
      grayChassisOwner: chassisOwner._id,
    }

    this.setState({
      ...chassisObj,
    });

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      ...chassisObj,
      source: "loadinfo"
    }

    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
      ...this.willCall,
      ...chassisObj,
      }
      return
    }

    const arr = [".grayChassisNo",".grayChassisOwner",".grayChassisType",".grayChassisSize"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("div"));

    editLoad(obj).then(()=>{
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}

  onRemovePress(e, input, showToast = true) {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      key: input.name,
    };
    if (!this.props.disabled) {
      removeValueLoad(params).then((result) => {
        this.willCall = Object.assign({})
        const element = document.querySelector(`.${input.name}`);
        let dates = ["Date","Day","vessel","return","cutOff","loadTime"];
        const isDate = dates.some((str)=>input.name.includes(str))
        const divInElement = isDate ? element?.querySelector("input") : element?.querySelector("div");
        const elementToChange = divInElement ? divInElement : element;
        elementToChange?.classList.remove("background-failure");
        elementToChange?.classList.add("background-success");
        setTimeout(()=>{
          elementToChange?.classList.remove("background-success","text-danger");
        },400)
        this.props.updateState(input.name, result.data.data[input.name]);
      });
    }
  }

  onRemovePressOnDate(e, input, index) {
    let params = {
      reference_number: this.props.customerProfile.reference_number,
      source: "loadInfo"
    };
    let { customerProfile } = this.props;
    delete customerProfile[`${input}Times`][index][`${input}FromTime`];
    delete customerProfile[`${input}Times`][index][`${input}ToTime`];
    params[`${input}Times`] = customerProfile[`${input}Times`];
    params[`${input}Times`].map((d) => {
      delete d._id;
      Object.keys(d).map((key) => {
        if (!d[key]) delete d[key];
      });
    }); // delete _id and falsy keys
    const finalParams = params[`${input}Times`].filter((obj) => !_.isEmpty(obj));
    params[`${input}Times`] = JSON.stringify(finalParams);
    let arr =
      input.indexOf("pickup") > -1
        ? `${input}From` === "pickupFrom"
          ? [`.pickupTo-${index}`, `.pickupFrom-${index}`]
          : [`.pickupTo-${index}`]
        : `${input}From` === "deliveryFrom"
        ? [`.deliveryTo-${index}`, `.deliveryFrom-${index}`]
        : [`.deliveryTo-${index}`];

    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
    
    if (!this.props.disabled) {
      editLoad(params).then((res) => {
        if (res.status === 200) {
          elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
          this.props.updateState(
            `${input}Times`,
            customerProfile[`${input}Times`]
          );
        }
      });
    }
  }
  clearInfosOnAdd() {
    this.setState({
      caller: "",
      shipper0: "",
      consignee0: "",
    });
  }

  _findOption(arrayKey, value, label) {
    let data = this.props[arrayKey].find((d) => d._id === value);

    if (data) return { value: data._id, label: data[label] || data.name };

    return;
  }

  getChassisSize(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getChassisSize({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;

          data = data.map((d) => ({
            value: d._id,
            label: d.name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getChassisType(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getChassisType({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getTMSChassisOwner(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getTMSChassisOwner({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.company_name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getTMSContainerOwner(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getTMSContainerOwner({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.company_name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  getChassis(searchTerm = "") {
    return new Promise((resolve, reject) => {
      this.props
        .getTMSChassis({ limit: 10, searchTerm })
        .then((result) => {
          let data = result.map((d) => ({
            value: d._id,
            label: d.chassisNo,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  getCommodityAllProfile() {
    getCommodityProfile()
      .then((data) => {
        this.setState({
          commodityProfiles: data.data.data.map((c) => ({
            label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
            value: c.name,
            name: c.name,
            isDisabled: c.isRestricted,
            allInfo: c,
          })),
        });
      })
      .catch((err) => {
        console.log("err----->>", err);
      });
  }

  componentDidMount() {
    this.getCommodityAllProfile();
    this.initialValues = this.props && _.cloneDeep(this.props.originalLoad);
  }

  componentWillReceiveProps(props) {
    let self = this;
    let ContainerOwner = [];
    let ContainerDefaultValue = null;
    if (props.TMSContainerOwner && props.TMSContainerOwner.length > 0) {
      props.TMSContainerOwner.forEach((owner, i) => {
        if (
          props.customerProfile &&
          props.customerProfile.containerOwner &&
          owner._id == props.customerProfile.containerOwner._id
        ) {
          ContainerDefaultValue = {
            value: owner._id,
            label: owner.company_name,
          };
        }
        ContainerOwner.push({
          label: owner.company_name,
          value: owner._id,
        });
      });
    }
    self.setState({ ContainerOwner }, () => {
      if (ContainerDefaultValue) {
        self.setState({ ContainerDefaultValue });
      }
    });
  }

  // On Apply Tracer Data
  onApplyTracerData = (key, value) => {
    let self = this;
    if (!self.props.disabled) {
      let newValue = value;
      let newKey = key;
      if (key === "containerTypeName") {
        newValue = self.props.containerTypes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerType";
      }
      if (key === "containerSizeName") {
        newValue = self.props.containerSizes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerSize";
      }
      if (key === "containerOwnerName") {
        newValue = self.props.containerOwners.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerOwner";
      }

      if (key === "chassisTypeName") {
        newValue = self.props.chassisTypes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisType";
      }
      if (key === "chassisSizeName") {
        newValue = self.props.chassisSizes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisSize";
      }
      if (key === "chassisOwnerName") {
        newValue = self.props.chassisOwners.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisOwner";
      }
    }
  };

  onBlurChange = (e)=> this.onSelectFieldChange(e.target.name, e.target.value );


  render() {
    let self = this;
    let ddlDisabled = false;
    let loadTime = "";
    let containerAvailableDay = "";
    let isDisabled = this.props.disabled;
    let disabled = this.props.disabled;
    if (this.props.newRecord) disabled = true;
    if (
      this.props &&
      this.props.customerProfile &&
      Object.keys(this.props.customerProfile).length > 0
    ) {
      if (this.props.customerProfile.loadTime) {
        loadTime = moment(this.props.customerProfile.loadTime).format(
          "MM/DD/YYYY h:mm a"
        );
      }
      if (this.props.customerProfile.containerAvailableDay) {
        containerAvailableDay = moment(
          this.props.customerProfile.containerAvailableDay
        ).format("MM/DD/YYYY");
      }
      if (
        this.props.customerProfile.chassisNo &&
        typeof this.props.customerProfile.chassisNo !== "string"
      )
        ddlDisabled = true;
    }

    const {
      customerProfile,
      selectedLoads,
      containerSizes,
      containerTypes,
      containerOwners,
      chassis,
      chassisOwners,
      chassisSizes,
      chassisTypes,
      customers,
      stepsEnabled,
      initialStep,
      onExit,
      showExtraData,
    } = this.props;

    const {
      chassisSize,
      chassisType,
      containerSize,
      containerOwner,
      containerType,
      chassisOwner,
      grayChassisSize,
      grayChassisType,
      grayContainerSize,
      grayContainerOwner,
      grayContainerType,
      grayChassisOwner,
      shipper,
      routeType,
      consignee,
    } = customerProfile;

    let { pickupTimes, deliveryTimes } = customerProfile;

    const shippers = shipper ? shipper : [{}];
    const consignees = consignee ? consignee : [{}];

    pickupTimes = pickupTimes ? pickupTimes : [{}];
    deliveryTimes = deliveryTimes ? deliveryTimes : [{}];
    const QueryString = window.location.search;
    let Step = [];
    let localItem = null;
    if (QueryString === "?reference") {
      Step = Step.concat(ReferenceInfo);
      localItem = localStorage.getItem("reference");
    } else if (QueryString === "?container") {
      Step = Step.concat(ContainerInfo);
      localItem = localStorage.getItem("container");
    }

    // Extra Tracers data for the loads
    let extraData = null;
    if (selectedLoads.extraTracerData) {
      extraData = selectedLoads.extraTracerData;
    }
    return (
      <div className="tab mt-10" id="tab-load-info">
        <div className="form-row-md">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* Customer Info */}
            <div className="card py-30 px-20 mb-20">
              <div>
                <h4 className="font-16 mb-20">
                  Load Info
                </h4>
                <div className="form-row">
                  <div className="col-lg form-group">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Customer
                    </label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.callers &&
                          this.props.callers.concat(this.props.allCustomers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      // isClearable
                      className="caller"
                      value={
                        (this.props.customerProfile &&
                          this.props.customerProfile.caller &&
                          this.props.customerProfile.caller.company_name) ||
                        this.state.caller
                          ? {
                              value: this.props.customerProfile.caller._id,
                              label:
                                this.props.customerProfile.caller.company_name,
                            }
                          : this.state.caller
                      }
                      onChange={(customer) => {
                        if (!customer) {
                          this.onRemovePress(null, { name: "caller" });
                          return;
                        }
                        this.onSelectFieldChange("caller", customer.allInfos)
                        this.props.updateState("caller", customer.allInfos);
                        // this.setState({ customer }, () => { })
                      }}
                      loadOptions={getTMScallerAsyncSearch}
                      isDisabled={disabled}
                    />
                  </div>
                  { showForTerminal ()  && (
                    <div className="form-group col-lg" style={{maxWidth: 170}}>
                      <label className="label-control">Terminal *</label>
                      {
                        this.state.Terminals && 
                        <Select
                          className="terminal"
                          styles={smallSelectStyle}
                          options={this.state.Terminals.map(it=>({value:it._id, label:it.name}))}
                          value={this.state.terminal}
                          onChange={(opt) => {
                            this.setState({
                              terminal: opt,
                            });
                            this.onSelectFieldChange("terminal", opt.value)
                            this.props.updateState("terminal", opt);
                          }}
                          isDisabled={disabled}
                        />
                      }
                    </div>
                  )} 
                </div>
              </div>

              <div className="hr-light my-25 mt-10"></div>
              {shippers.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Port</h4>

                    <div className="form-row">
                      <div className="col-md-6">
                        {shippers.map((shipper, i) => {
                          return (
                            <div className="form-group mb-10" key={i}>
                              <label>
                                {/* <span className="text-danger">*</span> */}
                                {`Port Name`}
                              </label>
                              <div className="input-wrapper">
                                <AsyncSelect
                                  placeholder="Select..."
                                  styles={smallSelectStyle}
                                  defaultOptions={makeOptionsForSelect(
                                    this.props.shippersOptions,
                                    "company_name",
                                    "address.address",
                                    "_id"
                                  )}
                                  className={`shipper-${i}`}
                                  cacheOptions
                                  value={
                                    shipper && shipper.company_name
                                      ? {
                                          label: shipper.company_name,
                                          value: shipper._id,
                                          allInfos: shipper,
                                        }
                                      : this.state["shipper" + i]
                                      ? {
                                          label:
                                            this.state["shipper" + i]
                                              .company_name,
                                          value:
                                            this.state["shipper" + i]._id,
                                          allInfos: this.state["shipper" + i],
                                        }
                                      : []
                                  }
                                  onChange={(shipper) => {
                                    let newShipper = self.props
                                      .customerProfile.shipper || [{}];
                                    newShipper[i] = shipper.allInfos;
                                    let newPickupTimes = self.props
                                    .customerProfile.pickupTimes || [];
                                    newPickupTimes[i].customerId = shipper.value;
                                    this.setState({
                                      ["shipper" + i]: shipper.label,
                                    });
                                    this.props.updateState(
                                      "shipper",
                                      newShipper
                                      );
                                    this.props.updateState(
                                      "pickupTimes",
                                      newPickupTimes
                                      );
                                    this.onMultipleSelectionChange("shipper", newShipper,i)
                                  }}
                                  loadOptions={getTMSshipperAsyncSearch}
                                  isDisabled={disabled}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {pickupTimes &&
                          pickupTimes.map((pickupTime, i) => (
                            <React.Fragment>
                              <div className="form-row">
                                <div className=" col-md">
                                  <label className="label-control">
                                    Pick Up From
                                  </label>
                                  <div className={`input-wrapper pickupFrom-${i}`}>
                                    <DateWrapper
                                      OnBlurUpdate={() => this.onPickDeliveryChanges("pickup","pickupFrom",i)}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery("pickupFromTime",e,i);
                                        this.props.datechangesPickupDelivery("pickupToTime",e,i); 
                                      }}
                                      timeFormat={this.userTimeFormat}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      disabled={disabled}
                                      defaultDate={pickupTime && pickupTime.pickupFromTime} 
                                      hideShowSelectDate={true}
                                    />
                                    {pickupTime &&
                                    pickupTime.pickupFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "pickup",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md">
                                  <div className="form-group mb-10">
                                    <label className="label-control">
                                      Pick Up To
                                    </label>
                                    <div className={`input-wrapper pickupTo-${i}`}>
                                    <DateWrapper
                                      OnBlurUpdate={()=> this.onPickDeliveryChanges("pickup","pickupTo",i)}
                                      onChange={(e) => 
                                      this.props.datechangesPickupDelivery("pickupToTime",e,i)
                                      }
                                      disabled={disabled}
                                      timeFormat={this.userTimeFormat}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      defaultDate={pickupTime && pickupTime.pickupToTime} 
                                      hideShowSelectDate={true}
                                    />
                                      {pickupTime &&
                                      pickupTime.pickupToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "pickup",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {this.props.customerProfile &&
                                  this.props.customerProfile.shipper &&
                                  this.props.customerProfile.shipper.length >
                                  1 && (
                                    <div className="col-md form-group d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        onClick={(e) => {
                                          let newShipper =
                                            this.props.customerProfile
                                              .shipper || [];
                                          if (newShipper.length > 1) {
                                            newShipper.splice(i, 1);
                                            this.props.updateState(
                                              "shipper",
                                              newShipper
                                            );
                                            this.setState({
                                              ["shipper" + i]: "",
                                            });
                                            pickupTimes.splice(i, 1);
                                            this.onRemoveField("pickup",pickupTimes,newShipper)
                                            this.props.updateState(
                                              "pickupTimes",
                                              pickupTimes
                                            );
                                          }
                                        }}
                                        style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <span style={disabled ? {cursor: "not-allowed"} : {}}>
                    <div
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newShipper =
                          self.props.customerProfile &&
                          self.props.customerProfile.shipper
                            ? self.props.customerProfile.shipper
                            : [];
                        newShipper.push({});
                        pickupTimes.push({});
                        self.props.updateState("pickupTimes", pickupTimes);
                        self.props.updateState("shipper", newShipper);
                        self.setState({
                          ["shipper" + newShipper.length]: "",
                        });
                      }}
                      style={disabled ? {pointerEvents: "none", opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      Add Port
                    </div>
                    </span>
                  </div>
                  <div className="hr-light my-25"></div>
                </React.Fragment>
              )}
              {consignees.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Consignee</h4>
                    <div className="form-row">
                      <div className="col-md-6">
                        {consignees.map((consignee, i) => {
                          return (
                            <div className="form-group mb-10" key={i}>
                              <label>{`Consignee`}</label>
                              <div className="input-wrapper">
                                <AsyncSelect
                                  styles={smallSelectStyle}
                                  defaultOptions={makeOptionsForSelect(
                                    this.props.consigneesOptions,
                                    "company_name",
                                    "address.address",
                                    "_id"
                                  )}
                                  className={`consignee-${i}`}
                                  cacheOptions
                                  value={
                                    consignee && consignee.company_name
                                      ? {
                                          label: consignee.company_name,
                                          value: consignee._id,
                                          allInfos: consignee,
                                        }
                                      : this.state["consignee" + i]
                                      ? {
                                          label:
                                            this.state["consignee" + i]
                                              .company_name,
                                          value:
                                            this.state["consignee" + i]._id,
                                          allInfos:
                                            this.state["consignee" + i],
                                        }
                                      : []
                                  }
                                  onChange={(consignee) => {
                                    let newConsignee = self.props
                                      .customerProfile.consignee || [{}];
                                    newConsignee[i] = consignee.allInfos;
                                    let newDeliveryTimes = self.props
                                    .customerProfile.deliveryTimes || [];
                                    newDeliveryTimes[i].customerId = consignee.value;
                                    this.setState({
                                      ["consignee" + i]: consignee.label,
                                    });
                                    this.props.updateState(
                                      "consignee",
                                      newConsignee
                                    );
                                    this.props.updateState(
                                      "deliveryTimes",
                                      newDeliveryTimes
                                      );
                                    this.onMultipleSelectionChange("consignee", newConsignee,i)
                                  }}
                                  loadOptions={getTMSconsigneeAsyncSearch}
                                  isDisabled={disabled}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {deliveryTimes &&
                          deliveryTimes.map((deliveryTime, i) => (
                            <React.Fragment>
                              <div className="form-row ">
                                <div className=" col-md">
                                  <label className="label-control">
                                    Delivery From
                                  </label>
                                  <div className={`input-wrapper deliveryFrom-${i}`}>
                                    <DateWrapper 
                                      disabled={disabled}
                                      OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryFrom",i)}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery("deliveryFromTime",e,i);
                                        this.props.datechangesPickupDelivery("deliveryToTime",e,i); 
                                      }}
                                      timeFormat={this.userTimeFormat}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      defaultDate={deliveryTime && deliveryTime.deliveryFromTime} 
                                      hideShowSelectDate={true}
                                    />
                                    {deliveryTime &&
                                    deliveryTime.deliveryFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "delivery",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md ">
                                  <div className="form-group mb-10">
                                    <label className="label-control">
                                      Delivery To
                                    </label>
                                    <div className={`input-wrapper deliveryTo-${i}`}>
                                    <DateWrapper 
                                      disabled={disabled}
                                      OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryTo",i)}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery("deliveryToTime",e,i);
                                      }}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      timeFormat={this.userTimeFormat}
                                      defaultDate={deliveryTime && deliveryTime.deliveryToTime} 
                                      hideShowSelectDate={true}
                                    />
                                      {deliveryTime &&
                                      deliveryTime.deliveryToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "delivery",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {this.props.customerProfile &&
                                  this.props.customerProfile.consignee &&
                                  this.props.customerProfile.consignee
                                    .length > 1 && (
                                      <div className="form-group col-md d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        onClick={(e) => {
                                          let newConsignee =
                                            self.props.customerProfile
                                              .consignee || [];
                                          if (newConsignee.length > 1) {
                                            newConsignee.splice(i, 1);
                                            self.props.updateState(
                                              "consignee",
                                              newConsignee
                                            );
                                            self.setState({
                                              ["consignee" + i]: "",
                                            });
                                            deliveryTimes.splice(i, 1);
                                            this.onRemoveField("delivery",deliveryTimes,newConsignee)
                                            self.props.updateState(
                                              "deliveryTimes",
                                              deliveryTimes
                                            );
                                          }
                                        }}
                                        style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <span style={disabled ? {cursor: "not-allowed"} : {}}>
                    <div
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newConsignee =
                          self.props.customerProfile &&
                          self.props.customerProfile.consignee
                            ? self.props.customerProfile.consignee
                            : [];
                        newConsignee.push({});
                        deliveryTimes.push({});
                        self.props.updateState(
                          "deliveryTimes",
                          deliveryTimes
                        );
                        self.props.updateState("consignee", newConsignee);
                        self.setState({
                          ["consignee" + newConsignee.length]: "",
                        });
                      }}
                      style={disabled ? {pointerEvents: "none", opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      Add Consignee
                    </div>
                    </span>
                  </div>
                  <div className="hr-light my-25"></div>
                </React.Fragment>
              )}
              {/* Container Return */}
              <div>
                <h4 className="font-16 mb-20">Container Return</h4>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Container Return</label>
                    <AsyncSelect
                      placeholder="Select..."
                      styles={smallSelectStyle}
                      className="emptyOrigin"
                      defaultOptions={makeOptionsForSelect(
                        this.props.shippersOptions,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      isClearable
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.emptyOrigin
                          ? {
                              value:
                                this.props.customerProfile.emptyOrigin._id,
                              label:
                                this.props.customerProfile.emptyOrigin
                                  .company_name,
                            }
                          : this.state.emptyOrigin
                      }
                      onChange={(emptyOrigin) => {
                        if (!emptyOrigin) {
                          this.onRemovePress(null, { name: "emptyOrigin" });
                          return;
                        }
                        this.props.updateState(
                          "emptyOrigin",
                          emptyOrigin.allInfos
                        );
                        this.onSelectFieldChange("emptyOrigin", emptyOrigin.allInfos)
                        // this.setState({ emptyOrigin }, () => { })
                      }}
                      loadOptions={getEmptyOriginCustomersAsyncSearch}
                      isDisabled={disabled}
                      isLoading={this.state.isLoader}
                    />
                  </div>

                  <div className="form-group col-lg-3">
                    <label className="label-control">Return From </label>
                    <div className="input-wrapper returnFromTime">
                      <DateWrapper 
                        disabled={disabled} 
                        OnBlurUpdate={() => this.onReturnTimes(this.props.customerProfile?.returnFromTime)}
                        onChange={(e) => {
                          this.props.datechanges("returnFromTime", e);
                          setTimeout(() => {
                            this.props.datechanges("returnToTime", e);
                          }, 1000);
                        }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={this.props.customerProfile && this.props.customerProfile?.returnFromTime} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.returnFromTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, {
                              name: "returnFromTime",
                            });
                            this.onRemovePress(e,{
                              name: "returnToTime"
                            }, false);
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="label-control">Return To</label>
                    <div className="input-wrapper returnToTime">
                      <DateWrapper 
                        disabled={disabled} 
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "returnToTime" });}}
                        OnBlurUpdate={() => this.onDateSelect("returnToTime",this.props.customerProfile?.returnToTime)}
                        onChange={(e) => this.props.datechanges("returnToTime", e)}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={this.props.customerProfile && this.props.customerProfile?.returnToTime} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.returnFromTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e,{ 
                              name: "returnToTime"
                            });
                            this.onRemovePress(e,{
                              name: "returnFromTime"
                            }, false);
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Recommended Return Terminals */}
                {this.props.isEmptyEnabled && <RecommendedReturnTerminals  row={this.props.customerProfile} isLoadInfo={true} setWrongReturnMessage={(val)=>this.setState({wrongReturnMessage:val})} />}
                
              </div>
              <div className="hr-light my-25"></div>

              <div>
                <h4 className="font-16 mb-20">Chassis Locations</h4>
                <div className="form-row">
                  <div className="form-group col-lg-6 mb-0">
                    <label className={`col-form-label`}>Chassis Pickup</label>
                    <AsyncSelect
                      className="chassisPick"
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.chassisTerms,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      isClearable
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.chassisPick
                          ? {
                              value: this.props.customerProfile.chassisPick._id,
                              label:
                                this.props.customerProfile.chassisPick
                                  .company_name,
                            }
                          : null
                      }
                      onChange={(chassisPick) => {
                        if (!chassisPick) {
                          this.onRemovePress(null, { name: "chassisPick" });
                          return;
                        }
                        this.props.updateState(
                          "chassisPick",
                          chassisPick.allInfos
                        );
                        this.onSelectFieldChange("chassisPick",chassisPick.allInfos)
                        // this.setState({ chassisPick }, () => { })
                      }}
                      loadOptions={getChassisPickCustomersAsyncSearch}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-6 mb-0">
                    <label>Chassis Termination</label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      className="chassisTermination"
                      defaultOptions={makeOptionsForSelect(
                        this.props.chassisTerms,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.chassisTermination
                          ? {
                              value:
                                this.props.customerProfile.chassisTermination
                                  ._id,
                              label:
                                this.props.customerProfile.chassisTermination
                                  .company_name,
                            }
                          : this.state.chassisTermination
                      }
                      onChange={(chassisTermination) => {
                        if (!chassisTermination) {
                          this.onRemovePress(null, {
                            name: "chassisTermination",
                          });
                          return;
                        }
                        this.props.updateState(
                          "chassisTermination",
                          chassisTermination.allInfos
                        );
                        this.onSelectFieldChange("chassisTermination",chassisTermination.allInfos)
                        this.setState({ chassisTermination }, () => {});
                      }}
                      isClearable
                      loadOptions={getChassisTerminationCustomersAsyncSearch}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>

             
            </div>

            {/* New Section For Holds */}
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Holds 
              </h4>
                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="freight"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Freight Hold"
                      />
                    </label>
                    <Select
                      className="freight"
                      styles={smallSelectStyle}
                      options={HoldOrReleased}
                      value={customerProfile?.freight
                        ? HoldOrReleased.find((pt) => pt.value == customerProfile.freight)
                        : null}
                      onChange={(value) => {
                        this.props.updateState("freight", value.value);
                        this.onSelectFieldChange("freight", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="label-control">Custom Hold</label>
                    <Select
                      styles={smallSelectStyle}
                      className="custom"
                      options={HoldOrReleased}
                      value={customerProfile?.custom
                        ? HoldOrReleased.find((pt) => pt.value == customerProfile.custom)
                        : null}
                      onChange={(value) => {
                        this.props.updateState("custom", value.value);
                        this.onSelectFieldChange("custom", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>

                  <div className="form-group col-lg-3">
                    <label className="label-control">Carrier Hold</label>
                    <Select
                      styles={smallSelectStyle}
                      options={YesOrNo}
                      className="carrierHold"
                      value={YesOrNo.find(
                        (pt) =>
                          this.props.customerProfile &&
                          pt.value === this.props.customerProfile.carrierHold
                      )}
                      onChange={(value) => {
                        this.props.updateState("carrierHold", value.value);
                        this.onSelectFieldChange("carrierHold", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="brokerHold"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Broker Hold"
                      />
                    </label>
                    <Select
                      styles={smallSelectStyle}
                      options={YesOrNo}
                      className="brokerHold"
                      value={YesOrNo.find(
                        (pt) =>
                          this.props.customerProfile &&
                          pt.value === this.props.customerProfile.brokerHold
                      )}
                      onChange={(value) => {
                        this.props.updateState("brokerHold", value.value);
                        this.onSelectFieldChange("brokerHold", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
            </div>

          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {this.props.loadType !== "BILL_ONLY" && (
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Dates
              </h4>
              <div className="form-row">
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Load</label>
                    <div className="input-wrapper loadTime">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "loadTime" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("loadTime", this.props.customerProfile?.loadTime)}
                          onChange={(e) => {this.props.datechanges("loadTime", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={true}
                          timeFormat={this.userTimeFormat}
                          defaultDate={this.props.customerProfile && this.props.customerProfile?.loadTime} 
                          hideShowSelectDate={true}
                        />
                      {this.props.customerProfile &&
                      this.props.customerProfile.loadTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "loadTime" });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3  ">
                    <label className="label-control">ERD</label>
                    <div className="input-wrapper containerAvailableDay">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "containerAvailableDay" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("containerAvailableDay", this.props.customerProfile?.containerAvailableDay)}
                          onChange={(e) => {this.props.datechanges("containerAvailableDay", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={false}
                          defaultDate={this.props.customerProfile && this.props.customerProfile?.containerAvailableDay} 
                          hideShowSelectDate={true}
                        />

                      {this.props.customerProfile &&
                      this.props.customerProfile.containerAvailableDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, {
                              name: "containerAvailableDay",
                            });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Cutoff</label>
                    <div className="input-wrapper cutOff">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "cutOff" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("cutOff", this.props.customerProfile?.cutOff)}
                          onChange={(e) => {this.props.datechanges("cutOff", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={true}
                          timeFormat={this.userTimeFormat}
                          defaultDate={this.props.customerProfile && this.props.customerProfile?.cutOff} 
                          hideShowSelectDate={true}
                          className="right-0 form-control transition-white-field"
                        />

                      {this.props.customerProfile &&
                      this.props.customerProfile.cutOff &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "cutOff" });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <i className="uil uil-calendar-alt"></i>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Return</label>
                    <div className="input-wrapper return">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "return" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("return", this.props.customerProfile.return)}
                          onChange={(e) => {this.props.datechanges("return", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={false}
                          defaultDate={this.props.customerProfile && this.props.customerProfile.return} 
                          hideShowSelectDate={true}
                          className="form-control transition-white-field right-0"
                        />
                      {this.props.customerProfile &&
                      this.props.customerProfile.return &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "return" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3  ">
                    <label className="label-control">Vessel ETA</label>
                    <div className="input-wrapper vessel">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "vessel" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("vessel", this.props.customerProfile?.vessel?.eta)}
                        onChange={(e) => {this.props.vesselChange("vessel", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.vessel && this.props.customerProfile.vessel?.eta} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.vessel &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "vessel" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Last Free Day</label>
                      <div className="input-wrapper lastFreeDay">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "lastFreeDay" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("lastFreeDay", this.props.customerProfile.lastFreeDay)}
                        onChange={(e) => {this.props.datechanges("lastFreeDay", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={false}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.lastFreeDay } 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.lastFreeDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "lastFreeDay" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                      </div>
                  </div>
                )}

                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Discharged Date</label>
                    <div className="input-wrapper dischargedDate">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "dischargedDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("dischargedDate", this.props.customerProfile.dischargedDate)}
                        onChange={(e) => {this.props.datechanges("dischargedDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.dischargedDate} 
                        hideShowSelectDate={true}
                        className="form-control transition-white-field right-0"
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.dischargedDate &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "dischargedDate" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group col-lg-3 ">
                  <label className="label-control">Outgate Date</label>
                  <div className="input-wrapper outgateDate">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "outgateDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("outgateDate", this.props.customerProfile.outgateDate)}
                        onChange={(e) => {this.props.datechanges("outgateDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.outgateDate} 
                        hideShowSelectDate={true}
                        className="form-control transition-white-field right-0"
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile.outgateDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "outgateDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                </div>

                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Empty Date</label>
                    <div className="input-wrapper emptyDay">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "emptyDay" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("emptyDay", this.props.customerProfile.emptyDay)}
                        onChange={(e) => {this.props.datechanges("emptyDay", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={false}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.emptyDay} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.emptyDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "emptyDay" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3 ">
                    <label className="label-control">Return Day</label>
                    <div className="input-wrapper freeReturnDate">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "freeReturnDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("freeReturnDate", this.props.customerProfile.freeReturnDate)}
                        onChange={(e) => {this.props.datechanges("freeReturnDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={false}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.freeReturnDate} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.freeReturnDate &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, {
                              name: "freeReturnDate",
                            });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group col-lg-3 ">
                  <label className="label-control">Ingate Date</label>
                  <div className="input-wrapper ingateDate">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "ingateDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("ingateDate", this.props.customerProfile.ingateDate)}
                        onChange={(e) => {this.props.datechanges("ingateDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.ingateDate} 
                        hideShowSelectDate={true}
                        className="form-control transition-white-field right-0"
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile.ingateDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "ingateDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>)}
            <div className={`card py-30 pl-0 pr-0 pb-0 px-20 mb-20`}>
             <div className="pl-20 pb-20 pr-20">
              <h4 className="font-16 mb-20">
                Equipment
              </h4>

              <div className="form-row">
                <div className="form-group col-lg-3">
                  <Field
                    type="text"
                    className="form-control containerNo transition-white-field"
                    component={InputField}
                    name="containerNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    label="Container #"
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerSizeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Size"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerSizes.map((cs) => ({
                        label: cs.name,
                        value: cs._id,
                        allInfos: cs,
                      }))}
                      className="containerSize"
                      isClearable
                      loadOptions={getContainerSize}
                      value={
                        containerSize && {
                          label: containerSize.name,
                          value: containerSize._id,
                        }
                      }
                      onChange={(data, { action }) => {
                        data && 
                          this.props.onDdlChange({
                            target: {
                              name: "containerSize",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerSize",
                          });
                        }
                        data && this.onSelectFieldChange("containerSize",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerTypeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Type"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerTypes.map((ct) => ({
                        label: ct.name,
                        value: ct._id,
                        allInfos: ct,
                      }))}
                      loadOptions={getContainerType}
                      value={
                        containerType && {
                          label: containerType.name,
                          value: containerType._id,
                        }
                      }
                      className="containerType"
                      isClearable
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "containerType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerType",
                          });
                        }
                        data && this.onSelectFieldChange("containerType",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerOwnerName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Owner"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerOwners.map((co) => ({
                        label: co.company_name,
                        value: co._id,
                        allInfos: co,
                      }))}
                      cacheOptions
                      value={
                        containerOwner && {
                          label: containerOwner.company_name,
                        }
                      }
                      className="containerOwner"
                      isClearable
                      loadOptions={this.getTMSContainerOwner}
                      onChange={(data, { action }) => {
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerOwner",
                          });
                        }
                        if (action === "select-option") {
                          this.setState({ ContainerDefaultValue: data });
                          this.props.updateState(
                            "containerOwner",
                            data.allInfos
                          );
                          data && this.onSelectFieldChange("containerOwner",data.allInfos)
                          setTimeout(() => {
                            this.props.onDdlChange({
                              target: {
                                name: "scac",
                                value: data.allInfos.scac
                                  ? data.allInfos.scac
                                  : "",
                              },
                            });
                          this.onSelectFieldChange("scac",data.allInfos.scac ? data.allInfos.scac : "")
                          }, 2000);
                        }
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>
            
              <div className="form-row ">
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisNo"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Chassis #"
                    />
                  </label>
                  <AsyncCreatableSelect
                    className="chassisNo"
                    styles={smallSelectStyle}
                    cacheOptions
                    loadOptions={this.getChassis.bind(this)}
                    defaultOptions={chassis.map((chassis) => ({
                      label: chassis.chassisNo,
                      value: chassis._id,
                      allInfos: chassis,
                    }))}
                    isClearable
                    formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                    value={
                      this.props.customerProfile.chassisNo && {
                        label: this.props.customerProfile.chassisNo,
                      }
                    }
                    onChange={(value, { action }) => {
                      this.selectChassis(value);
                      if (action === "clear") {
                        this.onRemovePress(null, { name: "chassisNo" });
                      }
                    }}
                    onCreateOption={(value) =>
                      {
                        this.onSelectFieldChange("chassisNo", value)
                        this.props.updateState("chassisNo", value)
                      }
                    }
                    isDisabled={disabled}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisSizeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Size"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                    className="chassisSize"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisSizes.map((cs) => ({
                        label: cs.name,
                        value: cs._id,
                        allInfos: cs,
                      }))}
                      isClearable
                      loadOptions={this.getChassisSize}
                      value={chassisSize && { label: chassisSize.name }}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisSize",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisSize" });
                        }
                        data && this.onSelectFieldChange("chassisSize",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisTypeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Type"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      className="chassisType"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisTypes.map((ct) => ({
                        label: ct.name,
                        value: ct._id,
                        allInfos: ct,
                      }))}
                      isClearable
                      loadOptions={this.getChassisType}
                      value={chassisType && { label: chassisType.name }}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisType" });
                        }
                        data && this.onSelectFieldChange("chassisType",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisOwnerName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Owner"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      className="chassisOwner"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisOwners.filter((el)=>{ return el.company_name !== null }).map((co) => ({
                        label: co.company_name,
                        value: co._id,
                        allInfos: co,
                      }))}
                      isClearable
                      loadOptions={this.getTMSChassisOwner.bind(this)}
                      value={
                        chassisOwner && {
                          label: chassisOwner.company_name,
                        }
                      }
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisOwner",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisOwner" });
                        }
                        data && this.onSelectFieldChange("chassisOwner",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>
        
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control genset transition-white-field"
                    name="genset"
                    maxlength="12"
                    disabled={disabled}
                    isRemove={true}
                    value={this.props.customerProfile.genset}
                    onRemovePress={this.onRemovePress}
                    label="Genset #"
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control temperature transition-white-field"
                    name="temperature"
                    disabled={disabled}
                    isRemove={true}
                    value={this.props.customerProfile.temperature}
                    onRemovePress={this.onRemovePress}
                    label="Temperature"
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-3">
                  <label className="">Route</label>

                  <div className="input-wrapper">
                    <AsyncSelect
                      className="routeType"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={this.state.routeTypes.map((co) => ({
                        label: co,
                        value: co,
                        allInfos: co,
                      }))}
                      isClearable
                      value={routeType && { label: routeType }}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "routeType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "routeType" });
                        }
                        data && this.onSelectFieldChange("routeType",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-3">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control scac transition-white-field"
                    label="SCAC"
                    name="scac"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    onBlur={this.onBlurChange}
                  />
                </div>
              </div>
              <div className="form-row mt-2">
              <div className="form-group col-lg-3 mb-0">
                  <div class="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                    <input 
                      type="checkbox" 
                      class="form-check-input rounded-3 border-secondary" 
                      id="hazmat" 
                      value="1"
                      checked={this.props.customerProfile?.hazmat}
                      onChange={(e) => {
                        this.onSelectFieldChange("hazmat", e.target.checked)
                        this.props.updateState("hazmat", e.target.checked);
                      }}
                      disabled={disabled} 
                      />
                    <label class="m-0 pl-10" for="hazmat" id="hazmat-label">Hazmat</label>
                  </div>
                </div>
              <div className="form-group col-lg-3 mb-0">
                <div class="custom-control custom-checkbox d-flex align-items-center pl-0">
                    <input 
                      type="checkbox" 
                      class="form-check-input rounded-3 border-secondary" 
                      id="overWeight" 
                      value="2"
                      checked={this.props.customerProfile?.overWeight}
                      onChange={(e) => {
                        this.onSelectFieldChange("overWeight", e.target.checked)
                        this.props.updateState("overWeight", e.target.checked);
                      }}
                      disabled={disabled} 
                      />
                    <label class="m-0 pl-10" for="overWeight" id="overWeight-label">Overweight</label>
                  </div>
                </div>
                <div className="form-group col-lg-3 mb-0">
                <div class="custom-control custom-checkbox d-flex align-items-center pl-0">
                  <input 
                      type="checkbox" 
                      class="form-check-input rounded-3 border-secondary" 
                      id="liquor" 
                      value="2"
                      checked={this.props.customerProfile?.liquor}
                      onChange={(e) => {
                        this.onSelectFieldChange("liquor", e.target.checked)
                        this.props.updateState("liquor", e.target.checked);
                      }}
                      disabled={disabled} 
                    />
                    <label className="m-0 pl-10" for="liquor" id="liquor-label">{showForCarrier() ? "Liquid" : "Liquor"}</label>
                </div>
                </div>
                <div className="form-group col-lg-3 mb-0">
                <div class="custom-control custom-checkbox d-flex align-items-center pl-0">
                  <input 
                    type="checkbox" 
                    class="form-check-input rounded-3 border-secondary" 
                    id="hot" 
                    value="2"
                    checked={this.props.customerProfile?.hot}
                    onChange={(e) => {
                      this.onSelectFieldChange("hot", e.target.checked)
                      this.props.updateState("hot", e.target.checked);
                    }}
                    disabled={disabled} 
                  />
                  <label className="m-0 pl-10" for="hot" id="hot-label">Hot</label>
                </div>
                </div>
              </div>
            </div>
            { this.props.customerProfile.isGrayPool &&
              <div className="bg-back-light pl-20 pr-20 pt-20 pb-20">           
                <h4 className="font-16 mb-20 d-flex align-items-center">
                <IconPool /> <span className="ml-10">Gray Pool Empty</span>
                </h4>
                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <Field
                      type="text"
                      className="form-control grayContainerNo transition-white-field"
                      component={InputField}
                      name="grayContainerNo"
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      label="Gray Container #"
                      onBlur={this.onBlurChange}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerSizeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Size"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayContainerSize"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerSizes.map((cs) => ({
                          label: cs.name,
                          value: cs._id,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={getContainerSize}
                        value={
                          grayContainerSize && {
                            label: grayContainerSize.name,
                            value: grayContainerSize._id,
                          }
                        }
                        onChange={(data) => {
                          if(data){
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerSize",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerSize",
                            });
                          }
                          data && this.onSelectFieldChange("grayContainerSize",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerTypeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Type"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayContainerType"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerTypes.map((ct) => ({
                          label: ct.name,
                          value: ct._id,
                          allInfos: ct,
                        }))}
                        loadOptions={getContainerType}
                        value={
                          grayContainerType && {
                            label: grayContainerType.name,
                            value: grayContainerType._id,
                          }
                        }
                        isClearable
                        onChange={(data) => {
                          if(data) { 
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerType",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerType",
                            });
                          }
                        data && this.onSelectFieldChange("grayContainerType",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerOwnerName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Owner"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayContainerOwner"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerOwners.map((co) => ({
                          label: co.company_name,
                          value: co._id,
                          allInfos: co,
                        }))}
                        cacheOptions
                        value={
                          grayContainerOwner && {
                            label: grayContainerOwner.company_name,
                          }
                        }
                        isClearable
                        loadOptions={this.getTMSContainerOwner}
                        onChange={(data) => {
                          if(data){ 
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerOwner",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerOwner",
                            });
                          }
                          data && this.onSelectFieldChange("grayContainerOwner",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                </div>
            
                <div className="form-row ">
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisNo"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Gray Chassis #"
                      />
                    </label>
                    <AsyncCreatableSelect
                      styles={smallSelectStyle}
                      cacheOptions
                      className="grayChassisNo"
                      loadOptions={this.getChassis.bind(this)}
                      defaultOptions={chassis.map((chassis) => ({
                        label: chassis.chassisNo,
                        value: chassis._id,
                        allInfos: chassis,
                      }))}
                      isClearable
                      formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                      value={
                        this.props.customerProfile.grayChassisNo && {
                          label: this.props.customerProfile.grayChassisNo,
                        }
                      }
                      onChange={(value) => {
                        if(value){
                          this.selectGrayChassis(value);
                        }else {
                          this.onRemovePress(null, { name: "grayChassisNo" });
                        }
                      }}
                      onCreateOption={(value) =>
                        {
                          this.onSelectFieldChange("grayChassisNo", value)
                          this.props.updateState("grayChassisNo", value)
                        }
                      }
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisSizeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Size"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayChassisSize"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisSizes.map((cs) => ({
                          label: cs.name,
                          value: cs._id,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={this.getChassisSize}
                        value={grayChassisSize && { label: grayChassisSize.name }}
                        onChange={(data) => {
                          if(data){
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisSize",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisSize",
                            });
                          }
                          data && this.onSelectFieldChange("grayChassisSize",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisTypeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Type"
                      />
                    </label>
                    <div className="input-wrapper grayChassisType">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisTypes.map((ct) => ({
                          label: ct.name,
                          value: ct._id,
                          allInfos: ct,
                        }))}
                        isClearable
                        loadOptions={this.getChassisType}
                        value={grayChassisType && { label: grayChassisType.name }}
                        onChange={(data) => {
                          if(data) { 
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisType",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisType",
                            });
                          }
                          data && this.onSelectFieldChange("grayChassisType",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisOwnerName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Owner"
                      />
                    </label>
                    <div className="input-wrapper grayChassisOwner">
                      <AsyncSelect
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisOwners.filter((el)=>{ return el.company_name !== null }).map((co) => ({
                          label: co.company_name,
                          value: co._id,
                          allInfos: co,
                        }))}
                        isClearable
                        loadOptions={this.getTMSChassisOwner.bind(this)}
                        value={
                          grayChassisOwner && {
                            label: grayChassisOwner.company_name,
                          }
                        }
                        onChange={(data) => {
                          if(data){ 
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisOwner",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisOwner",
                            });
                          }
                          data && this.onSelectFieldChange("grayChassisOwner",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                </div>              
              </div>
            } 
            </div>
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Reference Numbers
              </h4>
              <div className="form-row">
                <div className="form-group col-lg-6">
                  <label>Master Bill of Lading</label>
                  <Field
                    type="text"
                    className="form-control callerbillLandingNo transition-white-field"
                    component={InputField}
                    name="callerbillLandingNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>

                 {this.props.loadType === "IMPORT" && <div className="form-group col-lg-6">
                    <label>House Bill of Lading</label>
                    <Field
                      type="text"
                      className="form-control doNo transition-white-field"
                      component={InputField}
                      name="doNo"
                      placeholder=""
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      onBlur={this.onBlurChange}
                      />
                  </div>}
                  {this.props.loadType === "IMPORT" &&
                    <div className="form-group col-lg-6 ">
                      <label>Seal #</label>
                      <Field
                        type="text"
                        className="form-control sealNo transition-white-field"
                        component={InputField}
                        name="sealNo"
                        isRemove={true}
                        onRemovePress={this.onRemovePress}
                        disabled={disabled}
                        placeholder=""
                        onBlur={this.onBlurChange}
                      />
                    </div>
                  }
                {this.props.loadType == "EXPORT" && (
                  <div className="form-group col-lg-6">
                    <label>Booking #</label>
                    <Field
                      type="text"
                      className="form-control bookingNo transition-white-field"
                      component={InputField}
                      name="bookingNo"
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      placeholder=""
                      onBlur={this.onBlurChange}
                    />
                  </div>
                )}
                <div className="form-group col-lg-6">
                  <label>Reference #</label>
                  <Field
                    type="text"
                    className="form-control secondaryReferenceNo transition-white-field"
                    component={InputField}
                    name="secondaryReferenceNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={isDisabled}
                    placeholder=""  
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Vessel Name</label>
                  <Field
                    type="text"
                    className="form-control deliveryOrderNo transition-white-field"
                    component={InputField}
                    name="deliveryOrderNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Voyage</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control releaseNo transition-white-field"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="releaseNo"
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label>Purchase Order #</label>
                  <Field
                    type="text"
                    className="form-control purchaseOrderNo transition-white-field"
                    component={InputField}
                    name="purchaseOrderNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label>Shipment #</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control shipmentNo transition-white-field"
                    placeholder=""
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="shipmentNo"
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label>Pick Up #</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control callerPONo transition-white-field"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerPONo"
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Appointment #</label>
                  <Field
                    type="text"
                    className="form-control appointmentNo transition-white-field"
                    component={InputField}
                    name="appointmentNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Return #</label>
                  <Field
                    type="text"
                    className="form-control returnNo transition-white-field"
                    component={InputField}
                    name="returnNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-6 ">
                  <label>Reservation #</label>
                  <Field
                    type="text"
                    className="form-control reservationNo transition-white-field"
                    component={InputField}
                    name="reservationNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                  />
                </div>
                  {this.props.loadType === "EXPORT" && (
                    <div className="form-group col-lg-6 ">
                      <label>Seal #</label>
                      <Field
                        type="text"
                        className="form-control sealNo transition-white-field"
                        component={InputField}
                        name="sealNo"
                        isRemove={true}
                        onRemovePress={this.onRemovePress}
                        disabled={disabled}
                        placeholder=""
                        onBlur={this.onBlurChange}
                    />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="card py-20 px-30 mb-0">
          <h4 className="font-16 mb-0">Freight Description</h4>
        </div>
        <div className="table mb-20">
          <table className="table table-card table-card--ls fd-row">
            <thead>
              <tr>
                <th scope="col" width="17%">Commodity</th>
                <th scope="col">Description</th>
                <th width="10%" scope="col">
                  Pieces
                </th>
                <th width="10%" scope="col">
                  Weight LBS
                </th>
                <th width="10%" scope="col">
                  Weight KGS
                </th>
                <th width="10%" scope="col">
                  Pallets
                </th>
                <th width="2%" scope="col"></th>
              </tr>
            </thead>
            <tbody>
                
              {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items.map((data, key) => {
                  return (
                    <tr key={key} className={`select-custom-input fd-row-${key} ${this.state.addCommodity || disabled ? "disabled-pointer-events": ""}`}  style={this.state.addCommodity ? {opacity: "0.2", transition: "background 0.4s ease-out"}: {transition: "background 0.4s ease-out"}}>
                      <td className={`commodity-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        {JSON.parse(getStorage("measureUnits"))
                          .isCommodityProfileShow == true ? (
                          <div className="form-group m-0">
                            <Select
                              styles={newSmallSelectStyle}
                              options={this.state.commodityProfiles}
                              value={this.state.commodityProfiles.find(
                                (pt) => pt.value == data.commodity
                              )}
                              onChange={(value) => {
                                const temp = [...this.state.items];
                                temp[key]["commodity"] = value.value;
                                this.setState({
                                  items: [...temp],
                                });
                                this.props.updateState("items", temp);
                                let oldItems = [
                                  ...this.props.originalLoad?.items,
                                ]
                                oldItems[0] && delete oldItems[0]?._id
                                oldItems?.forEach((item)=> {
                                  item && item._id && delete item._id
                                })
                                const noChange = _.isEqual(temp, this.props.originalLoad?.items)
                                if(noChange) return
                                this.onCommodityChanges(temp,key,"commodity")
                              }}
                            />
                          </div>
                        ) : (
                          <input
                            className={`form-control border-0 `}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name="commodity"
                            autoComplete="off"
                            value={data.commodity}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlur={(e) => this.tblvalchanges(key, e, true)}
                          />
                        )}
                      </td>
                      <td className={`description-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`form-control`}
                          type="text"
                          name="description"
                          value={data.description}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`pieces-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control`}
                          type="number"
                          name="pieces"
                          value={data.pieces}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`weight-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control `}
                          type="number"
                          name="weight"
                          value={data.weight}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`weightKgs-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control `}
                          type="number"
                          name="weightKgs"
                          value={data.weightKgs}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`pallets-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          type="number"
                          className={`tblrequire form-control `}
                          name="pallets"
                          value={data.pallets}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>

                      <td style={disabled ? {cursor: "not-allowed", transition: "background 0.4s ease-out"} : {transition: "background 0.4s ease-out"}}>
                        <div
                          type="button"
                          style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                          onClick={() => this.DeleteItemRow(key)}
                          className="btn btn-circle btn-xs bg-soft-danger"
                        >
                          <IconMinus />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            {this.state.addCommodity && <CommodityComponent commodityProfiles={this.state.commodityProfiles} handleAddCommodity={this.AddItemRows} items={this.state.items} discardRow={()=>this.setState({ addCommodity: false })}/>}
            </tbody>
          </table>
          <span style={disabled ? {cursor: "not-allowed"} : {}}>
          <div
            type="button"
            className="btn btn-white btn-sm text-primary mt-10"
            onClick={() => !disabled && this.setState({ addCommodity: true })}
            style={disabled || this.state.addCommodity ? {pointerEvents: "none", opacity: 0.4} : {}}
          >
            <IconPlus className="mr-2" />
            Add Row
          </div>
          </span>
        </div>

        <Modal show={this.state.showCutOffModal} bsSize="lg">
          <Modal.Header>
            <h4 className="modal-title"> Cuttof Status </h4>
          </Modal.Header>
          <Modal.Body>
            {this.state.bookingInfoLoading ? (
              <div>Getting Infos please wait.... This may take a minute.</div>
            ) : (
              <div>
                {this.state.bookingCutOffInfo &&
                this.state.bookingCutOffInfo.result ? (
                  <div>
                    cut Of Date :{" "}
                    {moment(
                      this.state.bookingCutOffInfo.result.cutOff
                    ).format("lll")}
                  </div>
                ) : (
                  <div>Not any Booking Infos Found</div>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-6">
              <button
                className="btn btn-default "
                disabled={!this.state.bookingCutOffInfo}
                onClick={() => {
                  this.updateCutOff();
                }}
              >
                <span> Update </span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-default col-md-6 "
                onClick={() => {
                  this.setState({ showCutOffModal: false });
                }}
              >
                <span> Cancel </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValue: state.form,
    containerSizes: state.containerReducer.containerSize,
    containerTypes: state.containerReducer.containerType,
    containerOwners: state.containerReducer.containerOwner,
    chassis: state.chassisReducer.chassis,
    chassisOwners: state.chassisReducer.chassisOwner,
    chassisSizes: state.chassisReducer.chassisSize,
    chassisTypes: state.chassisReducer.chassisType,
    isEmptyEnabled: state.TmsReducer.isEmptyEnabled,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getTMSChassis: bindActionCreators(getTMSChassis, dispatch),
  tmsAction: bindActionCreators(tmsAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCommonLoadComponent);

const StatusButton = ({
  updateState,
  buttonStatus,
  preSetOrderStatus,
  loadStatus,
}) => {
  return (
    <div className="col-md-4">
      <div className="form-check form-group">
        <input
          type="radio"
          disabled
          checked={preSetOrderStatus === buttonStatus.value ? true : false}
          className="form-check-input"
          id="prepull-drop-hook"
          name="pre-order-status"
        />
        <label className="form-check-label" for="prepull-drop-hook">
          {buttonStatus.label ? buttonStatus.label : "DEFAULT"}
        </label>
      </div>
    </div>
  );
};
const YesOrNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
const HoldOrReleased = [
  { label: "Yes", value: "HOLD" },
  { label: "No", value: "RELEASED" },
];