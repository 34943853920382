import _ from 'lodash';
import { convertTMSStatusName } from '../Common/functions';
import { getStorage } from "../../services/Common.services";
import { HTTP } from "../../services/Http.service";
import jsonToQueryParams from '../Common/jsonToQueryParams';
import {
  GET_SHIPPERS_START,
  GET_SHIPPERS_SUCCESS,
  GET_SHIPPERS_FAILED,
  GET_CONSIGNEES_SUCCESS,
  GET_CONSIGNEES_FAILED,
  GET_CONSIGNEES_START,
  GET_CHASSIS_TERMS_START,
  GET_CHASSIS_TERMS_SUCCESS,
  GET_CHASSIS_TERMS_FAILED,
  GET_CALLERS_START,
  GET_CALLERS_SUCCESS,
  GET_CALLERS_FAILED,
  GET_ALL_CUSTOMER_START,
  GET_ALL_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_FAILED,
  GET_ALL_USER_PUBLIC_INFO_SUCCESS,
  GET_ALL_USER_PUBLIC_INFO_FAILED,
} from '../../pages/tms/constant';

export function getContainerSize() {
  return function (dispatch) {
    const token = getStorage('token')
    let url = 'admin/getContainerSize'
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CONTAINER_SIZE', payload: result.data.data })
        }
      })
      .catch(error => {
        console.log('---- error ----', error)
      })
  }
}

export function getContainerType() {
  return function (dispatch) {
    let url = 'admin/getContainerType'
    const token = getStorage('token')
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CONTAINER_TYPE', payload: result.data.data })
        }
      })
      .catch(error => {
        console.log('---- error ----', error)
      })
  }
}

export function getTMSContainerOwner() {
  return function (dispatch) {
    let url = 'carrier/getTMSContainerOwner'
    const token = getStorage('token')
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CONTAINER_OWNER', payload: result.data.data })
        }
      })
      .catch(error => {
        console.log('---- error ----', error)
      })
  }
}

export function getTMSChassis() {
  const token = getStorage('token');
  let url = 'carrier/getTMSChassis';
  return function (dispatch) {
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CHASSIS', payload: result.data.data })
        }
      })
      .catch(error => {
      })
  }
}

export function getTrailers() {
  const params = { equipment_type: "TRAILER", limit: 5 };
  let url = 'carrier/getTMSEquipments';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_TRAILER', payload: result.data.data })
        }
      })
      .catch((error) => { });
  }
}

export function getChassisSize() {
  return function (dispatch) {
    let url = 'admin/getChassisSize'
    const token = getStorage('token')
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CHASSIS_SIZE', payload: result.data.data })
        }
      })
      .catch(error => {
        console.log('---- error ----', error)
      })
  }
}

export function getChassisType() {
  return function (dispatch) {
    let url = 'admin/getChassisType'
    const token = getStorage('token')
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CHASSIS_TYPE', payload: result.data.data })
        }
      })
      .catch(error => {
        console.log('---- error ----', error)
      })
  }
}
export function getTMSChassisOwner() {
  return function (dispatch) {
    let url = 'carrier/getTMSChassisOwner'
    const token = getStorage('token')
    HTTP('get', url, null, {
      authorization: token
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch({ type: 'SET_CHASSIS_OWNER', payload: result.data.data })
        }
      })
      .catch(error => {
        console.log('---- error ----', error)
      })
  }
}

export function getAllStatusFilter(params) {
  let url = 'tms/allLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const statuses = []
          result.data.data.forEach((obj) => {
            let updatedVal = convertTMSStatusName('IMPORT',obj,{custom:''},null)
            statuses.push({ value: obj, label: updatedVal });
          })
          dispatch({ type: 'SET_STATUS_TYPES', payload: statuses });
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getAllDrivers() {
  const url = 'carrier/getAllDriver';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'TMS_DRIVER_START' })
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const drivers = _.get(result, 'data.data.data');
          if (drivers && _.isArray(drivers)) {
            dispatch({ type: 'TMS_DRIVER', payload: drivers });
          }
          else {
            dispatch({ type: 'TMS_DRIVER_FAILED' })
          }
          resolve(result.data.data)

        })
        .catch((error) => {
          dispatch({ type: 'TMS_DRIVER_FAILED' })
          reject(error);
        });
    })
  }
}

export const getCallers = () => (dispatch) => {
  const obj = { limit: 10, customerType: JSON.stringify(['caller','ALL']) }
  const url = 'carrier/getTMSCustomers' + "?" + jsonToQueryParams(obj);

  dispatch({ type: GET_CALLERS_START });
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const payload = _.get(result, 'data.data')

        if (payload)
          dispatch({ type: GET_CALLERS_SUCCESS, payload });
        else
          dispatch({ type: GET_CALLERS_FAILED });
      })
      .catch((error) => {
        dispatch({ type: GET_CALLERS_FAILED });
        reject(error);
      });
  })
}

export const getShippers = () => (dispatch) => {
  const obj = { limit: 10, customerType: JSON.stringify(['shipper', 'containerReturn','ALL']) }
  const url = 'carrier/getTMSCustomers' + "?" + jsonToQueryParams(obj);

  dispatch({ type: GET_SHIPPERS_START });
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const payload = _.get(result, 'data.data')

        if (payload)
          dispatch({ type: GET_SHIPPERS_SUCCESS, payload });
        else
          dispatch({ type: GET_SHIPPERS_FAILED });
      })
      .catch((error) => {
        dispatch({ type: GET_SHIPPERS_FAILED });
        reject(error);
      });
  })
}

export const getConsignees = () => (dispatch) => {
  const obj = { limit: 10, customerType: JSON.stringify(['consignee','ALL']) }
  const url = 'carrier/getTMSCustomers' + "?" + jsonToQueryParams(obj);

  dispatch({ type: GET_CONSIGNEES_START });
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const payload = _.get(result, "data.data")

        if (payload)
          dispatch({ type: GET_CONSIGNEES_SUCCESS, payload });
        else
          dispatch({ type: GET_CONSIGNEES_FAILED });
      })
      .catch((error) => {
        dispatch({ type: GET_CONSIGNEES_FAILED });
        reject(error);
      });
  })
}

export const getAllCustomers = () => (dispatch) => {
  const obj = { limit: 10, customerType: JSON.stringify(['ALL']) }
  const url = 'carrier/getTMSCustomers' + "?" + jsonToQueryParams(obj);

  dispatch({ type: GET_ALL_CUSTOMER_START });
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const payload = _.get(result, "data.data")

        if (payload)
          dispatch({ type: GET_ALL_CUSTOMER_SUCCESS, payload });
        else
          dispatch({ type: GET_ALL_CUSTOMER_FAILED });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_CUSTOMER_FAILED });
        reject(error);
      });
  })
}

export const getChassisTerms = () => (dispatch) => {
  const obj = { limit: 10, customerType: JSON.stringify(['chassisTermination', 'chassisPick','ALL']) }
  const url = 'carrier/getTMSCustomers' + "?" + jsonToQueryParams(obj);

  dispatch({ type: GET_CHASSIS_TERMS_START });
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const payload = _.get(result, "data.data")

        if (payload)
          dispatch({ type: GET_CHASSIS_TERMS_SUCCESS, payload });
        else
          dispatch({ type: GET_CHASSIS_TERMS_FAILED });
      })
      .catch((error) => {
        dispatch({ type: GET_CHASSIS_TERMS_FAILED });
        reject(error);
      });
  })
}

export const getAllUserPublicInfo = () => (dispatch) => {
  const url = 'carrier/getUserPublicInfo'
  dispatch({ type: GET_ALL_CUSTOMER_START });
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const payload = _.get(result, "data.data")

        if (payload)
          dispatch({ type: GET_ALL_USER_PUBLIC_INFO_SUCCESS, payload });
        else
          dispatch({ type: GET_ALL_USER_PUBLIC_INFO_FAILED });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_USER_PUBLIC_INFO_FAILED });
        reject(error);
      });
  })
}

export function getChargeCodes () {
  return function (dispatch) {
    let url = 'chargeCode/getChargeCode'
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result) {
            dispatch({ type: 'SET_CHARGE_CODE', payload: result.data.data })
            // resolve(result.data.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getUserById(_id) {
  let url = `admin/getUserById?_id=${_id}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = _.get(result, "data.data")
        resolve(data.user);
      })
      .catch((error) => {
        reject(error);
      });
  })
}