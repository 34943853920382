import config from "../../config";
import { toastr } from "../../services";
import quickbookPaths from "../Common/Header/paths";

export const listenQBSocketMessage = (socketEvent, isFirebaseInitialize) => {
  socketEvent.on("value", (snapshot) => {
    const pathname = window.location.pathname;
    if (!isFirebaseInitialize) {
      isFirebaseInitialize = true;
      return;
    }
    const data = snapshot.val();
    const messageData = JSON.parse(data);
    if (
      messageData &&
      messageData.message &&
      messageData.type &&
      Object.values(quickbookPaths).includes(pathname)
    ) {
      const messageType = messageData.type;
      toastr.show(messageData.message, messageType.toLowerCase());
    }
  });
};

export const listenQBCustomerSocketMessage = (
  socketEvent,
  isCustomerFirebaseInitialize
) => {
  socketEvent.on("value", (snapshot) => {
    const pathname = window.location.pathname;
    if (!isCustomerFirebaseInitialize) {
      isCustomerFirebaseInitialize = true;
      return;
    }
    const data = snapshot.val();
    const messageData = JSON.parse(data);
    if (
      messageData &&
      messageData.message &&
      messageData.type &&
      Object.values(quickbookPaths).includes(pathname) &&
      pathname !== "/tms/billing/account-receivable"
    ) {
      const messageType = messageData.type;
      toastr.show(messageData.message, messageType.toLowerCase());
    }
  });
};
