import React, { useState } from "react";
import { TextInputField } from "./CommonInputComponent";
import { SetRequired ,successColorResponse,errorColorResponse, onChangeSuccessColorResponse  } from "../../utility";
import { smallSelectStyle } from "../../../../../../assets/js/select-style";
import { useEffect } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { editTMSCustomer } from "../../../../services";
import { toastr } from "../../../../../../services";
const EmailCustomerComponent = ({ Selected_customer, tab, customerType,updateCustomer}) => {
  const [email, setEmail] = useState(Selected_customer.email ? Selected_customer.email : "");
  const [receiverEmail, setReceiverEmail] = useState(Selected_customer.receiverEmail ? Selected_customer.receiverEmail : "");
  const [qbEmail, setQbEmail] = useState(Selected_customer.qbEmail ? Selected_customer.qbEmail : "");
  const [emailList, setEmailList] = useState([]);
  const [allEmail ,setAllEmail] = useState([])
  const [tempEmail, setTempEmail] = useState(null);
  
  const onBlurHandler = (key, val) => {
    if(val === "") return
    if (customerType.intersect(["caller"]).length > 0 && email == "") {
      return;
    }
    SetRequired(key)
    if (Selected_customer[key] == val) return;
    let data = {
      _id: Selected_customer._id,
      [key]: val,
    };
    editTMSCustomer(data)
      .then((result) => {
        successColorResponse(key);
        updateCustomer(result.data.data);
      })
      .catch((err) => {
        console.log("err", err);
        toastr.show(err, "error");
        errorColorResponse(key);
      });
  };
  useEffect(()=>{
    if (Selected_customer.billingEmail) {
        const billingEmail = Selected_customer.billingEmail.includes(",")
          ? Selected_customer.billingEmail.split(",")
          : [Selected_customer.billingEmail];
        const allEmail = billingEmail
          .filter((email) => email)
          .map((email) => ({ label: email, value: email }));
        setEmailList(allEmail)
      }
  },[])
  
  const addEmails = (values) => {
    if(values) {
      const value = { label: values, value: values };
      setEmailList([...emailList, value]);
      const inValidEmailList = [...emailList, value].filter(
        (email) =>  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.value) ? false : true)
      
      if (inValidEmailList && inValidEmailList.length > 0) {
        toastr.show("Please check the format of the billing email.", "error");
        return;
      } else {
        let billingEmail = "";
        ([...emailList, value] || []).forEach((email) => {
          billingEmail +=
          [...emailList, value].length === 1 ? email.value : email.value + ",";
        });
        let params = {
          _id: Selected_customer._id,
          billingEmail: billingEmail
        }
        editTMSCustomer(params).then((result) => {
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse("billingEmail")
        }).catch((err) => {
            console.log(err)
            errorColorResponse("billingEmail")
        }) 
      }
    }
  }

  const handleBillingEmail = (value) => {
    let billingEmail = "";
    value.forEach((email) => {
      billingEmail +=
      value.length === 1 ? email.value : email.value + ",";
    });
    let params = {
      _id: Selected_customer._id,
      billingEmail: billingEmail
    }
    editTMSCustomer(params).then((result) => {
        updateCustomer(result.data.data)
        onChangeSuccessColorResponse("billingEmail")
    }).catch((err) => {
        console.log(err)
        errorColorResponse("billingEmail")
    }) 
  }

  const onEmailInputChange = (textInput, { action }) => {
    setTempEmail(textInput);
    let tempEmailValue
    if (action === "input-change") {
      tempEmailValue = textInput
    }
    if (action === "set-value" || action === "menu-close") {
      tempEmailValue = null;
    }
    if (tempEmailValue !== null && action === "input-blur") {
      const emailListValue = emailList
      const findDuplicateTempEmail = emailListValue.find((email) => email.value === tempEmailValue )
      
      if(!findDuplicateTempEmail) {
        addEmails(tempEmailValue);
      }
      tempEmailValue = null;
    }
  };
  const _handleKeyDown = (e) => {
    const emailListValue = emailList;
    const checkDuplicateEmail = emailListValue.some((el) => el.value === tempEmail);
    if (e.key === 'Tab' || e.key === 'Enter') {
      if(checkDuplicateEmail) {
        e.preventDefault();
        toastr.show("Please check! You have added a duplicate email", "warning");
        return;
      }
    }
  }
  return (
    <div>
      {tab == 1 &&
        customerType.intersect(["caller", "consignee", "ALL"]).length > 0 && (
          <React.Fragment>
            <div className="form-row">
              <div className="col-md-12">
                <div className="font-medium font-14 mb-15">Email</div>
              </div>
              <React.Fragment>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">
                      {customerType.intersect(["caller"]).length > 0 && (
                        <span className="text-danger">*</span>
                      )}{" "}
                      Email and Customer Portal Admin Login
                    </label>
                    <TextInputField
                      type={"text"}
                      name="email"
                      className={`form-control ${!email && customerType.intersect(["caller"]).length > 0 && "background-failure"}`}
                      placeholder="Email and Customer Portal Admin Login"
                      value={email}
                      onChange={(e, key) => {
                        setEmail(e.target.value.trim());
                        if (customerType.intersect(["caller"]).length > 0) {
                          SetRequired(key);
                        }
                      }}
                      onBlur={(key, val) => {
                        if(val && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(val)) {
                          toastr.show("Please Enter Valid Receiver Email Address", "error");
                          return
                        } else {
                          onBlurHandler(key, val)
                        }
                      }}
                      required={customerType.intersect(["caller"]).length > 0 ? true : false}
                      autoComplete="off"
                    />
                  </div>
                </div>
                
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Receiver Email</label>
                    <TextInputField
                      type={"text"}
                      name="receiverEmail"
                      className="form-control"
                      placeholder="Enter Receiver Email"
                      value={receiverEmail}
                      onChange={(e,key)=>{
                        setReceiverEmail(e.target.value)
                      }}
                      onBlur={(key, val) => {
                        if(val && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(val)) {
                          toastr.show("Please Enter Valid Receiver Email Address", "error");
                          return
                        } else {
                          onBlurHandler(key, val)
                        }
                      }}
                      autoComplete="off"
                      required={false}
                    />
                  </div>
                </div>
              </React.Fragment>
              {customerType.intersect(["caller", "ALL"]).length > 0 && (
                <React.Fragment>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">Billing Email</label>
                        <AsyncCreatableSelect
                          menuPlacement="top"
                          cacheOptions
                          isMulti
                          styles={smallSelectStyle}
                          defaultOptions={allEmail}
                          formatCreateLabel={(inputValue) =>
                            `Add "${inputValue}"`
                          }
                          value={emailList}
                          onChange={(value) => {
                            console.log("val ", value);
                            setEmailList(value);
                            handleBillingEmail(value)
                          }}
                          onCreateOption={(value) => addEmails(value)}
                          onInputChange={onEmailInputChange}
                          placeholder={"Enter Billing Email"}
                          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null,Menu: () => null}}
                          onKeyDown={_handleKeyDown}
                          className={"billingEmail"}
                        />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">Quickbooks Email</label>
                      <TextInputField
                        type={"text"}
                        name="qbEmail"
                        className="form-control"
                        placeholder="Quickbooks Email"
                        value={qbEmail}
                        onChange={(e,key)=>{
                            setQbEmail(e.target.value)
                            SetRequired(key)
                        }}
                        onBlur={(key, val) => {
                          if(val && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(val)) {
                            toastr.show("Please Enter Valid Receiver Email Address", "error");
                            return
                          } else {
                            onBlurHandler(key, val)
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div class="hr-light my-20"></div>
          </React.Fragment>
        )}
    </div>
  );
};

export default EmailCustomerComponent;
