import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import _ from "underscore";
import { toastr } from "../../../services/Common.services";
import Invoice from "./Invoice";
import "./style.css";
import {
  IconInvoiceAlt,
  IconDelivery,
  IconGate,
  IconBundle,
  IconDoumentAlt,
} from "../../../Components/Common/Icons";
import {
  sendBillingEmail,
  printSelected,
  downloadLambdaDocument,
} from "../services";
import { emailInvoice, getBundleDesign, getDocumentDesigns, getPODDesign, sendEmailofDocument, getCarrierRateConDesign } from "../DocumentConfiguration/actionCreators";
import { getDocumentDesignswithLoad, getInvoiceDesign, getRateConfirmationDesign } from "../DocumentConfiguration/actionCreators";
import { invoice, rateConf ,  pod, carrierRateCon, combineInvoice } from "../DocumentConfiguration/constants";
import { getStorage } from "../../../services";
class PrintModal extends Component {
  state = {
    base64invoice: "",
    selected: null,
    docs: [],
    choosenDocs: [],
    isLoading: null,
  };

  arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  componentWillReceiveProps(props) {
    if (props.selectedLoads) {
      this.setState({ docs: props.selectedLoads.documents });
    }
  }

  downloadSelected = () => {
    if (this.state.choosenDocs.length > 0) {
      printSelected(JSON.stringify(this.state.choosenDocs)).then((result) => {
        const fileName = `${new Date().toISOString()}-document.pdf`;
        var byteArray = new Uint8Array(result.data.data.data);
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(
          new Blob([byteArray], { type: "application/octet-stream" })
        );
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    } else {
      toastr.show("Please select your documents", "warning");
    }
  };

  selectDoc = (info) => {
    let choosenDocs = [...this.state.choosenDocs];
    const index = this.state.choosenDocs.findIndex((x) => x._id == info._id);
    if (index != -1) {
      choosenDocs.splice(index, 1);
    } else {
      choosenDocs.push(info);
    }
    this.setState({ choosenDocs }, () => {});
  };

  onChange = (selectedNumber) => {
    let selected = selectedNumber;
    let indexs = [1,2,4,7,8];
    if (indexs.includes(selected)) {
      const formData = new FormData();
      formData.append(
        "reference_number",
        this.props.selectedLoads.reference_number
      );
      formData.append(
        "type",
        selected == 1 || selected === 7 || selected === 8 ? "invoice" : "delivery"
      );
      if (selected == 4) {
        formData.append("isBundle", true);
      }
      if (selected === 7) {
        formData.append("isRateConfirm", true);
      }
      if (selected === 8) {
        formData.append("isExpense", true)
      }
      this.setState({ isLoading: selected });
      sendBillingEmail(formData)
        .then((result) => {
          let fileName;
          if (selected == 1) {
            fileName = `${moment().toISOString()}-invoice.pdf`;
          }
          if (selected == 2) {
            fileName = `${moment().toISOString()}-delivery.pdf`;
          }
          if (selected == 8) {
            fileName = `${moment().toISOString()}-expense.pdf`;
          }
          if (selected == 4) {
            fileName = `${moment().toISOString()}-bundle.pdf`;
          }
          if (selected == 6) {
            fileName = `${moment().toISOString()}-bol.pdf`;
          }
          if (selected == 7) {
            fileName = `${moment().toISOString()}-RateConf.pdf`;
          }
          var byteArray = new Uint8Array(result.data.data.data);
          var a = window.document.createElement("a");

          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          this.setState({ isLoading: null });
        })
        .catch(() => this.setState({ isLoading: null }));
    }

    if (selected == 5 || selected == 6) {
      const type =
        selected == 5 ? "inout" : selected == 6 ? "billOfLading" : null;
      let obj = {
        // reference_number: load.reference_number,
        type,
        load: this.props.selectedLoads,
      };
      // formData.append("reference_number", load.reference_number);
      // formData.append("mergeDownload", true);
      this.setState({ isLoading: selected });
      downloadLambdaDocument(obj)
        .then((result) => {
          let fileName = `${moment().toISOString()}-${type}.pdf`;
          var byteArray = new Uint8Array(result.data.data.data);
          var a = window.document.createElement("a");

          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          this.setState({ isLoading: null });
        })
        .catch((err) => {
          this.setState({ isLoading: null });
        });
    }
    this.setState({ selected });
  };

  on_Change = (selectedNumber) => {
    let selected = selectedNumber;
    let indexs = [1,2,4,7,8];
    if (indexs.includes(selected)) {
      const formData = new FormData();
      formData.append(
        "reference_number",
        this.props.selectedLoads.reference_number
      );
      formData.append(
        "type",
        selected == 1 || selected === 7 || selected === 8 ? "invoice" : "delivery"
      );
      if (selected == 4) {
        formData.append("isBundle", true);
      }
      if (selected === 7) {
        formData.append("isRateConfirm", true);
      }
      if (selected === 8) {
        formData.append("isExpense", true)
      }
      this.setState({ isLoading: selected });
      if(selected === 2) {
        let query = {};
        if(this.props.selectedLoads.reference_number){
         query.reference_number = this.props.selectedLoads.reference_number
        } 
        query.typeOfDocument = pod;
          getPODDesign({...query}).then((result)=>{
            const fileName = `${moment().toISOString()}-proofOfdelivery.pdf`;
            var byteArray = new Uint8Array(result.data[0].data);
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
              new Blob([byteArray], { type: "application/octet-stream" })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.setState({ isLoading: null });
          })
       
      }
      if(selected === 4){
        let query = {};
        if(this.props.selectedLoads.reference_number){
         query.reference_number = this.props.selectedLoads.reference_number
        } 
        query.typeOfDocument = 'bundle';
          getBundleDesign(query).then((result)=>{
            const  fileName = `${moment().toISOString()}-bundle.pdf`;
            var byteArray = new Uint8Array(result.data.data);
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
              new Blob([byteArray], { type: "application/octet-stream" })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.setState({ isLoading: null });
          })
       
      }
      let query = {reference_number : this.props.selectedLoads.reference_number }
      
     if(selected == 1){
        query.typeOfDocument = invoice;  
        query.ignoreDocument = true;                                                               
        getInvoiceDesign({ ...query}).then((result)=>{
          const fileName = `${
            query.invoiceNumber ? query.invoiceNumber :
            this.props.loads ?
               this.props.loads.reference_number
              : this.props.selectedLoads.reference_number
          }-invoice.pdf`;
          var byteArray = new Uint8Array(result.data[0].data);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({ isLoading: null });
        }).catch(() => {this.setState({ isLoading: null }); toastr.show("Invoice can not be downloaded","error")})
     }
    
     if(selected == 7){
       query.typeOfDocument = rateConf
        getRateConfirmationDesign(query).then((result)=>{
         const fileName = `${moment().toISOString()}-RateConf.pdf`;
          var byteArray = new Uint8Array(result.data[0].data);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({ isLoading: null });
        }).catch(() => {this.setState({ isLoading: null }) ; toastr.show("Rate confirmation could not be downloaded", "error")} )
     }
      if(selected == 8) {
        let query = {};
        if(this.props.selectedLoads.reference_number){
         query.reference_number = this.props.selectedLoads.reference_number
        } 
        query.typeOfDocument = carrierRateCon;
          getCarrierRateConDesign(query).then((result)=>{
            const fileName = `${moment().toISOString()}-carrierRateCon.pdf`;
            var byteArray = new Uint8Array(result.data[0].data);
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
              new Blob([byteArray], { type: "application/octet-stream" })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.setState({ isLoading: null });
          })
       
      }
  
      // sendBillingEmail(formData)
      //   .then((result) => {
      //     let fileName;
      //     if (selected == 1) {
      //       fileName = `${moment().toISOString()}-invoice.pdf`;
      //     }
      //     if (selected == 2) {
      //       fileName = `${moment().toISOString()}-delivery.pdf`;
      //     }
      //     if (selected == 8) {
      //       fileName = `${moment().toISOString()}-expense.pdf`;
      //     }
      //     if (selected == 4) {
      //       fileName = `${moment().toISOString()}-bundle.pdf`;
      //     }
      //     if (selected == 6) {
      //       fileName = `${moment().toISOString()}-bol.pdf`;
      //     }
      //     if (selected == 7) {
      //       fileName = `${moment().toISOString()}-RateConf.pdf`;
      //     }
      //     var byteArray = new Uint8Array(result.data.data.data);
      //     var a = window.document.createElement("a");

      //     a.href = window.URL.createObjectURL(
      //       new Blob([byteArray], { type: "application/octet-stream" })
      //     );
      //     a.download = fileName;

      //     document.body.appendChild(a);
      //     a.click();

      //     document.body.removeChild(a);
      //     this.setState({ isLoading: null });
      //   })
      //   .catch(() => this.setState({ isLoading: null }));
    }

    if (selected == 5 || selected == 6) {
      const type =
        selected == 5 ? "inout" : selected == 6 ? "billOfLading" : null;
      let obj = {
        // reference_number: load.reference_number,
        type,
        load: this.props.selectedLoads,
      };
      // formData.append("reference_number", load.reference_number);
      // formData.append("mergeDownload", true);
      this.setState({ isLoading: selected });
      downloadLambdaDocument(obj)
        .then((result) => {
          let fileName = `${moment().toISOString()}-${type}.pdf`;
          var byteArray = new Uint8Array(result.data.data.data);
          var a = window.document.createElement("a");

          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          this.setState({ isLoading: null });
        })
        .catch((err) => {
          this.setState({ isLoading: null });
        });
    }
    this.setState({ selected });
  };

  render() {
  
    return (
      // <div>
      <Modal className="backdrop_modal_open" show={this.props.showModal} centered animation={false} dialogClassName="modal-xl">
        <Modal.Header>
          <Modal.Title>Choose doc type for Print</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-30 px-30">
          <div className="print-wrapper">
          <div className="form-row-sm" style={{ cursor: "pointer" }}>
              <a
                download={`${
                  this.props.selectedLoads &&
                  this.props.selectedLoads.reference_number
                }-invoice.pdf`}
                id="downloadPdf"
                href={this.state.downloadedContent}
              ></a>
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 1}
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ? this.on_Change(1) : this.onChange(1)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 1 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconInvoiceAlt />
                      </div>
                    )}

                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      Invoice 
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 2}
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(2) : this.onChange(2)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center ">
                    {this.state.isLoading === 2 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconDelivery />
                      </div>
                    )}

                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      POD 
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 5}
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(5) : this.onChange(5)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 5 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconGate />
                      </div>
                    )}

                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      In/Out Gate Receipt
                    </div>
                  </div>
                </button>
              </div>
              {this.state.docs&& this.state.docs.length!=0 &&<div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 3}
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(3) : this.onChange(3)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 3 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconDoumentAlt />
                      </div>
                    )}

                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      Documents
                    </div>
                  </div>
                </button>
              </div>}
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 4 }
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(4) : this.onChange(4)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 4 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconBundle />
                      </div>
                    )}

                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      Bundle 
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 6 }
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(6) : this.onChange(6)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 6 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconBundle />
                      </div>
                    )}

                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      BOL
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 7}
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(7) : this.onChange(7)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 7 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconBundle />
                      </div>
                    )}
                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      Rate Con 
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-md col-sm-4 my-1">
                <button
                  className="border border-gray p-10 ncard w-100"
                  disabled={this.state.isLoading === 8}
                  onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ?  this.on_Change(8) : this.onChange(8)}
                >
                  <div className="d-flex flex-column justify-content-start align-items-center">
                    {this.state.isLoading === 8 ? (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <div className="">
                        <IconBundle />
                      </div>
                    )}
                    <div
                      className={`font-weight-500 mt-2  ${
                        this.state.isLoading ? "text-secondary" : "text-dark"
                      }  text-center font-12`}
                    >
                      Carrier Rate Con 
                    </div>
                  </div>
                </button>
              </div>

              {/* <button className="btn btn-primary mr-2 my-2" onClick={() => this.onChange(1)}>Invoice</button>
                                <button className="btn btn-success text-white mr-2 my-2" onClick={() => this.onChange(7)}>RATE CONF</button>
                                <button className="btn btn-success text-white mr-2 my-2" onClick={() => this.onChange(2)}>Delivery</button>
                                <button className="btn btn-success text-white mr-2 my-2" onClick={() => this.onChange(5)}>In Gate/ Out Gate Receipt</button>
                                <button className="btn btn-secondary mr-2 my-2" onClick={() => this.onChange(3)}>Documents</button>
                                <button className="btn btn-secondary mr-2 my-2" onClick={() => this.onChange(4)}>Bundle</button>
                                <button className="btn btn-secondary my-2" onClick={() => this.onChange(6)}>BOL</button> */}
              {/* </span> */}
              {this.state.selected == 3 && this.state.docs.length > 0 && (
                <div>
                  <div className="col-md-12" style={{ padding: 10 }}>
                    <label className="Bill-label">List of Documents</label>
                    <div
                      className="custom-style-scroll"
                      style={{ height: 400, overflow: "auto" }}
                    >
                      <table className="table table-striped table-bordered billing-table">
                        <thead>
                          <tr className="imaging-HTable">
                            <th className="imaging-WidthT">Doc Type</th>
                            <th>Date</th>
                            <th>User</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.docs.map((info, key) => {
                            return (
                              <tr
                                key={key}
                                id={info._id}
                                style={{
                                  backgroundColor:
                                    this.state.selectedDoc &&
                                    this.state.selectedDoc._id === info._id
                                      ? "#a5db03"
                                      : "",
                                }}
                              >
                                <td>
                                  {" "}
                                  <input
                                    type="checkbox"
                                    onChange={() => this.selectDoc(info)}
                                  />{" "}
                                  {info.type}
                                </td>
                                <td>
                                  {moment(info.date).format("MM/DD/YYYY")}
                                </td>
                                <td>{info.userId.name}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn view-btn"
                                    onClick={() => window.open(info.url)}
                                  >
                                    <i className="uil uil-print"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ padding: 10 }}>
                    <button
                      type="button"
                      className="btn view-btn"
                      onClick={() => this.downloadSelected()}
                    >
                      <i className="uil uil-print"></i>
                      Print Selected Documents
                    </button>
                  </div>
                </div>
              )}
          </div> 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={()=> {this.setState({selected : ""});  this.props.handleClose()}}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      // </div>
    );
  }
}

export default PrintModal;
