import React, { Component } from "react";
import { Field } from "redux-form";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  getTerminal,
  removeValueLoad,
  getTrailers,
  getTrailerTypes,
  getTrailerSizes,
  editLoad,
  UpdatePricing,
} from "../services";
import configuration from "../../../config";
import {
  toastr,
  makeOptionsForSelect,showForTerminal,
  getStorage,
  isMilitaryTime,
} from "./../../../services/Common.services";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import { InputField, SelectField } from "../formComponent";
import Select from "react-select";
import { loadFlags } from "./utility";

import {
  getTMSshipper,
  getTMSconsignee,
  getTMScaller,
  getCommodityProfile,
} from "../Dispatcher/actionCreators";
import {
  IconCalendar,
  IconMinus,
  IconPlus,
  IconTimes,
} from "../../../Components/Common/Icons";
import { newSmallSelectStyle, smallSelectStyle } from "../../../assets/js/select-style";
import _ from "lodash";
import confirm from "../../../Components/Common/ConfirmAert";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import CommodityComponent from "./CommodityComponent";
let timeZone = getStorage("timeZone");

const commodityItems = {
  commodity: "",
  description: "",
  pieces: null,
  units: null,
  weight: null,
  weightKgs: null,
  weightUnitType: "",
  pallets: null,
  palletsUnits: "",
};

class NewRoadLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminals: [],
      commodityProfiles: [],
      Terminals: [],
      items: [{ ...commodityItems}],
      terminal:null,
      addCommodity: false,
    };
    this.willCall = {};
    this.clearInfosOnAdd = this.clearInfosOnAdd.bind(this);
    this.onRemovePress = this.onRemovePress.bind(this);
    this.userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
    this.timeZone = getStorage("timeZone");
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this;
    if (props.Terminals && props.Terminals.length > 0) {
      self.setState({ Terminals: props.Terminals });
    }
    if (props.customerProfile.terminal) {
      if(props.customerProfile.terminal._id){
        this.setState({ terminal:{value: props.customerProfile.terminal._id, label:props.customerProfile.terminal.name} });  
      }
      if(props.customerProfile.terminal.value){
        this.setState({ terminal:props.customerProfile.terminal });  
      }
      
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      getTerminal().then((response) => {
        const Terminals = response.data.data ? response.data.data : [];
        self.setState({ Terminals });
      });
    }
  }
  repetiveAPICall = (obj) => {
    for(const property in obj){
      let finalObj = {
        reference_number: this.props.customerProfile.reference_number,
        [property]: obj[property],
        source: "loadinfo",
      }
        editLoad(finalObj).then(()=>{}).catch(()=>{})
    }
    this.willCall = Object.assign({})
  }

  onRemovePress(e, input) {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      key: input.name,
    };
    removeValueLoad(params).then((result) => {
      this.willCall = Object.assign({})
      const element = document.querySelector(`.${input.name}`);
      let dates = ["Date","Day","vessel","return","cutOff","loadTime"];
      const isDate = dates.some((str)=>input.name.includes(str))
      const divInElement = isDate ? element?.querySelector("input") : element?.querySelector("div");
      const elementToChange = divInElement ? divInElement : element;
      elementToChange?.classList.remove("background-failure");
      elementToChange?.classList.add("background-success");
      setTimeout(()=>{
        elementToChange?.classList.remove("background-success","text-danger");
      },400)
      this.props.updateState(input.name, result.data.data[input.name]);
    });
  }
  onRemovePressOnDate(e, input, index) {
    let params = {
      reference_number: this.props.customerProfile.reference_number,
    };
    let { customerProfile } = this.props;
    delete customerProfile[`${input}Times`][index][`${input}FromTime`];
    delete customerProfile[`${input}Times`][index][`${input}ToTime`];
    params[`${input}Times`] = customerProfile[`${input}Times`];
    params[`${input}Times`].map((d) => {
      delete d._id;
      Object.keys(d).map((key) => {
        if (!d[key]) delete d[key];
      });
    }); // delete _id and falsy keys
    const finalParams = params[`${input}Times`].filter((obj) => !_.isEmpty(obj));
    params[`${input}Times`] = JSON.stringify(finalParams);
    let arr =
      input.indexOf("pickup") > -1
        ? `${input}From` === "pickupFrom"
          ? [`.pickupTo-${index}`, `.pickupFrom-${index}`]
          : [`.pickupTo-${index}`]
        : `${input}From` === "deliveryFrom"
        ? [`.deliveryTo-${index}`, `.deliveryFrom-${index}`]
        : [`.deliveryTo-${index}`];
        
      const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
      
      editLoad(params).then((res) => {
        if (res.status === 200) {
          elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
          this.props.updateState(
            `${input}Times`,
            customerProfile[`${input}Times`]
          );
        }
      });
  }
  clearInfosOnAdd() {
    this.setState({
      caller: "",
      shipper0: "",
      consignee0: "",
    });
  }

  onPickDeliveryChanges = (time,update,i) => {
    let Times = [];
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    
    let noChange = _.isEqual(this.props.originalLoad[controlName],this.props.customerProfile[controlName])
    if (noChange) return

    this.props.customerProfile[field].forEach((el,i)=>{
      el._id && Times.push({ ...this.props.customerProfile[controlName][i], customerId: el._id })
    })
    let filteredPickup = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [controlName]: filteredPickup,
      }
      return
    }
    let arr =
    time.indexOf("pickup") > -1
      ? update === "pickupFrom"
        ? [`.pickupTo-${i}`, `.pickupFrom-${i}`]
        : [`.pickupTo-${i}`]
      : update === "deliveryFrom"
      ? [`.deliveryTo-${i}`, `.deliveryFrom-${i}`]
      : [`.deliveryTo-${i}`];

    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
    editLoad(obj).then(()=>{
      Times = [];
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}
  onReConfirmUpdate = (data,reference_number,key) => {
    let title = "Container: " + data[key];
    let body = (
      <div>
        <h4>
            This container is already in the system.
        </h4>
        <div>
          <p>
            Do you want to save the{" "}
            {"Container: " + data[key]}{" "}
            to Load {reference_number} anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        data.isContainerConfirm = true
        return editLoad(data).then((result)=>{
          const element = document.querySelector(`.${key}`);
          element?.classList.add("border-success");
          setTimeout(()=>{
            element?.classList.remove("border-success","border-red","text-danger");
          },3000)
        }).catch((err) => {
          const element = document.querySelector(`.${key}`);
          element?.classList.add("border-red","text-danger")
      });
      } else {
        return
      }
    });
};

  onSelectFieldChange = (key,value) => {
    const element = document.querySelector(`.${key}`);
    const divInElement = element?.querySelector("div");
    const elementToChange = divInElement ? divInElement : element;
    const valueToSend = typeof value === "string" ? value.trim() : value;

    if(valueToSend === "" && !!this.props.originalLoad[key]){
      const params = {
        reference_number: this.props.customerProfile.reference_number,
        key: key
      };
      if (!this.props.disabled) {
        removeValueLoad(params).then((result) => {
          elementToChange?.classList.remove("background-failure");
          elementToChange?.classList.add("background-success");
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
          },400)
          this.props.updateState(key, result.data.data[key]);
        });
      }
      return
    }
    if(valueToSend === "" && !this.props.originalLoad[key]) return
    let finalValue;
    if (typeof valueToSend === "boolean" || typeof valueToSend === "string") {
      if(key === "freight" || key === "custom"){
      let change = valueToSend ? "HOLD" : null
      let noChange = _.isEqual(this.props.originalLoad[key],change)
      if(noChange) return
    }
    if(this.props.originalLoad[key]){
      let noChange = key === "terminal" ? _.isEqual(this.props.originalLoad[key]._id,valueToSend) : _.isEqual(this.props.originalLoad[key],valueToSend)
      if(noChange) return // || valueToSend === "" for not allowed empty elements
  }
      finalValue = valueToSend
    }
    else {
      let noChange = this.props.originalLoad[key] ? _.isEqual(this.props.originalLoad[key]._id,valueToSend._id) : false
      if(noChange) return
      finalValue = valueToSend._id
    }

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      source: "loadinfo"
    }
    if(key === "hot") obj.isHot = value;
    let list = ["secondaryReferenceNo"]
    if(this.props.isRequireReference && !list.includes(key)){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue
      }
      return
    }
      editLoad(obj).then(()=>{
        elementToChange?.classList.remove("background-failure");
        elementToChange?.classList.add("background-success");
        if(this.willCall !== {}){
          this.repetiveAPICall(this.willCall)
        }
        setTimeout(()=>{
          elementToChange?.classList.remove("background-success","text-danger");
        },400)
      }).catch((err) => {
        elementToChange?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          elementToChange?.classList.add("background-failure","text-danger");
          elementToChange?.classList.remove("background-error","text-danger");
        },400)
    })
  }

  onMultipleSelectionChange = (key,value,i) =>{
    if(!value) return
    let finalValue;
    let controlField = key === "shipper" ? "pickupTimes": "deliveryTimes";
    let prevValue = this.props.originalLoad[key] && this.props.originalLoad[key].map((obj)=> obj._id)
    finalValue = value && value.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let noChange = _.isEqual(prevValue,finalValue)
    if(noChange) return
    const Times = this.props.customerProfile[controlField].filter((obj)=> !_.isEmpty(obj))
    const finalTimes = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      [controlField]: finalTimes,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue,
        [controlField]: finalTimes,
      }
      return
    }
    const element = document.querySelector(`.${key}-${i} > div`);
    editLoad(obj).then(()=>{
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
        setTimeout(()=>{
          element?.classList.remove("background-success","text-danger");
        },400)
  }).catch(() => {
    element?.classList.add("background-error","text-danger");
    setTimeout(()=>{
      element?.classList.add("background-failure","text-danger");
      element?.classList.remove("background-error","text-danger");
    },400)
})}

  onRemoveField = (time, updateTime, updateList) => {
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let updatedTime = updateTime.filter((obj)=> !_.isEmpty(obj))
    let filteredPickup = JSON.stringify(updatedTime)
    let list = updateList.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      [field]: list,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
      ...this.willCall,
      [controlName]: filteredPickup,
      [field]: list,
      }
      return
    }
    editLoad(obj).then((result)=>{
      toastr.show("Updated!", "success");
    }).catch((err) => {
      toastr.show('Failed to Update !', 'error');
  })
  }

  onCommodityChanges = (comdArr,index,name) => {
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: comdArr,
      source: "loadinfo"
    }
    if(this.props.isRequireReference && name){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: comdArr,
      }
      return
    }
    let elementArr;
    if(name === "weight" || name === "weightKgs"){
      let elements = [`.weight-${index}`,`.weightKgs-${index}`];
      elementArr = elements.map((el)=>document.querySelector(el))
    } else {
      const element = document.querySelector(`.${name}-${index}`)
      elementArr = name === "commodity" && JSON.parse(getStorage("measureUnits"))?.isCommodityProfileShow ? [element] : [element]
    }
    editLoad(obj).then(()=>{
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
        !name && toastr.show("Successfully removed", "success");
    }).catch((err) => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
  })}

  tblvalchanges = (index, e,blur) => {
    const { name, value } = e.target;
    const old = [...this.state.items];
    if(value === "" &&  !this.props.originalLoad?.items[index] && this.props.originalLoad?.items[index][name]) return
    old[index][name] = value;
    if (name == "weight") {
      old[index]["weightKgs"] = parseFloat((parseFloat(value) * 0.453592).toFixed(2));
    }
    if (name == "weightKgs") {
      old[index]["weight"] = parseFloat(((parseFloat(value) * 2.20462).toFixed(2)));
    }
    if(name == "pieces"){
      old[index]["pieces"]= parseFloat(value)
    }
    if(name == "weight"){
      old[index]["weight"]= parseFloat(value)
    }
    if(name == "pallets"){
      old[index]["pallets"]= parseFloat(value)
    }
    if(name == "weightKgs"){
      old[index]["weightKgs"]= parseFloat(value)
    }
    this.setState({
      items: [...old],
    });
    let oldItems = [
      ...this.props.originalLoad?.items,
    ]
    oldItems?.forEach((item)=> {
      item && item._id && delete item._id
    })
    if(!_.isEqual(old, oldItems) && blur) this.onCommodityChanges(old,index,name)
  };

  DeleteItemRow = (index) => {
    let data = [...this.state.items];
    data.splice(index, 1);
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: data,
      }
      this.setState({
        items: data,
      })
      return
    }
    this.setState({
      items: data,
    },()=>{
      this.onCommodityChanges(data)
    });
  };

  AddItemRows = (item) => {
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.push(item);
    this.setState({
      items,
      addCommodity: false
    });
    this.updateCommodity(items)
  };

  updateCommodity = (items) => {
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: items,
      source: "loadinfo",
    }
    editLoad(obj).then(()=>{
      let element = document.querySelector(`.fd-row-${items.length - 1 }`)
      element?.classList.add("background-success")
      setTimeout(()=>{
        element?.classList.remove("background-success")
      },400)
    })
  }

  updatePricing(isSaveCustomerRates) {
    let valid = true;
    var payload = {};
    let data = this.state.items.filter((d) => d.commodity);
    payload.items = data;
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      UpdatePricing("tms/updateItemsLoad", payload)
        .then((response) => {
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("Your pricing has been updated!", "success");
        })
        .catch((err) => {});
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isSaveCalled !== nextProps.isSaveCalled) {
      this.updatePricing();
    }
    let self = this;
    if (nextProps.customerProfile) {
      if (
        nextProps.customerProfile.items &&
        nextProps.customerProfile.items.length > 0
      ) {
        let items = nextProps.customerProfile.items.map((D) => {
          if (D._id) {
            delete D._id;
          }
          return D;
        });
        self.setState({ items });
      }
    }
  }

  componentDidMount() {
    this.getCommodityAllProfile();
  }

  getCommodityAllProfile() {
    getCommodityProfile()
      .then((data) => {
        this.setState({
          commodityProfiles: data.data.data.map((c) => ({
            label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
            value: c.name,
            name: c.name,
            isDisabled: c.isRestricted,
            allInfo: c,
          })),
        });
      })
      .catch((err) => {
        console.log("err----->>", err);
      });
  }
  onBlurChange = (e)=> this.onSelectFieldChange(e.target.name, e.target.value );

  getSeachTrailers = (params) => {
    const obj = { limit: 5 }
    if (params) {
      obj.searchTerm = params
    }
    return getTrailers(obj).then((data) => {
     return data;
    });
  };
  
  render() {
    let self = this;
    let ddlDisabled = false;
    let pickupFrom = "";
    let pickupTo = "";
    let deliveryFrom = "";
    let deliveryTo = "";
    let loadTime = "";
    let containerAvailableDay = "";
    let isDisabled = this.props.disabled;
    let disabled = this.props.disabled;
    if (this.props.newRecord) disabled = true;
    if (
      this.props &&
      this.props.customerProfile &&
      Object.keys(this.props.customerProfile).length > 0
    ) {
      if (this.props.customerProfile.pickupFromTime) {
        pickupFrom = moment(this.props.customerProfile.pickupFromTime).format(
          "MM/DD/YYYY HH:mm"
        );
        pickupTo = moment(this.props.customerProfile.pickupToTime).format(
          "MM/DD/YYYY HH:mm"
        );
      }
      if (this.props.customerProfile.deliveryFromTime) {
        deliveryFrom = moment(
          this.props.customerProfile.deliveryFromTime
        ).format("MM/DD/YYYY HH:mm");
        deliveryTo = moment(this.props.customerProfile.deliveryToTime).format(
          "MM/DD/YYYY HH:mm"
        );
      }
      if (this.props.customerProfile.loadTime) {
        loadTime = moment(this.props.customerProfile.loadTime).format(
          "MM/DD/YYYY h:mm a"
        );
      }
      if (this.props.customerProfile.containerAvailableDay) {
        containerAvailableDay = moment(
          this.props.customerProfile.containerAvailableDay
        ).format("MM/DD/YYYY");
      }
      if (
        this.props.customerProfile.chassisNo &&
        typeof this.props.customerProfile.chassisNo != "string"
      )
        ddlDisabled = true;
    }
    const shippers =
      this.props.customerProfile && this.props.customerProfile.shipper
        ? this.props.customerProfile.shipper
        : [{}];
    const consignees =
      this.props.customerProfile && this.props.customerProfile.consignee
        ? this.props.customerProfile.consignee
        : [{}];
    let pickupTimes = this.props.customerProfile.pickupTimes
      ? this.props.customerProfile.pickupTimes
      : [{}];
    let deliveryTimes = this.props.customerProfile.deliveryTimes
      ? this.props.customerProfile.deliveryTimes
      : [{}];

    return (
      <div className="tab mt-10" id="tab-load-info">
        <div className="form-row">
          <div className="col-md-6">
            {/* Customer Info */}
            <div className="card pt-30 pb-30 px-20 mb-10">
              <div>
                <h4 className="font-16 mb-20">
                  
                  Customer Info
                </h4>

                <div className="form-row">
                  <div className="col-lg form-group mb-0">
                    <label className={`label-control`}>Customer *</label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      className="caller"
                      defaultOptions={makeOptionsForSelect(
                        this.props.callers.concat(this.props.allCustomers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      // isClearable
                      value={
                        (this.props.customerProfile &&
                          this.props.customerProfile.caller &&
                          this.props.customerProfile.caller.company_name) ||
                        this.state.caller
                          ? {
                              value: this.props.customerProfile.caller._id,
                              label:
                                this.props.customerProfile.caller.company_name,
                            }
                          : this.state.caller
                      }
                      onChange={(customer) => {
                        if (!customer) {
                          this.onRemovePress(null, { name: "caller" });
                          return;
                        }
                        this.onSelectFieldChange("caller", customer.allInfos)
                        this.props.updateState("caller", customer.allInfos);
                      }}
                      loadOptions={getTMScaller}
                      isDisabled={disabled}
                    />
                  </div>

                  { showForTerminal()  && (
                    <div className="form-group col-lg" style={{maxWidth: 170}}>
                      <label className="label-control">Terminal *</label>
                      {
                        this.state.Terminals && 
                        <Select
                          styles={smallSelectStyle}
                          className="terminal"
                          options={this.state.Terminals.map(it=>({value:it._id, label:it.name}))}
                          value={this.state.terminal}
                          onChange={(opt) => {
                            this.setState({
                              terminal: opt,
                            });
                            this.onSelectFieldChange("terminal", opt.value)
                            this.props.updateState("terminal", opt);
                          }}
                          isDisabled={disabled}
                        />
                      }
                    </div>
                  )} 
                </div>
                
              </div>

              <div className="hr-light my-25"></div>
              {shippers.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Shipper</h4>

                    <div className="form-row">
                      <div className="col-md-6">
                        {shippers.map((shipper, i) => {
                          return (
                            <div className="form-group  " key={i}>
                              <label>
                                <span className="text-danger">*</span> Shipper
                              </label>
                              <div className={`input-wrapper`}>
                                <AsyncSelect
                                  styles={smallSelectStyle}
                                  className={`shipper-${i}`}
                                  defaultOptions={makeOptionsForSelect(
                                    this.props.shippersOptions,
                                    "company_name",
                                    "address.address",
                                    "_id"
                                  )}
                                  cacheOptions
                                  value={
                                    shipper && shipper.company_name
                                      ? {
                                          label: shipper.company_name,
                                          value: shipper._id,
                                          allInfos: shipper,
                                        }
                                      : this.state["shipper" + i]
                                      ? {
                                          label:
                                            this.state["shipper" + i]
                                              .company_name,
                                          value: this.state["shipper" + i]._id,
                                          allInfos: this.state["shipper" + i],
                                        }
                                      : []
                                  }
                                  onChange={(shipper) => {
                                    let newShipper = self.props.customerProfile
                                      .shipper || [{}];
                                    newShipper[i] = shipper.allInfos;
                                    let newPickupTimes = self.props
                                      .customerProfile.pickupTimes || [];
                                      newPickupTimes[i].customerId = shipper.value;
                                    this.setState({
                                      ["shipper" + i]: shipper.label,
                                    });
                                    this.props.updateState(
                                      "shipper",
                                      newShipper
                                      );
                                      this.props.updateState(
                                        "pickupTimes",
                                        newPickupTimes
                                        );
                                      this.onMultipleSelectionChange("shipper", newShipper,i)
                                  }}
                                  loadOptions={getTMSshipper}
                                  isDisabled={disabled}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {pickupTimes &&
                          pickupTimes.map((pickupTime, i) => (
                            <React.Fragment>
                              <div className="form-row">
                                <div className=" col-lg">
                                  <label className="label-control">
                                    Pick Up From
                                  </label>
                                  <div className={`input-wrapper pickupFrom-${i}`}>
                                    <DateWrapper
                                      OnBlurUpdate={() => this.onPickDeliveryChanges("pickup","pickupFrom",i)}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery("pickupFromTime",e,i);
                                        this.props.datechangesPickupDelivery("pickupToTime",e,i); 
                                      }}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      timeFormat={this.userTimeFormat}
                                      disabled={disabled}
                                      defaultDate={pickupTime && pickupTime.pickupFromTime} 
                                      hideShowSelectDate={true}
                                    />
                                    {pickupTime &&
                                    pickupTime.pickupFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "pickup",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Pick Up To
                                    </label>
                                    <div className={`input-wrapper pickupTo-${i}`}>
                                    <DateWrapper
                                      OnBlurUpdate={()=> this.onPickDeliveryChanges("pickup","pickupTo",i)}
                                      onChange={(e) => 
                                      this.props.datechangesPickupDelivery("pickupToTime",e,i)
                                      }
                                      timeFormat={this.userTimeFormat}
                                      disabled={disabled}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      defaultDate={pickupTime && pickupTime.pickupToTime} 
                                      hideShowSelectDate={true}
                                    />
                                      {pickupTime &&
                                      pickupTime.pickupToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "pickup",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              
                                {this.props.customerProfile &&
                                  this.props.customerProfile.shipper &&
                                  this.props.customerProfile.shipper.length >
                                    1 && (
                                    <div className="col-lg form-group d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        onClick={(e) => {
                                          let newShipper =
                                            this.props.customerProfile.shipper ||
                                            [];
                                          if (newShipper.length > 1) {
                                            newShipper.splice(i, 1);
                                            this.props.updateState(
                                              "shipper",
                                              newShipper
                                            );
                                            this.setState({
                                              ["shipper" + i]: "",
                                            });
                                            pickupTimes.splice(i, 1);
                                            this.onRemoveField("pickup",pickupTimes,newShipper)
                                            this.props.updateState(
                                              "pickupTimes",
                                              pickupTimes
                                            );
                                          }
                                        }}
                                        style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                                
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <span style={disabled ? {cursor: "not-allowed"} : {}}>
                    <div
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newShipper =
                          self.props.customerProfile &&
                          self.props.customerProfile.shipper
                            ? self.props.customerProfile.shipper
                            : [];
                        newShipper.push({});
                        pickupTimes.push({});
                        self.props.updateState("pickupTimes", pickupTimes);
                        self.props.updateState("shipper", newShipper);
                        self.setState({
                          ["shipper" + newShipper.length]: "",
                        });
                      }}
                      style={disabled ? {pointerEvents: "none", opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      Add Port
                    </div>
                    </span>
                  </div>
                  <div className="hr-light"></div>
                </React.Fragment>
              )}
              {consignees.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Consignee</h4>
                    <div className="form-row">
                      <div className="col-md-6">
                        {consignees.map((consignee, i) => {
                          return (
                            <div className="form-group" key={i}>
                              <label>Consignee</label>
                              <div className={`input-wrapper`}>
                              <AsyncSelect
                                styles={smallSelectStyle}
                                className={`consignee-${i}`}
                                defaultOptions={makeOptionsForSelect(
                                  this.props.consigneesOptions,
                                  "company_name",
                                  "address.address",
                                  "_id"
                                )}
                                cacheOptions
                                value={
                                  consignee && consignee.company_name
                                    ? {
                                        label: consignee.company_name,
                                        value: consignee._id,
                                        allInfos: consignee,
                                      }
                                    : this.state["consignee" + i]
                                    ? {
                                        label:
                                          this.state["consignee" + i]
                                            .company_name,
                                        value:
                                          this.state["consignee" + i]._id,
                                        allInfos: this.state["consignee" + i],
                                      }
                                    : []
                                }
                                onChange={(consignee) => {
                                  let newConsignee = self.props
                                    .customerProfile.consignee || [{}];
                                  newConsignee[i] = consignee.allInfos;
                                  let newDeliveryTimes = self.props
                                    .customerProfile.deliveryTimes || [];
                                    newDeliveryTimes[i].customerId = consignee.value;
                                  this.setState({
                                    ["consignee" + i]: consignee.label,
                                  });
                                  this.props.updateState(
                                    "consignee",
                                    newConsignee
                                  );
                                  this.props.updateState(
                                    "deliveryTimes",
                                    newDeliveryTimes
                                    );
                                  this.onMultipleSelectionChange("consignee", newConsignee,i)
                                }}
                                loadOptions={getTMSconsignee}
                                isDisabled={disabled}
                              />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {deliveryTimes &&
                          deliveryTimes.map((deliveryTime, i) => (
                            <React.Fragment>
                              <div className="form-row ">
                                <div className=" col-md">
                                  <label className="label-control">
                                    Delivery From
                                  </label>
                                  <div className={`input-wrapper deliveryFrom-${i}`}>
                                    <DateWrapper 
                                      disabled={disabled}
                                      OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryFrom",i)}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery("deliveryFromTime",e,i);
                                        this.props.datechangesPickupDelivery("deliveryToTime",e,i); 
                                      }}
                                      timeFormat={this.userTimeFormat}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      defaultDate={deliveryTime && deliveryTime.deliveryFromTime} 
                                      hideShowSelectDate={true}
                                    />
                                    {deliveryTime &&
                                    deliveryTime.deliveryFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "delivery",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />{" "}
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Delivery To
                                    </label>
                                    <div className={`input-wrapper deliveryTo-${i}`}>
                                    <DateWrapper 
                                      disabled={disabled}
                                      OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryTo",i)}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery("deliveryToTime",e,i);
                                      }}
                                      timeFormat={this.userTimeFormat}
                                      displayTimeZone={this.timeZone}
                                      isShowTime={true}
                                      defaultDate={deliveryTime && deliveryTime.deliveryToTime} 
                                      hideShowSelectDate={true}
                                    />
                                      {deliveryTime &&
                                      deliveryTime.deliveryToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "delivery",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />{" "}
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />{" "}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {this.props.customerProfile &&
                                this.props.customerProfile.consignee &&
                                this.props.customerProfile.consignee.length >
                                  1 && (
                                  <div className="form-group col-md d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                    <div
                                      className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                      onClick={(e) => {
                                        let newConsignee =
                                          self.props.customerProfile
                                            .consignee || [];
                                        if (newConsignee.length > 1) {
                                          newConsignee.splice(i, 1);
                                          self.props.updateState(
                                            "consignee",
                                            newConsignee
                                          );
                                          self.setState({
                                            ["consignee" + i]: "",
                                          });
                                          deliveryTimes.splice(i, 1);
                                          this.onRemoveField("delivery",deliveryTimes,newConsignee)
                                          self.props.updateState(
                                            "deliveryTimes",
                                            deliveryTimes
                                          );
                                        }
                                      }}
                                      style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                                    >
                                      <IconMinus />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  <span style={disabled ? {cursor: "not-allowed"} : {}}>
                    <div
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newConsignee =
                          self.props.customerProfile &&
                          self.props.customerProfile.consignee
                            ? self.props.customerProfile.consignee
                            : [];
                        newConsignee.push({});
                        deliveryTimes.push({});
                        self.props.updateState(
                          "deliveryTimes",
                          deliveryTimes
                        );
                        self.props.updateState("consignee", newConsignee);
                        self.setState({
                          ["consignee" + newConsignee.length]: "",
                        });
                      }}
                      style={disabled ? {pointerEvents: "none", opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      Add Consignee
                    </div>
                    </span>
                  </div>
                </React.Fragment>
              )}
              {/* Container Return */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">
                
                Reference
              </h4>
              <div className="form-row mb-10">
                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control callerbillLandingNo transition-white-field"
                    label="Master Bill of Lading"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerbillLandingNo"
                    onBlur={this.onBlurChange}
                  />
                </div>

                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control doNo transition-white-field"
                    label="House Bill of Lading"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="doNo"
                    onBlur={this.onBlurChange}
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control containerNo transition-white-field"
                    label="Reference Container #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="containerNo"
                    onBlur={this.onBlurChange}
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control callerPONo transition-white-field"
                    label="Pick Up #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerPONo"
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-4 ">
                  <Field
                    type="text"
                    className="form-control secondaryReferenceNo transition-white-field"
                    component={InputField}
                    name="secondaryReferenceNo"
                    label="Reference #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={isDisabled}
                    onBlur={this.onBlurChange}
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control shipmentNo transition-white-field"
                    label="Shipment #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="shipmentNo"
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-4 ">
                  <Field
                    type="text"
                    className="form-control sealNo transition-white-field"
                    component={InputField}
                    name="sealNo"
                    label="Seal #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    onBlur={this.onBlurChange}
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control releaseNo transition-white-field"
                    label="Order #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="releaseNo"
                    onBlur={this.onBlurChange}
                  />
                </div>
              </div>
            </div>

            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">
                
                Equipment
              </h4>
              
              <div className="form-row ">
                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control temperature transition-white-field"
                    label="Temperature"
                    name="temperature"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    type="text"
                    className="form-control hazmat transition-white-field"
                    component={SelectField}
                    backgroundColor="hazmat"
                    name="hazmat"
                    label="Hazmat"
                    data={loadFlags}
                    disabled={disabled}
                    onChange={(e)=> this.onSelectFieldChange("hazmat", e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    type="text"
                    className="form-control hot transition-white-field"
                    backgroundColor="hot"
                    component={SelectField}
                    name="hot"
                    label="Hot"
                    disabled={disabled}
                    data={loadFlags}
                    onChange={(e)=> this.onSelectFieldChange("hot", e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    type="text"
                    className="form-control overWeight transition-white-field"
                    component={SelectField}
                    backgroundColor="overWeight"
                    name="overWeight"
                    label="OVERWEIGHT"
                    data={loadFlags}
                    disabled={disabled}
                    onChange={(e)=> this.onSelectFieldChange("overWeight", e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control scac transition-white-field"
                    label="SCAC"
                    name="scac"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    onBlur={this.onBlurChange}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label className="label-control">Trailer</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    className= "trailer"
                    cacheOptions
                    // defaultOptions
                    value={{
                      label: this.props.customerProfile.trailer,
                      value: this.props.customerProfile.trailer,
                    }}
                    onChange={(trailer) => {
                      !trailer
                        ? this.onRemovePress(null, { name: "trailer" })
                        : this.props.updateState("trailer", trailer.value);this.onSelectFieldChange("trailer", trailer.value)
                    }}
                    loadOptions={this.getSeachTrailers}
                    isClearable={
                      this.props.customerProfile.trailer ? true : false
                    }
                    isDisabled={disabled}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <label className="label-control">Type</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    className="trailerType"
                    cacheOptions
                    // defaultOptions
                    value={{
                      label: this.props.customerProfile.trailerType,
                      value: this.props.customerProfile.trailerType,
                    }}
                    onChange={(trailerType) => {
                      this.onSelectFieldChange("trailerType", trailerType.value)
                      this.props.updateState(
                        "trailerType",
                        trailerType.value
                      );
                    }}
                    loadOptions={getTrailerTypes}
                    isDisabled={disabled}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label className="label-control">Size</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    className="trailerSize"
                    cacheOptions
                    // defaultOptions
                    value={{
                      label: this.props.customerProfile.trailerSize,
                      value: this.props.customerProfile.trailerSize,
                    }}
                    onChange={(trailerSize) => {
                      this.onSelectFieldChange("trailerSize", trailerSize.value)
                      this.props.updateState(
                        "trailerSize",
                        trailerSize.value
                      );
                    }}
                    loadOptions={getTrailerSizes}
                    isDisabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card py-20 px-30 mb-0">
          <h4 className="font-16 mb-0">Freight Description</h4>
        </div>

        <div className="table">
          <table className="table table-card table-card--ls mb-10 fd-row">
            <thead>
              <tr>
                <th scope="col" className="z-0" width="17%" >Commodity</th>
                <th scope="col" className="z-0">Description</th>
                <th width="10%" scope="col" className="z-0">
                  Pieces
                </th>
                <th width="10%" scope="col" className="z-0">
                  Weight LBS
                </th>
                <th width="10%" scope="col" className="z-0">
                  Weight KGS
                </th>
                <th width="10%" scope="col" className="z-0">
                  Pallets
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items.map((data, key) => {
                  return (
                    <tr key={key} className={`select-custom-input fd-row-${key} ${this.state.addCommodity || disabled ? "disabled-pointer-events": ""}`}  style={this.state.addCommodity ? {opacity: "0.2", transition: "background 0.4s ease-out"}: {transition: "background 0.4s ease-out"}}>
                      <td className={`commodity-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        {JSON.parse(getStorage("measureUnits"))
                          .isCommodityProfileShow == true ? (
                          <div className="form-group m-0">
                            <Select
                              styles={newSmallSelectStyle}
                              options={this.state.commodityProfiles}
                              value={this.state.commodityProfiles.find(
                                (pt) => pt.value == data.commodity
                              )}
                              onChange={(value) => {
                                const temp = [...this.state.items];
                                temp[key]["commodity"] = value.value;
                                this.setState({
                                  items: [...temp],
                                });
                                this.props.updateState("items", temp);
                                let oldItems = [
                                  ...this.props.originalLoad?.items,
                                ]
                                oldItems[0] && delete oldItems[0]?._id
                                oldItems?.forEach((item)=> {
                                  item && item._id && delete item._id
                                })
                                const noChange = _.isEqual(temp, this.props.originalLoad?.items)
                                if(noChange) return
                                this.onCommodityChanges(temp,key,"commodity")
                              }}
                            />
                          </div>
                        ) : (
                          <input
                            className={`form-control border-0 `}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name="commodity"
                            autoComplete="off"
                            value={data.commodity}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlur={(e) => this.tblvalchanges(key, e, true)}
                          />
                        )}
                      </td>
                      <td className={`description-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`form-control`}
                          type="text"
                          name="description"
                          value={data.description}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`pieces-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control`}
                          type="number"
                          name="pieces"
                          value={data.pieces}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`weight-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control `}
                          type="number"
                          name="weight"
                          value={data.weight}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`weightKgs-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`tblrequire form-control `}
                          type="number"
                          name="weightKgs"
                          value={data.weightKgs}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>
                      <td className={`pallets-${key} px-0`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          type="number"
                          className={`tblrequire form-control `}
                          name="pallets"
                          value={data.pallets}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autocomplete="off"
                        />
                      </td>

                      <td style={disabled ? {cursor: "not-allowed", transition: "background 0.4s ease-out"} : {transition: "background 0.4s ease-out"}}>
                        <div
                          type="button"
                          style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                          onClick={() => this.DeleteItemRow(key)}
                          className="btn btn-circle btn-xs bg-soft-danger"
                        >
                          <IconMinus />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            {this.state.addCommodity && <CommodityComponent commodityProfiles={this.state.commodityProfiles} handleAddCommodity={this.AddItemRows} items={this.state.items} discardRow={()=>this.setState({ addCommodity: false })}/>}
            </tbody>
          </table>
          <span style={disabled ? {cursor: "not-allowed"} : {}}>
          <div
            type="button"
            className="btn btn-white btn-sm text-primary mt-10"
            onClick={() => !disabled && this.setState({ addCommodity: true })}
            style={disabled || this.state.addCommodity ? {pointerEvents: "none", opacity: 0.4} : {}}
          >
            <IconPlus className="mr-2" />
            Add Row
          </div>
          </span>
        </div>
      </div>
    );
  }
}

export default NewRoadLoadComponent;

const StatusButton = ({
  updateState,
  buttonStatus,
  preSetOrderStatus,
  loadStatus,
}) => {
  return (
    <div className="col-md-4">
      <div className="form-check form-group">
        <input
          type="radio"
          disabled
          checked={preSetOrderStatus !== buttonStatus.value ? true : false}
          className="form-check-input"
          id="prepull-drop-hook"
          name="pre-order-status"
        />
        <label className="form-check-label" for="prepull-drop-hook">
          {buttonStatus.label ? buttonStatus.label : "DEFAULT"}
        </label>
      </div>
    </div>
  );
};