import React, { Component } from 'react';


import './style.css';

class Invoice extends Component  {
    componentWillReceiveProps(props) {
    }
    componentDidMount() {
    }
    render() {
        return(
            <div style={{backgroundColor: 'rgb(255, 255, 255)',padding: '0px !important',margin: 'auto'}}>
<div>
                <p>Linked Image:</p>
<a href="http://www.natural-environment.com/blog/2008/04/22/fiordland-national-park-new-zealand/"><img src="/pix/milford_sound/milford_sound_t.jpg"  alt="Milford Sound in New Zealand"/></a>
            </div>
            </div>
        )
    }
}

export default Invoice;