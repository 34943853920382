import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import Papa from "papaparse";
import pdfjsLib from "pdfjs-dist";
import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import { Document, Page } from "react-pdf";
import "react-pdf-reader/dist/TextLayerBuilder.css";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import { differnceFinder } from "../../../../src/services/index";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import confirm from "../../../Components/Common/ConfirmAert";
import { CustomIconTruck } from "../../../Components/Common/CustomIcons/Index";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { fileNameTypeFromUrl, getYear } from "../../../services/Common.services";
import { getDocumentTypesByType } from '../../../Components/Common/functions';
import {
  StateFormDateTime,
  StateFormInput,
  StateFormSelect,
} from "../../../Components/FormComponent/StateFormComponent";
import SubHeaderTab from "../../../Components/SubHeaderTab";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCart,
  IconCheck,
  IconCheckCircle,
  IconClock9,
  IconDocument,
  IconDownload,
  IconNotes,
  IconPen,
  IconSearchData,
  IconTimes,
  IconTrash,
  IconUpload,
  IconPlus,
  IconSortDown,
  IconSortUp,
  IconSort
} from "../../../Components/Common/Icons";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import configuration from "../../../config";
import {
  addCheck,
  getStorage,
  makeOptionsForSelect,
  sendDocumentsInEmail,
  showForTerminal,
  toastr,
} from "../../../services/Common.services";
import { size, TMS_GET_USER_LOAD } from "../constant";
import { getCustomers } from "../Dispatcher/actionCreators";
import UploadedDocuments from "../Load/DocumentList";
import {
  deleteDocument,
  deleteEquipmentDoc,
  getEquipmentHistory,
  getTerminal,
  rotatePdf,
  updateDocument,
} from "../services";
import { getEquipmentAudits } from "../Truck/actionCreators";
import * as actionCreators from "./actionCreators";
import FullScreenUploader from "../../../Components/FullScreenUploader";
import ReactTooltip from "react-tooltip";

let timeZone = getStorage("timeZone");
let Data = {
  _id: undefined,
  vin: "",
  AID: "",
  ITD: "",
  size: "",
  type:"",
  year: "",
  make: "",
  model: "",
  notes: "",
  hut_exp: "",
  terminal: "",
  equipmentID: "",
  trailerType: "",
  reg_expiration: "",
  inspection_exp: "",
  equipmentHistory: [],
  licence_plate_state: "",
  licence_plate_number: "",
  collapseSubCards: true,
};
const sortingOptions = {
  VIN: "vin",
  AID: "AID",
  ITD: "ITD",
  HUT: "hut_exp",
  "Trailer #": "equipmentID",
  TERMINAL: "terminal.name",
  Inspection: "inspection_exp",
  Registration: "reg_expiration",
  Load: "load.reference_number",
  License: "licence_plate_number",
  Address: "lastCompletedStatus.address.address",
};
const tableHeaderData = [
  "Trailer #",
  "License",
  "VIN",
  "Address",
  "AID",
  "ITD",
  "Type",
  "Size",
  "Registration",
  "Inspection",
  "HUT",
];

const yearOptions = getYear().map((year) => {
  return { value: year, label: year }
});
class Trailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...Data,
      terminals: [],
      AddTrailerModal: false,
      fileType: null,
      tab: 1,
      documents: [],
      documentFile: null,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      type: "",
      selectedStatuses: [],
      sort: undefined,
      asce: true,
      fitlerDeletedEnabled: "All",
      filterTrailerTypes: "Enabled",
      isEnableTrailer:true,
      isDisableTrailer:false,
      isLoadingBarShow: false,
      subCardFilter: null,
      choosenTerminals: [],
      formErrors: {},
      formsTouched: {},
      isTerminal: false,
      selectedDoc: null,
      selectedPdf: null,
      pdfPageNumber: 1,
      currPage: 1,
      addModal: false,
      uploadFileDetails: "",
      progress: 0,
      documentObj: {},
      loading: false,
      documentImage: true,
      showFullDropScreen: false,
      documentTypes: [],
    };
    this.allStatuses = ["Available", "Dispatched", "Loaded", "Empty"];
  }

  deleteEquipmentDoc = (params) => {
    deleteEquipmentDoc(params).then((response) => {
      const documents = response.data.data.documents;
      this.setState({ documents });
      toastr.show("Deleted!", "success");
    });
  };

  componentDidMount() {
    if (!this.props.isOnlyPopup) {
      this.getlist();
    }
    let isTerminal = showForTerminal();
    this.setState({ isTerminal: isTerminal });
    if (isTerminal) {
      getTerminal().then((response) => {
        const terminals = response.data.data ? response.data.data : [];
        this.setState({ terminals });
      });
    }
    if (this.props.isOnlyPopup) {
      this.setState({ AddTrailerModal: true });
    }
    this.setState({showFullDropScreen:true})
  }
  componentWillUnmount() {
    this.setState({showFullDropScreen:false})
  }
  UNSAFE_componentWillMount() {
    let defaultState = {};
    this.allStatuses.forEach((key) => {
      defaultState[`${key}`] = [];
    });
    this.setState({ ...defaultState });
    // fetch and fill document types option from api
    (async () => {
      let types = await getDocumentTypesByType('trailerTypes');
      this.setState({ documentTypes: types });
      this.setState({ isLoadingBarShow: true })
    })();
  }

  emptyOrLoaded = (info) => {
    let status;
    let customer;
    if (info.driverOrderId) {
      const activeStatusIndex = info.activeIndex || -1;
      const activeDriverOrderId = info.driverOrder[activeStatusIndex];
      const prevDriverOrderId = info.driverOrder[activeStatusIndex - 1];
      if (
        activeDriverOrderId &&
        prevDriverOrderId &&
        [
          "DROPCONTAINER_DEPARTED",
          "HOOKCONTAINER_ARRIVED",
          "HOOKCONTAINER_DEPARTED",
        ].indexOf(info.status) > -1
      ) {
        if (["EXPORT", "IMPORT"].indexOf(info.type_of_load) > -1) {
          if (activeDriverOrderId.prevType === "PULLCONTAINER") {
            status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
          } else if (
            activeDriverOrderId.prevType === "DELIVERLOAD" &&
            activeDriverOrderId.customerId &&
            prevDriverOrderId.customerId &&
            prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
          ) {
            status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
          } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
            if (info.isReadyForPickup) {
              status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
            } else {
              status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
            }
          }
        } else if (
          [
            "DROPCONTAINER_DEPARTED",
            "HOOKCONTAINER_ARRIVED",
            "HOOKCONTAINER_DEPARTED",
          ].indexOf(info.status) > -1 &&
          ["ROAD"].indexOf(info.type_of_load) > -1
        ) {
          if (activeDriverOrderId.prevType === "PULLCONTAINER") {
            status = "loaded";
          } else if (
            activeDriverOrderId.prevType === "DELIVERLOAD" &&
            activeDriverOrderId.customerId &&
            prevDriverOrderId.customerId &&
            prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
          ) {
            status = "empty";
          } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
            status = "loaded";
          }
        }
      }
      if (
        activeDriverOrderId &&
        activeDriverOrderId.customerId &&
        activeDriverOrderId.customerId.company_name
      ) {
        customer = activeDriverOrderId.customerId.company_name;
      }
    }
    return status;
  };

  getlist() {
    const prm = { equipment_type: "TRAILER", isIncludeDeleted: true };
    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      prm.terminals = JSON.stringify(
        this.state.choosenTerminals.map((J) => {
          return J.value;
        })
      );
    }
    this.props.actions.getTMSEquipments(prm).then((result) => {
      let data = result;
      let { Available, Dispatched, Loaded, Empty } = this.state;

      Available = data.filter(
        (obj) =>
          !obj.load ||
          (obj.load &&
            [
              "PENDING",
              "AVAILABLE",
              "DEPARTED",
              "COMPLETED",
              "APPROVED",
              "BILLING",
            ].indexOf(obj.load.status) > -1)
      );
      Dispatched = data.filter(
        (obj) =>
          obj.load &&
          [
            "DISPATCHED",
            "CHASSISPICK_ARRIVED",
            "CHASSISPICK_DEPARTED",
            "PULLCONTAINER_ARRIVED",
            "PULLCONTAINER_DEPARTED",
            "DROPCONTAINER_ARRIVED",
            "DELIVERLOAD_ARRIVED",
            "DELIVERLOAD_DEPARTED",
            "RETURNCONTAINER_ARRIVED",
            "RETURNCONTAINER_DEPARTED",
            "CHASSISTERMINATION_ARRIVED",
            "CHASSISTERMINATION_DEPARTED",
          ].indexOf(obj.load.status) > -1
      );
      Loaded = data.filter(
        (obj) =>
          obj.load &&
          this.emptyOrLoaded(obj.load) === "Loaded" &&
          ["DROPPED", "NEED_TO_GET_DROP"].indexOf(obj.load.status) > -1
      );
      Empty = data.filter(
        (obj) =>
          obj.load &&
          this.emptyOrLoaded(obj.load) === "Empty" &&
          ["DROPPED", "NEED_TO_GET_DROP"].indexOf(obj.load.status) > -1
      );
      this.setState({
        AllTMSEquipments: result,
        Available,
        Dispatched,
        Loaded,
        Empty,
        isLoadingBarShow: false,
      });
    });
  }

  changeColor = (id) => {
    getEquipmentHistory({ equipmentId: id }).then((response) => {
      this.setState({ equipmentHistory: response.data.data });
    });
    this.props.actions
      .getEquipmentAudits({ equipmentId: id })
      .then((response) => this.setState({ audits: response }))
      .catch((error) => console.log(error));
    this.state.AllTMSEquipments.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ [id]: true });
    let selectData = _.find(this.state.AllTMSEquipments, function (s) {
      return s._id == id;
    });

    let setdata = {
      _id: selectData._id,
      equipmentID: selectData.equipmentID,
      year: selectData.year ? selectData.year : "",
      make: selectData.make ? selectData.make : "",
      model: selectData.model,
      AID: selectData.AID ? new Date(selectData.AID) : "",
      ITD: selectData.ITD ? new Date(selectData.ITD) : "",
      size: selectData.size ? selectData.size : "",
      trailerType: selectData.trailerType ? selectData.trailerType : "",
      reg_expiration: selectData.reg_expiration
        ? new Date(selectData.reg_expiration)
        : "",
      licence_plate_state: selectData.licence_plate_state ? selectData.licence_plate_state : "",
      licence_plate_number: selectData.licence_plate_number ? selectData.licence_plate_number : "",
      inspection_exp: selectData.inspection_exp
        ? new Date(selectData.inspection_exp)
        : "",
      hut_exp: selectData.hut_exp ? new Date(selectData.hut_exp) : "",
      vin: selectData.vin,
      newTerminal:
        selectData.newTerminal && selectData.newTerminal.length > 0
          ? selectData.newTerminal
          : [],
      notes: selectData.notes ? selectData.notes : "",
    };

    if (setdata.newTerminal && setdata.newTerminal.length > 0) {
      let newTerminal = [];
      this.state.terminals.forEach((item, i) => {
        if (setdata.newTerminal.indexOf(item._id) > -1) {
          newTerminal.push({
            value: item._id,
            label: item.name,
          });
        }
      });
      setdata.newTerminal = newTerminal;
    }
    this.setState({
      ...setdata,
      AddTrailerModal: true,
      documents: selectData.documents ? selectData.documents : [],
      imagePreviewUrl: null,
      documentFile: null,
      pdfPreviewUrl: null,
      formErrors: {},
      formsTouched: {},
    });
  };

  Newform() {
    this.state.AllTMSEquipments.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ ...Data });
  }

  setval(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  previewImage = (data) => {
    if (!data.url) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.url;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.url },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.url, pdfPreviewUrl: null });
      }
    }
  };

  downloadDoc(doc) {
    $.ajax({
      url: doc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: (blob) => {
        const extension = fileNameTypeFromUrl(doc.url)[1];
        if (extension != "pdf") {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.click();
        } else {
          var blob = new Blob([blob], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.download = fileName;
          link.click();
        }
      },
    });
  }
  deleteTMSEquipement(TMSEquipments) {
    let formData = new FormData();
    formData.append("_id", TMSEquipments._id);
    formData.append("isDeleted", !TMSEquipments.isDeleted);
    confirm(
      `${TMSEquipments.isDeleted ? "Enable" : "Delete"} Trailer`,
      `Are you sure you want to ${TMSEquipments.isDeleted ? "enable this?" : "delete this?"
      }`,
      (confirm) => {
        if (confirm) {
          this.props.actions.deleteTMSEquipement(formData).then((res) => {
            toastr.show(
              `Successfully ${TMSEquipments.isDeleted ? "Enabled" : "Deleted"}`,
              "success"
            );
            this.getlist();
          });
        }
      }
    );
  }

  // Validate form
  insertdata = (isSubmit = true) => {
    var errors = {};
    var touched = { ...this.state.formsTouched };

    schemaValidatorObject
      .validate(this.state, { abortEarly: false })
      .then((value) => {
        this.setState({
          formErrors: {},
          formsTouched: {},         
        });
        isSubmit && this.insertdataa();
      })
      .catch((err) => {
        console.log("err", err);
        err &&
          err.inner &&
          err.inner.forEach((e, i) => {
            if (isSubmit && i + 1 === err.inner.length) {
              toastr.show(e.message, "error");
            }
            errors[e.path] = e.message;
            touched[e.path] = true;
          });
        this.setState(
          { formErrors: errors, formsTouched: touched},
          () => {
            isSubmit && toastr.show("Please enter all required fields.", "warning");
          }
        );
      });
  };
  // Set State For Val
  setValChange = (key, val) => { 
    var formsTouched = { ...this.state.formsTouched };
    formsTouched[key] = true;
    this.setState({ [key]: val, formsTouched }, () => {
      this.insertdata(false);
    });
  };
  insertdataa() {
    if (
      this.state.isTerminal &&
      (!this.state.newTerminal || this.state.newTerminal.length == 0)
    ) {
      toastr.show("Please choose a terminal.", "error");
      return;
    }
    let formData = new FormData();
    if (this.state.documentFile && !this.state.fileType) {
      toastr.show("You must choose a type.", "error");
      return;
    }

    this.setState({ loading: true });

    if (this.state._id) formData.append("_id", this.state._id);
    formData.append("equipment_type", "TRAILER");
    formData.append("equipmentID", this.state.equipmentID);
    formData.append("year", this.state.year);
    formData.append("make", this.state.make);
    formData.append("model", this.state.model);
    formData.append("vin", this.state.vin);
    formData.append("size", this.state.size);
    if (this.state.reg_expiration)
      formData.append(
        "reg_expiration",
        moment(this.state.reg_expiration).toISOString()
      );
    formData.append("licence_plate_state", this.state.licence_plate_state);
    formData.append("licence_plate_number", this.state.licence_plate_number);
    if (this.state.AID)
      formData.append("AID", moment(this.state.AID).toISOString());
    if (this.state.ITD)
      formData.append("ITD", moment(this.state.ITD).toISOString());
    if (this.state.inspection_exp)
      formData.append(
        "inspection_exp",
        moment(this.state.inspection_exp).toISOString()
      );
    if (this.state.hut_exp)
      formData.append("hut_exp", moment(this.state.hut_exp).toISOString());
    if (this.state.terminal) {
      formData.append("terminal", this.state.terminal);
    }
    formData.append("notes", this.state.notes);
    // if (this.state.notes) {
    //   formData.append("notes", this.state.notes);
    // }
    if (this.state.newTerminal && this.state.newTerminal.length > 0) {
      let newTerminal = this.state.newTerminal.map((Data) => {
        return Data.value;
      });
      formData.append("newTerminal", JSON.stringify(newTerminal));
    }
    formData.append("trailerType", this.state.trailerType);
    if (this.state.documentFile && this.state.fileType) {
      formData.append("document", this.state.documentFile);
      formData.append("fileType", this.state.fileType);
      formData.append("filename", `${new Date().getTime().toString()}`);
    }
    this.props.actions.addTMSEquipment(formData).then((result) => {
      this.setState({
        documents: result.data.data.documents,
      });

      this.setState({ addModal: false, loading: false, showUploadModal: false });

      if (!this.state._id) {
        this.setState({ AddTrailerModal: false, loading: false, fileType: null });
        toastr.show("Trailer Added", "success");
      } else if (this.state._id && this.state.tab === 2) {
        
        toastr.show("This document has been uploaded.", "success");
      } else {
        // this.setState({ AddTrailerModal: false });
        toastr.show("Trailer Updated", "success");
      }
      this.setState({
        loading: false,
        documentImage: false,
        imagePreviewUrl: null,
        fileType: null,
        documentFile: null,
        pdfPreviewUrl: null,
      });
      

      if (!this.props.isOnlyPopup) {
        this.getlist();
      }
      if (this.props.isOnlyPopup) {
        this.props.falsyData();
      }
    });
  }

  updateDocument = (e) => {
    this.setState({ fileType: e.target.value });
  };

  // uploadResult(file, preview, type) {
  //   if (file.type.match("image.*") || file.type.match("application.pdf")) {
  //     if (file.type.indexOf("image") == -1) {
  //       type = file.type.split("/")[1];
  //     }
  //     let pdfPreviewUrl = null;
  //     let imagePreviewUrl = null;
  //     if(type === 'image') {
  //       imagePreviewUrl = preview;
  //     } else if (type === 'pdf') {
  //       pdfPreviewUrl = preview;
  //     }
  //     this.setState(
  //       {
  //         documentFile: file,
  //         imagePreviewUrl,
  //         pdfPreviewUrl,
  //         [type]: preview,
  //         selectedDoc: null,
  //       });

  //     if (type == 'pdf') {
  //         this.setState({ imagePreviewUrl: null })
  //         let base64result = preview.split(',')[1];
  //         var pdfData = atob(base64result);
  //         var options = { scale: 1 };
  //         let canvasContainer = document.getElementById("holder");
  //         $('#holder').html('');

  //         function renderPage(page) {
  //           var viewport = page.getViewport(options.scale);
  //           var canvas = document.createElement('canvas');
  //           var ctx = canvas.getContext('2d');
  //           var renderContext = {
  //             canvasContext: ctx,
  //             viewport: viewport
  //           };

  //           canvas.height = viewport.height;
  //           canvas.width = viewport.width;
  //           canvasContainer.appendChild(canvas);

  //           page.render(renderContext);
  //         }

  //         function renderPages(pdfDoc) {
  //           for (var num = 1; num <= pdfDoc.numPages; num++)
  //             pdfDoc.getPage(num).then(renderPage);
  //         }
  //         pdfjsLib.disableWorker = true;
  //         pdfjsLib.getDocument({ data: pdfData }).then(renderPages);

  //     }
  //   } else {
  //     toastr.show("Please enter only PDF and/or Image (JPG, PNG) file formats.", "error");
  //     return;
  //   }
  // }

  uploadResult = (file, preview, type) => {
    if (
      (file.type.match("image/png")||file.type.match("image/jpg") ||file.type.match("image/jpeg")  || file.type.match("application.pdf")) ){
        this.setState({showUploadModal:true})
        if (file.type.indexOf("image") == -1) {
        type = file.type.split("/")[1];
      }
      setTimeout(() => {
        this.setState({ progress: 100 });
      }, 1000);
      this.setState(
        {
          documentFile: null,
          [`${type}PreviewUrl`]: null,
          pdfPreviewUrl: null,
          [type]: null,
        },
        () => {
          this.setState({
            documentFile: file,
            [`${type}PreviewUrl`]: preview,
            [type]: preview,
          });
        }
      );

      if (type == "pdf") {
        this.setState({ imagePreviewUrl: null });
        let base64result = preview.split(",")[1];
        var pdfData = atob(base64result);
        var loadingTask = pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then(function (pdf) {
          // Fetch the first page.
          pdf.getPage(1).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport(scale);
            // Prepare canvas using PDF page dimensions.
            var canvas = document.getElementById("the-canvas");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // Render PDF page into canvas context.
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          });
        });
      }
    }else {
      toastr.show("Please enter only PDF and/or Image (JPG, PNG) file formats.", "error");
      return;
  }
  };
  filterStatus(status) {
    let newStatus = this.state.selectedStatuses;
    if (
      this.state.selectedStatuses.find((obj) => {
        return obj === status;
      })
    ) {
      newStatus = newStatus.subtract(status);
    } else {
      newStatus.insert(status);
    }
    this.setState({ selectedStatuses: newStatus, subCardFilter: null });
  }

  findAddress(d) {
    let lastCompletedStatus;
    if (d.load) {
      d.status !== "COMPLETED" &&
        d.load.driverOrder &&
        d.load.driverOrder.forEach((element) => {
          if (element.departed) {
            lastCompletedStatus = d.status;
          }
        });
    }
  }
  sortTable(sort) {
    this.setState({
      sort,
      asce: this.state.sort !== sort ? true : !this.state.asce,
    });
  }

  _subFilter(key) {
    this.setState({
      subCardFilter: key === this.state.subCardFilter ? null : key,
    });
  }

  searchInEquipments(AllTMSEquipments) {
    const regex = RegExp(this.state.searchTerm, "i");
    return AllTMSEquipments.filter((obj) => {
      return (
        (obj.equipmentID && obj.equipmentID.match(regex)) ||
        (obj.licence_plate_number && obj.licence_plate_number.match(regex)) ||
        (obj.licence_plate_state && obj.licence_plate_state.match(regex)) ||
        (obj.vin && obj.vin.match(regex))
      );
    });
  }

  mutateObject(AllTMSEquipments = []) {
    AllTMSEquipments.forEach((d) => {
      let totalAmountOfDays;
      let lastCompletedStatus = this.findAddress(d);
      let completedStatus =
        d.load &&
        d.status === "COMPLETED" &&
        d.load.driverOrder &&
        d.load.driverOrder.find((ele) => ele.departed === true);

      let enrouteToPickup =
        d.load &&
        d.load.driverOrder &&
        d.load.driverOrder.find((e) => e.type === "PULLCONTAINER" && e.arrived);

      if (enrouteToPickup) {
        totalAmountOfDays =
          moment(completedStatus && completedStatus.completedAt).diff(
            moment(enrouteToPickup.completedAt),
            "days"
          ) + 1;
      }

      d.completedStatus = completedStatus;
      d.totalAmountOfDays = totalAmountOfDays;
      d.lastCompletedStatus = lastCompletedStatus;
    });
    return AllTMSEquipments;
  }

  exportToCsv = () => {
    const loadData = [];
    let allTrailers = this.state.AllTMSEquipments ? this.state.AllTMSEquipments : [];
    if (this.state.searchTerm && allTrailers) {
      const regex = RegExp(this.state.searchTerm, "i")
      allTrailers = allTrailers.filter((obj) => {
        return (obj.equipmentID && obj.equipmentID.match(regex))  || (obj.licence_plate_number && obj.licence_plate_number.match(regex)) || (obj.licence_plate_state && obj.licence_plate_state.match(regex)) || (obj.vin && obj.vin.match(regex))
      })
    }
    allTrailers = this.state.fitlerDeletedEnabled === 'Enabled' ? allTrailers.filter(d => !d.isDeleted) : allTrailers;
    allTrailers = this.state.fitlerDeletedEnabled === 'Disabled' ? allTrailers.filter(d => d.isDeleted) : allTrailers;

    if (this.state.sort) {
      allTrailers = _.orderBy(allTrailers, this.state.sort, !this.state.asce && 'desc')
    }
    allTrailers.map(d => {
      let data = {
        "TRAILER #": d.equipmentID,
        "LICENSE PLATE #": d.licence_plate_number,
        "LICENSE PLATE STATE": d.licence_plate_state,
        "VIN": d.vin,
        "ADDRESS": d.lastCompletedStatus && d.lastCompletedStatus.address ? d.lastCompletedStatus.address.address : '',
        "AID": d.AID ? moment(d.AID).format("MM/DD/YYYY") : '',
        "ITD": d.ITD ? moment(d.ITD).format("MM/DD/YYYY") : '',
        "REGISTRATION": d.reg_expiration ? moment(d.reg_expiration).format("MM/DD/YYYY") : '',
        "INSPECTION": d.inspection_exp ? moment(d.inspection_exp).format("MM/DD/YYYY") : '',
        "HUT": d.hut_exp ? moment(d.hut_exp).format("MM/DD/YYYY") : '',
      }
      loadData.push(data);
    })
    var csv = Papa.unparse(loadData);
    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `${new Date().getTime()}.csv`);
    }
    else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${new Date().getTime()}.csv`);
    tempLink.click();
  }

  //#########################
  deleteDocument = (id) => {
    confirm(
      "Remove Document?",
      "Are you sure you want to remove this document?",
      (confirm) => {
        if (confirm) {
          deleteDocument({
            loadRef: this.props.selectedLoads.reference_number,
            _id: id,
          }).then((result) => {
            toastr.show("This document has been deleted.", "success");
            if (this.props.updateList) {
              this.props.updateList(result.data.data);
            } else {
              this.props.actions.updateDocument(result.data.data);
            }
            this.setState({ pdfPreviewUrl: null });
          });
        }
      }
    );
  };

  updateDocument = (id, type) => {
    updateDocument({
      loadRef: this.props.selectedLoads.reference_number,
      _id: id,
      type,
    }).then((result) => {
      toastr.show("This document has been updated.", "success");
      if (this.props.updateList) {
        this.props.updateList(result.data.data);
      } else {
        this.props.actions.updateDocument(result.data.data);
      }
    });
  };

  addCheck = (doc) => {
    addCheck({
      documentId: doc._id,
      reference_number: this.props.selectedLoads.reference_number,
      checked: !doc.checked,
    }).then((response) => {
      response.data.data.documents.forEach((reply) => {
        if (reply._id == doc._id) {
          if (reply.checked) {
            toastr.show("This document has been selected.", "success");
          } else {
            toastr.show("This document has been unselected.", "success");
          }
        }
      });
      if (this.props.updateList) {
        this.props.updateList(response.data.data);
      } else {
        this.props.dispatch({
          type: TMS_GET_USER_LOAD,
          reference_number: this.props.selectedLoads
            ? this.props.selectedLoads.reference_number
            : this.props.loads.reference_number,
          payload: {
            skip: 0,
            limit: 1,
          },
        });
      }
    });
  };

  selectDocument = (document) => {
    const documents = [...this.state.documents];
    documents.push(document);
    this.setState({ documents });
  };

  sendDocumentsInEmail = () => {
    if (!this.state.processing) {
      const emailObj = {
        documents: this.state.documents,
        email: "test@gmail.com",
        reference_number: this.props.selectedLoads.reference_number,
      };
      sendDocumentsInEmail(emailObj)
        .then((res) => {
          toastr.show(
            "Document has been successfully sent to the email",
            "success"
          );
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
    if (this.state.documents.length == 0) {
      toastr.show("Please choose the document you wish to send.", "warning");
    }
  };

  checkedOrNot = (info) => {
    if (info.checked) {
      return true;
    } else {
      return false;
    }
  };

  rotatePdf = () => {
    if (!this.state.processing) {
      this.setState({ processing: true }, () => {
        rotatePdf({
          url: this.state.selectedDoc.url,
          _id: this.props.selectedLoads._id,
          degree: this.state.degree,
          documentId: this.state.selectedDoc._id,
        }).then((res) => {
          let selectedDoc = Object.assign({}, this.state.selectedDoc);
          selectedDoc.url = res.data.data.url;
          let documents = JSON.parse(
            JSON.stringify(
              this.props.selectedLoads && this.props.selectedLoads.documents
                ? this.props.selectedLoads.documents
                : []
            )
          );
          documents = documents.map((doc) => {
            if (doc.url == this.state.selectedDoc.url) {
              doc.url = res.data.data.url;
            }
            return doc;
          });
          this.setState(
            {
              selectedDoc,
              listAllImagingDocuments: documents,
              pdfPreviewUrl: res.data.data.url,
            },
            () => {
              this.setState({ processing: false });
              if (this.props.updateList) {
                this.props.updateList(res.data.data.rotatedDoc);
              } else {
                this.props.actions.updateDocument(res.data.data.rotatedDoc);
              }
              this.props.actions.updateDocumentRotate(documents);
            }
          );
        });
      });
    } else {
      toastr.show("Please wait while your document is rotated!", "success");
    }
  };

  newView = (info) => {
    this.setState(
      { pdfPreviewUrl: info.url, selectedDoc: info, pageNumber: 1 },
      () => { }
    );
  };

  handleShowUploadModal = (event) => {
    this.setState({
      showUploadModal: true,
      uploadFileDetails: null,
      documentFile: null,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      progress: 0,
    });
  };

  removeDocument = () => {
    this.setState({
      uploadFileDetails: "",
      progress: 0,
      pdfPreviewUrl: null,
      imagePreviewUrl: null,
      documentImage: true,
    });
    this.setState({ documentFile: null });
  };

  // uploadFileData = () => {
  //   let data = new FormData();
  //   let loadDetail = this.props.selectedLoads;
  //   let apiUrl = `${configuration.docUrl}driver/uploadDoctoAWS`;
  //   if (!this.state.documentFile) {
  //     toastr.show("Please select a document.", "error");
  //     return false;
  //   }
  //   if (this.state.documentFile) {
  //     if (this.state.documentFile.name.split('.').pop() !== 'png' && this.state.documentFile.name.split('.').pop() !== 'jpg' && this.state.documentFile.name.split('.').pop() !== 'pdf' && this.state.documentFile.name.split('.').pop() !== 'jpeg') {
  //       toastr.show("Please select only PDF, JPG, PNG file formats.", "error");
  //       return this.removeDocument();
  //     }
  //   }
  //   if (this.state.documentFile.type.indexOf("image") > -1) {
  //     apiUrl = `${configuration.docUrl}driver/uploadProofOfDelivery`;
  //     data.append("proofOfDelivery", this.state.image);
  //     data.append("shipmentId", new Date().getTime().toString());
  //   } else {
  //     data.append("document", this.state.documentFile);
  //     data.append("filename", new Date().getTime().toString());
  //   }
  //   HTTP("put", null, data, { "Content-Type": `multipart/form-data` }, apiUrl)
  //     .then((result) => {
  //       if (result.status === 200) {
  //         const documentObjData = {
  //           // loadRef: this.props.selectedLoads.reference_number,
  //           _id: this.state._id,
  //           type: this.state.fileType,
  //           url: result.data.data.url,
  //         };
  //         if (this.state.activeTab === "subCustomer") {
  //           documentObjData.customerId = this.state.customerId.value;
  //         }
  //         this.setState({
  //           documentObj: documentObjData,
  //           progress: 100,
  //           documentImage: false,
  //         });
  //       } else {
  //         toastr.show("Something went wrong! Please try again.", "error");
  //         this.setState({ docUploading: false });
  //       }
  //     })
  //     .catch((err) => {
  //       toastr.show("Something went wrong! Please try again.", "error");
  //       this.setState({ docUploading: false });
  //     });
  // };

  //########################

  render() {
    const {
      fitlerDeletedEnabled,
      filterTrailerTypes,
      subCardFilter,
      selectedDoc,
      tab,
      _id,
      equipmentID,
      year,
      make,
      model,
      reg_expiration,
      isTerminal,
      AID,
      hut_exp,
      selectedPdf,
      selectedStatuses,
      searchTerm,
      isLoadingBarShow,
      terminals,
      licence_plate_number,
      inspection_exp,
      licence_plate_state,
      trailerType,
      ITD,
      vin,
      formsTouched,
      formErrors,
      equipmentHistory,
      audits,
      notes,
      AddTrailerModal,
      pdfPageNumber,
      currPage,
      addModal,
      loading,
      isEnableTrailer,
      isDisableTrailer 
    } = this.state;

    let documents = _.filter(this.state.documents, (i) => i.checked);

    let AllTMSEquipments =
      selectedStatuses.length === 0 ? this.state.AllTMSEquipments : [];

    if (!AllTMSEquipments) {
      AllTMSEquipments = [];
    }

    selectedStatuses.length > 0 &&
      selectedStatuses.map(
        (key) =>
          (AllTMSEquipments = AllTMSEquipments.concat(this.state[`${key}`]))
      );

    AllTMSEquipments = this.mutateObject([...AllTMSEquipments]);

    const enabledCount = _.cloneDeep(AllTMSEquipments).filter(
      (d) => !d.isDeleted
    ).length;
    const disabledCount = _.cloneDeep(AllTMSEquipments).filter(
      (d) => d.isDeleted
    ).length;

    if (this.state.searchTerm && AllTMSEquipments) {
      AllTMSEquipments = this.searchInEquipments(AllTMSEquipments);
    }

    if (!isEnableTrailer && isDisableTrailer) {
      AllTMSEquipments = AllTMSEquipments.filter((d) => d.isDeleted);
    } else if (isEnableTrailer && !isDisableTrailer) {
      AllTMSEquipments = AllTMSEquipments.filter((d) => !d.isDeleted);
    }


    // if (["Enabled", "Disabled"].includes(fitlerDeletedEnabled))
    //   AllTMSEquipments = AllTMSEquipments.filter((d) =>
    //     fitlerDeletedEnabled === "Enabled" ? !d.isDeleted : d.isDeleted
    //   );

    if (this.state.sort) {
      AllTMSEquipments = _.orderBy(
        AllTMSEquipments,
        this.state.sort,
        !this.state.asce && "desc"
      );
    }

    const groupedTrailer = _.groupBy(
      AllTMSEquipments,
      "lastCompletedStatus.address.address"
    );

    if (subCardFilter) AllTMSEquipments = groupedTrailer[subCardFilter];

    if (AllTMSEquipments && AllTMSEquipments.length > 0) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1
      ) {
        let fleetTerminal = JSON.parse(getStorage("loggedInUser")).fleetManager
          .terminals;
        AllTMSEquipments = AllTMSEquipments.filter(
          (D) => D.terminal && fleetTerminal.indexOf(D.terminal) > -1
        );
      }
    }
    let Terminal = [];
    const allTerminal = JSON.parse(JSON.stringify(this.state.terminals))
    if (this.state.terminals && this.state.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.state.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }

    const isCardActive = true;
    const isMoreOptions = 1;

    const selectedValues = _.filter(Terminal, (v) => {
      const data = _.includes(
        !this.state.newTerminal
          ? []
          : this.state.newTerminal.map((e) => e.value),
        v.value
      )
      return data
    }
    );

    const sizesOptions = size.map((i) => {
      return {
        label: i,
        value: i,
      };
    });

    return (
      <div className="position-relative subheader-wrapper">
        {!this.props.isQuickAdd && (
          <div>
            <SubHeaderTab
              val="equipmentItems"
              buttons={
                <Fragment>
                  {this.state.AllTMSEquipments &&
                    this.state.AllTMSEquipments.length > 0 && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-outline-light mr-2"
                          onClick={() => this.exportToCsv()}
                        >
                          <IconDownload className="mr-2" />
                          Download CSV
                        </button>
                      </span>
                    )}
                  <button
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#addnewprofile"
                    onClick={() => {
                      this.Newform();
                      this.setState({
                        addModal: true,
                        newTerminal: null,
                        formErrors: {},
                        formsTouched: {},
                      });
                    }}
                  >
                    <IconPlus className="mr-2" />
                    Add New Trailer
                  </button>
                </Fragment>
              }
            />

            <div
              className={
                "position-relative " +
                (this.state.collapseSubCards ? "" : "afilter-open")
              }
            >
              {!this.props.isOnlyPopup && (
                <div
                  class={`subheader ${
                    this.state.AddTrailerModal ? "d-block" : "d-none"
                  }`}
                >
                  <div className="subheader__inner d-flex">
                    <div className="d-flex flex-row align-items-center">
                      <button
                        className="btn btn-outline-light"
                        onClick={() => {
                          this.setState({
                            AddTrailerModal: false,
                          });
                        }}
                      >
                        <IconAngleArrowLeft />
                      </button>
                      <div className="d-flex ml-3 align-items-center">
                        <h5 className="mb-0">{equipmentID}</h5>
                      </div>
                    </div>

                    <div class="ml-auto position-relative">
                      <button
                        type="button"
                        class="btn btn-outline-light mr-1"
                        onClick={() => {
                          this.setState({
                            AddTrailerModal: false,
                            fileType: null,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={() => {
                          this.insertdata();
                          this.setState({ isLoadTabActive: 1 });
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  "position-relative " +
                  (this.state.collapseSubCards ? "" : "afilter-open")
                }
              >
                {!this.props.isOnlyPopup && (
                  <div
                    className={`page-fluid ${
                      this.state.AddTrailerModal ? "d-none" : "d-block"
                    }
                `}
                  >
                    <div className="top-page">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <h4 className="font-20 mb-0">Trailers</h4>
                        </div>

                      </div>
                      <div
                        className={`form-row ${
                          selectedStatuses.length > 0 && "hasactive"
                        }`}
                      >
                        {this.allStatuses.map((status) => {
                          let statusName = status;
                          let iconName = "";
                          if (statusName === "Available") {
                            iconName = "uil uil-thumbs-up";
                          } else if (statusName === "Dispatched") {
                            iconName = "uil uil-truck";
                          } else if (statusName === "Loaded") {
                            iconName = "uil uil-box";
                          } else if (statusName === "Empty") {
                            iconName = "uil uil-ban";
                          }
                          return (
                            <div className="col-md my-2">
                              <div
                                className={`ncard d-flex flex-column justify-content-between p-10 ${
                                  selectedStatuses.indexOf(statusName) > -1
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.filterStatus(status)}
                              >
                                <div className="d-flex align-items-center">
                                  <div
                                    className={`mr-1 sbox sbox--default px-2 h-100 ${
                                      selectedStatuses.indexOf(statusName) > -1
                                        ? "sbox--active"
                                        : ""
                                    }`}
                                  >
                                    {["Enabled", "Disabled"].includes(
                                      // fitlerDeletedEnabled
                                      filterTrailerTypes
                                    )
                                      ? this.state[`${status}`].filter((d) =>
                                          filterTrailerTypes === "Enabled"
                                            ? !d.isDeleted
                                            : d.isDeleted
                                        ).length
                                      : this.state[`${status}`].length || 0}
                                  </div>
                                  <div> {statusName}</div>
                                  <div className="ml-auto">
                                    <div className="form-check">
                                      <input
                                        checked={
                                          selectedStatuses.indexOf(statusName) >
                                          -1
                                        }
                                        type="checkbox"
                                        className="form-check-input"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* Filter Row */}
                      <div className="filter-row d-flex align-items-center">
                        <div className="app-search header-search">
                          <span className="search-icon"> </span>
                          <div className="position-relative">
                            <input
                              type="search"
                              className="form-control input-search-left"
                              placeholder="Search..."
                              id="customerSearchInput"
                              value={searchTerm}
                              onChange={(e) => {
                                this.setState({
                                  searchTerm: e.target.value.replace(
                                    /[^a-zA-Z0-9 ]/g,
                                    ""
                                  ),
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-check form-check--gray pr-30">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Available"
                              checked={isEnableTrailer}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({ isEnableTrailer: true });
                                } else {
                                  this.setState({ isEnableTrailer: false });
                                }
                              }}
                            />
                            <label className="form-check-label" for="Available">
                              Enabled
                              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                                {enabledCount}
                              </span>
                            </label>
                          </div>
                          <div className="form-check form-check--gray pr-30">

                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Pending"
                              value="Disabled"
                              checked={isDisableTrailer}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({ isDisableTrailer: true });
                                } else {
                                  this.setState({ isDisableTrailer: false });
                                }
                              }}
                            />
                            <label className="form-check-label" for="Pending">
                              Disabled
                              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                                {disabledCount}
                              </span>
                            </label>
                          </div>
                        </div>
                        {/* Select Terminal */}
                        {this.state.isTerminal && (
                          <div className="w-200 mr-2">
                            <Select
                              onChange={(choosenTerminals) =>
                                this.setState({ choosenTerminals }, () => {
                                  this.getlist();
                                })
                              }
                              styles={smallSelectStyle}
                              isMulti
                              name="choosenTerminals"
                              options={Terminal}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Table */}
                    <div
                      className="table-responsive"
                      style={{ height: "calc(100vh - 290px)" }}
                    >
                      {isLoadingBarShow && <LoaderBar></LoaderBar>}
                      <table className="table table-card table-card--ls">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            {tableHeaderData.map((headetItem) => {
                              return (
                                <th
                                  scope="col"
                                  key={headetItem}
                                  onClick={() =>
                                    sortingOptions.hasOwnProperty(headetItem) &&
                                    this.sortTable(sortingOptions[headetItem])
                                  }
                                  className={`${sortingOptions.hasOwnProperty(headetItem) &&
                                  (this.state.sort == undefined ||
                                    this.state.sort !==
                                      sortingOptions[headetItem]) ? (
                                    'table-sort table-sort--default'
                                  ) : sortingOptions.hasOwnProperty(
                                      headetItem
                                    ) ? (
                                    this.state.sort ===
                                      sortingOptions[headetItem] &&
                                    this.state.asce == true ? (
                                    'table-sort table-sort--ascending'
                                    ) : (
                                    'table-sort table-sort--descending'
                                    )
                                  ) : (
                                    ""
                                  )}`}
                                >
                                  {headetItem}
                                </th>
                              );
                            })}
                            {/* <th></th> */}
                            {isTerminal && (
                              <th
                                onClick={() =>
                                  sortingOptions.hasOwnProperty("TERMINAL") &&
                                  this.sortTable(sortingOptions["TERMINAL"])
                                }
                                className={`${sortingOptions.hasOwnProperty("TERMINAL") &&
                                (this.state.sort == undefined ||
                                  this.state.sort !==
                                  sortingOptions["TERMINAL"]) ? (
                                  'table-sort table-sort--default'
                                ) : sortingOptions.hasOwnProperty(
                                    "TERMINAL"
                                  ) ? (
                                  this.state.sort ===
                                    sortingOptions["TERMINAL"] &&
                                  this.state.asce == true ? (
                                    'table-sort table-sort--ascending'
                                  ) : (
                                   'table-sort table-sort--descending'
                                  )
                                ) : (
                                  ""
                                )}`}
                              >
                                TERMINAL
                               
                              </th>
                            )}
                           
                          </tr>
                        </thead>

                        <tbody>
                          {AllTMSEquipments &&
                            AllTMSEquipments.map((d) => {
                              return (
                                <tr key={d._id}>
                                  <td className="text-center" width="57">
                                    {!d.isDeleted && (
                                      <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => this.changeColor(d._id)}
                                      >
                                        <IconPen />
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-center" width="57">
                                    {d.isDeleted ? (
                                      <button
                                        type="button"
                                        className="btn btn-link text-success"
                                        onClick={() =>
                                          this.deleteTMSEquipement(d)
                                        }
                                      >
                                        <IconCheckCircle />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() =>
                                          this.deleteTMSEquipement(d)
                                        }
                                      >
                                        <IconTrash />
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-center" data-tip data-for={d._id}>   
                                    <button
                                      type="button"
                                      className="btn btn-link"   
                                    >
                                      <IconNotes/>
                                    </button>
                                    {d.notes && d.notes.trim().length>0 ?  
                                      <ReactTooltip id={d._id}  className="type-gray w-300 text-wrap text-break line-break" >
                                        <span>{d.notes}</span>
                                      </ReactTooltip>
                                      : null
                                    }
                                  </td>
                                  <td>
                                    {d.equipmentID}
                                  </td>

                                  <td>{d.licence_plate_number}</td>
                                  <td>{d.vin}</td>
                                  <td width="10%">
                                    {d.lastCompletedStatus &&
                                    d.lastCompletedStatus.address
                                      ? d.lastCompletedStatus.address.address
                                      : ""}
                                  </td>
                                  {d.AID != null ? (
                                    <td
                                      className={`${
                                        d.AID &&
                                        moment(d.AID).diff(moment(), "days") <=
                                          0
                                          ? "bg-warning-500"
                                          : ""
                                      }`}
                                    >
                                      {d.AID
                                        ? moment(d.AID).format("MM/DD/YYYY")
                                        : null}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {d.ITD != null ? (
                                    <td
                                      className={`${
                                        d.ITD &&
                                        moment(d.ITD).diff(moment(), "days") <=
                                          0
                                          ? "bg-warning-500"
                                          : ""
                                      }`}
                                    >
                                      {d.ITD
                                        ? moment(d.ITD).format("MM/DD/YYYY")
                                        : null}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                
                                  <td>{d.trailerType}</td>
                                  <td>{d.size}</td>
                                  {d.reg_expiration != null ? (
                                    <td
                                      className={`${
                                        d.reg_expiration &&
                                        moment(d.reg_expiration).diff(
                                          moment(),
                                          "days"
                                        ) <= 0
                                          ? "bg-warning-50"
                                          : ""
                                      }`}
                                    >
                                      {d.reg_expiration
                                        ? moment(d.reg_expiration).format(
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {d.inspection_exp != null ? (
                                    <td
                                      className={`${
                                        d.inspection_exp &&
                                        moment(d.inspection_exp).diff(
                                          moment(),
                                          "days"
                                        ) <= 0
                                          ? "bg-warning-50"
                                          : ""
                                      }`}
                                    >
                                      {d.inspection_exp
                                        ? moment(d.inspection_exp).format(
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {d.hut_exp != null ? (
                                    <td
                                      className={`${
                                        d.hut_exp &&
                                        moment(d.hut_exp).diff(
                                          moment(),
                                          "days"
                                        ) <= 0
                                          ? "bg-warning-50"
                                          : ""
                                      }`}
                                    >
                                      {d.hut_exp
                                        ? moment(d.hut_exp).format("MM/DD/YYYY")
                                        : ""}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {isTerminal && (
                                    <td className={`hideextra`}>
                                      {d.newTerminal &&
                                      d.newTerminal.length > 0 &&
                                      terminals &&
                                      terminals.length > 0
                                        ? d.newTerminal.map((ids, index) => {
                                            let thatTerminal = terminals.find(
                                              (D) => D._id == ids
                                            );
                                            return (
                                              <div key={index}>
                                                <span>
                                                  {thatTerminal
                                                    ? thatTerminal.name
                                                    : ""}
                                                </span>
                                              </div>
                                            );
                                          })
                                        : ""}
                                    </td>
                                  )}
                                
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div
                  className={`page-fluid ${
                    this.state.AddTrailerModal ? "d-block" : "d-none"
                  }`}
                >
                  <div className="form-row-md fcpopup">
                    <div className="col-lg fcpopup__left">
                      <div className="card p-15 mb-1">
                        <h5>About</h5>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Trailer #</span>
                          <span>{equipmentID}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Year</span>
                          <span>{year}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Make</span>
                          <span>{make}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Model</span>
                          <span>{model}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg fcpopup__right">
                      <div className="card mb-0">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                tab === 1 ? "active" : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                this.setState({
                                  tab: 1,
                                  documentFile: null,
                                  imagePreviewUrl: null,
                                  pdfPreviewUrl: null,
                                })
                              }
                            >
                              <IconCart />
                              <div className="nav-link__text">Trailer</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                tab === 2 ? "active" : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                this.setState({
                                  tab: 2,
                                  imagePreviewUrl: null,
                                  pdfPreviewUrl: null,
                                })
                              }
                            >
                              <IconDocument />
                              <div className="nav-link__text">Documents</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                tab === 3 ? "active" : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                this.setState({
                                  tab: 3,
                                  imagePreviewUrl: null,
                                  pdfPreviewUrl: null,
                                })
                              }
                            >
                              <IconClock9 />
                              <div className="nav-link__text">History</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                tab === 4 ? "active" : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                this.setState({
                                  tab: 4,
                                  imagePreviewUrl: null,
                                  pdfPreviewUrl: null,
                                })
                              }
                            >
                              <IconSearchData />
                              <div className="nav-link__text">Audit</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                tab === 5 ? "active" : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() =>
                                this.setState({
                                  tab: 5,
                                  imagePreviewUrl: null,
                                  pdfPreviewUrl: null,
                                })
                              }
                            >
                              <IconNotes />
                              <div className="nav-link__text">Notes</div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-loads">
                        <div className="tab" id="tab-chassis">
                          <div className={`${tab === 2 ? "" : "card"}`}>
                            {tab === 1 && (
                              <div className="pt-30 px-20 mb-10">
                                <div className="font-14 font-medium mb-15">Equipment</div>
                                <div className="form-row">
                                  <StateFormInput
                                    className={"col-md-3"}
                                    type="text"
                                    name="equipmentID"
                                    isRequired="true"
                                    placeholder="Enter Trailer #"
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    label={"Trailer #"}
                                    value={this.state.equipmentID}
                                  />
                                  <StateFormSelect
                                    className={"col-md-3"}
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    label="Trailer Type"
                                    name="trailerType"
                                    options={trailerTypeOptions}
                                    value={
                                      trailerType &&
                                      trailerTypeOptions.find((option) => option.value === trailerType)
                                    }
                                    placeholder="Select Type"
                                    onValChange={this.setValChange}
                                    isClearable={true}
                                    isMulti={false}
                                  />
                                  <StateFormSelect
                                    className={"col-md-3"}
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    label="Trailer Size"
                                    name="size"
                                    options={sizesOptions}
                                    value={this.state.size && sizesOptions.find(el=>el.value===this.state.size)}
                                    placeholder="Select Size"
                                    onValChange={(name, val) => this.setValChange(name, val)}
                                    isClearable={true}
                                    isMulti={false}
                                  />
                                </div>
                              
                                <div className="form-row">
                                  <StateFormSelect
                                    className={"col-lg"}
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    styles={smallSelectStyle}
                                    name="year"
                                    options={yearOptions}
                                    label="Year"
                                    value={
                                      this.state.year &&
                                      yearOptions.find((el) => el.label === this.state.year)
                                    }
                                    placeholder="Select Year"
                                    onValChange={this.setValChange}
                                    isClearable={true}
                                  />
                                  <StateFormInput
                                    className={"col-lg"}
                                    type="text"
                                    placeholder="Who Makes"
                                    name="make"
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    label={"Make"}
                                    value={make}
                                  />
                                  <StateFormInput
                                    className={"col-lg"}
                                    type="text"
                                    name="model"
                                    placeholder="Enter Model"
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    label={"Model"}
                                    value={model}
                                  />
                                  <StateFormInput
                                    className={"col-lg"}
                                    type="text"
                                    placeholder="Enter Lisence State"
                                    name="licence_plate_state"
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    label={"License State"}
                                    value={licence_plate_state}
                                  />
                                  <StateFormInput
                                    className={"col-lg"}
                                    type="text"
                                    name="licence_plate_number"
                                    placeholder="Enter Enter Lisence Plate #"
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    label={"Licence Number"}
                                    value={licence_plate_number}
                                  />
                                  <StateFormInput
                                    className={"col-lg"}
                                    type="text"
                                    placeholder="Enter VIN"
                                    name="vin"
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    label={"VIN"}
                                    value={vin}
                                  />
                                </div>
                                <div className="hr-light mt-10 mb-20"></div>
                                <div className="text-14 text-medium mb-15">Expiration Dates</div>
                                <div className="form-row">
                                  <StateFormDateTime
                                    className={"col-lg"}
                                    formsTouched={formsTouched}
                                    formErrors={formErrors}
                                    name="reg_expiration"
                                    label={"Registration Exp"}
                                    value={reg_expiration ? moment(reg_expiration) : ""}
                                    onValChange={this.setValChange}
                                  />
                              
                                  <StateFormDateTime
                                    className={"col-lg"}
                                    formsTouched={formsTouched}
                                    formErrors={formErrors}
                                    name="inspection_exp"
                                    label={"Inspection Exp"}
                                    value={inspection_exp ? moment(inspection_exp) : ""}
                                    onValChange={this.setValChange}
                                  />
                              
                                  <StateFormDateTime
                                    className={"col-lg"}
                                    formsTouched={formsTouched}
                                    formErrors={formErrors}
                                    name="hut_exp"
                                    label={"HUT Expiration"}
                                    value={hut_exp ? moment(hut_exp) : ""}
                                    onValChange={this.setValChange}
                                  />
                                  <StateFormDateTime
                                    className={"col-lg"}
                                    formsTouched={formsTouched}
                                    formErrors={formErrors}
                                    name="AID"
                                    label={"AID"}
                                    value={AID ? moment(AID) : ""}
                                    onValChange={this.setValChange}
                                  />
                                  <StateFormDateTime
                                    className={"col-lg"}
                                    formsTouched={formsTouched}
                                    formErrors={formErrors}
                                    name="ITD"
                                    label={"ITD"}
                                    value={ITD ? moment(ITD) : ""}
                                    onValChange={this.setValChange}
                                  />
                                  {isTerminal && (
                                    <StateFormSelect
                                      className={"col-lg"}
                                      formErrors={formErrors}
                                      formsTouched={formsTouched}
                                      label="Terminal"
                                      isRequired={true}
                                      name="newTerminal"
                                      options={Terminal}
                                      value={selectedValues}
                                      placeholder="Select Terminal"
                                      onValChange={(name, val) => this.setValChange(name, val)}
                                      isClearable={true}
                                      isMulti
                                    />
                                  )}
                                </div>
                              </div>
                            )}

                            {tab === 2 && (
                              <React.Fragment>
                                <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                                  <h4 className="mb-0 font-20">
                                    {documents && documents.length == 0
                                      ? "No "
                                      : documents.length}{" "}
                                    Documents
                                  </h4>
                                  <button
                                    className="ml-auto btn btn-primary"
                                    onClick={this.handleShowUploadModal}
                                  >
                                    <IconUpload className="mr-2" />
                                    Upload Document
                                  </button>
                                </div>
                                <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                                {!this.props.type &&
                                  getStorage("currentUserRole") !=
                                    "customer" && (
                                    <UploadedDocuments
                                      listDocuments={documents}
                                      deleteDocument={(id) =>
                                        this.deleteEquipmentDoc({
                                          docId: id,
                                          _id: _id,
                                        })
                                      }
                                      onDocumentLoadSuccess={
                                        this.onDocumentLoadSuccess
                                      }
                                      newView={this.newView}
                                      selectedDoc={this.state.selectedDoc}
                                      setSelectedDoc={(docIndex) => {
                                        this.setState({
                                          selectedDoc: docIndex,
                                        });
                                      }}
                                      handleShowUploadModal={
                                        this.handleShowUploadModal
                                      }
                                      uploadResult={this.uploadResult}
                                      multi={false} 
                                      type={"image"}
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            )}

                            {tab === 3 && (
                              <div className="table-responsive">
                                <table className="table table-card table-card--ls mb-10">
                                  <thead>
                                    <tr>
                                      <th>Load #</th>
                                      <th>Eqipment #</th>
                                      <th>Driver Name</th>
                                      <th>Load Status</th>
                                      <th>Begining</th>
                                      <th>End</th>
                                      <th>Container #</th>
                                      <th>From Location</th>
                                      <th>To Location</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {equipmentHistory.length === 0 && (
                                      <tr>
                                        <td colSpan={7}>
                                          No Trailer History Available.
                                        </td>
                                      </tr>
                                    )}
                                    {equipmentHistory.map((d) => {
                                      return (
                                        <tr key={d._id}>
                                          <td>
                                            {" "}
                                            {d.load
                                              ? d.load.reference_number
                                              : ""}
                                          </td>
                                          <td>
                                            {" "}
                                            {d.equipmentId
                                              ? d.equipmentId.equipmentID
                                              : ""}
                                          </td>
                                          <td>
                                            {" "}
                                            {d.driver && d.driver.name ? (
                                              d.driver.lastName ? (
                                                <div className="d-flex align-items-center">
                                                  <span class="avatar-circle avatar-xs mr-1">
                                                    {d.driver.name.charAt(0)}
                                                  </span>
                                                  {d.driver.name +
                                                    d.driver.lastName}
                                                </div>
                                              ) : (
                                                <div className="d-flex align-items-center">
                                                  <span class="avatar-circle avatar-xs mr-1">
                                                    {d.driver.name.charAt(0)}
                                                  </span>
                                                  {d.driver.name}
                                                </div>
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            <span className="badge badge-green">
                                              {d.loadTypeStatus
                                                ? d.loadTypeStatus
                                                : ""}
                                            </span>
                                          </td>
                                          <td>
                                            {d.action === "ADD" ? (
                                              <React.Fragment>
                                                <div>
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("MM/DD/YY")}
                                                </div>
                                                <div className="text-muted">
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("hh:mm a")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {d.action === "REMOVE" ? (
                                              <React.Fragment>
                                                <div>
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("MM/DD/YY")}
                                                </div>
                                                <div className="text-muted">
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("hh:mm a")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {d.containerNo ? d.containerNo : ""}
                                          </td>
                                          <td>
                                            {d.action === "ADD" &&
                                            d.driverOrder &&
                                            d.driverOrder.address
                                              ? d.driverOrder.address.address
                                              : ""}
                                          </td>
                                          <td>
                                            {d.action === "REMOVE" &&
                                            d.driverOrder &&
                                            d.driverOrder.address
                                              ? d.driverOrder.address.address
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            )}

                            {tab === 4 && (
                              <React.Fragment>
                                <div className="table mb-20">
                                  <table className="table table-card table-card--ls mb-10">
                                    <thead>
                                      <tr>
                                        <th>Type</th>
                                        <th>User</th>
                                        <th>Time</th>
                                        <th>Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.audits &&
                                        this.state.audits.map((d, key) => {
                                          let description;
                                          let url;
                                          let equipmentID;
                                          if (
                                            [
                                              "DOCUMENT_UPLOAD",
                                              "DOCUMENT_REMOVE",
                                            ].includes(d.type)
                                          ) {
                                            description = d.data.document.type;
                                            url = (
                                              <a href={d.data.document.url}>
                                                Open Document...
                                              </a>
                                            );
                                          }
                                          if (d.type === "ADD") {
                                            description = "Truck No ";
                                            equipmentID = (
                                              <b>"{d.data.equipmentID}"</b>
                                            );
                                          }
                                          if (d.type === "UPDATE") {
                                            let data;
                                            if (
                                              key !== audits.length - 1 &&
                                              audits[key + 1].userId
                                            ) {
                                              data = audits[key + 1].data;
                                            }
                                            const additionalInfo = d.data;
                                            let dateFormat = [
                                              "AID",
                                              "ITD",
                                              "reg_expiration",
                                              "inspection_exp",
                                              "annual_inspection",
                                              "bobtail_insurance",
                                              "diesel_emission",
                                              "ifta",
                                              "bobTail",
                                              "maintenanceReceived",
                                              "maintenanceDue",
                                              "hut_exp",
                                            ];
                                            let differanceDescription =
                                              differnceFinder(
                                                data,
                                                additionalInfo
                                              );
                                            description = Object.keys(
                                              differanceDescription || []
                                            )
                                              .filter(
                                                (key1) =>
                                                  differanceDescription[key1] &&
                                                  typeof differanceDescription[
                                                    key1
                                                  ] !== "object"
                                              )
                                              .map((key1) => {
                                                if (dateFormat.includes(key1)) {
                                                  return (
                                                    <p>
                                                      {key1} changed to{" "}
                                                      <b>
                                                        {moment(
                                                          differanceDescription[
                                                            key1
                                                          ]
                                                        ).format(
                                                          "ddd MMM DD YYYY"
                                                        )}
                                                      </b>
                                                    </p>
                                                  );
                                                } else {
                                                  return (
                                                    <p>
                                                      {key1} changed to{" "}
                                                      <b className="badge badge-soft-warning">
                                                        {
                                                          differanceDescription[
                                                            key1
                                                          ]
                                                        }
                                                      </b>
                                                    </p>
                                                  );
                                                }
                                              });
                                          }
                                          return (
                                            <tr key={d._id} id={d._id}>
                                              <td>
                                                {" "}
                                                <div className="d-flex align-items-center">
                                                  <span class="avatar-circle avatar-xs mr-1">
                                                    {d.userId.name.charAt(0)}
                                                  </span>
                                                  {d.userId.name}
                                                </div>
                                              </td>
                                              <td>
                                                {" "}
                                                <span class="badge badge-gray-100">
                                                  {d.type.replace(/_/g, " ")}
                                                </span>
                                              </td>
                                              <td>
                                                <div>
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("MM/DD/YY")}
                                                </div>
                                                <div className="text-muted">
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("hh:mm a")}
                                                </div>
                                              </td>
                                              <td>
                                                {description} {equipmentID}{" "}
                                                {url}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </React.Fragment>
                            )}

                            {tab === 5 && (
                              <div className="px-30 py-20">
                                <div className="d-flex align-items-center mb-20">
                                  <IconCart className="mr-10" />
                                  <h5 className="font-16 mb-0">Trailer</h5>
                                </div>
                                <div className="form-group">
                                  <textarea
                                    onChange={({ target }) =>
                                      this.setState({ notes: target.value })
                                    }
                                    className="form-control"
                                    placeholder="Notes for driver"
                                    rows="10"
                                  >
                                    {notes}
                                  </textarea>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedPdf && (
          <Modal
            show={selectedPdf === null ? false : true}
            centered
            animation={false}
          >
            <Modal.Header className="d-flex align-items-start modal-header--gray">
              <div>
                <h5>{selectedPdf.type}</h5>
                <div className="text-muted mb-2">{selectedPdf.userId.name}</div>
                <div className="text-muted">
                  {" "}
                  {moment(selectedPdf.date).format("MM/DD/YYYY")}
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="py-30" style={{ overflow: "scroll" }}>
              <Document
                options={{
                  cMapUrl: "cmaps/",
                  cMapPacked: true,
                }}
                file={selectedPdf.url}
                onLoadSuccess={({ numPages }) => {
                  this.setState({
                    pdfPageNumber: numPages,
                  });
                }}
              >
                <Page pageNumber={currPage} />
              </Document>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-link"
                  disabled={currPage <= 1}
                  onClick={() => {
                    this.setState(({ currPage }) => ({
                      currPage: currPage - 1,
                    }));
                  }}
                >
                  <IconAngleArrowLeft />
                </button>
                <div className="mx-15">
                  <span>{currPage || (currPage ? 1 : "--")}</span>
                  <span className="text-muted mx-1">/</span>
                  <span className="text-muted">{pdfPageNumber || "--"}</span>
                </div>
                <button
                  className="btn btn-link"
                  disabled={currPage >= pdfPageNumber}
                  onClick={() => {
                    this.setState(({ currPage }) => ({
                      currPage: currPage + 1,
                    }));
                  }}
                >
                  <IconAngleArrowRight />
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-outline-light mr-2"
                  onClick={() => this.downloadDoc(selectedPdf)}
                >
                  <IconDownload className="mr-2" />
                  Download
                </button>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({
                      selectedPdf: null,
                      currPage: 1,
                      pdfPageNumber: 0,
                    });
                  }}
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={addModal || this.props.isQuickAdd}
          animation={false}
          dialogClassName="modal-xl modal-dialog-centered"
        >
          <Modal.Header>
            <Modal.Title>Add New Trailer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="font-medium mb-15 font-14">
                  Equipment
                </div>
                <div className="form-row">
                  <StateFormInput
                    className={"col-lg-3"}
                    type="text"
                    name="equipmentID"
                    isRequired="true"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Trailer #"}
                    value={equipmentID}
                    placeholder="Enter Trailer #"
                  />
                  <StateFormSelect
                    className={"col-lg-3"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    label="Trailer Type"
                    name="trailerType"
                    options={trailerTypeOptions}
                    value={
                      trailerType &&
                        trailerTypeOptions.find(
                          (option) => option.value === trailerType
                        )
                    }
                    placeholder="Select Type"
                    onValChange={(name, val) => this.setValChange(name, val)}
                    isMulti={false}
                    isClearable={true}

                  />
                  <StateFormSelect
                    className={"col-lg-3"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    label="Trailer Sizes"
                    name="size"
                    options={sizesOptions}
                    value={
                      this.state.size && {
                      label: this.state.size,
                      value: this.state.size,
                      }
                    }
                    placeholder="Select Size"
                    onValChange={(name, val) => this.setValChange(name, val)}
                    isMulti={false}
                    isClearable={true}

                  />
                </div>
                <div className="form-row">
                  <StateFormSelect
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    styles={smallSelectStyle}
                    name="year"
                    options={yearOptions}
                    label="Year"
                    value={
                      this.state.year && yearOptions.find(el => el.label === this.state.year)}
                    placeholder="Select Year"
                    onValChange={this.setValChange}
                    isClearable={true}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="make"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Make"}
                    value={make}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="model"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Model"}
                    value={model}
                  />
                  
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="licence_plate_state"
                    value={licence_plate_state}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"License Plate"}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="licence_plate_number"
                    value={licence_plate_number}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"License Number"}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="vin"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"VIN"}
                    value={vin}
                  />
                </div>
                <div className="hr-light my-10 mb-20"></div>
                <div className="form-row">
                  <div className="col-12">
                      <div className="font-14 font-medium mb-15">
                        Expiration Dates
                      </div>
                    </div>
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="reg_expiration"
                    label={"Registration Exp."}
                    value={reg_expiration ? moment(reg_expiration) : ""}
                    onValChange={this.setValChange}
                    className="col-lg"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="inspection_exp"
                    label={"Inspection Exp."}
                    value={inspection_exp ? moment(inspection_exp) : ""}
                    onValChange={this.setValChange}
                    className="col-lg"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="hut_exp"
                    label={"HUT Expiration"}
                    value={hut_exp ? moment(hut_exp) : ""}
                    onValChange={this.setValChange}
                    className="col-lg"
                  />

                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="AID"
                    label={"AID"}
                    value={AID ? moment(AID) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="ITD"
                    label={"ITD"}
                    value={ITD ? moment(ITD) : ""}
                    onValChange={this.setValChange}
                    className="col-lg"
                  />
                  {isTerminal && (
                    <StateFormSelect
                      formErrors={formErrors}
                      formsTouched={formsTouched}
                      className="col-lg"
                      isRequired={true}
                      label="Terminal"
                      name="newTerminal"
                      options={Terminal}
                      onValChange={(name, val) => this.setValChange(name, val)}
                      value={Terminal.find(
                        (data) => data.value === this.state.newTerminal
                      )}
                      placeholder="Select Terminal"
                      isClearable={true}
                      isMulti
                    />
                  )}
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-link"
              onClick={() => {
                if (this.props.isQuickAdd) {
                  this.props.falsyData("truck");
                }
                this.setState({ addModal: false, fileType: null });
              }}
            >
              Cancel
            </button>

            <button
              className={`btn ${
                this.state._id ? "btn-primary" : "btn-success"
              }`}
              onClick={() => {
                this.insertdata();
              }}
            >
              <span> {this.state._id ? "Update" : "Add New Trailer"} </span>
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {!this.props.type && getStorage("currentUserRole") != "customer" && (
                <div>
                  <ImageUploader
                    uploadResult={this.uploadResult}
                    multi={false}
                    name="Choose from your device..."
                    type={"image"}
                    className="custom-file-input "
                  />

              {this.state.documentFile && (
                <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                  <IconUpload className="mr-20" />
                  <div className="w-100">
                    <div className="font-medium d-flex justify-content-between w-100 mb-1">
                      <div>{this.state.documentFile.name}</div>
                      <div className="ml-auto">{this.state.progress}%</div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={`${this.state.progress}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${this.state.progress}%` }}
                      ></div>
                    </div>
                  </div>
                  <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                    <span
                      onClick={() =>
                        this.setState({
                          documentFile: null,
                          imagePreviewUrl: null,
                          pdfPreviewUrl: null,
                          progress: 0,
                        })
                      }
                    >
                      {" "}
                      <IconTimes className="" />
                    </span>
                  </button>
                </div>
              )}
                </div>
              )}
              {/* {acceptedFileItems} */}

              {/* After File is Uploaded sue the code block below */}
              {this.state.documentFile && (
                <div className="dropzone-fileupload d-flex">
                  <button className="btn btn-success btn-circle btn-xs mr-15">
                    <IconCheck />
                  </button>
                 
                    <div className="document-preview document-preview--sm mr-3">
                      <div className="document-preview__image">
                        {this.state.imagePreviewUrl && (
                          <img
                            className="document-preview__image"
                            src={this.state.imagePreviewUrl}
                            alt={""}
                          />
                        )}
                        {this.state.pdfPreviewUrl && (
                          <canvas id="the-canvas"></canvas>
                        )}
                      </div>
                    </div>
                  
                  <div className="w-100 d-flex">
                    <div>
                      <div className="font-medium ">
                        {this.state.documentFile.name}
                      </div>
                      <div className="text-muted">
                        {(
                          this.state.documentFile.size * 0.0009765625
                        ).toFixed(1)}{" "}
                        kb
                      </div>
                    </div>
                    {!this.props.type &&
                      getStorage("currentUserRole") != "customer" && (
                        <div className="w-150 ml-auto">
                          <Select
                            className="w-100"
                          options={this.state.documentTypes}
                            styles={smallSelectStyle}
                            value={
                              this.state.fileType &&
                              this.state.documentTypes.find(
                                (option) => option.value === this.state.fileType
                              )
                            }
                            onChange={({ value }) =>
                              this.setState({ fileType: value })
                            }
                            style={{ width: "150px", flex: "1 1 0%" }}
                          />
                        </div>
                      )}

                    {!this.props.type &&
                      getStorage("currentUserRole") != "customer" &&
                      this.state.activeTab === "subCustomer" && (
                        <div className="w-150 ml-auto">
                          <AsyncCreatableSelect
                            className="w-100"
                            cacheOptions
                            defaultOptions={makeOptionsForSelect(
                              this.props.customers,
                              "company_name",
                              "address.address",
                              "_id"
                            )}
                            Style={{ width: "150px", flex: "1 1 0%" }}
                            value={this.state.customerId}
                            styles={smallSelectStyle}
                            onChange={(customerId) => {
                              this.setState({ customerId: customerId });
                            }}
                            onInputChange={(customerId) => {
                              customerId !== "" &&
                                this.setState({ customerId: customerId });
                            }}
                            loadOptions={getCustomers}
                          />
                        </div>
                      )}
                  </div>
                  <button className="btn btn-link btn-circle btn-xs ml-3">
                    <span onClick={() => this.removeDocument()}>
                      {" "}
                      <IconTrash />{" "}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={loading}
              className="btn btn-close"
              onClick={() =>
                this.setState({
                  showUploadModal: false,
                  uploadFileDetails: "",
                  documentFile: null,
                  progress: 0,
                  pdfPreviewUrl: null,
                  imagePreviewUrl: null,
                  fileType: ""
                })
              }
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              // disabled={this.state.documentImage}
              onClick={() => {
                if (this.state.documentFile && this.state.fileType) {
                  this.insertdata();
                } else {
                  toastr.show("Please select the type.", "error");
                }
              }}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        { this.state.showFullDropScreen && tab === 2 && !this.state.showUploadModal &&
          <FullScreenUploader uploadResult={this.uploadResult} multi={false} type={"image"}/>
        }
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...actionCreators, getEquipmentAudits },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(Trailer);

const trailerTypeOptions = [
  { value: "Dry Van", label: "Dry Van" },
  { value: "Reefer", label: "Reefer" },
  { value: "Flat Bed", label: "Flat Bed" },
  { value: "Drop Deck", label: "Drop Deck" },
  { value: "Low Boy", label: "Low Boy" },
  { value: "Double Drop Deck", label: "Double Drop Deck" },
];

const schemaValidatorObject = Yup.object().shape({
  isTerminal: Yup.boolean(),
  newTerminal: Yup.array()
    .nullable()
    .when("isTerminal", {
      is: (val) => val === true,
      then: Yup.array()
        .min(1, "Terminal is Required.")
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        )
        .typeError("Terminal is Required.")
        .required("Terminal is Required."),
    }),
  equipmentID: Yup.string().trim().required("Trailer # is Required."),
});