import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getStorage, isMilitaryTime, toastr} from "../../../services/Common.services";
import { HTTP} from "../../../services/Http.service";
import {
  CustomIconBilling,
  CustomIconBuilding,
  CustomIconMapMarker,
  CustomIconTruck,
} from "../../../Components/Common/CustomIcons/Index";
import * as tmsAction from "./actionCreator";
import { LoaderBar } from '../../../Components/Common/LoaderBar';
class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billingNote: {},
      officeNote: {},
      yardNote: {},
      driverNote: {},
      isLoading: true,
      driver: "",
      billing: "",
      yard: "",
      office: "",
    };
    this.userTimeFormat = isMilitaryTime() ? 'MM/DD/YY HH:mm': 'MM/DD/YY hh:mm A';
  }
  componentDidMount() {
    this.notesDetail()
  }
  notesDetail(){
    this.setState({isLoading :true});
    tmsAction.getLoadNoteDetails({reference_number : this.props.selectedLoads.reference_number}).then((data) => {
      if(data.billingNote && Object.keys(data.billingNote).length != 0){
        this.setState({
          billingNote : data.billingNote ? data.billingNote : {},
        })
      }
      if(data.officeNote && Object.keys(data.officeNote).length != 0){
        this.setState({
          officeNote : data.officeNote ? data.officeNote : {},
        })
      }
      if(data.yardNote && Object.keys(data.yardNote).length != 0){
        this.setState({
          yardNote : data.yardNote ? data.yardNote : {},
        })
      }
      if(data.driverNote && Object.keys(data.driverNote).length != 0){
        this.setState({
          driverNote : data.driverNote ? data.driverNote : {},
        })
      }
      this.setState({isLoading :false});
    });;

  }

  handleNoteActive(i) {
    this.setState({
      isNoteActive: i,
    });
  }

  handleNoteNull() {
    this.setState({
      isNoteActive: null,
    });
  }

  changeHandler(e) {
    const note = this.state[e.target.name];
    note.note = e.target.value;
    this.setState({ [e.target.name] : note });
  }

  editNotes(id) {
    let data = {};
    data.reference_number = this.props.selectedLoads.reference_number;
    data[`${id}`] = this.state[`${id}`].note;
    HTTP("post", "tms/saveLoadNotes", data, {
      authorization: getStorage("token"),
    }).then((res) => {
      this.notesDetail()
      toastr.show("Notes have been added.", "success");
      this.setState({
        isNoteActive: null,
      });
    });
  }
  renderReturn = () => {
    const timeZone = getStorage("timeZone");
    return (
      <React.Fragment>
        <div className="tab">
          {this.state.isLoading &&
            <LoaderBar></LoaderBar>
          }
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 1 ? "active  " : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive(1)}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconTruck className="mr-10" />
                  <h5 className="font-16 mb-0">Driver</h5>
                </div>
                <div className="d-flex">
                  {this.state.driverNote.createdAt && this.state.driverNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.driverNote.createdBy} at {moment(this.state.driverNote.createdAt).tz(timeZone).format(this.userTimeFormat)}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.driverNote.note ? this.state.driverNote.note : ""}
                  placeholder="Notes for driver"
                  name="driverNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === 1 && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.driver}
                  onClick={() => this.editNotes("driverNote")}
                >
                  Add Note
                </button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 2 ? "active  " : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive(2)}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconBilling className="mr-10" />
                  <h5 className="font-16 mb-0">Billing</h5>
                </div>
                <div className="d-flex">
                  {this.state.billingNote.createdAt && this.state.billingNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.billingNote.createdBy} at {moment(this.state.billingNote.createdAt).tz(timeZone).format(this.userTimeFormat)}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.billingNote.note ? this.state.billingNote.note : ""}
                  placeholder="Notes for billing"
                  name="billingNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === 2 && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.billing}
                  onClick={() => this.editNotes("billingNote")}
                >
                  Add Note
                </button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 3 ? "active  " : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive(3)}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconMapMarker className="mr-10" />
                  <h5 className="font-16 mb-0">Yard</h5>
                </div>
                <div className="d-flex">
                  {this.state.yardNote.createdAt && this.state.yardNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.yardNote.createdBy} at {moment(this.state.yardNote.createdAt).tz(timeZone).format(this.userTimeFormat)}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.yardNote.note ? this.state.yardNote.note : ""}
                  placeholder="Notes for yard"
                  name="yardNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === 3 && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.yard}
                  onClick={() => this.editNotes("yardNote")}
                >
                  Add Note
                </button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 4 ? "active  " : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive(4)}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconBuilding className="mr-10" />
                  <h5 className="font-16 mb-0">Load</h5>
                </div>
                <div className="d-flex">
                  {this.state.officeNote.createdAt && this.state.officeNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.officeNote.createdBy} at {moment(this.state.officeNote.createdAt).tz(timeZone).format(this.userTimeFormat)}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.officeNote.note ? this.state.officeNote.note : ""}
                  placeholder="Notes for office"
                  name="officeNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === 4 && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.office}
                  onClick={() => this.editNotes("officeNote")}
                >
                  Add Note
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser) {
      if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" ||loggedInUser.role === "fleetcustomer") {
        return this.renderReturn();
      } else if (loggedInUser.permissions.length !== 0) {
        if (loggedInUser.permissions.includes("customer_service_notes")){ 
          return this.renderReturn();
        }
        else {return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>}
      }

      else {return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>;}
    }
    // return this.renderReturn();
  }
}
export default connect()(Notes);
