import React, { Component } from 'react'
import CustomerAccessManagement from '../../../../carrier/CustomerAccessManagement'
import AuditCustomerComponent from './AuditCustomerComponent'
import CustomerComponent from './CustomerComponent'
import DocumentCustomer from './DocumentCustomer'
import EmailNotificationComponent from './EmailNotificationComponent'
import GeofenceCustomerComponent from './GeofenceCustomerComponent'
import NotesCustomerComponent from './NotesCustomerComponent'

export default class EditCustomerComponent extends Component {
    render() {
        return (
            <div className='overflow-auto' style={{ height: "calc(100vh - 185px)" }}>
                {this.props.tab === 1 && (
                <CustomerComponent
                    isQuickAdd={this.props.isQuickAdd}
                    tab={this.props.tab}
                    addCustomer={this.props.addCustomer}
                    Selected_customer={this.props.Selected_customer}
                    updateCustomer={this.props.updateCustomer}
                    AllProps={this.props.AllProps}
                    AllState={this.props.AllState}
                />
                )}
                {this.props.tab === 2 && (
                    <DocumentCustomer
                        Selected_customer={this.props.Selected_customer}
                    />
                )}
                {this.props.tab === 3 && (
                    <GeofenceCustomerComponent
                        tab={this.props.tab}
                        Selected_customer={this.props.Selected_customer}
                    />
                )}
                {this.props.tab === 4 && (
                    <NotesCustomerComponent
                        updateCustomer={this.props.updateCustomer}
                        Selected_customer={this.props.Selected_customer}
                    />
                )}
                {this.props.tab === 5 && (
                    <AuditCustomerComponent
                        Selected_customer={this.props.Selected_customer}
                    />
                )}
                {this.props.tab == 6 && (
                    <CustomerAccessManagement
                        isCustomerProfile={true}
                        CustomerID={this.props.Selected_customer._id}
                        isQuick={this.props.isQuick}
                        initialValues={this.props.Selected_customer}
                    />
                )}
                {this.props.tab === 7 && (
                    <EmailNotificationComponent
                        AllProps={this.props.AllProps}
                        tab={this.props.tab}
                        updateCustomer={this.props.updateCustomer}
                        Selected_customer={this.props.Selected_customer}
                        AllState={this.props.AllState}
                    />
                )}
            </div>
        )
    }
}
