import moment from "moment";
import { getStorage } from "../../../../../services";

export const formatDateFreeDay = (date) => {
  if (date) {
    return moment(date).tz(getStorage("timeZone")).format("MM/DD");
  } else {
    return "";
  }
};

export const formatToDateAndTime = (date) => {
  if (date) {
    return moment(date).tz(getStorage("timeZone")).format("MM/DD HH:mm");
  } else {
    return "";
  }
};

export const colorStatusBased = function (status) {
  let color = "red";
  if (status === "PENDING") {
    color = "#ff8099";
  } else if (status === "AVAILABLE") {
    color = "#bed96c";
  } else if (status === "DROPCONTAINER_DEPARTED") {
    color = "#c791d9";
  } else {
    color = "#A5DB03";
  }
  return color;
};

export const isAtBottom = ({ currentTarget }) => {
  return !(
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
};

export const BoxPosition = (Size, boxRef) => {
  const { x, y } = Size.current.parentNode.getBoundingClientRect();
  const clickX = x;
  const clickY = y;
  const screenW = window.innerWidth;
  const screenH = window.innerHeight;
  const rootW = boxRef.current.offsetWidth;
  const rootH = 200;

  const right = screenW - clickX > rootW;
  const left = !right;
  const top = screenH - clickY > rootH;
  const bottom = !top;
  const style = {
    position: "absolute",
    transition: "none",
    opacity: 1,
  };
  if (right) {
    style.left = `${clickX + 5}px`;
  }

  if (left) {
    style.left = `${clickX - rootW - 5}px`;
  }

  if (top) {
    style.top = `${clickY + 5}px`;
  }

  if (bottom) {
    style.top = `${clickY - rootH - 5}px`;
  }

  return style;
};

const REGEXP = /^$/;
export const IsEmptyString = (text) => {     
    return REGEXP.test(text);
};

export const CompareStrings = (o,n) => {
  if (o.length !== n.length) {
    return false;
  }
  return o.localeCompare(n) === 0;
};

export const generateStatusColor = (status) => {
  switch(status) {
    case "CONFIRMED":
      return "success"
    case "DECLINED":
      return "status-dark"
    case "PENDING":
      return "warning"
    case "TENTATIVE":
      return "primary"
    case "CANCELLED":
      return "brown"
    case "REJECTED":
      return "danger"
    default:
      break
  }
}