import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { smallSelectStyle } from '../../../../../../assets/js/select-style';
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { editTMSCustomer, getFleetCustomers } from '../../../../services';
import { directFactoryFlags } from '../../../../Load/utility';
import _ from 'lodash';
import { getCustomers } from '../../../../Dispatcher/actionCreators';
import { onChangeErrorColorResponse, onChangeSuccessColorResponse  } from '../../utility';
import { toastr } from '../../../../../../services';

const PreferencesCustomerComponent = ({ Selected_customer, updateCustomer, AllProps }) => {
    const [fleetCustomerdata, setFleetCustomerdata] = useState([]);
    const [fleetCustomer, setFleetCustomer] = useState(Selected_customer.fleetCustomer ? Selected_customer.fleetCustomer : []);
    const [payType, setPayType] = useState(Selected_customer.payType ? Selected_customer.payType : "")
    const [canEditLoad, setCanEditLoad] = useState(Selected_customer.canEditLoad ? Selected_customer.canEditLoad : false);
    const [isTIROptional, setIsTIROptional] = useState(Selected_customer.isTIROptional ? Selected_customer.isTIROptional : false);
    const [defaultSubCustomer, setDefaultSubCustomer] = useState([])
    const subcustomerOptions = Selected_customer.extraSubCustomer.map((e) => ({ label: e.company_name, value: e._id }))
    const [subcustomer, setSubCustomer] = useState(subcustomerOptions ? subcustomerOptions : [])
    const [customerTags, setCustomerTags] = useState(Selected_customer.customerTags ? Selected_customer.customerTags : [])
    const [allCustomerTags , setAllCustomerTags] = useState([])
    const YesOrNo = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    useEffect(() => {
        getAllFleetCustomers();
        getCustomerOptions();
       AllProps.actions.getCustomerTags().then((tags) => {
            let allTags = tags.map((tag) => ({ label: tag.tag, value: tag.tag }));
            setAllCustomerTags(allTags)
          });
    }, [])
    let freeze = false; //mark delay
    let timer; //saved timer

    const getAllFleetCustomers = () => {
        getFleetCustomers()
            .then((data) => {
                if (data.length !== 0) {
                    let id = Selected_customer.fleetCustomer;
                    setFleetCustomerdata(data);
                    let val = data.filter((d) => d.value === id);
                    setFleetCustomer(val)
                }
            })
            .catch((error) => {
                console.log("Err", error)
            });
    }
    const onChangeHandler = (key, value) => {
        if (Selected_customer[key] == value) return;
        let params = {
            _id: Selected_customer._id,
            [key]: value
        }
        editTMSCustomer(params).then((result) => {
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse(key)
        }).catch((err) => {
            console.log("Err", err)
            onChangeErrorColorResponse(key)
        })
    }

    const getCustomerOptions = (search) => {
        if (!search) {
            return new Promise((resolve, reject) => {
                getCustomers(null, 10).then((options) => {
                    const allDatas = options.filter(
                        (op) => op.value !== Selected_customer._id
                    );
                    const subCustomers = [];
                    allDatas.forEach((obj) => {
                        subCustomers.push({
                            value: obj._id,
                            label: `${obj.company_name}, ${obj.address ? obj.address.address : ""
                                }`,
                            allInfos: obj,
                        });
                    });
                    setDefaultSubCustomer(subCustomers)
                    resolve(defaultSubCustomer);
                });
            });
        }
        return new Promise((resolve, reject) => {
            getCustomers(search).then((options) => {
                const result = options.filter(
                    (op) => op.value !== _.get(this.props, "Selected_customer._id")
                );
                resolve(result);
            });
        });
    };

    const addCustomerTags = (values) => {
        const formData = new FormData();
        formData.append("tag", values);
        setCustomerTags([...customerTags, values])
            AllProps.actions.addCustomerTags(formData)
            .then(() => {
                toastr.show("Customer Tags Add", "success");
                onChangeSuccessColorResponse('customerTags')
            })
            .catch((err) => { });
        }
   const _getCustomerTags = async (strSearch) => {
         freeze = true;
        return new Promise(async (res, err) => {
          let p = new Promise((res, err) => {
            if (freeze) clearTimeout(timer);
             timer = setTimeout(async () => {
              freeze = false;
              const r = await AllProps.actions.getCustomerTags({
                searchText: strSearch,
              });
              res(r.map((d) => ({ label: d.tag, value: d.tag })));
            }, 1000);
          });
    
          p.then(function (x) {
            res(x);
          });
        });
      };
    return (
        <div>
            <React.Fragment>
                <div class="hr-light my-20"></div>
                <div className="font-medium font-14 mb-15">Preferences</div>
                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Fleet Customer</label>
                            <Select
                                isClearable
                                styles={smallSelectStyle}
                                options={fleetCustomerdata}
                                value={fleetCustomer}
                                onChange={(val) => {
                                    setFleetCustomer(val)
                                    onChangeHandler("fleetCustomer", val.value)
                                }}
                                className="fleetCustomer"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Sub Customer</label>
                            <AsyncSelect
                                menuPlacement="top"
                                isMulti
                                styles={smallSelectStyle}
                                defaultOptions={defaultSubCustomer}
                                loadOptions={getCustomerOptions}
                                value={subcustomer}
                                onChange={(val) => {
                                    setSubCustomer(val)
                                    onChangeHandler("extraSubCustomer", val.map((el) => el.value))
                                }}
                                className="extraSubCustomer"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Pay Type
                            </label>
                            <Select
                                options={directFactoryFlags}
                                styles={smallSelectStyle}
                                menuPlacement="top"
                                value={directFactoryFlags.find((el) => el.value === payType)}
                                onChange={(val) => {
                                    setPayType(val.value)
                                    onChangeHandler("payType", val.value)
                                }}
                                className={"payType"}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Customer Tags</label>
                            <AsyncCreatableSelect
                                menuPlacement="top"
                                cacheOptions
                                isMulti
                                styles={smallSelectStyle}
                                formatCreateLabel={(inputValue) =>
                                    `Add "${inputValue}"`   
                                }
                                onCreateOption={(value) => addCustomerTags(value)}
                                onChange={(value) => {
                                    setCustomerTags(value.map((el)=>el.value))
                                    onChangeHandler("customerTags",value.map((el)=>el.value))
                                }}
                                loadOptions={_getCustomerTags}
                                defaultOptions={allCustomerTags}
                                value={customerTags.map((d) => ({
                                    label: d,
                                    value: d,
                                  }))}
                                  className="customerTags"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Can Edit Load
                            </label>
                            <Select
                                options={YesOrNo}
                                styles={smallSelectStyle}
                                name={"canEditLoad"}
                                className={"canEditLoad"}
                                onChange={(val) => {
                                    setCanEditLoad(val.value)
                                    onChangeHandler("canEditLoad", val.value)
                                }}
                                value={YesOrNo.find((el) => el.value === canEditLoad)}
                                id={"canEditLoad"}
                                menuPlacement="top"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Tir Optional
                            </label>
                            <Select
                                options={YesOrNo}
                                styles={smallSelectStyle}
                                name={"isTIROptional"}
                                className={"isTIROptional"}
                                onChange={(val) => {
                                    setIsTIROptional(val.value)
                                    onChangeHandler("isTIROptional", val.value)
                                }}
                                value={YesOrNo.find((el) => el.value === isTIROptional)}
                                id={"isTIROptional"}
                                menuPlacement="top"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

export default PreferencesCustomerComponent