import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
  import { Link } from "react-bootstrap/lib/Navbar";

const LogoutButton = ({handleClick}) => {
  const { logout } = useAuth0();

  return (
    <Link
    className="sidebar-link sidebar-link pointer"
    onClick={() =>{
    handleClick()
    setTimeout(()=>{
      logout({ returnTo: window.location.origin })
    },2500)
    
  }}
  >
    <i className="uil uil-sign-out-alt"></i>
    <span className="hide-menu">Log Out</span>
  </Link>
  );
};

export default LogoutButton;