import AES from "crypto-js/aes";
import Utf8 from 'crypto-js/enc-utf8'
import moment from "moment";
import { toastr } from "../../services/Common.services";
import _ from "lodash";
import { HTTP } from "../../services/Http.service";
import { getStorage } from "../../services/Common.services";
import { loadEvents, tmsDrops } from "../../pages/tms/constant";

var Enumerable = require("linq");
const Buffer = require("buffer/").Buffer;

export function beautifyDate(date, formatType) {
  return moment(new Date(date)).format(formatType);
}

export function getDistanceFromLatLonMile(lat1, lon1, lat2, lon2) {
  const deg2rad = function deg2rad(deg) {
    return deg * (Math.PI / 180);
  };
  var R = 3959; // Radius of the earth in mile
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in mile
  return d;
}

export function sortShipmentsToClosetPickupTime(shipments) {
  var diffdate = new Date();

  let sortedShipmetns = shipments.sort(function (a, b) {
    var distancea = Math.abs(diffdate - new Date(a.pickupDateTimeFrom));
    var distanceb = Math.abs(diffdate - new Date(b.pickupDateTimeFrom));
    if (
      new Date(a.pickupDateTimeFrom) < diffdate &&
      new Date(b.pickupDateTimeFrom) >= diffdate
    ) {
      return -1;
    }
    if (
      new Date(a.pickupDateTimeFrom) >= diffdate &&
      new Date(b.pickupDateTimeFrom) < diffdate
    ) {
      return 1;
    }
    return distancea - distanceb; // sort a before b when the distance is smaller
  });
  return sortedShipmetns;
}

export function copyToClipboard(_dataString) {
  document.addEventListener("copy", function (e) {
    if (_dataString !== null) {
      try {
        e.clipboardData.setData("text/plain", _dataString);
        e.preventDefault();
      } finally {
        toastr.show("Copied to your clipboard!", "success");
        _dataString = null;
      }
    }
  });
  document.execCommand("copy");
}

export const objCycle = {
  "70_8": "USA . 70 Hour / 8 Day",
  "60_7": "USA . 60 Hour / 7 Day",
  "80_8": "California . 80 Hour / 8 Day",
  tx_70_7: "Texas 70 Hour / 7 Day",
  ak_70_7: "Alaska 70 Hour / 7 Day",
  ak_80_8: "Alaska 80 Hour / 8 Day",
  "70_7": "Canada South 70 Hour / 7 Day",
  "120_14": "Canada South 120 Hour / 14 Day",
  canada_oil: "Canada South Oil and Gas",
  "80_7": "Canada North 80 Hour / 7 Day (Cycle 1)",
  "120_14_north": "Canada North 120 Hour / 14 Day (Cycle 2)",
  Other: "Other",
};

export function hourDifference(startdate, enddate) {
  let difference = moment(enddate).diff(startdate, "minutes");
  let h = Math.floor(difference / 60);
  let m = difference % 60;
  if (m == 59) {
    h += 1;
    m = 0;
  }
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  return h + ":" + m;
}

export function isoToUTCDate(isodate, time = null) {
  if (time != null)
    return new Date(
      moment(new Date(isodate))
        .add(time, "seconds")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss")
    );

  return new Date(
    moment(new Date(isodate)).utc().format("YYYY-MM-DD HH:mm:ss")
  );
}

export function getOnedayErrors(currentDay, nextDay) {
  let allErrors = [];
  let combinedLog = [];
  let isNeedToConsiderInPrevDay = true;
  var total_DriveHoursInDay = 0;
  var total_OnHoursInDay = 0;
  currentDay.logs.forEach((_log, k) => {
    if (["ON", "D"].indexOf(_log.status) > -1) {
      _log["combinedStatus"] = "ON-D";
    } else {
      _log["combinedStatus"] = "OFF-SL";
    }
    combinedLog.push(_log);
  });
  if (nextDay && nextDay.logs) {
    nextDay.logs.forEach((_log, k) => {
      let lastAddedLog = combinedLog[combinedLog.length - 1];
      if (["ON", "D"].indexOf(_log.status) > -1) {
        _log["combinedStatus"] = "ON-D";
      } else {
        _log["combinedStatus"] = "OFF-SL";
      }
      if (lastAddedLog) {
        if (_log.combinedStatus == lastAddedLog.combinedStatus) {
          combinedLog.push(_log);
        }
      }
    });
  }

  let consiCutiveON_D_Hours = 0;
  let partialOFFSLBreak = 0;

  combinedLog.forEach((log, k) => {
    let endDateTime = moment(log.day).tz("UTC").endOf("day")._d;
    if (
      log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    let duration = moment(log.timeEnd || endDateTime).diff(
      log.timeStart,
      "minutes"
    );
    if (log.combinedStatus == "ON-D") {
      consiCutiveON_D_Hours = consiCutiveON_D_Hours + duration;
    } else {
      partialOFFSLBreak += duration;
      if (partialOFFSLBreak >= 30) {
        consiCutiveON_D_Hours = 0;
        partialOFFSLBreak = 0;
      }
    }
    if (consiCutiveON_D_Hours > 8 * 60) {
      allErrors.push("Break Limit Exceeded");
    }
  });

  combinedLog.forEach((_log, k) => {
    let endDateTime = moment(_log.day).tz("UTC").endOf("day")._d;
    if (
      _log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    let duration = moment(_log.timeEnd || endDateTime).diff(
      _log.timeStart,
      "minutes"
    );
    if (_log.status == "ON") {
      total_OnHoursInDay += duration;
    }
    if (_log.status == "D") {
      total_DriveHoursInDay += duration;
    }
  });

  if (total_DriveHoursInDay > 11 * 60) {
    allErrors.push("Exceeded 11 Hours Driving Limit");
  }
  if (total_OnHoursInDay + total_DriveHoursInDay > 14 * 60) {
    allErrors.push("Exceeded 14 Hours Shift Limit");
  }
  if (currentDay.day != moment().tz("UTC").startOf("day").toISOString()) {
    if (currentDay.reviewLogs == undefined) {
      allErrors.push("Review Logs Form is not filled up");
    } else {
      if (currentDay.reviewLogs.signature == undefined) {
        allErrors.push("Logs are not Signed");
      }
    }
  }
  var uniqueErrors = allErrors.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueErrors;
}

export function getOneDayCycleLogs(allLogs, day) {
  let matchFound = false;
  let combinedLog = [];
  allLogs.forEach((dayLog, k) => {
    if (!matchFound) {
      matchFound = dayLog.day === day;
    }
    if (matchFound) {
      dayLog.logs.forEach((_row) => {
        const _log = Object.assign({}, _row);
        if (["ON", "D"].indexOf(_log.status) > -1) {
          _log.combinedStatus = "ON-D";
        } else {
          _log.combinedStatus = "OFF-SL";
        }
        combinedLog.push(_log);
      });
    }
  });
  combinedLog = combinedLog.sort((a, b) => {
    return moment(b.day)._d - moment(a.day)._d;
  });
  let continousOFFSLMinutes = 0;
  let lastIndex = -1;
  combinedLog.forEach((log, k) => {
    let endDateTime = moment(log.day).tz("UTC").endOf("day")._d;
    if (
      log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    const duration = moment(log.timeEnd || endDateTime).diff(
      log.timeStart,
      "minutes"
    );
    if (log.combinedStatus === "ON-D") {
      continousOFFSLMinutes = 0;
    } else {
      continousOFFSLMinutes += duration;
    }
    if (continousOFFSLMinutes >= 10 * 60 && lastIndex === -1) {
      lastIndex = k;
    }
  });
  return combinedLog.splice(0, lastIndex + 1);
}

export function getOnedayCycleErrors(cycleLogs, currentDay) {
  const allErrors = [];
  const isNeedToConsiderInPrevDay = true;
  var total_DriveHoursInDay = 0;
  var total_OnHoursInDay = 0;

  let consiCutiveON_D_Hours = 0;
  let partialOFFSLBreak = 0;

  cycleLogs.forEach((log, k) => {
    let endDateTime = moment(log.day).tz("UTC").endOf("day")._d;
    if (
      log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    const duration = moment(log.timeEnd || endDateTime).diff(
      log.timeStart,
      "minutes"
    );
    if (log.combinedStatus == "ON-D") {
      consiCutiveON_D_Hours += duration;
    } else {
      partialOFFSLBreak += duration;
      if (partialOFFSLBreak >= 30) {
        consiCutiveON_D_Hours = 0;
        partialOFFSLBreak = 0;
      }
    }
    if (consiCutiveON_D_Hours >= 8 * 60) {
      allErrors.push("Break Limit Exceeded");
    }
  });

  cycleLogs.forEach((_log, k) => {
    let endDateTime = moment(_log.day).tz("UTC").endOf("day")._d;
    if (
      _log.day ==
      moment()
        .add("minutes", moment().utcOffset())
        .tz("UTC")
        .startOf("day")
        .toISOString()
    ) {
      endDateTime = new Date();
    }
    const duration = moment(_log.timeEnd || endDateTime).diff(
      _log.timeStart,
      "minutes"
    );
    if (_log.status == "ON") {
      total_OnHoursInDay += duration;
    }
    if (_log.status == "D") {
      total_DriveHoursInDay += duration;
    }
  });

  if (total_DriveHoursInDay >= 11 * 60) {
    allErrors.push("Exceeded 11 Hours Driving Limit");
  }
  if (total_OnHoursInDay + total_DriveHoursInDay >= 14 * 60) {
    allErrors.push("Exceeded 14 Hours Shift Limit");
  }
  if (currentDay.day != moment().tz("UTC").startOf("day").toISOString()) {
    if (currentDay.reviewLogs == undefined) {
      allErrors.push("Review Logs Form is not filled up");
    } else if (currentDay.reviewLogs.signature == undefined) {
      allErrors.push("Logs are not Signed");
    }
  }
  var uniqueErrors = allErrors.filter((v, i, a) => a.indexOf(v) === i);
  return uniqueErrors;
}

const statusColors = {
  CHASSISPICK_ARRIVED: "normal",
  CHASSISPICK_DEPARTED: "normal",
  PULLCONTAINER_ARRIVED: "normal",
  PULLCONTAINER_DEPARTED: "normal",
  DROPCONTAINER_ARRIVED: "normal",
  DROPCONTAINER_DEPARTED: "blue",
  HOOKCONTAINER_ARRIVED: "normal",
  HOOKCONTAINER_DEPARTED: "normal",
  DELIVERLOAD_ARRIVED: "normal",
  DELIVERLOAD_DEPARTED: "normal",
  RETURNCONTAINER_ARRIVED: "normal",
  RETURNCONTAINER_DEPARTED: "normal",
  CHASSISTERMINATION_ARRIVED: "normal",
  CHASSISTERMINATION_DEPARTED: "normal",
  FULL_PAID: "dark-brown",
  PARTIAL_PAID: "light-brown",
  COMPLETED: "dark",
  PENDING: "pending",
  REBILLING: "brown",
  APPROVED: "normal",
  UNAPPROVED: "unapproved",
  BILLING: "brown",
  AVAILABLE: "green",
  DISPATCHED: "purple",
};

export function convertTMSStatusName(
  type_of_load,
  status = "",
  info,
  isDriverList,
  needColor = false,
  toUpperCase = true
) {
  let colorStatus = statusColors[status];
  let labelStatus = status;
  const isLiftOff = info?.driverOrderId?.type === loadEvents.LIFTOFF;
  const isLiftOn = info?.driverOrderId?.type === loadEvents.LIFTON;

  if (type_of_load === "IMPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute to Chassis";
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived to Chassis";
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Pick Container";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Pick Container";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOff ? "Enroute to Lift Off" : "Enroute to Drop Container";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOn ? "Enroute to Lift On" : "Enroute to Hook Container";
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOn ? "Arrived to Lift On" : "Arrived to Hook Container";
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Deliver Load";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Deliver Load";
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Return Empty";
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Return Empty";
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute to Return Chassis";
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived to Return Chassis";
    }
    if (status === "DISPATCHED") {
      labelStatus = "Dispatched";
    }
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
    if (status === "COMPLETED" && isDriverList) {
      labelStatus = "RETURN";
    }
    if (status === "PENDING" && info && (info.custom || info.freight)) {
      labelStatus = "PENDING";
    }
  } else if (type_of_load === "EXPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = "Enroute to Chassis";
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = "Arrived to Chassis";
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Pick Container";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Pick Container";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOff ? "Enroute to Lift Off" : "Enroute to Drop Container";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOn ? "Enroute to Lift On" : "Enroute to Hook Container";
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOn ? "Arrived to Lift On" : "Arrived to Hook Container";
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Get Loaded";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Get Loaded";
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Return Load";
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Return Load";
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = "Enroute to Return Chassis";
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = "Arrived to Return Chassis";
    }
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
  } else if (type_of_load === "ROAD") {
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = "Enroute to Pick Up";
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = "Arrived at Pick Up";
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = isLiftOff ? "Enroute to Lift Off" : "Enroute to Drop Trailer";
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = isLiftOff ? "Grounded" : "Dropped";
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = isLiftOn ? "Enroute to Lift On" : "Enroute to Hook Trailer";
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = isLiftOn ? "Arrived to Lift On" : "Arrived to Hook Trailer";
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = "Enroute to Deliver Load";
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = "Arrived at Deliver Load";
    }
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
  } else if (type_of_load === "BILL_ONLY") {
    if (status === "FULL_PAID") {
      labelStatus = "Full Paid";
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = "Partial Paid";
    }
  }
  if (needColor) {
    return {
      status: toUpperCase
        ? labelStatus.toUpperCase()
        : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
            .slice(1)
            .toLowerCase()}`,
      colorStatus: colorStatus,
    };
  } else {
    return toUpperCase
      ? labelStatus.toUpperCase()
      : `${labelStatus.charAt(0).toUpperCase()}${labelStatus
          .slice(1)
          .toLowerCase()}`;
  }
}

export function statusOrderAddressFormat(obj, address) {
  if (address && obj && (obj.customerId || obj.locationId)) {
    if (obj.customerId) {
      address = `${obj.customerId.company_name}, ${address}`;
    } else if (obj.locationId) {
      address = `${obj.locationId.company_name}, ${address}`;
    }
  }
  return address;
}

export function deepClone(nestedObject) {
  const value = _.cloneDeep(nestedObject);
  // const value = Object.assign({}, nestedObject);
  // const value = JSON.parse(JSON.stringify(nestedObject));;
  return value;
}

export function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function isEmptyOrLoaded(info, index = -1) {
  let status;
  let customer;
  let LoadedIndex = -1;
  if (info.driverOrderId) {
    const activeStatusIndex = info.activeIndex || -1;
    const activeDriverOrderId = info.driverOrder[activeStatusIndex];
    const prevDriverOrderId = info.driverOrder[activeStatusIndex - 1];
    if (
      activeDriverOrderId &&
      prevDriverOrderId &&
      [
        "DROPCONTAINER_DEPARTED",
        "HOOKCONTAINER_ARRIVED",
        "HOOKCONTAINER_DEPARTED",
      ].indexOf(info.status) > -1
    ) {
      if (["EXPORT", "IMPORT"].indexOf(info.type_of_load) > -1) {
        if (activeDriverOrderId.prevType === "PULLCONTAINER") {
          status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
        } else if (
          activeDriverOrderId.prevType === "DELIVERLOAD" &&
          activeDriverOrderId.customerId &&
          prevDriverOrderId.customerId &&
          prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
        ) {
          // if(info.isReadyForPickup){
          //   status = info.type_of_load === 'IMPORT' ? 'loaded' : 'empty';
          // } else {
          status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
          // }
        } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
          if (info.isReadyForPickup) {
            status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
          } else {
            status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
          }
        }
      } else if (
        [
          "DROPCONTAINER_DEPARTED",
          "HOOKCONTAINER_ARRIVED",
          "HOOKCONTAINER_DEPARTED",
        ].indexOf(info.status) > -1 &&
        ["ROAD"].indexOf(info.type_of_load) > -1
      ) {
        if (activeDriverOrderId.prevType === "PULLCONTAINER") {
          status = "loaded";
        } else if (
          activeDriverOrderId.prevType === "DELIVERLOAD" &&
          activeDriverOrderId.customerId &&
          prevDriverOrderId.customerId &&
          prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
        ) {
          status = "empty";
        } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
          status = "loaded";
        }
      }
    }
    if (
      activeDriverOrderId &&
      activeDriverOrderId.customerId &&
      activeDriverOrderId.customerId.company_name
    ) {
      customer = activeDriverOrderId.customerId.company_name;
    }
  }
  if (window.location.search === "?pickup") {
    if (status === "Loaded" && LoadedIndex === -1) {
      LoadedIndex = index;
    }
  } else if (window.location.search === "?orderStatus") {
    if (info.driver && LoadedIndex === -1) {
      LoadedIndex = index;
    }
  }
  return { customer, status, LoadedIndex };
}

export function convertTMSStatusToDriverLocation(
  load,
  activeDriverOrder,
  toUpperCase = false
) {
  const type_of_load = load.type_of_load;
  const status =
    ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(load.status) >
      -1 && !load.isLive
      ? "DROPCONTAINER_DEPARTED"
      : load.status;
  const info = load;
  const driverLocation =
    activeDriverOrder &&
    activeDriverOrder.company_name &&
    activeDriverOrder.company_name.toUpperCase();

  let labelStatus = status;
  if (type_of_load === "IMPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DISPATCHED") {
      labelStatus = `Dispatched`;
    }
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
    if (status === "COMPLETED") {
      labelStatus = `RETURN`;
    }
    if (status === "PENDING" && info && (info.custom || info.freight)) {
      labelStatus = `PENDING`;
    }
  } else if (type_of_load === "EXPORT") {
    if (status === "CHASSISPICK_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISPICK_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "RETURNCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "CHASSISTERMINATION_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
  } else if (type_of_load === "ROAD") {
    if (status === "PULLCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "PULLCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DROPCONTAINER_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "HOOKCONTAINER_DEPARTED") {
      labelStatus = `Arrived to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_ARRIVED") {
      labelStatus = `Enroute to ${driverLocation}`;
    }
    if (status === "DELIVERLOAD_DEPARTED") {
      labelStatus = `Arrived at ${driverLocation}`;
    }
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
  } else if (type_of_load === "BILL_ONLY") {
    if (status === "FULL_PAID") {
      labelStatus = `Full Paid`;
    }
    if (status === "PARTIAL_PAID") {
      labelStatus = `Partial Paid`;
    }
  }

  return toUpperCase ? labelStatus.toUpperCase() : labelStatus;
}
export const encryptAndGenerateLiveShareCode = (
  referenceNumber,
  isoTimeString
) => {
  const secretKey = process.env.REACT_APP_LIVE_SHARE_SECRET_KEY;
  const liveShareURL = process.env.REACT_APP_LIVE_SHARE_URL;

  const plainText = `${referenceNumber};${isoTimeString}`;
  const cipherText = AES.encrypt(plainText, secretKey);
  const base64String = cipherText.toString();
  const hexString = Buffer.from(base64String, "base64").toString("hex");

  /**
   * const decryption = AES.decrypt(Buffer.from(hexString, "hex").toString("base64"), secretKey);
   * console.log("Decrypted hax method: ", decryption.toString(utf8));
   */

  return `${liveShareURL}?code=${hexString}`;
};


export const getDocumentTypesByType = (profileType) => {
  let url = `tms/getDocumentTypesByType?profileType=${profileType}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const encryptString = (data) => {
  const secretKey = process.env.REACT_APP_LIVE_SHARE_SECRET_KEY;
  return AES.encrypt(data, secretKey).toString();
}
export const descryptString = (data) => {
  const secretKey = process.env.REACT_APP_LIVE_SHARE_SECRET_KEY;
  return AES.decrypt(data, secretKey).toString(Utf8)
}


export const createLegsFromDriverOrder = (driverOrder) => {
  const driverLegs = [],
    legIndexes = [0];
  let current = 0,
    lastPos = 0;
  while (current < driverOrder.length) {
    if ( tmsDrops.includes(driverOrder[current].type)) {
      driverLegs.push(driverOrder.slice(lastPos, current + 1));
      lastPos = current + 1;
      legIndexes.push(lastPos);
    }
    current++;
  }
  driverLegs.push(driverOrder.slice(lastPos, current + 1));
  return { driverLegs, legIndexes };
};

export const getAppointmentDateForDriverOrder = (load, driverOrder) => {
  const userTimeZone = getStorage("timeZone");
  const isPullContainer = driverOrder.type === "PULLCONTAINER";
  const isDelivery = driverOrder.type === "DELIVERLOAD";
  const isReturn = driverOrder.type === "RETURNCONTAINER";

  if (isPullContainer && (load.pickupTimes?.length ?? 0) > 0 && load.pickupTimes[0]?.pickupFromTime) {
    const pickupTime = load.pickupTimes[0]?.pickupFromTime;

    return moment(pickupTime).tz(userTimeZone).format("MM/DD HH:mm");
  }

  if (isDelivery && (load.deliveryTimes?.length ?? 0) > 0 && load.deliveryTimes[0]?.deliveryFromTime) {
    const deliveryTime = load.deliveryTimes[0]?.deliveryFromTime;

    return moment(deliveryTime).tz(userTimeZone).format("MM/DD HH:mm");
  }

  if (isReturn && load.returnFromTime) {
    const returnTime = load.returnFromTime;

    return moment(returnTime).tz(userTimeZone).format("MM/DD HH:mm");
  }

  return "";
}

export const etaFormatter = (date) => {
  const duration = moment.duration(moment(date).diff(moment()));

  const dayFormat =
    parseInt(duration.asDays()) > 0
      ? `${parseInt(duration.asDays()).toString().padStart(2, "0")} days `
      : "";
  const hrFormat = `${parseInt(duration.asHours() % 24)
    .toString()
    .padStart(2, "0")} hr `;
  const minFormat = `${parseInt(duration.asMinutes() % 60)
    .toString()
    .padStart(2, "0")} min`;

  const formattedCountdown = `${dayFormat}${hrFormat}${minFormat}`;

  return formattedCountdown;
};

export const getAssignedDriverOrders = (load, driverId) => {
  let tempAssingedDriverOrders = [];
  if (load && load.driverOrder) {
    for (let driverOrderIndex in load.driverOrder) {
      if (
        (load.driverOrder[driverOrderIndex]?.driver?._id ??
          load.driverOrder[driverOrderIndex]?.driver) === driverId
      ) {
        tempAssingedDriverOrders.push(load.driverOrder[driverOrderIndex]);
      }
    }
  }
  return tempAssingedDriverOrders;
};


export const getTotalEmailStatusReportRowCount = async () => {
  let url = 'email-audit/getTotalEmailStatusReportRowCount';
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getAllSenderEmails = async () => {
  let url = 'email-audit/getAllSenderEmails';
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getEmailStatusReport = async (sender, status, offset) => {
  let url = `email-audit/getEmailStatusReport?sender=${sender}&status=${status}&offset=${offset}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getEmailStatusReportBySender = async (senderEmail) => {
  let url = 'email-audit/getEmailStatusReportBySender?sender=' + senderEmail;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getEncodedMailAuditHref = async (options) => {
  const { startDate, endDate, status, size } = options;

  let url = `email-audit/generateJson2CsvHref?startDate=${startDate}&endDate=${endDate}&status=${status}&size=${size}`;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'Authorization': getStorage('token')
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  });
}
export const legTypeToHuman = (type) => {
  if(!type) {
    return "";
  }

  switch (type.toUpperCase()) {
    case "PULLCONTAINER":
      return "Pull Container";
    case "CHASSISPICK" :
      return "Chassis Pick";
    case "DELIVERLOAD":
      return "Deliver Load";
    case "RETURNCONTAINER":
      return "Return Container";
    case "DROPCONTAINER":
      return "Drop Container";
    case "CHASSISTERMINATION":
      return "Chassis Termination";
    case "COMPLETED":
      return "Completed"; 
    case "HOOKCONTAINER":
      return "Hook Container";
    default:
      return type;
  }
}

export const getAppointmentDateForDriverOrderWithLabel = (
  load,
  driverOrder
) => {
  const userTimeZone = getStorage("timeZone");
  const isPullContainer = driverOrder.type === "PULLCONTAINER";
  const isDelivery = driverOrder.type === "DELIVERLOAD";
  const isReturn = driverOrder.type === "RETURNCONTAINER";

  if (
    isPullContainer &&
    (load.pickupTimes?.length ?? 0) > 0 &&
    load.pickupTimes[0]?.pickupFromTime
  ) {
    const pickupTime = load.pickupTimes[0]?.pickupFromTime;

    return {
      label: "Pickup: ",
      date: moment(pickupTime).tz(userTimeZone).format("MM/DD"),
      time: moment(pickupTime).tz(userTimeZone).format("HH:mm"),
      type: "pickup",
    };
  }

  if (
    isDelivery &&
    (load.deliveryTimes?.length ?? 0) > 0 &&
    load.deliveryTimes[0]?.deliveryFromTime
  ) {
    const deliveryTime = load.deliveryTimes[0]?.deliveryFromTime;

    return {
      label: "Delivery: ",
      date: moment(deliveryTime).tz(userTimeZone).format("MM/DD"),
      time: moment(deliveryTime).tz(userTimeZone).format("HH:mm"),
      type: "delivery",
    };
  }

  if (isReturn && (load.returnFromTime || load.freeReturnDate)) {
    const returnTime = load.returnFromTime ?? load.freeReturnDate;

    return {
      label: "Return: ",
      date: moment(returnTime).tz(userTimeZone).format("MM/DD"),
      time: moment(returnTime).tz(userTimeZone).format("HH:mm"),
      type: "return",
    };
  }

  return { label: null, date: null, time: null, type: null };
};

export const BADGE_COLOR_CLASSES = {
  SELECTED: 'badge-status-dark',
  UPCOMING: 'badge-gray-50',
  COMPLETED: 'badge-gray-300 ',
  ACTIVE: 'badge-gray-300 bg-blue-500',
}

export const TIMELINE_COLOR_CLASSES = {
  UPCOMING: 'text-gray-100',
  COMPLETED: 'text-gray-100 ',
  ACTIVE: 'text-blue-500',
}

export const DRIVER_ORDER_STATUS = {
  COMPLETED: 'COMPLETED',
  UPCOMING: 'UPCOMING',
  ACTIVE: 'ACTIVE',
}

export const getBadgeColorForDriverOrder = (driverOrder) => {
  if(driverOrder?.arrived && driverOrder?.departed) {
    return BADGE_COLOR_CLASSES.COMPLETED;
  } else if(driverOrder?.arrived && !driverOrder?.departed) {
    return BADGE_COLOR_CLASSES.ACTIVE;
  }

  return BADGE_COLOR_CLASSES.UPCOMING;
}

export const getTimelineColorForDriverOrder = (driverOrder) => {
  if(driverOrder?.arrived && driverOrder?.departed) {
    return TIMELINE_COLOR_CLASSES.COMPLETED;
  } else if(driverOrder?.arrived && !driverOrder?.departed) {
    return TIMELINE_COLOR_CLASSES.ACTIVE;
  }

  return TIMELINE_COLOR_CLASSES.UPCOMING;
}

export const getDriverOrderStatus = (driverOrder) => {
  if(driverOrder?.arrived && driverOrder?.departed) {
    return DRIVER_ORDER_STATUS.COMPLETED;
  } else if(driverOrder?.arrived && !driverOrder?.departed) {
    return DRIVER_ORDER_STATUS.ACTIVE;
  }

  return DRIVER_ORDER_STATUS.UPCOMING;
}

export const getDriverPlannerOrderColorClass = (driverOrder, selected) => {
  const plannerColorClasses = {
    badgeColor: !selected ? getBadgeColorForDriverOrder(driverOrder): BADGE_COLOR_CLASSES.SELECTED,
    timelineColor: getTimelineColorForDriverOrder(driverOrder),
    status: getDriverOrderStatus(driverOrder),
  };

  return plannerColorClasses;
}

export const getFormattedDateTime = (date) => {
  const formattedDateTime = {
    date: null,
    time: null,
  };
  try {
    if (date) {
      const userTimeZone = getStorage("timeZone");
      formattedDateTime.date = moment(date).tz(userTimeZone).format("MM/DD");
      formattedDateTime.time = moment(date).tz(userTimeZone).format("HH:mm");
    }
  } catch (e) {
    console.error(e);
  } finally {
    return formattedDateTime;
  }
}
