import { HTTP, getStorage } from '../../../services';
import config from '../../../config';

export function getSubHeaders() {
  let url = config.wpUrl+'categories?orderby=id';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', null, null, {
        'authorization': getStorage('token'),
      },url)
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCategoryPosts(id,param1,param1Query) {
  let url = config.wpUrl+'posts?categories='+id+'&'+param1+'='+param1Query;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', null, null, {
        'authorization': getStorage('token'),
      },url)
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getPostFeaturedImage(id) {
  let url = config.wpUrl+'media/'+id;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', null, null, {
        'authorization': getStorage('token'),
      },url)
        .then((result) => {
          resolve(result.data.source_url)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getBlogsPosts(pageCount) {
  let url = config.wpUrl+`posts?per_page=10&page=${pageCount}&categories=17`;
  
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', null, null, {
        'authorization': getStorage('token'),
      },url)
        .then((result) => {
          resolve({data:result.data,
              totalPosts:Number(result.headers['x-wp-total']),
              totalPages:Number(result.headers['x-wp-totalpages'])
            })
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

// save feature request
export function saveFeatureRequest(data) {   
  let url ='tms/learningFeatureRequest'
    return new Promise((resolve, reject) => {
      HTTP('post', url, data, { 
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.status);
        })
        .catch((error) => {
          reject(error);
        });
    })
}

// get learning features
export function getLearningFeatures(data) {   
  let url ='tms/learningFeatures'
    return new Promise((resolve, reject) => {
      HTTP('post', url, data, { 
        'authorization': getStorage('token'),
      })
        .then((result) => {
          const totalPages = Math.floor(result.data.data.features.length / data.nPerPage)+1;
          resolve({features:result.data.data.features, totalPages});
        })
        .catch((error) => {
          reject(error);
        });
    })
}

// get learning features admin
export function getLearningFeaturesAdmin(data) {   
  let url ='tms/learningFeaturesAdmin'
    return new Promise((resolve, reject) => {
      HTTP('post', url, data, { 
        'authorization': getStorage('token'),
      })
        .then((result) => {
        const totalPages = Math.floor(result.data.data.features.length / data.nPerPage)+1;
          resolve({features:result.data.data.features, totalPages});
        })
        .catch((error) => {
          reject(error);
        });
    })
}