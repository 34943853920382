import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

import mySaga from '../pages/tms/saga';
import reducers from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["form"]
}

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()

const enhancers = [];

const middleware = [
  thunk,
  sagaMiddleware
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  persistedReducer,
  undefined,
  composedEnhancers
);

const persistor = persistStore(store)

export default () => {
  return { store, persistor }
}
// Running saga middleware
sagaMiddleware.run(mySaga)
