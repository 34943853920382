import currency from "currency.js";

String.prototype.toCurrencyObject = function (opts) {
  try {
    let currencyObject;

    if (opts) {
      currencyObject = currency(Number.parseFloat(this), opts);
    } else {
      currencyObject = currency(Number.parseFloat(this));
    }

    return currencyObject;
  } catch (e) {
    console.error(e);
    return null;
  }
};

String.prototype.toCurrency = function (opts) {
  try {
    return this.toCurrencyObject(opts).format();
  } catch (e) {
    console.error(e);
    return null;
  }
};