import moment from 'moment'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import DateTimePicker from './DateTimePicker'

const DateWrapper = React.memo(({ defaultDate, isShowTime, onChange, OnBlurUpdate, onRemoveDate, displayTimeZone, placeholder, hideShowSelectDate, className, disabled,inputClassName, dateFormat, timeFormat }) => {
    const [show,setShow] = useState(false)
    
    useEffect(() => {
		const keydown = (e) => {
			if (e.keyCode === 9) setShow(false);
		}
		document.addEventListener('keydown', keydown);
		return () => {
			document.removeEventListener('keydown', keydown);
		}
	}, [defaultDate]);

    return (
    <>
        <input
            className={`form-control transition-white-field ${inputClassName ? inputClassName : "" }`}
            type="text"
            disabled={disabled}
            placeholder={placeholder || "Select"}
            value={
                (defaultDate && !isShowTime)
                    ? moment(defaultDate).tz(displayTimeZone)?.format(dateFormat || "MM/DD/yyyy")
                    : (defaultDate && isShowTime)
                        ? moment(defaultDate).tz(displayTimeZone)?.format(`${timeFormat ? timeFormat : "MM/DD/yyyy hh:mm A"}`)
                        : ""
            }
            onFocus={()=> setShow(!show)}
        />
        {show && <DateTimePicker 
            onRemoveDate={onRemoveDate}
            displayTimeZone={displayTimeZone}
            OnBlurUpdate={OnBlurUpdate}
            onChange={onChange}
            isShowTime={isShowTime}
            defaultDate={defaultDate
              ? moment(defaultDate).tz(displayTimeZone)?.format("lll")
              : ""}
            hideShowSelectDate={hideShowSelectDate}
            setShow={setShow}
            className={className}
        />}
    </>
  )
}, (prevProps, nextProps) => {
    if (prevProps.defaultDate === nextProps.defaultDate) {
      return true;
    }
    return false; 
  });

export default DateWrapper