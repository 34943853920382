import React from 'react';

const index = () => {
  return (
    <div className='pro__batch'>
      <span>Pro</span>
    </div>
  )
}

export default index;
