import React, { useState } from "react";

const Avatar = (props) => {
  const { src, size, children,className } = props;
  return (
    <div className={`avatar-circle ${className}`}>
      {/* if img */}
      {src && (
        <img
          src={src}
          className={`avatar rounded-circle`}
          alt=""
          width={size ? size : 30}
        />
      )}
      {/* if no img  */}
      {
        !src &&
        <div className="avatar-circle__initial ">{children}</div>}
    </div>
  );
};

export default Avatar;
