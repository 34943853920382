import React, { useState } from 'react'
import { editTMSCustomer } from '../../../../services';
import { errorColorResponse, Required, SetRequired, successColorResponse  } from '../../utility';

const NotesCustomerComponent = ({Selected_customer, updateCustomer}) => {
    const [notes, setNotes] = useState(Selected_customer.notes ? Selected_customer.notes : "");

    const onBlurHandler = (key, val) => {
        if (Selected_customer[key] == val) return

        let data = {
            _id: Selected_customer._id,
            [key]: val
        }
        editTMSCustomer(data).then((result) => {
            successColorResponse(key)
            updateCustomer(result.data.data)
        }).catch((err) => {
            console.log("err", err)
            errorColorResponse(key)
        })
    }
    return (
        <div>
            <div className="card my-10">
                <div className="px-30 py-20">
                    <div className="d-flex align-items-center mb-20">
                        <h5 className="font-16 mb-0">Add Notes</h5>
                    </div>
                    <div style={{ height: "calc( 100% - 30px )" }}>
                        <textarea
                            rows="5"
                            className="form-control"
                            name='notes'
                            value={notes}
                            onBlur={(e) => {
                                onBlurHandler('notes', e.target.value);
                            }}
                            style={{ height: "100%" }}
                            onChange={({ target }) =>
                                setNotes(target.value)
                            }
                            id='notes'
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotesCustomerComponent