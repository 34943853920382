import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../Dispatcher/actionCreators";
import AsyncSelect from 'react-select/async';
import Select, {components} from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  getCallers,
  getShippers,
  getConsignees,
} from "../Dispatcher/actionCreators";
import AddCustomerComponent from "../Customer/AddCustomerComponent";
import { createLoad } from "../services";
import { TMS_GET_USER_LOAD } from "../constant";
import configuration from "../../../config";
import { Modal } from "react-bootstrap";
import * as $ from "jquery";
import { getStorage, toastr,
  showForTerminal,
  isTrialUser,
  disableScreen,
  addCustomerCommonFunction,
  makeOptionsForSelect,
  isNewModal, } from "../../../services/Common.services";
import {
  HTTP
} from "../../../services/Http.service";
import RossumAi from "../Dispatcher/RossumAi";
import {
  rossumAiConfirm,
  uploadLoad,
  getFleetManagersByCustomerId
} from "../services";

import { smallSelectStyle } from "../../.../../../assets/js/select-style";
import { browserHistory } from "react-router";
import { checkTMXPermission } from '../../../services/Common.services'
import { debounce } from "throttle-debounce";
import config from "../../../config";
import confirm from "../../../Components/Common/ConfirmAert";

let Selected_customer = {
  companyID: 0,
  company_name: "",
  address: "",
  lat: "",
  lng: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",
  main_contact_name: "",
  secondary_contact_name: "",
  mobile: "",
  email: "",
  billingEmail: "",
  password: "",
  credit_limit: "",
  payment_terms: "",
  account_hold: false,
  qbEmail: "",
  documents: [],
};
class AddNewLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_of_load: "IMPORT",
      caller: props.caller || [],
      shipper: props.shipper || [],
      consignee: props.consignee || [],
      temperature: undefined,
      terminal:
        props.terminals && props.terminals.length == 1
          ? { label: props.terminals[0].name, value: props.terminals[0]._id }
          : null,
      containerOwnerName: undefined,
      containerOwner: undefined,
      addCustomer: false,
      Terminals:
        props.terminals && props.terminals.length > 0
          ? props.terminals.map((D) => ({ label: D.name, value: D._id }))
          : [],
      Selected_customer: Selected_customer,
      documents: [],
      tab: 1,
      defaultCustomerOptions: [{ label: "test", value: "test" }],
      showDragAndDrop: false,
      finalData: [],
      allAnnotation: [],
      token: null,
      responseData: [],
      finalDatas: [],
      allAnnotations: [],
      file: null,
      allFiles: [],
      preSetOrderStatus: "",
      file: props.file,
      noOfLoads: props.noOfLoads || null,
      customerEmployee: [],
      isTabActive: 1,
      showEmployee: false,
      isSaving:false,
      isLoader : false,
      isShowMore : false,
      isOptMenu:false,
      isSaving:false,
      isEmployeeEmail: checkTMXPermission('add_customer_employee_load'),
      containerNo:null
    };
    this.menu = React.createRef();
    this.createLoad = this.createLoad.bind(this);
    this.addNewCustomer = this.addNewCustomer.bind(this);
    this.handleChangeOfFleetThrottle = debounce(500, this.getFleetManagersByCustomerId);
  }

  componentDidUpdate(){
    const classTest = document.getElementsByClassName('freemium')
    if(this.state.isTabActive ==2 && classTest.length ==0 ){
      if(isTrialUser()) disableScreen('.freemium_blur');
    }
    if(this.state.isTabActive ==1 && isTrialUser()){
      var element = document.getElementsByClassName("freemium");
      if(element.length>0){
        const elements = document.getElementsByClassName('freemium');
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    }
  }
  componentDidMount(){
    document.addEventListener("click", this.handleClickOutside, false);
    return () => {
      document.removeEventListener("click", this.handleClickOutside, false);
    };
  }
  selectOwnerName = (id) => {
    if (id) {
      let choosenContainerOwner = this.props.TMSContainerOwner.find((obj) => {
        return obj._id == id;
      });
      if (choosenContainerOwner) {
        this.setState({
          containerOwnerName: choosenContainerOwner.company_name,
        });
      }
    }
  };

  uploadDocument = (file, index) => {
    return new Promise((resolve) => {
      let apiUrl = `${configuration.docUrl}driver/uploadDoctoAWS`;
      let data = new FormData();
      data.append("document", file);
      data.append("filename", new Date().getTime().toString());
      this.setState({ docUploading: true, selectedDoc: null });
      HTTP(
        "put",
        null,
        data,
        { "Content-Type": `multipart/form-data` },
        apiUrl
      ).then((result) => {
        resolve({ index, url: result.data.data.url });
      });
    });
  };

  updateToDocument = (documentObj) => {
    return new Promise((resolve) => {
      HTTP(
        "post",
        null,
        documentObj,
        { authorization: getStorage("token") },
        `${configuration.docUrl}tms/uploadDocumentForLoad`
      ).then(() => {
        resolve();
      });
    });
  };
  handleClickOutside = event => {
    if (this.menu.current && !this.menu.current.contains(event.target)) {
      this.setState({ isOptMenu: false })
    }
  };
  createLoad() {
    if (
      this.state.finalData.length > 0 &&
      this.state.allAnnotation.length > 0 &&
      this.state.token
    ) {
      toastr.show("Your PDF is being generated. Hang tight!", "info");
      let allQueues = [];
      let finalData = [...this.state.finalData];
      this.setState({ loader: true, isSaving: true });
      finalData.forEach((eachLoad, index) => {
        eachLoad.forEach((load) => {
          load.index = index;
          load.isContainerConfirm = true;
          load.isChassisConfirm = true;
          load.preSetOrderStatus = this.state.preSetOrderStatus;
          this.props.isEdi &&
            this.props.ediVals &&
            (load = { ...load, ...this.props.ediVals });
          allQueues.push(uploadLoad({ loadData: load }));
        });
      });

      Promise.all(allQueues)
        .then((DD) => {
          let allAnnotationQueue = [];
          this.setState({
            responseData: [],
            finalData: [],
            finalDatas: [],
            allAnnotations: [],
            loader: false,
            isSaving: false,
          });
          this.props.closeModal();
          this.state.allAnnotation.forEach((annotation) => {
            allAnnotationQueue.push(
              rossumAiConfirm(annotation, this.state.token)
            );
          });
          Promise.all(allAnnotationQueue).then((res) => {
            this.setState({ allAnnotation: [] });
          });
          toastr.show("Load created", "success");

          let File = [];
          this.state.allFiles.forEach((F, key) => {
            File.push(this.uploadDocument(F, key));
          });
          Promise.all(File).then((AllFile) => {
            let updateToDocument = [];
            DD.forEach((eachLoad) => {
              let findDoc = AllFile.find((u) => u.index == eachLoad.index);
              if (findDoc) {
                let documentObj = {
                  reference_number: eachLoad.reference_number,
                  type: "Delivery Order",
                  url: findDoc.url,
                };
                updateToDocument.push(this.updateToDocument(documentObj));
              }
            });
            Promise.all(updateToDocument).then(() => {
              DD.forEach((eachLoad) => {
                if (this.props.updateLoad) {
                  this.props.updateLoad(eachLoad);
                }
              });
              this.props.dispatch({
                type: TMS_GET_USER_LOAD,
                reference_number: undefined,
                payload: {
                  skip: 0,
                  limit: 1,
                },
              });
            });
          });
        })
        .catch(() => {
          this.setState({ loader: false, isSaving: false });
        });
      return;
    }
    if (!this.state.caller && getStorage("currentUserRole") != "customer") {
      toastr.show("Please select a customer!", "error");
      return;
    }
    if (getStorage("currentUserRole") == "customer") {
      if (!this.state.containerOwner) {
        toastr.show("Please select a SSL.", "error");
        return;
      }
      if (!this.state.bolbkg) {
        toastr.show("Please enter a bill of lading number.", "error");
        return;
      }
    } else if (
      this.state.type_of_load !== "BILL_ONLY" &&
      (!this.state.shipper || this.state.shipper.length === 0) &&
      getStorage("currentUserRole") != "customer"
    ) {
      toastr.show("Please select a shipper.", "error");
      return;
    } else if (
      this.state.type_of_load !== "BILL_ONLY" &&
      (!this.state.consignee || this.state.consignee.length === 0) &&
      getStorage("currentUserRole") != "customer"
    ) {
      toastr.show("Please select a consignee.", "error");
      return;
    }
    if (
      !this.state.terminal &&
      ((getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
        (getStorage("currentUserRole") == "carrier" &&
          configuration.idForTerminal.indexOf(getStorage("currentUserID")) !=
            -1))
    ) {
      toastr.show("Please select the terminal.", "error");
      return;
    }
    const params = {
      type_of_load: this.state.type_of_load,
      status: "PENDING",
      preSetOrderStatus: this.state.preSetOrderStatus,
    };
    if (  
      this.state.containerNo &&
      !/^[A-Za-z]{4}\d{6,8}$/.test(this.state.containerNo)
    ) {
      toastr.show("Please Enter Correct Container Number", "warning");
      this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
      return;
    }else if(this.state.containerNo){
      params.containerNo = this.state.containerNo;
      if(this.props.appSettings.some(r=> ['IMPORT_AVAILABILITY_INFO','TIR_DOCUMENT' ].includes(r))){
        params.myappCheck=true;
      }
      
    }
    if (getStorage("currentUserRole") != "customer") {
      params.caller = this.state.caller.value;
      params.shipper = this.state.shipper.map((obj) => {
        return obj.value;
      });
      params.consignee = this.state.consignee.map((obj) => {
        return obj.value;
      });
    }
    if (getStorage("currentUserRole") == "customer") {
      params.bolbkg = this.state.bolbkg;
      params.containerOwner = this.state.containerOwner;
      params.containerOwnerName = this.state.containerOwnerName;
      if (this.state.shipper && this.state.shipper.length > 0) {
        params.shipper = this.state.shipper.map((obj) => {
          return obj.value;
        });
      }
      if (this.state.consignee && this.state.consignee.length > 0) {
        params.consignee = this.state.consignee.map((obj) => {
          return obj.value;
        });
      }
    }
    if (this.state.temperature) {
      params.temperature = this.state.temperature;
    }
    if (
      this.state.terminal &&
      ((getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
        (getStorage("currentUserRole") == "carrier" &&
          configuration.idForTerminal.indexOf(getStorage("currentUserID")) !=
            -1))
    ) {
      params.terminal = this.state.terminal.value;
    }
    if (this.props.pricing) {
      params.pricing = this.props.pricing;
    }
    if (this.state.customerEmployee && this.state.customerEmployee.length > 0) {
      params.customerEmployee = this.state.customerEmployee.map(
        (Data) => Data.value
      );
    }

    this._createLoad(params, this.state.noOfLoads);
  }

  _createLoad(params, count = 1) {
    this.setState({ isSaving: true });
    let loadapi = ""
    let loadData = this.props.addLoadData;
    let consigneeValue = [];
    this.state.consignee.forEach((d) => consigneeValue.push(d.value))
    if(this.props.isQuotes) {
      let load = {
        caller: loadData.caller._id,
        chassisType: loadData.chassisType,
        commodityProfile: loadData.commodityProfile,
        consignee: loadData.consignee.length > 0 ? loadData.consignee.map((d) => d._id) : consigneeValue,
        shipper: loadData.shipper.map((d) => d._id),
        type_of_load: loadData.type_of_load,
        quote: loadData.quote,
        pricing: loadData.pricing,
        overweight: loadData.overweight,
        hot: loadData.hot,
        hazmat: loadData.hazmat,
        containerType: loadData.containerType,
        containerSize: loadData.containerSize,
      };
      loadapi = uploadLoad({ loadData: load })
    } else {
      loadapi = createLoad(params)
    }
    loadapi
      .then((result) => {
        if (result && result.data && result.data.data.hasContainer){
            this.onReConfirmUpdate(params)
        }else{
          if(this.props.isQuickAdd){
            {isNewModal() ? this.props.OpenLoadInfo(result.data.data.reference_number)
              : browserHistory.push(`/tms/customer-service/load?reference_number=${result.data.data.reference_number}`)
            }          }
          if (count > 1) {
            this._createLoad(params, --count);
          } else {
            this.props.dispatch({
              type: TMS_GET_USER_LOAD,
              reference_number: undefined,
              payload: {
                skip: 0,
                limit: 1,
              },
            });
            this.setState({ isSaving: false });
            if(this.props.isQuotes) {
              toastr.show(`Your ${result.reference_number} load is created!`, "success");
            } else {
              toastr.show(`Your ${result.data.data.reference_number} load is created!`, "success");
            }
            this.props.closeModal();
            if (this.props.updateLoad) {
              this.props.updateLoad(result.data.data);
            }
          }
        }        
      })
      .catch((e) => {
        this.setState({ isSaving: false });
        console.log("-=-=- load create error =-=-=-", e);
      });
  }

  // Reconfirm Load with Duplicate ContainerNo
  onReConfirmUpdate = (data) => {
    let title ="Container: " + data.containerNo;
    let body = (
      <div>
        <h4>
          This container is already in the system.
        </h4>
        <div>
          <p>
            Do you want to save the Container: {data.containerNo} anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        data.isContainerConfirm=true;
        this._createLoad(data);
      }else{
        this.setState({ loader: false, isSaving: false });
      }
    });
  };


  camelize(str) {
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }
  addNewCustomer = (data) => {
    this.setState({isLoader:true});
    return new Promise((resolve) => {
      addCustomerCommonFunction(data).then((result) => {
        resolve();
        let data = [...this.state[this.state.addType]];

        if (this.state.addType === "caller")
          data = {
            value: result.data.data.user.customer._id,
            label: result.data.data.user.name,
            allInfos: result.data.data.user,
          };
        else
          data.push({
            value: result.data.data.user.customer._id,
            label: result.data.data.user.name,
            allInfos: result.data.data.user,
          });

          this.setState({ [this.state.addType]: data, addCustomer: false, isLoader:false })
      }).catch(()=>{
        this.setState({ isLoader: false })
      })
      
    });
  };

  inhaleData = (finalData, allAnnotation, token, allFiles) => {
    this.setState({ finalData, allAnnotation, token, allFiles });
  };

  getFleetManagersByCustomerId = (searchTerm) => {
    let params = {
      customer: searchTerm ? searchTerm : this.state.caller.allInfos._id,
    };
    if (!this.state.isEmployeeEmail && searchTerm) {
      params.searchTerm = searchTerm;
    }
    if(this.state.isEmployeeEmail && searchTerm && this.state.customerInputValue &&
      (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.customerInputValue))){
        params.searchTerm = searchTerm;
      }
    return new Promise((resolve) => {
      getFleetManagersByCustomerId(params).then((result) => {
        const fleet = [];
        result.data.data.forEach((obj) => {
          fleet.push({
            value: obj._id,
            label: `${obj.firstName} ${obj.lastName ? obj.lastName : ""}`,
            allInfos: obj,
          });
        });
        if (
          this.state.customerInputValue &&
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.state.customerInputValue
          )
        ) {
          let thatEmployee = result.data.data.find(
            (J) =>
              J.billingEmail &&
              J.billingEmail.toLowerCase() ==
                this.state.customerInputValue.toLowerCase()
          );
          if (thatEmployee) {
            let customerEmployee = [];
            // let caller = {
            //   label: `${thatEmployee.customer.company_name} ${thatEmployee.customer.address.address}`.trim(),
            //   value: thatEmployee.customer._id,
            //   allInfos: thatEmployee.customer
            // }
            customerEmployee.push({
              label: `${thatEmployee.firstName} ${thatEmployee.lastName}`,
              value: thatEmployee._id,
              allInfos: thatEmployee,
            });
            customerEmployee = customerEmployee.unique((D) => D.value);
            this.setState({ customerEmployee, customerInputValue:'', isOptMenu:false, showEmailfor:'' });
          }
        }
        resolve(fleet);
      });
    });
  };

  _findContainerOwner(value) {
    let data = this.state.TMSContainerOwner.find((data) => data._id === value);

    if (data) return { value: data._id, label: data.company_name };

    return;
  }

  onCustomerSelect=(caller)=>{
    this.setState({ caller, isOptMenu: false, customerEmployee:[], showEmailfor:''}, () => {
      // this.getFleetManagersByCustomerId()
    })
  }

  //  Client Email Select or change
  onEmployeeEmailChange=(customerInputValue, isInput=false)=>{
    if(customerInputValue){
      this.setState({ customerInputValue: customerInputValue},() => {
        isInput?this.handleChangeOfFleetThrottle(customerInputValue): this.getFleetManagersByCustomerId(customerInputValue);
      });
    }
    return undefined;
  }

  render() {
    const { callers, shippers, consignees, allCustomers } =
      this.props;
      
    return (
      <React.Fragment>
        <div className="modal-body" data-testid="form-body">
          <ul
            className="nav nav-compact bg-gray-50 w-100 mb-30 "
            role="tablist"
          >
            {tabItems.map((tabItem) => (
              <li className="nav-item w-50 text-center" key={tabItem.id}>
                <span
                  // href="#!"
                  className={`nav-link ml-1 pointer ${
                    this.state.isTabActive === tabItem.id
                      ? " active"
                      : "rounded-3"
                  }`}
                  onClick={() => this.setState({ isTabActive: tabItem.id })}
                >
                  {tabItem.title}
                </span>
              </li>
            ))}
          </ul>
          {!this.props.isFromEmail && (
            <div>
              {this.state.isTabActive ==2 && isTrialUser() ? 
              <div style={{position:"fixed", textAlign:"center",top:"50%",zIndex:"9"}}>
               <h4>For more features and a better user experience, 
                   <div>please upgrade to full version of PortPro</div></h4>
               <span className="btn btn-primary" style={{cursor:"pointer"}}>Upgrade to full version</span>
               </div>
              : ''}
            <form className={`${ this.state.isTabActive ==2 && isTrialUser() && "freemium_blur"}`}>
              <label className="mb-10">
                <span className="text-danger">*</span> Load Type
              </label>
              <div className="form-group mb-20">
                <div className="row">
                  {LoadType.map((item, index) => {
                    return (
                      <div
                        className={`col-md ${index == 2 && "pr-0"}`}
                        key={index}
                      >
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={item.value === this.state.type_of_load}
                            name={item.label}
                            id={item.label}
                            onChange={(e) => {
                              this.setState({ type_of_load: item.value });
                            }}
                          />
                          <label className="form-check-label text-capitalize" for={item.label}>
                            {item.label.toLowerCase()}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {getStorage("currentUserRole") == "customer" && (
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Bill of Lading
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.bolbkg}
                      onChange={(e) =>
                        this.setState({ bolbkg: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}
              {getStorage("currentUserRole") == "customer" && (
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Container Owner
                  </label>
                  <Select
                    styles={smallSelectStyle}
                    options={this.props.TMSContainerOwner.map((d) => ({
                      value: d._id,
                      label: d.company_name,
                    }))}
                    value={
                      this.state.containerOwner &&
                      this._findContainerOwner(this.state.containerOwner)
                    }
                    onChange={({ value }) => {
                      this.setState({ containerOwner: value });
                      this.selectOwnerName(value);
                    }}
                  />
                </div>
              )}
              {this.state.isTabActive == 1 && (
                <div>
                  {getStorage("currentUserRole") != "customer" && (
                    <React.Fragment>
                      <div className="form-group mb-20" ref={this.menu}>
                        <label className="mb-10">
                          <span className="text-danger">*</span> Customer
                        </label>

                        <AsyncCreatableSelect
                          cacheOptions
                          data-testid="container-owner-inp"
                          defaultOptions={makeOptionsForSelect(
                            callers.concat(allCustomers),
                            "company_name",
                            "address.address",
                            "_id"
                          )}
                          onEmployeeEmailChange={this.onEmployeeEmailChange}
                          onInputChange={(customerInputValue) => {
                            if(this.state.isEmployeeEmail){
                              this.onEmployeeEmailChange(customerInputValue, true)
                            } else {
                              this.setState({
                                customerInputValue: customerInputValue
                                  ? customerInputValue
                                  : this.state.customerInputValue,
                              });
                            }
                          }}
                          value={this.state.caller}
                          onChange={(caller) => {
                            this.setState({ caller }, () => {
                              this.getFleetManagersByCustomerId();
                            });
                          }}
                          loadOptions={getCallers}
                          styles={smallSelectStyle}
                          createOptionPosition="first"
                          formatCreateLabel={() => `Add New Customer`}
                          onCreateOption={() =>
                            this.setState({
                              addCustomer: true,
                              addType: "caller",
                            })
                          }
                          className={"customers"}
                          components={{ Option }}
                          menuIsOpen={this.state.isOptMenu}
                          onFocus={()=>{
                            this.setState({ isOptMenu: true })}}
                          blurInputOnSelect={true}
                          onCustomerSelect={this.onCustomerSelect}
                          isEmployeeEmail={this.state.isEmployeeEmail}
                        />
                      </div>
                      {this.state.isEmployeeEmail && this.state.customerEmployee && this.state.customerEmployee.length!==0 &&
                        <React.Fragment>
                          <label className="label-control">
                            Employee
                          </label>
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            isMulti
                            value={this.state.customerEmployee}
                            onChange={(customerEmployee) => {
                              this.setState({ customerEmployee })
                            }}
                            // loadOptions={this.getFleetManagersByCustomerId}
                            formatCreateLabel={(inputValue) => `Select Employee`}
                            className="mb-2"
                            isDisabled={true}
                          />
                        </React.Fragment>
                        }
                    </React.Fragment>
                  )}

                  <div className="form-group mb-20">
                    <label className="mb-10">
                      <span className="text-danger">*</span>{" "}
                      {`${
                        this.state.type_of_load === "ROAD" ? "Shipper" : "Port"
                      }`}
                    </label>
                    <AsyncCreatableSelect
                      isMulti
                      cacheOptions
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        shippers,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      value={this.state.shipper}
                      onChange={(shipper) => {
                        this.setState({ shipper });
                      }}
                      loadOptions={getShippers}
                      createOptionPosition="first"
                      formatCreateLabel={() =>
                        `Add New ${
                          this.state.type_of_load === "ROAD"
                            ? "Shipper"
                            : "Port"
                        }`
                      }
                      onCreateOption={() =>
                        this.setState({ addCustomer: true, addType: "shipper" })
                      }
                      className="ports"
                      isDisabled={this.state.type_of_load === "BILL_ONLY"}
                    />
                  </div>

                  <div className="form-group mb-20">
                    <label className="mb-10">
                      <span className="text-danger">*</span>Consignee
                    </label>
                    <AsyncCreatableSelect
                      isMulti
                      cacheOptions
                      allowCreateWhileLoading
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        consignees,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      value={this.state.consignee}
                      onChange={(consignee) => {
                        this.setState({ consignee });
                      }}
                      menuPosition={"fixed"}
                      loadOptions={getConsignees}
                      createOptionPosition="first"
                      formatCreateLabel={() => `Add New Consignee`}
                      onCreateOption={() =>
                        this.setState({
                          addCustomer: true,
                          addType: "consignee",
                        })
                      }
                      className={"consignee"}
                      isDisabled={this.state.type_of_load === "BILL_ONLY"}
                    />
                  </div>

                  {this.state.noOfLoads && (
                    <div className="form-group">
                      <label className="label-control">No of Loads</label>
                      <input
                        type="number"
                        min="1"
                        max="1"
                        className="form-control"
                        value={this.state.noOfLoads}
                        onChange={(e) =>
                          this.setState({
                            noOfLoads:
                              e.target.value < 11 && e.target.value > 0
                                ? e.target.value
                                : 1,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              )}

                  {showForTerminal () && (
                    <div className="form-group">
                      <label className="label-control"><span className="text-danger">*</span> Terminal</label>
                      <Select
                        value={this.state.terminal}
                        cacheOptions
                        defaultOptions
                        styles={smallSelectStyle}
                        onChange={(terminal) => {
                          this.setState({ terminal });
                        }}
                        options={this.state.Terminals}
                      />
                    </div>
                  )}

              {  
                this.props.appSettings.some(r=> ['IMPORT_AVAILABILITY_INFO','TIR_DOCUMENT' ].includes(r)) && this.state.type_of_load=='IMPORT' && (
                <div className="form-group mb-20">
                  <label className="mb-10">
                    Container Number
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Container #"
                      className="form-control"
                      value={this.state.containerNo}
                      onChange={(e) =>
                        this.setState({ containerNo: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}

              {this.state.isTabActive == 2 && (
                <div>
                  <RossumAi
                    inhaleData={this.inhaleData}
                    isFromEmail={this.props.isFromEmail}
                    file={this.state.file}
                    responseData={this.state.responseData}
                    finalDatas={this.state.finalDatas}
                    allAnnotations={this.state.allAnnotations}
                    isEdi={this.props.isEdi}
                    terminal={this.state.terminal}
                    type_of_load={this.state.type_of_load}
                  />
                </div>
                )}
              <div className="form-group mb-20" style={{minHeight: "230px"}}>
                <label className="mb-10">
                  <span className="text-danger">*</span> Select Route for this
                  load
                  </label>
                  <div className="form-check mb-10">
                  <input
                    type="radio"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked
                          ? "PULL_DELIVER_DROP_RETURN"
                          : "",
                      })
                    }
                    className="form-check-input"
                    id="pick-run-drop-hook"
                    checked={
                      this.state.preSetOrderStatus ===
                      "PULL_DELIVER_DROP_RETURN"
                    }
                    name="route"
                  />
                  <label className="form-check-label" for="pick-run-drop-hook">
                    Pick and Run + Drop & Hook
                  </label>
                </div>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked ? "" : "",
                      })
                    }
                    className="form-check-input"
                    id="pick-run-live-unload"
                    checked={this.state.preSetOrderStatus === ""}
                    name="route"
                  />
                  <label
                    className="form-check-label"
                    for="pick-run-live-unload"
                  >
                    Pick and Run + Live Unload
                  </label>
                </div>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="prepull-drop-hook"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked
                          ? "PULL_DROP_DELIVER_DROP_RETURN"
                          : "",
                      })
                    }
                    checked={
                      this.state.preSetOrderStatus ===
                      "PULL_DROP_DELIVER_DROP_RETURN"
                    }
                    name="route"
                  />
                  <label className="form-check-label" for="prepull-drop-hook">
                    Prepull + Drop & Hook
                  </label>
                </div>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked
                          ? "PULL_DROP_DELIVER_RETURN"
                          : "",
                      })
                    }
                    className="form-check-input"
                    id="prepull-live-unload"
                    checked={
                      this.state.preSetOrderStatus ===
                      "PULL_DROP_DELIVER_RETURN"
                    }
                    name="route"
                  />
                  <label className="form-check-label" for="prepull-live-unload">
                    Prepull + Live Unload
                  </label>
                </div>                

                <div className="form-check mb-10">
                  <input
                    type="radio"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked
                          ? "PULL_RETURN"
                          : "",
                      })
                    }
                    className="form-check-input"
                    id="one-way-move"
                    checked={this.state.preSetOrderStatus === "PULL_RETURN"}
                    name="route"
                  />
                  <label className="form-check-label" for="one-way-move">
                    One Way Move
                  </label>
                </div>
                {this.state.isShowMore && 
                  (
                    <React.Fragment>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked
                          ? "PICK_RUN_GRAY_POOL"
                          : "",
                      })
                    }
                    className="form-check-input"
                    id="pick-run-gray-pool"
                    checked={this.state.preSetOrderStatus === "PICK_RUN_GRAY_POOL"}
                    name="route"
                  />
                  <label className="form-check-label" for="pick-run-gray-pool">
                    Pick and Run + Gray Pool
                  </label>
                </div>

                <div className="form-check mb-10">
                  <input
                    type="radio"
                    disabled={this.state.type_of_load === "BILL_ONLY"}
                    onChange={(e) =>
                      this.setState({
                        preSetOrderStatus: e.target.checked
                          ? "PREPULL_GRAY_POOL"
                          : "",
                      })
                    }
                    className="form-check-input"
                    id="prepull-gray-pool"
                    checked={this.state.preSetOrderStatus === "PREPULL_GRAY_POOL"}
                    name="route"
                  />
                  <label className="form-check-label" for="prepull-gray-pool">
                    Prepull + Gray Pool
                  </label>
                </div>
                    </React.Fragment>
                  )
                }
                <div className="pointer text-primary font-12 mt-15"
                  onClick={()=>{
                    this.setState({ isShowMore: !this.state.isShowMore })
                  }}
                > 
                  {this.state.isShowMore ? '- Show less' : '+ Show more'}
                </div>
              </div>
            </form>
  
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.props.closeModal()}
            className="btn btn-close"
          >
            Cancel
          </button>
          <button
            type="button"
            data-testid="save-load"
            onClick={() => {
              this.createLoad();
            }}
            className="btn btn-success"
            disabled={this.state.isSaving}
          >
            {this.state.isSaving &&   <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>}
            Create Load
          </button>
        </div>

        <Modal show={this.state.addCustomer} bsSize="lg" className="show">
          <Modal.Header>
            <ul className="tab nav nav-pills nav-pills-success">
              <li className="nav-item active">
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${this.state.tab == 1 ? "active" : ""}`}
                  onClick={() => this.setState({ tab: 1 })}
                >
                  Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${this.state.tab == 2 ? "active" : ""}`}
                  onClick={() => this.setState({ tab: 2 })}
                >
                  Documents
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${this.state.tab == 3 ? "active" : ""}`}
                  onClick={() => this.setState({ tab: 3 })}
                >
                  GeoFence
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${this.state.tab == 4 ? "active" : ""}`}
                  onClick={() => this.setState({ tab: 4 })}
                >
                  Notes
                </a>
              </li>
            </ul>
          </Modal.Header>
          <Modal.Body>
            <AddCustomerComponent
              submitForm={this.addNewCustomer}
              initialValues={this.state.Selected_customer}
              {...this.props}
              {...this.state}
              documents={this.state.documents}
              isLoader={this.state.isLoader}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                this.setState({ addCustomer: false, tab: 1 });
              }}
            >
              Close
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                $(".btncustomersave").trigger("click");
              }}
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const tabItems = [
  {
    id: 1,
    title: "Manually",
  },
  {
    id: 2,
    title: "Automatically",
  },
];
const LoadType = [
  { value: "IMPORT", label: "IMPORT" },
  { value: "EXPORT", label: "EXPORT" },
  { value: "ROAD", label: "ROAD" },
  { value: "BILL_ONLY", label: "BILL ONLY" },
];

const states = (state) => ({
  shippers: state.TmsReducer.shippers,
  consignees: state.TmsReducer.consignees,
  customers: state.TmsReducer.customers,
  callers: state.TmsReducer.callers,
  allCustomers: state.TmsReducer.allCustomers,
  terminals: state.HomeReducer.terminals,
  trackosSynced: state.TmsReducer.trackosSynced,
  appSettings: state.TmsReducer.appSettings,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch: dispatch,
  };
}

export default connect(states, mapDispatchToProps)(AddNewLoad);

const Option = props => {
  const [showEmails, setShowEmails]=useState(false)
  // ontoggle email
  const onClickShowEmail=(event)=>{
    event.preventDefault();
    setShowEmails(!showEmails);
  }
  // onDefault Select Customer
  const onSelectCustomer=(val)=>{
    // console.log(val)
    props.selectProps.onCustomerSelect(val);
  }
  // OnSelect employeeEmails
  const onSelectEmails=(val)=>{
    props.selectProps.onEmployeeEmailChange(val)
  }

  return (
    <components.Option {...props} className="py-0" >
      <div className="row d-flex align-items-center justify-content-between">
        <div className="col-md-10 py-2" onClick={()=>onSelectCustomer(props.data)}>
          {props.data.label}
        </div>
        {
          props.data && props.selectProps.isEmployeeEmail && props.data.allInfos && props.data.allInfos.employeeEmails && props.data.allInfos.employeeEmails.length>0 &&
          <div className="col-md-2 d-flex justify-content-end">
            <span className="badge badge-success pointer mr-2" onClick={(event)=>onClickShowEmail(event)}  >Emails</span>
          </div>
        }
      </div>
      {
        showEmails &&
        <div className="row">
          <div className="col-sm-12">
            <div class="list-group mb-2">
              {
                props.data && props.selectProps.isEmployeeEmail && props.data.allInfos && props.data.allInfos.employeeEmails && props.data.allInfos.employeeEmails.length>0 &&
                props.data.allInfos.employeeEmails.map(email=>(
                  <a  href="javascript:void(0)" class="list-group-item list-group-item-action" onClick={()=>onSelectEmails(email)}> {email}</a>
                ))
              }
            </div>
          </div>
        </div>
      }
    </components.Option>
  )
}