import { parseInt } from "lodash-es";
import React, { Component } from "react";
import { IconMinus, IconPlus } from "../Icons";

export default class NewPriceInput extends Component {
  constructor(props) {
    super(props);

  }

  increment = () => {
    if(Number(Number(this.props.value) + 1).toFixed(2)< 99999.99){
          this.props.onChange(Number(Number(this.props.value) + 1).toFixed(2));
    
    }
  };
  decrement = () => {
    if (parseInt(this.props.value) == 0) return;
          this.props.onChange(Number(Number(this.props.value) - 1).toFixed(2));
  };

  handleOnChange = (e) => {
    const { value } = e.target;
    let fieldValue = parseFloat(value)
    if(fieldValue<= 99999.99){
      if(fieldValue.toString().indexOf(".") !== -1){
        let num = fieldValue.toString(); 
        num = num.slice(0, (num.indexOf("."))+3); 
        fieldValue = num;
      }
          this.props.onChange(fieldValue);
    }
    if(isNaN(fieldValue)){
          this.props.onChange('')
    }
  };

  keyPressOnNumberInput = (evt) => {
    var invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };
  render() {
    return (
      <div>
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              type="button"
              className={`btn btn-outline-light border-0 ${this.props.disabled && "disable-input"}`}
              onClick={() => this.decrement()}
              disabled={this.props.disabled}
              onFocus={(e)=> e?.target.classList?.add("outline-1","text-gray-300")}
              onBlur={(e)=> {e?.target.classList?.remove("outline-1","text-gray-300"); this.props.onBlur && this.props?.onBlur()}}
            >
              <IconMinus className="text-dark" />
            </button>
          </div>
          <input
            type="number"
            min={0}
            max={99999.99}
            step="any"
            className="form-control border-0 text-center bg-transparent"
            value={this.props.value ? this.props.value : ''}
            onKeyDown={(e) => {
              this.keyPressOnNumberInput(e);
            }}
            placeholder="0"
            onChange={this.handleOnChange}
            disabled={this.props.disabled}
            onBlur={this.props?.onBlur}
          />
          <div className="input-group-append">
            <button
              type="button"
              className={`btn btn-outline-light border-0 ${this.props.disabled && "disable-input"}`}
              onClick={() => this.increment()}
              disabled={this.props.disabled}
              onFocus={(e)=> e?.target.classList?.add("outline-1","text-gray-300")}
              onBlur={(e)=> {e?.target.classList?.remove("outline-1","text-gray-300"); this.props.onBlur && this.props?.onBlur()}}
            >
              <IconPlus className="text-dark" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}