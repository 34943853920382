import React, { useState, useEffect } from "react";
import { IconDownloadAlt } from "../../../../Components/Common/Icons";
import moment from "moment";
import { getStorage } from "../../../../services";

const getTimeDifference = (endTime, startTime) => {
  if (startTime && endTime) {
    const startTimeObject = moment(startTime);
    const endTimeObject = moment(endTime);
    const duration = moment.duration(endTimeObject.diff(startTimeObject));

    return duration.humanize();
  }
  return null;
}
const ContainerSummary = ({details}) => {
  const {company_name, type, city, state, arrived, departed } = details;
  let timeZone = getStorage("timeZone");
  return (
    <div className="dlist--card card shadow-5 p-10" style={{
      position: "absolute",
      bottom: "-65px",
      left: "-100%",
      zIndex: 2
    }}>
      <div className="dlist__distance--info w-100 m-0 p-0">
        <div className="d-flex align-items-start mb-1">
          <div className="flex-grow-1">
            <h5 class="font-12 mb-1 font-weight-500 text-uppercase">
              <a href="#" class="text-dark">{company_name}</a>
            </h5>
            <p class="text-muted m-0 font-weight-normal font-12"><span>{city}, {state}</span></p>
          </div>
          <div className="flex-shrink-0">
            <span className="badge badge-sm text-capitalize badge-load bg-gray-100 text-dark font-weight-normal font-10"><IconDownloadAlt /> {type.toLowerCase()}</span>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pb-0">
          <div className="flex-grow-1">
            <p className="text-muted m-0 font-weight-normal font-12">
              <span>Enroute</span>
            </p>
            <div className="text-dark font-12 font-weight-normal">{arrived? moment(arrived)?.tz(timeZone)?.format("MM/DD/YY hh:mm A"): ""}</div>
          </div>
          <div className="flex-shrink-0 mx-2">
            <span className="badge bg-gray-50 font-weight-normal font-12 w-80px">
              {departed && arrived &&`${getTimeDifference(departed, arrived)}`}
            </span>
          </div>
          <div className="flex-grow-1">
            <p className="text-muted m-0">
              <span>Arrived</span>
            </p>
            <div className="text-dark font-12 font-weight-normal">{departed? moment(departed).tz(timeZone).format("MM/DD/YY hh:mm A"): ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerSummary;