import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { browserHistory } from 'react-router';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { cloneDeep } from 'lodash';
import { debounce } from "throttle-debounce";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import draftToHtml from "draftjs-to-html";
import jsonLogic from "json-logic-js";
import moment from "moment";

import { CustomIconNotes } from "../../../Components/Common/CustomIcons/Index";
import CustomCreatableSelect from "../../../Components/Common/CustomSelect/creatable";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { getTruckersByCustomer } from "../../../Components/Common/FloatingChat/actionCreators";
import { LoaderBar } from '../../../Components/Common/LoaderBar';
import {
  IconAngleArrowDown,
  IconCalendar,
  IconDownload,
  IconMinus,
  IconPlus,
  IconSendEmail,
} from "../../../Components/Common/Icons/index";
import { CustomIconSaveOutline } from "../../../Components/Common/CustomIcons/Index";
import NewPriceInput from "../../../Components/Common/PriceInput/NewPriceInput";
import { getPricingRules } from "../../../Components/DynamicPricing/actionCreators";
import {
  convertUnit,
  getPricingSettingsOptions,
  getStorage,
  isMilitaryTime,
  toastr,
  treeToJsonParser,
} from "../../../services/Common.services";
import { validateEmail } from "../../../utils";
import { TMS_GET_USER_LOAD } from "../constant";
import {
  getSettings,
  saveLoadNotes,
  sendBillingEmail,
} from "../services";
import * as tmsAction from "./actionCreator";
import NewLoadExpense from "./NewLoadExpense";
import confirmCharge from "../../../Components/Common/NewChargeConflictAlert";
import "./style.css";
import { emailInvoice, getInvoiceDesign } from "../DocumentConfiguration/actionCreators";
import { invoice, rateConf } from "../DocumentConfiguration/constants";
import PricingComponent from "./PricingComponent";
import tabChangeConfirm from "./TabChangeConfirm";
import NewAdditionalPricing from "./NewAdditionalPricing";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import SelectCustomComponent from "../SelectComponent";
import { newSmallSelectStyle } from "../../../assets/js/select-style";
import configuration from "../../../config";
const MANUALLY_CHANGED = 'MANUALLY_CHANGED';

const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};
const newFirebase = configuration.getNewFirebaseInstance;
const newDb = newFirebase('bill-of-lading').database();

class NewBillOfLading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ ...commodityItems }],
      APPROVED: false,
      UNAPPROVED: false,
      REBILLING: false,
      BILLING: false,
      statusname: props.loads.status,
      paymentDate: new Date(),
      showConfirmModel: false,
      extraEmail: [],
      ccEmail: [],
      showNote: false,
      isEmailSendInProcess: false,
      pricingRules: [],
      editorState: EditorState.createEmpty(),
      inpSubject: null,
      billingNote: {},
      officeNote: {},
      asce: true,
      isLoadTabActive: false,
      showUploadModal: false,
      showSendEmailModal: false,
      isCustomerTabActive: props.billingActiveTab ? props.billingActiveTab : "main-customer",
      isSubExpBillTabActive: "bill",
      isBillActive: true,
      billingNoteLoader : true,
      officeNoteLoader : true,
      name : "",
      description : "",
      unit : 1,
      amount : 0,
      finalAmount : "",
      manualFinalAmount: 0,
      perType : "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
      isAPICall: false,
      isSuccess: false,
      nameColor : "transition-white-field",
      isFailed : "transition-white-field",
      type : "",
      rowIndex : null,
      isSubPrompt: false,
      isEdited: false,
      addSub: false,
      selectedCell:null,
      approvalStatus:"",
      nextClick : null
    };

    this.pricingDescription = null;
    this.pricingFreeUnit = null;
    this.handleextraEmailChange = this.handleextraEmailChange.bind(this);
    this.handleccEmailChange = this.handleccEmailChange.bind(this);
    this.pricingSettingsOptions = getPricingSettingsOptions();
    this.saveNotesDebounce = debounce(2000, this.submitNotes);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.DeletePriceRow = this.DeletePriceRow.bind(this);
    this.pricingchanges = this.pricingchanges.bind(this);
    this.unselectCell = this.unselectCell.bind(this);
    this.userTimeZone = getStorage("timeZone");
    this.chargeCodeList = [];
    if(this.props.chargeCode?.length) {
      this.chargeCodeList = _.orderBy(this.props.chargeCode, "orderIndex", "asc");
    }
    this.userTimeFormat = isMilitaryTime();
    this.firebaseRef = React.createRef();
  }

  selectCell = (name) => {
    if(this.state.selectedCell===name) return
    this.setState({selectedCell:name});
  }
  
  unselectCell = () => {
    this.setState({selectedCell: null});
  }

  handleExpenseUpdate = () => {};
  addCustomerPriceRows = () => {};
  updateAdditionalPricing = () => {};

  handleCustomerTabActive = (i) => {
      this.setState({
        isCustomerTabActive: i,
      });
  };

  handleSubExpBillTabActive = (i) => {
    this.setState({
      isSubExpBillTabActive: i,
    });
  };

  componentWillMount() {
    this.getPricingRules();
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
    } else {
      this.componentWillReceiveProps(this.props);
    }
  }
  keyPressOnNumberInput = (evt) => {
    var invalidChars = ["+", "e"];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };
  componentDidMount() {
    if (getStorage("loggedInUser") != null) {
      const currentUser = JSON.parse(getStorage("loggedInUser"));
      let carrierId = currentUser._id;
      if (currentUser.role === "driver") {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === "fleetmanager") {
        carrierId = currentUser.fleetManager.carrier;
      }
      if (currentUser.role === "customer") {
        this.props.dispatch(getTruckersByCustomer()).then((truckers) => {
          carrierId =
            truckers[0] && truckers[0].carrier && truckers[0].carrier._id;
        });
      } 
      this.getSettings()
      this.notesDetail()
      this.setCcEmail()
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    
    if (this.props.handleSubmitLoad) {
      this.props.handleSubmitLoad(() => {
        if (this.state.isSubExpBillTabActive === "sub_expense") this.handleExpenseUpdate();
      }
      );
    }

    this.subscribeToFirebase()

  }
  getSettings() {
    getSettings()
      .then((result) => {
        const data = _.get(result, "data.data");
        if (data) this.setState({ settings: data });
      })
  }

  notesDetail(key){
    if(key){
      this.setState({ [key] : true})
    } else {
      this.setState({billingNoteLoader :true, officeNoteLoader: true});
    }
    tmsAction.getLoadNoteDetails({reference_number : this.props.id}).then((data) => {
      if(data.billingNote && Object.keys(data.billingNote).length != 0){
        this.setState({
          billingNote : data.billingNote ? data.billingNote : {},
        })
      }
      if(data.officeNote && Object.keys(data.officeNote).length != 0){
        this.setState({
          officeNote : data.officeNote ? data.officeNote : {},
        })
      }
      this.setState({billingNoteLoader :false, officeNoteLoader: false});
    });;

  }
  
  setCcEmail(){
    let ccEmail = "";
      let ccEmails = [];
      let inpBody = "";

      if (this.props && this.props.selectedLoads) {
        inpBody = "Please review attached billing invoice.";
        ccEmails = [
          ...ccEmails,
          this.props.selectedLoads.carrier.carrier.billingEmail,
          this.props.selectedLoads.caller.extraEmail,
        ];
        if (
          this.props.selectedLoads.customerEmployee &&
          this.props.selectedLoads.customerEmployee.length > 0 &&
          this.props.selectedLoads.customerEmployee[0].billingEmail
        ) {
          let customerEmployeeEmails = this.props.selectedLoads.customerEmployee.map(
            (D) => D.billingEmail
          );
          ccEmails = [...ccEmails, ...customerEmployeeEmails];
        }
        ccEmail = ccEmails.join(",");
      }else if (this.props.loads) {
        inpBody = "Please review attached billing invoice.";
        let billingEmail =
          this.props.loads.carrier.carrier && this.props.loads.carrier.carrier.billingEmail
            ? this.props.loads.carrier.carrier.billingEmail
            : "";
        ccEmails = [...ccEmails, billingEmail, this.props.loads.caller.extraEmail];
        if (
          this.props.loads.customerEmployee &&
          this.props.loads.customerEmployee.length > 0 &&
          this.props.loads.customerEmployee[0].billingEmail
        ) {
          let customerEmployeeEmails = this.props.loads.customerEmployee.map(
            (D) => D.billingEmail
          );
          ccEmails = [...ccEmails, ...customerEmployeeEmails];
        }
        ccEmail = ccEmails.join(",");
      }
      this.setState({
        inpBody,
        ccEmail:ccEmail? ccEmail.split(",").compact(true):[],
      })
  }
  
  submitNotes = (key,value) => {
    saveLoadNotes({
      [key]:value,
      reference_number: this.props.loads.reference_number,
    })
      .then(() => {
        toastr.show("Notes have been added.", "success");
        this.notesDetail(`${key}Loader`)
      })
      .catch((err) => toastr.show(err.message, "error"));
  };

  componentWillUnmount() {
    if (this.userPresense) {
      this.userPresense.set(false);
    }
    document.removeEventListener("mousedown", this.handleClickOutside);

    if(newFirebase){
      this.unsubscribeFromFirebase();
    }
  }
  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  getPricingRules() {
    getPricingRules()
      .then((pricingRules) => {
        this.setState({ pricingRules });
      })
      .catch((error) => {
        console.log(" -*-*-* error get pricing rules -*-*-*- ", error);
      });
  }

  componentWillReceiveProps(props) {
    var items = [];
    if (
      this.props.pricing !== props.pricing ||
      (props.pricing && !this.state.pricing)
    ) {
      let base_price = 0,
        x_charges = 0;
      let allPricings = props.pricing.filter((x) => x);
      if (allPricings && allPricings.length > 0) {
        allPricings = _.sortBy(allPricings, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
        allPricings.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price +=  parseFloat(x.finalAmount)  ;
            } else {
              x_charges = (
                parseFloat(x_charges) + parseFloat(x.finalAmount)
              ).toFixed(2);
            }
            x.manualFinalAmount = x.finalAmount;
          }
        })
      }
      const Total = (parseFloat(base_price) + parseFloat(x_charges)).toFixed(2);
      this.setState(
        { pricing: allPricings ,  base_price:base_price.toFixed(2), x_charges, Total, billing_date:null },
        () => {}
      );
    }
    if (getStorage("currentUserRole") == "customer" && !props.pricing) {
      this.setState({ pricing: [] });
    }
    if (props.selectedLoads && props.selectedLoads.items) {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: props.selectedLoads.status,
        [props.selectedLoads.status]: true,
      });
      items = _.map(props.selectedLoads.items || [], function (e) {
        return _.omit(e, "_id");
      });
    }
    if (props.loads && props.loads.items) {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: props.loads.status,
        [props.loads.status]: true,
      });
      items = _.map(props.loads.items || [], function (e) {
        return _.omit(e, "_id");
      });
    }
    this.setState({
      customerProfile: props.selectedLoads ? props.selectedLoads : props.loads,
      items: items,
    });
  }

  findMatchingPricingRule(chargeName) {
    let selectedLoads = undefined;
    if (this.props.selectedLoads) {
      selectedLoads = this.props.selectedLoads;
    } else {
      selectedLoads = this.props.loads;
    }

    const loads = {
      ...selectedLoads,
      caller: selectedLoads.caller && selectedLoads.caller._id,
      shipper: selectedLoads.shipper && selectedLoads.shipper.map((s) => s._id),
      consignee:
        selectedLoads.consignee && selectedLoads.consignee.map((s) => s._id),
      chassisPick: selectedLoads.chassisPick && selectedLoads.chassisPick._id,
      emptyOrigin: selectedLoads.emptyOrigin && selectedLoads.emptyOrigin._id,
      chassisTermination:
        selectedLoads.chassisTermination &&
        selectedLoads.chassisTermination._id,
      containerType:
        selectedLoads.containerType && selectedLoads.containerType._id,
      containerSize:
        selectedLoads.containerSize && selectedLoads.containerSize._id,
      containerOwner:
        selectedLoads.containerOwner && selectedLoads.containerOwner._id,
      chassisType: selectedLoads.chassisType && selectedLoads.chassisType._id,
      chassisSize: selectedLoads.chassisSize && selectedLoads.chassisSize._id,
      chassisOwner:
        selectedLoads.chassisOwner && selectedLoads.chassisOwner._id,
      terminal: selectedLoads.terminal && selectedLoads.terminal._id,
      commodityProfile:
        selectedLoads.commodityProfile &&
        selectedLoads.commodityProfile.map((s) => s._id),
    };

    const { pricingRules } = this.state;
    const newPricingFromSettings = [];
    let weight = 0;

    if (selectedLoads.items) {
      selectedLoads.items.forEach((obj) => {
        weight += parseInt(obj.weight);
      });
    }
    selectedLoads.weight = weight;
    pricingRules
      .filter((rule) => rule.name === chargeName && rule.isManual)
      .forEach((obj) => {
        let isAllConditionMatched = true;

        if (obj.query && obj.query[0] && obj.query[0].children.length > 0) {
          const rule = JSON.stringify(treeToJsonParser(obj.query)[0]);
          const data = JSON.stringify(loads);
          isAllConditionMatched = jsonLogic.apply(
            JSON.parse(rule),
            JSON.parse(data)
          );
        }

        if (isAllConditionMatched) {
          let totalDays = 0;
          if (obj.type === "fixed") {
            obj.finalAmount = obj.amount;
          } else if (obj.type === "perpound") {
            totalDays = weight;
          }
          obj.totalDays = totalDays;
          newPricingFromSettings.push(obj);
        }
      });
    return newPricingFromSettings[0];
  }

  pricingchanges(index, e) {
    let { name, value } = e.target;
    const old = [...this.state.pricing];

    const positiveValuesOnly = [
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      if(name==="amount" && !value ) value=0
      old[index][name] = value;
    }
    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    if (name === "name") {
      const data = this.findMatchingPricingRule(value);

      if (data) {
        let units = 0;
        const freeDays = data.freeDays || 0;
        const chargePerDay = data.amount || 0;
        
        if (data.type === "fixed") units = 1;

        let finalAmount = (units - freeDays) * chargePerDay;

        if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;
        
        old[index]["description"] = data.description;
        old[index]["perType"] = data.type;
        old[index]["freeDays"] = freeDays > 0 ? freeDays : 0;
        old[index]["chargePerDay"] = chargePerDay;
        old[index]["unit"] = units;
        old[index]["finalAmount"] = finalAmount;
        old[index]["manualFinalAmount"] = 0;
        old[index]["amount"] = finalAmount;

        this.setState({
          pricing: [...old],
          rowIndex:index,
        });
        return;
      }
    }
    if (name === "type") { 
      old[index]["finalAmount"] =
      old[index]["type"] === "fixed"
      ? old[index]["amount"]
      : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
      100;
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "amount") { 
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
            100;

      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay",'perType'].includes(name)) {
      let computedAmount = (parseFloat(old[index].unit) - parseFloat(old[index].freeDays)) * parseFloat(old[index].chargePerDay);

      old[index][name] = value;
      if(old[index].name != "Fuel"){
        console.log(1);
        if (computedAmount >= parseFloat(old[index].manualFinalAmount || 0)) {
          old[index]["finalAmount"] = computedAmount.toFixed(2);
        } else {
          old[index]["finalAmount"] = parseInt( old[index].manualFinalAmount || 0).toFixed(2);
        }
      }

      if((name == "perType" && value =="percentage")
      || (name !== "perType" && old[index]["perType"] == "percentage") ){
        old[index]["finalAmount"] = (this.state.base_price * old[index]["unit"] / 100).toFixed(2);
      }

    }

    if (!old[index].chargeType || old[index].chargeType !== MANUALLY_CHANGED){
      old[index].chargeType = MANUALLY_CHANGED;
    }
    this.setState({
      pricing: [...old],
    });
  }

  DeletePriceRow(index) {
    let data = [...this.state.pricing];
    let payloadData = {};
    if(this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    if(data[index]?._id){
      payloadData.pricingId = data[index]._id;
      this.setState({ isAPICall: true });
      tmsAction.removePricing(payloadData).then((response) => {
        let base_price = 0, x_charges = 0;
        let allPricings = response.pricing.filter((x) => x);
        allPricings = _.sortBy(allPricings, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
        if (allPricings && allPricings.length > 0) {
          allPricings.map((x) => {
            if (x) {
              if (x.name == "Base Price") {
                base_price +=  parseFloat(x.finalAmount)  ;
              } else {
                x_charges = (
                  parseFloat(x_charges) + parseFloat(x.finalAmount)
                ).toFixed(2);
              }
              x.manualFinalAmount = x.finalAmount;
            }
          });
        }
        this.setState({
          Total: response.totalAmount,
          pricing: allPricings,
          isAPICall: false,
          base_price,
          x_charges
        })
        toastr.show(`Your pricing has been deleted!`, "success");
      }).catch((err) => {
        this.setState({ isAPICall: false })
        toastr.show("Something went wrong! Please try again.", "error");
      });
    } else {
      this.setState({ addCharge: false},() =>{
        this.props.blockTabChange(false)
        this.clearAddPricing();
      })
    }
  }

  onBillingDateUpdate() {
    if (this.state.billing_date) {
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.billingDate = moment(this.state.billing_date).toISOString();
      tmsAction.updateBillingDate(payloadData).then((response) => {
        toastr.show("The billing date has been updated.", "success");
      }).catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
      });
    } else {
      toastr.show("Please select a billing date.", "warning");
    }
  }

  sendEmail(isDownload) {
    let progressField = "isEmailSendInProcess";
    let formData = new FormData();
    if (this.props.loads) {
      formData.append("reference_number", this.props.loads.reference_number);
    } else {
      formData.append(
        "reference_number",
        this.props.selectedLoads.reference_number
      );
    }
    if (this.state.inpSubject) {
      formData.append("subject", this.state.inpSubject);
    }
    if (this.state.inpBody) {
      formData.append("body", this.state.inpBody);
    }
    if (isDownload) {
      progressField = "isDownloadInProcess";
      formData.append("mergeDownload", true);
    }
    if (this.state.extraEmail.length > 0) {
      formData.append("email", this.state.extraEmail.join(","));
    }
    if (this.state.ccEmail.length > 0) {
      formData.append("cc", this.state.ccEmail.join(","));
    }
    if(this.state.isRateConfirm){
      formData.append("isRateConfirm", true)
    }
    this.setState({ [progressField]: true });
    sendBillingEmail(formData)
      .then((result) => {
        this.setState({ [progressField]: false, showConfirmModel: false });
        if (isDownload) {
          const fileName = `${
            this.props.loads
              ? this.props.loads.reference_number
              : this.props.selectedLoads.reference_number
          }-invoice.pdf`;
          var byteArray = new Uint8Array(result.data.data.data);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: "application/octet-stream" })
          );
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          toastr.show("Your email has been sent!", "success");
          this.setState({ showConfirmModel: false, isRateConfirm : false });
        }
      })
      .catch(() => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ [progressField]: false,  isRateConfirm : false });
      });
  }
  async send_Email(isDownload) {
    return new Promise((resolve, reject)=>{
      let load = {};
      let progressField = "";
      if (this.props.loads) {
      load = this.props.loads
      } else {
       load = this.props.selectedLoads
      }
      let query = {
        typeOfDocument: invoice,
      };
      if(this.state.isRateConfirm){
        query.typeOfDocument = rateConf
      }
      if (load.reference_number) {
        query.reference_number = load.reference_number 
      }       
      if (!isDownload) {
        query.isEmailing = true
      }
      if (!isDownload) {
        progressField = "isEmailSendInProcessNew";
       
      } else {
        progressField = "isDownloadInProcess";
      }
      this.setState({ [progressField]: true });
          if(isDownload)   {
            
            getInvoiceDesign(query).then((result)=>{
              const fileName = `${this.props.loads
                  ? this.props.loads.reference_number
                  : this.props.selectedLoads.reference_number
              }-invoice.pdf`;
              var byteArray = new Uint8Array(result.data[0].data);
              var a = window.document.createElement("a");
              a.href = window.URL.createObjectURL(
                new Blob([byteArray], { type: "application/octet-stream" })
              );
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              resolve(byteArray)
              this.setState({ [progressField]: false })
            }).catch(err => {toastr.show("Failed to download invoice","error"); this.setState({ [progressField]: false });})
          }  else{
          
            let formData = {};
            if (this.props.loads) {
              formData.reference_number = this.props.loads.reference_number
            } else {
              formData.reference_number= this.props.selectedLoads.reference_number
              
            }
            if (this.state.inpSubject) {
              formData.subject = this.state.inpSubject
            }
            if (this.state.inpBody) {
              formData.body= this.state.inpBody
            }
           
            if (this.state.extraEmail.length > 0) {
              formData.email= this.state.extraEmail.join(",")
            }
            if (this.state.ccEmail.length > 0) {
              formData.cc= this.state.ccEmail.join(",")
            }
             formData.typeOfDocument = query.typeOfDocument
            emailInvoice({ ...formData, ...query })
            .then(res => { 
              toastr.show("email sent", "success");
              this.setState({ [progressField]: false, showConfirmModel: false, isRateConfirm : false });
            }).catch(err => {
                     toastr.show("Something went wrong, Please try again", "error");
                     this.setState({ [progressField]: false, isRateConfirm : false })})
          }                                                                     
    })
  }

  handleextraEmailChange(extraEmail) {
    this.setState({ extraEmail });
  }
  handleccEmailChange(ccEmail) {
    this.setState({ ccEmail });
  }

  handleChange(e) {
    const note = this.state[e.target.name];
    note.note = e.target.value;
    this.setState({ [e.target.name] : note });
    this.saveNotesDebounce(e.target.name,e.target.value);
  }

  dynamicStringByLoadInfo(str, data) {
    var allData = str.split(" ");
    var pattern = /\@(.*?)\@/g;
    allData.filter((obj) => {
      let vals = obj.match(pattern);
      if (vals) {
        vals.forEach((key) => {
          if (data.type_of_load === "IMPORT" && key === "@bookingNo@") {
            str = str.replace(key, data.callerbillLandingNo || "");
          } else if (key === "@referenceNo@") {
            str = str.replace(key, data.secondaryReferenceNo || "");
          } else if (key === "@invoiceNo@" || key === "@LoadNo@") {
            str = str.replace(key, data.reference_number || "");
          } else if (key === "@consigneeName@") {
            str = str.replace(key, data.consigneeName || "");
          } else if (key === "@weight@") {
            str = str.replace(key, data.totalWeight || "");
          } else {
            str = str.replace(key, data[key.substr(1, key.length - 2)] || "");
          }
        });
      }
    });
    return str;
  }

  setLoadEmails(load, document) {
      if(load && load.caller){
        this.setState({extraEmail: load.caller.billingEmail ? load.caller.billingEmail.split(',').filter(Boolean) : [load.caller.email]})
      }
    const updateState = {
      showConfirmModel: load,
    };
    const emails = [];
    const cc = [];
    let editorState;
    let carrierId;
    let body;
    let subject;
    if (getStorage("currentUserRole") == "fleetmanager") {
      let loggedInUser = JSON.parse(getStorage("loggedInUser"));
      carrierId = loggedInUser.fleetManager.carrier;
    } else {
      carrierId = getStorage("currentUserID");
    }
    
    body =
      this.state.settings &&
      this.state.settings.carrier &&
      this.state.settings.carrier.emailBody
        ? this.dynamicStringByLoadInfo(
            this.state.settings.carrier.emailBody,
            load
          )
        : `<p>Please review attached billing ${document || invoice}s.</p>`;
    subject =
      this.state.settings &&
      this.state.settings.carrier &&
      this.state.settings.carrier.emailSubject
        ? this.dynamicStringByLoadInfo(
            this.state.settings.carrier.emailSubject,
            load
          )
        : `${document == rateConf ? rateConf : 'Billing'} for Load ${load.reference_number}`;

    const processedHTML = DraftPasteProcessor.processHTML(body);
    const contentState = ContentState.createFromBlockArray(processedHTML);
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.moveFocusToEnd(editorState);
    updateState.inpSubject = subject;
    updateState.inpBody = body;
    emails.push(load.caller.billingEmail || load.caller.email);
    cc.push(load.carrier.carrier.billingEmail);
    cc.push(load.caller.extraEmail);
    if (load.customerEmployee && load.customerEmployee.length > 0) {
      load.customerEmployee.forEach((employee, i) => {
        if (employee.billingEmail) {
          cc.push(employee.billingEmail);
        }
      });
    }
    updateState.emails = emails.compact(true);
    updateState.cc = cc.compact(true);
    if(document == rateConf){
      updateState.isRateConfirm  = true
    }else{
      updateState.isRateConfirm  = false
    }
    this.setState(updateState);
    this.setState({ editorState });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      inpBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  redirectToRates = (chargeItem) => {
    if (chargeItem.chargeType === CHARGE_TYPES.CUSTOMER_RATES.name) {
      browserHistory.push('/tms/load-price-settings?rateId=' + chargeItem.customerRateId);
    }
    else if (chargeItem.chargeType === CHARGE_TYPES.DYNAMIC_PRICING.name) {
      browserHistory.push('/dynamic-pricing?rateId=' + chargeItem.dynamicPricingId);
    }
  }

  confirmChargeConlicts = (index, charge, conflictedCharge, chargeCode) => {
    confirmCharge(
      (confirm, selectedCharge) => {
        if (confirm) {
          const _pricing = cloneDeep(this.state.pricing);
          _pricing[index] = selectedCharge;

          const otherConflicts = this.props.chargeConflicts && this.props.chargeConflicts.filter((cc) => cc.name !== selectedCharge.name);
          if (selectedCharge.chargeType === CHARGE_TYPES.DYNAMIC_PRICING.name && otherConflicts.length > 0) {
            otherConflicts.forEach((cc) => {
              const cIndex = this.state.pricing.findIndex((price) => price.name === cc.name);
              if (cIndex >= -1) _pricing[cIndex] = cc;
            })
          }
            this.conflictedPricing(selectedCharge, index)
        }
      },
      {
        charge,
        conflictedCharge,
        chargeCode
      }
    );
  }

  conflictedPricing = (pricing, index) => {
    let payload = { };
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }

    if(pricing.chargeType) {
      payload.preferredRateType = pricing.chargeType;
    }
    tmsAction.conflictedCharges(payload)
      .then((response) => {
        this.setState({ isSuccess: true,rowIndex : index, isFailed : "transition-white-field"});
        toastr.show(`Your pricing has been updated!`, "success");
        setTimeout(() => {
          this.setState({ isSuccess: false});
        }, 400);
      })
      .catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({isFailed: "background-error"}, () => {
          setTimeout(() => {
            this.setState({isFailed: "background-failure"})
          }, 400);
        });
      })
  }

  handleSubmit = (e) => {
    if(!this.state.name){
      this.setState({nameColor: "background-error"}, () => {
        setTimeout(() => {
          this.setState({nameColor: "background-failure"})
        }, 400);
      });
      return false;
    }
    if(this.state.selectedCell) return 
    if(
      !this.state.isAPICall &&
      this.state.isEdited &&
      this.state.name) {
      let payload = {};
      if(this.props.selectedLoads) {
        payload.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payload.reference_number = this.props.loads.reference_number;
      }
      payload.pricing = {
        "name" : this.state.name,
        "amount" : this.state.amount,
        "chargePerDay" : this.state.chargePerDay,
        "chargePerMinute" : this.state.chargePerMinute,
        "finalAmount" : (parseFloat(this.state.finalAmount) || 0).toFixed(2),
        "unit" : (parseFloat(this.state.unit) || 0).toFixed(2),
        "freeDays" : this.state.freeDays,
        "perType" : this.state.perType,
        "chargeType" : this.state.chargeType,
        "customerRateId" : null,
        "dynamicPricingId" : null,
        "description" : this.state.description,
        "type" : this.state.type,
      }

      this.setState({isAPICall: true, rowIndex: this.state.pricing.length}, () => this.props.blockTabChange(true))
      tmsAction.addPricing(payload)
        .then((response) => {
          this.setState({ Total: response.totalAmount, isAPICall: false,isEdited:false, isSuccess: true, isFailed : "transition-white-field"});
          let x_charges = 0, base_price = 0;
          response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
          response.pricing.map((x) => {
            if (x) {
              if (x.name == "Base Price") {
                base_price +=  parseFloat(x.finalAmount)  ;
              } else {
                x_charges = (
                  parseFloat(x_charges) + parseFloat(x.finalAmount)
                ).toFixed(2);
              }
              x.manualFinalAmount = x.finalAmount;
            }
          });
          this.setState({base_price, x_charges});
          // toastr.show(`Your pricing has been added`, "success");
          setTimeout(() => {
            this.setState({ isSuccess: false});
          }, 400);
          this.setState({pricing : response.pricing, addCharge: false,selectedCell:null}, () => {
            document.querySelector(`#addChargeFocus`)?.focus()
            this.props.blockTabChange(false)});
          this.clearAddPricing();
        })
        .catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({isFailed: "background-error", isAPICall: false }, () => {
            this.props.blockTabChange(false);
            setTimeout(() => {
              this.setState({isFailed: "background-failure"})
            }, 400);
          });
        })
    }
  }

  clearAddPricing = () => {
    this.setState({
      name : "",
      description : "",
      unit : 1,
      amount : 0,
      finalAmount : "",
      perType : "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
      type : "",
      manualFinalAmount : 0
    })
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

 nextFunction() {
    if(["approveCheckbox","chkApproved","lblApproved"].includes(this.state.nextClick)){
       this.onApprove();
       this.DeletePriceRow( this.state.pricing?.length)
       this.setState({nextClick : null})
    }else if(["approveBillingCheckbox","chkApprovedBilling","lblApprovedBilling"].includes(this.state.nextClick)){
      this.onApprovedBillling()
      this.DeletePriceRow( this.state.pricing?.length)
      this.setState({nextClick : null})
   }
  }
  checkStatusOfLoad (){
    let selectedLoads;
    if (this.props.loads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    return selectedLoads.status
  }
  handleClickOutside(event) {
     const status = this.checkStatusOfLoad();
    if(this.props.isShowingPrompt && this.props.isBlockingTabChange    ){
       if(document.querySelector("#continueWthoutSaving")?.contains(event.target)){
        this.nextFunction()
       }else if(document.querySelector("#cancleChanges")?.contains(event.target)){
        this.setState({nextClick : null})
       }
    }
    if((document.querySelector("#approveBillingCheckbox")?.contains(event.target) ||
       document.querySelector("#approveCheckbox")?.contains(event.target) || 
       document.querySelector("#chkApprovedBilling")?.contains(event.target) ||
       document.querySelector("#chkApproved")?.contains(event.target) ||
       document.querySelector("#lblApprovedBilling")?.contains(event.target) ||
       document.querySelector("#lblApproved")?.contains(event.target)) && this.props.isBlockingTabChange && ['COMPLETED','UNAPPROVED'].includes(status) ){
       this.props.showPrompt(true)
       this.setState({nextClick : event.target?.id})
    }else{
    if (
        this.wrapperRef &&
        !this.wrapperRef.contains(event.target) &&
        !document.querySelector("[role='tablist']")?.contains(event.target) &&
        (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
    ) {
      if(this.state.selectedCell) return 
        this.handleSubmit()
    }
   }
  }
  updateRowPricing = (index) => {
    this.setState({rowIndex : index})
    let pricing = this.state.pricing;
    let currentRow = pricing[index];
    currentRow.finalAmount = (parseFloat(currentRow.finalAmount) || 0).toFixed(2);
    currentRow.unit = (parseFloat(currentRow.unit) || 0).toFixed(2);
    currentRow.chargePerDay = parseInt(currentRow.chargePerDay) || 0;
    currentRow.freeDays = parseInt(currentRow.freeDays) || 0;
    delete currentRow.subChargeType;
    currentRow.chargeType = MANUALLY_CHANGED;
    if(!currentRow.name){
      this.setState({nameColor: "background-error"}, () => {
        setTimeout(() => {
          this.setState({nameColor: "background-failure"})
        }, 400);
      });
      return false;
    }
      let payload = {};
      if(this.props.selectedLoads) {
        payload.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payload.reference_number = this.props.loads.reference_number;
      }
      delete currentRow.manualFinalAmount;
      payload.pricing = currentRow;
      this.setState({ isAPICall: true });
      tmsAction.updatePricing(payload)
        .then((response) => {
          if(response){
            this.setState({ Total: response.totalAmount, isSuccess: true, isAPICall: false });
            let x_charges = 0, base_price = 0;
            response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
            response.pricing.map((x) => {
              if (x) {
                if (x.name == "Base Price") {
                  base_price +=  parseFloat(x.finalAmount)  ;
                } else {
                  x_charges = (
                    parseFloat(x_charges) + parseFloat(x.finalAmount)
                  ).toFixed(2);
                }
                x.manualFinalAmount = x.finalAmount;
              }
            });
            this.setState({base_price, x_charges});
            // toastr.show(`Your pricing has been updated!`, "success");
            setTimeout(() => {
              this.setState({ isSuccess: false});
            }, 400);
            this.setState({pricing : response.pricing, addCharge: false}, () => this.props.blockTabChange(false));
          }
        })
        .catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({isFailed: "background-error", isAPICall: false }, () => {
            this.props.blockTabChange(false);
            setTimeout(() => {
              this.setState({isFailed: "background-failure"})
            }, 400);
          });
        })
    
  }

  newRowChanges(e) {
    const { name, value } = e.target;
    const old = [...this.state.pricing];

    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    let { type, amount, unit, freeDays, chargePerDay, perType, manualFinalAmount, finalAmount } = this.state;
    if (name === "type") { 
      finalAmount = type === "fixed" ? amount  : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "amount") { 
      finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "finalAmount" && value) {
      manualFinalAmount = value;
    } else if (["unit", "freeDays", "chargePerDay",'perType'].includes(name)) {
      let computedAmount = (parseFloat(unit) - parseFloat(freeDays)) * parseFloat(chargePerDay);
      if (this.state.name) {
        if (computedAmount >= parseFloat(manualFinalAmount || 0)) {
          finalAmount = computedAmount.toFixed(2);
        } else {
          finalAmount = parseInt( manualFinalAmount || 0).toFixed(2);
        }
      }

      if((name == "perType" && value =="percentage") || (name !== "perType" && perType == "percentage") ){
        finalAmount = (this.state.base_price * unit / 100).toFixed(2);
      }
      
    }

    this.setState({
      finalAmount: finalAmount,
      manualFinalAmount: manualFinalAmount
    });
  }


  disableAddSub = (value) => {
    this.setState({ addSub: value })
  }
  onApprove = (e) => {
    this.setState({
      APPROVED: true,
      UNAPPROVED: false,
      REBILLING: false,
      BILLING: false,
      APPROVEDBILLING: false,
      statusname: "APPROVED",
    })
    let payloadData = {};
    if(this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    payloadData.status = "APPROVED";
    tmsAction.approveStatus(payloadData).then((response) => {
      toastr.show("Load Status changed to APPROVED.", "success");
    }).catch((err) => {
      toastr.show("Something went wrong! Please try again.", "error");
    });
  }

  onUnapprove = () => {
    this.setState({
      APPROVED: false,
      UNAPPROVED: true,
      REBILLING: false,
      BILLING: false,
      APPROVEDBILLING: false,
      statusname: "UNAPPROVED",
    })
    let payloadData = {};
    if(this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    payloadData.status = "UNAPPROVED";
    tmsAction.unapproveStatus(payloadData).then((response) => {
      toastr.show("Load Status changed to UNAPPROVED.", "success");
    }).catch((err) => {
      toastr.show("Something went wrong! Please try again.", "error");
    });
  }

  onRebilling = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("rebill_loads")) {
      toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
    } else {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: true,
        BILLING: false,
        APPROVEDBILLING: false,
        statusname: "REBILLING",
      })
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.status = "REBILLING";
      tmsAction.rebillingStatus(payloadData).then((response) => {
        toastr.show("Load Status changed to REBILLING.", "success");
      }).catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
      });
    }
  }

  onBilling = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("approve_invoice")) {
      toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
    } else {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: true,
        APPROVEDBILLING: false,
        statusname: "BILLING",
      })
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.status = "BILLING";
      tmsAction.billingStatus(payloadData).then((response) => {
        toastr.show("Load Status changed to BILLING.", "success");
          this.setLoadEmails(
            this.props.selectedLoads
              ? this.props.selectedLoads
              : this.props.loads
          );
        this.setState({ showConfirmModel: true });
      }).catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
      });
    }
  }

  onApprovedBillling = (e) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if(loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("approve_invoice")) {
      toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
    } else {
      this.setState({
        APPROVED: false,
        UNAPPROVED: false,
        REBILLING: false,
        BILLING: false,
        APPROVEDBILLING: true,
        statusname: "APPROVEDBILLING",
      })
      let payloadData = {};
      if(this.props.selectedLoads) {
        payloadData.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payloadData.reference_number = this.props.loads.reference_number;
      }
      payloadData.status = "APPROVED";
      tmsAction.approveStatus(payloadData).then((response) => {
        toastr.show("Load Status changed to APPROVED.", "success");
        payloadData.status = "BILLING";
        tmsAction.billingStatus(payloadData).then((response) => {
          this.setLoadEmails(
            this.props.selectedLoads
              ? this.props.selectedLoads
              : this.props.loads
          );
          this.setState({ showConfirmModel: true });
          toastr.show("Load Status changed to BILLING.", "success");
        }).catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
        });
      }).catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
      });
    }
  }

  onAddSelectCharge = (name, value) => {
    this.setState({
      name: value.value,
      nameColor: "transition-white-field",
      isEdited: true,
      selectedCell: null,
    });

    if (this.pricingDescription) this.pricingDescription.focus();
    const data = this.findMatchingPricingRule(value.value);
    if (data) {
      let units = 0;
      const freeDays = data.freeDays || 0;
      const chargePerDay = data.amount || 0;
      
      if (data.type === "fixed") units = 1;

      let finalAmount = (units - freeDays) * chargePerDay;
      if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;

      this.setState({
        description: data.description,
        amount: finalAmount,
        finalAmount: finalAmount,
        unit: units,
        perType: data.type,
        freeDays: freeDays > 0 ? freeDays : 0,
        chargePerDay: chargePerDay,
      });
    }
  }

  handleKeyDown(e) {
    if(e.keyCode === 13) {
        e?.target?.blur();
        this.unselectCell()
        this.handleSubmit();
        e.stopPropagation()
    }
  }

  focusOnButton(){
    const element = document?.getElementById("addChargeFocus")
    element.style.border = "1px solid black"
  }

  handleOnKeyDown = (e, disabled) => {
    if(e.keyCode === 13) {
      !disabled && this.setState({addCharge: true}, () => {this.props.blockTabChange(true); this.selectCell("add-Charge")}) 
      const element = document?.getElementById("addChargeFocus")
      element.style.border = "none"
    } else {
      const element = document?.getElementById("addChargeFocus")
      element.style.border = "none"
      return
    }
  }

    // for subscribing to firebase
    subscribeToFirebase = () => {
      this.unsubscribeFromFirebase();
      const currentUserRole = JSON.parse(getStorage("currentUser"));
      let carrierId = '';
      if(currentUserRole!=='carrier'){
        const carrierDetail = JSON.parse(getStorage("carrierDetail"));
        carrierId = carrierDetail._id;
      }else{
        const carrierDetail = JSON.parse(getStorage("loggedInUser"));
        carrierId = carrierDetail._id;
      }
      this.firebaseRef.current = newDb.ref(`${carrierId}/lastUpdatedLoad`);
      this.firebaseRef.current.on('value', (snapshot) => {
        try {
          const values=snapshot.val();
          const data=values && JSON.parse(values)
          this.setState({approvalStatus:data.approvalStatus})
        } catch (error) {
          console.error("Error: ", error)
        }
      });
    }
  
    // for unsubscribing to firebase
    unsubscribeFromFirebase = () => {
      if(this.firebaseRef.current) {
        this.firebaseRef.current.off();
      }
    }

  renderReturn = () => {
    var disabled = true;
    var billing_date = null;
    var selectedLoads;
    if (this.props.loads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    if (selectedLoads) {
      disabled = [
        "APPROVED",
        "BILLING",
        "REBILLING",
        "PARTIAL_PAID",
        "FULL_PAID",
      ].includes(selectedLoads.status);
      billing_date = selectedLoads.billingDate;
    }
    let showSave = false;
    if (selectedLoads) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ==
          selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") == selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "customer" &&
        getStorage("currentUserID") == selectedLoads.addedBy._id
      ) {
        showSave = true;
      }
      else if (
        JSON.parse(getStorage("loggedInUser")).role === "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
      ) {
        disabled = true;
      }
      else if (
        ["customer", "fleetcustomer"].includes(JSON.parse(getStorage("loggedInUser")).role)
      ) {
        disabled = true;
      }
    }
    
    let { pricing } = this.state;
    const {chargeCode} = this.props;

    return (
      <React.Fragment>
        <div className="tab">
          <div className="card p-15 d-flex flex-row align-items-center justify-content-between mb-10 border-top">
            <ul className="nav nav-compact" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link  ${
                    this.state.isCustomerTabActive === "main-customer" ?
                    "active" : ""
                  }`}
                  href="#!"
                  onClick={() => {
                    if(this.props.isBlockingTabChange){
                      tabChangeConfirm((change)=>{
                        if(change && this.props.isBlockingTabChange){
                          this.clearAddPricing();
                          this.setState({ addCharge: false })
                          if(this.props.setBillingActiveTab) {
                            this.props.setBillingActiveTab("main-customer")
                            this.handleCustomerTabActive("main-customer")
                          }
                        } else return
                      })
                    } else {
                      if(this.props.setBillingActiveTab) {
                        this.props.setBillingActiveTab("main-customer")
                        this.handleCustomerTabActive("main-customer")
                      }
                    }
                  }}
                >
                  Main Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link  ${
                    this.state.isCustomerTabActive === "sub-customer" ?
                    "active" : ""
                  }`}
                  href="#!"
                  onClick={() => {
                    if(this.props.isBlockingTabChange){
                      tabChangeConfirm((change)=>{
                        if(change && this.props.isBlockingTabChange){
                          this.clearAddPricing();
                          this.setState({ addCharge: false })
                          if(this.props.setBillingActiveTab) {
                            this.props.setBillingActiveTab("sub-customer")
                            this.handleCustomerTabActive("sub-customer")
                          }
                        } else return
                      })
                    } else {
                      if(this.props.setBillingActiveTab) {
                        this.props.setBillingActiveTab("sub-customer")
                        this.handleCustomerTabActive("sub-customer")
                      }
                    }
                  }}
                >
                  Sub Customer
                </a>
              </li>
            </ul>
            {this.state.isCustomerTabActive === "sub-customer" ? (
              <button
                className="btn btn-primary"
                onClick={() => this.addCustomerPriceRows()}
                disabled={this.state.addSub}
              >
                <IconPlus className="mr-2" />
                Add Sub Bill
              </button>
            ) : (
              <div>
              <button
                className="ml-auto btn btn-outline-light mr-2"
                onClick={() => {
                  this.setCcEmail()
                  this.setLoadEmails(
                    this.props.selectedLoads
                      ? this.props.selectedLoads
                      : this.props.loads,
                      rateConf
                  );
                }}
                disabled={this.state.addCharge}
              >
                <IconSendEmail className="mr-3" />
                Send Rate Confirmation
              </button> 
              </div>
            )}
          </div>
          {this.state.isCustomerTabActive === "main-customer" && (
            <div id="main-customer">
              <div className="card mb-0 p-15 d-flex flex-row justify-content-between">
                <ul className="nav nav-compact" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "bill" ? "active" : ""
                      }`}
                      href="#!"
                      onClick={() =>{
                        if(this.props.isBlockingTabChange){
                          tabChangeConfirm((change)=>{
                            if(change){
                              this.clearAddPricing();
                              this.setState({ addCharge: false })
                              this.handleSubExpBillTabActive("bill")
                              this.props.handleExpenseTab()
                            } else return
                        })
                      } else this.handleSubExpBillTabActive("bill")
                    }}
                    >
                      Invoice
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link  ${
                        this.state.isSubExpBillTabActive === "sub_expense" ?
                        "active" : ""
                      }`}
                      href="#!"
                      onClick={() =>{
                        if(this.props.isBlockingTabChange){
                          tabChangeConfirm((change)=>{
                            if(change){
                              this.clearAddPricing();
                              this.setState({ addCharge: false })
                              this.handleSubExpBillTabActive("sub_expense")
                              this.props.handleExpenseTab()
                            } else return
                        })
                      } else this.handleSubExpBillTabActive("sub_expense")
                    }
                      }
                    >
                      Expense
                    </a>
                  </li>
                </ul>
                <div className="d-flex flex-row align-items-center">
                  {selectedLoads &&
                    ["BILLING", "PARTIAL_PAID"].indexOf(selectedLoads.status) >
                      -1 && (
                      <button
                        className="ml-auto btn btn-outline-light mr-2"
                        onClick={() => {
                          this.setLoadEmails(
                            this.props.selectedLoads
                              ? this.props.selectedLoads
                              : this.props.loads
                          );
                        }}
                      >
                        <IconSendEmail className="mr-2" />
                        Email
                      </button>
                    )}

                  <a
                    download={`${
                      this.props.selectedLoads &&
                      this.props.selectedLoads.reference_number
                    }-invoice.pdf`}
                    id="downloadPdf"
                    href={this.state.downloadedContent}
                  ></a>

                  {this.state.isSubExpBillTabActive === "bill" && 
                   
                
                    <button
                      disabled={this.state.isDownloadInProcess}
                      className="btn btn-outline-light "
                      onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ? this.send_Email(true) :  this.sendEmail(true)}
                    >
                      {this.state.isDownloadInProcess  ? (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <IconDownload className="mr-2" />
                      )}
                      Download PDF
                    </button> 
                    }
                  
                </div>
              </div>

              <div className="mb-1">
                {this.state.isSubExpBillTabActive === "bill" && (
                  <div id="bills" className="table">
                    <div className="position-relative">
                    {(this.state.approvalStatus && pricing.length>0 && this.state.status==='BILLING') &&
                      <div className="d-flex p-15 mb-10 justify-content-end">
                        <button class={`btn btn-sm mr-2 ${this.state.approvalStatus==='PENDING' ? 'btn-primary' : this.state.approvalStatus==='REJECTED' ?'btn-danger':'btn-success'}`}>INVOICE APPROVAL: {this.state.approvalStatus}</button>
                      </div>
                     }
                    <table className="table table-card table-card--wrap mb-10 table-billing">
                      {this.state.isAPICall && this.state.addCharge && 
                        <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                            <span
                                class="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Processing...
                        </div>
                      }
                      <thead>
                        <tr>
                          <th width="200" className="z-3">Name of Charge #</th>
                          <th width="300" className="z-3">Description</th>
                          <th width="210" className="text-right z-3">Price</th>
                          <th width="160" className="z-3">Unit Count</th>
                          <th width="150" className="z-3">Unit of Measure</th>
                          <th width="100" className="text-right z-3">Free Units</th>
                          <th width="100" className="text-right z-3">Per Units</th>
                          <th width="42"></th>
                          <th width="42"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pricing &&
                          pricing.length > 0 &&
                          pricing.map((item, key) => {
                            let conflictedCharge = null;
                            if(this.props.chargeConflicts && this.props.chargeConflicts.length) {
                              conflictedCharge = this.props.chargeConflicts.find((charge) => item.chargeType !== MANUALLY_CHANGED && charge.name === item.name )
                            }
                            let chargeValue = this.chargeCodeList && this.chargeCodeList.find((d) => d.value === item.name)
                            let charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive)
                            return (
                              <React.Fragment>
                                <PricingComponent
                                  index={key}
                                  key={key}
                                  charges={charges}
                                  chargeValue={chargeValue}
                                  pricingchanges = {this.pricingchanges}
                                  item ={item}
                                  disabled ={disabled}
                                  conflictedCharge = {conflictedCharge}
                                  chargeCode ={chargeCode}
                                  showSave= {showSave}
                                  addCharge = {this.state.addCharge}
                                  DeletePriceRow = {this.DeletePriceRow}
                                  keyPressOnNumberInput ={this.keyPressOnNumberInput}
                                  confirmChargeConlicts={this.confirmChargeConlicts}
                                  redirectToRates = {this.redirectToRates}
                                  isAPICall = {this.state.isAPICall}
                                  isSuccess={this.state.isSuccess}
                                  updateRowPricing = { this.updateRowPricing }
                                  rowIndex={this.state.rowIndex}
                                  setWrapperRef = {this.setWrapperRef}
                                  handleSubmit = {this.handleSubmit}
                                  selectedCell={this.state.selectedCell}
                                  selectCell={this.selectCell}
                                  unselectCell={this.unselectCell}
                                />
                              </React.Fragment>
                            );
                          })
                        }       
                        { this.state.addCharge &&
                          <tr className={`select-custom-input newdriverrow shadow-md ${this.state.isFailed}`} 
                            onKeyDown={(e) => this.handleKeyDown(e)}
                            ref={this.setWrapperRef}
                            // onKeyDown={(e) => {this.setState({selectedCell:null});e.keyCode === 13 && this.handleSubmit() }}
                          >
                            <td className={`${this.state.nameColor} cell-1 text-dark ${!this.state.name ? 'requied_hover' : ""} 
                            ${this.state.selectedCell==="add-Charge" ? 'newdriverrow td-clicked':""}
                            pl-2 pr-1`} 
                                style={{ transition: 'background .4s ease-out' }}
                                onClick={() => this.selectCell("add-Charge")}
                              >
                                <div className="d-flex justify-content-between align-items-center text-dark">
                                  {this.state.name?.toUpperCase() || <span className="text-muted">Select Charge</span>}
                                  <IconAngleArrowDown className='' />
                                  {this.state.selectedCell === "add-Charge" &&
                                   <SelectCustomComponent
                                    name='charges'
                                    searchable={true}
                                    refClassName="cell-1"
                                    listData={this.chargeCodeList.filter((d) => d.isActive)}
                                    keyName="chargeName"
                                    onSelect={this.onAddSelectCharge}
                                    unselectCell={this.unselectCell}
                                  />}
                                </div>
                            </td>
                            <td className={`px-0 ${this.state.selectedCell==="description" ? 'newdriverrow td-clicked' : ""}`}
                              onClick={() => this.selectCell("description")}
                            >
                              <input
                                ref={(i) => { this.pricingDescription = i; }} 
                                type="text"
                                className={`form-control border-0`}
                                name="description"
                                value={this.state.description || ""}
                                onChange={(e) =>
                                  this.setState({ description : e.target.value, isEdited: true })
                                }
                                disabled={disabled}
                                placeholder="Add description..."
                                autoComplete="off"
                                onBlur={()=>this.unselectCell()}
                              />
                            </td>
                            {/* {this.state.name === "Fuel" ? (
                              <td 
                                className={`lint px-0 ${this.state.selectedCell==="finalAmount" && 'newdriverrow td-clicked'}`}
                                onClick={() => this.selectCell("finalAmount")}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="mr-1 d-flex w-100">
                                    <input
                                      className="form-control border-0 text-right"
                                      type="number"
                                      disabled={disabled}
                                      name="amount"
                                      min={0}
                                      value={this.state.amount || ""}
                                      onChange={(e) => {
                                        this.setState({amount: e.target.value, isEdited: true},() => {
                                          this.newRowChanges({
                                              target: {
                                              value: e.target.value,
                                              name: "amount",
                                              },
                                          });
                                        });
                                      }}
                                      autoComplete="off"
                                      onBlur={()=>this.unselectCell()}
                                    />
                                  </div>
                                  <div className="mr-1 d-flex w-100 custom-select-border price-component">
                                    <CustomSelect
                                      size="small"
                                      placement="right"
                                      placeholder="Select..."
                                      style={newSmallSelectStyle}
                                      options={[
                                        {
                                          value: "fixed",
                                          label: "$",
                                        },
                                        {
                                          value: "percentage",
                                          label: "%",
                                        },
                                      ]}
                                      value={
                                        this.state.type && this.state.type === "fixed"
                                          ? {
                                              value: "fixed",
                                              label: "$",
                                            }
                                          : {
                                              value: "percentage",
                                              label: "%",
                                            }
                                      }
                                      onChange={({ value }) =>{
                                        this.setState({ type: value, isEdited: true },() => {
                                          this.newRowChanges({
                                            target: {
                                              value: value,
                                              name: "type",
                                            },
                                          });
                                        })
                                        this.unselectCell()
                                      }}
                                      onBlur={()=> this.unselectCell()}
                                    />
                                  </div>
                                  <div className="d-flex w-100">
                                    <input
                                      min={0}
                                      placeholder = "0.00"
                                      onKeyDown={(e) => {
                                        this.keyPressOnNumberInput(e);
                                      }}
                                      className="form-control border-0 text-right"
                                      type="number"
                                      disabled={disabled}
                                      name="finalAmount"
                                      value={this.state.finalAmount || ""}
                                      onChange={(e) => {
                                        this.setState({finalAmount: e.target.value, isEdited: true}, () => {
                                          this.newRowChanges({
                                            target: {
                                              value: e.target.value,
                                              name: "finalAmount",
                                            },
                                          });
                                        })
                                      }}
                                      autoComplete="off"
                                      onBlur={()=>this.unselectCell()}
                                    />
                                  </div>
                                </div>
                              </td>
                            ) : ( */}
                              <td className={`px-0 ${this.state.selectedCell==="finalAmount" ? 'newdriverrow td-clicked' : ""}`}
                                onClick={() => this.selectCell("finalAmount")}
                              >
                                <span data-tip data-for={`discount${pricing.length}`}>
                                  <input
                                    min={0}
                                    placeholder = "0.00"
                                    type="number"
                                    className={`form-control border-0 text-right`}
                                    disabled={disabled}
                                    name="finalAmount"
                                    value={this.state.finalAmount || ""}
                                    onChange={(e) => {
                                      this.setState({finalAmount: e.target.value, isEdited: true},() => {
                                        this.newRowChanges({
                                          target: {
                                            value: e.target.value,
                                            name: "finalAmount",
                                          },
                                        });
                                      })
                                    }}
                                    autoComplete="off"
                                    onBlur={()=>this.unselectCell()}
                                  />
                                </span>
                                {this.state.discountApplied &&
                                  <ReactTooltip effect="solid" id={`discount${pricing.length}`}>
                                    <span>A discount has been applied</span>
                                  </ReactTooltip>
                                }
                              </td>
                            {/* )} */}

                              <td 
                                className={`px-0 ${this.state.selectedCell==="unit" && 'newdriverrow td-clicked'}`}
                                onClick={() => this.selectCell("unit")}
                              >
                              <div>
                                <NewPriceInput
                                  name="unit"
                                  value={this.state.unit}
                                  onChange={(val) => {
                                    this.setState({ unit : val, isEdited: true},() => {
                                      this.newRowChanges({
                                        target: {
                                          value: val,
                                          name: "unit",
                                        },
                                      });
                                    })
                                  }}
                                  disabled={disabled}
                                  onBlur={()=>this.unselectCell()}
                                />
                              </div>
                            </td>

                            <td className={`px-1 pl-2 cell-5 ${this.state.selectedCell==="perType" ? 'newdriverrow td-clicked' : ""}`}
                                onClick={() => this.selectCell("perType")}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  {this.state.perType || <span className="text-muted">Select...</span>}
                                  <IconAngleArrowDown className='' />
                                  {this.state.selectedCell === "perType" &&
                                   <SelectCustomComponent
                                    name='perType'
                                    searchable={true}
                                    refClassName="cell-5"
                                    listData={options}
                                    keyName="label"
                                    onSelect={(name, value) => 
                                      this.setState({ perType : value.value, isEdited: true,selectedCell:null },() => {
                                        this.newRowChanges({
                                          target: {
                                            value: value.value,
                                            name: "perType",
                                          },
                                        });
                                        if (this.pricingFreeUnit) this.pricingFreeUnit.focus();
                                      })
                                    }
                                    unselectCell={this.unselectCell}
                                  />}
                                </div>
                            </td>
                            <td 
                                className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell==="freeDays" && 'newdriverrow td-clicked'}`}
                                onClick={() => {
                                  if(!this.state.perType)return
                                  this.selectCell("freeDays")
                                }}
                            >
                              <input
                                ref={(i) => { this.pricingFreeUnit = i; }} 
                                min={0}
                                type="number"
                                className={`form-control border-0 text-right`}
                                name="freeDays"
                                onKeyDown={(e) => {
                                  this.keyPressOnNumberInput(e);
                                }}
                                disabled={!this.state.perType || disabled || this.state.perType === "percentage"  }
                                value={this.state.freeDays || ""}
                                onChange={(e) => {
                                  this.state.perType &&
                                  this.setState({ freeDays : e.target.value, isEdited: true }, () => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "freeDays",
                                      },
                                    });
                                  })
                                }}
                                placeholder="0"
                                autoComplete="off"
                                onBlur={()=>this.unselectCell()}
                              />
                            </td>
                            <td 
                                className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell==="chargePerDay" && 'newdriverrow td-clicked'}`}
                                onClick={() => {if(!this.state.perType)return 
                                  this.selectCell("chargePerDay")}}
                            >
                              <input
                                min={0}
                                type="number"
                                className={`form-control border-0 text-right`}
                                name="chargePerDay"
                                disabled={!this.state.perType || disabled || this.state.perType === "percentage" }
                                value={this.state.chargePerDay || ""}
                                onChange={(e) => {
                                  this.state.perType &&
                                  this.setState({ chargePerDay : e.target.value, isEdited: true },() => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "chargePerDay",
                                      },
                                    });
                                  })
                                }}
                                placeholder="0"
                                autoComplete="off"
                                onBlur={()=>this.unselectCell()}
                              />
                            </td>
                            <td className="px-1">
                              
                            </td>
                            {showSave && (
                              <td className="d-flex align-items-center justify-content-center">
                                <button
                                  className="btn btn-xs btn-circle bg-soft-danger"
                                  disabled={disabled}
                                    onClick={() => {
                                      this.setState({
                                        nameColor: "transition-white-field"
                                      })
                                      !this.state.isAPICall && this.DeletePriceRow(pricing.length)
                                    }}
                                >
                                  <IconMinus className="text-danger" />
                                </button>
                              </td>
                            )}
                          </tr>
                        }                
                      </tbody>
                    </table>
                    </div>

                    <div className="mb-10">
                        <button className="btn btn-white btn-sm text-primary add-charge-button"
                          onKeyDown={(e) => this.handleOnKeyDown(e, disabled)}
                          id="addChargeFocus"
                          onFocus={this.focusOnButton}
                        >
                        <a
                          className="text-primary font-weight-500"
                          onClick={() =>{
                            const element = document?.getElementById("addChargeFocus")
                            element.style.border = "none"
                            !disabled && this.setState({addCharge: true}, () => {this.props.blockTabChange(true); this.selectCell("add-Charge")}) 
                          }}
                        >
                          <IconPlus className="mr-2" />
                          Add Charge
                        </a>
                        </button>
                        </div>
                  </div>
                )}
                {this.state.isSubExpBillTabActive === "sub_expense" && (
                  <div id="expenses">
                    <NewLoadExpense
                      loads={this.props.loads}
                      id={this.props.loads.reference_number}
                      key={`sub_${this.props.loads.reference_number}`}
                      handleExpenseUpdate={(submit) => {
                        this.handleExpenseUpdate = submit;
                      }}
                      changeSavingLoadStatus={this.props.changeSavingLoadStatus}
                      chargeCodeList={this.chargeCodeList}
                      blockTabChange={this.props.blockTabChange}
                      selectedLoads={this.props.selectedLoads}
                      newRowChanges={this.newRowChanges}
                      selectedCell={this.state.selectedCell}
                      isBlockingTabChange={this.props.isBlockingTabChange}
                    />
                  </div>
                )}

                <div className="card d-flex flex-row align-items-center justify-content-between mb-3 p-15 bill_status">
                  <div className="d-flex flex-row">
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.APPROVED === true &&
                        selectedLoads &&
                        !(
                          ["COMPLETED", "UNAPPROVED"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-primary-400"
                          : this.state.APPROVED === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check" id="approveCheckbox">
                        <input
                          type="checkbox"
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          value="approve"
                          name="APPROVED"
                          id="chkApproved"
                          checked={ this.state.statusname === "APPROVED" }
                          onChange={(e) => 
                            this.onApprove(e)
                          }
                          disabled={
                            selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                        />
                        <label className="form-check-label" for="chkApproved" id="lblApproved">
                          Approve
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.UNAPPROVED === true &&
                        selectedLoads &&
                        !(
                          ["APPROVED", "REBILLING"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-primary-400"
                          : this.state.UNAPPROVED === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="UNAPPROVED"
                          id="chkUnapproved"
                          checked={this.state.statusname === "UNAPPROVED" }
                          onChange={(e) => 
                            this.onUnapprove(e)
                          }
                          disabled={
                            selectedLoads &&
                            !(
                              ["APPROVED", "REBILLING"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                        />
                        <label className="form-check-label" for="chkUnapproved">
                          Unapprove
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.REBILLING &&
                        (this.state.UNAPPROVED ||
                          (selectedLoads && selectedLoads.status !== "BILLING"))
                          ? "badge-primary-400"
                          : this.state.REBILLING === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="REBILLING"
                          id="chkRebill"
                          checked={this.state.statusname === "REBILLING"}
                          onChange={(e) => 
                            this.onRebilling(e)
                          }
                          disabled={
                            this.state.UNAPPROVED ||
                            (selectedLoads &&
                              selectedLoads.status !== "BILLING")
                          }
                        />
                        <label className="form-check-label" for="chkRebill">
                          Rebill
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-1 ${
                        this.state.BILLING &&
                        (this.state.UNAPPROVED ||
                          (selectedLoads &&
                            !(
                              ["APPROVED", "REBILLING"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )))
                          ? "badge-primary-400"
                          : this.state.BILLING === true
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="BILLING"
                          id="chkBilling"
                          checked={this.state.statusname === "BILLING"}
                          onChange={(e) => 
                            this.onBilling(e)
                          }
                          disabled={
                            this.state.UNAPPROVED ||
                            (selectedLoads &&
                              !(
                                ["APPROVED", "REBILLING"].indexOf(
                                  selectedLoads.status
                                ) > -1
                              ))
                          }
                        />
                        <label className="form-check-label" for="chkBilling">
                          Invoice
                        </label>
                      </div>
                    </div>
                    <div
                      className={`rounded-3 py-2 px-2 mr-2 ${
                        this.state.APPROVEDBILLING &&
                        selectedLoads &&
                        !(
                          ["COMPLETED", "UNAPPROVED"].indexOf(
                            selectedLoads.status
                          ) > -1
                        )
                          ? "badge-primary-400"
                          : this.state.APPROVEDBILLING
                          ? "badge-primary-400"
                          : "badge-light"
                      }`}
                    >
                      <div className="form-check" id="approveBillingCheckbox">

                        <input
                          className="form-check-input form-check-input--radio-check rounded-circle"
                          type="checkbox"
                          name="APPROVEDBILLING"
                          id="chkApprovedBilling"
                          checked={this.state.statusname === "APPROVEDBILLING"}
                          onChange={(e) => {
                            this.onApprovedBillling(e)
                          }}
                          disabled={
                            selectedLoads &&
                            !(
                              ["COMPLETED", "UNAPPROVED"].indexOf(
                                selectedLoads.status
                              ) > -1
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          for="chkApprovedBilling"
                          id="lblApprovedBilling"
                        >
                          Approve & Invoice
                        </label>
                      </div>
                    </div>
                    
                  </div>

                  <div className="d-flex align-items-center">
                    <span className="text-dark font-weight-500 mr-3">
                      Billing Date
                    </span>
                    <div className="input-wrapper" style={{ width: "124px"}}>
                      <DateWrapper
                          dateFormat="DD MMM YYYY"
                          displayTimeZone={this.userTimeZone}
                          onChange={(e) => this.setState({
                            billing_date: moment(e).toISOString()
                          },() => 
                            this.onBillingDateUpdate()
                          )}
                          defaultDate={this.state.billing_date
                            ? moment(this.state.billing_date)
                            : billing_date
                            ? moment(billing_date)
                            : ""
                          }
                          dontShowSelectDate={true}
                          isShowTime={false}
                          hideShowSelectDate={true}
                          className="right-0"
                          placeholder="Select Date..."
                      />
                      <div
                        className="input-icon"
                      >
                        <IconCalendar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row no-gutters">
                <div className="col-md-4">
                  <div className="card rounded-5 p-20 mr-3">
                    <h5 className="mb-4 font-weight-bold">
                      <CustomIconNotes className="mr-2" />
                      Summary
                    </h5>
                    <div className="summary">
                      <div className="row mb-15">
                      <div className="col-6 text-muted font-size-regular">Base Price</div>
                        <div className="col-6 font-14 font-weight-500 text-right">
                          <NumberFormat
                            value={this.state.base_price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-6 text-muted font-14">
                          Accessorials
                        </div>
                        <div className="col-6 text-right font-14 font-weight-500">
                          <NumberFormat
                            value={this.state.x_charges}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row mb-10">
                        <div className="col-6 font-size-regular font-weight-500 text-primary">
                          Total
                        </div>
                        <div className="col-6 font-size-medium font-weight-500 text-primary text-right">
                          <NumberFormat
                            value={parseFloat(this.state.Total).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card rounded-5 p-20 mr-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-4 font-weight-bold">Billing Notes</h5>
                      {this.state.billingNoteLoader &&
                        <LoaderBar></LoaderBar>
                      }
                      {this.state.billingNote.createdAt && this.state.billingNote.createdBy && 
                        (<h6 className="font-10 text-gray-500">Last Edited By {this.state.billingNote.createdBy} <br/>
                          at {moment(this.state.billingNote.createdAt).tz(this.userTimeZone).format(`${this.userTimeFormat ? 'MM/DD/YY HH:mm' : 'MM/DD/YY hh:mm A'}`)}</h6>)
                      }
                    </div>
                    <textarea
                      name="billingNote"
                      type="message"
                      className="border rounded px-3 pt-3 text-break font-size-regular"
                      style={{ minHeight: "105px", resize: "none" }}
                      value={this.state.billingNote.note ? this.state.billingNote.note : ""}
                      onChange={(e) =>{
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card rounded-5 p-20">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-4 font-weight-bold">Load Notes</h5>
                      {this.state.officeNoteLoader &&
                        <LoaderBar></LoaderBar>
                      }
                      {this.state.officeNote.createdBy && this.state.officeNote.createdAt && 
                        (<h6 className="font-10 text-gray-500">Last Edited By {this.state.officeNote.createdBy} <br/>
                        at {moment(this.state.officeNote.createdAt).tz(this.userTimeZone).format(`${this.userTimeFormat ? 'MM/DD/YY HH:mm' : 'MM/DD/YY hh:mm A'}`)}</h6>)
                      }
                    </div>
                    <textarea
                      name="officeNote"
                      type="message"
                      className="border rounded px-3 pt-3 font-size-regular"
                      style={{ minHeight: "105px", resize: "none" }}
                      value={this.state.officeNote.note ? this.state.officeNote.note : ""}
                      onChange={(e) =>{
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.isCustomerTabActive === "sub-customer" && (
            <div id="sub-customer">
              {selectedLoads && (
                <NewAdditionalPricing
                  selectedLoads={selectedLoads}
                  load={selectedLoads}
                  TMSCustomers={this.props.TMSCustomers}
                  updateList={this.props.updateList}
                  addCustomerPriceRows={(addrow) => {
                    this.addCustomerPriceRows = addrow;
                  }}
                  updateAdditionalPricing={(submit) => {
                    this.updateAdditionalPricing = submit;
                  }}
                  changeSavingLoadStatus={this.props.changeSavingLoadStatus}
                  blockTabChange={this.props.blockTabChange}
                  isBlockingTabChange={this.props.isBlockingTabChange}
                  disableAddSub={this.disableAddSub}
                />
              )}
            </div>
          )}

        </div>

        {/* Send Email Modal */}
        <Modal className="backdrop_modal_open" show={this.state.showConfirmModel} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label className="col-form-label">To</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.extraEmail.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };
                      return val;
                    })}
                    onChange={(val) => {
                      this.handleextraEmailChange(
                        _.compact(
                          val.map((i) => {
                            if (!validateEmail(i.value)) return i.value;
                          })
                        )
                      );
                    }}
                  />

                  <span className="text-gray-400 d-block mt-1">
                    You can add multiple email addresses
                  </span>
                </div>
                <div className="form-group">
                  <label className="col-form-label">CC/BCC</label>

                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                    value={this.state.ccEmail.map((i) => {
                      const val = {
                        label: i,
                        value: i,
                      };

                      return val;
                    })}
                    onChange={(val) => {
                      this.handleccEmailChange(
                        _.compact(
                          val.map((i) => {
                            if (!validateEmail(i.value)) return i.value;
                          })
                        )
                      );
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    value={this.state.inpSubject}
                    onChange={(e) => {
                      this.setState({ inpSubject: e.target.value });
                    }}
                  />
                </div>
                <div className="editor">
                  <label className="col-form-label">Message</label>
                  <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    ref="draftRef"
                    onEditorStateChange={this.onEditorStateChange}
                    handlePastedText={() => false}
                    editorClassName="editorClassName"
                    placeholder="Enter Messsage"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => this.setState({ showConfirmModel: false })}
            >
              Cancel
            </button>
            
           
             <button
              disabled={
                this.state.isEmailSendInProcessNew ||
                this.state.extraEmail.length === 0
              }
              className="btn btn-primary"
              onClick={() => JSON.parse(getStorage("carrierDetail")).isUsingNewDocDesign ? this.send_Email() : this.sendEmail()}
            >
              {this.state.isEmailSendInProcessNew ? (
                <i className="uil uil-cloud-download mr-1"></i>
              ) : (
                ""
              )}
              Send Email
            </button> 
            
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_billing") ||
          (loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer &&
            loggedInUser.permissions.includes("customer_employee_load_billing"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapStateToProps(state) {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBillOfLading);

const options = [
  { value: "perday", label: "perday" },
  { value: "perhour", label: "perhour" },
  { value: "permile", label: `per${convertUnit().toLowerCase()}` },
  {
    value: "perpound",
    label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
  },
  { value: "fixed", label: "fixed" },
  { value: "percentage", label: "percentage" },
];

const CHARGE_TYPES = {
  CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
  DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
  SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
  MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
  PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
  ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
  CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
};