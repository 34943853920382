import React, { Fragment } from 'react';
import MessageList from '../MessageListComponent';
import GroupsMessageList from '../MessageListComponent/GroupsMessageList';
import ChatSearch from '../ChatSearch';

// [chatTabs] is the list of tabs
// [selectedChatTabID] is the selected tab ID
// [onChatTabClicked] handler for chatTabClicked
// [onBackButtonClicked] callback for when the back button is clicked on the chatMessage
const ChatDropdownComponent = ({
    chatTabs,
    selectedChatTabID,
    onChatTabClicked,
    createGroupModal,
    counts,
    displayChatBox,
    isCreateGroupModal,
    createGroup
}) => {

    return (
        <div className="card card--shadow-5 popdrop popdrop--right popdrop--bottom-no-space rounded-0 animated" style={{ width: '400px' }}>
            {/* Nav Tabs */}
            <ul className="nav nav-tabs nav-tabs-custom nav-justified align-items-center nav-border-bottom">
                {chatTabs.map((chatTab) => {
                    return (
                        <li className="nav-item" key={Math.random()}>
                            <a
                                // active class here sets the underline color of the tab
                                className={`nav-link ${chatTab.chatTabID == selectedChatTabID ? 'active' : ''}`}
                                href="javascript:void(0)"
                                onClick={() => {
                                    if (onChatTabClicked != null) {
                                        onChatTabClicked(chatTab.chatTabID);
                                    }
                                }}
                            >
                                {chatTab.label}
                                {counts && counts[`${chatTab.chatTabID}UnreadMessage`].sum('count') > 0 && <span className="badge badge-primary badge-rounded ml-1 badge-sm">{counts[`${chatTab.chatTabID}UnreadMessage`].sum('count')}</span>}
                            </a>
                        </li>
                    );
                })}
            </ul>
            <div className="nav-tab-content">
                {/* Message List */}
                {chatTabs.map((chatTab) => {
                    {
                        /* if true, returns the chatMessageList else returns the message of selected */
                    }
                    return (
                        chatTab.chatTabID == selectedChatTabID &&
                        (
                            <div className="p-10" key={Math.random()}>
                                {selectedChatTabID !== "groups" && (
                                    <Fragment>
                                        <ChatSearch
                                            chatTabID={chatTab.chatTabID}
                                            label={chatTab.label}
                                            displayChatBox={displayChatBox}
                                            createGroup={createGroup}
                                        />
                                        <MessageList messageList={chatTab.messageSnippets} key={Math.random()} displayChatBox={displayChatBox} />
                                    </Fragment>)
                                }

                                {selectedChatTabID === "groups" && (
                                    <GroupsMessageList  createGroupModal={createGroupModal} isCreateGroupModal={isCreateGroupModal} messageList={chatTab.messageSnippets} key={Math.random()} displayChatBox={displayChatBox} createGroup={createGroup} />)}
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default ChatDropdownComponent;
