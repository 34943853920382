import $ from "jquery";
import _ from "lodash";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import { debounce } from "throttle-debounce";

import config from "../../../config";
import {
  duplicateLoad,
  getLoad,
  getMasterTermsBySearch,
  getTerminal,
} from "../../../pages/tms/services";
import { displayName, getStorage, isNewModal, toastr } from "../../../services/Common.services";
import { IconBell, IconChatFill, IconLoads } from "../../Common/Icons";
import Avatar from "../Avatar";
import "../FloatingChat/style.css";
import * as actionCreators from "./actionCreators";
import "./autocomplete.css";
import { masterSearchHeader } from "./masterSearchHeader";
import QuickAdd from "./QuickAdd";
import ChatComponents from "../FloatingChat/ChatComponents";
import * as alertActionCreators from '../../../pages/admin/AlertSystem/actionCreators'
import "../FloatingChat/style.css";
import DeliquetAccountAlert from '../DelinquetAccount'
import TimeZoneAlert from "../TimeZoneAlert";
import moment from "moment";
import { getLoadDetail } from "../../../pages/tms/Dispatcher/actionCreators";
import CustomerServiceSideBar from "../../CustomerServiceSideBar/CustomerServiceSideBar";
import PrintModal from "../../../pages/tms/Dashboard/Print";
import confirm from "../ConfirmAert";
import LoadInfoModal from "../../LoadInfoModal/LoadInfoModal";
import quickbookPaths from "./paths";
import { listenQBCustomerSocketMessage, listenQBSocketMessage } from "../../CustomHooks/listenQBSocket";

const firebase = config.firebase;
const pathname = window.location.pathname;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allLoads: [],
      showNotification: false,
      allNotifications: [],
      searchType: "ALL",
      searchText: "",
      dropdownOpen: false,
      alerts:[],
      loggedInUser:{},
      loadingAlert:true,
      showAlertValue:0,
      hideLastAlert:false,
      showLoadModel :false,
      reference_number:null,
      activeCustomerServiceSidebarTab:"load"
    };
    this.loggedInUser = JSON.parse(getStorage("loggedInUser"));
    this.carrierDetail = JSON.parse(getStorage("carrierDetail"));
    // this.onSelect = this.onSelect.bind(this);
    // this.searchByLoad = debounce(1000, this.getLoads);
    this.searchByLoad = debounce(1000, this.dataFilterOnType);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.openLoadInfoModal = this.openLoadInfoModal.bind(this);
  }

  searchByLoad = _.debounce(() => {
    this.dataFilterOnType();
  }, 500);

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    let self = this;
    if (getStorage("loggedInUser")) {
      const user = JSON.parse(getStorage("loggedInUser"));
      let carrierId = "";
      this.setState({ user });
      if (user.role === "carrier") {
        carrierId = user._id;
      } else if (user.role === "fleetmanager") {
        carrierId = user.fleetManager.carrier;
        if(JSON.parse(getStorage("loggedInUser"))?.fleetManager?.isCustomer){
          return;
        }
      }
      if (getStorage("loggedInUser")) {
        if(getStorage('currentUserRole')!=='admin'){
          const {email,_id,role:userRole} =JSON.parse(getStorage("loggedInUser"));
          const carrierDetails =JSON.parse(getStorage("carrierDetail"));
          const portsMarkets = carrierDetails?.carrier?.portMarket;
          const timeZone =getStorage("timeZone");
          this.setState({loggedInUser:{email,_id}})
          this.props.alertActions.getApprovedAlertsAction({user:userRole,market:portsMarkets,userId:_id}).then((result) => {
            let alerts=[]
            for(let i=0;i<result.data.length;i++){
              const compareDate = moment().tz("YYYY-MM-DD HH:mm:ss",timeZone);  
              const startDate = moment(result.data[i].startDate).tz("YYYY-MM-DD HH:mm:ss",timeZone);  
              const endDate = moment(result.data[i].endDate).tz("YYYY-MM-DD HH:mm:ss",timeZone);  
              const isBetween = compareDate.isBetween(startDate, endDate);  
              if(isBetween){
                alerts.push(result.data[i]);
              }
            }
            this.setState({alerts:alerts,loadingAlert:false},()=>{
              !this.state.loadingAlert && 
              alerts.length!==0 && 
              this.state.hideLastAlert===false && this.props.showShowTopAlert();
            })
          }).catch((error) => {
            console.log(error)
          });
        }
      }
      
      // Listen to QB Attempt Event
      if (["fleetmanager","carrier"].includes(user.role)) {
          this.socketQBStatus = firebase.database().ref(`${carrierId}/qb_attempt`);
          listenQBSocketMessage(this.socketQBStatus, this.isFirebaseInitialize)

          this.socketCustomerQBStatus = firebase.database().ref(`${carrierId}/qb_customer_attempt`);
          listenQBCustomerSocketMessage(this.socketCustomerQBStatus, this.isCustomerFirebaseInitialize)
        }
    }

    let selectedCarrier = JSON.parse(getStorage("@Carrier"));
    if (selectedCarrier) {
      this.props.actions.setSelectedCarrier(selectedCarrier);
    }
    getTerminal().then((data) => {
      this.setState(
        { terminals: !data.data.data ? [] : data.data.data },
        () => {
          this.props.dispatch({
            type: "ADD_TERMINAL_DATA",
            payload: this.state.terminals,
          });
        }
      );
    });

    
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);

    if(this.socketQBStatus) {
      this.socketQBStatus.off("value")
    }

    if(this.socketCustomerQBStatus) {
      this.socketCustomerQBStatus.off("value")
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.state.dropdownOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdownOpen: false, searchText: "" })
    }
  }

  getLoads(search) {
    const params = {
      limit: 10,
      skip: 0,
      searchTerm: search,
      isIncludeDeleted: true,
    };
    if (this.state.user.role === "customer") {
      params.caller = [this.state.user.customer];
    }
    getLoad(params).then((response) => {
      this.setState({ allLoads: response.data.data });
    });
  }

  toggleNotification() {
    $("#ress1").toggleClass("show");
  }

  toggle = () => this.setState({ dropdownOpen: false, searchText:''});

  // onSelect(value, item) {
  //   this.setState({ reference_number: "" });
  //   let reference_num = encodeURIComponent(value);
  //   browserHistory.push(
  //     `/tms/customer-service/load?reference_number=${reference_num}`
  //   );
  //   this.props.dispatch({
  //     type: "TMS_SELECTED_FROM_SEARCH",
  //     payload: item,
  //   });
  // }
  toggleSideBar() {
    $("#main-wrapper").toggleClass("hide-sidebar");
    if ($("#main-wrapper").hasClass("hide-sidebar")) {
    } else {
    }
  }
  onFilter(searchType) {
    this.setState({ searchType }, () => {
      this.dataFilterOnType();
    });
  }

  dataFilterOnType() {
    const { searchType, searchText } = this.state;
    if (searchText) {
      let params = {
        searchType: searchType || "ALL",
        searchText: searchText.trim(),
      };
      getMasterTermsBySearch(params).then((response) => {
        let omitedData = _.omitBy(response.data.data, _.isEmpty);
        this.setState({ allLoads: [omitedData], dropdownOpen: true });
      });
    } else {
      this.setState({ allLoads: [] });
    }
  }

  openLoadInfoModal(refNumber){
    if(refNumber)this.setState({reference_number:refNumber, showLoadModel:true});
  }

  handleSearchClick(searchKey, searchItem) {
    switch (searchKey) {
      case "loads":
        isNewModal() ?
        this.openLoadInfoModal(searchItem.reference_number)
        : browserHistory.push(
          "/tms/customer-service/load?reference_number=" +
            encodeURIComponent(searchItem.reference_number)
        );
        break;
      case "drivers":
        browserHistory.push("/tms/Driver?id=" + searchItem._id);
        break;
      case "customers":
        browserHistory.push("/tms/customer?id=" + searchItem._id);
        break;
      case "containerypes":
        browserHistory.push("/tms/Container/Type?id=" + searchItem._id);
        break;
      case "containerOwners":
        browserHistory.push("/tms/Container/Owner?id=" + searchItem._id);
        break;
      case "chassisTypes":
        browserHistory.push("/tms/Chassis/Type?id=" + searchItem._id);
        break;
      case "chassisOwners":
        browserHistory.push("/tms/Chassis/Owner?id=" + searchItem._id);
        break;
      default:
        break;
    }
    this.setState({ dropdownOpen: false ,searchText:""});
  }

  filterSearchTabsByUserType = (tab) => {
    if (
      this.loggedInUser.role === "customer" ||
      (this.loggedInUser.role === "fleetmanager" &&
        this.loggedInUser.fleetManager &&
        this.loggedInUser.fleetManager.isCustomer)
    )
      return false;

    return true;
  };

  handleCloseAlert =()=>{
    const readByData = this.state.alerts[this.state.showAlertValue].readBy;
    readByData.push(this.state.loggedInUser._id);
    this.props.alertActions.readAlertAction({readBy:readByData,id:this.state.alerts[this.state.showAlertValue]._id});
    if(this.state.alerts.length!==this.state.showAlertValue+1){
      this.setState({showAlertValue:this.state.showAlertValue+1})
    }else{
      this.setState({hideLastAlert:true});
      this.props.closeShowTopAlert();
    }
  }


  render() {
    let count = 0;

    this.props.unreadUserMessages &&
      this.props.unreadUserMessages.forEach((element) => {
        count += element.count;
      });
    let { user } = this.props;
    let { searchText, searchType, allLoads } = this.state;
    let showQuickAndEmaiil = true;
    if (
      ["customer", "fleetmanager", "fleetcustomer"].indexOf(
        getStorage("currentUserRole")
      ) > -1
    ) {
      if (
        getStorage("currentUserRole") == "customer" ||
        getStorage("currentUserRole") == "fleetcustomer"
      ) {
        showQuickAndEmaiil = false;
      }
      if (
        getStorage("currentUserRole") === "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
      ) {
        showQuickAndEmaiil = false;
      }
    }
    return (
      <React.Fragment>
        {
          !this.state.loadingAlert && 
          this.state.alerts.length!==0 && 
          this.state.hideLastAlert===false &&
          <div className="top__alert__div d-flex align-items-center justify-content-center">
          <div className="alert__message">
            {this.state.alerts[this.state.showAlertValue].message}
            {
              (this.state.alerts[this.state.showAlertValue].hyperLink && this.state.alerts[this.state.showAlertValue].appealText) && 
                <a href={this.state.alerts[this.state.showAlertValue].hyperLink} target="_blank" rel="noopener noreferrer" className="text-white ml-10">
                  <span>{this.state.alerts[this.state.showAlertValue].appealText}</span>
                </a>
            }
          </div>
          <div className="alert__message__close" onClick={this.handleCloseAlert}><i class="uil uil-times"></i></div>
        </div>
        }
      
        <header className="topbar" data-navbarbg="skin6">
          <DeliquetAccountAlert/>
          <TimeZoneAlert />
          <nav className="navbar top-navbar navbar-expand-md navbar-light">
            <div className="d-flex align-items-center">
              <a
                onClick={() => {
                  this.toggleSideBar();
                }}
                className="font-18 ml-3 d-lg-none"
                style={{ color: "#555b6d" }}
                href="javascript:void(0)"
                data-sidebartype="mini-sidebar"
              >
                <i className="uil uil-bars"></i>
              </a>
              <div
                ref={this.setWrapperRef}
                className={`app-search header-search position-relative ${
                  searchText ? "bg-gray-50 py-15" : ""
                }`}
              >
                <span className={`search-icon ${searchText ? "" : ""}`}>
                  {" "}
                </span>
                <div className="position-relative" >
                  <input
                    type={"search"}
                    className="form-control input-search-left"
                    placeholder="Search Everything..."
                    onChange={(e) =>
                      this.setState({ searchText: e.target.value }, () => {
                        if (this.state.searchText) {
                          this.searchByLoad();
                        } else {
                          this.setState({ dropdownOpen: false, allLoads: [] });
                        }
                      })
                    }
                    value={searchText}
                    style={{ width: "454px" }}
                  />
                </div>

                <Dropdown isOpen={this.state.dropdownOpen} >
                  <DropdownMenu
                    style={{ background: "transparent", boxShadow: "none" }}
                  >
                    <div
                      className="card card--shadow-5 popdrop popdrop--center popdrop--bottom-no-space w-max rounded-0"
                      style={{ width: 454 }}
                    >
                      {/* Nav Tabs */}
                      <ul className="nav nav-tabs nav-tabs-custom nav-tabs-custom--sm nav-justified align-items-center nav-border-bottom">
                        {masterSearchHeader
                          .filter((tab) => this.filterSearchTabsByUserType(tab))
                          .map((tab, i) => (
                            <li className="nav-item tooltip-label hover-gray-50" key={i}>
                              <a
                                className={`nav-link position-relative ${
                                  searchType == tab.key ? "active" : ""
                                }`}
                                href="javascript:void(0)"
                                onClick={() => this.onFilter(tab.key)}
                              >
                                {tab.icon ? (
                                  <div>{tab.icon}</div>
                                ) : (
                                  <span className="">{tab.title}</span>
                                )}
                                <div
                                  className="tooltip bs-tooltip-bottom bg-gray-700 text-white w-max rounded-10 px-3  py-2"
                                  data-placement="bottom-center"
                                >
                                  <div className="arrow"></div>
                                  {tab.title}
                                </div>
                              </a>
                            </li>
                          ))}
                      </ul>

                      {/* Nav Tab Content */}
                      <div
                        className="nav-tab-content overflow-auto"
                        style={{ height: "calc(100vh - 125px)" }}
                      >
                        {allLoads.map((item, k) => {
                          let mappedData = Object.entries(item);
                          if (
                            this.loggedInUser.role === "customer" ||
                            (this.loggedInUser.role === "fleetmanager" &&
                              this.loggedInUser.fleetManager &&
                              this.loggedInUser.fleetManager.isCustomer)
                          )
                            mappedData = mappedData.filter(
                              (data) => data[0] === "loads"
                            );
                          return (
                            <div key={k}>
                              <div className="">
                                {!mappedData.length ? (
                                  <div className="p-4">No data found</div>
                                ) : (
                                  mappedData.map((item, i) => {
                                    const tabItem = masterSearchHeader.find(
                                      (header) => header.apiText === item[0]
                                    );
                                    return (
                                      <Fragment>
                                        <div className="p-15">
                                          <h5 className="title text-muted mb-2">
                                            {tabItem && tabItem.icon2}
                                            {tabItem && tabItem.title}
                                          </h5>

                                          {item[1].map((items, key) => {
                                            return (
                                              <div
                                                onClick={() =>
                                                  this.handleSearchClick(
                                                    item[0],
                                                    items
                                                  )
                                                }
                                                className={`d-flex justify-content-between form-row ${
                                                  key !== item[1].length - 1
                                                    ? "border-bottom"
                                                    : ""
                                                } hover-gray-50 py-15`}
                                              >
                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                  {tabItem && tabItem.title}#
                                                    {items.isDeleted && (
                                                      <span className="badge badge-danger badge-pill ml-auto mr-3 font-medium px-2 py-1">
                                                        DELETED
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.company_name
                                                      : item[0] == "drivers"
                                                      ? items.name
                                                      : item[0] == "trucks"
                                                      ? items.equipmentID
                                                      : item[0] == "trailers"
                                                      ? items.equipmentID
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.company_name
                                                      : item[0] == "chassisOwners"
                                                      ? items.company_name
                                                      : item[0] == "chassisTypes"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : items.reference_number}
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                    {item[0] == "customers"
                                                      ? "Mobile"
                                                      : item[0] == "drivers"
                                                      ? "Mcn No."
                                                      : item[0] == "trucks"
                                                      ? "Truck"
                                                      : item[0] == "trailers"
                                                      ? "Trailor no"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? "Mobile"
                                                      : item[0] == "chassisOwners"
                                                      ? "Mobile"
                                                      : item[0] == "chassisTypes"
                                                      ? "Chasis"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : "Bol"}{" "}
                                                    #
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.mobile
                                                      : item[0] == "drivers"
                                                      ? items.driver.MCNumber
                                                      : item[0] == "trucks"
                                                      ? ""
                                                      : item[0] == "trailers"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.mobile
                                                      : item[0] == "chassisOwners"
                                                      ? items.mobile
                                                      : item[0] == "chassisTypes"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : items.bolbkg}
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                    {item[0] == "customers"
                                                      ? "Email"
                                                      : item[0] == "drivers"
                                                      ? "Licence No."
                                                      : item[0] == "trucks"
                                                      ? "Truck Name"
                                                      : item[0] == "trailers"
                                                      ? "Trailor"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? "Address"
                                                      : item[0] == "chassisOwners"
                                                      ? "Address"
                                                      : item[0] == "chassisTypes"
                                                      ? "Chasis No."
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : "Container"}{" "}
                                                    #
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.email
                                                      : item[0] == "drivers"
                                                      ? items.driver.licenceNumber
                                                      : item[0] == "trucks"
                                                      ? ""
                                                      : item[0] == "trailers"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.address
                                                      : item[0] == "chassisOwners"
                                                      ? items.address
                                                      : item[0] == "chassisTypes"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : items.containerNo}
                                                  </div>
                                                </div>

                                                <div className="col-md-3">
                                                  <div className="text-muted">
                                                    {item[0] == "customers"
                                                      ? "City"
                                                      : item[0] == "drivers"
                                                      ? "Mobile"
                                                      : item[0] == "trucks"
                                                      ? "Truck"
                                                      : item[0] == "trailers"
                                                      ? "Trailor no"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? "Scas"
                                                      : item[0] == "chassisOwners"
                                                      ? "State"
                                                      : item[0] == "chassisTypes"
                                                      ? "Chasis"
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : "Booking No"}{" "}
                                                    #
                                                  </div>
                                                  <div className="mt-1">
                                                    {item[0] == "customers"
                                                      ? items.city
                                                      : item[0] == "drivers"
                                                      ? items.driver.mobile
                                                      : item[0] == "trucks"
                                                      ? ""
                                                      : item[0] == "trailers"
                                                      ? items.name
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? items.scac
                                                      : item[0] == "chassisOwners"
                                                      ? ""
                                                      : item[0] == "chassisTypes"
                                                      ? ""
                                                      : item[0] ==
                                                        "containerOwners"
                                                      ? ""
                                                      : items.bolbkg}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <hr className="border-2 border-light m-0" />
                                      </Fragment>
                                    );
                                  })
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div
              className="navbar-collapse collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav float-right d-flex align-items-center">
                {showQuickAndEmaiil && (
                  <li
                    className="nav-item position-relative"
                    onClick={() => {
                      this.tooltip.tooltopRef = null;
                    }}
                  >
                    {isNewModal() ? <QuickAdd OpenLoadInfo={this.openLoadInfoModal} /> : <QuickAdd />}
                  </li>
                )}
                <ChatComponents />

                {showQuickAndEmaiil && (
                  <li className="nav-item">
                    <a href="#!" className="nav-link text-gray-300">
                      {/* <Link
                      data-tip
                      data-for="Email"
                      to="/emails"
                      id="floatingChatsSideBarHandler"
                      className="nav-link text-gray-300"
                      style={{ position: "relative" }}
                      onClick={() => {
                        this.tooltip.tooltopRef = null;
                        ReactTooltip.hide();
                      }}
                    > */}
                      <IconBell />
                    </a>
                    {/* </Link> */}
                    {/* <ReactTooltip
                      place="bottom"
                      effect="solid"
                      id="Email"
                      ref={(el) => (this.tooltip = el)}
                    >
                      <span>Email</span>
                    </ReactTooltip> */}
                  </li>
                )}

                <li className="nav-item ml-2 nav-item-image">
                  {user && user.profilePicture ? (
                    <Link
                      className="d-flex align-items-center text-muted"
                      to="/profile"
                    >
                      <Avatar size={36} src={user.profilePicture} />

                      <span className="ml-2">
                        Hi,
                        <span className="ml-1 font-medium text-dark">
                          {this.state.user ? this.state.user.name : ""}
                        </span>
                      </span>
                    </Link>
                  ) : (
                    <Link
                      className="d-flex align-items-center text-muted"
                      to="/profile"
                    >
                      <Avatar>{displayName(user && user.name)}</Avatar>
                      <span className="ml-2">
                        Hi,
                        <span className="ml-1 font-medium text-dark">
                          {this.state.user ? this.state.user.name : ""}
                        </span>
                      </span>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </header>
          {this.state.showLoadModel && 
            this.state.reference_number &&
            <LoadInfoModal 
              refNumber={this.state.reference_number} 
              hideLoadModal={()=>this.setState({showLoadModel:false,reference_number:null})}  
              />
          }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCarrier: state.HeaderReducers.selectedCarrier,
    unreadUserMessages: state.HomeReducer.unreadMessages,
    user: state.LoginReducer.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    alertActions: bindActionCreators(alertActionCreators, dispatch),
    dispatch: dispatch,
  };
};
Header = connect(mapStateToProps, mapDispatchToProps)(Header);

export default Header;