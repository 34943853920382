import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actionCreators from "./actionCreators";
import * as customerActionCreators from "../../Customer/actionCreators";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import * as Yup from "yup";
import _ from "underscore";
import $ from "jquery";
import { withFormik } from "formik";
import { toastr, getStorage } from "../../../../services/Common.services";
import { getTerminal } from "../../services";
import configuration from "../../../../config";
import { convertTMSStatusName } from "../../../../Components/Common/functions";
import { IdealBankElement } from "react-stripe-elements";
import confirm from "../../../../Components/Common/ConfirmAert";
import NumberFormat from "react-number-format";
import { smallSelectStyle } from "../../../../assets/js/select-style";
import {
  IconPen,
  IconPlus,
  IconTrash,
  IconEye,
} from "../../../../Components/Common/Icons";
import {
  StateFormInput,
  StateFormNumberFormat,
  StateFormSelect,
} from "../../../../Components/FormComponent/StateFormComponent";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import { Spinner } from "../../../../Components/Common/Components/Component";

let blank = {
  _id: undefined,
  email: "",
  billingEmail: "",
  password: "",
  password1: "",
  firstName: "",
  lastName: "",
  mobile: "",
  // admin: true,
  allTerminal: false,
  // mechanic: false,
  notificationEmails: [],
  emailStatusOptions: [],
  roles: "",
  permissions: [],
  isSendNotificationEmail:false,
  isSendEmailDeliveryAppointmentChanged:false,
  isSendEmailPricingDetailEmail:false,
  isSendEmailContainerAvailable:false,
  isSendEmailContainerAdd:false,
  isSendEmailLoadAccepted:false,
  isSendEmailContainerReadyForPickup:false,
};

const emailStatusOptions = [
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "COMPLETED",
].map((d) => ({
  value: d,
  label: convertTMSStatusName("IMPORT", d),
}));


const Permissions = {
  customer_shipments: "customer_shipments",
  customer_employee_load: [
    "customer_employee_load_info",
    "customer_employee_load_billing",
    "customer_employee_load_documents",
    "customer_employee_load_payments",
    "customer_employee_load_tracking",
    "customer_employee_load_messaging",
  ],
  dropped_containers: "dropped_containers",
  account_payable: "account_payable",
  customer: "customer",
  reports: "reports",
  // fleetmanagers: 'fleetmanagers',

  // customers: ['customer_service_dashboard', 'customer_service_dropped_container', 'customer_service_load_calendar', 'customer_service_bulk_upload', 'customer_service_customer_messaging', 'customer_service_drive_messaging', 'customer_service_deleted_load'],
  // dispatcher: 'dispatcher',
  // billings: ['billing_driver_pay', 'billing_driver_settlement', 'billing_settlement_setting', 'billing_account_receivable', 'billing_subaccount_receivable', 'billing_batch_apply_payment'],
  // safeties: ['safety_chassis', 'safety_truck', 'safety_trailer', 'safety_driver'],
  // others: ['other_permission', 'other_assign_truck', 'other_customer_rate', 'other_driver_pay_rate'],
  // container: ['other_container_type', 'other_container_size', 'other_container_owner', 'other_container_free_day'],
  // chassis: ['other_chassis_type', 'other_chassis_size', 'other_chassis_owner',],
  // profile: ['other_profile_customer', 'other_profile_chassis', 'other_profile_truck', 'other_profile_trailer', 'other_profile_driver',],
  // reports: ['reports_customer_revenue', 'reports_aging', 'reports_driver_revenue', 'reports_status_driver', 'reports_clock_report'],
  // accounts: 'account',
  // settings: 'settings'
};
const columns = ["First name", "Last Name", "Phone ", "Email", "Role"];
const employeePermission = [
  { label: "Loads", value: "customer_employee_load" },
  { label: "Dropped container", value: "dropped_containers" },
  { label: "Accounts payable", value: "account_payable" },
  { label: "Info", value: "customer_employee_load_info" },
  { label: "Billing", value: "customer_employee_load_billing" },
  { label: "Documents", value: "customer_employee_load_documents" },
  { label: "Payments", value: "customer_employee_load_payments" },
  { label: "Tracking", value: "customer_employee_load_tracking" },
  { label: "Service Messaging", value: "customer_employee_load_messaging" },
  { label: "Tracking", value: "customer_shipments" },
  { label: "Customer", value: "customer" },
  { label: "Reports", value: "reports" },
  { label: "Reports Customer Revenue", value: "reports_customer_revenue" },
  { label: "Reports Aging", value: "reports_aging" },
  { label: "Reports Driver Revenue", value: "reports_driver_revenue" },
  { label: "Reports Status Driver", value: "reports_status_driver" },
  { label: "Reports Clock Report", value: "reports_clock_report" },


];
class CustomerFleetManagers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFleetManagers: [],
      // admin: true,
      // mechanic: false,
      allTerminal: false,
      passwordShown: false,
      ...blank,
      fleetID: "",
      formErrors: {},
      formsTouched: {},
      selectedEmployee: {},
      employeePermission: [], 
      loading: false,
    };
    // this.handleChangeFleetManager = this.handleChangeFleetManager.bind(this);
    this.Terminals = [];
  }

  handleNotificationEmailsChange = (notificationEmails) => {
    notificationEmails = notificationEmails.filter((d) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
    );
    this.setState({ notificationEmails });
  };

  setValChange = (key, val) => { 
    var formsTouched = { ...this.state.formsTouched };
    formsTouched[key] = true;
    this.setState({ [key]: val, formsTouched }, () => {
      this.insertdata(false);
    });
  };

  componentWillReceiveProps(props) {
    if (props.CustomerID) {
      this.getdata(props.CustomerID);
    }
  }

  insertdata = (isSubmit = true) => {
    var errors = {};
    var touched = { ...this.state.formsTouched };
    if (!this.state._id) {
      withPasswordSchemaValidatorObject
        .validate(this.state, { abortEarly: false })
        .then((value) => {
          this.setState({ formErrors: {}, formsTouched: {} });
          isSubmit && this.insertdataa();
        })
        .catch((err) => {
          this.setState({ loader: false, isSaving: false });
          err &&
            err.inner &&
            err.inner.forEach((e, i) => {
              if (isSubmit && i + 1 === err.inner.length) {
                toastr.show(e.message, "error");
              }
              errors[e.path] = e.message;
              if (isSubmit) touched[e.path] = true;
            });
          this.setState({ formErrors: errors, formsTouched: touched }, () => {
            isSubmit && toastr.show("Please enter all required fields.", "warning");
          });
        });
    } else {
      withOutPasswordSchemaValidatorObject
      .validate(this.state, { abortEarly: false })
      .then((value) => {
        this.setState({ formErrors: {}, formsTouched: {} });
        isSubmit && this.insertdataa();
      })
      .catch((err) => {
          this.setState({ loader: false, isSaving: false });
          err &&
            err.inner &&
            err.inner.forEach((e, i) => {
              if (isSubmit && i + 1 === err.inner.length) {
                toastr.show(e.message, "error");
              }
              errors[e.path] = e.message;
              if (isSubmit) touched[e.path] = true;
            });
          this.setState({ formErrors: errors, formsTouched: touched }, () => {
            isSubmit && toastr.show("Please enter all required fields.", "warning");
          });
        });
    }
  };

  insertdataa() {
    let valid = true;

    let employPerm = [];
    if (valid) {
      let formData = new FormData();
      let newMobile;

      if (this.state.mobile) {
        newMobile = this.state.mobile.replace(/-|\s/g, "");
      }
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("mobile", newMobile);
      // formData.append("admin", this.state.admin);
      // formData.append("mechanic", this.state.mechanic);
      if (typeof this.state.isSendNotificationEmail === "boolean") {
        formData.append(
          "isSendNotificationEmail",
          this.state.isSendNotificationEmail
        );
      }
      if (
        this.state.notificationEmails &&
        this.state.notificationEmails.length > 0
      ) {
        formData.append(
          "notificationEmails",
          this.state.notificationEmails.toString()
        );
      }
      if (
        this.state.emailStatusOptions &&
        this.state.emailStatusOptions.length > 0
      ) {
        formData.append(
          "emailStatuses",
          JSON.stringify(this.state.emailStatusOptions.map((d) => d.value))
        );
      }
      if (this.props.CustomerID) {
        formData.append("CustomerID", this.props.CustomerID);
      }
      if (this.props.CustomerID) {
        formData.append("isCustomer", true);
      }
      if (this.state.billingEmail) {
        formData.append("billingEmail", this.state.billingEmail);
      }
      if (this.state.terminals && this.state.terminals.length > 0) {
        let lastTerminalValue = this.state.terminals.map((data) => {
          return data.value;
        });
        formData.append("terminals", JSON.stringify(lastTerminalValue));
      }
      // if (this.state.roles) {
      //   formData.append("roles", this.state.roles);
      // }
      if (this.state.employeePermission) {
        this.state.employeePermission.map((item) => {
          if (item.children && item.children.length) {
            item.checked && employPerm.push(item.values);
            const permissions =  item.children.filter((elem) => elem.checked === true);
            employPerm.push(...permissions.map(element => element.values));
          } else {          
          item.checked && employPerm.push(item.values);
          }
        })
        formData.append(
          "permissions",
          JSON.stringify(employPerm)
        );
      }
      if (
        typeof this.state.isSendEmailDeliveryAppointmentChanged === "boolean"
      ) {
        formData.append(
          "isSendEmailDeliveryAppointmentChanged",
          this.state.isSendEmailDeliveryAppointmentChanged
        );
      }
      if (typeof this.state.isSendEmailPricingDetailEmail === "boolean") {
        formData.append(
          "isSendEmailPricingDetailEmail",
          this.state.isSendEmailPricingDetailEmail
        );
      }
      if (typeof this.state.isSendEmailContainerAvailable === "boolean") {
        formData.append(
          "isSendEmailContainerAvailable",
          this.state.isSendEmailContainerAvailable
        );
      }
      if (typeof this.state.isSendEmailContainerAdd === "boolean") {
        formData.append(
          "isSendEmailContainerAdd",
          this.state.isSendEmailContainerAdd
        );
      }
      if (typeof this.state.isSendEmailLoadAccepted === "boolean") {
        formData.append(
          "isSendEmailLoadAccepted",
          this.state.isSendEmailLoadAccepted
        );
      }
      if (typeof this.state.isSendEmailContainerReadyForPickup === "boolean") {
        formData.append(
          "isSendEmailContainerReadyForPickup",
          this.state.isSendEmailContainerReadyForPickup
        );
      }

      if (this.state.email) {
        formData.append("email", this.state.email);
      }

      if (this.state.password) {
        formData.append("password", this.state.password);
      }
      if (!this.state._id) {
        this.props.actions.addFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false });
          toastr.show("This has been added!", "success");
        });
      } else {
        formData.append("_id", this.state._id);
        this.props.actions.updateFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false });
          toastr.show("Updated!", "success");
        });
      }
    } else {
      toastr.show("Please enter all mandatory fields properly.", "warning");
    }
  }

  ConvertObjectintoArrays (permissionList=[]) {
    let temp = [];

    Object.keys(Permissions).map((permission) => { 
      if (Array.isArray(Permissions[permission])) {
        let final = [];
        Permissions[permission].map(elem => {
          final.push({
            values: elem,
            checked: permissionList.includes(elem)?true:false
          })
        })
        temp.push({values: permission, checked:  permissionList.includes(permission)?true:false, children: final});
      } else {
        temp.push({
          values: permission,
          checked: permissionList.includes(permission)?true:false,
        })
      }
    })
    return temp
  }

  componentDidMount() {
    this.setState({employeePermission: this.ConvertObjectintoArrays()});
    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      getTerminal().then((response) => {
        let terminals = response.data.data;
        this.Terminals = terminals.map((terminal) => {
          return {
            value: terminal._id,
            label: terminal.name,
          };
        });
        // this.setState({ terminals });
      });
    }
  }

  componentWillMount() {
    this.getdata();
  }

  getdata(CustomerID) {
    let params = {};
    let customerId = CustomerID ? CustomerID : this.props.CustomerID;
    if (customerId) {
      params.CustomerID = customerId;
    }
    if (this.props.isQuick && !this.props.CustomerID) {
      return;
    }

    if (this.props.isCustomerProfile && !customerId) {
      return;
    }
    this.setState({loading: true})
    this.props.actions.listAllFleetManagers(params).then((result) => {
      this.setState({ allFleetManagers: result, loading: false });
    }).catch((err) => {
      this.setState({loading: false})
    });
  }
  checkIsPermissionAssigned(roles, rolename) {
    let checked = true;
    if (Array.isArray(rolename)) {
      rolename.forEach((e) => {
        if (roles.indexOf(e) === -1) checked = false;
      });
    } else {
      checked = roles.indexOf({value: rolename, checked: true}) === -1 ? false : true;
    }
    return checked;
  }

  handleChangeFleetManager(event, permission, key, ikey=null) {
      let current_data = [...this.state.employeePermission];
      if (permission.children && permission.children.length>0) {
        current_data[key] = {
          ...current_data[key],
          checked: event.target.checked
        }
        permission.children && permission.children.map((item, index)=> {
          current_data[key].children[index] = {
            ...current_data[key].children[index],
            checked: event.target.checked
          }
        })
      }else if(Number.isInteger(ikey)){
          current_data[key].children[ikey] = {
            ...current_data[key].children[ikey],
            checked: event.target.checked
          }
      } 
      else {
          current_data[key] = {
            ...current_data[key],
            checked: event.target.checked,
          };
      }
      this.setState({
       employeePermission: current_data
      })
    }
  // handleChangeFleetManager(event, key) {
  //   const userId = event.target.getAttribute("data-userId");
  //   const permission = event.target.getAttribute("data-permission").split(",");
  //   if (userId) {
  //     this.props.actions
  //     .addPermission({
  //       userId: userId,
  //       permissions: permission,
  //       isAdd: event.target.checked,
  //     })
  //     .then((result) => {
  //       toastr.show(
  //         `Permission updated Successfully`,
  //         "success"
  //       );
  //       let updatedUsers = [];
  //       this.setState({permissions: result});
  //       this.state.allFleetManagers.forEach((user) => {
  //         if (user._id === userId) {
  //           user.permissions = result;
  //         }
  //         updatedUsers.push(user);
  //       });
  //       this.setState({
  //         allFleetManagers: updatedUsers,
  //       });
  //     })
  //     .catch((err) => {});
  //   } else if (event.target.checked) {
  //     if (key === "customer_dashboard") {
  //       userPermissions.push({
  //         value : Permissions[key],
  //         checked: event.target.checked          
  //       });
  //     } else {
  //       userPermissions.push({
  //         value : Permissions[key],
  //         checked: event.target.checked 
  //       });
  //     }
  //     // let perm = [];
  //     this.setState({permissions: userPermissions.map(elem => elem.checked === true)});
  //   } 
  // }

  Newform() {
    this.state.allFleetManagers.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ ...blank, selectedEmployee: {}, employeePermission: this.ConvertObjectintoArrays()});
  }
  setval(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "mobile") {
      value = value.replace(/[^0-9]+/g, "");
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value });
    }
  }
  insetupdate() {
    let valid = true;
    $(".require").each(function () {
      if ($(this).val().trim() == "") {
        $(this).addClass("errorClass");
        valid = false;
      } else {
        $(this).removeClass("errorClass");
      }
    });
    if (
      this.state.email &&
      !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      toastr.show("Please enter a valid phone number format.", "warning");
      return;
    }
    if (
      !this.state._id &&
      valid &&
      (this.state.password.length < 5 || this.state.password.length > 20)
    ) {
      toastr.show("Please enter a password with a length between 5 to 20 characters.", "warning");
      return;
    }
    if (
      !this.state._id &&
      valid &&
      this.state.password !== this.state.password1
    ) {
      toastr.show("The passwords do not match.  Please try again.", "warning");
      return;
    }
    if (
      ((getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
        (getStorage("currentUserRole") == "carrier" &&
          configuration.idForTerminal.indexOf(getStorage("currentUserID")) !=
            -1)) &&
      (!this.state.terminals || this.state.terminals.length == 0)
    ) {
      toastr.show("Please enter a terminal.", "warning");
      return;
    }

    if (valid && this.state.mobile) {
      let mobile = this.state.mobile;
      mobile = mobile.replace(/-|_| /g, "");

      if (mobile.length < 10 || mobile.length > 10) {
        toastr.show("Please enter a mobile number with at least 10 digits.", "warning");
        return;
      }

      this.setState({ mobile });
    }
    if (
      this.state.password &&
      (this.state.password.length < 5 || this.state.password.length > 20)
    ) {
      toastr.show("Please enter a password with a length between 5 to 20 characters.", "warning");
      return;
    }
    if (this.state.password && this.state.password !== this.state.password1) {
      toastr.show("The passwords do not match.  Please try again.", "warning");
      return;
    }

    if (valid) {
      let formData = new FormData();
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("mobile", this.state.mobile);
      // formData.append("admin", this.state.admin);
      // formData.append("mechanic", this.state.mechanic);
      if (typeof this.state.isSendNotificationEmail === "boolean") {
        formData.append(
          "isSendNotificationEmail",
          this.state.isSendNotificationEmail
        );
      }
      if (
        this.state.notificationEmails &&
        this.state.notificationEmails.length > 0
      ) {
        formData.append(
          "notificationEmails",
          this.state.notificationEmails.toString()
        );
      }
      if (
        this.state.emailStatusOptions &&
        this.state.emailStatusOptions.length > 0
      ) {
        formData.append(
          "emailStatuses",
          JSON.stringify(this.state.emailStatusOptions.map((d) => d.value))
        );
      }
      if (this.props.CustomerID) {
        formData.append("CustomerID", this.props.CustomerID);
      }
      if (this.props.CustomerID) {
        formData.append("isCustomer", true);
      }
      if (this.state.billingEmail) {
        formData.append("billingEmail", this.state.billingEmail);
      }
      if (this.state.terminals && this.state.terminals.length > 0) {
        let lastTerminalValue = this.state.terminals.map((data) => {
          return data.value;
        });
        formData.append("terminals", JSON.stringify(lastTerminalValue));
      }
      // if (this.state.roles) {
      //   formData.append("roles", this.state.roles);
      // }
      if (this.state.permissions) {
        formData.append(
          "permissions",
          JSON.stringify(this.state.permissions.map((p) => p.value))
        );
      }
      if (
        typeof this.state.isSendEmailDeliveryAppointmentChanged === "boolean"
      ) {
        formData.append(
          "isSendEmailDeliveryAppointmentChanged",
          this.state.isSendEmailDeliveryAppointmentChanged
        );
      }
      if (typeof this.state.isSendEmailPricingDetailEmail === "boolean") {
        formData.append(
          "isSendEmailPricingDetailEmail",
          this.state.isSendEmailPricingDetailEmail
        );
      }
      if (typeof this.state.isSendEmailContainerAvailable === "boolean") {
        formData.append(
          "isSendEmailContainerAvailable",
          this.state.isSendEmailContainerAvailable
        );
      }
      if (typeof this.state.isSendEmailContainerAdd === "boolean") {
        formData.append(
          "isSendEmailContainerAdd",
          this.state.isSendEmailContainerAdd
        );
      }
      if (typeof this.state.isSendEmailLoadAccepted === "boolean") {
        formData.append(
          "isSendEmailLoadAccepted",
          this.state.isSendEmailLoadAccepted
        );
      }
      if (typeof this.state.isSendEmailContainerReadyForPickup === "boolean") {
        formData.append(
          "isSendEmailContainerReadyForPickup",
          this.state.isSendEmailContainerReadyForPickup
        );
      }

      if (this.state.email) {
        formData.append("email", this.state.email);
      }

      if (this.state.password) {
        formData.append("password", this.state.password);
      }
      if (!this.state._id) {
        this.props.actions.addFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false });
          toastr.show("This has been added!", "success");
        });
      } else {
        formData.append("_id", this.state._id);
        this.props.actions.updateFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false });
          toastr.show("Updated!", "success");
        });
      }
    } else {
      toastr.show("Please enter all mandatory fields properly.", "warning");
    }
  }
  isPermissionChecked=()=>{

  }

  changeColor(id) {
    this.setState({fleetID: id});
    const selectedEmployee = this.state.allFleetManagers.find(elem  => elem._id === id);

    if(selectedEmployee && selectedEmployee.permissions && selectedEmployee.permissions.length>0 ){
      this.setState({employeePermission: this.ConvertObjectintoArrays(selectedEmployee.permissions)});
    } 

    if (this.state[id]) {
      this.setState({ [id]: false, ...blank });
      return;
    }
    this.state.allFleetManagers.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ [id]: true, addUser: true });
    let selectData = _.find(this.state.allFleetManagers, function (s) {
      return s._id == id;
    });
    let setdata = {
      _id: selectData.fleetManager._id,
      email: selectData.email,
      password: selectData.fleetManager.password,
      password1: selectData.fleetManager.password1,
      firstName: selectData.fleetManager.firstName,
      lastName: selectData.fleetManager.lastName,
      mobile: selectData.fleetManager.mobile,
      // admin: selectData.fleetManager.admin,
      // mechanic: selectData.fleetManager.mechanic,
      notificationEmails: selectData.fleetManager.notificationEmails
        ? selectData.fleetManager.notificationEmails.split(",")
        : [],
      isSendNotificationEmail: selectData.fleetManager.isSendNotificationEmail
        ? selectData.fleetManager.isSendNotificationEmail
        : false,
      emailStatusOptions:
        (selectData.fleetManager &&
          selectData.fleetManager.emailStatuses &&
          selectData.fleetManager.emailStatuses.map((d) => ({
            value: d,
            label: convertTMSStatusName("IMPORT", d),
          }))) ||
        [],
      billingEmail:
        selectData.fleetManager && selectData.fleetManager.billingEmail
          ? selectData.fleetManager.billingEmail
          : "",
      // roles: selectData.fleetManager.roles,
      permissions:
        selectData.permissions && selectData.permissions.length
          ? selectData.permissions.map((p) => ({ label: p, value: p }))
          : [],
      isSendEmailDeliveryAppointmentChanged: selectData.fleetManager
        .isSendEmailDeliveryAppointmentChanged
        ? selectData.fleetManager.isSendEmailDeliveryAppointmentChanged
        : false,
      isSendEmailPricingDetailEmail: selectData.fleetManager
        .isSendEmailPricingDetailEmail
        ? selectData.fleetManager.isSendEmailPricingDetailEmail
        : false,
      isSendEmailContainerAvailable: selectData.fleetManager
        .isSendEmailContainerAvailable
        ? selectData.fleetManager.isSendEmailContainerAvailable
        : false,
      isSendEmailContainerAdd: selectData.fleetManager.isSendEmailContainerAdd
        ? selectData.fleetManager.isSendEmailContainerAdd
        : false,
      isSendEmailLoadAccepted: selectData.fleetManager.isSendEmailLoadAccepted
        ? selectData.fleetManager.isSendEmailLoadAccepted
        : false,
      isSendEmailContainerReadyForPickup: selectData.fleetManager
        .isSendEmailContainerReadyForPickup
        ? selectData.fleetManager.isSendEmailContainerReadyForPickup
        : false,
    };
    if (
      selectData.fleetManager.terminals &&
      selectData.fleetManager.terminals.length > 0
    ) {
      let terminals = this.Terminals.filter((terminal) => {
        return selectData.fleetManager.terminals.indexOf(terminal.value) != -1;
      });
      let allTerminal = false;
      if (terminals.length == this.Terminals.length) {
        allTerminal = true;
      }
      this.setState({ allTerminal, terminals });
    }
    this.setState({ ...setdata });
  }
  handleChange = (values) => {
    let terminals = [];
    if (values.length) {
      terminals = JSON.parse(JSON.stringify(values));
    }
    if (terminals.length == this.Terminals.length) {
      this.setState({ allTerminal: true });
    } else {
      this.setState({ allTerminal: false });
    }
    this.setState({ terminals });
  };

  onDeleteClick = (id) => {
    confirm(
      "Delete Employee?",
      `Are You sure to Delete this Employee ?`,
      (confirm) => {
        if (confirm) {
          this.props.actions.removeFleetManager(id).then(() => {
            let allFleetManagers = this.state.allFleetManagers.filter(
              (item) => item._id !== id
            );
            this.setState({ allFleetManagers: allFleetManagers });
            toastr.show("Removed!", "success");
          });
        }
      }
    );
  };
  togglePasswordVisiblity = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };
  setChangePasswordId(data, role) {
    this.setState({
      employeeData: data, role: role
    })
  }
  render() {
    let { formErrors, formsTouched } = this.state;
    return (
      <div>
        <div className="card p-20 mb-0">
          <button
            className="ml-auto btn btn-primary"
            onClick={() => {
              this.Newform();
              this.setState({ addUser: true });
            }}
          >
            <IconPlus className="mr-2" />
            Add Employee
          </button>
        </div>
        <div className="page-content container-fluid px-0">
          <div className="table-responsive">
          {this.state.loading ? (<LoaderBar />) : (
            <table className="table table-card table-card--ls">
              <thead>
                <tr>
                  {columns.map((column) => {
                    return (
                      <th scope="col" key={column}>
                        {column}
                      </th>
                    );
                  })}
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "2%" }}></th>
                </tr>
              </thead>
              <tbody>
                {((getStorage("currentUserRole") == "fleetmanager" && !(JSON.parse(getStorage("loggedInUser")).fleetManager && JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer)) || getStorage("currentUserRole") == "carrier" ) &&
                  <tr>
                    <td>{this.props.initialValues && this.props.initialValues.main_contact_name?.split(" ")[0]}</td>
                    <td>{this.props.initialValues && this.props.initialValues.main_contact_name?.split(" ").splice(1).join(" ")}</td>
                    <td>{this.props.initialValues && this.props.initialValues.mobile}</td>
                    <td>{this.props.initialValues && this.props.initialValues.email}</td>
                    <td>{this.props.initialValues && this.props.initialValues.email && 'Admin'}</td>
                    <td></td>
                    <td className="text-center">
                      { this.props.initialValues && this.props.initialValues.isDeleted ? null : (
                        <button
                          type="button"
                          title="Change Password"
                          className="btn btn-link text-muted"
                          onClick={() => this.setChangePasswordId(this.props.initialValues, 'Admin')}
                        >
                          <i className="uil uil-key-skeleton font-18"></i>
                        </button>
                      )}
                    </td>
                    <td></td>
                  </tr>
                }
                  {this.state.allFleetManagers.map((datum) => {
                    return (
                      <tr key={datum.id}>
                        <td>
                          <a
                            href="javascript:void(0)"
                            className="text-primary font-weight-500"
                          >
                            {datum.fleetManager.firstName}
                          </a>
                        </td>
                        <td>{datum.fleetManager.lastName}</td>
                        <td>{datum.fleetManager.mobile}</td>
                        <td>{datum.email}</td>
                        <td>{datum.email && 'Standard'}</td>
                        <td>
                          <button className="btn btn-link">
                            <span onClick={() => this.changeColor(datum._id)}>
                              {" "}
                              <IconPen />{" "}
                            </span>
                          </button>
                        </td>
                        <td className="text-center">
                      {datum.isDeleted ? null : (
                        <button
                          type="button"
                          title="Change Password"
                          className="btn btn-none text-muted"
                          onClick={() => this.setChangePasswordId(datum, 'Standard')}
                        >
                          <i className="uil uil-key-skeleton font-18"></i>
                        </button>
                       )} 
                    </td>
                        <td>
                          <button className="btn btn-link text-muted">
                            <span onClick={() => this.onDeleteClick(datum._id)}>
                              {" "}
                              <IconTrash />{" "}
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          </div>
        </div>
        {
          this.state.addUser &&
          <Modal show={this.state.addUser} dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable">
            <Modal.Header>
              <h4 className="modal-title">
                {this.state._id ? "Update Employee" : "Add Employee"}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="form-wrapper">
                <form>
                  <div className="form-row">
                    <div className=" col-md-4">
                      <StateFormInput
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        isRequired={true}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"First Name"}
                        value={this.state.firstName}
                      />

                      {/* <label className="col-form-label">
                        <span className="text-danger">*</span> First Name
                      </label>
                      <input
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        className="form-control require "
                        onChange={(e) => this.setval(e)}
                      /> */}
                    </div>
                    <div className=" col-md-4">
                      <StateFormInput
                        type="text"
                        name="lastName"
                        isRequired={true}
                        placeholder="Enter Last Name"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Last Name"}
                        value={this.state.lastName}
                      />

                      {/* <label className="col-form-label">
                        <span className="text-danger">*</span> Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={this.state.lastName}
                        className="form-control require"
                        onChange={(e) => this.setval(e)}
                      /> */}
                    </div>
                    <div className="col-md-4">
                      <StateFormNumberFormat
                        type="text"
                        name="mobile"
                        format="###-###-####"
                        mask="_"
                        isRequired={true}
                        value={this.state.mobile}
                        onValChange={this.setValChange}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        placeholder="Enter Phone Number"
                        label={"Phone"}
                      />
                    </div>
                    <div className=" col-md-12">
                      <StateFormInput
                        type="text"
                        isRequired={true}
                        name="email"
                        placeholder="Enter Email "
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Email"}
                        value={this.state.email}
                      />
                    </div>
                    <div className=" col-md-6">
                      <div className="input-wrapper">
                        <StateFormInput
                          type={this.state.passwordShown ? "text" : "password"}
                          isRequired={!this.state._id ? true : false}
                          placeholder="Enter Password "
                          name="password"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          label={"Password"}
                          value={this.state.password}
                        />
                        <div
                          className="input-icon"
                          style={{ top: "35px" , marginBottom:"50px"}}
                          onClick={() => this.togglePasswordVisiblity()}
                        >
                        {this.state.passwordShown ? <i class="uil uil-eye"></i> : <i class="uil uil-eye-slash"></i>} 
                        </div>
                      </div>

                      {/* <label className="col-form-label">
                        <span className="text-danger">*</span> Password
                      </label>
                      <div className="input-wrapper">
                        <input
                          type={this.state.passwordShown ? "text" : "password"}
                          placeholder="Enter Password "
                          name="password"
                          value={this.state.password}
                          className={`form-control ${
                            !this.state._id && "require"
                          } `}
                          onChange={(e) => this.setval(e)}
                        />
                        <div
                          className="input-icon"
                          onClick={() => this.togglePasswordVisiblity()}
                        >
                          <IconEye />
                        </div>
                      </div> */}
                    </div>
                    <div className=" col-md-6">
                      <div className="input-wrapper">
                        <StateFormInput
                          type={this.state.passwordShown ? "text" : "password"}
                          placeholder="Enter Password"
                          isRequired={!this.state._id ? true : false}
                          name="password1"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          label={"Repeat Password"}
                          value={this.state.password1}
                        />
                        <div
                          className="input-icon"
                          style={{ top: "35px", marginBottom :"50px" }}
                          onClick={() => this.togglePasswordVisiblity()}
                        >
                        {this.state.passwordShown ? <i class="uil uil-eye"></i> : <i class="uil uil-eye-slash"></i>} 
                        </div>
                      </div>

                      {/* <label className="col-form-label">
                        <span className="text-danger">*</span> Repeat Password
                      </label>
                      <div className="input-wrapper">
                        <input
                          type={this.state.passwordShown ? "text" : "password"}
                          placeholder="Enter Repeat Password "
                          name="password1"
                          value={this.state.password1}
                          className={`form-control ${
                            !this.state._id && "require"
                          } `}
                          onChange={(e) => this.setval(e)}
                        />
                        <div
                          className="input-icon"
                          onClick={() => this.togglePasswordVisiblity()}
                        >
                          <IconEye />
                        </div>
                      </div> */}
                    </div>
                    <div className="col-md-12">
                      <StateFormInput
                        type="email"
                        isRequired={true}
                        placeholder="Enter Billing Email "
                        name="billingEmail"
                        value={this.state.billingEmail}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Billing Email"}
                      />
                      {/* <label className="col-form-label">
                        <span className="text-danger">*</span> Billing Email
                      </label>
                      <input
                        type="email"
                        placeholder="Enter Billing Email "
                        name="billingEmail"
                        value={this.state.billingEmail}
                        className="form-control require"
                        onChange={(e) => this.setval(e)}
                      /> */}
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    {/* {(getStorage('currentUserRole') == 'fleetmanager' && (configuration.idForTerminal.indexOf(JSON.parse(getStorage('loggedInUser')).fleetManager.carrier) != -1) || getStorage('currentUserRole') == 'carrier' && (configuration.idForTerminal.indexOf(getStorage('currentUserID')) != -1)) &&
                        <label>All Terminal</label>
                        <div className="form-check">
                          <input name="allTerminal" id={`allTerminal`} type="checkbox" className="form-check-input" onClick={(e) => this.setState({ allTerminal: !!e.target.checked }, () => {
                            if (this.state.allTerminal) {
                              let terminals = this.Terminals.map((terminal) => {
                                return terminal;
                              });
                              this.setState({ terminals });
                            } else {
                              this.setState({ terminals: [] });
                            }
                          })} checked={this.state.allTerminal} />
                          <label className="form-check-label" htmlFor={`allTerminal`}></label>
                    </div>
                    } */}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12 mb-0">
                      {/* <StateFormSelect
                        name="permissions"
                        label={`Permission`}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        value={this.state.permissions}
                        options={employeePermission}
                        isMulti
                      /> */}

                      {/* <label className="col-form-label">
                        <span className="text-danger">*</span> Permission
                      </label>
                      <Select
                        styles={smallSelectStyle}
                        onChange={(permissions) => this.setState({ permissions })}
                        isMulti
                        name="permissions"
                        value={this.state.permissions}
                        options={employeePermission}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      /> */}
                    </div>
                  </div>
                  <div className="hr-light my-20"></div>
                  <div className="form-row align-items-center">
                    <div className="col-md-6 form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          id="isSendNotificationEmail"
                          type="checkbox"
                          name="isSendNotificationEmail"
                          checked={
                            this.state.isSendNotificationEmail ? "checked" : null
                          }
                          onChange={
                            (e) =>
                              this.setValChange(
                                "isSendNotificationEmail",
                                e.target.checked
                              )
                            // this.setState({
                            //   isSendNotificationEmail: e.target.checked,
                            // })
                          }
                        />

                        <label
                          className="form-check-label font-14"
                          for="isSendNotificationEmail"
                        >
                          Send Email Notification
                        </label>
                      </div>
                    </div>
                    {this.state.isSendNotificationEmail && (
                      <div className="form-group col-md-6">
                        {/* <StateFormInput
                        type="email"
                        
                        placeholder="Enter Billing Email "
                        name="billingEmail"
                        value={this.state.notificationEmails}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Enter Email Notification Address"}
                      /> */}

                        <TagsInput
                          disabled={!this.state.isSendNotificationEmail}
                          inputProps={{
                            placeholder: "Enter Email Notification Address",
                            className: "form-control",
                          }}
                          value={this.state.notificationEmails}
                          onChange={this.handleNotificationEmailsChange}
                        />
                      </div>
                    )}
                  </div>

                  {/* Send Email Notification */}
                  {this.state.isSendNotificationEmail && (
                    <div className="form-row mt-10">
                      <div className="form-group col-md-6">
                        <div className="form-check pl-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSendEmailDeliveryAppointmentChanged"
                            checked={
                              this.state.isSendEmailDeliveryAppointmentChanged
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setState({
                                isSendEmailDeliveryAppointmentChanged:
                                  e.target.checked,
                              })
                            }
                          />

                          <label
                            className="form-check-label"
                            for="isSendEmailDeliveryAppointmentChanged"
                          >
                            Email Delivery Appointment Changed
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-check pl-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSendEmailPricingDetailEmail"
                            checked={
                              this.state.isSendEmailPricingDetailEmail
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setState({
                                isSendEmailPricingDetailEmail: e.target.checked,
                              })
                            }
                          />

                          <label
                            className="form-check-label"
                            for="isSendEmailPricingDetailEmail"
                          >
                            Email Pricing Detail
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-check pl-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSendEmailContainerAvailable"
                            checked={
                              this.state.isSendEmailContainerAvailable
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setState({
                                isSendEmailContainerAvailable: e.target.checked,
                              })
                            }
                          />

                          <label
                            className="form-check-label"
                            for="isSendEmailContainerAvailable"
                          >
                            Email Container Available
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-check pl-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSendEmailContainerAdd"
                            checked={
                              this.state.isSendEmailContainerAdd ? true : false
                            }
                            onChange={(e) =>
                              this.setState({
                                isSendEmailContainerAdd: e.target.checked,
                              })
                            }
                          />

                          <label
                            className="form-check-label"
                            for="isSendEmailContainerAdd"
                          >
                            Email Container Add
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-check pl-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSendEmailLoadAccepted"
                            checked={
                              this.state.isSendEmailLoadAccepted ? true : false
                            }
                            onChange={(e) =>
                              this.setState({
                                isSendEmailLoadAccepted: e.target.checked,
                              })
                            }
                          />

                          <label
                            className="form-check-label"
                            for="isSendEmailLoadAccepted"
                          >
                            Email Load Accepted
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="form-check pl-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isSendEmailContainerReadyForPickup"
                            checked={
                              this.state.isSendEmailContainerReadyForPickup
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.setState({
                                isSendEmailContainerReadyForPickup:
                                  e.target.checked,
                              })
                            }
                          />

                          <label
                            className="form-check-label"
                            for="isSendEmailContainerReadyForPickup"
                          >
                            Email Container Ready To Return
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="hr-light"></div>

                  {/* {this.state.allFleetManagers &&
                    this.state._id && this.state.fleetID &&
                    this.state.allFleetManagers.filter(elem => this.state.fleetID === elem._id).map((info, i) => {
                      //#region
                      return ( */}
                        <div
                          // key={i}
                          // id=""
                          className="d-flex flex-column"
                        >
                          <div className="font-14 font-medium mb-20">Permission</div>
                          {this.state.employeePermission.map((permission, key) => {
                            return (
                              <div className="" key={key}>
                              <div className="form-group form-check">
                                <input
                                  name="permissions"
                                  id={`check${key}_${
                                    permission.values
                                  }`}
                                  checked={permission.checked}
                                  type="checkbox"
                                  className="form-check-input"
                                  data-userId={this.state.selectedEmployee && this.state.selectedEmployee._id || ""}
                                  // data-permission={Permissions[
                                  //   permission
                                  // ].toString()}
                                  onChange = {(e) => this.handleChangeFleetManager(e, permission, key)}
                                  disabled = {getStorage("currentUserRole")==="fleetmanager" &&
                                   JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer?JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer:false}
                                />
                                <label
                                  className="form-check-label text-capitalize"
                                  // htmlFor={`check${key}_${
                                  //   permission.values
                                  // .toString()}`}
                                >
                                  {employeePermission.find(item => item.value === permission.values) && employeePermission.find(item => item.value === permission.values).label}
                                  
                                </label>
                              </div>
                              {permission.children && permission.children.length>0 && (
                              <div className="bg-gray-50 px-10 pt-10 mb-15">
                                <div className="form-row">
                                  {permission.children.map((d, index) => {
                                      return (
                                        <div className="col-md-3">
                                          <div
                                            className="form-group form-check"
                                            key={`${index}-children`}
                                          >
                                            <input
                                              name="permissions"
                                              id={`check${
                                                index + "_" + key
                                              }_${d.values}`}
                                              checked={d.checked}
                                              type="checkbox"
                                              className="form-check-input"
                                              data-userId={this.state.selectedEmployee && this.state.selectedEmployee._id || ""}
                                              // data-permission={d}
                                              onChange={(e) => this.handleChangeFleetManager(e, d, key, index)}
                                              disabled = {getStorage("currentUserRole")==="fleetmanager" &&
                                              JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer?JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer:false}
                                            />
                                            <label
                                              className="form-check-label text-capitalize"
                                              htmlFor={`check${
                                                index + "_" + key
                                              }_${d.values}`}
                                            >
                                              {employeePermission.find(item => item.value === d.values) && employeePermission.find(item => item.value === d.values).label}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              )

                              }
                              
                            </div>
                            )
                          })}
                          {/* {Object.keys(Permissions).map((permission, key) => {
                            return (
                              <div className="" key={key}>
                                <div className="form-group form-check">
                                  <input
                                    name="permissions"
                                    id={`check${key}_${Permissions[
                                      permission
                                    ].toString()}`}
                                    checked={this.checkIsPermissionAssigned(
                                      this.state.permissions,
                                      Permissions[permission]
                                    )}
                                    type="checkbox"
                                    className="form-check-input"
                                    data-userId={this.state.selectedEmployee && this.state.selectedEmployee._id || ""}
                                    data-permission={Permissions[
                                      permission
                                    ].toString()}
                                    onChange = {(e) => this.handleChangeFleetManager(e , permission)}
                                  />
                                  <label
                                    className="form-check-label text-capitalize"
                                    htmlFor={`check${key}_${Permissions[
                                      permission
                                    ].toString()}`}
                                  >
                                    {Array.isArray(Permissions[permission])
                                      ? "Loads"
                                      : permission.replace(/['_']/g, " ")}
                                  </label>
                                </div>
                                {
                                Array.isArray(Permissions[permission]) && (
                                <div className="bg-gray-50 px-10 pt-10 mb-15">
                                  <div className="form-row">
                                    {Permissions[permission].map((d, index) => {
                                        let name = d.split("_");
                                        let lastWord;
                                        if (
                                          ["messaging", "receivable", "rate"].indexOf(
                                            d.split("_").pop()
                                          ) > -1
                                        ) {
                                          lastWord = `${name[name.length - 2]} ${
                                            name[name.length - 1]
                                          }`;
                                        } else {
                                          lastWord = name.pop();
                                        }
                                        return (
                                          <div className="col-md-3">
                                            <div
                                              className="form-group form-check"
                                              key={`${index}-children`}
                                            >
                                              <input
                                                name="permissions"
                                                id={`check${
                                                  index + "_" + key
                                                }_${d}`}
                                                checked={this.state.permissions.includes(d)}
                                                type="checkbox"
                                                className="form-check-input"
                                                data-userId={this.state.selectedEmployee && this.state.selectedEmployee._id || ""}
                                                data-permission={d}
                                                onChange={(e) => this.handleChangeFleetManager(e, d)}
                                              />
                                              <label
                                                className="form-check-label text-capitalize"
                                                htmlFor={`check${
                                                  index + "_" + key
                                                }_${d}`}
                                              >
                                                {lastWord}
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                )

                                }
                                
                              </div>
                            );
                          })} */}
                        </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-close"
                onClick={() => {
                  this.setState({ addUser: false });
                }}
              >
                Close
              </button>
              <button
                className={`btn ${
                  this.state._id ? "btn-primary" : "btn-success"
                }`}
                onClick={() => {
                  this.insertdata();
                  // this.insetupdate();
                }}
              >
                {this.state._id ? "Update Employee" : "Add Employee"}
              </button>
            </Modal.Footer>
          </Modal>
        }
    
           <Modal show={this.state.employeeData} className="show">
            <ResetCustomerPassword
              setResetId={() => this.setState({ role: undefined, employeeData:undefined})}
              employeeData={this.state.employeeData}
              actions={this.props.actions}
              customerActions={this.props.customerActions}
              passwordShown={this.state.passwordShown}
              togglePasswordVisiblity={this.togglePasswordVisiblity}
              role={this.state.role}
            />
          </Modal> 
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    customerActions:bindActionCreators(customerActionCreators, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    profile: state.ProfileReducer.profile,
  };
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationShapewithPass = {
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile is not valid")
    .required("Mobile is Required."),
  lastName: Yup.string().required("This field is Required."),
  firstName: Yup.string().required("This field is Required."),
  email: Yup.string()
    .email("Must be a valid email")
    .required("This field is Required."),
  password: Yup.string().required("This field is Required."),
  password1: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
  billingEmail: Yup.string()
    .email("Must be a valid email")
    .required("This field is Required."),
  Permission: Yup.object(),
  isSendNotificationEmail: Yup.bool(),
  isSendEmailDeliveryAppointmentChanged: Yup.bool(),
  isSendEmailPricingDetailEmail: Yup.bool(),
  isSendEmailContainerAvailable: Yup.bool(),
  isSendEmailContainerAdd: Yup.bool(),
  isSendEmailLoadAccepted: Yup.bool(),
  isSendEmailContainerReadyForPickup: Yup.bool(),
};
const validationShapewithOutPass = {
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile is not valid")
    .required("Mobile is Required."),
  lastName: Yup.string().required("This field is Required."),
  firstName: Yup.string().required("This field is Required."),
  email: Yup.string()
    .email("Must be a valid email")
    .required("This field is Required."),
  password: Yup.string(),
  password1: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
  billingEmail: Yup.string()
    .email("Must be a valid email")
    .required("This field is Required."),
  Permission: Yup.object(),
  isSendNotificationEmail: Yup.bool(),
  isSendEmailDeliveryAppointmentChanged: Yup.bool(),
  isSendEmailPricingDetailEmail: Yup.bool(),
  isSendEmailContainerAvailable: Yup.bool(),
  isSendEmailContainerAdd: Yup.bool(),
  isSendEmailLoadAccepted: Yup.bool(),
  isSendEmailContainerReadyForPickup: Yup.bool(),
};
const withPasswordSchemaValidatorObject = Yup.object().shape(
  validationShapewithPass
);
const withOutPasswordSchemaValidatorObject = Yup.object().shape(
  validationShapewithOutPass
);

const ResetForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = props;
  return (
    <div className="center-child">
      <Modal.Header>
        <h4 className="modal-title" id="myModalLabel">
          Reset Password
        </h4>
        <button
          type="button"
          className="close"
          onClick={() => props.setResetId()}
        >
          <i className="uil uil-times"></i>
        </button>
      </Modal.Header>
      <div className="sign-up-form">
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <Modal.Body>
              <div className={`form-group  ${errors.password && "has-error"}`}>
                <label htmlFor="password">
                  New Password
                  <span
                    className="required-field"
                    title="This field is reqired."
                  >
                    *
 
                  </span>
                </label>
                <input
                  type={props.passwordShown ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                <div
                  className="input-icon"
                  style={{ top: "35px" , marginBottom:"50px"}}
                  onClick={()=>props.togglePasswordVisiblity()}
                >
                {props.passwordShown ? <i class="uil uil-eye"></i> : <i class="uil uil-eye-slash"></i>} 
                </div>
                {errors.password && touched.password && (
                  <span className="text-danger">{errors.password}</span>
                )}
              </div>
              {isSubmitting && (
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <Spinner />
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-close"
                onClick={() => {
                  props.setResetId();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                name="resetCustomerPassword"
                id="resetCustomerPassword"
                value="Save"
                className="btn btn-danger"
              >
                Reset
              </button>
            </Modal.Footer>
          </div>
        </form>
      </div>
    </div>
  );
};

const ResetCustomerPassword = withFormik({
  mapPropsToValues: (props) => ({
    password: "",
    confirmPassword: "",
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(5, "Enter atleast 5 characters.")
      .max(25, "Enter less than 25 characters.")
      .required("Password is required."),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const role = props.role
    const password = values.password;
    const customerId =props.employeeData._id;
    
    if (role === "Admin") {
      props.customerActions
        .resetCustomerPassword({
          customerId,
          password,
        })
        .then((result) => {
          props.setResetId();
          toastr.show("Your password has been successfully changed!", "success");
          setSubmitting(false);
        })
        .catch((Err) => { })
        .finally(() => {
          setSubmitting(false);
        });
        
    }
    else {
      const _id =  props.employeeData.fleetManager._id
      const firstName = props.employeeData.fleetManager.firstName
      const lastName = props.employeeData.fleetManager.lastName
      const mobile =props.employeeData.fleetManager.mobile
      props.actions
        .updateFleetManager({
          _id,
          firstName,
          lastName,
          mobile,
          password
         
        })
        .then((result) => {
          
          props.setResetId();
          toastr.show("Your password has been successfully changed!", "success");
          setSubmitting(false);
        })
        .catch((Err) => { console.log("Err----", Err); })
        .finally(() => {
          setSubmitting(false);
        });
    }
  },
})(ResetForm);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFleetManagers);
