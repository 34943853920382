import React,{useState,useEffect} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import { IconLearning } from "../../Common/Icons";
import * as actionCreators from "../../../pages/tms/Learnings/actionCreators";

function LearningMenu(props){

  // Destructuring props
  const {state,handleMenuClick,setState} = props;

  // USE STATES
  const [subHeaders, setSubHeaders] =useState();

  // USE EFFECTS
  useEffect(()=>{
    props.actions.getSubHeaders().then((result) => {
      setSubHeaders(result);
    }).catch((error) => {
      console.log(error)
    });
  },[]);

    
    return(
        <div
        className={`sidebar-item ${
          state.selected === "learning" ? "active" : ""
        } ${
          state.isMenuClicked === "learning" ? "isclicked" : ""
        }`}
        onClick={() => handleMenuClick("learning")}
      >
         <div className="sidebar-link has-arrow">
          <IconLearning />
          <span className="hide-menu">Learning</span>
         </div>

         <div aria-expanded="false" className="collapse first-level">
          {subHeaders && subHeaders.map((subHeader,index)=>{
           return  <Link
                  key={index}
                  className="first-level__link"
                  to={`/tms/learning/${subHeader.name}`}
                  onClick={() =>
                    setState({
                      selected: "learning",
                      currentUrl: `/tms/learning/${subHeader.name}`,
                    })
                  }
                  activeClassName={"active"}
                >
                  {subHeader.name}
                </Link>
          })}
        </div>
         
    </div>
    );
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(actionCreators, dispatch) };
  }
  
  export default connect(null, mapDispatchToProps)(LearningMenu);
  