import * as $ from "jquery";
import _ from "lodash";
import moment from "moment";
import React, { Component, Fragment } from "react";
import update from "react-addons-update";
import Chart from "react-apexcharts";
import Datetime from "react-datetime";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { browserHistory, withRouter } from "react-router";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import { smallSelectStyle } from "../../../../assets/js/select-style";
import confirm from "../../../../Components/Common/ConfirmAert";
import DayCalenderFilter from "../../../../Components/Common/DayCalenderFilter";
import {
  IconNoteArrowRight,
  IconCalendar,
  IconPlus,
  IconTrash,
} from "../../../../Components/Common/Icons";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import CustomerServiceSideBar from "../../../../Components/CustomerServiceSideBar/CustomerServiceSideBar";
import * as actionCreatorsProfile from "../../../../Components/shipper";
import SubHeaderTab from "../../../../Components/SubHeaderTab";
import configuration from "../../../../config";
import {
  approveNewDeduction,
  changeWeek,
  getCarrierByUserId,
  getDriverOrderLocation,
  getNewTranscation,
  getPricingSettingsOptions,
  getStorage,
  showForCompany,
  toastr,
  showForTerminal,
  getNewTranscationCount,
  isMilitaryTime
} from "../../../../services/Common.services";
import {
  deleteNewDriverPay,
  getAllNewSettlements,
  getDriverForDeduction,
  getLoad,
  getTerminal,
  manualDriverPay
} from "../../services";
import * as tmsReducer from "./actionCreator";
import ChartCard from "./ChartCard";
import Manual from "./Manual";
import NewEdit from "./NewEdit";
import DataGrid, { Row } from "react-data-grid";
import { isAtBottom } from "../../../tms/NewDispatcher/DispatcherTable/Functions";

const firebase = configuration.firebase;
let timeZone = getStorage("timeZone");

const sortingOptions = {
  Terminal: "terminal.name",
  Load: "reference_number",
  Time: "time",
  Owner: "fleetTruckOwner.company_name",
  Truck: "truck.equipmentID",
  Driver: "createdBy.name",
  Amount: "calculateAmount",
  "Load Status": "renderStatus",
  From: "from",
  To: "to",
  Status: "approved",
};

const DATES = [
  { day: "Mon", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
  { day: "Tue", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
  { day: "Wed", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
  { day: "Thu", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
  { day: "Fri", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
  { day: "Sat", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
  { day: "Sun", totalNetPay: 0, unapprovedPay: 0, unapprovedLoads: 0 },
];

const chartOptions = {
  chart: {
    id: "driver-pay-chart",
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  colors: [
    function ({ value, seriesIndex, w }) {
      if (value == 20) {
        return "var(--color-primary-500)";
      } else {
        return "#DBE2EB";
      }
    },
  ],
  plotOptions: {
    bar: {
      columnWidth: "65%",
      barHeight: "100%",
      endingShape: "rounded",
      borderRadius: 2,
    },
  },

  xaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  

  tooltip: {
    enabled: false,
  },
  grid: {
    show: false,
  },
};

const series = [
  {
    name: "series-1",
    data: [20, 60, 100, 90, 10, 40, 70],
  },
];

const pricingSettingsOptions = getPricingSettingsOptions();

const EmptyRowsRenderer =() => {
  return (
    <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
      <div style={{padding: "20px 10px", backgroundColor: 'white', fontSize: "12px"}}>
        No Result
      </div>
    </div>
  );  
}
class NewTranscation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transcations: [],
      paginate: 0,
      startDate: null,
      endDate: null,
      activePage: 1,
      from: null,
      to: null,
      showSearch: true,
      innerHeight: 500,
      openedId: null,
      order: -1,
      searchTerm: "",
      terminal:'',
      terminals: [],
      originalTranscations: [],
      totalItemsCount: 0,
      terminal: null,
      newTerminal: null,
      driverPayTerminal: null,
      filterStatus: [],
      filterdDriver: undefined,
      showSidebar: false,
      selectedLoad: undefined,
      activeCustomerServiceSidebarTab: "documents",
      showCardsInDriverPay: false,
      sort: [],
      asce: true,
      isLoadingBarShow: false,
      choosenTerminals: [],
      insideLoadInfo: false,
      settlementSettings: null,
      settlementData: [],
      fromDate: moment(),
      toDate: moment(),
      isSaving: false,
      isStatusHovered: null,
      selectedDate: moment(),
      initialValues:{},
      isTerminal:false,
      missingDocType: [],
      columns: [],
      skip: 0,
      loadsPerReq: 20,
      sortColumns:[],
      sortBy: { reference_number: 1 },
      dateWiseCount: [],
      indexData: [],
      weekWiseDates: [],
      dateArray:[]
      // toEdit : ""
    };
    this.remountKey = 0;
    this.userTimeZone=getStorage("timeZone") ||  moment.tz.guess();
    this.userTimeFormat = isMilitaryTime();
  }

  getTranscation = (query = {}, appendTranscationData) => {
    this.setState({ isLoadingBarShow: true})
    if(this.state.missingDocType.length > 0) {
      query.missingDocType = JSON.stringify(this.state.missingDocType.map((d) => d.value))
    }
    if(this.state.searchTerm) {
      query.searchTerm = this.state.searchTerm
    }
    if(this.state.filterStatus) {
      query.filterDate = JSON.stringify(this.state.filterStatus.map((d) => moment(d).toISOString()));
    }
    getNewTranscation({
      ...query,
      limit: this.state.loadsPerReq,
      skip: this.state.skip,
      from: this.state.filterStatus.length > 0 ? null : this.state.startDate,
      to: this.state.filterStatus.length > 0 ? null : this.state.endDate,
    })
      .then((response) => {
        let transcations =
          !response.data.data || response.data.data.result == null
            ? []
            : response.data.data.result;
        if(appendTranscationData) {
          transcations = [...this.state.transcations, ...response.data.data.result]
        } else {
          this.remountKey = this.remountKey + 1;
        }
        transcations = transcations.filter((trans) => trans._id);
        transcations = transcations.map((el,index) => ({index: index+1 , ...el}))
        this.setState({
          transcations,
          originalTranscations: transcations,
          totalItemsCount: !response.data.data ? 0 : response.data.data.count,
          isLoadingBarShow: false
        }, () => this.handleDefaultScroll());
      }).catch(err => {
        console.log('err', err)
        this.setState({ isLoadingBarShow: false})
      })
      .finally((err) => {
        this.setState({ isLoadingBarShow: false });
      });
  };

  getTranscationCount = (query = {}) => {
    if(this.state.missingDocType.length > 0) {
      query.missingDocType = JSON.stringify(this.state.missingDocType.map((d) => d.value))
    }
    getNewTranscationCount({
      ...query,
      from: this.state.startDate,
      to: moment(this.state.endDate).tz(this.userTimeZone).add(1, 'days').toISOString(),
    }).then((response) => {
      let dateWiseCount = response.data.data
      const home = this.state.dateArray.map((d)=>{
        const isDate = dateWiseCount.find(el=>el._id === moment(d).format('YYYY-MM-DD'))
        if(isDate){
          return isDate
        }else{
          return {
            _id:moment(d).format('YYYY-MM-DD'),
            amount:"0.00",
            total:"0"
          }
        }
      })
    dateWiseCount=home
      this.setState({dateWiseCount})
    }).catch(err => {
      console.log('err', err)
      this.setState({ isLoadingBarShow: false})
    })
  }

  deleteNewDriverPay = (params) => {
    confirm("Delete Driver Pay Rate?", "Are you sure to delete Driver Pay Rate?", (confirm) => {
      if (confirm) {
        // params.from = "transcationdd";
        deleteNewDriverPay(params).then((result) => {
          let transcations = JSON.parse(JSON.stringify(this.state.transcations));
          transcations = transcations.filter((data) => data._id != params._id);
          transcations = transcations.filter((month,idx) => idx < 10)
          this.setState({ transcations });
          toastr.show("Deleted!", "success");
        });
      }
    });
  };
  // editNewDriverPay = (params) => {   
  //  if(params._id === this.state.toEdit){
  //     this.closeEditOptions()
  //   }else{
  //     this.setState({toEdit : params._id})
  //   }
  //   console.log("going to edit", params)
  // }
  // closeEditOptions = () => {
  //   this.setState({toEdit : ""})
  // }

  keyPressOnNumberInput = (evt)=>{
    var invalidChars = [
      "-",
      "+",
      "e",
    ];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
  }

  searchByDate = () => {
    var from = this.state.from && moment(this.state.from).toISOString();
    var to = this.state.to && moment(this.state.to).toISOString();
    const query = Object.assign({}, this.state.query, { from, to });
    this.getTranscation(query);
  };

  searchLoad = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm, skip: 0 }, () => {
      this.getTranscation()
    });
  };
  componentDidMount() {
    this.getColumns();
    
    const currentUser = JSON.parse(getStorage("loggedInUser"));
    let carrierId = currentUser._id;
    if (currentUser.role === "driver") {
      carrierId = currentUser.driver.carrier;
    }
    if (currentUser.role === "fleetmanager") {
      carrierId = currentUser.fleetManager.carrier;
    }
    let isTerminal = showForTerminal();
    this.setState({ isTerminal: isTerminal });
    if (_.get(this.props, "location.pathname") !== "/tms/billing/driver-pay") {
      this.setState({ insideLoadInfo: true });
      if (isTerminal) {
        getTerminal().then((response) =>
          this.setState({
            terminals: response.data.data ? response.data.data : [],
          })
        );
      }
    }

    if (
      this.props.location.query.reference_number ||
      this.props.reference_number
    ) {
      this.setState({ showSearch: false }, () => {
        let reference_number = encodeURIComponent(
          this.props.location.query.reference_number
        );
        if (this.props.reference_number) {
          reference_number = encodeURIComponent(this.props.reference_number);
        }
        const query = Object.assign({}, this.state.query, { reference_number });
        this.getTranscation(query);
        this.getTranscationCount(query);
      });
    } else {
      

      const startDate = moment()
        .tz(this.userTimeZone)
        .startOf("weeks")
        .toISOString();
      const endDate = moment()
        .tz(this.userTimeZone)
        .endOf("weeks")
        .toISOString();

      if (!this.props.isTab) {
        this.setState({ showCardsInDriverPay: true });
      }
      var dateArray = this.getDates(startDate);
      this.setState(
        {
          startDate,
          endDate,
          fromDate: null,
          toDate: null,
          dateArray
        },
        () => {
          this.getTranscation();
          this.getTranscationCount();
        }
      );
    }
    const self = this;
    self.setState({ innerHeight: window.innerHeight }, () => {
      $(".setfixedheight").css("height", window.innerHeight - 200);
    });

    if (
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.indexOf("customer-service") > -1
    ) {
    } else {
      this.props.dispatch({
        type: "CHANGE_NAV",
        payload: "driverPay",
      });
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      getTerminal().then((response) => {
        let terminals = response.data.data;
        if (
          getStorage("currentUserRole") == "fleetmanager" &&
          configuration.idForTerminal.indexOf(
            JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
          ) != -1
        ) {
          let fleetTerminal = JSON.parse(getStorage("loggedInUser"))
            .fleetManager.terminals;
          terminals = terminals.filter(
            (D) => fleetTerminal.indexOf(D._id) > -1
          );
        }
        this.setState({ terminals });
      });
    }
    getCarrierByUserId(carrierId).then((mainAccount) => {
      let { settlementSettings } = mainAccount.data.data;
      this.setState(
        {
          settlementSettings: settlementSettings ? settlementSettings : {},
        },
        () => {
          if (
            settlementSettings &&
            settlementSettings.day &&
            settlementSettings.duration
          ) {
            let query = {
              homeTerminalTimezone: this.userTimeZone,
              limit: 1,
            };
            getAllNewSettlements(query).then((data) => {
              this.setState({ settlementData: data.data.data });
            });
          }
        }
      );
    });
    getDriverForDeduction().then((result) => {
      let allDrivers = result.data.data.filter(
        (driver) => driver.isDeleted === false
      );
      this.setState({
        allDrivers,
        isLoadingBarShow: false,
      });
      this.props.tmsReducer.addDriver(allDrivers);
    });
    if (getStorage("loggedInUser") != null) {
      let reference_number;
      if (this.props.selectedLoads) {
        reference_number = this.props.selectedLoads.reference_number;
      } else {
        if (this.props.loads && this.props.loads.reference_number) {
          reference_number = this.props.loads.reference_number;
        } else if (this.props.id) {
          reference_number = this.props.id;
        } else if (
          this.props.location &&
          this.props.location.query &&
          this.props.location.query.reference_number
        ) {
          reference_number = this.props.location.query.reference_number;
        }
      }
      this.userPresense = firebase
        .database()
        .ref(
          `${carrierId}/presense/${reference_number}/driverpay/${currentUser._id}`
        );
      // window.addEventListener('focus', () => {
      //   this.userPresense.set(true);
      // }, false)

      // window.addEventListener('blur', () => {
      //   this.userPresense.set(false);
      // }, false)

      if (this.userPresense) {
        this.userPresense.set(true);
      }
    }
  }
  UNSAFE_componentWillMount() {
    this.setState({ isLoadingBarShow: true });
  }

  componentWillUnmount() {
    if (this.userPresense) {
      this.userPresense.set(false);
    }
  }



  getEachTranscation = (_id) => {
    browserHistory.push(`tms/customer-service/transcation/${_id}`);
  };

  addManual = (data) => {
    this.setState({ isSaving: true });
    const newFormData = new FormData();
    newFormData.append("description", data.description);
    newFormData.append("amount", data.amount);
    newFormData.append("driverId", data.driverId);
    if (data.containerNo) {
      newFormData.append("containerNo", data.containerNo);
    }
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.reference_number
    ) {
      newFormData.append(
        "reference_number",
        this.props.location.query.reference_number
      );
    } else if( this.props.selectedLoads && this.props.selectedLoads.reference_number){
      newFormData.append(
        "reference_number",
        this.props.selectedLoads.reference_number
      );
    }else {
      newFormData.append("reference_number", data.reference_number);
    }
    newFormData.append("from", data.from);
    newFormData.append("to", data.to);
    if (this.state.driverPayTerminal) {
      newFormData.append("terminal", this.state.driverPayTerminal);
    }
    newFormData.append("loadId", data.loadId ? data.loadId : this.props.selectedLoads?._id)
    manualDriverPay(newFormData)
      .then((response) => {
        let transcations = this.state.transcations.slice();
        transcations.unshift(response.data.data);
        this.setState({ isSaving: false, showManual: false, transcations });
        toastr.show("Driver Pay has been added successfully!", "success");
      })
      .catch((error) => {
        console.log("error =====", error);
        this.setState({ isSaving: false });
      });
  };

  renderStatus = (status, reference_number) => {
    const firstLetter = reference_number?.substring(0, 1);
    let newStatus = "";
    switch (status) {
      case "ARRIVED_PICKUP":
        newStatus = firstLetter == "R" ? "Picked Up Load" : "Pulled Container";
        break;
      case "COMPLETED":
        newStatus = "Return Container";
        break;
      case "DELIVERED":
        newStatus = firstLetter == "R" ? "Delivered Load" : "Delivered";
        break;
      case "NEED_TO_GET_DROP":
        newStatus = "Get Container";
        break;
      default:
        newStatus = this.getPricingOptionsInStatus(status);
    }
    return newStatus;
  };

  getPricingOptionsInStatus = (str) => {
    if (str) {
      pricingSettingsOptions.forEach((opt) => {
        if (str.includes(opt.value)) {
          let re = new RegExp(opt.value, "g");
          str = str.replace(re, opt.label);
        }
      });
    }
    return str;
  };

  componentWillReceiveProps(props) {
    let referenceNumber=null
    if(props.selectedLoads &&  this.props.selectedLoads && this.props.selectedLoads.reference_number!== props.selectedLoads.reference_number){
      referenceNumber =props.selectedLoads.reference_number;
    }
    // else  if (
    //   props.location &&
    //   props.location.query &&
    //   props.location.query.reference_number
    // ) {
    //   referenceNumber =props.location.query.reference_number;
    // }
    if(referenceNumber){
      this.setState({ showSearch: false }, () => {
        let reference_number = encodeURIComponent(referenceNumber);
        const query = Object.assign({}, this.state.query, { reference_number });
        this.getTranscation(query);
      })
    }
  }

  handlePageChange = (page) => {
    var query = {
      limit: 10,
      skip: (page - 1) * 10,
    };
    this.setState(
      {
        activePage: page,
        query,
      },
      () => {
        let query = Object.assign(
          {},
          this.state.query,
          { searchTerm: this.state.searchTerm },
          { from: this.state.from, to: this.state.to }
        );
        if (
          this.props.location &&
          this.props.location.query &&
          this.props.location.query.reference_number
        ) {
          query.reference_number = encodeURIComponent(
            this.props.location.query.reference_number
          );
        }
        this.getTranscation(query);
      }
    );
  };

  fetchByOrder = () => {
    let order;
    if (this.state.order == -1) {
      order = 1;
    } else {
      order = -1;
    }
    this.setState({ order }, () => {
      const query = Object.assign(
        {},
        this.state.query,
        { searchTerm: this.state.searchTerm },
        { order },
        { from: this.state.from, to: this.state.to }
      );
      this.getTranscation(query);
    });
  };

  handleChangeOfACReceivable(e) {
    var input = document.getElementById("acReceivableSearch");
    var filterVal = input.value.toLowerCase();
    $("#acReceivableTable tbody tr").each(function (table) {
      var isSubstr = false;
      $(this)
        .find("td")
        .each(function () {
          var valueToCompare = "";
          if ($(this).find("input").length > 0) {
            valueToCompare = $(this).find("input").val().toLowerCase();
          } else {
            valueToCompare = $(this).text().toLowerCase();
          }
          if (valueToCompare.indexOf(filterVal) >= 0) {
            isSubstr = true;
          }
        });
      if (isSubstr) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  }

  getLocation = (statusOrder, createdBy, status) => {
    let location = {
      from: [],
      to: [],
    };

    if (status == "DELIVERED") {
      var findIndex = JSON.parse(JSON.stringify(statusOrder)).findIndex(
        (status) => {
          return status.status == "ARRIVED_PICKUP";
        }
      );
      var D = statusOrder[findIndex + 2];
      if (D && D.status == "DROPPED" && D.isCompleted && D.address) {
        let formattedData = D.address.address.split(",");
        location.from = [
          {
            company: D.company,
            street: formattedData[0],
            city: D.city ? D.city : formattedData[1],
            state: D.state ? D.state : formattedData[2],
          },
        ];
      } else {
        var D = statusOrder[findIndex];
        if (D && D.address && D.address.address) {
          let formattedData = D.address.address.split(",");
          location.from = [
            {
              company: D.company,
              street: formattedData[0],
              city: D.city ? D.city : formattedData[1],
              state: D.state ? D.state : formattedData[2],
            },
          ];
        }
      }
      var findIndex = JSON.parse(JSON.stringify(statusOrder)).findIndex(
        (status) => {
          return status.status == "DELIVERED";
        }
      );
      var D = statusOrder[findIndex];
      if (D && D.address && D.address.address) {
        let formattedData = D.address.address.split(",");
        location.to = [
          {
            company: D.company,
            street: formattedData[0],
            city: D.city ? D.city : formattedData[1],
            state: D.state ? D.state : formattedData[2],
          },
        ];
      }
    }
    if (status == "ARRIVED_PICKUP") {
      var driverId;
      var hasChanged = false;

      var findIndex = JSON.parse(JSON.stringify(statusOrder)).findIndex(
        (status) => {
          return status.address;
        }
      );
      var D = statusOrder[findIndex];
      if (D && D.address && D.address.address) {
        let formattedData = D.address.address.split(",");
        location.from = [
          {
            company: D.company,
            street: formattedData[0],
            city: D.city ? D.city : formattedData[1],
            state: D.state ? D.state : formattedData[2],
          },
        ];
      }
      var findIndex = statusOrder.findIndex((status) => {
        return status.status == "DROPPED" && status.isCompleted;
      });
      if (findIndex != -1 && statusOrder[findIndex]) {
        var D = statusOrder[findIndex];
        if (D && D.address && D.address.address) {
          let formattedData = D.address.address.split(",");
          location.to = [
            {
              company: D.company,
              street: formattedData[0],
              city: D.city ? D.city : formattedData[1],
              state: D.state ? D.state : formattedData[2],
            },
          ];
        } else {
          var findIndex = JSON.parse(JSON.stringify(statusOrder))
            .slice()
            .reverse()
            .findIndex((status) => {
              return status.isCompleted == true;
            });
          var D = statusOrder[findIndex];
          if (D && D.address && D.address.address) {
            let formattedData = D.address.address.split(",");
            location.to = [
              {
                company: D.company,
                street: formattedData[0],
                city: D.city ? D.city : formattedData[1],
                state: D.state ? D.state : formattedData[2],
              },
            ];
          }
        }
      } else {
        var findIndex = JSON.parse(JSON.stringify(statusOrder))
          .slice()
          .reverse()
          .findIndex((status) => {
            return status.status == "DELIVERED";
          });
        var D = statusOrder[findIndex];
        if (D && D.address && D.address.address) {
          let formattedData = D.address.address.split(",");
          location.to = [
            {
              company: D.company,
              street: formattedData[0],
              city: D.city ? D.city : formattedData[1],
              state: D.state ? D.state : formattedData[2],
            },
          ];
        }
      }
    }
    if (status == "COMPLETED") {
      var findIndexForDelivery = JSON.parse(
        JSON.stringify(statusOrder)
      ).findIndex((status) => {
        return status.status == "DELIVERED";
      });
      var D = statusOrder[findIndexForDelivery];
      if (D && D.address && D.address.address) {
        var formattedData = D.address.address.split(",");
        location.from = [
          {
            company: D.company,
            street: formattedData[0],
            city: D.city ? D.city : formattedData[1],
            state: D.state ? D.state : formattedData[2],
          },
        ];
      }

      let returnData = JSON.parse(JSON.stringify(statusOrder)).reverse();
      var findIndex = returnData.findIndex((status) => {
        return status.status == "ARRIVED_RETURN";
      });
      var D = returnData[findIndex];
      if (D && D.address && D.address.address) {
        let formattedData = D.address.address.split(",");
        location.to = [
          {
            company: D.company,
            street: formattedData[0],
            city: D.city ? D.city : formattedData[1],
            state: D.state ? D.state : formattedData[2],
          },
        ];
      }

      let dataForDrop = JSON.parse(JSON.stringify(statusOrder));
      if (findIndexForDelivery != -1) {
        var splicedDrop = dataForDrop
          .splice(findIndexForDelivery, dataForDrop.length)
          .reverse();
        var findIndexForDrop = splicedDrop.findIndex((status) => {
          return status.status == "DROPPED";
        });

        if (
          findIndexForDrop != -1 &&
          splicedDrop[findIndexForDrop] &&
          splicedDrop[findIndexForDrop].isCompleted
        ) {
          var D = splicedDrop[findIndexForDrop];
          if (D && D.address && D.address.address) {
            let formattedData = D.address.address.split(",");
            location.from = [
              {
                company: D.company,
                street: formattedData[0],
                city: D.city ? D.city : formattedData[1],
                state: D.state ? D.state : formattedData[2],
              },
            ];
          }
        }
      }
    }
    if (status == "NEED_TO_GET_DROP") {
      var driverId;
      var hasChanged = false;
      statusOrder.forEach((status, index) => {
        if (index == 0) {
          driverId = status.driver;
        } else {
          if (status.driver != driverId) {
            hasChanged = true;
          }
        }
      });
      var findIndexForDelivery = statusOrder.findIndex((status) => {
        return status.status == "DELIVERED";
      });
      var D = statusOrder[findIndexForDelivery];
      if (D && D.address && D.address.address) {
        let formattedData = D.address.address.split(",");
        location.from = [
          {
            company: D.company,
            street: formattedData[0],
            city: D.city ? D.city : formattedData[1],
            state: D.state ? D.state : formattedData[2],
          },
        ];
      }
      let dataForDrop = JSON.parse(JSON.stringify(statusOrder)).reverse();
      var findIndexForDrop = dataForDrop.findIndex((status) => {
        return status.status == "DROPPED" && status.isCompleted;
      });
      if (findIndexForDrop != -1) {
        var D = dataForDrop[findIndexForDrop];
        if (D && D.address && D.address.address) {
          let formattedData = D.address.address.split(",");
          location.to = [
            {
              company: D.company,
              street: formattedData[0],
              city: D.city ? D.city : formattedData[1],
              state: D.state ? D.state : formattedData[2],
            },
          ];
        }
      } else {
        var findIndex = statusOrder.findIndex((status) => {
          return status.status == "ARRIVED_RETURN";
        });
        var D = statusOrder[findIndex];
        if (D && D.address && D.address.address) {
          let formattedData = D.address.address.split(",");
          location.to = [
            {
              company: D.company,
              street: formattedData[0],
              city: D.city ? D.city : formattedData[1],
              state: D.state ? D.state : formattedData[2],
            },
          ];
        }
      }
      let dataForNeedToGetDrop = JSON.parse(JSON.stringify(statusOrder));
      if (findIndexForDelivery != -1) {
        var splicedDrop = dataForNeedToGetDrop.splice(
          findIndexForDelivery,
          dataForNeedToGetDrop.length
        );
        var findIndexForDrop = splicedDrop.findIndex((status) => {
          return status.status == "NEED_TO_GET_DROP";
        });

        if (
          findIndexForDrop != -1 &&
          splicedDrop[findIndexForDrop] &&
          splicedDrop[findIndexForDrop].isCompleted
        ) {
          var D = splicedDrop[findIndexForDrop];
          if (D && D.address && D.address.address) {
            let formattedData = D.address.address.split(",");
            location.from = [
              {
                company: D.company,
                street: formattedData[0],
                city: D.city ? D.city : formattedData[1],
                state: D.state ? D.state : formattedData[2],
              },
            ];
          }
        }
      }
    }
    return location;
  };

  onChangeTerminal = () => {
    let query = {
      fromDate: moment(this.state.fromDate)
        .tz(this.userTimeZone)
        .toISOString(),
      toDate: moment(this.state.toDate)
        .tz(this.userTimeZone)
        .toISOString(),
    };
    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      let choosenTerminals = this.state.choosenTerminals.map(
        (Data) => Data.value
      );
      query = Object.assign({}, query, {
        terminal: JSON.stringify(choosenTerminals),
      });
    }
    this.getTranscation(query);
  }

  approveDeduction = (params) => {
    params.from = "Driver Pay";
    approveNewDeduction(params).then((response) => {
      let transcations = JSON.parse(JSON.stringify(this.state.transcations));

      if (!response.data.data._id && typeof response.data.data === 'string') {
        toastr.show(response.data.data, 'error');
        return;
      }
      transcations = transcations.map((trans) => {
        if (trans._id == response.data.data._id) {
          let data=response.data.data;
          trans.lock=data.lock;
          trans.approved=data.approved;
        }
        return trans;
      });
      this.setState({ transcations, openedId: null });

      if (params.approved) {
        toastr.show("Approved!", "success");
      } else {
        toastr.show("This has been unapproved.", "success");
      }
    });
  };
  calculateAmount = (amount, additionalPrice) => {
    let totalAmount = amount.toFixed(2);
    let otherAmount = [];
    if (
      additionalPrice &&
      additionalPrice.length > 0 &&
      Array.isArray(additionalPrice)
    ) {
      additionalPrice.forEach((price) => {
        if (price && price != "") {
          otherAmount.push(`${price.name} = ${price.amount.toFixed(2)}`);
        }
      });
    }
    if (otherAmount.length > 0) {
      return `${totalAmount}, ${otherAmount.join(",")}`;
    } else {
      return `${totalAmount}`;
    }
  };

  filterDriver = (e) => {
    let value = e.target.value;
    let transcations = [];
    if (value) {
      transcations = update(transcations, {
        $set: this.state.originalTranscations,
      });
      transcations = transcations.filter((data) => {
        return (
          data.createdBy &&
          data.createdBy.driver &&
          data.createdBy.driver.terminal == value
        );
      });
    } else {
      transcations = update(transcations, {
        $set: this.state.originalTranscations,
      });
    }
    this.setState({ transcations });
  };
  update = (data) => {
    let transcations = JSON.parse(JSON.stringify(this.state.transcations));
    transcations = transcations.map((trans) => {
      if (trans._id == data._id) {
        trans = { ...data };
      }
      return trans;
    });
    this.setState({ transcations, openedId: null });
  };

  handleChange = (e, info) => {
    if (e) {
      let startDate = moment(e.toISOString())
        .tz(this.userTimeZone)
        .startOf("weeks")
        .toISOString();
      let endDate = moment(e.toISOString())
        .tz(this.userTimeZone)
        .endOf("weeks")
        .toISOString();

      const time = moment(e.toISOString())
        .tz(this.userTimeZone)
        .toISOString();
      changeWeek({
        _id: info._id,
        from: info.from,
        startDate,
        endDate,
        createdBy: info.createdBy._id,
        time,
        hasSettlementSettings:
          this.state.settlementSettings &&
          this.state.settlementSettings.duration &&
          this.state.settlementSettings.day
            ? true
            : false,
      }).then((response) => {
        const transcations = this.state.transcations.map((trans) => {
          if (trans._id == response.data.data._id) {
           let data=response.data.data;
            trans.startDate=data.startDate;
            trans.endDate=data.endDate;
            trans.time=data.time;
          }
          return trans;
        });
        this.setState({ transcations });
        toastr.show("You have changed the settlement week. You must verify your settlements.", "success");
        // this.closeEditOptions()
      });
    }
  };

  finalSearch = () => {
    let query = Object.assign(
      {},
      this.state.query,
      { searchTerm: this.state.searchTerm },
      { from: this.state.from, to: this.state.to }
    );
    if (this.props.location.query.reference_number) {
      let reference_number = this.props.location.query.reference_number;
      query = Object.assign({}, query, { reference_number });
    }
    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      let choosenTerminals = this.state.choosenTerminals.map(
        (Data) => Data.value
      );
      query = Object.assign({}, query, {
        terminal: JSON.stringify(choosenTerminals),
      });
    }
    this.getTranscation(query);
  };

  onSelectTerminal = (driverPayTerminal) => {
    this.setState({ driverPayTerminal });
  };

  getDates = (startDate) => {
    var dateArray = new Array();
    _.range(0,7).map((j) => {
      let startDates = moment(startDate).add(j+1, 'days').toISOString();
      dateArray.push(startDates)
    })
    return dateArray;
  }
 
  paginate = (val) => {
    this.setState({ isLoadingBarShow: true });
    if (!this.state.loading) {
      const startDate = moment(val).tz(this.userTimeZone).startOf("week").toISOString();
      var dateArray = this.getDates(startDate);
      let updatedDates = [];
      this.setState({dateArray})
      dateArray.map((d, i) => {
        if(this.state.indexData.includes(i)) {
          updatedDates.push(d);
          this.setState({filterStatus: updatedDates})
        }
      })
      this.setState(
        {
          selectedDate: val,
          startDate: moment(val).tz(this.userTimeZone).startOf("week").add(1, 'days').toISOString(),
          endDate: moment(val).tz(this.userTimeZone).endOf("week").toISOString(),
          fromDate: null,
          toDate: null,
          indexData: this.state.indexData ? this.state.indexData : [],
          skip: 0
        },
        () => {

          this.getTranscation();
          this.getTranscationCount();
        }
      );
    } else {
      toastr.show("This request is already processing. ", "warning");
    }
  };
  getSelectedLoadInfo(reference_number) {
    getLoad({ searchTerm: reference_number }).then((res) =>
      this.setState({
        showSidebar: !this.state.showSidebar,
        selectedLoad: res.data.data[0],
      })
    );
  }
  filterStatus(date, index) {
    let { filterStatus, indexData } = this.state;
    if (filterStatus.map((d) => moment(d).toISOString()).includes(moment(date).toISOString()) || indexData.includes(index)) {
      filterStatus = filterStatus.map((d) => moment(d).toISOString()).subtract(moment(date).toISOString());
      indexData = indexData.subtract(index)
    } else {
      filterStatus.push(moment(date).toISOString());
      indexData.push(index)
    }
    filterStatus = filterStatus.map((d) => moment(d).tz(this.userTimeZone).toISOString())
    this.setState({ filterStatus, activePage: 1,  indexData, skip: 0}, () => this.getTranscation());
  }
  // enumerateDaysBetweenDates(startDate, endDate) {
  //   var dates = [];

  //   var currDate = moment(startDate).startOf('day');
  //   var lastDate = moment(endDate).startOf('day');

  //   while (currDate.add(1, 'days').diff(lastDate) <= 0) {
  //     dates.push({ day: currDate.format('ddd'), totalNetPay: 0 });
  //   }

  //   return dates;
  // };

  sortTable = ((sort) => {
    if(sort.length > 0) {
      this.setState({
        sort : sort.slice(-1),
        asce: sort[0].direction === "ASC" ? true : sort[0].direction === "DESC" ? false : null,
      });
    } else {
      this.setState({sort: null})
    }
  });

  onDateChange = (e, dateType) => {

    let dates = {
      fromDate:
        dateType === "fromDate"
          ? moment(e)
          : this.state.fromDate
          ? this.state.fromDate
          : this.state.startDate,
      toDate:
        dateType === "toDate"
          ? moment(e)
          : this.state.toDate
          ? this.state.toDate
          : this.state.endDate,
    };
    this.setState(
      {
        fromDate: dates.fromDate,
        toDate: dates.toDate,
        skip: 0
      },
      () => {
        let query = {
          fromDate: moment(this.state.fromDate)
            .tz(this.userTimeZone)
            .toISOString(),
          toDate: moment(this.state.toDate)
            .tz(this.userTimeZone)
            .toISOString(),
        };
        this.getTranscation(query);
      }
    );
  };

  redirectToRates = (payItem) => {
    if (payItem.driverPayRateId) {
      browserHistory.push('/tms/driver-pay-rates?rateId=' + payItem.driverPayRateId);
    } else if (payItem.dynamicDriverPayId) {
      browserHistory.push('/dynamic-driverPay?rateId=' + payItem.dynamicDriverPayId);
    }
  }

  getColumns = () => {
    let columns = [
      {
        key: "reference_number",
        name: "Load",
        width: 120,
        sortable: true,
        resizable: true,
        formatter: ({ row }) => {
          return (
            <div class="text-primary font-12" onClick={() =>
              !this.props.isTab &&
              this.getSelectedLoadInfo(row.reference_number)
            }> 
              <span className="pointer">{row.reference_number}</span>
            </div>
          )
        },
      },
      {
        key: "time",
        name: "Time",
        resizable: true,
        formatter: ({ row }) => {
          return <div class="font-12">{moment(row.time).tz(this.userTimeZone).format("lll")}</div>
        },
      },
      {
        key: "driverName",
        name: "Driver",
        resizable: true,
        formatter: ({ row }) => {
          return <div class="font-12" 
            onClick={() =>
              this.state.showCardsInDriverPay &&
              this.setState({
                filterdDriver: row.createdBy,
                activePage: 1,
              })
          }><span className="pointer">{row && row.createdBy && row.createdBy.name
            ? row.createdBy.name
            : " "}{" "}
          {row && row.createdBy && row.createdBy.lastName
            ? row.createdBy.lastName
            : ""}</span></div>
        },
      },

      {
        key: "truck",
        name: "Truck",
        width: 90,
        resizable: true,
        formatter: ({ row }) => {
          return <div class="font-12">{row.truck && row.truck.equipmentID
            ? row.truck.equipmentID
            : ""}</div>
        },
      },
      {
        key: "fleetTruckOwner",
        name: "Owner",
        width: 90,
        resizable: true,
        formatter: ({ row }) => {
          return <div class="font-12">{row.fleetTruckOwner &&
            row.fleetTruckOwner.company_name
              ? row.fleetTruckOwner.company_name
              : ""}</div>
        },
      },
      {
        key: "status",
        name: "Load Status",
        resizable: true,
        formatter: ({ row }) => {
          return <div class="font-12">{this.renderStatus(
            row.status,
            row.reference_number
          )}</div>
        },
      },
      {
        key: "from",
        name: "From",
        width: 250,
        resizable: true,
        formatter: ({ row }) => {
          let address = undefined;
          if (row.statusOrder && !row.loadId) {
            address = this.getLocation(
              row.statusOrder,
              row.createdBy,
              row.status
            );
          } else {
            address = getDriverOrderLocation(
              row.loadId ? row.loadId.driverOrder : [],
              row.createdBy,
              row.status
            );
          }
          // Get From And to
          let from = row.from ? row.from : "";
          // paymenttype is DYNAMIC DRIVER
          if (
            row &&
            row.paymentType &&
            row.paymentType.includes("DYNAMIC DRIVER")
          ) {
            from = row.from;
          } else {
            // fRom
            if (row.isManual && row.from) {
              if (
                row.from != "undefined" &&
                row.from != undefined &&
                row.from != "transcation"
              ) {
                from = row.from;
              }
            }
            if (row.groupFrom) {
              from = row.groupFrom;
            }
            if (
              !row.groupForm &&
              address &&
              address.from.length > 0
            ) {
              from = address.from.map((address, keya) => {
                return (
                  <span key={keya}>
                    {address.company ? `${address.company}` : ""}
                    {address.city ? `, ${address.city}` : ""}
                    {address.state ? `, ${address.state}` : ""}
                  </span>
                );
              });
            }
          }
          return <div class="font-12">{from}</div>
        },
      },
      {
        key: "to",
        name: "To",
        width: 250,
        resizable: true,
        formatter: ({ row }) => {
          let address = undefined;
          if (row.statusOrder && !row.loadId) {
            address = this.getLocation(
              row.statusOrder,
              row.createdBy,
              row.status
            );
          } else {
            address = getDriverOrderLocation(
              row.loadId ? row.loadId.driverOrder : [],
              row.createdBy,
              row.status
            );
          }
          // Get From And to
          let to = row.to ? row.to : "";
          // paymenttype is DYNAMIC DRIVER
          if (
            row &&
            row.paymentType &&
            row.paymentType.includes("DYNAMIC DRIVER")
          ) {
            to = row.to;
          } else {
            
            // To
            if (row.isManual && row.to) {
              to = row.to;
            }
            if (row.groupTo) {
              to = row.groupTo;
            }
            if (
              !row.groupTo &&
              address &&
              address.to.length > 0
            ) {
              to = address.to.map((address, keya) => {
                return (
                  <div key={keya}>
                    {keya == 1 && <p>-----------</p>}
                    <span>
                      {address.company
                        ? `${address.company}`
                        : ""}
                      {address.city ? `, ${address.city}` : ""}
                      {address.state ? `, ${address.state}` : ""}
                    </span>
                  </div>
                );
              });
            }
          }
          return <div class="font-12">{to}</div>
        },
      },
      {
        key: "totalPrice",
        name: "Date & Time",
        width: 170,
        resizable: true,
        formatter: ({ row }) => {
          let conditionForWeekChange = true;
          if (row.lock) {
            conditionForWeekChange = false;
          }
          if (row.lock && row.approved != true) {
            conditionForWeekChange = true;
          }
          if (row.lock && row.approved == true) {
            conditionForWeekChange = false;
          }
          return (
            <div>
              {!conditionForWeekChange &&
              <div>{moment(row.time).format("lll")}</div>}
              {conditionForWeekChange && (
                <div className="input-group mt-1">
                  <div className="input-group-append input-wrapper">
                    <Datetime
                      dateFormat="MM-DD-YY"
                      inputProps={{
                        className:
                          "w-150 form-control datetime-right",
                      }}
                      displayTimeZone={this.userTimeZone}
                      onChange={(e) =>
                        this.handleChange(e, row)
                      }
                      value={moment(row.time)}
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        },
      },
      {
        key: "amount",
        name: "Amount",
        width: 90,
        resizable: true,
        formatter: ({ row }) => {
          return <div class="font-12">
            {row.lock &&
              this.calculateAmount(
                row.amount,
                row.additionalPrice
              )}
            {!row.lock && (
              <Fragment>
                <NewEdit
                  key={row.index+row._id+row.amount}
                  from="transcation"
                  update={this.update}
                  form={row._id}
                  initialValues={{
                    amount: row.amount,
                    id: row._id,
                    reference_number: row.reference_number,
                    status: row.status,
                  }}
                  label={this.renderStatus(
                    row.status,
                    row.reference_number
                  )}
                ></NewEdit>
                {row.additionalPrice &&
                  row.additionalPrice.length > 0 &&
                  Array.isArray(row.additionalPrice) &&
                  row.additionalPrice &&
                  row.additionalPrice.map((price, k) => {
                    if (price) {
                      
                      return (
                        <Fragment>
                        <NewEdit
                          key={k+row.index+row._id+row.amount}
                          from="transcation"
                          update={this.update}
                          form={price.name}
                          // closeEditOptions={this.closeEditOptions}
                          initialValues={{
                            amount: price.amount,
                            id: row._id,
                            originalId: price._id,
                          }}
                          label={price.name}
                          orginalPricing={
                            row.additionalPrice
                          }
                        ></NewEdit>
                        </Fragment>
                      );
                    }
                  })}
              </Fragment>
            )}
          </div>
        },
      },
      {
        key: "driverPayRateId",
        name: "",
        width: 80,
        cellClass: "overflow-visible",
        resizable: true,
        formatter: ({row}) => {
          return (
            <div>
              {(row.driverPayRateId || row.dynamicDriverPayId || row.isManual) &&
                <div data-tip data-for={`pay_rate`} className={`tooltip-label`}>
                  <span  onClick={() => this.redirectToRates(row)}>
                    <IconNoteArrowRight />
                  </span>
                  <div
                    className="tooltip tooltip--gray-700 bs-tooltip-top"
                    id={`pay_rate`}
                    data-placement="top"
                    style={{top:"-27px",right:"-9px",position:"absolute"}}
                  >{row.driverPayRateId ? "Driver Pay Rate (Beta)"
                  : ( row.dynamicDriverPayId ? "Dynamic Driver Pay"
                  : ( row.isManual ? "Manually Changed" : "") )}
                  <span className="arrow"></span>
                  </div>
                </div>
              }
            </div>
          )
        }
      },
      {
        key: "approved",
        name: "",
        width: 90,
        resizable: true,
        formatter: ({row}) => {
          let { isStatusHovered } = this.state;
          let isHovering = isStatusHovered === row._id;
          return (
            <div>
              {row.lock && row.approved === true ? (
                <span className="pointer badge badge-purple-50 py-1 ">
                  Completed
                </span>
              ) : row.approved === true ? (
                <Fragment>
                  <span
                    className={`pointer d-block text-center badge ${
                      isHovering ? "badge-danger" : "badge-accent-100"
                    }`}
                    onMouseEnter={() =>
                      this.setState({
                        isStatusHovered: row._id,
                      })
                    }
                    onMouseLeave={() =>
                      this.setState({
                        isStatusHovered: null,
                      })
                    }
                    onClick={() =>
                      this.approveDeduction({
                        _id: row._id,
                        approved: row.approved ? false : true,
                      })
                    }
                  >
                    {isHovering ? (
                      <span>UnApprove</span>
                    ) : (
                      <span>Approved</span>
                    )}
                  </span>
                </Fragment>
              ) : (
                <span
                  className={`pointer d-block text-center badge ${
                    isHovering ? "badge-success" : "badge-danger-light"
                  }`}
                  onMouseEnter={() =>
                    this.setState({
                      isStatusHovered: row._id,
                    })
                  }
                  onMouseLeave={() =>
                    this.setState({
                      isStatusHovered: null,
                    })
                  }
                  onClick={() =>
                    this.approveDeduction({
                      _id: row._id,
                      approved: row.approved ? false : true,
                    })
                  }
                >
                  {isHovering ? (
                    <span>Approve</span>
                  ) : (
                    <span>Unapproved</span>
                  )}
                </span>
              )}
            </div>
          )
        }
      },
      {
        key: "delete",
        name: "",
        width: 80,
        resizable: true,
        formatter: ({row}) => {
          return (
            <div>
              {!row.lock && (
                <Fragment>
                  <button
                    onClick={() =>
                      this.deleteNewDriverPay({
                        _id: row._id,
                        status: row.status,
                        reference_number: row.reference_number,
                        type: "driverpay",
                      })
                    }
                    style={{
                      pointerEvents: row.lock ? "none" : "",
                    }}
                    type="button"
                    className="btn btn-link text-muted"
                    data-tip
                    data-for="delete"
                  >
                    <IconTrash />{" "}
                  </button>

                  <ReactTooltip id="delete" place="top">
                    <span>Delete</span>
                  </ReactTooltip>
                </Fragment>
                // </div>
              )}
            </div>
          )
        }
      }
    ]
    if((getStorage("currentUserRole") == "fleetmanager" && configuration.idForTerminal.indexOf(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier) != -1) ||
      (getStorage("currentUserRole") == "carrier" && configuration.idForTerminal.indexOf(getStorage("currentUserID") ) != -1)) {
        columns.splice(0, 0, {
          key: "terminal",
          name: "Terminal",
          width: 80,
          formatter: ({ row }) => {
            return <div class="font-12"> {row.terminal ? row.terminal.name : ""}</div>
          },
        })
      } 
      
    
    this.setState({ columns })
  }
  
  handleScroll = (e) => {
    if (this.state.isLoadingBarShow || isAtBottom(e)) return;
    
    if(this.state.loadsPerReq + this.state.skip < this.state.totalItemsCount) {
      this.setState({skip: this.state.loadsPerReq + this.state.skip}, () => {
        this.getTranscation({}, true)
      })
    }
  }

  handleDefaultScroll = () => {
    const dataTable = document.getElementsByClassName('rdg r104f42s700-beta13 fill-grid rdg-light');
    if (dataTable && dataTable[0]) {
      this.handleScroll({ currentTarget: dataTable[0] }, true);
    }
  }
  renderReturn = () => {

    let { transcations, filterStatus, isStatusHovered } = this.state;
    if (this.state.filterdDriver) {
      transcations = transcations.filter(
        (f) =>
          f &&
          f.createdBy &&
          f.createdBy._id === this.state.filterdDriver._id &&
          !f.approved
      );
    }
    transcations.forEach((el) => {
      el.calculateAmount = this.calculateAmount(
        el.amount,
        el.additionalPrice
      )
      el.renderStatus = this.renderStatus(el.status, el.reference_number);
    });
    if (this.state.sort) {
      let sortData = this.state.sort[0];
      if(sortData) {
      transcations = _.orderBy(
        transcations,
        trans => {
          if (sortData.columnKey === 'from') {
            return trans[sortData.columnKey] ? trans[sortData.columnKey].toLowerCase() : (trans.groupFrom && trans.groupFrom.toLowerCase())
          }else if(sortData.columnKey === 'to'){
            return trans[sortData.columnKey] ? trans[sortData.columnKey].toLowerCase() : (trans.groupTo && trans.groupTo.toLowerCase())
          }else if(sortData.columnKey === 'amount'){
            return Number(trans[sortData.columnKey])
          }else if(sortData.columnKey === 'driverName'){
            return trans.createdBy.name.toLowerCase()
          } else if(sortData.columnKey === "fleetTruckOwner"){
            return trans.fleetTruckOwner && trans.fleetTruckOwner.company_name ? trans.fleetTruckOwner.company_name.toLowerCase() : null
          } else if(sortData.columnKey === "truck"){
            return trans.truck && trans.truck.equipmentID ? trans.truck.equipmentID.toLowerCase() : null
          }else if(sortData.columnKey === "terminal"){
            return trans.terminal.name ? trans.terminal.name.toLowerCase() : null
          } else if(sortData.columnKey === "totalPrice"){
            return trans && trans.time?.toLowerCase()
          } else {
            return trans[sortData.columnKey]?.toLowerCase()
          }
        },
        this.state.asce === false && "desc"
      );
      }
    
    }
    let Terminal = [];
    if (this.state.terminals && this.state.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.state.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }
    return (
      <div
        className={`position-relative ${
          !this.props.isTab && "subheader-wrapper"
        }`}
      >
        {!this.props.isTab && (
          <SubHeaderTab
            val="accPayableItems"
            buttons={
              <Fragment>
                <button
                  type="button"
                  className="btn btn-success  ml-2 text-center"
                  onClick={() =>
                    this.setState({ showManual: !this.state.showManual })
                  }
                >
                  <IconPlus className="mr-2" />
                  Add Driver Pay
                </button>
              </Fragment>
            }
          />
        )}

        <div className={`${!this.props.isTab && "page-fluid"}`}>
          {this.props.isTab && (
            <div class="card p-15 mb-0 d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex align-items-center false">
                <div className="app-search header-search mr-20">
                  <span className="search-icon"> </span>
                  <div className="position-relative">
                    <input
                      className="form-control input-search-left"
                      name=""
                      id="acReceivableSearch"
                      placeholder="Search"
                      value={this.state.searchTerm}
                      onChange={this.searchLoad}
                    />
                  </div>
                </div>
                {showForCompany() && (
                  <React.Fragment>
                    <div className="form-group mb-0 position-relative">
                      <div className="input-wrapper">
                        <Datetime
                          // displayTimeZone={timeZone}
                          onChange={(e) => this.onDateChange(e, "fromDate")}
                          value={
                            this.state.fromDate
                              ? this.state.fromDate
                              : this.state.startDate
                          }
                          timeFormat={false}
                        />
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0 ml-2 position-relative">
                      <div className="input-wrapper">
                        <Datetime
                          // displayTimeZone={timeZone}
                          onChange={(e) => this.onDateChange(e, "toDate")}
                          value={
                            this.state.toDate
                              ? this.state.toDate
                              : this.state.endDate
                          }
                          timeFormat={false}
                        />
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      </div>
                    </div>
                    {this.state.isTerminal && (['fleetmanager', 'carrier'].includes(getStorage('currentUserRole')) > -1) && (
                    <div className="form-group mb-0 ml-2 position-relative">
                      <Select
                        onChange={(choosenTerminals) =>
                          this.setState({ choosenTerminals }, () => {
                            this.onChangeTerminal();
                          })
                        }
                        isMulti
                        isClearable={true}
                        styles={smallSelectStyle}
                        name="choosenTerminals"
                        options={Terminal}
                        className="basic-multi-select w-150"
                        classNamePrefix="select"
                      />
                    </div>
                  )}
                  </React.Fragment>
                )}
                </div>
              <button
                type="button"
                className="btn btn-primary  ml-2 text-center"
                onClick={() =>
                  this.setState({ showManual: !this.state.showManual })
                }
              >
                <IconPlus className="mr-2" />
                Add Driver Pay
              </button>
            </div>
          )}

          {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
          {!this.state.showSidebar && (
            <Fragment>
              <div className="top-page">
              {!this.props.isTab && (
                <div className="pb-15 d-flex align-items-center">
                  <Fragment>
                    <DayCalenderFilter
                      handleDateChange={this.paginate}
                      selectedDate={this.state.selectedDate}
                      deliveryFrom={this.state.startDate}
                      isWeek={true}
                    />
                    {/* Pagination  */}
                    {/* <Pagination
                      innerClass="pagination ml-auto mb-0"
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={transcations.length}
                      pageRangeDisplayed={10}
                      onChange={(page) => this.setState({ activePage: page })}
                    /> */}
                  </Fragment>                  
                </div>
                )}
              </div>
              {this.state.showCardsInDriverPay && (
                <div className={`form-row ${this.state.filterStatus.length > 0 && 'hasactive'}`}>
                  {
                    this.state.dateWiseCount.map((date, j) => {
                          return (
                            <ChartCard
                              key={j}
                              title={moment(date._id).format("dddd")}
                              date={moment(date._id).format("D MMMM")}
                              count={date ? date.total : 0}
                              price={date.amount !== "0.00" ? date.amount.toFixed(2) : "0.00"}
                              chart={
                                <Chart
                                  options={chartOptions}
                                  series={series}
                                  type="bar"
                                  width="40"
                                  height="30"
                                />
                              }
                              onCardClicked={() => this.setState({skip: 0}, ()=> {
                                this.filterStatus(date._id, j)
                              })}
                              isCardActive={this.state.indexData.includes(j)}
                            />
                          );
                      })
                  }
                </div>
              )}
              {!this.props.isTab &&
                <div className={`filter-row d-flex align-items-center mt-2`}>
                  <div className="app-search header-search mr-20">
                    <span className="search-icon"> </span>
                    <div className="position-relative">
                      <input
                        className="form-control input-search-left"
                        name=""
                        id="acReceivableSearch"
                        placeholder="Search"
                        value={this.state.searchTerm}
                        onChange={this.searchLoad}
                      />
                    </div>
                  </div>
                  <div className="ml-2">
                    <Select
                      isClearable={true}
                      isMulti={true}
                      options={docType}
                      placeholder="Select missing doc type"
                      className="w-200"
                      styles={smallSelectStyle}
                      value={this.state.missingDocType}
                      onChange={(missingDocType) => {
                        this.setState({ missingDocType, activePage: 1, skip: 0 }, () => {
                        this.getTranscation();
                        this.getTranscationCount();
                      })}}
                    />
                  </div>
                  {showForCompany() && (
                    <React.Fragment>
                      <div className="form-group mb-0 position-relative">
                        <div className="input-wrapper">
                          <Datetime
                            // displayTimeZone={timeZone}
                            onChange={(e) => this.onDateChange(e, "fromDate")}
                            value={
                              this.state.fromDate
                                ? moment(this.state.fromDate)
                                : moment(this.state.startDate)
                            }
                            timeFormat={false}
                          />
                          <div className="input-icon">
                            <IconCalendar />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-0 ml-2 position-relative">
                        <div className="input-wrapper">
                          <Datetime
                            // displayTimeZone={timeZone}
                            onChange={(e) => this.onDateChange(e, "toDate")}
                            value={
                              this.state.toDate
                                ? moment(this.state.toDate)
                                : moment(this.state.endDate)
                            }
                            timeFormat={false}
                          />
                          <div className="input-icon">
                            <IconCalendar />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.filterdDriver && (
                    <button
                      type="button"
                      className="btn btn-danger ml-2"
                      onClick={() => this.setState({ filterdDriver: undefined })}
                    >
                      Driver:{" "}
                      {this.state.filterdDriver.name +
                        " " +
                        this.state.filterdDriver.lastName}
                      <i className="uil uil-times ml-1"></i>
                    </button>
                  )}

                  {this.state.isTerminal && (['fleetmanager', 'carrier'].includes(getStorage('currentUserRole')) > -1) && (
                    <div className="form-group mb-0 ml-2 position-relative">
                      <Select
                        onChange={(choosenTerminals) =>
                          this.setState({ choosenTerminals }, () => {
                            this.finalSearch();
                          })
                        }
                        styles={smallSelectStyle}
                        isMulti
                        name="choosenTerminals"
                        options={Terminal}
                        className="basic-multi-select w-150"
                        classNamePrefix="select"
                      />
                    </div>
                  )}
                </div>
              }

               {
                 this.state.showManual &&  
                <Manual
                  onSelectTerminal={this.onSelectTerminal}
                  terminals={this.state.terminals}
                  submitForm={this.addManual}
                  location={this.props.location}
                  allDrivers={
                    this.props.allDrivers && this.props.allDrivers.length > 0
                      ? this.props.allDrivers
                      : this.state.allDrivers
                  }
                  loadDriver={true}
                  isTab={this.props.isTab}
                  isSaving={this.state.isSaving}
                  showManual={this.state.showManual}
                  onCancel={() =>
                    this.setState({ showManual: !this.state.showManual })
                  }
                  selectedLoads={this.props.selectedLoads}
                />
              }

              {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
              <div className="mt-1">
                <DataGrid
                  key={this.remountKey}
                  columns={this.state.columns}
                  rows={transcations}
                  rowHeight={50}
                  defaultColumnOptions={{
                    sortable: true,
                  }}
                  sortColumns={this.state.sort}
                  onSortColumnsChange={this.sortTable}
                  
                  headerRowHeight={30}
                  className={"fill-grid rdg-light"}
                  onScroll={this.handleScroll}
                  components={{
                    noRowsFallback: <EmptyRowsRenderer />
                  }}
                   style={{ height: `calc(100vh - ${this.props.isTab ? '285px' : '368px'})` }}
                />
              </div>
              {/* <div
                className="table-responsive"
                style={{ height: `calc(100vh - ${this.props.isTab ? '285px' : '368px'})` }}
                ref="inner"
              >
                {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
                <table className="table table-card table-card--ls">
                  <thead>
                    <tr>
                      {((getStorage("currentUserRole") == "fleetmanager" &&
                        configuration.idForTerminal.indexOf(
                          JSON.parse(getStorage("loggedInUser")).fleetManager
                            .carrier
                        ) != -1) ||
                        (getStorage("currentUserRole") == "carrier" &&
                          configuration.idForTerminal.indexOf(
                            getStorage("currentUserID")
                          ) != -1)) && (
                        <th
                          width="10%"
                          scope="col"
                          onClick={() =>
                            sortingOptions.hasOwnProperty("Terminal") &&
                            this.sortTable(sortingOptions["Terminal"])
                          }
                            className={`${sortingOptions.hasOwnProperty('Terminal') && (this.state.sort == undefined || this.state.sort !== sortingOptions['Terminal'])
                          ? 'table-sort table-sort--default'
                          : (sortingOptions.hasOwnProperty('Terminal') ? this.state.sort === sortingOptions['Terminal'] && this.state.asce == true ? 'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                        >
                          Terminal
                          {
                            sortingOptions.hasOwnProperty("Terminal")  
                          }
                        </th>
                      )}
                      {[
                        "Load",
                        "Time",
                        "Driver",
                        "Truck",
                        "Owner",
                        "Load Status",
                        "From",
                        "To",
                        "Date & Time",
                        "Amount",
                        
                      ].map((thStr) => (
                        <th
                          key={thStr}
                          width="10%"
                          scope="col"
                          onClick={() =>
                            sortingOptions.hasOwnProperty(thStr) &&
                            this.sortTable(sortingOptions[thStr])
                          }
                           className={`${sortingOptions.hasOwnProperty(thStr) && (this.state.sort == undefined || this.state.sort !== sortingOptions[thStr])
                            ? 'table-sort table-sort--default'
                            : (sortingOptions.hasOwnProperty(thStr) ? this.state.sort === sortingOptions[thStr] && this.state.asce == true ?   'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                        >
                          {thStr}
                         
                        </th>
                      ))}
                      <th width="15%" scope="col" className=" text-center"></th>
                      <th width="15%" scope="col" className=" text-center"></th>
                      <th width="15%" scope="col" className=" text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {transcations &&
                      transcations.map((info, key) => {
                        if(!this.props.isTab){
                          if (
                            key < (this.state.activePage - 1) * 10 ||
                            key > this.state.activePage * 10 - 1
                          )
                            return;
                        }
                        let address = undefined;
                        if (info.statusOrder && !info.loadId) {
                          address = this.getLocation(
                            info.statusOrder,
                            info.createdBy,
                            info.status
                          );
                        } else {
                          address = getDriverOrderLocation(
                            info.loadId ? info.loadId.driverOrder : [],
                            info.createdBy,
                            info.status
                          );
                        }
                        if (info.terminal && !info.terminal.name) {
                          info.terminal = this.state.terminals.find(
                            (terminal) => terminal._id == info.terminal
                          );
                        }
                        let conditionForWeekChange = true;
                        if (info.lock) {
                          conditionForWeekChange = false;
                        }
                        if (info.lock && info.approved != true) {
                          conditionForWeekChange = true;
                        }
                        if (info.lock && info.approved == true) {
                          conditionForWeekChange = false;
                        }
                        // Get From And to
                        let from = info.from ? info.from : "";
                        let to = info.to ? info.to : "";
                        // paymenttype is DYNAMIC DRIVER
                        if (
                          info &&
                          info.paymentType &&
                          info.paymentType.includes("DYNAMIC DRIVER")
                        ) {
                          from = info.from;
                          to = info.to;
                        } else {
                          // fRom
                          if (info.isManual && info.from) {
                            if (
                              info.from != "undefined" &&
                              info.from != undefined &&
                              info.from != "transcation"
                            ) {
                              from = info.from;
                            }
                          }
                          if (info.groupFrom) {
                            from = info.groupFrom;
                          }
                          if (
                            !info.groupForm &&
                            address &&
                            address.from.length > 0
                          ) {
                            from = address.from.map((address, keya) => {
                              return (
                                <span key={keya}>
                                  {address.company ? `${address.company}` : ""}
                                  {address.city ? `, ${address.city}` : ""}
                                  {address.state ? `, ${address.state}` : ""}
                                </span>
                              );
                            });
                          }
                          // To
                          if (info.isManual && info.to) {
                            to = info.to;
                          }
                          if (info.groupTo) {
                            to = info.groupTo;
                          }
                          if (
                            !info.groupTo &&
                            address &&
                            address.to.length > 0
                          ) {
                            to = address.to.map((address, keya) => {
                              return (
                                <div key={keya}>
                                  {keya == 1 && <p>-----------</p>}
                                  <span>
                                    {address.company
                                      ? `${address.company}`
                                      : ""}
                                    {address.city ? `, ${address.city}` : ""}
                                    {address.state ? `, ${address.state}` : ""}
                                  </span>
                                </div>
                              );
                            });
                          }
                        }

                        let isHovering = isStatusHovered === info._id;

                        return (
                          <tr
                            key={key}
                            style={{
                              display: info.isDeleted === true ? "none" : "",
                            }}
                          >
                            {((getStorage("currentUserRole") ==
                              "fleetmanager" &&
                              configuration.idForTerminal.indexOf(
                                JSON.parse(getStorage("loggedInUser"))
                                  .fleetManager.carrier
                              ) != -1) ||
                              (getStorage("currentUserRole") == "carrier" &&
                                configuration.idForTerminal.indexOf(
                                  getStorage("currentUserID")
                                ) != -1)) && (
                              <td>{info.terminal ? info.terminal.name : ""}</td>
                            )}
                            <td
                              className="text-primary font-weight-500"
                              onClick={() =>
                                !this.props.isTab &&
                                this.getSelectedLoadInfo(info.reference_number)
                              }
                            >
                              <span className="pointer">
                                {info.reference_number}
                              </span>
                            </td>
                            <td>
                           
                              {moment(info.time)
                                .tz(this.userTimeZone)
                                .format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)}
                       
                            </td>
                            
                            
                            <td
                              onClick={() =>
                                this.state.showCardsInDriverPay &&
                                this.setState({
                                  filterdDriver: info.createdBy,
                                  activePage: 1,
                                })
                              }
                              // className="d-flex justify-content-left align-items-center"
                            >
                              {info && info.createdBy && info.createdBy.name
                                ? info.createdBy.name
                                : " "}{" "}
                              {info && info.createdBy && info.createdBy.lastName
                                ? info.createdBy.lastName
                                : ""}
                            </td>

                            <td>
                              {info.truck && info.truck.equipmentID
                                ? info.truck.equipmentID
                                : ""}
                            </td>
                            <td>
                              {info.fleetTruckOwner &&
                              info.fleetTruckOwner.company_name
                                ? info.fleetTruckOwner.company_name
                                : ""}
                            </td>
                            <td>
                              {this.renderStatus(
                                info.status,
                                info.reference_number
                              )}
                            </td>
                            <td>{from}</td>
                            <td>{to}</td>
                            <td>
                              {!conditionForWeekChange && info.time &&
                                moment(info.time).format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)}
                                {conditionForWeekChange && info.time && (
                                <div className="input-group mt-1">
                                  <div className="input-group-append input-wrapper">
                                    <Datetime
                                      dateFormat="MM-DD-YY"
                                      inputProps={{
                                        className:
                                          "w-150 form-control datetime-right",
                                      }}
                                      displayTimeZone={this.userTimeZone}
                                      timeFormat={this.userTimeFormat ? this.userTimeFormat : true }
                                      onChange={(e) =>
                                        this.handleChange(e, info)
                                      }
                                      value={moment(info.time)}
                                    />
                                    <div className="input-icon">
                                      <IconCalendar />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              {info.lock &&
                                this.calculateAmount(
                                  info.amount,
                                  info.additionalPrice
                                )}
                              {!info.lock && (
                                <Fragment>
                                  <NewEdit
                                    key={key+info._id+info.amount}
                                    update={this.update}
                                    form={info._id}
                                    initialValues={{
                                      amount: info.amount,
                                      id: info._id,
                                      reference_number: info.reference_number,
                                      status: info.status,
                                    }}
                                    label={this.renderStatus(
                                      info.status,
                                      info.reference_number
                                    )}
                                  ></NewEdit>
                                  {info.additionalPrice &&
                                    info.additionalPrice.length > 0 &&
                                    Array.isArray(info.additionalPrice) &&
                                    info.additionalPrice &&
                                    info.additionalPrice.map((price, k) => {
                                      if (price) {
                                        
                                        return (
                                          <Fragment>
                                          <NewEdit
                                            key={k+key+info._id+info.amount}
                                            update={this.update}
                                            form={price.name}
                                            // closeEditOptions={this.closeEditOptions}
                                            initialValues={{
                                              amount: price.amount,
                                              id: info._id,
                                              originalId: price._id,
                                            }}
                                            label={price.name}
                                            orginalPricing={
                                              info.additionalPrice
                                            }
                                          ></NewEdit>
                                          </Fragment>
                                        );
                                      }
                                    })}
                                </Fragment>
                              )}
                            </td>
                        
                            <td>
                              {(info.driverPayRateId || info.dynamicDriverPayId || info.accessorialPayId || info.isManual) &&
                                <div>
                                  <span data-tip data-for={`pay_rate${key}`} onClick={() => this.redirectToRates(info)}>
                                    <IconNoteArrowRight />
                                  </span>
                                  <ReactTooltip id={`pay_rate${key}`}>
                                    <span>
                                      {info.driverPayRateId ? "Driver Pay Rate"
                                      : ( info.dynamicDriverPayId ? "Dynamic Driver Pay"
                                      : ( info.accessorialPayId ? "Accessorial Pay"
                                      : ( info.isManual ? "Manually Changed" : "")))}
                                    </span>
                                  </ReactTooltip>
                                </div>
                              }
                            </td>

                            <td>
                              {info.isDraft &&
                                <span class="pointer d-block text-center badge badge-soft-primary">
                                  DRAFT
                                </span>
                              }
                              {!info.isDraft && (info.lock && info.approved === true ? (
                                <span className="pointer badge badge-purple-50 py-1 ">
                                  Completed
                                </span>
                              ) : info.approved === true ? (
                                <Fragment>
                                  <span
                                    className={`pointer d-block text-center badge ${
                                      isHovering ? "badge-danger" : "badge-accent-100"
                                    }`}
                                    onMouseEnter={() =>
                                      this.setState({
                                        isStatusHovered: info._id,
                                      })
                                    }
                                    onMouseLeave={() =>
                                      this.setState({
                                        isStatusHovered: null,
                                      })
                                    }
                                    onClick={() =>
                                      this.approveDeduction({
                                        _id: info._id,
                                        approved: info.approved ? false : true,
                                      })
                                    }
                                  >
                                    {isHovering ? (
                                      <span>UnApprove</span>
                                    ) : (
                                      <span>Approved</span>
                                    )}
                                  </span>
                                </Fragment>
                              ) : (
                                <span
                                  className={`pointer d-block text-center badge ${
                                    isHovering ? "badge-success" : "badge-danger-light"
                                  }`}
                                  onMouseEnter={() =>
                                    this.setState({
                                      isStatusHovered: info._id,
                                    })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({
                                      isStatusHovered: null,
                                    })
                                  }
                                  onClick={() =>
                                    this.approveDeduction({
                                      _id: info._id,
                                      approved: info.approved ? false : true,
                                    })
                                  }
                                >
                                  {isHovering ? (
                                    <span>Approve</span>
                                  ) : (
                                    <span>Unapproved</span>
                                  )}
                                </span>
                              ))}
                            </td>

                            <td>
                              {!info.lock && (
                                <Fragment>
                                  <button
                                    onClick={() =>
                                      this.deleteNewDriverPay({
                                        _id: info._id,
                                        status: info.status,
                                        reference_number: info.reference_number,
                                        type: "driverpay",
                                      })
                                    }
                                    style={{
                                      pointerEvents: info.lock ? "none" : "",
                                    }}
                                    type="button"
                                    className="btn btn-link text-muted"
                                    data-tip
                                    data-for="delete"
                                  >
                                    <IconTrash />{" "}
                                  </button>

                                  <ReactTooltip id="delete" place="top">
                                    <span>Delete</span>
                                  </ReactTooltip>
                                </Fragment>
                                // </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="radius-wrapper radius-wrapper-big"></div>
              </div> */}
            </Fragment>
          )}
        </div>
        {this.state.showSidebar && (
          <CustomerServiceSideBar
            key={
              this.state.selectedLoad
                ? `loadInfo_${this.state.selectedLoad.reference_number}`
                : ""
            }
            closeCustomerServiceSideBar={() => {
              this.setState({
                showSidebar: false,
                activeCustomerServiceSidebarTab: "documents",
              });
            }}
            showSidebar={this.state.showSidebar}
            selectedLoad={this.state.selectedLoad}
            ContainerSize={this.state.ContainerSize}
            ContainerType={this.state.ContainerType}
            TMSContainerOwner={this.state.TMSContainerOwner}
            ChassisSize={this.state.ChassisSize}
            ChassisType={this.state.ChassisType}
            TMSChassisOwner={this.state.TMSChassisOwner}
            updateData={this.updateData}
            changeCurrentLoad={(load) => {
              this.setState({ selectedLoad: load });
            }}
            allLoads={this.state.TripList}
            activeTab={this.state.activeCustomerServiceSidebarTab}
            changeCustomerServiceSidebarTab={(
              activeCustomerServiceSidebarTab
            ) => this.setState({ activeCustomerServiceSidebarTab })}
            showNotes={"billing"}
          />
        )}
      </div>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (loggedInUser.role === "carrier") return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (loggedInUser.permissions.includes("customer_service_driver_pay"))
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    carrierProfile: bindActionCreators(actionCreatorsProfile, dispatch),
    dispatch: dispatch,
    tmsReducer: bindActionCreators(tmsReducer, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    // selectedLoads: state.TmsReducer.selectedLoads,
    userProfile: state.ProfileReducer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewTranscation));

const docType = [
  {label: "TIR OUT", value: "TIR OUT"},
  {label: "PROOF OF DELIVERY", value: "Proof of Delivery"},
  {label: "TIR IN", value: "TIR IN"}
]