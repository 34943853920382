//  This file contains form input with State Implementation
import React, { useEffect, useCallback } from "react";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Datetime from "react-datetime";
import { getStorage } from "../../services";
import { IconCalendar, IconTimes } from "../Common/Icons";
import { smallSelectStyle } from "../../assets/js/select-style";
import DateWrapper from "../../pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { debounce } from 'throttle-debounce';

const controlStylesHasError = (hasError) =>
  hasError ? { borderColor: "red" } : {};

// input type
const StateFormInput = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    placeholder,
    type,
    isRequired,
    className,
    disabled,
    onBlur
  } = props;


  useEffect(() => {
    document.getElementById(name).value = value ?? '';
  }, [name, value])

  let err = formErrors[name] && typeof formErrors[name] === "string" ? formErrors[name] : null
  let touched = formsTouched[name]

  // throttling user input
  const debounceFn = useCallback(debounce(1000, (name, value) => {
    onValChange(name, value);
  }), [name, value]);

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <label className="col-form-label">
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        className="form-control"
        formErrors={formErrors}
        formsTouched={formsTouched}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        onChange={(e) => debounceFn(name, e.target.value)}
        onBlur={(e) => onBlur && onBlur(name, e.target.value)}
      />
      {err && touched && (
        <small className="text-danger">{formErrors[name]}</small>
      )}
    </div>
  );
};
const AddressInput = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    placeholder,
    type,
    isRequired,
    className,
    disabled
  } = props;

  useEffect(() => {
    document.getElementById(name).value = value;
  }, [props])

  let err =
    formErrors[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];

  // throttling user input
  const debounceFn = useCallback(debounce(1000, (name, value) => {
    onValChange(name, value);
  }), [name, value]);

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <label>
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        // value={value}
        className="form-control"
        formErrors={formErrors}
        formsTouched={formsTouched}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        onChange={debounceFn}
      // onBlur={(e) => onValChange(e)}
      />
      {err && touched && (
        <small className="text-danger">{formErrors[name]}</small>
      )}
    </div>
  )
}

// React Select Input
const StateFormSelect = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    options,
    isMulti,
    isClearable,
    placeholder,
    className,
    isRequired,
  } = props;

  let err =
    formErrors[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label>
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <div>
          <Select
            name={name}
            options={options}
            value={value}
            placeholder={placeholder}
            // onBlur={(opt) =>onValChange(name, opt ? opt.value ? opt.value : '' : '')}
            onChange={(opt) =>
              onValChange(name, isMulti ? opt : opt ? opt.value : "")
            }
            // onBlur={(opt) =>onValChange(opt)}
            // onChange={(opt) => onValChange(opt)}}
            isMulti={isMulti}
            isClearable={isClearable}
            styles={smallSelectStyle}
          />
          {err && touched && (
            <small className="text-danger">{formErrors[name]}</small>
          )}
        </div>
      </div>
    </div>
  );
};

const dateInputProps = {
  onKeyDown: (e) => e.preventDefault(),
  placeholder: "Select Date",
};
// Date Time Input
const StateFormDateTime = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    timeFormat = false,
    isRequired,
    className,
    isDate,
    handleRemoveDate,
    inputClassName
  } = props;

  let err =
    formErrors[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;
  let touched = formsTouched[name];
  let timeZone = getStorage("timeZone");

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <label className="col-form-label">
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <div className="input-wrapper">
        <DateWrapper 
          placeholder = "Select Date"
          displayTimeZone = {timeZone}
          onChange = {(e) => onValChange(name, e)}
          defaultDate = {value}
          isShowTime = {false}
          hideShowSelectDate={true}
          className = 'form-control transition-white-field right'
        />
        {isDate && value ?
          <div
            className="input-icon"
            onClick={() => handleRemoveDate()}
          >
            <IconTimes />
          </div>
          :
          <div className="input-icon">
            <IconCalendar />
          </div>
        }
      </div>
      {err && touched && (
        <small className="text-danger">{formErrors[name]}</small>
      )}
    </div>
  );
};

// input type
const StateFormNumberFormat = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onValChange,
    type,
    mask = "_",
    format,
    isRequired,
    className,
    placeholder,
    onBlur
  } = props;

  let err = formErrors[name] && typeof formErrors[name] === "string" ? formErrors[name] : null
  let touched = formsTouched[name]

  // throttling user input
  const debounceFn = useCallback(debounce(1000, (name, value) => {
    onValChange(name, value);
  }), [name, value]);

  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label className="col-form-label">
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        <div>
          <NumberFormat
            name={name}
            format={format}
            mask={mask}
            className="form-control"
            value={value}
            placeholder={placeholder}
            type="text"
            onChange={(e) => debounceFn(name, e.target.value)}
            // onBlur={(e) => onValChange(name, e.target.value)}
            onBlur={(e) => onBlur && onBlur(name, e.target.value)}
          />
          {err && touched && (
            <small className="text-danger">{formErrors[name]}</small>
          )}
        </div>
      </div>
    </div>
  );
};


export {
  StateFormInput,
  StateFormSelect,
  StateFormDateTime,
  StateFormNumberFormat,
  AddressInput
};
