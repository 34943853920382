import React from "react";
import { useState } from "react";
import { 
  IconPhone, 
  IconUserSingle,
  IconMapMarkerOutline,
  IconAt,
  IconTimesThinClose,
  IconTimesThin,
  IconPlusSmall
} from "../../../../../Components/Common/Icons";
import ReactTooltip from "react-tooltip";
import { debounce } from "throttle-debounce";

const SideBar = ({
  selectCustomer,
  assignedCSR,
  isLoading,
  showAllCSRList,
  setShowAllCSRList,
  showAddRemoveOptions,
  filteredAllCSR,
  assignCSR,
  removeCSRfromCustomer,
  wrapperRef,
  listAllCSR
}) => {
  const [showRemoveIcon, setShowRemoveIcon] = useState(undefined);
  const [csrSearchTerm, setCsrSearchTerm] = useState("");
  return (
      <div className="col-md" style={{ maxWidth: 270, width: "20%" }}>
        <div className="card p-15 mb-1">
          <h5>About</h5>
          <div className="d-flex align-items-top justify-content-between mb-10">
            <span className="text-muted mr-10">
              <IconUserSingle />
            </span>
            <span> {selectCustomer && selectCustomer.main_contact_name}</span>
          </div>
          <div className="d-flex align-items-top justify-content-between mb-10">
            <span className="text-muted mr-10">
              <IconPhone />
            </span>
            <span> {selectCustomer && selectCustomer.mobile}</span>
          </div>
          <div className="d-flex align-items-top justify-content-between mb-10">
            <span className="text-muted mr-10">
              <IconAt />
            </span>
            <span className="text-break">
              {" "}
              {selectCustomer && selectCustomer.email}
            </span>
          </div>
          <div className="d-flex align-items-top justify-content-between mb-10">
            <span className="text-muted mr-10">
              <IconMapMarkerOutline />
            </span>
            {typeof selectCustomer?.address === 'object' ? 
              <span> {selectCustomer?.address?.address}</span>
            :
              <span> {selectCustomer?.address}</span>
            }
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Customer Reps </span>&nbsp;
            <div className="group d-flex align-items-center mt-1">
              {assignedCSR &&
                assignedCSR.length > 0 &&
                assignedCSR.slice(0, 3).map((csr, index) => {
                  return (
                    <React.Fragment>
                      <div className="group__avatar  position-relative">
                        <div
                          className={`avatar-circle avatar-sm ${
                            csr.allInfos &&
                            !csr.allInfos.profilePicture &&
                            "border-1 text-primary"
                          }`}
                          data-tip
                          data-for={csr.value}
                          onMouseEnter={() => setShowRemoveIcon(index)}
                          onMouseLeave={() => setShowRemoveIcon(undefined)}
                        >
                          {csr.allInfos && csr.allInfos.profilePicture ? (
                            <img
                              className="avatar rounded-circle"
                              src={csr.allInfos.profilePicture}
                            />
                          ) : (
                            <div className="avatar-circle__initial">{`${
                              csr.label[0]
                            }${csr.label.split(" ")[1][0]}`}</div>
                          )}
                          {showRemoveIcon == index && showAddRemoveOptions && (
                            <div
                              className="notification-icon-header z-1 pointer"
                              onClick={() => removeCSRfromCustomer(csr)}
                              onMouseEnter={() => ReactTooltip.hide()}
                              style={{
                                top: "-7px",
                                color: "red",
                                backgroundColor: "#dbe2eb",
                              }}
                            >
                              <IconTimesThinClose />
                            </div>
                          )}
                        </div>
                      </div>
                      <ReactTooltip
                        place="bottom"
                        effect="solid"
                        id={csr.value}
                      >
                        <span key={index}>{csr.label}</span>
                      </ReactTooltip>
                    </React.Fragment>
                  );
                })}
              {assignedCSR && assignedCSR.length > 3 && (
                <div className="group__avatar z-3">
                  <div
                    className="avatar avatar-circle avatar-sm bg-light border-1 text-primary"
                    data-tip
                    data-for="more"
                    onMouseEnter={() => setShowRemoveIcon("more")}
                  >
                    <span className="text-dark">
                      {" "}
                      +{assignedCSR.length - 3}
                    </span>
                  </div>

                  {showRemoveIcon == "more" && (
                    <div
                      className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated z-3"
                      style={{ right: "0" }}
                      ref={wrapperRef}
                    >
                      <div
                        className="clist"
                        style={{ maxHeight: "calc(350px - 191px)" }}
                        onMouseLeave={() => setShowRemoveIcon(undefined)}
                      >
                        {assignedCSR &&
                          assignedCSR.length > 3 &&
                          assignedCSR.slice(3).map((csr, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex align-items-center mb-10 pointer"
                              >
                                <div className="flex-shrink-0 mr-10 align-self-center">
                                  {csr &&
                                  csr.allInfos &&
                                  csr.allInfos.profilePicture ? (
                                    <img
                                      className="avatar rounded-circle "
                                      src={csr.allInfos.profilePicture}
                                    />
                                  ) : (
                                    <div className="avatar-circle undefined">
                                      {`${csr.allInfos.name[0]}${csr.allInfos.lastName[0]}`}
                                    </div>
                                  )}
                                </div>
                                <div className="flex-grow-1 text-truncate">
                                  <h5 className="font-12 font-weight-500 text-dark m-0">
                                    {csr.label}
                                  </h5>
                                </div>
                                {showAddRemoveOptions && (
                                  <div
                                    onClick={() =>
                                      removeCSRfromCustomer(csr)
                                    }
                                  >
                                    <IconTimesThin />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {showAddRemoveOptions && (
                <div className="ml-10 group__avatar">
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <div
                      className="avatar-circle avatar-sm bg-blue-500 pointer"
                      onMouseEnter={() => setShowRemoveIcon(undefined)}
                      onClick={() => {
                        setShowAllCSRList();
                        listAllCSR();
                      }}
                    >
                      <div className="avatar-circle__initial text-white font-10">
                        <IconPlusSmall />
                      </div>
                    </div>
                  )}

                  {showAllCSRList && (
                    <div
                      className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated popdrop--hcenter z-1"
                      ref={wrapperRef}
                    >
                      <div className="app-search position-relative mb-1">
                        <span className="search-icon ml-1"></span>
                        <div className="position-relative mb-10">
                          <input
                            type="input"
                            class="form-control input-search-left bg-gray-50"
                            placeholder="Search CSR.."
                            value={csrSearchTerm}
                            onChange={(e) => {
                              setCsrSearchTerm(e.target.value);
                              debounce(listAllCSR(e.target.value), 500);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="clist"
                        style={{ maxHeight: "calc(350px - 191px)" }}
                      >
                        {filteredAllCSR && filteredAllCSR.length > 0 ? (
                          filteredAllCSR.map((csr, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex align-items-center mb-10 pointer"
                                onClick={() => assignCSR(csr)}
                              >
                                <div className="flex-shrink-0 mr-10 align-self-center">
                                  {csr &&
                                  csr.allInfos &&
                                  csr.allInfos.allInfos &&
                                  csr.allInfos.allInfos.profilePicture ? (
                                    <img
                                      className="avatar rounded-circle "
                                      src={csr.allInfos.allInfos.profilePicture}
                                    />
                                  ) : (
                                    <div className="avatar-circle undefined">
                                      {`${csr.label[0]}${
                                        csr.label.split(" ")[1][0]
                                      }`}
                                    </div>
                                  )}
                                </div>
                                <div className="flex-grow-1 text-truncate">
                                  <h5 className="font-12 font-weight-500 text-dark m-0">
                                    {csr.label}
                                  </h5>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div> No CSR's Available</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default SideBar;
