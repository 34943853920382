import {
    getChassisPickCustomers,
    getChassisTerminationCustomers,
    getDroppedCustomers,
    getEmptyOriginCustomers,
    getTMSconsignee,
    getTMSshipper,
  } from "../Dispatcher/actionCreators";

import {
    IconDownloadAlt,
    IconFlag,
    IconLoadCartLeft,
    IconLoadCartRight,
    IconMapMarkerLoad,
    IconNoteArrowRight,
    IconReturn,
    IconUploadAlt,
} from "../../../Components/Common/Icons";

export const ROUTING_DRAG_LEGS = [
    { leg: 'CHASSISPICK', legTitle: 'Chassis Pick' },
    { leg: 'PULLCONTAINER', legTitle: 'Pull Container' },
    { leg: 'DELIVERLOAD', legTitle: 'Deliver Load' },
    { leg: 'RETURNCONTAINER', legTitle: 'Return Container' },
    { leg: 'DROPCONTAINER', legTitle: 'Drop Container' },
    { leg: 'LIFTOFF', legTitle: 'Lift Off' },
    { leg: 'CHASSISTERMINATION', legTitle: 'Chassis Termination' },
    { leg: 'COMPLETED', legTitle: 'Completed' },
];

let freeze = false; //mark delay
let timer; //saved timer

const getChassisPickCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisPickCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getTMSshipperAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSshipper(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getTMSconsigneeAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSconsignee(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getEmptyOriginCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getEmptyOriginCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getDroppedCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getDroppedCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getChassisTerminationCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisTerminationCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const ROUTING_ADDRESS_FIELDS = {
    CHASSISPICK: { optionsField: 'chassisTerms', loadOptions: getChassisPickCustomersAsyncSearch },
    PULLCONTAINER: { optionsField: 'shippersOptions', loadOptions: getTMSshipperAsyncSearch },
    DELIVERLOAD: { optionsField: 'consigneesOptions', loadOptions: getTMSconsigneeAsyncSearch },
    RETURNCONTAINER: { optionsField: 'customers', loadOptions: getEmptyOriginCustomersAsyncSearch },
    DROPCONTAINER: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
    HOOKCONTAINER: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
    LIFTOFF: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
    LIFTON: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
    CHASSISTERMINATION: { optionsField: 'chassisTerms', loadOptions: getChassisTerminationCustomersAsyncSearch }
};

export const ICONS = {
    CHASSISPICK: <IconLoadCartRight className="mr-10" />,
    PULLCONTAINER: <IconNoteArrowRight className="mr-10" />,
    DELIVERLOAD: <IconMapMarkerLoad className="mr-10" />,
    RETURNCONTAINER: <IconReturn className="mr-10" />,
    DROPCONTAINER: <IconDownloadAlt className="mr-10" />,
    LIFTOFF: <IconDownloadAlt className="mr-10" />,
    HOOKCONTAINER: <IconUploadAlt className="mr-10" />,
    LIFTON: <IconUploadAlt className="mr-10" />,
    CHASSISTERMINATION: <IconLoadCartLeft className="mr-10" />,
    COMPLETED: <IconFlag className="mr-10" />,
};

export const PRESET_ORDER_STATUS = [
  { label: "Pick and Run + Drop & Hook", value: "PULL_DELIVER_DROP_RETURN" },
  { label: "Pick and Run + Live Unload", value: "" },
  { label: "Prepull + Drop & Hook", value: "PULL_DROP_DELIVER_DROP_RETURN" },
  { label: "Prepull + Live Unload", value: "PULL_DROP_DELIVER_RETURN" },
  { label: "One Way Move", value: "PULL_RETURN" },
  { label: "Pick and Run + Gray Pool", value: "PICK_RUN_GRAY_POOL" },
  { label: "Prepull + Gray Pool", value: "PREPULL_GRAY_POOL" },
];
