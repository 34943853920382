import React, { Component } from "react";
import moment from "moment";
import { getYear } from "../../../../services/Common.services";
import {
  StateFormDateTime,
  StateFormInput,
  StateFormSelect,
} from "../../../../Components/FormComponent/StateFormComponent";


const yearOptions = getYear().map((year) => {
  return { value: year, label: year }
});
class TabChassis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  _findValue(key, value, label) {
    let data = this.props.chassisDetail[key].find((d) => d._id === value);

    if (data) return { value: data._id, label: data[label] || data.name };
    return;
  }
  render() {
    let Terminal = [];
    if (this.props.chassisDetail.terminals && this.props.chassisDetail.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.props.chassisDetail.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }
    return (
      <div className="tab" id="tab-chassis" ref="inner">
        <div className="card pt-30 px-20 mb-10">
          <div className="font-14 font-medium mb-15">Equipment</div>
          <div className="form-row">
            <StateFormSelect
              formErrors={this.props.chassisDetail.formErrors}
              formsTouched={this.props.chassisDetail.formsTouched}
              className="col-lg-3"
              label="Chassis Owner"
              isRequired={true}
              name="Chassis_Owner"
              options={this.props.chassisDetail.TMSChassisOwner.map((co) => ({
                value: co._id,
                label: co.company_name,
              }))}
              value={
                this.props.chassisDetail.Chassis_Owner &&
                this._findValue(
                  "TMSChassisOwner",
                  this.props.chassisDetail.Chassis_Owner,
                  "company_name"
                )
              }
              placeholder="Select Owner"
              onValChange={(name, val) => this.props.setValChange(name, val)}
              isClearable={true}
              isMulti={false}
            />
            <StateFormInput
              className="col-lg-3"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="Chassis #"
              name="chassisNo"
              isRequired={true}
              value={this.props.chassisDetail.chassisNo}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter Chassis Number.."
              type="text"
            />
            <StateFormSelect
              formErrors={this.props.chassisDetail.formErrors}
              formsTouched={this.props.chassisDetail.formsTouched}
              className="col-lg-3"
              label="Chassis Type"
              isRequired={true}
              name="Chassis_Type"
              options={this.props.chassisDetail.ChassisType.map((ct) => ({
                value: ct._id,
                label: ct.name,
              }))}
              value={
                this.props.chassisDetail.Chassis_Type &&
                this._findValue("ChassisType", this.props.chassisDetail.Chassis_Type)
              }
              placeholder="Select Type"
              onValChange={(name, val) => this.props.setValChange(name, val)}
              isClearable={true}
              isMulti={false}
            />
            <StateFormSelect
              formErrors={this.props.chassisDetail.formErrors}
              formsTouched={this.props.chassisDetail.formsTouched}
              className="col-lg-3"
              label="Chassis Size"
              isRequired={true}
              name="Chassis_Size"
              options={this.props.chassisDetail.ChassisSize.map((cs) => ({
                value: cs._id,
                label: cs.name,
              }))}
              value={
                this.props.chassisDetail.Chassis_Size &&
                this._findValue("ChassisSize", this.props.chassisDetail.Chassis_Size)
              }
              placeholder="Select Size"
              onValChange={(name, val) => this.props.setValChange(name, val)}
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="form-row">
            <StateFormSelect
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="Year"
              options={yearOptions}
              name="year"
              value={
                this.props.chassisDetail.year &&
                yearOptions.find((el) => el.label === this.props.chassisDetail.year)
              }
              // value={this.props.chassisDetail.year}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Select Year"
              type="number"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="Model"
              name="model"
              value={this.props.chassisDetail.model}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter Model"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={true}
              label="License State"
              name="licenceState"
              value={
                this.props.chassisDetail.licenceState
                  ? this.props.chassisDetail.licenceState
                  : ""
              }
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter License State"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={true}
              label="License Number"
              name="licenceNumber"
              value={
                this.props.chassisDetail.licenceNumber
                  ? this.props.chassisDetail.licenceNumber
                  : ""
              }
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter License Number"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="VIN"
              name="vin"
              value={this.props.chassisDetail.vin}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter VIN"
              type="text"
            />
            <StateFormInput
              className="col-lg-2"
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              isRequired={false}
              label="GPS #"
              name="gps"
              value={this.props.chassisDetail.gps}
              onValChange={(name, value) => this.props.setVal(name, value)}
              placeholder="Enter GPS"
              type="text"
            />
          </div>
          <div className="hr-light mt-10 mb-20"></div>
          <div className="font-14 font-medium mb-15">Expiration Dates</div>
          <div className="form-row">
            <StateFormDateTime
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="Inspection"
              name="inspection"
              value={
                this.props.chassisDetail.inspection
                  ? moment(this.props.chassisDetail.inspection)
                  : ""
              }
              onValChange={this.props.setValChange}
              isRequired={false}
              className="col-lg-3"
            />
            <StateFormDateTime
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="Insurance"
              name="insurance"
              value={
                this.props.chassisDetail.insurance
                  ? moment(this.props.chassisDetail.insurance)
                  : ""
              }
              onValChange={this.props.setValChange}
              isRequired={false}
              className="col-lg-3"
            />
            <StateFormDateTime
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="Registration"
              name="registration"
              value={
                this.props.chassisDetail.registration &&
                this.props.chassisDetail.registration.length === 7
                  ? moment(this.props.chassisDetail.registration, "MM/YYYY")
                      .endOf("month")
                      .format("MM/DD/YYYY")
                  : this.props.chassisDetail.registration &&
                    this.props.chassisDetail.registration.length > 7
                  ? moment(this.props.chassisDetail.registration).format("MM/DD/YYYY")
                  : ""
              }
              onValChange={this.props.setValChange}
              isRequired={false}
              className="col-lg-3"
            />
            <StateFormDateTime
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="Preventative Maintenance"
              name="preventativeMaintance"
              value={
                this.props.chassisDetail.preventativeMaintance
                  ? moment(this.props.chassisDetail.preventativeMaintance)
                  : ""
              }
              onValChange={this.props.setValChange}
              isRequired={false}
              className="col-lg-3"
            />

            <StateFormDateTime
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="AID"
              name="AID"
              value={
                this.props.chassisDetail.AID ? moment(this.props.chassisDetail.AID) : ""
              }
              onValChange={this.props.setValChange}
              isRequired={false}
              className="col-lg-3"
            />
            <StateFormDateTime
              formsTouched={this.props.chassisDetail.formsTouched}
              formErrors={this.props.chassisDetail.formErrors}
              label="ITD"
              name="ITD"
              value={
                this.props.chassisDetail.ITD ? moment(this.props.chassisDetail.ITD) : ""
              }
              onValChange={this.props.setValChange}
              isRequired={false}
              className="col-lg-3"
            />

            {this.props.chassisDetail.isTerminal && (
              <StateFormSelect
                formErrors={this.props.chassisDetail.formErrors}
                formsTouched={this.props.chassisDetail.formsTouched}
                className="col-lg-3"
                label="Terminal"
                isRequired={true}
                name="newTerminal"
                options={Terminal}
                value={this.props.chassisDetail.newTerminal}
                placeholder="Select Terminal"
                onValChange={(name, val) => this.props.setValChange(name, val)}
                isClearable={true}
                isMulti={true}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TabChassis;
