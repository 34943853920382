import React, { Component, Fragment } from "react";
import { updateNewTranscation, toastr } from "../../../../services/Common.services";

class NewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      amount: props.initialValues.amount,
    };

  }
   
  update = () => {
   
    if (
      !this.validate() &&
      this.props.initialValues.amount * 1 !== this.state.amount * 1
    ) {
      let data = {...this.props.initialValues};
      data.amount= this.state.amount;
      let additionalPrice = [];
      if (
        this.props.label != "Pulled Container" &&
        this.props.label != "Return Container" &&
        this.props.label != "Delivered" &&
        this.props.label != "Get Container" &&
        this.props.orginalPricing &&
        this.props.orginalPricing.length > 0
      ) {
        this.props.orginalPricing.map((dat) => {
          if (dat) {
            if (dat.name == this.props.label) {
              additionalPrice.push({
                name: this.props.label,
                amount: Number(data.amount),
              });
            } else {
              additionalPrice.push({
                name: dat.name,
                amount: dat.amount,
              });
            }
          }
        });
        delete data.amount;
      }
      if (additionalPrice.length > 0) {
        data.additionalPrice = JSON.stringify(additionalPrice);
      }
      if (this.props.isFromSettlement) {
        data.isFromSettlement = true;
      } else {
        data.isFromSettlement = false;
      }
      if (this.props.from) {
        data.from = this.props.from;
      }

      data.isManual = true;
      this.setState({ submitting: true }, () => {
        updateNewTranscation(data)
          .then((response) => {
            toastr.show("Updated!", "success");

            this.props.update(response.data.data, this.props.settlementNo);
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      });
    }
    
  };

  validate = () => {
    var error = null;
    if (this.state.amount && !/^\d+(\.\d{1,2})?$/.test(this.state.amount)) {
      error = "Invalid amount";
    }
    return error;
  };

  keyPressOnNumberInput = (evt) => {
    var invalidChars = ["+", "e"];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };

  render() {
    return (
      <Fragment>
        <div className="form-group mb-0">
          <span className="text-danger"> {this.validate()}</span>
          <input
            className="form-control"
            type="input"
            style={{backgroundColor: `${this.state.amount === 0 && "#F1F4F8"}`}}
            value={this.state.amount === 0 ? '-' : this.state.amount}
            onChange={(e) => {
              if(e.target.value < 0) {
                e.target.value = e.target.value * -1;
              }
              this.setState({ amount: e.target.value })
            }}
            onBlur={() => this.update()}
            onKeyDown={(e) => { this.keyPressOnNumberInput(e); }}
          />
        </div>
      </Fragment>
    );
  }
}

export default NewEdit;
