import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { differnceFinder, getStorage } from '../../../../../../services';
import moment from "moment";
import * as actionCreateors from "../../../actionCreators";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const AuditCustomerComponent = (props) => {
    const [audits, setAudits] = useState([]);

    useEffect(() => {
        const selectedCustomer = props.Selected_customer || {};
        props.actions.getTMSCustomerAudit(selectedCustomer._id)
            .then((res) => {
                const auditsData = res || [];
                const newAudit = [];
                if (auditsData && auditsData.length) {
                    for (let i = 0; i < auditsData.length; i++) {
                        if (i === 0) {
                            newAudit.push(auditsData[i]);
                        } else {
                            const diff = difference(
                                auditsData[i - 1].data,
                                auditsData[i].data
                            );
                            auditsData[i].data = diff;
                        }
                    }
                }
                setAudits(auditsData)
                //   self.setState({ audits });
            });
    }, [])

    const difference = (origObj, newObj) => {
        function changes(newObj, origObj) {
            let arrayIndexCounter = 0;
            return _.transform(newObj, function (result, value, key) {
                if (!_.isEqual(value, origObj[key])) {
                    let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
                    result[resultKey] =
                        _.isObject(value) && _.isObject(origObj[key])
                            ? changes(value, origObj[key])
                            : value;
                }
            });
        }
        return changes(newObj, origObj);
    }
    return (
        <div>
            {/* {this.props.tab == 5 && ( */}
            <div className="table-responsive">
                <table className="table table-card table-card--wrap">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Type</th>
                            <th>Time</th>

                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audits &&
                            audits.map((d, index) => {
                                let description;
                                let url;
                                let company_name;

                                if (
                                    ["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)
                                ) {
                                    url = (
                                        <a
                                            className="badge badge-info"
                                            href={d.data.document && d.data.document.url}
                                            target="_blank"
                                        >
                                            {d.data.document && d.data.document.url}
                                        </a>
                                    );
                                    description = !_.isNil(d.data.document && d.data.document.url) && (
                                        <p>
                                            {d.data.document.type} changed to{" "}
                                            <b className="badge badge-info">{url}</b>
                                        </p>
                                    );
                                }
                                if (d.type === "ADD") {
                                    description = "Company Name ";
                                    company_name = (
                                        <b className="badge badge-soft-warning">
                                            {d.data.company_name}
                                        </b>
                                    );
                                }
                                if (d.type === "UPDATE") {
                                    let data;
                                    if (
                                        index !== audits.length - 1 &&
                                        audits[index + 1].userId
                                    ) {
                                        data = audits[index + 1].data;
                                    }
                                    const additionalInfo = d.data;
                                    let dateFormat = ["officeHoursEnd"];
                                    let differanceDescription = differnceFinder(
                                        data,
                                        additionalInfo
                                    );

                                    description = Object.keys(differanceDescription || [])
                                        .filter(
                                            (key1) =>
                                                differanceDescription[key1] &&
                                                typeof differanceDescription[key1] !== "object"
                                        )
                                        .map((key1) => {
                                            if (dateFormat.includes(key1)) {
                                                return (
                                                    <p>
                                                        {key1} changed to{" "}
                                                        <b className="badge badge-soft-warning">
                                                            {moment(differanceDescription[key1]).format(
                                                                "ddd MMM DD YYYY"
                                                            )}
                                                        </b>
                                                    </p>
                                                );
                                            } else {
                                                return (
                                                    !_.isNil(differanceDescription[key1]) && (
                                                        <p>
                                                            {key1} changed to{" "}
                                                            <b className="badge badge-soft-warning">
                                                                {differanceDescription[key1].toString()}
                                                            </b>
                                                        </p>
                                                    )
                                                );
                                            }
                                        });
                                }
                                if (['ADDED_CSR', 'REMOVED_CSR'].includes(d.type)) {

                                    let csrName = ""
                                    if (d.data) {
                                        csrName = d.data.name ? csrName + d.data.name + " " : "";
                                        csrName = d.data.lastName ? csrName + d.data.lastName : ""
                                    }
                                    const desc = d.type === "ADDED_CSR" ? "New CSR Assigned " : "CSR Removed "
                                    description = <p>{desc}   <b className={`badge  ${d.type === "ADDED_CSR" ? "badge-soft-green" : "badge-soft-brown"}`}>{csrName}</b></p>
                                }
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="avatar-circle avatar-xs mr-1">
                                                    {" "}
                                                    {d.userId && d.userId.name.charAt(0)}
                                                </span>
                                                {d.userId && d.userId.name}
                                            </div>
                                        </td>
                                        <td>
                                            <span className="badge badge-gray-100 badge-sm text-capitalize">
                                                {d.type.replaceAll(/_/g, " ").toLowerCase()}
                                            </span>
                                        </td>

                                        <td>
                                            <div>
                                                {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("MM/DD/YY")}
                                            </div>
                                            <div className="text-muted">
                                                {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("LT")}
                                            </div>
                                        </td>

                                        <td>
                                            {description}
                                            {company_name}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            {/* )} */}
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actionCreateors, dispatch),
    };
  }

export default connect(null,mapDispatchToProps)(AuditCustomerComponent)