import $ from "jquery";
import * as pdfjsLib from "pdfjs-dist";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import "react-pdf-reader/dist/TextLayerBuilder.css";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { bindActionCreators } from "redux";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import confirm from "../../../Components/Common/ConfirmAert";
import _ from "lodash";
import {
  IconCheck,
  IconTimes,
  IconTrash,
  IconUpload,
} from "../../../Components/Common/Icons";
import configuration from "../../../config";
import {
  addCheck,
  getStorage,
  makeOptionsForSelect,
  sendDocumentsInEmail,
  toastr,
} from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";
import { TMS_GET_USER_LOAD } from "../constant";
import { getCustomers } from "../Dispatcher/actionCreators";
import {
  deleteDocument,
  getDocumentType,
  rotatePdf,
  updateDocument,
} from "../services";
import * as actionCreator from "./actionCreator";
import UploadedDocuments from "./DocumentList";
import FileViewer from "react-file-viewer";
import FullScreenUploader from "../../../Components/FullScreenUploader";

const firebase = configuration.firebase;

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAllImagingDocuments:
        props.selectedLoads && props.selectedLoads.documents
          ? props.selectedLoads.documents
          : [],
      docUploading: false,
      documents: [],
      processing: false,
      uploadedFileName: "",
      degree: 0,
      height: "0px",
      selectedDoc: null,
      selectedUrl: null,
      url: null,
      numPages: null,
      pageNumber: 1,
      activeTab: "mainCustomer",
      customerId: null,
      allCustomers: [],
      DOC_OPTIONS: [],
      showUploadModal: false,
      uploadFileDetails: [],
      progress: [],
      documentObj: [],
      loader: false,
      isSaving: true,
      previewUrl: [],
      editDoc: null,
      showFullDropScreen:false,
      isPopupOpen: false,
      isSend: false,
      isType:false,
      isDisabledDropOverListener: false,
      sendDocumentId: null,
    };
    this.previewImage = this.previewImage.bind(this);
    this.downloadDoc = this.downloadDoc.bind(this);
    this.uploadResult = this.uploadResult.bind(this);
    this.selectedDoc = null;
  }

  setSelectedDoc(docIndex) {
    this.setState({ selectedDoc: docIndex });
  }

  disabledDropOverListener(bool) {
    this.setState({ isDisabledDropOverListener: bool})
  }

  componentWillReceiveProps(props) {
    if (props.selectedLoads && props.selectedLoads.documents) {
      this.setState(
        { listAllImagingDocuments: props.selectedLoads.documents },
        () => {
          const documents = this.state.listAllImagingDocuments.filter(
            (doc) => doc.checked == true
          );
          this.setState({ documents });
        }
      );
    } else {
      this.setState({ listAllImagingDocuments: [] });
    }
    if (
      props.selectedLoads.reference_number !==
      this.props.selectedLoads.reference_number
    ) {
      this.setState({
        previewUrl: [],
        selectedDoc: null,
      });
    }
  }

  componentWillMount() {
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
    } else {
      this.componentWillReceiveProps(this.props);
    }
  }

  componentDidMount() {
    try {
      $(".reloadCurrentLoad").click();
    } catch (er) {}
    if (getStorage("loggedInUser") != null) {
      const currentUser = JSON.parse(getStorage("loggedInUser"));
      let carrierId = currentUser._id;
      if (currentUser.role === "driver") {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === "fleetmanager") {
        carrierId = currentUser.fleetManager.carrier;
      }
      let reference_number;
      if (this.props.selectedLoads) {
        reference_number = this.props.selectedLoads.reference_number;
      } else {
        reference_number = this.props.loads.reference_number;
      }
      this.userPresense = firebase
        .database()
        .ref(
          `${carrierId}/presense/${reference_number}/document/${currentUser._id}`
        );
      if (this.userPresense) {
        this.userPresense.set(true);
      }
    }

    getDocumentType().then((docs) => {
      this.setState({ DOC_OPTIONS: docs });
    });
    this.setState({showFullDropScreen:true})
  }
  componentWillUnmount() {
    if (this.userPresense) {
      this.userPresense.set(false);
    }
    this.setState({showFullDropScreen:false})
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () => {
    let pageNumber = this.state.pageNumber - 1;
    if (pageNumber == 0) {
      return;
    } else {
      this.setState({ pageNumber });
    }
  };

  goToNextPage = () => {
    let pageNumber = this.state.pageNumber + 1;
    if (pageNumber > this.state.numPages) {
      return;
    } else {
      this.setState({ pageNumber });
    }
  };

  previewImage = (info) => {
    this.setState({ pdfPreviewUrl: info.url }, () => {
      var options = { scale: 1 };
      let canvasContainer = document.getElementById("holder");
      $("#holder").html("");

      function renderPage(page) {
        var viewport = page.getViewport(options.scale);
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvasContainer.appendChild(canvas);
        page.render(renderContext);
      }

      function renderPages(pdfDoc) {
        for (var num = 1; num <= pdfDoc.numPages; num++)
          pdfDoc.getPage(num).then(renderPage);
      }
      pdfjsLib.disableWorker = true;
      pdfjsLib.getDocument(info.url).then(renderPages);
    });
  };

  uploadResult(file, preview, type) {
    file.progress = 0;
    let dummydata = [];
    const fileType = file.type.toLowerCase();
    const temp =
      this.state.uploadFileDetails &&
      this.state.uploadFileDetails.map((d) => d.name);
    let dummySize = this.state.uploadFileDetails && this.state.uploadFileDetails.filter((d) => d.size === file.size);
    dummydata = temp;
    dummySize = dummySize.map((d) => d.size)
    if (
      (fileType.match("image/png") || fileType.match("image/jpg") || fileType.match("image/jpeg") ||
        fileType.match("application.pdf") ||
        fileType.match("message/rfc822") ||
        fileType.match("text/csv") ||
        fileType.match("application/vnd.ms-excel") ||
        fileType.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) &&
      (!dummydata.includes(file.name) || !dummySize.includes(file.size))
    ) {
      if (fileType.indexOf("image") === -1) {
        type = fileType.split("/")[1];
      }
      let previewUrl = this.state.previewUrl
        ? [...this.state.previewUrl]
        : [];
      previewUrl.push({ pre: preview, name: file.name, size: file.size });
      this.setState({
        documentFile: file,
        uploadedFileName: file.name,
        previewUrl,
        [type]: preview,
        selectedDoc: null,
        uploadFileDetails: [...this.state.uploadFileDetails, file],
      }, () => {
        this.uploadFileData();
      });
    } else {
      if (dummydata.includes(file.name) && dummySize.includes(file.size)) {
        toastr.show("This file has already been uploaded.", "error");
        this.setState({isSaving: false, docUploading: false});
        return;
      } else {
        toastr.show("Only Pdf, CSV, XLS, XLSX and Image files are allowed.", "error");
        this.setState({isSaving: false, docUploading: true});
        return;
      }
    }
  }

  updateInput(event) {
    this.setState({ imageType: event.target.value });
  }

  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  uploadFileData = () => {
    let data = new FormData();
    let loadDetail = this.props.selectedLoads;
    let apiUrl = `${configuration.docUrl}driver/uploadDoctoAWS`;
    if (!this.state.documentFile) {
      toastr.show("Please select a document.", "error");
      return false;
    }
    if (this.state.documentFile) {
      const docFileName = this.state.documentFile.name.toLowerCase();
      if (
        docFileName.split(".").pop() !== "png" &&
        docFileName.split(".").pop() !== "jpg" &&
        docFileName.split(".").pop() !== "gif" &&
        docFileName.split(".").pop() !== "pdf" &&
        docFileName.split(".").pop() !== "jpeg" &&
        docFileName.split(".").pop() !== "csv" &&
        docFileName.split(".").pop() !== "xls" &&
        docFileName.split(".").pop() !== "eml" &&
        docFileName.split(".").pop() !== "xlsx"
      ) {
        toastr.show(
          "Only select pdf, png, jpg, csv, xls, xlsx and jpeg file",
          "error"
        );
        return this.removeDocument();
      }
    }
    if (this.state.documentFile.type.indexOf("image") > -1) {
      apiUrl = `${configuration.docUrl}driver/uploadProofOfDelivery`;
      data.append("proofOfDelivery", this.state.image);
      data.append("shipmentId", new Date().getTime().toString());
    } else {
      data.append("document", this.state.documentFile);
      data.append("filename", new Date().getTime().toString());
    }

    HTTP("put", null, data, { "Content-Type": `multipart/form-data` }, apiUrl)
      .then((result) => {
        if (result.status === 200) {
          const documentObjData = {
            reference_number: loadDetail.reference_number,
            type: "",
            url: result.data.data.url,
          };
          if (this.state.activeTab === "subCustomer") {
            documentObjData.customerId = "";
          }
          let documentObj = this.state.documentObj
            ? [...this.state.documentObj]
            : [];
          documentObj.push(documentObjData);

          const progressBar = this.state.uploadFileDetails.map((item) => {
            if (item.name === this.state.uploadedFileName) {
              item.progress = 100;
            }
            return item;
          });

          this.setState({
            documentObj,
            uploadFileDetails: progressBar,
            isSaving: false,
            docUploading: false,
            isType: true
          });
        } else {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({ docUploading: false });
        }
      })
      .catch((err) => {
        console.log("err :: ", err);
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ docUploading: false });
      });
  };

  uploadDocument = () => {
    // remove the docs from previous state list [main-customer || sub-customer]
    this.state.sendDocumentId && this.deleteDocument(this.state.sendDocumentId);
    let finalDocData = this.state.documentObj;
    for (let i = 0; i < finalDocData.length; i++) {
      if (finalDocData[i].type !== "") {
        if (this.state.activeTab === "subCustomer") {
          if (finalDocData[i].customerId === "") {
            toastr.show("Please select a customer!", "error");
            return;
          }
        }
        this.setState({ selectedDoc: null, processing: false });
        this.setState({ docUploading: true});
        this.setState({ loader: true, isSaving: true });
        HTTP("post", "tms/uploadDocumentForLoad", finalDocData[i], {
          authorization: getStorage("token"),
        }).then((response) => {
          if (response.status === 200) {
            // set default value to sendDocumentId
            this.setState({ sendDocumentId: null });

            if(this.state.activeTab === "subCustomer") {
              // set default value to sendDocumentId
              this.setState({ sendDocumentId: null });
              toastr.show(`${this.state.isSend ? 'Document has been successfully moved to main customer' : 'This document has been uploaded.'}`, "success");
            } else {
              toastr.show(`${this.state.isSend ? 'Document has been successfully moved to sub customer' : 'This document has been uploaded.'}`, "success");
            }
            if (this.props.updateList) {
              this.props.updateList(response.data.data);
            }
            this.setState(
              {
                docUploading: false,
                listAllImagingDocuments: response.data.data.documents,
                documentFile: null,
                customers: null,
                previewUrl: [],
                imageType: "",
                customerId: null,
                loader: false,
                isSaving: false,
                documentObj: null,
                uploadFileDetails: [],
                isPopupOpen: false,
                isSend: false
              },
              () => {
                this.props.actions.updateDocumentRotate(
                  response.data.data.documents
                );
              }
            );
            this.setState({ showUploadModal: false });
          }
        });
      } else {
        this.setState({
          selectedDoc: null,
          processing: false,
          isSaving: false,
          isPopupOpen: false
        });
        toastr.show("Please select a document type first.", "error");
        return;
      }
    }
  };

  downloadDoc() {
    $.ajax({
      url: this.state.selectedDoc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: function (blob) {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "new.pdf";
        link.click();
      },
    });
  }

  commonDeleteFunction = (id, shouldShow) => {
    deleteDocument({
      loadRef: this.props.selectedLoads.reference_number,
      _id: id,
    }).then((result) => {
      shouldShow && toastr.show("This document has been deleted.", "success");
      if (result.data && result.data.data && result.data.data.documents) {
        this.setState({ listAllImagingDocuments: result.data.data.documents });
      }
      if (this.props.updateList) {
        this.props.updateList(result.data.data);
      } else {
        this.props.actions.updateDocument(result.data.data);
      }
    });
  }

  deleteDocument = (id) => {
    this.state.sendDocumentId ? this.commonDeleteFunction(id, false) : confirm(
      "Remove Document?",
      "Are you sure you want to remove this document?",
      (confirm) => {
        if (confirm) {
          this.commonDeleteFunction(id, true)
        }
      }
    )
  };

  updateDocument = (id, type) => {
    this.setState({
      isSaving: true,
    });
    updateDocument({
      loadRef: this.props.selectedLoads.reference_number,
      _id: id,
      type,
    }).then((result) => {
      toastr.show("This document has been updated.", "success");
      this.setState({
        isSaving: false,
        editDoc: null,
      });
      if (result.data && result.data.data && result.data.data.documents) {
        this.setState({ listAllImagingDocuments: result.data.data.documents });
      }
      if (this.props.updateList) {
        this.props.updateList(result.data.data);
      } else {
        this.props.actions.updateDocument(result.data.data);
      }
    });
  };

  addCheck = (doc) => {
    addCheck({
      documentId: doc._id,
      reference_number: this.props.selectedLoads.reference_number,
      checked: !doc.checked,
    }).then((response) => {
      response.data.data.documents.forEach((reply) => {
        if (reply._id == doc._id) {
          if (reply.checked) {
            toastr.show("This document has been selected.", "success");
          } else {
            toastr.show("This document has been unselected.", "success");
          }
        }
      });
      if (this.props.updateList) {
        this.props.updateList(response.data.data);
      } else {
        this.props.dispatch({
          type: TMS_GET_USER_LOAD,
          reference_number: this.props.selectedLoads
            ? this.props.selectedLoads.reference_number
            : this.props.loads.reference_number,
          payload: {
            skip: 0,
            limit: 1,
          },
        });
      }
    });
  };

  selectDocument = (document) => {
    const documents = [...this.state.documents];
    documents.push(document);
    this.setState({ documents });
  };

  sendDocumentsInEmail = () => {
    if (!this.state.processing) {
      const emailObj = {
        documents: this.state.documents,
        email: "test@gmail.com",
        reference_number: this.props.selectedLoads.reference_number,
      };
      sendDocumentsInEmail(emailObj)
        .then((res) => {
          toastr.show(
            "Document has been successfully sent to the email",
            "success"
          );
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
    if (this.state.documents.length == 0) {
      toastr.show("Please choose the document you wish to send.", "warning");
    }
  };

  checkedOrNot = (info) => {
    if (info.checked) {
      return true;
    } else {
      return false;
    }
  };

  rotatePdf = (pdf) => {
    if (!this.state.processing) {
      this.setState({ processing: true }, () => {
        rotatePdf({
          url: pdf.url,
          _id: this.props.selectedLoads._id,
          degree: this.state.degree,
          documentId: pdf._id,
        }).then((res) => {
          let selectedUrl = Object.assign({}, this.state.selectedUrl);
          selectedUrl.url = res.data.data.url;
          let documents = JSON.parse(
            JSON.stringify(
              this.props.selectedLoads && this.props.selectedLoads.documents
                ? this.props.selectedLoads.documents
                : []
            )
          );
          documents = documents.map((doc) => {
            if (doc.url == selectedUrl.url) {
              doc.url = res.data.data.url;
            }
            return doc;
          });
          this.setState(
            {
              selectedUrl,
              listAllImagingDocuments: documents,
            },
            () => {
              this.setState({ processing: false });
              if (this.props.updateList) {
                this.props.updateList(res.data.data.rotatedDoc);
              } else {
                this.props.actions.updateDocument(res.data.data.rotatedDoc);
              }
              this.props.actions.updateDocumentRotate(documents);
            }
          );
        });
      });
    } else {
      toastr.show("Please wait while your document is rotated!", "success");
    }
  };

  handleShowUploadModal = (event) => {
    this.setState({
      showUploadModal: true,
      uploadFileDetails: [],
      progress: 0,
      isSaving: false,
      docUploading: true,
    });
  };

  handleDocumentType = (type, index) => {
    let data = this.state.documentObj.findIndex((obj, i) => i === index);
    let documentObj = this.state.documentObj;
    documentObj[data].type = type.value;
    let typeValue = documentObj.filter((d) => d.type === "")
    if (typeValue.length > 0) {
      this.setState({isType: true})
    } else {
      this.setState({isType: false})
    }
    this.setState({ documentObj: documentObj });
  };

  handleCustomerType = (custId, index) => {
    let data = this.state.documentObj.findIndex((obj, i) => i === index);
    let documentObj = this.state.documentObj;
    documentObj[data].customerId = custId.value;
    this.setState({ documentObj: documentObj });
  };

  uploadFile = (file, preview, type) => {
    this.setState({ docUploading: true });
    if (file.type.match("image.*") && file.size > 5242880) {
      toastr.show(
        "File too Big, please select a file less than 5mb",
        "warning"
      );
    } else {
      this.setState({ showUploadModal: true })
      this.uploadResult(file, preview, type);
    }
  };
  removeDocument = (index) => {
    let uploadFileDetails = this.state.uploadFileDetails;
    let documentObj = this.state.documentObj;
    let previewUrl = this.state.previewUrl;
    uploadFileDetails.splice(index, 1);
    documentObj.splice(index, 1);
    previewUrl.splice(index, 1);
    this.setState({
      uploadFileDetails,
      documentObj,
      documentFile: null,
      previewUrl,
    });
    if (this.state.uploadFileDetails.length === 0) {
      this.setState({ docUploading: true });
    }
  };

  handleDocType = (doc) => {
    this.setState({
      isSaving: false,
      editDoc: doc,
    });
  };

  updateDocType = () => {
    this.updateDocument(this.state.editDoc._id, this.state.editDoc.type);
  };

  handleSendToCustomer = (doc, id) => {
    let documentObj = [];
    let data = {
      url: doc.url,
      type: doc.type,
      reference_number: this.props.selectedLoads && this.props.selectedLoads.reference_number
    };

    // set the incoming doc id
    this.setState({ sendDocumentId: doc._id });

    if(this.state.activeTab !== "subCustomer") {
      data.customerId = id ? id : this.props.selectedLoads && this.props.selectedLoads.additionalPricing[0].customerId._id;
    }
    documentObj.push(data);
    this.setState({documentObj, isSend: true}, () => {
      this.uploadDocument();
    })
  }

  handlePopupOpen = (value) => {
    this.setState({isPopupOpen: value});
  }

  renderReturn = () => {
    this.listDocuments = this.state.listAllImagingDocuments.map((info, key) =>
      this.state.activeTab !== "subCustomer"
        ? !info.customerId
          ? info
          : null
        : info.customerId
          ? info
          : null
    );
    const { editDoc } = this.state;
    return (
      <React.Fragment>
        <div className="tab">
          <div className="card p-15 mb-10 d-flex flex-row align-items-center justify-content-between border-top">
            <ul className="nav nav-compact" role="tablist">
              <li className="nav-item">
                <a
                   className={`nav-link ${
                    this.state.activeTab === "mainCustomer" && "active"
                  }`}
                  data-bs-toggle="tab"
                  onClick={() =>
                    this.setState({
                      activeTab: "mainCustomer",
                    })
                  }
                >
                  Main Customer
                </a>
              </li>
              {getStorage("currentUserRole") !== "customer" && (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.activeTab === "subCustomer" && "active"
                    }`}
                    role="tab"
                    onClick={() => {
                      this.setState({ selectedDoc: null });
                      this.setState({
                        activeTab: "subCustomer",
                      });
                    }}
                  >
                    Sub Customer
                  </a>
                </li>
              )}
            </ul>
            {/* {!this.props.type && getStorage("currentUserRole") !== "customer" && (
            <button
              className="ml-auto btn btn-primary"
              onClick={this.handleShowUploadModal}
            >
              <IconUpload className="mr-2" />
              Upload Document
            </button>
          )} */}
          </div>
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 265px)', overflowX: 'hidden' }}>
            <UploadedDocuments
              docTab="load"
              processing={this.state.processing}
              listDocuments={this.listDocuments}
              addCheck={this.addCheck}
              rotatePdf={this.rotatePdf}
              rotateDoc={true}
              deleteDocument={this.deleteDocument}
              onDocumentLoadSuccess={this.onDocumentLoadSuccess}
              selectedDoc={this.state.selectedDoc}
              setSelectedDoc={(docIndex) => {
                this.setState({ selectedDoc: docIndex });
              }}
              handleShowUploadModal={this.handleShowUploadModal}
              editDocType={this.handleDocType}
              uploadResult={this.uploadFile}
              multi={false}
              type={"image"}
              selectedLoads={this.props.selectedLoads}
              sendToCustomer={this.handleSendToCustomer}
              isPopupOpen={this.state.isPopupOpen}
              handlePopupOpen={this.handlePopupOpen}
              activeTab={this.state.activeTab}
              disabledDropOverListener={this.disabledDropOverListener.bind(this)}
              isDisabledDropOverListener={this.state.isDisabledDropOverListener}
            />
          </div>
        </div>

        <Modal className="backdrop_modal_open" show={this.state.showUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {!this.props.type && getStorage("currentUserRole") != "customer" && (
                <div>
                  <ImageUploader
                    uploadResult={this.uploadFile}
                    multi={false}
                    name="Choose from your device..."
                    type={"image"}
                    className="custom-file-input "
                  />
                  {this.state.uploadFileDetails &&
                    this.state.uploadFileDetails.map((fileData, index) => {
                      return (
                        <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                          <IconUpload className="mr-20" />
                          <div className="w-100">
                            <div className="font-medium d-flex justify-content-between w-100 mb-1">
                              <div>{fileData.name}</div>
                              <div className="ml-auto">
                                {fileData.progress}%
                              </div>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                aria-valuenow={`${fileData.progress}`}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: `${fileData.progress}%` }}
                              ></div>
                            </div>
                          </div>
                          <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                            <span onClick={() => this.removeDocument(index)}>
                              {" "}
                              <IconTimes className="" />
                            </span>
                          </button>
                        </div>
                      );
                    })}
                </div>
              )}
              {this.state.uploadFileDetails &&
                this.state.uploadFileDetails.map((fileData, index) => {
                  const doc = this.state.documentObj ? this.state.documentObj[index] : [];
                  return (
                    <div className="dropzone-fileupload d-flex">
                      <button className="btn btn-success btn-circle btn-xs mr-15">
                        <IconCheck />
                      </button>
                      <div className="document-preview document-preview--sm mr-3">
                        <div className="document-preview__image">
                          {this.state.previewUrl &&
                            this.state.previewUrl.map((d) => {
                              if (fileData.name === d.name) {
                                if((d.name.split(".").pop() === "png" || d.name.split(".").pop() === "jpg" || d.name.split(".").pop() === "jpeg") && d.size === fileData.size) {
                                  return (
                                    <img
                                      className="document-preview__image"
                                      src={d.pre}
                                      alt={""}
                                    />
                                  );
                                } else if(d.name.split(".").pop() === "pdf") {
                                  return (
                                    <div id="holder">
                                      <Document
                                        file={d.pre}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                      >
                                        <Page pageNumber={1} />
                                      </Document>
                                    </div>
                                  );
                                } else if(d.name.split(".").pop() === "csv") {
                                  return (
                                    <img
                                      className="document-preview__image"
                                      src="/assets/img/csv.png"
                                      alt="csv.png"
                                    />
                                  );
                                } else if(d.name.split(".").pop() === "xls" || d.name.split(".").pop() === "xlsx") {
                                  return (
                                    <FileViewer
                                      fileType="xlsx"
                                      filePath={d.pre}
                                    />
                                  );
                                }
                              }
                            })}
                        </div>
                      </div>
                      <div className="w-100 d-flex">
                        <div className="mr-2">
                          <div className="font-medium text-break">
                            {fileData.name}
                          </div>
                          <div className="text-muted">
                            {(fileData.size * 0.0009765625).toFixed(1)} kb
                          </div>
                        </div>
                        {!this.props.type &&
                          getStorage("currentUserRole") != "customer" && (
                            <div className="w-150 ml-auto  mr-2">
                              <Select
                                className="w-100"
                                options={this.state.DOC_OPTIONS}
                                placeholder="Select Doc Type"
                                style={{ width: "150px", flex: "1 1 0%" }}
                                onChange={(imageType) =>
                                  this.handleDocumentType(imageType, index)
                                }
                                isDisabled={this.state.docUploading}
                                value={(doc && doc.type) ? {label: doc.type, value: doc.type} : null}
                              />
                            </div>
                          )}

                        {!this.props.type &&
                          getStorage("currentUserRole") != "customer" &&
                          this.state.activeTab === "subCustomer" && (
                            <div className="w-150 ml-auto">
                              <AsyncCreatableSelect
                                className="w-100"
                                cacheOptions
                                defaultOptions={makeOptionsForSelect(
                                  this.props.customers,
                                  "company_name",
                                  "address.address",
                                  "_id"
                                )}
                                Style={{ width: "150px", flex: "1 1 0%" }}
                                onChange={(customerId) =>
                                  this.handleCustomerType(customerId, index)
                                }
                                onInputChange={(customerId) => {
                                  customerId !== "" &&
                                    this.setState({ customerId: customerId });
                                }}
                                loadOptions={getCustomers}
                                isDisabled={this.state.docUploading}
                              />
                            </div>
                          )}
                      </div>
                      <button className="btn btn-link btn-circle btn-xs ml-3">
                        <span onClick={() => this.removeDocument(index)}>
                          {" "}
                          <IconTrash />{" "}
                        </span>
                      </button>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() =>
                this.setState({
                  showUploadModal: false,
                  previewUrl: null,
                  documentObj: null,
                  uploadFileDetails: []
                })
              }
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isSaving || this.state.docUploading || this.state.isType}
              onClick={() => {
                this.uploadDocument();
              }}
            >
              {this.state.isSaving && (
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.editDoc !== null} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Edit Document Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              className="w-100"
              options={this.state.DOC_OPTIONS}
              placeholder="Select Doc Type"
              value={
                editDoc === null
                  ? null
                  : this.state.DOC_OPTIONS.find(
                    (d, i) => d.value === editDoc.type
                  )
              }
              style={{ width: "150px", flex: "1 1 0%" }}
              onChange={({ value }) => {
                var upObj = { ...editDoc, type: value };
                this.setState({
                  editDoc: upObj,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() =>
                this.setState({
                  editDoc: null,
                })
              }
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.isSaving}
              onClick={() => {
                this.updateDocType();
              }}
            >
              {this.state.isSaving && (
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        {!this.props.type && getStorage("currentUserRole") !== "customer" && this.state.showFullDropScreen && !this.state.showUploadModal && !this.state.isDisabledDropOverListener &&
          <FullScreenUploader
            uploadResult={this.uploadFile}
            multi={false}
            type={"image"}
          />
        }
      </React.Fragment>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_documents") ||
          (loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer &&
            loggedInUser.permissions.includes("customer_employee_load_documents"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}

const states = (state) => ({
  customers: state.TmsReducer.customers,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreator, dispatch),
    dispatch: dispatch,
  };
}

export default connect(states, mapDispatchToProps)(Documents);
