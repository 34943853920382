import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as actionCreators from "../Dispatcher/actionCreators";
import { convertTMSStatusName } from "../../../Components/Common/functions";
import {
  deepDiffMapper,
  differnceFinder,
  pricingDiffernceFinder,
  toastr,
  getStorage,
  auditKey,
  isMilitaryTime,
} from "../../../services/Common.services";

import $ from "jquery";
import { withRouter } from "react-router";
import { Fragment } from "react";
import _ from "lodash";
const auditType = {
  LOAD_ADD: "Load Added",
  LOAD_ADD_DUPLICATE: "Load Added via Duplication",
  LOAD_ADD_BULKUPLOAD: "Load Added via Bulk Upload",
  LOAD_ADD_QUOTE: "Load Added via Quote",
  CUTOFF_ADD: "Cutoff Added",
  LOAD_ADD_ARTIFICIAL: "LOAD ADDED VIA ARTIFICAL INTELLIGENCE",
  LOAD_EDIT: "Load Edited",
  REMOVE_FIELD: "Value Erased",
  ASSIGN_COMPANY: "Load Assigned",
  LOAD_ACCEPT: "Load Accepted",
  SYSTEM_CHANGE_STATUS: "System Order Status Created",
  LOAD_CHANGE_STATUS: "Load Status Change",
  LOAD_REMOVE: "Load Removed",
  LOAD_ENABLE: "Load Enable",
  PRICING_UPDATE: "Price Update",
  ADDITIONAL_PRICING_UPDATE: "Additional Price Update",
  CHASSIS_PRICE_CALCULATED: "Chassis Price Calculated",
  SYSTEM_PRICE_UPDATE: "System Price Update",
  ASSIGN_LOAD_DRIVER: "Driver Assigned to Load",
  ASSIGN_LOAD_CODRIVER: "Co Driver Assigned to Load",
  ASSIGN_LOAD_EQUIPMENT: "Equipment Assigned to Load",
  AUTO_ASSIGN_LOAD_DRIVER: "Load Driver Auto Assigned",
  CRON_ASSIGN_LOAD_DRIVER: "System Load Driver Auto Assigned",
  UNASSIGN_LOAD_DRIVER: "Load Driver Removed",
  AUTO_UNASSIGN_LOAD_DRIVER: "System Load Driver Removed",
  UNASSIGN_LOAD_EQUIPMENT: "Load Equipment Removed",
  HARD_REMOVE_DRIVER: "Manual Driver Removed",
  START_LOAD_DRIVER: "Driver Has Started Load",
  UPLOAD_DOCUMENT_LOAD: "Documents Uploaded",
  CRUX_CONTAINER_STATUS: "Container API Status",
  NAME_SIGNATURE_UPLOAD_LOAD: 'Load Signature Uploaded by "X"',
  UPDATE_LOAD_STATUSORDER: "Load Status Updated",
  BUILD_STATUS_ORDER: "Order Status Created",
  MOBILEAPP_LOAD_STATUS_UPDATE: "Mobile App Load Status Update",
  MOBILEAPP_DRIVER_ORDER_UPDATE: "Load Status Update",
  DRIVER_ORDER_UPDATE: "Order Status Updated",
  UPDATE_DOCUMENT_STATUS_LOAD: "Document Status Updated",
  STATUS_COMPLETE_DATE_CHANGE: "Manual Date/Address change for Status",
  RESET_LOAD: "Load Reseted",
  STREET_TURN: "Street Turn Added",
  STREET_TURN_EDIT: "Street Turn Removed",
  LOAD_READY_FOR_PICKUP: "Ready To Return",
  PAYMENT_ADD: "Payment Added",
  PAYMENT_REMOVED: "Payment Removed",
  DRIVER_LOAD_REJECTED: "Driver rejected the load.",
  ADDED_CSR: "CSR Added",
  REMOVED_CSR: "CSR Removed",
  NOTES_UPDATE: "Notes Update",
  DRIVER_PAY_APPROVAL: "Driver Pay",
  SUB_BILL_ADDED:"Sub Bill Added",
  SUB_BILL_REMOVED: "Sub Bill Removed",
  SUB_BILL_PRICING_ADDED: "Sub Bill Pricing Added",
  SUB_BILL_PRICING_UPDATED: "Sub Bill Pricing Updated",
  SUB_BILL_CUSTOMER_UPDATED: "Sub Bill Customer was changed",
  SUB_BILL_PRICING_REMOVED: "Sub Bill Pricing removed",
  SUB_BILL_BILLING_DATE_UPDATED: "Sub Bill Billing Date changed",
  SUB_BILL_STATUS_CHANGED: "Sub Bill Status changed",
  MYAPP_UPDATE: "MyApp Update",
  GEO_FENCE_CHANGE: "Load Status Update",
};

const auditNotes = [
  { value: "officeNote", label: "Load Notes" },
  { value: "yardNote", label: "Yard Notes" },
  { value: "billingNote", label: "Billing Notes" },
  { value: "driverNote", label: "Driver Notes" }
]

const auditConstants={
  GEO_FENCE_CHANGE:"GEO_FENCE_CHANGE",
}
class AuditLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auditList: [],
      equipmentList: [],
      ContainerSize: [],
      ContainerType: [],
      TMSContainerOwner: [],
      ChassisSize: [],
      ChassisType: [],
      TMSChassisOwner: [],
      terminals:[],
    };
    this.handleChangeOfSearch = this.handleChangeOfSearch.bind(this);
    this.getDriverName = this.getDriverName.bind(this);
    this.getEquipmentName = this.getEquipmentName.bind(this);
    this.userTimeFormat = isMilitaryTime();
    this.changeColorCode = {
      created: "#F4D4CF",
      updated: "#F4ECCF",
      deleted: "#CFF4EE",
      unchanged: "",
    };
  }

  componentWillReceiveProps(props) {
    if (
      (!this.props.selectedLoads && props.selectedLoads) ||
      (this.props.selectedLoads &&
        this.props.selectedLoads != props.selectedLoads)
    ) {
      this.fetchAllInfos();
    }
  }

  componentWillMount() {
    this.fetchAllInfos();
  }

  fetchAllInfos() {
    this.props.actions.allTrucks().then((result) => {
      this.setState({
        equipmentList: result,
      });
    });
    const { reference_number } = this.props.selectedLoads
      ? this.props.selectedLoads
      : this.props.location.query;

    this.props.actions
      .auditByLoad({ reference_number })
      .then((result) => {
        let data = this.getfilteredAuditList(result);
        this.setState({ auditList: data });
        // this.setState({ auditList: result });
      })
      .catch((err) => {
        console.log("-*-* get audit load *-*-", err);
        toastr.show("No audit information is available.", "error");
      });

  }

  getfilteredAuditList=(result=[])=>{
    let excluedStatus = [
      "BILLING_DATE_CHANGED",
      "STATUS_APPROVED",
      "STATUS_UNAPPROVED",
      "STATUS_BILLING",
      "STATUS_REBILLING",
    ]
    result = result.filter((d) => !excluedStatus.includes(d.type))
    result=result.map((item, key)=>{
      item.ikey=key;
      return item
    });
    let systemLfdData=result.filter(item=>{
      if( (!item.userId && !item.userData) && item.data && ('lastContainerTracedDate' in  item.data)){
        return true;
      }
    })
    let userData=result.filter(item=>{
      if( (item.userId||item.userData) || item.data && !('lastContainerTracedDate' in  item.data)){
        return true;
      }
    })
    let trueIndexes = [];
    let falseIndexes = [];
    let count = 0;
    systemLfdData.map((rr, i) => {
      if (rr.data.lastContainerErrorDate || rr.data.caution || rr.data.errorDate) {
        count++
        // trueIndexes[i]
      } else {
        count = 0;
        falseIndexes.push(i)
      }
      if (count === 3) {
        count = 0;
        trueIndexes.push(i - 2);
      }
    });

    systemLfdData = systemLfdData.filter((rr, i) => {
      if ([...trueIndexes, ...falseIndexes].includes(i)) {
        return rr
      }
    })
    systemLfdData=systemLfdData.map((item, key)=>{
      if(trueIndexes.includes(key) && (item.additionalInfo || item.previousData)){
        item.additionalInfo.caution=false;
        item.additionalInfo.errorMessage='';
      }
      return item;
    })

    let final = _.orderBy(_.concat(systemLfdData, userData), 'ikey')

    return final;
  }

  handleChangeOfSearch(e) {
    var filterVal = e.target.value.toLowerCase();
    $("#auditTable tbody tr").each(function (table) {
      var isSubstr = false;
      $(this)
        .find("td")
        .each(function () {
          var tdVal = $(this).text().toLowerCase();
          if (tdVal.indexOf(filterVal) >= 0) {
            isSubstr = true;
          }
        });
      if (isSubstr) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  }

  audit_LOAD_ADD_DUPLICATE(infos) {
    const { additionalInfo, previousData } = infos;
    return (
      <div className="auditField">
        {`Load Duplicated From ${previousData ? previousData.reference_number: additionalInfo.reference_number}`}{" "}
      </div>
    );
  }

  audit_LOAD_ACCEPT(infos) {
    const { additionalInfo, previousData } = infos;
    return (
      <div className="auditField">
        {`Load Accepted From ${
          previousData
            ? previousData.customer
            : additionalInfo && additionalInfo.customer
        }`}{" "}
      </div>
    );
  }

  audit_ASSIGN_COMPANY(infos) {
    let { data, additionalInfo, previousData } = infos;
    previousData = previousData ?? additionalInfo;
    return (
      <div className="auditField">
        {previousData && previousData.company && (
          <span>Load Assigned to {previousData.company}</span>
        )}
        {!previousData && data && data.driverName && (
          <span>Load Assigned to {data.driverName}</span>
        )}
      </div>
    );
  }

  audit_BUILD_STATUS_ORDER(infos) {
    return <div>{``} </div>;
  }
  audit_PAYMENT_ADD(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        payment added{" "}
        <span className="badge badge-info badge-pill ml-auto mr-3 font-medium px-2 py-1">{`$${data.amount}`}</span>{" "}
      </div>
    );
  }
  audit_PAYMENT_REMOVE(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        payment removed{" "}
        <span className="badge badge-info badge-pill ml-auto mr-3 font-medium px-2 py-1">{`$${data.amount}`}</span>{" "}
      </div>
    );
  }

  audit_LOAD_CHANGE_STATUS(infos) {
    const { data } = infos;
    const { selectedLoads } = this.props;
    let newStatus = data.status;
    if (!newStatus && data.set && data.set.status) {
      newStatus = data.set.status;
    }
    let statusObj = convertTMSStatusName(
      selectedLoads ? selectedLoads.type_of_load : "",
      newStatus,
      null,
      null,
      true
    );
    return (
      <div className="auditField">
        Load Status Changed To{" "}
        <span className={`badge badge-soft-${statusObj.colorStatus}  mr-3 `}>
          {statusObj.status}
        </span>{" "}
      </div>
    );
  }

  audit_MOBILEAPP_LOAD_STATUS_UPDATE(infos) {
    const { data } = infos;
    const { selectedLoads } = this.props;
    if (data && data.nearLocation) {
      let newStatus = data.nearLocation.loadStatus;
      let statusObj = convertTMSStatusName(
        selectedLoads ? selectedLoads.type_of_load : "",
        newStatus,
        null,
        null,
        true
      );
      return (
        <div className="auditField">
          Load Status Changed To{" "}
          <span
            className={`badge badge-soft-warning badge-${statusObj.colorStatus}`}
          >
            {statusObj.status}
          </span>{" "}
        </div>
      );
    } else {
      return <div>{"No Status"}</div>;
    }
  }

  audit_MOBILEAPP_DRIVER_ORDER_UPDATE(infos) {
    const { updatedStatus, removedStatus } = infos.data;
    const { selectedLoads } = this.props;
    if (updatedStatus) {
      return (
        <div className="auditField">
          Load Status Changed To{" "}
          {updatedStatus.map((status) => {
            let statusObj = convertTMSStatusName(
              selectedLoads ? selectedLoads.type_of_load : "",
              status,
              null,
              null,
              true
            );
            return (
              <><span
                className={`badge badge-soft-${statusObj.colorStatus} mr-0`}
              >
                {statusObj.status}
              </span>{infos.type === auditConstants.GEO_FENCE_CHANGE ?
              <b>by Geofence</b>
              :""}</>
            );
          })}
        </div>
      );
    } else if (removedStatus) {
      return (
        <div className="auditField">
          Load Status Removed{" "}
          {removedStatus.map((status) => {
            return (
              <span className="badge badge-danger-light">{`${convertTMSStatusName(
                selectedLoads ? selectedLoads.type_of_load : "",
                status
              )}`}</span>
            );
          })}
        </div>
      );
    } else {
      return <div>{"No Status"}</div>;
    }
  }

  audit_ASSIGN_LOAD_DRIVER(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        {`Driver ${this.getDriverName(
          data.driver
        )} and Truck ${this.getEquipmentName(data.equipment)} assigned`}{" "}
      </div>
    );
  }

  audit_DRIVER_LOAD_REJECTED(infos) {
    let { type, data, additionalInfo, userId, previousData } = infos;
    previousData=previousData??additionalInfo
    if (previousData) {
      return (
        <div className="auditField">
          {`${this.getDriverName(
            previousData.driver
          )} rejected the load.`}{" "}
        </div>
      );
    } else {
      return <div className="auditField">{`Driver  removed`} </div>;
    }
  }

  audit_HARD_REMOVE_DRIVER(infos) {
    let { additionalInfo, previousData } = infos;
    previousData=previousData?? additionalInfo
    if (previousData) {
      return (
        <div className="auditField">
          {`Driver ${this.getDriverName(
            previousData.driver
          )} and Truck ${this.getEquipmentName(
            previousData.equipment
          )} removed`}{" "}
        </div>
      );
    } else {
      return <div className="auditField">{`Driver  removed`} </div>;
    }
  }

  audit_STREET_TURN(infos) {
    const { data } = infos;
    return (
      <div className="auditField">
        {data && (
          <span>
            <span className="font-medium">Street Turn </span>
            {data.type_of_load === "IMPORT" && "to"}
            {data.type_of_load === "EXPORT" && "from"}
            <span className="font-medium"> {data.streetTurn}</span>
          </span>
        )}
      </div>
    );
  }

  audit_LOAD_EDIT(infos, prevUserId) {
    let { data, additionalInfo, userId, previousData, userData } = infos;
    previousData=previousData?? additionalInfo
    const changedInfos =  differnceFinder(
      previousData,
      data,
      userData??userId,
      prevUserId
    );
    const removeArray=[
      "isDrayosTrackosError",
      "isUserEnteredfreight",
      "isUserEnteredcustom",
      "isUserEnteredlastFreeDay",
      "isUserEnteredvessel",
      "isUserEnteredcontainerSize",
      "isUserEnteredcontainerType",
      "isUserEnteredcontainerOwner",
      "isUserEnterdischargedDate",
      "isUserEnteredreleaseNo",
      "isUserEntereddeliveryOrderNo",
      "isUserEnteredingateDate",
      "isUserEnteredoutgateDate",
      "systemGeneratedContainerSizeName",
      "systemGeneratedOwnerName",
      "systemGeneratedTypeName",
      "systemGeneratedLastFreeDay",
      "systemGeneratedDischarge",
      "systemGeneratedEstimatedToArrive",
      "systemGeneratedOutGateDate",
      "systemGeneratedInGateDate",
      "systemGeneratedVesselName",
      "systemGeneratedVoyageName",
      "isSelectedFromRecommendation"
    ]

    const newAuditHandledKey = [
      "chassisType",
      "chassisSize",
      "chassisOwner",
      "containerType",
      "terminal",
      "containerSize",
      "containerOwner",
      "grayChassisType",
      "grayChassisSize",
      "grayChassisOwner",
      "grayContainerType",
      "grayContainerSize",
      "grayContainerOwner",
    ];

    removeArray.forEach(i=>{
      delete changedInfos[i]
    })
    
    if (Object.keys(changedInfos).length === 0) {
      return (
        <div>
          <span>Not anything changed</span>
        </div>
      );
    }

    return (
      <div>
        {Object.keys(changedInfos).map((key, i) => {
          let fieldName = key;
          let value = changedInfos[key];
          if (
            [
              "caller",
              "shipper",
              "consignee",
              "chassisPick",
              "emptyOrigin",
              "chassisTermination",
            ].indexOf(key) > -1
          ) {
          } else if (key === "chassisType") {
            if(typeof value === "object"){
              value = value.name;
            }else{
              const chassisInfo = this.props.ChassisType.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.name;
              }
            }
            
          } else if (key === "chassisSize") {
            if(typeof value === "object"){
              value = value.name;
            }else{
              const chassisInfo = this.props.ChassisSize.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.name;
              }
            }
          
          } else if (key === "chassisOwner") {
            if(typeof value === "object"){
              value = value.company_name;
            }else{
              const chassisInfo = this.props.TMSChassisOwner.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.company_name;
              }
            }
            
          } else if (key === "containerType") {
            if (typeof value === "object") {
              value = value.name;
            } else {
              const containerInfo = this.props.ContainerType.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
          }else if (key === "terminal") {
            if (typeof value === "object") {
              value = value.name;
            }else{
              const terminalInfo = this.props.terminals.find((obj) => {
                return obj._id === value;
              });
              if (terminalInfo) {
                value = terminalInfo.name;
              }
            }
           
          } else if (key === "containerSize") {
            if (typeof value === "object") {
              value = value.name;
            }else{
              const containerInfo = this.props.ContainerSize.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
            
          } else if (key === "containerOwner") {
            if (typeof value === "object") {
              value = value.company_name;
            }else{
              const containerInfo = this.props.TMSContainerOwner.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.company_name;
              }
            }
            
          } else if (key === "grayChassisType") {
            if (typeof value === "object") {
              value = value.name;
            }else{
              const chassisInfo = this.props.ChassisType.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.name;
              }
            }
          } else if (key === "grayChassisSize") {
            if (typeof value === "object") {
              value = value.name;
            }else{
              const chassisInfo = this.props.ChassisSize.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.name;
              }
            }
            
          } else if (key === "grayChassisOwner") {
            if (typeof value === "object") {
              value = value.company_name;
            }else{
              const chassisInfo = this.props.TMSChassisOwner.find((obj) => {
                return obj._id === value;
              });
              if (chassisInfo) {
                value = chassisInfo.company_name;
              }
            }
            
          } else if (key === "grayContainerType") {
            if (typeof value === "object") {
              value = value.name;
            }else{
              const containerInfo = this.props.ContainerType.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
          
          } else if (key === "grayContainerSize") {
            if (typeof value === "object") {
              value = value.name;
            }else{
              const containerInfo = this.props.ContainerSize.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.name;
              }
            }
            
          } else if (key === "grayContainerOwner") {
            if (typeof value === "object") {
              value = value.company_name;
            }else{
              const containerInfo = this.props.TMSContainerOwner.find((obj) => {
                return obj._id === value;
              });
              if (containerInfo) {
                value = containerInfo.company_name;
              }
            }
          
          } else if (value && value.length > 10 && moment(value).isValid()) {
            value = moment(value)
            .tz(getStorage("timeZone")).format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)
          } else if (key === "pickupTimes") {
            const date = { ...changedInfos[key][`0`] };

            if (date && date.pickupFromTime && date.pickupToTime) {
              value = `FromTime: ${moment(date.pickupFromTime)
                .tz(getStorage("timeZone"))
                .format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)}`;
              value += `, ToTime: ${moment(date.pickupToTime)
                .tz(getStorage("timeZone"))
                .format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)}`;
            }

            changedInfos.pickupTimes = value;
          } else if (key === "deliveryTimes") {
            const date = { ...changedInfos[key][`0`] };

            if (date && date.deliveryFromTime && date.deliveryToTime) {
              value = `FromTime: ${moment(date.deliveryFromTime)
                .tz(getStorage("timeZone"))
                .format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)}`;
              value += `, ToTime: ${moment(date.deliveryToTime)
                .tz(getStorage("timeZone"))
                .format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)}`;
            }

            changedInfos.deliveryTimes = value;
          } else if (
            key === "vessel" &&
            Object.keys(changedInfos[key]).includes("eta") &&
            changedInfos[key].eta
          ) {
            value = moment(changedInfos[key].eta)
              .tz(getStorage("timeZone"))
              .format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`);
            changedInfos.vessel = value;
          } else if (key === "source") {
            return;
          }

          if (fieldName == "caution") {
            return;
          }

          if (fieldName == "cautionCount") {
            return;
          }
          if (fieldName == "brokerHold" || fieldName == "carrierHold" || fieldName == "isGrayPool" || fieldName == "isContainerConfirm") {
            if (value == true) {
              value = "Yes";
            }
            if (value === false) {
              value = "No";
            }
          }
          if (fieldName === "lastContainerErrorDate" || fieldName === "errorDate") {
            return;
          }

          if (fieldName === "pickupTimes" || fieldName === "deliveryTimes") {
            if (changedInfos[key] && changedInfos[key][`0`] && Object.keys({ ...changedInfos[key][`0`] }).length === 0) {
              const result = !_.isEqualWith(previousData[fieldName].map((d) => {
                delete d._id;
                return d;
              }), data[fieldName].map((d) => {
                delete d._id;
                return d;
              }));
              if (result) {
                return (
                  <div>
                    <div className="auditField">
                      <span className="font-medium"> {auditKey[fieldName] ? auditKey[fieldName] : fieldName} was Removed</span>
                    </div>
                  </div>
                );
              }
            }
          }
          if (
            fieldName !== "isEmptyOrigin" &&
            fieldName !== "isLastFreeDay" &&
            fieldName !== "isLastFreeDay" &&
            fieldName !== "chassisId" &&
            fieldName !== "grayChassisId" &&
            fieldName !== "shipperInfo" &&
            fieldName !== "consigneeInfo"
          ){
            
            return (
              (typeof changedInfos[key] !== "object" ||newAuditHandledKey.includes(key)) && (
                <div className="auditField" key={i}>
                  {
                    fieldName !== "caution" && fieldName !== "errorMessage" &&
                    <Fragment>
                      <span className="font-medium">
                        {auditKey[fieldName] ? auditKey[fieldName] : fieldName}
                      </span>
                      {` changed to `}
                    </Fragment>
                  }
                  <span className="font-medium">{value}</span>
                </div>
              )
            );
          }
          if (key === "shipperInfo" || key === "consigneeInfo") {
            return value.map((val, i) => {
              return (
                <div className="auditField" key={i}>
                  {auditKey[fieldName] ? auditKey[fieldName] : fieldName}
                  <span className="font-medium">{' ' + val.value + ' '}</span>
                  {val.editType}
                </div>
              );
            })
          }
        })}
      </div>
    );
  }

  audit_UPDATE_LOAD_STATUSORDER(infos) {
    return <div>{``} </div>;
  }

  audit_LOAD_READY_FOR_PICKUP(infos) {
    return (
      <div className="auditField">
        <span className="font-medium">Load Become Ready To Return</span>{" "}
      </div>
    );
  }
  audit_LOAD_CONFIRM(infos) {
    return (
      <div className="auditField">
        <span className="font-medium">
          {infos.data.isConfirm ? `Load Confirm` : `Load Un Confirm`}
        </span>
        {infos.data.isConfirm && infos.data.sealNo && (
          <span className="font-medium">, seal No: {infos.data.sealNo} </span>
        )}
        {infos.data.isConfirm && infos.data.notes && (
          <span className="font-medium">, notes: {infos.data.notes} </span>
        )}
      </div>
    );
  }
  audit_PRICING_UPDATE(infos){
    let { data, additionalInfo, previousData } = infos;
    previousData=previousData?? additionalInfo
    const changedInfos = pricingDiffernceFinder(
      previousData ? previousData : {},
      data ? data : {},
    );

    if (changedInfos.length === 0) {
      return null;
    }
    else {
      return (
        <div className="auditField">
          {changedInfos.map((charge) => {
            let badge = 'badge-soft-purple';
            if (charge.editType === 'DELETED PRICING') badge = 'badge-danger-light';
            else if (charge.editType === 'ADDED PRICING') badge = 'badge-soft-green';

            return (
              <div className="mb-2">
                <span className={"mr-3 badge " + badge}>{charge.editType}</span>
                <span className="mr-3 font-weight-bold">{charge.name}</span>
                <span>(Price: {charge.finalAmount}, Unit: {charge.unit}, Type: {charge.perType}
                  , Free Units: {charge.freeDays}, Per Units: {charge.chargePerDay})</span>
              </div>
            )
          })}
        </div>
      )
    }
  }

  audit_DRIVER_PAY_CHANGE(infos) {

    let { additionalInfo, userId, previousData, userData } = infos;
    previousData=previousData?? additionalInfo
    userData=userData??userId
    const driverName=this.getDriverName(previousData.driver)
    const changedInfos = (approveStatusData)=>{
      if(approveStatusData.approved===true){
        return "approved"
      } else {
        return "unapproved"
      }
    }
      return (
        <div className="auditField">
        <span className="font-medium"><b>{userData.name}</b> {changedInfos(previousData)} driver pay for driver <b>{driverName}</b> on <b>{previousData.status}</b></span>
      </div>
    )
  }

  audit_CSR_CHANGE(infos) {
    let csrName = ""
    if (infos.data) {
      csrName = infos.data.name ? csrName + infos.data.name + " " : "";
      csrName = infos.data.lastName ? csrName + infos.data.lastName : ""
    }
    const desc = infos.type === "ADDED_CSR" ? "New CSR Assigned " : "CSR Removed "
    return <p>{desc}   <b className={`badge ${infos.type === "ADDED_CSR" ? "badge-soft-green" : "badge-soft-brown"} `}>{csrName}</b></p>
  }
  audit_NOTES_CHANGE(infos){
    const previousData=infos.previousData??infos.additionalInfo
    return (
      <div className="auditField">
        {auditNotes.map((note) => {
          if(note.value === Object.keys(previousData)[0]) {
            return note.label;
          }
        })}
        {" "}
        changed to {" "}
        <b>{previousData && 
        previousData.driverNote ? previousData.driverNote :
        previousData.billingNote ? previousData.billingNote :
        previousData.yardNote ? previousData.yardNote : previousData.officeNote}</b>{" "}
      </div>
    )
  }

  audit_LOAD_ADD_QUOTE(infos) {
    return (
      <div className="auditField">
        Generated off of QUOTE {" "} <b>{infos && infos.data && infos.data.quote}</b>
      </div>
    );
  }

  myapp_update(info, userId) {
    let importAvailablityKeys = [
      "custom",
      "freight",
      "deliveryOrderNo",
      "releaseNo", "status",
      "containerOwnerName",
      "containerSizeName",
      "containerTypeName",
      "lastFreeDay",
      "outgateDate",
      "vessel",
      "dischargedDate",
      "ingateDate"

    ]
    const { data } = info
    
    Object.keys(data).forEach(i => {
      if (!importAvailablityKeys.includes(i)) {
        delete data[i]
      }
    })
    return (
     
      <div className="auditField">
        {
          Object.keys(data).map((key, i) => {
            let val=data[key]
            if(key === 'vessel') {
              val=data[key].eta;
            }
            if(key === 'brokerHold') {
              const mapper={
                true:"YES",
                false:"NO"
              }
              val=  mapper[val]
            }
            return (
              <div>
                <span className="font-medium">
                  {auditKey[key]}  </span>changed to   <span className="font-medium"> {moment(data[key]).isValid() ? moment(data[key]).tz(getStorage("timeZone")).format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`) : data[key]}
                </span>
              </div>
            )
          })
        }
      </div>
    )



  }


  audit_REMOVE_FIELD(infos){
    const { data } = infos;

    return (
      <div>
        <div className="auditField">
          <span className="font-medium">{auditKey[data.key] ? auditKey[data.key] : data.key} was Removed</span>
        </div>
      </div>
    );

  }

  audit_SUB_BILL_ADDED(infos){
    const { data } = infos;
    return (
      <div>
       <div className="auditField">
        <span className="font-medium">New Sub bill <b className="badge badge-gray-100 badge-sm">{data?.sub_reference_number ? data.sub_reference_number : ""}</b> was added.</span>
        </div>
      </div>
    );
  }

  audit_SUB_BILL_REMOVED(infos){
    const { additionalInfo } = infos;
    return (
      <div>
       <div className="auditField">
        <span className="font-medium">Sub bill <b className="badge badge-danger-light">{additionalInfo?.sub_reference_number ? additionalInfo.sub_reference_number : ""}</b> was removed.</span>
        </div>
      </div>
    );
  }

  audit_SUB_BILL_PRICING_ADDED(infos, isUpdate){
    const { data } = infos;
    const { sub_reference_number, pricing } = data
    return (
      <div className="auditField">
        <div className="mb-2">
          <span className={isUpdate ? "badge badge-soft-purple" : "badge badge-soft-green"}>{isUpdate ? "PRICING UPDATED" :"ADDED PRICING"}</span> In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b><br></br>
          <span className="mr-3 font-weight-bold">{pricing?.name}</span>
          <span>(Price: {pricing?.finalAmount}, Unit: {pricing?.unit}, Type: {pricing?.perType}
          , Free Units: {pricing?.freeDays}, Per Units: {pricing?.chargePerDay})</span>
        </div>
      </div>
    )
  }

  audit_SUB_BILL_PRICING_REMOVED(infos){
    const { data } = infos;
    const { sub_reference_number, pricing } = data
    return (
      <div className="auditField">
        <div className="mb-2">
          <span className={"badge badge-danger-light"}>PRICING REMOVED</span> In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b><br></br>
          <span className="mr-3 font-weight-bold">{pricing?.name}</span>
          <span>(Price: {pricing?.finalAmount}, Unit: {pricing?.unit}, Type: {pricing?.perType}
          , Free Units: {pricing?.freeDays}, Per Units: {pricing?.chargePerDay})</span>
        </div>
      </div>
    )
  }

  audit_SUB_BILL_CUSTOMER_UPDATED(infos){
    const { data } = infos;
    const { sub_reference_number, oldCustomerName, newCustomerName } = data
    return (
      <div className="auditField">
        <div className="mb-2">
          <span className={"badge badge-soft-green"}>CUSTOMER CHANGED</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldCustomerName}</span> TO <span className="mr-3 font-weight-bold">{newCustomerName}</span>
        </div>
      </div>
    )
  }

  audit_SUB_BILL_BILLING_DATE_UPDATED(infos){
    const { data } = infos;
    const { sub_reference_number, oldBillingDate, newBillingDate } = data
    return (
      <div className="auditField">
        <div className="mb-2">
          <span className={"badge badge-soft-purple"}>BILLING DATE CHANGED</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldBillingDate ? moment(oldBillingDate).tz(getStorage("timeZone")).format("MM/DD/YY") : "EMPTY"}</span> TO 
          <span className="mr-3 font-weight-bold">{" " + moment(newBillingDate).tz(getStorage("timeZone")).format("MM/DD/YY")}</span>
        </div>
      </div>
    )
  }

  audit_SUB_BILL_STATUS_CHANGED(infos){
    const { data } = infos;
    const { sub_reference_number, oldStatus, newStatus } = data
    return (
      <div className="auditField">
        <div className="mb-2">
          <span className={"badge badge-soft-green"}>STATUS CHANGED</span>  In Sub Bill
          <b className="badge badge-gray-100 badge-sm m-2">{sub_reference_number}</b>
          <span className="font-weight-bold">{oldStatus}</span> TO  
          <span className="mr-3 font-weight-bold"> {newStatus}</span>
        </div>
      </div>
    )
  }

  getDriverName(id) {
    const driver = this.props.driverList.find((obj) => {
      return obj._id === id;
    });
    return driver ? driver.name : "";
  }

  getEquipmentName(id) {
    const equipment = this.state.equipmentList.find((obj) => {
      return obj._id === id;
    });
    return equipment ? equipment.equipmentID : "";
  }

  displayDifference(infos, prevUserId) {
    const { type } = infos;
    if (type === "LOAD_ADD_DUPLICATE") {
      return this.audit_LOAD_ADD_DUPLICATE(infos);
    } else if (type === "BUILD_STATUS_ORDER") {
      return this.audit_BUILD_STATUS_ORDER(infos);
    } else if (type === "LOAD_CHANGE_STATUS") {
      return this.audit_LOAD_CHANGE_STATUS(infos);
    } else if (type === "MOBILEAPP_LOAD_STATUS_UPDATE") {
      return this.audit_MOBILEAPP_LOAD_STATUS_UPDATE(infos);
    } else if (type === "MOBILEAPP_DRIVER_ORDER_UPDATE" 
                || type === "GEO_FENCE_CHANGE") {
      return this.audit_MOBILEAPP_DRIVER_ORDER_UPDATE(infos);
    } else if (type === "ASSIGN_LOAD_DRIVER") {
      return this.audit_ASSIGN_LOAD_DRIVER(infos);
    } else if (type === "HARD_REMOVE_DRIVER") {
      return this.audit_HARD_REMOVE_DRIVER(infos);
    } else if (type === "LOAD_EDIT") {
      return this.audit_LOAD_EDIT(infos, prevUserId);
    } else if (type === "UPDATE_LOAD_STATUSORDER") {
      return this.audit_UPDATE_LOAD_STATUSORDER(infos);
    } else if (type === "LOAD_READY_FOR_PICKUP") {
      return this.audit_LOAD_READY_FOR_PICKUP(infos);
    } else if (type === "LOAD_CONFIRM") {
      return this.audit_LOAD_CONFIRM(infos);
    } else if (type === "LOAD_ACCEPT") {
      return this.audit_LOAD_ACCEPT(infos);
    } else if (type === "ASSIGN_COMPANY") {
      return this.audit_ASSIGN_COMPANY(infos);
    } else if (type === "PAYMENT_ADD") {
      return this.audit_PAYMENT_ADD(infos);
    } else if (type === "PAYMENT_REMOVED") {
      return this.audit_PAYMENT_REMOVE(infos);
    } else if (type === "STREET_TURN") {
      return this.audit_STREET_TURN(infos);
    } else if (type === 'PRICING_UPDATE') {
      return this.audit_PRICING_UPDATE(infos);
    } else if (type === "DRIVER_LOAD_REJECTED") {
      return this.audit_DRIVER_LOAD_REJECTED(infos);
    } else if (['REMOVED_CSR', 'ADDED_CSR'].includes(type)) {
      return this.audit_CSR_CHANGE(infos)
    } else if (type === "NOTES_UPDATE") {
      return this.audit_NOTES_CHANGE(infos)
    } else if (type === "LOAD_ADD_QUOTE") {
      return this.audit_LOAD_ADD_QUOTE(infos)
    } else if (type === "DRIVER_PAY_APPROVAL") {
      return this.audit_DRIVER_PAY_CHANGE(infos)
    }else if(type === "REMOVE_FIELD"){
      return this.audit_REMOVE_FIELD(infos,prevUserId)
    } else if(type === "SUB_BILL_ADDED"){
      return this.audit_SUB_BILL_ADDED(infos)
    } else if(type === "SUB_BILL_REMOVED"){
      return this.audit_SUB_BILL_REMOVED(infos)
    } else if(type === 'SUB_BILL_PRICING_ADDED'){
      return this.audit_SUB_BILL_PRICING_ADDED(infos)
    } else if(type === 'SUB_BILL_PRICING_UPDATED'){
      return this.audit_SUB_BILL_PRICING_ADDED(infos, true)
    } else if( type === 'SUB_BILL_CUSTOMER_UPDATED'){
      return this.audit_SUB_BILL_CUSTOMER_UPDATED(infos)
    } else if(type === "SUB_BILL_PRICING_REMOVED"){
      return this.audit_SUB_BILL_PRICING_REMOVED(infos)
    } else if(type === "SUB_BILL_BILLING_DATE_UPDATED"){
      return this.audit_SUB_BILL_BILLING_DATE_UPDATED(infos)
    } else if( type === 'SUB_BILL_STATUS_CHANGED'){
      return this.audit_SUB_BILL_STATUS_CHANGED(infos)
    } else if (type === "MYAPP_UPDATE") {
      return this.myapp_update(infos, prevUserId)
  } 
    return "";
  }
  renderReturn = () => {
    return (
      <div className="tab" id="tab-document">
        <div className="card px-20 py-10 mb-0 mt-10">
          <div className="app-search">
            <input
              type="text"
              className="input-search-left form-control"
              placeholder="Search.."
              onChange={(e) => {
                this.handleChangeOfSearch(e);
              }}
            />
            <span className="search-icon"></span>
          </div>
        </div>
        <div className="mb-20">
          <table
            id="auditTable"
            className="table table-card table-card--wrap mb-10"
          >
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                <th>Data</th>
              </tr>
            </thead>

            <tbody>
              {
                this.state.auditList.map((info, key) => {
                  if(info.type === "MYAPP_UPDATE"){
                    delete info.data.documents
                    delete info.data.isDrayosTrackosError
                    if(_.isEmpty(info.data)){
                       return 
                    }
                 }
                  let prevUserId = null;
                  if (
                    key !== this.state.auditList.length - 1 &&
                    (this.state.auditList[key + 1].userId ||this.state.auditList[key + 1].userData)
                  ) {
                    prevUserId = this.state.auditList[key + 1].userData??this.state.auditList[key + 1].userId;
                  }
                  if (
                    info.type === "LOAD_EDIT" &&
                    Object.keys(
                      differnceFinder(
                        info.previousData??info.additionalInfo,
                        info.data,
                        info.userData??info.userId,
                        prevUserId
                      )
                    ).length === 0
                  ) {
                    return;
                  }
                  let tdVal = this.displayDifference(info, prevUserId);
                  if (
                    !['LOAD_EDIT', 'PRICING_UPDATE'].includes(info.type) ||
                    (tdVal &&
                      tdVal.props &&
                      tdVal.props.children &&
                      _.compact(tdVal.props.children).length > 0)
                  ) {
                    return (
                      <tr key={info._id} id={info._id}>
                        <td className="d-flex align-items-center">
                          <span className="avatar-circle avatar-xs mr-1">
                            {info.userData ? info.userData.name.charAt(0) :info.userId ? info.userId.name.charAt(0) : "S"}
                          </span>{" "}
                          {info.userData?info.userData.name:info.userId ? info.userId.name : "SYSTEM"}
                        </td>
                        <td>
                          {auditType[info.type] && (
                            <span className="badge badge-gray-100 badge-sm">
                              {auditType[info.type]}{" "}
                            </span>
                          )}
                        </td>
                        <td>
                          <div>
                            {moment(info.createdAt)
                              .tz(getStorage("timeZone"))
                              .format("MM/DD/YY")}
                          </div>
                          <div className="text-muted">
                            {moment(info.createdAt)
                              .tz(getStorage("timeZone"))
                              .format(`${this.userTimeFormat ? 'HH:mm': "hh:mm a"}`)}
                          </div>
                        </td>
                        <td>
                          <div className="auditField">{tdVal}</div>
                        </td>
                      </tr>
                    );
                  }
                })
              }



            </tbody>
          </table>
        </div>
      </div>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (loggedInUser.role === "carrier") return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (loggedInUser.permissions.includes("customer_service_audit"))
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch: dispatch,
  };
}

const states = (state) => ({
  driverList: state.TmsReducer.drivers,
  ContainerSize: state.containerReducer.containerSize,
  ContainerType: state.containerReducer.containerType,
  TMSContainerOwner: state.containerReducer.containerOwner,
  ChassisType: state.chassisReducer.chassisType,
  ChassisSize: state.chassisReducer.chassisSize,
  TMSChassisOwner: state.chassisReducer.chassisOwner,
  terminals: state.HomeReducer.terminals,
});

export default connect(states, mapDispatchToProps)(withRouter(AuditLoad));
