import axios from 'axios';
import _ from 'lodash';

import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import * as ToQuery from '../../../Components/Common/jsonToQueryParams'
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams'
import { LOCATION_PRICE } from '../constant';
import configuration from '../../../config';


export function getCustomers(params, limit, customerType) {
  let obj = {}
  if(limit && typeof(limit)==='number'){
    if(params){
      obj = { ...params, limit: limit} 
    }else{
      obj = {limit: limit}
    }
  } else {
    obj = { nameSearch: params, limit: 10}
  }
  if (customerType) {
  obj.customerType=customerType
}
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        if(limit && typeof(limit)==='number'){
            resolve(allDatas);
        }
        let customers = []
        allDatas.forEach((obj) => {
          customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, allInfos: obj });
        });
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getSubCustomer(params) {
  if (!params) {
    return
  }

  const obj = { ...params } 

  let url = 'carrier/getTMSSubCustomer';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDroppedCustomers(params) {
  const obj = { searchTerm: params }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.forEach((obj) => {
          customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, allInfos: obj });
        })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getDefaultAddress(params) {
  const obj = params;
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data[0]);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getCallers(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('caller') > -1) })
          .forEach((obj) => {
            let labelData = [];
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getShippers(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('shipper') > -1) })
          .forEach((obj) => {
            let labelData = [];
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getConsignees(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('consignee') > -1) })
          .forEach((obj) => {
            let labelData = [];
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getChassisPickCustomers(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'chassisPick']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getTMScaller(params, _id) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'caller']
  }
  if (_id && _id !== "undefined"  && typeof _id !== 'function') {
    obj._id = _id;
    obj.limit = 1;
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: obj.company_name, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getTMSshipper(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'shipper']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getTMSconsignee(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'consignee']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getEmptyOriginCustomers(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'containerReturn','shipper']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getChassisTerminationCustomers(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'chassisTermination']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getTerminal(params) {
  let obj;
  if (params) {
    obj = { searchTerm: params }
  }
  let url = 'getTerminal';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let datas = []
        if (result.data.data.length > 0) {
          result.data.data.forEach((obj) => {
            datas.push({ value: obj._id, label: obj.name, allInfos: obj });
          })
        }
        if (getStorage('currentUserRole') == 'fleetmanager' && (configuration.idForTerminal.indexOf(JSON.parse(getStorage('loggedInUser')).fleetManager.carrier) != -1)) {
          let fleetTerminal = JSON.parse(getStorage('loggedInUser')).fleetManager.terminals;
          datas = datas.filter((D) => fleetTerminal.indexOf(D.value) > -1);
        }
        resolve(datas);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTypeOfLoads(params) {
  return new Promise((resolve, reject) => {
    const types = [
      { value: 'IMPORT', label: 'IMPORT' },
      { value: 'EXPORT', label: 'EXPORT' },
      { value: 'ROAD', label: 'ROAD' }
    ]
    
    resolve(types);
  })
}

export function getTypeOfLoadsForUnassignedLoads(params) {
  return new Promise((resolve, reject) => {
    const types = [
      { value: 'IMPORT', label: 'IMPORT' },
      { value: 'EXPORT', label: 'EXPORT' },
      { value: 'ROAD', label: 'ROAD' }
    ]

    resolve(types);
  })
}

export function getDirectStatuses(params) {
  return new Promise((resolve, reject) => {
    const types = [
      { value: 'PENDING', label: 'PENDING' },
      { value: 'AVAILABLE', label: 'AVAILABLE' },
      { value: 'DEPARTED', label: 'DEPARTED' },
    ]
    resolve(types);
  })
}

export function getFiterLoads(params) {
  const obj = { searchTerm: params, limit: 10, skip: 0 }
  let url = 'tms/getLoads';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('POST', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const loads = []
        result.data.data.forEach((obj) => {
          loads.push({ value: obj.reference_number, label: obj.reference_number, allInfos: obj });
        })
        resolve(loads);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllDriver(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getAllDriverFilter(params) {
  const obj = {}
  if (params !== "")
    obj.name = params
  let url = 'carrier/getAllDriver';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const drivers = []
        result.data.data.data.forEach((obj) => {
          drivers.push({ value: obj._id, label: obj.name + ' ' + obj.lastName });
        })
        resolve(drivers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllStatusFilter(params) {
  let url = 'tms/allLoadStatus';
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const statuses = []
        result.data.data.forEach((obj) => {
          statuses.push({ value: obj, label: obj });
        })
        resolve(statuses);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function allTrucks(params) {
  let url = 'tms/allTrucks';
  url = params ? url + "?" + ToQuery(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getLoads(params) {
  let url = 'tms/getLoads';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getLoadsV2(params) {
  let url = 'tms/getLoads';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  
}

export function getStreetTurnLoadCounts(params) {
  let url = 'tms/getStreetTurnLoadCounts';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTMSLoadsByDates(params) {
  let url = 'tms/getTMSLoadsByDates';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getLoadDetail(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getNewLoadDetail(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function funGetLoadDetail(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function allLoadStatus() {
  let url = 'tms/allLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null)
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSChassis(params) {
  let url = 'carrier/getTMSChassis';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignLoadToDriver(params) {
  let url = 'tms/assignLoadToDriver';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignLoadToEquipment(params) {
  let url = 'tms/assignLoadToEquipment';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateTMSLoadStatus(params) {
  let url = 'tms/updateTMSLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function unCompleteStatus(params) {
  let url = 'tms/unCompleteStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function changeLoadStatus(params) {
  let url = 'tms/changeLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function unAssignLoadToEquipment(params) {
  let url = 'tms/unAssignLoadToEquipment';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function editLoad(params) {
  let url = 'tms/editLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function fetchLocationProfiles(url, params) {
  url = params ? url + "?" + Object.entries(params).map(e => e.join('=')).join('&') : url;

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, {}, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateStatusOrder(params) {
  let url = 'tms/updateStatusOrder';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getLocationName(lat, lng) {
  let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=pk.eyJ1IjoiaWFuamVubmluZ3MiLCJhIjoiZExwb0p5WSJ9.XLi48h-NOyJOCJuu1-h-Jg`;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      const query = {
        method: 'get',
        url: url
      }
      axios(query).then(function (response) {
        let address;
        let lat;
        let lng;

        if (response && response.data && response.data.features) {
          response.data.features.forEach((feature) => {
            if (feature.id.indexOf('place') === 0) {
              address = feature.place_name;
              lat = feature.center[1];
              lng = feature.center[0];
            }
          });
          resolve(address);
        } else {
          reject(response);
        }
      })
        .catch((error) => {
          reject(error)
        })

    })
  }
}

export function updateReverseAddress(params) {
  let url = 'driver/updateReverseAddress';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function hardRemoveDriver(params) {
  let url = 'tms/hardRemoveDriver';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function makeLoadStartabelToDriver(params) {
  let url = 'tms/makeLoadStartabelToDriver';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function makeLoadReadyForPickup(params) {
  let url = 'tms/makeLoadReadyForPickup';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function makeLoadConfirm(params) {
  let url = 'tms/makeLoadConfirm';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getPricingForLocation(params) {
  let url = 'tms/driver-payment';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          dispatch({
            type: LOCATION_PRICE,
            payload: result.data.data

          })
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function auditByLoad(params) {
  let url = 'tms/auditByLoad';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function changeStatusCompleteDate(params) {
  let url = 'tms/changeStatusCompleteDate';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function resetLoad(params) {
  let url = 'tms/resetLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function addBobTail(params) {
  let url = 'tms/addBobTail';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateBobTail(params) {
  let url = 'tms/updateBobTail';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignBobTailToLoad(params) {
  let url = 'tms/assignBobTailToLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function changeBobTailStatus(params) {
  let url = 'tms/changeBobTailStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function removeBobTail(params) {
  let url = 'tms/removeBobTail';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignStreetTurn(params) {
  let url = 'tms/assignStreetTurn';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getContainerSize(params = '') {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'admin/getContainerSize';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerSize = []
        result.data.data.forEach((obj) => {
          containerSize.push({ value: obj._id, label: obj.name, allInfos: obj });
        })
        resolve(containerSize);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getCommodityProfile() {

  const url = "tms/getCommodityProfile";
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      authorization: getStorage("token")
    }).then(result => {
      resolve(result)
    })
      .catch(error => {
        reject(error)
      })

  })
}


export function getContainerType(params) {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'admin/getContainerType';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerType = []
        result.data.data.forEach((obj) => {
          containerType.push({ value: obj._id, label: obj.name, allInfos: obj });
        })
        resolve(containerType);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getContainerOwner(params) {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'carrier/getTMSContainerOwner';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerOwner = []
        result.data.data.forEach((obj) => {
          containerOwner.push({ value: obj._id, label: obj.company_name, allInfos: obj });
        })
        resolve(containerOwner);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDrivers(searchTerm) {
  let url = 'carrier/getAllDriver';
  let data = {
    searchTerm,
    limit: 10
  }

  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {

        if (result.data && result.data && result.data.data && result.data.data.data) {
          const { data } = result.data.data;
          const allDrivers = data.map(d => ({ value: d._id, label: `${d.name} ${d.lastName || ''}`.trim(), allInfo: d }))
          resolve(allDrivers)
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDriverItinerary(params) {
  let url = 'tms/getDriverItinerary';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, {}, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = result.data.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getDriverItineraryNew(params) {
  let url = 'tms/driver-itinerary/paginate';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = result.data.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTMScustomerForMap(params, cType = 'caller', isPdfMap = false,) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
  }
  if (cType) {
    obj.customerType = ['ALL', cType]
  }
  if (isPdfMap) {
    obj.fieldName = 'pdfMap';
    obj.limit = 10;
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, pdfMap: obj.pdfMap });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getCSVLoads(params) {
  let url = 'tms/getTMSLoadsByAppointment';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  // url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllFleetManagers(params) {
  let url = 'carrier/getFleetManagers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function handleColumnMove(tableColumns) {
  let tbColumn=JSON.stringify(tableColumns)
  // setStorage('tbColumn', tbColumn);
  let url = 'user/updateUserDispatcherSorting';
  let params ={
    dispatcherColumnSorting:tbColumn
  }
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getUserColumn() {
  let url = 'user/getUserDispatcherSorting';
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getLoadByReference(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result) resolve(result.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}



export function getConsigneeListByCaller(params) {
  let url = 'tms/getConsigneeListByCaller';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        const options = []
        allDatas
          .forEach((obj) => {
            options.push({ value: obj._id, label: obj.company_name });
          })
        resolve(options);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// get Loads with lfd and Cuttoff 
export function getCarrierProblemsInfo(params) {
  const obj ={
    skip: params.skip,
    limit: params.limit,
    status: params.status
  };
  let url = params.type==='lfd' ?'carrier/getCarrierLFDInfo':'carrier/getCarrierReturnContainerInfo';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// get Loads with Empties Problems 
export function getEmptiesProblemLoads() {
  const obj ={
    timeZone:getStorage('timeZone')
  };
  let url = 'myapp/getProblemContainers';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// Get Loads(problem) Counts
export function getContainerInformationCount() {
  const params = {
    "status":["AVAILABLE", "PENDING", "DEPARTED", "DISPATCHED","CHASSISPICK_ARRIVED","CHASSISPICK_DEPARTED", "PULLCONTAINER_ARRIVED","PULLCONTAINER_DEPARTED","DROPCONTAINER_ARRIVED","DROPCONTAINER_DEPARTED","HOOKCONTAINER_ARRIVED","HOOKCONTAINER_DEPARTED","DELIVERLOAD_ARRIVED","DELIVERLOAD_DEPARTED","RETURNCONTAINER_ARRIVED","RETURNCONTAINER_DEPARTED","CHASSISTERMINATION_ARRIVED", "CHASSISTERMINATION_DEPARTED"],
    "isCount":true,
    // "limit":0,
    // "skip":0  
  }
  let url = 'tms/getContainerInformationCount';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result) resolve(result.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function updateUserItinerarySorting(params) {
  let url = 'user/updateUserItinerarySorting';
  return new Promise((resolve, reject) => {
    HTTP("post", url, {itineraryColumnSorting:params}, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUserItinerarySorting(params) {
  let url = 'user/getUserItinerarySorting';
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBobtail(params) {
  let url = 'tms/getBobTail';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, {}, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = result.data.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCitiesAndStates(params) {
  let url = 'tms/getCitiesAndStates';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result){
          let { cities, states, countries } = result.data.data;
          cities = cities && cities.map((city) => ({ label: city._id, value: city._id }));
          states = states && states.map((state) => ({ label: state._id, value: state._id }));
          countries = countries && countries.map((country) => ({ label: country._id, value: country._id }));

          resolve({ cities: _.uniqBy(cities, 'label'), state: _.uniqBy(states, 'label'), country:  _.uniqBy(countries, 'label')})
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDesyncedQbLoadsCount(params) {
  let url = "tms/getDesyncedQbLoads";
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const getTimeSlots = (params) => (dispatch) => {
  let url = `tms/apptSlots`;
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;

  return new Promise((resolve, reject) => {
    dispatch({
      type: "GET_TIMESLOTS_START"
    });
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "GET_TIMESLOTS_SUCCESS",
          payload: response.data
        })
        resolve("success")
      })
      .catch((error) => {
        dispatch({
          type: "GET_TIMESLOTS_FAILED"
        })
        console.log(error, "error")
        reject(error);
      });
  })
}

export const bookPickupAppointment = (payload) => (dispatch) => {
  let appointmentType = "bookAppointment";
  let url = `tms/${appointmentType}`;

  return new Promise((resolve, reject) => {
    dispatch({
      type: "BOOK_APPOINTMENT_START"
    });
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "BOOK_APPOINTMENT_SUCCESS",
          payload: response.data
        })
        resolve("success")
      })
      .catch((error) => {
        dispatch({
          type: "BOOK_APPOINTMENT_FAILED"
        });
        console.log(error, "error")
        reject(error);
      });
  })
}

export const getApptSlots = (params) => (dispatch) => {
  let url = `tms/totalApptSlots`;
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    dispatch({
      type: "GET_TOTAL_APPT_SLOTS_START"
    });
    HTTP('get', url, null, {
      'authorization': getStorage('token')
    })
      .then((response) => {
        dispatch({
          type: "GET_TOTAL_APPT_SLOTS_SUCCESS",
          payload: response.data
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAppSettings = ()  => (dispatch) =>  {
  let url = `myapp/getSetting`;
  return new Promise((resolve, reject) => {
    HTTP('post', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        let payload= response && response.data && response.data.data && response.data.data.message && response.data.data.message.myappFeature?response.data.data.message.myappFeature:[];
        dispatch({
          type: "GET_APPSETTINGS_SUCCESS",
          payload: payload
        })
        if(payload.includes("EMPTY_RETURN_LOCATIONS")){
          dispatch(getAllEmpties())
        }
        resolve(response)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllCustomers(params) {
  let obj = {}
  if (params) {
    obj = { _id: params }
  }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        let customers = []
        allDatas.forEach((obj) => {
          customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, allInfos: obj });
        });
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export const getAllEmpties = ()  => (dispatch) =>  {
  let timeZone=getStorage('timeZone')
  let obj = {
    emptiesCriteria: {
      terminal: timeZone=="America/New_York"?"NY":"LA",
      isTomorrow: false,
    },
  };
  let url = 'myapp/getAllEmpty';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        let payload= response && response.data && response.data.data?response.data.data:null;
        dispatch({
          type: "GET_ALLEMPTY_SUCCESS",
          payload: payload
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDoubleMoveLoadCardCount(params) {
  let url = 'tms/getDoubleMoveLoadCardCount';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}