import { UPDATE_TYPE } from './constants';
import { setStorage, getStorage, removeItem, changeTheme, getCarrierId,getCarrierByUserId, toastr } from '../../services/Common.services';
import { HTTP } from '../../services/Http.service';
import moment from 'moment'

import { asyncActionNames, buildAsyncActions } from '../Common/GlobalActionCreators';
import { getMainTheme } from '../../pages/tms/Profile/actionCreators';
import themesOptions from '../../pages/tms/Profile/themeOptions';

// creating actions name and action creators
const actionNames = asyncActionNames('LOGIN');
const actionCreators = buildAsyncActions(actionNames);

export function updateUserType(payload) {
  return {
    type: UPDATE_TYPE,
    payload
  }
};

export function processForm(formData, isTms) {
  return function (dispatch) {
    // we can dispatch progress
    return new Promise((resolve, reject) => {
      dispatch(actionCreators.progress());
      HTTP('post', 'login', formData)
        .then((result) => {
          if (result.data && result.data.statusCode == 200 && result.data.message == "Success") {
            const user = result.data.data.user;
            HTTP('get', 'user/getTimezone?userId=' + user._id)
              .then((timeZoneInfo) => {
                let timeZone = null;
                if (timeZoneInfo.data.data.timeZone) {
                  timeZone = timeZoneInfo.data.data.timeZone
                }
                setStorage('timeZone', timeZone);
                // moment.tz.setDefault(timeZone);
              })
            setStorage('loggedInUser', JSON.stringify(user));
            setStorage('token', 'Bearer ' + result.data.data.token);
            setStorage('currentUserRole', result.data.data.user.role);
            setStorage('approvedByShipper', JSON.stringify(result.data.data.user.approvedBy ? result.data.data.user.approvedBy : []));
            setStorage('currentUserID', result.data.data.user._id);
            if (result.data.data.user.shipper) {
              setStorage('shipperCompanyName', result.data.data.user.shipper.company_name);
            }
            if (result.data.data.user.acl) {
              dispatch({
                type: "aclPermission",
                acl: result.data.data.user.acl
              });
            }
            if (!result.data.data.user.isEmailVerified) {
              setStorage('varified', false);
            }
            if (isTms) {
              dispatch({
                type: "UPDATE_TYPE",
                payload: 'tms'
              });
            }

            if (formData.email && !isTms) {
              dispatch({
                type: "UPDATE_TYPE",
                payload: result.data.data.user.role
              });
              setStorage('varified', true);
              if (getStorage('unverfied') != null) {
                removeItem('unverfied');
              }
            }
            dispatch(actionCreators.success(user));
            if (result.data.data.user.isMobileVerified && result.data.data.user.role == 'driver') {
              dispatch({
                type: "UPDATE_TYPE",
                payload: result.data.data.user.role
              });
            }
            let carrierId=getCarrierId();
            getCarrierByUserId(carrierId)
              .then((res) => {
                if(res){
                  setStorage("carrierDetail", JSON.stringify(res.data.data));
                }
              })
              .catch((err) =>{
                console.log(err)
              });
            getMainTheme({carrierId:carrierId})
              .then((res) => {
                if(res){
                  setStorage("themeSetting", JSON.stringify(res));
                  res.themeName && changeTheme(themesOptions.find(theme=>theme.name===res.themeName));
                  resolve(result.data.data);
                }
              })
              .catch((err) =>{
                // console.log(err)
                resolve(result.data.data);
              });
          }
          
          // resolve(result.data.data);
        }).catch((error) => {
          reject(false);
          if (error.response) {
            toastr.show(error.response.data.message, 'error');
          }
        })
    })
  }
}

export function updateUserTypeGlobal() {
  return function (dispatch) {
    dispatch({
      type: "UPDATE_TYPE",
      payload: getStorage('varified') === 'true' ? getStorage('currentUserRole') : null
    });
  }
}

export function destroyLoginSession() {
  return function (dispatch) {
    window.Raven.setUserContext()
    dispatch(actionCreators.success(''));
  }
}

export function registration(payload) {
  return function (dispatch) {
    return HTTP('post', 'preRegister', payload)
  }
}

export function getUserTimeZone(id) {
  let url = 'user/getTimezone?userId=' + id
  return new Promise((resolve, reject) => {
    HTTP('GET', url)
    .then((result) => {
      let timeZone = '';
      if (result && result.data && result.data.data && result.data.data.timeZone) {
        timeZone = result.data.data.timeZone
      }
      setStorage('timeZone', timeZone);
      // moment.tz.setDefault(timeZone);
    })
  })
}

export function auth0ProcessForm(formData, isTms) {
  delete formData.email
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(actionCreators.progress());
      HTTP('post', 'auth0Login', formData,{authorization: formData.token})
        .then((result) => {
          if (result.data && result.data.statusCode == 200 && result.data.message == "Success") {
            const user = result.data.data.user;
            HTTP('get', 'user/getTimezone?userId=' + user._id)
              .then((timeZoneInfo) => {
                let timeZone = null;
                if (timeZoneInfo.data.data.timeZone) {
                  timeZone = timeZoneInfo.data.data.timeZone
                }
                setStorage('timeZone', timeZone);
                moment.tz.setDefault(timeZone);
              })
            setStorage('loggedInUser', JSON.stringify(user));
            setStorage('token', 'Bearer ' + result.data.data.token);
            setStorage('currentUserRole', result.data.data.user.role);
            setStorage('approvedByShipper', JSON.stringify(result.data.data.user.approvedBy ? result.data.data.user.approvedBy : []));
            setStorage('currentUserID', result.data.data.user._id);
            if (result.data.data.user.shipper) {
              setStorage('shipperCompanyName', result.data.data.user.shipper.company_name);
            }
            if (result.data.data.user.acl) {
              dispatch({
                type: "aclPermission",
                acl: result.data.data.user.acl
              });
            }
            if (!result.data.data.user.isEmailVerified) {
              setStorage('varified', false);
            }
            if (isTms) {
              dispatch({
                type: "UPDATE_TYPE",
                payload: 'tms'
              });
            }

            if (formData.email && !isTms) {
              dispatch({
                type: "UPDATE_TYPE",
                payload: result.data.data.user.role
              });
              setStorage('varified', true);
              if (getStorage('unverfied') != null) {
                removeItem('unverfied');
              }
            }
            dispatch(actionCreators.success(user));
            if (result.data.data.user.isMobileVerified && result.data.data.user.role == 'driver') {
              dispatch({
                type: "UPDATE_TYPE",
                payload: result.data.data.user.role
              });
            }
            let carrierId=getCarrierId();
            getCarrierByUserId(carrierId)
              .then((res) => {
                if(res){
                  setStorage("carrierDetail", JSON.stringify(res.data.data));
                }
              })
              .catch((err) =>{
                console.log(err)
              });
            getMainTheme({carrierId:carrierId})
              .then((res) => {
                if(res){
                  setStorage("themeSetting", JSON.stringify(res));
                  res.themeName && changeTheme(themesOptions.find(theme=>theme.name===res.themeName));
                  resolve(result.data.data);
                }
              })
              .catch((err) =>{
                // console.log(err)
                resolve(result.data.data);
              });
          }
          
          // resolve(result.data.data);
        }).catch((error) => {
          reject(false);
          if (error.response) {
            toastr.show(error.response.data.message, 'error');
          }
        })
    })
  }
}
