import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js";
import _ from 'lodash';
import mapboxgl from "mapbox-gl";
import configuration from '../../../../../../config';
import { useRef } from 'react';


mapboxgl.accessToken = configuration.map_box_api_key;

const GeofenceCustomerComponent = (props) => {

    const [zoom, setZoom] = useState(props.Selected_customer?.geofence?.zoom ? props.Selected_customer.geofence.zoom : 10.91)
    const [lat, setLat] = useState(props.Selected_customer?.geofence?.lat ? props.Selected_customer.geofence.lat : 40.717718);
    const [lng, setLng] = useState(props.Selected_customer.geofence.lng ? props.Selected_customer.geofence.lng : -74.0029393);
    const [polygonData, setPolygonData] = useState(props?.Selected_customer?.geofence?.polygonData ? props.Selected_customer.geofence.polygonData : []);

     const mapContainer = useRef(null);
        const map = useRef(null);
        const draw = useRef(null);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/satellite-streets-v11",
            center: [lng, lat],
            zoom: zoom
        });
        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
        });
        const { latValue, lngValue } = props.Selected_customer;

        new mapboxgl.Marker({
            color: "#3b93f1",
            draggable: true,
        })
            .setLngLat([lngValue || lng, latValue || lat])
            .addTo(map.current);

        map.current.addControl(draw.current);
        map.current.on("load", () => {
            map.current.resize();
            drawPolygon(polygonData);
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });



    const drawPolygon = (points) => {
        if (points.length > 0) {
            draw.current.add({ type: "Polygon", coordinates: points });
        }
    }

    const updateArea = (e) => {
        let data = draw.current.getAll();
        let polygonDataValue = [];
        if (data.features.length === 0) {
            setPolygonData(polygonDataValue);
            return;
        }
        data.features.length > 1 && draw.current.delete(data.features.shift().id);
        polygonDataValue = data.features[0].geometry.coordinates;
        setPolygonData(polygonDataValue);
        setLat(map.current.getCenter().lat.toFixed(4));
        setLng(map.current.getCenter().lng.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
       
    }
    return (
        <div>
            <React.Fragment>
                <div
                    className="pb-3 position-relative"
                    style={{
                        height: "400px",
                        display: props.tab == 3 ? "" : "none",
                    }}
                >
                    <div
                        id="map"
                        ref={mapContainer}
                        style={{ height: "100%", minHeight: "400px" }}
                        className="mapContainer"
                    ></div>
                </div>
            </React.Fragment>
        </div>
    )
}

export default GeofenceCustomerComponent