import React, { useState } from "react";
import { useRef } from "react";
import useOnClickOutside from "../../../../NewDispatcher/DispatcherTable/CustomHooks/useOnClickOutside";
export const TextInputField = ({
  type,
  name,
  className,
  onChange,
  value,
  onBlur,
  placeholder,
  autoComplete,
  disabled,
  required
}) => {
  const customerInput = useRef();
  useOnClickOutside(customerInput, () => {
    if (required && !value && !customerInput.current.value) {
      const Element = document.getElementById(name);
      Element?.classList.remove("background-failure");
      Element?.classList.add("background-error");
      Element.style.transition = "background .4s ease-out";

      setTimeout(() => {
        Element.classList.remove("background-error");
        {required && Element?.classList.add("background-failure");}
      }, 400);
    }
  }); 

  return (
    <>
      <input
        type={type}
        name={name}
        class={className}
        onChange={(e) => {
          onChange(e, name);
        }}
        id={name}
        value={value}
        onBlur={(e) => {
          onBlur(name, e.target.value);
        }}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
        ref={customerInput}
      />
    </>
  );
};
