import { getStorage, HTTP } from "../../../../services";
import { makeOptionsForSelect } from "../../../../services/Common.services";
import * as ToQuery from '../../../../Components/Common/jsonToQueryParams';

export function updateLoad(params) {
	const payload = {...params}
	let url = 'tms/updateLoadTextFields';

	return new Promise((resolve, reject) => {
	  HTTP('post', url, payload, {
		'authorization': getStorage('token'),
	  })
		.then((result) => {
		  resolve("sucess");
		})
		.catch((error) => {
		  reject(error);
		});
	})
  }

export function getTMSUsers(obj) {
	let url = 'carrier/getTMSCustomers';
	url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
	return new Promise((resolve, reject) => {
	  	HTTP('get', url, null, {
			'authorization': getStorage('token'),
		})
		.then((result) => {
			const customers = makeOptionsForSelect(
				result.data.data,
				"company_name",
				"address.address",
				"_id"
			  );
		  	resolve(customers);
		})
		.catch((error) => {
		  	reject(error);
		});
	})
}
  export function assignLoadToDriver(params) {
	let url = 'tms/assignLoadToDriver';
	  return new Promise((resolve, reject) => {
		HTTP('post', url, params, {
		  'authorization': getStorage('token'),
		})
		  .then((result) => {
			if (result)
			  resolve(result.data.data)
		  })
		  .catch((error) => {
			reject(error);
		  });
	  })
	
  }
  export function makeLoadStartabelToDriver(params) {
	let url = 'tms/makeLoadStartabelToDriver';
	  return new Promise((resolve, reject) => {
		HTTP('post', url, params, {
		  'authorization': getStorage('token'),
		})
		  .then((result) => {
			if (result)
			  resolve(result)
		  })
		  .catch((error) => {
			reject(error);
		  });
	  })
  }
  export function hardRemoveDriver(params) {
	let url = 'tms/hardRemoveDriver';
	  return new Promise((resolve, reject) => {
		HTTP('post', url, params, {
		  'authorization': getStorage('token'),
		})
		  .then((result) => {
			if (result)
			  resolve(result)
		  })
		  .catch((error) => {
			reject(error);
		  });
	  })
	}
	export function editLoad(params) {
		let url = 'tms/editLoad';
			return new Promise((resolve, reject) => {
				HTTP('post', url, params, {
					'authorization': getStorage('token'),
				})
					.then((result) => {
						if (result)
							resolve(result)
					})
					.catch((error) => {
						reject(error);
					});
			})
		
	}
	export function getLoadDetail(reference_number) {
		let url = 'tms/getLoadDetail?reference_number=' + reference_number;
		  return new Promise((resolve, reject) => {
			HTTP('GET', url, null, {
			  'authorization': getStorage('token'),
			})
			  .then((result) => {
				resolve(result.data.data)
			  })
			  .catch((error) => {
				reject(error);
			  });
		  })
	  }

	export function changeEmptyOrigin(params) {
		let url = 'myapp/changeEmptyOrigin';
		return new Promise((resolve, reject) => {
			HTTP('post', url, params, {
				'authorization': getStorage('token'),
			})
				.then((result) => {
					if (result)
						resolve(result)
				})
				.catch((error) => {
					reject(error);
				});
		})
		
	}
