import React, { Component } from "react";
import moment from "moment";
import { differnceFinder } from "../../../../services/index";
class TabAudit extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="tab" id="tab-document" ref="inner">
        <div className="table mb-20">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
            {
              this.props.audits && this.props.audits.map((d, key) => {
                let description;
                let url;
                let chassisNo;
                if (["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)) {
                  description = d.data.document.type;
                  url = <a href={d.data.document.url}>Open Document...</a>
                }
                if (d.type === "ADD") {
                  description = "Chassis No "
                  chassisNo = <b>{d.data.chassisNo}</b>
                }
                if (d.type === 'UPDATE') {
                  let data
                  if(key!==(this.props.audits.length-1) && this.props.audits[key+1].userId){
                    data = this.props.audits[key+1].data
                  }
                  const additionalInfo = d.data
                  let dateFormat = ['AID', 'ITD', 'registration', 'inspection', 'insurance', 'preventativeMaintance'];
                  let differanceDescription = differnceFinder(data, additionalInfo);
                  
                  description = Object.keys(differanceDescription || [])
                    .filter((key1) => differanceDescription[key1] && typeof differanceDescription[key1] !== 'object')
                    .map((key1) => {
                      if(dateFormat.includes(key1)) {
                        return <p>{key1} changed to <b>{moment(differanceDescription[key1]).format('ddd MMM DD YYYY')}</b></p>
                      } else {
                        return <p>{key1} changed to <b>{differanceDescription[key1]}</b></p>
                      }
                    })
                  
                } 
                return (
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="avatar-circle avatar-xs mr-1">{(d.userId.name.charAt(0))}</span>
                        {d.userId.name}
                      </div>
                    </td>
                    <td>
                      <span className="badge badge-gray-100">
                      {d.type.replace(/_/g, " ")}
                      </span>
                    </td>
                    <td>
                      <div>{moment(d.createdAt).format("MM/DD/YY")}</div>
                      <div className="text-muted">{moment(d.createdAt).format("hh:mm a")}</div>
                    </td>
                    <td>
                      {description}{chassisNo} {url}
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TabAudit;
