import React, { PureComponent, Component } from "react";
import ReactTooltip from "react-tooltip";
import {
    IconAngleArrowDown,
    IconMinus,
} from "../../../Components/Common/Icons/index";
import {
    convertUnit,
} from "../../../services/Common.services";
import SelectCustomComponent from "../SelectComponent";
import NewPriceInput from "../../../Components/Common/PriceInput/NewPriceInput";
import CustomSelect from "../../../Components/Common/CustomSelect/index";
import { newSmallSelectStyle } from "../../../assets/js/select-style";
class ExpenseComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEdited: false,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.state.isEdited &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.setState({ isEdited: false });
            if(this.state.selectedCell) return 
            this.props.updateRowExpense(this.props.index);
        }
    }

    onEnter(e,index) {
        if(e.keyCode === 13||e.keyCode === 9) {
            e?.target?.blur()
            this.props.unselectCell()
            if(!this.state.isEdited)return
            this.props.updateRowExpense(index);
        }
    }
    render() {
        let { index, charges, selectedCell,unselectCell, nameColor, expenseChanges,chargeValue,chargeCodeList, item, name, disabled, conflictedCharge, showSave, addExpense, DeletePriceRow,selectCell, keyPressOnNumberInput, redirectToRates, isAPICall, isSuccess, updateRowExpense,rowIndex} = this.props;
        let success = rowIndex === index && isSuccess;
        return(
            <tr 
                key={index} 
                style={{opacity: `${addExpense ? '0.2' : ""}`, transition: 'background .4s ease-out'}}
                className={`cell-${index}-1 select-custom-input ${success ? 'background-success' : ''} ${addExpense ? "disabled-pointer-events":""}`}
                onKeyDown={(e) => { this.setState({ isEdited: false }); this.onEnter(e, index); }}
                ref={this.setWrapperRef}
            >
                <td className={`${disabled ? "disable-input" : ""} px-1 pl-2
                            ${selectedCell === `cell-${index}-1` ? 'newdriverrow td-clicked' : ""}
                            `}
                    style={{ transition: 'background .4s ease-out' }}
                    onClick={() => selectCell(`cell-${index}-1`)}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        {chargeValue?.chargeName.toUpperCase() || <span className="text-muted">Select Status</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-1` &&
                            <SelectCustomComponent
                                name='charges'
                                searchable={true}
                                refClassName={`cell-${index}-1`}
                                listData={charges}
                                keyName="chargeName"
                                onSelect={(name, value) => {
                                    expenseChanges(index, {
                                        target: { value: value.value, name: "name" },
                                    })
                                    updateRowExpense(index);
                                    unselectCell()
                                }}
                                unselectCell={unselectCell}
                            />}
                    </div>
                </td>
                <td className={`${disabled ? "disable-input":''} px-0
                 ${selectedCell === `cell-${index}-2` ? 'newdriverrow td-clicked':""}
                `}
                  onClick={() => selectCell(`cell-${index}-2`)}>
                    <div className="custom-select-border">
                    <input
                        type="text"
                        className="form-control border-0"
                        name="description"
                        value={item.description || ""}
                        onChange={(e) => {
                            this.setState({ isEdited: true });
                            expenseChanges(index, e)
                        }}
                        placeholder="Add description..."
                        disabled={disabled}
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                        />
                    </div>
                </td>
                {/* {item.name === "Fuel" ? (
                    <td className={`${disabled && "disable-input"} px-0 ${selectedCell === `cell-${index}-3` && 'newdriverrow td-clicked'}`}
                    onClick={() => selectCell(`cell-${index}-3`)}
                    >
                    <div className={`d-flex    custom-select-border justify-content-between`}>
                    <div className="d-flex align-items-center justify-content-center w-100">
                            <input
                                className="form-control border-0 text-right"
                                type="number"
                                disabled={disabled}
                                name="amount"
                                min={0}
                                value={item.amount || ""}
                                onChange={(e) => {
                                    this.setState({ isEdited: true });
                                    expenseChanges(index, e);
                                }}
                                autoComplete="off"
                                onBlur={()=>unselectCell()}
                                onFocus={(e)=>e.target.select()}
                            />
                        </div>
                        <div className="mr-1 d-flex w-100 custom-select-border justify-content-center price-component">
                            <CustomSelect
                                size="small"
                                placement="right"
                                placeholder="Select..."
                                style={newSmallSelectStyle}
                                options={[
                                    {
                                        value: "fixed",
                                        label: "$",
                                    },
                                    {
                                        value: "percentage",
                                        label: "%",
                                    },
                                ]}
                                value={
                                    item.type && item.type === "fixed"
                                        ? {
                                            value: "fixed",
                                            label: "$",
                                        }
                                        : {
                                            value: "percentage",
                                            label: "%",
                                        }
                                }
                                onChange={({ value }) => {
                                    this.setState({ isEdited: true });
                                    expenseChanges(index, {
                                        target: {
                                        value,
                                        name: "type",
                                        },
                                    })
                                    unselectCell()
                                }}
                                isSearchable={false}
                                onBlur={()=>unselectCell()}
                            />
                        </div>
                        <div className="d-flex w-100">
                            <input
                                placeholder= "0.00"
                                min={0}
                                onKeyDown={(e) => {
                                    keyPressOnNumberInput(e);
                                }}
                                className="form-control border-0 text-right"
                                type="number"
                                disabled={disabled}
                                name="finalAmount"
                                value={item.finalAmount || ""}
                                onChange={(e) => {
                                    this.setState({ isEdited: true });
                                    expenseChanges(index, e)
                                }}
                                autoComplete="off"
                                onBlur={()=>unselectCell()}
                            />
                        </div>
                    </div>
                    </td>
                ) : ( */}
                    <td className={`${disabled ? "disable-input":""} px-0 ${selectedCell === `cell-${index}-3` ? 'newdriverrow td-clicked':""}`}
                        onClick={() => selectCell(`cell-${index}-3`)}>
                        <div className="custom-select-border">
                            <span data-tip data-for={`discount${index}`}>
                                <input
                                    min={0}
                                    placeholder="0.00"
                                    type="number"
                                    className={`form-control text-right ${conflictedCharge
                                        ? "input-border-red"
                                        : (item.discountApplied ? "border-2 border-primary rounded":"")} border-0`}
                                    disabled={disabled}
                                    name="finalAmount"
                                    value={item.finalAmount || ""}
                                    onChange={(e) => {
                                        this.setState({ isEdited: true });
                                        expenseChanges(index, e)
                                    }}
                                    autoComplete="off"
                                    onBlur={() => unselectCell()}
                                />
                            </span>
                            {item.discountApplied &&
                                <ReactTooltip id={`discount${index}`}>
                                    <span>A discount has been applied</span>
                                </ReactTooltip>
                            }
                        </div>
                    </td>
                {/* )} */}
                <td className={`${disabled && "disable-input"} px-0 ${selectedCell === `cell-${index}-4` && 'newdriverrow td-clicked'}`}
                    onClick={() => selectCell(`cell-${index}-4`)}
                >
                    <div className="custom-select-border">
                        <NewPriceInput
                        name="unit"
                        value={item.unit}
                        onChange={(val) => {
                            this.setState({ isEdited: true });
                            expenseChanges(index, {
                                target: {
                                value: val,
                                name: "unit",
                                },
                            });
                        }}
                        disabled={disabled}
                        onBlur={()=>unselectCell()}
                    />
                    </div>
                </td>
    
                <td className={`option-box-${index} ${disabled ? "disable-input":""} px-1 pl-2
                  ${selectedCell===`cell-${index}-5` ? 'newdriverrow td-clicked':""}
                `}
                    onClick={() => selectCell(`cell-${index}-5`)}>
                    <div className="d-flex justify-content-between align-items-center">
                        {item?.perType || <span className="text-muted">Select...</span>}
                        <IconAngleArrowDown className='' />
                        {selectedCell === `cell-${index}-5` &&
                            <SelectCustomComponent
                                name='perType'
                                searchable={true}
                                refClassName={`option-box-${index}`}
                                listData={options}
                                keyName="label"
                                onSelect={(name, i) => {
                                    expenseChanges(index, {
                                        target: {
                                        value:i.value,
                                        name: "perType",
                                        },
                                    })
                                    updateRowExpense(index);
                                    unselectCell()
                                }
                                }
                                unselectCell={unselectCell}
                            />}
                    </div>
                </td>
                <td className={`${((!item.perType && !success) || disabled) ? 'disable-input' : ""} px-0
                    ${selectedCell === `cell-${index}-6` ? 'newdriverrow td-clicked' : ""}`}
                    onClick={() => selectCell(`cell-${index}-6`)}>
                    <div className="custom-select-border">
                        <input
                            min={0}
                            type="number"
                            className="form-control text-right border-0"
                            name="freeDays"
                            onKeyDown={(e) => {
                                keyPressOnNumberInput(e);
                            }}
                            disabled={!item.perType || disabled}
                            value={item.freeDays || ""}
                            onChange={(e) => {
                                this.setState({ isEdited: true });
                                item.perType && expenseChanges(index, e);
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={() => unselectCell()}
                        />
                    </div>
                </td>
    
                <td className={`${((!item.perType && !success) || disabled) ? 'disable-input':""} px-0
                 ${selectedCell===`cell-${index}-7` ? 'newdriverrow td-clicked':""}
                `}
                 onClick={() => selectCell(`cell-${index}-7`)}>
                    <div className="custom-select-border">
                    <input
                        min={0}
                        type="number"
                        className="form-control text-right border-0"
                        name="chargePerDay"
                        onKeyDown={(e) => {
                            keyPressOnNumberInput(e);
                        }}
                        disabled={!item.perType || disabled}
                        value={item.chargePerDay  || ""}
                        onChange={(e) => {
                            this.setState({ isEdited: true });
                            item.perType && expenseChanges(index, e);
                        }}
                        placeholder="0"
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                    />
                    </div>
                </td>
                {showSave && (
                    <td className={`${disabled ? "disable-input":''}`}>
                    <button
                        className="btn btn-xs btn-circle bg-soft-danger"
                        disabled={disabled}
                        onClick={() => !isAPICall && DeletePriceRow(index)}
                    >
                        <IconMinus className="text-danger" />
                    </button>
                    </td>
                )}
            </tr>
        )
    }
}
export default ExpenseComponent;

const options = [
    { value: "perday", label: "perday" },
    { value: "perhour", label: "perhour" },
    { value: "permile", label: `per${convertUnit().toLowerCase()}` },
    {
      value: "perpound",
      label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
    },
    { value: "fixed", label: "fixed" },
    { value: "percentage", label: "percentage" },
  ];

  const CHARGE_TYPES = {
    CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES'},
    DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING'},
    SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED'},
    MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED'},
    PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES'},
    ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES'},
    CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES'},
  };