import currency from "currency.js";

Number.prototype.toCurrencyObject = function (opts) {
  try {
    let currencyObject;

    if (opts) {
      currencyObject = currency(this, opts);
    } else {
      currencyObject = currency(this);
    }

    return currencyObject;
  } catch (e) {
    console.error(e);
    return null;
  }
};

Number.prototype.toCurrency = function (opts) {
  try {
    return this.toCurrencyObject(opts).format();
  } catch (e) {
    console.error(e);
    return null;
  }
};