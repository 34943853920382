import React, { useState, Component } from "react";
import moment from "moment";
import { getStorage } from "../../../services/Common.services";
import { driverGeofenceIcon, getRoutesFromDriverOrders } from "./utility";
import TrackingHistoryPlayer from "./elements/TrackingHistoryPlayer";
import { IconCapture, IconCheck, IconDownloadDark, IconShare } from "../../../Components/Common/Icons";
import LiveShareModal from "./LiveShareModal";
import ReactTooltip from "react-tooltip";
import ContainerSummary from "./elements/ContainerSummary";
import { createLegsFromDriverOrder, legTypeToHuman } from "../../../Components/Common/functions";
import { getLocationHistory } from "./actionCreators";

let timeZone = null;

class NewDriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectCard: -1,
      selectedDriverOrder: null,
      selectedLoad: null,
      showModal: false,
      liveShareReferenceNumber: null,
      documents: [],
    };
    timeZone = getStorage("timeZone");
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d sec",
        m: "%d min",
        mm: "%d min",
        h: "%d hr",
        hh: "%d hr",
        d: "%d day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years",
      },
    });
  }

  componentWillUnmount = () => {
    this.props.geofenceLocationMarkerLayer.clearLayers();
  }

  plotEntryExitPoint = async ({legIndex, load, leg}) => {
    this.props.geofenceLocationMarkerLayer.clearLayers();

    const { driverLegs } = createLegsFromDriverOrder(load?.driverOrder);
    const totalLegs = driverLegs.length;
    
    // get arrived/departed pair
    const arrivedDepartedList = [];
    let dateList = [];
    let arrivedDepartedHashMap = {};
    let nextDataDoesNotExists = false;
    
    leg.forEach((driverOrder, i) => {
      if(i === 0) {
        dateList.push(driverOrder.departed);
      } else {
        dateList.push(driverOrder.arrived);
        dateList.push(driverOrder.departed);
      }
    });
    
    if(legIndex !== totalLegs - 1) {
      dateList.push(driverLegs[legIndex + 1]?.[0]?.arrived);
    } else {
      nextDataDoesNotExists = true;
      dateList.push(moment().toISOString());
    }

    dateList.forEach((date, i) => {
      if(i % 2 == 0) {
        arrivedDepartedHashMap.startDate = date;
      } else {
        arrivedDepartedHashMap.endDate = date;
      }

      if(arrivedDepartedHashMap.startDate && arrivedDepartedHashMap.endDate) {
        arrivedDepartedList.push(JSON.parse(JSON.stringify(arrivedDepartedHashMap)));
        arrivedDepartedHashMap = {};
      }
    });

    const promiseList = [];
    arrivedDepartedList.forEach((data) => {
      promiseList.push(getLocationHistory({
        refNo: encodeURIComponent(load.reference_number),
        startDate: encodeURIComponent(data.startDate),
        endDate: encodeURIComponent(data.endDate),
      }));
    });

    if(promiseList.length > 0) {
      const results = await Promise.allSettled(promiseList);

      results?.forEach((promiseResult, i) => {
        if(promiseResult?.value?.data?.data?.length > 0) {
          console.log("Promise: ", i)
          const locations = [];
          promiseResult?.value?.data?.data?.forEach((responseData) => {
            locations.push(...(responseData?.locations ?? [] ));
          });
          const entry = locations?.[0];
          const exit = locations?.[locations?.length - 1];

          if(entry?.l?.length > 0) {
            const marker = window.L.marker([entry.l?.[0],entry.l?.[1]], {icon: driverGeofenceIcon});
            marker.addTo(this.props.geofenceLocationMarkerLayer);
          }
          
          if(exit?.l?.length > 0) {
            const marker = window.L.marker([exit.l?.[0],exit.l?.[1]], {icon: driverGeofenceIcon});
            marker.addTo(this.props.geofenceLocationMarkerLayer);
          }
        }
      });
    }
  }

  // legIndex, load, leg, driverId
  handleSelectCard = (i, load = null, leg, driverId = null) => {
    if (!this.props.disableClicks) {
      this.setState({
        // isSelectCard: this.state.isSelectCard === i ? -1 : i,
        isSelectCard: i,
      });
    }

    if (this.props.drawRouteToMap && load) {
      const routes = getRoutesFromDriverOrders(leg, driverId);
      this.props.drawRouteToMap(routes);
    }

    this.plotEntryExitPoint({legIndex: i, load: load, leg: leg, driverId: driverId});
  };
  handleShowModal = (referenceNumber) => {
    this.setState({ showModal: true, liveShareReferenceNumber: referenceNumber });
  };
  onCancel = () => {
    this.setState({ showModal: false,liveShareReferenceNumber: null });
  };
  getStatusClass = (driverOrder) => {
    if (driverOrder.arrived && driverOrder.departed) {
      return ["complete", "uil-check", "dlist--complete"];
    } else if (driverOrder.arrived && !driverOrder.departed) {
      return ["active", "uil-play", "dlist--active"];
    } else if (!driverOrder.arrived && !driverOrder.departed) {
      return ["upcoming", "uil-hourglass", "dlist--upcoming"];
    }
  };

  getTimeDifference(endTime, startTime) {
    if (startTime && endTime) {
      const startTimeObject = moment(startTime);
      const endTimeObject = moment(endTime);
      const duration = moment.duration(endTimeObject.diff(startTimeObject));

      return duration.humanize();
    }
    return null;
  }

  loads = [];
  componentWillReceiveProps(props){
    if (
      (!this.props.allLoads && props.allLoads) ||
      (this.props.allLoads &&
        this.props.allLoads != props.allLoads)
    ) {
      this.setState({ documents: props.allLoads?.documents}, ()=>{
        const length  = props.allLoads.driverOrder?.length;
        for(let i=0; i<length; i++){
          const moveDoc = [...props.allLoads?.documents].reverse().find(doc => doc.moveId === `move-${i}`)
          this.setState({ [`move-${i}`]: moveDoc })
        }
      })
    }
  }
  componentDidMount() {
    this.setState({ documents: this.props.allLoads?.documents}, ()=>{
      const length  = this.props.allLoads.driverOrder?.length;
      for(let i=0; i<length; i++){
        const moveDoc = [...this.props.allLoads?.documents].reverse().find(doc => doc.moveId === `move-${i}`)
        this.setState({ [`move-${i}`]: moveDoc })
      }
    })
  }
  render() {
    if (this.props.allLoads) {
      this.loads = [this.props.allLoads];
    } else {
      this.loads = this.props.selectedDriver.loads;
    }

    return (
      <React.Fragment>
        <LiveShareModal
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          referenceNumber={this.state.liveShareReferenceNumber}
        />
        {/* START TrackBar Design */}
        <div
          className="mapbar mapbar-big"
          style={{ height: "calc(100vh - 120px)"}}
        >
            {/* custom design end */}

            {/* New Design Implementation */}
            {
              this.loads &&
              this.loads.map((load, index) => {
                const { driverLegs } = createLegsFromDriverOrder(load.driverOrder);
                this.driverLegs = driverLegs;
                return (
                  <div className="dlist dlist--cardselected" style={{ height: "calc(100% - 10px)"}}>
                  {driverLegs.length > 0 && driverLegs.map((leg, legIndex) => {
                    let legColor = legIndex % 2 == 0 ? "blue" : "pink";
                    let firstStatus = leg.find((status) => !status.isVoidOut);
                    if (leg.length > 0) {
                      let ignoreInSS = this.state.isSelectCard !== legIndex ? "true" : null;
                      return (
                        <div
                          key={legIndex}
                          className={`dlist__card mb-1 px-20 py-15 cursor-pointer ${this.state.isSelectCard === legIndex
                            ? "dlist__card--selected"
                            : ""
                            }`}
                            data-html2canvas-ignore={ignoreInSS}
                          onClick={() => {
                            if (
                              getStorage("currentUserRole") != "customer" &&
                              getStorage("currentUserRole") !== "fleetcustomer"
                            ) {
                              if (this.props.toggleLiveLocation) {
                                this.props.toggleLiveLocation(false);
                              }
                              this.setState({
                                selectedLoad: load,
                                selectedDriverOrder: null,
                              });
                              this.selectedDriver =
                                this.props.selectedDriver &&
                                this.props.selectedDriver._id;
                              this.selectedDriver = null;
                              this.handleSelectCard(
                                legIndex,
                                load,
                                leg,
                                this.props.selectedDriver &&
                                this.props.selectedDriver._id
                              );
                            }
                          }}
                        >
                          <div className="d-flex align-items-start mb-20">
                            <div className="flex-grow-1">
                              <span className={`badge badge-${legColor} badge-rounded font-12 font-weight-500 py-2 px-10`}>Move {legIndex + 1}</span>
                            </div>
                            <div className="flex-shrink-0"></div>
                            <div className="flex-shrink-0 ml-10">
                              <div className="d-flex align-items-center text-muted">
                                <h5 className="font-14 mb-0 mr-10 font-weight-500">{firstStatus?.driverName}</h5>
                                <div className="avatar-circle undefined text-uppercase">
                                  {firstStatus.driver?.name?.charAt(0)}{firstStatus.driver?.lastName?.charAt(0)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dlist__distance pl-1">
                            {leg.map((legItem, key) => {
                              let showLoad = true;
                              let showNextLeg = false;

                              if (this.props.allLoads) {
                                showLoad = true;
                                showNextLeg = true;
                              } else if (
                                legItem.driver !== this.props.selectedDriver._id
                              ) {
                                showLoad = false;
                              }

                              if (
                                (load.driverOrder[key + 1] &&
                                  load.driverOrder[key + 1].driver) ===
                                (this.props.selectedDriver &&
                                  this.props.selectedDriver._id)
                              ) {
                                showNextLeg = true;
                              }

                              const statusClassData =
                                this.getStatusClass(legItem) &&
                                this.getStatusClass(legItem);
                              const statusClass =
                                statusClassData && statusClassData[2];
                              const statusIcon =
                                statusClassData && statusClassData[1];

                              return (
                                <div className={`dlist__distance--row d-flex ${statusClass}`} key={key}
                                  onClick={(e) => {
                                    if (
                                      getStorage("currentUserRole") != "customer" &&
                                      getStorage("currentUserRole") !==
                                        "fleetcustomer"
                                    ) {
                                      if (this.props.toggleLiveLocation) {
                                        this.props.toggleLiveLocation(false);
                                      }
                                      this.handleSelectCard(
                                        legIndex,
                                        load,
                                        leg,
                                        this.props.selectedDriver &&
                                        this.props.selectedDriver._id
                                        );
                                      // e.stopPropagation();
                                      this.setState({
                                        selectedDriverOrder: legItem,
                                        selectedLoad: load,
                                      });
            
                                      if (
                                        legItem.address &&
                                        this.props.handleDriverOrderClick
                                      ) {
                                        this.props.handleDriverOrderClick(
                                          legItem
                                        );
                                      }
            
                                      if (
                                        this.props.drawRouteToMap &&
                                        load.driverOrder[key + 1]
                                      ) {
                                        let routeId;
            
                                        if (
                                          this.props.selectedDriver &&
                                          this.props.selectedDriver._id &&
                                          load.driverOrder[key + 1].driver ===
                                            this.props.selectedDriver._id
                                        ) {
                                          routeId = `${legItem._id}-${
                                            load.driverOrder[key + 1]._id
                                          }`;
                                        } else if (
                                          this.props.selectedDriver &&
                                          this.props.selectedDriver._id &&
                                          load.driverOrder[key + 1].driver !==
                                            this.props.selectedDriver._id
                                        ) {
                                          routeId = "ignore";
                                        } else {
                                          routeId = `${legItem._id}-${
                                            load.driverOrder[key + 1]._id
                                          }`;
                                        }
            
                                        const routes = getRoutesFromDriverOrders(
                                          leg,
                                          this.props.selectedDriver &&
                                            this.props.selectedDriver._id,
                                          routeId
                                        );
                                        this.props.drawRouteToMap(routes);
                                      } else if (
                                        this.props.drawRouteToMap &&
                                        !load.driverOrder[key + 1]
                                      ) {
                                        const routes = getRoutesFromDriverOrders(
                                          leg,
                                          this.props.selectedDriver &&
                                            this.props.selectedDriver._id,
                                          "ignore"
                                        );
                                        this.props.drawRouteToMap(routes);
                                      }
                                      this.setState({
                                        isSelectCard: index,
                                      });
                                    }
                                  }}
                                >
                                  <div className="dlist__timeframe">
                                    <div className="dlist__timeframe--icon">
                                      <i className={`uil ${statusIcon}`}></i>
                                    </div>
                                    {load.driverOrder[key + 1] &&
                                      load.driverOrder[key + 1].arrived &&
                                      showNextLeg && (
                                        <div className="dlist__timeframe--miles">
                                          <div className="dlist__timeframe--miles">
                                            {load.driverOrder[key + 1] &&
                                              load.driverOrder[key + 1].distance}{" "}
                                            mi
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                  <div className={`dlist__distance--info w-100 ${(leg.length - 1) === key && 'pb-0' }`}>
                                    <div className="d-flex align-items-start pb-2">
                                      <div className="flex-grow-1">
                                        <h5 class="font-14 mb-1 font-weight-500 text-uppercase">
                                          <a href="#" class="text-dark"> {legItem.company_name && legItem.company_name}</a>
                                        </h5>
                                        <p class="text-muted mb-0">
                                          <span>
                                            {legItem.address &&
                                              legItem.address.address}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <span className="badge badge-sm text-capitalize badge-load badge-skyblue">{legItem.type && legTypeToHuman(legItem.type)}</span>
                                      </div>
                                    </div>
                                    <div className={`d-flex align-items-center justify-content-between ${(leg.length - 1) !== key ? 'pb-2' : 'pb-0'} `}>
                                      <div className="flex-grow-1">
                                        <p className="text-muted mb-0">
                                          <span>Enroute</span>
                                        </p>
                                        <div>
                                          {legItem.arrived
                                            ? `${moment(legItem.arrived)
                                              .tz(timeZone)
                                              .format("MM/DD/YY, LT")}`
                                            : ""}
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0 mx-2">
                                        <span className="badge bg-light font-weight-normal font-12">{legItem.departed &&
                                          legItem.arrived &&
                                          `${this.getTimeDifference(
                                            legItem.departed,
                                            legItem.arrived
                                          )}`}</span>
                                      </div>
                                      <div className="flex-grow-1">
                                        <p className="text-muted mb-0">
                                          <span>Arrived</span>
                                        </p>
                                        <div> {legItem.departed
                                          ? `${moment(legItem.departed)
                                            .tz(timeZone)
                                            .format("MM/DD/YY, LT")}`
                                          : ""}</div>
                                      </div>
                                    </div>
                                    {(leg.length - 1) !== key && (
                                    <div className="dlist__hr">
                                      <span className="badge badge-light font-12 dlist__hr__time font-weight-normal">
                                        {legItem.departed &&
                                          load.driverOrder[key + 1] &&
                                          load.driverOrder[key + 1].arrived &&
                                          `${this.getTimeDifference(
                                            load.driverOrder[key + 1].arrived,
                                            legItem.departed
                                          )}`}
                                      </span>
                                    </div>
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                          {(this.state.isSelectCard === legIndex && !this.state[`move-${legIndex}`] ) && <><div className="dlist__hr mb-25" data-html2canvas-ignore={true}></div><div className="d-flex align-items-center" data-html2canvas-ignore={true}>
                          <div className="flex-grow-1 mr-20">
                          <p class="text-muted mb-0 font-weight-normal font-14 line-height-20">Do you want to add this Wait Time Report to your invoice?</p>
                          </div>
                          <div className="flex-shrink-0" onClick={(e)=>this.props.takeScreenShot(e,legIndex)}>
                              <a href="#" className="bg-blue-500 rounded-3 wh-44px d-flex align-items-center justify-content-center">
                                <IconCapture />
                              </a>
                          </div>
                        </div></>} { (this.state.isSelectCard === legIndex && this.state[`move-${legIndex}`]) && <>
                        <div className="dlist__hr mb-25"></div>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 mr-20">
                          <p class="text-muted mb-0 font-weight-normal font-14 line-height-20"><IconCheck /> Wait Time Report is added to your invoice.</p>
                          </div>
                          <div className="flex-shrink-0" onClick={()=> window.open(this.state[`move-${legIndex}`]?.url)}>
                              <a href="#" className="rounded-3 wh-40px text-gray-100 border-1 d-flex align-items-center justify-content-center">
                                <IconDownloadDark className="text-dark" />
                              </a>
                          </div>
                        </div>
                        </>}
                        </div>
                      )
                    }
                  })}
                  </div>
                )
              })
            }
          {/* Old Code */}
          {/* return (
            <div
              key={Math.random()}
              className={`dlist__card mb-1 px-20 py-15 ${
                this.state.isSelectCard === index
                  ? "dlist__card--selected"
                  : ""
              }`}
              onClick={() => {
                if (
                  getStorage("currentUserRole") != "customer" &&
                  getStorage("currentUserRole") !== "fleetcustomer"
                ) {
                  if (this.props.toggleLiveLocation) {
                    this.props.toggleLiveLocation(false);
                  }
                  this.setState({
                    selectedLoad: load,
                    selectedDriverOrder: null,
                  });
                  this.selectedDriver =
                    this.props.selectedDriver &&
                    this.props.selectedDriver._id;
                  this.selectedDriver = null;
                  this.handleSelectCard(
                    index,
                    load,
                    this.props.selectedDriver &&
                      this.props.selectedDriver._id
                  );
                }
              }}
            >
              <div className="d-flex align-items-start mb-20">
                <div className="flex-grow-1">
                  <span className="badge badge-blue badge-rounded font-12 font-weight-500 py-2 px-10">Move 1</span>
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a className="text-dark">{load.reference_number}</a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>
                      {moment(load.loadAssignedDate)
                        .tz(timeZone)
                        .format("MM/DD/YY, LT")}
                    </span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">
                    {load.containerSizeName}
                  </div>
                  <div className="text-muted">{load.containerNo}</div>
                </div>
                <div className="flex-shrink-0 ml-10">
                  <button
                    className="btn btn-outline-primary p-1"
                    onClick={(e) => {
                      this.handleShowModal(load.reference_number);
                      e.stopPropagation();
                    }}
                  >
                    <IconShare />
                    <ReactTooltip id="buttonEnable" place="right">
                      <span>
                        share a public links for others to view ETA and
                        live Location
                      </span>
                    </ReactTooltip>
                  </button>
                </div>
              </div>
              <div className="dlist__distance">
                {load.driverOrder.map((driverOrder, key) => {
                  let showLoad = true;
                  let showNextLeg = false;

                  if (this.props.allLoads) {
                    showLoad = true;
                    showNextLeg = true;
                  } else if (
                    driverOrder.driver !== this.props.selectedDriver._id
                  ) {
                    showLoad = false;
                  }

                  if (
                    (load.driverOrder[key + 1] &&
                      load.driverOrder[key + 1].driver) ===
                    (this.props.selectedDriver &&
                      this.props.selectedDriver._id)
                  ) {
                    showNextLeg = true;
                  }

                  const statusClassData =
                    this.getStatusClass(driverOrder) &&
                    this.getStatusClass(driverOrder);
                  const statusClass =
                    statusClassData && statusClassData[2];
                  const statusIcon =
                    statusClassData && statusClassData[1];

                  if (!showLoad) {
                    return;
                  }

                  return (
                    <div
                      key={Math.random()}
                      className={`dlist__distance--row d-flex ${statusClass}`}
                      onClick={(e) => {
                        if (
                          getStorage("currentUserRole") != "customer" &&
                          getStorage("currentUserRole") !==
                            "fleetcustomer"
                        ) {
                          if (this.props.toggleLiveLocation) {
                            this.props.toggleLiveLocation(false);
                          }
                          e.stopPropagation();
                          this.setState({
                            selectedDriverOrder: driverOrder,
                            selectedLoad: load,
                          });

                          if (
                            driverOrder.address &&
                            this.props.handleDriverOrderClick
                          ) {
                            this.props.handleDriverOrderClick(
                              driverOrder
                            );
                          }

                          if (
                            this.props.drawRouteToMap &&
                            load.driverOrder[key + 1]
                          ) {
                            let routeId;

                            if (
                              this.props.selectedDriver &&
                              this.props.selectedDriver._id &&
                              load.driverOrder[key + 1].driver ===
                                this.props.selectedDriver._id
                            ) {
                              routeId = `${driverOrder._id}-${
                                load.driverOrder[key + 1]._id
                              }`;
                            } else if (
                              this.props.selectedDriver &&
                              this.props.selectedDriver._id &&
                              load.driverOrder[key + 1].driver !==
                                this.props.selectedDriver._id
                            ) {
                              routeId = "ignore";
                            } else {
                              routeId = `${driverOrder._id}-${
                                load.driverOrder[key + 1]._id
                              }`;
                            }

                            const routes = getRoutesFromDriverOrders(
                              load.driverOrder,
                              this.props.selectedDriver &&
                                this.props.selectedDriver._id,
                              routeId
                            );
                            this.props.drawRouteToMap(routes);
                          } else if (
                            this.props.drawRouteToMap &&
                            !load.driverOrder[key + 1]
                          ) {
                            const routes = getRoutesFromDriverOrders(
                              load.driverOrder,
                              this.props.selectedDriver &&
                                this.props.selectedDriver._id,
                              "ignore"
                            );
                            this.props.drawRouteToMap(routes);
                          }
                          this.setState({
                            isSelectCard: index,
                          });
                        }
                      }}
                    >
                      <div className="dlist__timeframe">
                        <div className="dlist__timeframe--icon">
                          <i className={`uil ${statusIcon}`}></i>
                        </div>
                        {load.driverOrder[key + 1] &&
                          load.driverOrder[key + 1].arrived &&
                          showNextLeg && (
                            <div className="dlist__timeframe--miles">
                              {load.driverOrder[key + 1] &&
                                load.driverOrder[key + 1].distance}{" "}
                              mi
                            </div>
                          )}
                      </div>
                      <div className="dlist__distance--info w-100">
                        <div className="d-flex align-items-start pb-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a className="text-dark">
                                {driverOrder.company_name &&
                                  driverOrder.company_name}
                              </a>
                            </h5>
                            <p className="text-muted mb-0">
                              <span>
                                {driverOrder.address &&
                                  driverOrder.address.address}
                              </span>
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <span className="badge badge-soft-success">
                              {driverOrder.type && driverOrder.type}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center pb-2">
                          <div className="flex-grow-1">
                            <p className="text-muted mb-0">
                              <span>Enroute</span>
                            </p>
                            <div>
                              {driverOrder.arrived
                                ? `${moment(driverOrder.arrived)
                                    .tz(timeZone)
                                    .format("MM/DD/YY, LT")}`
                                : ""}
                            </div>
                          </div>
                          <div className="flex-shrink-0 mx-2">
                            <span className="badge bg-light">
                              {driverOrder.departed &&
                                driverOrder.arrived &&
                                `${this.getTimeDifference(
                                  driverOrder.departed,
                                  driverOrder.arrived
                                )}`}
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-0">
                              <span>Arrived</span>
                            </p>
                            <div>
                              {driverOrder.departed
                                ? `${moment(driverOrder.departed)
                                    .tz(timeZone)
                                    .format("MM/DD/YY, LT")}`
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="dlist__hr">
                          <span className="badge badge-light font-12 dlist__hr__time">
                            {driverOrder.departed &&
                              load.driverOrder[key + 1] &&
                              load.driverOrder[key + 1].arrived &&
                              `${this.getTimeDifference(
                                load.driverOrder[key + 1].arrived,
                                driverOrder.departed
                              )}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ); */}
        
          {this.state.isSelectCard !== -1 && (
            <TrackingHistoryPlayer
              driverOrder={this.state.selectedDriverOrder}
              load={this.state.selectedLoad}
              driver={this.props.selectedDriver}
              mapInstance={this.props.mapInstance}
              toggleLiveLocation={this.props.toggleLiveLocation}
              liveLocationToggled={this.props.liveLocationToggled}
            />           
          )}
           {/* <ContainerSummary /> */}
        </div>
        {/* END TrackBar Design */} 
      
      </React.Fragment>
    );
  }
}

export default NewDriverDetails;
