import React from "react";
import SideBarContactInfo from "../../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/SideBarContactInfo";
import { IconInfo } from "../../../Common/Icons";
import useHover from "../../../CustomHooks/useHover";
const Port = ({ shipper }) => {
  const [referenceElementRef, referenceElement] = useHover();
  return (
    <div className="mt-10">
      <div className="d-flex justify-content-between align-items-start">
        <div className="font-medium mb-2 font-14 text-capitalize mr-1">
          {shipper.company_name}
        </div>
        <div >
          <div className={`bg-light wh-16px rounded-circle d-flex justify-content-center align-items-center ${referenceElement && "bg-gray-200 pointer"}`} ref={referenceElementRef}>
            <IconInfo className="text-gray-900 font-10 " />
          </div>
        </div>
        {referenceElement && (
          <SideBarContactInfo
            opne={referenceElement} 
            refNo={referenceElementRef.current}
            email={shipper.email}
            mobile={shipper.mobile}
            color={"gray-700"}
            cssClass={"sidebar-contact__info"}
          />
        )}
      </div>
      <div
        className={`${
          (shipper.main_contact_name || shipper.mobile || shipper.email) &&
          "text-gray-900 font-weight-normal font-12"
        }`}
      >
        {shipper.address && shipper.address.address}
      </div>
    </div>
  );
};

export default Port;
