export const loadFlags = [
    { label: 'Yes', value: true },
    { label: 'No', value: false}
  ]

export const directFactoryFlags = [
  {
    label: "Direct Pay",
    value: "Direct_Pay",
  },
  {
    label: "Factoring",
    value: "Factoring",
  },
]

export const CustomerPricingTabItems = [
  {
    id: 1,
    title: 'Rates',
    path: '/tms/load-price-settings',
  },
  {
    id: 2,
    title: 'Dynamic Pricing',
    path: '/dynamic-pricing',
  },
  {
    id: 3,
    title: 'Customer Discounts',
    path: '/tms/customer-discount',
  },
];