import React, {useState} from 'react'
import { Document, Page, pdfjs } from "react-pdf";
import FileViewer from "react-file-viewer";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper'
import { IconFileUpload } from '../../../Components/Common/Icons'

const DocUpload = ({index, data, onDocumentLoadSuccess, addPayment}) => {
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const [open, setOpen] = useState(false);
    const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top',});
    let fileName = data?.document?.split(/[?#]/)[0].split('/').pop();
    let fileType = fileName && fileName.split(".").pop();
    // fileType = fileType.split("?")[0]
  return (
    <>
      <td className="select_hover" >
        {data?.document &&
            <div
                ref={setReferenceElement}
                onMouseOver={() => setOpen(true)}
                onMouseOut={() => setOpen(false)}
                className="tooltip-label position-relative d-inline-block"
            >
                <IconFileUpload />
            </div>
            
        }
        {open && ReactDOM.createPortal(
                                <div  
                                    className="tooltip bs-tooltip-top tooltip--light-500 o-1 text-capitalize portal-tooltip load-nextDriverOrderId rounded-5 shadow-md border-5 text-white"
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                >
                                    {!addPayment && 
                                        <div
                                            style={{width: '188px', height: '176px'}}
                                        >
                                            {data?.document && (
                                                <div className="react-pdf-preview" style={{width: '100%', height: '100%'}}>
                                                    {fileType === "jpg" ||
                                                        fileType === "png" ||
                                                        fileType === "jpeg" ? (
                                                        <img
                                                            className="document-preview__image"
                                                            src={data.document}
                                                            alt={""}
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {fileType === "pdf" && (
                                                        <Document
                                                            key={data.document}
                                                            file={data.document}
                                                            onLoadSuccess={onDocumentLoadSuccess()}
                                                        >
                                                            <Page pageNumber={1} />
                                                        </Document>
                                                    )}
                                                    {fileType === "csv" || fileType === "xls" || fileType === "xlsx" ? (
                                                        <FileViewer
                                                            fileType={fileType === "csv" ? "csv" : "xlsx"}
                                                            filePath={data.document}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    }
                                    <div className="arrow" style={{ bottom: '-11px' }} ></div>
                                </div>,
                                document.querySelector('#poper-dest')
                             )}
    </td>
    </>
  )
}

export default DocUpload
