import moment from "moment";
import "moment-timezone";
import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { browserHistory, IndexRoute, Route, Router } from "react-router";
import { bindActionCreators } from "redux";
import {
  asyncActionNames,
  buildAsyncActions,
} from "./Components/Common/GlobalActionCreators";
import Loader from "./Components/Loader";
import { getUserTimeZone } from "./Components/Login/actionCreators";
import Customers from "./pages/tms/Customer";
import newModalCustomer from './pages/tms/Customer/NewCustomer/newCustomerIndex';
import { getMainTheme } from "./pages/tms/Profile/actionCreators";
import themesOptions from "./pages/tms/Profile/themeOptions";
import { changeTheme, getBasicSettings, getCarrierId, getPortMarkets, getStorage, removeItem, setStorage, clearStorage, isNewModal, allowedRoutes } from "./services/Common.services";
import { HTTP } from "./services/Http.service";
import { sendPageChangeDataToGAnalytic } from "./utils/googleAnalytics";
import config from './config';

import * as commonActionCreators from './utils/actionCreator';

const loadable = (loader) => {
  return Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });
};

const actionNames = asyncActionNames("LOGIN");
const actionCreators = buildAsyncActions(actionNames);

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acl: null,
      permissions: null,
      user: null,
      userLoggedIn: false,
      isTimedOut: false,
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.idleHandler = this.idleHandler.bind(this)
  }

  _onAction(e) {
    const timezone = getStorage("timeZone");
    setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
  }

  _onActive(e) {
  }

  checkSessionTimeoutActive() {
    let carrierDetail = JSON.parse(getStorage("carrierDetail"));
    let currentUserRole = getStorage("currentUserRole");
    const enabledRoles = ["carrier", "fleetmanager"]
    return carrierDetail && carrierDetail?.strictSessionPolicy && enabledRoles.includes(currentUserRole)
  }
  idleHandler(clientOnly) {
    const prevTime = moment(JSON.parse(getStorage("isTimedOut")));
    const timezone = getStorage("timeZone");

    const currentTime = timezone ? moment().tz(timezone) : moment()
    const diff = moment.duration(
          currentTime.diff(prevTime)
        ).asMilliseconds()

    const isTimedOut = diff > config.sessionTimeoutDuration;
    if (isTimedOut && this.checkSessionTimeoutActive()) {
        if(clientOnly) {
          clearStorage()
        }else{
          this.props.commonActions.logoutUser();
        }

    }
  }
  _onIdle() {
    this.idleHandler(false);
    if(this.idleTimer)
      this.idleTimer.reset();
  }

  isRouteAuthenticated = () => {
    const paths = [
      "/",
      "/sign-up",
      "/administrator",
      "/getresetToken",
      "/forget",
      "/verify",
    ];
    let path = "/" + window.location.pathname.split("/")[1];

    return !paths.includes(path)
  }
  componentDidMount() {
    this.idleTimer.start()
    if(this.checkSessionTimeoutActive()) {
      const timezone = getStorage("timeZone");
      setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
    } else {
      this.idleTimer.pause()
    }

    window.addEventListener("sessionTimeout", () => {
      if(!this.checkSessionTimeoutActive()) {
        this.idleTimer.pause()
      } else {
        this.idleTimer.resume()
        const timezone = getStorage("timeZone");
        setStorage("isTimedOut", JSON.stringify(timezone ? moment().tz(timezone) : moment()))
      }
    })
    window.onstorage = () => {
      const isTimedOut = JSON.parse(getStorage("sessionEnd"))
      if (
        isTimedOut === true
      ) {
        if(this.isRouteAuthenticated()){
          window.location.reload()
          removeItem("sessionEnd")
        }
        return;
      }

      const loggedInUser = JSON.parse(getStorage("loggedInUser"));
      if (!loggedInUser) {
        if(this.isRouteAuthenticated())
          browserHistory.push("/");
      }
    }
  }

  componentWillMount() {
    removeItem("sessionEnd")
    this.idleHandler(true)
    changeTheme();
    const script = document.createElement("script");
    script.src = "/lib/MovingMarker.js";
    script.async = true;

    const script2 = document.createElement("script");
    script2.src = "/lib/leaflet-routing-machine.min.js";
    script2.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    const self = this;
    const userToken = getStorage("token");
    const timeZone = getStorage("timeZone");
    if (timeZone) {
      moment().tz(getStorage("timeZone"));
    }
    this.getPortMarkets();



    if (userToken) {
      this._getThemeSetting();
      HTTP("get", "getUser", null, {
        Authorization: getStorage("token"),
      }).then((result) => {
        if (result.data.statusCode === 200) {
          let user = result.data.data.user;
          this.props.dispatch(actionCreators.success(user));
          setStorage("loggedInUser", JSON.stringify(user));
          self.setState({ user, permissions: user.permissions });
          if (!timeZone) {
            getUserTimeZone(user._id);
          }
        }
      });
      getBasicSettings();
    }
    let loggedInUser = JSON.parse(getStorage("loggedInUser"));
    if (loggedInUser) this.setState({ acl: loggedInUser.acl });
    this.unlisten = browserHistory.listen((location, action) => {
      sendPageChangeDataToGAnalytic()
      try {
        if (location && location.query && location.query.reference_number) {
          document.title = `#${location.query.reference_number} `;
        } else {
          document.title = `PortPro: Drayage TMS `;
        }
      } catch (ex) {
        document.title = `PortPro: Drayage TMS `;
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  _getThemeSetting(){
    let carrierId=getCarrierId();
    getMainTheme ({carrierId:carrierId})
      .then((res) => {
        if(res){          
          setStorage("themeSetting", JSON.stringify(res));
          res.themeName && changeTheme(themesOptions.find(theme=>theme.name===res.themeName));
        }
      })
      .catch((err) =>{
        changeTheme();
        console.log(err)
      });
  }
  getPortMarkets() {
    this.props.actions.getPortMarkets();
  }

  authorize = (roles) => {
    let self = this;
    const customerPages = [
      "/customer-shipments",
      "/loads",
      "/tms/account-receivable",
      "/tms/customer",
      "/tms/at-yard-container-loads",
      "/tms/customer-service/load",
      "/tms/customer-service/Billing",
      "/tms/report/agingChart",
      "/tms/report/report1",
      "/tms/report/report2",
      "/tms/report/report3",
      "/tms/report/report4",
      "/tms/report/revenue-report",
      "/tms/report/driver-pay-report",
      "/tms/report/driver-pay-summary-report",
      "/tms/report/lfd-report",
      "/tms/report/return-report",
      "/tms/report/revenue",
      "/tms/report/aging",
      "load-quotes",
    ];
    const customerEmployeePages = [
      "/tms/customer-service/load",
      "/tms/customer-service/Billing",
    ];
    const userToken = getStorage("token");
    const user =
      getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser"));
    const carrierDetail = JSON.parse(getStorage("carrierDetail"));
    if (userToken && user) {
      // HTTP("get", "getUser", null, {
      //   Authorization: getStorage("token")
      // }).then(result => {
      //   if (result.data.statusCode === 200) {
      this.props.dispatch(actionCreators.success(user));
      // setStorage("loggedInUser", JSON.stringify(user));
      !user.permissions.includes('container-tracking') && user.permissions.push('container-tracking')
      self.setState({ user, permissions: user.permissions });
      var allow = false;
      let permissions = this.state.permissions || [];
      let loggedInUser = user;
      if (loggedInUser) {
        permissions = loggedInUser.permissions || [];
      }

      if (roles && loggedInUser) {
        carrierDetail?.isShiposCarrier && !allowedRoutes.includes(roles[0]) && browserHistory.push("/upgrade");

        roles.map((x) => {
          if (permissions.includes(x)) {
            allow = true;
          }
          return;
        });
        if (loggedInUser.role === "carrier") allow = true;

        if (
          ["customer", "fleetcustomer"].includes(loggedInUser.role) &&
          customerPages.indexOf(window.location.pathname) >= 0
        ) {
          allow = true;
        }
        if(
          (loggedInUser.role === "fleetmanager" && loggedInUser.fleetManager.isCustomer) &&
          customerEmployeePages.indexOf(window.location.pathname) >= 0
        ){
          allow = true;
        }
        if (
          !permissions.length &&
          ["fleetmanager"].includes(loggedInUser.role)
        ) {
          if (
            loggedInUser.fleetManager &&
            !loggedInUser.fleetManager.isCustomer
          ) {
            browserHistory.push("/onboarding");
            return;
          }
        }

        if (!allow) browserHistory.push("/nopermission");
      }
      //   }
      // });
    }
  };

  setPageTitle = (title) => {
    const loggedInUser = getStorage("loggedInUser");

    const paths = [
      "/",
      "/welcome",
      "/sign-up",
      "/administrator",
      "/create-account",
      "/getresetToken",
      "/forget",
      "/verify",
      "/quotesRates",
      "/scheduleMeeting",
      "/unsubscribe"
    ];

    let path = "/" + window.location.pathname.split("/")[1];
    // if (!loggedInUser && !paths.includes(window.location.pathname)) {
    if (!loggedInUser && !paths.includes(path)) {
      browserHistory.push("/");
    }

    let val = title ? title + " | " : "";
    document.title = val + "PortPro: Drayage TMS ";
  };

  render() {
    return (
      <>
      <IdleTimer
      ref={ref => { this.idleTimer = ref }}
      element={document}
      onActive={this.onActive}
      onIdle={this.onIdle}
      onAction={this.onAction}
      debounce={250}
      // crossTab={true}
      timeout={1000 * 60} />
      <Router history={browserHistory}>
        <Route
          path="/"
          component={loadable(() => import("./Components/Home/Home"))}
          onEnter={() => {
            this.setPageTitle("Home");
          }}
        >
          <IndexRoute
            component={loadable(() => import("./Components/Login/Login"))}
            onEnter={() => {
              this.setPageTitle("Login");
            }}
          />

          {/* ----------------- signup start --------------- */}
          <Route
            exact
            path="/sign-up"
            component={loadable(() => import("./Components/SignUp/SignUp"))}
            onEnter={() => {
              this.setPageTitle("Sign Up");
            }}
          />
          <Route
            path="/unsubscribe"
            component={loadable(() => import("./pages/tms/Unsubscribe"))}
            onEnter={() => {
              this.setPageTitle("Unsubscribe");
            }}
          />

          {/* <Route
            path="/sign-up/payment-verify/:userId"
            component={loadable(() => import("./Components/SignUp/SignUp"))}
            onEnter={() => {
              this.setPageTitle("Verify Payment");
            }}
          /> */}
          <Route
            path="/verify"
            component={loadable(() => import("./Components/SignUp/VerifyOTP"))}
            onEnter={() => {
              this.setPageTitle("Verify Otp");
            }}
          />
          <Route
            path="/registerEmployee"
            component={loadable(() =>
              import("./Components/SignUp/FleetManager")
            )}
            onEnter={() => {
              this.setPageTitle("Register Employee");
            }}
          />

          {/*---------------------- Create account starts ----------------------*/}

          <Route
            exact
            path="/sign-up"
            component={loadable(() => import("./Components/SignUp/SignUp"))}
            onEnter={() => {
              this.setPageTitle("Sign Up");
            }}
          />
             <Route
            exact
            path="/create-account"
            component={loadable(() => import("./Components/CreateAccount"))}
            onEnter={() => {
              this.setPageTitle("Create Account");
            }}
          />
          <Route
            exact
            path="/scheduleMeeting"
            component={loadable(() => import("./Components/CreateAccount/MeetingCalender/MeetingCalender"))}
            onEnter={() => {
              this.setPageTitle("Schedule Meeting");
            }}
          />

          <Route
            exact
            path="/welcome"
            component={loadable(() => import("./Components/CreateAccount/WelcomeMessage/WelcomeMessage"))}
            onEnter={() => {
              this.setPageTitle("Schedule Meeting");
            }}
          />

          {/* --------------------Create account ends-------------------- */}

          {/* ----------------- signup end --------------- */}

          {/* ---------------calender start -------------------- */}
          <Route
            path="/calendar"
            component={loadable(() => import("./pages/calendar"))}
            onEnter={() => {
              this.setPageTitle("Calendar");
            }}
          />
          {/* --------------- calender end -------------- */}

          {/* ---------------onboarding start -------------------- */}
          <Route
            path="/onboarding"
            component={loadable(() => import("./pages/OnboardingNew"))}
            onEnter={() => {
              this.setPageTitle("Onboarding");
            }}
          />
          {/* --------------- onboarding end -------------- */}

          <Route
            path="/account/"
            component={loadable(() =>
              import("./pages/trucker/EditProfileTrucker")
            )}
            onEnter={() => {
              this.setPageTitle("Account");
            }}
          >
            <Route
              path="/settings/general-info"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/CompanyInfo")
              )}
              onEnter={() => {
                this.authorize(["settings_general_info"]);
                this.setPageTitle("Company Info");
              }}
            />
            <Route
              path="/settings/auth0-connect"
              component={loadable(() =>
                import("./pages/trucker/AuthConnection/index")
              )}
              onEnter={() => {
                this.authorize(["settings_general_info"]);
                this.setPageTitle("Enterprise Connection Setup");
              }}
            />
            <Route
              path="/account/email-configuration"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/EmailConfig")
              )}
              onEnter={() => {
                this.authorize(["settings_email_configuration"]);
                this.setPageTitle("Email Configuration");
              }}
            />
            <Route
              path="/account/DocumentConfig"
              component={loadable(() =>
                import("./pages/tms/DocumentConfiguration/DocumentConfig")
              )} 
              />
              <Route           
              path="/settings/developer-settings"
              component={loadable(() =>
                import("./pages/tms/Settings/DeveloperSetting")
              )}
              onEnter={() => {
                this.authorize(["settings_developer"]);
                this.setPageTitle("Developer Settings");
              }}
            />
              <Route           
              path="/settings/my-apps"
              component={loadable(() =>
                import("./pages/tms/Settings/MyApps")
              )}
              onEnter={() => {
                this.authorize(["settings_my_apps"]);
                this.setPageTitle("My Apps");
              }}
            />
            <Route
              path="/account/invoice-setup"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/InvoiceSettings")
              )}
              onEnter={() => {

                this.authorize(["settings_invoice_setup"]);
                this.setPageTitle("Invoice Setup");
              }}
            />
            <Route
              path="/account/accounting-preference"
              component={loadable(() =>
                import(
                  "./pages/trucker/EditProfileTrucker/AccountingPreference"
                )
              )}
              onEnter={() => {
                this.setPageTitle("Accounting Preference");
              }}
            />
            <Route
              path="/account/equipment-setting"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/EquipmentSetting")
              )}
              onEnter={() => {
                this.setPageTitle("Equipment Setting");
              }}
            />
            <Route
              path="/account/equipment-parameters"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/EquipmentParameters")
              )}
              onEnter={() => {
                this.authorize(["settings_equipment_params"]);
                this.setPageTitle("Equipment parameters");
              }}
            />

            <Route
              path="/account/geo-fence"
              component={loadable(() =>
                import("./pages/trucker/EditProfileTrucker/GeoFence")
              )}
              onEnter={() => {
                this.setPageTitle("Geo Fence");
              }}
            />
          </Route>

          <Route
            path="/settings"
            component={loadable(() => import("./pages/trucker/Settings"))}
            onEnter={() => {
              this.setPageTitle("Settings");
            }}
          >
            <Route
              path="/settings/users"
              component={loadable(() =>
                import("./pages/tms/carrier/PermissionRoleAssign")
              )}
              onEnter={() => {
                this.setPageTitle("Permission Role");
              }}
            />
            <Route
              path="/settings/trucks"
              component={loadable(() => import("./pages/tms/Truck"))}
              onEnter={() => {
                this.setPageTitle("Trucks");
              }}
            />
            <Route
              path="/settings/trailers"
              component={loadable(() => import("./pages/tms/Trailer"))}
              onEnter={() => {
                this.setPageTitle("Trailers");
              }}
            />
            <Route
              path="/settings/chassis"
              component={loadable(() => import("./pages/tms/AddChassis"))}
              onEnter={() => {
                this.authorize(["other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/settings/customer"
              component={loadable(() => import("./pages/tms/Customer"))}
              onEnter={() => {
                this.setPageTitle("Customer");
              }}
            />
            <Route
              path="/settings/driver-rates"
              component={loadable(() =>
                import("./pages/tms/DriverPayment/DriverPayment")
              )}
              onEnter={() => {
                this.setPageTitle("Driver Rates");
              }}
            />
            <Route
              path="/settings/Subdomain"
              component={loadable(() =>
                import("./pages/Onboarding/AddDomainName")
              )}
              onEnter={() => {
                this.setPageTitle("Subdomain");
              }}
            />
            <Route
              path="/settings/Commodity"
              component={loadable(() =>
                import("./pages/Onboarding/CommodityProfile")
              )}
              onEnter={() => {
                this.authorize(["settings_commodity"]);
                this.setPageTitle("Commodity");
              }}
            />
            <Route
              path="/settings/assignTruck"
              component={loadable(() =>
                import("./pages/tms/AssignTruck/AssignTruck")
              )}
              onEnter={() => {
                this.authorize(["other_assign_truck"]);
                this.setPageTitle("Asign Truck");
              }}
            />
            <Route
              path="/settings/customer-rates"
              component={loadable(() =>
                import("./pages/tms/Load/loadPriceSettings")
              )}
              onEnter={() => {
                this.setPageTitle("Customer Rates");
              }}
            />
            <Route
              path="/settings/dynamic-pricing"
              component={loadable(() => import("./Components/DynamicPricing"))}
              onEnter={() => {
                // this.authorize(["dynamic_dynamic_pricing"]);
                this.setPageTitle("Dynamic pricing");
              }}
            />
            <Route
              path="/settings/expense-setting"
              component={loadable(() =>
                import("./Components/GeneralExpenseSetting")
              )}
              onEnter={() => {
                this.authorize(["settings_expense"]);
                this.setPageTitle("General Expense Settings");
              }}
            />
            <Route
              path="/settings/general-setting"
              component={loadable(() =>

                import("./pages/tms/Settings/Settings")
              )}
              onEnter={() => {
                this.authorize(["settings_general"]);
                this.setPageTitle("General Settings");
              }}
            />
            <Route
              path="/settings/group-setting"
              component={loadable(() =>
                import("./pages/tms/Settings/GroupSettings")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Settings");
              }}
            />

            <Route
              path="/settings/group-setting/cities"
              component={loadable(() =>
                import("./pages/tms/Settings/GroupSettingsCity")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Group Cities Settings");
              }}
            />

            <Route
              path="/settings/email-notification-setting"
              component={loadable(() =>
                import("./pages/tms/Settings/EmailNotificationReport")
              )}
              onEnter={() => {
                this.authorize(["settings_group_settings"]);
                this.setPageTitle("Email Notification Settings");
              }}
            />
            <Route
              path="/settings/ediSettings"
              component={loadable(() => import("./pages/tms/EdiSettings"))}
              onEnter={() => {
                this.setPageTitle("EDI Settings");
              }}
            />
            <Route
              path="/settings/loadStatusColors"
              component={loadable(() => import("./pages/tms/LoadStatusColors"))}
              onEnter={() => {
                this.authorize(["settings_load_status_colors"]);
                this.setPageTitle("Loads Status Color Settings");
              }}
            />
          </Route>

          <Route
            path="/dynamic-pricing"
            component={loadable(() => import("./Components/DynamicPricing"))}
            onEnter={() => {
              // this.authorize(["other_dynamic_pricing"]);
              this.setPageTitle("Dynamic Pricing");
            }}
          />
          <Route
            path="/dynamic-expense"
            component={loadable(() => import("./Components/DynamicExpense"))}
            onEnter={() => {
              this.setPageTitle("Dynamic Expense");
            }}
          />

          <Route
            path="/dynamic-driverPay"
            component={loadable(() => import("./pages/tms/DynamicDriverPay"))}
            onEnter={() => {
              this.authorize(["other_dynamic_driver_pay"]);
              this.setPageTitle("Dynamic Driver Pay");
            }}
          />
          <Route
            path="/profile"
            component={loadable(() => import("./pages/tms/Profile"))}
            onEnter={() => {
              this.setPageTitle("Profile");
            }}
          />
          <Route
            path="/nopermission"
            component={loadable(() => import("./pages/nopermission"))}
            onEnter={() => {
              this.setPageTitle("No Permission");
            }}
          />
          <Route
            path="/new-company"
            component={loadable(() => import("./pages/tms/Profile"))}
            onEnter={() => {
              this.setPageTitle("Profile");
            }}
          />
          <Route
            path="/getresetToken"
            component={loadable(() => import("./Components/Token/Token"))}
            onEnter={() => {
              this.setPageTitle("Reset Password");
            }}
          />
          <Route
            path="/forget/:token"
            component={loadable(() => import("./Components/Forget/Forget"))}
            onEnter={() => {
              this.setPageTitle("Forget Password");
            }}
          />

          <Route
            path="/track1"
            component={loadable(() => import("./pages/trucker/NewTrack1"))}
            onEnter={() => {
              this.authorize(["tracking"]);
              this.setPageTitle("Track");
            }}
          />

          <Route
            path="/tendered"
            component={loadable(() => import("./pages/tms/Assigned/Assigned"))}
            onEnter={() => {
              this.authorize(["customer_service_tendered_loads"]);
              this.setPageTitle("Tendered Loads");
            }}
          />
          <Route
            path="/new-tendered"
            component={loadable(() => import("./pages/tms/TenderLoads"))}
            onEnter={() => {
              this.authorize(["customer_service_tendered_loads"]);
              this.setPageTitle(" New Tendered Loads");
            }}
          />
          
          <Route
            path="/tendered-online"
            component={loadable(() => import("./pages/tms/OnlineTender/OnlineTender"))}
            onEnter={() => {
              this.authorize(["customer_service_online_tendered_loads"]);
              this.setPageTitle("Online Tendered Loads");
            }}
          />
          
          <Route
            path="/notification"
            component={loadable(() =>
              import("./pages/tms/CustomerNotification")
            )}
            onEnter={() => {
              this.setPageTitle("Notifications");
            }}
          />
          <Route
            path="/loads"
            component={loadable(() =>
              import("./pages/trucker/CustomerLoad/CustomerLoad")
            )}
            onEnter={() => {
              this.setPageTitle("Loads");
            }}
          />
          {/* Customer Dropped Container */}
          <Route
            path="/tms/at-yard-container-loads"
            component={loadable(() =>
              import(
                "./pages/tms/carrier/AtYardContainerLoads/AtYardContainerLoads"
              )
            )}
            onEnter={() => {
              this.authorize([
                "dropped_containers",
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />
          {/* Customer Account recievable */}
          <Route
            path="/tms/account-receivable"
            component={loadable(() =>
              import(
                "./pages/tms/carrier/AccountReceivable/AccountReceivable"
              )
            )}
            onEnter={() => {
              this.authorize([
                "billing_account_receivable",
                "account_payable",
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />
          {/* <Route
            path="/emails"
            component={loadable(() => import("./pages/tms/Emails"))}
            onEnter={() => {
              this.setPageTitle("Emails");
            }}
          />
          <Route
            path="/emails/:email_id"
            component={loadable(() => import("./pages/tms/Emails"))}
            onEnter={() => {
              this.setPageTitle("Email");
            }}
          /> */}
          <Route
            path="/load-quotes"
            component={loadable(() =>
              import("./pages/tms/Load/LoadQuotesForCustomer")
            )}
            onEnter={() => {
              this.setPageTitle("Load Quotes");
            }}
          />
          <Route
            path="/truck-account-profile"
            component={loadable(() =>
              import("./pages/trucker/EditProfileTrucker/EditProfileTrucker")
            )}
            onEnter={() => {
              this.authorize(["account"]);
              this.setPageTitle("Truck Account profile");
            }}
          />
          <Route
            path="/driver-messaging"
            component={loadable(() =>
              import("./pages/trucker/DriverMessaging/DriverMessaging")
            )}
            onEnter={() => {
              this.authorize(["customer_service_drive_messaging"]);
              this.setPageTitle("Driver Messaging");
            }}
          />

          <Route
            path="/group-messaging"
            component={loadable(() =>
              import("./pages/trucker/DriverMessaging/GroupMessaging")
            )}
            onEnter={() => {
              // this.authorize(["customer_service_group_messaging"]);
              this.setPageTitle("Group Messaging");
            }}
          />

          <Route
            path="/employee-messaging"
            component={loadable(() =>
              import("./pages/trucker/DriverMessaging/EmployeeMessaging")
            )}
            onEnter={() => {
              // this.authorize(["customer_service_employee_messaging"]);
              this.setPageTitle("Employee Messaging");
            }}
          />

          <Route
            path="/fleetmanagement"
            component={loadable(() =>
              import("./pages/tms/FleetManagement/index")
            )}
            onEnter={() => {
              this.setPageTitle("fleet Customer");
            }}
          />

          <Route
            path="/carrieraccessmanagement"
            component={loadable(() =>
              import("./pages/tms/carrier/CarrierAccessManagement")
            )}
            onEnter={() => {
              this.authorize(["other_permission"]);
              this.setPageTitle("Carrier Access Management");
            }}
          />
          <Route
            path="/roleaccessmanagement"
            component={loadable(() =>
              import("./pages/tms/carrier/RoleAccessManagement")
            )}
            onEnter={() => {
              this.authorize(["other_role_management"]);
              this.setPageTitle("Role Access Management");
            }}
          />
          <Route
            path="/permission-role-assign"
            component={loadable(() =>
              import("./pages/tms/carrier/PermissionRoleAssign")
            )}
            onEnter={() => {
              this.authorize(["other_permission"]);
              this.setPageTitle("Assign permission Role");
            }}
          />
          <Route
              path="/container-tracking"
              component={loadable(() =>
                import("./pages/tms/ContainerTracking")
              )}
              onEnter={() => {
                this.authorize(["container-tracking"]);
                this.setPageTitle("Container Tracking");
              }}
            />

          {/* --------------------------------- Billing Section --------------------- */}
          <Route
            path="/tms/billing"
            Component={loadable(() => import("./pages/tms/Settlement/Billing"))}
            onEnter={() => {
              this.setPageTitle("Billing");
            }}
          >
            <IndexRoute
              component={loadable(() =>
                import("./pages/tms/carrier/Transcation/NewTranscations")
              )}
              onEnter={() => {
                this.authorize(["billing_driver_pay"]);
                this.setPageTitle("Driver pay");
              }}
            />
            <Route
              path="/tms/billing/driver-pay"
              component={loadable(() =>{
                if(getStorage("loggedInUser")){
                  const logUserDetails = JSON.parse(getStorage("loggedInUser"));
                  if (logUserDetails && logUserDetails.isPaymentInline) {
                    return import("./pages/tms/DriverPay/index.jsx")
                  }
                }
                return import("./pages/tms/carrier/Transcation/NewTranscations")
              })}
              onEnter={() => {
                this.authorize(["billing_driver_pay"]);
                this.setPageTitle("Driver Pay");
              }}
            />
            <Route
              path="/tms/dynamic-expense"
              component={loadable(() => import("./Components/DynamicExpense"))}
              onEnter={() => {
                this.authorize(["dynamic_dynamic_expense"]);
                this.setPageTitle("Dynamic Expense");
              }}
            />
            <Route
              path="/tms/billing/new-transcation"
              component={loadable(() =>
                import("./pages/tms/carrier/Transcation/NewTranscations")
              )}
              onEnter={() => {
                this.setPageTitle("New Transactions");
              }}
            />
            <Route
              path="/tms/billing/payment"
              component={loadable(() =>
                import("./pages/tms/QbSetting/QbPayment")
              )}
              onEnter={() => {
                this.authorize(["billing_batch_apply_payment"]);
                this.setPageTitle("Payment");
              }}
            />
            <Route
              path="/tms/billing/settlements"
              component={loadable(() =>
                import("./pages/tms/Settlement/NewDriverPay")
              )}
              onEnter={() => {
                this.authorize(["billing_driver_settlement"]);
                this.setPageTitle("Settlements");
              }}
            />
            <Route
              path="/tms/billing/new-settlements"
              component={loadable(() =>
                import("./pages/tms/Settlement/NewDriverPay")
              )}
              onEnter={() => {
                this.setPageTitle("New Settlements");
              }}
            />
            <Route
              path="/tms/billing/settlements/:id"
              component={loadable(() =>
                import("./pages/tms/Settlement/EachSettlement")
              )}
              onEnter={() => {
                this.setPageTitle("Settlement");
              }}
            />
            <Route
              path="/tms/billing/settlements/driver/:id"
              component={loadable(() =>
                import("./pages/tms/Settlement/DriverSettlement")
              )}
              onEnter={() => {
                this.setPageTitle("Driver Settlement");
              }}
            />
            <Route
              path="/tms/billing/settlements/page/:id"
              component={loadable(() =>
                import("./pages/tms/Settlement/EachSettlementPage")
              )}
              onEnter={() => {
                this.setPageTitle("Each Settlement Page");
              }}
            />
            <Route
              path="/tms/billing/settings"
              component={loadable(() =>
                import("./pages/tms/Settlement/DriverDeductionSetting")
              )}
              onEnter={() => {
                this.authorize(["billing_settlement_setting"]);
                this.setPageTitle("Billing Setting");
              }}
            />
            <Route
              path="/tms/billing/account-receivable"
              component={loadable(() =>
                import(
                  "./pages/tms/carrier/AccountReceivable/AccountReceivable"
                )
              )}
              onEnter={() => {
                this.authorize([
                  "billing_account_receivable"
                ]);
                this.setPageTitle("Accounts Receivable");
              }}
            />
            <Route
              path="/tms/billing/aging1"
              component={loadable(() =>
                import("./pages/tms/reports/AgingReportsNew")
              )}
              onEnter={() => {
                this.authorize(["reports_aging"]);
                this.setPageTitle("Aging");
              }}
            />
            <Route
              path="/tms/presidential-dashboard"
              component={loadable(() =>
                import("./pages/tms/carrier/Dashboard/PresidentialDashboard")
              )}
              onEnter={() => {
                this.setPageTitle("Presidential Dashboard");
              }}
            />
            <Route
              path="/tms/desyncedQBLoads"
              component={loadable(() =>
                import("./pages/tms/carrier/DesyncedQBLoads/index")
              )}
              onEnter={() => {
                this.authorize(["billing_problem_invoices"]);
                this.setPageTitle("Desynced QuickBooks Loads");
              }}
            />
            <Route
              path="/tms/billing/sub-customer-account-receivable"
              component={loadable(() =>
                import(
                  "./pages/tms/carrier/SubAccountReceivable/SubAccountReceivable"
                )
              )}
              onEnter={() => {
                this.authorize(["billing_subaccount_receivable"]);
                this.setPageTitle("Sub Accounts Receivable");
              }}
            />
            <Route
              path="/account/billing-configuration"
              component={loadable(() =>
                import("./pages/tms/Settings/BillingConfiguration")
              )}              
              onEnter={() => {
                this.authorize(["settings_billing_configuration"]);
                this.setPageTitle("Billing Configuration");
              }}
            />
          </Route>
          <Route
              path="/account/billing-configuration"
              component={loadable(() =>
                import("./pages/tms/Settings/BillingConfiguration")
              )}              
              onEnter={() => {
                this.authorize(["settings_billing_configuration"]);
                this.setPageTitle("Billing Configuration");
              }}
            />
           

          {/* -------------------------------- Customer Service Section ----------------------------------------- */}
          <Route
            path="/tms/customer-service"
            onEnter={() => {
              this.authorize([
                "customer_service_load_info",
                "customer_service_dashboard",
                "customer_service",
                "billing",
              ]);
              this.setPageTitle("Customer Service");
            }}
            component={loadable(() => import("./pages/tms/Dashboard"))}
          >
            <Route
              path="/tms/customer-service/load"
              component={loadable(() => import("./pages/tms/Load/Load"))}
              onEnter={() => {
                this.authorize(["customer_service_load_info"]);
                this.setPageTitle("Customer Service");
              }}
            />
            <Route
              path="/tms/customer-service/history"
              component={loadable(() => import("./pages/tms/Load/LoadHistory"))}
              onEnter={() => {
                this.setPageTitle("Customer Service");
              }}
            />
            <Route
              path="/tms/customer-service/Billing"
              onEnter={() => {
                this.authorize(["customer_service_billing"]);
                this.setPageTitle("Customer Service Billing");
              }}
              component={loadable(() =>
                import("./pages/tms/Load/BillOfLading")
              )}
            />
            <Route
              path="/tms/customer-service/Payment"
              component={loadable(() => import("./pages/tms/Load/Payment"))}
              onEnter={() => {
                this.setPageTitle("Customer Service Payment");
              }}
            />
            <Route
              path="/tms/customer-service/Documents"
              component={loadable(() => import("./pages/tms/Load/Documents"))}
              onEnter={() => {
                this.setPageTitle("Customer Service Documents");
              }}
            />
            <Route
              path="/tms/customer-service/Status"
              component={loadable(() =>
                import("./pages/tms/Load/LoadDriverOrder")
              )}
              onEnter={() => {
                this.setPageTitle("Customer Service Status");
              }}
            />
            <Route
              path="/tms/customer-service/chat"
              component={loadable(() =>
                import("./pages/tms/Load/OneLoadMessaging")
              )}
              onEnter={() => {
                this.setPageTitle("Customer Service Message");
              }}
            />
            <Route
              path="/tms/customer-service/Pay"
              component={loadable(() =>
                import("./pages/tms/carrier/Transcation/NewTranscations")
              )}
              onEnter={() => {
                this.setPageTitle("Customer Service Pay");
              }}
            />
            <Route
              path="/tms/customer-service/auditLoad"
              component={loadable(() => import("./pages/tms/Load/AuditLoad"))}
              onEnter={() => {
                this.setPageTitle("Customer Service Audit");
              }}
            />
            <Route
              path="/tms/customer-service/notes"
              component={loadable(() => import("./pages/tms/Load/Notes"))}
              onEnter={() => {
                this.setPageTitle("Customer Service Notes");
              }}
            />
          </Route>
          <Route
            path="/tms/deleted-loads"
            component={loadable(() => import("./pages/tms/Loads/DeletedLoads"))}
            onEnter={() => {
              this.authorize(["customer_service_deleted_load"]);
              this.setPageTitle("Deleted Loads");
            }}
          />
          <Route
            path="/tms/terminals"
            component={loadable(() =>
              import("./pages/tms/Terminals/Terminals")
            )}
            onEnter={() => {
              this.setPageTitle("Terminals");
            }}
          />
          <Route
            path="/tms/customer-service/calendar"
            component={loadable(() => import("./pages/tms/Load/LoadCalendar"))}
            onEnter={() => {
              this.authorize(["customer_service_load_calendar"]);
              this.setPageTitle("Load Calendar");
            }}
          />
          <Route
            path="/tms/customer-service/customer-dashboard"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerDashboard")
            )}
            onEnter={() => {
              this.setPageTitle("Customer Dashboard");
            }}
          />
          <Route
            path="/tms/customer-service/at-yard-container-loads"
            component={loadable(() =>
              import(
                "./pages/tms/carrier/AtYardContainerLoads/AtYardContainerLoads"
              )
            )}
            onEnter={() => {
              this.authorize([
                "customer_service_dropped_container"
              ]);
              this.setPageTitle("Dropped Container");
            }}
          />

          <Route
            path="/tms/customer-service/dropped-dispatch-yard-container"
            component={loadable(() =>
              import(
                "./pages/tms/carrier/DoubleDispatchDroppedYard/DoubleDispatchDroppedYard"
              )
            )}
            onEnter={() => {
              this.authorize(["dispatcher_double_moves"]);
              this.setPageTitle("Double Moves");
            }}
          />
          <Route
            path="/tms/customer-service/problem-container"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/ProblemContainer")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_problem_container"]);
              this.setPageTitle("Problem Container");
            }}
          />
          <Route
            path="/tms/customer-service/street-turns"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/StreetTurns")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_street_turns"]);
              this.setPageTitle("Street Turns");
            }}
          />
          <Route
            path="/tms/customer-service/driver-itinerary"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/DriverItinerary")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_driver_itinerary"]);
              this.setPageTitle("Driver Itinerary");
            }}
          />
          <Route
            path="/tms/customer-service/driver-planner"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/NewDriverItinerary")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_driver_itinerary"]);
              this.setPageTitle("Driver Planner");
            }}
          />
          {/* for driver details pages  */}
          <Route
            path="/tms/customer-service/driver-planner/details"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/NewDriverItinerary/driverItineraryDetails")
            )}
            onEnter={() => {
              this.authorize(["dispatcher_driver_itinerary"]);
              this.setPageTitle("Driver Planner");
            }}
          />
          <Route
            path="/tms/customer-service/chassis-validation"
            component={loadable(() =>
              import("./pages/tms/carrier/ChassisValidation")
            )}
            onEnter={() => {
              this.authorize(["billing_chassis_validation"]);
              this.setPageTitle("Chassis Audit");
            }}
          />

          {/* ---------------------------- Bulk Uplaod ----------------------------- */}
          {/* <Route
            path="/tms/customer-service/bulk-upload"
            component={loadable(() => import("./pages/tms/carrier/BulkUpload"))}
            onEnter={() => {
              this.setPageTitle("Bulk Uploads");
            }}
          /> */}
          <Route
            path="/tms/customer-service/bulk-upload1"
            component={loadable(() =>
              import("./pages/tms/carrier/BulkUploadNew")
            )}
            onEnter={() => {
              this.authorize(["customer_service_bulk_upload"]);
              this.setPageTitle("Bulk Uploads");
            }}
          />

          <Route
            path="/tms/AssignTruck"
            component={loadable(() =>
              import("./pages/tms/AssignTruck/AssignTruck")
            )}
            onEnter={() => {
              this.authorize(["other_assign_truck"]);
              this.setPageTitle("Assign Truck");
            }}
          />
          <Route
            path="/tms/customer"
            component={isNewModal() ? newModalCustomer : Customers}
            onEnter={() => {
              this.authorize(["other_profile_customer","customer"]);
              this.setPageTitle("Customers");
            }}
          >
            <Route
              path="/tms/customer/customer"
              component={loadable(() => import("./pages/tms/Customer/NewCustomer/NewCustomerModal/Components/CustomerComponent"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
            <Route
              path="/tms/customer/documents"
              component={loadable(() => import("./pages/tms/Customer/NewCustomer/NewCustomerModal/Components/DocumentCustomer"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
            <Route
              path="/tms/customer/geofence"
              component={loadable(() => import("./pages/tms/Customer/NewCustomer/NewCustomerModal/Components/GeofenceCustomerComponent"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
            <Route
              path="/tms/customer/notes"
              component={loadable(() => import("./pages/tms/Customer/NewCustomer/NewCustomerModal/Components/NotesCustomerComponent"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
            <Route
              path="/tms/customer/audit"
              component={loadable(() => import("./pages/tms/Customer/NewCustomer/NewCustomerModal/Components/AuditCustomerComponent"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
            <Route
              path="/tms/customer/employees"
              component={loadable(() => import("./pages/tms/carrier/CustomerAccessManagement"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
            <Route
              path="/tms/customer/emailnotification"
              component={loadable(() => import("./pages/tms/Customer/NewCustomer/NewCustomerModal/Components/EmailNotificationComponent"))}
              onEnter={() => {
                this.authorize(["other_profile_customer"]);
                this.setPageTitle("Customers");
              }}
            />
          </Route>
          <Route
            path="/tms/contacts"
            component={loadable(() => import("./pages/tms/Contacts"))}
            onEnter={() => {
              this.setPageTitle("Contacts");
            }}
          />
          <Route
            path="/tms/FleetCustomer"
            component={loadable(() => import("./pages/tms/FleetCustomer"))}
            onEnter={() => {
              this.authorize(["other_group_customer"]);
              this.setPageTitle("Group Customer");
            }}
          />
          <Route
            path="/tms/fleetmanagers"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerAccessManagement")
            )}
            onEnter={() => {
              this.setPageTitle("Employee List");
            }}
          />
          <Route
            path="/tms/AddChassis"
            component={ isNewModal ? loadable(() => import("./pages/tms/AddChassis/NewIndex")):loadable(() => import("./pages/tms/AddChassis/index"))}
            onEnter={() => {
              this.authorize(["safety_chassis", "other_profile_chassis"]);
              this.setPageTitle("Chassis");
            }}
          >
            <Route
              path="/tms/AddChassis/chassis"
              component={loadable(() => import("./pages/tms/AddChassis/NewTabContent/NewChassis"))}
              onEnter={() => {
                this.authorize(["safety_chassis", "other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/tms/AddChassis/documents"
              component={loadable(() => import("./pages/tms/AddChassis/NewTabContent/Documents"))}
              onEnter={() => {
                this.authorize(["safety_chassis", "other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/tms/AddChassis/history"
              component={loadable(() => import("./pages/tms/AddChassis/tabContent/history"))}
              onEnter={() => {
                this.authorize(["safety_chassis", "other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/tms/AddChassis/audit"
              component={loadable(() => import("./pages/tms/AddChassis/tabContent/audit"))}
              onEnter={() => {
                this.authorize(["safety_chassis", "other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/tms/AddChassis/audit"
              component={loadable(() => import("./pages/tms/AddChassis/tabContent/audit"))}
              onEnter={() => {
                this.authorize(["safety_chassis", "other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
            <Route
              path="/tms/AddChassis/notes"
              component={loadable(() => import("./pages/tms/AddChassis/tabContent/notes"))}
              onEnter={() => {
                this.authorize(["safety_chassis", "other_profile_chassis"]);
                this.setPageTitle("Chassis");
              }}
            />
          </Route>
          <Route
            path="/tms/Truck"
            component={loadable(() => import("./pages/tms/Truck"))}
            onEnter={() => {
              this.authorize(["safety_truck", "other_profile_truck"]);
              this.setPageTitle("Truck");
            }}
          />
          <Route
            path="/tms/fleetTruck"
            component={loadable(() => import("./pages/tms/FleetTruck"))}
            onEnter={() => {
              this.authorize(["safety_truck_owner"]);
              this.setPageTitle("Fleet Owner");
            }}
          />
          <Route
            path="/tms/Trailer"
            component={loadable(() => import("./pages/tms/Trailer"))}
            onEnter={() => {
              this.authorize(["other_profile_trailer"]);
              this.setPageTitle("Trailer");
            }}
          />
          <Route
            path="/tms/Dispatcher"
            component={loadable(() => {
              if(getStorage("carrierDetail")){
                const carrierDetail = JSON.parse(getStorage("carrierDetail"));
                if (carrierDetail && carrierDetail.newDispatcher) {
                  return import("./pages/tms/NewDispatcher/index")
                }
              }
              return import("./pages/tms/Dispatcher/Dispatcher");
            })}
            onEnter={() => {
              this.authorize(["dispatcher"]);
              this.setPageTitle("Dispatcher");
            }}
          >
            <Route
              path="/tms/Dispatcher/load"
              component={loadable(() => import("./pages/tms/Load/Load"))}
              onEnter={() => {
                this.authorize(["customer_service_load_info"]);
                this.setPageTitle("Dispatcher");
              }}
            />
            <Route
              path="/tms/Dispatcher/billing"
              onEnter={() => {
                this.authorize(["customer_service_billing"]);
                this.setPageTitle("Dispatcher Billing");
              }}
              component={loadable(() =>
                import("./pages/tms/Load/BillOfLading")
              )}
            />
            <Route
              path="/tms/Dispatcher/documents"
              component={loadable(() => import("./pages/tms/Load/Documents"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Documents");
              }}
            />
            <Route
              path="/tms/Dispatcher/payments"
              component={loadable(() => import("./pages/tms/Load/Payment"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Payment");
              }}
            />
            <Route
              path="/tms/Dispatcher/orderstatus"
              component={loadable(() =>
                import("./pages/tms/Load/LoadDriverOrder")
              )}
              onEnter={() => {
                this.setPageTitle("Dispatcher Routing");
              }}
            />
            <Route
              path="/tms/Dispatcher/driverpay"
              component={loadable(() =>
                import("./pages/tms/carrier/Transcation/NewTranscations")
                )}
              onEnter={() => {
                this.setPageTitle("Dispatcher Driver Pay");
              }}
            />
            <Route
              path="/tms/Dispatcher/tracking"
              component={loadable(() => import("./pages/tms/Load/LoadHistory"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Tracking");
              }}
            />
            <Route
              path="/tms/Dispatcher/messaging"
              component={loadable(() =>
                import("./pages/tms/Load/OneLoadMessaging")
              )}
              onEnter={() => {
                this.setPageTitle("Dispatcher Message");
              }}
            />
            
            <Route
              path="/tms/Dispatcher/audit"
              component={loadable(() => import("./pages/tms/Load/AuditLoad"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Audit");
              }}
            />
            <Route
              path="/tms/Dispatcher/notes"
              component={loadable(() => import("./pages/tms/Load/Notes"))}
              onEnter={() => {
                this.setPageTitle("Dispatcher Notes");
              }}
            />
          </Route>
          <Route
            path="/tms/ai"
            component={loadable(() =>
              import("./pages/tms/Dispatcher/Callback")
            )}
            onEnter={() => {
              this.setPageTitle("AI");
            }}
          />

          {/* <Route path='/dynamicpricesetting' component={loadable(() => import('./ConditionTest'))} /> */}
          <Route
            path="/tms/Container"
            component={loadable(() => import("./pages/tms/Container"))}
            onEnter={() => {
              this.setPageTitle("Container");
            }}
          />
          <Route
            path="/tms/Driver"
            component={loadable(() => import("./pages/tms/Driver"))}
            onEnter={() => {
              this.authorize(["other_profile_driver"]);
              this.setPageTitle("Driver");
            }}
          />
          <Route
            path="/tms/Container/Owner"
            component={loadable(() => import("./pages/tms/Container/Owner"))}
            onEnter={() => {
              this.authorize(["other_container_owner", 'other_container_size', 'other_container_type', 'other_container_free_day']);
              this.setPageTitle("Container Owner");
            }}
          />
          <Route
            path="/tms/Container/Size"
            component={loadable(() => import("./pages/tms/Container/Size"))}
            onEnter={() => {
              // this.authorize(["other_container_size"]);
              this.setPageTitle("Container Size");
            }}
          />
          <Route
            path="/tms/Container/Type"
            component={loadable(() => import("./pages/tms/Container/Type"))}
            onEnter={() => {
              // this.authorize(["other_container_type"]);
              this.setPageTitle("Container Type");
            }}
          />
          <Route
            path="/tms/Container/FreeDay"
            component={loadable(() => import("./pages/tms/Container/FreeDay"))}
            onEnter={() => {
              // this.authorize(["other_container_free_day"]);
              this.setPageTitle("Container Free day");
            }}
          />
          <Route
            path="/tms/Chassis"
            component={loadable(() => import("./pages/tms/Chassis"))}
            onEnter={() => {
              this.setPageTitle("Chassis");
            }}
          />
          <Route
            path="/tms/Chassis/Owner"
            component={loadable(() => import("./pages/tms/Chassis/Owner"))}
            onEnter={() => {
              this.authorize(["other_chassis_owner", "other_chassis_size", "other_chassis_type"]);
              this.setPageTitle("Chassis Owner");
            }}
          />
          <Route
            path="/tms/Chassis/Size"
            component={loadable(() => import("./pages/tms/Chassis/Size"))}
            onEnter={() => {
              // this.authorize(["other_chassis_size"]);
              this.setPageTitle("Chassis Size");
            }}
          />
          <Route
            path="/tms/Chassis/Type"
            component={loadable(() => import("./pages/tms/Chassis/Type"))}
            onEnter={() => {
              // this.authorize(["other_chassis_type"]);
              this.setPageTitle("Chassis Type");
            }}
          />
          <Route
            path="/tms/load-price-settings"
            component={loadable(() =>
              import("./pages/tms/Load/loadPriceSettings")
            )}
            onEnter={() => {
              this.authorize(["other_customer_rate"]);
              this.setPageTitle("Customer Rates");
            }}
          />
          <Route
            path="/tms/customer-discount"
            component={loadable(() =>
              import("./pages/tms/Load/CustomerDiscount")
            )}
            onEnter={() => {
              // this.authorize(["other_customer_discount"]);
              this.setPageTitle("Customer Discount");
            }}
          />
          <Route
            path="/tms/customer-dashboard"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerDashboard")
            )}
            onEnter={() => {

              this.authorize(["customer_service_dashboard"]);
              this.setPageTitle("Customer Dashboard");
            }}
          />
          <Route
            path="/tms/load-quote-settings"
            component={loadable(() =>
              import("./pages/tms/Load/LoadQuoteSettings")
            )}
            onEnter={() => {
              this.authorize(["customer_service_quotes"]);
              this.setPageTitle("Load Quotes");
            }}
          />

          <Route
            path="/tms/load-chat"
            component={loadable(() =>
              import("./pages/tms/carrier/LoadChat/LoadChat")
            )}
            onEnter={() => {
              // this.authorize(["customer_service_customer_messaging"]);
              this.setPageTitle("Chat Messages");
            }}
          />
          <Route
            path="/tms/payment-setting"
            component={loadable(() =>
              import("./pages/tms/DriverPayment/DriverPayment")
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Payment Setting");
            }}
          />
          <Route
            path="/tms/driver-pay-rates"
            component={loadable(() =>
              import("./pages/tms/DriverPayRates")
            )}
            onEnter={() => {
              this.authorize(["other_driver_pay_rate"]);
              this.setPageTitle("Driver Pay Rates");
            }}
          />
          <Route
            path="/tms/revoke-QuickBooks"
            component={loadable(() => import("./pages/tms/RevokePage/Revoke"))}
            onEnter={() => {
              this.setPageTitle("Revoke QuickBooks");
            }}
          />

          {/* ------------------------ Reports ------------------- */}

          <Route
            path="/tms/report/load-master-report"
            component={loadable(() =>
              import("./pages/tms/reports/LoadMasterReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_master_load-master-report"]);
              this.setPageTitle("Master Reports");
            }}
          />
          <Route
            path="/tms/report/leg-type-report"
            component={loadable(() =>
              import("./pages/tms/reports/LegTypeReport")
            )}
            onEnter={() => {
              this.authorize(["reports_leg_type"]);
              this.setPageTitle("Leg Type Reports");
            }}
          />
          <Route
            path="/tms/report/ForcastReport"
            component={loadable(() =>
              import("./pages/tms/carrier/CustomerDashboard")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_forecast_report"]);
              this.setPageTitle("Forecast Reports");
            }}
          />
          <Route
            path="/tms/report/aging1"
            component={loadable(() =>
              import("./pages/tms/reports/AgingReportsNew")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_aging"]);
              this.setPageTitle("Aging Reports");
            }}
          />
          <Route
            path="/tms/report/agingChart"
            component={loadable(() => import("./pages/tms/reports/AgingChart"))}
            onEnter={() => {
              this.authorize(["reports", "reports_admin_dashboard"]);
              this.setPageTitle("Aging Chart");
            }}
          />
          <Route
            path="/tms/report/reportDashboard"
            component={loadable(() =>
              import("./pages/tms/reports/ReportDashboard")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_admin_dashboard_2"]);
              this.setPageTitle("Report Dashboard");
            }}
          />
          <Route
            path="/tms/report/productivityReport"
            component={loadable(() =>
              import("./pages/tms/reports/ProductivityReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_productivity_report"]);
              this.setPageTitle("Productivity Report");
            }}
          />
          <Route
            path="/tms/report/report1"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="charge-report-1"
            onEnter={() => {
              this.authorize(["reports", "reports_charge_report"]);
              this.setPageTitle("Charge Report");
            }}
          />
          <Route
            path="/tms/report/charge-3"
            component={loadable(() => import("./pages/tms/reports/ChargeReportMain"))}
            reportType="charge-report-3"
            onEnter={() => {
              this.authorize(["reports", "reports_charge_report_3"]);
              this.setPageTitle("Charge Report 3");
            }}
          />
          <Route
            path="/tms/report/report2"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="load-report"
            onEnter={() => {
              this.authorize(["reports", "reports_loads_report"]);
              this.setPageTitle("Load Report");
            }}
          />
          <Route
            path="/tms/report/report3"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="load-count"
            onEnter={() => {
              this.authorize(["reports", "reports_load_count"]);
              this.setPageTitle("Load Count Report");
            }}
          />
          <Route
            path="/tms/report/report4"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="vessel-report"
            onEnter={() => {
              this.authorize(["reports", "reports_vessel_report"]);
              this.setPageTitle("Vessel Report");
            }}
          />
          <Route
            path="/tms/report/chassishistory"
            component={loadable(() => import("./pages/tms/reports/ChassisHistory"))}
            reportType="chassishistory"
            onEnter={() => {
              this.authorize(["reports", "reports_chassis_history"]);
              this.setPageTitle("Chassis History");
            }}
          />
          <Route
            path="/tms/report/report5"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="pricing-breakdown"
            onEnter={() => {
              this.authorize(["reports", "reports_pricing_breakdown"]);
              this.setPageTitle("Pricing BreakDown");
            }}
          />
          <Route
            path="/tms/report/report6"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="H"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report7"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="I"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report8"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="J"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report9"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="K"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report10"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="L"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report11"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="M"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report12"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="N"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report13"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="P"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report14"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="Q"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/report15"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="R"
            onEnter={() => {
              this.setPageTitle("Report");
            }}
          />
          <Route
            path="/tms/report/summary-report"
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="summary-report"
            onEnter={() => {
              this.authorize(["reports", "reports_summary_report"]);
              this.setPageTitle("Summary Report");
            }}
          />
          
          <Route
            path="/tms/report/equipment-report"
            component={loadable(() =>
              import("./pages/tms/reports/EquipmentReport")
            )}
            onEnter={() => {
              this.setPageTitle("Equipment Report");
            }}
          />
          <Route
            path="/tms/report/revenue-report"
            component={loadable(() =>
              import("./pages/tms/reports/RevenueReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_revenue_report"]);
              this.setPageTitle("Revenue Report");
            }}
          />
          <Route
            path="/tms/report/driver-pay-report"
            component={loadable(() =>
              import("./pages/tms/reports/DriverPayReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_driver_pay"]);
              this.setPageTitle("Driver Pay Report");
            }}
          />
          <Route
            path="/tms/report/driver-pay-summary-report"
            component={loadable(() =>
              import("./pages/tms/reports/DriverPaySummaryReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_driver_pay_summary"]);
              this.setPageTitle("Driver Pay Summary Report");
            }}
          />
          <Route
            path="/tms/report/lfd-report"
            component={loadable(() => import("./pages/tms/reports/LFDReport"))}
            onEnter={() => {
              this.authorize(["reports", "reports_lfd_report"]);
              this.setPageTitle("LFD Report");
            }}
          />
          <Route
            path="/tms/report/return-report"
            component={loadable(() =>
              import("./pages/tms/reports/ReturnReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_return_report"]);
              this.setPageTitle("Return Report");
            }}
          />
          <Route
            path="/tms/report/aging/:customerID"
            component={loadable(() =>
              import("./pages/tms/reports/AgingReportsNew")
            )}
            onEnter={() => {
              this.setPageTitle("Aging Report");
            }}
          />
          <Route
            path="/tms/report/revenue"
            component={loadable(() =>
              import("./pages/tms/reports/AccountingRevenue")
            )}
            onEnter={() => {
              this.authorize(["reports_customer_revenue"]);
              this.setPageTitle("Accounting Revenue");
            }}
          />
          <Route
            path="/tms/report/driverrevenue"
            component={loadable(() =>
              import("./pages/tms/reports/DriverRevenue")
            )}
            onEnter={() => {
              this.authorize(["reports_driver_revenue"]);
              this.setPageTitle("Driver Revenue");
            }}
          />
          <Route
            path="/tms/report/newdriverrevenue"
            component={loadable(() =>
              import("./pages/tms/reports/newDriverRevenue")
            )}
            onEnter={() => {
              this.authorize(["reports_new_driver_revenue"]);
              this.setPageTitle("New Driver Revenue");
            }}
          />
          <Route
            path="/tms/report/driverstatusreport"
            component={loadable(() =>
              import("./pages/tms/reports/DriverStatusReport")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_status_driver"]);
              this.setPageTitle("Driver Status Report");
            }}
          />
          <Route
            path="/tms/report/driverclockinoutreport"
            component={loadable(() =>
              import("./pages/tms/reports/DrvierClockInOut")
            )}
            onEnter={() => {
              this.authorize(["reports", "reports_clock_report"]);
              this.setPageTitle("Driver Clock In Out Report");
            }}
          />
          <Route
            path="/tms/report/loaddriverrevenue"
            component={loadable(() =>
              import("./pages/tms/reports/LoadDriverRevenue")
            )}
            onEnter={() => {
              this.setPageTitle("Load Driver Revenue");
            }}
          />
          <Route
            path="/tms/report/customer-list"
            onEnter={() => {
              this.setPageTitle("Customer List");
            }}
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="customer-list"
          />
          <Route
            path="/tms/report/pricing-load-report"
            onEnter={() => {
              this.authorize(["reports", "reports_loads_report_pricing"]);
              this.setPageTitle("Pricing Load Report");
            }}
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="pricing-load-report"
          />
          <Route
            path="/tms/report/charge-report-2"
            onEnter={() => {
              this.authorize(["reports", "reports_charge_report_2"]);
              this.setPageTitle("Charge Report 2");
            }}
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="charge-report-2"
          />
          <Route
            path="/tms/report/import-charge-report"
            onEnter={() => {
              this.authorize(["reports", "reports_import_charge"]);
              this.setPageTitle("Import Charge Report");
            }}
            component={loadable(() =>
              import("./pages/tms/reports/ChargeReport")
            )}
            reportType="import-charge-report"
          />
          <Route
            path="/tms/report/export-charge-report"
            onEnter={() => {
              this.authorize(["reports", "reports_export_charge"]);
              this.setPageTitle("Export Charge Report");
            }}
            component={loadable(() =>
              import("./pages/tms/reports/ChargeReport")
            )}
            reportType="export-charge-report"
          />
          <Route
            path="/tms/report/load-driver-revenue"
            onEnter={() => {
              this.setPageTitle("Driver Revenue");
            }}
            component={loadable(() => import("./pages/tms/reports/Report1"))}
            reportType="load-driver-revenue"
          />
          <Route
            path="/tms/report/profitability-report"
            onEnter={() => {
              this.authorize(["reports", "reports_profitability_report"]);
              this.setPageTitle("Profitability Report");
            }}
            component={loadable(() => import("./pages/tms/reports/ProfitabilityReport"))}
            reportType="profitability-report"
          />
          <Route
            path="/tms/report/clock-in-clock-out-report"
            onEnter={() => {
              this.authorize(["reports", "reports_clock_in_out"]);
              this.setPageTitle("ClockIn-ClockOut Report");
            }}
            component={loadable(() => import("./pages/tms/reports/ClockInAndClockOut"))}
            reportType="clockIn-clockOut-report"
          />
          <Route
            path="/tms/report/external-charge"
            onEnter={() => {
              this.authorize(["reports", "reports_external_charge_report"]);
              this.setPageTitle("External Charge");
            }}
            component={loadable(() =>
              import("./pages/tms/reports/ExternalCharge")
            )}
            reportType="external-charge-report"
          />
          <Route
            path="/tms/customer-employees"
            component={loadable(() => import("./pages/tms/CustomerEmployees"))}
            onEnter={() => {
              this.setPageTitle("Employees");
            }}
          />
          <Route
            path="/tms/AppointmentList"
            component={loadable(() => import("./pages/tms/AppointmentList"))}
            onEnter={() => {
              this.authorize(["appointment_list"]);
              this.setPageTitle("Appointment Lists");
            }}
          />

          {/* --------------------- for admin ------------------------*/}
          
          {/*        system logger page   */}
          <Route
            path="/tms/systemlogs"
            component={loadable(() => import("./pages/tms/SystemLogger/index"))}
            onEnter={() => {
              this.authorize(["systemlog"]);
              this.setPageTitle("System Log");
            }}
          />
          <Route
            path="/administrator"
            component={loadable(() => import("./pages/admin/login/AdminLogin"))}
            onEnter={() => {
              this.setPageTitle("Administrator");
            }}
          />
          <Route
            path="/admin"
            component={loadable(() =>
              import("./pages/admin/dashboard/AdminCheck")
            )}
            onEnter={() => {
              this.setPageTitle("Admin");
            }}
          >
            <IndexRoute
              component={loadable(() =>
                import("./pages/admin/dashboard/Dashboard")
              )}
              onEnter={() => {
                this.setPageTitle("Admin Dashboard");
              }}
            />
            <Route
              path="/carrier"
              component={loadable(() =>
                import("./pages/admin/dashboard/Carrier")
              )}
              onEnter={() => {
                this.setPageTitle("Carrier");
              }}
            />
            <Route
              path="/company"
              component={loadable(() =>
                import("./pages/admin/dashboard/Company")
              )}
              onEnter={() => {
                this.setPageTitle("Company");
              }}
            />
          </Route>
          {/* --------------------------------- Learning Section --------------------- */}
          <Route
            path="/tms/learning"
            component={loadable(() => import("./pages/tms/Learnings/SubTabs/Features/Features.js"))}
            onEnter={() => {
              this.setPageTitle("Learnings");
            }}
          />
          <Route
            path="/tms/learning/Features"
            component={loadable(() =>
              import("./pages/tms/Learnings/SubTabs/Features/index.js")
            )}
            onEnter={() => {
              this.setPageTitle("Learning Features");
            }}
          />
          <Route
            path="/tms/learning/Webinars"
            component={loadable(() =>
              import("./pages/tms/Learnings/SubTabs/Webinars/Webinars.js")
            )}
            onEnter={() => {
              this.setPageTitle("Learning webinars");
            }}
          />
          <Route
            path="/tms/learning/Videos"
            component={loadable(() =>
              import("./pages/tms/Learnings/SubTabs/Videos/VideosContainer")
            )}
            onEnter={() => {
              this.setPageTitle("Learning Videos");
            }}
          />
          <Route
            path="/tms/learning/Tutorials"
            component={loadable(() =>
              import("./pages/tms/Learnings/SubTabs/Videos/VideosContainer")
            )}
            onEnter={() => {
              this.setPageTitle("Learning Tutorials");
            }}
          />
          <Route
            path="/tms/learning/Blogs"
            component={loadable(() =>
              import("./pages/tms/Learnings/SubTabs/Blogs/BlogsContainer.js")
            )}
            onEnter={() => {
              this.setPageTitle("Blogs");
            }}
          />
          {/* --------------------- for admin ------------------------*/}
          <Route
            path="/customer-shipments"
            component={loadable(() =>
              import("./pages/trucker/NewTrack/CustomerTrack")
            )}
            onEnter={() => {
              this.authorize(["customer_shipments"]);
              this.setPageTitle("Customer Shipments");
            }}
          />
          <Route
            path="/admin/company-tms"
            component={loadable(() =>
              import("./pages/admin/dashboard/AddCompanyTMS")
            )}
            onEnter={() => {
              this.setPageTitle("Company Tms");
            }}
          />
          <Route
            path="/admin/short-name"
            component={loadable(() =>
              import("./pages/admin/dashboard/ShortNameCompany")
            )}
            onEnter={() => {
              this.setPageTitle("Short Name Company");
            }}
          />
          <Route
            path="/admin/dispatcher"
            component={loadable(() =>
              import("./pages/admin/dashboard/Dispatcher")
            )}
            onEnter={() => {
              this.setPageTitle("Dispatcher");
            }}
          />
          <Route
            path="/admin/account-receivable"
            component={loadable(() =>
              import("./pages/admin/dashboard/AccountReceivable")
            )}
            onEnter={() => {
              this.setPageTitle("Accounts Receivable");
            }}
          />
          <Route
            path="/admin/subaccount-receivable"
            component={loadable(() =>
              import("./pages/admin/dashboard/SubAccountReceivable")
            )}
            onEnter={() => {
              this.setPageTitle("Sub Accounts Receivable");
            }}
          />
          <Route
            path="/admin/locked-accounts"
            component={loadable(() =>
              import("./pages/admin/dashboard/LockedAccounts")
            )}
            onEnter={() => {
              this.setPageTitle("Locked Account");
            }}
          />
          <Route
            path="/admin/viewpayments/:id"
            component={loadable(() =>
              import("./pages/admin/dashboard/ViewPayments")
            )}
            onEnter={() => {
              this.setPageTitle("View Payments");
            }}
          />
          <Route
            path="/admin/PreRegisterUsers"
            component={loadable(() =>
              import("./pages/admin/dashboard/PreRegisterUsers")
            )}
            onEnter={() => {
              this.setPageTitle("Pre Register Users");
            }}
          />
          <Route
            path="/admin/chassis/size"
            component={loadable(() => import("./pages/admin/Chassis/Size"))}
            onEnter={() => {
              this.setPageTitle("Chasis Size");
            }}
          />
          <Route
            path="/admin/chassis/type"
            component={loadable(() => import("./pages/admin/Chassis/Type"))}
            onEnter={() => {
              this.setPageTitle("Chasis Type");
            }}
          />
          <Route
            path="/admin/chassis/owner"
            component={loadable(() => import("./pages/admin/Chassis/Owner"))}
            onEnter={() => {
              this.setPageTitle("Chasis Owner");
            }}
          />
          <Route
            path="/admin/container/size"
            component={loadable(() => import("./pages/admin/Container/Size"))}
            onEnter={() => {
              this.setPageTitle("Container Size");
            }}
          />
          <Route
            path="/admin/container/type"
            component={loadable(() => import("./pages/admin/Container/Type"))}
            onEnter={() => {
              this.setPageTitle("Container Type");
            }}
          />
          <Route
            path="/admin/container/Owner"
            component={loadable(() => import("./pages/admin/Container/Owner"))}
            onEnter={() => {
              this.setPageTitle("Container Owner");
            }}
          />
          <Route
            path="/admin/customers"
            component={loadable(() => import("./pages/admin/Customer"))}
            onEnter={() => {
              this.setPageTitle("Customers");
            }}
          />
          {/* =========== for support ============= */}
          <Route
            path="/admin/account-status"
            component={loadable(() => import("../src/Components/SupportRole"))}
            onEnter={() => {
              this.setPageTitle("Account Status");
            }}
          />
          <Route
            path="/admin/alertSystem"
            component={loadable(() => import("./pages/admin/AlertSystem/AlertSystem"))}
            onEnter={() => {
              this.authorize(["systemlog"]);
              this.setPageTitle("Alert System");
            }}
          />  
          <Route
            path="/upgrade"
            component={loadable(() => import("./Components/Common/UpgradeToPro/index"))}
            onEnter={() => {
              this.setPageTitle("Upgrade To Pro");
            }}
          />  
           <Route
            path="/admin/liveVideoSource"
            component={loadable(() => import("./pages/admin/LiveVideoSource/LiveVideoSource"))}
            onEnter={() => {
              this.setPageTitle("Live Video Source");
            }}
          />
          <Route
            path="/admin/emailAuditReport"
            component={loadable(() => import("./pages/admin/EmailAuditReport/index"))}
            onEnter={() => {
              this.setPageTitle("Email Audit Report");
            }}
          />

          {/* currencies route */}
          <Route
            path="/admin/currency"
            component={loadable(() => import("./pages/admin/Currency/CurrencyC"))}
            onEnter={() => {
              this.setPageTitle("Currency");
            }}
          />
          <Route
            path="*"
            component={loadable(() => import("./pages/NoMatchpage"))}
            onEnter={() => {
              this.setPageTitle("404 Page Not Found");
            }}
          />

        </Route>
        </Router>
      </>
    );
  }
}
// export default Routes
function mapStateToProps(state) {
  return {
    user: state.LoginReducer,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getPortMarkets }, dispatch),
  commonActions: bindActionCreators(commonActionCreators, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
