import React, { Component } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import { getStorage, isMilitaryTime, toastr } from "../../../services/Common.services";
import Select from "react-select";
import { findAccounts } from "./../services";
import { Modal } from "react-bootstrap";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import {
  IconCalendar,
  IconInfo,
  IconTimes,
  IconTrash,
  IconUpload,
} from "../../../Components/Common/Icons";

import { smallSelectStyle } from "../../../assets/js/select-style";

let timeZone = getStorage("timeZone");

const docuementType = [
  { value: "Check", label: "Check" },
  { value: "ACH", label: "ACH" },
  { value: "Wire", label: "Wire" },
  { value: "Credit", label: "Credit" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Cash", label: "Cash" },
  { value: "Other", label: "Other" },
];

const INITIAL_STATE = {
  amount: "",
  notes: "",
  customerId: null,
  checkNo: "",
  paymentDate: "",
  DepositToAccountRef: "",
  Account: [],
  pricingId: "",
  additionalPricingId: null,
};
export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      INITIAL_STATE,
      loader: this.props.loader,
    };
    this.userTimeFormat = isMilitaryTime();
  }

  componentDidMount() {
    findAccounts().then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.QueryResponse
      ) {
        let { Account } = response.data.data.QueryResponse;
        this.setState({ Account });
      }
    });
  }

  savepayment = () => {
    this.props.savepayment(this.state).then(() => {
      this.setState(INITIAL_STATE);
    });
  };

  _findDeposit(key) {
    let data = this.state[key].find(
      (pm) => pm.Id === this.state.DepositToAccountRef
    );
    if (data) return { value: data.Id, label: data.Name };
    return;
  }

  // get options for Sub Customer Invoice No
  getSubCustomerBillingOpts = (isCustomer = false) => {
    let opts = [];
    if (
      this.props.load &&
      this.props.load.additionalPricing &&
      this.props.load.additionalPricing.length > 0
    ) {
      this.props.load.additionalPricing.map((item, i) => {
        if (item.sub_reference_number) {
          let value = {
            label: isCustomer
              ? item.customerId.company_name
              : item.sub_reference_number
              ? item.sub_reference_number
              : "",
            value: item._id,
            customerId: item.customerId._id,
          };
          opts.push(value);
        }
      });
    }
    return opts;
  };
  // GEt SubCustomer
  getCustomerVal = () => {
    let opts = this.getSubCustomerBillingOpts(true);
    let val = opts.find((item) => item.customerId === this.state.customerId);
    return val;
  };
  // GEt AdditionalPricingValues
  getPricingVal=()=>{
    let opts=this.getSubCustomerBillingOpts();
    let val= opts.find(item=>item.value===this.state.additionalPricingId);
    return val;
  }
  uploadFile = (file, preview, type) => {
    if(file.type.match("image/png")||file.type.match("image/jpg") ||file.type.match("image/jpeg")  || file.type.match("application.pdf") ||
    file.type.match("text/csv") || file.type.match("application/vnd.ms-excel") || file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")){
    this.setState({ uploadFileDetails: file });
    setTimeout(() => {
      this.setState({ progress: 100 });
    }, 1000);
    this.props.uploadResult(file, preview, type);
  }else {
   toastr.show("Please enter only PDF, CSV, XLS, XLSX and/or Image (JPG, PNG, JPEG) file formats.", "error");
    return;
}
  }
  removeDocument = () => {
    this.setState({
      uploadFileDetails: "",
      progress: 0,
    });
    this.props.removeDocument();
  };

  render() {
    return (
      <Modal className="backdrop_modal_open" show={this.props.showAddPaymentModal} centered animation={true}>
        <Modal.Header>
          <Modal.Title>Add Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="font-14 mb-20">Payment Info</h5>
          <div className="mobal-dispacher-box pb-4">
            <div className="row">
              {this.props.tab == 2 && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      <span className="text-danger mr-1">*</span>Sub Reference No
                      </label>
                    <Select
                      options={this.getSubCustomerBillingOpts()}
                      placeholder=""
                      styles={smallSelectStyle}
                      value={this.getPricingVal() || ''}
                      onChange={(opt) => {
                        this.setState({
                          additionalPricingId: opt.value,
                          customerId: opt.customerId,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    <span className="text-danger mr-1">*</span>Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.amount}
                    onChange={(e) => this.setState({ amount: e.target.value })}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Check #</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.checkNo}
                    onChange={(e) => this.setState({ checkNo: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    <span className="text-danger mr-1">*</span>Payment Date
                  </label>
                  <div className="position-relative">
                    <Datetime
                      inputProps={{}}
                      dateFormat="MM-DD-YYYY"
                      timeFormat={this.userTimeFormat ? this.userTimeFormat : true }
                      displayTimeZone={timeZone}
                      onChange={(e) => this.setState({ paymentDate: e })}
                      value={
                        this.state.paymentDate && this.state.paymentDate
                          ? moment(this.state.paymentDate)
                          : ""
                      }
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
              </div>
              {this.props.tab == 2 && (
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Sub Customer</label>
                    <Select
                      options={this.getSubCustomerBillingOpts(true)}
                      placeholder=""
                      styles={smallSelectStyle}
                      value={this.getCustomerVal()}
                      onChange={(opt) => {
                        this.setState({ customerId: opt.customerId });
                      }}
                      isDisabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
            {!this.props.type && (
              <div className="mb-20">
                <h6>Document upload</h6>
                <ImageUploader
                  uploadResult={this.uploadFile}
                  multi={false}
                  type={"image"}
                  className="custom-file-input "
                />

                {this.state.uploadFileDetails && (
                  <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                    <IconUpload className="mr-20" />
                    <div className="w-100">
                      <div className="font-medium d-flex justify-content-between w-100 mb-1">
                        <div>{this.state.uploadFileDetails.name}</div>
                        <div className="ml-auto">{this.state.progress}%</div>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          aria-valuenow={`${this.state.progress}`}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${this.state.progress}%` }}
                        ></div>
                      </div>
                    </div>
                    <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                      <span onClick={() => this.removeDocument()}>
                        {" "}
                        <IconTimes className="" />
                      </span>
                    </button>
                  </div>
                )}

                {this.state.uploadFileDetails && this.state.progress == 100 && (
                  <div className="dropzone-fileupload d-flex">
                    <button className="btn btn-warning btn-circle btn-xs mr-15">
                      <IconInfo />
                    </button>
                    <div className="w-100 d-flex">
                      <div>
                        <div className="font-medium ">
                          {this.state.uploadFileDetails.name}
                        </div>
                        <div className="text-muted">
                          {(
                            this.state.uploadFileDetails.size * 0.0009765625
                          ).toFixed(1)}{" "}
                          kb
                        </div>
                      </div>
                      <div className="w-150 ml-auto">
                        <Select
                          options={docuementType}
                          styles={smallSelectStyle}
                          value={docuementType.find(
                            (d) => d.value === this.props.documentType
                          )}
                          onChange={({ value }) =>
                            this.props.setDocumentType(value)
                          }
                        />
                      </div>
                    </div>
                    <button className="btn btn-link btn-circle btn-xs ml-3">
                      <span onClick={() => this.removeDocument()}>
                        {" "}
                        <IconTrash />{" "}
                      </span>
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className="form-group">
              <label>Notes</label>
              <textarea
                name=""
                id=""
                rows="5"
                placeholder="Enter notes here.."
                className="form-control"
                onChange={(e) => this.setState({ notes: e.target.value })}
                value={this.state.notes}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={this.props.handleHidePaymentModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            disabled={this.props.isSaving}
            onClick={() => {
              this.setState({ loader: true });
              this.savepayment();
            }}
          >
            {this.props.isSaving && <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
            Add Payment
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
