import React, { Component } from "react";
import { connect } from "react-redux";
import {
  rossumAiUpload,
  rossumAiConfirm,
  createQue,
  getContainerSize,
  getContainerType,
  getTMSContainerOwner,
  getRossumLoginDetail,
  createLoad,
} from "../services";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import {
  toastr,
  showForTerminal,
} from "../../../services/Common.services";
import { TMS_GET_CUSTOMERS } from "../constant";
import EachPdf from "./EachPdf";
import {
  IconCheck,
  IconInfo,
  IconTimes,
  IconTrash,
  IconUpload,
} from "./../../../../src/Components/Common/Icons";

class RossumAi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      file: props.file,
      isLogged: false,
      iframe: null,
      openModal: false,
      documentTypes: [],
      choosenDocumentId: null,
      documentName: "",
      processing: false,
      annotation: null,
      wholeCompaniesInfo: [],
      allCompanies: [],
      workspace: null,
      ContainerSize: [],
      ContainerType: [],
      TMSContainerOwner: [],
      queue: null,
      content: [],
      allData: [],
      type_of_load: "IMPORT",
      pdfData: [],
      mappedData: [],
      responseData: [],
      times: 0,
      finalData: [],
      allAnnotation: [],
      allFiles: [],

      uploadResultPc: 0,
    };
  }

  getCustomers = (payload) => {
    this.props.dispatch({
      type: TMS_GET_CUSTOMERS,
      payload,
    });
  };
  getRossumLoginDetail = () => {
    getRossumLoginDetail().then((response) => {
      let token = response.data.data.token;
      let workspace = response.data.data.workspace;
      let queue = response.data.data.queue;
      this.setState({ token, workspace, queue });
    });
  };

  componentWillReceiveProps(props) {
    let self = this;
    if (props && props.customers.length > 0) {
      let allCompanies = props.customers.map((D) => D.company_name);
      self.setState({ allCompanies, wholeCompaniesInfo: props.customers });
    }
    if (props.responseData) {
      self.setState({ responseData: props.responseData });
    }
    if (props.finalDatas) {
      self.setState({ finalData: props.finalDatas });
    }
    if (props.allAnnotations) {
      self.setState({ allAnnotation: props.allAnnotations });
    }
    if (props.file) {
      self.setState({ file: props.file });
    }
    if(props.type_of_load){
      self.setState({type_of_load : props.type_of_load})
    }
  }

  componentDidMount() {
    let self = this;
    self.getRossumLoginDetail();
    getContainerSize().then((data) => {
      this.setState({ ContainerSize: data.data.data });
    });
    getContainerType().then((data) => {
      this.setState({ ContainerType: data.data.data });
    });
    getTMSContainerOwner().then((data) => {
      this.setState({ TMSContainerOwner: data.data.data });
    });
    this.getCustomers({ isIncludeDeleted: false });
  }

  chooseDocumentType = (e) => {
    let choosenDocumentId = e.target.value;
    this.setState({ choosenDocumentId });
  };

  uploadResult = (file, preview, type) => {
    if (showForTerminal() && !this.props.terminal) {
      toastr.show("Please select a terminal first.", "warning");
      return;
    }
    if (!this.state.type_of_load) {
      toastr.show("Please select a load type.", "warning");
      return;
    }
    this.setState({ file, responseData: [] },()=>{
      this.uploadFile();
    });
    
  };

  uploadFile = () => {
    if (!this.state.file || (this.state.file && this.state.file.length == 0)) {
      toastr.show("Please upload a file first.", "warning");
      return;
    }
    let allUploadedFiles = this.state.file;
    this.setState({ processing: true, allData: [] });
    let allFiles = [];
    allUploadedFiles.forEach((file) => {
      allFiles.push(
        rossumAiUpload(
          file,
          this.state.queue,
          this.state.token,
          this.state.queue
        )
      );
    });
    Promise.all(allFiles)
      .then((result) => {
        this.setState({ responseData: result,  uploadResultPc:100});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  createDocument = () => {
    this.setState({ openModal: true });
  };

  change = (e) => {
    let documentName = e.target.value;
    this.setState({ documentName });
  };

  createQue = () => {
    createQue(
      this.state.documentName,
      this.state.token,
      this.state.workspace
    ).then((response) => {
      let newQue = response.data;
      let documentTypes = [newQue, ...this.state.documentTypes];
      this.setState({ documentTypes });
    });
  };

  closeProcess = () => {
    let times = this.state.times;
    times += 1;
    this.setState({ times }, () => {
      if (this.state.times >= this.state.responseData.length) {
        this.setState({ processing: false, times: 0 });
      }
    });
  };

  saveData = (id, Data, annotation) => {
    let finalData = JSON.parse(JSON.stringify(this.state.finalData));
    let allAnnotation = JSON.parse(JSON.stringify(this.state.allAnnotation));
    allAnnotation[id] = annotation;
    finalData[id] = Data;
    this.setState({ finalData, allAnnotation }, () => {
      this.props.inhaleData(
        finalData,
        allAnnotation,
        this.state.token,
        this.state.file
      );
    });
  };

  finalLoadCreation = () => {
    let allQueues = [];
    let finalData = [...this.state.finalData];
    this.setState({ loader: true });
    let allFiles = [];
    finalData.forEach((eachLoad, index) => {
      eachLoad.forEach((load) => {
        load.index = index;
        allFiles[index] = load.file;
        delete load.file;
        allQueues.push(createLoad(load));
      });
    });
    Promise.all(allQueues).then((DD) => {
      this.setState({
        responseData: [],
        finalData: [],
        loader: false,
        allAnnotation: [],
      });
      let allAnnotationQueue = [];
      this.state.allAnnotation.forEach((annotation) => {
        allAnnotationQueue.push(rossumAiConfirm(annotation, this.state.token));
      });
      Promise.all(allAnnotationQueue).then(() => {});
      toastr.show("Load created", "success");
    });

    // this.props.saveData(this.props.id,allQueues);
    // Promise.all(allQueues)
    //   .then(() => {
    //     this.setState({ content: [], allData: [] })
    //     toastr.show('Your load is created!', "success");
    //   });
  };

  removeFile = (e, index) => {
    e.preventDefault();
    let file = [...this.state.file];
    file.splice(index, 1);
    this.setState({ file });
  };

  isDisabled = () => {
    if (!this.props.type_of_load) {
      return true;
    } else if (showForTerminal() && this.props.terminal && this.props.terminal.length===0) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="">
          <div className=" mb-3">
            <ImageUploader
              uploadResult={this.uploadResult}
              multi={true}
              type={"image"}
              className="custom-file-input"
              disabled={this.isDisabled()}
              label={this.isDisabled() && "Please select the required Field"}
            />
          </div>

          {this.state.processing &&
            this.state.file &&
            this.state.file.map((data, index) => {
              return (
                <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                  <IconUpload className="mr-20" />
                  <div className="w-100">
                    <div className="font-medium d-flex justify-content-between w-100 mb-1">
                      <div>{data.name.substring(0, 20)}</div>
                      <div className="ml-auto">
                        {this.state.uploadResultPc}%
                      </div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        aria-valuenow={`${this.state.uploadResultPc}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${this.state.uploadResultPc}%` }}
                      ></div>
                    </div>
                  </div>
                  <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                    <span onClick={(e) => this.removeFile(e, index)}>
                      {" "}
                      <IconTimes className="" />
                    </span>
                  </button>
                </div>
              );
            })}

          {!this.state.processing &&
            this.state.file &&
            this.state.file.length > 0 &&
            this.state.file.map((D, index) => {
              return (
                <div className="dropzone-fileupload d-flex mb-20">
                  {this.state.uploadResultPc === 0 ? (
                    <button className="btn btn-danger btn-circle btn-xs mr-15">
                      <IconInfo />
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-circle btn-xs mr-15">
                      <IconCheck />
                    </button>
                  )}
                  <div className="document-preview document-preview--sm mr-3">
                    <img
                      className="document-preview__image"
                      src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                    />
                  </div>
                  <div className="w-100 d-flex">
                    <div>
                      <div className="font-medium ">
                        {D.name.substring(0, 20)}
                      </div>
                      <div className="text-muted">
                        {(D.size * 0.0009765625).toFixed(1)} kb
                      </div>
                    </div>
                    <div className="w-150 ml-auto">
                    </div>
                  </div>
                  <button className="btn btn-link btn-circle btn-xs ml-3">
                    <span onClick={(e) => this.removeFile(e, index)}>
                      <IconTrash />{" "}
                    </span>
                  </button>
                </div>
              );
            })}
          {this.state.processing && (
            <p className="badge info">
              Your document is processing please wait
            </p>
          )}

        </div>

        {this.state.responseData &&
          this.state.responseData.length > 0 &&
          this.state.responseData.map((Data, key) => {
            return (
              <EachPdf
                saveData={this.saveData}
                ContainerType={this.state.ContainerType}
                key={key}
                id={key}
                closeProcess={this.closeProcess}
                data={Data}
                {...this.state}
              />
            );
          })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.TmsReducer.customers,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
  };
}
RossumAi = connect(mapStateToProps, mapDispatchToProps)(RossumAi);

export default RossumAi;
