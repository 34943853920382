import { useRef, useState } from "react";
import { getLocationHistory } from "./actionCreators";
import { angleFromCoordinate } from "./utility";
import moment from "moment";

const useTrackingHistoryPlayer = (
  mapInstance,
  driverMarkerLayer,
  timeZone,
  driverRouteLayer
) => {
  // loading state
  const [loadState, setloadState] = useState(true);
  // error state
  const [errorState, setErrorState] = useState(false);
  // player state
  const [playersState, setplayersState] = useState(false);
  // player seek
  // const [playerSeekingState, _setPlayerSeekingState] = useState(0);
  // setInterval state
  const [intervalState, _setIntervalState] = useState(null);
  // history data
  const [historyDataState, _setHistoryDataState] = useState([]);
  // unix key list: will be the list of unix time millis
  const [unixtimeKeyList, _setunixTimeKeyList] = useState([]);
  // time label list
  const [labelList, _setLabelList] = useState([]);

  // time zone
  // const [timeZone, _setTimeZone] = useState(null);
  // start date time
  const [startDateTime, _setStartDateTime] = useState(null);
  // end date time
  const [endDateTime, _setEndDateTime] = useState(null);
  // last rotation direction
  const [rotationAngle, _setRotationAngle] = useState(0);
  //
  const [driverPolyLinePoints, _setDriverPolyLinePoints] = useState([]);

  // moving marker
  const movingMarker = useRef(null);

  // player seek
  const playerSeekingState = useRef(0);

  const init = () => {
    mapInstance = mapInstance;
    driverMarkerLayer = driverMarkerLayer;
    timeZone = timeZone;
    driverRouteLayer = driverRouteLayer;

    if (
      mapInstance &&
      driverMarkerLayer &&
      !mapInstance.hasLayer(driverMarkerLayer)
    ) {
      mapInstance.addLayer(driverMarkerLayer);
    }

    if (
      mapInstance &&
      driverRouteLayer &&
      !mapInstance.hasLayer(driverRouteLayer)
    ) {
      mapInstance.addLayer(driverRouteLayer);
    }
  };

  const fetchHistory = async (params) => {
    reset();
    setloadState(true);
    try {
      const response = await getLocationHistory(params);
      if (response.status === 200) {
        const responseData = response.data.data;
        const tempHistoryDataList = [];
        const historyDataList = {};
        let timeKeyList = [];

        for (const key in responseData) {
          tempHistoryDataList.push(...responseData[key].locations);
        }
        // console.log("Temp history data: ", tempHistoryDataList);
        tempHistoryDataList.forEach((e) => {
          historyDataList[parseInt(e.t)] = e.l;
        });
        timeKeyList = Object.keys(historyDataList);

        // console.log("Mapped history data: ", historyDataList);
        // console.log("Time key list: ", timeKeyList);
        const tempDriverPolyLinePoints = [];
        Object.values(historyDataList).forEach((e) => {
          tempDriverPolyLinePoints.push(e);
        });
        _setDriverPolyLinePoints(tempDriverPolyLinePoints);
        _setHistoryDataState(historyDataList);
        // console.log("History Data State: ", historyDataState);
        _setunixTimeKeyList(timeKeyList);
        const labels = labelGenerator(timeKeyList);
        _setLabelList(labels);
        if (timeKeyList.length > 0) {
          // startDateTime = moment
          // .unix(timeKeyList[0] / 1000)
          // .tz(timeZone);
          _setStartDateTime(moment.unix(timeKeyList[0] / 1000).tz(timeZone));
          // endDateTime = moment
          // .unix(timeKeyList[timeKeyList.length - 1] / 1000)
          // .tz(timeZone);
          _setEndDateTime(
            moment.unix(timeKeyList[timeKeyList.length - 1] / 1000).tz(timeZone)
          );
        }
      }
    } catch (e) {
      console.log("Exception: ", e);
    }
    setloadState(false);
  };

  const clearDriverMarkerLayer = () => {
    driverMarkerLayer.clearLayers();
    movingMarker.current = null;
  };

  const reset = () => {
    setErrorState(false);
    setplayersState(false);
    _setStartDateTime(null);
    _setEndDateTime(null);
    // _setPlayerSeekingState(0);
    playerSeekingState.current = 0;
    _setRotationAngle(0);
    clearDriverMarkerLayer();
    clearDriverRouteLayer();
    clearIntervalState();
  };

  const play = async () => {
    setplayersState(true);
    if (intervalState) {
      clearIntervalState();
    }
    clearDriverMarkerLayer();
    drawDriverRoute();

    const intervalTimer = await setInterval(() => {
      if (playerSeekingState.current < unixtimeKeyList.length) {
        if (movingMarker.current) {
          const unixKey = unixtimeKeyList[playerSeekingState.current];
          const nextUnixKey = unixtimeKeyList[playerSeekingState.current + 1];
          moveDriverTo(
            historyDataState[unixKey][0],
            historyDataState[unixKey][1],
            nextUnixKey ? historyDataState[nextUnixKey][0] : null,
            nextUnixKey ? historyDataState[nextUnixKey][1] : null
          );
        } else {
          drawMovingMarker();
        }
        if (movingMarker.current) {
          const unixKey = unixtimeKeyList[playerSeekingState.current];
          const nextUnixKey = unixtimeKeyList[playerSeekingState.current + 1];
          moveDriverTo(
            historyDataState[unixKey][0],
            historyDataState[unixKey][1],
            nextUnixKey ? historyDataState[nextUnixKey][0] : null,
            nextUnixKey ? historyDataState[nextUnixKey][1] : null
          );

          //   _setPlayerSeekingState(playerSeekingState + 1);
          playerSeekingState.current = playerSeekingState.current + 1;
        }
      } else {
        clearInterval(intervalTimer);
        pause();
      }
    }, 100);
    _setIntervalState(intervalTimer);
  };

  const drawMovingMarker = () => {
    const tempMovingMarker = window.L.Marker.movingMarker(
      [historyDataState[unixtimeKeyList[playerSeekingState.current]]],
      [],
      {
        id: "driver-moving-marker-data-marker",
        icon: window.L.divIcon({
          html:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" xml:space="preserve">' +
            '<circle cx="17" cy="17" r="9" fill="var(--color-primary-500)"/>' +
            '<circle cx="17" cy="17" r="10" fill="none" stroke="#fff" stroke-width="2"/>' +
            "</svg>",
          iconSize: [30, 30],
          className: "customLeaflet-icon",
        }),
        zIndexOffset: 10,
      }
    );
    driverMarkerLayer.addLayer(tempMovingMarker);
    movingMarker.current = tempMovingMarker;
    mapInstance.panTo(
      window.L.latLng(
        historyDataState[unixtimeKeyList[playerSeekingState.current]][0],
        historyDataState[unixtimeKeyList[playerSeekingState.current]][1]
      )
    );
  };

  const clearDriverRouteLayer = () => {
    driverRouteLayer && driverRouteLayer.clearLayers();
  };

  const drawDriverRoute = () => {
    clearDriverRouteLayer();
    const latLngList = driverPolyLinePoints;
    if (latLngList && latLngList.length > 0) {
      const routeLayer = window.L.polyline(latLngList, {
        color: "var(--color-primary-500)",
        weight: 7,
        opacity: 1.0,
      });
      driverRouteLayer && driverRouteLayer.addLayer(routeLayer);
    }
  };

  const pause = () => {
    clearIntervalState();
    setplayersState(false);
  };

  const stop = () => {
    pause();
    clearDriverMarkerLayer();
    // _setPlayerSeekingState(0);
    playerSeekingState.current = 0;
  };

  const seekTo = (value) => {
    if (playersState) {
      pause();
    }
    // _setPlayerSeekingState(value);
    playerSeekingState.current = value;
    if (!movingMarker.current) {
      drawMovingMarker();
    }

    if (value < unixtimeKeyList.length) {
      const unixKey = unixtimeKeyList[value];
      const nextUnixKey = unixtimeKeyList[value + 1];

      moveDriverTo(
        historyDataState[unixKey][0],
        historyDataState[unixKey][1],
        nextUnixKey && historyDataState[nextUnixKey][0],
        nextUnixKey && historyDataState[nextUnixKey][1]
      );
    }
  };

  const labelGenerator = (unixTimeList) => {
    if (unixTimeList && unixTimeList.length > 0) {
      const labels = unixTimeList.map((e) => {
        return moment
          .unix(e / 1000)
          .tz(timeZone)
          .format("LLLL");
      });

      return labels;
    }
    return [];
  };

  const moveDriverTo = (lat, lng, nextLat = null, nextLng = null) => {
    if (movingMarker.current) {
      let tempRotationAngle;

      if (nextLat && nextLng) {
        tempRotationAngle = angleFromCoordinate(lat, lng, nextLat, nextLng);
        _setRotationAngle(tempRotationAngle);
      } else {
        tempRotationAngle = rotationAngle;
      }

      movingMarker.current.moveTo(
        {
          lat: lat,
          lng: lng,
        },
        100,
        {
          rotationAngle: tempRotationAngle,
        }
      );
    } else {
      // console.log("Moving marker not found");
    }
  };

  const clearIntervalState = () => {
    if (intervalState) {
      clearInterval(intervalState);
      _setIntervalState(null);
    }
  };

  return [
    loadState,
    errorState,
    labelList,
    playersState,
    startDateTime,
    playerSeekingState.current,
    endDateTime,
    unixtimeKeyList,
    init,
    fetchHistory,
    play,
    pause,
    stop,
    seekTo,
  ];
};

export default useTrackingHistoryPlayer;