import React, { Component } from 'react';
import pdfjsLib from 'pdfjs-dist';
import $ from 'jquery'
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rossumAiEmbedPreview, rossumAiEmbed, rossumeExport, mapData } from '../services';
import Iframe from 'react-iframe'
import config from '../../../config';
import { getStorage, toastr } from '../../../services/Common.services';
import PdfMap from './PdfMap';
import _ from 'lodash';

const firebase = config.firebase;

class EachPdf extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      file: null,
      iframe: null,
      openModal: false,
      documentTypes: [],
      choosenDocumentId: null,
      documentName: '',
      processing: false,
      annotation: null,
      wholeCompaniesInfo: [],
      allCompanies: [],
      workspace: null,
      ContainerSize: [],
      ContainerType: [],
      TMSContainerOwner: [],
      queue: null,
      content: [],
      allData: [],
      type_of_load: null,
      terminal:null,
      pdfData: [],
      mappedData: [],
      responseData: [],
      caller: null,
      shipper: null,
      consignee: null,
      allMapped: []

    }
    this.socketupdatedLoadRef = null;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.socketupdatedLoadRef) {
      this.socketupdatedLoadRef.off("value")
    }
    if (this.socketStatusNotificationRef) {
      this.socketStatusNotificationRef.off("value")
    }
  }

  embed = (url) => {
    let setTime;
    rossumAiEmbed(url, this.props.token)
      .then((result) => {
        if (setTime) {
          clearTimeout(setTime);
        }
        let id = this.state.annotation.split('/');
        let annotId = id[id.length - 1];
        this.export(annotId);
        this.props.closeProcess();
        this.setState({ processing: false });
      })
      .catch((Err) => {
        if (Err.data.annotation_status == 'exported') {
          toastr.show('Your load is created!', "success");
        } else {
          setTime = setTimeout(() => {
            this.embed(url);
          }, 10000)
        }

      })
  }


  export = (id) => {
    // this.setState({ iframe: null });
    rossumeExport(id, this.props.token)
      .then((response) => {
        let content = response.data.content[0]['children'];
        this.setState({ content }, () => {
          this.finalExport();
        })
        this.setState({ file: null })
      })
  }

  finalExport = () => {
    let containers = [];
    let pdfData = [];
    let containerList = [];
    let containerSizeList = [];
    let containerOwnerList = [];
    let containerTypeList = [];
    let commodityList = [];
    let descriptionList = [];
    let piecesLists = [];
    let weightList = [];
    let weightUnitTypesList = [];
    let palletsList = [];
    let sealNoList = [];
    let purchaseOrderNoList = [];
    let noOfLoads = 1;
    let loadInfo = {
      caller: null,
      shipper: null,
      consignee: null,
      containerOwner: null,
      containerType: null,
      containerSize: null
    }
    this.state.content.forEach((dat) => {
      let O = dat.schema_id.replace(/\D/g, "");
      if (O != "" && dat.content.value) {
        if (O > noOfLoads) {
          noOfLoads = O;
        }
      }
    });
    for (var i = 0; i < noOfLoads; i++) {
      containers.push({});
      pdfData.push({});
      containerList.push(`containerNo${i + 1}`);
      containerSizeList.push(`containerSize${i + 1}`)
      containerOwnerList.push(`containerOwner${i + 1}`)
      containerTypeList.push(`containerType${i + 1}`)
      sealNoList.push(`sealNo${i + 1}`)
      commodityList.push(`commodity${i + 1}`);
      descriptionList.push(`description${i + 1}`);
      piecesLists.push(`pieces${i + 1}`);
      weightList.push(`weight${i + 1}`);
      weightUnitTypesList.push(`weightUnitType${i + 1}`);
      palletsList.push(`pallets${i + 1}`);
      purchaseOrderNoList.push(`purchaseOrderNo${i + 1}`)
    }
    this.state.content.forEach((dat) => {
      if (dat.schema_id == 'caller') {
        loadInfo.callerValue = dat.content.value;
        let company = this.state.wholeCompaniesInfo.find((data) => {
          return (dat.content.value && data.company_name.toLowerCase() == dat.content.value.toLowerCase()) || (data.pdfMap && dat.content.value && data.pdfMap.indexOf(dat.content.value.toLowerCase()) != -1);
        });
        if (company) {
          loadInfo.caller = company._id;
        }

      }
      if (dat.schema_id == 'shipper') {
        loadInfo.shipperValue = dat.content.value;
        let company = this.state.wholeCompaniesInfo.find((data) => {
          return (dat.content.value && data.company_name.toLowerCase() == dat.content.value.toLowerCase()) || (data.pdfMap && dat.content.value && data.pdfMap.indexOf(dat.content.value.toLowerCase()) != -1);
        });
        if (company) {
          loadInfo.shipper = company._id;
        }

      }
      if (dat.schema_id == 'consignee') {
        loadInfo.consigneeValue = dat.content.value;
        let company = this.state.wholeCompaniesInfo.find((data) => {
          return (dat.content.value && data.company_name.toLowerCase() == dat.content.value.toLowerCase()) || (data.pdfMap && dat.content.value && data.pdfMap.indexOf(dat.content.value.toLowerCase()) != -1);
        });
        if (company) {
          loadInfo.consignee = company._id;
        }

      }
      if (dat.schema_id == 'bolbkg' && dat.content.value) {
        loadInfo.bolbkg = dat.content.value;
      }
      if (dat.schema_id == 'shipmentNo' && dat.content.value) {
        loadInfo.shipmentNo = dat.content.value;
      }
      if (dat.schema_id == 'secondaryReferenceNo' && dat.content.value) {
        loadInfo.secondaryReferenceNo = dat.content.value;
      }
      if (dat.schema_id == 'doNo' && dat.content.value) {
        loadInfo.doNo = dat.content.value;
      }
      if (dat.schema_id == 'deliveryOrderNo' && dat.content.value) {
        loadInfo.deliveryOrderNo = dat.content.value;
      }
      containerList.forEach((DD, index) => {

        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            containerNo: dat.content.value
          }, containers[index])))
          containers[index] = newData;

        }
      });
      containerSizeList.forEach((DD, index) => {

        if (dat.schema_id == DD && dat.content.value) {
          let containerId = this.state.ContainerSize.find((H) => {
            return (H.name.toLowerCase().indexOf(dat.content.value.toLowerCase()) != -1) || (H.pdfMap && dat.content.value && H.pdfMap.indexOf(dat.content.value.toLowerCase()) != -1);
          })
          if (containerId) {
            let newData = JSON.parse(JSON.stringify(Object.assign({}, {
              containerSize: containerId._id
            }, containers[index])))
            containers[index] = newData;

          }
          let newData = Object.assign({}, {
            containerSizeValue: dat.content.value
          }, containers[index])
          containers[index] = newData;
        }
      });
      containerOwnerList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let containerId = this.state.TMSContainerOwner.find((H) => {
            return (H.company_name.toLowerCase() == dat.content.value.toLowerCase()) || (H.pdfMap && dat.content.value && H.pdfMap.indexOf(dat.content.value.toLowerCase()) != -1);
          });
          if (containerId) {
            let newData = JSON.parse(JSON.stringify(Object.assign({}, {
              containerOwner: containerId._id
            }, containers[index])))
            containers[index] = newData;
          }
          let newData = Object.assign({}, {
            containerOwnerValue: dat.content.value
          }, containers[index])
          containers[index] = newData;
        }
      });
      containerTypeList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let containerId = this.state.ContainerType.find((H) => {
            return (H.name.toLowerCase() == dat.content.value.toLowerCase()) || (H.pdfMap && dat.content.value && H.pdfMap.indexOf(dat.content.value.toLowerCase()) != -1);
          })
          if (containerId) {
            let newData = JSON.parse(JSON.stringify(Object.assign({}, {
              containerType: containerId._id
            }, containers[index])))
            containers[index] = newData;
          }
          let newData = Object.assign({}, {
            containerTypeValue: dat.content.value
          }, containers[index])
          containers[index] = newData;
        }
      });
      sealNoList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            sealNo: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });
      commodityList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            commodity: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });
      descriptionList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            description: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });
      piecesLists.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            pieces: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });
      weightList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            weight: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });
      weightUnitTypesList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            weightUnitType: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });
      palletsList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            pallets: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });

      purchaseOrderNoList.forEach((DD, index) => {
        if (dat.schema_id == DD && dat.content.value) {
          let newData = JSON.parse(JSON.stringify(Object.assign({}, {
            purchaseOrderNo: dat.content.value
          }, containers[index])))
          containers[index] = newData;
        }
      });

    });
    containers = containers.map((D) => {
      return Object.assign({}, loadInfo, D);
    });
    containers = containers.map((D, key) => {
      Object.keys(D).forEach((Key) => {
        let k = `${Key}Value`;
        if (D.hasOwnProperty(k)) {
          let Data = JSON.parse(JSON.stringify(Object.assign({}, pdfData[key], {
            [k]: D[k]
          })));
          pdfData[key] = Data;

          delete D[k];
        }
      });
      return D;
    });

    this.setState({ allData: containers, pdfData }, () => {
      this.finalLoadCreation();
    })
  }

  componentDidMount() {
    let self = this;
    self._isMounted = true;
    if (self._isMounted) {
      self.setState({ annotation: self.props.data.data.annotation }, () => {
        if (self.state.annotation) {
          self.embed(self.state.annotation)
        }
      });
      self.setState({ wholeCompaniesInfo: self.props.wholeCompaniesInfo, ContainerSize: self.props.ContainerSize, ContainerType: self.props.ContainerType, TMSContainerOwner: self.props.TMSContainerOwner })


      this.socketupdatedLoadRef = firebase.database().ref(`${getStorage('currentUserID')}/ai-success`);
      this.socketupdatedLoadRef.on('value', (snapshot) => {
        const loadDetail = snapshot.val();
        if (loadDetail && moment().diff(moment(loadDetail.updatedAt), 'seconds') <= 10) {
          const msg = `${loadDetail.statusMessage}, ${moment().format('LLL')}.`
          if (window.lastReceivedMessage !== msg) {
            window.lastReceivedMessage = msg;
            window.toastr.success(msg, '', { "showMethod": "slideDown", "hideMethod": "slideUp", timeOut: 1000 * 3 * 60 });
            self.setState({ openModal: false });
            if (self.state.annotation) {
              let id = self.state.annotation.split('/');
              let annotId = id[id.length - 1];
              self.export(annotId);
            }
          }
        }
      })
    }
  }

  finalLoadCreation = () => {
    let allQueues = [];
    let finalData = [...this.state.allData];
    finalData = finalData.forEach((load, index) => {
      let items = [{}];
      let Data = Object.assign({}, load, { type_of_load: this.props.type_of_load, status: 'PENDING' });
      if (this.props.terminal) {
        Data.terminal = this.props.terminal.value;
      }
      Object.keys(Data).forEach((Key) => {
        if (Data[Key] == null || Data[Key] == "null" || Data[Key] == "") {
          delete Data[Key];
        }
      })
      if (Data['bolbkg']) {
        delete Data['bolbkg'];
      }
      if (Data['consignee']) {
        Data['consignee'] = [Data['consignee'] || this.state.consignee];
      }
      if (!Data['consigne'] && this.state.consignee) {
        Data['consignee'] = [this.state.consignee];
      }
      if (Data['shipper']) {
        Data['shipper'] = [Data['shipper'] || this.state.shipper];
      }
      if (!Data['shipper'] && this.state.shipper) {
        Data['shipper'] = [this.state.shipper];
      }
      if (Data['caller']) {
        Data['caller'] = Data['caller'] || this.state.caller;
      }
      if (!Data['caller'] && this.state.caller) {
        Data['caller'] = this.state.caller;
      }
      if (Data['commodity'] && Data['commodity'] != "") {
        items[0]['commodity'] = Data.commodity;
        delete Data.commodity;
      }
      if (Data['description'] && Data['description'] != "") {
        items[0]['description'] = Data.description;
        delete Data.description;
      }
      if (Data['pieces'] && Data['pieces'] != "") {
        items[0]['pieces'] = Data.pieces.replace(/^\D+/g, '');
        delete Data.pieces;
      }
      if (Data['weight'] && Data['weight'] != "") {
        Data.weight = Data.weight.replace(',', '');
        items[0]['weight'] = parseFloat(Data.weight);
        delete Data.weight;
      }
      if (Data['weightUnitType'] && Data['weightUnitType'] != "") {
        items[0]['weightUnitType'] = Data.weightUnitType;
        delete Data.weightUnitType;
      }
      if (Data['pallets'] && Data['pallets'] != "") {
        items[0]['pallets'] = Data.pallets.replace(/^\D+/g, '');

        delete Data.pallets;
      }
      if (!Data['containerSize']) {
        this.state.allMapped.forEach((D, key) => {
          if (key == index && D && D['containerSize']) {
            Data['containerSize'] = D['containerSize'];
          }
        })
      }
      if (!Data['containerOwner']) {
        this.state.allMapped.forEach((D, key) => {
          if (key == index && D && D['containerOwner']) {
            Data['containerOwner'] = D['containerOwner'];
          }
        })
      }
      if (!Data['containerType']) {
        this.state.allMapped.forEach((D, key) => {
          if (key == index && D && D['containerType']) {
            Data['containerType'] = D['containerType'];
          }
        })
      }
      items = items.filter(value => Object.keys(value).length !== 0)
      if (items && items.length > 0) {
        Data.items = items;
      }
      allQueues.push(Data)
    });
    this.props.saveData(this.props.id, allQueues, this.state.annotation);
    // Promise.all(allQueues)
    //   .then(() => {
    //     this.setState({ content: [], allData: [] })
    //     toastr.show('Your load is created!', "success");
    //   });

  }

  changeMap = (data) => {
    let Id = data.Id;
    delete data.Id;
    if (!data.id) {
      toastr.show('Please make a selection.', "warning");
      return;
    }
    
    mapData(data)
      .then((response) => {
        if (['caller', 'shipper', 'consignee'].indexOf(data.key) != -1) {
          let caller = this.state.caller;
          let shipper = this.state.shipper;
          let consignee = this.state.consignee;
          if (data.key == 'caller') {
            caller = data.id;
          }
          if (data.key == 'shipper') {
            shipper = data.id;
          }
          if (data.key == 'consignee') {
            consignee = data.id;
          }
          let wholeCompaniesInfo = [...this.state.wholeCompaniesInfo];
          wholeCompaniesInfo = wholeCompaniesInfo.map((Data) => {
            if (Data._id == response && response.data.data._id) {
              Data.pdfMap = response.data.data.pdfMap;
            } else {
              if (data.value && Data.pdfMap && Data.pdfMap.length > 0 && Data.pdfMap.indexOf(data.value.toLowerCase()) != -1) {
                Data.pdfMap.splice(Data.pdfMap.indexOf(data.value.toLowerCase()), 1);
              }
            }
            return Data;
          });
          this.setState({ wholeCompaniesInfo, caller, shipper, consignee }, () => {
            // this.finalExport();
          })
        }
        if (['containerSize'].indexOf(data.key) != -1) {
          let allMapped = [...this.state.allMapped];
          allMapped[Id] = Object.assign({}, allMapped[Id], { containerSize: data.id });
          let ContainerSize = [...this.state.ContainerSize];
          ContainerSize = ContainerSize.map((Data) => {
            if (Data._id == response && response.data.data._id) {
              Data.pdfMap = response.data.data.pdfMap;
            }
            return Data;
          });
          this.setState({ ContainerSize, allMapped }, () => {
            // this.finalExport();
          })
        }
        if (['containerOwner'].indexOf(data.key) != -1) {
          let allMapped = [...this.state.allMapped];
          allMapped[Id] = Object.assign({}, allMapped[Id], { containerOwner: data.id });
          let TMSContainerOwner = [...this.state.TMSContainerOwner];
          TMSContainerOwner = TMSContainerOwner.map((Data) => {
            if (Data._id == response && response.data.data._id) {
              Data.pdfMap = response.data.data.pdfMap;
            }
            return Data;
          });
          this.setState({ TMSContainerOwner, allMapped }, () => {
            // this.finalExport();
          })
        }
        if (['containerType'].indexOf(data.key) != -1) {
          let allMapped = [...this.state.allMapped];
          allMapped[Id] = Object.assign({}, allMapped[Id], { containerType: data.id });
          let ContainerType = [...this.state.ContainerType];
          ContainerType = ContainerType.map((Data) => {
            if (Data._id == response && response.data.data._id) {
              Data.pdfMap = response.data.data.pdfMap;
            }
            return Data;
          });
          this.setState({ ContainerType, allMapped }, () => {
            // this.finalExport();
          })
        }
        // removed // this.finalExport(); coz this is resetting every values again from ai export
        // set Updated Value
        let finalData = [...this.state.allData];
        finalData[Id][data.key]=data.id;
        this.setState({allData:finalData},()=>{
          this.finalLoadCreation();
        })
      })
  }

  onSetDataValue=(data)=>{
    let finalData = [...this.state.allData];
    finalData[data.index][data.key]=data.value;
    let allMapped = [...this.state.allMapped];
    allMapped[data.index] = Object.assign({}, allMapped[data.index], { [data.key]: data.value });
    this.setState({allData:finalData, allMapped},()=>{
      this.finalLoadCreation();

    })
  }

  preview = () => {
    let previewRequest;
    rossumAiEmbedPreview(this.state.annotation, this.props.token)
      .then((result) => {
        if (result.data.annotation_status == 'exported') {
          toastr.show('You can not view this document. Please try again.', "error");
        } else {
          this.setState({ iframe: result.data.url, processing: false, openModal: true });
          if (previewRequest) {
            clearTimeout(previewRequest);
          }
        }
      })
      .catch((Err) => {
        if (Err.data.annotation_status == 'exported') {
          toastr.show('You can not view this document. Please try again.', "error");
        } else {
          previewRequest = setTimeout(() => {
            this.preview();
          }, 5000)
        }
      })
    // rossumAiEmbedPreview(this.state.preview)
  }
  render() {
    return (
      <React.Fragment>
        <div className="mb-2">
          {this.state.allData.length > 0 &&
            <button type="button" onClick={() => this.preview()} className="btn btn-sm btn-success">Preview</button>
          }
        </div>
        <div className="">
          {this.state.allData.length > 0 && this.state.allData.map((Data, index) => {
            return (
              <div style={{ border: '1px solid #ddd', borderRadius: '8px' }} className="px-2 py-3 mb-2" key={index}>
                {Object.keys(Data).map((Key, k) => {
                  return (
                    <PdfMap caller={this.state.caller} shipper={this.state.shipper} consignee={this.state.consignee} changeMap={this.changeMap} wholeCompaniesInfo={this.state.wholeCompaniesInfo} ContainerSize={this.state.ContainerSize} ContainerOwner={this.state.TMSContainerOwner} ContainerType={this.state.ContainerType} Data={Data} Key={Key} key={k} Id={index} type_of_load={this.props.type_of_load} pdfData={this.state.pdfData}  onSetDataValue={this.onSetDataValue} />
                  )
                })}
              </div>
            )
          })}
        </div>

        <Modal
          bsSize="sm"
          show={this.state.openModal}
          onHide={() => this.setState({ openModal: false, iframe: null })}
          aria-labelledby="example-modal-sizes-title-lg"
          className="modal-extend preview-modal"
        >
          <Modal.Dialog className="">
            <Modal.Header closeButton className="d-flex flex-row-reverse">
              <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.iframe &&
                <React.Fragment>
                  {/* <span className="hide-rossum-support"></span> */}
                  <Iframe url={this.state.iframe}
                    width="100%"
                    height="100%"
                    id="myId"
                    className=""
                    display="initial"
                    position="relative" />
                </React.Fragment>
              }
            </Modal.Body>

            <Modal.Footer>
              <button type="button" className="btn btn-primary" onClick={() => this.setState({ openModal: false, iframe: null })}>Close</button>
            </Modal.Footer>
          </Modal.Dialog>

        </Modal>
        <div className="">
        </div>
      </React.Fragment>
    )
  }
}


export default EachPdf;
