import { getStorage, removeItem, toastr } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import { browserHistory } from 'react-router';

export function logout() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      resolve(true);
    })
  }
}


export function filterUsers(payload) {
  return function (dispatch) {
    dispatch({
      type: 'FILTER_USERS',
      payload
    })
  }
}


export function changeNav(payload) {
  return function (dispatch) {
    dispatch({
      type: 'CHANGE_NAV',
      payload
    })
  }
}

export function changeEquipment(payload) {
  return function (dispatch) {
    dispatch({
      type: 'Equipment',
      payload
    })
  }
}


export function logoutUser() {
  let url = 'logout';

  return function (dispatch) {
    HTTP('put', url, null, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result.data.statusCode === 200) {
          const carrierDetail = JSON.parse(getStorage("carrierDetail"));
          localStorage.clear();
          removeItem('token');
          removeItem('currentUserRole');
          removeItem('requestedLoads');
          removeItem('loggedInUser');
          removeItem('loadSteps');
          removeItem('reference');
          removeItem('container');
          removeItem('appoinment');
          removeItem('driver');
          removeItem('greenDot');
          removeItem('orderStatus');
          removeItem('pickUp');
          dispatch({
            type: "UPDATE_TYPE",
            payload: null
          });
          browserHistory.push('/')
          if(carrierDetail?.strictSessionPolicy) {
            window?.dispatchEvent(new Event('sessionTimeout'))
          }
          toastr.show("You are now logged out.", 'success');
        }
      })
      .catch((error) => {
        toastr.show(error.response.data.message, 'error');
      });
  }
}

export function setSelectedCarrier(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'SET_SELECTED_CARRIER', payload: data })
      resolve()
    })
  }
}
