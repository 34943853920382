import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Link,browserHistory } from "react-router";
import { bindActionCreators } from 'redux';
import { isNewModal, toastr } from './../../../services/Common.services'

import * as actionCreators from '../Dispatcher/actionCreators';
import _ from 'lodash'
import Autocomplete from 'react-autocomplete';
import { getLoad } from '../../../pages/tms/services';
import promptAlert from '../../../Components/Common/PromptAlert';
import {
  updateDriverOrderById
} from "./actionCreator";
import LoadInfoModal from '../../../Components/LoadInfoModal/LoadInfoModal';
class StreetTurn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLoads: [],
      streetTurn:props.selectedLoads.streetTurn
    }
    this.onSelect = this.onSelect.bind(this)
  }

  getLoads(search) {
    const params = {
      limit: 10,
      skip: 0,
      searchTerm: search,
      status: ['PENDING', 'AVAILABLE', 'DEPARTED', 'DISPATCHED'],
      type_of_load: ['EXPORT']
    }
    getLoad(params)
      .then((response) => {
        this.setState({ allLoads: response.data.data });
      })
  }

  onSelect(value, item) {
    this.setState({ streetTurn: value }, () => {
      this.assignStreetTurn();
    });
   }
 

  assignStreetTurn() {
    this.props.actions.assignStreetTurn({ reference_number: this.props.selectedLoads.reference_number, streetTurn: this.state.streetTurn }).then((result) => {
       toastr.show('Assigned!', "success");
       this.completeLoad();
      })
    }
    
    completeLoad = () => {
      let driverOrder = this.props.selectedLoads.driverOrder;
      const driverOrderId = driverOrder[driverOrder.length - 1]._id;
      const index = driverOrder.findIndex((order) => {return !order.driver})

      if(
        !(['COMPLETED', 'APPROVED', 'BILLING', 'UNAPPROVED', 'REBILLING', 'PARTIAL_PAID', 'FULL_PAID',]).includes(this.props.selectedLoads.status)){
        promptAlert(
          `You have assigned a street turn to ${this.props.selectedLoads.containerOwnerName} container # ${this.props.selectedLoads.containerNo} `,
          `Should we complete load ${this.props.selectedLoads.reference_number} ?`,
          async (confirm) => {
            if (confirm) {
              if(index >= 0){
                this.props.removeExtraLeg(index,true)
              }else{
                const formData = new FormData();
                formData.append(
                  "reference_number",
                  this.props.selectedLoads &&
                    this.props.selectedLoads.reference_number
                );
                formData.append("driverOrderId", driverOrderId);
                formData.append("arrivedOrDeparted", "departed");
                formData.append("completeLoad", true);
            
                updateDriverOrderById(formData)
                .then((result) => {
                  toastr.show("Completed!", "success");
                  isNewModal() ? this.setState(this.setState({openModalRefNumber:this.props.selectedLoads.streetTurn, showLoadModel:true})) : browserHistory.push(`/tms/customer-service/load?reference_number=${this.props.selectedLoads.streetTurn}`);
                })
                .catch((err) => {
                  toastr.show("The update has failed.  Please try again.", "error");
                  console.log("Error ------", err);
                });
              }
            }
          }
        );
      }
    }
  
  
  componentDidUpdate(props){
    if (!_.isEqual(props.selectedLoads.streetTurn, this.props.selectedLoads.streetTurn)) {
      this.setState({ streetTurn: this.props.selectedLoads.streetTurn });
    }
  }

  render() {
    return (

      <React.Fragment>
        <form>
          <div className="form-group mb-0">
            <Autocomplete
              wrapperStyle={{ flex: 1 }}
              items={this.state.allLoads}
              getItemValue={(item) => item.reference_number}
              renderItem={(load, highlighted) =>
                <div className="list-group-item"
                  key={load._id}
                  style={{ backgroundColor: highlighted ? '#eee' : 'transparent', zIndex: 9999 }}
                >
                  <div className="">
                    <div className="d-flex no-block align-items-center pb-2">
                      <div className="mr-auto seacrhdesign">
                        <label className="seacrhdesign_headding"> Load # </label>
                        <span className="seacrhdesign_value"> {load.reference_number} </span>
                      </div>
                      <div className="ml-auto seacrhdesign">
                        <label className="seacrhdesign_headding"> Container # </label>
                        <span className="seacrhdesign_value"> {load.containerNo} </span>
                      </div>
                    </div>
                    <div className="d-flex no-block align-items-center pb-2">
                      <div className="mr-auto seacrhdesign">
                        <label className="seacrhdesign_headding"> Bol # </label>
                        <span className="seacrhdesign_value"> {load.bolbkg} </span>
                      </div>
                      <div className="ml-auto seacrhdesign">
                        <label className="seacrhdesign_headding"> Booking # </label>
                        <span className="seacrhdesign_value"> {load.bolbkg} </span>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              }
              inputProps={{ className: 'form-control', placeholder: "Enter Load # to Street Turn", disabled: this.props.disabled }}
              menuStyle={{
                zIndex: 2,
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '0px 0',
                fontSize: '90%',
                position: 'absolute',
                top: "35px",
                overflow: 'auto',
                maxHeight: '200px',
                left: "0px",
                background: "#fff",
                right: "132px",
              }}
              value={this.state.streetTurn}
              onChange={e => {
                this.setState({ streetTurn: e.target.value });
                this.getLoads(e.target.value);
              }}
              onSelect={this.onSelect}
            />
          </div>
        </form>
        {this.state.showLoadModel && 
            this.state.openModalRefNumber &&
            <LoadInfoModal 
              refNumber={this.state.openModalRefNumber} 
              hideLoadModal={()=>this.setState({showLoadModel:false,openModalRefNumber:null})}  
              />
          }
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(StreetTurn);
