import React, { useEffect, useState } from 'react'
import { IconCalendar } from '../../../../../../Components/Common/Icons'
import Datetime from "react-datetime";
import moment from 'moment';
import Select from "react-select";
import { getStorage, toastr } from '../../../../../../services';
import configuration from '../../../../../../config';
import AsyncSelect from "react-select/async";
import { TextInputField } from './CommonInputComponent';
import NumberFormat from "react-number-format";
import { customerSubType } from '../../../../../../services';
import { smallSelectStyle } from '../../../../../../assets/js/select-style';
import { editTMSCustomer } from '../../../../services';
import { errorColorResponse, onChangeErrorColorResponse, onChangeSuccessColorResponse, onDateChangeErrorColorResponse, onDateChangeSuccesssColorResponse, successColorResponse  } from '../../utility';
const ContactCustomerComponent = ({tab,customerType ,showAddModal , Selected_customer ,AllProps ,updateCustomer , AllState}) => {
    const [officeHoursStart , setOfficeHoursStart] = useState(Selected_customer.officeHoursStart ? Selected_customer.officeHoursStart : "")
    const [officeHoursEnd ,setOfficeHoursEnd] = useState(Selected_customer.officeHoursEnd ? Selected_customer.officeHoursEnd : "")
    const [main_contact_name , setMain_contact_name] = useState(Selected_customer.main_contact_name ? Selected_customer.main_contact_name : "");
    const [mobile , setMobile] = useState(Selected_customer.mobile ? Selected_customer.mobile : "");
    const [secondary_contact_name , setSecondary_contact_name] = useState(Selected_customer.secondary_contact_name ? Selected_customer.secondary_contact_name : "");
    const [secondaryPhoneNo , setSecondaryPhoneNo] = useState(Selected_customer.secondaryPhoneNo ? Selected_customer.secondaryPhoneNo : "");
    let AllsalesAgent = Selected_customer.salesAgents.map((d) => ({ label: d.name, value: d._id }))
    const [newTerminal ,setNewTerminal] = useState(Selected_customer.newTerminal ? Selected_customer.newTerminal : "")
    const [salesAgent , setSalesAgent] = useState(AllsalesAgent ? AllsalesAgent : []);
    const [defaultSalesAgents , setDefaultSalesAgents] = useState([]);
    const [customer_SubType , setCustomer_SubType] = useState(Selected_customer.customerSubType ? Selected_customer.customerSubType : "")
    const [companyField ,setCompanyField] = useState(Selected_customer.companyField ? Selected_customer.companyField : "")
    const timeZone = getStorage("timeZone");
    useEffect(()=>{
        listAllFleetManagers()
    },[])

    let Terminals = [];
    if (AllState.Terminals && AllState.Terminals.length > 0) {
        Terminals = AllState.Terminals.map((D) => {
            return {
                value: D._id,
                label: D.name,
            };
        });
    }

    const listAllFleetManagers = (search) => {
        if (!search) {
            return new Promise((resolve, reject) => {
                AllProps.actions
                    .listAllFleetManagers({ salesAgent: true }, 10)
                    .then((options) => {
                        const salesAgents = [];
                        let allDatas = options || [];

                        allDatas.forEach((obj) => {
                            salesAgents.push({
                                value: obj._id,
                                label: `${obj.name}`,
                                allInfos: obj,
                            });
                        });
                        setDefaultSalesAgents(salesAgents)
                        resolve(salesAgents);
                    });
            });
        }
        let searchData = {
            searchTerm: search,
            salesAgent: true,
        };
        return new Promise((resolve, reject) => {
            AllProps.actions.listAllFleetManagers(searchData).then((options) => {
                resolve(options);
            });
        });
    };
    const onChangeHandler = (key , value) =>{
        if(Selected_customer[key] === value) return
        let params ={
            _id : Selected_customer._id,
            [key] : value
        }
        editTMSCustomer(params).then((result)=>{
            updateCustomer(result.data.data)
            onChangeSuccessColorResponse(key)
        }).catch((err)=>{
            console.log("Err",err)
            onChangeErrorColorResponse(key)
        })
    }
    const onBlurHandler = (key ,value) =>{
        if(Selected_customer[key] === value) return
        if((key === "mobile" || key === "secondaryPhoneNo") && value.length !== 10) {
            return toastr.show("Please enter valid mobile nulber", "error");
        }
        let params = {
            _id : Selected_customer._id,
            [key] : value
        }
        editTMSCustomer(params).then((result)=>{
            updateCustomer(result.data.data)
            if(key === "officeHoursStart" || key === "officeHoursEnd"){
                onDateChangeSuccesssColorResponse(key)
            }else{
                successColorResponse(key)
            }
        }).catch((err)=>{
            console.log("Err",err);
            if(key === "officeHoursStart" || key === "officeHoursEnd"){
                onDateChangeErrorColorResponse(key)
            }else{
                errorColorResponse(key)
            }
        })
    }
    return (
        <div>
            <React.Fragment>
                <div className="font-14 font-medium mb-15">Contact</div>
                <div className="form-row">
                    {customerType.intersect([
                        "caller",
                        "shipper",
                        "consignee",
                        "ALL",
                    ]).length > 0 && (
                            <React.Fragment>
                                <div className="col-md-2">
                                    <div className="form-group dropdown-on-top">
                                        <label>Office Hour Start</label>
                                        <div className="input-wrapper">
                                            <Datetime
                                                name="officeHoursStart"
                                                displayTimeZone={timeZone}
                                                dateFormat={false}
                                                value={moment(officeHoursStart)}
                                                onBlur={(value) => onBlurHandler("officeHoursStart",moment(value).toISOString())}
                                                onChange={(value) => setOfficeHoursStart(moment(value))}
                                                className={"officeHoursStart"}
                                            />
                                            <div className="input-icon">
                                                {!officeHoursStart ? (
                                                    <IconCalendar />
                                                ) : (
                                                    <i
                                                        className="uil uil-multiply"
                                                        onClick={() => {
                                                            setOfficeHoursStart("")
                                                            onBlurHandler("officeHoursStart","")
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group dropdown-on-top">
                                        <label>Office Hour End</label>
                                        <div className="input-wrapper">
                                            <Datetime
                                                name="officeHoursEnd"
                                                displayTimeZone={timeZone}
                                                onChange={(value) => { setOfficeHoursEnd(moment(value)) }}
                                                dateFormat={false}
                                                value={moment(officeHoursEnd)}
                                                onBlur={(value)=>{onBlurHandler("officeHoursEnd",moment(value).toISOString())}}
                                                className={"officeHoursEnd"}
                                            />
                                            <div className="input-icon">
                                                {!officeHoursEnd ? (
                                                    <IconCalendar />
                                                ) : (
                                                    <i
                                                        className="uil uil-multiply"
                                                        onClick={() =>{
                                                            setOfficeHoursEnd("")
                                                            onBlurHandler("officeHoursEnd","")
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                </div>
                <div className="form-row">
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Main Contact Name
                            </label>
                            <TextInputField
                                className={"form-control"}
                                name={"main_contact_name"}
                                value={main_contact_name}
                                onChange={(e, name) => {
                                    setMain_contact_name(e.target.value)
                                }}
                                onBlur={(name, value) => {
                                    onBlurHandler(name, value)
                                }}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Main Phone
                            </label>
                            <NumberFormat
                                className={'form-control'}
                                format="###-###-####"
                                mask="_"
                                onValueChange={(e) => setMobile(e.value)}
                                value={mobile}
                                onBlur={(e) => {
                                    onBlurHandler("mobile", mobile)
                                }}
                                id={"mobile"}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                Secondary Contact Name
                            </label>
                            <TextInputField
                                className={"form-control"}
                                value={secondary_contact_name}
                                name={"secondary_contact_name"}
                                onChange={(e, name) => {
                                    setSecondary_contact_name(e.target.value)
                                }}
                                onBlur={(name, value) => {
                                    onBlurHandler(name, value)
                                }}
                                required={false}
                            />
                        </div>
                    </div>
                    {customerType.intersect([
                        "caller",
                        "consignee",
                        "shipper",
                        "containerReturn",
                        "ALL",
                    ]).length > 0 && (
                            <div className="col-md-2">
                                <div className="form-group new-set-label">
                                    <label class="col-form-label">
                                        Secondary Phone
                                    </label>
                                    <NumberFormat
                                        className={'form-control'}
                                        format="###-###-####"
                                        mask="_"
                                        onValueChange={(e) => setSecondaryPhoneNo(e.value)}
                                        value={secondaryPhoneNo}
                                        name={"secondaryPhoneNo"}
                                        onBlur={(e) => { onBlurHandler("secondaryPhoneNo", secondaryPhoneNo) }}
                                        id={"secondaryPhoneNo"}
                                    />
                                </div>
                            </div>
                        )}

                </div>
                <div className="form-row">
                    {customerType.intersect([
                        "ALL",
                        "caller",
                        "shipper",
                        "containerReturn",
                        "consignee",
                        "chassisTermination",
                        "chassisPick",
                    ]).length > 0 &&
                        ((getStorage("currentUserRole") == "fleetmanager" &&
                            configuration.idForTerminal.indexOf(
                                JSON.parse(getStorage("loggedInUser")).fleetManager
                                    .carrier
                            ) != -1) ||
                            (getStorage("currentUserRole") == "carrier" &&
                                configuration.idForTerminal.indexOf(
                                    getStorage("currentUserID")
                                ) != -1)) &&
                        tab === 1 && (
                            <React.Fragment>
                                <div className="col-md-4">

                                    <div className="form-group new-set-label">
                                        <label><span class="text-danger">*</span> Terminal</label>
                                        <Select
                                            styles={smallSelectStyle}
                                            name="newTerminal"
                                            isMulti={true}
                                            value={newTerminal}
                                            options={Terminals}
                                            onChange={(value)=>{
                                                if(!value) {
                                                    
                                                }
                                                setNewTerminal(value)
                                                onChangeHandler("newTerminal",value.map((el)=>el.value))
                                            }}
                                            className="newTerminal"
                                        />
                                    </div>
                                </div>

                            </React.Fragment>
                        )}
                    {customerType.intersect(["shipper"]).length > 0 && (
                        <React.Fragment>
                            <div className="col-md-4">
                                <div className="form-group new-set-label">
                                    <label class="col-form-label">
                                        Port Username
                                    </label>
                                    <TextInputField 
                                        type={"text"}
                                        className="form-control"
                                        name="portUsername"
                                        disabled={showAddModal}
                                        value={Selected_customer.portUsername}
                                        required={false}
                                    />
                               </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group new-set-label">
                                    <label class="col-form-label">
                                        Port Password
                                    </label>
                                     <TextInputField 
                                        type={"text"}
                                        className="form-control"
                                        name="portPassword"
                                        disabled={showAddModal}
                                        value={Selected_customer.portPassword}
                                        required={false}
                                    />

                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Sales Agent</label>

                            <AsyncSelect
                                menuPlacement="top"
                                isMulti
                                styles={smallSelectStyle}
                                className={"salesAgents"}
                                value={salesAgent}
                                defaultOptions={defaultSalesAgents}
                                loadOptions={listAllFleetManagers}
                                onChange={(salesAgents) => {
                                    setSalesAgent(salesAgents)
                                    onChangeHandler("salesAgents", salesAgents.map((el) => el.value))
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label>Customer Sub Type </label>
                            <Select
                                styles={smallSelectStyle}
                                options={customerSubType}
                                className={"customerSubType"}
                                onChange={(value) => {
                                    setCustomer_SubType(value)
                                    onChangeHandler("customerSubType", value.value)
                                }}
                                value={customer_SubType && customerSubType.find((el) => el.value === customer_SubType)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group new-set-label">
                            <label class="col-form-label">
                                QuickBooks Company Field
                            </label>
                            <TextInputField
                                name={"companyField"}
                                className={'form-control'}
                                value={companyField}
                                onChange={(e, name) => {
                                    setCompanyField(e.target.value)
                                }}
                                onBlur={(name, value) => {
                                    onBlurHandler(name, value)
                                }}
                                required={false}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

export default ContactCustomerComponent
