import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams from '../../../Components/Common/jsonToQueryParams'
import { reject } from 'lodash';
import { TMS_UPDATE_STATE } from '../constant';

export async function getTMSChassisOwner(params) {
  const token = getStorage('token');
  let url = 'carrier/getTMSChassisOwner';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  const response = await HTTP('get', url, null, {
    authorization: token
  })
  return response;
}
export async function getChassisSize(params) {
  const token = getStorage('token');
  let url = 'admin/getChassisSize';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  const response = await HTTP('get', url, null, {
    authorization: token
  })
  return response;
}
export async function getChassisType(params) {
  const token = getStorage('token');
  let url = 'admin/getChassisType';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  const response = await HTTP('get', url, null, {
    authorization: token
  })
  return response;
}

   export function getTMSChassisById(params) {   
    let url = 'carrier/getTMSChassisById';
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject)=>{
        HTTP('get', url , null ,{
          'Authorization': getStorage('token')
        }).then((result)=>{
            const data = result.data ? result.data : {}
            resolve(data)
        }).catch((error)=>{
          reject(error)
          console.log("error",error)
        })
      })
}

export async function getTMSChassis(params) {
  const token = getStorage('token');
  let url = 'carrier/getTMSChassis';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  const response = await HTTP('get', url, null, {
    authorization: token
  })
  return response;
}

export async function addChassis(params) {
  const token = getStorage('token');
  const response = await HTTP('post', 'carrier/addChassis', params, {
    authorization: token
  })
  return response;
}

export async function UpdateChassis(params) {
  const token = getStorage('token');
  const response = await HTTP('put', 'carrier/UpdateChassis', params, {
    authorization: token
  })
  return response;
}

export async function removeChassis(params) {
  const token = getStorage('token');
  const response = await HTTP('post', 'carrier/removeChassis', params, {
    authorization: token
  })
  return response;
}

export async function addChasisDocument(params) {
  const token = getStorage('token');
  const response = await HTTP('post', 'carrier/addChasisDocument', params, {
    authorization: token
  })
  return response;
}

export function getChassisAudits(params) {
  let url = 'carrier/getTMSChassisAudit';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}