import _ from 'lodash';
import { getStorage, toastr } from "../../services/Common.services"
import { HTTP } from "../../services/Http.service"
import jsonToQueryParams from '../Common/jsonToQueryParams';


export function addPricingRules(payload) {
  const url = "tms/addpricingrules";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, payload, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          if (_.get(response, "data.statusCode") === 200) {
            resolve(response);
            toastr.show("Your pricing has been updated!", "success")
          }
          else {
            reject(response)
          }
        })
        .catch(error => {
          console.log("---- addPricingRules error -----", error)
          reject(error);
        })
    })
  }
}

export function addRatePricingRules(payload) {
  const url = "tms/addRatePricingRules";
    return new Promise((resolve, reject) => {
      HTTP("post", url, payload, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          if (_.get(response, "data.statusCode") === 200) {
            resolve(response);
            toastr.show("Your pricing has been updated!", "success")
          }
          else {
            reject(response)
          }
        })
        .catch(error => {
          console.log("---- addRatePricingRules error -----", error)
          reject(error);
        })
    })
}

export function updateRatePricingRules(payload) {
  const url = "tms/updateRatePricingRules";
    return new Promise((resolve, reject) => {
      HTTP("post", url, payload, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          if (_.get(response, "data.statusCode") === 200) {
            resolve(response);
            toastr.show("Your pricing has been updated!", "success")
          }
          else {
            reject(response)
          }
        })
        .catch(error => {
          console.log("---- updateRatePricingRules error -----", error)
          reject(error);
        })
    })
}
export function deleteRatePricingRules(payload) {
  const url = "tms/deleteRatePricingRules";
    return new Promise((resolve, reject) => {
      HTTP("post", url, payload, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          if (_.get(response, "data.statusCode") === 200) {
            resolve(response);
            toastr.show("Your pricing has been updated!", "success")
          }
          else {
            reject(response)
          }
        })
        .catch(error => {
          console.log("---- deleteRatePricingRules error -----", error)
          reject(error);
        })
    })
}
export function getPricingRules(data) {
  let url = "tms/getPricingRules";
  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const rules = _.get(response, "data.data.rules", [])

        resolve(rules)
      })
      .catch(error => {
        console.log("---- getPricingRules error -----", error)
        reject(error);
      })
  })
}

export function addExpenseRules(payload) {
  const url = "tms/addExpenseRules";
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", url, payload, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          if (_.get(response, "data.statusCode") === 200) {
            resolve(response);
            toastr.show("The expense rules have been saved.", "success")
          }
          else {
            reject(response)
          }
        })
        .catch(error => {
          console.log("---- addExpenseRules error -----", error)
          reject(error);
        })
    })
  }
}

export function getExpenseRules() {
  const url = "tms/getExpenseRules";
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const rules = _.get(response, "data.data.rules", [])

        resolve(rules)
      })
      .catch(error => {
        console.log("---- getExpenseRules error -----", error)
        reject(error);
      })
  })
}

export function getCustomersByIDS(data) {
  let url = "carrier/getTMSCustomers";
  url = data ? url + "?" + jsonToQueryParams(data) : url;

  return new Promise((resolve, reject) => {
    HTTP("get", url, data, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const customers = _.get(response, "data.data", [])

        resolve(customers)
      })
      .catch(error => {
        console.log("---- getCustomersByIDS error -----", error)
        reject(error);
      })
  })
}

export const getAllStates = () => (dispatch) => {
  let url = "setup/getAllStates";

  return new Promise((resolve, reject) => {
    HTTP("get", url, {}, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        response = response.data.data.sort();
        const states = response.map((res) => { return { label: res, value: res} });
        dispatch({
          type: 'SET_CONSIGNEE_STATES',
          payload: states
        });
        resolve("success");
      })
      .catch(error => {
        console.log("---- error -----", error)
        reject(error);
      })
  });
}



export function addDriverPayRules(payload) {
  const url = "tms/addDriverPayRules";
   return new Promise((resolve, reject) => {
      HTTP("post", url, payload, {
        'authorization': getStorage('token'),
      })
        .then(response => {
          if (_.get(response, "data.statusCode") === 200) {
            resolve(response);
            toastr.show("The driver pay rules have been saved.", "success")
          }
          else {
            reject(response)
          }
        })
        .catch(error => {
          console.log("---- addDriverPayRules error -----", error)
          reject(error);
        })
    })
}

export function getDriverPayRules() {
  const url = "tms/getDriverPayRules";
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const rules = _.get(response, "data.data.rules", [])

        resolve(rules)
      })
      .catch(error => {
        console.log("---- getDriverPayRules error -----", error)
        reject(error);
      })
  })
}