import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import Notifications from "react-notify-toast";

import { getStorage } from "../../services/Common.services";
import * as actionCreators from "../Login/actionCreators";

import Header from "../Common/Header/";
import Sidebar from "../Common/Sidebar/";
import fetchData from "../FetchData";

const $ = window.$;

class Home extends Component {
  constructor(props) {
    super(props);
    if (getStorage("token") != null) {
      this.props.actions.updateUserTypeGlobal();
    }

    this.state = {
      userType: "",
      innerHeight: window.innerHeight,
      currentUserRole: null,
      showHeaderAndSidebar: false,
      showTopAlert:'',
      onboardStyle: {display:'block',position: "absolute", right: 0, margin: 0, height: "100vh", left: 0,top: 0}
    };
  }

  componentWillMount() {
    if (routes.indexOf(`/${window.location.pathname.split("/")[1]}`) === -1) {
      this.setState({ showHeaderAndSidebar: true });
    }
    this.checkRoute();
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleCaptchaWidget(this.props.location);
    }, 3 * 1000);

    if (getStorage("currentUserRole")) {
      this.setState({ currentUserRole: getStorage("currentUserRole") });
    }

    fetchData(this.props.dispatch);
  }

  componentWillUpdate() {
    this.checkRoute();
  }

  checkRoute() {
    var self = this;

    const status = window.HubSpotConversations && window.HubSpotConversations.widget.status();
    browserHistory.listen((location) => {
      if (routes.indexOf(`/${location.pathname.split("/")[1]}`) === -1) {
        self.setState({
          showHeaderAndSidebar: true,
          currentUserRole: getStorage("currentUserRole"),
        });
        $(".grecaptcha-badge").css({ display: "none" });
        if (status && status.loaded) {
          window.HubSpotConversations && window.HubSpotConversations.widget.refresh();
        } else {
          window.HubSpotConversations && window.HubSpotConversations.widget.load();
        }
      } else {
        $(".grecaptcha-badge").css({ display: "block" });
        self.setState({
          showHeaderAndSidebar: false,
          currentUserRole: getStorage("currentUserRole"),
        });
        if (status && status.loaded) {
          window.HubSpotConversations && window.HubSpotConversations.widget.remove();
        }
      }

      this.toggleCaptchaWidget(location);
    });
  }

  toggleCaptchaWidget(location) {
    if (routes.indexOf(`/${location.pathname.split("/")[1]}`) === -1) {
      $(".grecaptcha-badge").css({ display: "none" });
    } else {
      $(".grecaptcha-badge").css({ display: "block" });
    }
  }

  render() {
    const closeShowTopAlert =()=>{
      this.setState({showTopAlert:''})
    }
    const showShowTopAlert =()=>{
      this.setState({showTopAlert:'show-top-alert'})
    }
    return (
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="mini-sidebar"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
        className={this.state.showTopAlert}

      >
        {this.state.showHeaderAndSidebar && <Header showTopAlert={this.state.showTopAlert} closeShowTopAlert={closeShowTopAlert} showShowTopAlert={showShowTopAlert}/>}
        {this.state.showHeaderAndSidebar && <Sidebar />}
        <Notifications options={{ zIndex: 5000 }} />
        {/* <ToastContainer enableMultiContainer /> */}
        <div className="page-wrapper" style={window.location.pathname.split("/")[1] != 'create-account' ? { display: "block" } : this.state.onboardStyle}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const states = (state) => ({
  user: state.LoginReducer.profile,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch,
  };
}

export default connect(states, mapDispatchToProps)(Home);

const routes = [
  "/",
  "/welcome",
  "/create-account",
  "/administrator",
  "/getresetToken",
  "/privacy-policy",
  "/terms-conditions",
  "/forget",
  "/verify",
  "/create-password",
  "/sign-up",
  "/unsubscribe",
  "/validation-code",
  "/registerEmployee",
  "/quotesRates",
  "/scheduleMeeting"
];
