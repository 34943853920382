import { HTTP } from '../../../services/Http.service';
import { toastr, getStorage, removeItem, setStorage } from '../../../services/Common.services';

export function logout() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      resolve(true);
    })
  }
}


export function filterUsers(payload) {
  return function (dispatch) {
    dispatch({
      type: 'FILTER_USERS',
      payload
    })
  }
}


export function changeNav(payload) {
  return function (dispatch) {
    dispatch({
      type: 'CHANGE_NAV',
      payload
    })
  }
}

export function changeEquipment(payload) {
  return function (dispatch) {
    dispatch({
      type: 'Equipment',
      payload
    })
  }
}


export function logoutUser() {
  let url = 'logout';

  return function (dispatch) {
    HTTP('put', url, null, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if (result.data.statusCode === 200) {
          const carrierDetail = JSON.parse(getStorage("carrierDetail"));
          removeItem('token');
          removeItem('currentUserRole');
          removeItem('requestedLoads');
          removeItem('loggedInUser');
          removeItem('isTimedOut');
          // dispatch({
          //   type: "UPDATE_TYPE",
          //   payload: null
          // });
          // window.location.href = '/';
          localStorage.clear();
          if(carrierDetail?.strictSessionPolicy) {
            setStorage("sessionEnd", true)
            window?.dispatchEvent(new Event('sessionTimeout'))
          }
          toastr.show("You are now logged out.", 'success');
        }
      })
      .catch((error) => {
        toastr.show(error.response.data.message, 'error');
      });
  }
}
