import React, { useState } from 'react'
import _ from 'lodash'
import CompanyCustomerComponent from './CompanyCustomerComponent'
import EmailCustomerComponent from './EmailCustomerComponent'
import PaymentCustomerComponent from './PaymentCustomerComponent';
import ContactCustomerComponent from './ContactCustomerComponent';
import PreferencesCustomerComponent from './PreferencesCustomerComponent';
import { editTMSCustomer } from '../../../../services';
import { errorColorResponse ,onChangeSuccessColorResponse} from '../../utility';

const CustomerComponent = (props) => {

    const [customerType, setCustomerType] = useState(props.Selected_customer.customerType ? props.Selected_customer.customerType : ["ALL"]);

    const handleCustomerType = (arr) => {
        let type = _.cloneDeep(customerType)
        if (
            type.length == 1 &&
            type.indexOf("ALL") > -1 &&
            arr.indexOf("ALL") === -1
        ) {
            setCustomerType(arr);
            onChangeHandler(arr);
            return;
        }
        if (type.intersect(arr).length > 0) {
            type = type.subtract(arr);
        } else {
            type = type.insert(arr);
        }
        if (arr.indexOf("ALL") > -1) {
            type = ["ALL"];
        }
        if (
            type.intersect([
                "caller",
                "shipper",
                "containerReturn",
                "consignee",
                "chassisTermination",
                "chassisPick",
            ]).length === 6
        ) {
            type = ["ALL"];
        }

        if (type.length === 0) {
            type = ["ALL"];
        }
        setCustomerType(type);
        onChangeHandler(type);
    }
    const onChangeHandler = (val) => {
        let params = {
            _id: props.Selected_customer._id,
            customerType: val
        }
        editTMSCustomer(params).then((result) => {
            props.updateCustomer(result.data.data)
            onChangeSuccessColorResponse("customerType")
        }).catch((err) => {
            console.log(err)
            errorColorResponse("customerType")
        })
    }
    return (
        <div>
            <div
                id="formContainer"
                className={`${props.Selected_customer._id ? "card pt-30 px-20" : ""
                    }`}
                style={{
                    display: props.tab == 1 ? "" : "none",
                }}
            >
                <div>
                    {props.isQuickAdd && (
                        <div className="font-medium mb-10 font-14">Customer Type</div>
                    )}
                    <div className="d-flex mb-4">
                        <div className="form-check form-check-primary pr-30 ml-1">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="caller"
                                onClick={() => handleCustomerType(["caller"])}
                                checked={customerType.includes("caller")}
                            />
                            <label className="form-check-label" for="caller">
                                Customer
                            </label>
                        </div>
                        <div className="form-check form-check-primary pr-30">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="shipper"
                                onClick={() =>
                                    handleCustomerType(["shipper", "containerReturn"])
                                }
                                checked={
                                    customerType.intersect(["shipper", "containerReturn"])
                                        .length > 0
                                        ? true
                                        : false
                                }
                            />
                            <label className="form-check-label" for="shipper">
                                Port/Shipper
                            </label>
                        </div>
                        <div className="form-check form-check-primary pr-30">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="consignee"
                                onClick={() => handleCustomerType(["consignee"])}
                                checked={customerType.includes("consignee")}
                            />
                            <label className="form-check-label" for="consignee">
                                Consignee
                            </label>
                        </div>
                        <div className="form-check form-check-primary pr-30">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="chassisPick"
                                onClick={() =>
                                    handleCustomerType([
                                        "chassisTermination",
                                        "chassisPick",
                                    ])
                                }
                                checked={customerType.intersect(["chassisPick","chassisTermination",]).length > 0 ? true : false}
                            />
                            <label className="form-check-label" for="chassisPick">
                                Chassis
                            </label>
                        </div>
                        <div className="form-check form-check-primary pr-30">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="ALL"
                                onClick={() => handleCustomerType(["ALL"])}
                                checked={customerType.includes("ALL")}
                            />
                            <label className="form-check-label" for="all">
                                All
                            </label>
                        </div>
                    </div>
                </div>
                <CompanyCustomerComponent
                    isQuickAdd={props.isQuickAdd}
                    tab={props.tab}
                    Customer={props.Selected_customer}
                    updateCustomer={props.updateCustomer}
                />
                <EmailCustomerComponent
                    isQuickAdd={props.isQuickAdd}
                    tab={props.tab}
                    Selected_customer={props.Selected_customer}
                    customerType={customerType}
                    updateCustomer={props.updateCustomer}
                />
                <PaymentCustomerComponent
                    isQuickAdd={props.isQuickAdd}
                    tab={props.tab}
                    Selected_customer={props.Selected_customer}
                    customerType={customerType}
                    updateCustomer={props.updateCustomer}
                />
                <ContactCustomerComponent
                    isQuickAdd={props.isQuickAdd}
                    tab={props.tab}
                    Selected_customer={props.Selected_customer}
                    showAddModal={props.addCustomer}
                    customerType={customerType}
                    AllProps={props.AllProps}
                    updateCustomer={props.updateCustomer}
                    AllState={props.AllState}
                />
                <PreferencesCustomerComponent
                    isQuickAdd={props.isQuickAdd}
                    tab={props.tab}
                    Selected_customer={props.Selected_customer}
                    customerType={customerType}
                    AllProps={props.AllProps}
                    updateCustomer={props.updateCustomer}
                />
            </div>
        </div>
    )
}

export default CustomerComponent
