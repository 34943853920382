import { browserHistory } from "react-router";
import { HTTP } from "./Http.service";
import { AddProfile } from "../pages/tms/services";
import axios from "axios";
import config from "../config";
import moment from "moment";
import _ from "lodash";
import themesOptions from "../pages/tms/Profile/themeOptions";
import jsonToQueryParams from "../Components/Common/jsonToQueryParams";
import { createDispatcherFilter } from "../pages/tms/Dispatcher/DispatcherView/actionCreators";
import { RANGES } from "../pages/tms/Dispatcher/DispatcherView/constant";
import { tmsDrops } from "../pages/tms/constant";
import { getSelectedDate } from "./../Components/Common/DispatcherDayCalenderFilter/dateFilter"

let groupByLoadsh = require("lodash/groupBy");

export const limit = 500;

export const isTrialUser = ()=>{ 
  const trial =  JSON.parse(localStorage.getItem('loggedInUser'))
  if(trial && trial.isTrialUser){
     return trial.isTrialUser
  }else return false
}
export const disableScreen =(pageClassName)=> {
    var div= document.createElement("div");
    div.className += "freemium";
    if(pageClassName){
      document.querySelector(pageClassName).appendChild(div);
    }
}

const camelize = (str) => {
  return str.replace(/\W+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
};

export function addCustomerCommonFunction(data) {
  return new Promise((resolve, reject) => {
    if (data.documentFile && !data.fileType) {
      toastr.show("You must choose a type.", "warning");
      this.setState({ tab: 2 });
      return;
    }
    let customerProfileData = JSON.parse(JSON.stringify(data));
    customerProfileData.address = {
      address: customerProfileData.address,
      lat: customerProfileData.lat ? customerProfileData.lat : 12,
      lng: customerProfileData.lng ? customerProfileData.lng : 12,
      address1: data.address1,
      city: data.city,
      state: data.state,
      country: data.country,
      zip_code: data.zip_code
    };
    customerProfileData.companyID =
      camelize(customerProfileData.company_name) +
      Math.floor(Math.random() * 10000 + 1);

    delete customerProfileData.lat;
    delete customerProfileData.lng;
    if (customerProfileData.createdAt) {
      delete customerProfileData.createdAt;
    }
    if (customerProfileData.updatedAt) {
      delete customerProfileData.updatedAt;
    }
    delete customerProfileData.__v;
    delete customerProfileData.carrier;
    delete customerProfileData.SyncToken;
    delete customerProfileData.qbId;
    if (customerProfileData.mobile) {
      customerProfileData.mobile = customerProfileData.mobile.replace(
        /-|\s/g,
        ""
      );
    }

    if (!customerProfileData._id && !customerProfileData.password) {
      customerProfileData.password = "123456";
    }

    if (data.documentFile && data.fileType) {
      customerProfileData.document = data.documentFile;
      customerProfileData.fileType = data.fileType;
      customerProfileData.filename = `${new Date().getTime().toString()}`;
      delete data.documentFile;
      delete customerProfileData.documentFile;
    }
    if (!customerProfileData.notes || customerProfileData.notes === "") {
      delete customerProfileData.notes;
    }
    Object.keys(customerProfileData).forEach((key) => {
      if (customerProfileData[key] === null) {
        delete customerProfileData[key];
      }
    });
    AddProfile("carrier/addTMSCustomer", customerProfileData)
      .then((result) => {
        toastr.show("Successfully added", "success");
        resolve(result);
      })
      .catch((error) => {
        reject();
      });
  });
};

export async function setStorage(key, data) {
  const value = await localStorage.setItem(key, data);
  return value;
}

export function getStorage(key) {
  let value = localStorage.getItem(key);
  if (key === "timeZone") {
    value = value === "null" ? `America/New_York` : value;
  }
  return value;
}

export function checkTMXPermission(key) {
  let value = localStorage.getItem('loggedInUser');
  let isValid = false
  if(value){
    let user = JSON.parse(value)
    if(user.role === 'carrier'){
      if(user.featurePermission && user.featurePermission.length && user.featurePermission.indexOf(key) > -1){
        isValid = true
      }
    } else {
      let carrier = localStorage.getItem('carrierDetail');
      if(carrier){
        let carrierData = JSON.parse(carrier)
        if(carrierData.featurePermission && carrierData.featurePermission.length && carrierData.featurePermission.indexOf(key) > -1){
          isValid = true
        }
      }
    }
  }
  return isValid;
}

export function removeItem(key) {
  return localStorage.removeItem(key);
}

export async function clearStorage() {
  let clear = await localStorage.clear();

  return clear;
}


export function setSessionStorage(key, data, isJson=false) {
  sessionStorage.setItem(key, isJson ? JSON.stringify(data) : data);
  return  sessionStorage.getItem(key);
}

export function getSessionStorage(key, isJson=false) {
  let value = sessionStorage.getItem(key);
  if(key==="timeZone"){
    value= value === "null"?`America/New_York`:value
  }
  return isJson ? JSON.parse(value) : value;
}

export function removeSessionItem(key) {
  return sessionStorage.removeItem(key);
}

export function clearSessionStorage() {
  let clear =  sessionStorage.clear();
  return clear;
}

export function redirection() {
  browserHistory.push("/");
  return;
}

export function customerRedirection() {
  browserHistory.push("/customer-shipments");
  return;
}

export function clearSession() {
  localStorage.clear();
  return;
}

export async function defaultAccount(token) {
  const response = HTTP("get", "getDefaultAccount", null, {
    Authorization: token,
  });
  return response;
}

export async function getAllUsers() {
  const response = await HTTP(
    "get",
    `admin/getAllUsers`,
    {},
    { Authorization: `Bearer ${getStorage("@AdminToken")}` }
  );
  return response;
}

export async function getCarrierByUserId(id) {
  const response = await HTTP(
    "get",
    `getFleetCarrier?carrier=${id}`,
    {},
    { Authorization: `Bearer ${getStorage("token")}` }
  );
  return response;
}

export async function getCustomer(id) {
  const response = await HTTP(
    "get",
    `tms/getCustomer`,
    {},
    { Authorization: `${getStorage("token")}` }
  );
  return response;
}

export function renderDuty(data) {
  let status;
  switch (data) {
    case "D":
      status = "DRIVING";
      break;
    case "SL":
      status = "SLEEPER-BERTH";
      break;
    case "OFF":
      status = "OFF-DUTY";
      break;
    case "ON":
      status = "ON-DUTY";
      break;
    default:
      status = "OFF-DUTY";
  }
  return status;
}

export function getYear() {
        let rows = [];
        for (var i = 2035; i >= 1970; i--) {
          rows.push(i);
        }
        return rows;
}

export function getMake() {
  return [
    "American LaFrance",
    "Autocar",
    "Bering",
    "Chevrolet",
    "Dodge",
    "Ford",
    "Freightliner",
    "GMC",
    "Hino",
    "International",
    "Isuzu",
    "Kenworth",
    "Mack",
    "Marmon",
    "Mercedes",
    "Mitsubishi",
    "Nissan",
    "Peterbilt",
    "Volvo",
    "Western Start",
  ];
}

export function getFuel() {
  return [
    "Diesel",
    "Gasoline",
    "Propane",
    "Liquid Natural Gas",
    "Compressed Natural Gas",
    "Ethanol",
    "Methanol",
    "E-85",
    "M-85",
    "A55",
    "Biodiesel",
    "Other",
  ];
}

export async function getGooglePlaces(query) {
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD1WC-07rfhCPOaSCmuvKDr5-x5nbquf04&address=" +
    query +
    "";
  const response = await axios({
    method: "get",
    url: url,
  });
  return response;
}

export async function getGoogleAutoPlaces(query) {
  let url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&language=pt_BR&key=AIzaSyBr2wqrbPk4xxs8vS3qvZRiMrlI3vVx7fo`;
  
  let response = await axios({
    method: "get",
    url: url,
    headers: { }
  });
  console.log('response', JSON.stringify(response))
  return response;
}

export async function getGooglePlacesAutoComplete(query) {
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD1WC-07rfhCPOaSCmuvKDr5-x5nbquf04&address=" +
    query +
    "";
  const response = await axios({
    method: "get",
    url: url,
  });
  const locations = [];
  response.data.results.forEach((obj) => {
    locations.push({
      value: obj.formatted_address,
      label: obj.formatted_address,
      allInfos: {
        address: obj.formatted_address,
        lat: obj.geometry.location.lat,
        lng: obj.geometry.location.lng,
      },
    });
  });
  return locations;
}

export async function getTmsLocation(params) {
  const token = getStorage("token");
  let url = "carrier/getTMSCustomers";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    `${url}`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getPlace(query) {
  let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${config.map_box_api_key}&country=us`;
  const response = await axios({
    method: "get",
    url: url,
  });
  return response;
}

export const groupBy = (array, callback) => {
  var groups = {};
  array.forEach((o) => {
    var group = JSON.stringify(callback(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};

export const roles = {
  carrier: "carrier",
  customer: "customer",
  dispatcher: "dispatcher",
  customer_service: "customer_service",
  safety_manager: "safety_manager",
  accounting: "accounting",
  billing: "billing",
  mechanic: "mechanic",
};

export async function connectWithQuickBook() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    "carrier/getToken",
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getLoadForCustomer() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/getLoadsForCustomer`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getAccessToken(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `carrier/getAccessToken?code=${params.code}&realmId=${params.realmId}&state=${params.state}`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function createQbCustomer(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `carrier/createQbCustomer`, data, {
    authorization: token,
  });
  return response;
}

export async function changeQbSetting(type, data) {
  const token = getStorage("token");
  const newFormData = new FormData();
  if (type == "qbConnection") {
    newFormData.append("qbConnection", data);
  }
  if (type == "qbDesktop") {
    newFormData.append("qbDesktop", data);
  }
  if (type == "driverPreferences") {
    newFormData.append("driverPreferences", data);
  }
  if (type == "showTerminalData") {
    newFormData.append("showTerminalData", data);
  }
  if (type == 'requireReference'){
    newFormData.append("requireReference", data);
  }
  if(type == 'driverAccountHold'){
    newFormData.append("driverAccountHold", data);
  }
  if(type == 'isAllowSystem'){
    newFormData.append("isAllowSystem", data);
  }
  const response = await HTTP("post", `user/changeQbSetting`, newFormData, {
    authorization: token,
  });
  return response;
}

export async function deletFleetCustomer(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/removeFleetCustomer`, data, {
    authorization: token,
  });
  return response;
}
export async function deletTmsCustomer(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `carrier/removeTMSCustomer`, data, {
    authorization: token,
  });
  return response;
}

export async function getListOfServices() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `carrier/getListOfServices`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getInvoice(params) {
  // console.log("alert===========");
  const token = getStorage("token");
  const newFormData = new FormData();
  newFormData.append("reference_number", params);
  newFormData.append("print", true);
  const response = await HTTP(
    "POST",
    "tms/sendEmailDocumentOfLoad",
    newFormData,
    {
      authorization: token,
    }
  );
  return response;
}

export async function approveDeduction(params) {
  const token = getStorage("token");
  const newFormData = new FormData();
  newFormData.append("approved", params.approved);
  newFormData.append("_id", params._id);
  if (params.settlementNo) {
    newFormData.append("settlementNo", params.settlementNo);
  }
  newFormData.append("startDate", params.startDate);
  newFormData.append("endDate", params.endDate);
  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }

  const response = await HTTP("put", `tms/deduction/approve`, newFormData, {
    authorization: token,
  });
  return response;
}

export async function approveNewDeduction(params) {
  const token = getStorage("token");
  const newFormData = new FormData();
  newFormData.append("approved", params.approved);
  newFormData.append("_id", params._id);
  if (params.settlementNo) {
    newFormData.append("settlementNo", params.settlementNo);
  }
  if (params.startDate) {
    newFormData.append("startDate", params.startDate);
  }
  if (params.endDate) {
    newFormData.append("endDate", params.endDate);
  }
  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }
  if (params.from) {
    newFormData.append("from", params.from);
  }
  const response = await HTTP("put", `tms/deduction/new-approve`, newFormData, {
    authorization: token,
  });
  return response;
}

export async function searchLoads(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "post",
    `tms/getLoads`,
    { searchTerm: params.searchTerm },
    {
      authorization: token,
    }
  );
  return response;
}

export async function getTranscation(params) {
  const token = getStorage("token");
  let url = "tms/transcation";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getNewTranscationDriverPay(params) {
  //used by the driver pay
  const token = getStorage("token");
  let fullUrl;
  fullUrl = `${config.newDocUrl}report/new-transcation`;
  fullUrl = params ? fullUrl + "?" + jsonToQueryParams(params) : fullUrl;
  return new Promise((resolve, reject) => {
    HTTP("GET", null, params, { authorization: token }, fullUrl)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getNewTranscation(params) {
  const token = getStorage("token");
  let url = "tms/new-transcation";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getNewTranscationCount(params) {
  const token = getStorage("token");
  let url = "tms/new-transcation/count";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getMasterReport(params) {
  let url = "dashboard/getReports";
  const token = getStorage("token");
  const response = await HTTP("post", url, params, {
    authorization: token,
  });
  return response;
}

export async function updateTranscation(params) {
  const token = getStorage("token");
  let url = "tms/transcation";
  const newFormData = new FormData();
  newFormData.append("id", params.id);
  if (params.amount) {
    newFormData.append("amount", params.amount);
  }
  if (params.additionalPrice) {
    newFormData.append("additionalPrice", params.additionalPrice);
  }
  newFormData.append("isFromSettlement", params.isFromSettlement);
  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }
  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function updateNewTranscation(params) {
  const token = getStorage("token");
  let url = "tms/new-transcation";
  const newFormData = new FormData();
  newFormData.append("id", params.id);
  if (params.amount) {
    newFormData.append("amount", params.amount);
  }
  if (params.additionalPrice) {
    newFormData.append("additionalPrice", params.additionalPrice);
  }

  newFormData.append("isFromSettlement", params.isFromSettlement);

  if (params.reference_number) {
    newFormData.append("reference_number", params.reference_number);
  }
  if (params.status) {
    newFormData.append("status", params.status);
  }
  if (params.from) {
    newFormData.append("from", params.from);
  }
  if (params.to) {
    newFormData.append("to", params.to);
  }
  if (params.isManual) {
    newFormData.append("isManual", params.isManual);
  }
  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function sendDocumentsInEmail(params) {
  const token = getStorage("token");
  let url = "tms/sendDocumentsToEmail";
  const newFormData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key == "documents") {
      newFormData.append("documents", JSON.stringify(params[key]));
    } else {
      newFormData.append(key, params[key]);
    }
  });
  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function changeWeek(params) {
  const token = getStorage("token");
  const newFormData = new FormData();
  Object.keys(params).forEach((key) => {
    newFormData.append(key, params[key]);
  });
  const response = await HTTP(
    "post",
    "tms/driver-pay/change-week",
    newFormData,
    {
      authorization: token,
    }
  );
  return response;
}

export async function addCheck(params) {
  const token = getStorage("token");
  let url = "tms/changeDocumentStatus";
  const newFormData = new FormData();

  newFormData.append("documentId", params.documentId);
  newFormData.append("reference_number", params.reference_number);
  newFormData.append("checked", params.checked);

  const response = await HTTP("post", url, newFormData, {
    authorization: token,
  });
  return response;
}

export async function getPayments(params) {
  const token = getStorage("token");
  let url = "tms/driver-payment";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  // let url = `tms/driver-payment`;
  // if (params.type) {
  //   url = `${url}?type=${params.type}`
  // }
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function disconnectQuickBooks() {
  const token = getStorage("token");
  let url = "carrier/revokeQuickBooks";
  const response = await HTTP(
    "post",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getDocumentForEquipment(params) {
  const token = getStorage("token");
  let url = "driver/getImagingDocs";
  url = params
    ? url +
      "?" +
      Object.entries(params)
        .map((e) => e.join("="))
        .join("&")
    : url;
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function getPaymentGroups() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/create-payment-group`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function createPaymentGroup(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/create-payment-group`, data, {
    authorization: token,
  });
  return response;
}

export async function addPayment(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/driver-payment`, data, {
    authorization: token,
  });
  return response;
}

export async function removePayment(data) {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/remove-driver-payment`, data, {
    authorization: token,
  });
  return response;
}

function getMonday(d) {
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export async function getEachTranscation(_id) {
  const token = getStorage("token");
  let url = "tms/getDriverPayById";
  if (_id) {
    url = `${url}?_id=${_id}`;
  }
  const response = await HTTP(
    "get",
    url,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function updateCharges(payload) {
  const token = getStorage("token");
  const response = await HTTP("put", `tms/updateCharge`, payload, {
    authorization: token,
  });
  return response;
}

export async function getCharges() {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/getCharges`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export const loadStatus = [
  {
    name: "Pending",
    value: "PENDING",
  },
  {
    name: "Needs Carrier",
    value: "NEEDS_CARRIER",
  },
  {
    name: "Needs Driver",
    value: "NEEDS_DRIVER",
  },
  {
    name: "Booked - awaiting for confirmation",
    value: "BOOKED_AWAITING_CONFIRMATION",
  },
  {
    name: "Driver Assigned",
    value: "DRIVER_ASSIGNED",
  },
  {
    name: "Ready - Confirmation signed",
    value: "READY_CONFIRMATION_SIGNED",
  },
  {
    name: "Dispatched",
    value: "DISPATCHED",
  },
  {
    name: "In Transit",
    value: "IN_TRANSIT",
  },
  {
    name: "Watch",
    value: "WATCH",
  },
  {
    name: "Possible Claim",
    value: "POSSIBLE_CLAIM",
  },
  {
    name: "Delivered",
    value: "DELIVERED",
  },
  {
    name: "Completed",
    value: "COMPLETED",
  },
  {
    name: "To Be Billed",
    value: "TO_BE_BILLED",
  },
  {
    name: "Actual Claim",
    value: "ACTUAL_CLAIM",
  },
];

export const groupByTime = function (arr, key, group) {
  var groupings = {
    day: function (obj) {
      var date = new Date(obj[key]);
      date.setHours(0, 0, 0, 0);
      return date.valueOf();
    },
    week: function (obj) {
      var date = new Date(obj[key]);
      date.setHours(0, 0, 0, 0);
      return getMonday(date).valueOf();
    },
    month: function (obj) {
      var date = new Date(obj[key]);
      return new Date(date.getFullYear(), date.getMonth(), 1).valueOf();
    },
  };

  if (!group) {
    group = "day";
  }
  return groupByLoadsh(arr, groupings[group]);
};

export const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: "created",
    VALUE_UPDATED: "updated",
    VALUE_DELETED: "deleted",
    VALUE_UNCHANGED: "unchanged",
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw "Invalid argument. Function given, object expected.";
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        };
      }

      var diff = {};
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }
        var value2 = undefined;
        if ("undefined" != typeof obj2[key]) {
          value2 = obj2[key];
        }
        diff[key] = this.map(obj1[key], value2);
      }
      for (var key in obj2) {
        if (this.isFunction(obj2[key]) || "undefined" != typeof diff[key]) {
          continue;
        }
        diff[key] = this.map(undefined, obj2[key]);
      }
      return diff;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (
        this.isDate(value1) &&
        this.isDate(value2) &&
        value1.getTime() === value2.getTime()
      ) {
        return this.VALUE_UNCHANGED;
      }
      if ("undefined" == typeof value1) {
        return this.VALUE_CREATED;
      }
      if ("undefined" == typeof value2) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (obj) {
      return {}.toString.apply(obj) === "[object Function]";
    },
    isArray: function (obj) {
      return {}.toString.apply(obj) === "[object Array]";
    },
    isObject: function (obj) {
      return {}.toString.apply(obj) === "[object Object]";
    },
    isDate: function (obj) {
      return {}.toString.apply(obj) === "[object Date]";
    },
    isValue: function (obj) {
      return !this.isObject(obj) && !this.isArray(obj);
    },
  };
})();

export const differnceFinder = function (
  obj1,
  obj2,
  userId = null,
  prevUserId = null
) {


  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }

  obj1 = obj1 || {};
  obj2 = obj2 || {};
  Object.keys(obj1)
    .concat(Object.keys(obj2))
    .forEach((key) => {
      if (key !== "extraTracerData" && key!=="lastContainerTracedDate" && key!== "lastContainerErrorDate" &&  key!== "cautionCount" ) {
        const currentUserId = userId ? userId._id : null;
        const previousUserId = prevUserId ? prevUserId._id : null;

        if (
          currentUserId &&
          previousUserId &&
          currentUserId === previousUserId
        ) {
          if (
            key === "lastFreeDay" ||
            key === "status" ||
            key === "custom" ||
            key === "freight" ||
            key === "extraTracerData"
          ) {
            result[key] = obj2[key];
          }
        }
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          if (obj2[key] !== undefined) {
            result[key] = obj2[key];
          }
        }
        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = differnceFinder(obj1[key], obj2[key]);
          if (value !== undefined) {
            result[key] = value;
          }
        }
        if(key === 'consigneeInfo' || key === 'shipperInfo'){
          result[key] = []
          obj1[key] && obj1[key].forEach((val) => {
            if(obj2[key] && !obj2[key].includes(val)){
              result[key].push({value: val, editType: 'Deleted '});
            }
          })
          obj2[key] && obj2[key].forEach((val) => {
            if(obj1[key] && !obj1[key].includes(val)){
              result[key].push({value: val, editType: 'Added '});
            }
          })
        }
      }
    });
  return result;
};

export const pricingDiffernceFinder = function (obj1, obj2) {
  let pricing1 = (obj1.pricing && obj1.pricing.map((charge) => {
    return {
      ...charge,
      amount: charge.amount ? parseFloat(charge.amount) : 0,
      chargePerDay: charge.chargePerDay ? parseFloat(charge.chargePerDay) : 0,
      freeDays: charge.freeDays ? parseFloat(charge.freeDays) : 0,
      unit: charge.unit ? parseFloat(charge.unit) : 0,
      finalAmount: charge.finalAmount ? parseFloat(charge.finalAmount) : 0,
    }
  })) || [];
  let pricing2 = (obj2.pricing && obj2.pricing.map((charge) => {
    return {
      ...charge,
      amount: charge.amount ? parseFloat(charge.amount) : 0,
      chargePerDay: charge.chargePerDay ? parseFloat(charge.chargePerDay) : 0,
      freeDays: charge.freeDays ? parseFloat(charge.freeDays) : 0,
      unit: charge.unit ? parseFloat(charge.unit) : 0,
      finalAmount: charge.finalAmount ? parseFloat(charge.finalAmount) : 0,
    }
  })) || [];

  const result = [];
  pricing1.forEach((charge) => {
    const charge2 = pricing2.find((d) => d.name === charge.name && d.perType === charge.perType);
    if (!charge2) {
      result.push({ ...charge, editType: 'DELETED PRICING' });
    }
  });

  pricing2.forEach((charge) => {
    const charge1 = pricing1.find((d) => d.name === charge.name && d.perType === charge.perType);
    if (!charge1) {
      result.push({ ...charge, editType: 'ADDED PRICING' })
    }
    else if (!_.isEqual(charge, charge1)) {
      result.push({ ...charge, editType: 'UPDATED PRICING' });
    }
  });

  return result;
};

export const customerSubType=[
  {label:'MVOCC',value:'MVOCC'},
  {label:'Freight Forwarder',value:'Freight Forwarder'},
  {label:' TruckBroker',value:'TruckBroker'},
  {label:'Importer/Exporter',value:'Importer/Exporter'},
  {label:'Customs Brokerage',value:'Customs Brokerage'},
]

export const toastr = {
  show: function (message, type, title, timeOut) {
    if (type === "success") {
      window.toastr.success(message, title, {
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: timeOut ? timeOut : 2000,
      });
    } else if (type === "error") {
      window.toastr.error(message, title, {
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: timeOut ? timeOut : 2000,
      });
    } else if (type === "info") {
      window.toastr.info(message, title, {
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: timeOut ? timeOut : 2000,
      });
    } else if (type === "warning") {
      window.toastr.warning(message, title, {
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: timeOut ? timeOut : 2000,
      });
    } else {
      window.toastr.info(message, title, {
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: timeOut ? timeOut : 2000,
      });
    }
  },
};

export async function getChatNotifications(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "get",
    `tms/getDriverChatUnreadNotifications`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}
export function tableHeightOffset(containerLine) {
  const box = containerLine.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

  var clientTop = docEl.clientTop || body.clientTop || 0;

  // var getOffset = box.top + scrollTop - clientTop + 10;
  return;
  // return Math.round(getOffset);
}

// export function tableRadius() {
//   var tableFixedHeader = document.querySelector('.table-header-fixed'),
//     theadTag = tableFixedHeader.querySelector('thead');
//   var tableHeader = theadTag.offsetHeight;
//   var topValue = this.offsetTop - this.scrollTop;
//   var top = Math.round(topValue + tableHeader);

//   var verticalScroll = document.querySelector(".radius-wrapper");
//   verticalScroll.setAttribute("style", "top:" + top + "px");

//   return;
// }

export function displayName(fullName) {
  if (fullName) {
    const name = fullName.split(" ");
    const lname = name[1];
    const fname = name[0];
    if (lname) {
      return (
        fname.substr(0, 1).toUpperCase() + lname.substr(0, 1).toUpperCase()
      );
    } else return fname.substr(0, 2).toUpperCase();
  }
  return "";
}

export async function connectDesktop(params) {
  const token = getStorage("token");
  const response = await HTTP(
    "post",
    `tms/connectDesktop`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export async function verifyAutoFy() {
  const token = getStorage("token");
  const response = await HTTP(
    "post",
    `tms/verifyAutoFy`,
    {},
    {
      authorization: token,
    }
  );
  return response;
}

export const getPortMarkets = () => (dispatch) => {
  const type = "SET_PORT_MARKETS";
  return new Promise((resolve, reject) => {
    HTTP("get", "setup/portMarkets")
      .then((response) => {
        dispatch({ type, payload: _.get(response, "data.data", []) });
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const isNewModal = () => {
  const trial =  JSON.parse(localStorage.getItem('loggedInUser'))
  if(trial && trial.isPaymentInline){
     return trial.isPaymentInline
  }else return false
}


export const isMilitaryTime = () => {
  const carrierDetail = JSON.parse(localStorage.getItem('userBasicSettings'));
  if(carrierDetail && carrierDetail.isMilitaryTime){
    return "HH:mm"
  } else return false
}

export const pricingSettingsOptionsTMX = [
  { value: 'ATTEMPT', label: 'Delivery Attempt at Ocean Terminal' },
  { value: 'Base Price', label: 'Line Haul' },
  { value: 'BOBTAIL', label: 'Bob Tail / Dry Run' },
  { value: 'BONDED', label: 'BONDED MOVE' },
  { value: 'C & C SPLIT', label: 'C & C SPLIT' },
  { value: 'CHANGE', label: 'CHANGE' },
  { value: 'CHASSIS', label: 'Chassis Use' },
  { value: 'COMPLIANCE', label: 'COMPLIANCE' },
  { value: 'CONGESTION', label: 'Terminal Congestion' },
  { value: 'CONRET', label: 'Container Return' },
  { value: 'CROTOL', label: 'Croxton Toll' },
  { value: 'CSXTOL', label: 'CSX Toll' },
  { value: 'DEDELIVER', label: 'Destination Delivery' },
  { value: 'DEPICKUP', label: 'Destination Pickup Export' },
  { value: 'DETENT', label: 'Detention' },
  { value: 'DPAYADJ', label: 'Driver Pay Adjustment' },
  { value: 'DRVTOL', label: 'Driver Toll' },
  { value: 'DSTYARD', label: 'Destination to Yard' },
  { value: 'E - Z PASS', label: 'E-Z Pass Use Owner Operator' },
  { value: 'EMISC', label: 'EMISC' },
  { value: 'EU', label: 'E-Z Pass' },
  { value: 'EXLOAD', label: 'Export Load' },
  { value: 'EXPDSTYARD', label: 'EXPDSTYARD' },
  { value: 'EXPORT', label: 'Export Ship Load' },
  { value: 'EZ - PASS', label: 'E-Z Pass Use Company Driver' },
  { value: 'FC', label: 'Driver Fuel Card' },
  { value: 'FLATBED', label: 'Flat Bed' },
  { value: 'FLEXITANK', label: 'FLEXITANK' },
  { value: 'FOODGRADE', label: 'Food Grade Cleanup' },
  { value: 'FUEL SURCHARGE', label: 'FUEL SURCHARGE' },
  { value: 'FUEL CARD', label: 'Fuel Card' },
  { value: 'FUELEXTRCH', label: 'Fuel Extra Charge' },
  { value: 'GENSET', label: 'GENSET' },
  { value: 'GENSETFUEL', label: 'Genset Fuel' },
  { value: 'GENSETUSE', label: 'Genset Use' },
  { value: 'GLOBTOL', label: 'Global Toll' },
  { value: 'HAZMAT', label: 'Hazardous' },
  { value: 'HNMTOL', label: 'H&M Toll' },
  { value: 'IMISC', label: 'IMISC' },
  { value: 'IMPORT', label: 'Import Terminal P/U' },
  { value: 'LAYOVER', label: 'LAYOVER ELD Miles' },
  { value: 'LINING', label: 'Line Container' },
  { value: 'LIQUOR', label: 'Liquor  Permit' },
  { value: 'LOW BOY 20', label: 'LOW BOY 20' },
  { value: 'LOW BOY 40', label: 'LOW BOY 40' },
  { value: 'MILES', label: 'Extra Miles' },
  { value: 'MISC', label: 'Misc' },
  { value: 'NJTINC', label: 'NJ Toll Increase' },
  { value: 'NYCTOLL', label: 'NY Toll Increase' },
  // { value: 'NYTINC', label: 'NYTINC' },
  { value: 'OVERWEIGHT', label: 'OVERWEIGHT PERMIT' },
  { value: 'PDETIN', label: 'PDETIN' },
  { value: 'PDETOUT', label: 'PDETOUT' },
  { value: 'PERDIEM', label: 'Per Diem' },
  { value: 'PETTY CASH', label: 'PETTY CASH' },
  { value: 'PLACARD REMOVE', label: 'PLACARD REMOVAL' },
  { value: 'PORTDELAY', label: 'Port Delay' },
  { value: 'PREPULL', label: 'Pre Pull for Storage' },
  { value: 'REEFER', label: 'Reefer Charge' },
  { value: 'REEFERFUEL', label: 'Reefer Fuel' },
  { value: 'REEFMONITOR', label: 'Reefer Monitoring' },
  { value: 'REEFSTOR', label: 'Over Night Reefer Storage' },
  { value: 'RENTREVENUE', label: 'Rent Revenue' },
  { value: 'RESIDENCE', label: 'Residence' },
  { value: 'SCALE', label: 'Scale Weight' },
  { value: 'SCALEXP', label: 'SCALEXP' },
  { value: 'SECSTOP', label: '2ND STOP' },
  { value: 'SIDELIFT', label: 'SIDELIFT' },
  { value: 'SPLITCHAS', label: 'Split Chassis' },
  { value: 'SPLITMOVE', label: 'SPLITMOVE' },
  { value: 'STEPDECK', label: 'STEPDECK' },
  { value: 'STORE', label: 'Over Night Storage' },
  { value: 'TANKEND', label: 'Tank Endorsement' },
  { value: 'TERMPICK', label: 'Export Container  P/U' },
  // { value: 'TERMYARD', label: 'Terminal to YARD' },
  { value: 'TERMYARD', label: 'Terminal to Yard' },
  { value: 'TILT', label: 'TILT' },
  { value: 'TOLL', label: 'TOLL' },
  { value: 'TRANSLOAD', label: 'TRANSLOAD' },
  { value: 'TRIAX', label: 'Tri-Axle' },
  { value: 'TRIAXNY', label: 'Tri-Axle NY' },
  { value: 'TRUCK MILES', label: 'Truck Miles' },
  { value: 'TRUCK RENT', label: 'Truck Rent' },
  { value: 'UNLOAD', label: 'Import Unload' },
  { value: 'WAREHOUS', label: 'Warehouse' },
  // { value: 'WC', label: 'Workmans Comp Exp' },
  { value: 'WCOMP', label: 'Workmans Comp Exp' },
  { value: 'Tolls', label: 'Tolls' },
]

export const pricingSettingsOptions = [
  { value: "Base Price", label: "Base Price".toUpperCase() },
  { value: "Chassis", label: "Chassis".toUpperCase() },
  { value: "Detention", label: "Detention".toUpperCase() },
  { value: "Fuel", label: "Fuel".toUpperCase() },
  { value: "demur_det_fee", label: "demur-det fee".toUpperCase() },
  { value: "Over Weight", label: "Over Weight".toUpperCase() },
  { value: "Toll", label: "Toll".toUpperCase() },
  { value: "PrePull", label: "PrePull".toUpperCase() },
  { value: "attempted_to_drop", label: "attempted to drop".toUpperCase() },
  {
    value: "attempted_to_pick_up",
    label: "attempted to pick up".toUpperCase(),
  },
  { value: "bonded_cargo_charge", label: "bonded cargo charge".toUpperCase() },
  {
    value: "container_inspection",
    label: "container inspection".toUpperCase(),
  },
  { value: "demurrage", label: "demurrage".toUpperCase() },
  { value: "domestic_move", label: "domestic move".toUpperCase() },
  { value: "drop_charge", label: "drop charge".toUpperCase() },
  { value: "dry_run", label: "dry run".toUpperCase() },
  { value: "Hazmat", label: "Hazmat".toUpperCase() },
  { value: "line_hall", label: "line haul".toUpperCase() },
  {
    value: "maintenance_and_repair",
    label: "maintenance and repair".toUpperCase(),
  },
  { value: "other", label: "other".toUpperCase() },
  { value: "per_diem", label: "per diem".toUpperCase() },
  { value: "pick_up_charge", label: "pick up charge".toUpperCase() },
  { value: "port_congestion_fee", label: "Port Congestion Fee".toUpperCase() },
  { value: "redelivery", label: "redelivery".toUpperCase() },
  { value: "scale_load", label: "scale load".toUpperCase() },
  { value: "stop_off", label: "stop off".toUpperCase() },
  { value: "storage", label: "storage".toUpperCase() },
  { value: "strap", label: "strap".toUpperCase() },
  { value: "tire_rebill", label: "tire rebill".toUpperCase() },
  { value: "traffic_fine", label: "traffic fine".toUpperCase() },
  { value: "transaction_fee", label: "transaction fee".toUpperCase() },
  { value: "tri_axle", label: "tri axle".toUpperCase() },
  { value: "washout_container", label: "washout container".toUpperCase() },
  { value: "reefer", label: "Reefer".toUpperCase() },
  { value: "bobtail_charge", label: "Bobtail Charge".toUpperCase() },
  { value: "pier_congestion", label: "Pier Congestion".toUpperCase() },
  { value: "hourly_pay", label: "Hourly Pay".toUpperCase() },
  // { value: "storage", label: "Storage" },
  { value: "transload", label: "Transload".toUpperCase() },
  { value: "placard_removal", label: "Placard Removal".toUpperCase() },
  { value: "flexi_bag_disposal", label: "Flexi Bag Disposal".toUpperCase() },
  {
    value: "Handling - Documentation Fee",
    label: "Handling - Documentation Fee".toUpperCase(),
  },
  { value: "AES Fee", label: "AES Fee".toUpperCase() },
  { value: "Ocean Freight", label: "Ocean Freight".toUpperCase() },
  { value: "Dead Run", label: "Dead Run".toUpperCase() },
  { value: "Tarp", label: "Tarp".toUpperCase() },
  { value: "Unload", label: "Unload".toUpperCase() },
  { value: "Flatbed", label: "Flatbed".toUpperCase() },
  { value: "Waiting time", label: "Waiting time".toUpperCase() },
  { value: "Shunt", label: "Shunt".toUpperCase() },
  { value: "Tolls", label: "Tolls".toUpperCase() },
  { value: "Exam Site Pick Up", label: "Exam Site Pick Up".toUpperCase() },
  { value: "pier_pass", label: "Pier-Pass".toUpperCase() },
  { value: "Chassis Split", label: "Chassis Split".toUpperCase() },
  { value: "Tank", label: "Tank".toUpperCase() },
];

export const pricingSettingsOptionsForGeorge = [
  { value: "Genset", label: "Genset" },
  { value: "Seal Charge", label: "Seal Charge" },
  { value: "Weekend Delivery", label: "Weekend Delivery" },
  { value: "Night Delivery", label: "Night Delivery" },
  { value: "Dangerous Goods", label: "Dangerous Goods" },
  { value: "Residential", label: "Residential" },
  { value: "Wait Time", label: "Wait Time" },
];
export const pricingSettingsOptionsForDHC = [
  { value: "230BOL", label: "230BOL" },
  { value: "230DEVAN", label: "230DEVAN" },
  { value: "230HANDLING", label: "230HANDLING" },
  { value: "230REWORK", label: "230REWORK" },
  { value: "230SEAL", label: "230SEAL" },
  { value: "230TRANSLOAD", label: "230TRANSLOAD" },
  { value: "233BOL", label: "233BOL" },
  { value: "233DEVAN", label: "233DEVAN" },
  { value: "233HANDLING", label: "233HANDLING" },
  { value: "233REWORK", label: "233REWORK" },
  { value: "233SEAL", label: "233SEAL" },
  { value: "233TRANSLOAD", label: "233TRANSLOAD" },
  { value: "233WHSE STORAGE", label: "233WHSE STORAGE" },
  { value: "ADVANCEMENT FEE", label: "ADVANCEMENT FEE" },
  { value: "BONBLOCKBRACE", label: "BONBLOCKBRACE" },
  { value: "BONITABOL", label: "BONITABOL" },
  { value: "BONREMOVEBLKBRC", label: "BONREMOVEBLKBRC" },
  { value: "BONREWORK", label: "BONREWORK" },
  { value: "BONSEAL", label: "BONSEAL" },
  { value: "BONTRANSLOAD", label: "BONTRANSLOAD" },
  { value: "DHE CHASSIS", label: "DHE CHASSIS" },
  { value: "DIVERSION", label: "DIVERSION" },
  { value: "FLIP CHARGE", label: "FLIP CHARGE" },
  { value: "FULFILLMENT", label: "FULFILLMENT" },
  { value: "NIGHT PICK", label: "NIGHT PICK" },
  { value: "PALLET HANDLING", label: "PALLET HANDLING" },
  { value: "PALLETS", label: "PALLETS" },
  { value: "PORTCHASSIS", label: "PORTCHASSIS" },
  { value: "PP-ADV. FEE", label: "PP-ADV. FEE" },
  { value: "PREPULL", label: "PREPULL" },
  { value: "SUNDAYGATE", label: "SUNDAYGATE" },
];
export const pricingSettingsOptionsForCobra = [
  { value: "CP Rail Surcharge", label: "CP Rail Surcharge" },
  { value: "Lift Charge", label: "Lift Charge" },
  { value: "Flip Charge", label: "Flip Charge" },
  { value: "Layover Charge", label: "Layover Charge" },
  { value: "Additional Drayage", label: "Additional Drayage" },
];

export const importChargeCodesForDHC = {
  "230BOL": "3165-60-11-00",
  "230DEVAN": "3165-60-11-00",
  "230HANDLING": "3165-60-11-00",
  "230REWORK": "3165-60-11-00",
  "230SEAL": "3165-60-11-00",
  "230TRANSLOAD": "3165-60-11-00",
  "233BOL": "3165-60-14-00",
  "233DEVAN": "3165-60-14-00",
  "233HANDLING": "3165-60-14-00",
  "233REWORK": "3165-60-14-00",
  "233SEAL": "3165-60-14-00",
  "233TRANSLOAD": "3165-60-14-00",
  "233WHSE STORAGE": "3165-60-14-00",
  "ADVANCEMENT FEE": "3210-40-10-00",
  "AES FEE": "3210-40-10-00",
  ATTEMPTED_TO_DROP: "3200-40-10-00",
  ATTEMPTED_TO_PICK_UP: "3200-40-10-00",
  "BASE PRICE": "3200-40-10-00",
  BOBTAIL_CHARGE: "3200-40-10-00",
  BONDED_CARGO_CHARGE: "3210-40-10-00",
  "CHASSIS SPLIT": "3216-40-10-00",
  CHASSIS: "3220-40-10-00",
  CONTAINER_INSPECTION: "3210-40-10-00",
  "DEAD RUN": "3200-40-10-00",
  DEMURRAGE: "1750-00-00-00",
  DETENTION: "1760-00-00-00",
  "DHE CHASSIS": "3220-40-10-00",
  DIVERSION: "3203-40-10-00",
  DOMESTIC_MOVE: "3200-40-10-00",
  DROP_CHARGE: "3200-40-10-00",
  DRY_RUN: "3200-40-10-00",
  "EXAM SITE PICK UP": "3200-40-10-00",
  FLATBED: "3200-40-10-00",
  FLEXI_BAG_DISPOSAL: "3200-40-10-00",
  "FLIP CHARGE": "3200-40-10-00",
  FUEL: "3104-40-10-00",
  FULFILLMENT: "3165-60-11-00",
  "HANDLING - DOCUMENTATION FEE": "3210-40-10-00",
  HAZMAT: "3205-40-10-00",
  HOURLY_PAY: "3200-40-10-00",
  LINE_HALL: "3200-40-10-00",
  MAINTENANCE_AND_REPAIR: "5901-40-10-95",
  "NIGHT PICK": "3204-40-10-00",
  "OCEAN FREIGHT": "3200-40-10-00",
  OTHER: "3200-40-10-00",
  "OVER WEIGHT": "3225-40-10-00",
  "PALLET HANDLING": "3165-60-11-00",
  PALLETS: "3165-60-11-00",
  PER_DIEM: "1760-00-00-00",
  PICK_UP_CHARGE: "3200-40-10-00",
  PIER_CONGESTION: "3207-40-10-00",
  PIER_PASS: "1755-00-00-00",
  PLACARD_REMOVAL: "3205-40-10-00",
  PORT_CONGESTION_FEE: "3207-40-10-00",
  PORTCHASSIS: "3220-40-10-00",
  "PP-ADV. FEE": "3210-40-10-00",
  PREPULL: "3202-40-10-00",
  REDELIVERY: "3200-40-10-00",
  REEFER: "3200-40-10-00",
  SCALE_LOAD: "3200-40-10-00",
  SHUNT: "3200-40-10-00",
  STRAP: "3200-40-10-00",
  STOP_OFF: "3200-40-10-00",
  STORAGE: "3102-40-10-00",
  SUNDAYGATE: "3200-40-10-00",
  TANK: "3200-40-10-00",
  TARP: "3200-40-10-00",
  TIRE_REBILL: "5925-40-10-95",
  TOLLS: "3200-40-10-00",
  TRANSACTION_FEE: "3210-40-10-00",
  TRANSLOAD: "3165-60-11-00",
  TRI_AXLE: "3225-40-10-00",
  UNLOAD: "3200-40-10-00",
  "WAITING TIME": "3207-40-10-00",
  WASHOUT_CONTAINER: "5955-40-10-94",
};

export const exportChargeCodesForDHC = {
  "230BOL": "3165-60-11-00",
  "230DEVAN": "3165-60-11-00",
  "230HANDLING": "3165-60-11-00",
  "230REWORK": "3165-60-11-00",
  "230SEAL": "3165-60-11-00",
  "230TRANSLOAD": "3165-60-11-00",
  "233BOL": "3165-60-14-00",
  "233DEVAN": "3165-60-14-00",
  "233HANDLING": "3165-60-14-00",
  "233REWORK": "3165-60-14-00",
  "233SEAL": "3165-60-14-00",
  "233TRANSLOAD": "3165-60-14-00",
  "233WHSE STORAGE": "3165-60-14-00",
  "ADVANCEMENT FEE": "3240-40-10-00",
  "AES FEE": "3240-40-10-00",
  ATTEMPTED_TO_DROP: "3230-40-10-00",
  ATTEMPTED_TO_PICK_UP: "3230-40-10-00",
  "BASE PRICE": "3230-40-10-00",
  BOBTAIL_CHARGE: "3230-40-10-00",
  BONDED_CARGO_CHARGE: "3240-40-10-00",
  "CHASSIS SPLIT": "3246-40-10-00",
  CHASSIS: "3250-40-10-00",
  CONTAINER_INSPECTION: "3240-40-10-00",
  "DEAD RUN": "3230-40-10-00",
  DEMURRAGE: "1750-00-00-00",
  DETENTION: "1760-00-00-00",
  "DHE CHASSIS": "3250-40-10-00",
  DIVERSION: "3256-40-10-00",
  DOMESTIC_MOVE: "3230-40-10-00",
  DROP_CHARGE: "3230-40-10-00",
  DRY_RUN: "3230-40-10-00",
  "EXAM SITE PICK UP": "3230-40-10-00",
  FLATBED: "3230-40-10-00",
  FLEXI_BAG_DISPOSAL: "3230-40-10-00",
  "FLIP CHARGE": "3230-40-10-00",
  FUEL: "3107-40-10-00",
  FULFILLMENT: "3165-60-11-00",
  "HANDLING - DOCUMENTATION FEE": "3240-40-10-00",
  HAZMAT: "3235-40-10-00",
  HOURLY_PAY: "3230-40-10-00",
  LINE_HALL: "3230-40-10-00",
  MAINTENANCE_AND_REPAIR: "5901-40-10-95",
  "NIGHT PICK": "3234-40-10-00",
  "OCEAN FREIGHT": "3230-40-10-00",
  OTHER: "3230-40-10-00",
  "OVER WEIGHT": "3256-40-10-00",
  "PALLET HANDLING": "3165-60-11-00",
  PALLETS: "3165-60-11-00",
  PER_DIEM: "1760-00-00-00",
  PICK_UP_CHARGE: "3230-40-10-00",
  PIER_CONGESTION: "3237-40-10-00",
  PIER_PASS: "1755-00-00-00",
  PLACARD_REMOVAL: "3235-40-10-00",
  PORT_CONGESTION_FEE: "3237-40-10-00",
  PORTCHASSIS: "3250-40-10-00",
  "PP-ADV. FEE": "3240-40-10-00",
  PREPULL: "3232-40-10-00",
  REDELIVERY: "3230-40-10-00",
  REEFER: "3230-40-10-00",
  SCALE_LOAD: "3230-40-10-00",
  SHUNT: "3230-40-10-00",
  STRAP: "3230-40-10-00",
  STOP_OFF: "3230-40-10-00",
  STORAGE: "3106-40-10-00",
  SUNDAYGATE: "3230-40-10-00",
  TANK: "3230-40-10-00",
  TARP: "3230-40-10-00",
  TIRE_REBILL: "5925-40-10-95",
  TOLLS: "3230-40-10-00",
  TRANSACTION_FEE: "3240-40-10-00",
  TRANSLOAD: "3165-60-11-00",
  TRI_AXLE: "3256-40-10-00",
  UNLOAD: "3230-40-10-00",
  "WAITING TIME": "3237-40-10-00",
  WASHOUT_CONTAINER: "5955-40-10-94",
};


// get pricingSettingOptions according to Carrier _id
export const getPricingSettingsOptions = () => {
  let defaultOptions = pricingSettingsOptions;
  let userData = JSON.parse(getStorage('loggedInUser'))
  let carrierId = getCarrierId();
  if (carrierId && carrierId === "6058a1f9afecf214ec2fab2b") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForGeorge,
    ];
  }
  if (carrierId && carrierId === "60747b8448cb687a0f377e82") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForDHC,
    ];
  }
  // test ltlexpress Id //ignored in Production
  if (carrierId && carrierId === "5ae8972b5eb3b02839cad0e1") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForDHC,
    ];
  }
  if (carrierId && carrierId === "6079fd99b8cedc0368c197c5") {
    defaultOptions = [
      ...pricingSettingsOptions,
      ...pricingSettingsOptionsForCobra,
    ];
  }
  if(checkTMXPermission('tmx_charge_code')){
    defaultOptions = pricingSettingsOptionsTMX
  }
  return defaultOptions;
};

export const makeOptionsForSelect = (
  object = [],
  labelKeyPath1 = "",
  labelKeyPath2 = "",
  valueKeyPath = ""
) => {
  return object
    .map((obj) => ({
      label: `${_.get(obj, labelKeyPath1)}  ${_.get(
        obj,
        labelKeyPath2,
        ""
      )}`.trim(),
      value: _.get(obj, valueKeyPath),
      allInfos: obj,
    }))
    .unique();
};
export const getDistanceFromLatLon = (
  lat1,
  lon1,
  lat2,
  lon2,
  unit = "miles"
) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km

  return unit === "miles" ? d * 0.621371 : d;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  var now = startDate.clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("MM-DD-YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export const convertUnit = (
  method = "convertedUnit",
  type = "distance",
  value = 0,
  convertTo
) => {
  const measureUnits = JSON.parse(getStorage("measureUnits"));
  const weightUnit = _.get(measureUnits, "weightUnit", "lbs");
  const distanceUnit = _.get(measureUnits, "distanceUnit", "mi");

  switch (method) {
    case "convertedUnit":
      if (type === "weight") {
        return weightUnit === "kg" ? "Kilograms" : "Pounds";
      } else {
        return distanceUnit === "km" ? "Kilometers" : "Miles";
      }

    case "convertedValue":
      if (type === "weight") {
        if (weightUnit === "kg") {
          return parseFloat((value * 0.453592).toFixed(2));
        }
        return value;
      } else {
        if (distanceUnit === "km") {
          return parseFloat(
            floatingValue.format(value * 1.60934).replace(",", "")
          );
        }

        return value;
      }

    case "convertTo":
      switch (convertTo) {
        case "km_mi":
          return parseFloat((value * 0.621371).toFixed(2));
        case "mi_km":
          return parseFloat((value * 1.60934).toFixed(2));
        case "kg_lbs":
          return parseFloat((value * 2.20462).toFixed(2));
        case "lbs_kg":
          return parseFloat((value * 0.453592).toFixed(2));
        default:
          return value;
      }
  }
};

export const floatingValue = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const treeToJsonParser = (children) => {
  let jsonLogicBit = [];
  children.map((data) => {
    if (data.type === "GROUP") {
      if (data.isNegated) {
        jsonLogicBit.push({
          "!": {
            [`${data.value.toLowerCase()}`]: treeToJsonParser(data.children),
          },
        });
      } else {
        jsonLogicBit.push({
          [`${data.value.toLowerCase()}`]: treeToJsonParser(data.children),
        });
      }
    } else if (
      [
        "EQUAL",
        "NOT_EQUAL",
        "LARGER",
        "SMALLER",
        "LARGER_EQUAL",
        "SMALLER_EQUAL",
      ].indexOf(data.operator) > -1
    ) {
      jsonLogicBit.push({
        [`${getOperator(data.operator)}`]: [
          {
            var: data.field,
          },
          data.value,
        ],
      });
    } else if (data.operator === "BETWEEN") {
      jsonLogicBit.push(getBetweenRule(data.value, data.field));
    } else if (data.operator === "NOT_BETWEEN") {
      jsonLogicBit.push({
        "!": getBetweenRule(data.value, data.field),
      });
    } else if (data.operator === "LIKE") {
      jsonLogicBit.push(getLikeRule(data.value, data.field));
    } else if (data.operator === "NOT_LIKE") {
      jsonLogicBit.push({
        "!": getLikeRule(data.value, data.field),
      });
    } else if (
      data.operator === "ANY_IN" &&
      ["shipper", "consignee", "state", "commodityProfile"].indexOf(
        data.field
      ) === -1
    ) {
      jsonLogicBit.push(getAnyRule(data.value, data.field));
    } else if (
      data.operator === "NOT_IN" &&
      ["shipper", "consignee", "state", "commodityProfile"].indexOf(
        data.field
      ) === -1
    ) {
      jsonLogicBit.push({
        "!": getAnyRule(data.value, data.field),
      });
    } else if (
      data.operator === "ANY_IN" &&
      ["shipper", "consignee", "commodityProfile"].indexOf(data.field) > -1
    ) {
      jsonLogicBit.push(getArrayAnyRule(data.value, data.field));
    } else if (
      data.operator === "NOT_IN" &&
      ["shipper", "consignee", "commodityProfile"].indexOf(data.field) > -1
    ) {
      jsonLogicBit.push({
        "!": getArrayAnyRule(data.value, data.field),
      });
    } else if (data.operator === "ANY_IN" && data.field === "state") {
      jsonLogicBit.push(getConsigneeStateRule(data.value, data.field));
    } else if (data.operator === "NOT_IN" && data.field === "state") {
      jsonLogicBit.push({
        "!": getConsigneeStateRule(data.value, data.field),
      });
    }
  });
  return jsonLogicBit;
};
const getOperator = (operator) => {
  switch (operator) {
    case "EQUAL":
      return "==";
    case "NOT_EQUAL":
      return "!=";
    case "LARGER":
      return ">";
    case "SMALLER":
      return "<";
    case "LARGER_EQUAL":
      return ">=";
    case "SMALLER_EQUAL":
      return "<=";
  }
};
const getBetweenRule = (value, field) => {
  return {
    "<=": [
      value[0],
      {
        var: field,
      },
      value[1],
    ],
  };
};
const getLikeRule = (value, field) => {
  return {
    in: [
      value,
      {
        var: field,
      },
    ],
  };
};
const getAnyRule = (value, field) => {
  return {
    in: [
      {
        var: field,
      },
      value.map((val) => JSON.parse(val).value),
    ],
  };
};
const getArrayAnyRule = (value, field) => {
  return {
    some: [
      {
        var: field,
      },
      {
        in: [
          {
            var: "",
          },
          value.map((val) => JSON.parse(val).value),
        ],
      },
    ],
  };
};
const getConsigneeStateRule = (value, field) => {
  const arr = [];
  value.forEach((val) => {
    arr.push({
      in: [
        JSON.parse(val).value,
        {
          var: field,
        },
      ],
    });
  });
  return {
    or: arr,
  };
};

export const getDriverOrderLocation = (driverOrder, createdBy, status) => {
  let from, to;
  let location = {
    from: [],
    to: [],
  };
  const getPoint = function (type, point, isNeedToGet) {
    let index = isNeedToGet
      ? _.findLastIndex(
          driverOrder,
          (D) => tmsDrops.includes(D.type) && D.prevType == "DELIVERLOAD"
        )
      : driverOrder.findIndex((D) => D.type == type);

    let searchIndex;
    if (point == "previous") {
      searchIndex = index - 1;
      if (searchIndex == -1) {
        searchIndex = index;
      }
    }
    if (point == "current") {
      searchIndex = index;
    }
    if (point == "next") {
      searchIndex = index + 1;
    }

    if (index != -1) {
      let location = driverOrder[searchIndex];
      return Object.assign({}, location, {
        company: location.company_name,
      });
    }
  };

  if (status == "DELIVERED") {
    from = getPoint("DELIVERLOAD", "previous");
    to = getPoint("DELIVERLOAD", "current");
  }
  if (status == "ARRIVED_PICKUP") {
    from = getPoint("PULLCONTAINER", "previous");
    to = getPoint("PULLCONTAINER", "current");
    if (from && !from.city) {
      from = to;
    }
    if (from && to && from._id == to._id) {
      to = getPoint("PULLCONTAINER", "next");
    }
  }
  if (status == "COMPLETED") {
    from = getPoint("RETURNCONTAINER", "previous");
    to = getPoint("RETURNCONTAINER", "current");
  }
  if (status == "NEED_TO_GET_DROP") {
    from = getPoint("HOOKCONTAINER", "previous", true);
    to = getPoint("DROPPEDCONTAINER", "current", true);
  }
  if (from || to) {
    location.from = [from];
    location.to = [to];
  }
  return location;
};

export const getFileTypeFromFilename = (item) => {
  var fileType = "";
  if (item) {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(item)[1].toLowerCase();
    if (ext === "jpeg" || ext === "jpg" || ext === "png" || ext === "bmp") {
      fileType = "IMG";
    } else if (ext === "pdf") {
      fileType = "PDF";
    } else if (ext === "csv") {
      fileType = "CSV";
    } else if (ext === "xls") {
      fileType = "EXCEL";
    } else if (ext === "doc" || ext === "docx") {
      fileType = "DOC";
    } else if (ext === "txt") {
      fileType = "TXT";
    } else {
      fileType = "OTHER";
    }
  }

  return fileType;
};

export const showForCarrier = () => {
  if (
    getStorage("currentUserRole") == "fleetmanager" &&
    config.idForCarrier.includes(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
    )
  ) {
    return true;
  } else if (
    getStorage("currentUserRole") == "carrier" &&
    config.idForCarrier.includes(JSON.parse(getStorage("loggedInUser"))._id)
  ) {
    return true;
  } else {
    return false;
  }
};

export const showForCompany = () => {
  if (config.idForCompany.includes(getStorage("currentUserID"))) {
    return true;
  } else if (
    getStorage("currentUserRole") == "fleetmanager" &&
    config.idForCompany.includes(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
    )
  ) {
    return true;
  } else if (
    getStorage("currentUserRole") == "carrier" &&
    config.idForCompany.includes(getStorage("currentUserID"))
  ) {
    return true;
  } else {
    return false;
  }
};


// Show For Terminal
export const showForTerminal = () => {
  if (config.idForTerminal.includes(getStorage("currentUserID"))) {
    return true;
  } else if (
    getStorage("currentUserRole") == "fleetmanager" &&
    config.idForTerminal.includes(
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
    )
  ) {
    return true;
  } else if (
    getStorage("currentUserRole") == "carrier" &&
    config.idForTerminal.includes(getStorage("currentUserID"))
  ) {
    return true;
  } else {
    return false;
  }
};

// get Carrier _id for Logged User With All Roles
export const getCarrierId = () => {
  const currentUser = JSON.parse(getStorage("loggedInUser"));
  if (currentUser) {
    let carrierId = currentUser._id;
    if (currentUser.role === "driver") {
      carrierId = currentUser.driver.carrier;
    }
    if (currentUser.role === "fleetmanager") {
      carrierId = currentUser.fleetManager.carrier;
    }
    if (currentUser.role === "customer") {
      carrierId = currentUser.customer.carrier;
    }
    return carrierId;
  } else {
    return false;
  }
};

export const getDate = (dayINeed, endDate) => {
  // if we haven’t yet passed the day of the week that I need:
  let endDay = moment(endDate).tz(getStorage("timeZone")).isoWeekday();
  // console.log(endDay, dayINeed);
  if (endDay <= dayINeed) {
    // then just give me this week’s instance of that day
    return moment(endDate)
      .tz(getStorage("timeZone"))
      .isoWeekday(dayINeed)
      .startOf("days")
      .toISOString();
    // let a = moment(endDate).tz(getStorage('timeZone'))
    // let b = moment().tz(getStorage('timeZone')).isoWeekday(dayINeed);
    // if (b.diff(a) > 0) {
    //    return moment().tz().add(1, 'weeks').isoWeekday(dayINeed);
    // }
  } else {
    // otherwise, give me next week’s instance of that day
    return moment(endDate)
      .add(1, "weeks")
      .isoWeekday(dayINeed)
      .startOf("days")
      .toISOString();
  }
};

export var currencies = [
  { label: "Canadian Dollar", value: "CAD" },
  { label: "US Dollar", value: "USD" },
];

export const downloadCSV = (csv, filename) => {
  var csvFile;
  var downloadLink;
  csvFile = new Blob([csv], { type: "text/csv" });
  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const downloadTableAsCSV = (filename, rows) => {
  const csv = [];

  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td.csv, th.csv");
    for (var j = 0; j < cols.length; j++) {
      if (cols && cols[j]) {
        row.push(cols[j].innerText.replace(/,|\n/g, ""));
      }
    }
    csv.push(row.join(","));
  }

  downloadCSV(csv.join("\n"), `${new Date().getTime()}-${filename}.csv`);
};

export const getDatesBetweenTwoDate = (startDate, endDate, timeZone) => {
  const datesObj = [moment(startDate).tz(timeZone).format("MM/DD/YYYY")];
  var currDate = moment(startDate);
  var lastDate = moment(endDate);
  while (currDate.add(1, "days").diff(lastDate) < 0) {
    datesObj.push(currDate.tz(timeZone).format("MM/DD/YYYY"));
  }
  const lastDateMDY = moment(endDate).tz(timeZone).format("MM/DD/YYYY");
  if (datesObj.indexOf(lastDateMDY) === -1) {
    datesObj.push(lastDateMDY);
  }
  return datesObj;
};

// find if the logged user is using Qb Online or Desktop
export const isQBOnline = () => {
  let loggedUser = JSON.parse(getStorage("loggedInUser"));
  let isOnline = true;
  if (loggedUser) {
    if (!!loggedUser.agentToken && !!loggedUser.endpoint) {
      isOnline = false;
    }
    if (!!loggedUser.access_token && !!loggedUser.refresh_token) {
      isOnline = true;
    }
  }
  return isOnline;
};


export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}



export function changeTheme(themeVal) {
  if(!themeVal){
    const currenttheme = JSON.parse(getStorage("themeSetting"));
    if(currenttheme && currenttheme.themeName){
      themeVal=themesOptions.find(theme=>theme.name===currenttheme.themeName);
    }
    if(!themeVal){
      themeVal=themesOptions[0]
    }
  }
  let themeData=themeVal.themeData?themeVal.themeData:themeVal;
  const scssVariables = Object.keys(themeData);
  scssVariables.forEach((variable) => {
    document.documentElement.style.setProperty(variable, themeData[variable]);
  });
}

export var auditKey={
  "_id": "_id",
  "updatedAt": "Updated At",
  "createdAt": "Created At",
  "type_of_load": "Load Type",
  "status": "Status",
  "preSetOrderStatus": "Preset Order Status",
  "caller": "Caller",
  "addedBy": "Added By",
  "carrier": "Carrier",
  "reference_number": "Reference Number",
  "distance": "Distance",
  "pricing": "Pricing",
  "callerName": "Customer Name",
  "caller": "Customer ",
  "callerInfo": "Customer Info",
  "shipperName": "Shipper Name",
  "shipperAddress": "Shipper Address",
  "shipperInfo": "Shipper Info",
  "consigneeName": "Consignee Name",
  "consigneeAddress": "Consignee Address",
  "consigneeInfo": "Consignee Info",
  "weight": "Weight",
  "expense": "expense",
  "customerEmployee": "Customer Employee",
  "consignee": "Consignee",
  "shipper": "Port",
  "carrierHold": "Carrier Hold",
  "brokerHold": "Broker Hold",
  "overWeight": "Over Weight",
  "isConfirm": "Confirm",
  "remainAmount": "Remain Amount",
  "totalAmount": "Total Amount",
  "paidAmount": "Paid Amount",
  "totalWeight": "Total Weight",
  "isHot": "Hot",
  "isReadyForPickup": "Ready To Return",
  "isloadStartable": "Load Startable",
  "radio": "Radio",
  "isDeleted": "Is Deleted",
  "items": "Items",
  "additionalPricing": "Additional Pricing",
  "deliveryTimes": "Delivery Times",
  "pickupTimes": "Pickup Times",
  "hot": "Hot",
  "hazmat": "Hazmat",
  "totalMiles": "Total Miles",
  "bolbkg": "Master Bill of Lading",
  "containerNo": "Container #",
  "containerOwner": "Container Owner",
  "containerOwnerName": "Container Owner Name",
  "containerSize": "Container Size",
  "containerSizeName": "Container Size",
  "containerType": "Container Type",
  "containerTypeName": "Container Type",
  "secondaryReferenceNo": "Reference #",
  "shipmentNo": "Shipment No",
  "scac": "Scac",
  "vessel": "Vessel",
  "caution": "caution",
  "custom": "Custom",
  "freight": "Freight",
  "errorMessage": "Error Message",
  "lastScrappedAt": "Data Last Scrapped Date",
  "lastContainerTracedDate": "Container  Data Last Traced Date",
  "errorDate": "Error Date",
  "lastContainerErrorDate":"Last Traced Error at",
  "lastFreeDay":"Last Free Day",
  "chassisType":"Chassis Type",
  "chassisOwner":"Chassis Owner",
  "chassisNo":"Chassis #",
  "chassisSize":"Chassis Size",
  "bolbkg":"Booking #",
  "purchaseOrderNo":"Purchase Order #",
  "emptyOrigin":"Empty Origin",
  "trailer":"Trailer",
  "lastFreeDay":"ERD",
  "callerPONo":"Pick Up #",
  "cutOff":"Return Day",
  "emptyDay":"Empty Date",
  "returnToTime":"Return To",
  "returnFromTime":"Return From",
  "chassisTermination":"Chassis Termination",
  "chassisPick":"Chassis PickUp",
  "reservationNo":"Reservation #",
  "returnNo":"Return #",
  "appointmentNo":"Appointment #",
  "releaseNo":"Voyage",
  "deliveryOrderNo":"Vessel Name",
  "sealNo":"Seal #",
  "doNo":"House Bill of Lading",
  "liquor":"Liquor",
  "routeType":"Route Type",
  "temperature":"Temperature",
  "trailerType":"Trailer Type",
  "trailerSize":"Trailer Size",
  "grayChassisNo": "Gray Chassis #",
  "grayContainerOwner": "Gray Container Owner",
  "grayContainerType": "Gray Container Type",
  "grayContainerSize": "Gray Container Size",
  "grayChassisOwner": "Gray Chassis Owner",
  "grayChassisSize": "Gray Chassis Size",
  "grayChassisType": "Gray Chassis Type",
  "grayContainerNo": "Gray Container #",
  "isContainerConfirm": "Container Confirm",
  "isGrayPool": "Gray Pool",
  "consigneeInfo": "Consignee",
  "shipperInfo": "Port",
  "loadCompletedAt": "Load Completed Date",
  "ingateDate":"INGate date",
  "outgateDate":"OutGate date",
  "availableDate":"Available Date",
  "dischargedDate":"Discharge Date",
}

export const selectedCharge  = (chargeCodeList, name) =>{
  let charge = chargeCodeList.find(
   (pso) => pso.value == name
  ) 
  if(charge){
    return {label :  charge.chargeName, value : charge.value}
  }else{
    return {}
  }
}

export async function addAgentToken() {
  const token = getStorage("token");
  let data={isConfirm:true};
  const response = await HTTP("post", `tms/qbDesktopCreateAgentToken`, data, {
    authorization: token,
  });
  return response;
}

export async function refreshAgentToken() {
  const token = getStorage("token");
  const response = await HTTP("get", `tms/qbDesktopCheckConnectedAgentToken`, null, {
    authorization: token,
  });
  return response;
}


export async function authorizeAutoFyToken() {
  const token = getStorage("token");
  const response = await HTTP("post", `tms/qbDesktopSyncAutofy`, null, {
    authorization: token,
  });

  return response;
}
// ****** Dispatch Filter Start
export const setupDispatchSessionTab = (state) => {
  const views = getSessionStorage("tabDispatchViews", true)
  if(!views) {
    setSessionStorage("tabDispatchViews", [
      {
        id: 'default',
        ...convertStateToViewParams(state),
      }
    ], true);
    setSessionStorage("activeDispatchView", "default");
  }
}

export const syncSessionDispatchWithDb = () => {
  const dispatchViews = getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews"));
  const sessionViews = getSessionStorage("tabDispatchViews", true);
  if(!dispatchViews || dispatchViews==="undefined") return
  setSessionStorage("tabDispatchViews", [
    sessionViews ? sessionViews[0] : {id : 'deafult'},
    ...dispatchViews
  ], true);
}

// Update each tab fiters attributes
// currentView is view.id or view_id
const updateDispatchView = (currentView, keys, values, clearAll=false) => {
  const views = getSessionStorage("tabDispatchViews", true);
  if(!currentView) currentView = "default";

  const selectedViewIdx = views && views.findIndex((view) => {
    return (view.id == currentView) || (view._id == currentView);
  })
  if(clearAll){
    views[selectedViewIdx] = {id: currentView} 
    setSessionStorage("tabDispatchViews", views, true)
    setSessionStorage("activeDispatchView", currentView);
    
    return
  }
  if(typeof selectedViewIdx === 'number' && selectedViewIdx !== -1){

    keys.map((key, index) => {
      views[selectedViewIdx][key] = values[index];
    })

    setSessionStorage("tabDispatchViews", views, true);
    setSessionStorage("activeDispatchView", currentView);

  }
}
// update Tab specific dispatcher State to localstorage and session storage
export const updateSessionDispatchView = (state, clearAll=false) => {
  const viewParams = convertStateToViewParams(state);
  const keys = Object.keys(viewParams);
  const values = Object.values(viewParams); 
  // convertStateToDispatchURL(state);
  // Clear filter query param
  // const baseLocation = document.location.href.split("?")[0]
  // window.history.pushState({}, null, `${baseLocation}`);

  updateDispatchView(state.currentView, [...keys],[...values], clearAll);
}

export const updateQueryString = (filterParams) => {
  const baseLocation = document.location.href.split("?")[0];
  if (!_.isEmpty(filterParams)) {
    const qs = '?' + Object.keys(filterParams)
    .map(key => `${key}=${encodeURIComponent(filterParams[key])}`)
    .join('&')
    window.history.pushState({}, null, `${baseLocation}${qs}`);
  } else {
    window.history.pushState({}, null, `${baseLocation}`);
  }
}

export const getObjFromQueryString = (querystring) => {
  const queryObj = {};
  let queries = querystring.split('&');
  queries.forEach((_str) => {
    const field = _str.split('=');
    queryObj[field[0]] = decodeURIComponent(field[1]);
  });
  return queryObj;
}

export const convertStateToDispatchURL = (state) => {
  convertStateToQueryString(state).then((filterParams) => {
    updateQueryString(filterParams)
  })
  // const str = encryptString(JSON.stringify(filterParams))
  // const dec = descryptString(str)
  // const qs = '?' + "filter=" + encodeURIComponent(str)

}
// Get current tab dispatcher filter state from localstorage
export const getSessionDispatchView = (viewId, isState=true) => {
  
  let views = (getStorage("dispatcherViews") && JSON.parse(getStorage("dispatcherViews")))
  if(!views || views==="undefined") 
    return null
  const idx = views.findIndex((view) => {
    return view.id === viewId || view._id === viewId
  });
  if(idx===-1) return null
  
  let ret = views[idx];
  if(isState) {
    ret = convertViewParamsToState(views[idx])
  }
  return ret;
}



// Convert state loaded form localstorage to dispatcherView format
export const convertStateToViewParams = (state, saveEmpty=true) => {
  let viewParams = {}
  if(state.currentView){
    viewParams['id'] = state.currentView
  }

  viewParams['viewName'] = state.currentViewName ? state.currentViewName.trim() : "default"
  viewParams['isDefaultView'] = state.isDefaultView
  viewParams['selectedStatuses'] = state.selectedStatuses
  viewParams['subFilter'] = state.subFilter2
  if(state.vesselName && state.vesselName !== "")
    viewParams['vesselName'] = state.vesselName
  else
    if(saveEmpty)  
      viewParams['vesselName'] = ""

  if (state.grid3TypeOfLoad && state.grid3TypeOfLoad.length > 0)
    viewParams.type_of_load = state.grid3TypeOfLoad.map((obj) => {
      return obj.value;
    });
  else
    if(saveEmpty)
      viewParams.type_of_load = []

  if (state.grid3caller && state.grid3caller.length > 0)
    viewParams.caller = state.grid3caller.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.caller = []

  if (state.grid3shipper && state.grid3shipper.length > 0)
    viewParams.shipper = state.grid3shipper.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.shipper = []

  if (state.grid3consignee && state.grid3consignee.length > 0)
    viewParams.consignee = state.grid3consignee.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.consignee = []
  if (state.grid3emptyorigin && state.grid3emptyorigin.length > 0)
    viewParams.emptyOrigin = state.grid3emptyorigin.map((obj) => {
      return obj.allInfos;
    });
  else
    if(saveEmpty)
      viewParams.emptyOrigin = []

  if (state.grid3ContainerSize && state.grid3ContainerSize.length > 0)
    viewParams.containerSize = state.grid3ContainerSize.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.containerSize = []

  if (state.grid3ContainerType && state.grid3ContainerType.length > 0)
    viewParams.containerType = state.grid3ContainerType.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.containerType = []

  if (state.grid3SSL && state.grid3SSL.length > 0)
    viewParams.containerOwner = state.grid3SSL.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.containerOwner = []

  if (state.grid3driver && state.grid3driver.length > 0)
    viewParams.drivers = state.grid3driver.map((obj) => {
      return obj.allInfo;
    });
  else
    if(saveEmpty)
      viewParams.drivers = []

  if (state.grid3filterStatusField && state.grid3filterStatusField.length > 0)
    viewParams.status = state.grid3filterStatusField.map((obj) => {
      return obj.value;
    });
  else
    if(saveEmpty)
      viewParams.status = []

  if (state.subFilter)
    viewParams.status = viewParams.status && viewParams.status.length > 0 ?
                        _.union(viewParams.status, [state.subFilter]) : [state.subFilter]
  
  if(state.pickUpLabel)
    viewParams["pickUpLabel"] = state.pickUpLabel;
  else 
    viewParams["pickUpLabel"] = undefined

  if (state.pickupFrom && state.pickUpTo) {
    viewParams["pickupFrom"] = moment(state.pickupFrom).toISOString();
    viewParams["pickUpTo"] = moment(state.pickUpTo).toISOString();
  } else {
    viewParams["pickupFrom"] = undefined
    viewParams["pickUpTo"] = undefined
  }

  if(state.deliveryLabel)
    viewParams["deliveryLabel"] = state.deliveryLabel;
  else 
    viewParams["deliveryLabel"] = undefined  

  if (state.deliveryFrom && state.deliveryTo ) {
    viewParams["deliveryFrom"] = moment(state.deliveryFrom).toISOString();
    viewParams["deliveryTo"] = moment(state.deliveryTo).toISOString(); 
  } else {
    viewParams["deliveryFrom"] = undefined
    viewParams["deliveryTo"] = undefined
  }


  if(state.returnAPTLabel)
    viewParams["returnAPTLabel"] = state.returnAPTLabel;
  else 
    viewParams["returnAPTLabel"] = undefined  
  
  if (state.returnAPTFrom && state.returnAPTTo) {
    viewParams["returnAPTFrom"] = moment(state.returnAPTFrom).toISOString();
    viewParams["returnAPTTo"] = moment(state.returnAPTTo).toISOString();
  } else {
    viewParams["returnAPTFrom"] = undefined
    viewParams["returnAPTTo"] = undefined
  }

  if(state.lastFreeDayLabel)
    viewParams["lastFreeDayLabel"] = state.lastFreeDayLabel;
  else 
    viewParams["lastFreeDayLabel"] = undefined  
    
  if (state.lastFreeDayFrom && state.lastFreeDayTo) {
    viewParams["lastFreeDayFrom"] = moment(state.lastFreeDayFrom).toISOString();
    viewParams["lastFreeDayTo"] = moment(state.lastFreeDayTo).toISOString();
  } else {
    viewParams["lastFreeDayFrom"] = undefined
    viewParams["lastFreeDayTo"] = undefined
  }

  if(state.freeReturnLabel)
    viewParams["freeReturnLabel"] = state.freeReturnLabel;
  else 
    viewParams["freeReturnLabel"] = undefined  
      
  if (state.freeReturnFrom && state.freeReturnTo) {
    viewParams["freeReturnFrom"] = moment(state.freeReturnFrom).toISOString();
    viewParams["freeReturnTo"] = moment(state.freeReturnTo).toISOString();
  } else {
    viewParams["freeReturnFrom"] = undefined
    viewParams["freeReturnTo"] = undefined
  }


  if(state.cutOffLabel)
    viewParams["cutOffLabel"] = state.cutOffLabel;
  else 
    viewParams["cutOffLabel"] = undefined  
        
  if (state.cutOffFrom && state.cutOffTo) {
    viewParams["cutOffFrom"] = moment(state.cutOffFrom).toISOString();
    viewParams["cutOffTo"] = moment(state.cutOffTo).toISOString();
  } else {
    viewParams["cutOffFrom"] = undefined
    viewParams["cutOffTo"] = undefined
  }


  if (state.terminal && state.terminal.value) {
    viewParams["terminal"] = state.terminal.allInfo;
  } else {
    if(saveEmpty)
      viewParams["terminal"] = {}
  }
  if (state.grid3createdByUser) {
    viewParams["addedBy"] = state.grid3createdByUser.map(
      (user) => user.allInfo
    );
  } else {
    if(saveEmpty)
      viewParams["addedBy"] = []
  }
  if(state.grid3csr){
    viewParams['assignedCSR'] = state.grid3csr
  } else {
    if(saveEmpty)
      viewParams["assignedCSR"] = []
  }
  viewParams["isReadyForPickup"] = state.isReadyForPickupShow
  if(state.sortBy){
    viewParams["sortBy"] = state.sortBy
  } else {
    viewParams["sortBy"] = {createdAt: -1}
  }

  // EXTRA ADDED

  // // -------------------- add subcard filter --------------------
  if (state.filterBySubCard) {
    viewParams["filterBySubCard"] = state.filterBySubCard;
  }



  return viewParams
}

export const filterAppointmentMap = (from, to, label) => {
  let updatedLabel = null, updatedFrom = null, updatedTo = null;
  if(label){
    updatedLabel = label
    if(updatedLabel === "Custom Range") {
      if(from){
        updatedFrom = from
      }
      if(to){
        updatedTo = to
      }
    } else {
      updatedFrom = RANGES[updatedLabel][0]
      updatedTo = RANGES[updatedLabel][1]
    }
  }
   else {
    if(from){
      updatedFrom = from
    }
    if(to){
      updatedTo = to
    }
  }
  return [updatedFrom, updatedTo, updatedLabel]
} 

export const convertViewParamsToState = (viewParams) => {
  let updatedDrivers = [],
  updatedCallers = [], 
  updatedShippers = [], 
  updatedConsignees = [], 
  updatedEmptyOrigins = [], 
  updatedAddedBy = [],
  updatedContainerType = [],
  updatedContainerSize = [],
  updatedContainerOwner = [],
  updatedTerminal = [],
  updatedSelectedStatuses = [],
  updatedTypeOfLoad = [],
  updatedStatus = [],
  updatedCSR = [],
  updatedSortBy = { createdAt: -1 },
  updatedFilterBySubCard = [],
  updatedVesselName = '',
  dateFilter = 'all',
  selectedDate = null;
  
  if(viewParams.drivers){
    updatedDrivers = viewParams.drivers.map((d) =>{
      return {
        value: d._id,
        label: `${d.name} ${d.lastName}`.trim(),
        allInfo: d
      }
    })
  }
  if(viewParams.caller) {
    updatedCallers = makeOptionsForSelect(
      viewParams.caller,
      "company_name",
      "address.address",
      "_id") 
  }
  if(viewParams.shipper){
    updatedShippers = makeOptionsForSelect(
      viewParams.shipper,
      "company_name",
      "address.address",
      "_id")
  }
  if(viewParams.consignee){
    updatedConsignees = makeOptionsForSelect(
      viewParams.consignee,
      "company_name",
      "address.address",
      "_id")
  }
  if(viewParams.emptyOrigin){
    updatedEmptyOrigins =  makeOptionsForSelect(
      viewParams.emptyOrigin,
      "company_name",
      "address.address",
      "_id")
  }
  if(viewParams.addedBy){
    updatedAddedBy = viewParams.addedBy.map((d) => {
      return {
        value: d._id,
        label: `${d.name} ${
          d.name.includes(d.lastName) ? "" : d.lastName 
        }`.trim(),
        allInfo: d,
      }
    })
  }
  if(viewParams.containerType){
    updatedContainerType = viewParams.containerType.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      }
    })
  }
  if(viewParams.containerSize){
    updatedContainerSize = viewParams.containerSize.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      }
    })
  }
  if(viewParams.containerOwner){
    updatedContainerOwner = viewParams.containerOwner.map((d) => {
      return {
        value: d._id,
        label: d.company_name,
        allInfo: d,
      }
    })
  }
  if(viewParams.terminal){
    updatedTerminal = { 
        value: viewParams.terminal._id,
        label: viewParams.terminal.name,
        allInfo: viewParams.terminal,
    }
  }
  if(viewParams.selectedStatuses){
    updatedSelectedStatuses = viewParams.selectedStatuses
  }

  // Pickup
 const [
  updatedPickUpFrom,
  updatedPickUpTo,
  updatedPickUpLabel
 ] = filterAppointmentMap(
  viewParams.pickupFrom, 
  viewParams.pickUpTo, 
  viewParams.pickUpLabel
  )
  // Delivery
 const [
  updatedDeliveryFrom,
  updatedDeliveryTo,
  updatedDeliveryLabel
 ] = filterAppointmentMap(
  viewParams.deliveryFrom, 
  viewParams.deliveryTo, 
  viewParams.deliveryLabel
  )
  // ReturnAPT
  const [
    updatedReturnAPTFrom,
    updatedReturnAPTTo,
    updatedReturnAPTLabel
   ] = filterAppointmentMap(
    viewParams.returnAPTFrom, 
    viewParams.returnAPTTo, 
    viewParams.returnAPTLabel
    )
  // LastFreeDay
  const [
    updatedLastFreeDayFrom,
    updatedLastFreeDayTo,
    updatedLastFreeDayLabel
   ] = filterAppointmentMap(
    viewParams.lastFreeDayFrom, 
    viewParams.lastFreeDayTo, 
    viewParams.lastFreeDayLabel
    )
  // FreeReturn
  const [
    updatedFreeReturnFrom,
    updatedFreeReturnTo,
    updatedFreeReturnLabel
   ] = filterAppointmentMap(
    viewParams.freeReturnFrom, 
    viewParams.freeReturnTo, 
    viewParams.freeReturnLabel
    )
  // CutOff
  const [
    updatedCutOffFrom,
    updatedCutOffTo,
    updatedCutOffLabel
   ] = filterAppointmentMap(
    viewParams.cutOffFrom, 
    viewParams.cutOffTo, 
    viewParams.cutOffLabel
    )
  if (viewParams.type_of_load){ 
    updatedTypeOfLoad = viewParams.type_of_load.map((d) =>{
      return {
        value: d,
        label: d
      }
    })
  }
  if (viewParams.status)
    updatedStatus = viewParams.status.map((d) => {
      return {
        value: d,
        label: d
      }
    });
  if(viewParams.assignedCSR){
    updatedCSR = viewParams.assignedCSR
  }
  if(viewParams.sortBy){
    if(typeof viewParams.sortBy == "string")
      updatedSortBy = JSON.parse(viewParams.sortBy)
    else
      updatedSortBy = viewParams.sortBy
  }
  if(viewParams.dateFilter){
    dateFilter = viewParams.dateFilter;
    selectedDate = getSelectedDate(dateFilter)
  }
    // EXTRA ADDED

  // // -------------------- add subcard filter --------------------
  if(viewParams.filterBySubCard && viewParams.filterBySubCard.length > 0){
    updatedFilterBySubCard = viewParams.filterBySubCard
  }
  if(viewParams.vesselName){
    updatedVesselName = viewParams.vesselName
  }

  const subFiter = _.intersection(["AVAILABLE", "PENDING"], updatedStatus.map((status) => status.value))
  let loadsFilter = {
    grid3TypeOfLoad: updatedTypeOfLoad,
    grid3caller: updatedCallers,
    grid3consignee: updatedConsignees,
    grid3emptyorigin: updatedEmptyOrigins,
    grid3driver: updatedDrivers,
    grid3shipper: updatedShippers,
    grid3filterStatusField: updatedStatus,
    grid3ContainerType: updatedContainerType,
    grid3ContainerSize: updatedContainerSize,
    grid3SSL: updatedContainerOwner,
    grid3csr : updatedCSR,
    grid3createdByUser: updatedAddedBy,
    vesselName: updatedVesselName
  };
  return {
    currentView : viewParams._id || viewParams.id,
    currentViewName : viewParams.viewName, 
    grid3caller : updatedCallers, 
    grid3driver : updatedDrivers, 
    grid3shipper : updatedShippers,
    grid3consignee : updatedConsignees,
    grid3emptyorigin : updatedEmptyOrigins,
    grid3createdByUser : updatedAddedBy,
    grid3ContainerType : updatedContainerType,
    grid3ContainerSize : updatedContainerSize,
    grid3SSL : updatedContainerOwner,
    terminal : updatedTerminal,
    selectedStatuses : updatedSelectedStatuses,
    pickupFrom : updatedPickUpFrom,
    pickUpTo : updatedPickUpTo,
    deliveryFrom : updatedDeliveryFrom,
    deliveryTo : updatedDeliveryTo,
    returnAPTFrom: updatedReturnAPTFrom,
    returnAPTTo: updatedReturnAPTTo,
    lastFreeDayFrom: updatedLastFreeDayFrom,
    lastFreeDayTo: updatedLastFreeDayTo,
    freeReturnFrom: updatedFreeReturnFrom,
    freeReturnTo: updatedFreeReturnTo,
    cutOffFrom: updatedCutOffFrom,
    cutOffTo: updatedCutOffTo,
    pickUpLabel: updatedPickUpLabel,
    deliveryLabel: updatedDeliveryLabel,
    returnAPTLabel: updatedReturnAPTLabel,
    lastFreeDayLabel: updatedLastFreeDayLabel,
    freeReturnLabel: updatedFreeReturnLabel,
    cutOffLabel: updatedCutOffLabel,
    grid3TypeOfLoad : updatedTypeOfLoad,
    isReadyForPickupShow : viewParams.isReadyForPickup,
    grid3filterStatusField : updatedStatus,
    loadsFilter: loadsFilter,
    grid3csr : updatedCSR,
    sortBy: updatedSortBy,
    isDefaultView: viewParams.isDefaultView || false,
    subFilter2: viewParams.subFilter,
    subFilter: subFiter && subFiter.length>0 ? subFiter[0] : undefined,
    filterBySubCard: updatedFilterBySubCard,
    vesselName: updatedVesselName,
    dateFilter: dateFilter,
    selectedDate : selectedDate,
  }
}

export const convertStateToQueryString = async (state) => {
  let filterParams = {};
  const params = convertStateToViewParams(state, false)
  const data = await createDispatcherFilter({
    filter: JSON.stringify(params)
  })
  if(data && data.filterId) {
    filterParams["filter"] = data.filterId
  }
  if(state.currentView) {
    filterParams["view"] = state.currentView
  } else {
    filterParams["view"] = "default"
  }
  return filterParams
}
export const eligibleFile =[
  "png" ,
  "jpg" ,
  "pdf" ,
  "jpeg",
  "csv" ,
  "xls" ,
  "eml" ,
  "xlsx"
  ]

// ****** Dispatch Filter End
export const fileNameTypeFromUrl= (url) => {
  let fileName = ""
  let fileType = ""
  if(url){
    fileName = url.split(/[?#]/)[0].split("/").pop()
    fileType = fileName && fileName.split(".").pop();
  }
  return [fileName, fileType]
} 

export const getBasicSettings = () => {
  return new Promise((resolve, reject) => {
    let url = "user/get-basic-settings";
    const token = getStorage("token");
    HTTP("get", url, null, {
      Authorization: token,
    }).then((result) => {
      setStorage("userBasicSettings", JSON.stringify(result.data.data));
      resolve(result?.data?.data);
    })
      .catch((error) => {
        reject(error);
      });
  });
}


// ****** Dispatch Filter End
export const isQuickbooksUser = () => {
  const user = getStorage("loggedInUser") ? JSON.parse(getStorage("loggedInUser")) : null
  if(!user) return false

  const isQbOnline =  !!user.qbConnection || (!!user.access_token && !!user.refresh_token);
  const isQbDesktop = (!!user.agentToken && !!user.endpoint);
  return isQbOnline || isQbDesktop;
}

export const allowedRoutes = ["dispatcher", "customer_service_load_info","customer_service_billing","customer_service_tendered_loads",
  "other_profile_driver","other_assign_truck","safety_truck_owner","other_driver_pay_rate","billing_settlement_setting","other_dynamic_driver_pay",
  "settings_general_info","settings_my_apps","other_profile_customer","customer","other_customer_rate","other_group_customer","billing_account_receivable"
];