import axios from 'axios';
import config from '../config';
import { getStorage } from './Common.services';

export function HTTP(method, uri, data, headers = null, fullUrl) {
    return new Promise((resolve, reject) => {
        let url;
        if (!uri && fullUrl) {
            url = fullUrl;
        } else {
            url = `${config.baseUrl}${uri}`.trim();
        }

        const query = {
            method: method,
            url: url
        }

        if (headers != null) {
            query.headers = Object.assign({ browsertoken: getStorage("browsertoken") }, headers);
        }
        if (method === 'post' || method === 'put' || method === 'delete'|| method === 'PATCH') {
            query.data = data;
        }
        axios(query).then(function(response) {
          resolve(response);
        })
        .catch((error) => {
            reject(error)

        })
    })
}