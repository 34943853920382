import React, { useEffect, useState } from 'react'
import pdfjsLib from "pdfjs-dist";
import { Modal } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import $ from "jquery";
import Select from "react-select";
import FileViewer from "react-file-viewer";
import { IconTimes, IconUpload, IconInfo, IconCheck, IconTrash  } from '../../../../../../Components/Common/Icons';
import { toastr } from '../../../../../../services';
import UploadedDocuments from '../../../../Load/DocumentList';
import { deleteCustomerDoc } from '../../../../services';
import { ImageUploader } from '../../../../../../Components/Common/Components/Component';
import { smallSelectStyle } from '../../../../../../assets/js/select-style';
import FullScreenUploader from '../../../../../../Components/FullScreenUploader';
import confirm from '../../../../../../Components/Common/ConfirmAert';
import { uploadDocumentCustomer } from '../../../actionCreators';

const DocumentCustomer = (props) => {
    const [documents, setDocuments] = useState([]);
    const [isShowFileUploadModal, setIsShowFileUploadModal] = useState(false);
    const [documentFile, setdocumentFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [fileType, setFileType] = useState("");
    const [isSaving, setIsSaving] = useState(true);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [csvPreviewUrl, setCsvPreviewUrl] = useState(null);
    const [xlsPreviewUrl, setXlsPreviewUrl] = useState(null);
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [type, setType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showFullDropScreen, setShowFullDropScreen] = useState(false);

    useEffect(() => {
        setShowFullDropScreen(true);
        setDocuments([...props.Selected_customer?.documents])
    }, [])

    const handleShowFileUploadModal = () => {
        setIsShowFileUploadModal(true);
        setdocumentFile(null);
        setProgress(0);
        setFileType(null);
        setIsSaving(true);
    };

    const deleteDocument = (id) => {
        confirm(
            "Remove Document?",
            "Are you sure you want to remove this document?",
            (confirm) => {
                if (confirm) {
                    deleteEquipmentDoc({
                        docId: id,
                        _id: props.Selected_customer._id,
                    })
                }
            }
        );
    };
    const deleteEquipmentDoc = (params) => {
        deleteCustomerDoc(params)
            .then((response) => {
                const documents = response?.data?.data?.documents;
                setDocuments(documents);
                setImagePreviewUrl("");
                setSelectedDoc(null);
                toastr.show("Successfully deleted", "success");
            })
            .catch((error) => console.log(error));
    };
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const uploadResult = (file, preview, type) => {
        if (file.type.match("image/png") || file.type.match("image/jpg") || file.type.match("image/jpeg") || file.type.match("application.pdf") ||
            file.type.match("text/csv") || file.type.match("application/vnd.ms-excel") || file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {

            setIsShowFileUploadModal(true);
            // this.setState({ showFileUploadModal: true })
            if (file.type.indexOf("image") == -1) {
                type = file.type.split("/")[1];
            }
            setTimeout(() => {
                setProgress(100);
                setIsSaving(false);
                // this.setState({ progress: 100, isSaving: false });
            }, 1000);

            setdocumentFile(file);
            if(type === "image") {
                setImagePreviewUrl(preview);
                setPdfPreviewUrl(null);
                setCsvPreviewUrl(null);
                setXlsPreviewUrl(null);
            }
            setType(preview);
            if (type == "pdf") {
                let pdfUrl = { pre: preview, name: file.name };
                setPdfPreviewUrl(pdfUrl)
                setImagePreviewUrl(null);
                setCsvPreviewUrl(null);
                setXlsPreviewUrl(null);
                let base64result = preview.split(",")[1];
                var pdfData = atob(base64result);
                var loadingTask = pdfjsLib.getDocument({ data: pdfData });
                loadingTask.promise.then(function (pdf) {
                    // Fetch the first page.
                    pdf.getPage(1).then(function (page) {
                        var scale = 1.5;
                        var viewport = page.getViewport(scale);
                        // Prepare canvas using PDF page dimensions.
                        var canvas = document.getElementById("the-canvas");
                        var context = canvas.getContext("2d");
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        // Render PDF page into canvas context.
                        var renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };
                        page.render(renderContext);
                    });
                });
            } else if (type === "csv") {
                let csvUrl = { pre: preview, name: file.name };
                setCsvPreviewUrl(csvUrl);
                setXlsPreviewUrl(null);
                setPdfPreviewUrl(null);
                setImagePreviewUrl(null);
            } else if (type === "vnd.ms-excel" || type === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                let xlsUrl = { pre: preview, name: file.name };
                setXlsPreviewUrl(xlsUrl);
                setCsvPreviewUrl(null);
                setPdfPreviewUrl(null);
                setImagePreviewUrl(null);
            }
        } else {
            toastr.show("Please enter only PDF, CSV, XLS, XLSX and/or Image (JPG, PNG, JPEG ) file formats.", "error");
            return;
        }
    };

    const handleDocumentUpload = () => {
        const formData = new FormData();
        if(!fileType) {
            return toastr.show("Please select customer type", "error");
        }
        formData.append("_id", props.Selected_customer._id);
        formData.append("document", documentFile);
        formData.append("fileType", fileType);
        formData.append("filename", `${new Date().getTime().toString()}`);
        setIsLoading(true)
        uploadDocumentCustomer(formData).then((res) => {
            setDocuments(res?.documents);
            setIsShowFileUploadModal(false);
            setFileType("");
            setIsSaving(true);
            setCsvPreviewUrl(null);
            setXlsPreviewUrl(null);
            setPdfPreviewUrl(null);
            setImagePreviewUrl(null);
            setIsLoading(false);
            toastr.show("This document has been uploaded.", "success");
        }).catch((error) => console.log(error));
    }
    return (
        <div>
            <React.Fragment>
                <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                    <h4 className="mb-0 font-20">
                        {documents && documents.length === 0 ? "No " : documents?.filter((doc) => doc.checked).length} Documents
                    </h4>
                    <button
                        className="ml-auto btn btn-primary"
                        onClick={() => handleShowFileUploadModal()}
                    >
                        <IconUpload className="mr-2" />
                        Upload Document
                    </button>
                </div>
                {documents && (
                    <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                        <UploadedDocuments
                            listDocuments={documents.filter((doc) => doc.checked)}
                            deleteDocument={deleteDocument}
                            onDocumentLoadSuccess={onDocumentLoadSuccess}
                            selectedDoc={selectedDoc}
                            setSelectedDoc={(docIndex) => {
                                setSelectedDoc(docIndex);
                            }}
                            uploadResult={uploadResult}
                            multi={false}
                            type={"image"}
                        />
                    </div>
                )}
                <Modal show={isShowFileUploadModal} centered animation={false}>
                    <Modal.Header>
                        <Modal.Title>Upload Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card p-20 mb-0">
                            <ImageUploader
                                uploadResult={uploadResult}
                                type={"image"}
                                className="custom-file-input"
                            />

                            {documentFile && (
                                <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                                    <IconUpload className="mr-20" />
                                    <div className="w-100">
                                        <div className="font-medium d-flex justify-content-between w-100 mb-1">
                                            <div>{documentFile.name}</div>
                                            <div className="ml-auto">{progress}%</div>
                                        </div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar bg-primary"
                                                role="progressbar"
                                                aria-valuenow={`${progress}`}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: `${progress}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                    <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                                        <span
                                            onClick={() => {
                                                setdocumentFile(null);
                                                setImagePreviewUrl(null);
                                                setPdfPreviewUrl(null);
                                                setProgress(0);
                                                setIsSaving(true);
                                                setCsvPreviewUrl(null);
                                                setXlsPreviewUrl(null);
                                            }
                                            }
                                        >
                                            {" "}
                                            <IconTimes className="" />
                                        </span>
                                    </button>
                                </div>
                            )}
                            {documentFile && (
                                <div className="dropzone-fileupload d-flex">
                                    {progress == 0 ? (
                                        <button className="btn btn-warning btn-circle btn-xs mr-15">
                                            <IconInfo />
                                        </button>
                                    ) : (
                                        <button className="btn btn-primary btn-circle btn-xs mr-15">
                                            <IconCheck />
                                        </button>
                                    )}
                                    <div className="document-preview document-preview--sm mr-3">
                                        {imagePreviewUrl && (
                                            <img src={imagePreviewUrl} alt={""} />
                                        )}
                                        {pdfPreviewUrl && (
                                            <div id="holder">
                                                <Document
                                                    file={pdfPreviewUrl.pre}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                >
                                                    <Page pageNumber={1} />
                                                </Document>
                                            </div>
                                        )}
                                        {csvPreviewUrl && (
                                            <img
                                                className="document-preview__image"
                                                src="/assets/img/csv.png"
                                                alt="csv.png"
                                            />
                                        )}
                                        {xlsPreviewUrl && (
                                            <FileViewer
                                                fileType="xlsx"
                                                filePath={xlsPreviewUrl.pre}
                                            />
                                        )}
                                    </div>
                                    <div className="w-100 d-flex">
                                        <div>
                                            <div className="font-medium ">
                                                {documentFile.name}
                                            </div>
                                            <div className="text-muted">
                                                {(documentFile.size * 0.0009765625).toFixed(
                                                    1
                                                )}{" "}
                                                kb
                                            </div>
                                        </div>
                                        <div className="w-150 ml-auto">
                                            <Select
                                                menuPlacement="auto"
                                                styles={smallSelectStyle}
                                                menuPosition={"fixed"}
                                                options={documentType}
                                                value={
                                                    fileType &&
                                                    documentType.find(
                                                        (d) => d.value === fileType
                                                    )
                                                }
                                                onChange={({ value }) =>
                                                    setFileType(value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button className="btn btn-link btn-circle btn-xs ml-3">
                                        <span
                                            onClick={() => {
                                                setdocumentFile(null);
                                                setImagePreviewUrl(null);
                                                setPdfPreviewUrl(null);
                                                setProgress(0);
                                                setIsSaving(true);
                                                setCsvPreviewUrl(null);
                                                setXlsPreviewUrl(null);
                                                
                                            }
                                            }
                                        >
                                            {" "}
                                            <IconTrash />{" "}
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-close"
                            onClick={() => {
                                setIsShowFileUploadModal(false);
                                setFileType("");
                                setIsSaving(true);
                                setCsvPreviewUrl(null);
                                setXlsPreviewUrl(null);
                                setPdfPreviewUrl(null);
                                setImagePreviewUrl(null);
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={isSaving || isLoading || !fileType}
                            onClick={() => {
                                if (documentFile) {
                                    handleDocumentUpload();
                                    
                                } else {
                                    toastr.show("Please select file", "error");
                                }
                            }}
                        >
                            {isLoading && (
                                <span
                                    class="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            Save Changes
                        </button>
                    </Modal.Footer>
                </Modal>
                {showFullDropScreen && props.tab === 2 && !isShowFileUploadModal &&
                    <FullScreenUploader uploadResult={uploadResult} multi={false} type={"image"} />
                }

            </React.Fragment>
        </div>
    )
}

export default DocumentCustomer

const documentType = [
    { value: "Customer Contract", label: "CUSTOMER CONTRACT" },
    { value: "Rate Confirmation", label: "RATE CONFIRMATION" },
    { value: "Email", label: "Email" },
    { value: "Other", label: "Other" },
    { value: "Credit App", label: "Credit App" },
    { value: "Credit Report", label: "Credit Report" },
    { value: "Business License", label: "Business License" },
  ];