import React, { useEffect } from 'react';
import Dropzone from "react-dropzone";
import {isNewModal } from "../../services/Common.services";


function FullScreenUploader(props) {

  useEffect(() => {
    window.addEventListener('mouseup', onDragLeave);
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('dragover', onDragOver);
    if (document.getElementById('dropZone'))
      document.getElementById('dropZone').addEventListener('dragleave', onDragLeave);
    window.addEventListener('drop', onDrop);

    return () => {
      window.removeEventListener('mouseup', onDragLeave);
      window.removeEventListener('dragenter', onDragEnter);
      window.addEventListener('dragover', onDragOver);
      if (document.getElementById('dropZone'))
        document.getElementById('dropZone').removeEventListener('dragleave', onDragLeave);
      window.removeEventListener('drop', onDrop);
    }
  }, [])

  const onDragEnter = (e) => {
    const dropZone = document.getElementById('dropZone');
    if (dropZone) {
      dropZone.style.display = "block";
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
  
  const onDragLeave = (e) => {
    const dropZone = document.getElementById('dropZone');
    if (dropZone) {
      dropZone.style.display = "none";
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  
  const onDrop = (e) => {
    e.preventDefault();
    const dropZone = document.getElementById('dropZone');
    if (dropZone) {
      dropZone.style.display = "none";
    }
    return false;
  }

  const fileUploader = (data) => {
    if (data) {
      if (props.multi) {
        let allFiles = [];
        for (let index = 0; index < data.length; index++) {
          allFiles.push(data[index]);
        }
        props.uploadResult(allFiles, null, props.type);
      } else {
        let reader = new FileReader();
        let file = data[0];
        if(file) {
          reader.onload = () => {
            props.uploadResult(file, reader.result, props.type);
          };
          reader.readAsDataURL(file);
        } else {
          return;
        }
      }
    }
  };

  return (
    <Dropzone
      className="border-0 w-100"
      onDrop={(acceptedFiles) => {
        fileUploader(acceptedFiles);
      }}
    >
      {({getRootProps, getInputProps}) => (
        <div id="dropZone" {...getRootProps()}
          style={{ display: 'none'}} className={`${isNewModal() ? "new_modal_document_upload_popup" : "document_upload_popup"} position-fixed rounded-lg`}>
        </div>
      )}
    </Dropzone>

  );
}

export default FullScreenUploader;