import React, { Component } from "react";
import * as Yup from "yup";
import _ ,{ startCase, toLower, capitalize } from "lodash";
import $ from "jquery";
import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import Select from "react-select";
import { withFormik } from "formik";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import NumberFormat from "react-number-format";
import { browserHistory } from "react-router";
import { Document, Page } from "react-pdf";
import Pagination from "react-js-pagination";
import { debounce } from "throttle-debounce";
import AsyncCreatableSelect from "react-select/async-creatable";
import ReactTooltip from "react-tooltip";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import DateTimePicker from "react-datetime-picker";
import Papa from "papaparse";
import "../style.css";
import SubHeaderTab from "../../../Components/SubHeaderTab";
import configuration from "../../../config";
import * as actionCreators from "./actionCreators"; 
import {addDriver} from '../../tms/carrier/Transcation/actionCreator.js'
import { DRIVER_PERMISSIONS } from './constant'
import { getDocumentTypesByType } from '../../../Components/Common/functions';
import {
  getStorage,
  toastr,
  setStorage,
  showForCarrier,
  showForTerminal,
  checkTMXPermission,
  fileNameTypeFromUrl,
} from "../../../services/Common.services";
import { StatesOptions } from "../services";
import { getTerminal, deleteDriverDoc } from "../services";
import { Spinner } from "../../../Components/Common/Components/Component";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import confirm from "../../../Components/Common/ConfirmAert";
import Accessorials from "./Accessorials";
import { differnceFinder } from "../../../../src/services/index";
import {
  StateFormInput,
  StateFormNumberFormat,
  StateFormSelect,
  StateFormDateTime,
  AddressInput
} from "../../../Components/FormComponent/StateFormComponent";
import { EncryptFormInput } from '../../../Components/FormComponent/EncryptFormComponent'
import { loadFlags } from "./utility";

import {
  IconDownload,
  IconPlus,
  IconPen,
  IconTrash,
  IconCheckCircle,
  IconSteering,
  IconDocument,
  IconSearchData,
  IconNotes,
  IconFilter,
  IconColumn,
  IconAngleArrowLeft,
  IconTruck,
  IconClock9,
  IconUpload,
  IconTimes,
  IconCheck,
  IconSort,
  IconSortUp,
  IconSortDown,
  IconAngleArrowDown,
  IconAngleArrowRight,
  IconWarningCircle,
  IconLock,
  IconMobilePermission,
  IconHalfLock
} from "../../../Components/Common/Icons";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { CustomIconTruck } from "../../../Components/Common/CustomIcons/Index";
import UploadedDocuments from "../Load/DocumentList";
import { InputField } from "../formComponent";
import FullScreenUploader from "../../../Components/FullScreenUploader";

let timeZone = getStorage("timeZone");

let Data = {
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  licence: "",
  licenceNumber: "",
  homeTerminalTimezone: "",
  cycleRestart: "",
  cycleRestBreak: "",
  cycleShortHaulException: "",
  password: "",
  dlExp: "",
  medicalExp: "",
  seaLinkExp: "",
  twicExp: "",
  terminal: "",
  image: "",
  termination: "",
  dob: "",
  doh: "",
  sealLinkNumber: "",
  newTerminal: "",
  preferredStates: [],
  preferredTypesOfLoad: [],
  preferredDistance: [],
  hazmat: "",
  liquor: "",
  overweight: "",
  owerWeightStates: null,
  reefer: "",
  notes: "",
  EmergencyContactNumber: "",
  billingEmail: "",
  EmergencyContactName: "",
  EmergencyRelation: "",
  profileType: "",
  socialSecurity: "",
  tablet: "",
  eld: "",
  fuelCard: "",
  ezPass: "",
  driverPreferences: false,
  bankAccount: "",
  routing: "",
  tShirtSizes: "",
  disableDriverPay: false,
  settlementNote: "",
  hst: "",
  hstPercentage: null,
  registerBusinessName: "",
  isLoadTabActive: 1,
  settlementNote: "",
  accountHold: false,
  permanentAddress: '',
  username: "",
  isUserNameValid: null,
  permissions : [],
  isLocked: false,
  documentTypes: [],
  isUnmount: false
};
const cardItems = [
  {
    id: 1,
    count: "availableDrivers",
    title: "Available",
    state: "isAvailable",
  },
  {
    id: 2,
    count: "dispatchedDrivers",
    title: "Dispatched",
    state: "isDispatched",
  },
  // {
  //   id: 3,
  //   count: 740,
  //   title: "Loaded",
  // },
  // {
  //   id: 4,
  //   count: 994,
  //   title: "Empty",
  // },
];
const sortingOptions = {
  "First Name": "name",
  "Last Name": "lastName",
  Phone: "driver.mobile",
  "DL EXP": "driver.dlExp",
  "MEDICAL EXP": "driver.medicalExp",
  "SEALINK EXP": "driver.seaLinkExp",
  TERMINAL: "terminal.name",
  "Address": "driver.permanentAddress"
};
const profileOptions = [
  { label: "PORT", value: "PORT" },
  { label: "DELIVERY", value: "DELIVERY" },
  { label: "TRAILER", value: "TRAILER" },
  { label: "All", value: "All" },
];
class Driver extends Component {
  constructor(props) {
    let filterByProfileTypes = getStorage("filterByProfileTypes");
    super(props);
    this.state = {
      showFileUploadModal: false,
      AddDriverModal: false,
      allDrivers: null,
      isSaving: false,
      EmergencyContactName: "",
      EmergencyContactNumber: "",
      EmergencyRelation: "",
      _id: _.get(props, "location.query.id"),
      asce: true,
      // cargoType: 'property',
      cycleRestBreak: "30",
      cycleRestart: "34",
      cycleShortHaulException: "0",
      dlExp: "",
      dob: "",
      documentFile: null,
      documents: [],
      doh: "",
      email: "",
      fileType: null,
      filterByProfileTypes: filterByProfileTypes ? filterByProfileTypes : "All",
      // filterDriverTypes: "Enabled",
      isEnableDriver: true,
      isDisableDriver: false,
      isDispatched: false,
      isAvailable: false,
      homeTerminalTimezone: getStorage("timeZone"),
      imagePreviewUrl: null,
      lastName: "",
      licence: "",
      licenceNumber: "",
      password: "",
      medicalExp: "",
      mobile: "",
      name: "",
      pdfPreviewUrl: null,
      profileType: [],
      seaLinkExp: "",
      sort: undefined,
      isSettlementHide:false,
      tab: 1,
      terminals: [],
      termination: "",
      newTerminal: [],
      twicExp: "",
      type: "",
      reg_expiration: new Date(),
      isLoadingBarShow: false,
      choosenTerminals: [],
      socialSecurity: "",
      tablet: "",
      eld: "",
      fuelCard: "",
      ezPass: "",
      driverPreferences: false,
      bankAccount: "",
      routing: "",
      tShirtSizes: "",
      disableDriverPay: false,
      settlementNote: "",
      hst: "",
      hstPercentage: null,
      registerBusinessName: "",
      isDayDriver: true,
      formErrors: {},
      formsTouched: {},
      isTerminal: false,
      showModal: false,
      isCardActive: 1,
      uploadFileDetails: "",
      progress: 0,
      documentObj: {},
      documentImage: true,
      loader: false,
      accountHold: false,
      permanentAddress:'',
      features: [],
      vacationFeature: checkTMXPermission('driver_on_vacation'),
      isVacation: false,
      isOnVacation:false,
      driverHoldStartDt: null,
      driverHoldEndDt: null,
      username: "",
      defaultSuggestions: [],
      isUserNameValid: null,
      permissions : [],
      isLocked: false,
      showEncryptedValue: false,
      disableButton: false,
      forDocument: false,
      showFullDropScreen:false
    };
    this.handleChangeAll = this.handleChangeAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkStartDate = this.checkStartDate.bind(this);
    this.endValidDate = this.endValidDate.bind(this);
    this.startValidDate = this.startValidDate.bind(this);

    this.handleChangeOfUsername = debounce(1000, this.checkUserName);
  }
  UNSAFE_componentWillMount() {
    this.setState({ isLoadingBarShow: true });
    // fetch and fill document types option from api
    (async () => {
      let types = await getDocumentTypesByType('driverTypes');
      this.setState({ documentTypes: types })
    })();
  }
  componentWillReceiveProps(props) {
    if (props.isOnlyPopup) {
      this.setState({ AddDriverModal: false });
    }
    
  }
  componentDidMount() {
    if (!this.props.isOnlyPopup) {
      this.getlist();
    }
    this.props.actions.getProfile().then((result) => {
      this.setState({ userProfile: result });
    });
    let isTerminal = showForTerminal();
    this.setState({ isTerminal: isTerminal});
    if (isTerminal) {
      getTerminal().then((response) =>
        this.setState({
          terminals: response.data.data ? response.data.data : [],
        })
      );
    }
    if (this.props.isOnlyPopup) {
      this.setState({ AddDriverModal: true });
    }
    this.setState({showFullDropScreen:true})
  }
  componentWillUnmount() {
    this.setState({showFullDropScreen:false})
  }
  componentWillReceiveProps(props) {
    const newId = _.get(props, "location.query.id");
    const oldId = _.get(this.props, "location.query.id");
    if (newId && newId !== oldId) {
      this.changeColor(newId);
    }
  }
  uploadResult = (file, preview, type) => {
    if (
      (file.type.match("image/png")||file.type.match("image/jpg") ||file.type.match("image/jpeg")  || file.type.match("application.pdf")) ){
          this.setState({showFileUploadModal:true})
          if (file.type.indexOf('image') == -1) {
            type = file.type.split('/')[1];
          }
          setTimeout(() => {
            this.setState({ progress: 100 });
          }, 1000);
          this.setState({
            documentFile: null,
            [`${type}PreviewUrl`]: null,
            pdfPreviewUrl: null,
            [type]: null
          }, () => {
            this.setState({
              documentFile: file,
              [`${type}PreviewUrl`]: preview,
              [type]: preview
            });
          });

          if (type == 'pdf') {
            this.setState({ imagePreviewUrl: null })
            let base64result = preview.split(',')[1];
            var pdfData = atob(base64result);
            var loadingTask = pdfjsLib.getDocument({ data: pdfData });
            loadingTask.promise.then(function (pdf) {
              // Fetch the first page.
              pdf.getPage(1).then(function (page) {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                if (viewport.width > 1000) {
                  scale = 0.5;
                  viewport = page.getViewport(scale);
                }
                //   // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById('the-canvas');
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                //   // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport
                };
                page.render(renderContext);
              });
            });
          }
  }else {
      toastr.show("Please enter only PDF and/or Image (JPG, PNG) file formats.", "error");
      return;
  }

  }
  
  handleVacation = (vacation) => {
    let dataToSend = {
      _id : this.state._id,
      isOnVacation : vacation ? true : false
    }
    this.removeChassis(dataToSend, 'vacation')
  }

  removeDocument = () => {
    this.setState({
      uploadFileDetails: "",
      progress: 0,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      documentImage: true,
    });
    this.setState({ documentFile: null });
  };

  exportToCsv = () => {
    const loadData = [];
    let allDrivers = this.state.allDrivers ? this.state.allDrivers : [];
    if (this.state.searchTerm && allDrivers) {
      const regex = RegExp(this.state.searchTerm, "i");
      allDrivers = allDrivers.filter((obj) => {
        return (
          (obj.name && obj.name.match(regex)) ||
          (obj.lastName && obj.lastName.match(regex)) ||
          (obj.driver && obj.driver.mobile && obj.driver.mobile.match(regex))
        );
      });
    }
    if (!this.state.isEnableDriver && this.state.isDisableDriver) {
      allDrivers = allDrivers.filter((d) => d.isDeleted);
    } else if (this.state.isEnableDriver && !this.state.isDisableDriver) {
      allDrivers = allDrivers.filter((d) => !d.isDeleted);
    }
    // allDrivers =
    //   this.state.filterDriverTypes === "Enabled"
    //     ? allDrivers.filter((d) => !d.isDeleted)
    //     : allDrivers;
    // allDrivers =
    //   this.state.filterDriverTypes === "Disabled"
    //     ? allDrivers.filter((d) => d.isDeleted)
    //     : allDrivers;

    if (this.state.filterByProfileTypes !== "All") {
      allDrivers = allDrivers.filter((d) =>
        d.driver.profileType.includes(this.state.filterByProfileTypes)
      );
    }
    if (this.state.sort) {
      allDrivers = _.orderBy(
        allDrivers,
        this.state.sort,
        !this.state.asce && "desc"
      );
    }
    allDrivers.map((d) => {
      let data = {
        "FIRST NAME": d.name,
        "LAST NAME": d.lastName,
        "USER NAME": d.username,
        "TRUCK #": d.driver && d.driver.truck && d.driver.truck.equipmentID,
        "FLEET OWNER":
          d.driver &&
          d.driver.truck &&
          d.driver.truck.fleetTruckOwner &&
          d.driver.truck.fleetTruckOwner.company_name,
        PHONE: d.driver.mobile,
        "DL EXP": d.driver.dlExp
          ? moment(d.driver.dlExp).format("MM/DD/YYYY")
          : "",
        "Medical Exp": d.driver.medicalExp
          ? moment(d.driver.medicalExp).format("MM/DD/YYYY")
          : "",
        [showForCarrier() ? "PORT ID EXP" : "SEALINK EXP"]: d.driver.seaLinkExp
          ? moment(d.driver.seaLinkExp).format("MM/DD/YYYY")
          : "",
        [showForCarrier() ? "PORT ID" : "SEALINK #"]:
          (d.driver && d.driver.sealLinkNumber) ? d.driver.sealLinkNumber : "",
        "TWIC EXP": d.driver.twicExp
          ? moment(d.driver.twicExp).format("MM/DD/YYYY")
          : "",
        "DATE OF BIRTH": d.driver.dob
          ? moment(d.driver.dob).format("MM/DD/YYYY")
          : "",
        "DATE OF HIRE": d.driver.doh
          ? moment(d.driver.doh).format("MM/DD/YYYY")
          : "",
        EMAIL: d.email,
        "EMERGENCY CONTACT NAME":
          d.driver.EmergencyContactName && d.driver.EmergencyContactName,
        "EMERGENCY CONTACT NUMBER":
          d.driver.EmergencyContactNumber && d.driver.EmergencyContactNumber,
        "EMERGENCY RELATION":
          d.driver.EmergencyRelation && d.driver.EmergencyRelation,
      };
      loadData.push(data);
    });
    var csv = Papa.unparse(loadData);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `${new Date().getTime()}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${new Date().getTime()}.csv`);
    tempLink.click();
  };
  deleteEquipmentDoc = (params) => {
    deleteDriverDoc(params).then((response) => {
      const documents = response.data.data.documents;
      this.setState({ documents });
      // this.getlist();
      toastr.show("Deleted!", "success");
    });
  };

  getlist() {
    let data = {
      isIncludeDeleted: true,
    };
    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      data.terminals = JSON.stringify(
        this.state.choosenTerminals.map((J) => {
          return J.value;
        })
      );
    }
    this.props.actions.listAllDrivers(data).then((result) => {
      let allDrivers = result;
      this.setState({ allDrivers, isLoadingBarShow: false }, async () => {
        this.props.dispatch(addDriver(allDrivers));
        if (this.state._id) {
          this.changeColor(this.state._id);
        }
      });
    });
  }

  handleChange({ target }) {
    const userId = target.getAttribute("data-userId");
    const role = target.getAttribute("data-roleName");
    if (target.checked) {
      this.props.actions
        .assignRole({ userId: userId, roles: [role] })
        .then((result) => {
          toastr.show("Assigned!", "success");
          let updatedUsers = [];
          this.state.allDrivers.forEach((user) => {
            if (user._id === userId) {
              user.acl.push(role);
            }
            updatedUsers.push(user);
          });
          this.setState({
            allDrivers: updatedUsers,
          });
        })
        .catch((err) => { });
    } else {
      this.props.actions
        .removeRole({ userId: userId, roles: [role] })
        .then((result) => {
          toastr.show("Removed!", "success");
          let updatedUsers = [];
          this.state.allDrivers.forEach((user) => {
            if (user._id === userId) {
              var index = user.acl.indexOf(role);
              if (index > -1) {
                user.acl.splice(index, 1);
              }
            }
            updatedUsers.push(user);
          });
          this.setState({
            allDrivers: updatedUsers,
          });
        })
        .catch((err) => { });
    }
  }

  viewDocument = (data) => {
    if (!data.image) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.image;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.image },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.image, pdfPreviewUrl: null });
      }
    }
  };

  changeColor(id) {
    
    _.get(this.state, "allDrivers", []).map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ [id]: true });
    const allDrivers = JSON.parse(JSON.stringify(this.state.allDrivers));
    let selectData = {};
    selectData = _.find(allDrivers, function (s) {
      return s._id == id;
    });
    this.setState({fleetOwner: selectData && selectData.driver && selectData.driver.truck && selectData.driver.truck.fleetTruckOwner})
    this.props.actions
      .getDriverAudits({ driverId: selectData.driver._id })
      .then((result) => this.setState({ audits: result }))
      .catch((error) => console.log(error));
    
    this.setState({
      setSocialSecurity: selectData.driver.socialSecurity,
      setBankAccount: selectData.driver.bankAccount,
      setRouting: selectData.driver.routing,
      setDlExp: selectData.driver.dlExp
    })
    let setdata = {
      _id: selectData._id,
      driverId: selectData.driver._id,
      name: selectData.name,
      lastName: selectData.lastName,
      username: selectData.username,
      permissions : selectData.permissions,
      accountHold:selectData.driver.accountHold,
      email: selectData.email == undefined ? "" : selectData.email,
      mobile: selectData.driver.mobile,
      licence: selectData.driver.licence ? selectData.driver.licence : null,
      licenceNumber:
        selectData.driver.licenceNumber == undefined ||
          selectData.driver.licenceNumber == "undefined"
          ? ""
          : selectData.driver.licenceNumber,
      homeTerminalTimezone: selectData.driver.homeTerminalTimezone,
      // cargoType: selectData.driver.cargoType,
      // cycleRestart: selectData.driver.cycleRestart,
      // cycleRestBreak: selectData.driver.cycleRestBreak,
      // cycleShortHaulException: selectData.driver.cycleShortHaulException,
      dlExp: selectData.driver.dlExp ? new Date(selectData.driver.dlExp) : "",
      medicalExp: selectData.driver.medicalExp
        ? new Date(selectData.driver.medicalExp)
        : "",
      seaLinkExp: selectData.driver.seaLinkExp
        ? new Date(selectData.driver.seaLinkExp)
        : "",
      twicExp: selectData.driver.twicExp
        ? new Date(selectData.driver.twicExp)
        : "",
      termination: selectData.driver.termination
        ? new Date(selectData.driver.termination)
        : "",
      driverHoldStartDt: selectData.driver.driverHoldStartDt
        ? selectData.driver.driverHoldStartDt
        : null,
      driverHoldEndDt: selectData.driver.driverHoldEndDt
        ? selectData.driver.driverHoldEndDt
        : null,
      dob: selectData.driver.dob ? new Date(selectData.driver.dob) : "",
      isSettlementHide: selectData.driver.isSettlementHide,
      doh: selectData.driver.doh ? new Date(selectData.driver.doh) : "",
      image: selectData.image ? selectData.image : null,
      sealLinkNumber: selectData.driver.sealLinkNumber ? selectData.driver.sealLinkNumber : "",
      billingEmail: selectData.driver.billingEmail ? selectData.driver.billingEmail : '',
      profileType: selectData.driver.profileType.map((d) => {
        return { value: d, label: d };
      }),
      EmergencyContactName: selectData.driver.EmergencyContactName
        ? selectData.driver.EmergencyContactName
        : "",
      EmergencyContactNumber: selectData.driver.EmergencyContactNumber
        ? selectData.driver.EmergencyContactNumber
        : "",
      EmergencyRelation: selectData.driver.EmergencyRelation
        ? selectData.driver.EmergencyRelation
        : "",
      newTerminal:
        selectData.driver.newTerminal &&
          selectData.driver.newTerminal.length > 0
          ? selectData.driver.newTerminal
          : [],
      preferredStates: selectData.driver.preferredStates
        ? StatesOptions.filter((d) =>
          selectData.driver.preferredStates.includes(d.value)
        )
        : [],
      preferredTypesOfLoad: selectData.driver.preferredTypesOfLoad
        ? LoadTypeOptions.filter((d) =>
          selectData.driver.preferredTypesOfLoad.includes(d.value)
        )
        : [],
      preferredDistance: selectData.driver.preferredDistance
        ? DistanceOptions.filter((d) =>
          selectData.driver.preferredDistance.find((f) =>
            _.isEqual(f, d.value)
          )
        )
        : [],
      hazmat: selectData.driver.hazmat ? true : false,
      liquor: selectData.driver.liquor === "true" ? true : false,
      overweight: selectData.driver.overweight ? true : false,
      owerWeightStates: selectData.driver.owerWeightStates
        ? StatesOptions.filter((d) =>
          selectData.driver.owerWeightStates.includes(d.value)
        )
        : [],
      reefer: selectData.driver.reefer ? selectData.driver.reefer : "",
      notes: selectData.driver.notes ? selectData.driver.notes : "",
      socialSecurity: selectData.driver.socialSecurity
        ? selectData.driver.socialSecurity
        : "",
      tablet: selectData.driver.tablet ? selectData.driver.tablet : "",
      eld: selectData.driver.eld ? selectData.driver.eld : "",
      fuelCard: selectData.driver.fuelCard ? selectData.driver.fuelCard : "",
      ezPass: selectData.driver.ezPass ? selectData.driver.ezPass : "",
      driverPreferences: selectData.driver.driverPreferences
        ? selectData.driver.driverPreferences
        : "",
      bankAccount: selectData.driver.bankAccount
        ? selectData.driver.bankAccount
        : "",
      routing: selectData.driver.routing ? selectData.driver.routing : "",
      tShirtSizes: selectData.driver.tShirtSizes
        ? selectData.driver.tShirtSizes
        : "",
      disableDriverPay: selectData.driver.disableDriverPay
        ? selectData.driver.disableDriverPay
        : "",
      settlementNote: selectData.driver.settlementNote
        ? selectData.driver.settlementNote
        : "",
      hst: selectData.driver.hst ? selectData.driver.hst : "",
      hstPercentage: selectData.driver.hstPercentage
        ? selectData.driver.hstPercentage
        : "",
      registerBusinessName: selectData.driver.registerBusinessName
        ? selectData.driver.registerBusinessName
        : "",
      settlementNote: selectData.driver.settlementNote
        ? selectData.driver.settlementNote
        : "",
      isLoadTabActive: this.state.isLoadTabActive ? this.state.isLoadTabActive : 1,
      permanentAddress: selectData.driver.permanentAddress ? selectData.driver.permanentAddress : '',
      isOnVacation: selectData.driver.isOnVacation ? true : false,
      permissions: selectData.driver.permissions ? selectData.driver.permissions : [],
      isLocked: false
    };
    if (selectData.driver.terminal) {
      setdata.terminal = selectData.driver.terminal
        ? selectData.driver.terminal
        : "";
    }
    if (setdata.newTerminal && setdata.newTerminal.length > 0) {
      let newTerminal = [];
      this.state.terminals.forEach((item, i) => {
        if (setdata.newTerminal.indexOf(item._id) > -1) {
          newTerminal.push({
            value: item._id,
            label: item.name,
          });
        }
      });
      setdata.newTerminal = newTerminal;
    }
    // this.setState({ ...setdata, isDayDriver: selectData.driver.isDayDriver, email: setdata.email, AddDriverModal: true, documents: selectData.documents ? selectData.documents : [], imagePreviewUrl: null, documentFile: null, pdfPreviewUrl: null }, () => {
    // })
    const updateState = {
      ...setdata,
      isDayDriver: selectData.driver.isDayDriver,
      email: setdata.email,
      // AddDriverModal: false,
      documents: selectData.documents ? selectData.documents : [],
      imagePreviewUrl: null,
      documentFile: null,
      pdfPreviewUrl: null,
      formErrors: {},
      formsTouched: {},
    };

    if (this.state.isUpdate) {
      // updateState.AddDriverModal = false;
      updateState.isUpdate = false;
    }

    this.setState(updateState, () => { console.log("this.state", this.state.billingEmail); });
    this.props.actions.profileCheck({ userId: selectData._id})
      .then((result) => this.setState({isLocked: result.isLocked}))
      .catch((error) => console.log(error));
  }

  userUnlock(){
    this.props.actions.userUnlock({ userId: this.state._id})
    .then((result) => {
      toastr.show('Driver unlocked Successfully', "success");
      this.getlist()
    })
    .catch((error) => console.log(error));
  }
  uploadResult(file, preview, type) {
    if (file.type.match("image.*") || file.type.match("application.pdf")) {
      if (file.type.indexOf("image") == -1) {
        type = file.type.split("/")[1];
      }

      let pdfPreviewUrl = null;
      let imagePreviewUrl = null;
      if (type === 'image') {
        imagePreviewUrl = preview;
      } else if (type === 'pdf') {
        pdfPreviewUrl = preview;
      }
      this.setState(
        {
          documentFile: file,
          imagePreviewUrl,
          pdfPreviewUrl,
          [type]: preview,
          selectedDoc: null,
        });

      // if (type == 'pdf') {
      //     this.setState({ imagePreviewUrl: null })
      //     let base64result = preview.split(',')[1];
      //     var pdfData = atob(base64result);
      //     var options = { scale: 1 };
      //     let canvasContainer = document.getElementById("holder");
      //     $('#holder').html('');

      //     function renderPage(page) {
      //       var viewport = page.getViewport(options.scale);
      //       var canvas = document.createElement('canvas');
      //       var ctx = canvas.getContext('2d');
      //       var renderContext = {
      //         canvasContext: ctx,
      //         viewport: viewport
      //       };

      //       canvas.height = viewport.height;
      //       canvas.width = viewport.width;
      //       canvasContainer.appendChild(canvas);

      //       page.render(renderContext);
      //     }

      //     function renderPages(pdfDoc) {
      //       for (var num = 1; num <= pdfDoc.numPages; num++)
      //         pdfDoc.getPage(num).then(renderPage);
      //     }
      //     pdfjsLib.disableWorker = true;
      //     pdfjsLib.getDocument({ data: pdfData }).then(renderPages);

      // }
    } else {
      toastr.show("Please enter only PDF and/or Image (JPG, PNG) file formats.", "error");
      return;
    }
  }
  // uploadResult = (file, preview, type) => {
  //   if (file.type.indexOf("image") == -1) {
  //     type = file.type.split("/")[1];
  //   }
  //   this.setState(
  //     {
  //       documentFile: null,
  //       [`${type}PreviewUrl`]: null,
  //       pdfPreviewUrl: null,
  //       [type]: null,
  //     },
  //     () => {
  //       this.setState({
  //         documentFile: file,
  //         [`${type}PreviewUrl`]: preview,
  //         [type]: preview,
  //       });
  //     }
  //   );

  //   if (type == "pdf") {
  //     this.setState({ imagePreviewUrl: null });
  //     let base64result = preview.split(",")[1];
  //     var pdfData = atob(base64result);
  //     var loadingTask = pdfjsLib.getDocument({ data: pdfData });
  //     loadingTask.promise.then(function (pdf) {
  //       // Fetch the first page.
  //       pdf.getPage(1).then(function (page) {
  //         var scale = 1.5;
  //         var viewport = page.getViewport(scale);
  //         if (viewport.width > 1000) {
  //           scale = 0.5;
  //           viewport = page.getViewport(scale);
  //         }
  //         //   // Prepare canvas using PDF page dimensions.
  //         var canvas = document.getElementById("the-canvas");
  //         var context = canvas.getContext("2d");
  //         canvas.height = viewport.height;
  //         canvas.width = viewport.width;
  //         //   // Render PDF page into canvas context.
  //         var renderContext = {
  //           canvasContext: context,
  //           viewport: viewport,
  //         };
  //         page.render(renderContext);
  //       });
  //     });
  //   }
  // };

  Newform = () => {
    if (this.state.allDrivers) {
      this.state.allDrivers.map((e) => {
        this.setState({ [e._id]: false });
      });
      this.setState({ ...Data });
    }
  };

  setval(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // Validate form
  insertdata = (isSubmit = true, isToggle) => {
    var errors = {};
    var touched = { ...this.state.formsTouched };
    schemaValidatorObject
      .validate(this.state, { abortEarly: false })
      .then((value) => {
        this.setState({ formErrors: {}, formsTouched: {}, showFileUploadModal: false, disableButton: false,});
        isSubmit && this.insertdataa(isToggle);
      })
      .catch((err) => {
        this.setState({ loader: false, isSaving: false });
        err &&
          err.inner &&
          err.inner.forEach((e, i) => {
            if (isSubmit && i + 1 === err.inner.length) {
              toastr.show(e.message, "error");
            }
            errors[e.path] = e.message;
            if (isSubmit) touched[e.path] = true;
          });
        this.setState({ formErrors: errors, formsTouched: touched }, () => {
          isSubmit && toastr.show("Please enter all required fields.", "warning");
        });
      });
  };
  onRemovePressOnDate(e, index) {
    delete this.state[index];
  }
  // Set State For Val
  setValChange = (key, val) => {
    var formsTouched = { ...this.state.formsTouched };
    formsTouched[key] = true;
    if(key === 'username'){
      val = val.replace(/\s/g,'')
      if(val.length < 7 && this.state.isUserNameValid !== null) {
        this.setState({isUserNameValid: null})
      }
    }
    this.setState({ [key]: val, formsTouched }, () => {
      if(key === 'username'){
        this.handleChangeOfUsername()
      }
      this.insertdata(false);
    });
  };

  onBlur = (key, val) => {
    
  }
  // insert Data
  insertdataa(isToggle) {
    if(this.state.isUserNameValid === false){
      toastr.show("This username is already taken, please try another one", "error");
      this.setState({ loader: false, isSaving: false });
      return;
    }
    let valid = true;
    if (valid) {
      if(this.state.isLoadTabActive != 7){
        let formData = new FormData();
        let newMobile;
        let newEmergencyMobile;
        if (this.state.mobile) {
          newMobile = this.state.mobile.replace(/-|\s/g, "");
        }
        if (this.state.EmergencyContactNumber) {
          newEmergencyMobile = this.state.EmergencyContactNumber.replace(
            /-|\s/g,
            ""
          );
        }
        if (this.state.documentFile && !this.state.fileType) {
          toastr.show("Please choose file type", "error");
          return;
        }
        if (
          this.state.isTerminal &&
          (!this.state.newTerminal || this.state.newTerminal.length == 0)
        ) {
          toastr.show("Please choose terminal", "error");
          return;
        }
          formData.append("permanentAddress", this.state.permanentAddress || '');
          formData.append(
            "truckType",
            JSON.stringify(["REEFER", "DRY VAN, FLATBED"])
          );
          formData.append("name", this.state.name);
          // formData.append("accountHold",this.state.accountHold)
          formData.append("lastName", this.state.lastName);
          formData.append("username", this.state.username.trim());
          if (this.state.email) formData.append("email", this.state.email);
          if (this.state.terminal) {
            formData.append("terminal", this.state.terminal);
          }
          formData.append("mobile", newMobile);
          formData.append("licence", this.state.licence ? this.state.licence : "");
          formData.append("licenceNumber", this.state.licenceNumber);
          formData.append("homeTerminalTimezone", this.state.homeTerminalTimezone);
          if (!this.state._id && this.state.password) {
            formData.append("password", this.state.password);
          }
          // formData.append("primaryCycle", this.state.primaryCycle);
          // formData.append("cargoType", this.state.cargoType);
          // formData.append("cycleRestart", this.state.cycleRestart);
          // formData.append("cycleRestBreak", this.state.cycleRestBreak);
          // formData.append("cycleShortHaulException", this.state.cycleShortHaulException);
          formData.append("sealLinkNumber", this.state.sealLinkNumber ? this.state.sealLinkNumber : "");
          formData.append("billingEmail", this.state.billingEmail);
          formData.append(
            "preferredStates",
            JSON.stringify(
              this.state.preferredStates
                ? this.state.preferredStates.map((d) => d.value)
                : []
            )
            );
            formData.append(
              "preferredTypesOfLoad",
              JSON.stringify(
                this.state.preferredTypesOfLoad
                  ? this.state.preferredTypesOfLoad.map((d) => d.value)
                  : []
              )
            );
            formData.append(
            "preferredDistance",
            JSON.stringify(
              this.state.preferredDistance
                ? this.state.preferredDistance.map((d) => d.value)
                : []
            )
          );
          
          formData.append("hazmat", this.state.hazmat || false);
          formData.append("liquor", this.state.liquor || false);
          formData.append("overweight", this.state.overweight || false);
          formData.append(
            "owerWeightStates",
            JSON.stringify(
              this.state.owerWeightStates
                ? this.state.owerWeightStates.map((d) => d.value)
                : []
            )
          );
          formData.append("reefer", this.state.reefer || "");
          if(this.state.socialSecurity !== this.state.setSocialSecurity) {
            formData.append("socialSecurity", this.state.socialSecurity || "");
          }
          formData.append("tablet", this.state.tablet || "");
          formData.append("eld", this.state.eld || "");
          formData.append("fuelCard", this.state.fuelCard || "");
          formData.append("ezPass", this.state.ezPass || "");
          formData.append(
            "driverPreferences",
            this.state.driverPreferences ? this.state.driverPreferences : false
          );
          if(this.state.bankAccount !== this.state.setBankAccount) {
            formData.append("bankAccount", this.state.bankAccount || "");
          }
          if(this.state.routing !== this.state.setRouting) {
            formData.append("routing", this.state.routing || "");
          }
          formData.append("tShirtSizes", this.state.tShirtSizes || "");
          formData.append(
            "disableDriverPay",
            this.state.disableDriverPay ? this.state.disableDriverPay : false
          );
          formData.append("isDayDriver", this.state.isDayDriver);
          if (this.state.profileType.length > 0)
            formData.append(
              "profileType",
              JSON.stringify(this.state.profileType.map((d) => d.value))
            );

          if (this.state.notes) formData.append("notes", this.state.notes);
             formData.append("isSettlementHide", this.state.isSettlementHide||false);
          if (this.state.settlementNote)
            formData.append("settlementNote", this.state.settlementNote);

          if (this.state.EmergencyContactName)
            formData.append(
              "EmergencyContactName",
              this.state.EmergencyContactName
            );
          if (this.state.EmergencyRelation)
            formData.append("EmergencyRelation", this.state.EmergencyRelation);
          if (newEmergencyMobile)
            formData.append("EmergencyContactNumber", newEmergencyMobile);
          if (this.state.documentFile && this.state.fileType) {
            formData.append("document", this.state.documentFile);
            formData.append("fileType", this.state.fileType.value);
            formData.append("filename", `${new Date().getTime().toString()}`);
          }
          if (this.state.newTerminal && this.state.newTerminal.length > 0) {
            let newTerminal = this.state.newTerminal.map((Data) => {
              return Data.value;
            });
            formData.append("newTerminal", JSON.stringify(newTerminal));
          }
          if (this.state.dlExp && moment(this.state.dlExp).toISOString() !== this.state.setDlExp)
            formData.append("dlExp", moment(this.state.dlExp).toISOString());
          if (this.state.medicalExp)
            formData.append(
              "medicalExp",
              moment(this.state.medicalExp).toISOString()
            );
          if (this.state.seaLinkExp)
            formData.append(
              "seaLinkExp",
              moment(this.state.seaLinkExp).toISOString()
            );
          if (this.state.twicExp)
            formData.append("twicExp", moment(this.state.twicExp).toISOString());
          if (this.state.doh)
            formData.append("doh", moment(this.state.doh).toISOString());
          if (this.state.dob)
            formData.append("dob", moment(this.state.dob).toISOString());
          
          formData.append("termination", moment(this.state.termination).toISOString() || "");
    
          formData.append("driverHoldStartDt", moment(this.state.driverHoldStartDt).toISOString() || "");

          formData.append("driverHoldEndDt", moment(this.state.driverHoldEndDt).toISOString() || "");
          if (this.state.hst) formData.append("hst", this.state.hst);
          if (this.state.hstPercentage)
            formData.append("hstPercentage", this.state.hstPercentage);
          if (this.state.registerBusinessName)
            formData.append(
              "registerBusinessName",
              this.state.registerBusinessName
            );
            
        if (!this.state._id) {
          formData.append("country_code", "+1");
          this.props.actions.newDriverRegister(formData).then((result) => {
            if (!this.props.isOnlyPopup) {
              this.getlist();
            } else {
              this.props.falsyData();
            }
            this.Newform();
            if (!this.state._id) {
              this.setState({ showModal: false, isSaving:false, fileType: null });
              toastr.show("Driver Added Successfully", "success");
            }
          }).catch((err) => { this.setState({ loader: false, isSaving: false }); });
        } else {
          formData.append("_id", this.state._id);
          this.props.actions.updateDriverProfile(formData).then((result) => {
            if (!this.props.isOnlyPopup) {
              this.setState({ isUpdate: true,showEncryptedValue: true }, () => this.getlist());
            }
            if (this.state._id && !this.state.forDocument && !isToggle) {
              toastr.show('Document Uploaded Successfully', "success");
              this.setState({ showFileUploadModal: false, documentImage: true, isSaving: false, forDocument: false });
            } else {
              // this.setState({ AddDriverModal: false });
              toastr.show("Driver Updated Successfully", "success");
              this.setState({ forDocument: false });
            }
            this.setState({ loader: false, isSaving: false, fileType: null, isUserNameValid: null, fleetOwner: null }, () => this.getlist());
          }).catch((err) => { console.log("err", err); this.setState({ loader: false, isSaving: false, fileType: null, isUserNameValid: null, fleetOwner: null }); });
        }
      } else {
        let params = new FormData();
        params.append("driverId", this.state.driverId);
        params.append("permissions", this.state.permissions);
          this.props.actions.updateDriverPermissions({driverId:this.state.driverId, permissions: this.state.permissions}).then((result) => {
              toastr.show("Driver Updated Successfully", "success");
            this.setState({ loader: false, isSaving: false}, () => this.getlist());
          }).catch((err) => { console.log("err", err); this.setState({ loader: false, isSaving: false, fileType: null }); });
      }

    } else {
      toastr.show("Please enter all required fields.", "error");
    }
  }

  checkIsRoleAssigned(acl, rolename) {
    return acl.indexOf(rolename) > -1;
  }

  removeChassis(driver, vacation) {
    if (!driver._id) {
      toastr.show("Please make a selection.", "error");
      return;
    }
    const formData = new FormData();
    formData.append("_id", driver._id);
    vacation === undefined && formData.append("isDeleted", !driver.isDeleted);
    vacation && formData.append("isOnVacation", !driver.isOnVacation);
    confirm(
      "Alert",
      vacation ? `Are you sure you want to ${driver.isOnVacation ? "cancel driver vacation?" : "set this driver On Vacation?  They will not be available for dispatching."}`: `Are you sure to ${driver.isDeleted ? "Enable!" : "Delete!"}`,
      (confirm) => {
        if (confirm) {
          this.setState({isLoadingBarShow: true})
          this.props.actions.deleteDriverProfile(formData).then((e) => {
            this.getlist();
            this.setState({isLoadingBarShow: false, isOnVacation: !driver.isOnVacation})
            // this.Newform();
            toastr.show(
              vacation ?  `Successfully ${driver.isOnVacation ? 'cancel vacation' : 'send to vacation.'}`  : `Successfully ${driver.isDeleted ? "Enabled" : "Deleted"}`,
              "success"
            );
          }).catch(err => this.setState({isLoadingBarShow: false}))
        }
      }
    );
  }

  updateDocument = (e) => {
    this.setState({ fileType: e });
  };
  previewImage = (data) => {
    if (!data.url) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.url;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.url },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.url, pdfPreviewUrl: null });
      }
    }
  };

  downloadDoc(doc) {
    $.ajax({
      url: doc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: (blob) => {
        const extension = fileNameTypeFromUrl(doc.url)[1];
        if (extension != "pdf") {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          link.download = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.click();
        } else {
          var blob = new Blob([blob], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.download = fileName;
          link.click();
        }
      },
    });
  }

  sortTable(sort) {
    this.setState({
      sort,
      asce: this.state.sort !== sort ? true : !this.state.asce,
    });
  }

  onSaveAccessorial = (values) => {
    this.props.actions.updateDriverAccessorials(values).then((result) => {
      let _id = result.data.data.data._id;
      let allDrivers = this.state.allDrivers.map((driver) => {
        if (_id == driver.driver._id) {
          driver.driver.accessorials = result.data.data.data.accessorials;
        }
        return driver;
      });

      toastr.show("Your accessorials have been added!", "success");
      this.setState({
        viewAccessprialForm: false,
        driverProfile: null,
        allDrivers: allDrivers,
      });
    });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? 1 : i,
    });
  };

  showAccessorialForCompany = () => {
    if (getStorage("currentUserID") === "60196e05993170084efd0f4d") {
      return true;
    } else if (
      getStorage("currentUserRole") == "fleetmanager" &&
      JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ===
      "60196e05993170084efd0f4d"
    ) {
      return true;
    } else if (
      getStorage("currentUserRole") == "carrier" &&
      getStorage("currentUserID") === "60196e05993170084efd0f4d"
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleShowUploadModal = (event) => {
    this.setState({
      showFileUploadModal: true,
      uploadFileDetails: null,
      progress: 0,
      documentImage: true
    });
  };
  accountHold = (e) => {
    this.setState({ accountHold: e.target.checked }, () => {
      this.props.actions.updateDriverOnHold({driverId:this.state.driverId, accountHold: this.state.accountHold}).then((result) => {
        toastr.show(`${this.state.accountHold ? 'Driver is put on hold.' : "Driver is no longer on hold."}`, "success");
      this.setState({ loader: false, isSaving: false});
      }).catch((err) => { console.log("err", err); this.setState({ loader: false, isSaving: false, fileType: null }); });
    });
  }

  handleCardClick = (stateName) => {
    if (stateName === "isDispatched" && !this.state.isDispatched) {
      this.setState({ isDispatched: true, isAvailable: false });
    } else if (stateName === "isAvailable" && !this.state.isAvailable) {
      this.setState({ isDispatched: false, isAvailable: true });
    } else {
      this.setState({ isDispatched: false, isAvailable: false });
    }
  };

  startValidDate(current) {
    let self = this;
    if (self.state.driverHoldEndDt) {
      return current.isBefore(moment(this.state.driverHoldEndDt).tz(getStorage("timeZone")).startOf("day")) && current.isAfter(moment().tz(getStorage("timeZone")).startOf("day"));
    } else {
      return current.isAfter(moment().tz(getStorage("timeZone")).startOf("day"));
    }
  };


  checkStartDate(e) {
    if (e >= (moment().tz(getStorage("timeZone")).startOf("day")).toISOString()) {
      if (!this.state.driverHoldEndDt) {
        this.setState({ driverHoldStartDt: e });
      } else {
        if (e <= this.state.driverHoldEndDt) {
          this.setState({ driverHoldStartDt: e });
        } else {
          this.setState({ driverHoldStartDt: null });
          toastr.show(`Start Date cannot be greater than To Date`, "warning");
        }
      }
    } else {
      this.setState({ driverHoldStartDt: null });
      toastr.show(`Start Date cannot be less than Today`, "warning");
    }
  }

  checkEndDate(e) {
    if (e >= this.state.driverHoldStartDt) {
      this.setState({ driverHoldEndDt: e });
    } else if (!this.state.driverHoldStartDt) {
      this.setState({ driverHoldEndDt: e });
    } else {
      this.setState({ driverHoldEndDt: "" });
      toastr.show(`To Date cannot be lesser than From Date`, "warning");
    }
  }

endValidDate(current){
  if(this.state.driverHoldStartDt){
    return current.isAfter(moment(this.state.driverHoldStartDt).tz(getStorage("timeZone")).startOf("day"));
  }else if(!this.state.driverHoldStartDt){
    return current.isAfter(moment().tz(getStorage("timeZone")).startOf("day"));
  }else{
    return false
  }
};


  checkUserName = () => {
    if(this.state.username && !this.state.formErrors.username){
      return new Promise((resolve, reject) => {
        let params = {
          _id : this.state._id,
          username : this.state.username.trim(),
        }
        this.props.actions.checkUserNameAvailability(params).then((result) => {
          this.setState({isUserNameValid:result.data.data.isValid})
          resolve(result)
        });
      });
    }
  }
  checkIsPermissionAssigned(rolename) {
    let checked = false;
    if (Array.isArray(rolename)) {
      checked = rolename.every((e) => {
        return this.state.permissions.includes(e)
      })
    } else {
      checked = this.state.permissions.includes(rolename) ? true : false;
    }
    return checked;
  }

  handleChangeAll({target}) {
    const permission = target.getAttribute("data-permission").split(",");
    let permissions = _.cloneDeep(this.state.permissions)
    if(target.checked){
      permissions = _.uniq(permissions.concat(permission))
    }else{
      if(permission.length > 1){
        permissions = _.pullAllWith(permissions, permission)
      }else {
        permissions.splice(permissions.indexOf(target.value),1)
      }
    }
    this.setState({permissions:permissions},()=>{
      let params = new FormData();
      params.append("driverId", this.state.driverId);
      params.append("permissions", this.state.permissions);
      this.props.actions.updateDriverPermissions({driverId:this.state.driverId, permissions: this.state.permissions}).then((result) => {
        toastr.show("Driver permission has been successfully changed", "success");
      }).catch((err) => { console.log("err", err);  });
    })
  }

  render() {
    let documents = _.filter(this.state.documents, (i) => i.checked);
    const { formErrors, formsTouched } = this.state;
    //Table Offset Height
    var tableGetHeight = this.state.getOffset;

    let allDrivers = this.state.allDrivers ? this.state.allDrivers : [];
    let enableDriverCount = allDrivers.filter((d) => !d.isDeleted && !d.isOnVacation).length;
    let disableDriverCount = allDrivers.filter((d) => d.isDeleted).length;
    let vacationDriverCount = allDrivers.filter((d) => d.isOnVacation).length;

    if (this.state.searchTerm && allDrivers) {
      const regex = RegExp(this.state.searchTerm, "i");
      allDrivers = allDrivers.filter((obj) => {
        return (
          (obj.name && obj.name.match(regex)) ||
          (obj.lastName && obj.lastName.match(regex)) ||
          (obj.driver && obj.driver.mobile && obj.driver.mobile.match(regex))
        );
      });
      enableDriverCount = allDrivers.filter((d) => !d.isDeleted).length;
      disableDriverCount = allDrivers.filter((d) => d.isDeleted).length;
      vacationDriverCount = allDrivers.filter((d) => d.isOnVacation).length;
    }

    const allCounts = {
      availableDrivers: allDrivers.filter((d) => {
        return !d.isDeleted && !d.loads.length;
      }).length,
      dispatchedDrivers: allDrivers.filter((d) => {
        return !d.isDeleted && d.loads.length;
      }).length,
    };

    if (!this.state.isEnableDriver && this.state.isDisableDriver) {
      allDrivers = allDrivers.filter((d) => this.state.isVacation ? (d.isDeleted || d.isOnVacation) : (d.isDeleted && !d.isOnVacation));
    } else if (this.state.isEnableDriver && !this.state.isDisableDriver) {
      allDrivers = allDrivers.filter((d) => this.state.isVacation ? (!d.isDeleted || d.isOnVacation) : (!d.isDeleted && !d.isOnVacation));
    } else if (!this.state.isEnableDriver && !this.state.isDisableDriver) {
      allDrivers = allDrivers.filter((d) => this.state.isVacation ? d.isOnVacation : (!d.isDeleted || d.isDeleted  || !d.isOnVacation));
    } else if(this.state.isEnableDriver && this.state.isDisableDriver){
      allDrivers = allDrivers.filter((d) => this.state.isVacation ? (!d.isDeleted || d.isDeleted  || d.isOnVacation) : !d.isOnVacation);
    }

    if (this.state.isAvailable) {
      allDrivers = allDrivers.filter((d) => !d.isDeleted && !d.loads.length);
    } else if (this.state.isDispatched) {
      allDrivers = allDrivers.filter((d) => !d.isDeleted && d.loads.length);
    }
    // allDrivers =
    //   this.state.filterDriverTypes === "Enabled"
    //     ? allDrivers.filter((d) => !d.isDeleted)
    //     : allDrivers;
    // allDrivers =
    //   this.state.filterDriverTypes === "Disabled"
    //     ? allDrivers.filter((d) => d.isDeleted)
    //     : allDrivers;

    if (this.state.filterByProfileTypes !== "All") {
      allDrivers = allDrivers.filter((d) =>
        d.driver.profileType.includes(this.state.filterByProfileTypes)
      );
    }
    if (this.state.sort) {
      allDrivers = _.orderBy(
        allDrivers,
        this.state.sort,
        !this.state.asce && "desc"
      );
    }
    let Terminal = [];
    if (this.state.terminals && this.state.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.state.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }
    let userRole = getStorage("currentUserRole")
    let isEnablePermission = (userRole === "carrier" || userRole === "fleetmanager")
    return (
      <div className="position-relative subheader-wrapper">
        {/* Sub Header */}
        {/* <AddDriver/> */}
        {!this.props.isQuickAdd &&
          <SubHeaderTab val="driverItems"
            buttons={
              <React.Fragment>
                {this.state.allDrivers && this.state.allDrivers.length > 0 ? (
                  <button
                    type="button"
                    className="btn btn-outline-light mr-2"
                    onClick={() => this.exportToCsv()}
                  >
                    <IconDownload className="mr-2" />
                    Download CSV
                  </button>
                ) : null}
                <button
                  className="btn btn-success"
                  data-toggle="modal"
                  data-target="#addnewprofile"
                  onClick={() => {
                    this.Newform();
                    Object.keys(Data).forEach((key, i) => {
                      this.setState({ [key]: Data[key] });
                    });
                    this.setState({
                      documents: [],
                      _id: undefined,
                      formErrors: {},
                      formsTouched: {},
                      showModal: true,
                      isSaving: false
                    });
                  }}
                >
                  <IconPlus className="mr-2" />
                  Add New Driver
                </button>
              </React.Fragment>
            }
          />
        }
        {!this.props.isQuickAdd && this.state._id && (
          <div
            class={`subheader ${this.state.AddDriverModal ? "d-block" : "d-none"
              }`}
          >
            <div class="subheader__inner d-flex">
              <div class="d-flex flex-row align-items-center">
                <button
                  class="btn btn-outline-light"
                  onClick={() => {
                    this.setState(
                      { AddDriverModal: false, fileType: null, tab: 1, isLoadTabActive: 1, isUserNameValid: null, showEncryptedValue: true, disableButton: false, isUnmount:true },
                      () => {
                        if (this.props.isOnlyPopup) {
                          this.props.falsyData();
                        } else {
                          this.getlist();
                        }
                      }
                    );
                  }}
                >
                  <IconAngleArrowLeft />
                </button>
                <div class="d-flex ml-3 align-items-center">
                  <h5 class="mb-0">{this.state.name}</h5>
                </div>
                <div class='pl-3'>
                  <h3 className={`${this.state.vacationFeature && this.state.isOnVacation && !this.state.accountHold  ? "badge badge-success m-0" :"m-0 badge text-gray-25 badge-status-light-grey"}`}>{this.state.accountHold ? 'ON HOLD' : this.state.vacationFeature && this.state.isOnVacation ? 'ON VACATION': ''}</h3>
                </div>
                {this.state.isLocked && 
                <div class='ml-3'>
                  <h3 className="m-0 badge badge text-white bg-secondary font-light font-10"><IconLock />Locked</h3>
                </div>
                }
              </div>

              <div class="ml-auto position-relative">
                <button
                  class="btn btn-outline-light mr-1"
                  onClick={() => {
                    this.setState(
                      { AddDriverModal: false, fileType: null, tab: 1, isLoadTabActive: 1, isUserNameValid: null, username: null, showEncryptedValue: true, disableButton: false },
                      () => {
                        if (this.props.isOnlyPopup) {
                          this.props.falsyData("driver");
                        } else {
                          this.getlist()
                        }
                      }
                    );
                  }}
                >
                  Cancel
                </button>
                <button
                  class="btn btn-success mr-4"
                  onClick={() => {
                    this.setState({ loader: true, isSaving: true, forDocument: true });
                    if(this.state.isLoadTabActive === 7){
                      this.insertdataa();
                    }else{
                      this.insertdata();
                    }
                  }}
                  disabled={this.state.isSaving || (this.state.isLoadTabActive === 2 || this.state.isLoadTabActive === 3 || this.state.isLoadTabActive === 4 || this.state.isLoadTabActive === 7)}
                >
                  {this.state.isSaving && <i className="uil uil-spinner-alt" />}  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}

        {!this.props.isOnlyPopup && (
          <div
            className={`page-fluid ${this.state.AddDriverModal ? "d-none" : "d-block"
              }`}
          >
            <div className="top-page">
              {/* Title and Pagination */}
              <div className="pb-15 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h4 className="font-20 mb-0">Drivers</h4>
                </div>
                {/* Pagination  */}
                {/* <Pagination className="ml-auto" /> */}
                {/* <Pagination
                  className="ml-auto"
                  innerClass="pagination ml-auto mb-0"
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                  itemsCountPerPage={1000}
                  totalItemsCount={1000}
                /> */}
              </div>
              {/* Cards Row */}
              <div
                className={`form-row ${this.state.isDispatched || this.state.isAvailable
                  ? "hasactive"
                  : ""
                  }`}
              >
                {cardItems.map((cardItem) => (
                  <div className="col-md my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between p-10 ${this.state[cardItem.state] ? "active" : ""
                        }`}
                      onClick={() => this.handleCardClick(cardItem.state)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="mr-1 sbox sbox--default px-2 h-100">
                          {allCounts[cardItem.count]}
                        </div>
                        <div>{cardItem.title}</div>
                        <div className="ml-auto">
                          <div className="form-check">
                            {this.state[cardItem.state] ? <IconAngleArrowDown /> : <IconAngleArrowRight />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Table Filter */}
            <div className="filter-row d-flex align-items-center">
              {/* Search Bar */}
              <div className="app-search header-search">
                <span className="search-icon"> </span>
                <div className="position-relative">
                  <input
                    type="search"
                    className="form-control input-search-left"
                    placeholder="Search..."
                    id="customerSearchInput"
                    value={this.state.searchTerm}
                    onChange={(e) => {
                      this.setState({
                        searchTerm: e.target.value.replace(
                          /[^a-zA-Z0-9 ]/g,
                          ""
                        ),
                      });
                    }}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check--gray pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="Available"
                    checked={
                      // this.state.filterDriverTypes === "Enabled" ? true : false
                      this.state.isEnableDriver
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        // this.setState({ filterDriverTypes: "Enabled" });
                        this.setState({ isEnableDriver: true });
                      } else {
                        // this.setState({ filterDriverTypes: "Disabled" });
                        this.setState({ isEnableDriver: false });
                      }
                    }}
                  />
                  <label className="form-check-label" for="Available">
                    Enabled
                    <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                      {enableDriverCount}
                    </span>
                  </label>
                </div>
                <div className="form-check form-check--gray pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="Pending"
                    checked={
                      this.state.isDisableDriver
                      // this.state.filterDriverTypes === "Disabled" ? true : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        // this.setState({ filterDriverTypes: "Disabled" });
                        this.setState({ isDisableDriver: true });
                      } else {
                        // this.setState({ filterDriverTypes: "Enabled" });
                        this.setState({ isDisableDriver: false });
                      }
                    }}
                  />
                  <label className="form-check-label" for="Pending">
                    Disabled
                    <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                      {disableDriverCount}
                    </span>
                  </label>
                </div>
              </div>
              {this.state.vacationFeature && <div className="form-check form-check--gray pr-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="Available"
                    checked={
                      // this.state.filterDriverTypes === "Enabled" ? true : false
                      this.state.isVacation
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        // this.setState({ filterDriverTypes: "Enabled" });
                        this.setState({ isVacation: true });
                      } else {
                        // this.setState({ filterDriverTypes: "Disabled" });
                        this.setState({ isVacation: false });
                      }
                    }}
                  />
                  <label className="form-check-label" for="Available">
                    Vacation
                    <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                      {vacationDriverCount}
                    </span>
                  </label>
                </div>}
              <div className="w-150 mr-2">
                <Select
                  options={profileOptions}
                  value={
                    this.state.filterByProfileTypes && {
                      value: this.state.filterByProfileTypes,
                      label: this.state.filterByProfileTypes,
                    }
                  }
                  onChange={({ value }) =>
                    this.setState({ filterByProfileTypes: value }, () =>
                      setStorage(
                        "filterByProfileTypes",
                        this.state.filterByProfileTypes
                      )
                    )
                  }
                  styles={
                    smallSelectStyle
                  }
                />
              </div>
              {/* Select Terminal */}
              {this.state.isTerminal && (
                <div className="w-150 mr-2">
                  <Select
                    onChange={(choosenTerminals) =>
                      this.setState({ choosenTerminals }, () => {
                        this.getlist();
                      })
                    }
                    styles={smallSelectStyle}
                    isMulti
                    name="choosenTerminals"
                    options={Terminal}
                  // className="basic-multi-select"
                  // classNamePrefix="select"
                  />
                </div>
              )}
              {/* <div className="ml-auto d-flex">
                <div className="position-relative">
                  <button className="btn btn-outline-light position-relative">
                    <IconColumn />
                  </button>
                </div>
              </div> */}

            </div>
            {/* Drivers Table */}
            <div
              className="table-responsive"
              style={{ height: "calc(100vh - 307px)" }}
            >
              {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
              <table className="table table-card">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    {[
                      "First Name",
                      "Last Name",
                      "Username",
                      "TRUCK #",
                      "Fleet Owner",
                      "Phone",
                      "DL EXP",
                      "MEDICAL EXP",
                      showForCarrier() ? "PORT ID EXP" : "SEALINK EXP",
                      showForCarrier() ? "PORT ID #" : "SEALINK #",
                      "TWIC EXP",
                      "Date of Birth",
                      "Date of Hire",
                      "Email",
                      "Emergency Contact Name",
                      "Emergency Contact Number",
                      "Emergency Relation",
                      "Address",
                    ].map((thStr, index) => (
                      <th
                        key={index}
                        scope="col"
                        onClick={() =>
                          sortingOptions.hasOwnProperty(thStr) &&
                          this.sortTable(sortingOptions[thStr])
                        }
                        className={`${sortingOptions.hasOwnProperty(thStr) && (this.state.sort == undefined || this.state.sort !== sortingOptions[thStr])
                          ? 'table-sort table-sort--default'
                          : (sortingOptions.hasOwnProperty(thStr) ? this.state.sort === sortingOptions[thStr] && this.state.asce == true ?  'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                      >
                        {thStr}
                       
                      </th>
                    ))}
                    {this.state.isTerminal && (
                      <th
                        onClick={() =>
                          sortingOptions.hasOwnProperty("TERMINAL") &&
                          this.sortTable(sortingOptions["TERMINAL"])
                        }
                        className={`${sortingOptions.hasOwnProperty('TERMINAL') && (this.state.sort == undefined || this.state.sort !== sortingOptions['TERMINAL'])
                          ? 'table-sort table-sort--default'
                          : (sortingOptions.hasOwnProperty('TERMINAL') ? this.state.sort === sortingOptions['TERMINAL'] && this.state.asce == true ? 'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                      >
                        TERMINAL
                        
                      </th>
                    )}
                    
                  </tr>
                </thead>
                <tbody>
                  {allDrivers &&
                    allDrivers.map((d) => {
                      return (
                        <tr
                          key={d._id}
                          className={`${d.driver.isDeleted ? "text-danger" : null
                            }`}
                        >
                          <td className="text-center">
                            {!d.isDeleted && (
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => { 
                                  this.setState({showEncryptedValue: false, username: "" ,isUnmount:false }, () => {
                                    this.changeColor(d._id); this.setState({ AddDriverModal: true })
                                  })
                                 }}
                              >
                                <IconPen />
                              </button>
                            )}
                          </td>
                          <td>
                            {d.isDeleted ? (
                              <button
                                type="button"
                                className="btn btn-link text-success"
                                onClick={() => this.removeChassis(d)}
                              >
                                <IconCheckCircle />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-link text-muted"
                                onClick={() => this.removeChassis(d)}
                              >
                                <IconTrash />
                              </button>
                            )}
                          </td>
                          <td>
                            {!d.isDeleted && (
                              <button
                                type="button"
                                title="Change Password"
                                className="btn btn-link text-muted"
                                onClick={() =>
                                  this.setState({ resetId: d._id })
                                }
                              >
                                <i className="uil uil-key-skeleton font-18"></i>
                              </button>
                            )}

                            {this.showAccessorialForCompany() && (
                              <button
                                type="button"
                                title="Update Accessioral"
                                className="btn btn-link text-primary ml-2"
                                onClick={() =>
                                  this.setState({
                                    viewAccessprialForm: true,
                                    driverProfile: d,
                                  })
                                }
                              >
                                <i className="uil uil-user-square font-18"></i>
                              </button>
                            )}
                          </td>  
                          <td>
                            {d.name}
                            <span className={`${this.state.vacationFeature && !d.driver.accountHold &&  d.isOnVacation ? "badge badge-success m-2" : "badge badge-status-dark-grey badge-sm text-white ml-2"}`}>
                                  {d.driver.accountHold ? 'On Hold' : this.state.vacationFeature && d.isOnVacation ? 'On Vacation':''}
                            </span>
                          </td>
                          <td>{d.lastName}</td>
                          <td class="text-break text-wrap w-150">{d.username}</td>
                          <td>
                            {d.driver &&
                              d.driver.truck &&
                              d.driver.truck.equipmentID}
                          </td>
                          <td>
                            {d.driver &&
                              d.driver.truck &&
                              d.driver.truck.fleetTruckOwner &&
                              d.driver.truck.fleetTruckOwner.company_name}
                          </td>
                          <td>{d.driver.mobile}</td>
                          <td className="p-0">
                            <span
                              className={`h-100 d-flex align-items-center justify-content-center px-1 ${d.driver.dlExp &&
                                moment(d.driver.dlExp).diff(moment(), "hours") <=
                                0
                                ? "bg-warning-50"
                                : ""
                                } `}
                            >
                              {d.driver.dlExp
                                ? moment(d.driver.dlExp).format("MM/DD/YYYY")
                                : ""}
                            </span>
                          </td>
                          <td className="p-0">
                            <span
                              className={`h-100 d-flex align-items-center justify-content-center px-1 ${d.driver.medicalExp &&
                                moment(d.driver.medicalExp).diff(
                                  moment(),
                                  "hours"
                                ) <= 0
                                ? "bg-warning-50"
                                : ""
                                } `}
                            >
                              {d.driver.medicalExp
                                ? moment(d.driver.medicalExp).format(
                                  "MM/DD/YYYY"
                                )
                                : ""}
                            </span>
                          </td>
                          <td className="p-0">
                            <span
                              className={`h-100 d-flex align-items-center justify-content-center px-1 ${d.driver.seaLinkExp &&
                                moment(d.driver.seaLinkExp).diff(
                                  moment(),
                                  "hours"
                                ) <= 0
                                ? "bg-warning-50"
                                : ""
                                } `}
                            >
                              {d.driver.seaLinkExp
                                ? moment(d.driver.seaLinkExp).format(
                                  "MM/DD/YYYY"
                                )
                                : ""}
                            </span>
                          </td>
                          <td>
                            {d.driver &&
                              d.driver.sealLinkNumber &&
                              d.driver.sealLinkNumber !== "undefined"
                              ? d.driver.sealLinkNumber
                              : ""}
                          </td>
                          <td className="p-0">
                            <span
                              className={`h-100 d-flex align-items-center justify-content-center px-1 ${d.driver.twicExp &&
                                moment(d.driver.twicExp).diff(
                                  moment(),
                                  "hours"
                                ) <= 0
                                ? "bg-warning-50"
                                : ""
                                } `}
                            >
                              {d.driver.twicExp
                                ? moment(d.driver.twicExp).format("MM/DD/YYYY")
                                : ""}
                            </span>
                          </td>
                          <td>
                            <span>
                              {d.driver.dob
                                ? moment(d.driver.dob).format("MM/DD/YYYY")
                                : ""}
                            </span>
                          </td>
                          <td>
                            <span>
                              {d.driver.doh
                                ? moment(d.driver.doh).format("MM/DD/YYYY")
                                : ""}
                            </span>
                          </td>
                          <td>{d.email}</td>
                          <td>
                            {d.driver.EmergencyContactName &&
                              d.driver.EmergencyContactName}
                          </td>
                          <td>
                            {d.driver.EmergencyContactNumber &&
                              d.driver.EmergencyContactNumber}
                          </td>
                          <td>
                            {d.driver.EmergencyRelation &&
                              d.driver.EmergencyRelation}
                          </td>
                          <td>
                            {d.driver.permanentAddress &&
                              d.driver.permanentAddress}
                          </td>
                          {this.state.isTerminal && (
                            <td className={`hideextra`}>
                              {d.driver &&
                                d.driver.newTerminal &&
                                d.driver.newTerminal.length > 0 &&
                                this.state.terminals &&
                                this.state.terminals.length > 0
                                ? d.driver.newTerminal.map((ids, index) => {
                                  let thatTerminal = this.state.terminals.find(
                                    (D) => D._id == ids
                                  );
                                  return (
                                    <div key={index}>
                                      <span>
                                        {thatTerminal
                                          ? thatTerminal.name
                                          : ""}
                                      </span>
                                    </div>
                                  );
                                })
                                : ""}
                            </td>
                          )}
                          
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="radius-wrapper"></div>
            </div>
          </div>
        )}
        {!this.props.isQuickAdd &&
          <div
            className={`page-fluid ${this.state.AddDriverModal ? "d-block" : "d-none"
              }`}
          >
            {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
            <div className="form-row-md fcpopup">
              <div className="col-lg fcpopup__left">
                {this.state.isLocked && 
                  <div className="">
                    <button
                      className="btn btn-primary text-center d-flex align-items-center justify-content-center w-100 mb-10"
                      onClick={() => {
                        this.userUnlock()
                      }}
                    >
                      <IconHalfLock/>
                      <span className="ml-10">Unlock Driver</span>
                    </button>
                  </div>
                }
                <div className="card p-15 mb-1">
                  <h5>About</h5>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">First Name:</span>
                    <span>{this.state.name}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">Last Name:</span>
                    <span>{this.state.lastName}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">Mobile:</span>
                    <span>{this.state.mobile}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-10">
                    <span className="text-muted">Email:</span>
                    <span>{this.state.email}</span>
                  </div>
                </div>
                <div className="form-check form-switch form-switch-md mt-15 d-flex align-item-center ml-2">                   	
                    <input	
                      type="checkbox"	
                      name="accountHold"	
                      className="form-check-input"	
                      checked={this.state.accountHold}	
                    onChange={(e) => 	
                        this.accountHold(e)	
                      }	
                    />	
                    <label className="ml-2 mt-10">	
                      {"Driver On Hold"}	
                    </label>	
                  </div>
                  {this.state.vacationFeature && (
                    <div className="form-check form-switch form-switch-md mt-15 d-flex align-item-center ml-2">                   	
                    <input	
                      type="checkbox"	
                      name="VacationHold"	
                      className="form-check-input"	
                      checked={this.state.isOnVacation}	
                      onClick={() => this.handleVacation(this.state.isOnVacation)}
                    />	
                    <label className="ml-2 mt-10">	
                      {"Send To Vacation"}	
                    </label>	
                  </div>
                  )}
                  <div className="card p-15 mb-1 d-flex  mt-10">  
                <h5>
                    Schedule on Hold
                  </h5>
                  <div class="mb-10 ">

                  <label className="label-control font-medium">
                    Start Hold Date
                  </label>
                  <div className="input-wrapper">
                    <Datetime
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      displayTimeZone={getStorage("timeZone")}
                      inputProps={{ placeholder: "Select date..",onKeyDown: function (e) {
                        e.preventDefault();
                      } }}
                      value={this.state.driverHoldStartDt ? moment(this.state.driverHoldStartDt) : null}
                      isValidDate={this.startValidDate}
                      onChange = {
                        (e) => this.checkStartDate(moment(e).toISOString())
                      }
                    />
                    {this.state.driverHoldStartDt ?
                      <div className="input-icon" onClick={(e, index) => { this.setState({driverHoldStartDt:null}); }}>
                        <i className="uil uil-times font-weight-bold pr-1 pt-1" />
                      </div> :
                      <div className="input-icon">
                        <i className="uil uil-calendar-alt"></i>
                      </div>
                    }
                  </div>
                  </div>
                  <div>
                  <label className="col-form-label font-medium">
                    End Hold Date
                  </label>
                  <div className="input-wrapper">
                  <Datetime
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      inputProps={{ placeholder: "Select date..",onKeyDown: function (e) {
                        e.preventDefault();
                      } }}
                      displayTimeZone={getStorage("timeZone")}
                      value={this.state.driverHoldEndDt ? moment(this.state.driverHoldEndDt) : ""}
                      isValidDate={this.endValidDate}
                      onChange={(e) =>
                        this.checkEndDate(moment(e).toISOString())
                      }
                    />
                    {this.state.driverHoldEndDt ?
                      <div className="input-icon" onClick={(e, index) => { this.setState({driverHoldEndDt:null}); }}>
                        <i className="uil uil-times font-weight-bold pr-1 pt-1" />
                      </div> :
                      <div className="input-icon">
                        <i className="uil uil-calendar-alt"></i>
                      </div>
                    }
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg fcpopup__right">
                <div className="card mb-0">
                  <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${this.state.isLoadTabActive === 1 ? "active" : ""
                          }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleLoadTabActive(1)}
                      >
                        <IconSteering />
                        <div className="nav-link__text">Driver</div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${this.state.isLoadTabActive === 2 ? "active" : ""
                          }`}
                        href="javascript:void(0)"
                        onClick={() => !this.state.disableButton && this.handleLoadTabActive(2)}
                      >
                        <IconDocument />
                        <div className="nav-link__text">Documents</div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${this.state.isLoadTabActive === 3 ? "active" : ""
                          }`}
                        href="javascript:void(0)"
                        onClick={() => !this.state.disableButton && this.handleLoadTabActive(3)}
                      >
                        <IconClock9 />
                        <div className="nav-link__text">History</div>
                      </a>
                    </li>
                    {isEnablePermission && 
                      <li className="nav-item">
                        <a
                          className={`nav-link ${this.state.isLoadTabActive === 7 ? "active" : ""
                            }`}
                          href="javascript:void(0)"
                          onClick={() => !this.state.disableButton && this.handleLoadTabActive(7)}
                        >
                          <IconMobilePermission />
                          <div className="nav-link__text">Mobile Permissions</div>
                        </a>
                      </li>
                    }
                    <li className="nav-item">
                      <a
                        className={`nav-link ${this.state.isLoadTabActive === 4 ? "active" : ""
                          }`}
                        href="javascript:void(0)"
                        onClick={() => !this.state.disableButton && this.handleLoadTabActive(4)}
                      >
                        <IconSearchData />
                        <div className="nav-link__text">Audit</div>
                      </a>
                    </li>
                    {this.state._id && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${this.state.isLoadTabActive === 5 ? "active" : ""
                            }`}
                          href="javascript:void(0)"
                          onClick={() => !this.state.disableButton && this.handleLoadTabActive(5)}
                        >
                          <IconNotes />
                          <div className="nav-link__text">Notes</div>
                        </a>
                      </li>
                    )}

                    {/* <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.state.isLoadTabActive === 5 ? "active" : ""
                          }`}
                          href="javascript:void(0)"
                          onClick={() => this.handleLoadTabActive(5)}
                        >
                          <i class="uil uil-notes font-20"></i>
                          <div className="nav-link__text">Settlement Notes</div>
                        </a>
                      </li> */}
                  </ul>
                </div>
                <div className="tab-loads">
                  {this.state.isLoadTabActive === 1 && (               
                    <div className="card pt-30 px-20 mb-10">
                    <div className="font-14 font-medium mb-15">Driver Info</div>
                    <div className="form-row">
                      <StateFormInput
                        type="text"
                        name="name"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"First Name"}
                        isRequired={true}
                        className={"col-lg"}
                        value={this.state.name}
                        onBlur={this.onBlur}
                      />
                      <StateFormInput
                        type="text"
                        name="lastName"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Last Name"}
                        isRequired={true}
                        className={"col-lg"}
                        value={this.state.lastName}
                        onBlur={this.onBlur}
                      />
                      <div className={`form-group col-lg-2`} >
                        <div className="position-relative w-100">
                        <StateFormInput
                          type="text"
                          name="username"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          label={"Username"}
                          isRequired={true}
                          value={this.state.username}
                          onBlur={this.onBlur}
                        />
                        {this.state.isUserNameValid === false && (
                          <small className="text-danger">This username is already taken, please try another one</small>
                        )}
                        <div className="position-absolute msg-pop-tooltip">
                        {this.state.isUserNameValid === false && (
                          <div>                   
                            <React.Fragment>
                            <span
                                data-tip
                                data-for="userreEnable"
                                type="button"                                                       
                              >
                              <IconWarningCircle className="text-danger"/>
                              </span>
                              <ReactTooltip id="userreEnable" place="right">
                                <span>This username is already taken, please try another one</span>
                              </ReactTooltip>
                            </React.Fragment>
                          </div>
                          )
                        }
                        {this.state.isUserNameValid && 
                          <IconCheckCircle className="text-success"/>
                        }
                        </div>
                        </div>                   
                      </div>    
                      <StateFormInput
                        type="text"
                        name="email"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Email"}
                        isRequired={true}
                        className={"col-lg"}
                        value={this.state.email}
                      />
                      <StateFormNumberFormat
                        type="text"
                        name="mobile"
                        format="###-###-####"
                        mask="_"
                        value={this.state.mobile}
                        onValChange={this.setValChange}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        isRequired={true}
                        className={"col-lg"}
                        label={"Phone"}
                        onBlur={this.onBlur}
                        isUnmount={this.state.isUnmount}
                      />
                      <StateFormSelect
                        type="text"
                        name="profileType"
                        label={"Select Profile Type"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={profileOptions}
                        className={"col-lg"}
                        value={this.state.profileType}
                        isClearable
                        isMulti={true}
                      />
                    </div>
                    <div className="form-row">
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label="Date of Birth"
                        name="dob"
                        value={this.state.dob ? moment(this.state.dob) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                        inputClassName="left"
                      />
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label="Date of Hire (DOH)"
                        name="doh"
                        value={this.state.doh ? moment(this.state.doh) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                      />
                      <StateFormInput
                        type="text"
                        name="billingEmail"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Billing Email"}
                        value={this.state.billingEmail}
                      />
                      {!this.state._id && (
                        <StateFormInput
                          type="text"
                          name="password"
                          className={"col-lg-2"}
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          label={"Password"}
                          isRequired={true}
                          value={this.state.password}
                        />
                      )}
                      <StateFormSelect
                        type="text"
                        name="homeTerminalTimezone"
                        label={"Home Terminal Time Zone"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={terminalTimzoneOptions}
                        className={"col-lg-2"}
                        value={
                          this.state.homeTerminalTimezone &&
                          terminalTimzoneOptions.find(
                            (option) => option.value === this.state.homeTerminalTimezone
                          )
                        }
                      />
                      {this.state.isTerminal && (
                        <StateFormSelect
                          name="newTerminal"
                          label={"Terminal"}
                          isRequired={true}
                          className="col-lg-2"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          options={Terminal}
                          value={this.state.newTerminal}
                          isMulti
                        />
                      )}
                    </div>
                    <div className="hr-light mt-10 mb-20"></div>
                    <div className="font-14 font-medium mb-15">Expiration Dates</div>
                    <div className="form-row">
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label="Medical Exp"
                        name="medicalExp"
                        value={this.state.medicalExp ? moment(this.state.medicalExp) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                        inputClassName="left"
                      />
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label="Twic Exp"
                        name="twicExp"
                        value={this.state.twicExp ? moment(this.state.twicExp) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                      />
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label={showForCarrier() ? "Port Id Exp" : "Sea link Exp"}
                        name="seaLinkExp"
                        value={this.state.seaLinkExp ? moment(this.state.seaLinkExp) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                      />
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label="DL Exp"
                        name="dlExp"
                        value={this.state.dlExp ? moment(this.state.dlExp) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                      />
                      <StateFormDateTime
                        formsTouched={formsTouched}
                        formErrors={formErrors}
                        label="Termination Date"
                        name="termination"
                        value={this.state.termination ? moment(this.state.termination) : ""}
                        onValChange={this.setValChange}
                        isRequired={false}
                        className="col-lg-2"
                        isDate={true}
                        handleRemoveDate={() => this.setState({termination: ""})}
                      />
                    </div>
                    <div className="hr-light mt-10 mb-20"></div>
                    <div className="font-14 font-medium mb-15">Document Information</div>
                    <div className="form-row">
                      <StateFormInput
                        type="text"
                        name="sealLinkNumber"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={showForCarrier() ? "Port Id" : "Sealink #"}
                        placeholder="Street"
                        value={this.state.sealLinkNumber}
                      />
                      <StateFormInput
                        type="text"
                        name="registerBusinessName"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Register Business Name"}
                        value={this.state.registerBusinessName}
                      />
                      <StateFormInput
                        type="text"
                        name="hst"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"HST #"}
                        value={this.state.hst}
                      />
                      <EncryptFormInput
                        type="text"
                        name="socialSecurity"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Social Security #"}
                        value={this.state.socialSecurity}
                        socialSecurityVal={this.state.socialSecurity}
                        driverId={this.state.driverId}
                        showEncryptedValue={this.state.showEncryptedValue}
                        setShowEncryptedValue = {(e) => this.setState({showEncryptedValue: e})}
                        setEncryptedData={this.state.setSocialSecurity}
                      />
                      <StateFormInput
                        type="text"
                        name="tablet"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Tablet #"}
                        value={this.state.tablet}
                      />
                      <StateFormInput
                        type="text"
                        name="eld"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"ELD #"}
                        value={this.state.eld}
                      />
                      <StateFormInput
                        type="text"
                        name="fuelCard"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Fuel Card"}
                        value={this.state.fuelCard}
                      />
                      <StateFormInput
                        type="text"
                        name="ezPass"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"EZ Pass"}
                        value={this.state.ezPass}
                      />
                      <EncryptFormInput
                        type="text"
                        name="bankAccount"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Bank Account #"}
                        value={this.state.bankAccount}
                        bankAccountVal={this.state.bankAccount}
                        driverId={this.state.driverId}
                        showEncryptedValue={this.state.showEncryptedValue}
                        setShowEncryptedValue = {(e) => this.setState({showEncryptedValue: e})}
                        setEncryptedData={this.state.setBankAccount}
                      />
                      <EncryptFormInput
                        type="text"
                        name="routing"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Routing #"}
                        value={this.state.routing}
                        // handleDecryptVal={this.handleDecryptVal}
                        routingVal={this.state.routing}
                        driverId={this.state.driverId}
                        showEncryptedValue={this.state.showEncryptedValue}
                        setShowEncryptedValue = {(e) => this.setState({showEncryptedValue: e})}
                        setEncryptedData={this.state.setRouting}
                      />
                      <StateFormSelect
                        type="text"
                        name="licence"
                        label={"License State"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={StatesOptions}
                        className={"col-lg-2"}
                        value={
                          this.state.licence &&
                          StatesOptions.find((item) => item.value === this.state.licence)
                        }
                        isClearable
                      />
                      <StateFormInput
                        type="text"
                        name="licenceNumber"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"License Number"}
                        // isRequired={true}
                        value={this.state.licenceNumber}
                      />
                    </div>
                    <div className="hr-light mt-10 mb-20"></div>
                    <div className="font-14 font-medium mb-15">Preferences</div>
                  
                    <div className="form-row">
                      <StateFormSelect
                        type="text"
                        name="preferredStates"
                        label={"Preferred States"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={StatesOptions}
                        className={"col-lg-2"}
                        value={this.state.preferredStates}
                        isMulti={true}
                      />
                      <StateFormSelect
                        type="text"
                        name="preferredTypesOfLoad"
                        label={"Preferred Load Types"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={LoadTypeOptions}
                        className={"col-lg-2"}
                        value={this.state.preferredTypesOfLoad}
                        isMulti={true}
                      />
                      <StateFormSelect
                        type="text"
                        name="preferredDistance"
                        label={"Preferred Distance"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={DistanceOptions}
                        className={"col-lg-2"}
                        value={this.state.preferredDistance}
                        isMulti={true}
                      />
                      <StateFormSelect
                        type="text"
                        name="hazmat"
                        label={"Hazmat"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={loadFlags}
                        className={"col-lg-2"}
                        value={loadFlags.find((opt) => opt.value === this.state.hazmat)}
                      />
                  
                      <div className="col-lg-2">
                        <label>Driver Shift</label>
                        <div className="d-flex mt-2">
                          <div className="form-check mr-4">
                            <input
                              type="radio"
                              className="form-check-input"
                              id={"day-shift"}
                              name="shift"
                              checked={this.state.isDayDriver}
                              onChange={(e) => {
                                this.setState({ isDayDriver: true });
                              }}
                            />
                            <label className="form-check-label" htmlFor={"day-shift"}>
                              Day
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id={"night-shift"}
                              name="shift"
                              checked={!this.state.isDayDriver}
                              onChange={(e) => {
                                this.setState({ isDayDriver: false });
                              }}
                            />
                            <label className="form-check-label" htmlFor={"night-shift"}>
                              Night
                            </label>
                          </div>
                        </div>
                      </div>
                      <StateFormSelect
                        type="text"
                        name="liquor"
                        label={showForCarrier() ? "Liquid" : "Liquor"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={loadFlags}
                        className={"col-lg-2"}
                        value={loadFlags.filter((opt) => opt.value === this.state.liquor)}
                      />
                      <StateFormSelect
                        type="text"
                        name="overweight"
                        label={"Overweight"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={loadFlags}
                        className={"col-lg-2"}
                        value={loadFlags.find((opt) => opt.value === this.state.overweight)}
                      />
                    </div>
                    <div className="form-row">
                      <StateFormSelect
                        type="text"
                        name="owerWeightStates"
                        label={"Overweight States"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={StatesOptions}
                        className={"col-lg-2"}
                        isMulti={true}
                        value={this.state.owerWeightStates}
                      />
                      <StateFormInput
                        type="text"
                        name="reefer"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Reefer"}
                        value={this.state.reefer}
                      />
                      <StateFormSelect
                        type="text"
                        name="disableDriverPay"
                        label={`Disable Driver Pay`}
                        // ${this.state.disableDriverPay}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={YesOrNo}
                        className={"col-lg-2"}
                        value={YesOrNo.find((pt) => pt.value == this.state.disableDriverPay)}
                      />
                    </div>
                    <div className="hr-light mt-10 mb-20"></div>
                    <div className="font-14 font-medium mb-15">Other</div>
                  
                    <div className="form-row">
                      <StateFormInput
                        type="text"
                        name="EmergencyContactName"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Emergency Contact Name"}
                        value={this.state.EmergencyContactName}
                      />
                      <StateFormInput
                        type="text"
                        name="EmergencyRelation"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Emergency Relation"}
                        value={this.state.EmergencyRelation}
                      />
                      <StateFormNumberFormat
                        type="text"
                        name="EmergencyContactNumber"
                        format="###-###-####"
                        mask="_"
                        value={this.state.EmergencyContactNumber}
                        onValChange={this.setValChange}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        className={"col-lg-2"}
                        label={"Emergency Home Phone"}
                        isUnmount={this.state.isUnmount}
                      />
                      <StateFormInput
                        type="text"
                        name="truck"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Fleet Owner"}
                        value={this.state.fleetOwner ? this.state.fleetOwner.company_name : ""}
                        disabled={true}
                      />
                      <StateFormInput
                        type="text"
                        name="carrier"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Carrier Name"}
                        value={
                          this.state.userProfile && this.state.userProfile.user.role === "carrier"
                            ? this.state.userProfile.user.carrier.company_name
                            : ""
                        }
                        disabled={true}
                      />
                      <StateFormInput
                        type="text"
                        name="carrierAddress"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Main Office Address"}
                        placeholder="Street"
                        value={
                          this.state.userProfile &&
                          this.state.userProfile.user.role === "carrier" &&
                          this.state.userProfile.user.defaultAddressId
                            ? this.state.userProfile.user.defaultAddressId.company_address
                            : ""
                        }
                        disabled={true}
                      />
                      <StateFormInput
                        type="text"
                        name="hstPercentage"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"HST Percentage"}
                        value={this.state.hstPercentage}
                      />
                      <StateFormInput
                        type="text"
                        name="tShirtSizes"
                        className={"col-lg-2"}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"T-Shirt Sizes"}
                        value={this.state.tShirtSizes}
                      />
                      <StateFormSelect
                        type="text"
                        name="driverPreferences"
                        label={`Driver Preferences ${this.state.driverPreferences}`}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={YesOrNo}
                        className={"col-lg-2"}
                        value={YesOrNo.find((pt) => pt.value == this.state.driverPreferences)}
                      />
                      <StateFormSelect
                        type="text"
                        name="isSettlementHide"
                        label={`Hide Settlements`}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        options={YesOrNo}
                        className={"col-lg-2"}
                        value={YesOrNo.find((pt) => pt.value == this.state.isSettlementHide)}
                      />
                      <div className="col-md-4">
                        <div>
                          <AddressInput
                            type="text"
                            label={"Permanent Address"}
                            name="address"
                            formErrors={formErrors}
                            formsTouched={formsTouched}
                            value={this.state.permanentAddress}
                            placeholder="e.g. 1111 Parker Rd. Allentown, New Mexico 11111"
                            onValChange={(e) => {
                              this.setValChange("permanentAddress",e.target.value);
                            }}
                            />
                            {this.state.features && this.state.features.length != 0 && (
                              <div className="react-mapbox-ac-suggestion">
                                {this.state.features.map((place, key) => {
                                  return (
                                    <p key={key} onClick={() => this.onSelect(place)}>
                                      {place.formatted_address}
                                    </p>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  )}
                  {this.state.isLoadTabActive === 2 && (
                    <div className="tab" id="tab-chassis">
                      <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                        <h4 className="mb-0 font-20">
                          {documents && documents.length} Documents{" "}
                        </h4>
                        <button
                          className="ml-auto btn btn-primary"
                          onClick={this.handleShowUploadModal}
                        >
                          <IconUpload className="mr-2" />
                          Upload Document
                        </button>
                      </div>
                      <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                      {!this.props.type &&
                        getStorage("currentUserRole") != "customer" && (
                          <UploadedDocuments
                            listDocuments={documents}
                            deleteDocument={(id) =>
                              this.deleteEquipmentDoc({
                                docId: id,
                                _id: this.state._id,
                              })
                            }
                            onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                            newView={this.newView}
                            selectedDoc={this.state.selectedDoc}
                            setSelectedDoc={(docIndex) => {
                              this.setState({ selectedDoc: docIndex });
                            }}
                            handleShowUploadModal={this.handleShowUploadModal}
                            uploadResult={this.uploadResult}
                            multi={false} 
                            type={"image"}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.isLoadTabActive === 3 && (
                    <div className="card p-10">
                      <label>Coming Soon</label>
                    </div>
                  )}
                  {isEnablePermission && this.state.isLoadTabActive === 7 && (
                    <div className="card p-10">
                      <div className="form-row">
                        {Object.keys(DRIVER_PERMISSIONS).map((permission, key) => (
                          <div className="col" key={key}>
                            <h6 className="font-size-regular mb-20 mt-10">
                              {startCase(toLower(permission))}
                            </h6>
                            <div className="d-flex flex-column">
                              {/* {Array.isArray(Object.keys(DRIVER_PERMISSIONS[permission])) && (
                                <React.Fragment>
                                  <div className="form-check form-check mb-1">
                                    <input
                                      name="showrequest"
                                      id={`check${i + "_" + key
                                        }_${DRIVER_PERMISSIONS[
                                          permission
                                        ].toString()}`}
                                      checked={this.checkIsPermissionAssigned(
                                        Object.keys(DRIVER_PERMISSIONS[permission])
                                      )}
                                      type="checkbox"
                                      className="form-check-input"
                                      data-permission={Object.keys(DRIVER_PERMISSIONS[permission]).toString()}
                                      onClick={this.handleChangeAll}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="isAll"
                                    >
                                      All
                                    </label>
                                  </div>
                                </React.Fragment>
                              )} */}
                              {Array.isArray(Object.keys(DRIVER_PERMISSIONS[permission])) &&
                                Object.keys(DRIVER_PERMISSIONS[permission]).map((d) => {
                                  return (
                                    <div className="form-check form-check mb-1" key={d}>
                                      <input
                                        name="showrequest"
                                        id={`check${key}_${d}`}
                                        checked={this.checkIsPermissionAssigned(
                                          d
                                        )}
                                        type="checkbox"
                                        className="form-check-input border-gray"
                                        data-permission={d}
                                        value={d}
                                        onClick={
                                          this.handleChangeAll
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`check${key}_${d}`}
                                      >
                                        {(DRIVER_PERMISSIONS[permission][d])}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {this.state.isLoadTabActive === 4 && (
                    <div className="tab" id="tab-chassis">
                      <div className="tab" id="tab-document">
                        <div className="table-responsive"
                          style={{ height: "calc(100vh - 230px)" }}
                        >
                          <table className="table table-card table-card--ls mb-10">
                            <thead>
                              <tr>
                                <th>User</th>
                                <th>Type</th>
                                <th>Time</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.audits &&
                                this.state.audits.map((d, key) => {
                                  let description;
                                  let url;
                                  let driverName;
                                  if (
                                    [
                                      "DOCUMENT_UPLOAD",
                                      "DOCUMENT_REMOVE",
                                    ].includes(d.type)
                                  ) {
                                    description = d.data.document.type;
                                    url = (
                                      <a href={d.data.document.url}>
                                        Open Document...
                                      </a>
                                    );
                                  }
                                  if (d.type === "ADD") {
                                    description = "Driver Name ";
                                    driverName = <b>{d.data.name}</b>;
                                  }

                                  if (d.type === "UPDATE") {
                                    let data;
                                    if (
                                      key !== this.state.audits.length - 1 &&
                                      this.state.audits[key + 1].userId
                                    ) {
                                      if (
                                        this.state.audits[key + 1].type ===
                                        "DOCUMENT_UPLOAD" ||
                                        this.state.audits[key + 1].type ===
                                        "DOCUMENT_REMOVE"
                                      ) {
                                        data =
                                          this.state.audits[key + 2] &&
                                          this.state.audits[key + 2].data;
                                      } else {
                                        data =
                                          this.state.audits[key + 1] &&
                                          this.state.audits[key + 1].data;
                                      }
                                    }
                                    const additionalInfo = d.data;

                                    let dateFormat = [
                                      "dlExp",
                                      "twicExp",
                                      "medicalExp",
                                      "seaLinkExp",
                                      "doh",
                                      "dob",
                                      "termination",
                                      "driverHoldStartDt",
                                      "driverHoldEndDt",
                                    ];
                                    let differanceDescription = differnceFinder(
                                      data,
                                      additionalInfo
                                    );
                                    description = Object.keys(
                                      differanceDescription || []
                                    )
                                      .filter(
                                        (key1) =>
                                          typeof differanceDescription[key1] !==
                                          "object"
                                      )
                                      .map((key1) => {
                                        if (dateFormat.includes(key1)) {
                                          if(differanceDescription[key1]) {
                                            return (
                                              <p>
                                                {key1} changed to{" "}
                                                <b>
                                                  {moment(
                                                    differanceDescription[key1]
                                                  ).format("ddd MMM DD YYYY")}
                                                </b>
                                              </p>                                            
                                            );
                                          } else {
                                            return(
                                              <p>{key1} removed</p>
                                            )
                                          }
                                        } else if (
                                          typeof differanceDescription[key1] ===
                                          "boolean"
                                        ) {
                                          return (     
                                              key1  == 'accountHold' ?
                                                <p>
                                           {differanceDescription[key1]  ? `Driver is put on hold.`: 'Driver is no longer on hold.' }
                                          </p> :
                                            <p>
                                              {key1} changed to{" "}
                                              {(key1 == 'socialSecurity' || key1 == 'routing' || key1 == 'bankAccount') ? 
                                                <b>
                                                {'*******'}
                                                </b>
                                              :
                                                <b>
                                                  {differanceDescription[
                                                    key1
                                                  ].toString()}
                                                </b>
                                              }
                                            </p>
                                          );
                                        } else if((key1 == 'socialSecurity' || key1 == 'routing' || key1 == 'bankAccount') && differanceDescription[key1] == "") {
                                          return ( <p>
                                            {key1} <b>removed</b>
                                          </p>)
                                        } else {
                                          return (
                                            <p>
                                              {key1} changed to{" "}
                                              <b>{differanceDescription[key1]}</b>
                                            </p>
                                          );
                                        }
                                      });
                                  }

                                  if(d.type === "DL_EXPIRY_UPDATED"){
                                    let data;
                                    const additionalInfo = d.data;

                                    let dateFormat = [
                                      "dlExp"
                                    ];
                                    let differanceDescription = differnceFinder(
                                      data,
                                      additionalInfo
                                    );
                                    description = Object.keys(
                                      differanceDescription || []
                                    )
                                      .filter(
                                        (key1) =>
                                          typeof differanceDescription[key1] !==
                                          "object"
                                      )
                                      .map((key1) => {
                                        if (dateFormat.includes(key1)) {
                                          if(differanceDescription[key1]) {
                                            return (
                                              <p>
                                                {key1} changed to{" "}
                                                <b>
                                                  {moment(
                                                    differanceDescription[key1]
                                                  ).format("ddd MMM DD YYYY")}
                                                </b>
                                              </p>                                            
                                            );
                                          }
                                        }
                                      });
                                  }
                                  if(d.type === "HOLD_UPDATED"){
                                    let data;
                                    const additionalInfo = d.data;

                                    let dateFormat = [
                                      "driverHoldStartDt"
                                    ];
                                    let differanceDescription = differnceFinder(
                                      data,
                                      additionalInfo
                                    );
                                    description = Object.keys(
                                      differanceDescription || []
                                    )
                                      .filter(
                                        (key1) =>
                                          typeof differanceDescription[key1] !==
                                          "object"
                                      )
                                      .map((key1) => {
                                        if (dateFormat.includes(key1)) {
                                          if(differanceDescription[key1]) {
                                            return (
                                              <p>
                                                {key1} changed to{" "}
                                                <b>
                                                  {moment(
                                                    differanceDescription[key1]
                                                  ).format("ddd MMM DD YYYY")}
                                                </b>
                                              </p>                                            
                                            );
                                          }
                                        }
                                      });
                                  }
                                  if(d.type === "UNHOLD_UPDATED"){
                                    let data;
                                    const additionalInfo = d.data;

                                    let dateFormat = [
                                      "driverHoldEndDt"
                                    ];
                                    let differanceDescription = differnceFinder(
                                      data,
                                      additionalInfo
                                    );
                                    description = Object.keys(
                                      differanceDescription || []
                                    )
                                      .filter(
                                        (key1) =>
                                          typeof differanceDescription[key1] !==
                                          "object"
                                      )
                                      .map((key1) => {
                                        if (dateFormat.includes(key1)) {
                                          if(differanceDescription[key1]) {
                                            return (
                                              <p>
                                                {key1} changed to{" "}
                                                <b>
                                                  {moment(
                                                    differanceDescription[key1]
                                                  ).format("ddd MMM DD YYYY")}
                                                </b>
                                              </p>                                            
                                            );
                                          }
                                        }
                                      });
                                  }
                                  if(d.type === "HOLD_CRON"){
                                    let data;
                                    const additionalInfo = d.data;

                                    let dateFormat = [
                                      "driverHoldStartDt"
                                    ];
                                    let differanceDescription = differnceFinder(
                                      data,
                                      additionalInfo
                                    );
                                    description = Object.keys(
                                      differanceDescription || []
                                    )
                                      .filter(
                                        (key1) =>
                                          typeof differanceDescription[key1] !==
                                          null
                                      )
                                      .map((key1) => {
                                        if (dateFormat.includes(key1)) {
                                          if(!differanceDescription[key1]) {
                                            return(
                                              <p>{key1} removed</p>
                                            )
                                          }
                                        }
                                      })
                                    }
                                    if(d.type === "UNHOLD_CRON"){
                                      let data;
                                      const additionalInfo = d.data;
  
                                      let dateFormat = [
                                        "driverHoldEndDt",
                                      ];
                                      let differanceDescription = differnceFinder(
                                        data,
                                        additionalInfo
                                      );
                                      description = Object.keys(
                                        differanceDescription || []
                                      )
                                        .filter(
                                          (key1) =>
                                            typeof differanceDescription[key1] !==
                                            null
                                        )
                                        .map((key1) => {
                                          if (dateFormat.includes(key1)) {
                                            if(!differanceDescription[key1]) {
                                              return(
                                                <p>{key1} removed</p>
                                              )
                                            }
                                          }
                                        })
                                      }
                                  return (
                                    <tr key={d._id} id={d._id}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <span className="avatar-circle avatar-xs mr-1">{(d.userId && d.userId.name ? d.userId.name.charAt(0): d.type && (d.type=="HOLD_CRON" || d.type == "UNHOLD_CRON") ? "S" : "")}</span>
                                          {d.userId && d.userId.name ? d.userId.name: d.type && (d.type=="HOLD_CRON" || d.type == "UNHOLD_CRON") ? "SYSTEM" : ""}
                                        </div>
                                      </td>
                                      <td>
                                        <span className="badge badge-gray-100">
                                          {d.type.replace(/_/g, " ")}
                                        </span>
                                      </td>
                                      <td>
                                        <div>{moment(d.createdAt).format("MM/DD/YY")}</div>
                                        <div className="text-muted">{moment(d.createdAt).format("hh:mm a")}</div>
                                      </td>
                                      <td>
                                        {description} {driverName} {url}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.isLoadTabActive === 5 && (
                    <div className="tab" id="tab-chassis">
                      <div className="card">
                        <div className="pt-30 px-20 mb-10">
                          <div className="d-flex align-items-center mb-20">
                            <CustomIconTruck className="mr-10" />
                            <h5 className="font-16 mb-0">Driver: </h5>
                          </div>
                          <div className="form-group">
                            <textarea
                              rows="5"
                              className="form-control"
                              style={{ height: "100%" }}
                              onChange={({ target }) =>
                                this.setState({ notes: target.value })
                              }
                              value={this.state.notes ? this.state.notes : ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.isLoadTabActive === 6 && (
                    <div className="tab" id="tab-chassis">
                      <div className="card">
                        <div className="pt-30 px-20 mb-10">
                          <div className="d-flex align-items-center mb-20">
                            <h5 className="font-16 mb-0">
                              Add Settlement Notes:{" "}
                            </h5>
                          </div>
                          <div className="form-group">
                            <textarea
                              rows="5"
                              className="form-control"
                              style={{ height: "100%" }}
                              onChange={({ target }) =>
                                this.setState({ settlementNote: target.value })
                              }
                            >
                              {this.state.settlementNote}
                            </textarea>
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => {
                              this.setState(
                                { AddDriverModal: false, fileType: null, tab: 1, isUserNameValid: null, username: null },
                                () => {
                                  if (this.props.isOnlyPopup) {
                                    this.props.falsyData();
                                  }
                                }
                              );
                            }}
                          >
                            Cancel
                          </button>
                          {this.state.tab !== 3 &&
                            (this.state.tab == 2 && this.state._id ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className={`btn ${this.state._id ? "btn-primary" : "btn-success"
                                  }`}
                                disabled={this.state.isSaving}
                                onClick={() => {
                                  this.setState({ loader: true, isSaving: true, isUserNameValid: null });
                                  this.insertdata();
                                }}
                              >
                                {this.state._id ? "Update" : "Add New Driver"}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        {/* <AddDriver/> */}

        <Modal
          show={this.state.showModal || this.props.isQuickAdd}
          animation={false}
          dialogClassName="modal-xl modal-dialog-centered"
        >
          <Modal.Header>
            <Modal.Title>Add New Driver</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="font-14 font-medium mb-15">Driver Info</div>
                <div className="form-row">
                  <StateFormInput
                    type="text"
                    name="name"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"First Name"}
                    isRequired={true}
                    className={"col-lg"}
                    value={this.state.name}
                    onBlur={this.onBlur}
                  />
                  <StateFormInput
                    type="text"
                    name="lastName"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Last Name"}
                    isRequired={true}
                    className={"col-lg"}
                    value={this.state.lastName}
                    onBlur={this.onBlur}
                  />
                  <div className={`form-group col-lg-2`} >
                    <div className="position-relative w-100">
                    <StateFormInput
                      type="text"
                      name="username"
                      formErrors={formErrors}
                      formsTouched={formsTouched}
                      onValChange={this.setValChange}
                      label={"Username"}
                      isRequired={true}
                      value={this.state.username}
                      onBlur={this.onBlur}
                    />
                    {this.state.isUserNameValid === false && (
                      <small className="text-danger">This username is already taken, please try another one</small>
                    )}
                    <div className="position-absolute msg-pop-tooltip">
                    {this.state.isUserNameValid === false && (
                      <div>                   
                        <React.Fragment>
                        <span
                            data-tip
                            data-for="userreEnable"
                            type="button"                                                       
                          >
                          <IconWarningCircle className="text-danger"/>
                          </span>
                          <ReactTooltip id="userreEnable" place="right">
                            <span>This username is already taken, please try another one</span>
                          </ReactTooltip>
                        </React.Fragment>
                      </div>
                      )
                    }
                    {this.state.isUserNameValid && 
                      <IconCheckCircle className="text-success"/>
                    }
                    </div>
                    </div>                   
                  </div>
                  <StateFormInput
                    type="text"
                    name="email"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Email"}
                    isRequired={true}
                    className={"col-lg"}
                    value={this.state.email}
                  />
                  <StateFormNumberFormat
                    type="text"
                    name="mobile"
                    format="###-###-####"
                    mask="_"
                    value={this.state.mobile}
                    onValChange={this.setValChange}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    isRequired={true}
                    className={"col-lg"}
                    label={"Phone"}
                    onBlur={this.onBlur}
                    isUnmount={this.state.isUnmount}
                  />
                  <StateFormSelect
                    type="text"
                    name="profileType"
                    label={"Select Profile Type"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={profileOptions}
                    className={"col-lg"}
                    value={this.state.profileType}
                    isClearable
                    isMulti={true}
                  />
                </div>
                <div className="form-row">
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label="Date of Birth"
                    name="dob"
                    value={this.state.dob ? moment(this.state.dob) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg-2"
                    inputClassName="left"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label="Date of Hire (DOH)"
                    name="doh"
                    value={this.state.doh ? moment(this.state.doh) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg-2"
                  />
                  <StateFormInput
                    type="text"
                    name="billingEmail"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Billing Email"}
                    value={this.state.billingEmail}
                  />
                  {!this.state._id && (
                    <StateFormInput
                      type="text"
                      name="password"
                      className={"col-lg-2"}
                      formErrors={formErrors}
                      formsTouched={formsTouched}
                      onValChange={this.setValChange}
                      label={"Password"}
                      isRequired={true}
                      value={this.state.password}
                    />
                  )}
                  <StateFormSelect
                    type="text"
                    name="homeTerminalTimezone"
                    label={"Home Terminal Time Zone"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={terminalTimzoneOptions}
                    className={"col-lg-2"}
                    value={
                      this.state.homeTerminalTimezone &&
                      terminalTimzoneOptions.find(
                        (option) => option.value === this.state.homeTerminalTimezone
                      )
                    }
                  />
                  {this.state.isTerminal && (
                    <StateFormSelect
                      name="newTerminal"
                      label={"Terminal"}
                      isRequired={true}
                      className="col-lg-2"
                      formErrors={formErrors}
                      formsTouched={formsTouched}
                      onValChange={this.setValChange}
                      options={Terminal}
                      value={this.state.newTerminal}
                      isMulti
                    />
                  )}
                </div>
                <div className="hr-light mt-10 mb-20"></div>

                <div className="font-14 font-medium mb-15">Expiration Dates</div>
                <div className="form-row">
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label="Medical Exp"
                    name="medicalExp"
                    value={this.state.medicalExp ? moment(this.state.medicalExp) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg"
                    inputClassName="left"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label="Twic Exp"
                    name="twicExp"
                    value={this.state.twicExp ? moment(this.state.twicExp) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label={showForCarrier() ? "Port Id Exp" : "Sea link Exp"}
                    name="seaLinkExp"
                    value={this.state.seaLinkExp ? moment(this.state.seaLinkExp) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label="DL Exp"
                    name="dlExp"
                    value={this.state.dlExp ? moment(this.state.dlExp) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg"
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    label="Termination Date"
                    name="termination"
                    value={this.state.termination ? moment(this.state.termination) : ""}
                    onValChange={this.setValChange}
                    isRequired={false}
                    className="col-lg"
                    isDate={true}
                    handleRemoveDate={() => this.setState({termination: ""})}
                  />
                </div>
                <div className="hr-light mt-10 mb-20"></div>
                <div className="font-14 font-medium mb-15">Document Information</div>
                <div className="form-row">
                  <StateFormInput
                    type="text"
                    name="sealLinkNumber"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={showForCarrier() ? "Port Id" : "Sealink #"}
                    placeholder="Street"
                    value={this.state.sealLinkNumber}
                  />
                  <StateFormInput
                    type="text"
                    name="registerBusinessName"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Register Business Name"}
                    value={this.state.registerBusinessName}
                  />
                  <StateFormInput
                    type="text"
                    name="hst"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"HST #"}
                    value={this.state.hst}
                  />
                  <StateFormInput
                    type="text"
                    name="socialSecurity"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Social Security #"}
                    value={this.state.socialSecurity}
                  />
                  <StateFormInput
                    type="text"
                    name="tablet"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Tablet #"}
                    value={this.state.tablet}
                  />
                  <StateFormInput
                    type="text"
                    name="eld"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"ELD #"}
                    value={this.state.eld}
                  />
                </div>
                <div className="form-row">
                  <StateFormInput
                    type="text"
                    name="fuelCard"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Fuel Card"}
                    value={this.state.fuelCard}
                  />
                  <StateFormInput
                    type="text"
                    name="ezPass"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"EZ Pass"}
                    value={this.state.ezPass}
                  />
                  <StateFormInput
                    type="text"
                    name="bankAccount"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Bank Account #"}
                    value={this.state.bankAccount}
                  />
                  <StateFormInput
                    type="text"
                    name="routing"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Routing #"}
                    value={this.state.routing}
                  />
                  <StateFormSelect
                    type="text"
                    name="licence"
                    label={"License State"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={StatesOptions}
                    className={"col-lg"}
                    value={
                      this.state.licence &&
                      StatesOptions.find((item) => item.value === this.state.licence)
                    }
                    isClearable
                  />
                  <StateFormInput
                    type="text"
                    name="licenceNumber"
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"License Number"}
                    // isRequired={true}
                    value={this.state.licenceNumber}
                  />
                </div>
                <div className="hr-light mt-10 mb-20">
                </div>
                <div className="font-14 font-medium mb-15">
                  Preferences
                </div>
                <div className="form-row">
                  <StateFormSelect
                    type="text"
                    name="preferredStates"
                    label={"Preferred States"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={StatesOptions}
                    className={"col-lg"}
                    value={this.state.preferredStates}
                    isMulti={true}
                  />
                  <StateFormSelect
                    type="text"
                    name="preferredTypesOfLoad"
                    label={"Preferred Load Types"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={LoadTypeOptions}
                    className={"col-lg"}
                    value={this.state.preferredTypesOfLoad}
                    isMulti={true}
                  />
                  <StateFormSelect
                    type="text"
                    name="preferredDistance"
                    label={"Preferred Distance"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={DistanceOptions}
                    className={"col-lg"}
                    value={this.state.preferredDistance}
                    isMulti={true}
                  />
                  <StateFormSelect
                    type="text"
                    name="hazmat"
                    label={"Hazmat"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={loadFlags}
                    className={"col-lg"}
                    value={loadFlags.find((opt) => opt.value === this.state.hazmat)}
                  />
                   <div className="col-lg">
                    <label>Driver Shift</label>
                    <div className="d-flex mt-2">
                      <div className="form-check mr-4">
                        <input
                          type="radio"
                          className="form-check-input"
                          id={"day-shift"}
                          name="shift"
                          checked={this.state.isDayDriver}
                          onChange={(e) => {
                            this.setState({ isDayDriver: true });
                          }}
                        />
                        <label className="form-check-label" htmlFor={"day-shift"}>
                          Day
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id={"night-shift"}
                          name="shift"
                          checked={!this.state.isDayDriver}
                          onChange={(e) => {
                            this.setState({ isDayDriver: false });
                          }}
                        />
                        <label className="form-check-label" htmlFor={"night-shift"}>
                          Night
                        </label>
                      </div>
                    </div>
                  </div>
                  <StateFormSelect
                    type="text"
                    name="liquor"
                    label={showForCarrier() ? "Liquid" : "Liquor"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={loadFlags}
                    className={"col-lg-2"}
                    // value={loadFlags.filter((opt) => opt.value === this.state.liquor)}
                  />
                </div>
                <div className="form-row">
                  <StateFormSelect
                    type="text"
                    name="overweight"
                    label={"Overweight"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={loadFlags}
                    className={"col-lg-2"}
                    value={loadFlags.find((opt) => opt.value === this.state.overweight)}
                  />
                  <StateFormSelect
                    type="text"
                    name="owerWeightStates"
                    label={"Overweight States"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={StatesOptions}
                    className={"col-lg-2"}
                    value={this.state.owerWeightStates}
                    isMulti={true}
                  />
                  <StateFormInput
                    type="text"
                    name="reefer"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Reefer"}
                    value={this.state.reefer}
                  />
                  <StateFormSelect
                    type="text"
                    name="disableDriverPay"
                    label={`Disable Driver Pay`}
                    // ${this.state.disableDriverPay}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={YesOrNo}
                    className={"col-lg-2"}
                    value={YesOrNo.find((pt) => pt.value == this.state.disableDriverPay)}
                  />
                </div>
                <div className="hr-light mt-10 mb-20">
                </div>
                <div className="font-14 font-medium mb-15">
                  Other
                </div>
                <div className="form-row">
                  <StateFormInput
                    type="text"
                    name="EmergencyContactName"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Emergency Contact Name"}
                    value={this.state.EmergencyContactName}
                  />
                  <StateFormInput
                    type="text"
                    name="EmergencyRelation"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Emergency Relation"}
                    value={this.state.EmergencyRelation}
                  />
                  <StateFormNumberFormat
                    type="text"
                    name="EmergencyContactNumber"
                    format="###-###-####"
                    mask="_"
                    value={this.state.EmergencyContactNumber}
                    onValChange={this.setValChange}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    className={"col-lg-2"}
                    label={"Emergency Home Phone"}
                    isUnmount={this.state.isUnmount}
                  />
                  <StateFormInput
                    type="text"
                    name="truck"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Fleet Owner"}
                    value={this.state.fleetOwner ? this.state.fleetOwner.company_name : ""}
                    disabled={true}
                  />
                  <StateFormInput
                    type="text"
                    name="carrier"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Carrier Name"}
                    value={
                      this.state.userProfile &&
                      this.state.userProfile.user.role === "carrier"
                        ? this.state.userProfile.user.carrier.company_name
                        : ""
                    }
                    disabled={true}
                  />
                  <StateFormInput
                    type="text"
                    name="carrierAddress"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Main Office Address"}
                    placeholder="Street"
                    value={
                      this.state.userProfile &&
                      this.state.userProfile.user.role === "carrier" &&
                      this.state.userProfile.user.defaultAddressId
                        ? this.state.userProfile.user.defaultAddressId.company_address
                        : ""
                    }
                    disabled={true}
                  />
                  <StateFormInput
                    type="number"
                    name="hstPercentage"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"HST Percentage"}
                    value={this.state.hstPercentage}
                  />
                  <StateFormInput
                    type="text"
                    name="tShirtSizes"
                    className={"col-lg-2"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"T-Shirt Sizes"}
                    value={this.state.tShirtSizes}
                  />
                    
                  <StateFormSelect
                    type="text"
                    name="driverPreferences"
                    label={`Driver Preferences ${this.state.driverPreferences}`}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    options={YesOrNo}
                    className={"col-lg-2"}
                    value={YesOrNo.find((pt) => pt.value == this.state.driverPreferences)}
                  />
                  <div className="col-md-4">
                  <div>
                  <AddressInput
                    type="text"
                    label={"Permanent Address"}
                    name="address"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    value={this.state.permanentAddress}
                    placeholder="e.g. 1111 Parker Rd. Allentown, New Mexico 11111"
                    onValChange={(e) => {
                      this.setValChange("permanentAddress",e.target.value);
                    }}
                    />
                    {this.state.features && this.state.features.length != 0 && (
                      <div className="react-mapbox-ac-suggestion">
                        {this.state.features.map((place, key) => {
                          return (
                            <p key={key} onClick={() => this.onSelect(place)}>
                              {place.formatted_address}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>


          <Modal.Footer>
            <button
              className="btn btn-link"
              onClick={() => {
                this.setState({ showModal: false, isUserNameValid: null, username: null});
                if (this.props.isQuickAdd) {
                  this.props.falsyData("driver");
                }
              }}
            >
              Cancel
            </button>
            {this.state.tab !== 4 &&
              (this.state.tab == 2 && this.state._id ? (
                ""
              ) : (
                <button
                  className={`btn ${this.state._id ? "btn-primary" : "btn-success"
                    }`}
                  onClick={() => {
                    this.setState({ loader: true, isSaving: true});
                    this.insertdata();
                  }}
                  disabled={this.state.isSaving}
                >
                  {this.state.isSubmit && <i className="uil uil-spinner-alt mr-1" />}
                  { this.props.isQuickAdd ? "Add New Driver" : "Add"}
                </button>
              ))}
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.resetId ? true : false} className="show">
          <ResetDriverPassword
            setResetId={() => this.setState({ resetId: undefined })}
            driverId={this.state.resetId}
            actions={this.props.actions}
          />
        </Modal>
        <Modal
          show={this.state.viewAccessprialForm ? true : false}
          className="modal-extend modal-nofooter"
        >
          <Accessorials
            onClose={() =>
              this.setState({ viewAccessprialForm: false, driverProfile: null })
            }
            driverProfile={this.state.driverProfile}
            onSaveAccessorial={this.onSaveAccessorial}
          />
        </Modal>

        <Modal show={this.state.showFileUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {!this.props.type && getStorage("currentUserRole") != "customer" && (
                <div>
                  <ImageUploader
                    uploadResult={this.uploadResult}
                    multi={false}
                    name="Choose from your device..."
                    type={"image"}
                    className="custom-file-input "
                  />
                  {this.state.documentFile && (
                      <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                        <IconUpload className="mr-20" />
                        <div className="w-100">
                          <div className="font-medium d-flex justify-content-between w-100 mb-1">
                            <div>{this.state.documentFile.name}</div>
                            <div className="ml-auto">{this.state.progress}%</div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              aria-valuenow={`${this.state.progress}`}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `${this.state.progress}%` }}
                            ></div>
                          </div>
                        </div>
                        <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                          <span
                            onClick={() =>
                              this.setState({
                                documentFile: null,
                                imagePreviewUrl: null,
                                pdfPreviewUrl: null,
                                progress: 0,
                              })
                            }
                          >
                            {" "}
                            <IconTimes className="" />
                          </span>
                        </button>
                      </div>
                    )}
                    </div> )}

                
              
              {/* {acceptedFileItems} */}

              {/* After File is Uploaded sue the code block below */}
              {this.state.documentFile && (
                <div className="dropzone-fileupload d-flex">
                  <button className="btn btn-success btn-circle btn-xs mr-15">
                    <IconCheck />
                  </button>
                 
                    <div className="document-preview document-preview--sm mr-3">
                      <div className="document-preview__image">
                        {this.state.imagePreviewUrl && (
                          <img
                            className="document-preview__image"
                            src={this.state.imagePreviewUrl}
                            alt={""}
                          />
                        )}
                        {this.state.pdfPreviewUrl && (
                          <canvas id="the-canvas"></canvas>
                        )}
                      </div>
                    </div>
                
                  <div className="w-100 d-flex">
                    <div>
                      <div className="font-medium ">
                        {this.state.documentFile.name}
                      </div>
                      <div className="text-muted">
                        {(this.state.documentFile.size * 0.0009765625).toFixed(
                          1
                        )}{" "}
                        kb
                      </div>
                    </div>
                    {!this.props.type &&
                      getStorage("currentUserRole") != "customer" && (
                        <div className="w-150 ml-auto">
                          <Select
                          options={this.state.documentTypes}
                            styles={smallSelectStyle}
                            value={this.state.fileType}
                            onChange={(e) => this.updateDocument(e)}
                          />
                        </div>
                      )}

                    {!this.props.type &&
                      getStorage("currentUserRole") != "customer" &&
                      this.state.activeTab === "subCustomer" && (
                        <div className="w-150 ml-auto">
                          <Select
                          options={this.state.documentTypes}
                            styles={smallSelectStyle}
                            value={this.state.fileType}
                            onChange={(e) => {
                              this.setState({ fileType: e.target.value });
                            }}
                          />
                        </div>
                      )}
                  </div>
                  <button className="btn btn-link btn-circle btn-xs ml-3">
                    <span onClick={() => this.removeDocument()}>
                      {" "}
                      <IconTrash />{" "}
                    </span>
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => this.setState({
                showFileUploadModal: false, uploadFileDetails: "", documentFile: null,
                progress: 0, imagePreviewUrl: null, pdfPreviewUrl: null, fileType: ""
              })}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (this.state.documentFile && this.state.fileType) {
                  this.insertdata();
                } else { toastr.show("Please select a type.", "error") }
              }}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        { this.state.showFullDropScreen && this.state.isLoadTabActive === 2 && !this.state.showFileUploadModal && 
          <FullScreenUploader 
            uploadResult={this.uploadResult} 
            multi={false} 
            type={"image"}
          />
        }
      </div>
    );
  }
}

Yup.addMethod(Yup.string, "sameAs", function (ref, message) {
  return this.test("sameAs", message, function (value) {
    let other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

const ResetForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;
  return (
    <div className="center-child">
      <Modal.Header>
        <h4 className="modal-title" id="myModalLabel">
          Reset Password
        </h4>
        <button
          type="button"
          className="close"
          onClick={() => props.setResetId()}
        >
          <i className="uil uil-times"></i>
        </button>
      </Modal.Header>
      <div className="sign-up-form">
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <Modal.Body>
              <div className={`form-group  ${errors.password && "has-error"}`}>
                <label htmlFor="password" className="text-uppercase">
                  New Password
                  <span
                    className="required-field"
                    title="This field is reqired."
                  >
                    *
                  </span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password && (
                  <span className="text-danger">{errors.password}</span>
                )}
              </div>

              <div
                className={`form-group  ${errors.confirmPassword && "has-error"
                  }`}
              >
                <label htmlFor="password" className="text-uppercase">
                  Confirm Password
                  <span
                    className="required-field"
                    title="This field is reqired."
                  >
                    *
                  </span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <span className="text-danger">{errors.confirmPassword}</span>
                )}
              </div>

              {isSubmitting && (
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <Spinner />
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-close"
                onClick={() => {
                  props.setResetId();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                name="resetDriverPassword"
                id="resetDriverPassword"
                value="Save"
                className="btn btn-danger"
              >
                Reset
              </button>
            </Modal.Footer>
          </div>
        </form>
      </div>
    </div>
  );
};

const ResetDriverPassword = withFormik({
  mapPropsToValues: (props) => ({
    password: "",
    confirmPassword: "",
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(5, "Enter atleast 5 characters.")
      .max(50, "Enter less than 50 characters.")
      .required("Password is required."),
    confirmPassword: Yup.string()
      .sameAs(Yup.ref("password"), "passwords doesn't match")
      .min(5, "Enter atleast 5 characters.")
      .max(20, "Enter less than 20 characters.")
      .required("Password confirm is required"),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const password = values.password;
    const driverId = props.driverId;
    props.actions
      .resetDriverPassword({
        driverId,
        password,
      })
      .then((result) => {
        props.setResetId();
        toastr.show("Your password has been successfully changed!", "success");
        setSubmitting(false);
      })
      .catch((Err) => { })
      .finally(() => {
        setSubmitting(false);
      });
  },
})(ResetForm);

function mapDispatchToProps(dispatch) {
  return { 
    actions: bindActionCreators(actionCreators, dispatch),
    dispatch
  };
}
export default connect(null, mapDispatchToProps)(Driver);

const LoadTypeOptions = [
  { value: "IMPORT", label: "IMPORT" },
  { value: "EXPORT", label: "EXPORT" },
  { value: "ROAD", label: "ROAD" },
];



const DistanceOptions = [];
let i = 0;
while (i < 3000) {
  DistanceOptions.push({
    value: { min: i === 0 ? 0 : i + 1, max: i + 50 },
    label: `${i === 0 ? 0 : i + 1}-${i + 50}`,
  });
  i += 50;
}
const documentOptions = [
  { value: "Driver Application", label: "Driver Application" },
  { value: "CDL", label: "CDL" },
  { value: "Social Security Card", label: "Social Security Card" },
  { value: "TWIC Card", label: "TWIC Card" },
  { value: "Pre Employment Drug Test", label: "Pre Employment Drug Test" },
  { value: "Medical Card", label: "Medical Card" },
  { value: "Sealink", label: "Sealink" },
  { value: "Detention Pictures", label: "Detention Pictures" },
  { value: "Other", label: "Other" },
];

const terminalTimzoneOptions = [
  { value: "America/New_York", label: "Eastern Time (US & Canada)" },
  { value: "America/Indiana/Indianapolis", label: "Indiana (East)" },
  { value: "America/Chicago", label: "Central Time (US & Canada)" },
  { value: "America/Regina", label: "Saskatchewan" },
  { value: "America/Denver", label: "Mountain Time (US & Canada)" },
  { value: "America/Phoenix", label: "Arizona" },
  { value: "America/Juneau", label: "Alaska" },
  { value: "America/Los_Angeles", label: "Pacific Time (US & Canada)" },
  { value: "America/Halifax", label: "Atlantic Time (Canada)" },
];

const YesOrNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


const schemaValidatorObject = Yup.object().shape({
  _id: Yup.string().nullable(),
  isTerminal: Yup.boolean(),


  newTerminal: Yup.array()
    .nullable()
    .when("isTerminal", {
      is: (val) => val === true,
      then: Yup.array()
        .min(1, "Terminal Is Required.")
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        )
        .typeError("Terminal is Required.")
        .required("Terminal is Required."),
    }),

  licenceNumber: Yup
    .string()
    .nullable()
    .when('licenceNumber', {
        is: (value) => value && value.length,
        then: (rule) => rule.min(7, "License Number is too short - should be 7 chars minimum."),
    }),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile is not valid")
    .required("Mobile is Required."),
  email: Yup.string().email("Invalid Email").nullable().required("Email is Required."),
  lastName: Yup.string()
    .min(2, "Enter atleast 2 characters.")
    .max(50, "Enter less than 50 characters.")
    .required("Last Name is Required."),
  name: Yup.string()
    .min(3, "Enter atleast 3 characters.")
    .max(50, "Enter less than 50 characters.")
    .required("Name is Required."),
  password: Yup.string().when("_id", {
    is: (val) => !val,
    then: Yup.string()
      .min(5, "Enter atleast 5 characters.")
      .max(50, "Enter less than 50 characters.")
      .required("Password is required."),
  }),
  username: Yup.string()
  // .when("isUserNameValid",{
  //   is: (val) => val === false,
  //     then: Yup.string()
  //       // .typeError("Username is Required.")
  //       .required("Username is Required."),
  // })
  .min(7, "Please enter a stronger username - should be 7 chars minimum.")
  .required("Username is required."),
},
[

  ['licenceNumber', 'licenceNumber'],
]);
