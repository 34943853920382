import React, { Fragment } from 'react';
import { Modal } from "react-bootstrap";
import { bindActionCreators } from 'redux';
import { debounce } from 'throttle-debounce';
import { connect } from 'react-redux';
import GroupChatCard from '../ChatCardComponent/GroupChatCard';
import * as actionCreators from "../../actionCreators";
import { displayName, getStorage } from '../../../../../services/Common.services';
import { IconPlus } from '../../../Icons';
import Avatar from '../../../Avatar';

let loggedInUser;
class GroupsMessageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editId: false,
            isCreateGroupModal:false,
            memberList: [],
            allSearchMember: [],
        }

        this.getMembersThrottle = debounce(500, this.findMembers);
        this.showEditGroupModal = this.showEditGroupModal.bind(this);
    }

    componentDidMount() {
        loggedInUser = JSON.parse(getStorage('loggedInUser'));
    }

    findMembers(search) {
        if (search && search === '') {
            return
        }

        actionCreators.loadGroupOptions(search).then((res) => {
            if (res && res.length) {
                this.setState({ allSearchMember: res })
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    removeMemersFromList(upMember) {
        const updatedList = this.state.memberList.filter((member) => member.value !== upMember.value);
        this.setState({ memberList: updatedList })
        this.setState({ allSearchMember: [...this.state.allSearchMember, upMember] })
    }

    addMembersToList(member) {
        const updatedList = [...this.state.memberList, member];
        const updatedAllSearchMember = this.state.allSearchMember.filter((searchMember) => searchMember.value !== member.value);
        this.setState({ memberList: updatedList, allSearchMember: updatedAllSearchMember })
    }

    handleCreateGroup() {
        const { createGroup } = this.props;
        const { memberList, groupName, editId } = this.state;
        const data = {
            groupName,
            members: [...memberList.map((d) => d.value), loggedInUser._id].unique(),
            type: 'groups'
        }

        if (editId) {
            data.edit_grp_id = editId;
        }

        createGroup(data);
    }

    showEditGroupModal(groupList) {
        let convertData = [];
        const groupName = groupList.groupName;
        if (groupList && groupList.members && groupList.members.length > 0) {
            convertData = groupList.members.map((d) => {
                const name = `${d.name ? d.name : ''} ${d.lastName ? d.lastName : ''}`
                return { value: d._id, label: name }
            })
        }

        this.setState({ groupName, memberList: convertData,  editId: groupList._id })
        this.setState({isCreateGroupModal:true})
    }

    render() {
        const { messageList, displayChatBox } = this.props;
        const {  allSearchMember, memberList, groupName, editId ,isCreateGroupModal} = this.state;

        return (
            <Fragment>
                <div className="d-flex align-items-center justify-content-between mb-10">
                    {/* No Groups yet UI */}
                    <span className="d-none text-muted font-14">
                        You don’t have any groups yet
                    </span>
                    <button className="btn btn-primary" onClick={() => {this.setState({ memberList:[], groupName:null, editId:false ,isCreateGroupModal:true})}}>
                        <IconPlus className="mr-3" />
                        Create Group
                    </button>
                </div>
                <div className="clist" style={{ height: "calc(100vh - 191px)" }}>
                    {messageList.map((message) => {

                        let lastMessage = '';
                        if (message && message.messages && message.messages.length > 0) {
                            const messageData = message.messages[message.messages.length - 1];

                            if (messageData.type === 'url') {
                                lastMessage = 'Image';
                            }

                            if (messageData.type === 'text') {
                                lastMessage = messageData.msg;
                            }
                        }

                        return <GroupChatCard details={message} key={message.groupID} displayChatBox={displayChatBox} showEditGroupModal={this.showEditGroupModal} lastMessage={lastMessage} />;
                    })}
                </div>
                    <Modal show={this.state.isCreateGroupModal} centered={true} animation={false} bsSize="sm">
                        <Modal.Header>
                            <Modal.Title>{editId ? 'Edit Group' : 'Create Group'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-0">
                            <div className="form-group px-30">
                                <label>Group name</label>
                                <input
                                    key='groupName'
                                    value={groupName}
                                    className="form-control"
                                    placeholder="Enter Group Name"
                                    onChange={(e) => {
                                        this.setState({ groupName: e.target.value })
                                    }}
                                />
                            </div>

                            <div className="form-group px-30">
                                <label>Members</label>
                                <div className="app-search bg-gray-50 rounded-lg mb-10">
                                    <input
                                        type="text"
                                        className="input-search-left form-control py-2"
                                        placeholder="Search..."
                                        onChange={(e) => this.getMembersThrottle(e.target.value)}
                                    />
                                    <span className="search-icon ml-1"></span>
                                </div>
                            </div>
                                <div className="members-list mt-20 px-30" style={{maxHeight: 'calc(100vh - 399px)', overflowY: 'auto'}}>

                                    {/* Remove */}
                                    {memberList && memberList.length > 0 ? memberList.map((member) => {
                                        return (
                                            member && (<div class="d-flex align-items-center mb-10">
                                                <div class="flex-shrink-0 mr-2 align-self-center">
                                                    {
                                                        member && member.allInfo && member.allInfo.profilePicture ?
                                                            <Avatar src={member.allInfo.profilePicture} />
                                                            :
                                                            <Avatar>{displayName(member.label && member.label)}</Avatar>
                                                    }
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="font-14">{member.label}</h5>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <span class="btn btn-sm btn-outline-light" onClick={() => this.removeMemersFromList(member)}>
                                                        Added
                                                    </span>
                                                </div>
                                            </div>)
                                        )
                                    }) : null}

                                    {memberList && memberList.length > 0 ? <hr /> : null}

                                    {/* Add */}
                                    {allSearchMember && allSearchMember.length > 0 ? allSearchMember.map((member) => {

                                        return (
                                            member && (<div class="d-flex align-items-center mb-10">
                                                <div class="flex-shrink-0 mr-2 align-self-center">
                                                    {
                                                        member.allInfo && member.allInfo.profilePicture ?
                                                            <Avatar src={member.allInfo.profilePicture} />
                                                            :
                                                            <Avatar>{displayName(member.label && member.label)}</Avatar>
                                                    }
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="font-14">{member.label}</h5>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <span class="btn btn-sm btn-primary" onClick={() => this.addMembersToList(member)}>
                                                        <IconPlus className="mr-10" />
                                                        Add
                                                    </span>
                                                </div>
                                            </div>)
                                        )
                                    }) : null}
                                </div>
                          
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-close" onClick={() => this.setState({isCreateGroupModal:false})}>
                                Cancel
                            </button>
                            <span className="btn btn-primary" onClick={() => this.handleCreateGroup()}>{editId ? 'Update Group' : 'Create Group'}</span>
                        </Modal.Footer>
                    </Modal>
            </Fragment>
        );
    }
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        allGroups: state.HomeReducer.groups
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsMessageList);