import React, { Component, Fragment } from "react";
import _ from "lodash";
import $ from "jquery";
import * as Yup from "yup";
import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import Select from "react-select";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import "../style.css";
import * as actionCreators from "./actionCreators";
import confirm from "../../../Components/Common/ConfirmAert";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import { CustomIconNotes } from "../../../Components/Common/CustomIcons/Index";
import { getDocumentTypesByType } from '../../../Components/Common/functions';
import {
  getStorage,
  toastr,
  showForTerminal,
  fileNameTypeFromUrl,
} from "../../../services/Common.services";
import {
  getTerminal,
  deleteEquipmentDoc,
  getEquipmentHistory,
} from "../services";
import Papa from "papaparse";
import { differnceFinder } from "../../../../src/services/index";
import {
  StateFormInput,
  StateFormSelect,
  StateFormDateTime
} from "../../../Components/FormComponent/StateFormComponent";
import {
  IconDownload,
  IconPlus,
  IconPen,
  IconCheckCircle,
  IconTrash,
  IconColumn,
  IconAngleArrowLeft,
  IconDocument,
  IconNotes,
  IconClock9,
  IconTruck,
  IconInfo,
  IconUpload,
  IconTimes,
  IconSearchData,
  IconCheck,
  IconSortDown,
  IconSortUp,
  IconSort,
  IconCalendar
} from "../../../Components/Common/Icons";
import { getYear } from "../../../services/Common.services";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { getCustomers } from "../Dispatcher/actionCreators";
import UploadedDocuments from "../Load/DocumentList";
import SubHeaderTab from "../../../Components/SubHeaderTab";
import FullScreenUploader from "../../../Components/FullScreenUploader";
import NumberFormat from "react-number-format";
import ReactTooltip from "react-tooltip";

let Data = {
  _id: undefined,
  equipmentID: "",
  year: "",
  make: "",
  model: "",
  AID: "",
  ITD: "",
  size: "",
  reg_expiration: "",
  licence_plate_state: "",
  licence_plate_number: "",
  inspection_exp: "",
  hut_exp: "",
  vin: "",
  terminal: "",
  document: "",
  equipmentHistory: [],
  annualInspection: "",
  bobtailInsurance: "",
  diselEmission: "",
  ifta: "",
  fleetTruckOwner: "",
  notes: "",
  collapseSubCards: true,
  gps: "",
  rfid: "",
  maintenanceReceived: "",
  maintenanceDue: "",
  odometer:"",
  enginefamily:"",
};
let timeZone = getStorage("timeZone");

const sortingOptions = {
  "Truck #": "equipmentID",
  "License Plate #": "licence_plate_number",
  "License Plate State": "licence_plate_state",
  VIN: "vin",
  Address: "lastCompletedStatus.address.address",
  AID: "AID",
  ITD: "ITD",
  Registration: "reg_expiration",
  Inspection: "inspection_exp",
  HUT: "hut_exp",
  "Annual Inspection": "annual_inspection",
  "Bobtail Insurance": "bobtail_insurance",
  "Diesel Emission": "disel_emission",
  IFTA: "ifta",
  Terminal: "terminal.name",
  "Fleet Owner": "fleetTruckOwner"
};

const yearOptions = getYear().map((year) => {
  return { value: year, label: year }
});

class Truck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAllImagingDocuments:
        props.selectedLoads && props.selectedLoads.documents
          ? props.selectedLoads.documents
          : [],

      ...Data,
      terminals: [],
      AddTruckModal: false,
      fileType: null,
      tab: 1,
      documents: [],
      year: null,
      documentFile: null,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      type: "",
      selectedStatuses: [],
      sort: undefined,
      asce: true,
      fitlerDeletedEnabled: "All",
      isEnableTruck: true,
      isDisableTruck: false,
      enableTruckCount: 0,
      disableTruckCount: 0,
      isLoadingBarShow: false,
      subCardFilter: null,
      showModal: false,
      choosenTerminals: [],
      customerId: null,
      formErrors: {},
      formsTouched: {},
      isTerminal: false,
      isLoadTabActive: 1,
      showFileUploadModal: false,
      uploadFileDetails: "",
      progress: 0,
      documentObj: {},
      transmission: "",
      imageType: null,
      documentImage: true,
      allFleetTruckOwners: [],
      showFullDropScreen: false,
      documentTypes: [],
    };

    this.allStatuses = ["Available", "Dispatched", "Loaded", "Empty"];
    this.listDocuments = this.state.listAllImagingDocuments.map((info, key) =>
      this.state.activeTab !== "subCustomer"
        ? !info.customerId
          ? info
          : null
        : info.customerId
          ? info
          : null
    );
    this.previewImage = this.previewImage.bind(this);
    this.downloadDoc = this.downloadDoc.bind(this);
    this.uploadResult = this.uploadResult.bind(this);
    this.selectedDoc = null;
  }

  componentDidMount() {
    if (!this.props.isOnlyPopup) {
      this.getlist();
    }
    let isTerminal = showForTerminal();
    this.setState({ isTerminal: isTerminal });
    if (isTerminal) {
      getTerminal().then((response) => {
        const terminals = response.data.data ? response.data.data : [];
        this.setState({ terminals });
      });
    }

    if (this.props.isOnlyPopup) {
      this.setState({ AddTruckModal: true });
    }
    this.setState({showFullDropScreen:true})
  }

  UNSAFE_componentWillMount() {
    let defaultState = {};

    this.setState({ isLoadingBarShow: true });
    this.props.actions.getFleetTruckOwner().then((res) => {
      this.setState({
        allFleetTruckOwners: res.map((d) => {
          return { label: d.company_name, value: d._id };
        }),
      });
    });

    this.allStatuses.forEach((key) => {
      defaultState[`${key}`] = [];
    });
    
    // fetch and fill document types option from api
    (async () => {
      let types = await getDocumentTypesByType('truckTypes');
      this.setState({ documentTypes: types })
    })();
    
    this.setState(defaultState);
  }

  deleteEquipmentDoc = (params) => {
    deleteEquipmentDoc(params).then((response) => {
      const documents = response.data.data.documents;
      this.setState({ documents });
      toastr.show("Deleted!", "success");
      this.getlist();
    });
  };
  componentWillUnmount() {
    this.setState({showFullDropScreen:false})
  }
  // uploadDocument = () => {
  //   if (this.state.imageType) {
  //     if (!this.state.customerId && this.state.activeTab === "subCustomer") {
  //       toastr.show("Please select a document type first.", "info");
  //       return;
  //     }
  //     this.setState({ selectedDoc: null, processing: false });

  //     this.setState({ docUploading: true, selectedDoc: null });
  //     const documentObjData = {
  //       ...this.state.documentObj,
  //       fileType: this.state.imageType.value,
  //     };
  //     this.setState({ documentObj: documentObjData })


  //     HTTP('post', 'tms/addTMSEquipment', documentObjData, { authorization: getStorage('token') })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           toastr.show('Your document has been uploaded!', 'success')
  //           if (this.props.updateList) {
  //             this.props.updateList(response.data.data);
  //           }
  //           this.setState({ docUploading: false, listAllImagingDocuments: response.data.data.documents, documentFile: undefined, customers: null, imagePreviewUrl: null, imageType: "", pdfPreviewUrl: null, customerId: null, documentImage: false }, () => {
  //             this.props.actions.updateDocumentRotate(response.data.data.documents)
  //           });
  //           this.setState({ showUploadModal: false })
  //         }
  //         this.setState(
  //           {
  //             docUploading: false,
  //             listAllImagingDocuments: response.data.data.documents,
  //             documentFile: undefined,
  //             customers: null,
  //             imagePreviewUrl: null,
  //             imageType: "",
  //             pdfPreviewUrl: null,
  //             customerId: null,
  //           },
  //           () => {
  //             this.props.actions.updateDocumentRotate(
  //               response.data.data.documents
  //             );
  //           }
  //         );
  //         this.setState({ showUploadModal: false });
  //       }
  //       );
  //   } else {
  //     toastr.show("Please select a document type first.", "info");
  //   }
  // };

  emptyOrLoaded = (info) => {
    let status;
    let customer;
    if (info.driverOrderId) {
      const activeStatusIndex = info.activeIndex || -1;
      const activeDriverOrderId = info.driverOrder[activeStatusIndex];
      const prevDriverOrderId = info.driverOrder[activeStatusIndex - 1];
      if (
        activeDriverOrderId &&
        prevDriverOrderId &&
        [
          "DROPCONTAINER_DEPARTED",
          "HOOKCONTAINER_ARRIVED",
          "HOOKCONTAINER_DEPARTED",
        ].indexOf(info.status) > -1
      ) {
        if (["EXPORT", "IMPORT"].indexOf(info.type_of_load) > -1) {
          if (activeDriverOrderId.prevType === "PULLCONTAINER") {
            status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
          } else if (
            activeDriverOrderId.prevType === "DELIVERLOAD" &&
            activeDriverOrderId.customerId &&
            prevDriverOrderId.customerId &&
            prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
          ) {
            status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
          } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
            if (info.isReadyForPickup) {
              status = info.type_of_load === "IMPORT" ? "empty" : "loaded";
            } else {
              status = info.type_of_load === "IMPORT" ? "loaded" : "empty";
            }
          }
        } else if (
          [
            "DROPCONTAINER_DEPARTED",
            "HOOKCONTAINER_ARRIVED",
            "HOOKCONTAINER_DEPARTED",
          ].indexOf(info.status) > -1 &&
          ["ROAD"].indexOf(info.type_of_load) > -1
        ) {
          if (activeDriverOrderId.prevType === "PULLCONTAINER") {
            status = "loaded";
          } else if (
            activeDriverOrderId.prevType === "DELIVERLOAD" &&
            activeDriverOrderId.customerId &&
            prevDriverOrderId.customerId &&
            prevDriverOrderId.customerId._id !==
            activeDriverOrderId.customerId._id
          ) {
            status = "empty";
          } else if (activeDriverOrderId.prevType === "DELIVERLOAD") {
            status = "loaded";
          }
        }
      }
      if (
        activeDriverOrderId &&
        activeDriverOrderId.customerId &&
        activeDriverOrderId.customerId.company_name
      ) {
        customer = activeDriverOrderId.customerId.company_name;
      }
    }
    return status;
  };

  getlist() {
    const prm = { equipment_type: "TRUCK", isIncludeDeleted: true };

    this.setState({ isLoadingBarShow: false });
    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      prm.terminals = JSON.stringify(
        this.state.choosenTerminals.map((J) => {
          return J.value;
        })
      );
    }
    this.props.actions.getTMSEquipments(prm).then((result) => {
      let data = result;
      let { Available, Dispatched, Loaded, Empty } = this.state;

      Available = data.filter(
        (obj) =>
          !obj.load ||
          (obj.load &&
            [
              "PENDING",
              "AVAILABLE",
              "DEPARTED",
              "COMPLETED",
              "APPROVED",
              "BILLING",
            ].indexOf(obj.load.status) > -1)
      );
      Dispatched = data.filter(
        (obj) =>
          obj.load &&
          [
            "DISPATCHED",
            "CHASSISPICK_ARRIVED",
            "CHASSISPICK_DEPARTED",
            "PULLCONTAINER_ARRIVED",
            "PULLCONTAINER_DEPARTED",
            "DROPCONTAINER_ARRIVED",
            "DELIVERLOAD_ARRIVED",
            "DELIVERLOAD_DEPARTED",
            "RETURNCONTAINER_ARRIVED",
            "RETURNCONTAINER_DEPARTED",
            "CHASSISTERMINATION_ARRIVED",
            "CHASSISTERMINATION_DEPARTED",
          ].indexOf(obj.load.status) > -1
      );
      Loaded = data.filter(
        (obj) =>
          obj.load &&
          this.emptyOrLoaded(obj.load) === "Loaded" &&
          ["DROPPED", "NEED_TO_GET_DROP"].indexOf(obj.load.status) > -1
      );
      Empty = data.filter(
        (obj) =>
          obj.load &&
          this.emptyOrLoaded(obj.load) === "Empty" &&
          ["DROPPED", "NEED_TO_GET_DROP"].indexOf(obj.load.status) > -1
      );
      const enableTrucks = data.filter((d) => !d.isDeleted);
      const disableTrucks = data.filter((d) => d.isDeleted);
      this.setState({
        AllTMSEquipments: result,
        Available,
        Dispatched,
        Loaded,
        Empty,
        isLoadingBarShow: false,
        enableTruckCount: enableTrucks.length,
        disableTruckCount: disableTrucks.length,
        AddTruckModal: false

      }, () => {
        if (this.state._id) {
          this.changeColor(this.state._id);
        }
      });
    });
  }

  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: i,
    });
  };

  changeColor(id) {
    getEquipmentHistory({ equipmentId: id }).then((response) => {
      this.setState({ equipmentHistory: response.data.data });
    });

    this.props.actions
      .getEquipmentAudits({ equipmentId: id })
      .then((response) => this.setState({ audits: response }))
      .catch((error) => console.log(error));
    this.state.AllTMSEquipments.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ [id]: true });

    let selectData = _.find(this.state.AllTMSEquipments, function (s) {
      return s._id == id;
    });
    let setdata = {
      _id: selectData._id,
      equipmentID: selectData.equipmentID,
      odometer : selectData.odometer?selectData.odometer:"",
      enginefamily:selectData.enginefamily?selectData.enginefamily:"",
      year: selectData.year,
      make: selectData.make,
      model: selectData.model,
      AID: selectData.AID ? new Date(selectData.AID) : "",
      ITD: selectData.ITD ? new Date(selectData.ITD) : "",
      size: selectData.size,
      reg_expiration: selectData.reg_expiration
        ? new Date(selectData.reg_expiration)
        : "",
      licence_plate_state: selectData.licence_plate_state,
      licence_plate_number: selectData.licence_plate_number,
      inspection_exp: selectData.inspection_exp
        ? new Date(selectData.inspection_exp)
        : "",
      hut_exp: selectData.hut_exp ? new Date(selectData.hut_exp) : "",
      annualInspection: selectData.annual_inspection
        ? new Date(selectData.annual_inspection)
        : "",
      bobtailInsurance: selectData.bobtail_insurance
        ? new Date(selectData.bobtail_insurance)
        : "",
      diselEmission: selectData.diesel_emission
        ? new Date(selectData.diesel_emission)
        : "",
      ifta: selectData.ifta ? new Date(selectData.ifta) : "",
      vin: selectData.vin,
      terminal: selectData.terminal ? selectData.terminal._id : "",
      fleetTruckOwner: selectData.fleetTruckOwner ? selectData.fleetTruckOwner : null,
      newTerminal:
        selectData.newTerminal && selectData.newTerminal.length > 0
          ? selectData.newTerminal
          : [],
      fileType: null,
      notes: selectData.notes ? selectData.notes : "",
      gps: selectData.gps,
      rfid: selectData.rfid,
      maintenanceReceived: selectData.maintenanceReceived,
      maintenanceDue: selectData.maintenanceDue,
      transmission: selectData.transmission
    };

    if (setdata.newTerminal && setdata.newTerminal.length > 0) {
      let newTerminal = [];
      this.state.terminals.forEach((item, i) => {
        if (setdata.newTerminal.indexOf(item._id) > -1) {
          newTerminal.push({
            value: item._id,
            label: item.name,
          });
        }
      });
      setdata.newTerminal = newTerminal;
    }

    this.setState({
      ...setdata,
      AddTruckModal: true,
      documents: selectData.documents ? selectData.documents : [],
      imagePreviewUrl: null,
      documentFile: null,
      pdfPreviewUrl: null,
      formErrors: {},
      formsTouched: {},
    });
  }

  previewImage = (data) => {
    if (!data.url) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.url;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.url },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.url, pdfPreviewUrl: null });
      }
    }
  };

  removeDocument = () => {
    this.setState({
      uploadFileDetails: "",
      progress: 0,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      documentImage: true,
    });
    this.setState({ documentFile: null });
  };

  viewDocument = (data) => {
    if (!data.document) {
      toastr.show("There is no document.", "success");
      return;
    }
    let extension;
    let fileName = data.document;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.document },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.document, pdfPreviewUrl: null });
      }
    }
  };

  uploadResult = (file, preview, type) => {

    if (
      (file.type.match("image/png") || file.type.match("image/jpg") || file.type.match("image/jpeg") || file.type.match("application.pdf"))) {
        this.setState({showFileUploadModal:true})
      if (file.type.indexOf('image') == -1) {
        type = file.type.split('/')[1];
      }
      setTimeout(() => {
        this.setState({ progress: 100 });
      }, 1000);
      this.setState({
        documentFile: null,
        [`${type}PreviewUrl`]: null,
        pdfPreviewUrl: null,
        [type]: null
      }, () => {
        this.setState({
          documentFile: file,
          [`${type}PreviewUrl`]: preview,
          [type]: preview
        });
      });

      if (type == 'pdf') {
        this.setState({ imagePreviewUrl: null })
        let base64result = preview.split(',')[1];
        var pdfData = atob(base64result);
        var loadingTask = pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then(function (pdf) {
          // Fetch the first page.
          pdf.getPage(1).then(function (page) {
            var scale = 1.5;
            var viewport = page.getViewport(scale);
            // Prepare canvas using PDF page dimensions.
            var canvas = document.getElementById('the-canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            // Render PDF page into canvas context.
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
          });
        });
      }
    } else {
      toastr.show("Please enter only PDF and/or Image (JPG, PNG) file formats.", "error");
      return;
    }

  }


  deleteTMSEquipement(TMSEquipments) {
    let formData = new FormData();
    formData.append("_id", TMSEquipments._id);
    formData.append("isDeleted", !TMSEquipments.isDeleted);
    confirm(
      `${TMSEquipments.isDeleted ? "Enable" : "Delete"} Truck`,
      `Are you sure you want to ${TMSEquipments.isDeleted ? "enable this?" : "delete this?"
      }`,
      (confirm) => {
        if (confirm) {
          this.props.actions.deleteTMSEquipement(formData).then((res) => {
            toastr.show(
              `Successfully ${TMSEquipments.isDeleted ? "Enabled" : "Deleted"}`,
              "success"
            );
            this.setState({ _id: null })
            this.getlist();
          });
        }
      }
    );
  }

  Newform() {
    this.state.AllTMSEquipments.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ ...Data });
  }

  setval(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleShowFileUploadModal = (event) => {
    this.setState({ showFileUploadModal: true, uploadFileDetails: null, progress: 0, documentImage: true });
  };

  downloadDoc(doc) {
    $.ajax({
      url: doc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: (blob) => {
        const extension = fileNameTypeFromUrl(doc.url)[1];
        if (extension != "pdf") {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          link.download = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.click();
        } else {
          var blob = new Blob([blob], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.download = fileName;
          link.click();
        }
      },
    });
  }
  // Validate form
  insertdata = (isSubmit = true) => {
    var errors = {};
    var touched = { ...this.state.formsTouched };
    schemaValidatorObject
      .validate(this.state, { abortEarly: false })
      .then((value) => {
        this.setState({ formErrors: {}, formsTouched: {} });
        isSubmit && this.insertdataa();
      })
      .catch((err) => {
        err &&
          err.inner &&
          err.inner.forEach((e, i) => {
            if (isSubmit && i + 1 === err.inner.length) {
              toastr.show(e.message, "error");
            }
            errors[e.path] = e.message;
            if (isSubmit) touched[e.path] = true;
          });
        this.setState({ formErrors: errors, formsTouched: touched }, () => {
          isSubmit && toastr.show("Please enter all required fields.", "warning");
        });
      });
  };
  // Set State For Val
  setValChange = (key, val) => {
    var formsTouched = { ...this.state.formsTouched };
    formsTouched[key] = true;
    this.setState({ [key]: val, formsTouched }, () => {
      this.insertdata(false);
    });
  };
  _findValue = (key, value, label) => {
    let data = this.state[key].find((d) => d._id === value);
    if (data) return { label: data[label] || data.name, value: data['_id'] };
    return;
  }
  insertdataa() {
    if (
      this.state.isTerminal &&
      (!this.state.newTerminal || this.state.newTerminal.length == 0)
    ) {
      toastr.show("Please choose a terminal.", "error");
      return;
    }
    let formData = new FormData();
    if (this.state.documentFile && !this.state.fileType) {
      toastr.show("You must choose a type.", "error");
      return;
    }
    if (this.state._id) formData.append("_id", this.state._id);
    formData.append("equipment_type", "TRUCK");
    formData.append("equipmentID", this.state.equipmentID);
    if (this.state.year) formData.append("year", this.state.year);
    if (this.state.make) formData.append("make", this.state.make);
    formData.append("model", this.state.model);
    formData.append("vin", this.state.vin);
    if (this.state.reg_expiration)
      formData.append(
        "reg_expiration",
        moment(this.state.reg_expiration).toISOString()
      );
    formData.append("licence_plate_state", this.state.licence_plate_state);
    if (this.state.licence_plate_number){
      formData.append("licence_plate_number", this.state.licence_plate_number);
    }else{
      formData.append("licence_plate_number", "");
    }
    if (this.state.documentFile && this.state.fileType) {
      formData.append("document", this.state.documentFile);
      formData.append("fileType", this.state.fileType.value);
      formData.append("filename", `${new Date().getTime().toString()}`);
    }
    if (this.state.AID)
      formData.append("AID", moment(this.state.AID).toISOString());
    if (this.state.ITD)
      formData.append("ITD", moment(this.state.ITD).toISOString());
    if (this.state.inspection_exp)
      formData.append(
        "inspection_exp",
        moment(this.state.inspection_exp).toISOString()
      );
    if (this.state.hut_exp)
      formData.append("hut_exp", moment(this.state.hut_exp).toISOString());
    if (this.state.annualInspection)
      formData.append(
        "annual_inspection",
        moment(this.state.annualInspection).toISOString()
      );
    if (this.state.bobtailInsurance)
      formData.append(
        "bobtail_insurance",
        moment(this.state.bobtailInsurance).toISOString()
      );
    if (this.state.diselEmission)
      formData.append(
        "diesel_emission",
        moment(this.state.diselEmission).toISOString()
      );
    if (this.state.ifta)
      formData.append("ifta", moment(this.state.ifta).toISOString());
    if (this.state.terminal) {
      formData.append("terminal", this.state.terminal);
    }
    formData.append("notes", this.state.notes);

    // if (this.state.notes) {
    //   formData.append("notes", this.state.notes);
    // } else {
    //   formData.append("notes", "");
    // }
    if (this.state.gps) {
      formData.append("gps", this.state.gps);
    }
    if (this.state.rfid) {
      formData.append("rfid", this.state.rfid);
    }
    if (this.state.maintenanceReceived) {
      formData.append(
        "maintenanceReceived",
        moment(this.state.maintenanceReceived).toISOString()
      );
    }
    if (this.state.maintenanceDue) {
      formData.append(
        "maintenanceDue",
        moment(this.state.maintenanceDue).toISOString()
      );
    }
    if (this.state.newTerminal && this.state.newTerminal.length > 0) {
      let newTerminal = this.state.newTerminal.map((Data) => {
        return Data.value;
      });
      formData.append("newTerminal", JSON.stringify(newTerminal));
    }
    if(this.state.odometer){
      formData.append("odometer" , this.state.odometer)
    }else{
      formData.append("odometer", "")
    }
    if(this.state.enginefamily){
      formData.append("enginefamily" , this.state.enginefamily)
    }else{
      formData.append("enginefamily" , "")
    }

    if (this.state.fleetTruckOwner) {
      formData.append("fleetTruckOwner", this.state.fleetTruckOwner);
    }

    if (this.state.transmission) {
      formData.append("transmission", this.state.transmission.value);
    } else {
      formData.append("transmission", "");
    }

    this.props.actions.addTMSEquipment(formData).then((result) => {
      if (!this.state._id) {  
        this.setState({ AddTruckModal: false });
        toastr.show("Your truck has been added!", "success");
      } else if (this.state._id && this.state.isLoadTabActive === 2) {
        this.setState({ fileType: null, documentFile: null, progress: 0 });
        toastr.show("Uploaded successfully!", "success");
      } else {
        // this.setState({ AddTruckModal: false });
        toastr.show("Your truck has been updated!", "success");
      }
      if (!this.props.isOnlyPopup) {
        this.getlist();
      }
      if (this.props.isOnlyPopup) {
        this.props.falsyData();
      }
      this.setState({ showFileUploadModal: false, showModal: false });
    });
  }

  removeFleetTruckOwner(_id) {
    this.props.actions.removeFleetTruckOwner({ _id }).then((res) => {
      if (res.statusCode === 200) {
        toastr.show("This has been removed.", "success");
      }
    });
  }

  updateDocument = (e) => {
    this.setState({ fileType: e });
  };

  filterStatus(status) {
    let newStatus = this.state.selectedStatuses;
    if (
      this.state.selectedStatuses.find((obj) => {
        return obj === status;
      })
    ) {
      newStatus = newStatus.subtract(status);
    } else {
      newStatus.insert(status);
    }
    this.setState({ selectedStatuses: newStatus, subCardFilter: null });
  }

  sortTable(sort) {
    this.setState({
      sort,
      asce: this.state.sort !== sort ? true : !this.state.asce,
    });
  }

  _subFilter(key) {
    this.setState({
      subCardFilter: key === this.state.subCardFilter ? null : key,
    });
  }

  renderFleetOwner = (id) => {
    let company_name = "";
    if (
      id &&
      this.state.allFleetTruckOwners &&
      this.state.allFleetTruckOwners.length > 0
    ) {
      let thisFleetOwner = this.state.allFleetTruckOwners.find(
        (data) => data.value == id
      );
      if (thisFleetOwner) {
        company_name = thisFleetOwner.label;
      }
    }
    return company_name;
  };

  exportToCsv = () => {
    const loadData = [];
    let allTrucks = this.state.AllTMSEquipments
      ? this.state.AllTMSEquipments
      : [];
    if (this.state.searchTerm && allTrucks) {
      const regex = RegExp(this.state.searchTerm, "i");
      allTrucks = allTrucks.filter((obj) => {
        return (
          (obj.equipmentID && obj.equipmentID.match(regex)) ||
          (obj.licence_plate_number && obj.licence_plate_number.match(regex)) ||
          (obj.licence_plate_state && obj.licence_plate_state.match(regex)) ||
          (obj.vin && obj.vin.match(regex))
        );
      });
    }
    if (!this.state.isEnableTruck && this.state.isDisableTruck) {
      allTrucks = allTrucks.filter((d) => d.isDeleted);
    } else if (this.state.isEnableTruck && !this.state.isDisableTruck) {
      allTrucks = allTrucks.filter((d) => !d.isDeleted);
    }
    allTrucks =
      this.state.fitlerDeletedEnabled === "Enabled"
        ? allTrucks.filter((d) => !d.isDeleted)
        : allTrucks;
    allTrucks =
      this.state.fitlerDeletedEnabled === "Disabled"
        ? allTrucks.filter((d) => d.isDeleted)
        : allTrucks;

    if (this.state.sort) {
      allTrucks = _.orderBy(
        allTrucks,
        this.state.sort,
        !this.state.asce && "desc"
      );
    }
    allTrucks.map((d) => {
      let data = {
        "TRUCK #": d.equipmentID,
        "FLEET OWNER": this.renderFleetOwner(d.fleetTruckOwner),
        "LICENSE PLATE #": d.licence_plate_number,
        "LICENSE PLATE STATE": d.licence_plate_state,
        VIN: d.vin,
        ADDRESS:
          d.lastCompletedStatus && d.lastCompletedStatus.address
            ? d.lastCompletedStatus.address.address
            : "",
        AID: d.AID ? moment(d.AID).format("MM/DD/YYYY") : "",
        ITD: d.ITD ? moment(d.ITD).format("MM/DD/YYYY") : "",
        REGISTRATION: d.reg_expiration
          ? moment(d.reg_expiration).format("MM/DD/YYYY")
          : "",
        INSPECTION: d.inspection_exp
          ? moment(d.inspection_exp).format("MM/DD/YYYY")
          : "",
        HUT: d.hut_exp ? moment(d.hut_exp).format("MM/DD/YYYY") : "",
        "ANNUAL INSPECTION": d.annual_inspection
          ? moment(d.annual_inspection).format("MM/DD/YYYY")
          : "",
        "BOBTAIL INSURANCE": d.bobtail_insurance
          ? moment(d.bobtail_insurance).format("MM/DD/YYYY")
          : "",
        "DIESEL EMISSION": d.diesel_emission
          ? moment(d.diesel_emission).format("MM/DD/YYYY")
          : "",
        IFTA: d.ifta ? moment(d.ifta).format("MM/DD/YYYY") : "",
        "ENGINE FAMILY" : d.enginefamily ? d.enginefamily : "",
        ODOMETER : d.odometer ? d.odometer : ""

      };
      loadData.push(data);
    });
    var csv = Papa.unparse(loadData);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `${new Date().getTime()}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${new Date().getTime()}.csv`);
    tempLink.click();
  };

  handleClose = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  render() {
    const {
      fitlerDeletedEnabled,
      subCardFilter,
      formsTouched,
      formErrors,
      isEnableTruck,
      isDisableTruck,
    } = this.state;

    let documents = _.filter(this.state.documents, (i) => i.checked);

    const tableGetHeight = this.state.getOffset;
    let { selectedStatuses } = this.state;
    let AllTMSEquipments =
      selectedStatuses.length === 0 ? this.state.AllTMSEquipments : [];
    if (!AllTMSEquipments) {
      AllTMSEquipments = [];
    }

    selectedStatuses.length > 0 &&
      selectedStatuses.map(
        (key) =>
          (AllTMSEquipments = AllTMSEquipments.concat(this.state[`${key}`]))
      );

    if (this.state.searchTerm && AllTMSEquipments) {
      const regex = RegExp(this.state.searchTerm, "i");
      AllTMSEquipments = AllTMSEquipments.filter((obj) => {
        return (
          (obj.equipmentID && obj.equipmentID.match(regex)) ||
          (obj.licence_plate_number && obj.licence_plate_number.match(regex)) ||
          (obj.licence_plate_state && obj.licence_plate_state.match(regex)) ||
          (obj.vin && obj.vin.match(regex))
        );
      });
    }
    let enableTruckCount = this.state.enableTruckCount
      ? this.state.enableTruckCount
      : 0;
    let disableTruckCount = this.state.disableTruckCount
      ? this.state.disableTruckCount
      : 0;

    if (!isEnableTruck && isDisableTruck) {
      AllTMSEquipments = AllTMSEquipments.filter((d) => d.isDeleted);
    } else if (isEnableTruck && !isDisableTruck) {
      AllTMSEquipments = AllTMSEquipments.filter((d) => !d.isDeleted);
    }
    if (["Enabled", "Disabled"].includes(fitlerDeletedEnabled))
      AllTMSEquipments = AllTMSEquipments.filter((d) =>
        fitlerDeletedEnabled === "Enabled" ? !d.isDeleted : d.isDeleted
      );

    if (AllTMSEquipments && AllTMSEquipments.length > 0) {
      AllTMSEquipments.forEach((e) => {
        if (e.load)
          e.lastCompletedStatus =
            e.load &&
            e.load.driverOrder &&
            e.load.driverOrder.find(
              (element) =>
                element.isActive &&
                element.isCompleted &&
                element.status !== "COMPLETED"
            );
      });
    }

    if (this.state.sort) {
      AllTMSEquipments = _.orderBy(
        AllTMSEquipments,
        this.state.sort,
        !this.state.asce && "desc"
      );
    }

    const groupedTrucks = _.groupBy(
      AllTMSEquipments,
      "lastCompletedStatus.address.address"
    );

    if (subCardFilter) AllTMSEquipments = groupedTrucks[subCardFilter];

    let Terminal = [];
    if (this.state.terminals && this.state.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.state.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }


    return (
      <div className="position-relative subheader-wrapper">
        {/* Sub Header */}
        {!this.props.isQuickAdd && (
          <div>
            <SubHeaderTab
              val="equipmentItems"
              buttons={
                <Fragment>
                  {this.state.AllTMSEquipments &&
                    this.state.AllTMSEquipments.length > 0 && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-outline-light mr-2"
                          onClick={() => this.exportToCsv()}
                        >
                          <IconDownload className="mr-2" />
                          Download CSV
                        </button>
                      </span>
                    )}
                  <button
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#addnewprofile"
                    onClick={() => {
                      this.Newform();
                      this.setState({
                        documents: [],
                        newTerminal: null,
                        formErrors: {},
                        formsTouched: {},
                        showModal: true,
                        transmission:""
                      });
                    }}
                  >
                    <IconPlus className="mr-2" />
                    Add New Truck
                  </button>
                </Fragment>
              }
            />
            <div
              className={
                "position-relative " +
                (this.state.collapseSubCards ? "" : "afilter-open")
              }
            >

              {!this.props.isOnlyPopup && (
                <div
                  class={`subheader ${this.state.AddTruckModal ? "d-block" : "d-none"
                    }`}
                >
                  <div class="subheader__inner d-flex">
                    <div class="d-flex flex-row align-items-center">
                      <button
                        class="btn btn-outline-light"
                        onClick={() => {
                          this.setState(
                            {
                              AddTruckModal: false,
                              tab: this.state.tab === 4 ? 1 : this.state.tab,
                              isLoadTabActive: 1,
                            },
                            () => {
                              if (this.props.isOnlyPopup) {
                                this.props.falsyData("truck");
                              }
                            }
                          );
                        }}
                      >
                        <IconAngleArrowLeft />
                      </button>
                      <div class="d-flex ml-3 align-items-center">
                        <h5 class="mb-0">{this.state.equipmentID}</h5>
                      </div>
                    </div>
                    <div class="ml-auto position-relative">
                      <button
                        class="btn btn-outline-light mr-1"
                        onClick={() => {
                          this.setState(
                            {
                              AddTruckModal: false,
                              tab: this.state.tab === 4 ? 1 : this.state.tab,
                              isLoadTabActive: 1,
                            },
                            () => {
                              if (this.props.isOnlyPopup) {
                                this.props.falsyData("truck");
                              }
                            }
                          );
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        class="btn btn-success"
                        onClick={() => {
                          this.insertdata();
                          this.setState({ isLoadTabActive: 1 });
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  "position-relative " +
                  (this.state.collapseSubCards ? "" : "afilter-open")
                }
              >

                {!this.props.isOnlyPopup && (
                  <div
                    className={`page-fluid ${this.state.AddTruckModal ? "d-none" : "d-block"
                      }
                `}
                  >
                    <div className="top-page">
                      <div className="pb-15 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <h4 className="font-20 mb-0">Trucks</h4>
                        </div>
                      </div>
                      {/* Truck Cards */}
                      <div className={`form-row mb-15 ${this.state.selectedStatuses.length > 0 && "hasactive"}`}>
                        {this.allStatuses.map((status) => {
                          let statusName = status;
                          let iconName = "";
                          if (statusName === "Available") {
                            iconName = "uil uil-thumbs-up";
                          } else if (statusName === "Dispatched") {
                            iconName = "uil uil-truck";
                          } else if (statusName === "Loaded") {
                            iconName = "uil uil-box";
                          } else if (statusName === "Empty") {
                            iconName = "uil uil-ban";
                          }
                          return (
                            <div className="col-xl col-md-3 col-sm-6 col-6">
                              <div
                                className={`ncard d-flex no-block align-items-center justify-content-between flex-row p-10 ${this.state.selectedStatuses.indexOf(
                                  statusName
                                ) > -1
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() => this.filterStatus(status)}
                              >
                                <div className="d-flex align-items-center">
                                  <div className={`mr-10 sbox sbox--default px-2 h-100 ${this.state.selectedStatuses.indexOf(
                                    statusName
                                  ) > -1
                                    && "sbox--active"
                                    }`}>
                                    {["Enabled", "Disabled"].includes(
                                      fitlerDeletedEnabled
                                    )
                                      ? this.state[`${status}`].filter((d) =>
                                        fitlerDeletedEnabled === "Enabled"
                                          ? !d.isDeleted
                                          : d.isDeleted
                                      ).length
                                      : this.state[`${status}`].length || 0}
                                  </div>
                                  <div>
                                    {statusName}
                                  </div>
                                </div>
                                <div className="ml-auto">
                                  <div className="form-check">
                                    {/* <i className={iconName} /> */}
                                    <input
                                      checked={
                                        selectedStatuses.indexOf(
                                          statusName
                                        ) > -1
                                      }
                                      type="checkbox"
                                      className="form-check-input"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* ---------- sub card section --------- */}
                    <div
                      className="afilter-list mt-50"
                      isOpen={!this.state.collapseSubCards}
                    >
                      <div className="afilter-masonary">
                        {Object.keys(groupedTrucks).map((key) => (
                          <div
                            className={`afilter-list__col ${key === "undefined" ? "disabled" : ""
                              }`}
                          >
                            <div
                              className="mb-1 filter-aval-item"
                              onClick={() =>
                                key !== "undefined" && this._subFilter(key)
                              }
                            >
                              {key}:
                              <b className="ml-2">
                                [{groupedTrucks[key].length}]
                              </b>
                              {this.state.subCardFilter === key && (
                                <i className="uil uil-times mx-2"></i>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* Table Filter */}
                    <div className="filter-row d-flex align-items-center">
                      {/* Search Bar */}
                      <div className="app-search header-search">
                        <span className="search-icon"> </span>
                        <div className="position-relative">
                          <input
                            type="search"
                            className="input-search-left form-control"
                            name=""
                            id="customerSearchInput"
                            placeholder="Search..."
                            value={this.state.searchTerm}
                            onChange={(e) => {
                              this.setState({
                                searchTerm: e.target.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Active or Disabled */}
                      <div className="d-flex">
                        <div className="form-check form-check--gray pr-30">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Available"
                            checked={isEnableTruck}
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.setState({ isEnableTruck: true });
                              } else {
                                this.setState({ isEnableTruck: false });
                              }
                            }}
                          />
                          <label className="form-check-label" for="Available">
                            Enabled
                            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                              {enableTruckCount}
                            </span>
                          </label>
                        </div>
                        <div className="form-check form-check--gray pr-30">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Pending"
                            checked={isDisableTruck}
                            onChange={(e) => {
                              if (e.target.checked) {
                                this.setState({ isDisableTruck: true });
                              } else {
                                this.setState({ isDisableTruck: false });
                              }
                            }}
                          />
                          <label className="form-check-label" for="Pending">
                            Disabled
                            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                              {disableTruckCount}
                            </span>
                          </label>
                        </div>
                      </div>
                      {/* Select Terminal */}
                      {this.state.isTerminal && (
                        <div className="w-150 mr-2">
                          <Select
                            onChange={(choosenTerminals) =>
                              this.setState({ choosenTerminals }, () => {
                                this.getlist();
                              })
                            }
                            styles={smallSelectStyle}
                            isMulti
                            name="choosenTerminals"
                            options={Terminal}
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      {/* <div className="ml-auto d-flex">
                        <div className="position-relative">
                          <button className="btn btn-outline-light position-relative">
                            <IconColumn />
                          </button>
                        </div>
                      </div> */}

                    </div>
                    <div
                      className="table-responsive"
                      style={{ height: "calc(100vh - 306px)" }}
                    >
                      {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
                      <table className="table table-card">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            {[
                              "Truck #",
                              "Fleet Owner",
                              "License Plate #",
                              "License Plate State",
                              "VIN",
                              "Address",
                              "AID",
                              "ITD",
                              "Registration",
                              "Inspection",
                              "HUT",
                              "Annual Inspection",
                              "Bobtail Insurance",
                              "Diesel Emission",
                              "IFTA",
                              "Engine Family",
                              "Odometer"
                            ].map((thStr) => (
                              <th
                                scope="col"
                                onClick={() =>
                                  sortingOptions.hasOwnProperty(thStr) &&
                                  this.sortTable(sortingOptions[thStr])
                                }
                                className={`${sortingOptions.hasOwnProperty(thStr) && (this.state.sort == undefined || this.state.sort !== sortingOptions[thStr])
                                  ? 'table-sort table-sort--default'
                                  : (sortingOptions.hasOwnProperty(thStr) ? this.state.sort === sortingOptions[thStr] && this.state.asce == true ?   'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                              >
                                {thStr}
                               
                              </th>
                            ))}
                            {this.state.isTerminal && (
                              <th
                                scope="col"
                                onClick={() =>
                                  sortingOptions.hasOwnProperty("Terminal") &&
                                  this.sortTable(sortingOptions["Terminal"])
                                }
                                className={`${sortingOptions.hasOwnProperty('Terminal') && (this.state.sort == undefined || this.state.sort !== sortingOptions['Terminal'])
                                  ? 'table-sort table-sort--default'
                                  : (sortingOptions.hasOwnProperty('Terminal') ? this.state.sort === sortingOptions['Terminal'] && this.state.asce == true ? 'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                              >
                                {"Terminal"}
                                
                              </th>
                            )}
                          

                          </tr>
                        </thead>
                        <tbody>
                          {AllTMSEquipments &&
                            AllTMSEquipments.map((d) => {
                              let completedStatus =
                                (d.load &&
                                  d.status === "COMPLETED" &&
                                  d.load.driverOrder &&
                                  d.load.driverOrder.find(
                                    (ele) => ele.departed
                                  )) ||
                                undefined;
                              let enrouteToPickup =
                                (d.load &&
                                  d.load.driverOrder &&
                                  d.load.driverOrder.find(
                                    (e) =>
                                      e.status === "PULLCONTAINER" && e.arrived
                                  )) ||
                                undefined;
                              let totalAmountOfDays;
                              if (enrouteToPickup) {
                                totalAmountOfDays =
                                  moment(
                                    completedStatus &&
                                    completedStatus.completedAt
                                  ).diff(
                                    moment(enrouteToPickup.completedAt),
                                    "days"
                                  ) + 1;
                              }
                              return (
                                <tr
                                  key={d._id}
                                  className={`${d.isDeleted ? "text-danger" : null
                                    }`}
                                >
                                  <td className="text-center">
                                    {!d.isDeleted && (
                                      <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => this.changeColor(d._id)}
                                      >
                                        <IconPen />
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {d.isDeleted ? (
                                      <button
                                        type="button"
                                        className="btn btn-link text-success"
                                        onClick={() =>
                                          this.deleteTMSEquipement(d)
                                        }
                                      >
                                        <IconCheckCircle />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() =>
                                          this.deleteTMSEquipement(d)
                                        }
                                      >
                                        <IconTrash />
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-center" data-tip data-for={d._id}>
                                    
                                    <button
                                      type="button"
                                      className="btn btn-link"
                                        
                                    >
                                      <IconNotes/>
                                    </button>
                                    {d.notes && d.notes.trim().length>0 ?  
                                      <ReactTooltip id={d._id}  className="type-gray w-300 text-wrap text-break line-break" >
                                        <span>{d.notes}</span>
                                      </ReactTooltip>
                                      : null
                                    }
                                  </td>
                                  <td>{d.equipmentID}</td>
                                  <td>
                                    {this.renderFleetOwner(d.fleetTruckOwner)}
                                  </td>
                                  <td>{d.licence_plate_number}</td>
                                  <td>{d.licence_plate_state}</td>
                                  <td>{d.vin}</td>
                                  <td width="10%">
                                    {d.lastCompletedStatus &&
                                      d.lastCompletedStatus.address
                                      ? d.lastCompletedStatus.address.address
                                      : ""}
                                  </td>
                                  {d.AID != null ? <td className={`${d.AID && moment(d.AID).diff(moment(), 'days') <= 0 ? 'bg-warning-500' : ''}`}>
                                    {d.AID
                                      ? moment(d.AID).format("MM/DD/YYYY")
                                      : ""}
                                  </td> : <td></td>}
                                  {d.ITD != null ? <td className={`${d.ITD && moment(d.ITD).diff(moment(), 'days') <= 0 ? 'bg-warning-500' : ''}`}>
                                    {d.ITD
                                      ? moment(d.ITD).format("MM/DD/YYYY")
                                      : ""}
                                  </td> : <td></td>}
                                  {d.reg_expiration != null ? <td className={`${d.reg_expiration && moment(d.reg_expiration).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    {d.reg_expiration
                                      ? moment(d.reg_expiration).format(
                                        "MM/DD/YYYY"
                                      )
                                      : ""}
                                  </td> : <td></td>}
                                  {d.inspection_exp != null ? <td className={`${d.inspection_exp && moment(d.inspection_exp).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    {d.inspection_exp
                                      ? moment(d.inspection_exp).format(
                                        "MM/DD/YYYY"
                                      )
                                      : ""}
                                  </td> : <td></td>}
                                  {d.hut_exp != null ? <td className={`${d.hut_exp && moment(d.hut_exp).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    {d.hut_exp
                                      ? moment(d.hut_exp).format("MM/DD/YYYY")
                                      : ""}
                                  </td> : <td></td>}
                                  {d.annual_inspection != null ? <td className={`${d.annual_inspection && moment(d.annual_inspection).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    {d.annual_inspection
                                      ? moment(d.annual_inspection).format(
                                        "MM/DD/YYYY"
                                      )
                                      : ""}
                                  </td> : <td></td>}
                                  {d.bobtail_insurance != null ? <td className={`${d.bobtail_insurance && moment(d.bobtail_insurance).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    <div className="text-muted">
                                      {d.bobtail_insurance
                                        ? moment(d.bobtail_insurance).format(
                                          "MM/DD/YYYY"
                                        )
                                        : ""}
                                    </div>
                                  </td> : <td></td>}
                                  {d.diesel_emission != null ? <td className={`${d.diesel_emission && moment(d.diesel_emission).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    {d.diesel_emission
                                      ? moment(d.diesel_emission).format(
                                        "MM/DD/YYYY"
                                      )
                                      : ""}
                                  </td> : <td></td>}
                                  {d.ifta != null ? <td className={`${d.ifta && moment(d.ifta).diff(moment(), 'days') <= 0 ? 'bg-warning-50' : ''}`}>
                                    {d.ifta
                                      ? moment(d.ifta).format("MM/DD/YYYY")
                                      : ""}
                                  </td> : <td></td>}
                                  <td>{d.enginefamily}</td>
                                  <td>{d.odometer}</td>
                                  {this.state.isTerminal && (
                                    <td className={`hideextra`}>
                                      {d.newTerminal &&
                                        d.newTerminal.length > 0 &&
                                        this.state.terminals &&
                                        this.state.terminals.length > 0
                                        ? d.newTerminal.map((ids, index) => {
                                          let thatTerminal =
                                            this.state.terminals.find(
                                              (D) => D._id == ids
                                            );
                                          return (
                                            <div key={index}>
                                              <span>
                                                {thatTerminal
                                                  ? thatTerminal.name
                                                  : ""}
                                              </span>
                                            </div>
                                          );
                                        })
                                        : ""}
                                    </td>
                                  )}
                              
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div
                  className={`page-fluid ${this.state.AddTruckModal ? "d-block" : "d-none"
                    }`}
                >
                  <div className="form-row-md fcpopup">
                    <div className="col-lg fcpopup__left" style={{ maxWidth: 270 }}>
                      <div className="card p-15 mb-1">
                        <h5>About</h5>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Truck #:</span>
                          <span>{this.state.equipmentID}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Year:</span>
                          <span>{this.state.year}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Make:</span>
                          <span>{this.state.make}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <span className="text-muted">Model:</span>
                          <span>{this.state.model}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg fcpopup__right">
                      <div className="card mb-0">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                          <li className="nav-item">
                            <a
                              className={`nav-link ${this.state.isLoadTabActive === 1 ? "active" : ""
                                }`}
                              href="javascript:void(0)"
                              onClick={() => this.handleLoadTabActive(1)}
                            >
                              <IconTruck />
                              <div className="nav-link__text">Truck</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${this.state.isLoadTabActive === 2 ? "active" : ""
                                }`}
                              href="javascript:void(0)"
                              onClick={() => this.handleLoadTabActive(2)}
                            >
                              <IconDocument />
                              <div className="nav-link__text">Documents</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${this.state.isLoadTabActive === 3 ? "active" : ""
                                }`}
                              href="javascript:void(0)"
                              onClick={() => this.handleLoadTabActive(3)}
                            >
                              <IconClock9 />
                              <div className="nav-link__text">History</div>
                            </a>
                          </li>
                          {this.state._id && (
                            <li className="nav-item">
                              <a
                                className={`nav-link ${this.state.isLoadTabActive === 4
                                  ? "active"
                                  : ""
                                  }`}
                                href="javascript:void(0)"
                                onClick={() => this.handleLoadTabActive(4)}
                              >
                                <IconSearchData />
                                <div className="nav-link__text">Audit</div>
                              </a>
                            </li>
                          )}

                          <li className="nav-item">
                            <a
                              className={`nav-link ${this.state.isLoadTabActive === 5 ? "active" : ""
                                }`}
                              href="javascript:void(0)"
                              onClick={() => this.handleLoadTabActive(5)}
                            >
                              <IconNotes />
                              <div className="nav-link__text">Notes</div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-loads">
                        {this.state.isLoadTabActive === 1 && (
                          <div className="tab" id="tab-chassis">
                           <div className="card pt-30 px-20 mb-10">
                            <div className="font-14 font-medium mb-15">Equipment</div>
                            <div className="form-row">
                              <div className="col-lg">
                                <label>Fleet Owner</label>
                                <Select
                                  placeholder="Select Fleet Truck Owner"
                                  isClearable
                                  styles={smallSelectStyle}
                                  value={this.state.allFleetTruckOwners.find(
                                    (data) => data.value === this.state.fleetTruckOwner
                                  )}
                                  onChange={(fleetTruckOwner) => {
                                    if (fleetTruckOwner === null && this.state._id)
                                      this.removeFleetTruckOwner(this.state._id);
                                    this.setState({ fleetTruckOwner:fleetTruckOwner.value });
                                  }}
                                  options={this.state.allFleetTruckOwners}
                                />
                              </div>
                              <div className="col-lg">
                                <StateFormInput
                                  type="text"
                                  name="equipmentID"
                                  isRequired="true"
                                  formErrors={formErrors}
                                  formsTouched={formsTouched}
                                  onValChange={this.setValChange}
                                  label={"Truck #"}
                                  value={this.state.equipmentID}
                                />
                              </div>

                          <div className="form-group col-lg"> 
                              <div className=" new-set-label"> 
                              <label>Transmission</label> 
                              <Select
                                isClearable
                                styles={smallSelectStyle}
                                options={transmissionOption}
                                value={this.state.transmission && transmissionOption.find(el=>el.value===this.state.transmission)}
                                onChange={(transmission) =>
                                this.setState({ transmission })
                                }
                            /> 
                              </div> 
                              </div> 
                              <div className="col-lg">
                                <StateFormInput
                                  type="text"
                                  name="enginefamily"
                                  formErrors={formErrors}
                                  formsTouched={formsTouched}
                                  onValChange={this.setValChange}
                                  placeholder="Enter Engine Family"
                                  label={"Engine Family"}
                                  value={this.state.enginefamily}
                                />
                              </div>
                              <div className="form-group col-lg"> 
                              <div className=" new-set-label"> 
                              <label>Odometer</label> 
                              <NumberFormat 
                               type="text"
                               name="odometer"
                               placeholder="Enter Odometer"
                               className="form-control"
                               value={this.state.odometer}
                               thousandSeparator={true}
                               onValueChange={(values) => {
                                 const {formattedValue , value} = values
                                  this.setState({odometer : formattedValue})
                               }}
                               />
                                 </div> 
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="col-lg">
                                <StateFormSelect
                                  formErrors={formErrors}
                                  formsTouched={formsTouched}
                                  styles={smallSelectStyle}
                                  name="year"
                                  options={yearOptions}
                                  label="Year"
                                  value={
                                    this.state.year &&
                                    yearOptions.find((el) => el.label === this.state.year)
                                  }
                                  placeholder="Select Year"
                                  onValChange={this.setValChange}
                                  isClearable={true}
                                />
                              </div>
                              <div className=" col-lg">
                                <StateFormInput
                                  type="text"
                                  name="make"
                                  formErrors={formErrors}
                                  formsTouched={formsTouched}
                                  onValChange={this.setValChange}
                                  label={"Make"}
                                  value={this.state.make}
                                />
                              </div>
                              <div className="form-group col-lg">
                                <label>Model</label>
                                <input
                                  type="text"
                                  name="model"
                                  className="form-control"
                                  placeholder="Enter Modal"
                                  value={this.state.model}
                                  onChange={(e) => this.setval(e)}
                                />
                              </div>
                              <div className="form-group col-lg">
                                <label>License State</label>
                                <input
                                  type="text"
                                  name="licence_plate_state"
                                  className="form-control"
                                  placeholder="Enter License State"
                                  value={this.state.licence_plate_state}
                                  onChange={(e) => this.setval(e)}
                                />
                              </div>
                              <div className="form-group col-lg">
                                <StateFormInput
                                  type="text"
                                  name="licence_plate_number"
                                  isRequired="true"
                                  formErrors={formErrors}
                                  formsTouched={formsTouched}
                                  label={"License Plate #"}
                                  value={this.state.licence_plate_number}
                                  onValChange={this.setValChange}
                                />
                              </div>
                              <div className=" form-group col-lg">
                                <label>VIN</label>
                                <input
                                  type="text"
                                  name="vin"
                                  className="form-control"
                                  placeholder="Enter Vin"
                                  value={this.state.vin}
                                  onChange={(e) => this.setval(e)}
                                />
                              </div>
                              <div className="form-group col-lg">
                                <label>GPS #</label>
                                <div>
                                  <input
                                    type="text"
                                    name="gps"
                                    className="form-control"
                                    placeholder="Enter.."
                                    value={this.state.gps}
                                    onChange={(e) => this.setval(e)}
                                  />
                                </div>
                              </div>
                              <div className="form-group col-lg">
                                <label>RFID #</label>
                                <div>
                                  <input
                                    type="text"
                                    name="rfid"
                                    className="form-control"
                                    placeholder="Enter RFID Number"
                                    value={this.state.rfid}
                                    onChange={(e) => this.setval(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="hr-light mt-10 mb-20"></div>
                            <div className="font-14 font-medium mb-15">Expiration Dates</div>

                            <div className="form-row">
                              <div className="form-group col-lg">
                                <label>Registration Exp</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ reg_expiration: e })}
                                    value={
                                      this.state.reg_expiration ? moment(this.state.reg_expiration) : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg">
                                <label>Inspection Expiration</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ inspection_exp: e })}
                                    value={
                                      this.state.inspection_exp ? moment(this.state.inspection_exp) : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg">
                                <label>HUT expiration</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ hut_exp: e })}
                                    value={this.state.hut_exp ? moment(this.state.hut_exp) : ""}
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-lg">
                                <label>Annual Inspection</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ annualInspection: e })}
                                    value={
                                      this.state.annualInspection
                                        ? moment(this.state.annualInspection)
                                        : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg">
                                <label>Bobtail Insurance</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ bobtailInsurance: e })}
                                    value={
                                      this.state.bobtailInsurance
                                        ? moment(this.state.bobtailInsurance)
                                        : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg">
                                <label>IFTA</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ ifta: e })}
                                    value={this.state.ifta ? moment(this.state.ifta) : ""}
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-lg">
                                <label>Diesel Emission</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ diselEmission: e })}
                                    value={
                                      this.state.diselEmission ? moment(this.state.diselEmission) : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-lg-3">
                                <label>AID</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ AID: e })}
                                    value={this.state.AID ? moment(this.state.AID) : ""}
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg-3">
                                <label>ITD</label>
                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Select Date" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ ITD: e })}
                                    value={this.state.ITD ? moment(this.state.ITD) : ""}
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg-3">
                                <label>Date Monthly Maintenance Last Received</label>

                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Who makes" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) =>
                                      this.setState({
                                        maintenanceReceived: e,
                                      })
                                    }
                                    value={
                                      this.state.maintenanceReceived
                                        ? moment(this.state.maintenanceReceived)
                                        : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group col-lg-3">
                                <label>Date Monthly Maintenance Next Due</label>

                                <div className="position-relative">
                                  <Datetime
                                    inputProps={{ placeholder: "Who makes" }}
                                    timeFormat={false}
                                    displayTimeZone={timeZone}
                                    onChange={(e) => this.setState({ maintenanceDue: e })}
                                    value={
                                      this.state.maintenanceDue ? moment(this.state.maintenanceDue) : ""
                                    }
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                              {this.state.isTerminal && (
                                <div className="col-lg-3">
                                  <StateFormSelect
                                    name="newTerminal"
                                    label={"Terminal"}
                                    isRequired={true}
                                    formErrors={formErrors}
                                    formsTouched={formsTouched}
                                    onValChange={this.setValChange}
                                    options={Terminal}
                                    value={this.state.newTerminal}
                                    isMulti={true}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          </div>
                        )}
                        {this.state.isLoadTabActive === 2 && (
                          <div className="tab" id="tab-chassis">
                            <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                              <h4 className="mb-0 font-20">
                                {documents && documents.length} Documents{" "}
                              </h4>
                              <button
                                className="ml-auto btn btn-primary"
                                onClick={this.handleShowFileUploadModal}
                              >
                                <IconUpload className="mr-2" />
                                Upload Document
                              </button>
                            </div>
                            <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                            {!this.props.type &&
                              getStorage("currentUserRole") != "customer" && (
                                <UploadedDocuments
                                  listDocuments={documents}
                                  deleteDocument={(id) =>
                                    this.deleteEquipmentDoc({
                                      docId: id,
                                      _id: this.state._id,
                                    })
                                  }
                                  onDocumentLoadSuccess={
                                    this.onDocumentLoadSuccess
                                  }
                                  newView={this.newView}
                                  selectedDoc={this.state.selectedDoc}
                                  setSelectedDoc={(docIndex) => {
                                    this.setState({ selectedDoc: docIndex });
                                  }}
                                  handleShowUploadModal={
                                    this.handleShowUploadModal
                                  }
                                  uploadResult={this.uploadResult}
                                  multi={false} 
                                  type={"image"}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {this.state.isLoadTabActive === 3 && (
                          <div className="tab" id="tab-chassis">
                            <div className="tab" id="tab-document">
                              <div className="table-responsive">
                                <table className="table table-card table-card--ls mb-10">
                                  <thead>
                                    <tr>
                                      <th>Equipment</th>
                                      <th>Load </th>
                                      <th>Container #</th>
                                      <th>Driver Name</th>
                                      <th>Load Status</th>
                                      <th>Begining</th>
                                      <th>End</th>

                                      <th>From Location</th>
                                      <th>To Location</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.equipmentHistory.length === 0 && (
                                      <tr>
                                        <td colSpan={100}>
                                          No Truck History Available.
                                        </td>
                                      </tr>
                                    )}
                                    {this.state.equipmentHistory.map((d) => {
                                      return (
                                        <tr key={d._id}>
                                          <td>
                                            {d.equipmentId
                                              ? d.equipmentId.equipmentID
                                              : ""}
                                          </td>
                                          <td>
                                            {d.load
                                              ? d.load.reference_number
                                              : ""}
                                          </td>
                                          <td>
                                            {d.containerNo ? d.containerNo : ""}
                                          </td>
                                          <td>
                                            {d.driver && (
                                              <div className="d-flex align-items-center">
                                                <span class="avatar-circle avatar-xs mr-1">
                                                  {d.driver.name.charAt(0)}
                                                </span>
                                                {d.driver && d.driver.name
                                                  ? d.driver.lastName
                                                    ? d.driver.name +
                                                    d.driver.lastName
                                                    : d.driver.name
                                                  : ""}
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            {d.loadTypeStatus && (
                                              <span className="badge badge-green">
                                                {d.loadTypeStatus
                                                  ? d.loadTypeStatus
                                                  : ""}{" "}
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {d.action === "ADD" ? (
                                              <React.Fragment>
                                                <div>
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("DD/MM/YY")}
                                                </div>
                                                <div className="text-muted">
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("hh:mm a")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {d.action === "REMOVE" ? (
                                              <React.Fragment>
                                                <div>
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("DD/MM/YY")}
                                                </div>
                                                <div className="text-muted">
                                                  {moment(d.createdAt)
                                                    .tz(getStorage("timeZone"))
                                                    .format("hh:mm a")}
                                                </div>
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {d.action === "ADD" &&
                                              d.driverOrder &&
                                              d.driverOrder.address
                                              ? d.driverOrder.address.address
                                              : ""}
                                          </td>
                                          <td>
                                            {d.action === "REMOVE" &&
                                              d.driverOrder &&
                                              d.driverOrder.address
                                              ? d.driverOrder.address.address
                                              : ""}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.isLoadTabActive === 4 && this.state._id && (
                          <div className="tab" id="tab-chassis">
                            <div className="table mb-20">
                              <table className="table table-card table-card--ls mb-10">
                                <thead>
                                  <tr>
                                    <th>User</th>
                                    <th>Type</th>
                                    <th>Time</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.audits &&
                                    this.state.audits.map((d, key) => {
                                      let description;
                                      let url;
                                      let equipmentID;
                                      if (
                                        [
                                          "DOCUMENT_UPLOAD",
                                          "DOCUMENT_REMOVE",
                                        ].includes(d.type)
                                      ) {
                                        description = d.data.document.type;
                                        url = (
                                          <a href={d.data.document.url}>
                                            Open Document...
                                          </a>
                                        );
                                      }
                                      if (d.type === "ADD") {
                                        description = "Truck No ";
                                        equipmentID = (
                                          <b>"{d.data.equipmentID}"</b>
                                        );
                                      }
                                      if (d.type === "UPDATE") {
                                        let data;
                                        if (
                                          key !==
                                          this.state.audits.length - 1 &&
                                          this.state.audits[key + 1].userId
                                        ) {
                                          data =
                                            this.state.audits[key + 1].data;
                                        }
                                        const additionalInfo = d.data;
                                        let dateFormat = [
                                          "AID",
                                          "ITD",
                                          "reg_expiration",
                                          "inspection_exp",
                                          "annual_inspection",
                                          "bobtail_insurance",
                                          "diesel_emission",
                                          "ifta",
                                          "bobTail",
                                          "maintenanceReceived",
                                          "maintenanceDue",
                                          "hut_exp",
                                        ];
                                        let differanceDescription =
                                          differnceFinder(data, additionalInfo);
                                        description = Object.keys(
                                          differanceDescription || []
                                        )
                                          .filter(
                                            (key1) =>
                                              differanceDescription[key1] &&
                                              typeof differanceDescription[
                                              key1
                                              ] !== "object"
                                          )
                                          .map((key1) => {
                                            if (dateFormat.includes(key1)) {
                                              return (
                                                <p>
                                                  {key1} changed to{" "}
                                                  <b>
                                                    {moment(
                                                      differanceDescription[
                                                      key1
                                                      ]
                                                    ).format("ddd MMM DD YYYY")}
                                                  </b>
                                                </p>
                                              );
                                            } else {
                                              return (
                                                <p>
                                                  {key1} changed to{" "}
                                                  <b className="badge badge-soft-warning">
                                                    {
                                                      differanceDescription[
                                                      key1
                                                      ]
                                                    }
                                                  </b>
                                                </p>
                                              );
                                            }
                                          });
                                      }
                                      if (d.type === "ASSIGN_TRUCK_DRIVER") {
                                          
                                        description ="Truck assigned to :"
                                        
                                        equipmentID = (
                                          <b class="badge badge-soft-warning"> {d.data.driver.name+" "+d.data.driver.lastName}</b>
                                        );
                                      }if (d.type === "UNASSIGNED_TRUCK_DRIVER") {
                                          
                                        description ="Truck unassigned from :"
                                        
                                        equipmentID = (
                                          <b class="badge badge-soft-warning"> {d.data.driver.name+" "+d.data.driver.lastName}</b>
                                        );
                                      }
                                      return (
                                        <tr key={d._id} id={d._id}>
                                          <td>
                                            {" "}
                                            <div className="d-flex align-items-center">
                                              <span class="avatar-circle avatar-xs mr-1">
                                                {d.userId.name.charAt(0)}
                                              </span>
                                              {d.userId.name}
                                            </div>
                                          </td>

                                          <td>
                                            {" "}
                                            <span class="badge badge-gray-100">
                                              {d.type.replace(/_/g, " ")}
                                            </span>
                                          </td>
                                          <td>
                                            <div>
                                              {moment(d.createdAt)
                                                .tz(getStorage("timeZone"))
                                                .format("MM/DD/YY")}
                                            </div>
                                            <div className="text-muted">
                                              {moment(d.createdAt)
                                                .tz(getStorage("timeZone"))
                                                .format("hh:mm a")}
                                            </div>
                                          </td>
                                          <td>
                                            {description} {equipmentID} {url}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        {this.state.isLoadTabActive === 5 && (
                          <div className="tab" id="tab-chassis">
                            <div className="card">
                              <div className="pt-30 px-20 mb-10">
                                <div className="d-flex align-items-center mb-20">
                                  <CustomIconNotes />
                                  <h5 className="font-16 ml-2 mb-0">Truck</h5>
                                </div>
                                <div className="form-group">
                                  <textarea
                                    className="form-control"
                                    placeholder="Notes for Truck"
                                    rows="10"
                                    name="notes"
                                    onChange={(e) => this.setval(e)}
                                  >
                                    {this.state.notes}
                                  </textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          show={this.state.showModal || this.props.isQuickAdd}
          animation={false}
          dialogClassName="modal-xl modal-dialog-centered"
        >
          <Modal.Header>
            <Modal.Title>Add New Truck</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form onSubmit={e => e.preventDefault()}>
                <div className="font-14 font-medium mb-15">Equipment </div>
                <div className="form-row">
                  <StateFormSelect
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    label="Fleet Owners"
                    name="fleetTruckOwner"
                    options={this.state.allFleetTruckOwners}
                    value={
                      this.state.fleetTruckOwner &&
                      this._findValue(
                        "allFleetTruckOwners",
                        this.state.fleetTruckOwner,
                        "name"
                      )}
                    placeholder="Select Fleet Truck Owner"
                    onValChange={this.setValChange}
                    isClearable={true}
                  />
               
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="equipmentID"
                    isRequired="true"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Truck #"}
                    value={this.state.equipmentID}
                  />
                  <StateFormSelect
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    label="Transmission"
                    name="transmission"
                    options={transmissionOption}
                    value={this.state.transmission && transmissionOption.find(el=>el.value===this.state.transmission)}
                    placeholder="Select Transmission"
                    onValChange={this.setValChange}
                    isClearable={true}
                  />
                           <div className="col-lg">
                        <StateFormInput
                          type="text"
                          name="enginefamily"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          placeholder="Enter Engine Family"
                          label={"Engine Family"}
                          value={this.state.enginefamily}
                        />
                  </div>
                  <div className="form-group col-lg"> 
                  <div className=" new-set-label"> 
                      <label>Odometer</label> 
                      <NumberFormat 
                          type="text"
                          name="odometer"
                          placeholder="Enter Odometer"
                          className="form-control"
                          value={this.state.odometer}
                          thousandSeparator={true}
                          onValueChange={(values) => {
                              const {formattedValue , value} = values
                              this.setState({odometer : formattedValue})
                            }}
                       />
                  </div> 
                  </div> 
                  {/* <StateFormInput
                    className={"col-md-4"}
                    type="number"
                    name="year"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Year"}
                    value={this.state.year}
                  /> */}
                  </div>
                <div className="form-row">
                  <StateFormSelect
                    className={"col-lg"}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    styles={smallSelectStyle}
                    name="year"
                    options={yearOptions}
                    label="Year"
                    value={
                      this.state.year && yearOptions.find(el => el.label === this.state.year)}
                    placeholder="Select Year"
                    onValChange={this.setValChange}
                    isClearable={true}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="make"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Make"}
                    value={this.state.make}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="model"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"Model"}
                    value={this.state.model}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="licence_plate_state"
                    value={this.state.licence_plate_state}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"License State"}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    isRequired="true"
                    name="licence_plate_number"
                    value={this.state.licence_plate_number}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"License Plate #"}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    type="text"
                    name="vin"
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={this.setValChange}
                    label={"VIN"}
                    value={this.state.vin}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    type="text"
                    name="gps"
                    label={"GPS #"}
                    value={this.state.gps}
                    onValChange={this.setValChange}
                  />
                  <StateFormInput
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    type="text"
                    name="rfid"
                    label={"RFID #"}
                    value={this.state.rfid}
                    onValChange={this.setValChange}
                  />
                </div>
                <div className="hr-light mt-10 mb-20"></div>
                <div className="form-row">
                  <div className="col-12">
                      <div className="font-14 font-medium mb-15">
                        Expiration Dates
                      </div>
                  </div>
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="reg_expiration"
                    label={"Registration Exp"}
                    value={
                      this.state.reg_expiration
                        ? moment(this.state.reg_expiration)
                        : ""
                    }
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="inspection_exp"
                    label={"Inspection Expiration"}
                    value={
                      this.state.inspection_exp
                        ? moment(this.state.inspection_exp)
                        : ""
                    }
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="hut_exp"
                    label={"HUT Expiration"}
                    value={this.state.hut_exp ? moment(this.state.hut_exp) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="annualInspection"
                    label={"Annual Inspection"}
                    value={this.state.annualInspection ? moment(this.state.annualInspection) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="bobtailInsurance"
                    label={"Bobtail Insurance"}
                    value={this.state.bobtailInsurance ? moment(this.state.bobtailInsurance) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="ifta"
                    label={"IFTA"}
                    value={this.state.ifta ? moment(this.state.ifta) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="diselEmission"
                    label={"Diesel Emission"}
                    value={this.state.diselEmission ? moment(this.state.diselEmission) : ""}
                    onValChange={this.setValChange}
                  />
                </div>

                <div className="form-row">
                  <StateFormDateTime
                    className={"col-lg"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="AID"
                    label={"AID"}
                    value={this.state.AID ? moment(this.state.AID) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="ITD"
                    label={"ITD"}
                    value={this.state.ITD ? moment(this.state.ITD) : ""}
                    onValChange={this.setValChange}
                    className="col-lg"
                  />                  
                  <StateFormDateTime
                    className={"col-lg-3"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="maintenanceReceived"
                    label={"Date Monthly Maintenance Last Received"}
                    value={this.state.maintenanceReceived ? moment(this.state.maintenanceReceived) : ""}
                    onValChange={this.setValChange}
                  />
                  <StateFormDateTime
                    className={"col-lg-3"}
                    formsTouched={formsTouched}
                    formErrors={formErrors}
                    name="maintenanceDue"
                    label={"Date Monthly Maintenance Next Due"}
                    value={this.state.maintenanceDue ? moment(this.state.maintenanceDue) : ""}
                    onValChange={this.setValChange}
                  />
                  
                  {this.state.isTerminal && (
                    <StateFormSelect
                      className={"col-lg"}
                      formErrors={formErrors}
                      formsTouched={formsTouched}
                      label="Terminal"
                      name="newTerminal"
                      options={Terminal}
                      isRequired={true}
                      value={this.state.selectedValues}
                      placeholder="Select Terminal"
                      onValChange={this.setValChange}
                      isClearable={true}
                      isMulti={true}
                    />
                  )}
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-link"
              onClick={() => {
                this.setState({ showModal: false });
                if (this.props.isQuickAdd) {
                  this.props.falsyData("truck");
                }
              }}
            >
              Cancel
            </button>
            {this.state.tab !== 4 &&
              (this.state.tab == 2 && this.state._id ? (
                ""
              ) : (
                <button
                  className={`btn ${this.state._id ? "btn-primary" : "btn-success"
                    }`}
                  onClick={() => {
                    this.insertdata();
                    // this.setState({ showModal: false });
                  }}
                >
                  <span> {this.state._id ? "Update" : "Add New Truck"} </span>
                </button>
              ))}
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showFileUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {!this.props.type && getStorage('currentUserRole') != 'customer' &&
                <div>
                  <ImageUploader
                    uploadResult={this.uploadResult}
                    multi={false}
                    name="Choose from your device..."
                    type={'image'} className="custom-file-input "
                  />

                  {this.state.documentFile && (
                    <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                      <IconUpload className="mr-20" />
                      <div className="w-100">
                        <div className="font-medium d-flex justify-content-between w-100 mb-1">
                          <div>{this.state.documentFile.name}</div>
                          <div className="ml-auto">{this.state.progress}%</div>
                        </div>
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            aria-valuenow={`${this.state.progress}`}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${this.state.progress}%` }}
                          ></div>
                        </div>
                      </div>
                      <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                        <span
                          onClick={() =>
                            this.setState({
                              documentFile: null,
                              imagePreviewUrl: null,
                              pdfPreviewUrl: null,
                              progress: 0,
                            })
                          }
                        >
                          {" "}
                          <IconTimes className="" />
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              }
              {/* {acceptedFileItems} */}

              {/* After File is Uploaded sue the code block below */}
              {this.state.documentFile && <div className="dropzone-fileupload d-flex">
                <button className="btn btn-success btn-circle btn-xs mr-15">
                  <IconCheck />
                </button>

                <div className="document-preview document-preview--sm mr-3">
                  {this.state.imagePreviewUrl && (
                    <img src={this.state.imagePreviewUrl} alt={""} />
                  )}
                  {this.state.pdfPreviewUrl && (
                    <canvas id="the-canvas"></canvas>
                  )}
                  {/* <div className="document-preview__image">
                      {this.state.popupFileType.indexOf('image') > -1 && <img className="document-preview__image" src={this.state.imagePreviewUrl} alt={''} />}
                      {this.state.popupFileType.indexOf('pdf') > -1 && this.state.popupPreview &&
                        <div id="holder">
                          <div>
                            <Document
                              file={this.state.popupPreview}
                              onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                              <Page pageNumber={1} />
                            </Document>
                          </div>
                        </div>
                      }
                    </div> */}
                </div>

                <div className="w-100 d-flex">
                  <div>
                    <div className="font-medium ">{this.state.documentFile.name}</div>
                    <div className="text-muted">{(this.state.documentFile.size * 0.0009765625).toFixed(1)} kb</div>
                  </div>
                  {!this.props.type && getStorage('currentUserRole') != 'customer' &&
                    <div className="w-150 ml-auto">

                      <Select
                        options={this.state.documentTypes}
                        styles={smallSelectStyle}
                        value={this.state.fileType}
                        onChange={(e) => this.updateDocument(e)}
                      />
                    </div>
                  }

                  {!this.props.type && getStorage('currentUserRole') != 'customer' &&
                    this.state.activeTab === 'subCustomer' &&
                    <div className="w-150 ml-auto">
                      <Select
                        options={this.state.documentTypes}
                        styles={smallSelectStyle}
                        value={this.state.fileType}
                        onChange={(e) => {
                          this.setState({ fileType: e.target.value })
                        }}
                      />
                    </div>
                  }

                </div>
                <button className="btn btn-link btn-circle btn-xs ml-3">
                  <span onClick={() => this.removeDocument()}> <IconTrash /> </span>
                </button>
              </div>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                this.setState({
                  showFileUploadModal: false, uploadFileDetails: "", documentFile: null,
                  progress: 0, imagePreviewUrl: null, pdfPreviewUrl: null,
                })
              }}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              // disabled={this.state.documentFile!=null?true:false}
              onClick={() => {
                if (this.state.documentFile && this.state.fileType) {
                  this.insertdata();
                } else { toastr.show("Please select a type.", "error") }
              }}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showFullDropScreen && this.state.isLoadTabActive === 2 && !this.state.showFileUploadModal && <FullScreenUploader uploadResult={this.uploadResult} multi={false} type={"image"}/>}
      </div>


    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) };
}

export default connect(null, mapDispatchToProps)(Truck);

const schemaValidatorObject = Yup.object().shape({
  isTerminal: Yup.boolean(),
  newTerminal: Yup.mixed()
    .nullable()
    .when("isTerminal", {
      is: (val) => val === true,
      then: Yup.array()
        .min(1, "Terminal Is Required.")
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        )
        .typeError("Terminal is Required.")
        .required("Terminal is Required."),
    }),
  equipmentID: Yup.string().trim().required("Truck # is Required."),
  enginefamily : Yup.string().max(30 , "Enter less than 30 characters.")
});
const transmissionOption = [
  { value: "Automatic", label: "Automatic" },
  { value: "Manual", label: "Manual" },
];
