import _ from "lodash";

export const chatTabs = [
  {
    chatTabID: "driver",
    label: "Driver",
    messageSnippets: [],
  },
  {
    chatTabID: "fleetmanager",
    label: "Employee",
    messageSnippets: [],
  },
  {
    chatTabID: "groups",
    label: "Group",
    messageSnippets: [],
  },
  // {
  //     chatTabID: 'group',
  //     label: 'Customer',
  //     messageSnippets: [],
  // },
];

export const validateEmail = (email) => {
  if(!email) return true;
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email.trim());
};
export const isImage = (file) => file["type"].includes("image");

export const isImageFromUrl = (url) => {
  if (!url) return false;
  const ext = url.split(/[#?]/)[0].split(".").pop().trim();
  return ["png", "jpg", "jped", "svg"].includes(ext);
};

export const splitEmailsFromString = (emails) => {
  var newEmails = [];

  if (!emails) return newEmails;

  if (_.isArray(emails)) {
    const splitted = emails.join(",").split(",");
    newEmails = [...splitted];
  } else {
    let multipleEmail = emails.includes(",");

    if (multipleEmail) {
      newEmails = [...newEmails, ...emails.split(",")];
    } else {
      newEmails.push(emails);
    }
  }

  newEmails = newEmails.filter((email) => !validateEmail(email));

  return _.compact(newEmails);
};

export const DriverItineraryDragDropType = {
  ASSIGNED_LOAD: 'assigned_load',
  UNASSIGNED_LOAD: 'unassigned_load',
  COMPLETED_LOAD: 'completed_load'
}