import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams from '../../../Components/Common/jsonToQueryParams'


export function getProfile() {
  const url = 'getUser';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function getDriverAudits(params) {
  let url = 'carrier/getTMSDriverAudit';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function listAllDrivers(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function newDriverRegister(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'carrier/newDriverRegister', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function assignRole(params) {
  let url = 'admin/assignRoles';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function removeRole(params) {
  let url = 'admin/removeRoles';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function updateDriverProfile(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', 'carrier/updateDriverProfile', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function deleteDriverProfile(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('delete', 'carrier/deleteDriverProfile', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function resetDriverPassword(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'user/driver-resetpassword', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateDriverAccessorials(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', 'carrier/updateDriverAccessorialsByCarrier', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function checkUserNameAvailability(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'driver/username-availability', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateDriverPermissions(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post','driver/update-permissions',params, {
        'authorization': getStorage('token'),
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
}

export function updateDriverOnHold(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post','driver/update-driver-hold',params, {
        'authorization': getStorage('token'),
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
}

export function profileCheck(params) {
  let url = 'user/profile-check';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function userUnlock(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post','user/unlock',params, {
        'authorization': getStorage('token'),
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
}

export function getDecryptedValues(params) {
  let url = 'carrier/getDecryptedValues';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
}