import React, { useState } from "react";
import { CustomIconNotes } from "../../../../Components/Common/CustomIcons/Index";
import { errorColorResponse, onChangeErrorColorResponse, onChangeSuccessColorResponse, successColorResponse } from '../NewTabContent/utility';
import { UpdateChassis } from '../actionCreators';


const TabNotes = ({ notes, SelectedChassis, updatechassis, setVal }) => {
  const [Notes, setNotes] = useState(SelectedChassis.notes ? SelectedChassis.notes : "")

  const onBlurHandler = (key, value) => {
    if (SelectedChassis[key] === value) return
    let params = {
      _id: SelectedChassis._id,
      [key]: value
    }

    UpdateChassis(params).then((result) => {
      updatechassis(result.data.data)
      successColorResponse(key)
    }).catch((err) => {
      errorColorResponse(key)
    })
  }
  
  return (
    <div className="tab" id="tab-document" >
      <div className="card card--shadow-5">
        <div className="px-30 py-20">
          <div className="d-flex align-items-center mb-20">
            <CustomIconNotes className="mr-10" />
            <h5 className="font-16 mb-0">Add Notes</h5>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Notes for driver"
              rows="10"
              name="notes"
              value={notes}
              onChange={(e)=>{
                setNotes(e.target.value)
              }}
              onBlur={(e)=>{
                onBlurHandler("notes",e.target.value)
              }}
            >
              {Notes}
            </textarea>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabNotes