import config from "../config";
import firebase from 'firebase/app';

export const REALTIME_DB_SOURCE = {
  primary: firebase.database(),
  driverLocation: firebase.database(config.driverLocationFirebaseApp),
};

export class RealtimeDBService {
  constructor(source) {
    this._source = source;
  }

  ref = (path) => {
    if(this._source) {
      this.activeReference = this._source.ref(path);
      return;
    }
    this._source = REALTIME_DB_SOURCE.primary;
    this.activeReference = this._source.ref(path);
    return;
  }

  on = (evt, callback) => {
    if(this.activeReference) {
      this.activeReference.on(evt, callback);
      return;
    }
    throw new Error("No active references available");
  }

  off = (evt) => {
    if(this.activeReference) {
      this.activeReference.off(evt);
      return;
    }
    throw new Error("No active references available");
  }

  once = (evt, callback) => {
    if(this.activeReference) {
      this.activeReference.once(evt, callback);
      return;
    }
    throw new Error("No active references available");
  }
}