import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Spinner } from '../../Components/Common/Components/Component';

var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is required';
  }

  if (!re.test(String(values.email).toLowerCase())) {
    errors.email = 'Invalid Email Address';
  }

  return errors;
};

export const NewInputField = ({
  input,
  type,
  label,
  keyPress,
  placeholder,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="form-group">
      <span>{label}</span>
      <input {...input} type={type} onKeyPress={keyPress} placeholder={placeholder} />
      {touched && ((error && <span className="error" style={{ color: 'red' }}>{error}</span>))}
    </div>
  )
}
class TokenComponent extends Component {
  render() {
    var { handleSubmit, valid, handleEmail } = this.props;
    return (
      <form onSubmit={handleSubmit(handleEmail)}>
        <Field
          type="email"
          component={NewInputField}
          name="email"
          placeholder="Email*"
          processing={this.props.processing}
        />
        <div className="text-center">
          <button type="submit" className="btn btn-primary btn-lg btn-block mt-40">Send Link</button>
        </div>
        {this.props.processing &&
          <Spinner />
        }
      </form>
    )
  }
}

TokenComponent = reduxForm({
  // a unique name for the form
  form: 'token',
  validate
})(TokenComponent)

export default TokenComponent;
