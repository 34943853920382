import axios from 'axios';

import { HTTP, getStorage, setStorage } from '../../../services';
import * as ToQuery from '../../../Components/Common/jsonToQueryParams'
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams'


import { param } from 'jquery';
import { ref } from 'yup';
import config from '../../../config';


export function getDocumentDesignswithLoad(query) { 
  let url = 'carrier/getDocumentDesignWithLoad';
  url = query ? url + "?" + jsonToQueryParams(query) : url; 
  
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then(async (result) => {
          if(query && query.reference_number){
            if(result && result.data && result.data.data){
              resolve(result.data.data)
            }
          }else{
            const data = await result.data.data.map( (design) => {
              const customers = [];
                design.customers.forEach(obj => {
                  customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
                })
                design.customers = customers;
                return design;
            })
            resolve(data);
          }
       
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
 
  export function getDocumentDesignWithMultipleLoad(query) { 
    let url = 'carrier/getDocumentDesignWithMultipleLoad';
    url = query ? url + "?" + jsonToQueryParams(query) : url; 
    
      return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
          'authorization': getStorage('token'),
        })
          .then(async (result) => {
            if(query && query.reference_number){
              if(result && result.data && result.data.data){
                resolve(result.data.data)
              }
            }else{
              const data = await result.data.data.map( (design) => {
                const customers = [];
                  design.customers.forEach(obj => {
                    customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
                  })
                  design.customers = customers;
                  return design;
              })
              resolve(data);
            }
         
          })
          .catch((error) => {
            reject(error);
          });
      })
    }
export function getDocumentDesigns(query) { 
  let url = 'carrier/getDocumentDesign';
  url = query ? url + "?" + jsonToQueryParams(query) : url; 
  
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then(async (result) => {
          
            const data = await result.data.data.map( (design) => {
              const customers = [];
                design.customers.forEach(obj => {
                  customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
                })
                design.customers = customers;
                return design;
            })
            resolve(data);
          
       
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  export function addDocumentDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params } 
      let url = 'carrier/addDocumentDesign';
      return new Promise((resolve, reject) => {
        HTTP('post', url, obj, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            let allDatas = result.data.data;
            
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function editDocumentDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params } 
      let url = 'carrier/editDocumentDesign';
      return new Promise((resolve, reject) => {
        HTTP('post', url, obj, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            let allDatas = result.data.data;
            
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function removeDocumentDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params } 
      let url = 'carrier/removeDocumentDesign';
      return new Promise((resolve, reject) => {
        HTTP('post', url, obj, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            let allDatas = result.data.data;
            
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function getInvoiceDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params, authToken : getStorage('token')  } 
      let url = 'invoice';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function getSubInvoiceDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params , authToken : getStorage('token')} 
      let url = 'subInvoice';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function emailInvoice(params){
    if (!params) {
        return
      }
      const obj = { ...params, authToken : getStorage('token') } 
      let url = 'emailInvoice';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function emailMultipleLoadInvoice(params){
    if (!params) {
        return
      }
      const obj = { ...params, authToken : getStorage('token') } 
      let url = 'emailMultipleLoadInvoice';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function emailMultipleLoadSubInvoice(params){
    if (!params) {
        return
      }
      const obj = { ...params, authToken :  getStorage('token')} 
      let url = 'emailMultipleLoadSubInvoice';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function getPODDesign(params){
    if (!params) {
      return
    }
    const obj = { ...params , authToken : getStorage('token')} 
    let url = 'v1/proofOfDelivery';
    return new Promise((resolve, reject) => {
      HTTP('post', null, obj, {
        'authorization': getStorage('token'),
      }, config.newDocUrl+url)
        .then((result) => {
          let allDatas = result.data;
          resolve(allDatas);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  export function getRateConfirmationDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params , authToken : getStorage('token')} 
      let url = 'rateConfirmation';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function getDeliveryOrderDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params , authToken : getStorage('token')} 
      let url = 'deliveryOrder';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function getBundleDesign(params){
    if (!params) {
        return
      }
      const obj = { ...params, authToken : getStorage('token') } 
      let url = 'bundle';
      return new Promise((resolve, reject) => {
        HTTP('post', null, obj, {
          'authorization': getStorage('token'),
        }, config.newDocUrl+url)
          .then((result) => {
            let allDatas = result.data;
            resolve(allDatas);
          })
          .catch((error) => {
            reject(error);
          });
      })
  }

  export function getCarrierRateConDesign(params){
    if (!params) {
      return
    }
    const obj = { ...params , authToken : getStorage('token')} 
    let url = 'carrierRateConfirmation';
    return new Promise((resolve, reject) => {
      HTTP('post', null, obj, {
        'authorization': getStorage('token'),
      }, config.newDocUrl+url)
        .then((result) => {
          let allDatas = result.data;
          resolve(allDatas);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }