import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

const PopperTooltip = (props) => {
  const { isOpen, refNo, color, name, cssClass,nameArray } = props;
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(refNo, popperElement, { placement: 'top' });

  return (
    <>
        {isOpen && ReactDOM.createPortal(
          <div
            className={`tooltip tooltip--${color} bs-tooltip-top portal-tooltip ${cssClass}`}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {name}
            {nameArray && nameArray.map((P, index)=>{
                                  if(P.name) {
                                      return (
                                          <span key={P.index}>
                                              {P.name}{" "}
                                              {P.lastName}{<br/>}
                                          </span>
                                      )
                                  }
                                })}
            <span className="arrow"></span>
          </div>
        ,
        document.querySelector('#poper-dest')
        )}
    </>
  )
  
};
export default PopperTooltip;
