import React, { Component } from "react";
import { connect } from "react-redux";
import Notifications from "react-notify-toast";
import { TMS_GET_USER_LOAD } from "../constant";
import $ from "jquery";
import differenceFinder from 'deep-diff';

import AddLoad from "./AddLoad";
import { createLoad, UpdatePricing, editLoad } from "../services";
import { RoadField } from "../validations";
import { getStorage, isNewModal, toastr } from "../../../services/Common.services";
import configuration from "../../../config";
import _ from "underscore";
import __, { cloneDeep } from "lodash";
import confirm from "../../../Components/Common/ConfirmAert";
import NewAddLoad from "./NewAddLoad";
const firebase = configuration.firebase;

class Load extends Component {
  constructor(props) {
    super(props);
    this.updatePricing = this.updatePricing.bind(this);
  }
  state = {
    hasReference: false,
    allTMSChassis: [],
    existingLoadChassis: [],
    existingLoadChassis: [],
    formValues:{},
    originalFormValues:{},
  };
  componentWillMount() {

  }
  componentDidMount() {
    if (getStorage('loggedInUser') != null) {
      const currentUser = JSON.parse(getStorage('loggedInUser'));
      let carrierId = currentUser._id;
      if (currentUser.role === 'driver') {
        carrierId = currentUser.driver.carrier;
      }
      if (currentUser.role === 'fleetmanager') {
        carrierId = currentUser.fleetManager.carrier;
      }
      let reference_number;
      if (this.props.selectedLoads) {
        reference_number = this.props.selectedLoads.reference_number
      } else {
        reference_number = this.props.loads.reference_number
      }
      this.userPresense = firebase.database().ref(`${carrierId}/presense/${reference_number}/loadinfo/${currentUser._id}`);

      if (this.userPresense) {
        this.userPresense.set(true);
      }
    }
    this.props.handleSubmitLoad(this.handleSubmitLoad);
  }
  componentWillUnmount() {
    if (this.userPresense) {
      this.userPresense.set(false);
    }
  }

  handleSubmitLoad = () => {}

  updatePricing = data => {
    var payload = Object.assign(data, {
      reference_number: this.props.selectedLoads.reference_number
    });
    UpdatePricing("tms/updatePricingLoad", payload)
      .then(response => {
        toastr.show("Your pricing has been updated!", "success");
        $(".reloadCurrentLoad").click();
      })
      .catch(err => { });
  };

  submit = (data, customerProfile, isContainerConfirm, isChassisConfirm) => {
    const carrierDetail = JSON.parse(localStorage.getItem("carrierDetail"));
    if(carrierDetail.requireReference && !data.secondaryReferenceNo){
      toastr.show("You must enter a Reference # to save", "error");
      return;
    }
    this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(true);
    let originalFormData = JSON.parse(JSON.stringify(customerProfile));
    data["type_of_load"] = this.props.loadType;
    if (!customerProfile.status) {
      data["status"] = "PENDING";
    }
    let d = JSON.parse(JSON.stringify(data));
    var fields = [];
    const aa = [
      "caller",
      "chassisPick",
      "chassisTermination",
      "emptyOrigin",
      "containerType",
      "containerSize",
      "containerOwner",
      "chassisOwner",
      "chassisSize",
      "chassisType",
      "grayChassisSize",
      "grayChassisType",
      "grayChassisOwner",
      "grayContainerType",
      "grayContainerSize",
      "grayContainerOwner",
    ];
    _.filter(customerProfile, function (e, c) {
      if (aa.indexOf(c) > -1) {
        fields[c] = e;
      }
    });
    for (var prop in fields) {
      if (fields[prop] && fields[prop]._id) d[prop] = fields[prop]._id;
    }
    d["shipper"] = customerProfile.shipper.map(shipper => {
      return shipper._id;
    });
    d["consignee"] = customerProfile.consignee.map(consignee => {
      return consignee._id;
    });
    let shippers = [];
    let consignees = [];
    let pickupTimes = [];
    let deliveryTimes = [];
    d["shipper"].forEach((el, i) => {
      if (!el) return
      shippers.push(el);
      pickupTimes.push({ ...d["pickupTimes"][i], customerId: el })
    })
    d["consignee"].forEach((el, i) => {
      if (!el) return
      consignees.push(el)
      deliveryTimes.push({ ...d["deliveryTimes"][i], customerId: el })
    })
    d["shipper"] = shippers;
    d["consignee"] = consignees;
    d.pickupTimes = pickupTimes;
    d.deliveryTimes = deliveryTimes;
    if (this.props.pricing && this.props.pricing.rate) {
      d.pricing = [this.props.pricing];
    }
    if (!customerProfile.chassisNo && d.chassisNo) {
      d.chassisNumber = d.chassisNo;
      delete d.chassisNo;
    }
    d = JSON.parse(JSON.stringify(d));
    delete d.carrier;
    delete d.addedBy;
    if (
      ((getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
        (getStorage("currentUserRole") == "carrier" &&
          configuration.idForTerminal.indexOf(getStorage("currentUserID")) !=
          -1)) &&
      !customerProfile.terminal
    ) {
      toastr.show("Please enter a terminal.", "warning");
      this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
      return;
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      d.terminal = customerProfile.terminal.value?customerProfile.terminal.value:customerProfile.terminal._id;
    }

    if(d.items) {
      d.items = d.items.filter((com) => com.commodity || com.weight);
    }

    // Since we are using same redux form component we need to filter the load form values based on load type
    var filtereredData = {};
    if (this.props.loadType == "ROAD") {
      for (var prop in RoadField) {
        if (d[prop]) {
          filtereredData[prop] = d[prop];
        }
      }
    } else {
      filtereredData = Object.assign({}, d);
    }

    if (this.props.selectedLoads && this.props.selectedLoads.reference_number) {
      delete filtereredData.status;
      delete filtereredData.type_of_load;
      filtereredData.reference_number = this.props.selectedLoads.reference_number;
      if (  
        filtereredData.containerNo &&
        !/^[A-Za-z]{4}\d{6,8}$/.test(filtereredData.containerNo)
      ) {
        toastr.show("Please Enter Correct Container No", "warning");
        this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
        return;
      }
      if (  
        filtereredData.grayContainerNo &&
        !/^[A-Za-z]{4}\d{6,8}$/.test(filtereredData.grayContainerNo)
      ) {
        toastr.show("Please Enter Correct Gray Container No", "warning");
        this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
        return;
      }
      if (filtereredData.pickupTimes[0] && filtereredData.pickupTimes[0].pickupFromTime) {                  // remove this  code block after old keys removed from db  from
        filtereredData.pickupFromTime = filtereredData.pickupTimes[0].pickupFromTime;
        filtereredData.pickupToTime = filtereredData.pickupTimes[0].pickupToTime;
      }
      else {
        delete filtereredData.pickupFromTime; delete filtereredData.pickupToTime;
      }
      if (filtereredData.deliveryTimes[0] && filtereredData.deliveryTimes[0].deliveryFromTime) {
        filtereredData.deliveryFromTime = filtereredData.deliveryTimes[0].deliveryFromTime;
        filtereredData.deliveryToTime = filtereredData.deliveryTimes[0].deliveryToTime;
      }
      else {
        delete filtereredData.deliveryFromTime; delete filtereredData.deliveryToTime;
      }
      if (filtereredData.deliveryToTime == undefined) {
        delete filtereredData.deliveryToTime;
      }                                                                                                       /// to
      filtereredData.pickupTimes.map(d => { if (!d.pickupFromTime) delete d.pickupFromTime; if (!d.pickupToTime) delete d.pickupToTime; })                // remove _id and falsy keys from object
      filtereredData.deliveryTimes.map(d => { if (!d.deliveryFromTime) delete d.deliveryFromTime; if (!d.deliveryToTime) delete d.deliveryToTime; })        // remove _id and falsy keys from object
      filtereredData.pickupTimes = JSON.stringify(filtereredData.pickupTimes);
      filtereredData.deliveryTimes = JSON.stringify(filtereredData.deliveryTimes);
      if (filtereredData.shipper.length === 0) delete filtereredData.pickupTimes
      if (filtereredData.consignee.length === 0) delete filtereredData.deliveryTimes;
      if (isContainerConfirm) {
        filtereredData.isContainerConfirm = true;
      }
      if (isChassisConfirm) {
        filtereredData.isChassisConfirm = true;
      }
      delete filtereredData.preSetOrderStatus

      if(this.props.selectedLoads.emptyOrigin && this.props.selectedLoads.emptyOrigin._id &&  filtereredData.emptyOrigin && this.props.selectedLoads.emptyOrigin._id ===filtereredData.emptyOrigin ){
        delete filtereredData.emptyOrigin  
      }
      if(this.props.selectedLoads.lastFreeDay &&  filtereredData.lastFreeDay && this.props.selectedLoads.lastFreeDay ===filtereredData.lastFreeDay ){
        delete filtereredData.lastFreeDay  
      }
     
      filtereredData.source = 'loadinfo'
      let originalData=__.cloneDeep(this.props.selectedLoads)
      let diffData={
          containerSize:originalData && originalData.containerSize && originalData.containerSize._id,
          containerType:originalData && originalData.containerType && originalData.containerType._id,
          containerOwner:originalData && originalData.containerOwner && originalData.containerOwner._id,
          vessel:originalData && originalData.vessel && originalData.vessel.eta,
          lastFreeDay:originalData && originalData.lastFreeDay,
          dischargedDate:originalData && originalData.dischargedDate,
          outgateDate:originalData && originalData.outgateDate,
          ingateDate:originalData && originalData.ingateDate,
          deliveryOrderNo:originalData && originalData.deliveryOrderNo,
          releaseNo:originalData && originalData.releaseNo
      }
      let difference = differenceFinder.diff(JSON.parse(JSON.stringify(diffData)), JSON.parse(JSON.stringify(filtereredData)))
      if (difference) {
        difference = difference.map(i => i.path[0])
        const removeObject = (datatoRemove) => {
          Object.keys(datatoRemove).map(i => {
            if (!(difference.includes(i))) {
              delete datatoRemove[i]
            }
          })
          return datatoRemove
        }
        filtereredData = removeObject(filtereredData)
    }

      editLoad(filtereredData)
        .then(result => {
          if (result && result.data && result.data.data.hasContainer) {
            this.setState({ existingLoadChassis: [] }, () =>
              this.onReConfirmUpdate(data, originalFormData, true, null, this.submit)
            );
          } else if (result && result.data && result.data.data.hasChassis) {
            this.setState({ existingLoadChassis: result.data.data.existingLoadChassis }, ()=>
              this.onReConfirmUpdate(data, originalFormData, null, true, this.submit)
            );
          }
          else {
            // const { reference_number } = result.data.data;
            // this.props.dispatch({
            //   type: TMS_GET_USER_LOAD,
            //   reference_number:
            //     reference_number || filtereredData.reference_number,
            //   payload: {
            //     skip: 0,
            //     limit: 1
            //   }
            // });
            toastr.show("Updated!", "success");
            // if (this.props.updateList) {
            //   this.props.updateList(result.data.data);
            // }
          }
          this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
        })
        .catch(e => { this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false); console.log("fail", e) });
    } else {
      createLoad(filtereredData)
        .then(result => {
          this.props.dispatch({
            type: TMS_GET_USER_LOAD,
            reference_number: undefined,
            payload: {
              skip: 0,
              limit: 1
            }
          });
          this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);
          const { reference_number } = result.data.data;
          toastr.show("Successfully added", "success");
        })
        .catch(e => { this.props.changeSavingLoadStatus && this.props.changeSavingLoadStatus(false);});
    }
  };

  onReConfirmUpdate = (
    data,
    originalFormData,
    isContainerConfirm,
    isChassisConfirm,
    update
  ) => {
    // console.log(data)
    let title = isContainerConfirm
      ? "Container: "+data.containerNo
      : isChassisConfirm
      ? "Chassis: "+data.chassisNo
      : "";
    let body = (
      <div>
        <h4>
          {isContainerConfirm
            ? "This container is already in the system."
            : isChassisConfirm
            ? "This chassis is already assigned to an active load."
            : ""}
        </h4>
        <div>
          <ul className="list-group list-group-flush">
            {this.state.existingLoadChassis.length > 0 &&
              this.state.existingLoadChassis.map((reference_number) => {
                return (
                  <li className="list-group-item">
                    <a
                      href={`customer-service/load?reference_number=${encodeURIComponent(reference_number)}`}
                      target="_blank"
                    >
                      {reference_number}
                    </a>
                  </li>
                );
              })}
          </ul>
          <p>
            Do you want to save the {isContainerConfirm ? "Container: "+data.containerNo : "Chassis: "+data.chassisNo} to Load {data.reference_number}
            anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        update(data, originalFormData, isContainerConfirm, isChassisConfirm);
      }
    });
  };
  renderReturn = () => {
    return (
      <React.Fragment>
        <Notifications />
        {isNewModal()
        ? <NewAddLoad
            formClass=""
            {...this.props}
            submitForm={this.submit}
            updatePricing={this.updatePricing}
            hasReference={this.state.hasReference}
            onExit={this.props.onExit}
            stepsEnabled={this.props.stepsEnabled}
            initialStep={this.props.initialStep}
            isSaving={this.props.isSavingLoad}
            handleSubmitLoad={(submit) => { this.handleSubmitLoad = submit }}
            changeSavingLoadStatus={this.props.changeSavingLoadStatus}
            originalLoad={cloneDeep(this.props.selectedLoads)} 
          /> 
        : <AddLoad
            formClass=""
            {...this.props}
            submitForm={this.submit}
            updatePricing={this.updatePricing}
            hasReference={this.state.hasReference}
            onExit={this.props.onExit}
            stepsEnabled={this.props.stepsEnabled}
            initialStep={this.props.initialStep}
            isSaving={this.props.isSavingLoad}
            handleSubmitLoad={(submit) => { this.handleSubmitLoad = submit }}
            changeSavingLoadStatus={this.props.changeSavingLoadStatus}
            originalLoad={cloneDeep(this.props.selectedLoads)}
          />
        } 
        
      </React.Fragment>
    );
  };

  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (loggedInUser.role === "carrier" || loggedInUser.role === 'customer' || loggedInUser.role === 'fleetcustomer') return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (loggedInUser.permissions.includes("customer_service_load_info") || 
        (loggedInUser.role === 'fleetmanager' && loggedInUser.fleetManager.isCustomer) && loggedInUser.permissions.includes("customer_employee_load_info")) return this.renderReturn();
        else return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>
      else return <div className="nopermission"><h1>You do not have permission to view this page</h1></div>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
}
export default connect(null, mapDispatchToProps)(Load);
