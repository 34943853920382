import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link, browserHistory } from 'react-router'
import { ReCaptcha } from 'react-recaptcha-v3';

export const NewInputField = ({
  input,
  type,
  label,
  keyPress,
  placeholder,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={`form-group ${touched && error && 'error'}`}>
      <span>{label}</span>
      <input {...input} type={type} onKeyPress={keyPress} placeholder={placeholder} />
      {touched && ((error && <span className="error" style={{ color: 'red' }}>{error}</span>))}
    </div>
  )
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is Required*';
  }
  if (!values.password) {
    errors.password = 'Password is Required*';
  }
  if (values.password && values.password.length<5  ) {
    errors.password = 'Password length should not be less than 5  character long*';
  }
  if (values.password &&  values.password.length>50 ) {
    errors.password = 'Password length should not be greater than 50 character long*';
  }
  if (values.email && !values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    errors.email = 'Invalid Email Address*';
  }
  return errors;
};

class LoginComponent extends Component {
  constructor(props) {
    super(props);
  }

  signUpHandler = e => {
    e.preventDefault();
    browserHistory.push('/create-account')
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <>
      <form onSubmit={
        handleSubmit(data => {
          this.recaptcha.execute();
          this.props.submitForm(data)
        })
      }>
        <Field
          component={NewInputField}
          type="email"
          placeholder="Email*"
          name="email" />
        <Field
          component={NewInputField}
          type="password"
          placeholder="Password*"
          name="password" />
        <ReCaptcha
          ref={ref => this.recaptcha = ref}
          sitekey="6Lc9TrsZAAAAANl8CR4Ha7SkuS2e4EDImobE44lZ"
          action='submit'
          verifyCallback={this.props.verifyCallback}
        />

        <div className="boxes mt-4">
          {/* <input type="checkbox" id="box-1" /> */}
          <label htmlFor="box-1">By logging in, you agree to PortPro's <a href="https://portpro.io/privacy"> Privacy Policy </a> and <a href="https://portpro.io/privacy"> Terms & Conditions </a></label>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary btn-lg btn-block mt-40" data-testid="login-btn"> 
          {this.props.processing &&  
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            >
            </span>
          }
          Sign In </button>
        </div> 
        <Link to="/getresetToken" >
          <p className="forgot-pass text-center mt-3">Forgot password?</p>
        </Link>
        <p className="pt-3 text-center">Not on PortPro yet?
          <a href="javascript:void(0)" className="" onClick={this.signUpHandler}> Sign Up</a>
        </p> 
      </form>
    
        </>
    )
  }
}

LoginComponent = reduxForm({
  // a unique name for the form
  form: 'axlelogin',
  validate
})(LoginComponent);

export default LoginComponent;
