import React from 'react';
import ChatCardComponent from '../ChatCardComponent/chatCardComponent';

const MessageList = (props) => {
    const { messageList, displayChatBox } = props;
    return (
        <div className="clist" style={{maxHeight: 'calc( 100vh - 191px )'}}>
            {messageList.map((message) => {
                let lastMessage = '';
                if (message && message.messages && message.messages.length > 0) {
                    const messageData = message.messages[message.messages.length - 1];

                    if (messageData.type === 'url') {
                        lastMessage = 'Image';
                    }

                    if (messageData.type === 'text') {
                        lastMessage = messageData.msg;
                    }
                }

                return <ChatCardComponent details={message} key={message.groupID} displayChatBox={displayChatBox} lastMessage={lastMessage} />;
            })}
        </div>
    );
};

export default MessageList;
