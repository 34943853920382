const defaultState = {
  userType: null,
  unreadMessages: [],
  groups: [],
  terminals: []
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_TYPE':
      return {
        ...state,
        userType: action.payload
      }
    case 'CHANGE_NAV':
      return {
        ...state,
        commonUrl: action.payload
      }
    case 'SET_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: [...state.unreadMessages, { groupId: action.groupId, count: action.count, type: action.groupType }].unique()
      }
    case 'ADD_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: state.unreadMessages.map((el) => {
          if (el.groupId === action.groupId) {
            el.count = el.count + 1
          }
          return el
        }).unique()
      }
    case 'READ_ALL_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: state.unreadMessages.map((el) => {
          if (el.groupId === action.groupId) {
            el.count = 0
          }
          return el
        }).unique()
      }
    case 'REMOVE_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: state.unreadMessages.filter((el) => el.groupId !== action.groupId)
      }
    case 'RESET_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: []
      }
    case 'SET_GROUPS':
      // debugger
      return {
        ...state,
        groups: [...action.payload]
      }
    case 'ADD_GROUP':
      // debugger
      return {
        ...state,
        groups: [...state.groups, action.payload]
      }
    case 'REMOVE_GROUP':
      // debugger
      return {
        ...state,
        groups: state.groups.filter(group => group._id !== action.groupId)
      }
    case 'ADD_MESSAGE_TO_GROUP':
      // debugger
      return {
        ...state,
        groups: [...state.groups.map(group => {
          if(action.groupId === group._id)
            return {...group, messages: [...group.messages, action.message]}
          return group
        })]
      }
    case 'ADD_TERMINAL_DATA':
      return {
        ...state,
        terminals: [...action.payload]
      }
    default:
      return state;
  }
}
