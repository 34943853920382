import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { Overlay, Popover, Button, Modal } from "react-bootstrap";
import Customer from "../../../pages/tms/Customer/index";
import AddChassis from "../../../pages/tms/AddChassis/index";
import Truck from "../../../pages/tms/Truck/index";
import Trailer from "../../../pages/tms/Trailer/index";
import Driver from "../../../pages/tms/Driver/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { getStorage } from "../../../services/Common.services";
import { addCustomerCommonFunction } from "../../../services/Common.services";
import $ from "jquery";
import {
  IconCart,
  IconHomeCheck,
  IconPlus,
  IconSteering,
  IconTruck,
  IconUsers,
  IconLoads
} from "../Icons";
import AddNewLoad from "../../../pages/tms/Load/AddNewLoad";

let Selected_customerOriginal = {
  companyID: 0,
  company_name: "",
  address: "",
  lat: "",
  lng: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",
  main_contact_name: "",
  secondary_contact_name: "",
  mobile: "",
  email: "",
  billingEmail: "",
  password: "",
  credit_limit: "",
  payment_terms: "",
  account_hold: false,
  qbEmail: "",
  documents: [],
};
const carrierDetail = JSON.parse(getStorage("carrierDetail"));

const QuickAdd = (props) => {
  const [show, setShow] = useState(false);
  const [Selected_customer, setCustomer] = useState(Selected_customerOriginal);
  const [documents, setDocuments] = useState([]);
  const [addCustomer, setCustomerModal] = useState(false);
  const [addChassis, setChassisModal] = useState(false);
  const [addTruck, setTruckModal] = useState(false);
  const [addTrailer, setTrailerModal] = useState(false);
  const [addDriver, setDriverModal] = useState(false);
  const [tab, setTab] = useState(1);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const buttonRef = React.createRef();
  const [quickAddOptions, setQuickAddOptions] = useState(false);
  const [addNewLoad, setAddNewLoad] = useState(false);

  // const showQuickAddOptions = () => {
  //   setQuickAddOptions(!quickAddOptions);
  // };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (quickAddOptions && ref.current && !ref.current.contains(e.target)) {
        setQuickAddOptions(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [quickAddOptions]);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleOpenNewLoad = () => {
    setAddNewLoad(true); 
  }
  const handleCloseNewLoad = () => {
    setAddNewLoad(false); 
  }

  const handleClose = () => setShow(false);

  const addNewCustomer = (data) => {
    delete data.pdfMap;
    delete data.usageCount;
    return new Promise((resolve, reject) => {
      addCustomerCommonFunction(data).then((Data) => {
        if (Data.data.data && Data.data.data.user) {
          resolve(Data.data.data.user._id);
          setCustomerModal(false);
        }
      });
    });
  };

  const falsyData = () => {
    $("#chassisModal").toggle();
    $("#trailer").toggle();
    $("#truck").toggle();
    $("#driver").toggle();

    setCustomerModal(false);
    setChassisModal(false);
    setTruckModal(false);
    setTrailerModal(false);
    setDriverModal(false);
  };

  const isShiposCarrier = JSON.parse(getStorage("carrierDetail"))?.isShiposCarrier;

  return (
    <React.Fragment>
      <div className="nav-link d-flex align-items-center" ref={ref}>
        <div onClick={() => !isShiposCarrier && setQuickAddOptions((oldState) => !oldState)}>
          <div className="btn btn-success btn-circle btn-sm pointer"
          data-tip
          data-for="QuickAdd"
           >
            <IconPlus />
          </div>
          <ReactTooltip
            place="bottom"
            effect="solid"
            id="QuickAdd"
            className="react-tooltip-new header__tooltip"
          >
            <span>{isShiposCarrier ? "Upgrade to pro":"Quick Add"}</span>
          </ReactTooltip>
        </div>

        {quickAddOptions && (
          <div
            id="popover-contained"
            className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--hcenter mb-0 p-2 animated w-200"
          >
            <li
              className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
             onClick={handleOpenNewLoad}
            >
              <IconLoads className="mr-3 wh-20px" />
              <span>Add New Load</span>
            </li>
            <li
              className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
              onClick={() => {
                setCustomerModal(true);
                setShow(false);
              }}
            >
              <IconUsers className="mr-3 wh-20px" />
              <span>Add Customer</span>
            </li>
            <li
              className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
              onClick={() => {
                setChassisModal(true);
                setShow(false);
              }}
            >
              <IconHomeCheck className="mr-3 wh-20px" />
              <span>Add Chassis</span>
            </li>
            <li
              className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
              onClick={() => {
                setTruckModal(true);
                setShow(false);
              }}
            >
              <IconTruck className="mr-3 wh-20px" />
              <span>Add Truck</span>
            </li>
            <li
              className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
              onClick={() => {
                setTrailerModal(true);
                setShow(false);
              }}
            >
              <IconCart className="mr-3 wh-20px" />
              <span>Add Trailer</span>
            </li>
            <li
              className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer"
              onClick={() => {
                setDriverModal(true);
                setShow(false);
              }}
            >
              <IconSteering className="mr-3 wh-20px" />
              <span>Add Driver</span>
            </li>
          </div>
        )}
      </div>
      {addNewLoad ?
        <Modal show={addNewLoad} bsSize="sm" dialogClassName="modal-dialog-scrollable" animation="false" data-testid="create-load">
          <Modal.Header>
            <h4 className="modal-title" id="myModalLabel">
              Create Load
            </h4>
          </Modal.Header>
          <AddNewLoad OpenLoadInfo={props.OpenLoadInfo} closeModal={handleCloseNewLoad} isQuickAdd={true}/>
        </Modal>
      : "" }
      {addCustomer && 
        <Customer isOnlyPopup={addCustomer} falsyData={falsyData} isQuickAdd={true} />
      }

      <Modal
        show={addChassis}
        id="chassisModal"
        // ref={(modal) => (this.chassisModal = modal)}
        bsSize="lg"
        className="quickadd-wrapper modal-extend"
        dialogClassName="modal-dialog-scrollable"
      >
        <AddChassis isOnlyPopup={addChassis} falsyData={falsyData} isQuickAdd={true}/>
      </Modal>
      {addTruck && 
        <Truck isOnlyPopup={addTruck} falsyData={falsyData} isQuickAdd={true} />
      }
      {addTrailer && 
       <Trailer isOnlyPopup={addTrailer} falsyData={falsyData} isQuickAdd={true}/>
      }
      {addDriver && 
        <Driver isOnlyPopup={addDriver} falsyData={falsyData} isQuickAdd={true}/>
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    Terminals: state.HomeReducer.terminals,
  };
};
export default connect(mapStateToProps, null)(QuickAdd);
