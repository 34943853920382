import React from 'react'

const CustomTooltip = ({ position, content }) => {
    return (
        <div
            className={`tooltip bs-tooltip-${position} bg-gray-700 text-white tooltip-custom w-max`}
            data-placement={position}
        >
            <div className="arrow"></div>
            {content}
        </div>
    )
}

export default CustomTooltip;