import {  IconUsers,
    IconDocument,
    IconMapWithMarker,
    IconNotes,
    IconSearchData,
    IconUsersGroup,
    IconSendEmail, } from "../../../../Components/Common/Icons";

export const Required = (element) => {
    const Element = document.getElementById(`${element}`)
    Element?.classList.add('background-failure');
}

export const SetRequired = (element) => {
    const Element = document.getElementById(`${element}`)
    Element?.classList.remove('background-failure');
}

export const successColorResponse = (element) => {
    const Element = document.getElementById(`${element}`)
    Element?.classList.add('background-success');
    Element.style.transition = "background .4s ease-out";

    setTimeout(() => {
        Element?.classList.remove("background-success")
    }, 400);
}

export const errorColorResponse = (element) => {
    const Element = document.getElementById(`${element}`)

    Element?.classList.add("background-error")
    Element.style.transition = "background .4s ease-out";

    setTimeout(() => {
        Element.classList.remove("background-error")
    }, 400);
}

export const onChangeSuccessColorResponse = (key) => {
    const Element = document?.querySelector(`.${key}`);
    let divInElement;
    divInElement = Element?.querySelector("div");
    divInElement.classList.add('background-success');
    divInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        divInElement.classList.remove("background-success")
    }, 400);
}

export const onChangeErrorColorResponse = (key) => {
    const Element = document?.querySelector(`.${key}`);
    let divInElement;
    divInElement = Element?.querySelector("div");
    divInElement.classList.add('background-error');
    divInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        divInElement.classList.remove("background-error")
    }, 400);
}

export const onDateChangeSuccesssColorResponse = (key) => {
    const Element = document?.querySelector(`.${key}`);
    let inputInElement;
    inputInElement = Element?.querySelector("input");
    inputInElement.classList.add('background-success');
    inputInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        inputInElement.classList.remove("background-success")
    }, 400);
}

export const onDateChangeErrorColorResponse = (key) => {
    const Element = document?.querySelector(`.${key}`);
    let inputInElement;
    inputInElement = Element?.querySelector("input");
    inputInElement.classList.add('background-error');
    inputInElement.style.transition = "background .4s ease-out";
    setTimeout(() => {
        inputInElement.classList.remove("background-error")
    }, 400);
}

export let TabLists = [
    {
        label: "Customer",
        value: "customer",
        icon: <IconUsers />,
        id: 1,
    },
    {
        label: "Documents",
        value: "documents",
        icon: <IconDocument />,
        id: 2,
    },
    {
        label: "Geofence",
        value: "geofence",
        icon: <IconMapWithMarker />,
        id: 3,
    },
    {
        label: "Notes",
        value: "notes",
        icon: <IconNotes />,
        id: 4,
    },
    {
        label: "Audit",
        value: "audit",
        icon: <IconSearchData />,
        id: 5,
    },
    {
        label: "Employees",
        value: "employees",
        icon: <IconUsersGroup />,
        id: 6,
    },
    {
        label: "Email Notification",
        value: "emailnotification",
        icon: <IconSendEmail />,
        id: 7,
    },
];
